import { IRequestCreateAssessment } from "src/entities/public/create-assessment";
import { gameService } from "src/shared/api/base/api-instances";

export const postCreateAssessmentGame = (
  parameters: IRequestCreateAssessment,
) => {
  const { id, assessment } = parameters;

  return gameService
    .makePlayerGamesApiFactory()
    .postGameCreateAssessment(id, assessment)
    .then((res) => res.data);
};
