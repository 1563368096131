import { sample } from "effector";

import {
  $queryParams,
  emailNotificationDomain,
  getEmailsListFx,
} from "./email-notification";

import { queryParamsToGetEmailListAdapter } from "../lib";

// Event

export const refreshEmailList = emailNotificationDomain.createEvent();

// Logic

sample({
  clock: refreshEmailList,
  source: $queryParams,
  fn: queryParamsToGetEmailListAdapter,
  target: getEmailsListFx,
});
