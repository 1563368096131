import { useUnit } from "effector-react";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { Tooltip } from "react-tooltip";
import {
  $countriesChart,
  $regionsChart,
} from "src/entities/hr/charts/model/charts";
import { MapCountry, MapRegion } from "src/generated/social";
import { MapMarker, russiaCityMarkers } from "../helpers/constants";
import styles from "./map-chart.module.scss";

interface MapData {
  map: string;
  center: [number, number];
  scale: number;
  cities: MapMarker[];
}

interface GeoJsonItem {
  geometry: Record<string, any>;
  properties: Record<string, any>;
  rsmKey: string;
  svgPath: string;
  type: string;
}

const data: Record<"russia" | "world", MapData> = {
  russia: {
    map: "russia_geojson_wgs84.json",
    center: [105, 70],
    scale: 300,
    cities: russiaCityMarkers,
  },
  world: {
    map: "ne_110m_admin_0_countries.geojson",
    center: [0, 50],
    scale: 160,
    cities: [],
  },
};

interface Props {
  view: "russia" | "world";
}

export const MapChart: FC<Props> = ({ view }) => {
  const { t } = useTranslation();

  const [tooltipContent, setTooltipContent] =
    useState<Partial<MapRegion | MapCountry>>();
  const [isVisibleTooltip, setIsVisibleTooltip] = useState<boolean>(false);

  const { regions } = useUnit($regionsChart);
  const { countries } = useUnit($countriesChart);

  const maxUsers = useMemo(() => {
    if (view === "russia") {
      return regions.reduce(
        (biggest, current) =>
          current.p_count > biggest ? current.p_count : biggest,
        0,
      );
    }

    return countries.reduce(
      (biggest, current) =>
        current.p_count > biggest ? current.p_count : biggest,
      0,
    );
  }, [regions, countries, view]);

  const getColor = (geo: GeoJsonItem) => {
    let users;

    if (view === "russia") {
      users = regions.find(
        (region) => region.title === geo.properties.full_name,
      )?.p_count;
    }

    if (view === "world") {
      users = countries.find(
        (country) => country.code === geo.properties.ISO_A2_EH,
      )?.p_count;
    }

    let opacity = (users ?? 0) / maxUsers + 0.2;

    return `rgba(40, 58, 151, ${opacity})`;
  };

  const setTooltip = (geo: GeoJsonItem) => {
    setIsVisibleTooltip(true);

    if (view === "russia") {
      return setTooltipContent(
        regions.find((region) => region.title === geo.properties.full_name) ?? {
          title: geo.properties.full_name,
        },
      );
    }

    setTooltipContent(
      countries.find(
        (country) => country.code === geo.properties.ISO_A2_EH,
      ) ?? {
        title: geo.properties.NAME_RU,
      },
    );
  };

  return (
    <div className={styles.map_wrapper}>
      <ComposableMap
        className={styles.map}
        projection="geoMercator"
        projectionConfig={{
          center: data[view].center,
          scale: data[view].scale,
          rotate: [-10, 0, 0],
        }}
      >
        <ZoomableGroup>
          <Geographies geography={`/maps/${data[view].map}`}>
            {({ geographies }) =>
              geographies.map((geo: GeoJsonItem) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltip(geo);
                  }}
                  onMouseLeave={() => {
                    setIsVisibleTooltip(false);
                  }}
                  fill={getColor(geo)}
                  stroke="white"
                  strokeWidth={1}
                  className={styles.geography}
                  cursor="pointer"
                />
              ))
            }
          </Geographies>
          {data[view].cities.map(({ name, coordinates, size }) => (
            <Marker
              key={name}
              coordinates={coordinates}
              className={styles.marker}
            >
              <rect
                x="0.5"
                width={Math.ceil(size / 2)}
                height={Math.ceil(size / 2)}
                rx="1"
                fill="#FF3D3D"
              />
              <text
                className={styles.city_label}
                textAnchor="middle"
                y={-Math.ceil(size / 4)}
                x={2 - Math.ceil(size / 4)}
                fontSize={size}
              >
                {name}
              </text>
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>

      <Tooltip
        anchorSelect={`.${styles.map_wrapper}`}
        float
        noArrow
        place="bottom"
        className={styles.tooltip}
        isOpen={isVisibleTooltip}
      >
        <h5>{tooltipContent?.title}</h5>
        {tooltipContent?.p_count ? (
          <ul>
            <li>
              <span>{t("navigation.users")}</span>
              <span>{tooltipContent?.p_count}</span>
            </li>
            <li>
              <span>{t("charts.map.rating", { rating: 0.5 })}</span>
              <span>{tooltipContent?.rate_eq_05}</span>
            </li>
            <li>
              <span>{t("charts.map.rating", { rating: 1 })}</span>
              <span>{tooltipContent?.rate_eq_1}</span>
            </li>
            <li>
              <span>{t("charts.map.rating", { rating: "1+" })}</span>
              <span>{tooltipContent?.rate_gt_1}</span>
            </li>
          </ul>
        ) : (
          <p>{t("charts.map.no_users")}</p>
        )}
      </Tooltip>
    </div>
  );
};
