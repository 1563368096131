interface IPayload {
  playUrl: string;
  assessmentId: string;
  language: string;
  queryParams: string;
}

export const constructPlayUrl: (payload: IPayload) => string = (
  payload: IPayload
) => {
  return (
    payload.playUrl
      .replace("{assessment_uid}", payload.assessmentId)
      .replace("{locale}", payload.language) +
    (payload.queryParams.length > 1 ? payload.queryParams : "")
  );
};
