import { FC, ReactElement, useEffect } from "react";
import { PageWrapper } from "src/shared/components";
import { ContactsForm } from "../components";
import { Manager } from "../../../generated/social";
import {
  cleanContact,
  requestContactCreate,
} from "../../../shared/store/ducks/contacts/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectContactsItemStatusCreate } from "../../../shared/store/ducks/contacts/selectors";
import { ERequestStatus } from "../../../shared/store/types";
import { useTranslation } from "react-i18next";
import { selectCdnOptionsContactAvatarStatus } from "../../../shared/store/ducks/cdn/selectors";
import {
  cleanOptionsContactAvatar,
  requestOptionsContactAvatar,
} from "../../../shared/store/ducks/cdn/actionCreators";

interface Props {}

export const AddContactPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingStatusAdd = useSelector(selectContactsItemStatusCreate);
  const loadingStatusOptions = useSelector(selectCdnOptionsContactAvatarStatus);
  const isLoadingAdd: boolean = loadingStatusAdd === ERequestStatus.LOADING;
  const isLoadingOptions: boolean =
    loadingStatusOptions === ERequestStatus.LOADING;

  useEffect(() => {
    dispatch(requestOptionsContactAvatar());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanContact());
      dispatch(cleanOptionsContactAvatar());
    };
  }, [dispatch]);

  const onSaveHandler = (contactItem: Manager) => {
    const callback = () => {
      navigate("/other/edit-contacts");
    };

    dispatch(
      requestContactCreate({
        options: {
          body: contactItem,
        },
        callback,
      }),
    );
  };

  return (
    <PageWrapper
      title={t("other.editContacts.pageTitle.newContact")}
      emptyPanel
      backButton
      isLoadingPanel={isLoadingOptions}
    >
      <div className="add-contact-page">
        <ContactsForm
          isDisabledBtn={isLoadingAdd}
          btnLabel={t("other.editContacts.btn.createContact")}
          onSave={onSaveHandler}
        />
      </div>
    </PageWrapper>
  );
};
