import { createEvent, createStore, sample } from "effector";
import { ITeam } from "src/pages/sessions/session-page/components/session-table/session-table";
import { $updatedLobbyPlayers } from "../../../../pages/sessions/session-page/components/session-table/components/allocate-player-popup/lib/lobbyPlayerList";

// stores

export const $sessionTeams = createStore<ITeam[]>([]);

export const $sessionPlayerChanged = createStore<boolean>(false);

// events

export const setSessionTeams = createEvent<ITeam[]>();

// logic

sample({
  clock: setSessionTeams,
  target: [$sessionTeams, $sessionPlayerChanged, $updatedLobbyPlayers],
});

sample({
  clock: setSessionTeams,
  fn: (teams: ITeam[]) => {
    return teams.flatMap(({ players }) => players);
  },
  target: $updatedLobbyPlayers,
});
