import { Fragment, memo, PropsWithChildren, ReactElement } from "react";
import { Link } from "react-router-dom";
import { IDataGridUserAvatarProps } from "../../../models/dataGrid";
import { BaseAvatar } from "../../base-avatar/base-avatar";
import classNames from "classnames";

export const DataGridUserAvatar = memo<
  PropsWithChildren<IDataGridUserAvatarProps>
>((props): ReactElement => {
  const fullName = props.fullName || props.children || "";

  const Inner = () => (
    <Fragment>
      <BaseAvatar className="data-grid__user-avatar-image-wrapper" {...props} />
      {fullName && (
        <span className="data-grid__user-avatar-label">{fullName}</span>
      )}
    </Fragment>
  );

  if (props.link) {
    return (
      <Link
        to={props.link}
        className="data-grid__user-avatar"
        title={props.fullName}
        onClick={props.onClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Inner />
      </Link>
    );
  }

  return (
    <span
      className={classNames("data-grid__user-avatar", {
        "data-grid__user-avatar--clickable": !!props.onClick,
      })}
      title={props.fullName}
      onClick={props.onClick}
    >
      <Inner />
    </span>
  );
});
