import { sample } from "effector";

import { QueryParams } from "../../../../shared/api/base";

import { queryParamsToGetListParamsAdapter } from "../lib";

import { $fines, $pagination, $queryParams, finesDomain } from "./fines";
import { getList } from "../../../../shared/api/admin/fines/model";

// Events

export const readmoreFines = finesDomain.createEvent<QueryParams>();

// Effects

export const readmoreFinesFx = finesDomain.createEffect(getList);

// Logic

sample({
  clock: [readmoreFines],
  fn: queryParamsToGetListParamsAdapter,
  target: readmoreFinesFx,
});

sample({
  clock: readmoreFines,
  target: $queryParams,
});

sample({
  clock: readmoreFinesFx.doneData,
  source: $fines,
  fn: (fines, data) => [...fines, ...data.fines],
  target: $fines,
});

sample({
  clock: readmoreFinesFx.doneData,
  fn: (data) => data.pagination || null,
  target: $pagination,
});
