import { Dispatch, FC, ReactElement, SetStateAction, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { LocStrDto } from "../../../../generated/social";
import {
  BaseButton,
  FormControl,
  TextWithIcon,
} from "../../../../shared/components";
import { IFaqItem } from "../../edit-faq-page/edit-faq-page";
import "./edit-faq-item.scss";
import {
  BaseInputText,
  BaseTextEditor,
} from "../../../../shared/components/form-fields";
import { IBaseInputChange } from "../../../../shared/models";

interface Props {
  index: number;
  indexInList: number;
  item: IFaqItem;
  activeLanguage: keyof LocStrDto;
  onChangeQuestion: (payload: IBaseInputChange, index: number) => void;
  onChangeAnswer: (payload: IBaseInputChange, index: number) => void;
  onDelete: (index: number) => void;
  setHasErrors: Dispatch<SetStateAction<Record<string, boolean>>>;
}

export const EditFaqItem: FC<Props> = ({
  index,
  indexInList,
  item,
  activeLanguage,
  onChangeQuestion,
  onChangeAnswer,
  onDelete,
  setHasErrors,
}): ReactElement => {
  const { t } = useTranslation();

  const onDeleteItem = () => {
    onDelete(index);
  };

  const isQuestionBeFilled = !!(
    !item.question[activeLanguage] && item.answer[activeLanguage]
  );

  const isAnswerBeFilled = !!(
    item.question[activeLanguage] && !item.answer[activeLanguage]
  );

  useEffect(() => {
    setHasErrors((hasErrors) => ({
      ...hasErrors,
      [activeLanguage]: isQuestionBeFilled || isAnswerBeFilled,
    }));
  }, [setHasErrors, isAnswerBeFilled, isQuestionBeFilled, activeLanguage]);

  const handleChangeQuestion = (payload: IBaseInputChange) =>
    onChangeQuestion(payload, index);

  const handleChangeAnswer = (payload: IBaseInputChange) =>
    onChangeAnswer(payload, index);

  return (
    <div className="edit-faq-item">
      <div className="edit-faq-item__header">
        <div className="edit-faq-item__header-title">{`${t(
          "editFaq.subtitle.question",
        )} №${indexInList + 1}`}</div>
        <BaseButton onClick={onDeleteItem}>
          <TextWithIcon
            label={t("editFaq.btn.delete")}
            iconName="trash-red"
            color="red"
          />
        </BaseButton>
      </div>
      <FormControl
        error={isQuestionBeFilled ? t("editFaq.errorMessage.notEmpty") : ""}
      >
        <BaseInputText
          placeholder={t("editFaq.subtitle.question")}
          value={item.question[activeLanguage] || ""}
          onChange={handleChangeQuestion}
          error={isQuestionBeFilled}
        />
      </FormControl>
      <FormControl
        error={isAnswerBeFilled ? t("editFaq.errorMessage.notEmpty") : ""}
      >
        <BaseTextEditor
          value={item.answer[activeLanguage] || ""}
          onChange={handleChangeAnswer}
          className={classNames({
            "quill-error": isAnswerBeFilled,
          })}
        />
      </FormControl>
    </div>
  );
};
