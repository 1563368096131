import { sample } from "effector";
import { Socket } from "socket.io-client";

import { LobbyAssessment } from "src/generated/ws4";

import { namespaces, paths, urls } from "../config";

import { connectSocket, SocketParams } from "../../lib/connect";

import { socketsV4Domain } from "./sockets";

import { LobbySocketCommander } from "./commands";

import { LobbySocketHandler } from "./events";

// Events

export const initGameV4Lobby = socketsV4Domain.createEvent<void>();

export const subGameV4Lobby = socketsV4Domain.createEvent<void>();

export const unSubGameV4Lobby = socketsV4Domain.createEvent<void>();

export const setLobbyGameV4Assessments =
  socketsV4Domain.createEvent<LobbyAssessment[]>();

// Effects

const initGameV4LobbyFx = socketsV4Domain.createEffect(connectSocket);

const addHandlersFx = socketsV4Domain.createEffect((socket: Socket | null) => {
  if (!socket) return;

  LobbySocketHandler.of(socket).onList((res) =>
    setLobbyGameV4Assessments(res.assessments),
  );
});

const subGameV4LobbyFx = socketsV4Domain.createEffect(
  (socket: Socket | null) => {
    if (!socket) return;

    LobbySocketCommander.of(socket).sub();
  },
);

const unSubGameV4LobbyFx = socketsV4Domain.createEffect(
  (socket: Socket | null) => {
    if (!socket) return;

    LobbySocketCommander.of(socket).sub();
  },
);

// Store

export const $socketGameV4Lobby = socketsV4Domain.createStore<Socket | null>(
  null,
);

export const $assessmentsLobby = socketsV4Domain.createStore<LobbyAssessment[]>(
  [],
);

export const $socketGameV4LobbyCommander =
  socketsV4Domain.createStore<LobbySocketCommander | null>(null);

export const $socketGameV4LobbyHandler =
  socketsV4Domain.createStore<LobbySocketHandler | null>(null);

// Logic

sample({
  clock: initGameV4Lobby,
  fn: (): SocketParams => ({
    path: paths.game.v4,
    url: urls.game,
    namespace: namespaces.game.lobby,
  }),
  target: initGameV4LobbyFx,
});

sample({
  clock: initGameV4LobbyFx.doneData,
  target: $socketGameV4Lobby,
});

sample({
  clock: $socketGameV4Lobby,
  target: addHandlersFx,
});

sample({
  clock: $socketGameV4Lobby,
  target: subGameV4LobbyFx,
});

sample({
  clock: setLobbyGameV4Assessments,
  target: $assessmentsLobby,
});

sample({
  clock: subGameV4Lobby,
  source: $socketGameV4Lobby,
  target: subGameV4LobbyFx,
});

sample({
  clock: unSubGameV4Lobby,
  source: $socketGameV4Lobby,
  target: unSubGameV4LobbyFx,
});

sample({
  clock: $socketGameV4Lobby,
  fn: (socket) => socket && LobbySocketCommander.of(socket),
  target: $socketGameV4LobbyCommander,
});

sample({
  clock: $socketGameV4Lobby,
  fn: (socket) => socket && LobbySocketHandler.of(socket),
  target: $socketGameV4LobbyHandler,
});
