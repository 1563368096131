import { AdmCalcDto, GameType } from "../../../../generated/game";
import {
  IFormulasData,
  TFormulasDataCompetence,
  TFormulasDataSingle,
} from "./types";

export const composeFormulasData = (
  prev: IFormulasData | null,
  supportedGameTypes: GameType[],
  calcs: AdmCalcDto[],
) => {
  const localCompetenceFormulas: TFormulasDataCompetence = {};
  const localSingleFormulas: TFormulasDataSingle = {};

  supportedGameTypes.forEach((gameType: GameType) => {
    const isTeamGameType: boolean =
      gameType === GameType.Team || gameType === GameType.Corp;

    const tmpDislikeFormulas: Record<string, string> = {};

    if (isTeamGameType) {
      tmpDislikeFormulas.dislike = "";
    }

    localCompetenceFormulas[gameType] = {
      general: "",
    };

    const competenceCalcTypeKeys: string[] = Object.keys(
      localCompetenceFormulas[gameType],
    );

    competenceCalcTypeKeys.forEach((calcType) => {
      const currentCalc: AdmCalcDto | undefined = calcs.find(
        (calc) => calc.calcType === calcType && calc.gameType === gameType,
      );

      const formulaDataValue: string | undefined =
        prev?.competenceFormulas[gameType][calcType];

      localCompetenceFormulas[gameType][calcType] =
        formulaDataValue || currentCalc?.formula || "";
    });

    localSingleFormulas[gameType] = { ...tmpDislikeFormulas };

    const singleCalcTypeKeys: string[] = Object.keys(
      localSingleFormulas[gameType],
    );

    singleCalcTypeKeys.forEach((calcType) => {
      const currentCalc: AdmCalcDto | undefined = calcs.find(
        (calc) => calc.calcType === calcType && calc.gameType === gameType,
      );

      const formulaDataValue: string | undefined =
        prev?.singleFormulas[gameType][calcType];

      localSingleFormulas[gameType][calcType] =
        formulaDataValue || currentCalc?.formula || "";
    });
  });

  return {
    localCompetenceFormulas,
    localSingleFormulas,
  };
};
