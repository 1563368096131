import { createStore, createEvent, sample } from "effector";
import { AdmAssessmentPlayerDto } from "../../../../../../../../generated/game";

export const setUpdatedLobbyPlayers = createEvent<AdmAssessmentPlayerDto[]>();

export const $updatedLobbyPlayers = createStore<AdmAssessmentPlayerDto[]>([]);

sample({
  clock: setUpdatedLobbyPlayers,
  fn: (payload) => payload,
  target: $updatedLobbyPlayers,
});
