import { combine, sample } from "effector";

import { keycloakModel } from "src/entities/public/keycloak";

import { Player, PlayersApiGetPlayerRequest } from "src/generated/social";

import { getIsSomeCompetenceFirstLvl } from "../lib/getIsSomeCompetenceFirstLvl";
import { getIsVerifiedSession } from "../lib/getIsVerifiedSession";

import { appAccessDomain } from "./domain";
import {
  EPopupName,
  openResponseErrorPopup,
} from "../../../../shared/components";
import { getUserProfileCompetence } from "../../../../shared/api/public/profile-competence/model/profile-competence";
import { openPopup } from "../../../../shared/components/base-popup/model";
import { patchPlayerById } from "../../../../shared/api/hr/rating-statuses";
const { $isPlayerClientId } = keycloakModel;

// Stores

export const $socialPlayerInfo = appAccessDomain.createStore<Player | null>(
  null,
);

export const $isSocialPlayerInfoLoading =
  appAccessDomain.createStore<boolean>(true);

export const $hasSocial = appAccessDomain.createStore<boolean>(true);

export const $isSomeCompetenceFirstLvl = appAccessDomain.createStore<
  boolean | null
>(null);

export const $isVerificationSession = appAccessDomain.createStore<
  boolean | null
>(null);

// Events

export const setSocialPlayerInfo = appAccessDomain.createEvent<Player>();

export const setIsSomeCompetenceFirstLvl =
  appAccessDomain.createEvent<boolean>();

export const setIsVerificationSession = appAccessDomain.createEvent<boolean>();

export const getSocialProfileInfo =
  appAccessDomain.createEvent<PlayersApiGetPlayerRequest>();

// Effects

export const fetchSocialProfileInfoFx = appAccessDomain.createEffect(
  getUserProfileCompetence,
);

// Logic

sample({ clock: setSocialPlayerInfo, target: $socialPlayerInfo });

sample({
  clock: fetchSocialProfileInfoFx.pending,
  target: $isSocialPlayerInfoLoading,
});

sample({
  clock: [fetchSocialProfileInfoFx.done],
  fn: () => false,
  target: $isSocialPlayerInfoLoading,
});

sample({
  clock: setIsSomeCompetenceFirstLvl,
  target: $isSomeCompetenceFirstLvl,
});

sample({ clock: setIsVerificationSession, target: $isVerificationSession });

sample({
  clock: $socialPlayerInfo,
  fn: getIsSomeCompetenceFirstLvl,
  target: setIsSomeCompetenceFirstLvl,
});

sample({
  clock: combine(
    $socialPlayerInfo,
    $isPlayerClientId,
    (socialPlayerInfo, isPlayerClientId) => ({
      socialPlayerInfo,
      isPlayerClientId,
    }),
  ),
  fn: getIsVerifiedSession,
  target: setIsVerificationSession,
});

sample({
  clock: keycloakModel.$keycloak,
  target: fetchSocialProfileInfoFx,
  fn: (keycloak) => ({ pId: keycloak?.subject || "" }),
});

sample({
  clock: getSocialProfileInfo,
  target: fetchSocialProfileInfoFx,
});

sample({
  clock: fetchSocialProfileInfoFx.doneData,
  target: $socialPlayerInfo,
});

sample({
  clock: fetchSocialProfileInfoFx.failData,
  target: $hasSocial,
  fn: (error: any) => {
    openResponseErrorPopup(error.response?.data?.err?.msg);
    return false;
  },
});

fetchSocialProfileInfoFx.doneData.watch((data) => {
  if (!data.is_agreement_confirmed) {
    openPopup({
      name: EPopupName.AGREEMENT_CONFIRMED,
      data: {
        callback: () => {
          const response = patchPlayerById({
            pId: data.id,
            body: { is_agreement_confirmed: true },
          });
          return response;
        },
      },
    });
  }
});
