import classNames from "classnames";
import { FC, ReactElement } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BaseButton, TextWithIcon } from "..";
import "./copy-button.scss";

interface Props {
  className?: string;
  copyValue: string;
  onCopy?: () => void;
  transparent?: boolean;
  noPadding?: boolean;
}

export const CopyButton: FC<Props> = ({
  copyValue,
  onCopy,
  className,
  transparent,
  noPadding,
}): ReactElement => {
  return (
    <CopyToClipboard text={copyValue} onCopy={onCopy}>
      <BaseButton
        white={transparent}
        noPaddingX={noPadding}
        lightPurple={!transparent}
        className={classNames("copy-button", className)}
      >
        <TextWithIcon iconName={"copy-icon"} iconSize={24} hideLabel isBlock />
      </BaseButton>
    </CopyToClipboard>
  );
};
