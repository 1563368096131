import parsePhoneNumber from "libphonenumber-js";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ERegEx, regExList } from "../constants/regExList";
import { isStringNumber } from "../helpers/isStringNumber";

export enum EInputValidateType {
  STRING = "string",
  NUMBER = "number",
  ARRAY_STRING = "array-string",
  ARRAY_NUMBER = "array-number",
  EMAIL = "email",
  PHONE_NUMBER = "phone-number",
  LINK = "link",
}

export interface IValidateValue {
  value: string | string[];
  type: EInputValidateType;
  required?: boolean;
  min?: number;
  max?: number;
}

const useInput = (initialValue?: string) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(initialValue || "");
  const productionEnvironment = "https://hr.alabuga.ru";

  const onChange = (e: ChangeEvent) => {
    setValue((e.target as HTMLInputElement).value);
  };

  const validateValue = (
    payload: Record<string, IValidateValue>,
  ): Record<string, string> => {
    const errors: { [key: string]: string } = {};
    const payloadItems: string[] = Object.keys(payload);

    payloadItems.forEach((item) => {
      const payloadItem = payload[item];
      const isArrayString: boolean =
        EInputValidateType.ARRAY_STRING === payloadItem.type;
      const isArrayNumber: boolean =
        EInputValidateType.ARRAY_NUMBER === payloadItem.type;
      const isArray: boolean = isArrayString || isArrayNumber;
      const isEmail: boolean = EInputValidateType.EMAIL === payloadItem.type;
      const isNumber: boolean = EInputValidateType.NUMBER === payloadItem.type;
      const isPhoneNumber: boolean =
        EInputValidateType.PHONE_NUMBER === payloadItem.type;

      const hasValue: boolean = isArray
        ? !!(payloadItem.value as string[]).length
        : !!(payloadItem.value as string)?.trim().length;

      if (!hasValue) {
        if (payloadItem.required) {
          errors[item] = t("userForm.errorMesasage.isEmpty");

          return;
        } else {
          return;
        }
      }

      if (isEmail) {
        const isInvalidEmail = !regExList[ERegEx.VALID_EMAIL].test(
          (payloadItem.value as string).toLowerCase(),
        );

        if (isInvalidEmail) {
          errors[item] = t("userForm.errorMesasage.invalidEmail");

          return;
        }
      }

      let isInvalidNumber: boolean = false;

      if (isPhoneNumber) {
        try {
          isInvalidNumber =
            !parsePhoneNumber(payloadItem.value as string)?.isValid() &&
            process.env.REACT_APP_DOMAIN === productionEnvironment;
        } catch (e) {
          isInvalidNumber = true;
        }

        if (isInvalidNumber) {
          errors[item] = t("userForm.errorMesasage.invalidPhoneNumber");

          return;
        }
      }
      if (payloadItem.type === EInputValidateType.LINK) {
        const isInvalidLink = !regExList[ERegEx.VALID_LINK].test(
          payloadItem.value as string,
        );
        if (isInvalidLink) {
          errors[item] = t("errors.validation.link");
          return;
        }
      }

      if (isArray) {
        const isSomeArrayItemEmpty: boolean = (
          payloadItem.value as string[]
        ).some((arrItem) => !arrItem.trim());

        if (isSomeArrayItemEmpty) {
          errors[item] = t("formFieldsErrors.someElementEmpty");

          return;
        }

        if (isArrayNumber) {
          const isSomeArrayItemLessThanMin: boolean = (
            payloadItem.value as string[]
          ).some(
            (arrItem) =>
              payloadItem.min !== undefined &&
              payloadItem.min > Number(arrItem),
          );

          if (isSomeArrayItemLessThanMin) {
            errors[item] = `${t("formFieldsErrors.someElementLessThanMin")} ${
              payloadItem.min
            }`;

            return;
          }

          const isSomeArrayItemMoreThanMax: boolean = (
            payloadItem.value as string[]
          ).some(
            (arrItem) =>
              payloadItem.max !== undefined &&
              payloadItem.max < Number(arrItem),
          );

          if (isSomeArrayItemMoreThanMax) {
            errors[item] = `${t("formFieldsErrors.someElementMoreThanMax")} ${
              payloadItem.max
            }`;

            return;
          }
        }
      }

      if (isNumber) {
        const isNotNumber: boolean = !isStringNumber(
          payloadItem.value as string,
        );

        if (isNotNumber) {
          errors[item] = t("formFieldsErrors.valueNotNumber");

          return;
        }

        const isLessThanMin: boolean =
          payloadItem.min !== undefined &&
          payloadItem.min > Number(payloadItem.value as string);

        if (isLessThanMin) {
          errors[item] = `${t("formFieldsErrors.valueLessThanMin")} ${
            payloadItem.min
          }`;

          return;
        }

        const isMoreThanMax: boolean =
          payloadItem.max !== undefined &&
          payloadItem.max < Number(payloadItem.value as string);

        if (isMoreThanMax) {
          errors[item] = `${t("formFieldsErrors.valueMoreThanMax")} ${
            payloadItem.max
          }`;

          return;
        }
      }
    });

    return errors;
  };

  return {
    value,
    onChange,
    setValue,
    validateValue,
  };
};

export default useInput;
