import { ERegEx, regExList } from "src/shared/constants/regExList";

import dateFormat from "date-fns/format";

export function getFormattedDatetime(
  date?: number | string,
  format: string = "dd.MM.yyyy HH:mm",
): string {
  if (!date) {
    return "—";
  }

  if (typeof date === "string" && !date.includes("T")) {
    date = date.replace(regExList[ERegEx.ONLY_SPACES], "T");
  }

  try {
    const dateForFormatter = new Date(date);

    return dateFormat(dateForFormatter, format);
  } catch (e) {
    return String(date);
  }
}
