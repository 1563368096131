import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
} from "src/shared/models/dataGrid";

import { getActiveGroup, getOrderByValue, t } from "src/shared/helpers";

import { QueryParams } from "../../../../shared/api/base";
import { MainCompetence } from "../../../../pages/other/fines-page/ui/fines-popover/types/types";

export const generateColumns = (
  queryParams: QueryParams = {},
  isArchive: boolean = false,
): IDataGridColumn[] => {
  const competenceTypeItems = () => {
    return Object.values(MainCompetence).map((type) => ({
      value: type,
      label: t(`common.competence.${type}`, type),
    }));
  };

  return [
    {
      title: "SELECT",
      type: EDataGridColumnType.SELECT_ROW,
      key: "selected",
      showed: !isArchive,
      hiddenTitle: true,
      sticky: {
        mobile: {
          left: 0,
        },
        tablet: {
          left: 0,
        },
        laptop: {
          left: 0,
        },
      },
    },
    {
      key: "fullName",
      showed: true,
      titleUppercase: true,
      title: t("table.header.fineCreator"),
      type: EDataGridColumnType.AVATAR,
      nowrap: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.header.fineCreator"),
        activeGroupKey: getActiveGroup(
          ["firstnameCreator", "lastnameCreator"],
          { all: ["firstnameCreator", "lastnameCreator"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "firstnameCreator",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.name"),
                value: queryParams?.firstnameCreator || "",
              },
              {
                key: "lastnameCreator",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.lastName"),
                value: queryParams?.lastnameCreator || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "firstname_creator",
                "-firstname_creator",
                "lastname_creator",
                "-lastname_creator",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc.name"),
                  value: "firstname_creator",
                },
                {
                  title: t("table.filters.alphabet.desc.name"),
                  value: "-firstname_creator",
                },
                {
                  title: t("table.filters.alphabet.asc.surname"),
                  value: "lastname_creator",
                },
                {
                  title: t("table.filters.alphabet.desc.surname"),
                  value: "-lastname_creator",
                },
              ],
            },
          },
        ],
      },
    },
    {
      key: "competenceName",
      showed: true,
      titleUppercase: true,
      title: t("table.header.competenceName"),
      type: EDataGridColumnType.STRING,
      controlPanel: {
        title: t("table.header.competenceName"),
        activeGroupKey: getActiveGroup(
          ["competenceName"],
          { all: ["competenceName"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "competenceName",
                type: EDataGridFilterType.CHECKBOX,
                items: competenceTypeItems(),
                value: queryParams?.competenceName || "",
              },
            ],
          },
        ],
      },
    },
    {
      key: "creationDate",
      showed: true,
      titleUppercase: true,
      title: t("table.header.creationDate"),
      type: EDataGridColumnType.DATETIME,
      controlPanel: {
        title: t("table.header.creationDate"),
        activeGroupKey: getActiveGroup(
          ["dateOfCreate", "dateOfCreateFrom", "dateOfCreateTo"],
          { all: ["dateOfCreate", "dateOfCreateFrom", "dateOfCreateTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "dateOfCreateFrom",
                type: EDataGridFilterType.DATE,
                placeholder: t("table.filters.from"),
                value: queryParams?.dateOfCreateFrom || "",
                isHalf: true,
              },
              {
                key: "dateOfCreateTo",
                type: EDataGridFilterType.DATE,
                placeholder: t("table.filters.to"),
                value: queryParams?.dateOfCreateTo || "",
                isHalf: true,
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "dateOfCreate",
                "-dateOfCreate",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "date_of_create",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-date_of_create",
                },
              ],
            },
          },
        ],
      },
    },
    {
      key: "finePeriod",
      showed: true,
      titleUppercase: true,
      title: t("table.header.finePeriod"),
      type: EDataGridColumnType.STRING,
      controlPanel: {
        title: t("table.header.finePeriod"),
        activeGroupKey: getActiveGroup(
          ["period", "periodFrom", "periodTo"],
          { all: ["period", "periodFrom", "periodTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "periodFrom",
                type: EDataGridFilterType.NUMBER,
                placeholder: t("table.filters.from"),
                value: queryParams?.periodFrom || "",
                isHalf: true,
              },
              {
                key: "periodTo",
                type: EDataGridFilterType.NUMBER,
                placeholder: t("table.filters.to"),
                value: queryParams?.periodTo || "",
                isHalf: true,
              },
            ],
          },
        ],
      },
    },
    {
      key: "ratingDowngrade",
      showed: true,
      titleUppercase: true,
      title: t("table.header.ratingDowngrade"),
      type: EDataGridColumnType.STRING,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.ratingDowngrade"),
        activeGroupKey: getActiveGroup(
          ["removableRating", "removableRatingFrom", "removableRatingTo"],
          {
            all: [
              "removableRating",
              "removableRatingFrom",
              "removableRatingTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "removableRatingFrom",
                type: EDataGridFilterType.NUMBER,
                placeholder: t("table.filters.from"),
                value: queryParams?.removableRatingFrom || "",
                isHalf: true,
              },
              {
                key: "removableRatingTo",
                type: EDataGridFilterType.NUMBER,
                placeholder: t("table.filters.to"),
                value: queryParams?.removableRatingTo || "",
                isHalf: true,
              },
            ],
          },
        ],
      },
    },
  ];
};
