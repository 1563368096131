import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TabsPanelLink } from "../tabs-panel-link/tabs-panel-link";

interface Props {
  to: string;
}

export const ArchiveLink: FC<Props> = (props: Props): ReactElement => {
  const { to } = props;

  const { t } = useTranslation();

  return (
    <TabsPanelLink to={to} icon="history-blue" isUppercase>
      {t("navigation.usersControl.archive")}
    </TabsPanelLink>
  );
};
