import { playersDomain } from "./players";
import {
  AssessmentMemberResDto,
  AssessmentMembersApiGetGameAssessmentAddMembersRequest,
  AssessmentMembersApiPatchGameAssessmentAddMembersRequest,
} from "src/generated/game";
import { sample } from "effector";
import { spread } from "patronum";
import { queryParamsToGetAssessmentMembersAdapter } from "../lib/queryParamsToGetAssessmentMembersAdapter";
import { Pagination, QueryParams } from "src/shared/api/base";
import {
  getInvitedPlayers,
  patchAssessmentMembers,
} from "src/shared/api/hr/invites-for-parcipants/model/invites-for-participants";

interface FetchInvitedPlayersParams {
  assessmentId: string;
  queryParams: QueryParams;
}

// events

export const fetchInvitedPlayers =
  playersDomain.createEvent<FetchInvitedPlayersParams>();

export const allocateInvitedPlayers =
  playersDomain.createEvent<AssessmentMembersApiPatchGameAssessmentAddMembersRequest>();

// effects

export const fetchInvitedPlayersFx =
  playersDomain.createEffect(getInvitedPlayers);

const patchAssessmentMembersFx = playersDomain.createEffect(
  patchAssessmentMembers,
);

// stores

export const $invitedPlayers = playersDomain.createStore<
  AssessmentMemberResDto[]
>([]);

export const $isLoading = playersDomain.createStore<boolean>(false);

export const $invitedPlayersPagination =
  playersDomain.createStore<Pagination>(null);

// logic

sample({
  clock: allocateInvitedPlayers,
  target: patchAssessmentMembersFx,
});

sample({
  clock: fetchInvitedPlayers,
  fn: (params): AssessmentMembersApiGetGameAssessmentAddMembersRequest => ({
    assessmentId: params.assessmentId,
    ...queryParamsToGetAssessmentMembersAdapter(params.queryParams),
  }),
  target: fetchInvitedPlayersFx,
});

sample({
  clock: fetchInvitedPlayersFx.pending,
  target: $isLoading,
});

sample({
  clock: patchAssessmentMembersFx.doneData,
  source: $invitedPlayers,
  fn: (players, patchedPlayer) => {
    const playersCopy = [...players];
    const patchedPlayerIndex = playersCopy.findIndex(
      (player) => player.playerId === patchedPlayer.playerId,
    );

    playersCopy[patchedPlayerIndex] = patchedPlayer;

    return playersCopy;
  },
  target: $invitedPlayers,
});

spread({
  source: fetchInvitedPlayersFx.doneData.map(({ members, pagination }) => ({
    players: members || [],
    pagination: pagination || null,
  })),
  targets: {
    players: $invitedPlayers,
    pagination: $invitedPlayersPagination,
  },
});
