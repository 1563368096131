import { createDomain, sample } from "effector";

import { spread } from "patronum";

import { Pagination, QueryParams } from "src/shared/api/base";

import { queryParamsToGetPlayersRatingAdapter } from "../lib";

import { getPlayers, PlayerRating } from "src/shared/api/admin/players";
import { patchPlayerById } from "../../../../shared/api/hr/rating-statuses";
import { Player, PlayersApiPatchPlayerByIdRequest } from "src/generated/social";

// Domain

export const playersDomain = createDomain("players");

// Events

export const fetchPlayers = playersDomain.createEvent<QueryParams>();

export const updatePlayerById =
  playersDomain.createEvent<PlayersApiPatchPlayerByIdRequest>();

// Effects

export const fetchPlayersFx = playersDomain.createEffect(getPlayers);

export const updatePlayerByIdFx = playersDomain.createEffect(patchPlayerById);

// Stores

export const $players = playersDomain.createStore<PlayerRating[]>([]);
export const $updatedPlayer = playersDomain.createStore<Player | null>(null);

export const $isLoading = playersDomain.createStore<boolean>(false);

export const $isUpdateLoading = playersDomain.createStore<boolean>(false);

export const $pagination = playersDomain.createStore<Pagination>(null);

export const $queryParams = playersDomain.createStore<QueryParams>({});

sample({
  clock: updatePlayerById,
  target: updatePlayerByIdFx,
});

sample({
  clock: updatePlayerByIdFx.pending,
  target: $isUpdateLoading,
});

sample({
  clock: fetchPlayers,
  fn: queryParamsToGetPlayersRatingAdapter,
  target: fetchPlayersFx,
});

sample({
  clock: fetchPlayers,
  target: $queryParams,
});

sample({
  clock: fetchPlayersFx.pending,
  target: $isLoading,
});

spread({
  source: fetchPlayersFx.doneData.map(({ ratings, pagination }) => ({
    players: ratings || [],
    pagination: pagination || null,
  })),
  targets: {
    players: $players,
    pagination: $pagination,
  },
});

sample({
  clock: updatePlayerByIdFx.doneData,
  target: $updatedPlayer,
});
