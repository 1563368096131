import { FC, ReactElement, useCallback, useEffect } from "react";

import { useUrlState } from "src/shared/hooks/useUrlState";

import { useTranslation } from "react-i18next";

import { BaseButton, CopyButton } from "src/shared/components";

import { declOfNum } from "src/shared/helpers";
import { useUnit } from "effector-react";
import {
  $invitedPlayersPagination,
  fetchInvitedPlayers,
} from "src/entities/hr/assessment-invite/model/invited-players";

interface ISessionShared {
  assessmentId?: string;
  lobbyUrl: string;
  onCopyLink: () => void;
}

export const SessionShared: FC<ISessionShared> = ({
  assessmentId,
  lobbyUrl,
  onCopyLink,
}: ISessionShared): ReactElement => {
  const { t } = useTranslation();
  const [urlState] = useUrlState();

  const sessionId = urlState?.params?.id;

  useEffect(() => {
    fetchInvitedPlayers({
      queryParams: {
        pageSize: "1",
      },
      assessmentId: sessionId,
    });
  }, [sessionId]);

  const invitedPlayersPagination = useUnit($invitedPlayersPagination);

  const playersCount = useCallback((): string => {
    const count = invitedPlayersPagination?.totalItems || 0;

    return `${count} ${declOfNum(count, [
      t("common.member.single"),
      t("common.member.some"),
      t("common.member.group"),
    ])}`;
  }, [t, invitedPlayersPagination?.totalItems]);

  return (
    <div className="session__shared">
      <div className="session__shared-item">
        <div className="session__shared-item-wrap">
          <div className="session__shared-item-title">
            {t("sessions.session.invited")}:
          </div>
          <div className="session__shared-item-description">
            {playersCount()}
          </div>
        </div>
        <BaseButton
          to={`/sessions/session/${sessionId}/invite`}
          lightPurple
          className="session__shared-item-btn session__shared-item-btn--with-padding"
        >
          {t("sessions.session.invite.more")}
        </BaseButton>
      </div>
      <div className="session__shared-item">
        <div className="session__shared-item-wrap session__shared-item-wrap--long">
          <div className="session__shared-item-title">
            {t("sessions.session.shared.link")}
          </div>
          <div className="session__shared-item-description">{lobbyUrl}</div>
        </div>
        {assessmentId && (
          <CopyButton copyValue={lobbyUrl} onCopy={onCopyLink} />
        )}
      </div>
    </div>
  );
};
