import { socialService } from "../../../base/api-instances";
import { FetchAllPlayers } from "./query";
import {
  PlayerComplaint,
  PlayersApiGetPlayerComplaintsRequest,
  PlayersApiPatchPlayerByIdRequest,
} from "src/generated/social";

export const getAllCompanyStatuses = () => {
  return socialService
    .makePublicRequest()
    .getStatusesCompany()
    .then((res) => res.data);
};

export const getAllEmploymentStatuses = () => {
  return socialService
    .makePublicRequest()
    .getStatusesEmployment()
    .then((res) => res.data);
};

export const getAllRejectionReasons = () => {
  return socialService
    .makePublicRequest()
    .getRejectionReasons()
    .then((res) => res.data);
};

export const getAllRelatedProject = () => {
  return socialService
    .makePublicRequest()
    .getRoleInRelatedProjects()
    .then((res) => {
      return res.data;
    });
};

export const patchPlayerById = (params: PlayersApiPatchPlayerByIdRequest) => {
  return socialService
    .makePlayerRequest()
    .patchPlayerById(params)
    .then((res) => res.data);
};

export const getAllPlayers = (params: FetchAllPlayers) => {
  return socialService
    .makePlayerRequest()
    .getPlayer(params)
    .then((res) => res.data);
};
