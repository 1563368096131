import { FC, ReactElement } from "react";

import { t } from "src/shared/helpers";

import {
  BaseCardWrapper,
  Props as WrapperProps,
} from "../base-card-wrapper/base-card-wrapper";

import "./news-card.scss";

type Props = Omit<WrapperProps, "cardTitle"> & {
  title: string;
  description: string | ReactElement;
  link?: string;
};

export const NewsCard: FC<Props> = ({
  title,
  description,
  link,
  whenDate,
  logoUrl,
}) => {
  return (
    <BaseCardWrapper logoUrl={logoUrl} whenDate={whenDate}>
      <div className="news-card">
        <strong className="news-card__title">{title}</strong>
        <p className="news-card__description">{description}</p>
        {link && (
          <a className="news-card__link" href={link} rel="noreferrer noopener">
            {t("notifications.panel.card.go.btn")}
          </a>
        )}
      </div>
    </BaseCardWrapper>
  );
};
