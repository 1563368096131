import { Action } from "redux";
import {
  Manager,
  ManagerApiDelManagerRequest,
  ManagerApiGetManagerRequest,
  ManagerApiGetManagersRequest,
  ManagerApiPostManagerRequest,
  ManagerApiPutManagerRequest,
} from "../../../../generated/social";

export enum EContactsActions {
  CLEAN_CONTACTS = "contacts/CLEAN_CONTACTS",
  REQUEST_CONTACTS_GET = "contacts/REQUEST_CONTACTS_GET",
  SUCCESS_CONTACTS_GET = "contacts/SUCCESS_CONTACTS_GET",
  FAILURE_CONTACTS_GET = "contacts/FAILURE_CONTACTS_GET",
  CLEAN_CONTACT = "contacts/CLEAN_CONTACT",
  REQUEST_CONTACT_GET = "contacts/REQUEST_CONTACT_GET",
  SUCCESS_CONTACT_GET = "contacts/SUCCESS_CONTACT_GET",
  FAILURE_CONTACT_GET = "contacts/FAILURE_CONTACT_GET",
  REQUEST_CONTACT_UPDATE = "contacts/REQUEST_CONTACT_UPDATE",
  SUCCESS_CONTACT_UPDATE = "contacts/SUCCESS_CONTACT_UPDATE",
  FAILURE_CONTACT_UPDATE = "contacts/FAILURE_CONTACT_UPDATE",
  REQUEST_CONTACT_DELETE = "contacts/REQUEST_CONTACT_DELETE",
  SUCCESS_CONTACT_DELETE = "contacts/SUCCESS_CONTACT_DELETE",
  FAILURE_CONTACT_DELETE = "contacts/FAILURE_CONTACT_DELETE",
  REQUEST_CONTACT_CREATE = "contacts/REQUEST_CONTACT_CREATE",
  SUCCESS_CONTACT_CREATE = "contacts/SUCCESS_CONTACT_CREATE",
  FAILURE_CONTACT_CREATE = "contacts/FAILURE_CONTACT_CREATE",
}

export interface ICleanContacts extends Action<EContactsActions> {
  type: EContactsActions.CLEAN_CONTACTS;
}

export interface IRequestContactsGet extends Action<EContactsActions> {
  type: EContactsActions.REQUEST_CONTACTS_GET;
  payload?: {
    options?: ManagerApiGetManagersRequest;
    callback?: () => void;
  };
}

export interface ISuccessContactsGet extends Action<EContactsActions> {
  type: EContactsActions.SUCCESS_CONTACTS_GET;
  payload: Manager[];
}

export interface IFailureContactsGet extends Action<EContactsActions> {
  type: EContactsActions.FAILURE_CONTACTS_GET;
}

export interface ICleanContact extends Action<EContactsActions> {
  type: EContactsActions.CLEAN_CONTACT;
}

export interface IRequestContactGet extends Action<EContactsActions> {
  type: EContactsActions.REQUEST_CONTACT_GET;
  payload: {
    options: ManagerApiGetManagerRequest;
    callback?: () => void;
  };
}

export interface ISuccessContactGet extends Action<EContactsActions> {
  type: EContactsActions.SUCCESS_CONTACT_GET;
  payload: Manager;
}

export interface IFailureContactGet extends Action<EContactsActions> {
  type: EContactsActions.FAILURE_CONTACT_GET;
}

export interface IRequestContactUpdate extends Action<EContactsActions> {
  type: EContactsActions.REQUEST_CONTACT_UPDATE;
  payload: {
    options: ManagerApiPutManagerRequest;
    callback?: () => void;
  };
}

export interface ISuccessContactUpdate extends Action<EContactsActions> {
  type: EContactsActions.SUCCESS_CONTACT_UPDATE;
}

export interface IFailureContactUpdate extends Action<EContactsActions> {
  type: EContactsActions.FAILURE_CONTACT_UPDATE;
}

export interface IRequestContactDelete extends Action<EContactsActions> {
  type: EContactsActions.REQUEST_CONTACT_DELETE;
  payload: {
    options: ManagerApiDelManagerRequest;
    callback?: () => void;
  };
}

export interface ISuccessContactDelete extends Action<EContactsActions> {
  type: EContactsActions.SUCCESS_CONTACT_DELETE;
}

export interface IFailureContactDelete extends Action<EContactsActions> {
  type: EContactsActions.FAILURE_CONTACT_DELETE;
}

export interface IRequestContactCreate extends Action<EContactsActions> {
  type: EContactsActions.REQUEST_CONTACT_CREATE;
  payload: {
    options: ManagerApiPostManagerRequest;
    callback?: () => void;
  };
}

export interface ISuccessContactCreate extends Action<EContactsActions> {
  type: EContactsActions.SUCCESS_CONTACT_CREATE;
}

export interface IFailureContactCreate extends Action<EContactsActions> {
  type: EContactsActions.FAILURE_CONTACT_CREATE;
}
