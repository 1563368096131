import {
  EDataGridFilterType,
  TDataGridDataParams,
} from "../../models/dataGrid";
import { getOrderByValue } from "./getOrderByValue";
import { t } from "../locale";

export const getDateControlPanelGroup = (
  fieldKey: string,
  fieldTitle: string,
  queryParams?: TDataGridDataParams,
) => {
  return {
    key: fieldKey,
    title: fieldTitle,
    filters: [
      {
        key: `${fieldKey}From`,
        type: EDataGridFilterType.DATE,
        placeholder: t("table.filters.from"),
        value: queryParams?.[`${fieldKey}From`] || "",
      },
      {
        key: `${fieldKey}To`,
        type: EDataGridFilterType.DATE,
        placeholder: t("table.filters.to"),
        value: queryParams?.[`${fieldKey}To`] || "",
      },
    ],
    sort: {
      value: getOrderByValue(queryParams?.orderBy, [
        "fieldKey",
        `-${fieldKey}`,
      ]),
      orderKey: "orderBy",
      items: [
        {
          title: t("table.filters.date.asc"),
          value: fieldKey,
        },
        {
          title: t("table.filters.date.desc"),
          value: `-${fieldKey}`,
        },
      ],
    },
  };
};
