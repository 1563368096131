import { combine, guard } from "effector";
import { $country, $district, $region } from "./profile";

import { $districts, $regions, $schools } from "./dictionaries";

import { appAccessDomain } from "./domain";

// Events

export const resetRegions = appAccessDomain.createEvent();

export const resetDistricts = appAccessDomain.createEvent();

export const resetSchools = appAccessDomain.createEvent();

// Logic

$regions.reset(resetRegions);

$districts.reset(resetDistricts);

$schools.reset(resetSchools);

guard({
  clock: combine(
    $country,
    $regions,
    (country, regions) => !country && !!regions,
  ),
  filter: (isClean: boolean): isClean is boolean => !!isClean,
  target: resetDistricts,
});

guard({
  clock: combine(
    $region,
    $districts,
    (region, districts) => !region && !!districts,
  ),
  filter: (isClean: boolean): isClean is boolean => !!isClean,
  target: resetDistricts,
});

guard({
  clock: combine(
    $district,
    $schools,
    (district, schools) => !district && !!schools,
  ),
  filter: (isClean: boolean): isClean is boolean => !!isClean,
  target: resetSchools,
});
