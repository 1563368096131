import { FC, memo, ReactElement, ReactNode } from "react";

import classNames from "classnames";

import { DataGridFieldTooltipItem } from "./data-grid-field-tooltip-item";

import { IDataGridRowTooltipItem } from "src/shared/models/dataGrid";

import { DataGridDropdown } from "../data-grid-dropdown";

interface Props {
  items?: IDataGridRowTooltipItem[];
  content?: string | ReactElement;
  dateFormat?: string;
  children?: ReactNode;
}

export const DataGridFieldTooltip: FC<Props> = memo(
  ({ children, items, dateFormat, content }): ReactElement => {
    return (
      <button
        type="button"
        className={classNames({
          "data-grid__field-with-dropdown": true,
          "data-grid__dropdown-wrapper--by-hover": true,
        })}
      >
        {children}

        <DataGridDropdown withoutOffsetX smallPadding>
          {items && (
            <dl className="data-grid__dropdown-list">
              {items.map((item) =>
                item.value ? (
                  <DataGridFieldTooltipItem
                    item={item}
                    dateFormat={dateFormat}
                    key={item.key}
                  />
                ) : null
              )}
            </dl>
          )}
          {content && (
            <div className="data-grid__dropdown-content">{content}</div>
          )}
        </DataGridDropdown>
      </button>
    );
  }
);
