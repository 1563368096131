import { FC, ReactElement } from "react";

import classNames from "classnames";

import { Competence } from "src/generated/social";

import "./number-item.scss";

interface Props {
  number: number;
  integerRate: number;
  rate: number;
  competence: Competence;
  className?: string;
}

export const NumberItem: FC<Props> = ({
  number,
  integerRate,
  competence,
  className,
}): ReactElement => {
  return (
    <div
      className={classNames(
        "number-item",
        className,
        number <= integerRate ? `number-item--${competence}` : undefined,
      )}
      translate="no"
    >
      <div
        className={classNames("number-item__value", {
          "number-item__value--next-lvl": number > integerRate,
        })}
      >
        {number}
      </div>
    </div>
  );
};
