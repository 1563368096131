import { createEvent, createStore, createEffect, sample } from "effector";
import { ERequestStatus } from "src/shared/store/types";
import {
  AssessmentsListAdminStateInterface,
  IRequestAssessmentsListAdmin,
  ReadmoreType,
} from "./types";
import { AdmAssessmentsResDto } from "src/generated/game";
import { getAdmListAssessments } from "src/shared/api/public/adm-list-assessments";

// Stores

export const $assessmentsListAdmin =
  createStore<AssessmentsListAdminStateInterface>({
    items: [],
    isReadmore: false,
    pagination: null,
    status: ERequestStatus.NEVER,
  });

// Events

export const cleanAssessmentsListAdmin = createEvent();
export const requestAssessmentsListAdmin =
  createEvent<IRequestAssessmentsListAdmin>();
export const setAssessmentsListAdminIsReadmore = createEvent<ReadmoreType>();
export const successAssessmentsListAdmin = createEvent<AdmAssessmentsResDto>();
export const failureAssessmentsListAdmin = createEvent();

// Effects

export const fetchAssessmentsListAdminFx = createEffect(getAdmListAssessments);

// Logic

$assessmentsListAdmin.on(cleanAssessmentsListAdmin, (state) => ({
  ...state,
  items: [],
  pagination: null,
  status: ERequestStatus.NEVER,
}));

$assessmentsListAdmin.on(requestAssessmentsListAdmin, (state) => ({
  ...state,
  status: ERequestStatus.LOADING,
}));

$assessmentsListAdmin.on(successAssessmentsListAdmin, (state, payload) => ({
  ...state,
  items:
    state.isReadmore && payload.assessments
      ? [...state.items, ...payload.assessments]
      : payload.assessments,
  pagination: payload.pagination,
  status: ERequestStatus.LOADED,
  isReadmore: false,
}));

$assessmentsListAdmin.on(
  setAssessmentsListAdminIsReadmore,
  (state, payload) => ({
    ...state,
    isReadmore: payload.value,
  }),
);

$assessmentsListAdmin.on(failureAssessmentsListAdmin, (state) => ({
  ...state,
  status: ERequestStatus.ERROR,
  isReadmore: false,
}));

sample({
  source: requestAssessmentsListAdmin,
  target: fetchAssessmentsListAdminFx,
});

sample({
  source: fetchAssessmentsListAdminFx.doneData,
  target: successAssessmentsListAdmin,
});

sample({
  clock: fetchAssessmentsListAdminFx.fail,
  target: failureAssessmentsListAdmin,
});
