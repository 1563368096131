import { TFunction } from "i18next";
import { ERegEx, regExList } from "../constants/regExList";

const errorsList: { [key: string]: string } = {
  dataSaved: "popup.baseMessage.message.dataSaved",
  invalidEmailMessage: "popup.baseMessage.responseMessage.invalidEmailMessage",
  emailExistsMessage: "popup.baseMessage.responseMessage.emailExistsMessage",
  invalidFormules: "popup.baseMessage.responseMessage.invalidFormules",
  usernameExistsMessage:
    "popup.baseMessage.responseMessage.usernameExistsMessage",
  "User exists with same username":
    "popup.baseMessage.responseMessage.userExistsWithSameUsername",
  "User exists with same username or email":
    "popup.baseMessage.responseMessage.userExistsWithSameUsernameOrEmail",
  "User email missing": "popup.baseMessage.responseMessage.userEmailMissing",
  "User is disabled": "popup.baseMessage.responseMessage.userIsDisabled",
  "Client id missing": "popup.baseMessage.responseMessage.clientIdMissing",
  "Client doesn't exist": "popup.baseMessage.responseMessage.clientDoesntExist",
  "Client is not enabled":
    "popup.baseMessage.responseMessage.clientIsNotEnabled",
  "Invalid redirect uri.":
    "popup.baseMessage.responseMessage.invalidRedirectUri",
  "Failed to send execute actions email":
    "popup.baseMessage.responseMessage.failedToSendExecuteActionsEmail",
  "No tutorial/single for HR":
    "popup.baseMessage.responseMessage.noTutorialSingleForHR",
  "You can't be your own relative":
    "popup.baseMessage.responseMessage.YouCantBeYourOwnRelative",
  "Not found link for this id":
    "popup.baseMessage.responseMessage.NotFoundLinkForThisId",
  "you cannot invite more than 3 users using one link":
    "popup.baseMessage.responseMessage.YouCannotInviteMoreThan3UsersUsingOneLink",
  "Link is not active after 24 hours":
    "popup.baseMessage.responseMessage.LinkIsNotActiveAfter24Hours",
  "Incorrect link": "popup.baseMessage.responseMessage.IncorrectLink",
  "You are already a relative":
    "popup.baseMessage.responseMessage.YouAreAlreadyARelative",
  "User exists with same email":
    "popup.baseMessage.responseMessage.userExistsWithSameEmail",
};

export const responseMessageSetter = (error: string, t: TFunction) => {
  if (error.includes("Try resend after")) {
    const timeLeft: string = error.replace(regExList[ERegEx.NOT_NUMBERS], "");

    return `${timeLeft} ${t("popup.baseMessage.responseMessage.nextRetry")}`;
  }

  return t(
    errorsList[error] || "popup.baseMessage.responseMessage.somethingWentWrong",
  );
};
