import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { QueryParams } from "../../../../shared/api/base";

import {
  Country,
  getCountries,
  Pagination,
} from "../../../../shared/api/public/countries";

import { queryParamsToGetListParamsAdapter } from "../lib/queryParamsToGetParamsAdapter";

export const countriesDomain = createDomain("countriesPublic");

// Effects

export const fetchCountriesFx = countriesDomain.createEffect(getCountries);

// Stores

export const $countries = countriesDomain.createStore<Country[]>([]);

export const $pagination = countriesDomain.createStore<Pagination>(null);

export const $isLoading = countriesDomain.createStore<boolean>(false);

// Events

export const fetchCountries = countriesDomain.createEvent<QueryParams>();

sample({
  clock: fetchCountries,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchCountriesFx,
});

sample({
  clock: fetchCountriesFx.pending,
  target: $isLoading,
});

spread({
  source: fetchCountriesFx.doneData.map(({ countries, pagination }) => ({
    countries,
    pagination: pagination || null,
  })),
  targets: {
    countries: $countries,
    pagination: $pagination,
  },
});
