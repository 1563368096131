import { ClientIdEnum, UserRoleEnum } from "../models/user";
import { useUnit } from "effector-react";
import { $keycloak } from "../../entities/public/keycloak/model";

const useClient = () => {
  const keycloak = useUnit($keycloak);

  const isPlayerClientId: boolean = keycloak?.clientId === ClientIdEnum.PLAYER;
  const isHrClientId: boolean = keycloak?.clientId === ClientIdEnum.HR;
  const isAdminClientId: boolean = keycloak?.clientId === ClientIdEnum.ADMIN;

  const isPlayerRole: boolean = !!keycloak?.realmAccess?.roles.includes(
    UserRoleEnum.PLAYER,
  );

  const isHrStaffRole: boolean = !!keycloak?.realmAccess?.roles.includes(
    UserRoleEnum.HR_STAFF,
  );

  const isHrAlabugaRole: boolean = !!keycloak?.realmAccess?.roles.includes(
    UserRoleEnum.HR_ALABUGA,
  );

  const isHrAdminRole: boolean = !!keycloak?.realmAccess?.roles.includes(
    UserRoleEnum.HR_ADMIN,
  );

  return {
    isPlayerClientId,
    isHrClientId,
    isAdminClientId,
    isPlayerRole,
    isHrStaffRole,
    isHrAlabugaRole,
    isHrAdminRole,
  };
};

export default useClient;
