import { combine, guard, sample } from "effector";

import { socketsModel } from "src/shared/api/public/sockets";

import { appAccessDomain } from "./domain";
import { UserReprAcc } from "src/generated/keycloak";
import {
  getProfileInfo,
  postProfileInfo,
  sendVerifyEmail,
} from "../../../../shared/api/public/profile/model/profile";
import {
  EPopupName,
  openResponseErrorPopup,
} from "../../../../shared/components";
import { openPopup } from "src/shared/components/base-popup/model";
import { snackbarOpen } from "../../snackbar";
import i18n from "src/shared/i18n/i18n";
import { $keycloak } from "../../../../entities/public/keycloak/model";

const cdnUrl = new URL(process.env.REACT_APP_yandexStorageEndpoint as string);

// Stores

export const $profile = appAccessDomain.createStore<UserReprAcc | null>(null);

export const $isProfileLoading = appAccessDomain.createStore<boolean>(false);

export const $emailVerified = appAccessDomain.createStore<boolean | null>(null);

export const $country = appAccessDomain.createStore<string | null>(null);

export const $region = appAccessDomain.createStore<string | null>(null);

export const $district = appAccessDomain.createStore<string | null>(null);

// Events

export const getProfile = appAccessDomain.createEvent<void>();

export const postProfile = appAccessDomain.createEvent<UserReprAcc>();

export const setCountry = appAccessDomain.createEvent<string>();

export const setRegion = appAccessDomain.createEvent<string>();

export const setDistrict = appAccessDomain.createEvent<string>();

// Effects

export const fetchProfileFx = appAccessDomain.createEffect(getProfileInfo);

export const fetchPostProfileFx = appAccessDomain.createEffect(
  (params: UserReprAcc) => {
    if (params.attributes?.avatarUrl[0]) {
      const avatarUrl = new URL(params.attributes.avatarUrl[0]);

      if (avatarUrl.hostname !== cdnUrl.hostname) {
        params.attributes.avatarUrl = [];
      }
    }

    return postProfileInfo(params);
  },
);

// Logic

sample({ clock: setCountry, target: $country });

sample({ clock: setRegion, target: $region });

sample({ clock: setDistrict, target: $district });

sample({
  clock: getProfile,
  target: fetchProfileFx,
});

sample({
  clock: fetchProfileFx.doneData,
  target: $profile,
});

sample({
  clock: fetchPostProfileFx.pending,
  target: $isProfileLoading,
});

sample({
  clock: postProfile,
  target: fetchPostProfileFx,
});

sample({
  clock: fetchPostProfileFx.doneData,
  source: combine($profile, $keycloak),
  fn: ([profile, keycloak], payload) => {
    const profileInfo = { ...profile, ...payload };

    if (!profileInfo.emailVerified && keycloak?.clientId) {
      sendVerifyEmail({
        clientId: keycloak.clientId,
        redirectUri: window.location.href,
      })
        .then(() => {
          openPopup({ name: EPopupName.EMAIL_VERIFY });
        })
        .catch((error) => {
          openResponseErrorPopup(error?.response?.data?.errorMessage);
        });
    }

    if (profileInfo.emailVerified) {
      snackbarOpen({
        visible: true,
        text: i18n.t("common.changesSaved"),
        type: "success",
      });
    }

    return profileInfo;
  },
  target: $profile,
});

sample({
  clock: $profile,
  fn: (profile) => {
    return profile?.emailVerified ?? null;
  },
  target: $emailVerified,
});

guard({
  clock: combine($profile, (profile) =>
    profile ? profile.attributes?.country?.[0] || "" : null,
  ),
  filter: (country: string | null): country is string => country !== null,
  target: setCountry,
});

guard({
  clock: combine($profile, (profile) =>
    profile ? profile.attributes?.region?.[0] || "" : null,
  ),
  filter: (region: string | null): region is string => region !== null,
  target: setRegion,
});

guard({
  clock: combine($profile, (profile) =>
    profile ? profile.attributes?.district?.[0] || "" : null,
  ),
  filter: (district: string | null): district is string => district !== null,
  target: setDistrict,
});

sample({
  clock: $profile,
  target: [socketsModel.v4.initGameV4Lobby, socketsModel.v4.initGameV4Act],
});

fetchPostProfileFx.failData.watch((error: any) => {
  openResponseErrorPopup(error?.response?.data?.errorMessage);
});
