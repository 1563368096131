import { FC, ReactElement } from "react";

import classNames from "classnames";

import "./dislike-item.scss";

interface Props {
  title: string;
  description: string | number;
  className?: string;
  isBoldTitle?: boolean;
}

export const DislikeItem: FC<Props> = ({
  title,
  description,
  className,
  isBoldTitle,
}): ReactElement => {
  return (
    <div className={classNames("dislike-item", className)}>
      <div
        className={classNames("dislike-item__title", {
          "dislike-item__title--bold": isBoldTitle,
        })}
      >
        {title}
      </div>
      <div className="dislike-item__description">{description}</div>
    </div>
  );
};
