import { ChangeEvent, FC, ReactElement } from "react";

import { IBaseInput } from "../../../models/base-input";

import { InputTemplate } from "../input-template/input-template";

interface Props extends IBaseInput {}

export const BaseInputText: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  isSmall,
  forDarkBg,
  onChange,
  maxLength,
  required,
  isContextBtnDisabled,
  readOnly,
  onDelete,
  onRefresh,
  ...otherProps
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;

    onChange({
      value: target.value,
      name: target.name,
    });
  };

  return (
    <InputTemplate
      value={value}
      onChange={onChangeHandler}
      name={name}
      maxLength={maxLength}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      onRefresh={onRefresh}
      onDelete={onDelete}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      {...otherProps}
    />
  );
};
