import { GetListParams } from "../../../../shared/api/public/regions";
import { QueryParams } from "../../../../shared/api/base";

export const queryParamsToGetListParamsAdapter = ({
  locale,
  country: cc,
}: QueryParams = {}): GetListParams => ({
  locale,
  countryCode: cc,
});
