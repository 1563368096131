import { useCallback, useState } from "react";

export function useErrorScroll(parent?: HTMLElement) {
  const [isErrorScroll, setIsErrorScroll] = useState<boolean>(false);

  const scrollToErrorField = useCallback(
    (payload?: { callback?: () => void }) => {
      const errorField: HTMLElement | null = (parent || document).querySelector(
        ".error-field",
      );

      if (!!errorField) {
        errorField.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        setIsErrorScroll(false);

        if (!!payload?.callback) {
          payload.callback();
        }
      }
    },
    [parent],
  );

  return {
    isErrorScroll,
    setIsErrorScroll,
    scrollToErrorField,
  };
}
