import classNames from "classnames";
import { FC, Fragment, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BaseBox, InputCheckbox } from "../../../../../shared/components";
import { getSimulationRoles } from "../../../../../shared/helpers";
import { IGameType } from "../../../../../shared/store/types";
import "./roles-block.scss";
import { Checkbox } from "../../../../../shared/models";

interface Props {
  selectedGameType: IGameType;
  className?: string;
  tenacity?: boolean;
  onChangeTenacity?: (value: boolean) => void;
}

export const RolesBlock: FC<Props> = ({
  selectedGameType,
  className,
  tenacity,
  onChangeTenacity,
}): ReactElement => {
  const { t, i18n } = useTranslation();

  const { roles, teamRoles } = useMemo(() => {
    return getSimulationRoles(selectedGameType, i18n.language);
  }, [selectedGameType, i18n.language]);

  return (
    <Fragment>
      {(!!roles || !!teamRoles) && (
        <BaseBox className={classNames("roles-block", className)}>
          {!!roles && (
            <div className="roles-block__item">
              <h2 className="roles-block__item-title">
                {t("simulationControl.simulationPage.rolesBlock.title.roles")}
              </h2>
              <div className="roles-block__item-description">{roles}</div>
            </div>
          )}
          {!!teamRoles && (
            <div className="roles-block__item">
              <h2 className="roles-block__item-title">
                {t(
                  "simulationControl.simulationPage.rolesBlock.title.teamsRoles",
                )}
              </h2>
              <div className="roles-block__item-description">{teamRoles}</div>
            </div>
          )}
          <div className="roles-block__item-checkboxes">
            {tenacity !== undefined && onChangeTenacity && (
              <InputCheckbox
                value={tenacity}
                onChange={onChangeTenacity}
                label={t("common.competence.communicationTenacity")}
                type={tenacity ? Checkbox.CHOSEN : Checkbox.EMPTY}
                isBoldLabel
              />
            )}
          </div>
        </BaseBox>
      )}
    </Fragment>
  );
};
