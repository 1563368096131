import { FC, ReactElement, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { useUnit } from "effector-react";

import { GameType } from "src/generated/game";

import {
  BaseBox,
  BaseButton,
  ConfirmDeleteSimulationTemplatePopup,
  DataGrid,
  EPopupDataIdName,
  EPopupName,
  popupModel,
  SimulationTemplatePopup,
  TextWithIcon,
} from "src/shared/components";

import { toCloneObject } from "src/shared/helpers";

import { useMediaQuery } from "src/shared/hooks/useMediaQuery";

import {
  EDataGridColumnType,
  EDataGridTextWeight,
  IDataGridColumn,
  IDataGridRow,
} from "src/shared/models";

import {
  ISimulationTemplate,
  SimulationListPlayerMetaInterface,
} from "src/shared/store/types";

import "./templates-block.scss";

interface Props {
  className?: string;
  templates: ISimulationTemplate[];
  selectedGameType: GameType;
  metaData: SimulationListPlayerMetaInterface;
  setTemplates: React.Dispatch<React.SetStateAction<ISimulationTemplate[]>>;
}

const { $activePopups, openPopup, closePopup } = popupModel;

export const TemplatesBlock: FC<Props> = ({
  className,
  templates,
  selectedGameType,
  metaData,
  setTemplates,
}): ReactElement => {
  const { t } = useTranslation();

  const { isLaptop } = useMediaQuery();

  const activePopups = useUnit($activePopups);

  const isSimulationTemplatePopup: boolean = useMemo(
    () =>
      activePopups.some(({ name }) => name === EPopupName.SIMULATION_TEMPLATE),
    [activePopups],
  );

  const columns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: t("simulationControl.simulationPage.infoBlock.title"),
        type: EDataGridColumnType.STRING,
        key: "name",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
      },
      {
        title: t("simulationControl.simulationPage.infoBlock.id"),
        type: EDataGridColumnType.STRING,
        key: "id",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
      },
      {
        title: t(
          "simulationControl.simulationPage.templatesBlock.table.playersRating",
        ),
        type: EDataGridColumnType.STRING,
        key: "rating",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
      },
      {
        title: "",
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "trashButton",
        showed: true,
        width: 16,
        noPaddingRight: isLaptop,
        buttonProps: {
          hideLabel: true,
          icon: "trash-red",
          iconSize: 16,
          onClick: (value: string) => {
            openPopup({
              name: EPopupName.CONFIRM_DELETE_SIMULATION_TEMPLATE,
              dataId: [{ name: EPopupDataIdName.DEFAULT, value }],
            });
          },
        },
      },
      {
        title: "",
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "editBtn",
        showed: true,
        width: 16,
        buttonProps: {
          className: "templates-block__grid-edit-btn",
          hideLabel: true,
          icon: "pen-blue",
          iconSize: 16,
          onClick: (value: string) => {
            openPopup({
              name: EPopupName.SIMULATION_TEMPLATE,
              dataId: [{ name: EPopupDataIdName.DEFAULT, value }],
            });
          },
        },
      },
    ],
    [isLaptop, t],
  );

  const rows = useMemo<IDataGridRow[]>(() => {
    const filteredTemplates: ISimulationTemplate[] = templates.filter(
      (item) => item.gameType === selectedGameType,
    );

    return filteredTemplates.map((item) => {
      const isStandardTemplate: boolean = !!item.isDefault;
      const name: string = isStandardTemplate
        ? t(
            "simulationControl.simulationPage.templatesBlock.table.standardTemplate",
          )
        : item.title?.ru ||
          t(
            "simulationControl.simulationPage.templatesBlock.table.templateName",
          );

      return {
        id: item.id || String(Math.random()),
        selected: false,
        name,
        rating:
          item.minRate && item.maxRate
            ? `${item.minRate} - ${item.maxRate}`
            : "",
        buttonProps: {
          trashButton: {
            hideBtn: isStandardTemplate,
          },
        },
      };
    });
  }, [selectedGameType, templates, t]);

  const onSubmit = (template: ISimulationTemplate) => {
    setTemplates((prev) => {
      const localPrev = toCloneObject(prev);
      const editableTemplateIndex: number = localPrev.findIndex(
        (item) => item.id === template.id,
      );

      if (editableTemplateIndex >= 0) {
        localPrev[editableTemplateIndex] = template;
      } else {
        localPrev.push(template);
      }

      return localPrev;
    });
  };

  const onDeleteTemplate = (id: string) => {
    setTemplates((prev) => {
      return prev.filter((item) => item.id !== id);
    });

    closePopup({ name: EPopupName.CONFIRM_DELETE_SIMULATION_TEMPLATE });
  };

  return (
    <BaseBox noPadding className={classNames("templates-block", className)}>
      <div className="templates-block__header">
        <h4 className="templates-block__header-title">
          {t("simulationControl.simulationPage.templatesBlock.title")}
        </h4>
        <BaseButton
          className="templates-block__header-btn"
          onClick={() => {
            openPopup({
              name: EPopupName.SIMULATION_TEMPLATE,
            });
          }}
        >
          <TextWithIcon
            iconName="plus-blue"
            label={t(
              "simulationControl.simulationPage.templatesBlock.btn.addTemplate",
            )}
            iconSize={12}
            isTextNowrap
          />
        </BaseButton>
      </div>
      {!!rows.length && (
        <DataGrid
          className="templates-block__grid"
          autoScrollDisabled
          columns={columns}
          rows={rows}
        />
      )}
      {isSimulationTemplatePopup && (
        <SimulationTemplatePopup
          meta={metaData}
          templates={templates}
          selectedGameType={selectedGameType}
          onSubmit={onSubmit}
        />
      )}
      <ConfirmDeleteSimulationTemplatePopup onConfirm={onDeleteTemplate} />
    </BaseBox>
  );
};
