import {
  ITeamTooltipItem,
  ITeamTooltipSection,
} from "../../../../../../shared/models";
import classNames from "classnames";
import { FC } from "react";
import { RoomStateInterface } from "../../../../../../shared/store/ducks/room";
import { useTranslation } from "react-i18next";

interface TeamTooltipProps {
  room: RoomStateInterface;
  isSoloGameType: boolean;
}

export const TeamTooltip: FC<TeamTooltipProps> = ({ room, isSoloGameType }) => {
  const { t } = useTranslation();

  const teamTooltipData = (): ITeamTooltipSection[] => {
    let teams: ITeamTooltipItem[] = [];

    let playerRoles: ITeamTooltipItem[] = [];

    let count = 0;

    const maxTeamsList = isSoloGameType
      ? room.maxPlayersByRole
      : room.maxTeamsByName;

    if (maxTeamsList) {
      teams = Object.entries(maxTeamsList).map((item) => {
        count = room.players.filter(
          (player) => player.role === item[0] || player.teamName === item[0],
        ).length;

        if (isSoloGameType) {
          return {
            count: count,
            role: item[0],
            total: item[1],
          };
        } else {
          playerRoles = Object.entries(item[1].maxPlayersByRole).map((role) => {
            count = room.players.filter(
              (player) => player.role === role[0],
            ).length;

            const maxTeams =
              (room.maxTeamsByName?.nursery.maxTeams ?? 0) +
              (room.maxTeamsByName?.shop.maxTeams ?? 0);

            const commonRoleMaxPlayers = maxTeams * (room.maxAreas ?? 0);

            return {
              count: count,
              role: role[0],
              total: role[0] === "ceo" ? maxTeams : commonRoleMaxPlayers,
            };
          });

          return {
            count: count,
            role: item[0],
            total: item[1].maxTeams,
          };
        }
      });
    }

    const list = [
      {
        title: t("sessions.session.teams.readiness"),
        list: teams,
      },
    ];

    if (!isSoloGameType) {
      playerRoles.forEach((role) => {
        list[0].list.push(role);
      });
    }
    return list;
  };

  return (
    <div className="session__board">
      {teamTooltipData().map((team: ITeamTooltipSection, index: number) => (
        <div className="session__board-section" key={index}>
          {team.title && (
            <div className="session__board-title">{team.title}</div>
          )}
          <div
            className={classNames("session__board-grid", {
              "session__board-grid--solo": isSoloGameType,
            })}
          >
            {team.list.map((item: ITeamTooltipItem) => (
              <div className="session__board-block" key={item.role}>
                <div className="session__board-label">
                  {t(`playerRoles.${item.role}`)}
                </div>
                <div className="session__board-count">
                  {item.count}/{item.total}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
