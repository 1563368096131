import React, { FC } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./resume-upload.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface ResumeEmbedProps {
  src: string;
}

export const Resume: FC<ResumeEmbedProps> = ({ src }) => {
  return (
    <Document file={src}>
      <Page
        renderAnnotationLayer={false}
        renderTextLayer={false}
        pageNumber={1}
      />
    </Document>
  );
};
