import { UsersApiUsersRequest } from "../../../../generated/keycloak";
import { ERequestStatus } from "../../types";

export interface IUsersControlState {
  statusDelete: ERequestStatus;
  statusDisable: ERequestStatus;
  statusEnable: ERequestStatus;
}

export enum EUsersControlRequestMethods {
  DELETE = "delete",
  DISABLE = "disable",
  ENABLE = "enable",
}

export interface IUsersControlPayloadItems {
  // method: EUsersControlRequestMethods;
  usersId: string[];
  queryForUpdateList: UsersApiUsersRequest;
}
