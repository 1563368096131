import {
  ChangeEvent,
  FC,
  FormEvent,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import {
  BaseButton,
  BaseGroup,
  BaseTextarea,
  InputCheckbox,
} from "src/shared/components";

import { Checkbox } from "src/shared/models";

import {
  BasePopup,
  EPopupName,
  popupModel,
} from "src/shared/components/base-popup";

import "./send-notification-popup.scss";

interface Props {
  usersIds: string[];
}

type TNotification = "notification" | "e-mail" | "sms";

type TNotificationType = Partial<Record<TNotification, boolean>>;

const notificationTypeKeys: TNotification[] = ["notification", "e-mail", "sms"];

const { closePopup } = popupModel;

export const SendNotificationPopup: FC<Props> = ({
  usersIds,
}): ReactElement => {
  const { t } = useTranslation();

  const defaultNotificationType: TNotificationType = useMemo(() => {
    const localNotificationType: TNotificationType = {};

    notificationTypeKeys.forEach((key) => {
      localNotificationType[key] = false;
    });

    return localNotificationType;
  }, []);

  const [notificationType, setNotificationType] = useState<TNotificationType>(
    defaultNotificationType,
  );

  const [notificationMessage, setNotificationMessage] = useState<string>("");

  const hasSomeNotificationType: boolean = useMemo(
    () => Object.values(notificationType).some((item) => item),
    [notificationType],
  );

  const isDisabledSendBtn: boolean =
    !notificationMessage.trim() || !hasSomeNotificationType;

  const onChangeNotificationTypeHandler = (
    value: boolean,
    type: TNotification,
  ): void => {
    setNotificationType((prev) => {
      const localPrev = { ...prev };

      localPrev[type] = value;

      return localPrev;
    });
  };

  const onChangeNotificationMessageHandler = (e: ChangeEvent) => {
    const { value } = e.target as HTMLTextAreaElement;

    setNotificationMessage(value);
  };

  const onSubmit = useCallback(() => {
    /* 
        Тут должен быть API запрос, которого пока нет
    */
    console.log("to users", usersIds);
    console.log("types", notificationType);
    console.log("value", notificationMessage);
  }, [usersIds, notificationType, notificationMessage]);

  const onKeyDownHander = (e: KeyboardEvent) => {
    const isEnterKey: boolean = e.key === "Enter";

    const isSubmitEvent: boolean =
      isEnterKey && (e.ctrlKey || e.shiftKey || e.metaKey);

    if (isSubmitEvent && !isDisabledSendBtn) {
      e.preventDefault();

      onSubmit();
    }
  };

  const onSubmitHandler = (e: FormEvent): void => {
    e.preventDefault();

    onSubmit();
  };

  const onCancelHandler = (): void => {
    setNotificationMessage("");

    setNotificationType(defaultNotificationType);

    closePopup({ name: EPopupName.SEND_NOTIFICATION });
  };

  return (
    <BasePopup name={EPopupName.SEND_NOTIFICATION} isWithoutPadding>
      <form onSubmit={onSubmitHandler} className="send-notification-popup">
        <h3 className="send-notification-popup__title">
          {t("popup.tournaments.sendNotification.title")}
        </h3>
        {notificationType && (
          <div className="send-notification-popup-type">
            <h4 className="send-notification-popup-type__title">
              {t("notifications.newsLetter.form.type")}
            </h4>
            <div className="send-notification-popup-type__items">
              {notificationTypeKeys.map((type) => (
                <InputCheckbox
                  key={type}
                  className="send-notification-popup-type__items-checkbox"
                  value={notificationType[type]}
                  onChange={(value) =>
                    onChangeNotificationTypeHandler(value, type)
                  }
                  label={t(
                    `notifications.newsLetter.form.notificationType.${type}`,
                    type,
                  )}
                  type={
                    notificationType[type] ? Checkbox.CHOSEN : Checkbox.EMPTY
                  }
                  isBoldLabel
                />
              ))}
            </div>
          </div>
        )}
        <BaseTextarea
          className="send-notification-popup__textarea"
          value={notificationMessage}
          onChange={onChangeNotificationMessageHandler}
          label={t("popup.tournaments.sendNotification.teaxarea.placeholder")}
          onKeyDown={onKeyDownHander}
          size="small"
          required
        />
        <BaseGroup
          left={
            <BaseButton onClick={onCancelHandler} small primary outline block>
              {t("common.cancel")}
            </BaseButton>
          }
          right={
            <BaseButton disabled={isDisabledSendBtn} submit small primary block>
              {t("common.send")}
            </BaseButton>
          }
        />
      </form>
    </BasePopup>
  );
};
