import axios, { AxiosInstance } from "axios";

let $axiosGame: AxiosInstance;
let $axiosSocial: AxiosInstance;
let $axiosStatistic: AxiosInstance;
let $axiosKeycloak: AxiosInstance;
let $axiosCdn: AxiosInstance;
let $axiosCdnDownload: AxiosInstance;

export function initializeAxios() {
  $axiosGame = axios.create();

  $axiosGame.interceptors.request.use(
    async (config) => {
      config.baseURL = process.env.REACT_APP_GAME_URL;

      config.headers = {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  $axiosSocial = axios.create();

  $axiosSocial.interceptors.request.use(
    async (config) => {
      config.baseURL = process.env.REACT_APP_SOCIAL_URL;

      config.headers = {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  $axiosStatistic = axios.create();

  $axiosStatistic.interceptors.request.use(
    async (config) => {
      config.baseURL = process.env.REACT_APP_STATISTIC_URL;

      config.headers = {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  $axiosKeycloak = axios.create();

  $axiosKeycloak.interceptors.request.use(
    async (config) => {
      config.baseURL = process.env.REACT_APP_KEYCLOAK_URL;

      config.headers = {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  $axiosCdn = axios.create();

  $axiosCdn.interceptors.request.use(
    async (config) => {
      config.baseURL = process.env.REACT_APP_CDN_URL;

      config.headers = {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  $axiosCdnDownload = axios.create();

  $axiosCdnDownload.interceptors.request.use(
    async (config) => {
      config.baseURL = process.env.REACT_APP_CDN_URL;
      config.responseType = "blob";
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );
}

export {
  $axiosGame,
  $axiosSocial,
  $axiosStatistic,
  $axiosKeycloak,
  $axiosCdn,
  $axiosCdnDownload,
};
