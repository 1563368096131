import {
  EUsersControlActions,
  IFailureUsersControlDelete,
  IFailureUsersControlDisable,
  IFailureUsersControlEnable,
  IRequestUsersControlDelete,
  IRequestUsersControlDisable,
  IRequestUsersControlEnable,
  ISuccessUsersControlDelete,
  ISuccessUsersControlDisable,
  ISuccessUsersControlEnable,
} from "./actionTypes";
import { IUsersControlPayloadItems } from "./type";

export const requestUsersControlDelete = (
  payload: IUsersControlPayloadItems
): IRequestUsersControlDelete => ({
  type: EUsersControlActions.REQUEST_DELETE_USERS,
  payload,
});

export const successUsersControlDelete = (): ISuccessUsersControlDelete => ({
  type: EUsersControlActions.SUCCESS_DELETE_USERS,
});

export const failureUsersControlDelete = (): IFailureUsersControlDelete => ({
  type: EUsersControlActions.FAILURE_DELETE_USERS,
});

export const requestUsersControlDisable = (
  payload: IUsersControlPayloadItems
): IRequestUsersControlDisable => ({
  type: EUsersControlActions.REQUEST_DISABLE_USERS,
  payload,
});

export const successUsersControlDisable = (): ISuccessUsersControlDisable => ({
  type: EUsersControlActions.SUCCESS_DISABLE_USERS,
});

export const failureUsersControlDisable = (): IFailureUsersControlDisable => ({
  type: EUsersControlActions.FAILURE_DISABLE_USERS,
});

export const requestUsersControlEnable = (
  payload: IUsersControlPayloadItems
): IRequestUsersControlEnable => ({
  type: EUsersControlActions.REQUEST_ENABLE_USERS,
  payload,
});

export const successUsersControlEnable = (): ISuccessUsersControlEnable => ({
  type: EUsersControlActions.SUCCESS_ENABLE_USERS,
});

export const failureUsersControlEnable = (): IFailureUsersControlEnable => ({
  type: EUsersControlActions.FAILURE_ENABLE_USERS,
});

export type TUsersControlActions =
  | IFailureUsersControlDelete
  | IRequestUsersControlDelete
  | ISuccessUsersControlDelete
  | IFailureUsersControlDisable
  | IRequestUsersControlDisable
  | ISuccessUsersControlDisable
  | IFailureUsersControlEnable
  | IRequestUsersControlEnable
  | ISuccessUsersControlEnable;
