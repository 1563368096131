import { IRequestLvlUp } from "src/entities/public/lvl-up";
import { gameService } from "src/shared/api/base/api-instances";

export const postLevelUp = (params: IRequestLvlUp) => {
  const { locale, competence } = params;

  return gameService
    .makePlayerGamesApiFactory()
    .postLevelUp(locale, competence)
    .then((res) => res.data);
};
