import { RoleInRelatedProjectEnum } from "../../generated/social";

export function stringToEnum<T extends string>(
  searchEnum: Record<string, T>,
  value: string,
): T | undefined {
  const find = Object.keys(searchEnum).find((key) => searchEnum[key] === value);
  if (!find) {
    return undefined;
  }
  return searchEnum[find];
}

export function stringsToEnumArray<T>(
  enumObj: Record<string, T>,
  values: string[],
): T[] {
  const objectValues = Object.values(enumObj);
  const notSelected = RoleInRelatedProjectEnum.NoSelected;
  let result: T[] = [];

  // Если выбрать "Не выбран" и есть параллельно выбранные опции - сбросить их
  // Если выбрана опция "Не выбран" и пользователь меняет на другую опцию - сбросить опцию "Не выбран"
  if (values?.includes(notSelected) && values?.at(-1) === notSelected) {
    result = [notSelected] as T[];
  } else {
    objectValues?.forEach((element) => {
      values.forEach((valueEl) => {
        if (element === valueEl && valueEl !== notSelected) {
          result.push(element);
        }
      });
    });
  }
  if (result.length === 0) {
    result = [notSelected] as T[];
  }

  return result as T[];
}
