import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";

const { openPopup } = popupModel;

export const openAssessmentParametersPopup = (value: string) => {
  openPopup({
    name: EPopupName.ASSESSMENT_PARAMETERS,
    dataId: [{ name: EPopupDataIdName.DEFAULT, value }],
  });
};

export const openAssessmentMembersPopup = (value: string) => {
  openPopup({
    name: EPopupName.ASSESSMENT_MEMBERS,
    dataId: [{ name: EPopupDataIdName.DEFAULT, value }],
  });
};

export const openAddAssessmentPopup = () => {
  openPopup({ name: EPopupName.CREATE_UNRATED_SESSION });
};

export const openAddTournamentPopup = () => {
  openPopup({ name: EPopupName.CREATE_TOURNAMENT });
};
