import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../helpers/pxToRem";

import "./icon-cross.scss";

export enum IconCrossColor {
  "GRAY" = "gray",
  "BLUE" = "blue",
  "RED" = "red",
  "DARK-GREY" = "dark-grey",
}

interface IconCrossProps {
  size?: number;
  color?: IconCrossColor;
  onClick?: (event: React.MouseEvent) => void;
}

export const IconCross: FC<IconCrossProps> = ({
  size = 12,
  color = "currentColor",
  onClick,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <button
      aria-label={t("common.close")}
      className={`icon-cross icon-cross--${color}`}
      onClick={onClick}
      style={{
        width: size ? pxToRem(size) : undefined,
        height: size ? pxToRem(size) : undefined,
      }}
    />
  );
};
