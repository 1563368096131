import { FormDataInterface } from "../components/user-form/user-form";

export const getRequestKeycloakUserData = (
  formData: FormDataInterface,
  withoutAvatar?: boolean,
) => {
  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    attributes: {
      phoneNumber: [formData.phoneNumber],
      gender: [formData.gender],
      country: [formData.country],
      region: [formData.region],
      district: [formData.district],
      locality: [formData.locality],
      school: [formData.school],
      groupCode: [formData.groupCode],
      birthdate: [formData.birthdate],
      studyWorkPlace: [formData.studyWorkPlace],
      desiredStudyPlace: [formData.desiredStudyPlace],
      desiredSpecialty:
        formData.desiredStudyPlace === "notAnEntrant"
          ? []
          : formData.desiredSpecialty,
      supervisedUnit: [formData.supervisedUnit],
      workPhoneNumber: [formData.workPhoneNumber],
      login: [formData.login],
      avatarUrl: withoutAvatar ? [] : [formData.avatarUrl],
      resumeFileName: [formData.resumeFileName],
      middleName: [formData.middleName],
    },
  };
};
