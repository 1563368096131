import { ERequestStatus } from "../../types";
import { TUsersControlActions } from "./actionCreators";
import { EUsersControlActions } from "./actionTypes";
import { IUsersControlState } from "./type";

const initialState: IUsersControlState = {
  statusDelete: ERequestStatus.NEVER,
  statusDisable: ERequestStatus.NEVER,
  statusEnable: ERequestStatus.NEVER,
};

export const usersControlReducer = (
  state = initialState,
  action: TUsersControlActions,
) => {
  switch (action.type) {
    case EUsersControlActions.REQUEST_DELETE_USERS:
      return {
        ...state,
        statusDelete: ERequestStatus.LOADING,
      };
    case EUsersControlActions.SUCCESS_DELETE_USERS:
      return {
        ...state,
        statusDelete: ERequestStatus.LOADED,
      };
    case EUsersControlActions.FAILURE_DELETE_USERS:
      return {
        ...state,
        statusDelete: ERequestStatus.ERROR,
      };
    case EUsersControlActions.REQUEST_DISABLE_USERS:
      return {
        ...state,
        statusDisable: ERequestStatus.LOADING,
      };
    case EUsersControlActions.SUCCESS_DISABLE_USERS:
      return {
        ...state,
        statusDisable: ERequestStatus.LOADED,
      };
    case EUsersControlActions.FAILURE_DISABLE_USERS:
      return {
        ...state,
        statusDisable: ERequestStatus.ERROR,
      };
    case EUsersControlActions.REQUEST_ENABLE_USERS:
      return {
        ...state,
        statusEnable: ERequestStatus.LOADING,
      };
    case EUsersControlActions.SUCCESS_ENABLE_USERS:
      return {
        ...state,
        statusEnable: ERequestStatus.LOADED,
      };
    case EUsersControlActions.FAILURE_ENABLE_USERS:
      return {
        ...state,
        statusEnable: ERequestStatus.ERROR,
      };
    default:
      return state;
  }
};
