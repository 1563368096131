import React, {
  FC,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import isEqual from "lodash/isEqual";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useUrlState } from "src/shared/hooks/useUrlState";

import {
  ArchiveLink,
  ConfirmPopup,
  PageWrapper,
  TextWithIcon,
} from "src/shared/components";

import { UsersControlPanelBtn } from "../components";
import { ERequestStatus } from "src/shared/store/types";

import {
  $usersListAdmin,
  cleanUsersListAdmin,
  requestUsersListAdmin,
  setUsersListAdminIsReadmore,
} from "src/entities/public/users-list-admin";

import { requestUsersControlDisable } from "src/shared/store/ducks/users-control/actionCreators";
import { selectUsersControlDisableStatus } from "src/shared/store/ducks/users-control/selectors";

import { TDataGridDataParams } from "src/shared/models/dataGrid";

import { ParticipantsTable } from "src/shared/components/data-grids/user-managments/participants";
import { UserRoleEnum } from "src/shared/models/user";
import { UsersApiUsersRequest } from "src/generated/keycloak";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import { useUnit } from "effector-react";
import { ActionUserEnum } from "../../../generated/social";
import { postChangeUsersHistory } from "../../../entities/admin/histories/model/users/users-change-history";
import "./players-page.scss";
import { ERegEx, regExList } from "../../../shared/constants/regExList";

interface Props {}

const { openPopup } = popupModel;

export const PlayersPage: FC<Props> = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [checkList, setCheckList] = useState<string[]>([]);

  const {
    items: usersListAdminItems,
    pagination,
    status: usersListAdminStatus,
  } = useUnit($usersListAdmin);

  const [count, setCount] = React.useState<number>(0);

  const usersControlDisableStatus = useSelector(
    selectUsersControlDisableStatus,
  );

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      setCount(0);
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );
  const clearFilterSettings = () => {
    setCount(0);
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      locality,
      desiredStudyPlace: dsp,
      resume,

      orderBy,
      pageNum,
      pageSize,
    } = urlState.query;

    const params: UsersApiUsersRequest = {
      role: [UserRoleEnum.PLAYER],
      enabled: true,

      // isBlocked: false,
      // // @ts-ignore
      // roleInRelatedProjects: roleInRelatedProjects
      //   ? [roleInRelatedProjects]
      //   : undefined,
      firstName: firstName ? [firstName] : undefined,
      lastName: lastName ? [lastName] : undefined,
      // phone: phoneNumber ? [phoneNumber] : undefined,
      phoneNumber: phoneNumber
        ? [phoneNumber.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
        : undefined,
      email: email ? [email] : undefined,
      locality: locality ? [locality] : undefined,
      desiredStudyPlace: dsp ? dsp.split(",") : undefined,
      // resume: resume ? resume : undefined,
      resume: resume ? resume === "true" : undefined,
      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [urlState.query]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   fetchAllPlayers(pageParams);
  // }, [pageParams]);
  useEffect(() => {
    if (count === 1) return;
    setCount((prev) => prev + 1);
    requestUsersListAdmin(pageParams);
  }, [pageParams]);

  // useEffect(() => {
  //   if (usersListItems.players) {
  //     setUsersListState(usersListItems);
  //     setIsLoading(false);
  //   }
  // }, [usersListItems.players]);

  useEffect(() => {
    // setIsLoading(true);
    return () => {
      cleanUsersListAdmin();
      // resetPlayersStore();
      // setIsLoading(true);
      // setUsersListState({});
    };
  }, []);

  const disableUsers = useCallback(() => {
    // if (!usersListState.players) return;
    // const list = checkList;
    // dispatch(
    //   requestUsersControlDisable({
    //     usersId: list,
    //     queryForUpdateList: pageParams as any,
    //   })
    postChangeUsersHistory({
      body: { users_id: checkList, action: ActionUserEnum.Blocked },
    });
    dispatch(
      requestUsersControlDisable({
        usersId: checkList,
        queryForUpdateList: pageParams,
      }),
    );
    setCheckList([]);

    // setUsersListState((prev) => {
    //   return {
    //     ...prev,
    //     players: [
    //       ...(prev.players as Player[]).filter((el) => {
    //         return list.some((id) => el.id !== id);
    //       }),
    //     ],
    //   };
    // });
  }, [pageParams, checkList, dispatch, setCheckList]);

  const setSelectedRow = useCallback(
    (idList: string[]) => {
      setCheckList(idList);
    },
    [setCheckList],
  );

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setCount(0);
    setUsersListAdminIsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      // query: { ...prevState.query, query: { ...prevState.query, pageNum: String(pageNum) }, pageSize: "50" },
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const isLoadingUsers: boolean =
    usersListAdminStatus === ERequestStatus.LOADING;

  // const readMore = () => {
  //   setUrlState((prevState) => ({
  //     ...prevState,
  //     query: {
  //       ...prevState.query,
  //       pageSize: String(
  //         prevState.query.pageSize
  //           ? Number(prevState.query.pageSize) + 10
  //           : 50 + 10
  //       ),
  //     },
  //   }));
  // };

  const isLoadingDisable: boolean =
    usersControlDisableStatus === ERequestStatus.LOADING;

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  // const onCheckHandler = (id: string, key: string, event: boolean) => {
  //   if (!usersListItems.players) return;
  //   const list = {
  //     ...usersListItems,
  //     players: [
  //       ...(usersListState.players as Player[]).map((el) => {
  //         if (id === el.id) {
  //           return {
  //             ...el,
  //             confirmationSingle: event,
  //           };
  //         }
  //         return el;
  //       }),
  //     ],
  //   };
  //   patchPlayerById({ pId: id, body: { id: id, confirmationSingle: event } });
  //
  //   setUsersListState(list);
  // };

  return (
    <PageWrapper
      title={t("usersControl.title")}
      tabsPanelSlot={<ArchiveLink to={"/users-control/archive"} />}
      isLoadingPanel={isLoadingUsers}
      // isLoadingPanel={isLoading}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("usersControl.controlPanel.label.users")}
      // controlPanelMaxCount={usersListItems?.pagination?.totalItems}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={checkList.length}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <Fragment>
          <div className="table__control">
            {filtersLength > 0 && (
              <div className="table__control-btn" onClick={clearFilterSettings}>
                <TextWithIcon
                  label={t("table.filters.reset")}
                  iconName={"cross-blue"}
                />
              </div>
            )}

            <UsersControlPanelBtn
              addUserType="player"
              isPlayerHrAdminPage
              isLockDisabled={checkList.length === 0}
              onLock={() => openPopup({ name: EPopupName.LOCK_USERS })}
            />
          </div>
        </Fragment>
      }
    >
      <div className="players-page-data-grid-wrapper">
        <ParticipantsTable
          // users={usersListState.players}
          users={usersListAdminItems}
          queryParams={urlState.query}
          onUpdateSortAndFilters={onUpdateQueryParams}
          onSelectRows={setSelectedRow}
          selectedRowsIds={checkList}
          loading={isLoadingUsers}
          pagination={pagination}
          // loading={isLoading}
          // changeCheckbox={onCheckHandler}
          // pagination={usersListState.pagination}
          goToPage={(pageNum) => goToPage(pageNum)}
          // readmore={readMore}
          readmore={(pageNum) => goToPage(pageNum, true)}
        />
      </div>

      <ConfirmPopup
        popupName={EPopupName.LOCK_USERS}
        title={t("usersControl.popup.blockUsers.title")}
        description={t("usersControl.popup.blockUsers.description")}
        confirmBtnLabel={t("usersControl.controlPanel.btn.block")}
        onConfirm={disableUsers}
        isConfirmBtnDisabled={isLoadingDisable}
      />
    </PageWrapper>
  );
};
