import {
  EPopupName,
  IPopupCloseAllPayload,
  IPopupClosePayload,
  IPopupOpenPayload,
} from "../model/types";

export const closePopupHandler = (
  showedPopup: EPopupName[],
  { name, callback }: IPopupClosePayload,
) => {
  showedPopup.length === 1 && document.body.classList.remove("overflow-hidden");

  callback && callback();

  return showedPopup.filter((popup) => popup !== name);
};

export const closeAllPopupHandler = (
  payload: IPopupCloseAllPayload | undefined,
) => {
  document.body.classList.remove("overflow-hidden");

  payload?.callback && payload.callback();

  return [];
};

export const hidePopupHandler = (
  activePopup: IPopupOpenPayload[],
  { name }: IPopupClosePayload,
) => activePopup.filter((popup) => popup.name !== name);

export const hideAllPopupHandler = () => [];
