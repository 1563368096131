import { IBaseButtonProps } from "../components/base-button/base-button";
import {
  BaseInputNumberProps,
  IBaseSelectProps,
  SelectItem,
} from "src/shared/components";
import { Pagination as GamePagination } from "../../generated/game";
import { Pagination as SocialPagination } from "../../generated/social";
import { Pagination as StatisticPagination } from "../../generated/statistic";
import { IBaseAvatarProps } from "../components/base-avatar/base-avatar";
import { TTextWithIconName } from "../components/text-with-icon/text-with-icon";
import { Checkbox } from "./checkbox";
import { ReactElement, ReactNode } from "react";
import { IUserSettingsTable } from "src/entities/public/user-settings/model/types";
import { IBaseInputChange } from "./base-input";

export type TDataGridPagination =
  | GamePagination
  | SocialPagination
  | StatisticPagination;

export interface IDefaultDataGridProps {
  columns: IDataGridColumn[];
  setColumns?: (columns: IDataGridColumn[]) => void; // TODO Deprecated
  rows: IDataGridRow[];
  setRows?: (rows: IDataGridRow[]) => void;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  onUpdateRows?: (rows: IDataGridRow[]) => void;
  onUpdateSelectedRows?: (ids: string[]) => void;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
  className?: string;
  nowrap?: boolean;
  titleUppercase?: boolean;
  contentFontWeight?: EDataGridTextWeight;
  loading?: boolean;
  pagination?: TDataGridPagination | null;
  pageUrl?: string;
  autoScrollDisabled?: boolean;
  hiddenHeader?: boolean;
  changeCheckbox?: (id: string, key: string, value: boolean) => void;
  hideCheckbox?: boolean;
  selectedRowsIds?: string[];
  tableSettings?: IUserSettingsTable;
  isRowCanDrag?: boolean;
}

export interface IDataGridProps extends IDefaultDataGridProps {
  onDrop?: never;
  canDrop?: never;
}

export interface IDataGridDragProps extends IDefaultDataGridProps {
  onDrop: () => void;
  canDrop: () => boolean;
}

export type DataGridProps = IDataGridProps | IDataGridDragProps;

export interface IDataGridSelectOption extends SelectItem {}

export enum EDataGridTextColor {
  GREEN = "green",
  RED = "red",
  GRAY = "gray",
  BLUE = "blue",
  ORANGE = "orange",
}

export enum EDataGridTextWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

export enum EDataGridColumnType {
  SELECT_ROW = "select-row",
  CHECKBOX = "checkbox",
  STRING = "string",
  PHONE = "phone",
  EMAIL = "email",
  DATE = "date",
  DATETIME = "datetime",
  BUTTON = "button",
  AVATAR = "avatar",
  MULTI_AVATAR = "multi-avatar",
  RATING = "rating",
  IMAGE = "image",
  DRAGGABLE = "draggable",
  SESSION_STATUS = "session-status",
  REMOVE = "remove",
  SELECT = "select",
  RESUME = "resume",
  MULTI_SELECT = "multi-select",
}

export enum EDataGridColumnValign {
  TOP = "top",
  MIDDLE = "middle",
}

export interface IDataGridUserAvatarProps extends IBaseAvatarProps {
  fullName?: string;
  link?: string;
  onClick?: () => void;
}

export interface IDataGridRowTooltipItem {
  key: string;
  value?: string;
  type?: "string" | "datetime";
}

export interface IDataGridRowTooltip {
  items?: IDataGridRowTooltipItem[];
  content?: string | ReactElement;
}

export interface IDataGridRow {
  id: string;
  selected: boolean;
  isTransparent?: boolean;
  columnsTextColor?: Record<string, EDataGridTextColor | null>;
  onClick?: (rowId: string) => void;
  buttonProps?: Record<string, IDataGridButtonProps>;
  statusEmployment?: IDataGridSelectProps;
  statusCompany?: IDataGridSelectProps;
  relatedProject?: IDataGridSelectProps;
  avatarPropsExtra?: IDataGridUserAvatarProps;
  tooltips?: Record<string, IDataGridRowTooltip | undefined>;
  [key: string]: any;
}

export interface IDataGridSortItem {
  value: string;
  title: string;
}

export interface IDataGridSort {
  value: string;
  orderKey: string;
  key?: string;
  items: IDataGridSortItem[];
}

export type TDataGridDataParams = Record<string, string>;

export enum EDataGridFilterType {
  SEARCH = "search",
  NUMBER = "number",
  DATE = "date",
  SELECT = "select",
  MULTI_SELECT = "multi-select",
  CHECKBOX = "checkbox",
}

export enum EDataGridFilterPosition {
  LEFT = "left",
  RIGHT = "right",
}

interface IDataGridInputNumberProps
  extends Omit<BaseInputNumberProps, "value" | "onChange"> {}

export interface IDataGridFilterItem {
  type: EDataGridFilterType;
  value: string;
  key: string;
  placeholder?: string;
  items?: IDataGridSelectOption[];
  onlyOneActiveOption?: boolean;
  isHalf?: boolean;
  numberProps?: IDataGridInputNumberProps;
  canSearch?: boolean;
  disableFilters?: boolean;
}

export interface IDataGridButtonProps
  extends Omit<IBaseButtonProps, "onClick"> {
  hideLabel?: boolean;
  hideBtn?: boolean;
  icon?: TTextWithIconName;
  iconSize?: number;
  onClick?: (rowId: string) => void;
}

export interface IDataGridSelectProps
  extends Omit<IBaseSelectProps, "onChange"> {
  hideSelect?: boolean;
  onChange: (payload: IBaseInputChange) => void;
}

export interface IDataGridPositionByDeviceType {
  mobile?: IDataGridColumnSticky;
  tablet?: IDataGridColumnSticky;
  laptop?: IDataGridColumnSticky;
}

export interface IDataGridColumnSticky {
  right?: number;
  left?: number;
  width?: number;
}

export interface IDataGridColumnControlPanelGroup {
  title?: string;
  key: string;
  filters?: IDataGridFilterItem[];
  sort?: IDataGridSort;
}

export interface IDataGridColumnControlPanel {
  title?: string;
  withScroll?: boolean;
  activeGroupKey: string;
  groups: IDataGridColumnControlPanelGroup[];
}

export interface IDataGridColumn {
  type: EDataGridColumnType;
  title: string;
  description?: string;
  linkKey?: string;
  valign?: EDataGridColumnValign;
  key: string;
  minWidth?: number;
  width?: number;
  sticky?: IDataGridPositionByDeviceType;
  showed: boolean;
  hiddenTitle?: boolean;
  hideInColumnSelector?: boolean;
  sort?: IDataGridSort;
  filterPosition?: EDataGridFilterPosition;
  filterTitle?: string;
  filters?: IDataGridFilterItem[];
  nowrap?: boolean;
  titleUppercase?: boolean;
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  buttonProps?: IDataGridButtonProps;
  selectProps?: IDataGridSelectProps;
  dateFormat?: string;
  fontWeight?: EDataGridTextWeight;
  controlPanel?: IDataGridColumnControlPanel;
  textAlign?: "left" | "center" | "right";
  noMobileFilters?: boolean;
  children?: ReactNode;
}

export interface DataGridHeadRowProps {
  columns: IDataGridColumn[];
  queryParams: TDataGridDataParams;
  onUpdateSortAndFilters: (params: TDataGridDataParams) => void;
  onSelectAllRows: (toggle: boolean) => void;
  nowrap?: boolean;
  uppercase?: boolean;
  allSelectedCheckboxType: Checkbox;
}

export interface IDataGridImage {
  url: string;
  alt?: string;
  width?: number;
  height?: number;
}
