import {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import "./player-short-stat.scss";

import { BaseAvatar } from "../base-avatar/base-avatar";

import { UserInfoInterface } from "../../store/types";

import { Competence, Player } from "../../../generated/social";

import { getCompetenceStatItem, IStatItem } from "../../models/stat";

import { PlayerShortStatItem } from "./player-short-stat-item";
import { PlayerShortStatStatus } from "./player-short-stat-status";

import { ResumeDownloadBlock } from "../../components";
import { numberSeparator } from "../../helpers/numberSeparator";

interface Props {
  profileInfo: UserInfoInterface;
  profileCompetence: Player;
}

enum InfoKey {
  GENERAL = "general",
  PLACE_IN_TABLE = "placeInTable",
}

export const PlayerShortStat: FC<Props> = ({
  profileInfo,
  profileCompetence,
}): ReactElement => {
  const { t } = useTranslation();
  const avatarUrl = profileInfo.attributes.avatarUrl?.[0];
  const resumeFileName = profileInfo.attributes.resumeFileName?.[0];
  const gRate = profileCompetence?.gRate.toFixed(2);

  const emptyValue = "—";

  const [statItems, setStatItems] = useState<IStatItem[]>([]);

  const competenceNames = useMemo(() => {
    return [
      Competence.Communication,
      Competence.Analytics,
      Competence.Command,
      Competence.Thinking3d,
      Competence.Economics,
      Competence.Jurisprudence,
      Competence.Microelectronics,
    ];
  }, []);

  useEffect(() => {
    const newStatItems: IStatItem[] = [];

    competenceNames.forEach((competenceName) => {
      newStatItems.push(
        getCompetenceStatItem(competenceName, profileCompetence),
      );
    });

    newStatItems.push(
      {
        key: InfoKey.GENERAL,
        title: t(`profile.competence.title.${InfoKey.GENERAL}`),
        description: gRate || emptyValue,
      },
      {
        key: InfoKey.PLACE_IN_TABLE,
        title: t(`profile.competence.title.${InfoKey.PLACE_IN_TABLE}`),
        description: profileCompetence.gPlace
          ? numberSeparator(profileCompetence.gPlace)
          : emptyValue,
        additionalDescription: profileCompetence.gPlaceTotal
          ? numberSeparator(profileCompetence.gPlaceTotal)
          : emptyValue,
      },
    );

    setStatItems(newStatItems);
  }, [
    gRate,
    profileCompetence.gPlace,
    profileCompetence.competenceRates,
    competenceNames,
    t,
  ]);

  return (
    <div className="player-short-stat__container">
      <div className="player-short-stat__grid">
        <BaseAvatar
          userId={profileInfo.id}
          customPath={avatarUrl}
          borderRadius={6}
          className="player-short-stat__img"
        />
        {!!statItems.length && (
          <Fragment>
            <div className="player-short-stat__header">
              <h2 className="player-short-stat__user-name">
                {profileInfo.firstName} {profileInfo.lastName}
              </h2>
            </div>
            <div className="player-short-stat__approvement">
              <PlayerShortStatStatus
                verified={profileInfo.emailVerified}
                // На бэке нет данных о дате подтверждения
                // date={"1995.03.20"}
              />
            </div>
            <div className="player-short-stat__stats">
              <ul className="player-short-stat__list">
                {statItems.map((item) => (
                  <PlayerShortStatItem item={item} key={item.key} />
                ))}
              </ul>
            </div>
          </Fragment>
        )}
      </div>
      {resumeFileName && profileInfo.id && (
        <ResumeDownloadBlock
          className="player-short-stat__resume-link"
          resumeName={resumeFileName}
          userId={profileInfo.id}
        />
      )}
    </div>
  );
};
