import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { openResponseErrorPopup } from "src/shared/components";
import { Configuration, PlayersApiFactory } from "../../../../generated/social";
import { $axiosSocial } from "../../../api/api";
import {
  failureProfileCompetence,
  setIsSocial,
  successProfileCompetence,
} from "./actionCreators";
import {
  ProfileCompetenceActionsType,
  RequestProfileCompetenceActionInterface,
} from "./actionTypes";

export function* fetchProfileCompetence({
  payload,
}: RequestProfileCompetenceActionInterface) {
  const request = PlayersApiFactory(new Configuration(), "", $axiosSocial);

  try {
    const profile: AxiosResponse = yield call(request.getPlayer, payload.id);
    yield put(successProfileCompetence(profile.data));

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    yield put(failureProfileCompetence());

    if (error.response.data.err.code === 404) {
      yield put(setIsSocial(false));

      return;
    }

    openResponseErrorPopup(error?.response?.data?.err?.msg);
  }
}

export function* profileCompetenceSaga() {
  yield takeLatest(
    ProfileCompetenceActionsType.REQUEST_PROFILE_COMPETENCE,
    fetchProfileCompetence,
  );
}
