import { FC, ReactElement, useCallback, useEffect, useRef } from "react";

import cls from "./relatives-block.module.scss";
import {
  BaseButton,
  BaseTooltip,
  EPopupName,
} from "../../../../shared/components";
import { openPopup } from "../../../../shared/components/base-popup/model";
import { InviteRelativePopup } from "../../../../shared/components/popups/invite-relative-popup/invite-relative-popup";
import {
  $relatives,
  fetchGetProfileRelativeUsers,
} from "../../../../entities/public/relatives/get-profile-relative-users/get-profile-relative-users";
import { useUnit } from "effector-react";
import { ProfileRelativeTable } from "../../../../shared/components/data-grids/relatives/profile-relative-table";
import { fetchGetLink } from "../../../../entities/public/relatives/create-link/create-link";
import { UnLinkRelativePopup } from "../../../../shared/components/popups/unlink-relative-popup/unlink-relative-popup";
import { RelativesTooltip } from "../relatives-tooltip/relatives-tooltip";
import { useTranslation } from "react-i18next";

interface Props {
  isStudent: boolean;
}
export const RelativesBlock: FC<Props> = ({ isStudent }): ReactElement => {
  const relativeItems = useUnit($relatives);

  const { t } = useTranslation();

  const openInviteRelativePopup = () => {
    openPopup({
      name: EPopupName.INVITE_RELATIVE,
      data: {
        callback: () => fetchGetLink(),
      },
    });
  };

  const needToScroll = localStorage.getItem("scrollToRelative");

  const relativeBlock: HTMLElement | null = document.querySelector(
    `.${cls.relativesBlock}`,
  );

  const filteredRelatives = relativeItems?.players.filter(
    (player) => player.confirmed !== false,
  );

  useEffect(() => {
    if (!!needToScroll) {
      relativeBlock?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      if (relativeBlock?.checkVisibility()) {
        localStorage.removeItem("scrollToRelative");
      }
    }
  }, [needToScroll, relativeBlock]);

  useEffect(() => {
    fetchGetProfileRelativeUsers();
  }, []);

  const unLinkRelative = useCallback((id: string) => {
    openPopup({ name: EPopupName.UNLINK_RELATIVE_POPUP, data: id });
  }, []);

  const relativesCountTooltip = useRef<HTMLSpanElement | null>(null);

  return (
    <div className={cls.wrapper}>
      <div className={cls.relativesBlock}>
        <div className={cls.title}>
          <span className={cls.titleText}>{t("common.relatives")}</span>
          <span
            ref={relativesCountTooltip}
            className={`tooltip-wrapper ${cls.relativeCount}`}
          >
            ({filteredRelatives?.length}/10)
            <BaseTooltip
              position="bottom-start"
              tooltipToggleRef={relativesCountTooltip}
            >
              <RelativesTooltip relatives={filteredRelatives} />
            </BaseTooltip>
          </span>
        </div>
        {relativeItems?.players.length ? (
          <ProfileRelativeTable
            autoScrollDisabled
            unLinkRelative={unLinkRelative}
            items={relativeItems.players}
          />
        ) : (
          <div className={cls.relativesContent}>
            <div className={cls.withoutRelatives}>
              {t("relative.withoutRelatives")}
            </div>
          </div>
        )}
      </div>
      {isStudent && (
        <BaseButton
          onClick={openInviteRelativePopup}
          className={cls.addButton}
          large
          disabled={filteredRelatives?.length === 10}
          primary
        >
          {t("relative.addRelative")}
        </BaseButton>
      )}
      <InviteRelativePopup />
      <UnLinkRelativePopup />
    </div>
  );
};
