export type TUserSettingsTableColumnVisibility = Record<string, boolean>;

export interface IUserSettingsTable {
  columnsVisibility: TUserSettingsTableColumnVisibility;
}

export enum EUserSettingsTablesName {
  RATINGS = "ratings",
  MAILING = "mailing",
}

export type TUserSettingsTables = Record<
  EUserSettingsTablesName,
  IUserSettingsTable
>;

export interface IUserSettings {
  tables: TUserSettingsTables;
}
