import { createDomain, sample } from "effector";

import { ResponseNotify } from "src/generated/notification";

import { getNotificationList } from "src/shared/api/admin/notification/notifications";

import { Pagination, QueryParams } from "src/shared/api/base";

import { queryParamsToGetNotificationListAdapter } from "../lib";

// Domain

export const notificationDomain = createDomain("notifications");

// Events

export const fetchNotificationList =
  notificationDomain.createEvent<QueryParams>();

// Effects

export const fetchNotificationListFx =
  notificationDomain.createEffect(getNotificationList);

// Stores

export const $notificationList = notificationDomain.createStore<
  ResponseNotify[]
>([]);

export const $pagination = notificationDomain.createStore<Pagination | null>(
  null,
);

export const $queryParams = notificationDomain.createStore<QueryParams>({});

export const $isLoading = notificationDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchNotificationList,
  fn: queryParamsToGetNotificationListAdapter,
  target: fetchNotificationListFx,
});

sample({
  clock: fetchNotificationList,
  target: $queryParams,
});

sample({
  clock: fetchNotificationListFx.doneData,
  fn: ({ listNotifies }) => listNotifies,
  target: $notificationList,
});

sample({
  clock: fetchNotificationListFx.doneData,
  fn: ({ pagination }) => pagination,
  target: $pagination,
});

sample({ clock: fetchNotificationListFx.pending, target: $isLoading });
