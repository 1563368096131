import { QueryParams } from "../../../../shared/api/base";
import { ERegEx, regExList } from "../../../../shared/constants/regExList";

export const queryParamsToGetAssessmentMembersAdapter = (
  params: QueryParams,
) => ({
  firstName: params.firstName || undefined,
  lastName: params.lastName || undefined,
  locality: params.locality ? [params.locality] : undefined,
  phone: params.phone
    ? [params.phone.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
    : undefined,
  workPhone: params.workPhone ? [params.workPhone] : undefined,
  studyWorkPlace: params.studyWorkPlace ? [params.studyWorkPlace] : undefined,
  email: params.email ? [params.email] : undefined,
  pageNum: params.pageNum ? Number(params.pageNum) : 1,
  pageSize: params.pageSize ? Number(params.pageSize) : 50,
});
