import { EDataGridTextColor } from "../../models/dataGrid";

export const getGridNumberColor = (
  number: number,
  defaultColor: EDataGridTextColor = EDataGridTextColor.GRAY,
): EDataGridTextColor => {
  if (number > 0) {
    return EDataGridTextColor.GREEN;
  }

  if (number < 0) {
    return EDataGridTextColor.RED;
  }

  return defaultColor;
};
