import { sample } from "effector";

import {
  AssessmentDto,
  HrGamesApiGetShortUrlsRequest,
} from "src/generated/game";
import {
  getAssessments,
  GetAssessmentsParams,
  getExportAssessments,
  patchAssessment,
  PatchAssessmentParams,
} from "src/shared/api/public/assessments";

import { assessmentsDomain } from "./domain";
import fileDownload from "js-file-download";
import format from "date-fns/format";

const downloadHandler = (response: Blob) => {
  const date = format(new Date(), "yyyy.MM.dd-HH.mm.ss");
  const filename = `export.${date}.assessments.zip`;

  fileDownload(response, filename);
};

// Stores

export const $assessments = assessmentsDomain.createStore<AssessmentDto[]>([]);

export const $isLoading = assessmentsDomain.createStore<boolean>(true);

// Events

export const fetchAssessments =
  assessmentsDomain.createEvent<GetAssessmentsParams>();

export const updateAssessment =
  assessmentsDomain.createEvent<PatchAssessmentParams>();

export const exportAssessments =
  assessmentsDomain.createEvent<HrGamesApiGetShortUrlsRequest>();

// Effects

export const fetchAssessmentsFx =
  assessmentsDomain.createEffect(getAssessments);

export const updateAssessmentFx =
  assessmentsDomain.createEffect(patchAssessment);

const exportAssessmentsFx =
  assessmentsDomain.createEffect(getExportAssessments);

// Logic

sample({
  clock: fetchAssessments,
  target: fetchAssessmentsFx,
});

sample({
  clock: updateAssessment,
  target: updateAssessmentFx,
});

sample({
  clock: fetchAssessmentsFx.doneData,
  fn: ({ assessments }) => assessments || [],
  target: $assessments,
});

sample({
  clock: fetchAssessmentsFx.pending,
  target: $isLoading,
});

sample({
  clock: exportAssessments,
  target: exportAssessmentsFx,
});

exportAssessmentsFx.doneData.watch(downloadHandler);
