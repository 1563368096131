import { ClientIdEnum } from "src/shared/models/user";

import { $keycloak } from "./keycloak";

export const $clientId = $keycloak.map((state) => state?.clientId || null);

export const $isPlayerClientId = $clientId.map(
  (state) => state === ClientIdEnum.PLAYER,
);

export const $isHrClientId = $clientId.map(
  (state) => state === ClientIdEnum.HR,
);

export const $isAdminClientId = $clientId.map(
  (state) => state === ClientIdEnum.ADMIN,
);
