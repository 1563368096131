import { sample } from "effector";

import {
  DeleteEmail,
  deleteEmailNotification,
} from "src/shared/api/admin/notification";

import { $emailsList, emailNotificationDomain } from "./email-notification";

// Event

export const deleteEmail = emailNotificationDomain.createEvent<DeleteEmail>();

// Effect

export const deleteEmailFx = emailNotificationDomain.createEffect(
  deleteEmailNotification,
);

// Logic

sample({
  clock: deleteEmail,
  target: deleteEmailFx,
});

sample({
  clock: deleteEmailFx.done,
  source: $emailsList,
  fn: (emails, deletedEmail) =>
    emails.filter((email) => email.id !== deletedEmail.params.id),
  target: $emailsList,
});
