import { IRequestAssessmentItemAdmin } from "src/entities/public/assessment-item-admin";
import { gameService } from "src/shared/api/base/api-instances";

export const getAdmAssessment = (params: IRequestAssessmentItemAdmin) => {
  const { id } = params;

  return gameService
    .makeAdminAssessmentApi()
    .getAdmAssessment(id)
    .then((res) => res.data);
};
