import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";

import { PageWrapper } from "src/shared/components";

import {
  cleanAssessmentsResults,
  requestAssessmentsResults,
  setAssessmentsReadmore,
  $assessmentsResults,
} from "src/entities/public/assessments-results";

import { ERequestStatus } from "../../../shared/store/types";

import { TDataGridDataParams } from "../../../shared/models/dataGrid";
import { useUrlState } from "src/shared/hooks/useUrlState";
import { ArchiveTable } from "../../../shared/components/data-grids/archive";
import { AssessmentType, GameType } from "../../../generated/game";
import { getDateForFilter } from "../../../shared/helpers/getDateForFilter";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import "./archive-page.scss";

import SessionsExportTableControl from "./components/sessions-export-table-control";

interface Props {}

export const ArchivePage: FC<Props> = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    items: results,
    itemsPagination: pagination,
    statusItems: loadingStatus,
  } = useUnit($assessmentsResults);

  const assessmentTypes = useMemo<AssessmentType[]>(
    () => Object.values(AssessmentType),
    [],
  );

  const gameTypes = useMemo<GameType[]>(() => Object.values(GameType), []);

  const [checkList, setCheckList] = useState<string[]>([]);

  useEffect(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      title,
      gameTitle,
      curPlayersFrom,
      curPlayersTo,
      createdFrom,
      createdTo,
      startedFrom,
      startedTo,
      finishedFrom,
      finishedTo,
      assessmentType,
      gameType,
      firstName,
      lastName,
    } = urlState.query;

    let assessmentTypeValues: AssessmentType[] = assessmentType
      ? (assessmentType.split(",") as AssessmentType[])
      : [];

    let gameTypeValues: GameType[] = gameType
      ? (gameType.split(",") as GameType[])
      : [];

    assessmentTypeValues = assessmentTypeValues.filter((assessmentTypeValue) =>
      assessmentTypes.includes(assessmentTypeValue),
    );

    gameTypeValues = gameTypeValues.filter((gameTypeValue) =>
      gameTypes.includes(gameTypeValue),
    );

    requestAssessmentsResults({
      params: {
        pageNum: pageNum ? Number(pageNum) : undefined,
        pageSize: pageSize ? Number(pageSize) : 50,
        orderBy,
        aTitle: title ? [title] : undefined,
        gTitle: gameTitle ? [gameTitle] : undefined,
        aType: assessmentTypeValues.length ? assessmentTypeValues : undefined,
        curPlayersFrom: curPlayersFrom ? Number(curPlayersFrom) : undefined,
        curPlayersTo: curPlayersTo ? Number(curPlayersTo) : undefined,
        createdFrom: getDateForFilter(createdFrom),
        createdTo: getDateForFilter(createdTo),
        startedFrom: getDateForFilter(startedFrom),
        startedTo: getDateForFilter(startedTo),
        finishedFrom: getDateForFilter(finishedFrom),
        finishedTo: getDateForFilter(finishedTo),
        gType: gameTypeValues.length ? gameTypeValues : undefined,
        firstName: firstName,
        lastName: lastName,
      },
    });
  }, [urlState, assessmentTypes, gameTypes, dispatch]);

  useEffect(() => {
    return () => {
      cleanAssessmentsResults();
    };
  }, []);

  const isLoading: boolean = loadingStatus === ERequestStatus.LOADING;

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setAssessmentsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const getSelectedRow = useCallback(
    (idList: string[]) => {
      setCheckList(idList);
    },
    [setCheckList],
  );

  return (
    <PageWrapper
      title={t("allSessions.title")}
      isLoadingPanel={isLoading}
      isShowContentWhenIsLoading
      isLightBlueBackground
      controlPanelTitle={t("assessmentsControl.controlPanel.label.assessments")}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={checkList.length}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={<SessionsExportTableControl selectedIds={checkList} />}
    >
      <div className="archive-hr-page">
        <div className="archive-hr-page__table">
          <ArchiveTable
            historyItems={results}
            queryParams={urlState.query as TDataGridDataParams}
            onUpdateSortAndFilters={onUpdateQueryParams}
            loading={isLoading}
            pagination={pagination}
            onSelectRows={getSelectedRow}
            selectedRowsIds={checkList}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum, true)}
          />
        </div>
        {/* {isError && <EmptyBlock title={t("alert.requestError")} />} */}
      </div>
    </PageWrapper>
  );
};
