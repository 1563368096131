import { GetListParams } from "../../../../shared/api/public/schools";
import { QueryParams } from "../../../../shared/api/base";

export const queryParamsToGetListParamsAdapter = ({
  locale,
  country: cc,
  region: rid,
  district: did,
}: QueryParams = {}): GetListParams => ({
  regionId: rid ? Number(rid) : undefined,
  districtId: did ? Number(did) : undefined,
  locale,
  countryCode: cc,
});
