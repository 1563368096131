import cls from "./session-history-player.module.scss";
import { Skeleton } from "../skeleton/Skeleton";

export const SessionHistoryPlayerSkeleton = () => {
  return (
    <div className={cls.wrapper}>
      <Skeleton width={1190} height={678} border="20px" />
      <div className={cls.row}>
        <div className={cls.rowPagination}>
          <Skeleton width={172} height={21} border="20px" />
          <Skeleton width={60} height={30} border="8px" />
        </div>
        <div className={cls.rowPagination}>
          <Skeleton width={32} height={35} border="8px" />
          <Skeleton width={32} height={35} border="8px" />
          <Skeleton width={32} height={35} border="8px" />
          <Skeleton width={32} height={35} border="8px" />
        </div>
      </div>
    </div>
  );
};
