import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FooterHeading } from "./components";
import { ReactComponent as YtCircle } from "../../images/icons/yt-white-circle.svg";
import { ReactComponent as DzenIcon } from "../../images/icons/dzen-icon.svg";
import { ReactComponent as VkCircle } from "../../images/icons/vk-white-circle.svg";
import { ReactComponent as MailIcon } from "../../images/icons/mail-white.svg";
import { ReactComponent as PhoneIcon } from "../../images/icons/phone-white-icon.svg";
import { ReactComponent as TelegramIcon } from "../../images/icons/telegram-white.svg";
import { ReactComponent as ChevronDown } from "../../images/icons/chevron-down.svg";
import styles from "./footer.module.scss";

interface IFooterProps {
  isScrollable?: boolean;
}

export const Footer: FC<IFooterProps> = ({ isScrollable = false }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(isScrollable);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const [previousScroll, setPreviousScroll] = useState<number>(0);
  const observerRef = useRef<null | IntersectionObserver>(null);
  const ref = useRef<null | HTMLDivElement>(null);

  const onClickHandler = (): void => {
    if (!isOpen) {
      setPreviousScroll(window.pageYOffset);
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: previousScroll,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0, rootMargin: "0px" },
    );
  }, []);

  useEffect(() => {
    if (ref !== null && ref.current) {
      observerRef.current?.observe(ref.current);
    }
    return () => observerRef.current?.disconnect();
  }, [ref]);

  useEffect(() => {
    if (isScrollable) {
      return;
    }

    if (isIntersecting) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isIntersecting]);

  return (
    <>
      <footer
        className={classNames(styles.footer, {
          [styles.footer_full]: isOpen,
          [styles.footer_static]: isScrollable,
        })}
      >
        <div className={styles.footer__top}>
          <div className={styles.footer__top_card}>
            <div className={styles.footer__top_heading}>
              <FooterHeading
                title={t("footer.heading.hr")}
                className={styles.footer__heading}
              />
            </div>
            <p className={styles.footer__text}>{t("footer.text.hr")}</p>
            <div>
              <a className={styles.footer__top_link} href="tel:+78555759024">
                <PhoneIcon className={styles.footer__icon} />
                <div>+7 (85557) 5 90 24</div>
              </a>
            </div>

            <div>
              <a className={styles.footer__top_link} href="tel:+78555759024">
                <PhoneIcon className={styles.footer__icon} />
                <div>+7 (927) 432-98-17 ({t("footer.text.admission")})</div>
              </a>
            </div>
            <div className={styles.footer__group_links}>
              <div>
                <a
                  className={styles.footer__top_link}
                  href="mailto:hr@alabuga.ru"
                >
                  <MailIcon className={styles.footer__icon} />
                  <div>hr@alabuga.ru</div>
                </a>
              </div>
              <div>
                <a
                  className={styles.footer__top_link}
                  href="http://t.me/hr_alabuga_bot"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TelegramIcon className={styles.footer__icon} />
                  <div>{t("footer.text.telegram-chat")}</div>
                </a>
              </div>
            </div>
            <p className={styles.footer__support__hours}>
              {t("support.hours")}
            </p>
          </div>
          <div className={styles.footer__top_card}>
            <div className={styles.footer__top_heading}>
              <FooterHeading
                title={t("footer.heading.tech-support")}
                className={styles.footer__heading}
              />
            </div>
            <p className={styles.footer__text}>
              {t("footer.text.tech-support")}
            </p>
            <div>
              <a className={styles.footer__top_link} href="tel:89272432348">
                <PhoneIcon className={styles.footer__icon} />
                <div>8 927 243 23 48</div>
              </a>
            </div>
            <div>
              <a
                className={styles.footer__top_link}
                href="mailto:hr.support@alabuga.ru"
              >
                <MailIcon className={styles.footer__icon} />
                <div>hr.support@alabuga.ru</div>
              </a>
            </div>
            <div>
              <a
                className={styles.footer__top_link}
                href="http://t.me/hr_alabuga_bot"
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon className={styles.footer__icon} />
                <div>{t("footer.text.telegram-chat")}</div>
              </a>
            </div>
            <p className={styles.footer__support__hours}>
              {t("support.hours")}
            </p>
          </div>
          <div className={styles.footer__top_card}>
            <div className={styles.footer__top_heading}>
              <FooterHeading
                title={t("contacts.socialNetworksBlock.title")}
                className={styles.footer__heading}
              />
            </div>
            <p className={styles.footer__text}>{t("footer.heading.social")}</p>
            <div>
              <a
                className={styles.footer__top_link}
                href="https://vk.com/sezalabuga"
                target="_blank"
                rel="noreferrer"
              >
                <VkCircle className={styles.footer__icon} />
                <div>{t("footer.text.vk-group")}</div>
              </a>
            </div>
            <div>
              <a
                className={styles.footer__top_link}
                href="https://www.youtube.com/@alabugapolytech"
                target="_blank"
                rel="noreferrer"
              >
                <YtCircle className={styles.footer__icon} />
                <div>{t("footer.text.yt-channel")}</div>
              </a>
            </div>
            <div>
              <a
                className={styles.footer__top_link}
                href="https://dzen.ru/alabuga"
                target="_blank"
                rel="noreferrer"
              >
                <DzenIcon className={styles.footer__icon} />
                <div>{t("footer.text.dzen-channel")}</div>
              </a>
            </div>
          </div>
        </div>
        <div onClick={onClickHandler} className={styles.footer__chevron}>
          <ChevronDown
            className={classNames(styles.footer__chevron_icon, {
              [styles.footer__chevron_icon_up]: isOpen,
              [styles.footer__chevron_icon_down]: !isOpen,
            })}
          />
        </div>
      </footer>
      <div ref={ref} className={styles.footer_observable} />
    </>
  );
};
