import { Competence } from "src/generated/statistic";

import { getSumFractionalNums } from "src/shared/helpers/getSumFractionalNums";

import { IDislikeItem } from "../model/types";

export const getDislikesSumByCompetence = (
  dislikes: IDislikeItem[],
  competence: Competence,
): number => {
  const dislikeNums = dislikes.reduce((final: number[], curr: IDislikeItem) => {
    curr.diffs.forEach(({ diff, competence: diffCompetence }) => {
      if (diffCompetence === competence) {
        final.push(diff);
      }
    });

    return final;
  }, []);

  return getSumFractionalNums(dislikeNums);
};
