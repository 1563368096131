import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocStrDto } from "../../../../generated/social";
import {
  BaseButton,
  FormControl,
  TextWithIcon,
  TitleWithContent,
} from "../../../../shared/components";
import { BaseInputText } from "../../../../shared/components/form-fields";
import {
  BaseSelect,
  SelectItem,
} from "../../../../shared/components/form-fields/base-select/base-select";
import { defaultLanguage, languageList } from "../../../../shared/i18n/i18n";
import { IBaseInputChange } from "../../../../shared/models/base-input";
import "./contact-positions.scss";

interface Props {
  positions: LocStrDto;
  onChange: (items: LocStrDto) => void;
  errors: Record<string, string>;
}

interface IPositionsItems {
  value: string;
  lang: string;
}

const languageSelectItems: SelectItem[] = languageList.map((item) => ({
  value: item,
  label: item.toLocaleUpperCase(),
}));

export const ContactPositions: FC<Props> = ({
  positions,
  onChange,
  errors,
}): ReactElement => {
  const { t } = useTranslation();
  const [positionsItems, setPositionsItems] = useState<IPositionsItems[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const isShowAddBtn: boolean =
    languageSelectItems.length !== positionsItems.length;
  const description: string | undefined = isShowAddBtn
    ? t("other.editContacts.field.description.position")
    : undefined;

  useEffect(() => {
    const localSelectedLanguages: string[] = positionsItems.reduce(
      (final: string[], curr: IPositionsItems) => {
        if (!!curr.lang) {
          final.push(curr.lang);
        }

        return final;
      },
      [],
    );

    setSelectedLanguages(localSelectedLanguages);
  }, [positionsItems]);

  useEffect(() => {
    const localItems: LocStrDto = {};

    positionsItems.forEach((item) => {
      if (!!item.lang) {
        localItems[item.lang as keyof LocStrDto] = item.value;
      }
    });

    onChange(localItems);
  }, [positionsItems, onChange]);

  useEffect(() => {
    const localItems: IPositionsItems[] = Object.keys(positions).map(
      (item) => ({
        value: positions[item as keyof LocStrDto] as string,
        lang: item,
      }),
    );

    setPositionsItems(localItems);
  }, [positions]);

  const onAddHandler = (): void => {
    setPositionsItems((prev) => [...prev, { value: "", lang: "" }]);
  };

  const onDeleteHandler = (itemIndex: number): void => {
    setPositionsItems((prev) => prev.filter((_, index) => itemIndex !== index));
  };

  const onChangePositionHandler = (
    payload: IBaseInputChange,
    index: number,
  ) => {
    setPositionsItems((prev) => {
      const localPrev = [...prev];

      localPrev[index].value = payload.value;

      return localPrev;
    });
  };

  const onChangeLanguageHandler = (
    payload: IBaseInputChange,
    index: number,
  ) => {
    setPositionsItems((prev) => {
      const localPrev = [...prev];

      localPrev[index].lang = payload.value;

      return localPrev;
    });
  };

  const getLocalLangSelectItems = (
    positionItem: IPositionsItems,
  ): SelectItem[] => {
    return languageSelectItems.filter(
      (langItem) =>
        positionItem.lang === langItem.value ||
        !selectedLanguages.includes(langItem.value),
    );
  };

  return (
    <TitleWithContent
      title={t("other.editContacts.field.placeholder.position")}
    >
      <p className="contact-position__description">{description}</p>
      {!!positionsItems.length && (
        <div>
          {positionsItems.map((positionItem, index) => {
            const localLanguageSelectItems =
              getLocalLangSelectItems(positionItem);
            const isDefaultLang: boolean =
              positionItem.lang === defaultLanguage;
            const isError: boolean = !!errors[positionItem.lang];

            return (
              <FormControl key={index}>
                <div className="contact-position">
                  <div className="contact-position__lang">
                    <BaseSelect
                      disabled={isDefaultLang}
                      placeholder={t(
                        "other.editContacts.field.placeholder.lang",
                      )}
                      activeItem={positionItem.lang}
                      items={localLanguageSelectItems}
                      onChange={(payload) =>
                        onChangeLanguageHandler(payload, index)
                      }
                      required
                      error={isError && !positionItem.lang}
                    />
                  </div>
                  <div className="contact-position__value">
                    <BaseInputText
                      placeholder={t(
                        "other.editContacts.field.placeholder.position",
                      )}
                      value={positionItem.value}
                      onChange={(payload) =>
                        onChangePositionHandler(payload, index)
                      }
                      error={isError && !positionItem.value}
                      required
                    />
                  </div>
                  {!isDefaultLang && (
                    <BaseButton onClick={() => onDeleteHandler(index)}>
                      <TextWithIcon iconName="cross-red" hideLabel />
                    </BaseButton>
                  )}
                </div>
              </FormControl>
            );
          })}
          {isShowAddBtn && (
            <BaseButton onClick={onAddHandler}>
              <TextWithIcon
                iconName="plus-blue"
                label={t("other.editContacts.btn.addPosition")}
              />
            </BaseButton>
          )}
        </div>
      )}
    </TitleWithContent>
  );
};
