import { createDomain, sample } from "effector";
import { spread } from "patronum";

import {
  Country,
  getList,
  Pagination,
} from "../../../../shared/api/admin/countries";

import { QueryParams } from "../../../../shared/api/base";

import { queryParamsToGetListParamsAdapter } from "../lib/queryParamsToGetParamsAdapter";

// Domain

export const countriesDomain = createDomain("countriesAdmin");

// Events

export const fetchCountries = countriesDomain.createEvent<QueryParams>();

// Effects

export const fetchCountriesFx = countriesDomain.createEffect(getList);

// Stores

export const $countries = countriesDomain.createStore<Country[]>([]);

export const $pagination = countriesDomain.createStore<Pagination>(null);

export const $queryParams = countriesDomain.createStore<QueryParams>({});

export const $isLoading = countriesDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchCountries,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchCountriesFx,
});

sample({
  clock: fetchCountries,
  target: $queryParams,
});

sample({
  clock: fetchCountriesFx.pending,
  target: $isLoading,
});

spread({
  source: fetchCountriesFx.doneData.map(({ countries, pagination }) => ({
    countries,
    pagination: pagination || null,
  })),
  targets: {
    countries: $countries,
    pagination: $pagination,
  },
});
