import { CSSProperties, FC, ReactElement } from "react";

import { Property } from "csstype";
import { useTranslation } from "react-i18next";

import Color from "color";

import "./assessment-avatar.scss";

import { IconUser } from "../icons-components";

interface Props {
  src?: string;
  alt?: string;
  isAdd?: boolean;
  color?: Property.Color;
  activeUsers?: number;
  allUsers?: number;
  onClickMembers?: () => void;
  onClickCreate?: () => void;
}

export const AssessmentAvatar: FC<Props> = ({
  src,
  alt,
  isAdd,
  color = "#d4daec",
  activeUsers,
  allUsers,
  onClickMembers,
  onClickCreate,
}): ReactElement => {
  const { t } = useTranslation();

  const computedColor = Color(color);

  const imgBorderColor = computedColor.darken(0.3).toString();
  const imgBgColor = computedColor.toString();
  const imgBoxShadowColor = computedColor.alpha(0.4).toString();

  const imgBoxShadow = `0 0 3rem ${imgBoxShadowColor}`;

  const usersBgColor = computedColor.darken(0.3).toString();
  const usersBoxShadowColor = computedColor.darken(0.75).alpha(0.4).toString();

  const usersBoxShadow = `0 0 3rem ${usersBoxShadowColor}`;

  const usersCountersArr = [];

  const usersColorContrast = computedColor.contrast(Color("white"));

  const usersTextColor = usersColorContrast > 1.35 ? "white" : "black";

  if (activeUsers !== undefined) {
    usersCountersArr.push(activeUsers);
  }

  if (allUsers !== undefined) {
    usersCountersArr.push(allUsers);
  }

  const usersCountersStr = usersCountersArr.join(" / ");

  const usersStyles: CSSProperties = {
    backgroundColor: usersBgColor,
    boxShadow: usersBoxShadow,
    color: usersTextColor,
  };

  return (
    <span className="assessment-avatar">
      {isAdd && (
        <button
          title={alt}
          className="assessment-avatar__img assessment-avatar__img--is-add"
          type="button"
          onClick={onClickCreate}
          aria-label={t("game.button.create")}
        />
      )}
      {!isAdd && (
        <img
          src={src}
          alt={alt}
          className="assessment-avatar__img"
          style={{
            borderColor: imgBorderColor,
            backgroundColor: imgBgColor,
            boxShadow: imgBoxShadow,
          }}
        />
      )}
      {!!usersCountersArr.length && (
        <button
          type="button"
          className="assessment-avatar__users"
          style={usersStyles}
          onClick={(event) => {
            if (onClickMembers) {
              event.stopPropagation();

              onClickMembers();
            }
          }}
          disabled={!activeUsers}
        >
          <IconUser className="assessment-avatar__users-icon" />
          {usersCountersStr}
        </button>
      )}
    </span>
  );
};
