import classNames from "classnames";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import { python } from "@codemirror/lang-python";
import { CalcType, Competence, GameType } from "../../../../../generated/game";
import { BaseButton, TextWithIcon } from "../../../../../shared/components";
import { TBaseTextareaSize } from "../../../../../shared/components/form-fields/base-textarea/base-textarea";
import { useMediaQuery } from "../../../../../shared/hooks/useMediaQuery";
import "./test-formulas-item.scss";
import { fullscreenElement } from "../../../../../shared/helpers/fullscreenElement";

interface Props {
  className?: string;
  gameType: GameType;
  type: CalcType;
  competence?: Competence;
  onClickTest: () => void;
  testBtnDisabled: boolean;
  value: string;
  onChange: (e: string) => void;
  error: boolean;
  textareaSize?: TBaseTextareaSize;
  label: string;
}

export const TestFormulasItem: FC<Props> = ({
  className,
  gameType,
  type,
  competence,
  onClickTest,
  testBtnDisabled,
  value,
  onChange,
  label,
}): ReactElement => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const btnLabel = t(
    `simulationControl.simulationPage.modesBlock.btn.test${
      !isMobile ? "Value" : ""
    }`,
  );

  return (
    <div
      key={gameType + type + competence}
      className={classNames("test-formulas-item", className)}
    >
      <div className="test-formulas-item__header">
        <h2 className="test-formulas-item__title">{label}</h2>
        <BaseButton
          primary
          outline
          xSmall
          disabled={testBtnDisabled}
          onClick={onClickTest}
        >
          {btnLabel}
        </BaseButton>
      </div>
      <CodeMirror
        className={`test-formulas-item__formula test-formulas-item__formula__${type}`}
        extensions={[python(), EditorView.lineWrapping]}
        onChange={onChange}
        placeholder={t(
          "simulationControl.simulationPage.modesBlock.textareaPlaceholder.formula",
        )}
        height="100%"
        value={value}
      >
        <button
          onClick={() =>
            fullscreenElement(`test-formulas-item__formula__${type}`)
          }
          className="test-formulas-item__formula__resize-btn"
        >
          <TextWithIcon hideLabel iconName="fullscreen-gray" />
        </button>
      </CodeMirror>
    </div>
  );
};
