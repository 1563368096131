import { sample } from "effector";

import { QueryParams } from "src/shared/api/base";

import { getSmsListMessages } from "src/shared/api/admin/notification/sms-notification";

import { queryParamsToGetSmsListAdapter } from "../lib";

import {
  $pagination,
  $queryParams,
  $smsList,
  smsNotificationDomain,
} from "./sms-notification";

// Event

export const readmoreSms = smsNotificationDomain.createEvent<QueryParams>();

// Effect

export const readmoreSmsFx =
  smsNotificationDomain.createEffect(getSmsListMessages);

// Logic

sample({
  clock: readmoreSms,
  fn: queryParamsToGetSmsListAdapter,
  target: readmoreSmsFx,
});

sample({
  clock: readmoreSms,
  target: $queryParams,
});

sample({
  clock: readmoreSmsFx.doneData,
  source: $smsList,
  fn: (emails, data) => [...emails, ...data.listSms],
  target: $smsList,
});

sample({
  clock: readmoreSmsFx.doneData,
  fn: ({ pagination }) => pagination,
  target: $pagination,
});
