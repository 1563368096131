import { sample } from "effector";

import { FormDataInterface } from "src/shared/components/user-form/user-form";

import { userFormDomain } from "./domain";

// Stores
export const $formData = userFormDomain.createStore<FormDataInterface | null>(
  null,
);

export const $country = userFormDomain.createStore<string>("");

export const $region = userFormDomain.createStore<string>("");

export const $district = userFormDomain.createStore<string>("");

// Events

export const changeFormData = userFormDomain.createEvent<FormDataInterface>();

// Logic

sample({ clock: changeFormData, target: $formData });

sample({
  clock: $formData,
  fn: (formData) => formData?.country || "",
  target: $country,
});

sample({
  clock: $formData,
  fn: (formData) => formData?.region || "",
  target: $region,
});

sample({
  clock: $formData,
  fn: (formData) => formData?.district || "",
  target: $district,
});
