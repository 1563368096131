import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./validateLobbyHelper.scss";
import {
  BaseButton,
  TextWithIcon,
} from "../../../../../../../shared/components";
import { useDelayUnmount } from "../../../../../../../shared/hooks/useDelayUnmount";

interface IProps {
  isMenuOpened: boolean;
  onClose: () => void;
  message: string;
}

export const ValidateLobbyHelper: React.FC<IProps> = ({
  isMenuOpened,
  onClose,
  message,
}) => {
  const { t } = useTranslation();

  const shouldRenderMenu = useDelayUnmount(isMenuOpened, 500);

  return !shouldRenderMenu ? null : (
    <div
      className={classNames("validate-lobby-helper", {
        "validate-lobby-helper__is_closed": !isMenuOpened,
      })}
    >
      <div className="validate-lobby-helper-title">
        {!message ? (
          <TextWithIcon
            className="validate-lobby-helper-icon"
            iconName="icon-check-green"
            iconSize={16}
            label={t(`sessions.session.validateMessage.sessionIsReadyToStart`)}
          />
        ) : (
          <TextWithIcon
            className="validate-lobby-helper-icon"
            iconName="cross-red"
            iconSize={16}
            label={t("sessions.session.validateMessage.unableToStartSession")}
          />
        )}
      </div>
      <span className="validate-lobby-helper_message">
        {message && t(`sessions.session.validateMessage.${message}`)}
      </span>
      <BaseButton xSmall primary onClick={onClose}>
        {t("popup.welcome.ok")}
      </BaseButton>
    </div>
  );
};
