import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { setSocketConnectionStatus, SocketConnectionStatus } from "../model/v4";

type SocketURL = string;
type SocketPath = string;

export interface SocketParams {
  url: SocketURL;
  path: SocketPath;
  namespace?: string;
}

export function connectSocket<
  T extends DefaultEventsMap,
  U extends DefaultEventsMap,
>(params: SocketParams): Socket<T, U> | null {
  let { path, url, namespace } = params;

  if (!url) {
    return null;
  }

  if (namespace) {
    url += "/" + namespace;
  }

  const socket = io(url, {
    path,
    auth: (cb: (cbParams: object) => void) => {
      cb({
        token: localStorage.getItem("alabuga-token"),
      });
    },
    upgrade: false,
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    setSocketConnectionStatus(SocketConnectionStatus.CONNECTED);
    console.log("socket-io init connect. listening ...", socket, params);
  });
  socket.on("disconnect", () => {
    setSocketConnectionStatus(SocketConnectionStatus.DISCONNECTED);
    console.error("socket disconnect...", socket, params);
  });
  socket.io.on("reconnect", () => {
    setSocketConnectionStatus(SocketConnectionStatus.RECONNECTING);
    console.log("reconnect socket");
  });

  return socket;
}

export function disconnectSocket<
  T extends DefaultEventsMap,
  U extends DefaultEventsMap,
>(socket: Socket<T, U> | null): Socket<T, U> | null {
  return socket?.disconnect() || null;
}
