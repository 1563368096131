import { declOfNum, t } from "src/shared/helpers";
import { snackbarOpen } from "../../snackbar";
import { ERegEx, regExList } from "src/shared/constants/regExList";

export const successResetPassword = () =>
  snackbarOpen({
    visible: true,
    text: t("usersControl.snackbar.passwordReset.success"),
    type: "success",
  });

export const failureResetPassword = (data: string) => {
  const timer = data.replace(regExList[ERegEx.NOT_NUMBERS], "");

  const formattedTimer = declOfNum(Number(timer), [
    t("usersControl.snackbar.passwordReset.unitOfTime.second"),
    t("usersControl.snackbar.passwordReset.unitOfTime.second.plural"),
    t("usersControl.snackbar.passwordReset.unitOfTime.second.pluralGenitive"),
  ]);

  let text = `${timer} ${formattedTimer} ${t(
    "usersControl.snackbar.passwordReset.failure",
  )}`;
  if (data === "User email missing") {
    text = t("popup.baseMessage.responseMessage.userEmailMissing");
  }
  snackbarOpen({
    visible: true,
    text,
    type: "error",
  });
};
