import { FC, ReactElement, useEffect, useState } from "react";

import {
  DataGridHeadRowProps,
  TDataGridDataParams,
} from "../../../models/dataGrid";

import { DataGridHeadColumn } from ".";

export const DataGridHeadRow: FC<DataGridHeadRowProps> = ({
  columns,
  queryParams,
  onSelectAllRows,
  onUpdateSortAndFilters,
  nowrap,
  uppercase,
  allSelectedCheckboxType,
}): ReactElement => {
  const [showedDropdown, setShowedDropdown] = useState<string>();

  const onClickDocument = (evt: MouseEvent) => {
    const target = evt.target as HTMLDivElement;

    if (
      !target.closest(".base-select__menu") &&
      !target.closest(".base-multi-select__menu") &&
      !target.closest(".data-grid__dropdown") &&
      !target.closest(".data-grid__th-button")
    ) {
      setShowedDropdown(undefined);
    }
  };

  const apply = (params: TDataGridDataParams) => {
    setShowedDropdown(undefined);

    onUpdateSortAndFilters && onUpdateSortAndFilters(params);
  };

  useEffect(() => {
    document.addEventListener("click", onClickDocument);

    return () => {
      document.removeEventListener("click", onClickDocument);
    };
  });

  return (
    <tr className="data-grid__tr">
      {columns.map(
        (column) =>
          column.showed && (
            <DataGridHeadColumn
              column={column}
              showedDropdown={showedDropdown}
              setShowedDropdown={setShowedDropdown}
              changeSelectedAllRows={onSelectAllRows}
              allSelectedCheckboxType={allSelectedCheckboxType}
              queryParams={queryParams}
              onUpdateSortAndFilters={apply}
              key={column.key}
              nowrap={nowrap}
              uppercase={uppercase}
            />
          ),
      )}
    </tr>
  );
};
