import { combine, sample } from "effector";

import { $countries, $queryParams, countriesDomain } from "./countries";

import { countriesToRowsAdapter, generateColumns } from "../lib";

import { $currentLanguage } from "src/shared/helpers";
import { IDataGridColumn, IDataGridRow } from "../../../../shared/models";

// Domains

const dataGrid = countriesDomain.createDomain();

// Stores

export const $rows = dataGrid.createStore<IDataGridRow[]>([]);
export const $columns = dataGrid.createStore<IDataGridColumn[]>([]);

// Logic

sample({
  clock: combine($countries, $currentLanguage, countriesToRowsAdapter),
  target: $rows,
});

sample({
  clock: combine($queryParams, $currentLanguage, (queryParams) =>
    generateColumns(queryParams),
  ),
  target: $columns,
});
