import React, {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { useUnit } from "effector-react";

import { AdmCalcDto } from "src/generated/game";

import { CalcDto } from "src/generated/statistic";

import {
  getSimulationParamType,
  getSimulationRoles,
  languagePicker,
} from "src/shared/helpers";

import {
  BaseButton,
  BaseLoader,
  BaseSelect,
  CopyButton,
  EmptyBlock,
  EPopupDataIdName,
  EPopupName,
  ParamsInfoItem,
  popupModel,
  TextWithIcon,
} from "src/shared/components";

import {
  ERequestStatus,
  ESimulationParamsType,
  IAssessmentItemAdmin,
  IAssessmentResults,
  IParams,
  SimulationListPlayerMetaInterface,
  SimulationListPlayerMetaParamsItemInterface,
} from "src/shared/store/types";

import {
  cleanAssessmentItemAdmin,
  requestAssessmentItemAdmin,
  changeAssessmentItemAdmin,
  $assessmentItemAdmin,
} from "src/entities/public/assessment-item-admin";

import {
  cleanAssessmentResults,
  requestAssessmentResults,
  $assessmentResults,
} from "src/entities/public/assessments-results";

import {
  cleanSimulationItemAdmin,
  requestGetSimulationItemAdmin,
  selectSimulationItemAdminItem,
  selectSimulationItemAdminStatusGet,
} from "src/shared/store/ducks/simulation-item-admin";

import { formulasModel } from "src/entities/admin/formulas";

import { CodeBlock } from "./code-block";

import "./assessment-parameters-popup.scss";
import useClient from "../../../hooks/useClient";
import { IBaseInputChange } from "../../../models";
import { updateAssessment } from "../../../../entities/public/assessments/model";
import { closePopup as modelClosePopup } from "../../../../shared/components/base-popup/model";

type TAssessmentInfo = IAssessmentItemAdmin | IAssessmentResults | null;

type TLocalMeta = SimulationListPlayerMetaInterface | undefined;

interface IParamsAdmin extends IParams {
  turn_durations: number[];
}

interface IAssessmentParametersItemAdmin extends IAssessmentItemAdmin {
  params: IParamsAdmin;
}

const {
  fetchFormulas,
  $isLoading: $isLoadingFormulas,
  $formulas,
} = formulasModel;

const { $activePopups } = popupModel;
export const AssessmentParametersPopup: FC = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { isPlayerClientId } = useClient();

  const { item: assessmentSelected, status: assessmentLoadingStatus } =
    useUnit($assessmentItemAdmin);

  const { item: results, statusItem: resultsLoadingStatus } =
    useUnit($assessmentResults);

  const assessment = useMemo(() => {
    return assessmentSelected as IAssessmentParametersItemAdmin;
  }, [assessmentSelected]);

  const simulation = useSelector(selectSimulationItemAdminItem);

  const simulationLoadingStatus = useSelector(
    selectSimulationItemAdminStatusGet,
  );

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const assessmentDataRef = useRef<IAssessmentItemAdmin | null>(null);

  const [currentInput, setCurrentInput] = useState<number>(0);

  const activePopups = useUnit($activePopups);

  const currentPopup = useMemo(
    () =>
      activePopups.find(
        ({ name }) => name === EPopupName.ASSESSMENT_PARAMETERS,
      ),
    [activePopups],
  );

  const itemId = useMemo(
    () =>
      currentPopup?.dataId?.find(
        ({ name }) => name === EPopupDataIdName.DEFAULT,
      )?.value,
    [currentPopup],
  );

  const [assessmentData, setAssessmentData] = React.useState({
    id: itemId,
    params: {},
  });

  const isFinished: boolean = useMemo(
    () => !!currentPopup?.someBooleanTrigger,
    [currentPopup],
  );

  const calcs = useUnit($formulas);

  const isLoadingCalcs = useUnit($isLoadingFormulas);

  const assessmentInfo: TAssessmentInfo = useMemo(() => {
    return assessment || results;
  }, [assessment, results]);

  const gameType = useMemo(
    () => assessment?.gameType || results?.gType,
    [assessment, results],
  );

  const assessmentType = useMemo(
    () => assessment?.assessmentType || results?.aType,
    [assessment, results],
  );

  const localMeta: TLocalMeta = useMemo(() => {
    if (assessment && simulation) {
      return simulation.rawMetaJson;
    } else if (results) {
      return results.rawMetaJson;
    }
  }, [simulation, assessment, results]);

  const localFormules: AdmCalcDto[] | CalcDto[] = useMemo(() => {
    if (!!calcs.length && assessment) {
      const tmpFomulas = calcs.filter(
        (calc) =>
          calc.calcType === "general" && calc.gameType === assessment?.gameType,
      );

      if (tmpFomulas.length > 0) {
        return tmpFomulas;
      }
    } else if (results && results.calcs && results.calcs.length > 0) {
      return results.calcs;
    }

    return [];
  }, [calcs, assessment, results]);

  const localParams: IParams | undefined = useMemo(() => {
    if (assessment) {
      return assessment.params;
    } else if (results) {
      return results.params;
    }
  }, [assessment, results]);

  const gameRoles: Record<string, string> | undefined = useMemo(() => {
    if (gameType && localMeta) {
      return getSimulationRoles(localMeta.gameTypes[gameType], i18n.language);
    }
  }, [localMeta, i18n.language, gameType]);

  useEffect(() => {
    if (itemId) {
      requestAssessmentItemAdmin({ id: itemId });
    }

    if (isFinished && itemId) {
      requestAssessmentResults({ id: itemId });
    }
  }, [itemId, isFinished]);

  useEffect(() => {
    if (
      !isFinished &&
      assessment &&
      simulationLoadingStatus !== ERequestStatus.LOADED
    ) {
      dispatch(requestGetSimulationItemAdmin({ id: assessment.gameId }));
      fetchFormulas({ gameId: assessment.gameId });
    }
  }, [dispatch, assessment, isFinished]);

  useEffect(() => {
    if (!assessment) return;
    if (assessmentDataRef.current?.params && assessment.params) {
      setIsDisabled(false);
      if (
        assessmentDataRef.current?.assessmentType !== assessment.assessmentType
      ) {
        setIsDisabled(true);
        return;
      }
      for (const el of Object.keys(assessment.params)) {
        if (
          el === "turn_durations" &&
          assessment.params.turn_durations.length !==
            (assessmentDataRef.current?.params.turn_durations as number[])
              .length
        ) {
          setIsDisabled(true);
          break;
        }
        if (
          el === "turn_durations" &&
          assessment.params.turn_durations[0] !==
            (assessmentDataRef.current?.params.turn_durations as number[])[0]
        ) {
          setIsDisabled(true);
          break;
        }
        if (
          assessment.params[el] !== assessmentDataRef.current?.params[el] &&
          el !== "turn_durations"
        ) {
          setIsDisabled(true);
          break;
        }
      }
      for (let x = 0; x !== assessment.params.turn_durations.length; x++) {
        if (
          assessment.params.turn_durations[x] !==
          (assessmentDataRef.current?.params.turn_durations as number[])[x]
        ) {
          setIsDisabled(true);
          break;
        }
      }
    }
    if (assessmentDataRef.current) return;
    assessmentDataRef.current = assessment;
  }, [assessment]);

  // const onClose = (): void => {
  //   // if (isFinished) {
  //   //   dispatch(cleanAssessmentResults());
  //   // } else {
  //   //   dispatch(cleanAssessmentItemAdmin());
  //   //   dispatch(cleanSimulationItemAdmin());
  //   // }
  // };
  const fromEntries = (key: string, value: any) => {
    return { [key]: value };
  };
  const onChangeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    paramKey: string,
  ) => {
    if (!localParams) return;
    const inputVal = e.target.value;
    const value = {
      ...fromEntries(paramKey, localParams[paramKey]),
      [paramKey]: +inputVal,
    };

    const newTimeDurations = [...assessment?.params.turn_durations];
    newTimeDurations[currentInput] = +inputVal;

    const newAssessmentData = !Array.isArray(localParams[paramKey])
      ? { ...assessment, params: { ...assessment?.params, ...value } }
      : {
          ...assessment,
          params: {
            ...assessment?.params,
            turn_durations: newTimeDurations,
          },
        };
    const newStateAssessmentData = !Array.isArray(localParams[paramKey])
      ? { ...assessmentData, params: { ...assessmentData.params, ...value } }
      : {
          ...assessmentData,
          params: {
            ...assessmentData.params,
            turn_durations: newTimeDurations,
          },
        };
    setAssessmentData(newStateAssessmentData);
    changeAssessmentItemAdmin(newAssessmentData);
  };

  const onChangeSelectTypeHandler = (payload: IBaseInputChange) => {
    const value = {
      ...fromEntries("assessmentType", payload.value),
    };
    setAssessmentData((prev) => ({
      ...prev,
      ...value,
    }));
    changeAssessmentItemAdmin({
      ...assessment,
      ...value,
    });
  };

  const onAddNewTurnHandler = () => {
    setAssessmentData((prev) => ({
      ...prev,
      params: {
        ...assessmentData?.params,
        turn_durations: [...assessment?.params.turn_durations, 300],
      },
    }));
    changeAssessmentItemAdmin({
      ...assessment,
      params: {
        ...assessment?.params,
        turn_durations: [...assessment?.params.turn_durations, 300],
      },
    });
  };

  const onDeleteHandler = (index: number) => {
    setAssessmentData((prev) => ({
      ...prev,
      params: {
        ...assessmentData?.params,
        turn_durations: [
          ...assessment?.params.turn_durations.filter(
            (_: number, elementIndex: number) => {
              return index !== elementIndex;
            },
          ),
        ],
      },
    }));
    changeAssessmentItemAdmin({
      ...assessment,
      params: {
        ...assessment?.params,
        turn_durations: [
          ...assessment?.params.turn_durations.filter(
            (_: number, elementIndex: number) => {
              return index !== elementIndex;
            },
          ),
        ],
      },
    });
  };

  const onCheckHandler = (param: boolean, keyParam: string) => {
    setAssessmentData((prev) => ({
      ...prev,
      params: {
        ...assessmentData?.params,
        [keyParam]: !param,
      },
    }));
    changeAssessmentItemAdmin({
      ...assessment,
      params: {
        ...assessment?.params,
        [keyParam]: !param,
      },
    });
  };

  const isEditable: boolean =
    !isPlayerClientId && assessment?.assessmentStatus === "lobby";
  const paramsList = () => {
    if (localParams && localMeta && assessment?.gameType) {
      const paramsKeys: string[] = Object.keys(localParams);
      return paramsKeys
        .map((paramKey) => {
          const metaParam: SimulationListPlayerMetaParamsItemInterface =
            localMeta.gameTypes[assessment.gameType].params?.[paramKey] ??
            (localMeta.params as any)[paramKey];

          const title: string = languagePicker(
            localMeta.gameTypes[assessment.gameType]?.params?.[paramKey]
              ?.title ?? localMeta.params?.[paramKey]?.title,
            i18n.language,
          );
          const type = metaParam.type;
          const subType = metaParam.items?.type;
          const className: string = `assessment-parameters-popup__params-item--${type}${
            subType ? "-" + subType : ""
          }`;
          const { isBoolean } = getSimulationParamType({
            param: metaParam,
          });

          return (
            <React.Fragment key={paramKey}>
              <ParamsInfoItem
                className={classNames(
                  "assessment-parameters-popup__params-item",
                  className,
                )}
                onDeleteHandler={onDeleteHandler}
                isEditable={isEditable}
                onChange={(e) => onChangeInputHandler(e, paramKey)}
                title={title}
                param={localParams[paramKey]}
                paramKey={paramKey}
                type={type}
                onAddHandler={onAddNewTurnHandler}
                subType={subType}
                onCheck={onCheckHandler}
                isBoldTitle={!isBoolean}
                inputOnClick={(num) => setCurrentInput(num)}
              />
            </React.Fragment>
          );
        })
        .filter(Boolean);
    }
  };

  const isLoading: boolean =
    assessmentLoadingStatus === ERequestStatus.LOADING ||
    simulationLoadingStatus === ERequestStatus.LOADING ||
    resultsLoadingStatus === ERequestStatus.LOADING ||
    isLoadingCalcs;

  const isLoaded: boolean =
    (assessmentLoadingStatus === ERequestStatus.LOADED &&
      simulationLoadingStatus === ERequestStatus.LOADED) ||
    resultsLoadingStatus === ERequestStatus.LOADED;

  const isError: boolean =
    assessmentLoadingStatus === ERequestStatus.ERROR ||
    simulationLoadingStatus === ERequestStatus.ERROR ||
    resultsLoadingStatus === ERequestStatus.ERROR;

  const isInfo: boolean = (!!assessment && !!simulation) || !!results;

  const gameTypeLabel: string = t(
    `simulationControl.simulationPage.tabLabel.${gameType}`,
  );
  const creatorLabel: string = `${assessmentInfo?.createdByUser?.firstname} ${assessmentInfo?.createdByUser?.lastname}`;
  const startTypeLabel: string = t(
    `assessmentsControl.popup.assessmentParameters.description.startType.${assessmentInfo?.createdBy}`,
  );
  const typeLabel: string = t(`common.assessment.${assessmentType}`);

  const selectValues = [
    { value: "rating", label: t("common.assessment.rating") },
    {
      value: "nonRating",
      label: t("common.assessment.nonRating"),
    },
  ];

  useEffect(() => {
    return () => {
      cleanAssessmentResults();
      cleanAssessmentItemAdmin();
      dispatch(cleanSimulationItemAdmin());
    };
  }, []);
  return (
    <div className="assessment-parameters-popup">
      {isLoading && <BaseLoader />}
      {isInfo && isLoaded && (
        <Fragment>
          <div className="assessment-parameters-popup__item assessment-parameters-popup__copy">
            <ParamsInfoItem
              className="assessment-parameters-popup__copy-info"
              title="ID"
              param={itemId}
              type={ESimulationParamsType.STRING}
              direction="row"
              isNoWrap
              isBoldDescription
            />
            <CopyButton copyValue={itemId || ""} />
          </div>

          <div className="assessment-parameters-popup__list">
            <ParamsInfoItem
              className="assessment-parameters-popup__item assessment-parameters-popup__list-item"
              title={t(
                "assessmentsControl.popup.assessmentParameters.subTitle.type",
              )}
              param={!isEditable ? typeLabel : undefined}
              type={ESimulationParamsType.STRING}
            >
              {isEditable && (
                <BaseSelect
                  hideEmptyItem
                  activeItem={assessmentType}
                  size="small"
                  items={selectValues}
                  onChange={onChangeSelectTypeHandler}
                />
              )}
            </ParamsInfoItem>

            <ParamsInfoItem
              className="assessment-parameters-popup__item assessment-parameters-popup__list-item"
              title={t(
                "assessmentsControl.popup.assessmentParameters.subTitle.startType",
              )}
              param={startTypeLabel}
              type={ESimulationParamsType.STRING}
            />

            {assessmentInfo?.createdByUser && (
              <ParamsInfoItem
                className="assessment-parameters-popup__item assessment-parameters-popup__list-item"
                title={t(
                  "assessmentsControl.popup.assessmentParameters.subTitle.creator",
                )}
                param={creatorLabel}
                type={ESimulationParamsType.STRING}
              />
            )}
          </div>

          <ParamsInfoItem
            className="assessment-parameters-popup__item"
            title={t(
              "assessmentsControl.popup.assessmentParameters.subTitle.gameType",
            )}
            param={gameTypeLabel}
            type={ESimulationParamsType.STRING}
            isBoldDescription
          >
            {!!localFormules.length && (
              <div className="assessment-parameters-popup__formulas">
                <div className="assessment-parameters-popup__formulas-title">
                  <div className="assessment-parameters-popup__formulas-title-item">
                    {t("common.competence")}
                  </div>
                  <div className="assessment-parameters-popup__formulas-title-item">
                    {t(
                      "simulationControl.simulationPage.modesBlock.textareaPlaceholder.formula",
                    )}
                  </div>
                </div>
                {localFormules.map(
                  (item: AdmCalcDto | CalcDto, index: number) => (
                    <CodeBlock
                      key={index}
                      /* TODO: обновить данный компонент */
                      competence={
                        ("competence" in item && item.competence) || ""
                      }
                      formula={item.formula}
                    />
                  ),
                )}
              </div>
            )}
          </ParamsInfoItem>
          {gameRoles?.roles && (
            <ParamsInfoItem
              className="assessment-parameters-popup__item"
              title={t(
                "assessmentsControl.popup.assessmentParameters.subTitle.gameRoles",
              )}
              param={gameRoles?.roles}
              type={ESimulationParamsType.STRING}
            />
          )}
          {gameRoles?.teamRoles && (
            <ParamsInfoItem
              className="assessment-parameters-popup__item"
              title={t(
                "assessmentsControl.popup.assessmentParameters.subTitle.teamRoles",
              )}
              param={gameRoles?.teamRoles}
              type={ESimulationParamsType.STRING}
            />
          )}
          <div className="assessment-parameters-popup__params">
            {paramsList()}
          </div>
        </Fragment>
      )}
      {!isEditable || !isDisabled ? null : (
        <div className="assessment-parameters-popup__send_wrapper">
          <BaseButton
            className="assessment-parameters-popup__button_submit"
            onClick={() => {
              if (!assessmentData.id) return;
              const name = EPopupName.ASSESSMENT_PARAMETERS;
              updateAssessment({
                assessmentId: assessmentData.id,
                updateAssessment: assessmentData,
              });
              modelClosePopup({ name });
            }}
            primary
            disabled={!isDisabled}
            small
          >
            Сохранить изменения
          </BaseButton>
          <BaseButton
            className="assessment-parameters-popup__button_cancel"
            transparentWhite
            disabled={!isDisabled}
            small
            onClick={() => {
              if (assessmentDataRef.current?.params) {
                changeAssessmentItemAdmin({ ...assessmentDataRef.current });
              }
              setAssessmentData({
                id: itemId,
                params: {},
              });
            }}
          >
            <TextWithIcon
              iconName="cross-red"
              label={t("userForm.btn.reset.changes")}
            />
          </BaseButton>
        </div>
      )}
      {isError && <EmptyBlock title={t("alert.requestError")} />}
    </div>
  );
};
