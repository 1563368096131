import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { EPopupName, popupModel } from "src/shared/components/base-popup";
import {
  BaseButton,
  BaseGroup,
  BaseSelect,
  SelectItem,
} from "../../../../../../../shared/components";
import { useUnit } from "effector-react";
import {
  $activePopups,
  openPopup,
} from "../../../../../../../shared/components/base-popup/model";

import "./allocate-player-popup.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  roomLeavePlayer,
  selectRoomName,
} from "../../../../../../../shared/store/ducks/room";
import {
  AssessmentMemberReqDto,
  GameType,
} from "../../../../../../../generated/game";
import _ from "lodash";
import { IBaseInputChange } from "../../../../../../../shared/models";
import {
  Specialties,
  Teamnames,
} from "../../../../../../../shared/store/types";
import SessionSimulationTitle from "../../../../../../../shared/components/session-simulation-title/session-simulation-title";
import { snackbarOpen } from "src/features/public/snackbar";
import sessionSimulationImg from "../../../../../../../shared/images/session-simulation.png";
import {
  $socketGameV4LobbyHandler,
  $socketGameV4RoomHrCommander,
  $socketGameV4RoomHrHandler,
} from "../../../../../../../shared/api/public/sockets/model/v4";
import useClient from "../../../../../../../shared/hooks/useClient";
import { LobbyChanged, Quit } from "../../../../../../../generated/ws4";
import { ITeam } from "../../session-table";
import { allocateInvitedPlayers } from "src/entities/hr/assessment-invite/model/invited-players";
import { getAssessmentRequest } from "../../../../../../../shared/api/hr/assessments";
import {
  $updatedLobbyPlayers,
  setUpdatedLobbyPlayers,
} from "./lib/lobbyPlayerList";

const { closePopup } = popupModel;

export const AllocatePlayerPopup = (): ReactElement => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const room = useSelector(selectRoomName);

  const currentPopup = activePopups.find(
    ({ name }) => name === EPopupName.ALLOCATE_PLAYER_POPUP,
  );

  const TeamGameType = room.gameType === GameType.Team;

  const SoloGameType = room.gameType === GameType.Solo;

  const { isHrClientId } = useClient();

  const socketHrRoom = useUnit($socketGameV4RoomHrHandler);

  const [updatedCurrentId, setUpdatedCurrentId] = useState("");

  const socketLobbyHandler = useUnit($socketGameV4LobbyHandler);

  const [role, setRole] = useState<IBaseInputChange>({
    value: "",
  });

  const [num, setNum] = useState<IBaseInputChange>({
    value: "",
  });

  const [areaNum, setAreaNum] = useState<IBaseInputChange>({
    value: "",
  });

  const updatedLobbyPlayers = useUnit($updatedLobbyPlayers);

  const dispatch = useDispatch();

  const includesExceptions = TeamGameType || SoloGameType;

  const gameNum = includesExceptions ? "teamNum" : "corpNum";

  const teamsList: ITeam[] = currentPopup?.data?.teams;

  const socketHrRoomCommander = useUnit($socketGameV4RoomHrCommander);

  let selectItemAreaNum: SelectItem[] = [];

  const CorpGameType = room.gameType === GameType.Corp;

  const selectItems = TeamGameType || CorpGameType ? Specialties : Teamnames;

  const selectItemRoles: SelectItem[] = Object.values(selectItems).map(
    (speciality) => {
      return {
        label: t(`playerRoles.${speciality}`),
        value: speciality,
      };
    },
  );

  const onRoomLeavePlayer = useCallback(
    (data: Quit) => {
      dispatch(roomLeavePlayer(data));
      setUpdatedCurrentId(data.pid);
    },
    [dispatch],
  );

  const onChangeLobby = useCallback(async (data: LobbyChanged) => {
    const response = await getAssessmentRequest({ assessmentId: data.aid });
    setUpdatedLobbyPlayers(response.assessment.players);
  }, []);

  useEffect(() => {
    if (currentPopup) {
      if (currentPopup.data.player.role) {
        setRole({ value: currentPopup.data.player.role });
      }
      if (currentPopup.data.player[gameNum]) {
        setNum({ value: String(currentPopup.data.player[gameNum]) });
      }
      if (currentPopup.data.player.areaNum) {
        setAreaNum({ value: String(currentPopup.data.player.areaNum) });
      }
      if (isHrClientId) {
        socketHrRoom?.onQuit(onRoomLeavePlayer);
        socketLobbyHandler?.onChange(onChangeLobby);
      }
    }
  }, [
    currentPopup,
    socketHrRoom,
    onRoomLeavePlayer,
    gameNum,
    isHrClientId,
    socketLobbyHandler,
    onChangeLobby,
  ]);

  const selectItemNum: SelectItem[] = useMemo(
    () =>
      teamsList?.map((team) => ({
        label: `${t(`playerRoles.${team.teamName?.value}`)} №${
          team.corpNum ?? team.teamNum
        }`,
        value: String(team.teamNum ?? team.corpNum),
      })),
    [teamsList],
  );

  if (room.maxAreas) {
    _.range(1, room.maxAreas + 1).map((areaNum) => {
      return selectItemAreaNum.push({
        label: `${t("common.area")} №${areaNum}`,
        value: `${areaNum}`,
      });
    });
  }

  const allocatePlayer = (
    role: string,
    num: number,
    areaNum: number,
    userId: string,
  ) => {
    if (userId === updatedCurrentId) return null;

    const team = teamsList?.find((team) => team[gameNum] === num);

    const areaNumToRequest =
      role === Specialties.CEO || includesExceptions ? null : areaNum;

    if (room.assessmentId) {
      socketHrRoomCommander?.distribute({
        aid: room.assessmentId,
        pid: userId,
      });
      socketHrRoomCommander?.role({
        aid: room.assessmentId,
        pid: userId,
        role: role,
        [gameNum]: num,
        teamName: team?.teamName?.value,
        areaNum: areaNumToRequest,
      });
    }
    snackbarOpen({
      text: t("allocate-player.snackbar.title"),
      type: "success",
      visible: true,
    });
  };

  let disableJoinButtonFlag = !role.value || !num.value || !areaNum.value;

  if (role.value === Specialties.CEO || TeamGameType) {
    disableJoinButtonFlag = !role.value || !num.value;
  } else if (SoloGameType) {
    disableJoinButtonFlag = !role.value;
  }

  const distributeConnectedPlayer = () => {
    allocatePlayer(
      role.value,
      Number(num.value),
      Number(areaNum.value),
      currentPopup?.data.player.pid,
    );
    closePopup({ name: EPopupName.ALLOCATE_PLAYER_POPUP });
  };
  const distributeInvitedPlayer = () => {
    const player: AssessmentMemberReqDto = currentPopup?.data?.player;

    const team = teamsList.find((team) => team[gameNum] === Number(num.value));

    const areaNumToRequest =
      role.value === Specialties.CEO || includesExceptions
        ? undefined
        : Number(areaNum.value);

    const playerInLobbyExist = updatedLobbyPlayers.some(
      (playerId) => playerId.pid === player.playerId,
    );

    if (!playerInLobbyExist) {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.errorMessage.lobbyNotFound"),
          isError: true,
        },
      });
      return closePopup({ name: EPopupName.ALLOCATE_PLAYER_POPUP });
    }

    allocateInvitedPlayers({
      assessmentId: room.assessmentId ?? "",
      members: {
        ...player,
        role: role.value,
        teamName: team?.teamName?.value,
        [gameNum]: Number(num.value),
        areaNum: areaNumToRequest,
        isDistributed: true,
      },
    });

    socketHrRoomCommander?.role({
      aid: room.assessmentId || "",
      pid: player.playerId,
      role: role.value,
      [gameNum]: Number(num.value),
      teamName: team?.teamName?.value,
      areaNum: areaNumToRequest,
    });

    snackbarOpen({
      text: t("allocate-player.snackbar.title"),
      type: "success",
      visible: true,
    });

    closePopup({ name: EPopupName.ALLOCATE_PLAYER_POPUP });
  };

  const roleSelectOptions =
    TeamGameType || SoloGameType
      ? selectItemRoles.filter((role) => role.value !== Specialties.CEO)
      : selectItemRoles;
  const currentPlayer = currentPopup?.data.player;
  const isButtonDisabled =
    currentPlayer &&
    currentPlayer.role === role.value &&
    Number(currentPlayer[gameNum]) === Number(num.value) &&
    (!currentPlayer.areaNum ||
      Number(currentPlayer.areaNum) === Number(areaNum.value));

  return (
    <div className="allocate-popup">
      <div className="allocate-popup__title">
        <div className="allocate-popup__title-player-info">
          <div className="allocate-popup__title-avatar">
            <img alt={"logo"} src={sessionSimulationImg}></img>
            <span>{room.title}</span>
          </div>
          <SessionSimulationTitle
            titleType={
              room.gameType === GameType.Solo ? GameType.Solo : "Distribute"
            }
          />
        </div>
      </div>
      <div className="allocate-popup__content">
        <BaseSelect
          placeholder={t("other.roleForm.chooseRole")}
          activeItem={role.value}
          items={roleSelectOptions}
          onChange={(e) => {
            setRole(e);
          }}
        />
        <BaseSelect
          placeholder={t("other.roleForm.chooseTeam")}
          activeItem={num.value}
          items={selectItemNum}
          hidden={SoloGameType}
          onChange={(e) => {
            setNum(e);
          }}
        />
        <BaseSelect
          placeholder={t("other.roleForm.chooseCity")}
          activeItem={areaNum.value}
          items={selectItemAreaNum}
          hidden={role.value === Specialties.CEO || includesExceptions}
          onChange={(e) => {
            setAreaNum(e);
          }}
        />
      </div>
      <div className="allocate-popup__buttons">
        <BaseGroup
          left={
            <BaseButton
              secondary
              block
              small
              onClick={() => {
                closePopup({ name: EPopupName.ALLOCATE_PLAYER_POPUP });
              }}
            >
              {t("common.cancel")}
            </BaseButton>
          }
          right={
            <BaseButton
              primary
              block
              small
              disabled={isButtonDisabled || disableJoinButtonFlag}
              onClick={
                currentPopup?.data?.distributeInvitedPlayer
                  ? distributeInvitedPlayer
                  : distributeConnectedPlayer
              }
            >
              {t("allocate-player.popup.button.distribute")}
            </BaseButton>
          }
        />
      </div>
    </div>
  );
};
