import { gameService } from "src/shared/api/base/api-instances";

import { AssessmentApiGetAssessmentRequest } from "src/generated/game";

export const getAssessmentRequest = (
  params: AssessmentApiGetAssessmentRequest,
) => {
  return gameService
    .makeAssessmentsRequest()
    .getAssessment(params)
    .then((res) => res.data);
};
