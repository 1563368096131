import classNames from "classnames";
import { FC, ReactElement, ReactNode } from "react";
import { useUnit } from "effector-react";
import { $appInterface } from "src/features/public/app-interface/model";
import styles from "./new-base-panel.module.scss";

interface Props {
  className?: string;
  children?: ReactNode;
}

export const NewBasePanel: FC<Props> = ({
  children,
  className,
}): ReactElement => {
  const { isHeaderShowed } = useUnit($appInterface);

  return (
    <div className={classNames(styles.basePanel, className)}>
      <div
        className={classNames(styles.children, className, {
          [styles.headerHidden]: !isHeaderShowed,
        })}
      >
        {children}
      </div>
    </div>
  );
};
