import axios from "axios";

import {
  AccountApi,
  Configuration,
  UserApi,
  UsersApi,
} from "src/generated/keycloak";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_KEYCLOAK_URL;

    config.headers = {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const makeAccountRequest = () => {
  return new AccountApi(new Configuration(), "", apiInstance);
};

const makeUsersRequest = () => {
  return new UsersApi(new Configuration(), "", apiInstance);
};

const makeUserRequest = () => {
  return new UserApi(new Configuration(), "", apiInstance);
};

export const keycloakService = {
  makeAccountRequest,
  makeUsersRequest,
  makeUserRequest,
};
