import { ERegEx, regExList } from "../constants/regExList";
import { ISimulationAdmin } from "../../entities/admin/simulations";
import { ISimulationUrls } from "../store/types";

const cutCurrentUrl = (url: string) => {
  return url?.replace(regExList[ERegEx.VALID_SIMULATION_URL], "") ?? url;
};

export const getFormattedUrl = (
  key: keyof ISimulationUrls,
  simulation: ISimulationAdmin,
) => {
  return cutCurrentUrl(simulation?.rawMetaJson.game_urls?.[key]?.en ?? "");
};
