import { useTranslation } from "react-i18next";
import { EDesiredStudyPlace } from "src/shared/components";
import { getActiveGroup, getOrderByValue } from "src/shared/helpers";
import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  TDataGridDataParams,
} from "src/shared/models";

export const useGenerateParticipantsColumns = () => {
  const { t } = useTranslation();

  const desiredStudyPlaceOptions = Object.values(EDesiredStudyPlace).map(
    (place) => ({
      label: t(`userForm.select.desiredStudyPlace.${place}`),
      value: place,
    }),
  );

  const resumeOptions = [
    {
      label: t("common.have"),
      value: "true",
    },
    {
      label: t("common.dontHave"),
      value: "false",
    },
  ];

  return (queryParams?: TDataGridDataParams): IDataGridColumn[] => [
    {
      title: "SELECT",
      type: EDataGridColumnType.SELECT_ROW,
      key: "selected",
      showed: true,
      hiddenTitle: true,
      sticky: {
        mobile: {
          left: 0,
        },
        tablet: {
          left: 0,
        },
        laptop: {
          left: 0,
        },
      },
    },
    {
      title: t("table.header.name"),
      type: EDataGridColumnType.AVATAR,
      key: "fullName",
      showed: true,
      nowrap: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.header.player"),
        activeGroupKey: getActiveGroup(
          ["firstName", "lastName"],
          { all: ["firstName", "lastName"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "firstName",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.name"),
                value: queryParams?.firstName || "",
              },
              {
                key: "lastName",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.lastName"),
                value: queryParams?.lastName || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "firstName",
                "-firstName",
                "lastName",
                "-lastName",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc.name"),
                  value: "firstName",
                },
                {
                  title: t("table.filters.alphabet.desc.name"),
                  value: "-firstName",
                },
                {
                  title: t("table.filters.alphabet.asc.surname"),
                  value: "lastName",
                },
                {
                  title: t("table.filters.alphabet.desc.surname"),
                  value: "-lastName",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.phone"),
      type: EDataGridColumnType.PHONE,
      key: "phoneNumber",
      showed: true,
      controlPanel: {
        title: t("table.header.phone"),
        activeGroupKey: getActiveGroup(
          ["phoneNumber"],
          { all: ["phoneNumber"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "phoneNumber",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.phoneNumber || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.email"),
      type: EDataGridColumnType.EMAIL,
      key: "email",
      showed: true,
      controlPanel: {
        title: t("table.header.email"),
        activeGroupKey: getActiveGroup(
          ["email"],
          { all: ["email"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "email",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.email || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.city"),
      type: EDataGridColumnType.STRING,
      key: "locality",
      showed: true,
      filterTitle: t("table.header.city"),
      controlPanel: {
        title: t("table.header.city"),
        activeGroupKey: getActiveGroup(
          ["locality"],
          { all: ["locality"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "locality",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.locality || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.desiredStudyPlace"),
      type: EDataGridColumnType.STRING,
      key: "desiredStudyPlace",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.desiredStudyPlace"),
        activeGroupKey: getActiveGroup(
          ["desiredStudyPlace"],
          { all: ["desiredStudyPlace"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "desiredStudyPlace",
                type: EDataGridFilterType.CHECKBOX,
                items: desiredStudyPlaceOptions,
                placeholder: "Не выбрано",
                value: queryParams?.desiredStudyPlace || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.resume"),
      type: EDataGridColumnType.RESUME,
      key: "resume",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.resume"),
        activeGroupKey: getActiveGroup(
          ["resume"],
          { all: ["resume"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "resume",
                type: EDataGridFilterType.CHECKBOX,
                items: resumeOptions,
                placeholder: "Не выбрано",
                value: queryParams?.resume || "",
                onlyOneActiveOption: true,
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.activity"),
      type: EDataGridColumnType.DATETIME,
      key: "activity",
      showed: true,
    },
  ];
};
