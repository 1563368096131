export const fullscreenElement = (elem: string) => {
  const element: HTMLElement | null = document.querySelector(`.${elem}`);
  if (element) {
    if (!document.fullscreenElement) {
      element.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};
