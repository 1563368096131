import { sample } from "effector";

import { queryParamsToGetPlayersRatingAdapter } from "../lib/queryParamsToGetPlayersRatingAdapter";

import { $pagination, $players, $queryParams, playersDomain } from "./players";

import { getPlayers, QueryParams } from "src/shared/api/admin/players";

// Events

export const readmorePlayers = playersDomain.createEvent<QueryParams>();

// Effects

export const readmorePlayersFx = playersDomain.createEffect(getPlayers);

// Logic

sample({
  clock: [readmorePlayers],
  fn: queryParamsToGetPlayersRatingAdapter,
  target: readmorePlayersFx,
});

sample({
  clock: readmorePlayers,
  target: $queryParams,
});

sample({
  clock: readmorePlayersFx.doneData,
  source: $players,
  fn: (players, data) => [...players, ...data.ratings!],
  target: $players,
});

sample({
  clock: readmorePlayersFx.doneData,
  fn: (data) => data.pagination || null,
  target: $pagination,
});
