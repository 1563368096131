import { sample } from "effector";

import {
  $queryParams,
  getSmsListFx,
  smsNotificationDomain,
} from "./sms-notification";

import { queryParamsToGetSmsListAdapter } from "../lib";

// Event

export const refreshSmsList = smsNotificationDomain.createEvent();

// Logic

sample({
  clock: refreshSmsList,
  source: $queryParams,
  fn: queryParamsToGetSmsListAdapter,
  target: getSmsListFx,
});
