import { sample } from "effector";

import {
  getUserSettings,
  GetUserSettingsParams,
} from "src/shared/api/public/user-settings";

import { userSettingsDomain } from "./domain";

import { IUserSettings } from "./types";

// Stores

export const $userSettings =
  userSettingsDomain.createStore<IUserSettings | null>(null);

export const $isLoading = userSettingsDomain.createStore<boolean>(false);

export const $is404Data = userSettingsDomain.createStore<boolean>(false);

// Events

export const fetchUserSettings =
  userSettingsDomain.createEvent<GetUserSettingsParams>();

// Effects

export const fetchUserSettingsFx =
  userSettingsDomain.createEffect(getUserSettings);

// Logic

sample({ clock: fetchUserSettings, target: fetchUserSettingsFx });

sample({ clock: fetchUserSettingsFx.pending, target: $isLoading });

sample({
  clock: fetchUserSettingsFx.doneData,
  fn: ({ payload }) => (payload || {}) as IUserSettings,
  target: $userSettings,
});

sample({
  clock: fetchUserSettingsFx.failData,
  fn: (data: any) => data.response.status === 404,
  target: $is404Data,
});
