import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "src/shared/components/index";

import useClient from "src/shared/hooks/useClient";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { PlayerComplaint } from "../../../../generated/social";
import {
  $subjectUser,
  requestGetUserInfoAdmin,
} from "../../../../pages/users-control/user-info-page/lib/user-info-page";
import { useParams } from "react-router";
import { useUnit } from "effector-react";

interface Props {
  complaintItems: PlayerComplaint[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const ComplaintsHistory: FC<Props> = ({
  loading,
  complaintItems,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isPlayerClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullName",
        showed: !isPlayerClientId,
        titleUppercase: true,
        title: t("table.header.admin"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
      },
      {
        key: "simulationType",
        showed: true,
        titleUppercase: true,
        title: t("table.header.simulation"),
        type: EDataGridColumnType.STRING,
      },
      {
        key: "assessmentTitle",
        showed: true,
        titleUppercase: true,
        title: t("table.header.session.name"),
        type: EDataGridColumnType.STRING,
        controlPanel: {
          title: t("table.header.session.name"),
          activeGroupKey: getActiveGroup(
            ["assTitle"],
            { all: ["assTitle"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "assTitle",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.assTitle || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "ass_title",
                  "-ass_title",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.short.name"),
                    value: "ass_title",
                  },
                  {
                    title: t("table.filters.alphabet.desc.short.name"),
                    value: "-ass_title",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "complaintDate",
        showed: true,
        titleUppercase: true,
        title: t("common.date"),
        type: EDataGridColumnType.DATETIME,
        controlPanel: {
          title: t("common.date"),
          activeGroupKey: getActiveGroup(
            ["complaintDate", "fromDateOfCreate", "toDateOfCreate"],
            {
              all: ["complaintDate", "fromDateOfCreate", "toDateOfCreate"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "fromDateOfCreate",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.fromDateOfCreate || "",
                  isHalf: true,
                },
                {
                  key: "toDateOfCreate",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.toDateOfCreate || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "date_of_create",
                  "-date_of_create",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "date_of_create",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-date_of_create",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "complaintMsg",
        showed: true,
        titleUppercase: true,
        title: t("table.header.complaint.message"),
        type: EDataGridColumnType.STRING,
      },
    ];

    return newColumns;
  }, [isPlayerClientId, queryParams]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = complaintItems.map(
      (item): IDataGridRow => {
        const fullName = splitStrings([
          item?.admin_firstname,
          item?.admin_lastname,
        ]);

        return {
          id: item.id,
          complaintDate: item.date_of_create,
          selected: false,
          fullName,
          avatarProps: {
            alt: fullName,
            fullName,
            customPath: getAvatarUrl(item.admin_id),
            userId: item.id,
          },
          simulationType: item.gTitle,
          assessmentTitle: item.ass_title,
          complaintMsg: item.msg_text,
        };
      },
    );

    setRows(rowsItems);
  }, [complaintItems, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"session-history-table"}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
