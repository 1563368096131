import {
  gameService,
  socialService,
  statisticService,
} from "../../../base/api-instances";
import {
  AssessmentApiGetPAssessmentsRequest,
  AssessmentApiGetPRolesRequest,
} from "src/generated/statistic";
import { PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest } from "src/generated/game";
import {
  PlayerFineHistoryApiGetPlayerFineHistoryRequest,
  PlayersApiGetPlayerComplaintsRequest,
} from "src/generated/social";

export const getTutorialHistory = (
  params: PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest,
) => {
  return gameService
    .makeTutorialHistoryRequest()
    .getPlayerTutorialHistory(params)
    .then((response) => response.data);
};

export const getSessionsHistory = (
  params: AssessmentApiGetPAssessmentsRequest,
) => {
  return statisticService
    .makeAssessmentsRequest()
    .getPAssessments(params)
    .then((response) => response.data);
};

export const getFinesHistory = (
  params: PlayerFineHistoryApiGetPlayerFineHistoryRequest,
) => {
  return socialService
    .makeFineHistoryRequest()
    .getPlayerFineHistory(params)
    .then((response) => response.data);
};

export const getPlayerRoles = (params: AssessmentApiGetPRolesRequest) => {
  return statisticService
    .makeAssessmentsRequest()
    .getPRoles(params)
    .then((response) => response.data);
};

export const getComplaintsHistory = (
  params: PlayersApiGetPlayerComplaintsRequest,
) => {
  return socialService
    .makePlayerRequest()
    .getPlayerComplaints(params)
    .then((res) => res.data);
};
