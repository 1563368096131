import { FC, ReactNode } from "react";

import { format, isToday, isYesterday } from "date-fns";

import { enGB, ru } from "date-fns/locale";

import { t } from "src/shared/helpers";

import "./base-card-wrapper.scss";
import { useTranslation } from "react-i18next";

export type Props = {
  cardTitle?: string;
  logoUrl?: string;
  whenDate: string;
  children?: ReactNode;
};

const isValidDate = (date: Date | number): boolean => {
  return date instanceof Date;
};

const formatWhenDate = (dateStr: string, language: string): string => {
  const date = new Date(dateStr);

  if (!isValidDate(date)) return "";

  const formattedTime = format(date, "HH:mm");

  if (isToday(date))
    return `${t("notifications.panel.card.whenDate.today")} ${formattedTime}`;

  if (isYesterday(date))
    return `${t(
      "notifications.panel.card.whenDate.yesterday",
    )} ${formattedTime}`;

  const locale = language === "ru" ? ru : enGB;

  return `${format(date, "dd MMMM", { locale })} ${t(
    "notifications.panel.card.whenDate.at",
  )} ${formattedTime}`;
};

export const BaseCardWrapper: FC<Props> = ({
  cardTitle,
  logoUrl,
  whenDate,
  children,
}) => {
  const { i18n } = useTranslation();

  const formattedWhenDate = formatWhenDate(whenDate, i18n.language);

  return (
    <div className="base-card-wrapper">
      <strong className="base-card-wrapper__title">{cardTitle}</strong>
      <div className="base-card-wrapper__content card-content">
        <img
          className="card-content__image"
          src={logoUrl}
          alt={`${cardTitle ?? ""} card-logo`}
        />
        <div className="card-content__right-side">
          {children}
          {formattedWhenDate && (
            <p className="base-card-when-date">{formattedWhenDate}</p>
          )}
        </div>
      </div>
    </div>
  );
};
