import { UserInfoInterface } from "src/shared/store/types";
import axios from "axios";

export const patchUpdateUserPromise = async (payload: {
  id: string;
  user: "hr" | "user";
}): Promise<UserInfoInterface> => {
  const { data } = await axios.patch(
    `${process.env.REACT_APP_KC_URL}/realms/${process.env.REACT_APP_KC_REALM}/accountEx/user-role/${payload.id}/${payload.user}`,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("alabuga-token"),
      },
    },
  );

  return data;
};
