import { combine, sample } from "effector";

import { QueryParams } from "src/shared/api/base";

import { $countries, fetchCountries } from "src/entities/public/countries";

import { $regions, fetchRegions } from "src/entities/public/regions";

import { $districts, fetchDistricts } from "src/entities/public/districts";

import { $schools, fetchSchools } from "src/entities/public/schools";

import { IDataGridColumn, IDataGridRow } from "src/shared/models/dataGrid";

import { $currentLanguage } from "src/shared/helpers";

import { playersToRowsAdapter } from "../lib";

import {
  $isLoading as $isLoadingPlayers,
  $players,
  $queryParams,
  playersDomain,
} from "./players";
import { generateColumns } from "../lib/generateColumns";

// Domains

const dataGrid = playersDomain.createDomain();

// Stores

export const $rows = dataGrid.createStore<IDataGridRow[]>([]);
export const $columns = dataGrid.createStore<IDataGridColumn[]>([]);

export const $isLoading = $isLoadingPlayers.map(Boolean);

export const $places = combine({
  countries: $countries,
  regions: $regions,
  districts: $districts,
  schools: $schools,
});

// Events

export const fetchPlaces = dataGrid.createEvent<QueryParams>();

// Logic

sample({
  clock: $players,
  fn: (players) => playersToRowsAdapter(players),
  target: $rows,
});

sample({
  clock: combine(
    $queryParams,
    $currentLanguage,
    $places,
    (queryParams, _, places) => generateColumns(queryParams, places),
  ),
  target: $columns,
});

sample({
  clock: fetchPlaces,
  target: [fetchCountries, fetchRegions, fetchDistricts, fetchSchools],
});
