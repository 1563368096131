import React from "react";
import { ConnectDropTarget, DropTargetMonitor, useDrop } from "react-dnd";
import { DragItem, DropArea } from "../../constants/dragEnums";
import { useUnit } from "effector-react";
import { $socketGameV4RoomHrCommander } from "../../api/public/sockets/model/v4";
import { useSelector } from "react-redux";
import { selectRoomName } from "../../store/ducks/room";
import { IPlayer } from "../../../pages/sessions/session-page/components/session-table/session-table";
import { Specialties } from "../../store/types";

interface DropContainerProps {
  acceptType?: DragItem;
  dropData?: any;
  dropArea: DropArea;
  element: (dropProps: {
    canDrop: boolean;
    isOver: boolean;
    dropRef: ConnectDropTarget;
    didDrop: boolean;
  }) => React.ReactElement;
}

export const DropContainer: React.FC<DropContainerProps> = ({
  acceptType = DragItem.PLAYER,
  element,
  dropData,
  dropArea,
}) => {
  const socketHrRoomCommander = useUnit($socketGameV4RoomHrCommander);
  const room = useSelector(selectRoomName);
  const [{ canDrop, isOver, didDrop }, dropRef] = useDrop({
    accept: acceptType,
    drop: (data) => {
      if (dropArea === DropArea.CITY) {
        const player = data as IPlayer;
        if (room.assessmentId) {
          if (!player.isDistributed) {
            socketHrRoomCommander?.distribute({
              aid: room.assessmentId,
              pid: player.pid,
            });
          }
          socketHrRoomCommander?.role({
            aid: room.assessmentId,
            pid: player.pid,
            role:
              player.role && player.role !== Specialties.CEO
                ? player.role
                : Specialties.CAPTAIN,
            corpNum: dropData.corpNum,
            areaNum: dropData.areaNum,
            teamName: dropData.teamName,
          });
        }
      }
      if (dropArea === DropArea.TEAM) {
        const player = data as IPlayer;

        const gameNum = dropData.isTeam ? "teamNum" : "corpNum";

        if (room.assessmentId) {
          if (!player.isDistributed) {
            socketHrRoomCommander?.distribute({
              aid: room.assessmentId,
              pid: player.pid,
            });
          }
          socketHrRoomCommander?.role({
            aid: room.assessmentId,
            pid: player.pid,
            role: player.role ? player.role : Specialties.CAPTAIN,
            [gameNum]: dropData[gameNum],
            areaNum: !dropData.isTeam
              ? player.areaNum
                ? player.areaNum
                : 1
              : null,
            teamName: dropData.teamName,
          });
        }
      }
      if (dropArea === DropArea.UNASSIGNED_PLAYERS) {
        const player = data as IPlayer;
        if (room.assessmentId && player.isDistributed) {
          socketHrRoomCommander?.distribute({
            aid: room.assessmentId,
            pid: player.pid,
          });
        }
      }
      if (dropArea === DropArea.CEO) {
        const player = data as IPlayer;
        if (room.assessmentId) {
          socketHrRoomCommander?.distribute({
            aid: room.assessmentId,
            pid: player.pid,
          });
          socketHrRoomCommander?.role({
            aid: room.assessmentId,
            pid: player.pid,
            role: Specialties.CEO,
            corpNum: dropData.corpNum,
            teamName: dropData.teamName,
          });
        }
      }
    },
    collect: (monitor: DropTargetMonitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        didDrop: monitor.didDrop(),
      };
    },
  });

  return element({ canDrop, isOver, dropRef, didDrop });
};
