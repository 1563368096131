import { FC, ReactElement, useState } from "react";

import { PageWrapper } from "src/shared/components";
import {
  BaseDatePicker,
  BaseInputEmail,
  BaseInputNumber,
  BaseInputPhone,
  BaseInputText,
  BaseSelect,
} from "../../shared/components/form-fields";

import { IBaseInputChange } from "../../shared/models/base-input";

import "./ui-kit-page.scss";

import {
  BaseButton,
  BaseError,
  InputPassword,
  NavList,
} from "../../shared/components";
import { SelectItem } from "../../shared/components/form-fields/base-select/base-select";

export interface NavListItem {
  title: string;
  to: string;
  access: boolean;
}

interface Props {}

export const UiKitPage: FC<Props> = (): ReactElement => {
  const links: NavListItem[] = [
    {
      title: "Пункт 1",
      to: "/aaa",
      access: true,
    },
    {
      title: "Пункт 2",
      to: "/bbb",
      access: true,
    },
  ];

  const selectItems: SelectItem[] = [
    {
      label: "Москва",
      value: "moskow",
    },
    {
      label: "Воронеж",
      value: "Voronesh",
    },
    {
      label: "Саратов",
      value: "saratov",
    },
    {
      label: "Санкт-петербург",
      value: "spb",
    },
    {
      label: "Калининград",
      value: "kaliningrad",
    },
    {
      label: "Владивосток",
      value: "vladivostok",
    },
    {
      label: "Махачкала",
      value: "mahachkala",
    },
    {
      label: "Киев",
      value: "kiev",
    },
  ];

  const [activeSelectItem, setActiveSelectItem] = useState<string>("");
  const [activeDate, setActiveDate] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const onChangeHandlerText = (payload: IBaseInputChange) => {
    setText(payload.value);
  };

  const onChangeHandlerPhone = (payload: IBaseInputChange): void => {
    setPhone(payload.value);
  };

  const onChangeHandlerNumber = (payload: IBaseInputChange): void => {
    setNumber(payload.value);
  };

  const onChangeHandlerPassword = (password: string): void => {
    console.log("InputPassword ->", password);
  };

  const onChangeHandlerSelect = (payload: IBaseInputChange) => {
    setActiveSelectItem(payload.value);
  };

  const onChangeHandlerDate = (payload: IBaseInputChange) => {
    setActiveDate(payload.value);
  };

  const onChangeHandlerEmail = (payload: IBaseInputChange) => {
    setEmail(payload.value);
  };

  return (
    <PageWrapper title="UI-Kit" emptyPanel>
      <div className="ui-kit">
        <p className="ui-kit__title">1. Button</p>
        <div className="ui-kit__block">
          <BaseButton>
            <span className="button__icon button__icon--pen-blue" />
            <span className="button__link button__link--blue">Изменить</span>
          </BaseButton>
          <br />
          <BaseButton>
            <span className="button__icon button__icon--trash-red" />
            <span className="button__link button__link--red">Удалить</span>
          </BaseButton>
          <br />
          <BaseButton small primary>
            small primary
          </BaseButton>
          <br />
          <BaseButton large primary>
            large primary
          </BaseButton>
          <br />
          <BaseButton small outline>
            small primary
          </BaseButton>
          <br />
          <BaseButton large outline>
            large primary
          </BaseButton>
          <br />
          <BaseButton small secondary>
            small primary
          </BaseButton>
          <br />
          <BaseButton large secondary>
            large primary
          </BaseButton>
          <BaseButton block small primary>
            small primary
          </BaseButton>
          <BaseButton block large primary>
            large primary
          </BaseButton>
          <BaseButton small primary>
            <span className="button__icon button__icon--play" />
            Удалить
          </BaseButton>
        </div>
        <p className="ui-kit__title">2. ButtonGroup</p>
        <div className="ui-kit__block">...</div>
        <p className="ui-kit__title">3. NavList</p>
        <div className="ui-kit__block ui-kit__block--darken">
          <NavList level={1} links={links} />
          <NavList level={2} links={links} />
          <NavList level={3} links={links} />
        </div>
        <p className="ui-kit__title">4. Input</p>
        <div className="ui-kit__block">
          <BaseInputText
            value={text}
            onChange={onChangeHandlerText}
            placeholder={"Текстовое поле"}
            name="text"
          />
          <BaseInputPhone
            value={phone}
            onChange={onChangeHandlerPhone}
            placeholder={"Поле с телефоном"}
            name="phone"
          />
          <BaseDatePicker
            name="birthdate"
            placeholder={"Дата"}
            onChange={onChangeHandlerDate}
            value={activeDate}
          />
          <BaseInputEmail
            name="email"
            value={email}
            onChange={onChangeHandlerEmail}
            placeholder={"userForm.inputLabel.email"}
          />
          <BaseInputNumber
            placeholder={"Числовое поле"}
            value={number}
            onChange={onChangeHandlerNumber}
            name="input-number"
          />
          <InputPassword
            value={"Some_password123"}
            placeholder={"Введите пароль"}
            onChange={onChangeHandlerPassword}
            error={false}
          />
          <BaseSelect
            placeholder={"Выберите значение"}
            items={selectItems}
            onChange={onChangeHandlerSelect}
            activeItem={activeSelectItem}
            className="ui-kit__select"
          />
        </div>
        <p className="ui-kit__title">5. BaseError</p>
        <div className="ui-kit__block">
          <BaseError type="danger" text="Недопустимое значение поля" />
          <BaseError type="warning" text="Не подтверждён" />
          <BaseError type="info" text="Какой-то текст" />
        </div>
      </div>
    </PageWrapper>
  );
};
