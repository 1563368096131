import axios from "axios";

export const getProfileInfo = async (payload: {
  url: string;
  token: string | null;
}) => {
  const { data } = await axios.get(payload.url, {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + payload.token,
    },
  });

  return data;
};
