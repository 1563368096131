import {
  FC,
  FormEvent,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./simulation-template-popup.scss";
import { BaseButton, FormControl, MinMaxCountField } from "../..";
import {
  IGameType,
  ISimulationTemplate,
  SimulationListPlayerMetaInterface,
} from "../../../store/types";
import {
  SimulationParamsFields,
  TTemplateParams,
} from "../../simulation-params-fields/simulation-params-fields";
import { BaseInputNumber, BaseInputText } from "../../form-fields";
import {
  EMinMaxPlayersByRole,
  getMinMaxPlayersGameTypeByRole,
  getSimulationParamsErrors,
  getSimulationParamsFieldItem,
  TMinMaxPlayersGameTypeByRoles,
  toCloneObject,
} from "../../../helpers";
import { v4 as generateUniqId } from "uuid";
import { GameType } from "../../../../generated/game";
import { IBaseInputChange } from "../../../models";
import useInput, {
  EInputValidateType,
  IValidateValue,
} from "../../../hooks/useInput";
import { useTranslation } from "react-i18next";
import { useErrorScroll } from "../../../hooks/useErrorScroll";
import {
  BasePopup,
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";
import { useUnit } from "effector-react";

interface Props {
  templates: ISimulationTemplate[];
  meta: SimulationListPlayerMetaInterface;
  selectedGameType: GameType;
  onSubmit: (template: ISimulationTemplate) => void;
}

type TChangingFormDataKeys = "titleRu" | "titleEn" | "minRate";

interface ILocalTemplate {
  id: string;
  titleRu: string;
  titleEn: string;
  minRate: string;
  maxRate: string;
  gameType: GameType;
  maxAreas?: number;
  minAreas?: number;
  params: TTemplateParams;
  isDefault?: boolean;
}

const { $activePopups, closePopup } = popupModel;

export const SimulationTemplatePopup: FC<Props> = ({
  templates,
  selectedGameType,
  onSubmit,
  meta,
}): ReactElement => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const { t } = useTranslation();

  const { validateValue } = useInput();

  const { isErrorScroll, setIsErrorScroll, scrollToErrorField } =
    useErrorScroll(formRef.current as HTMLElement);

  const uniqId: string = useMemo(() => generateUniqId(), []);

  const isSingleGameType: boolean = selectedGameType === GameType.Single;

  const isTeamGameType: boolean =
    selectedGameType === GameType.Team || selectedGameType === GameType.Corp;

  const activePopups = useUnit($activePopups);

  const selectedId = useMemo(() => {
    const currentPopup = activePopups.find(
      ({ name }) => name === EPopupName.SIMULATION_TEMPLATE,
    );

    return currentPopup?.dataId?.find(
      ({ name }) => name === EPopupDataIdName.DEFAULT,
    )?.value;
  }, [activePopups]);

  const selectedTemplate: ISimulationTemplate | undefined = templates.find(
    (item) => item.id === selectedId,
  );

  const isDefaultTemplate = !!selectedTemplate && !!selectedTemplate.isDefault;

  const isEditingTemplate: boolean = !!selectedTemplate?.id;

  const [formData, setFormData] = useState<ILocalTemplate>();

  const [formDataErrors, setFormDataErrors] = useState<Record<string, string>>(
    {},
  );
  const [paramsErrors, setParamsErrors] = useState<Record<string, string>>({});

  const [minMaxCounts, setMinMaxCounts] = useState<Record<string, string>>();

  const [minMaxCountsErrors, setMinMaxCountsErrors] = useState<
    Record<string, string>
  >({});

  const selectedGameTypeItem: IGameType = useMemo(
    () => meta.gameTypes[selectedGameType],
    [selectedGameType, meta],
  );

  const minMaxCountGameType: TMinMaxPlayersGameTypeByRoles | undefined =
    useMemo(() => {
      if (!isSingleGameType) {
        const resultType: EMinMaxPlayersByRole = isTeamGameType
          ? EMinMaxPlayersByRole.TEAM_ALL_TEAMS_BY_ROLE
          : EMinMaxPlayersByRole.SOLO_ALL_PLAYERS_BY_ROLE;

        return getMinMaxPlayersGameTypeByRole(selectedGameTypeItem, resultType);
      }
    }, [selectedGameTypeItem, isSingleGameType, isTeamGameType]);

  const minMaxCountKeys: string[] | undefined = minMaxCountGameType
    ? Object.keys(minMaxCountGameType)
    : undefined;

  const saveBtnLabel: string = isEditingTemplate
    ? t("common.saveChanges")
    : t("simulationControl.simulationPage.templatesBlock.btn.addTemplate");

  useEffect(() => {
    const localParams: TTemplateParams = selectedTemplate
      ? toCloneObject(selectedTemplate.params)
      : getSimulationParamsFieldItem(
          {
            ...meta.params,
            ...meta.gameTypes[selectedGameType].params,
          },
          true,
        );

    setFormData({
      id: selectedTemplate?.id || uniqId,
      titleRu: selectedTemplate?.title?.ru || "",
      titleEn: selectedTemplate?.title?.en || "",
      minRate: selectedTemplate?.minRate || "",
      maxRate: selectedTemplate?.maxRate || "",
      gameType: selectedGameType,
      params: localParams,
      maxAreas: selectedTemplate?.maxAreas,
    });
  }, [
    selectedTemplate,
    meta.params,
    isSingleGameType,
    uniqId,
    selectedGameType,
  ]);

  useEffect(() => {
    if (minMaxCountGameType) {
      const localMinMaxCounts: Record<string, string> = {};

      const neededCountField: Record<string, string> | undefined =
        selectedTemplate?.maxPlayersByRole || selectedTemplate?.maxTeamsByName;

      Object.keys(minMaxCountGameType).forEach((item) => {
        localMinMaxCounts[item] = neededCountField?.[item] || "";
      });

      setMinMaxCounts(localMinMaxCounts);

      setMinMaxCountsErrors({});
    }
  }, [minMaxCountGameType, selectedTemplate]);

  useEffect(() => {
    if (isErrorScroll) {
      scrollToErrorField();
    }
  }, [formDataErrors, paramsErrors, isErrorScroll, scrollToErrorField]);

  const onChangeParamsHandler = (
    value: string | boolean | string[],
    itemName: string,
  ): void => {
    setFormData((prev) => {
      const localPrev = toCloneObject(prev);

      if (localPrev) {
        localPrev.params[itemName].value = value;
      }

      return localPrev;
    });

    setParamsErrors((prev) => {
      const localPrev = toCloneObject(prev);

      if (localPrev[itemName]) {
        delete localPrev[itemName];
      }

      return localPrev;
    });
  };

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    if (!!formData) {
      const localDataErrorsOptions: Record<string, IValidateValue> = {};

      if (!isDefaultTemplate) {
        localDataErrorsOptions.titleRu = {
          value: formData.titleRu,
          type: EInputValidateType.STRING,
          required: true,
        };

        localDataErrorsOptions.titleEn = {
          value: formData.titleEn,
          type: EInputValidateType.STRING,
          required: true,
        };

        localDataErrorsOptions.minRate = {
          value: formData.minRate,
          type: EInputValidateType.NUMBER,
          required: true,
          min: 0,
          max: 10,
        };

        localDataErrorsOptions.maxRate = {
          value: formData.maxRate,
          type: EInputValidateType.NUMBER,
          required: true,
          min: 0,
          max: 10,
        };
      }
      if (selectedGameType === GameType.Corp) {
        localDataErrorsOptions.maxAreas = {
          value: String(formData.maxAreas),
          type: EInputValidateType.NUMBER,
          required: true,
          min: selectedGameTypeItem?.minAreas,
          max: selectedGameTypeItem?.maxAreas,
        };
      }

      const minMaxCountsErrorsTemplate: Record<string, IValidateValue> = {};

      if (minMaxCountGameType && minMaxCounts) {
        Object.keys(minMaxCountGameType).forEach((item) => {
          minMaxCountsErrorsTemplate[item] = {
            value: minMaxCounts[item],
            type: EInputValidateType.NUMBER,
            required: true,
            min: minMaxCountGameType[item].min,
            max: minMaxCountGameType[item].max,
          };
        });
      }

      const title = {
        ru: isDefaultTemplate
          ? `Стандарт ${selectedTemplate?.gameType.toUpperCase()}`
          : formData.titleRu,
        en: isDefaultTemplate
          ? `Standard ${selectedTemplate?.gameType.toUpperCase()}`
          : formData.titleEn,
      };

      const localTemplate: ISimulationTemplate = {
        id: formData.id,
        title,
        minRate: isDefaultTemplate ? undefined : formData.minRate,
        maxRate: isDefaultTemplate ? undefined : formData.maxRate,
        gameType: formData.gameType,
        params: formData.params,
        maxAreas: Number(formData.maxAreas),
      };

      if (minMaxCountGameType) {
        if (isTeamGameType) {
          localTemplate.maxTeamsByName = minMaxCounts;
        } else {
          localTemplate.maxPlayersByRole = minMaxCounts;
        }
      }

      const localParamsErrors = validateValue(
        getSimulationParamsErrors(formData.params, meta),
      );

      const localMinMaxCountsErrors = validateValue(minMaxCountsErrorsTemplate);

      const localFormDataErrors = validateValue(localDataErrorsOptions);

      const hasParamsErrors: boolean = !!Object.keys(localParamsErrors).length;

      const hasFormDataErrors: boolean =
        !!Object.keys(localFormDataErrors).length;

      const hasMinMaxCountsErrors: boolean = !!Object.keys(
        localMinMaxCountsErrors,
      ).length;

      const hasErrors: boolean =
        hasParamsErrors || hasFormDataErrors || hasMinMaxCountsErrors;

      setParamsErrors(localParamsErrors);
      setFormDataErrors(localFormDataErrors);
      setMinMaxCountsErrors(localMinMaxCountsErrors);
      setIsErrorScroll(true);

      if (!hasErrors) {
        onSubmit(localTemplate);

        closePopup({ name: EPopupName.SIMULATION_TEMPLATE });
      }
    }
  };

  const onChangeHandler = (payload: IBaseInputChange) => {
    setFormData((prev) => {
      const localPrev = toCloneObject(prev);

      if (!!localPrev && payload.name) {
        localPrev[payload.name as TChangingFormDataKeys] = payload.value;
      }

      return localPrev;
    });

    setFormDataErrors((prev) => {
      const localPrev = toCloneObject(prev);

      if (payload.name) {
        delete localPrev[payload.name];
      }

      return localPrev;
    });
  };

  const areaPlaceholderCount: string = `(${selectedGameTypeItem?.minAreas}-${selectedGameTypeItem?.maxAreas})`;

  const areaPlaceholder: string = `${t(
    "popup.createAssessment.field.areasCount",
  )} ${areaPlaceholderCount}`;

  const onChangeMinMaxCountHandler = (payload: IBaseInputChange): void => {
    setMinMaxCounts((prev) => {
      const localPrev = { ...prev };

      if (payload.name && localPrev) {
        localPrev[payload.name] = payload.value;
      }

      return localPrev;
    });

    setMinMaxCountsErrors((prev) => {
      const localPrev = { ...prev };

      if (payload.name && localPrev[payload.name]) {
        delete localPrev[payload.name];
      }

      return localPrev;
    });
  };

  return (
    <BasePopup
      name={EPopupName.SIMULATION_TEMPLATE}
      title={t("simulationControl.simulationPage.popup.addEditTemplate.title")}
    >
      {!!formData && (
        <form
          ref={formRef}
          onSubmit={onSubmitHandler}
          className="simulation-template-popup"
        >
          {!isDefaultTemplate && (
            <Fragment>
              <FormControl error={formDataErrors.titleRu}>
                <BaseInputText
                  name="titleRu"
                  onChange={onChangeHandler}
                  value={formData.titleRu}
                  placeholder={t(
                    "simulationControl.simulationPage.templatesBlock.table.templateNameRu",
                  )}
                  maxLength={30}
                  error={!!formDataErrors.titleRu}
                />
              </FormControl>
              <FormControl error={formDataErrors.titleEn}>
                <BaseInputText
                  name="titleEn"
                  onChange={onChangeHandler}
                  value={formData.titleEn}
                  placeholder={t(
                    "simulationControl.simulationPage.templatesBlock.table.templateNameEn",
                  )}
                  maxLength={30}
                  error={!!formDataErrors.titleEn}
                />
              </FormControl>
              <FormControl error={formDataErrors.minRate}>
                <BaseInputNumber
                  name="minRate"
                  onChange={onChangeHandler}
                  value={formData.minRate}
                  placeholder={`${t(
                    "simulationControl.simulationPage.templatesBlock.table.minRate",
                  )} (0-10)`}
                  min={0}
                  max={10}
                  error={!!formDataErrors.minRate}
                />
              </FormControl>
              <FormControl error={formDataErrors.maxRate}>
                <BaseInputNumber
                  name="maxRate"
                  onChange={onChangeHandler}
                  value={formData.maxRate}
                  placeholder={`${t(
                    "simulationControl.simulationPage.templatesBlock.table.maxRate",
                  )} (0-10)`}
                  min={0}
                  max={10}
                  error={!!formDataErrors.maxRate}
                />
              </FormControl>
            </Fragment>
          )}
          {!!minMaxCountGameType &&
            !!minMaxCounts &&
            !!minMaxCountsErrors &&
            minMaxCountKeys?.map((item) => (
              <MinMaxCountField
                key={item}
                role={item}
                selectedGameType={selectedGameTypeItem}
                minMaxCountGameType={minMaxCountGameType}
                isTeam={isTeamGameType}
                minMaxCounts={minMaxCounts}
                minMaxCountsErrors={minMaxCountsErrors}
                onChange={onChangeMinMaxCountHandler}
              />
            ))}
          {formData.gameType === "corp" && (
            <FormControl error={formDataErrors.maxAreas}>
              <BaseInputNumber
                placeholder={areaPlaceholder}
                value={String(formData.maxAreas)}
                onChange={onChangeHandler}
                name={"maxAreas"}
                min={selectedGameTypeItem?.minAreas}
                max={selectedGameTypeItem?.maxAreas}
                error={!!formDataErrors.maxAreas}
              />
            </FormControl>
          )}
          <SimulationParamsFields
            onChange={onChangeParamsHandler}
            metaParams={{
              ...meta.params,
              ...meta.gameTypes[formData.gameType].params,
            }}
            params={formData.params}
            templateItemKey={selectedTemplate?.id || uniqId}
            errors={paramsErrors}
          />

          <BaseButton submit onClick={onSubmitHandler} block large primary>
            {saveBtnLabel}
          </BaseButton>
        </form>
      )}
    </BasePopup>
  );
};
