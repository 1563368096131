import { FC } from "react";

import { BaseButton, BasePopup, EPopupName } from "src/shared/components";

import { useTranslation } from "react-i18next";

import "./agreement-confirmed-popup.scss";
import { $activePopups, closePopup } from "../../base-popup/model";
import { useUnit } from "effector-react";
import { setAgreementConfirmed } from "../../../../features/public/app-access/model/user-place";

interface Props {}

export const AgreementConfirmedPopup: FC<Props> = () => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const currentPopup = activePopups.find(
    ({ name }) => name === EPopupName.AGREEMENT_CONFIRMED,
  );

  const confirmAgreementConfirmed = (): void => {
    closePopup({ name: EPopupName.AGREEMENT_CONFIRMED });
    if (currentPopup?.data?.callback) {
      currentPopup.data.callback();
    }
    setAgreementConfirmed(false);
  };

  return (
    <BasePopup
      name={EPopupName.AGREEMENT_CONFIRMED}
      isOutsideClick={false}
      className={"agreement-confirmed-popup"}
    >
      <div className="agreement-confirmed-title">
        {t("base.popup.rules.title")}
      </div>
      <div className="agreement-confirmed-description">
        {t("base.popup.rules")}
      </div>
      <BaseButton block large primary onClick={confirmAgreementConfirmed}>
        {t("base.popup.rules.agree")}
      </BaseButton>
    </BasePopup>
  );
};
