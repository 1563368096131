import { createDomain, sample } from "effector";

import { Pagination, QueryParams } from "src/shared/api/base";

import { patchPlayerById } from "../../../../shared/api/hr/rating-statuses";
import { PlayersApiPatchPlayerByIdRequest } from "src/generated/social";
import { $socialPlayerInfo } from "./user-social";

// Domain

export const playersDomain = createDomain("patch-profile");

// Events
export const updateProfileById =
  playersDomain.createEvent<PlayersApiPatchPlayerByIdRequest>();

// Effects
export const updateProfileByIdFx = playersDomain.createEffect(patchPlayerById);

// Stores

export const $isLoading = playersDomain.createStore<boolean>(false);

export const $pagination = playersDomain.createStore<Pagination>(null);

export const $queryParams = playersDomain.createStore<QueryParams>({});

sample({
  clock: updateProfileById,
  target: updateProfileByIdFx,
});

sample({
  clock: updateProfileByIdFx.doneData,
  target: $socialPlayerInfo,
});
