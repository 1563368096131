import { Trans } from "react-i18next";
import { FC } from "react";
import { Competence } from "../../../../../generated/social";
import { getFormattedFineText } from "../../../../../shared/helpers/getForrmatedFineText";

interface FineWarningProps {
  days: Record<string, number> | object;
  rating: Record<string, number>;
  competencies: Competence[];
  notification?: boolean;
}

export const FineWarningText: FC<FineWarningProps> = ({
  days,
  rating,
  notification = false,
  competencies,
}) => {
  const { minDay, minRating, fineForm, dateForm, competenceList } =
    getFormattedFineText(days, rating, competencies);

  if (notification) {
    return (
      <Trans
        i18nKey="notification.fineNotification"
        values={{ minDay, competenceList, dateForm, fineForm, minRating }}
      />
    );
  }

  return (
    <Trans
      i18nKey="warning.fineWarning"
      values={{ minDay, competenceList, dateForm, fineForm }}
    />
  );
};
