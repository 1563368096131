import { createEffect, sample } from "effector";

import { delay } from "patronum";

import {
  closeAllPopupHandler,
  closePopupHandler,
  hideAllPopupHandler,
  hidePopupHandler,
  openPopupHandler,
  showPopupHandler,
} from "../lib";

import { popupsDomain } from "./domain";

import {
  EPopupName,
  IPopupCloseAllPayload,
  IPopupClosePayload,
  IPopupOpenPayload,
} from "./types";

// Stores

export const $activePopups = popupsDomain.createStore<IPopupOpenPayload[]>([]);

export const $showedPopups = popupsDomain.createStore<EPopupName[]>([]);

// Events

export const openPopup = popupsDomain.createEvent<IPopupOpenPayload>();

export const closePopup = popupsDomain.createEvent<IPopupClosePayload>();

export const closeAllPopup = popupsDomain.createEvent<
  IPopupCloseAllPayload | undefined
>();

const hidePopup = popupsDomain.createEvent<IPopupClosePayload>();

const hideAllPopup = popupsDomain.createEvent<
  IPopupCloseAllPayload | undefined
>();

export const openPopupFx = createEffect(
  async (popupOptions: IPopupOpenPayload) => {
    openPopup(popupOptions);
  },
);

// Logic

sample({
  clock: openPopup,
  source: $activePopups,
  fn: openPopupHandler,
  target: $activePopups,
});

sample({
  clock: openPopup,
  source: $showedPopups,
  fn: showPopupHandler,
  target: $showedPopups,
});

sample({
  clock: closePopup,
  source: $showedPopups,
  fn: closePopupHandler,
  target: $showedPopups,
});

sample({
  clock: closeAllPopup,
  fn: closeAllPopupHandler,
  target: $showedPopups,
});

sample({
  clock: hidePopup,
  source: $activePopups,
  fn: hidePopupHandler,
  target: $activePopups,
});

sample({
  clock: hideAllPopup,
  fn: hideAllPopupHandler,
  target: $activePopups,
});

delay({
  source: closePopup,
  timeout: 500,
  target: hidePopup,
});

delay({
  source: closeAllPopup,
  timeout: 500,
  target: hideAllPopup,
});
