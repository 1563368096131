import { createEffect, createEvent, createStore, sample } from "effector";
import {
  fetchCreateUser,
  FetchResetPasswordParams,
  fetchResetUserPassword,
} from "../../../../shared/api/admin/users";
import {
  Configuration,
  UsersApiPostUsersRequest,
} from "../../../../generated/keycloak";
import {
  EPopupName,
  IPopupClosePayload,
  IPopupOpenPayload,
  openResponseErrorPopup,
} from "../../../../shared/components";
import { t } from "i18next";
import { openPopupFx } from "../../../../shared/components/base-popup/model";
import { createUserFaq } from "./domain";
import { $axiosCdn } from "../../../../shared/api/api";
import { $userType } from "../../../../features/public/user-form/model";
import { AvatarApi } from "../../../../generated/cdn";
import { postChangeUsersHistoryFx } from "../../../../entities/admin/histories/model/users/users-change-history";
import {
  ActionUserEnum,
  PlayersApiPostChangesByAdminPlayerRequest,
} from "../../../../generated/social";
import { ERequestStatus } from "../../../../shared/store/types";

type AddAvatarParams = {
  id: string;
  file: File;
};

type TFilesNames = "avatar";

interface IUploadFileItem {
  file?: File;
  isLoaded: boolean;
}

type TUploadFiles = { [key in TFilesNames]: IUploadFileItem };

const initialUploadFilesState: TUploadFiles = {
  avatar: {
    isLoaded: false,
  },
};

// Store
export const $createdUserId = createUserFaq.createStore("");

export const $isLoading = createStore<boolean>(false);

export const $hasCreatedId = createUserFaq.createStore(true);

export const $resetPasswordRequestStatus = createStore<ERequestStatus>(
  ERequestStatus.IDLE,
);
// Events

export const fetchPostCreateUser = createEvent<UsersApiPostUsersRequest>();

export const setUploadFilesEvent = createEvent<File | undefined>();

export const fetchResetPasswordUser = createEvent<FetchResetPasswordParams>();

// Effects
export const fetchCreateUserFx = createEffect(fetchCreateUser);

export const fetchResetPasswordAdminFx = createEffect(fetchResetUserPassword);

sample({
  clock: fetchPostCreateUser,
  target: fetchCreateUserFx,
});

sample({
  source: fetchCreateUserFx.doneData,
  target: $createdUserId,
});

sample({
  clock: fetchResetPasswordUser,
  target: fetchResetPasswordAdminFx,
});

sample({
  clock: fetchCreateUserFx.done,
  source: { userId: $createdUserId, userType: $userType },
  filter: ({ userId, userType }) => !!userId && !!userType,
  fn: ({ userId, userType }) => ({
    userId,
    clientId: `alb-${userType}`,
  }),
  target: fetchResetPasswordAdminFx,
});

export const $uploadFiles = createStore<TUploadFiles>(
  initialUploadFilesState,
).on(setUploadFilesEvent, (state, file) => ({
  ...state,
  avatar: {
    ...state.avatar,
    file,
    isLoaded: !!file,
  },
}));

const fetchAddUserAvatarFx = createEffect<AddAvatarParams, void, Error>({
  handler: async ({ id, file }) => {
    const request = new AvatarApi(new Configuration(), "", $axiosCdn);
    const options = { id, file };
    await request.uploadAvatarAvatarsIdPost(options);
  },
});

// Samples
sample({
  clock: fetchResetPasswordAdminFx.done,
  source: {
    userId: $createdUserId,
    uploadFiles: $uploadFiles,
  },
  filter: ({ uploadFiles }) => !!uploadFiles.avatar.file,
  fn: ({ userId, uploadFiles }) => ({
    id: userId,
    file: uploadFiles.avatar.file!,
  }),
  target: fetchAddUserAvatarFx,
});

sample({
  clock: fetchAddUserAvatarFx.failData,
  fn: (error: any) => {
    openResponseErrorPopup(error?.response?.data?.errorMessage);
    return false;
  },
  target: $hasCreatedId,
});

sample({
  clock: fetchResetPasswordAdminFx.done,
  fn: ({ params }): IPopupOpenPayload | IPopupClosePayload => {
    if (params.callback) {
      return {
        name: params.callback.name as EPopupName,
        callback: params.callback,
      };
    } else {
      return {
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.baseMessage.responseMessage.userIsCreated"),
        },
      };
    }
  },
  target: openPopupFx,
});

sample({
  clock: fetchResetPasswordAdminFx.done,
  fn: (data): PlayersApiPostChangesByAdminPlayerRequest => {
    return {
      body: {
        users_id: [data.params.userId],
        action: ActionUserEnum.Created,
      },
    };
  },
  target: postChangeUsersHistoryFx,
});

sample({
  clock: fetchAddUserAvatarFx.done,
  fn: () => ({
    name: EPopupName.BASE_MESSAGE_POPUP,
    message: {
      text: t("popup.baseMessage.responseMessage.userIsCreated"),
    },
  }),
  target: openPopupFx,
});

sample({
  clock: fetchAddUserAvatarFx.done,
  fn: () => ({
    name: EPopupName.BASE_MESSAGE_POPUP,
    message: {
      text: t("popup.baseMessage.responseMessage.userIsCreated"),
    },
  }),
  target: openPopupFx,
});

sample({
  clock: fetchCreateUserFx.failData,
  fn: (error: any) => {
    openResponseErrorPopup(error?.response?.data?.errorMessage);
    return false;
  },
  target: $hasCreatedId,
});

sample({
  clock: fetchCreateUserFx,
  fn: () => true,
  target: $isLoading,
});

sample({
  clock: [fetchCreateUserFx.done, fetchCreateUserFx.fail],
  fn: () => false,
  target: $isLoading,
});

fetchResetPasswordAdminFx.fail.watch((error: any) => {
  openResponseErrorPopup(error?.response?.data?.errorMessage);
});

$resetPasswordRequestStatus
  .on(fetchResetPasswordAdminFx, () => ERequestStatus.LOADING)
  .on(fetchResetPasswordAdminFx.done, () => ERequestStatus.LOADED)
  .on(fetchResetPasswordAdminFx.fail, () => ERequestStatus.ERROR);
