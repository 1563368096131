import {
  FC,
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useTranslation } from "react-i18next";

import classNames from "classnames";

import {
  AdditionalCompetenceEnum,
  Checkbox,
  EDataGridFilterType,
  IDataGridColumnControlPanelGroup,
  IDataGridFilterItem,
  IDataGridSelectOption,
  TDataGridDataParams,
} from "src/shared/models";

import { FormControl } from "../../form-control/form-control";

import {
  BaseInputDate,
  BaseInputNumber,
  BaseInputText,
  BaseMultiSelect,
  BaseSelect,
} from "../../form-fields";

import { BaseButton } from "../../base-button/base-button";

import { InputCheckbox } from "../../input-checkbox/input-checkbox";

interface Props {
  group: IDataGridColumnControlPanelGroup;
  onEscapeKeyUp?: () => void;
  queryParams: TDataGridDataParams;
  onSave: () => void;
  onReset: () => void;
  onChangeFilter: (key: string, value: string) => void;
  onChangeSort: (value: string) => void;
}

export const DataGridControlsPanelGroup: FC<Props> = ({
  group,
  onSave,
  onEscapeKeyUp,
  queryParams,
  onChangeFilter,
  onReset,
  onChangeSort,
}): ReactElement => {
  const { t } = useTranslation();

  const submitForm = (e: FormEvent) => {
    e.preventDefault();

    onSave();
  };

  const onDocumentKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onEscapeKeyUp && onEscapeKeyUp();
      }
    },
    [onEscapeKeyUp],
  );

  const isChecked = useCallback(
    (
      filter: IDataGridFilterItem,
      filterItem: IDataGridSelectOption,
    ): boolean => {
      let filterValuesStr = queryParams[filter.key];

      let filterValues = !!filterValuesStr ? filterValuesStr.split(",") : [];

      return filterValues.includes(filterItem.value);
    },
    [queryParams],
  );

  const sortValues = useMemo<string[]>(() => {
    if (group?.sort?.orderKey) {
      const valuesStr = queryParams[group?.sort?.orderKey];

      return valuesStr ? valuesStr.split(",") : [];
    }

    return [];
  }, [queryParams, group]);

  const onChangeCheckbox = useCallback(
    (
      filter: IDataGridFilterItem,
      filterItem: IDataGridSelectOption,
      toggle: boolean,
    ) => {
      let filterValuesStr = queryParams[filter.key];

      let filterValues = !!filterValuesStr ? filterValuesStr.split(",") : [];

      if (toggle) {
        if (filter.onlyOneActiveOption) {
          filterValues = [filterItem.value];
        } else {
          filterValues.push(filterItem.value);
        }
      } else {
        filterValues = filterValues.filter(
          (filterValue) => filterValue !== filterItem.value,
        );
      }

      onChangeFilter(filter.key, filterValues.join(","));
    },
    [queryParams, onChangeFilter],
  );

  const isDisabledCheckbox = useCallback(() => {
    const hasActiveCheckbox = group.filters?.some((filter) => {
      if (filter.type !== EDataGridFilterType.CHECKBOX) {
        return false;
      }
      return filter.items?.some((item) => isChecked(filter, item));
    });

    const hasDisabledFilter = group.filters?.some(
      (filter) => filter.disableFilters,
    );

    return !!hasActiveCheckbox && !!hasDisabledFilter;
  }, [group.filters, isChecked]);

  const isDisabledButton = useCallback(() => {
    const hasDisabledFilter = group.filters?.some(
      (filter) => filter.disableFilters,
    );

    if (!hasDisabledFilter) {
      return false;
    }

    const hasActiveNumberFilter = group.filters?.some(
      (filter) =>
        filter.type === EDataGridFilterType.NUMBER &&
        !!queryParams?.[filter.key],
    );

    const hasSortValues = group.sort?.items.some((sortItem) =>
      sortValues.includes(sortItem.value),
    );

    const hasActiveMultiSelectItem = group.filters?.find((filter) => {
      const additionalValues = Object.values(AdditionalCompetenceEnum);
      const newQueryValues = queryParams[filter.key]?.split(",");
      const isIncludeItem = additionalValues.some(
        (value) => newQueryValues?.includes(value),
      );
      return filter.type === EDataGridFilterType.MULTI_SELECT && isIncludeItem;
    });

    const isEnabled =
      ((hasActiveNumberFilter || hasSortValues) && hasActiveMultiSelectItem) ||
      (hasActiveMultiSelectItem && isDisabledCheckbox());

    return !isEnabled;
  }, [group.filters, group.sort?.items, queryParams, sortValues, isChecked]);

  useEffect(() => {
    document.addEventListener("keyup", onDocumentKeyUp);

    return () => {
      document.removeEventListener("keyup", onDocumentKeyUp);
    };
  }, [onDocumentKeyUp]);

  return (
    <form className="data-grid__filters" onSubmit={submitForm}>
      {group.filters?.map((filter: IDataGridFilterItem) => (
        <FormControl
          key={filter.key}
          marginBottom={null}
          className={classNames("data-grid__filters-item", {
            "data-grid__filters-item--half": filter.isHalf,
          })}
        >
          {filter.type === EDataGridFilterType.SEARCH && (
            <BaseInputText
              isSmall
              forDarkBg
              value={queryParams?.[filter.key] || ""}
              placeholder={filter.placeholder}
              name={filter.key}
              onChange={({ value }) => {
                onChangeFilter(filter.key, value);
              }}
            />
          )}
          {filter.type === EDataGridFilterType.SELECT && (
            <BaseSelect
              size="medium"
              forDarkBg
              activeItem={queryParams?.[filter.key] || ""}
              placeholder={filter.placeholder}
              canSearch={filter.canSearch}
              name={filter.key}
              isSearchUnderOption
              items={filter.items || []}
              onChange={({ value }) => {
                onChangeFilter(filter.key, value);
              }}
            />
          )}
          {filter.type === EDataGridFilterType.MULTI_SELECT && (
            <BaseMultiSelect
              size="medium"
              forDarkBg
              activeItems={queryParams[filter.key]?.split(",")}
              placeholder={filter.placeholder}
              canSearch={filter.canSearch}
              name={filter.key}
              items={filter.items || []}
              onChange={({ value }) => {
                onChangeFilter(filter.key, value.join(","));
              }}
            />
          )}
          {filter.type === EDataGridFilterType.NUMBER && (
            <BaseInputNumber
              isSmall
              forDarkBg
              placeholder={filter.placeholder}
              name={filter.key}
              value={queryParams?.[filter.key] || ""}
              onChange={({ value }) => {
                onChangeFilter(filter.key, value);
              }}
              disabled={isDisabledCheckbox()}
              {...filter.numberProps}
            />
          )}
          {filter.type === EDataGridFilterType.DATE && (
            <BaseInputDate
              isSmall
              forDarkBg
              placeholder={filter.placeholder}
              name={filter.key}
              value={queryParams?.[filter.key] || ""}
              onChange={({ value }) => {
                onChangeFilter(filter.key, value);
              }}
            />
          )}
          {filter.type === EDataGridFilterType.CHECKBOX && (
            <div className="data-grid__filters-checkbox-list">
              {filter?.items?.map((filterItem) => (
                <InputCheckbox
                  forDarkBg
                  key={`${filter.key}-${filterItem.label}`}
                  className="data-grid__filters-checkbox-list-item"
                  value={isChecked(filter, filterItem)}
                  type={
                    isChecked(filter, filterItem)
                      ? Checkbox.CHOSEN
                      : Checkbox.EMPTY
                  }
                  onChange={(toggle) =>
                    onChangeCheckbox(filter, filterItem, toggle)
                  }
                >
                  {filterItem.label}
                </InputCheckbox>
              ))}
            </div>
          )}
        </FormControl>
      ))}

      {group.sort && (
        <div className="data-grid__sort">
          {group.sort?.items.map((sortItem) => (
            <InputCheckbox
              forSort
              onChange={() => onChangeSort(sortItem.value)}
              value={sortValues.includes(sortItem.value)}
              type={
                sortValues.includes(sortItem.value)
                  ? Checkbox.CHOSEN
                  : Checkbox.EMPTY
              }
              className="data-grid__sort-item"
              key={sortItem.value}
              isDisabled={isDisabledCheckbox()}
            >
              {sortItem.title}
            </InputCheckbox>
          ))}
        </div>
      )}

      <div className="data-grid__filters-buttons">
        <BaseButton
          submit
          xSmall
          white
          bold
          className="data-grid__filters-button data-grid__filters-button--submit"
          disabled={isDisabledButton()}
        >
          {t("table.filters.btn.apply")}
        </BaseButton>
        <BaseButton
          xSmall
          transparentWhite
          bold
          className="data-grid__filters-button"
          onClick={onReset}
        >
          {t("table.filters.btn.cancel")}
        </BaseButton>
      </div>
    </form>
  );
};
