import React, { FC, Fragment } from "react";
import { IDataGridColumn, IDataGridRow } from "../../../models";
import { GridColumnItem } from "../grid-column-item/grid-column-item";
import classNames from "classnames";
import { GridAccordion } from "../grid-accordion/grid-accordion";
import { DataGrid } from "../../data-grid/data-grid-lazy";
import { useTranslation } from "react-i18next";
import "./corp-results-grid.scss";

export interface ICorpGridItem {
  place?: number;
  areaNum: number;
  corpNum: number;
  role: string;
  rows: IDataGridRow[];
}

interface Props {
  columns: IDataGridColumn[];
  rows: ICorpGridItem[];
  className?: string;
}

const CorpResultsGrid: FC<Props> = ({ columns, rows, className }) => {
  const { t } = useTranslation();

  const headerItems = columns.map((column, index) => (
    <GridColumnItem
      key={index + column.title + column.key}
      column={column}
      className={classNames(
        "corp-results-grid-header__item",
        column.textAlign
          ? `corp-results-grid-header__item--text-align_${column.textAlign}`
          : undefined,
      )}
    >
      {column.title}
    </GridColumnItem>
  ));

  const accordionHeader = (corp: ICorpGridItem) => {
    const className: string = "corp-results-grid__accordion-header-item";

    const nameTitle: string =
      corp.areaNum === 0
        ? t("playerRoles.ceo")
        : `${t("common.area")} ${corp.areaNum}`;

    return (
      <div className="corp-results-grid__accordion-header">
        <GridColumnItem column={columns[0]} className={className}>
          {nameTitle}
        </GridColumnItem>
      </div>
    );
  };

  const corpAccordionHeader = (corpTeams: ICorpGridItem[], corpNum: number) => {
    const className: string = "corp-results-grid__accordion-header-item";

    return (
      <div className="corp-results-grid__accordion-header corp-results-grid__corp-wrapper-header">
        <GridColumnItem column={columns[0]} className={className}>
          {t("common.team")} {corpNum}
        </GridColumnItem>
        <GridColumnItem column={columns[1]} className={className}>
          {corpTeams[0].place || ""}
        </GridColumnItem>
        <GridColumnItem column={columns[1]} className={className}>
          {t(`playerRoles.${corpTeams[0].role}`)}
        </GridColumnItem>
      </div>
    );
  };

  const corpNums = new Set(rows.map((team) => team.corpNum));

  const accordionsItems = Array.from(corpNums).map((corpNum) => {
    const corpTeams = rows.filter((team) => team.corpNum === corpNum);

    const corpTeamsSorted = corpTeams.sort(
      (team1, team2) => team1.areaNum - team2.areaNum,
    );

    const teamGrids = corpTeamsSorted.map((team, index) => (
      <GridAccordion
        isShowed
        key={team.role + index}
        className="corp-results-grid__accordion"
        headerSlot={accordionHeader(team)}
      >
        <DataGrid
          className="corp-results-grid__table"
          columns={columns}
          rows={team.rows}
          autoScrollDisabled
          hiddenHeader
        />
      </GridAccordion>
    ));

    return (
      <div className="corp-results-grid__corp-wrapper">
        {corpAccordionHeader(corpTeams, corpNum)}
        <div className="corp-results-grid__corp-wrapper-content">
          {teamGrids}
        </div>
      </div>
    );
  });

  return (
    <Fragment>
      {!!rows.length && (
        <div className={classNames("corp-results-grid", className)}>
          <div className="corp-results-grid-header">{headerItems}</div>
          {accordionsItems}
        </div>
      )}
    </Fragment>
  );
};

export default CorpResultsGrid;
