import { sample, Store } from "effector";

import {
  AssessmentInvite,
  AssessmentStatus,
  MsgType,
} from "src/generated/notification";

import { notificationsModel } from "src/entities/public/notifications";

import { Notification } from "src/shared/api/public/sockets/model/notifications";

const {
  $notificationsPopup,
  $notifications,
  notificationsDomain,
  addNotificationToList,
  addNotificationsToList,
} = notificationsModel;

const isProgressAssessmentInviteNotification = (
  message?: Notification,
): boolean => {
  if (message?.type === MsgType.AssessmentInvite) {
    const msg: AssessmentInvite = message.msg as AssessmentInvite;

    return msg.aSt === AssessmentStatus.InProgress;
  }

  return false;
};

const desktopHideTime = 1000 * 60;

const mobileHideTime = 1000;

const isMobile = () => window.matchMedia("(max-width: 767px)").matches;

const getHideTimerTime = () => (isMobile() ? mobileHideTime : desktopHideTime);

const isNotificationNeedRemoveByTime = (whenAddDate: Date) => {
  const hideTime = getHideTimerTime();

  const currentTimeMilliseconds = new Date().getTime();

  const whenRemoveTime = whenAddDate.getTime() + hideTime;

  return whenRemoveTime > currentTimeMilliseconds;
};

export type ProgressAssessment = Omit<Notification, "msg" | "type"> & {
  msg: AssessmentInvite;
  type: MsgType.AssessmentInvite;
};

// Events

export const removeOutTimerNotifications = notificationsDomain.createEvent();

export const removeNotification =
  notificationsDomain.createEvent<Notification["mid"]>();

export const removeAllNotifications = notificationsDomain.createEvent();

export const removeProgressAssessmentNotification =
  notificationsDomain.createEvent<ProgressAssessment | null>();

export const hideProgressAssessment =
  notificationsDomain.createEvent<ProgressAssessment | null>();

export const showProgressAssessment = notificationsDomain.createEvent();

// Store

export const $hiddenProgressAssessment =
  notificationsDomain.createStore<ProgressAssessment | null>(null);

export const $listToRemove = notificationsDomain.createStore<Record<
  Notification["mid"],
  Date
> | null>(null);

// Logic

$listToRemove.watch(
  (() => {
    let timeoutRemoveSome: NodeJS.Timeout;

    return (state: Record<string, Date> | null) => {
      clearTimeout(timeoutRemoveSome);

      if (!state || Object.keys(state).length === 0) return;

      const timerTime = 500;

      timeoutRemoveSome = setTimeout(function removeSome() {
        removeOutTimerNotifications();

        timeoutRemoveSome = setTimeout(removeSome, timerTime);
      }, timerTime);
    };
  })(),
);

sample({
  clock: addNotificationToList,
  source: $listToRemove,
  fn: (list, newNotification) => {
    if (!newNotification) return list;

    const newList = { ...list, [newNotification.mid]: new Date() };

    return newList;
  },
  target: $listToRemove,
});

sample({
  clock: addNotificationsToList,
  source: $listToRemove,
  fn: (list, newNotifications) => {
    const formatted = newNotifications.reduce(
      (res, cur) => ({
        ...res,
        [cur.mid]: new Date(),
      }),
      Object.create(null),
    );
    return { ...list, ...formatted };
  },
  target: $listToRemove,
});

sample({
  clock: removeOutTimerNotifications,
  source: [$listToRemove, $notificationsPopup] as [
    Store<Record<string, Date> | null>,
    Store<Notification[]>,
  ],
  fn: ([listToRemove, listAll]) => {
    return listAll.filter((item) => {
      if (isProgressAssessmentInviteNotification(item)) return true;

      if (!listToRemove?.[item.mid]) return true;

      return isNotificationNeedRemoveByTime(listToRemove[item.mid]);
    });
  },
  target: $notificationsPopup,
});

sample({
  clock: removeOutTimerNotifications,
  source: [$listToRemove, $notificationsPopup] as [
    Store<Record<string, Date> | null>,
    Store<Notification[]>,
  ],
  fn: ([listToRemove, listAll]) => {
    const progressAssessment = listAll.find(
      isProgressAssessmentInviteNotification,
    ) as ProgressAssessment;

    if (!listToRemove?.[progressAssessment?.mid]) return null;

    const isNeedRemove =
      isNotificationNeedRemoveByTime(listToRemove[progressAssessment.mid]) &&
      isMobile();

    return isNeedRemove ? progressAssessment : null;
  },
  target: removeProgressAssessmentNotification,
});

sample({
  clock: $notificationsPopup,
  source: $listToRemove,
  fn: (listToRemove, listAll) => {
    const allIds = new Set(listAll.map(({ mid }) => mid));

    if (!listToRemove) return null;

    const newRemoveList = Object.entries(listToRemove).reduce(
      (result, [mid, whenDate]) => {
        if (allIds.has(mid)) {
          result[mid] = whenDate;
        }

        return result;
      },
      Object.create(null),
    );

    return newRemoveList;
  },
  target: $listToRemove,
});

sample({
  clock: removeNotification,
  source: $notificationsPopup,
  fn: (items, mid) =>
    items.filter(
      (item) =>
        item.mid !== mid || isProgressAssessmentInviteNotification(item),
    ),
  target: $notificationsPopup,
});

sample({
  clock: removeNotification,
  source: $notificationsPopup,
  fn: (items, mid) => {
    const notificationToRemove = items.find(
      (item) => item.mid === mid,
    ) as ProgressAssessment;

    const isProgressAssessmentRemove =
      isProgressAssessmentInviteNotification(notificationToRemove);

    return isProgressAssessmentRemove && isMobile()
      ? notificationToRemove
      : null;
  },
  target: removeProgressAssessmentNotification,
});

sample({
  clock: removeProgressAssessmentNotification,
  target: hideProgressAssessment,
});

sample({
  clock: removeProgressAssessmentNotification,
  source: $notificationsPopup,
  fn: (items, progressAssessmentNotification) => {
    return items.filter(
      (item) => item.mid !== progressAssessmentNotification?.mid,
    );
  },
  target: $notificationsPopup,
});

sample({
  clock: removeAllNotifications,
  source: $notificationsPopup,
  fn: (notificationsPopup) =>
    notificationsPopup.filter(isProgressAssessmentInviteNotification),
  target: $notificationsPopup,
});

sample({
  clock: removeAllNotifications,
  source: $notificationsPopup,
  fn: (items) => {
    const notificationToRemove = items.find(
      isProgressAssessmentInviteNotification,
    ) as ProgressAssessment;

    return isMobile() ? notificationToRemove : null;
  },
  target: removeProgressAssessmentNotification,
});

sample({
  clock: $notifications,
  source: $hiddenProgressAssessment,
  fn: (hiddenLobbyAssessment, notificationsPopup) => {
    const isExist = notificationsPopup.find(
      isProgressAssessmentInviteNotification,
    );

    return isExist ? hiddenLobbyAssessment : null;
  },
  target: $hiddenProgressAssessment,
});

sample({
  clock: hideProgressAssessment,
  target: $hiddenProgressAssessment,
});

sample({
  clock: showProgressAssessment,
  source: [$hiddenProgressAssessment, $notificationsPopup] as [
    Store<ProgressAssessment | null>,
    Store<notificationsModel.Notification[]>,
  ],
  fn: ([hidden, allViewed]) => allViewed.concat(hidden as ProgressAssessment),
  target: $notificationsPopup,
});

sample({
  clock: showProgressAssessment,
  fn: () => null,
  target: $hiddenProgressAssessment,
});
