import { Country, District, Region, School } from "src/generated/social";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
} from "src/shared/models/dataGrid";

import { getActiveGroup } from "src/shared/helpers/data-grids/getActiveGroup";

import { getOrderByValue } from "src/shared/helpers/data-grids/getOrderByValue";

import { EDesiredStudyPlace } from "src/shared/components/user-form/user-form";

import { t } from "src/shared/helpers";

import { QueryParams } from "src/shared/api/base";

import {
  countriesToSelectItemsAdapter,
  districtsToSelectItemsAdapter,
  regionsToSelectItemsAdapter,
  schoolsToSelectItemsAdapter,
} from "src/features/public/user-form/lib";

export const generateColumns = (
  queryParams: QueryParams = {},
  places: {
    countries: Country[];
    regions: Region[];
    districts: District[];
    schools: School[];
  },
): IDataGridColumn[] => {
  const desiredStudyPlaceList = Object.values(EDesiredStudyPlace).map(
    (item) => ({
      label: t(`userForm.select.desiredStudyPlace.${item}`),
      value: item,
    }),
  );

  return [
    {
      title: "SELECT",
      type: EDataGridColumnType.SELECT_ROW,
      key: "selected",
      showed: true,
      hiddenTitle: true,
      sticky: {
        mobile: {
          left: 0,
        },
        tablet: {
          left: 0,
        },
        laptop: {
          left: 0,
        },
      },
    },
    {
      title: t("table.header.position"),
      type: EDataGridColumnType.STRING,
      key: "gPlace",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.header.position"),
        activeGroupKey: getActiveGroup(
          ["gPlace", "gPlaceFrom", "gPlaceTo"],
          { all: ["gPlace", "gPlaceFrom", "gPlaceTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "gPlaceFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.gPlaceFrom || "",
              },
              {
                key: "gPlaceTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.gPlaceTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "gPlace",
                "-gPlace",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "gPlace",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-gPlace",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.player"),
      type: EDataGridColumnType.AVATAR,
      key: "fullName",
      showed: true,
      controlPanel: {
        title: t("table.header.player"),
        activeGroupKey: getActiveGroup(
          ["firstName", "lastName"],
          { all: ["firstName", "lastName"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "firstName",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.name"),
                value: queryParams?.firstName || "",
              },
              {
                key: "lastName",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.lastName"),
                value: queryParams?.lastName || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "firstName",
                "-firstName",
                "lastName",
                "-lastName",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc.name"),
                  value: "firstName",
                },
                {
                  title: t("table.filters.alphabet.desc.name"),
                  value: "-firstName",
                },
                {
                  title: t("table.filters.alphabet.asc.surname"),
                  value: "lastName",
                },
                {
                  title: t("table.filters.alphabet.desc.surname"),
                  value: "-lastName",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("userForm.inputLabel.gender"),
      type: EDataGridColumnType.STRING,
      key: "gender",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.gender"),
        activeGroupKey: getActiveGroup(
          ["gender"],
          { all: ["gender"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "gender",
                "-gender",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.asc.gender"),
                  value: "gender",
                },
                {
                  title: t("table.filters.desc.gender"),
                  value: "-gender",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.phone", "Phone"),
      type: EDataGridColumnType.PHONE,
      key: "phone",
      showed: true,
      controlPanel: {
        title: t("table.header.phone"),
        activeGroupKey: getActiveGroup(
          ["phone"],
          { all: ["phone"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "phone",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.phone || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.email"),
      type: EDataGridColumnType.EMAIL,
      key: "email",
      showed: true,
      controlPanel: {
        title: t("table.header.email"),
        activeGroupKey: getActiveGroup(
          ["email"],
          { all: ["email"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "email",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.email || "",
              },
              {
                key: "emailFull",
                type: EDataGridFilterType.CHECKBOX,
                value: queryParams?.emailFull || "",
                items: [
                  {
                    label: t("common.selectAll"),
                    value: "true",
                  },
                  {
                    label: t("common.empty"),
                    value: "false",
                  },
                ],
                onlyOneActiveOption: true,
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.settings.checkboxLabel.birthdate"),
      type: EDataGridColumnType.STRING,
      key: "birthdate",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.birthdate"),
        activeGroupKey: getActiveGroup(
          ["birthdate", "birthdateFrom", "birthdateTo"],
          {
            all: ["birthdate", "birthdateFrom", "birthdateTo"],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "birthdateFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.birthdateFrom || "",
              },
              {
                key: "birthdateTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.birthdateTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "birthdate",
                "-birthdate",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "birthdate",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-birthdate",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.rating"),
      type: EDataGridColumnType.RATING,
      key: "gRate",
      showed: true,
      filterTitle: t("table.header.rating"),
      controlPanel: {
        title: t("table.header.rating"),
        activeGroupKey: getActiveGroup(
          ["gRate", "gRateFrom", "gRateTo"],
          {
            all: ["gRate", "gRateFrom", "gRateTo"],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "gRateFrom",
                type: EDataGridFilterType.NUMBER,
                placeholder: t("table.filters.from"),
                value: queryParams?.gRateFrom || "",
                isHalf: true,
                numberProps: {
                  step: 0.01,
                },
              },
              {
                key: "gRateTo",
                type: EDataGridFilterType.NUMBER,
                placeholder: t("table.filters.to"),
                value: queryParams?.gRateTo || "",
                isHalf: true,
                numberProps: {
                  step: 0.01,
                },
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, ["gRate", "-gRate"]),
              orderKey: "orderBy",
              key: "gRate",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "gRate",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-gRate",
                },
              ],
            },
          },
        ],
      },
    },

    {
      title: t("table.header.communication.short"),
      description: t("table.header.communication"),
      filterTitle: t("table.header.communication"),
      type: EDataGridColumnType.STRING,
      key: "communication",
      showed: true,
      controlPanel: {
        title: t("table.header.communication"),
        activeGroupKey: getActiveGroup(
          ["communication", "communicationFrom", "communicationTo"],
          { all: ["communication", "communicationFrom", "communicationTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "communicationFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.communicationFrom || "",
              },
              {
                key: "communicationTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.communicationTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "communication",
                "-communication",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "communication",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-communication",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.communicationTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "communicationTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.communicationTenacity"),
        activeGroupKey: getActiveGroup(
          [
            "communicationTenacity",
            "communicationTenacityFrom",
            "communicationTenacityTo",
          ],
          {
            all: [
              "communicationTenacity",
              "communicationTenacityFrom",
              "communicationTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "communicationTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.communicationTenacityFrom || "",
              },
              {
                key: "communicationTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.communicationTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "communicationTenacity",
                "-communicationTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "communicationTenacity",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-communicationTenacity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.analytics.short"),
      description: t("table.header.analytics"),
      filterTitle: t("table.header.analytics"),
      type: EDataGridColumnType.STRING,
      key: "analytics",
      showed: true,
      controlPanel: {
        title: t("table.header.analytics"),
        activeGroupKey: getActiveGroup(
          ["analytics", "analyticsFrom", "analyticsTo"],
          { all: ["analytics", "analyticsFrom", "analyticsTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "analyticsFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.analyticsFrom || "",
              },
              {
                key: "analyticsTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.analyticsTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "analytics",
                "-analytics",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "analytics",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-analytics",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.analyticsTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "analyticsTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.analyticsTenacity"),
        activeGroupKey: getActiveGroup(
          ["analyticsTenacity", "analyticsTenacityFrom", "analyticsTenacityTo"],
          {
            all: [
              "analyticsTenacity",
              "analyticsTenacityFrom",
              "analyticsTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "analyticsTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.analyticsFrom || "",
              },
              {
                key: "analyticsTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.analyticsTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "analyticsTenacity",
                "-analyticsTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "analyticsTenacity",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-analyticsTenacity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.command.short"),
      description: t("table.header.command"),
      filterTitle: t("table.header.command"),
      type: EDataGridColumnType.STRING,
      key: "command",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.command"),
        activeGroupKey: getActiveGroup(
          ["command", "commandFrom", "commandTo"],
          { all: ["command", "commandFrom", "commandTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "commandFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.commandFrom || "",
              },
              {
                key: "commandTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.commandTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "command",
                "-command",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "command",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-command",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.commandTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "commandTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.commandTenacity"),
        activeGroupKey: getActiveGroup(
          ["commandTenacity", "commandTenacityFrom", "commandTenacityTo"],
          {
            all: [
              "commandTenacity",
              "commandTenacityFrom",
              "commandTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "commandTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.commandTenacityFrom || "",
              },
              {
                key: "commandTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.commandTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "commandTenacity",
                "-commandTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "commandTenacity",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-commandTenacity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.thinking3d.short"),
      description: t("table.header.thinking3d"),
      filterTitle: t("table.header.thinking3d"),
      type: EDataGridColumnType.STRING,
      key: "thinking3d",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.command"),
        activeGroupKey: getActiveGroup(
          ["thinking3d", "thinking3dFrom", "thinking3dTo"],
          { all: ["thinking3d", "thinking3dFrom", "thinking3dTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "thinking3dFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.thinking3dFrom || "",
              },
              {
                key: "thinking3dTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.thinking3dTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "thinking3d",
                "-thinking3d",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "thinking3d",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-thinking3d",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.thinking3dTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "thinking3dTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.thinking3dTenacity"),
        activeGroupKey: getActiveGroup(
          [
            "thinking3dTenacity",
            "thinking3dTenacityFrom",
            "thinking3dTenacityTo",
          ],
          {
            all: [
              "thinking3dTenacity",
              "thinking3dTenacityFrom",
              "thinking3dTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "thinking3dTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.thinking3dTenacityFrom || "",
              },
              {
                key: "thinking3dTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.commandTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "thinking3dTenacity",
                "-thinking3dTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "thinking3dTenacity",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-thinking3dTenacity",
                },
              ],
            },
          },
        ],
      },
    },

    {
      title: t("table.header.economics.short"),
      description: t("table.header.economics"),
      filterTitle: t("table.header.economics"),
      type: EDataGridColumnType.STRING,
      key: "economics",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.command"),
        activeGroupKey: getActiveGroup(
          ["economics", "economicsFrom", "economicsTo"],
          { all: ["economics", "economicsFrom", "economicsTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "economicsFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.economicsFrom || "",
              },
              {
                key: "economicsTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.economicsTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "economics",
                "-economics",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "economics",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-economics",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.economicsTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "economicsTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.economicsTenacity"),
        activeGroupKey: getActiveGroup(
          ["economicsTenacity", "economicsTenacityFrom", "economicsTenacityTo"],
          {
            all: [
              "economicsTenacity",
              "economicsTenacityFrom",
              "economicsTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "economicsTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.economicsTenacityFrom || "",
              },
              {
                key: "economicsTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.commandTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "economicsTenacity",
                "-economicsTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "economicsTenacity",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-economicsTenacity",
                },
              ],
            },
          },
        ],
      },
    },

    {
      title: t("table.header.jurisprudence.short"),
      description: t("table.header.jurisprudence"),
      filterTitle: t("table.header.jurisprudence"),
      type: EDataGridColumnType.STRING,
      key: "jurisprudence",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.command"),
        activeGroupKey: getActiveGroup(
          ["jurisprudence", "jurisprudenceFrom", "jurisprudenceTo"],
          { all: ["jurisprudence", "jurisprudenceFrom", "jurisprudenceTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "jurisprudenceFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.jurisprudenceFrom || "",
              },
              {
                key: "jurisprudenceTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.jurisprudenceTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "jurisprudence",
                "-jurisprudence",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "jurisprudence",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-jurisprudence",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.jurisprudenceTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "jurisprudenceTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.jurisprudenceTenacity"),
        activeGroupKey: getActiveGroup(
          [
            "jurisprudenceTenacity",
            "jurisprudenceTenacityFrom",
            "jurisprudenceTenacityTo",
          ],
          {
            all: [
              "jurisprudenceTenacity",
              "jurisprudenceTenacityFrom",
              "jurisprudenceTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "jurisprudenceTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.jurisprudenceTenacityFrom || "",
              },
              {
                key: "jurisprudenceTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.commandTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "jurisprudenceTenacity",
                "-jurisprudenceTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "jurisprudenceTenacity",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-jurisprudenceTenacity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.registration"),
      type: EDataGridColumnType.STRING,
      key: "created",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.header.registration"),
        activeGroupKey: getActiveGroup(
          ["created"],
          { all: ["created"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "createdFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.createdFrom || "",
              },
              {
                key: "createdTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.createdTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "created",
                "-created",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "created",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-created",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.lastActivity"),
      type: EDataGridColumnType.STRING,
      key: "lastActivity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.header.lastActivity"),
        activeGroupKey: getActiveGroup(
          ["lastActivity"],
          { all: ["lastActivity"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "lastActivityFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.lastActivityFrom || "",
              },
              {
                key: "lastActivityTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.lastActivityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "lastActivity",
                "-lastActivity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "lastActivity",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-lastActivity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.lastPlay"),
      type: EDataGridColumnType.STRING,
      key: "lastPlay",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.header.lastPlay"),
        activeGroupKey: getActiveGroup(
          ["lastPlay"],
          { all: ["lastPlay"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "lastPlayFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.from"),
                value: queryParams?.lastPlayFrom || "",
              },
              {
                key: "lastPlayTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: t("table.filters.to"),
                value: queryParams?.lastPlayTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "lastPlay",
                "-lastPlay",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.ascending"),
                  value: "lastPlay",
                },
                {
                  title: t("table.filters.descending"),
                  value: "-lastPlay",
                },
              ],
            },
          },
        ],
      },
    },

    {
      title: t("userForm.inputLabel.country"),
      type: EDataGridColumnType.STRING,
      key: "country",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.country"),
        activeGroupKey: getActiveGroup(
          ["country"],
          { all: ["country"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "country",
                type: EDataGridFilterType.SELECT,
                items: countriesToSelectItemsAdapter(places.countries),
                placeholder: t("common.enterValue"),
                value: queryParams?.country || "",
                canSearch: true,
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "country",
                "-country",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "country",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-country",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("userForm.inputLabel.region"),
      type: EDataGridColumnType.STRING,
      key: "region",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.region"),
        activeGroupKey: getActiveGroup(
          ["region"],
          { all: ["region"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "region",
                type: EDataGridFilterType.SELECT,
                items: regionsToSelectItemsAdapter(places.regions),
                placeholder: t("common.enterValue"),
                value: queryParams?.region || "",
                canSearch: true,
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "region",
                "-region",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "region",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-region",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("userForm.inputLabel.district"),
      type: EDataGridColumnType.STRING,
      key: "district",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.district"),
        activeGroupKey: getActiveGroup(
          ["district"],
          { all: ["district"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "district",
                type: EDataGridFilterType.SELECT,
                items: districtsToSelectItemsAdapter(places.districts),
                placeholder: t("common.enterValue"),
                value: queryParams?.district || "",
                canSearch: true,
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "district",
                "-district",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "district",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-district",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("userForm.inputLabel.school"),
      type: EDataGridColumnType.STRING,
      key: "school",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.school"),
        activeGroupKey: getActiveGroup(
          ["school"],
          { all: ["school"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "school",
                type: EDataGridFilterType.SELECT,
                items: schoolsToSelectItemsAdapter(places.schools),
                placeholder: t("common.enterValue"),
                value: queryParams?.school || "",
                canSearch: true,
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "school",
                "-school",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "school",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-school",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("userForm.inputLabel.locality"),
      type: EDataGridColumnType.STRING,
      key: "locality",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.locality"),
        activeGroupKey: getActiveGroup(
          ["locality"],
          { all: ["locality"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "locality",
                "-locality",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "locality",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-locality",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.settings.checkboxLabel.studyWorkPlace"),
      type: EDataGridColumnType.STRING,
      key: "studyWorkPlace",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.studyWorkPlace"),
        activeGroupKey: getActiveGroup(
          ["studyWorkPlace"],
          { all: ["studyWorkPlace"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "studyWorkPlace",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.studyWorkPlace || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "studyWorkPlace",
                "-studyWorkPlace",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "studyWorkPlace",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-studyWorkPlace",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.desiredStudyPlace"),
      type: EDataGridColumnType.STRING,
      key: "desiredStudyPlace",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: t("table.header.desiredStudyPlace"),
        activeGroupKey: getActiveGroup(
          ["desiredStudyPlace"],
          { all: ["desiredStudyPlace"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "desiredStudyPlace",
                type: EDataGridFilterType.CHECKBOX,
                items: desiredStudyPlaceList,
                placeholder: t("common.enterValue"),
                value: queryParams?.desiredStudyPlace || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("userForm.inputLabel.desiredSpecialty"),
      type: EDataGridColumnType.STRING,
      key: "desiredSpecialty",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.desiredSpecialty"),
        activeGroupKey: getActiveGroup(
          ["desiredSpecialty"],
          { all: ["desiredSpecialty"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "desiredSpecialty",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.desiredSpecialty || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("navigation.tournaments"),
      type: EDataGridColumnType.STRING,
      key: "tournaments",
      showed: true,
      noPaddingLeft: true,
    },
  ];
};
