import { sample } from "effector";

import { Socket } from "socket.io-client";

import { namespaces, paths, urls } from "../config";

import { connectSocket, SocketParams } from "../../lib/connect";

import { socketsV4Domain } from "./sockets";

import { LobbyPlayerSocketCommander } from "./commands";

import { RoomPlayerSocketHandler } from "./events";

// Store

export const $socketGameV4RoomPlayer =
  socketsV4Domain.createStore<Socket | null>(null);

export const $socketGameV4RoomPlayerCommander =
  socketsV4Domain.createStore<LobbyPlayerSocketCommander | null>(null);

export const $socketGameV4RoomPlayerHandler =
  socketsV4Domain.createStore<RoomPlayerSocketHandler | null>(null);

// Effects

const initGameV4RoomPlayerFx = socketsV4Domain.createEffect(connectSocket);

// Events

export const initGameV4RoomPlayer = socketsV4Domain.createEvent();

// Logic

sample({
  clock: initGameV4RoomPlayer,
  fn: (): SocketParams => ({
    path: paths.game.v4,
    url: urls.game,
    namespace: namespaces.game.room.player,
  }),
  target: initGameV4RoomPlayerFx,
});

sample({
  clock: initGameV4RoomPlayerFx.doneData,
  target: $socketGameV4RoomPlayer,
});

sample({
  clock: $socketGameV4RoomPlayer,
  fn: (socket) => socket && LobbyPlayerSocketCommander.of(socket),
  target: $socketGameV4RoomPlayerCommander,
});

sample({
  clock: $socketGameV4RoomPlayer,
  fn: (socket) => socket && RoomPlayerSocketHandler.of(socket),
  target: $socketGameV4RoomPlayerHandler,
});
