import {
  Competence,
  GameType,
  LocStrDto,
  PLvlUpCompetence,
  PLvlUpInfo,
} from "src/generated/game";

import { Player } from "src/generated/social";

import { languagePicker } from "src/shared/helpers";

import { ELanguage, ISimulationPlayer } from "src/shared/store/types";

import {
  IGameTypeAvailabilityItem,
  IGameTypeControlItem,
  ILvlUpGameTypeItem,
  ILvlUpSimulationItem,
} from "../model/types";

const gameTypeKeys = Object.values(GameType) as GameType[];

const competenceKeys = [
  Competence.Communication,
  Competence.Analytics,
  Competence.Command,
  Competence.Thinking3d,
  Competence.Economics,
  Competence.Jurisprudence,
  Competence.Microelectronics,
];

type PLvlUpInfoArray = Array<{ [key: string]: PLvlUpInfo }>;

const getCompetenceAvailability = (
  currentGameTypeContent: PLvlUpInfo,
  social: Player | null,
): IGameTypeAvailabilityItem[] => {
  return competenceKeys.reduce(
    (final: IGameTypeAvailabilityItem[], curr: Competence) => {
      const currentCompetence = currentGameTypeContent.rateBounds?.find(
        ({ competence }) => competence === curr,
      );

      if (currentCompetence) {
        const currSocialCompetence = social?.competenceRates?.find(
          ({ competence }) => competence === curr,
        );

        const currCompetenceMin: number = currentCompetence.min || 0;

        const currSocialCompetenceRate: number =
          currSocialCompetence?.rate || 0;

        const competenceAvail: boolean =
          currSocialCompetenceRate >= currCompetenceMin;

        const available: boolean =
          currentCompetence.avail !== undefined
            ? currentCompetence.avail
            : competenceAvail;

        final.push({
          competence: curr,
          available,
          value: currCompetenceMin,
        });
      }

      return final;
    },
    [],
  );
};

const getControlsItems = (
  competences: PLvlUpCompetence[],
  competenceAvailability: IGameTypeAvailabilityItem[],
): IGameTypeControlItem[] =>
  competenceKeys.reduce((final: IGameTypeControlItem[], curr: Competence) => {
    const isIncludesCompetence: boolean = final.some(({ combined }) =>
      combined.includes(curr),
    );

    if (isIncludesCompetence) {
      return final;
    }

    const currentCompetences = competences.find(
      ({ combined }) => combined?.includes(curr),
    );

    const tenacity: Competence = `${curr}Tenacity` as Competence;

    const hasCurrTenacityCompetence: boolean = competences.some(
      ({ combined }) => combined?.includes(tenacity),
    );

    if (currentCompetences) {
      const availability = !!currentCompetences.combined?.every(
        (competence) => {
          const currCompetence = competenceAvailability.find(
            (item) => item.competence === competence,
          );

          return currCompetence?.available;
        },
      );

      final.push({
        combined: currentCompetences.combined || [],
        availability,
        tenacity: hasCurrTenacityCompetence ? tenacity : undefined,
      });
    }

    return final;
  }, []);

const getGameTypes = (
  lvlUps: PLvlUpInfoArray = [],
  social: Player | null,
  gameRules?: Record<string, LocStrDto>,
): ILvlUpGameTypeItem[] =>
  gameTypeKeys.reduce((final: ILvlUpGameTypeItem[], curr: GameType) => {
    const currentGameTypes: PLvlUpInfoArray = lvlUps?.filter((lvlUp) => {
      const { gType } = Object.values(lvlUp)[0];

      return gType === curr;
    });

    const currentGameTypeContents: PLvlUpInfoArray | null =
      currentGameTypes.length ? currentGameTypes : null;

    const gameRule = gameRules?.[curr];

    if (currentGameTypeContents) {
      currentGameTypeContents.forEach((content) => {
        const gameTitle = Object.keys(content)[0];

        const currentGameTypeContent = Object.values(content)[0];

        const competenceAvailability: IGameTypeAvailabilityItem[] =
          getCompetenceAvailability(currentGameTypeContent, social);

        const competenceControls: IGameTypeControlItem[] = getControlsItems(
          currentGameTypeContent.competences || [],
          competenceAvailability,
        );

        final.push({
          gameSubType: gameTitle,
          gameType: curr,
          availability: currentGameTypeContent.avail || false,
          competenceAvailability,
          competenceControls,
          gameRule,
        });
      });
    }

    return final;
  }, []);

export const getLvlUpItemsAdapter = (
  simulations: ISimulationPlayer[],
  social: Player | null,
  lang: ELanguage,
): ILvlUpSimulationItem[] => {
  return simulations
    .filter(({ isVisibleForPlayers }) => isVisibleForPlayers)
    .map(({ id, logoUrl, title, lvlUps, desc, gameRules }) => {
      const imgUrl: string = languagePicker(logoUrl, lang);

      const simulationTitle: string = languagePicker(title, lang);

      // ToDo: {HRP-199} добавить перевод для правил симуляции и режимов. Сделать languagePicker
      const simulationRules = desc;

      const gameTypes: ILvlUpGameTypeItem[] = getGameTypes(
        lvlUps,
        social,
        gameRules,
      );

      const availability: boolean = gameTypes.some(
        ({ availability }) => availability,
      );

      return {
        id,
        imgUrl,
        simulationTitle,
        simulationRules,
        gameTypes,
        availability,
      };
    });
};
