import { UserRoleEnum } from "src/shared/models/user";

import { $keycloak } from "./keycloak";

export const $clientRoles = $keycloak.map(
  (state) => state?.realmAccess?.roles || [],
);

export const $isPlayerRole = $clientRoles.map((state) =>
  state.includes(UserRoleEnum.PLAYER),
);

export const $isHrStaffRole = $clientRoles.map((state) =>
  state.includes(UserRoleEnum.HR_STAFF),
);

export const $isHrAlabugaRole = $clientRoles.map((state) =>
  state.includes(UserRoleEnum.HR_ALABUGA),
);

export const $isHrAdminRole = $clientRoles.map((state) =>
  state.includes(UserRoleEnum.HR_ADMIN),
);
