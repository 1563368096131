import { createEvent, sample } from "effector";

import { emailNotificationDomain } from "./email-notification";

import {
  resetActiveRadioValue,
  resetCopySelectedIds,
  resetSelectedIds,
} from "src/pages/other/select-players-page/model";

// Events

export const resetEmailTheme = emailNotificationDomain.createEvent();

export const resetEmailText = emailNotificationDomain.createEvent();

export const resetNotification = createEvent();

// Logic

sample({
  clock: resetNotification,
  target: [
    resetEmailText,
    resetEmailTheme,
    resetSelectedIds,
    resetCopySelectedIds,
    resetActiveRadioValue,
  ],
});
