import { FC, ReactElement, useEffect, useState } from "react";
import { PageWrapper } from "src/shared/components";
import { useTranslation } from "react-i18next";
import {
  BaseButton,
  ConfirmPopup,
  ContactCard,
  TextWithIcon,
} from "../../../shared/components";
import "./edit-contacts-page.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanContacts,
  requestContactDelete,
  requestContactsGet,
  successContactsGet,
} from "../../../shared/store/ducks/contacts/actionCreators";
import {
  selectContactsItems,
  selectContactsItemsStatusGet,
  selectContactsItemStatusDelete,
} from "../../../shared/store/ducks/contacts/selectors";
import { Manager } from "../../../generated/social";
import { ERequestStatus } from "../../../shared/store/types";
import { requestDeleteContactAvatar } from "../../../shared/store/ducks/cdn/actionCreators";
import { selectCdnContactAvatarStatus } from "../../../shared/store/ducks/cdn/selectors";
import { EPopupName, popupModel } from "src/shared/components/base-popup";

interface Props {}

const { openPopup, closePopup } = popupModel;

export const EditContactsPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector(selectContactsItems);
  const loadingStatusDelete = useSelector(selectContactsItemStatusDelete);
  const loadingStatusGet = useSelector(selectContactsItemsStatusGet);
  const loadingStatusAvatar = useSelector(selectCdnContactAvatarStatus);
  const isLoadingDelete: boolean =
    loadingStatusDelete === ERequestStatus.LOADING;
  const isLoadingAvatar: boolean =
    loadingStatusAvatar === ERequestStatus.LOADING;
  const isLoadingGet: boolean = loadingStatusGet === ERequestStatus.LOADING;
  const isLoadedGet: boolean = loadingStatusGet === ERequestStatus.LOADED;
  const [deletedItemId, setDeletedItemId] = useState<string>("");

  useEffect(() => {
    dispatch(requestContactsGet());

    return () => {
      dispatch(cleanContacts());
    };
  }, [dispatch]);

  const onDeleteHandler = (): void => {
    const deletedItem: Manager | undefined = items.find(
      (item) => item.id === deletedItemId,
    );
    if (deletedItem) {
      const id: string = deletedItem.photo;

      const deleteContactCallback = () => {
        const filteredItems: Manager[] = items.filter(
          (item) => item.id !== deletedItemId,
        );

        closePopup({ name: EPopupName.CONFIRM_DELETE_CONTACT_ITEM });

        dispatch(successContactsGet(filteredItems));
      };

      const deleteAvatarCallback = () => {
        dispatch(
          requestContactDelete({
            options: {
              mid: deletedItemId,
            },
            callback: deleteContactCallback,
          }),
        );
      };

      dispatch(
        requestDeleteContactAvatar({
          options: {
            id,
          },
          callback: deleteAvatarCallback,
        }),
      );
    }
  };

  const onOpenConfirmPopupHandler = (id: string) => {
    setDeletedItemId(id);

    openPopup({ name: EPopupName.CONFIRM_DELETE_CONTACT_ITEM });
  };

  return (
    <PageWrapper
      title={t("other.editContacts.pageTitle.contacts")}
      isLoadingPanel={isLoadingGet}
      controlPanelTitle={t("other.editContacts.description.contacts")}
      controlPanelMaxCount={items.length}
      controlPanelSlot={
        <BaseButton to="/other/add-contact" lightBlue small>
          <TextWithIcon
            iconName="plus-blue"
            label={t("common.add")}
            labelSize={16}
          />
        </BaseButton>
      }
    >
      {isLoadedGet && (
        <div className="edit-contacts-page">
          <div className="edit-contacts-page__items">
            {items.map((item) => (
              <ContactCard
                key={item.id}
                contactItem={item}
                onDelete={onOpenConfirmPopupHandler}
              />
            ))}
          </div>
        </div>
      )}
      <ConfirmPopup
        popupName={EPopupName.CONFIRM_DELETE_CONTACT_ITEM}
        title={t("other.editContacts.popup.confirmDelete.title")}
        description={t("other.editContacts.popup.confirmDelete.description")}
        onConfirm={onDeleteHandler}
        confirmBtnLabel={t("other.editContacts.btn.delete")}
        isConfirmBtnDisabled={isLoadingDelete || isLoadingAvatar}
        reverse
      />
    </PageWrapper>
  );
};
