import { useTranslation } from "react-i18next";
import { User } from "src/generated/keycloak";
import { getAvatarUrl, splitStrings } from "src/shared/helpers";
import { EDataGridTextColor, IDataGridRow } from "src/shared/models";
import { UserInfoInterface } from "src/shared/store/types";

export const useGenerateParticipantsRows = () => {
  const { t } = useTranslation();

  return (users: UserInfoInterface[] | User[]): IDataGridRow[] =>
    users.map((user) => {
      const hasResume: boolean = !!user?.attributes?.resumeFileName?.[0];

      const fullName = splitStrings([user.firstName, user.lastName]);

      return {
        id: user.id ?? "",
        fullName,
        phoneNumber: user.attributes?.phoneNumber,
        email: user.email,
        locality: user.attributes?.locality,
        birthdate: user.attributes?.birthdate,
        resume: hasResume,
        studyWorkPlace: user.attributes?.studyWorkPlace,
        desiredStudyPlace: user.attributes?.desiredStudyPlace?.[0]
          ? t(
              `userForm.select.desiredStudyPlace.${user.attributes.desiredStudyPlace[0]}`,
              user.attributes.desiredStudyPlace[0],
            )
          : null,
        activity: undefined,
        selected: false,
        avatarProps: {
          alt: fullName,
          customPath: getAvatarUrl(user.id),
          userId: user.id,
          link: `/users-control/user/${user.id}/info`,
        },
        columnsTextColor: {
          email: !user.emailVerified ? EDataGridTextColor.RED : null,
        },
      };
    });
};
