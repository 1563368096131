import { sample } from "effector";

import {
  TUserSettingsTables,
  userSettingsModel,
} from "src/entities/public/user-settings";

import { setColumnsVisibilityHandler } from "../lib";

import { userSettingsDomain } from "./domain";

import { ISetColumnsVisibilityPayload } from "./types";

import { defaultTablesSettings } from "./defaults";

const { $userSettings } = userSettingsModel;

// Stores

export const $tablesSettings =
  userSettingsDomain.createStore<TUserSettingsTables | null>(
    defaultTablesSettings,
  );

export const $preTablesSettings =
  userSettingsDomain.createStore<TUserSettingsTables | null>(
    defaultTablesSettings,
  );

// Events

export const setColumnsVisibility =
  userSettingsDomain.createEvent<ISetColumnsVisibilityPayload>();

// Logic

sample({
  clock: $userSettings,
  fn: (userSettings) => {
    const tables = userSettings?.tables || {};

    return { ...defaultTablesSettings, ...tables };
  },
  target: [$tablesSettings, $preTablesSettings],
});

sample({
  clock: setColumnsVisibility,
  source: $preTablesSettings,
  fn: (tables, visibility) =>
    setColumnsVisibilityHandler(tables, visibility) as TUserSettingsTables,
  target: $preTablesSettings,
});
