import axios from "axios";
import { UserInfoInterface } from "src/shared/store/types";

export const postProfileInfo = async (payload: {
  url: string;
  token: string | null;
  user: UserInfoInterface;
}) => {
  const { data } = await axios.post(payload.url, payload.user, {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + payload.token,
    },
  });

  return data;
};
