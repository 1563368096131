import { FC } from "react";

import classNames from "classnames";

import { t } from "src/shared/helpers";

import { BaseButton, TextWithIcon } from "src/shared/components";

import {
  BaseCardWrapper,
  Props as WrapperProps,
} from "../base-card-wrapper/base-card-wrapper";

import "./assessment-card.scss";

type Badges = "wait" | "launched" | "completed" | "results";

type AssessmentResult = {
  role: string;
  place: string;
  communication: number;
  analytics: number;
  command: number;
};

type UnionProps = WrapperProps & {
  simulationName: string;
  networks?: {
    vkLink?: string;
  };
};

interface AssessmentProps extends UnionProps {
  name: string;
  isOpened: boolean;
  isRated: boolean;
  isCompleted?: boolean;
  isRequired?: boolean;
  isNewMode?: never;
  mode?: never;
  badgeType?: Badges;
  connectLink?: string;
  assessmentResult?: AssessmentResult;
}

interface ModeProps extends UnionProps {
  name?: never;
  isOpened?: never;
  isRated?: never;
  isCompleted?: never;
  isRequired?: boolean;
  isNewMode: boolean;
  mode: string;
  badgeType?: never;
  connectLink?: never;
  assessmentResult?: never;
}

const formatAssessmentResult = (assessmentResult: AssessmentResult) => {
  type FormattedResult = {
    key: string;
  } & (
    | { value: number; isEmpty?: never; isNegative?: boolean }
    | {
        value: number;
        isEmpty?: boolean;
        isNegative?: never;
      }
    | { value: string; isEmpty?: never; isNegative?: never }
  );

  return Object.entries(assessmentResult).reduce((result, [key, value]) => {
    if (typeof value === "string") {
      result.push({ key, value });
      return result;
    }

    const isNegative = value < 0;

    const isEmpty = value === 0;

    const payload = {
      key,
      value,
      ...(Boolean(isEmpty) ? { isEmpty } : { isNegative }),
    };

    result.push(payload);

    return result;
  }, [] as FormattedResult[]);
};

const getBadgeLocaleTitle = (badgeType: Badges) => {
  const text = {
    wait: "notifications.panel.card.badge.wait",
    launched: "notifications.panel.card.badge.launched",
    completed: "notifications.panel.card.badge.completed",
    results: "notifications.panel.card.badge.results",
  }[badgeType];

  return t(text);
};

export const AssessmentCard: FC<AssessmentProps | ModeProps> = ({
  simulationName,
  name,
  isOpened,
  isRated,
  isCompleted,
  isNewMode,
  isRequired = false,
  mode,
  badgeType,
  connectLink,
  networks,
  whenDate,
  cardTitle,
  logoUrl,
  assessmentResult,
}) => {
  const isOpenedOrRatedExist = (isOpened ?? isRated) !== undefined;

  const isNetworksExist = networks && Object.values(networks).length > 0;

  const isConnectAlertShowed = name && !connectLink && !isNetworksExist;

  return (
    <BaseCardWrapper
      cardTitle={cardTitle}
      logoUrl={logoUrl}
      whenDate={whenDate}
    >
      <div className="assessment-card">
        <section className="assessment-card-names">
          <p className="assessment-card-names__simulation-name">
            {simulationName}
          </p>
          <span className="assessment-card-names__name">{name}</span>
        </section>

        {isRequired && (
          <section className="assessment-card__participation">
            <h6 className="assessment-card__participation-title">
              {t("notifications.panel.card.participation.title")}
            </h6>
            <p className="assessment-card__participation-description">
              {t("notifications.panel.card.participation.description")}
            </p>
          </section>
        )}

        {isOpenedOrRatedExist && (
          <section className="assessment-card-statuses">
            <span className="assessment-card-statuses__rated">
              {isRated
                ? t("notifications.panel.card.status.ratings")
                : t("notifications.panel.card.status.not.ratings")}
            </span>
            {!isCompleted && (
              <span className="assessment-card-statuses__opened">
                {isOpened
                  ? t("notifications.panel.card.status.open")
                  : t("notifications.panel.card.status.close")}
              </span>
            )}
          </section>
        )}

        {mode && (
          <section className="assessment-card-modes">
            <p className="assessment-card-modes__title">
              {isNewMode
                ? t("notifications.panel.card.mode.new")
                : t("notifications.panel.card.mode.accessible")}
            </p>

            <span className="assessment-card-modes__mode">{mode}</span>
          </section>
        )}

        {connectLink && (
          <BaseButton
            className="assessment-card__connect-button"
            primary
            small
            to={connectLink}
          >
            {t("notifications.panel.card.connect.btn")}
          </BaseButton>
        )}

        {isConnectAlertShowed && (
          <p className="assessment-card__connect-alert">
            {t("notifications.panel.card.alert")}
          </p>
        )}

        {isNetworksExist && (
          <div className="assessment-card-share">
            <p className="assessment-card-share__text">
              {t("notifications.panel.card.share")}
            </p>
            <div className="assessment-card-share__buttons-group">
              {networks?.vkLink && (
                <BaseButton
                  className="assessment-card-share__button"
                  to={networks.vkLink}
                >
                  <TextWithIcon iconName="vk-blue" />
                </BaseButton>
              )}
            </div>
          </div>
        )}

        <div className="assessment-card-results">
          {badgeType && (
            <p
              className={`assessment-card-badge assessment-card-badge--${badgeType}`}
            >
              {getBadgeLocaleTitle(badgeType)}
            </p>
          )}

          {assessmentResult && (
            <div className="assessment-card-results__popup card-results-popup">
              {formatAssessmentResult(assessmentResult).map((result) => (
                <div className="card-results-popup__row card-popup-row">
                  <p className="card-popup-row__title">
                    {t(`notifications.panel.card.results.${result.key}`)}
                  </p>
                  <p
                    className={classNames("card-popup-row__value", {
                      "card-popup-row__value--red": result.isNegative,
                      "card-popup-row__value--green":
                        !result.isNegative && !result.isEmpty,
                      "card-popup-row__value--empty": result.isEmpty,
                    })}
                  >
                    {result.value || "—"}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BaseCardWrapper>
  );
};
