import classNames from "classnames";
import { FC, ReactElement } from "react";
import "./base-error.scss";

interface Props {
  type: "danger" | "warning" | "info" | "description";
  text: string;
  className?: string;
}

export const BaseError: FC<Props> = ({
  type,
  text,
  className,
}): ReactElement => {
  return (
    <div className={classNames(`base-error base-error--${type}`, className)}>
      <span className={`base-error__icon base-error__icon--${type}`} />
      {text}
    </div>
  );
};
