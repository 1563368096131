import {
  Children,
  cloneElement,
  FC,
  forwardRef,
  isValidElement,
  ReactElement,
  ReactNode,
  ReactText,
  Ref,
  useEffect,
  useState,
} from "react";

import classNames from "classnames";

import { BaseBox } from "src/shared/components";

import "react-quill/dist/quill.snow.css";

import "./base-accordion.scss";

interface Props {
  title?: ReactElement | ReactText;
  fullWidth?: boolean;
  roundTitle?: boolean;
  titleAlignLeft?: boolean;
  withBorder?: boolean;
  withShadow?: boolean;
  noShadow?: boolean;
  className?: string;
  headerSlot?: ReactElement;
  headerTheme?: "dark" | "light-green" | "light-red";
  containerRef?: Ref<HTMLDivElement>;
  children?: ReactNode;
  lang?: React.HTMLAttributes<
    HTMLHeadingElement | HTMLParagraphElement
  >["lang"];
  classNameTitle?: string;
  arrowOnLeft?: boolean;
  overrideActiveState?: boolean;
  isOver?: boolean;
}

export const BaseAccordion: FC<Props> = ({
  title,
  fullWidth,
  roundTitle,
  titleAlignLeft,
  withBorder,
  withShadow,
  headerSlot,
  className,
  children,
  containerRef,
  headerTheme = "dark",
  lang,
  noShadow = false,
  classNameTitle,
  arrowOnLeft = false,
  overrideActiveState,
  isOver,
}): ReactElement => {
  const [isActive, setIsActive] = useState<boolean>(
    overrideActiveState ?? false,
  );

  useEffect(() => {
    if (overrideActiveState !== undefined) {
      setIsActive(overrideActiveState);
    }
  }, [overrideActiveState]);

  const toggleAccordion = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <BaseBox
      className={classNames("base-accordion", className, {
        "base-accordion--full-width": fullWidth,
        "base-accordion--with-shadow": withShadow,
      })}
      noPadding
      noShadow={noShadow}
      ref={containerRef}
    >
      <h2
        className={classNames("base-accordion__title", classNameTitle, {
          "base-accordion__title--active": isActive,
          [`base-accordion__title--${headerTheme}`]: headerTheme,
          [`base-accordion__title--${headerTheme}--is-over`]:
            headerTheme && isOver,
          "base-accordion__title--with-border": withBorder,
          "base-accordion__title--is-over": isOver,
          "base-accordion__title--rounded": roundTitle && !isActive,
          "base-accordion__title--align-left": titleAlignLeft,
          "base-accordion__title--arrow-on-left": arrowOnLeft,
        })}
        onClick={toggleAccordion}
        lang={lang}
      >
        {title}
        {headerSlot}
      </h2>
      {isActive &&
        Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, { lang } as any);
          }
          return child;
        })}
    </BaseBox>
  );
};

export const BaseAccordionWithRef = forwardRef<HTMLDivElement, Props>(
  (props, ref) => <BaseAccordion {...props} containerRef={ref} />,
);
