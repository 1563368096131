import { AxiosResponse } from "axios";

import { combine, createDomain, sample } from "effector";

import fileDownload from "js-file-download";

import format from "date-fns/format";

import {
  exportRatingsCSV,
  exportRatingsExcel,
} from "src/shared/api/hr/export-rates";

import {
  ExportFromSocialApiExportRatingToCSVRequest,
  ExportFromSocialApiExportRatingToXLSXRequest,
} from "src/generated/export";

import { t } from "src/shared/helpers";

import { EPopupName } from "src/shared/components";

import { openPopup } from "src/shared/components/base-popup/model";
import {
  exportAssessmentHistoryCSV,
  exportAssessmentHistoryXLSX,
  exportHistoryCSV,
  exportHistoryXLSX,
  GetCsvAssessmentHistoryParams,
  GetXlsxAssessmentHistoryParams,
  exportAssessmentsStoryXLSX,
  exportAssessmentsStoryCSV,
} from "../../../../shared/api/hr/export-history";
import {
  $preTablesSettings,
  $tablesSettings,
} from "../../../../features/public/user-settings/model";
import { queryParamsToGetListParamsAdapter } from "./queryParamsToGetListParamsAdapter";
import { QueryParams } from "../../../../shared/api/base";
import { getAdditionalCompetences } from "../../../../shared/helpers/getAdditionalCompetences";
import {
  ExportApiExportAssessmentsStoryToCsvRequest,
  ExportApiExportAssessmentsStoryToXlsxRequest,
} from "../../../../generated/statistic";

export type Params =
  | ExportFromSocialApiExportRatingToXLSXRequest
  | ExportFromSocialApiExportRatingToCSVRequest
  | null;

const handleFileResponse = (response: AxiosResponse<any>) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

  const contentDisposition = filenameRegex.exec(
    response.headers["content-disposition"],
  );

  if (contentDisposition?.[1]) {
    const fileName = contentDisposition[1].replace(/['"]/g, "");

    fileDownload(response.data, fileName);

    return;
  }

  const formattedDate = format(new Date(), "yyyy-MM-dd");

  const fileName = `export.${formattedDate}.ratings.zip`;

  fileDownload(response.data, fileName);
};

const showError = () =>
  openPopup({
    message: { text: t("alert.requestError"), isError: true },
    name: EPopupName.BASE_MESSAGE_POPUP,
  });

const exportRatesDomain = createDomain("exportRatesDomain");

// Effects

export const exportCsv = exportRatesDomain.createEvent();

export const exportXlsx = exportRatesDomain.createEvent();

export const setParams = exportRatesDomain.createEvent<QueryParams>();

export const exportHistoryCsv = exportRatesDomain.createEvent<any>();

export const exportHistoryXlsx = exportRatesDomain.createEvent<any>();

export const exportAssessmentHistoryCsv =
  exportRatesDomain.createEvent<GetCsvAssessmentHistoryParams>();

export const exportAssessmentHistoryXlsx =
  exportRatesDomain.createEvent<GetXlsxAssessmentHistoryParams>();

export const exportAssessmentsStoryXlsx =
  exportRatesDomain.createEvent<ExportApiExportAssessmentsStoryToXlsxRequest>();

export const exportAssessmentsStoryCsv =
  exportRatesDomain.createEvent<ExportApiExportAssessmentsStoryToCsvRequest>();

// Effects

const exportCsvFx = exportRatesDomain.createEffect(exportRatingsCSV);

const exportXlsxFx = exportRatesDomain.createEffect(exportRatingsExcel);

const exportAssessmentHistoryCsvFx = exportRatesDomain.createEffect(
  exportAssessmentHistoryCSV,
);

const exportAssessmentHistoryXlsxFx = exportRatesDomain.createEffect(
  exportAssessmentHistoryXLSX,
);
const exportAssessmentsStoryXlsxFx = exportRatesDomain.createEffect(
  exportAssessmentsStoryXLSX,
);

const exportAssessmentsStoryCsvFx = exportRatesDomain.createEffect(
  exportAssessmentsStoryCSV,
);

export const exportHistoryCsvFx =
  exportRatesDomain.createEffect(exportHistoryCSV);

export const exportHistoryXlsxFx =
  exportRatesDomain.createEffect(exportHistoryXLSX);

// Stores

const $queryParams = exportRatesDomain.createStore<Params>(null);

export const $isLoading = exportRatesDomain.createStore<boolean>(false);

// Logic

sample({
  clock: setParams,
  fn: queryParamsToGetListParamsAdapter,
  target: $queryParams,
});

sample({
  clock: exportCsv,
  source: combine($preTablesSettings, $tablesSettings, $queryParams),
  fn: ([
    preTablesSettings,
    tablesSettings,
    queryParams,
  ]): ExportFromSocialApiExportRatingToCSVRequest => {
    const dumpedFields: string[] = Array.from(
      new Set([
        ...Object.keys(preTablesSettings?.ratings.columnsVisibility ?? {}),
        ...Object.keys(tablesSettings?.ratings.columnsVisibility ?? {}),
      ]),
    ).filter(
      (field) =>
        ({
          ...preTablesSettings?.ratings.columnsVisibility,
          ...tablesSettings?.ratings.columnsVisibility,
        })[field],
    );

    getAdditionalCompetences(dumpedFields);

    return {
      ...queryParams,
      dumpedFields,
    };
  },
  target: exportCsvFx,
});

sample({
  clock: exportXlsx,
  source: combine($preTablesSettings, $tablesSettings, $queryParams),
  fn: ([
    preTablesSettings,
    tablesSettings,
    queryParams,
  ]): ExportFromSocialApiExportRatingToXLSXRequest => {
    const dumpedFields: string[] = Array.from(
      new Set([
        ...Object.keys(preTablesSettings?.ratings.columnsVisibility ?? {}),
        ...Object.keys(tablesSettings?.ratings.columnsVisibility ?? {}),
      ]),
    ).filter(
      (field) =>
        ({
          ...preTablesSettings?.ratings.columnsVisibility,
          ...tablesSettings?.ratings.columnsVisibility,
        })[field],
    );

    getAdditionalCompetences(dumpedFields);

    return {
      ...queryParams,
      dumpedFields,
    };
  },
  target: exportXlsxFx,
});

sample({
  clock: exportHistoryCsv,
  fn: (params) => params,
  target: exportHistoryCsvFx,
});

sample({
  clock: exportHistoryXlsx,
  fn: (params) => params,
  target: exportHistoryXlsxFx,
});

sample({
  clock: exportAssessmentHistoryCsv,
  fn: (params) => params,
  target: exportAssessmentHistoryCsvFx,
});

sample({
  clock: exportAssessmentHistoryXlsx,
  fn: (params) => params,
  target: exportAssessmentHistoryXlsxFx,
});

sample({
  clock: [exportCsv, exportXlsx],
  source: $queryParams,
  fn: (params) => !!params,
  target: $isLoading,
});

sample({
  clock: exportAssessmentsStoryXlsx,
  fn: (params) => params,
  target: exportAssessmentsStoryXlsxFx,
});

sample({
  clock: exportAssessmentsStoryCsv,
  fn: (params) => params,
  target: exportAssessmentsStoryCsvFx,
});

sample({
  clock: [exportXlsxFx.done, exportXlsxFx.fail],
  fn: () => false,
  target: $isLoading,
});

sample({
  clock: [exportCsvFx.done, exportCsvFx.fail],
  fn: () => false,
  target: $isLoading,
});

exportCsvFx.doneData.watch(handleFileResponse);

exportXlsxFx.doneData.watch(handleFileResponse);

exportHistoryCsvFx.doneData.watch(handleFileResponse);

exportHistoryCsvFx.failData.watch(showError);

exportHistoryXlsxFx.doneData.watch(handleFileResponse);

exportHistoryXlsxFx.failData.watch(showError);

exportAssessmentHistoryCsvFx.doneData.watch(handleFileResponse);

exportAssessmentHistoryCsvFx.failData.watch(showError);

exportAssessmentHistoryXlsxFx.doneData.watch(handleFileResponse);

exportAssessmentHistoryXlsxFx.failData.watch(showError);

exportCsvFx.failData.watch(showError);

exportXlsxFx.failData.watch(showError);

exportAssessmentsStoryXlsxFx.doneData.watch(handleFileResponse);

exportAssessmentsStoryXlsxFx.failData.watch(showError);

exportAssessmentsStoryCsvFx.doneData.watch(handleFileResponse);

exportAssessmentsStoryCsvFx.failData.watch(showError);
