import { guard, sample } from "effector";

import { Country } from "../../../../shared/api/admin/countries";

import { $countries, countriesDomain } from "./countries";

import { updateCountry } from "./update-country";

// Events

export const toggleCountryRegEnabled = countriesDomain.createEvent<string>();

// Logic

guard({
  source: sample({
    clock: toggleCountryRegEnabled,
    source: $countries,
    fn: (countries, code): Country | undefined => {
      const country = countries.find((item) => item.code === code);

      if (country) {
        return {
          ...country,
          regEnabled: !country.regEnabled,
        };
      }
    },
  }),
  filter: (country): country is Country => !!country,
  target: updateCountry,
});
