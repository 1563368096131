import { UpdAdmCalcsDto, UpdGameReqDto } from "src/generated/game";

import { SimulationAdminInterface } from "../../types";

import {
  CleanSimulationItemAdminActionInterface,
  FailureGetSimulationItemAdminActionInterface,
  FailurePullMetaAdminActionInterface,
  FailurePutSimulationItemAdminActionInterface,
  RequestGetSimulationItemAdminActionInterface,
  RequestPullMetaAdminActionInterface,
  RequestPutSimulationItemAdminActionInterface,
  SimulationItemAdminActionsType,
  SuccessGetSimulationItemAdminActionInterface,
  SuccessPullMetaAdminActionInterface,
  SuccessPutSimulationItemAdminActionInterface,
} from "./actionTypes";

export const cleanSimulationItemAdmin =
  (): CleanSimulationItemAdminActionInterface => ({
    type: SimulationItemAdminActionsType.CLEAN_SIMULATION_ITEM_ADMIN,
  });

export const requestGetSimulationItemAdmin = (payload: {
  id: string;
  callback?: () => void;
}): RequestGetSimulationItemAdminActionInterface => ({
  type: SimulationItemAdminActionsType.REQUEST_GET_SIMULATION_ITEM_ADMIN,
  payload,
});

export const requestPutSimulationItemAdmin = (payload: {
  id: string;
  simulationBody: UpdGameReqDto;
  calcsBody: UpdAdmCalcsDto;
  callback?: () => void;
}): RequestPutSimulationItemAdminActionInterface => ({
  type: SimulationItemAdminActionsType.REQUEST_PUT_SIMULATION_ITEM_ADMIN,
  payload: payload,
});

export const requestPullMetaAdmin = (payload: {
  id: string;
  callback?: () => void;
  errorCallback?: () => void;
}): RequestPullMetaAdminActionInterface => ({
  type: SimulationItemAdminActionsType.REQUEST_PULL_META_ADMIN,
  payload,
});

export const successGetSimulationItemAdmin = (payload: {
  game: SimulationAdminInterface;
}): SuccessGetSimulationItemAdminActionInterface => ({
  type: SimulationItemAdminActionsType.SUCCESS_GET_SIMULATION_ITEM_ADMIN,
  payload,
});

export const successPutSimulationItemAdmin =
  (): SuccessPutSimulationItemAdminActionInterface => ({
    type: SimulationItemAdminActionsType.SUCCESS_PUT_SIMULATION_ITEM_ADMIN,
  });

export const successPullMetaAdmin =
  (): SuccessPullMetaAdminActionInterface => ({
    type: SimulationItemAdminActionsType.SUCCESS_PULL_META_ADMIN,
  });

export const failureGetSimulationItemAdmin =
  (): FailureGetSimulationItemAdminActionInterface => ({
    type: SimulationItemAdminActionsType.FAILURE_GET_SIMULATION_ITEM_ADMIN,
  });

export const failurePutSimulationItemAdmin =
  (): FailurePutSimulationItemAdminActionInterface => ({
    type: SimulationItemAdminActionsType.FAILURE_PUT_SIMULATION_ITEM_ADMIN,
  });

export const failurePullMetaAdmin =
  (): FailurePullMetaAdminActionInterface => ({
    type: SimulationItemAdminActionsType.FAILURE_PULL_META_ADMIN,
  });

export type SimulationItemAdminActions =
  | CleanSimulationItemAdminActionInterface
  | RequestGetSimulationItemAdminActionInterface
  | RequestPutSimulationItemAdminActionInterface
  | RequestPullMetaAdminActionInterface
  | SuccessGetSimulationItemAdminActionInterface
  | SuccessPutSimulationItemAdminActionInterface
  | SuccessPullMetaAdminActionInterface
  | FailureGetSimulationItemAdminActionInterface
  | FailurePutSimulationItemAdminActionInterface
  | FailurePullMetaAdminActionInterface;
