import React from "react";
import { BaseButton, TextWithIcon } from "src/shared/components";
import styles from "./style.module.scss";
import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "../../../../../shared/components";
import { useTranslation } from "react-i18next";
import { fetchUserPermissions } from "../../../../../entities/public/permissions/model";
import { useUnit } from "effector-react";
import { $keycloak } from "../../../../../entities/public/keycloak/model";

interface IUserRights {
  clientIdToModal: "hr" | "admin";
  userId?: string;
  hasPermission?: boolean;
}

const { openPopup } = popupModel;
export const UserRights: React.FC<IUserRights> = ({
  clientIdToModal,
  userId,
  hasPermission,
}) => {
  const keycloak = useUnit($keycloak);
  const selfUserId: string | undefined = keycloak?.subject;
  const { t } = useTranslation();

  const defineChangeable = (): boolean => {
    if (userId === selfUserId) return false;
    if (clientIdToModal && userId !== selfUserId) {
      return true;
    }
    return clientIdToModal === "hr";
  };

  const handleClick = (userId?: string): void => {
    if (userId) {
      fetchUserPermissions({ pId: userId as string });
      openPopup({
        name: EPopupName.USER_RIGHTS_POPUP,
        dataId: [{ name: EPopupDataIdName.DEFAULT, value: userId }],
        someBooleanTrigger: defineChangeable() && hasPermission,
      });
    }
  };

  return (
    <div className={styles.rights}>
      <BaseButton
        className={styles.rights__button}
        onClick={() => handleClick(userId)}
        lightBlue
      >
        <TextWithIcon
          iconName="check-square"
          label={
            !defineChangeable()
              ? t("permissions.main.view.title")
              : t("permissions.main.change.title")
          }
        />
      </BaseButton>
    </div>
  );
};
