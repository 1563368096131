import { FC, ReactElement } from "react";

import classNames from "classnames";

import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/light-async";

import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";

import { atomOneDarkReasonable } from "react-syntax-highlighter/dist/esm/styles/hljs";

import "./base-code-block.scss";

SyntaxHighlighter.registerLanguage("python", python);

interface Props {
  code: string;
  className?: string;
  codeLanguage?: string;
  isTransparentBg?: boolean;
  forTooltip?: boolean;
}

export const BaseCodeBlock: FC<Props> = ({
  code,
  className,
  codeLanguage,
  isTransparentBg,
  forTooltip,
}): ReactElement => {
  return (
    <SyntaxHighlighter
      language={codeLanguage || "python"}
      style={atomOneDarkReasonable}
      className={classNames("base-code-block", className, {
        "base-code-block--transparent-bg": isTransparentBg,
        "base-code-block--for-tooltip": forTooltip,
      })}
    >
      {code}
    </SyntaxHighlighter>
  );
};
