import { socialService } from "../../base/api-instances";
import {
  RelativeApiAddCandidateRelativeRequest,
  RelativeApiGetStudentForLinkRequest,
  RelativeApiUntieRelativeRequest,
} from "../../../../generated/social";

export const postCreateLink = () => {
  return socialService
    .makeRelativeRequest()
    .createLinkRelative()
    .then((res) => res.data);
};

export const getLink = () => {
  return socialService
    .makeRelativeRequest()
    .getLinkRelative()
    .then((res) => res.data);
};

export const getStudentForLink = (
  params: RelativeApiGetStudentForLinkRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .getStudentForLink(params)
    .then((res) => res.data);
};

export const postAddCandidateRelative = (
  params: RelativeApiAddCandidateRelativeRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .addCandidateRelative(params)
    .then((res) => res.data);
};

export const getProfileRelativeTable = () => {
  return socialService
    .makeRelativeRequest()
    .getRelatives()
    .then((res) => res.data);
};

export const unLinkRelative = (params: RelativeApiUntieRelativeRequest) => {
  return socialService
    .makeRelativeRequest()
    .untieRelative(params)
    .then((res) => res.data);
};
