import { AdmCalcDto, TestResult } from "../../../../generated/game";
import { RootState } from "../../store";
import { ERequestStatus, SimulationAdminInterface } from "../../types";
import { SimulationItemAdminStateInterface } from "./type";

export const selectSimulationItemAdmin = (
  state: RootState,
): SimulationItemAdminStateInterface => state.simulationItemAdmin;

export const selectSimulationItemAdminItem = (
  state: RootState,
): SimulationAdminInterface | null => selectSimulationItemAdmin(state).item;

export const selectSimulationItemAdminCalcs = (
  state: RootState,
): AdmCalcDto[] => selectSimulationItemAdmin(state).calcs;

export const selectSimulationItemAdminTestFormula = (
  state: RootState,
): TestResult[] => selectSimulationItemAdmin(state).testFormula;

export const selectSimulationItemAdminStatusGet = (
  state: RootState,
): ERequestStatus => selectSimulationItemAdmin(state).statusGetSimulation;

export const selectSimulationItemAdminStatusGetCalcs = (
  state: RootState,
): ERequestStatus => selectSimulationItemAdmin(state).statusGetCalcs;

export const selectSimulationItemAdminStatusUpdateSimulation = (
  state: RootState,
): ERequestStatus => selectSimulationItemAdmin(state).statusUpdateSimulation;

export const selectSimulationItemAdminStatusPullMeta = (
  state: RootState,
): ERequestStatus => selectSimulationItemAdmin(state).statusPullMeta;

export const selectSimulationItemAdminStatusTestFormula = (
  state: RootState,
): ERequestStatus => selectSimulationItemAdmin(state).statusTestFormula;
