import React, { FC } from "react";
import BaseDropdown from "src/shared/components/base-dropdown/base-dropdown";
import { TextWithIcon } from "src/shared/components";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "src/shared/hooks/useMediaQuery";
import { ListCheckbox } from "../list-checkbox";
import { useUnit } from "effector-react";
import {
  $preTablesSettings,
  saveTableSettings,
  setColumnsVisibility,
} from "../../model";
import { EUserSettingsTablesName } from "../../../../../entities/public/user-settings";

interface Props {
  items: string[];
  tableName: EUserSettingsTablesName;
}

const TableSettingsDropdown: FC<Props> = ({ items, tableName }) => {
  const { t } = useTranslation();

  const { isLaptop } = useMediaQuery();

  const preTableSettings = useUnit($preTablesSettings);

  const onChange = (columnName: string, value: boolean) => {
    setColumnsVisibility({
      tableName,
      columnName,
      value,
    });
    saveTableSettings();
  };

  return (
    <BaseDropdown
      align="right"
      view={
        <TextWithIcon
          iconName="settings-blue"
          iconSize={isLaptop ? 22 : 18}
          label={t("table.settings.btn")}
          hideLabelOnMobile
        />
      }
      isLong
    >
      <div className="table-settings-dropdown__items">
        {items.map((item) => {
          const checked: boolean | undefined =
            preTableSettings?.[tableName]?.columnsVisibility?.[item];

          return (
            <ListCheckbox
              key={item}
              item={item}
              checked={checked !== undefined ? checked : true}
              onChange={onChange}
            />
          );
        })}
      </div>
    </BaseDropdown>
  );
};

export default TableSettingsDropdown;
