import { createEffect, createEvent, createStore, sample } from "effector";
import {
  Pagination,
  Rating,
  RatingsApiGetRatingRequest,
} from "src/generated/social";
import { QueryParams } from "src/shared/api/base";
import { requestGetRatings } from "src/shared/api/hr/ratings/model/ratings";
import { queryParamsToRatingsRequestAdapter } from "../lib/queryParamsToRatingsRequestAdapter";

// stores

export const $ratings = createStore<Rating[]>([]);

export const $ratingsPagination = createStore<Pagination | null>(null);

export const $isRatingsLoading = createStore<boolean>(false);

export const $isRatingsRequestError = createStore<boolean>(false);

export const $isRatingsReadmore = createStore<boolean>(false);

export const $ratingsQueryParams = createStore<QueryParams>({});

// events

export const fetchRatings = createEvent<RatingsApiGetRatingRequest>();

export const setRatings = createEvent<Rating[]>();

export const setIsRatingsReadmore = createEvent<boolean>();

export const setRatingsQueryParams = createEvent<QueryParams>();

// effects

const fetchRatingsFx = createEffect(requestGetRatings);

// logic

sample({
  clock: setIsRatingsReadmore,
  target: $isRatingsReadmore,
});

sample({
  clock: setRatingsQueryParams,
  target: $ratingsQueryParams,
});

sample({
  clock: $ratingsQueryParams,
  fn: queryParamsToRatingsRequestAdapter,
  target: fetchRatingsFx,
});

sample({
  clock: fetchRatings,
  target: fetchRatingsFx,
});

sample({
  clock: setRatings,
  target: $ratings,
});

sample({
  clock: fetchRatingsFx.pending,
  target: $isRatingsLoading,
});

sample({
  clock: fetchRatingsFx.fail,
  fn: () => true,
  target: $isRatingsRequestError,
});

sample({
  clock: fetchRatingsFx.doneData,
  source: { isReadmore: $isRatingsReadmore, ratings: $ratings },
  fn: ({ isReadmore, ratings }, response) =>
    isReadmore ? [...ratings, ...response.ratings] : response.ratings,
  target: $ratings,
});

sample({
  clock: fetchRatingsFx.doneData,
  fn: ({ pagination }) => pagination || null,
  target: $ratingsPagination,
});
