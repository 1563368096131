import React, {
  FC,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useUrlState } from "src/shared/hooks/useUrlState";

import {
  ArchiveLink,
  ConfirmPopup,
  TextWithIcon,
} from "../../../shared/components";

import { PageWrapper } from "src/shared/components";

import { UsersControlPanelBtn } from "../components";

import {
  $usersListAdmin,
  cleanUsersListAdmin,
  requestUsersListAdmin,
  setUsersListAdminIsReadmore,
} from "src/entities/public/users-list-admin";

import { ERequestStatus } from "../../../shared/store/types";

import { TDataGridDataParams, UserRoleEnum } from "../../../shared/models";

import { HrTable } from "../../../shared/components/data-grids/user-managments/hr";
import { selectUsersControlDisableStatus } from "../../../shared/store/ducks/users-control/selectors";
import { requestUsersControlDisable } from "../../../shared/store/ducks/users-control/actionCreators";
import { UsersApiUsersRequest } from "../../../generated/keycloak";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import { togglePlaceParams } from "../../../shared/helpers";
import "./hrs-page.scss";
import { ERegEx, regExList } from "../../../shared/constants/regExList";

interface Props {}

const { openPopup } = popupModel;

export const HrsPage: FC<Props> = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [checkList, setCheckList] = useState<string[]>([]);

  const {
    items: usersListAdminItems,
    pagination,
    status: usersListAdminStatus,
  } = useUnit($usersListAdmin);

  const usersControlDisableStatus = useSelector(
    selectUsersControlDisableStatus,
  );

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (!isEqual(queryParams, newFilteredParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newFilteredParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setUsersListAdminIsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstName,
      lastName,
      phoneNumber,
      workPhoneNumber,
      email,
      locality,
      birthDateFrom,
      birthDateTo,
      studyWorkPlace,

      orderBy,
      pageNum,
      pageSize,
    } = urlState.query;

    const params: UsersApiUsersRequest = {
      role: [UserRoleEnum.HR_ALABUGA, UserRoleEnum.HR_STAFF],
      enabled: true,

      firstName: firstName ? [firstName] : undefined,
      lastName: lastName ? [lastName] : undefined,
      phoneNumber: phoneNumber
        ? [phoneNumber.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
        : undefined,
      workPhoneNumber: workPhoneNumber ? [workPhoneNumber] : undefined,
      email: email ? [email] : undefined,
      locality: locality ? [locality] : undefined,
      birthDateFrom,
      birthDateTo,
      studyWorkPlace: studyWorkPlace ? [studyWorkPlace] : undefined,

      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [urlState.query]);

  useEffect(() => {
    requestUsersListAdmin(pageParams);
  }, [pageParams]);

  useEffect(() => {
    return () => {
      cleanUsersListAdmin();
    };
  }, []);

  const onConfirmHandler = useCallback(() => {
    dispatch(
      requestUsersControlDisable({
        usersId: checkList,
        queryForUpdateList: pageParams,
      }),
    );
    setCheckList([]);
  }, [pageParams, checkList, dispatch, setCheckList]);

  const setSelectedRow = useCallback(
    (idList: string[]) => {
      setCheckList(idList);
    },
    [setCheckList],
  );

  const isLoadingUsers: boolean =
    usersListAdminStatus === ERequestStatus.LOADING;

  const isLoadingDisable: boolean =
    usersControlDisableStatus === ERequestStatus.LOADING;

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  return (
    <PageWrapper
      title={t("usersControl.title")}
      tabsPanelSlot={<ArchiveLink to={"/users-control/archive"} />}
      isLoadingPanel={isLoadingUsers}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("usersControl.controlPanel.label.users")}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={checkList.length}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <Fragment>
          <div className="table__control">
            {filtersLength > 0 && (
              <div className="table__control-btn" onClick={clearFilterSettings}>
                <TextWithIcon
                  label={t("table.filters.reset")}
                  iconName={"cross-blue"}
                />
              </div>
            )}
            <UsersControlPanelBtn
              addUserType="hr"
              isPlayerHrAdminPage
              isLockDisabled={checkList.length === 0}
              onLock={() => openPopup({ name: EPopupName.LOCK_USERS })}
            />
          </div>
        </Fragment>
      }
    >
      <div className="hrs-page-data-grid-wrapper">
        <HrTable
          users={usersListAdminItems}
          queryParams={urlState.query}
          onUpdateSortAndFilters={onUpdateQueryParams}
          onSelectRows={setSelectedRow}
          selectedRowsIds={checkList}
          loading={isLoadingUsers}
          pagination={pagination}
          goToPage={(pageNum) => goToPage(pageNum)}
          readmore={(pageNum) => goToPage(pageNum, true)}
        />
      </div>

      <ConfirmPopup
        popupName={EPopupName.LOCK_USERS}
        title={t("usersControl.popup.blockUsers.title")}
        description={t("usersControl.popup.blockUsers.description")}
        confirmBtnLabel={t("usersControl.controlPanel.btn.block")}
        onConfirm={onConfirmHandler}
        isConfirmBtnDisabled={isLoadingDisable}
      />
    </PageWrapper>
  );
};
