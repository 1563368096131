import { sample } from "effector";

import { Country, update } from "../../../../shared/api/admin/countries";

import { $countries, countriesDomain } from "./countries";

// Events

export const updateCountry =
  countriesDomain.createEvent<Country>("updateCountry");

// Effects

const updateCountryFx = countriesDomain.createEffect(update);

// Stores

export const $isLoadingUpdateCountry = updateCountryFx.pending.map(
  (loading) => loading,
);

// Logic

sample({
  clock: updateCountry,
  target: updateCountryFx,
});

sample({
  clock: updateCountryFx.doneData,
  source: $countries,
  fn: (countries, newCountry) =>
    countries.map((country) =>
      country.code === newCountry.code ? newCountry : country,
    ),
  target: $countries,
});
