import React, { FC, Fragment, ReactElement } from "react";

import { Join as UserInfoDto } from "src/generated/ws4";

import useClient from "src/shared/hooks/useClient";

import { BaseAvatar, IconCross, TextWithIcon } from "src/shared/components";

import { useTranslation } from "react-i18next";
import { EPopupName } from "../../../../../../../shared/components";
import { openPopup } from "../../../../../../../shared/components/base-popup/model";
import { IconCrossColor } from "../../../../../../../shared/components/icons-components/icon-cross/icon-cross";

import styles from "./unassignes-players-list.module.scss";
import classNames from "classnames";
import { IPlayer, ITeam } from "../../session-table";
import { DraggableItem } from "../../../../../../../shared/components/base-draggable-item/base-draggable-item";
import {
  DragItem,
  DropArea,
} from "../../../../../../../shared/constants/dragEnums";
import { PlayerDragLayer } from "../player-drag-layer/player-drag-layer";

interface Props {
  players: IPlayer[];
  deletePlayer: (player: UserInfoDto) => void;
  isTeam: boolean | undefined;
  teams: ITeam[];
}

export const UnassignedPlayersList: FC<Props> = ({
  players,
  deletePlayer,
  isTeam,
  teams,
}): ReactElement => {
  const { isHrClientId } = useClient();

  const { t } = useTranslation();

  const openAllocatePlayerPopup = (
    player: UserInfoDto,
    desiredRole: string,
  ) => {
    openPopup({
      name: EPopupName.ALLOCATE_PLAYER_POPUP,
      data: { player, desiredRole: desiredRole, teams },
    });
  };

  const crossClickHandler = (event: React.MouseEvent, item: UserInfoDto) => {
    event.stopPropagation();

    deletePlayer(item);
  };

  return (
    <Fragment>
      {players.map((item) => {
        const desiredRole = [
          item.role && t(`playerRoles.${item.role}`),
          (item.corpNum || item.teamNum) &&
            `${t("common.team")} №${isTeam ? item.teamNum : item.corpNum}`,
          item.areaNum && `${t("common.area")} №${item.areaNum}`,
        ]
          .filter((item) => item)
          .join(", ");

        const fullName = `${item.firstname} ${item.lastname}`;

        return (
          <DraggableItem
            type={DragItem.PLAYER}
            data={item}
            key={item.pid}
            canDrag={isHrClientId}
            element={(isDragging, dragRef) => {
              return (
                <div
                  ref={dragRef}
                  key={item.pid}
                  className={classNames(styles.playerCard, {
                    [styles.transparent]: item.isSearchTarget === false,
                    [styles.isDragging]: isDragging,
                  })}
                  id="dragged-player"
                  onClick={() => {
                    openAllocatePlayerPopup(item, desiredRole);
                  }}
                >
                  <TextWithIcon
                    iconName="drag"
                    hideLabel
                    iconSize={20}
                    className={styles.dragIcon}
                  />
                  <BaseAvatar size={40} userId={item.pid} />
                  <span className={styles.name}>{fullName}</span>
                  {desiredRole && (
                    <p className={styles.desiredRole}>({desiredRole})</p>
                  )}
                  {isHrClientId && (
                    <span className={styles.buttonCross}>
                      <IconCross
                        color={IconCrossColor.RED}
                        onClick={(event) => crossClickHandler(event, item)}
                        size={20}
                      />
                    </span>
                  )}
                </div>
              );
            }}
          />
        );
      })}
      <PlayerDragLayer />
    </Fragment>
  );
};
