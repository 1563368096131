import { FC, ReactElement, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { useUnit } from "effector-react";

import { BaseButton } from "src/shared/components/index";

import { responseMessageSetter } from "src/shared/helpers";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import "./base-message-popup.scss";

interface Props {}

const { $activePopups, closePopup } = popupModel;

export const BaseMessagePopup: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const currentPopup = useMemo(
    () =>
      activePopups.find(({ name }) => name === EPopupName.BASE_MESSAGE_POPUP),
    [activePopups],
  );

  const message = currentPopup?.message;

  const isResponseMessage = useMemo(
    () => message?.type === "response",
    [message],
  );

  const isErrorMessage = useMemo(() => !!message?.isError, [message]);

  const messageText = useMemo(() => message?.text || "", [message]);

  const onClosePopup = () => {
    closePopup({ name: EPopupName.BASE_MESSAGE_POPUP });
    if (currentPopup?.data?.callback) {
      currentPopup.data.callback();
    }
  };

  return (
    <div className="base-message-popup">
      <div
        className={classNames("base-message-popup__description", {
          "base-message-popup__description--error": isErrorMessage,
        })}
      >
        {isResponseMessage
          ? responseMessageSetter(messageText, t)
          : messageText}
      </div>

      <BaseButton
        primary={!isErrorMessage}
        secondary={isErrorMessage}
        outline
        onClick={onClosePopup}
        small
      >
        {t("popup.baseMessage.ok")}
      </BaseButton>
    </div>
  );
};
