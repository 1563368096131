import { FC, ReactElement } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { IGameTypeAvailabilityItem } from "../../model/types";

import "./game-type-availability.scss";

interface Props {
  availabilityList: IGameTypeAvailabilityItem[];
}

export const GameTypeAvailability: FC<Props> = ({
  availabilityList,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="game-type-availability">
      <h5 className="game-type-availability__title">{t("levelUp.required")}</h5>
      <ul className="game-type-availability__list">
        {availabilityList.map(({ competence, value, available }) => (
          <li
            key={competence}
            className={classNames("game-type-availability__list-item", {
              "game-type-availability__list-item--available": available,
            })}
          >
            {`${t(`lvlState.title.${competence}`)} ${value}`}
          </li>
        ))}
      </ul>
    </div>
  );
};
