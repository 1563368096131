import { SelectItem } from "src/shared/components/form-fields/base-select/base-select";

import { Country, District, Region, School } from "src/generated/social";
import { EAlabugaPolytech, EAlabugaStart, EKfu } from "src/shared/store/types";
import i18n from "i18next";

export const desiredSpeciality = {
  ...EAlabugaPolytech,
  ...EKfu,
  ...EAlabugaStart,
};

export const countriesToSelectItemsAdapter = (
  countries: Country[],
): SelectItem[] =>
  countries.reduce((final: SelectItem[], { title, code }: Country) => {
    const isCurrRussia: boolean = code === "RU";

    const selectItem: SelectItem = {
      label: title,
      value: code,
    };

    if (isCurrRussia) {
      final.unshift(selectItem);
    } else {
      final.push(selectItem);
    }

    return final;
  }, []);

export const regionsToSelectItemsAdapter = (regions: Region[]): SelectItem[] =>
  regions.map(({ title, rid }) => ({ label: title, value: String(rid) }));

export const districtsToSelectItemsAdapter = (
  districts: District[],
): SelectItem[] =>
  districts.map(({ title, did }) => ({
    label: title,
    value: String(did),
  }));

export const schoolsToSelectItemsAdapter = (schools: School[]): SelectItem[] =>
  schools.map(({ title, sid }) => ({ label: title, value: String(sid) }));

export const desiredSpecialityItemsAdapter = (): SelectItem[] =>
  Object.values(desiredSpeciality).map((speciality) => ({
    label: i18n.t(`table.desiredSpeciality.${speciality}`),
    value: speciality,
  }));
