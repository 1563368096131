import { FC, ReactElement, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { languagePicker } from "src/shared/helpers";

import {
  BaseInnerHtml,
  BaseLoader,
  BasePopup,
  EPopupName,
} from "src/shared/components";

import {
  cleanSidebarInformation,
  requestSidebarInformationGet,
  selectSidebarInformationItem,
} from "src/shared/store/ducks/sidebar-information";

import "./information-popup.scss";

interface Props {}

export const InformationPopup: FC<Props> = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const information = useSelector(selectSidebarInformationItem);

  useEffect(() => {
    dispatch(requestSidebarInformationGet({}));

    return () => {
      dispatch(cleanSidebarInformation());
    };
  }, [dispatch]);

  return (
    <BasePopup
      name={EPopupName.INFORMATION_POPUP}
      title={t("popup.informationPopup.title")}
    >
      <div className="information-popup">
        {!!information && (
          <BaseInnerHtml
            className="information-popup__inner-html"
            description={languagePicker(information, i18n.language)}
          />
        )}
        {!information && <BaseLoader />}
      </div>
    </BasePopup>
  );
};
