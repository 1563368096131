import { FC, ReactElement, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useUnit } from "effector-react";

import {
  ConfirmPopup,
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";

interface Props {
  onConfirm: (id: string) => void;
}

const { $activePopups } = popupModel;

export const ConfirmDeleteSimulationTemplatePopup: FC<Props> = ({
  onConfirm,
}): ReactElement => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const selectedId = useMemo(() => {
    const currentPopup = activePopups.find(
      ({ name }) => name === EPopupName.CONFIRM_DELETE_SIMULATION_TEMPLATE,
    );

    return currentPopup?.dataId?.find(
      ({ name }) => name === EPopupDataIdName.DEFAULT,
    )?.value;
  }, [activePopups]);

  const onConfirmHandler = () => {
    if (selectedId) {
      onConfirm(selectedId);
    }
  };

  return (
    <ConfirmPopup
      title={t("simulationControl.simulationPage.popup.deleteTemplate.title")}
      description={t(
        "simulationControl.simulationPage.popup.deleteTemplate.description",
      )}
      confirmBtnLabel={t("common.delete")}
      reverse
      onConfirm={onConfirmHandler}
      popupName={EPopupName.CONFIRM_DELETE_SIMULATION_TEMPLATE}
    />
  );
};
