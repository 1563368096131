import { createEffect, createEvent, createStore, sample } from "effector";
import {
  TutorialApiGetTutorialChartRequest,
  TutorialChartDto,
} from "src/generated/game";
import {
  DictionariesApiGetMapCountriesRequest,
  DictionariesApiGetMapRegionsRequest,
  MapCountries,
  MapRegions,
  PlayersApiGetPlayersGrowthRequest,
  PlayersCountDto,
  PlayersGrowth,
  RatingChartDto,
} from "src/generated/social";
import {
  fetchMapChartRussia,
  fetchMapChartWorld,
  fetchPlayersCount,
  fetchPlayersGrowth,
  fetchRatingChart,
  fetchTutorialChart,
} from "src/shared/api/hr/charts/model/charts";

interface Query<T> {
  data?: T;
  isLoading: boolean;
}

// store

export const $regionsChart = createStore<MapRegions>({ regions: [] });

export const $countriesChart = createStore<MapCountries>({ countries: [] });

export const $playersGrowth = createStore<Query<PlayersGrowth>>({
  isLoading: false,
});

export const $playersCount = createStore<PlayersCountDto | null>(null);

export const $ratingChart = createStore<Query<RatingChartDto>>({
  isLoading: false,
});

export const $tutorialChart = createStore<Query<TutorialChartDto>>({
  isLoading: false,
});

// event

export const getMapChartRussia =
  createEvent<DictionariesApiGetMapRegionsRequest>();

export const getMapChartWorld =
  createEvent<DictionariesApiGetMapCountriesRequest>();

export const getPlayersGrowth =
  createEvent<PlayersApiGetPlayersGrowthRequest>();

export const getPlayersCount = createEvent();

export const getRatingChart = createEvent();

export const getTutorialChart =
  createEvent<TutorialApiGetTutorialChartRequest>();

// effect

const getMapChartRussiaFx = createEffect(fetchMapChartRussia);

const getMapChartWorldFx = createEffect(fetchMapChartWorld);

const getPlayersGrowthFx = createEffect(fetchPlayersGrowth);

const getPlayersCountFx = createEffect(fetchPlayersCount);

const getRatingChartFx = createEffect(fetchRatingChart);

const getTutorialChartFx = createEffect(fetchTutorialChart);

// logic

sample({
  clock: getMapChartRussia,
  target: getMapChartRussiaFx,
});

sample({
  clock: getMapChartRussiaFx.doneData,
  target: $regionsChart,
});

sample({
  clock: getMapChartWorld,
  target: getMapChartWorldFx,
});

sample({
  clock: getMapChartWorldFx.doneData,
  target: $countriesChart,
});

sample({
  clock: getPlayersGrowth,
  target: getPlayersGrowthFx,
});

sample({
  clock: getPlayersGrowthFx.doneData,
  source: $playersGrowth,
  fn: (prev, response) => ({ ...prev, data: response }),
  target: $playersGrowth,
});

sample({
  clock: getPlayersGrowthFx.pending,
  source: $playersGrowth,
  fn: (prev, isLoading) => ({ ...prev, isLoading }),
  target: $playersGrowth,
});

sample({
  clock: getPlayersCount,
  target: getPlayersCountFx,
});

sample({
  clock: getPlayersCountFx.doneData,
  target: $playersCount,
});

sample({
  clock: getRatingChart,
  target: getRatingChartFx,
});

sample({
  clock: getRatingChartFx.doneData,
  source: $ratingChart,
  fn: (prev, response) => ({ ...prev, data: response }),
  target: $ratingChart,
});

sample({
  clock: getRatingChartFx.pending,
  source: $ratingChart,
  fn: (prev, isLoading) => ({ ...prev, isLoading }),
  target: $ratingChart,
});

sample({
  clock: getTutorialChart,
  target: getTutorialChartFx,
});

sample({
  clock: getTutorialChartFx.doneData,
  source: $tutorialChart,
  fn: (prev, response) => ({ ...prev, data: response }),
  target: $tutorialChart,
});

sample({
  clock: getTutorialChartFx.pending,
  source: $tutorialChart,
  fn: (prev, isLoading) => ({ ...prev, isLoading }),
  target: $tutorialChart,
});
