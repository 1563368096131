import { combine, guard, sample } from "effector";

import { keycloakModel } from "src/entities/public/keycloak";

import { getDistricts } from "src/shared/api/public/districts";

import { getRegions } from "src/shared/api/public/regions";

import { getSchools } from "src/shared/api/public/schools";

import {
  DictionariesApiGetDistrictsRequest as DistrictsParams,
  DictionariesApiGetRegionsRequest as RegionsParams,
  DictionariesApiGetSchoolsRequest as SchoolsParams,
  District,
  Region,
  School,
} from "src/generated/social";

import { appAccessDomain } from "./domain";

import { $country, $district, $region } from "./profile";

const { $isPlayerClientId } = keycloakModel;

// Stores

export const $regions = appAccessDomain.createStore<Region[] | null>(null);

export const $districts = appAccessDomain.createStore<District[] | null>(null);

export const $schools = appAccessDomain.createStore<School[] | null>(null);

export const $isRegionsLoading = appAccessDomain.createStore<boolean>(false);

export const $isDistrictsLoading = appAccessDomain.createStore<boolean>(false);

export const $isSchoolsLoading = appAccessDomain.createStore<boolean>(false);

// Effects

export const fetchRegionsFx = appAccessDomain.createEffect(getRegions);

export const fetchDistrictsFx = appAccessDomain.createEffect(getDistricts);

export const fetchSchoolsFx = appAccessDomain.createEffect(getSchools);

// Events

export const fetchRegions = appAccessDomain.createEvent<RegionsParams>();

export const fetchDistricts = appAccessDomain.createEvent<DistrictsParams>();

export const fetchSchools = appAccessDomain.createEvent<SchoolsParams>();

// Logic

/* loading detect */
sample({
  clock: fetchRegionsFx.pending,
  target: $isRegionsLoading,
});

sample({
  clock: fetchDistrictsFx.pending,
  target: $isDistrictsLoading,
});

sample({
  clock: fetchSchoolsFx.pending,
  target: $isSchoolsLoading,
});

/* setting data */
sample({
  clock: fetchRegionsFx.doneData,
  fn: ({ regions }) => regions,
  target: $regions,
});

sample({
  clock: fetchDistrictsFx.doneData,
  fn: ({ districts }) => districts,
  target: $districts,
});

sample({
  clock: fetchSchoolsFx.doneData,
  fn: ({ schools }) => schools,
  target: $schools,
});

/* trigger event */
guard({
  clock: combine($country, (country) =>
    country ? { countryCode: country } : null,
  ),
  filter: (
    requestParams: RegionsParams | null,
  ): requestParams is RegionsParams => !!requestParams,
  target: fetchRegions,
});

guard({
  clock: combine($country, $region, (country, region) =>
    country && region
      ? { countryCode: country, regionId: Number(region) }
      : null,
  ),
  filter: (
    requestParams: DistrictsParams | null,
  ): requestParams is DistrictsParams => !!requestParams,
  target: fetchDistricts,
});

guard({
  clock: combine(
    $country,
    $region,
    $district,
    $isPlayerClientId,
    (country, region, district, isPlayer) =>
      country && region && district && isPlayer
        ? {
            countryCode: country,
            regionId: Number(region),
            districtId: Number(district),
          }
        : null,
  ),
  filter: (
    requestParams: SchoolsParams | null,
  ): requestParams is SchoolsParams => !!requestParams,
  target: fetchSchools,
});

/* trigger effect */
sample({
  clock: fetchRegions,
  target: fetchRegionsFx,
});

sample({
  clock: fetchDistricts,
  target: fetchDistrictsFx,
});

sample({
  clock: fetchSchools,
  target: fetchSchoolsFx,
});
