import { t } from "src/shared/helpers";

import { fetchDeleteSimulation } from "../model/delete-simulation";

export const deleteSimulation = async (gameId: string) => {
  const isConfirm: boolean = window.confirm(
    t("simulationControl.activePage.confirmDeleteText"),
  );

  if (isConfirm && gameId) {
    fetchDeleteSimulation({ gameId });
  }
};
