import { EPopupName } from "../model/types";

import { openPopup } from "../model";

export const openResponseErrorPopup = (message?: string) => {
  openPopup({
    name: EPopupName.BASE_MESSAGE_POPUP,
    message: {
      text: message || "somethingWentWrong",
      type: "response",
      isError: true,
    },
  });
};
