import { some } from "patronum";

import {
  $isDistrictsLoading,
  $isRegionsLoading,
  $isSchoolsLoading,
} from "./dictionaries";

export const $isSomeLoading = some({
  predicate: (loading) => loading,
  stores: [$isRegionsLoading, $isDistrictsLoading, $isSchoolsLoading],
});
