import { ChangeEvent, FC, useEffect, useRef } from "react";
import styles from "./players-search.module.scss";
import { useTranslation } from "react-i18next";
import { TextWithIcon } from "../../../../../../../shared/components";
import classNames from "classnames";

interface Props {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  clearValue: () => void;
  active: boolean;
  setActive: (payload: boolean) => void;
}

export const PlayersSearch: FC<Props> = ({
  value,
  onChange,
  active,
  setActive,
  clearValue,
}) => {
  const { t } = useTranslation();

  const visualAreaRef = useRef<HTMLSpanElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const outsideClick = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest(`.${styles.visualArea}`)) {
        setActive(false);
        inputRef.current?.blur();
      }
    };

    document.addEventListener("click", outsideClick);

    return () => {
      document.removeEventListener("click", outsideClick);
    };
  }, []);

  return (
    <span
      className={classNames(styles.visualArea, {
        [styles.active]: active,
        [styles.expanded]: active || value,
      })}
      ref={visualAreaRef}
    >
      <TextWithIcon iconName="lens" hideLabel iconSize={18} />
      <input
        type="text"
        placeholder={t("allSessions.search.btn")}
        size={44}
        onFocus={() => setActive(true)}
        value={value}
        onChange={onChange}
        ref={inputRef}
      />
      {(active || value) && (
        <span onClick={clearValue} className={styles.cross}>
          <TextWithIcon iconName="cross-grey" />
        </span>
      )}
    </span>
  );
};
