import axios from "axios";
import {
  EUsersControlRequestMethods,
  IUsersControlPayloadItems,
} from "src/shared/store/ducks/users-control/type";

export const enableUsers = async (
  payload: IUsersControlPayloadItems,
): Promise<void> => {
  await axios.post(
    `${process.env.REACT_APP_KC_URL}/realms/${process.env.REACT_APP_KC_REALM}/albAdmin/users/${EUsersControlRequestMethods.ENABLE}`,
    { ids: payload.usersId },
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("alabuga-token"),
      },
    },
  );
};
