import {
  GetRatingParams,
  QueryParams,
} from "src/shared/api/admin/players/model";
import { ERegEx, regExList } from "../../../../shared/constants/regExList";

export const queryParamsToGetPlayersRatingAdapter = (
  queryParams: QueryParams = {},
): GetRatingParams => {
  return {
    name: queryParams.name ? [queryParams.name] : undefined,
    firstName: queryParams.firstName ? [queryParams.firstName] : undefined,
    lastName: queryParams.lastName ? [queryParams.lastName] : undefined,
    phone: queryParams.phone
      ? [queryParams.phone?.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
      : undefined,
    analyticsFrom: queryParams.analyticsFrom
      ? Number(queryParams.analyticsFrom)
      : undefined,
    analyticsTo: queryParams.analyticsTo
      ? Number(queryParams.analyticsTo)
      : undefined,
    commandFrom: queryParams.commandFrom
      ? Number(queryParams.commandFrom)
      : undefined,
    commandTo: queryParams.commandTo
      ? Number(queryParams.commandTo)
      : undefined,
    communicationFrom: queryParams.communicationFrom
      ? Number(queryParams.communicationFrom)
      : undefined,
    communicationTo: queryParams.communicationTo
      ? Number(queryParams.communicationTo)
      : undefined,
    lastActivityFrom: queryParams.lastActivityFrom
      ? queryParams.lastActivityFrom
      : undefined,
    lastActivityTo: queryParams.lastActivityTo
      ? queryParams.lastActivityTo
      : undefined,
    desiredStudyPlace: queryParams.desiredStudyPlace
      ? [queryParams.desiredStudyPlace]
      : undefined,
    orderBy: queryParams.orderBy ? [queryParams.orderBy] : undefined,
    pageNum: queryParams.pageNum ? Number(queryParams.pageNum) : 1,
    pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 50,
    email: queryParams.email ? [queryParams.email] : undefined,
    studyWorkPlace: queryParams.studyWorkPlace
      ? [queryParams.studyWorkPlace]
      : undefined,
    locality: queryParams.locality ? [queryParams.locality] : undefined,
  };
};
