import { useUnit } from "effector-react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { exportModel } from "src/entities/hr/export-rates";
import {
  BasePopup,
  BasePopupButtonsList,
  EPopupName,
  TBasePopupButtonGroup,
} from "src/shared/components";
import { closePopup } from "src/shared/components/base-popup/model";

export const DownloadRatingsPopup = () => {
  const hideDownloadPopup = () => {
    closePopup({ name: EPopupName.DOWNLOAD_FILE });
  };

  const onXlsxDownload = useCallback(() => {
    exportModel.exportXlsx();
    hideDownloadPopup();
  }, []);

  const onCsvDownload = useCallback(() => {
    exportModel.exportCsv();
    hideDownloadPopup();
  }, []);

  const isDisabledExportButtons = useUnit(exportModel.$isLoading);

  const { t } = useTranslation();

  const buttonGroups = useMemo<TBasePopupButtonGroup[]>(
    () => [
      [
        {
          label: `${t("common.unload")} CSV`,
          onClick: onCsvDownload,
          isDisabled: isDisabledExportButtons,
        },
        {
          label: `${t("common.unload")} XLSX`,
          onClick: onXlsxDownload,
          isDisabled: isDisabledExportButtons,
        },
      ],
      [
        {
          label: t("common.cancel"),
          onClick: hideDownloadPopup,
          isBold: true,
        },
      ],
    ],
    [t, isDisabledExportButtons, onXlsxDownload, onCsvDownload],
  );

  return (
    <BasePopup
      isWithoutPadding
      isHiddenSwipePanel
      name={EPopupName.DOWNLOAD_FILE}
      bgType="transparent"
    >
      <BasePopupButtonsList groups={buttonGroups} />
    </BasePopup>
  );
};
