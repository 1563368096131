import { GetAssessmentsParams, PatchAssessmentParams } from "./query";

import { gameService } from "../../../base/api-instances";
import { HrGamesApiGetShortUrlsRequest } from "src/generated/game";

export const getAssessments = (params: GetAssessmentsParams) => {
  return gameService
    .makeAssessmentsRequest()
    .getAssessments(params)
    .then((res) => res.data);
};

export const patchAssessment = (params: PatchAssessmentParams) => {
  return gameService
    .makeHrGamesRequest()
    .patchHrUpdateAssessment(params)
    .then((res) => res.data);
};

export const getExportAssessments = (params: HrGamesApiGetShortUrlsRequest) => {
  return gameService
    .makeHrGamesExportRequest()
    .getShortUrls(params)
    .then((res) => res.data);
};
