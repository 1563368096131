import { sample } from "effector";

import { queryParamsToGetListParamsAdapter } from "../lib";

import { $queryParams, fetchFinesFx, finesDomain } from "./fines";

// Events

export const refreshFines = finesDomain.createEvent();

// Logic

sample({
  clock: refreshFines,
  source: $queryParams,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchFinesFx,
});
