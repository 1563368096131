import { GetListParams as GetRegionsListParams } from "../../../../shared/api/public/regions";

import { GetListParams as GetDistrictsListParams } from "../../../../shared/api/public/districts";

import { GetListParams as GetSchoolListParams } from "../../../../shared/api/public/schools";

import { QueryParams } from "../../../../shared/api/base";

export const queryParamsToGetRegionsListParamsAdapter = ({
  locale,
  country: cc,
}: QueryParams = {}): GetRegionsListParams => ({
  locale,
  countryCode: cc,
});

export const queryParamsToGetDistrictsListParamsAdapter = ({
  locale,
  country: cc,
  region: rid,
}: QueryParams = {}): GetDistrictsListParams => ({
  regionId: rid ? Number(rid) : undefined,
  locale,
  countryCode: cc,
});

export const queryParamsToGetSchoolsListParamsAdapter = ({
  locale,
  country: cc,
  region: rid,
  district: did,
}: QueryParams = {}): GetSchoolListParams => ({
  regionId: rid ? Number(rid) : undefined,
  districtId: did ? Number(did) : undefined,
  locale,
  countryCode: cc,
});
