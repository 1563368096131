import { getCompetence } from "../helpers";
import { Competence, Player } from "../../generated/social";
import i18next from "i18next";

export interface IStatItem {
  key: string;
  title: string;
  description?: string;
  additionalDescription?: string;
  shortTitle?: string;
}

export const getCompetenceStatItem = (
  competenceName: Competence,
  profileCompetence: Player,
  emptyValue = "—",
) => {
  const getTranslate = (key: string): string => {
    return i18next.t(key);
  };
  const value = getCompetence(
    profileCompetence?.competenceRates ?? [],
    competenceName,
  );

  return {
    key: competenceName,
    title: getTranslate(`profile.competence.title.${competenceName}`),
    shortTitle: getTranslate(
      `profile.competence.title.${competenceName}.short`,
    ),
    description: value ? value.rate.toFixed(2) : emptyValue,
  };
};
