import { QueryParams } from "src/shared/api/base";

import { ELanguage } from "src/shared/store/types";

export const regionsRequestCheck = (
  country: string | null,
  locale: ELanguage,
): QueryParams | null => (country ? { country, locale } : null);

export const districtsRequestCheck = (
  country: string | null,
  region: string | null,
  locale: ELanguage,
): QueryParams | null =>
  country && region ? { country, region, locale } : null;

export const schoolsRequestCheck = (
  country: string | null,
  region: string | null,
  district: string | null,
  locale: ELanguage,
): QueryParams | null =>
  country && region && district ? { country, region, district, locale } : null;
