import { FC, ReactElement, useEffect } from "react";

import { useUnit } from "effector-react";

import { useDispatch, useSelector } from "react-redux";

import { selectProfileCompetenceStatus } from "src/shared/store/ducks/profile-competence/";

import { simulationsModel } from "src/entities/public/simulations";

import { LevelUpList } from "src/features/player/lvl-up";

import { SessionRulesPopup } from "src/features/public/session-rules-popup/session-rules-popup";

import "./level-up-page.scss";
import { $keycloak } from "../../entities/public/keycloak/model";

interface Props {}

const { fetchSimulations, $isLoading } = simulationsModel;

export const LevelUpPage: FC<Props> = (): ReactElement => {
  const dispatch = useDispatch();

  const keycloak = useUnit($keycloak);

  useUnit($isLoading);

  useSelector(selectProfileCompetenceStatus);

  useEffect(() => {
    fetchSimulations();

    // todo: hrp-502
    // if (keycloak?.subject) {
    //   dispatch(
    //     requestProfileCompetence({
    //       id: keycloak?.subject,
    //     })
    //   );
    // }
  }, [dispatch, keycloak?.subject]);

  return (
    <>
      <div className="level-up-page">
        <LevelUpList />
      </div>
      <SessionRulesPopup />
    </>
  );
};
