import { format } from "date-fns";
import {
  AdmAssessmentDto,
  AssessmentStatus,
  AssessmentType,
} from "../../generated/game";
import { AssessmentBriefDto, PlayerDto } from "../../generated/statistic";
import { TableItem } from "../models/table";
import { UserRoleEnum } from "../models/user";
import { UserInfoInterface } from "../store/types";
import { languagePicker } from "./languagePicker";

export const getTableItems = (
  item: UserInfoInterface | AdmAssessmentDto | PlayerDto | AssessmentBriefDto,
  lang?: string,
) => {
  const localItem: TableItem = {
    id: "",
    checked: undefined,
    position: undefined,
    firstname: undefined,
    lastname: undefined,
    avatar: undefined,
    phone: undefined,
    phoneAdditional: undefined,
    email: undefined,
    emailVerify: undefined,
    city: undefined,
    dateOfBirth: undefined,
    studyWorkPlace: undefined,
    resume: undefined,
    lastActivity: undefined,
    rating: undefined,
    communication: undefined,
    analytics: undefined,
    command: undefined,
    role: undefined,
    status: undefined,
    closeAction: undefined,
    login: undefined,
    userId: undefined,
    gameName: undefined,
    gameCode: undefined,
    gameType: undefined,
    gameMembers: undefined,
    gameCreated: undefined,
    gameRun: undefined,
    gameEnd: undefined,
    gameStatus: undefined,
    assessmentCurPlayers: undefined,
    place: undefined,
    competence: [],
  };

  const user = item as UserInfoInterface;
  const assessment = item as AdmAssessmentDto;
  const finishedAssessment = item as AssessmentBriefDto;
  const player = item as PlayerDto;

  if (user) {
    if (user.id) {
      localItem.id = user.id;
    }

    if (user.firstName) {
      localItem.firstname = user.firstName;
    }

    if (user.lastName) {
      localItem.lastname = user.lastName;
    }

    if (user.email) {
      localItem.email = user.email;
    }

    if (user.attributes?.phoneNumber) {
      localItem.phone = user.attributes.phoneNumber[0];
    }

    if (user.attributes?.login) {
      localItem.login = user.attributes.login[0];
    }

    if (user.attributes?.locality) {
      localItem.city = user.attributes.locality[0];
    }

    if (!!user.attributes?.birthdate?.[0]) {
      localItem.dateOfBirth = format(
        new Date(user.attributes.birthdate[0]),
        "dd.MM.yyyy",
      );
    }

    if (user.attributes?.studyWorkPlace) {
      localItem.studyWorkPlace = user.attributes.studyWorkPlace[0];
    }

    if (user.attributes?.workPhoneNumber) {
      localItem.phoneAdditional = user.attributes.workPhoneNumber[0];
    }

    if (user.attributes?.avatarUrl) {
      localItem.avatar = user.attributes.avatarUrl[0];
    }

    if (user.realmRoles && lang) {
      const tmpRole = () => {
        if (user.realmRoles?.includes(UserRoleEnum.SUPERADMIN)) {
          return lang === "ru" ? "Суперадмин" : "Superadmin";
        } else if (user.realmRoles?.includes(UserRoleEnum.HR_ADMIN)) {
          return lang === "ru" ? "Админ" : "Admin";
        } else if (user.realmRoles?.includes(UserRoleEnum.HR_ALABUGA)) {
          return lang === "ru" ? "HR Алабуги" : "HR Alabuga";
        } else if (user.realmRoles?.includes(UserRoleEnum.HR_STAFF)) {
          return "HR";
        } else if (user.realmRoles?.includes(UserRoleEnum.PLAYER)) {
          return lang === "ru" ? "Участник" : "Member";
        }

        return undefined;
      };

      localItem.role = tmpRole();
    }

    localItem.resume = !!user.attributes?.resumeUrl?.[0];
  }

  if (assessment) {
    if (assessment.id) {
      localItem.id = assessment.id;
    }

    if (assessment.gameTitle && lang) {
      localItem.gameName =
        assessment.title || languagePicker(assessment.gameTitle, lang);
    }

    if (assessment.code) {
      localItem.gameCode = assessment.code;
    }

    if (assessment.assessmentType && lang) {
      if (assessment.assessmentType === AssessmentType.Rating) {
        localItem.gameType = lang === "ru" ? "Рейтингуемая" : "Rated";
      } else if (assessment.assessmentType === AssessmentType.NonRating) {
        localItem.gameType = lang === "ru" ? "Нерейтингуемая" : "Unrated";
      }
    }

    if (!isNaN(assessment.maxPlayers) && !isNaN(assessment.curPlayers)) {
      localItem.assessmentCurPlayers = assessment.curPlayers;
      localItem.gameMembers = `${assessment.curPlayers}/${assessment.maxPlayers}`;
    }

    if (assessment.assessmentStatus) {
      localItem.gameStatus = assessment.assessmentStatus;
    }

    if (assessment.createdAt) {
      localItem.gameCreated = format(
        new Date(assessment.createdAt),
        "dd.MM.yy HH:mm",
      );
    }

    if (assessment.startedAt) {
      localItem.gameRun = format(
        new Date(assessment.startedAt),
        "dd.MM.yy HH:mm",
      );
    }

    if (assessment.finishedAt) {
      localItem.gameEnd = format(
        new Date(assessment.finishedAt),
        "dd.MM.yy HH:mm",
      );
    }
  }

  if (finishedAssessment) {
    if (finishedAssessment.aId) {
      localItem.id = finishedAssessment.aId;
    }

    if (finishedAssessment.gTitle && lang) {
      localItem.gameName =
        finishedAssessment.aTitle ||
        languagePicker(finishedAssessment.gTitle, lang);
    }

    if (finishedAssessment.code) {
      localItem.gameCode = finishedAssessment.code;
    }

    if (finishedAssessment.aType && lang) {
      if (finishedAssessment.aType === AssessmentType.Rating) {
        localItem.gameType = lang === "ru" ? "Рейтингуемая" : "Rated";
      } else if (finishedAssessment.aType === AssessmentType.NonRating) {
        localItem.gameType = lang === "ru" ? "Нерейтингуемая" : "Unrated";
      }
    }

    if (
      !isNaN(finishedAssessment.maxPlayers) &&
      !isNaN(finishedAssessment.curPlayers)
    ) {
      localItem.assessmentCurPlayers = finishedAssessment.curPlayers;
      localItem.gameMembers = `${finishedAssessment.curPlayers}/${finishedAssessment.maxPlayers}`;
    }

    if (finishedAssessment.finishedAt) {
      localItem.gameStatus = AssessmentStatus.Finished;
    }

    if (finishedAssessment.createdAt) {
      localItem.gameCreated = format(
        new Date(finishedAssessment.createdAt),
        "dd.MM.yy HH:mm",
      );
    }

    if (finishedAssessment.startedAt) {
      localItem.gameRun = format(
        new Date(finishedAssessment.startedAt),
        "dd.MM.yy HH:mm",
      );
    }

    if (assessment.finishedAt) {
      localItem.gameEnd = format(
        new Date(assessment.finishedAt),
        "dd.MM.yy HH:mm",
      );
    }
  }

  if (player) {
    if (player.pId) {
      localItem.id = player.pId;
    }

    if (player.firstname) {
      localItem.firstname = player.firstname;
    }

    if (player.lastname) {
      localItem.lastname = player.lastname;
    }

    if (player.place) {
      localItem.place = String(player.place);
    }

    localItem.competence?.push(
      String(
        player.communicationDiff !== undefined
          ? player.communicationDiff.toFixed(2)
          : 0.0,
      ),
    );

    localItem.competence?.push(
      String(
        player.analyticsDiff !== undefined
          ? player.analyticsDiff.toFixed(2)
          : 0.0,
      ),
    );

    localItem.competence?.push(
      String(
        player.commandDiff !== undefined ? player.commandDiff.toFixed(2) : 0.0,
      ),
    );

    localItem.rating = String(
      localItem.competence
        ?.reduce((sum: number, competenceItem: string) => {
          return sum + Number(competenceItem) / 3;
        }, 0)
        .toFixed(2),
    );

    if (player.role) {
      localItem.role = player.role;
    }
  }

  return localItem;
};
