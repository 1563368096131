import { GetListParams } from "src/shared/api/public/countries";
import { QueryParams } from "src/shared/api/base";

export const queryParamsToGetListParamsAdapter = (
  queryParams: QueryParams = {},
): GetListParams => ({
  countryCode: queryParams.code ? [queryParams.code] : undefined,
  title: queryParams.title ? [queryParams.title] : undefined,
  locale: queryParams.locale,
  orderBy: queryParams.orderBy ? queryParams.orderBy.split(",") : undefined,
  pageNum: queryParams.pageNum ? Number(queryParams.pageNum) : 1,
  pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 1000,
});
