import { lockPageScroll } from "../../../../shared/helpers/lockPageScroll";
import { createStore, createEvent } from "effector";
import { IAppInterface } from "./types";

//store

export const $appInterface = createStore<IAppInterface>({
  isHeaderShowed: true,
  isMobileMenuShowed: false,
});

//events

export const toggleHeader = createEvent<boolean>();

export const toggleMobileMenu = createEvent<boolean>();

//Logic

$appInterface.on(toggleHeader, (state, isHeaderShowed) => ({
  ...state,
  isHeaderShowed,
}));

$appInterface.on(toggleMobileMenu, (state, isMobileMenuShowed) => ({
  ...state,
  isMobileMenuShowed,
}));

toggleMobileMenu.watch((isMobileMenuShowed) => {
  lockPageScroll(isMobileMenuShowed);
});
