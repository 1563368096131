import { EPopupName, popupModel } from "../../shared/components/base-popup";

const { openPopup } = popupModel;

// TODO: HRP-228 исправить any
const openGameRulesPopup = (text: any): void => {
  openPopup({
    name: EPopupName.GAME_RULES,
    message: {
      text: text,
    },
  });
};

export default openGameRulesPopup;
