import { FC, Fragment, ReactElement, ReactNode } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import "./base-pagination.scss";
import { BaseSelect } from "../form-fields";
import { SelectItem } from "../form-fields/base-select/base-select";
import { useTranslation } from "react-i18next";
import { TextWithIcon } from "../text-with-icon/text-with-icon";

interface Props {
  className?: string;
  pagesCount: number;
  currentPage: number;
  url: string;
  queryParams?: string;
  pageSize?: number;
  onUpdatePageSize?: (pageSize: number) => void;
  goToPage?: (pageNum: number) => void;
}

export const BasePagination: FC<Props> = ({
  className,
  pagesCount,
  currentPage,
  url,
  pageSize,
  onUpdatePageSize,
  goToPage,
  queryParams = "",
}): ReactElement => {
  const { t } = useTranslation();

  const BasePaginationItem: FC<{ page: number; children?: ReactNode }> = ({
    page,
    children,
  }): ReactElement => {
    const searchParams = new URLSearchParams(queryParams);

    searchParams.delete("pageNum");

    if (page > 1) {
      searchParams.set("pageNum", String(page));
    }

    return (
      <li
        className={classNames("base-pagination__item", {
          "base-pagination__item--active": page === currentPage,
          "base-pagination__item--has-arrow": !!children,
        })}
      >
        <Link
          to={{
            pathname: url,
            search: searchParams.toString(),
          }}
          className="base-pagination__link"
          aria-current={page === currentPage ? true : undefined}
          aria-label={`Перейти на страницу ${page}`}
          onClick={(e) => {
            if (goToPage) {
              e.preventDefault();

              goToPage(page);
            }
          }}
        >
          {children || page}
        </Link>
      </li>
    );
  };

  const pageSizeItemsNumbers: number[] = [50, 100, 500, 1000];

  if (pageSize && !pageSizeItemsNumbers.includes(pageSize)) {
    pageSizeItemsNumbers.push(pageSize);

    pageSizeItemsNumbers.sort((a, b) => a - b);
  }

  const pageSizeItems: SelectItem[] = pageSizeItemsNumbers.map((num) => ({
    value: String(num),
    label: String(num),
  }));

  return (
    <Fragment>
      <div className={classNames("base-pagination", className)}>
        {!!pageSize && (
          <div className="base-pagination__page-size" aria-hidden="true">
            <span className="base-pagination__page-size-label">
              {t("pagination.pageSize")}
            </span>
            <BaseSelect
              menuPlacement="top"
              className="base-pagination__page-size-select"
              items={pageSizeItems}
              onChange={(payload) =>
                onUpdatePageSize && onUpdatePageSize(Number(payload.value))
              }
              activeItem={String(pageSize)}
              hideEmptyItem
              name="pageSize"
              isTopPosition
              size="small"
            />
          </div>
        )}
        <nav
          className="base-pagination__nav"
          role="navigation"
          aria-label="Навигация по страницам"
        >
          <ul className="base-pagination__items">
            {currentPage > 1 && (
              <BasePaginationItem key={"prev"} page={currentPage - 1}>
                <TextWithIcon
                  className="base-pagination__arrow base-pagination__arrow--prev"
                  iconName="arrow-down"
                  hideLabel
                  iconSize={32}
                />
              </BasePaginationItem>
            )}
            {currentPage - 3 < currentPage && currentPage - 3 > 0 && (
              <BasePaginationItem key="firstpage" page={1} />
            )}
            {currentPage - 4 < currentPage && currentPage - 4 > 0 && (
              <li className="base-pagination__item">...</li>
            )}
            {currentPage - 2 < currentPage && currentPage - 2 > 0 && (
              <BasePaginationItem
                key={currentPage - 2}
                page={currentPage - 2}
              />
            )}
            {currentPage - 1 < currentPage && currentPage - 1 > 0 && (
              <BasePaginationItem
                key={currentPage - 1}
                page={currentPage - 1}
              />
            )}
            <BasePaginationItem key="current" page={currentPage} />
            {currentPage + 1 < pagesCount && (
              <BasePaginationItem
                key={currentPage + 1}
                page={currentPage + 1}
              />
            )}
            {currentPage + 2 < pagesCount && (
              <BasePaginationItem
                key={currentPage + 2}
                page={currentPage + 2}
              />
            )}
            {currentPage + 3 < pagesCount && (
              <li
                className="base-pagination__item base-pagination__item--empty"
                aria-hidden="true"
              >
                ...
              </li>
            )}
            {currentPage < pagesCount && (
              <Fragment>
                <BasePaginationItem key="lastpage" page={pagesCount} />
                <BasePaginationItem key={"next"} page={currentPage + 1}>
                  <TextWithIcon
                    className="base-pagination__arrow base-pagination__arrow--next"
                    iconName="arrow-down"
                    hideLabel
                    iconSize={32}
                  />
                </BasePaginationItem>
              </Fragment>
            )}
          </ul>
        </nav>
      </div>
    </Fragment>
  );
};
