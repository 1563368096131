import axios from "axios";
import { SimulationAdminInterface } from "src/shared/store/types";

export const deleteSimulationById = async (payload: {
  id: string;
}): Promise<SimulationAdminInterface> => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_GAME_URL}/api/v1/admin/games/${payload.id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("alabuga-token"),
      },
    },
  );

  return data.game;
};
