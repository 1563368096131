import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasePopup, EPopupName } from "../../base-popup";
import { BaseSwitcher, SwitcherItem } from "../../base-switcher/base-switcher";

import cls from "./invite-relative-popup.module.scss";
import { CopyInput } from "../../copy-input/copy-input";
import { snackbarOpen } from "../../../../features/public/snackbar";
import { BaseButton } from "../../base-button/base-button";
import { $activePopups, closePopup } from "../../base-popup/model";
import {
  $relativeLink,
  fetchCreateLink,
} from "../../../../entities/public/relatives/create-link/create-link";
import { useUnit } from "effector-react";
import { CountDown } from "../../../helpers/countDown";

interface Props {}

export const InviteRelativePopup: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const relativeLink = useUnit($relativeLink);

  const updateLinkTime = relativeLink?.date_create
    ? new Date(relativeLink.date_create)
    : null;

  const [isAuth, setIsAuth] = useState<boolean>(true);

  const activePopups = useUnit($activePopups);

  const currentPopup = activePopups.find(
    ({ name }) => name === EPopupName.INVITE_RELATIVE,
  );

  useEffect(() => {
    if (currentPopup?.data?.callback) {
      currentPopup.data.callback();
    }
  }, [currentPopup?.data]);

  const switcherItems: SwitcherItem[] = [
    {
      onClick: () => setIsAuth(true),
      title: t("table.header.auth"),
      isActive: isAuth,
    },
    {
      onClick: () => setIsAuth(false),
      title: t("table.header.created"),
      isActive: !isAuth,
    },
  ];

  const onCopyLink = (): void => {
    snackbarOpen({
      text: t("popup.baseMessage.copied"),
      visible: true,
      type: "success",
      verticalCentered: true,
    });
  };

  const createLink = () => {
    fetchCreateLink();
    snackbarOpen({
      visible: true,
      text: t("relative.linkUpdated"),
      type: "success",
      verticalCentered: true,
    });
  };

  const linkType = isAuth ? "auth" : "registrations";

  const link = relativeLink?.id
    ? `${process.env.REACT_APP_KC_URL}/realms/Alabuga/protocol/openid-connect/${linkType}?client_id=alb-player&response_type=code&scope=openid&&redirect_uri=${process.env.REACT_APP_DOMAIN}%2Fmy-competence%3Finvite_link%3D${relativeLink.id}`
    : "";

  const inputPlaceHolder = isAuth
    ? t("relative.linkForAuthInvite")
    : t("relative.linkForRegInvite");

  const timerElement = updateLinkTime && (
    <>
      <span className={cls.invitePopup_buttons__text}>{`${t(
        "button.recreate",
      )}:`}</span>
      <CountDown countDownTime={updateLinkTime} />
    </>
  );

  return (
    <BasePopup
      name={EPopupName.INVITE_RELATIVE}
      className={cls.invitePopup}
      isWithoutPadding
      withoutScroll
      closeButton={false}
    >
      <div className={cls.invitePopup_content}>
        <div className={cls.invitePopup_title}>
          {t("relative.linkForInvite")}
        </div>
        <span className={cls.invitePopup_desc}>{t("relative.InviteDesc")}</span>
        <BaseSwitcher
          className={cls.invitePopup_switcher}
          items={switcherItems}
        />
        <div className={cls.invitePopup_link}>
          <CopyInput
            placeholder={inputPlaceHolder}
            value={link}
            onCopy={onCopyLink}
          />
        </div>
        <div className={cls.invitePopup_buttons}>
          <BaseButton
            outline
            large
            block
            small
            onClick={() => {
              closePopup({ name: EPopupName.INVITE_RELATIVE });
            }}
          >
            {t("common.cancel")}
          </BaseButton>
          {relativeLink?.count_create !== 2 ? (
            <BaseButton primary block large onClick={createLink}>
              {t("button.recreate")}
            </BaseButton>
          ) : (
            <BaseButton disabled primary block large>
              {timerElement}
            </BaseButton>
          )}
        </div>
      </div>
    </BasePopup>
  );
};
