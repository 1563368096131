import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useUnit } from "effector-react";
import { reflect } from "@effector/reflect";
import classNames from "classnames";

import { useDelayUnmount } from "../../hooks/useDelayUnmount";
import { useMutationObservable } from "../../hooks/useMutationObservable";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useNotifications } from "../../hooks/useNotifications";

import { BaseAvatar, BaseButton, NavList } from "../../components";
import { HeaderMobile } from "../../components/headerMobile/headerMobile";
import {
  NotificationButton,
  notificationsModel,
} from "../../../entities/public/notifications";
import { HeaderLinksMenu } from "./components/headerLinksMenu";
import { ReactComponent as Logo } from "../../images/aside-bar-logo.svg";
import { ReactComponent as ChevronDown } from "../../images/icons/chevron-down.svg";
import { ReactComponent as MenuIcon } from "../../images/icons/grid-icon.svg";
import {
  markAsRead,
  NotificationPanel,
} from "../../../features/public/notification";
import { HeaderDropdown } from "./components/headerDropdown";

import { $isDraft } from "../../../pages/profile/info-page/lib/draft";
import {
  $profile,
  $socialPlayerInfo,
} from "../../../features/public/app-access/model";
import { appAccessModel } from "src/features/public/app-access";
import { keycloakModel } from "src/entities/public/keycloak";
import {
  $socketGameV4RoomHrCommander,
  $socketGameV4RoomPlayerCommander,
} from "../../../shared/api/public/sockets/model/v4";
import {
  $specificChartsPermission,
  $specificRatingPermission,
  fetchSpecificChartsPermission,
  fetchSpecificDownloadPermission,
  fetchSpecificRatingPermission,
  fetchSpecificSessionPermission,
} from "../../../entities/public/permissions/model";
import { selectRoomName } from "../../store/ducks/room";
import { toggleMobileMenu } from "src/features/public/app-interface";
import { openPopup } from "../../../shared/components/base-popup/model";

import { EPopupName } from "../../../shared/components";
import { ELanguage } from "../../store/types";

import styles from "./header.module.scss";
import { IconButton } from "../icon-button";
import { StatusCompanyEnum } from "../../../generated/social";
import { RoleInRelatedProjectEnum } from "../../../generated/export";
import { $keycloak } from "../../../entities/public/keycloak/model";

interface Props {
  isPlayerClientId: boolean;
  isHrClientId: boolean;
  isAdminClientId: boolean;
}

interface IHeaderLinks {
  title: string;
  to: string;
  access: boolean;
  hidden?: boolean;
}

const { $isShowedPanel } = notificationsModel;

export const View: React.FC<Props> = ({
  isPlayerClientId,
  isHrClientId,
  isAdminClientId,
}) => {
  const keycloak = useUnit($keycloak);
  const storeKeycloak = useUnit($keycloak);
  const profile = useUnit($profile);
  const { t } = useTranslation();
  const isRatingAccess = useUnit($specificRatingPermission);
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const isAccess = useUnit(appAccessModel.$isAccess);
  const shouldRender = useDelayUnmount(isDropdownOpened, 200);
  const shouldRenderMenu = useDelayUnmount(isMenuOpened, 200);
  const navigate = useNavigate();
  const room = useSelector(selectRoomName);
  const socketHrRoomCommander = useUnit($socketGameV4RoomHrCommander);
  const socketPlayerRoomCommander = useUnit($socketGameV4RoomPlayerCommander);
  const { isLaptop } = useMediaQuery();
  const isShowedPanel = useUnit($isShowedPanel);
  const isDraft = useUnit($isDraft);
  const socialPlayerInfo = useUnit($socialPlayerInfo);
  const isShowedMenu =
    (socialPlayerInfo?.statusCompany === StatusCompanyEnum.Student ||
      socialPlayerInfo?.statusCompany === StatusCompanyEnum.Employee ||
      socialPlayerInfo?.statusCompany === StatusCompanyEnum.Relative ||
      socialPlayerInfo?.roleInRelatedProjects?.some(
        (role) =>
          role === RoleInRelatedProjectEnum.Teacher ||
          role === RoleInRelatedProjectEnum.AdministratorSchedule,
      )) ??
    false;

  const chartsPermission = useUnit($specificChartsPermission);

  useEffect(() => {
    if (keycloak?.subject && !isPlayerClientId) {
      fetchSpecificRatingPermission({
        pId: keycloak?.subject,
        permissionName: "table-rating",
      });
      fetchSpecificSessionPermission({
        pId: keycloak.subject,
        permissionName: "session-manager-create-session",
      });
      fetchSpecificDownloadPermission({
        pId: keycloak.subject,
        permissionName: "table-rating-export-users",
      });
      fetchSpecificChartsPermission({
        pId: keycloak.subject,
        permissionName: "graphics",
      });
    }
  }, [keycloak?.subject]);

  const asideBarLinksPlayer: IHeaderLinks[] = [
    {
      title: t("navigation.myCompetence"),
      to: "/my-competence",
      access: !!isAccess,
    },
    {
      title: t("navigation.faq"),
      to: "/faq",
      access: true,
    },
  ];

  const asideBarLinksHr: IHeaderLinks[] = [
    {
      title: t("navigation.ratings"),
      to: "/ratings",
      access: !!isAccess && isRatingAccess.has_permission,
    },
    {
      title: t("navigation.sessions"),
      to: "/sessions",
      access: !!isAccess,
    },
    {
      title: t("navigation.moderation"),
      to: "/moderation",
      access: !!isAccess,
    },
    {
      title: t("navigation.charts"),
      to: "/charts",
      access: !!isAccess && chartsPermission.has_permission,
    },
    // {
    //   title: t("navigation.tournaments"),
    //   to: "/tournaments",
    //   access: true,
    //   hidden: !isEnabledFeature(FeatureName.TOURNAMENTS),
    // },
    // {
    //   title: t("navigation.contacts"),
    //   to: "/contacts",
    //   access: true,
    // },
  ];

  const asideBarLinksAdmin: IHeaderLinks[] = [
    {
      title: t("navigation.history"),
      to: "/history",
      access: isAccess,
    },
    {
      title: t("navigation.ratings"),
      to: "/ratings",
      access: isAccess && isRatingAccess.has_permission,
    },
    {
      title: t("navigation.users"),
      to: "/users-control",
      access: isAccess,
    },
    {
      title: t("navigation.simulations"),
      to: "/simulation-control",
      access: isAccess,
    },
    {
      title: t("navigation.assessments"),
      to: "/assessments-control",
      access: isAccess,
    },
    {
      title: t("navigation.complaints"),
      to: "/complaints",
      access: isAccess,
    },
    // {
    //   title: t("композитные права"),
    //   to: "/composite-rights",
    //   access: isAccess,
    // },
    {
      title: t("navigation.mailingLists"),
      to: "/mailing-lists",
      access: isAccess,
    },
    {
      title: t("navigation.other"),
      to: "/other",
      access: isAccess,
    },
    {
      title: t("navigation.charts"),
      to: "/charts",
      access: !!isAccess && chartsPermission.has_permission,
    },
  ];

  const links = (): IHeaderLinks[] => {
    if (isAdminClientId) {
      return asideBarLinksAdmin;
    }

    if (isHrClientId) {
      return asideBarLinksHr;
    }

    return asideBarLinksPlayer;
  };

  const closeMobileMenuHandler = () => {
    toggleMobileMenu(false);
  };

  const redirectToMain = () => {
    if (isPlayerClientId) {
      navigate(`/my-competence`);
    }

    if (isHrClientId) {
      navigate("/sessions/archive");
    }

    if (isAdminClientId) {
      navigate("/users-control/players");
    }
  };

  const logout = () => {
    const keycloakLogout = () => {
      if (!!room.assessmentId) {
        if (isPlayerClientId) {
          socketPlayerRoomCommander?.quit({ aid: room.assessmentId });
          if (keycloak) {
            keycloak.logout();
          }
        }

        if (isHrClientId) {
          socketHrRoomCommander?.quit({ aid: room.assessmentId });
          if (keycloak) {
            keycloak.logout();
          }
        }
      } else {
        if (keycloak) {
          keycloak.logout();
        }
      }
    };
    if (isDraft) {
      openPopup({
        name: EPopupName.USER_DRAFT_POPUP,
        data: {
          keycloak: keycloakLogout,
        },
      });
      return;
    }
    keycloakLogout();
    redirectToMain();
  };
  const { toggleNotificationPanel } = useNotifications();
  const readingNotifications = useUnit(markAsRead);

  const switchLanguage = useCallback(
    (value: ELanguage) => {
      i18n.changeLanguage(value);
      localStorage.setItem("language", value);
    },
    [i18n],
  );

  const watchByPageLang = useCallback(
    (e: MutationRecord[]) => {
      let className: string = "";
      let lang: string = "";

      e.forEach((item) => {
        const target = item.target as HTMLElement;

        lang = target.lang;

        className = target.className;
      });

      const isRuLang: boolean = lang === ELanguage.RU;

      const isTranslatedLtrClass: boolean =
        className.includes("translated-ltr");

      const isCurrentI18nLangRu: boolean = i18n.language === ELanguage.RU;

      const isCurrentI18nLangEn: boolean = i18n.language === ELanguage.EN;

      if (!isRuLang && isTranslatedLtrClass && !isCurrentI18nLangEn) {
        switchLanguage(ELanguage.EN);
      } else if (!isCurrentI18nLangRu && !isTranslatedLtrClass && isRuLang) {
        switchLanguage(ELanguage.RU);
      }
    },
    [switchLanguage, i18n],
  );

  useMutationObservable(document.documentElement, watchByPageLang, {
    attributes: true,
  });

  const closeOpenMenus = () => {
    setIsDropdownOpened(false);
    setIsMenuOpened(false);
    toggleNotificationPanel(false);
  };

  const onOpenNotifications = () => {
    setIsMenuOpened(false);
    readingNotifications();
    setIsDropdownOpened(false);
  };

  useEffect(() => {
    const mainLayout = document.querySelector(".main-layout");

    mainLayout?.addEventListener("mousedown", closeOpenMenus);

    return () => {
      mainLayout?.removeEventListener("mousedown", closeOpenMenus);
    };
  }, []);

  return !isLaptop ? (
    <HeaderMobile
      links={links()}
      storeKeycloak={storeKeycloak}
      name={`${profile?.firstName} ${profile?.lastName}`}
      avatarUrl={profile?.attributes?.avatarUrl?.[0]}
      logout={logout}
      setIsMenuOpened={setIsMenuOpened}
      isShowedMenu={isShowedMenu}
      isMenuOpened={isMenuOpened}
      shouldRender={shouldRenderMenu}
      switchLanguage={switchLanguage}
      isPlayerClientId={isPlayerClientId}
    />
  ) : (
    <header className={styles.header}>
      <div className={styles.header__content}>
        <div className={styles.header__menu}>
          {isShowedMenu && (
            <IconButton
              icon={<MenuIcon />}
              onClick={() => {
                setIsDropdownOpened(false);
                toggleNotificationPanel(false);
                setIsMenuOpened((prev) => !prev);
              }}
            />
          )}
        </div>
        <div className={styles.header__logo}>
          <BaseButton
            onClick={redirectToMain}
            aria-label={t("common.goToHomePage")}
          >
            <Logo width={136} height={32} />
          </BaseButton>
        </div>
        <div className={styles.header__navigator}>
          <NavList
            level={4}
            links={links()}
            closeMobileMenuHandler={closeMobileMenuHandler}
          />
        </div>
        <div className={styles.header__buttons}>
          <NotificationButton
            className={styles.header__buttons_notification}
            hideMobile
            onOpen={onOpenNotifications}
          />
          {isShowedPanel && <NotificationPanel />}
          <div
            onClick={() => {
              setIsDropdownOpened((prev) => !prev);
              setIsMenuOpened(false);
              toggleNotificationPanel(false);
            }}
            className={classNames(styles.header__buttons_avatar, {
              [styles.header__buttons_avatar_is_dropdown]: isDropdownOpened,
            })}
          >
            {!!storeKeycloak?.subject && (
              <>
                <BaseAvatar
                  customPath={profile?.attributes?.avatarUrl?.[0]}
                  size={34}
                  userId={storeKeycloak.subject}
                  alt={t("profile.myAvatar")}
                  outlineType
                />
                <ChevronDown
                  className={classNames(styles.header__buttons_chevron, {
                    [styles.header__buttons_chevron_is_opened]:
                      isDropdownOpened,
                  })}
                />
              </>
            )}
            <HeaderDropdown
              shouldRender={shouldRender}
              isDropdownOpened={isDropdownOpened}
              setIsDropdownOpened={setIsDropdownOpened}
              storeKeycloak={storeKeycloak}
              name={`${profile?.firstName} ${profile?.lastName}`}
              avatarUrl={profile?.attributes?.avatarUrl?.[0]}
              logout={logout}
              switchLanguage={switchLanguage}
              isPlayerClientId={isPlayerClientId}
            />
          </div>
        </div>
        <HeaderLinksMenu
          isLaptop={isLaptop}
          onClose={closeOpenMenus}
          shouldRender={shouldRenderMenu}
          isMenuOpened={isMenuOpened}
        />
      </div>
    </header>
  );
};

const { $isPlayerClientId, $isHrClientId, $isAdminClientId } = keycloakModel;

export const Header = reflect<Props>({
  view: View,
  bind: {
    isPlayerClientId: $isPlayerClientId,
    isHrClientId: $isHrClientId,
    isAdminClientId: $isAdminClientId,
  },
});
