import { createDomain, sample } from "effector";

import i18n from "../i18n/i18n";

import { ELanguage } from "../store/types";

// Types

export type { ELanguage } from "../store/types";
export type { TFunction } from "i18next";

// Vars

export const t = i18n.t.bind(i18n);

export const defaultLanguage =
  (process.env.REACT_APP_DEFAULT_LANGUAGE as ELanguage) || ELanguage.RU;

// Domains

const locale = createDomain();

// Events

export const languageChange = locale.createEvent<ELanguage>();

// Stores

export const $currentLanguage = locale.createStore<ELanguage>(defaultLanguage);

// Logic

i18n.on("languageChanged", (currentLocale?: ELanguage) => {
  if (currentLocale) {
    languageChange(currentLocale);
  }
});

sample({
  clock: languageChange,
  target: $currentLanguage,
});
