import { FC } from "react";

import classNames from "classnames";

import { t } from "src/shared/helpers";

import { AssessmentType } from "src/generated/notification";

import { BaseButton } from "src/shared/components";

import "./assessment-card.scss";

export type AssessmentCardProps = {
  title: string;
  simulationTitle: string;
  assessmentType?: AssessmentType;
  isRequired?: boolean;
  url?: string;
  type?: "invite" | "connect";
  badgeProps?: {
    text: string;
    theme: "wait";
  } | null;
};

export const AssessmentCard: FC<AssessmentCardProps> = ({
  type,
  title,
  url,
  badgeProps,
  simulationTitle,
  assessmentType,
  isRequired = false,
}) => {
  const isConnectType = type === "connect";

  return (
    <div className="assessment-tile">
      <div className="assessment-tile__header assesment-header">
        <div className="assessment-header__left-side">
          <h6 className="assessment-header__simulation-title">
            {simulationTitle}
          </h6>

          <p className="assessment-header__title">{title}</p>
        </div>

        {badgeProps && (
          <div
            className={classNames("tile-badge assesment-header__right-side", {
              [`tile-badge-${badgeProps?.theme}`]: badgeProps?.theme,
            })}
          >
            {badgeProps?.text}
          </div>
        )}
      </div>

      {isRequired && (
        <section className="assessment-participation">
          <h6 className="assessment-participation__title">
            {t("notifications.panel.card.participation.title")}
          </h6>
          <p className="assessment-participation__description">
            {t("notifications.panel.card.participation.description")}
          </p>
        </section>
      )}

      <div className="assessment-content__types">
        {assessmentType && (
          <p className="assessment-content__type">
            {t(`common.assessment.${assessmentType}`)}
          </p>
        )}

        {isConnectType && (
          <p className="assessment-content__type">
            {t("sessions.session.open")}
          </p>
        )}
      </div>

      {url && (
        <BaseButton
          className={classNames(
            "assessment-content__button",
            "assessment-tile__button",
            {
              "assessment-tile__button--showed": type === "connect",
            },
          )}
          to={url}
          light
        >
          {t("popup.session.join.button")}
        </BaseButton>
      )}

      {url && (
        <BaseButton
          className={classNames(
            "assessment-tile__button-invite",
            "assessment-tile__button",
            {
              "assessment-tile__button--showed": type === "invite",
            },
          )}
          to={url}
          light
        >
          {t("popup.session.active.button")}
        </BaseButton>
      )}
    </div>
  );
};
