import { socialService } from "../../../base/api-instances";
import {
  CheckUserPermissions,
  GetUserPermissions,
  WriteUserPermissions,
} from "./query";

export const getAllUserPermissions = (params: GetUserPermissions) => {
  return socialService
    .makePermissionRequest()
    .getUserPermissions(params)
    .then((res) => res.data);
};

export const writeAllUserPermissions = (params: WriteUserPermissions) => {
  return socialService
    .makePermissionRequest()
    .writeUserPermissions(params)
    .then((res) => res.data);
};
export const CheckUserPermission = (params: CheckUserPermissions) => {
  return socialService
    .makePermissionRequest()
    .checkUserPermission(params)
    .then((res) => res.data);
};
