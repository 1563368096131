import { combine, createEffect, sample } from "effector";

import { keycloakModel } from "src/entities/public/keycloak";

import { appAccessDomain } from "./domain";
import { getIsAccess } from "../lib/getIsAccess";
import { $isFullProfile } from "./components-for-access";
import {
  $hasSocial,
  $isSomeCompetenceFirstLvl,
  $isVerificationSession,
} from "./user-social";
import { fetchPostProfileFx } from "./profile";
import { verifyClient } from "../lib/verifyClient";

const {
  $keycloak,
  $isPlayerClientId,
  $isHrClientId,
  $isAdminClientId,
  $isPlayerRole,
  $isHrStaffRole,
  $isHrAlabugaRole,
  $isHrAdminRole,
} = keycloakModel;

// Stores

export const $isAccess = appAccessDomain.createStore<boolean | null>(null);

export const $firstTry = appAccessDomain.createStore<boolean>(false);

// Events

export const setFirstTry = appAccessDomain.createEvent<boolean>();

// Effects

const verifyClientFx = createEffect(verifyClient);

// Logic

sample({
  clock: setFirstTry,
  target: $firstTry,
});

sample({
  clock: [
    $isFullProfile,
    $hasSocial,
    $isVerificationSession,
    $isSomeCompetenceFirstLvl,
    $isPlayerClientId,
    fetchPostProfileFx.done,
  ],
  source: combine(
    $isFullProfile,
    $hasSocial,
    $isVerificationSession,
    $isSomeCompetenceFirstLvl,
    $isPlayerClientId,
    (
      isFullProfile,
      hasSocial,
      isVerificationSession,
      isSomeCompetenceFirstLvl,
      isPlayerClientId,
    ) => ({
      isFullProfile,
      hasSocial,
      isVerificationSession,
      isSomeCompetenceFirstLvl,
      isPlayerClientId,
    }),
  ),
  fn: getIsAccess,
  target: $isAccess,
});

sample({
  clock: $keycloak,
  source: combine({
    $keycloak,
    $isPlayerClientId,
    $isHrClientId,
    $isAdminClientId,
    $isPlayerRole,
    $isHrStaffRole,
    $isHrAlabugaRole,
    $isHrAdminRole,
  }),
  fn: (params) => params,
  target: verifyClientFx,
});
