import { Action } from "redux";
import { IUsersControlPayloadItems } from "./type";

export enum EUsersControlActions {
  REQUEST_DELETE_USERS = "usersControl/REQUEST_DELETE_USERS",
  SUCCESS_DELETE_USERS = "usersControl/SUCCESS_DELETE_USERS",
  FAILURE_DELETE_USERS = "usersControl/FAILURE_DELETE_USERS",
  REQUEST_DISABLE_USERS = "usersControl/REQUEST_DISABLE_USERS",
  SUCCESS_DISABLE_USERS = "usersControl/SUCCESS_DISABLE_USERS",
  FAILURE_DISABLE_USERS = "usersControl/FAILURE_DISABLE_USERS",
  REQUEST_ENABLE_USERS = "usersControl/REQUEST_ENABLE_USERS",
  SUCCESS_ENABLE_USERS = "usersControl/SUCCESS_ENABLE_USERS",
  FAILURE_ENABLE_USERS = "usersControl/FAILURE_ENABLE_USERS",
}

export interface IRequestUsersControlDelete
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.REQUEST_DELETE_USERS;
  payload: IUsersControlPayloadItems;
}

export interface ISuccessUsersControlDelete
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.SUCCESS_DELETE_USERS;
}

export interface IFailureUsersControlDelete
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.FAILURE_DELETE_USERS;
}

export interface IRequestUsersControlDisable
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.REQUEST_DISABLE_USERS;
  payload: IUsersControlPayloadItems;
}

export interface ISuccessUsersControlDisable
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.SUCCESS_DISABLE_USERS;
}

export interface IFailureUsersControlDisable
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.FAILURE_DISABLE_USERS;
}

export interface IRequestUsersControlEnable
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.REQUEST_ENABLE_USERS;
  payload: IUsersControlPayloadItems;
}

export interface ISuccessUsersControlEnable
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.SUCCESS_ENABLE_USERS;
}

export interface IFailureUsersControlEnable
  extends Action<EUsersControlActions> {
  type: EUsersControlActions.FAILURE_ENABLE_USERS;
}
