export enum ERegEx {
  VALID_EMAIL = "valid-email",
  NOT_PLUS_AND_NUMBERS = "not-plus-and-numbers",
  ONLY_SPACES = "only-spaces",
  HTML_TAGS = "html-tags",
  NOT_NUMBERS = "not-numbers",
  ONLY_NUMBERS = "only-numbers",
  VALID_SIMULATION_URL = "valid-simulation-url",
  VALID_LINK = "valid_link",
}

export const regExList: {
  [key in ERegEx]: RegExp;
} = {
  [ERegEx.VALID_EMAIL]: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  [ERegEx.NOT_PLUS_AND_NUMBERS]: /[^+\d]/g,
  [ERegEx.ONLY_SPACES]: /\s/g,
  [ERegEx.HTML_TAGS]: /(<([^>]+)>)/gi,
  [ERegEx.NOT_NUMBERS]: /[^\d]/g,
  [ERegEx.ONLY_NUMBERS]: /[^\d.]/g,
  [ERegEx.VALID_SIMULATION_URL]: /ds-\d{2}\./,
  [ERegEx.VALID_LINK]: /^(ftp|http|https):\/\/[^ "]+$/,
};
