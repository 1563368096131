import { sample } from "effector";

import { UserTypeEnum } from "src/shared/models/user";

import { userFormDomain } from "./domain";

// Stores

export const $userType = userFormDomain.createStore<UserTypeEnum | null>(null);

export const $isPlayer = $userType.map(
  (state) => state === UserTypeEnum.PLAYER,
);

export const $isHr = $userType.map((state) => state === UserTypeEnum.HR);

export const $isAdmin = $userType.map((state) => state === UserTypeEnum.ADMIN);

// Events

export const setUserType = userFormDomain.createEvent<UserTypeEnum>();

// Logic

sample({ clock: setUserType, target: $userType });
