import { call, put, takeLatest } from "redux-saga/effects";
import {
  EPopupName,
  openResponseErrorPopup,
  popupModel,
} from "src/shared/components/base-popup";
import i18n from "src/shared/i18n/i18n";
import { putRepeatVerifiedEmail } from "src/shared/api/keycloak/put-repeat-verified-email";
import { getProfileInfo } from "src/shared/api/profile-info/get-profile-info";
import { postProfileInfo } from "src/shared/api/profile-info/post-profile-info";
import { UserInfoInterface } from "../../types";
import { setIsSocial } from "../profile-competence/actionCreators";
import { snackbarOpen } from "src/features/public/snackbar";
import {
  failureGetProfileInfo,
  failurePostProfileInfo,
  failurePutRepeatVerifiedEmail,
  successGetProfileInfo,
  successPostProfileInfo,
  successPutRepeatVerifiedEmail,
} from "./actionCreators";
import {
  ProfileInfoActionsType,
  RequestGetProfileInfoActionInterface,
  RequestPostProfileInfoActionInterface,
  RequestPutRepeatVerifiedEmailActionInterface,
} from "./actionTypes";

const { openPopup } = popupModel;

export function* fetchGetProfileInfo({
  payload,
}: RequestGetProfileInfoActionInterface) {
  try {
    const profile: UserInfoInterface = yield call(getProfileInfo, payload);

    yield put(successGetProfileInfo(profile));

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    yield put(failureGetProfileInfo());
  }
}

export function* fetchPostProfileInfo({
  payload,
}: RequestPostProfileInfoActionInterface) {
  try {
    const profile: UserInfoInterface = yield call(postProfileInfo, payload);

    if (!profile.emailVerified) {
      yield call(putRepeatVerifiedEmail, payload);

      openPopup({ name: EPopupName.EMAIL_VERIFY });
    }

    yield put(successPostProfileInfo(profile));
    yield put(setIsSocial(true));

    if (profile && profile.emailVerified) {
      yield snackbarOpen({
        visible: true,
        text: i18n.t("common.changesSaved"),
        type: "success",
      });
    }

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.errorMessage);

    yield put(failurePostProfileInfo());
  }
}

export function* fetchPutRepeatVerifiedEmail({
  payload,
}: RequestPutRepeatVerifiedEmailActionInterface) {
  try {
    yield call(putRepeatVerifiedEmail, payload);

    yield put(successPutRepeatVerifiedEmail());

    openPopup({ name: EPopupName.EMAIL_VERIFY });
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.errorMessage);

    yield put(failurePutRepeatVerifiedEmail());
  }
}

export function* profileInfoSaga() {
  yield takeLatest(
    ProfileInfoActionsType.REQUEST_GET_PROFILE_INFO,
    fetchGetProfileInfo,
  );
  yield takeLatest(
    ProfileInfoActionsType.REQUEST_POST_PROFILE_INFO,
    fetchPostProfileInfo,
  );
  yield takeLatest(
    ProfileInfoActionsType.REQUEST_PUT_REPEAT_VERIFIED_EMAIL,
    fetchPutRepeatVerifiedEmail,
  );
}
