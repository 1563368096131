import { socialService } from "../../../base/api-instances";

import { GetRatingParams } from "./query";

export const getPlayers = (params: GetRatingParams) => {
  // return new Promise<GetRatingRes>((resolve) => {
  //   setTimeout(() => {
  //     resolve(getPlayerRatingsMock());
  //   }, 1000);
  // });

  return socialService
    .makeRatingsRequest()
    .getRating(params)
    .then((res) => res.data);
};
