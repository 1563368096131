import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseButton,
  BaseSelect,
  EPopupName,
  SelectItem,
  TextWithIcon,
} from "src/shared/components";
import { NotificationType } from "../newsletter-form";
import { openPopup } from "src/shared/components/base-popup/model";
import { IBaseInputChange, IBaseMultiSelectChange } from "src/shared/models";
import { useUrlState } from "src/shared/hooks/useUrlState";

interface Props {
  onClickCreateNotification: () => void;
  onChangeTypeHandler: (
    payload: IBaseInputChange | IBaseMultiSelectChange,
  ) => void;
  hasFormErrors: boolean;
}

export const NewsLetterControlPanel: FC<Props> = ({
  onClickCreateNotification,
  onChangeTypeHandler,
  hasFormErrors,
}) => {
  const { t } = useTranslation();

  const [urlState] = useUrlState();

  const showPopup = () => {
    openPopup({ name: EPopupName.EMAIL_PREVIEW });
  };

  const notificationListType = Object.values(
    NotificationType,
  ) as NotificationType[];

  const notificationItem: SelectItem[] = notificationListType.map((item) => ({
    label: t(`notifications.newsLetter.form.notificationType.${item}`),
    value: item,
  }));

  return (
    <div className="newsletter-form">
      <div className="newsletter-form__base-select">
        <BaseSelect
          hideEmptyItem
          placeholder={t("notifications.newsLetter.form.type")}
          items={notificationItem}
          activeItem={urlState.query.type}
          onChange={onChangeTypeHandler}
          name="type"
          className="newsletter-form__base-select-width"
        />
        <div className="newsletter-form__base-select-title">
          {t("notifications.newsLetter.form.choiceType")}
        </div>
      </div>
      <div className="newsletter-form__buttons">
        {urlState.query.type === NotificationType.E_MAIL && (
          <BaseButton
            small
            outline
            disabled={hasFormErrors}
            onClick={showPopup}
          >
            <TextWithIcon
              label={t("table.header.preview")}
              iconName="file-blue"
            />
          </BaseButton>
        )}
        <BaseButton
          primary
          small
          disabled={hasFormErrors}
          onClick={onClickCreateNotification}
        >
          {t("notifications.newsLetter.form.sendNewsletter")}
        </BaseButton>
      </div>
    </div>
  );
};
