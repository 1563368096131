import { IDataGridRow } from "src/shared/models/dataGrid";

import { getAvatarUrl } from "src/shared/helpers/pathToUserFile";

import { splitStrings } from "src/shared/helpers/splitStrings";
import i18n from "src/shared/i18n/i18n";
import { FineDto } from "src/generated/social";
import { daysForms, selectForm } from "src/shared/hooks/useSendingTime";

export const finesToRowsAdapter = (fines: FineDto[]) => {
  return fines.map<IDataGridRow>((fine) => {
    const fullName = splitStrings([
      fine.firstname_creator,
      fine.lastname_creator,
    ]);

    return {
      id: fine.fine_id,
      firstName: fine.firstname_creator,
      lastName: fine.lastname_creator,
      fullName,
      competenceName: i18n.t(`common.competence.${fine.competence}`),
      creationDate: fine.date_of_create,
      finePeriod: `${fine.period} ${selectForm(
        Number(fine.period),
        daysForms,
      )}`,
      ratingDowngrade: fine.removable_rating,
      avatarProps: {
        alt: fullName,
        fullName,
        customPath: getAvatarUrl(fine.pid),
        userId: fine.pid,
      },
      selected: false,
      archived: fine.archived,
    };
  });
};
