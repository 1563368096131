import classNames from "classnames";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BaseInputText } from "..";
import { ESimulationArrayParamType } from "../../../store/types";
import { BaseButton } from "../../base-button/base-button";
import { TextWithIcon } from "../../text-with-icon/text-with-icon";
import { BaseInputNumber } from "../base-input-number/base-input-number";
import "./base-array-field.scss";

interface Props {
  value: string[];
  placeholder?: string;
  name: string;
  onChange: (payload: { value: string[]; name: string }) => void;
  paramKey: string;
  error?: boolean;
  min?: number;
  max?: number;
  required?: boolean;
  type?: ESimulationArrayParamType;
}

export const BaseArrayField: FC<Props> = ({
  value,
  placeholder,
  onChange,
  name,
  paramKey,
  error,
  min,
  max,
  required,
  type,
}): ReactElement => {
  const { t } = useTranslation();
  const isArrayNumber: boolean =
    type === ESimulationArrayParamType.INTEGER ||
    type === ESimulationArrayParamType.NUMBER;
  const isArrayString: boolean = type === ESimulationArrayParamType.STRING;

  const localOnChange = (localValue: string[]) => {
    onChange({
      value: localValue,
      name,
    });
  };

  const onAddHandler = () => {
    const localValue = [...value];

    localValue.push("");

    localOnChange(localValue);
  };

  const onDeleteHandler = (index: number, array: string[]) => {
    const localValue = [...array];

    localValue.splice(index, 1);

    localOnChange(localValue);
  };

  const onChangeHandler = (
    payload: { value: string },
    index: number,
    array: string[]
  ): void => {
    const localValue = [...array];

    localValue[index] = payload.value;

    localOnChange(localValue);
  };

  const addButton = () => (
    <BaseButton
      className={classNames("base-array-field__list-add", {
        "base-array-field__list-add--number-array": isArrayNumber,
      })}
      onClick={onAddHandler}
    >
      <TextWithIcon
        iconName="plus-blue"
        hideLabel={isArrayNumber}
        label={
          isArrayString ? t("usersControl.controlPanel.btn.add") : undefined
        }
        iconSize={isArrayString ? 12 : 16}
      />
    </BaseButton>
  );

  return (
    <div
      className={classNames("base-array-field", {
        "error-field": !!error,
      })}
    >
      {!!placeholder && (
        <h5
          className={classNames("base-array-field__title", {
            required: required,
          })}
        >
          {placeholder}
        </h5>
      )}
      <ul
        className={classNames("base-array-field__list", {
          "base-array-field__list--number-array": isArrayNumber,
        })}
      >
        {value.map((valueItem, index, array) => {
          const localError: boolean =
            !!error &&
            (!valueItem.trim() ||
              (min !== undefined && Number(valueItem) < min) ||
              (max !== undefined && Number(valueItem) > max));
          const isNotFirstItem: boolean = index > 0;

          return (
            <li
              className={classNames("base-array-field__list-item", {
                "base-array-field__list-item--string-array": isArrayString,
              })}
              key={paramKey + index}
            >
              {isArrayNumber && (
                <BaseInputNumber
                  placeholder={`#${index + 1}`}
                  onChange={(payload) => onChangeHandler(payload, index, array)}
                  value={valueItem}
                  error={localError}
                  min={min}
                  max={max}
                />
              )}
              {isArrayString && (
                <BaseInputText
                  placeholder={`#${index + 1}`}
                  onChange={(payload) => onChangeHandler(payload, index, array)}
                  value={valueItem}
                  error={localError}
                  onDelete={
                    isNotFirstItem
                      ? () => onDeleteHandler(index, array)
                      : undefined
                  }
                />
              )}
              {isNotFirstItem && isArrayNumber && (
                <BaseButton
                  className="base-array-field__list-item-btn"
                  onClick={() => onDeleteHandler(index, array)}
                >
                  {t("editFaq.btn.delete")}
                </BaseButton>
              )}
            </li>
          );
        })}
        {isArrayNumber && <li>{addButton()}</li>}
      </ul>
      {isArrayString && addButton()}
    </div>
  );
};
