import { FC, Fragment, ReactElement, useEffect, useState } from "react";
import { formatPhone } from "../../../helpers/phone";

interface Props {
  phone: string;
}

export const DataGridPhone: FC<Props> = ({ phone }): ReactElement => {
  const [formattedPhone, setFormattedPhone] = useState<string>(phone);

  const format = async (val: string) => {
    const newPhone = (await formatPhone(val)) || val;

    setFormattedPhone(newPhone);
  };

  useEffect(() => {
    format(phone);

    return () => setFormattedPhone(phone);
  }, [phone]);

  return <Fragment>{formattedPhone}</Fragment>;
};
