import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  AssessmentType,
  PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest,
  SimulationType,
} from "../../../generated/game";

import "./session-history-player.scss";

import { TDataGridDataParams } from "../../models";

import { getDateForFilter } from "../../helpers";

import {
  cleanSessionsHistory,
  requestSessionsHistoryRoles,
  setSessionsHistoryIsReadmore,
} from "../../store/ducks/sessions-history/actionCreators";

import { selectSessionHistoryRoles } from "../../store/ducks/sessions-history/selectors";

import { SessionsHistory } from "../data-grids/histories/sessions-history";

import { BaseBox } from "../../../shared/components";
import classNames from "classnames";
import { TutorialHistory } from "../data-grids/histories/tutorial-history";
import { useUnit } from "effector-react";
import {
  $complaintsItems,
  $complaintsPagination,
  $fineItems,
  $finePagination,
  $isLoading,
  $sessionItems,
  $sessionsPagination,
  $tutorialItems,
  $tutorialPagination,
  requestComplaintsHistory,
  requestFinesHistory,
  requestSessionHistory,
  requestTutorialHistory,
} from "./lib/player-history";
import { AssessmentApiGetPAssessmentsRequest } from "../../../generated/statistic";
import { FinesHistory } from "../data-grids/histories/fines-history";
import {
  PlayerFineHistoryApiGetPlayerFineHistoryRequest,
  PlayersApiGetPlayerComplaintsRequest,
} from "../../../generated/social";
import { showDataGrid } from "../../helpers/showDataGrid";
import { SessionHistoryPlayerSkeleton } from "./session-history-player-skeleton";
import {
  renderAllCompetencyParams,
  renderNullCompetence,
  updateAdditionalCompetence,
} from "../../helpers/renderAdditionalParams";
import { UrlState, useUrlState } from "src/shared/hooks/useUrlState";
import { $keycloak } from "../../../entities/public/keycloak/model";
import { ComplaintsHistory } from "../data-grids/complaints/complaints-history";
import i18n from "i18next";

interface Props {}

export enum HistoryTabs {
  SESSIONS = "sessions",
  TUTORIAL = "tutorial",
  FINES = "fines",
  COMPLAINTS = "complaints",
}

export const SessionsHistoryPlayer: FC<Props> = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const [count, setCount] = React.useState<number>(0);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const keycloak = useUnit($keycloak);
  const historyRoles = useSelector(selectSessionHistoryRoles);

  const [selectedTab, setSelectedTab] = useState<HistoryTabs>(
    HistoryTabs.SESSIONS,
  );

  const historyItems = useUnit($sessionItems);

  const tutorialItems = useUnit($tutorialItems);

  const fineItems = useUnit($fineItems);

  const complaintsItems = useUnit($complaintsItems);

  const tutorialPagination = useUnit($tutorialPagination);

  const sessionsPagination = useUnit($sessionsPagination);

  const finePagination = useUnit($finePagination);

  const complaintsPagination = useUnit($complaintsPagination);

  const isLoading = useUnit($isLoading);

  const assessmentTypes = useMemo<AssessmentType[]>(
    () => Object.values(AssessmentType),
    [],
  );

  const simulationTypes = useMemo<SimulationType[]>(
    () => Object.values(SimulationType),
    [],
  );

  useEffect(() => {
    dispatch(
      requestSessionsHistoryRoles({
        params: {
          playerId: String(keycloak?.subject),
        },
      }),
    );
  }, [dispatch, keycloak?.subject]);

  useEffect(() => {
    if (count === 1) return;
    const {
      pageNum,
      pageSize,
      orderBy,
      aTitle,
      gTitle,
      curPlayersFrom,
      curPlayersTo,
      createdFrom,
      createdTo,
      startedFrom,
      startedTo,
      finishedFrom,
      finishedTo,
      communicationDiffFrom,
      communicationDiffTo,
      analyticsDiffFrom,
      analyticsDiffTo,
      commandDiffFrom,
      commandDiffTo,
      placeFrom,
      placeTo,
      assessmentType,
      role,
      date_of_complete_from,
      date_of_complete_to,
      attemptTo,
      attemptFrom,
      simulationType,
      chapterNumber,
      date_of_issue_to,
      date_of_issue_from,
      competence,
      removableRatingFrom,
      removableRatingTo,
      periodFrom,
      periodTo,
      additionalCompetence,
      additionalFrom,
      additionalTo,
      tenacityCompetenceNull,
      assTitle,
      fromDateOfCreate,
      toDateOfCreate,
    } = urlState.query;

    let assessmentTypeValues: AssessmentType[] = assessmentType
      ? (assessmentType.split(",") as AssessmentType[])
      : [];

    assessmentTypeValues = assessmentTypeValues.filter((assessmentTypeValue) =>
      assessmentTypes.includes(assessmentTypeValue),
    );

    let simulationTypesValues: SimulationType[] = simulationType
      ? (simulationType.split(",") as SimulationType[])
      : [];

    simulationTypesValues = simulationTypesValues.filter(
      (simulationTypeValue) => simulationTypes.includes(simulationTypeValue),
    );

    const roleTypeValues: string[] = role ? role.split(",") : [];

    const additionalCompetencies = additionalCompetence?.split(",");

    let orderByAdditional: string =
      updateAdditionalCompetence(orderBy, additionalCompetencies) || orderBy;

    const paramsSessions: AssessmentApiGetPAssessmentsRequest = {
      pageSize: pageSize ? Number(pageSize) : 50,
      playerId: String(keycloak?.subject),
      pageNum: pageNum ? Number(pageNum) : 1,
      orderBy: orderBy ? orderByAdditional : undefined,
      aTitle: aTitle ? [aTitle] : undefined,
      gTitle: gTitle ? [gTitle] : undefined,
      aType: !!assessmentTypeValues.length ? assessmentTypeValues : undefined,
      curPlayersFrom: curPlayersFrom ? Number(curPlayersFrom) : undefined,
      curPlayersTo: curPlayersTo ? Number(curPlayersTo) : undefined,
      createdFrom: getDateForFilter(createdFrom),
      createdTo: getDateForFilter(createdTo),
      startedFrom: getDateForFilter(startedFrom),
      startedTo: getDateForFilter(startedTo),
      finishedFrom: getDateForFilter(finishedFrom),
      finishedTo: getDateForFilter(finishedTo),
      communicationDiffFrom: communicationDiffFrom
        ? Number(communicationDiffFrom)
        : undefined,
      communicationDiffTo: communicationDiffTo
        ? Number(communicationDiffTo)
        : undefined,
      analyticsDiffFrom: analyticsDiffFrom
        ? Number(analyticsDiffFrom)
        : undefined,
      analyticsDiffTo: analyticsDiffTo ? Number(analyticsDiffTo) : undefined,
      commandDiffFrom: commandDiffFrom ? Number(commandDiffFrom) : undefined,
      commandDiffTo: commandDiffTo ? Number(commandDiffTo) : undefined,
      ...renderAllCompetencyParams(
        additionalCompetencies,
        additionalFrom,
        additionalTo,
        additionalCompetence,
      ),
      tenacityCompetenceNullQuery: tenacityCompetenceNull
        ? renderNullCompetence(additionalCompetencies)
        : undefined,
      placeFrom: placeFrom ? Number(placeFrom) : undefined,
      placeTo: placeTo ? Number(placeTo) : undefined,
      role: !!roleTypeValues.length ? roleTypeValues : undefined,
    };

    const paramsTutorial: PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest =
      {
        playerId: String(keycloak?.subject),
        pageSize: pageSize ? Number(pageSize) : 50,
        pageNum: pageNum ? Number(pageNum) : 1,
        orderByQuery: orderBy ? orderBy : undefined,
        attemptTo: attemptTo ? Number(attemptTo) : undefined,
        attemptFrom: attemptFrom ? Number(attemptFrom) : undefined,
        simulationType: !!simulationTypesValues.length
          ? simulationTypesValues
          : undefined,
        chapterNumber: chapterNumber ? chapterNumber : undefined,
        dateOfCompleteFrom: date_of_complete_from
          ? date_of_complete_from
          : undefined,
        dateOfCompleteTo: date_of_complete_to ? date_of_complete_to : undefined,
      };
    const paramsFines: PlayerFineHistoryApiGetPlayerFineHistoryRequest = {
      pId: String(keycloak?.subject),
      pageSize: pageSize ? Number(pageSize) : 50,
      pageNum: pageNum ? Number(pageNum) : 1,
      orderBy: orderBy ? orderByAdditional : undefined,
      dateOfIssueFrom: date_of_issue_from ? date_of_issue_from : undefined,
      dateOfIssueTo: date_of_issue_to ? date_of_issue_to : undefined,
      competence: competence ? competence.split(",") : undefined,
      removableRatingFrom: removableRatingFrom
        ? Number(removableRatingFrom)
        : undefined,
      removableRatingTo: removableRatingTo
        ? Number(removableRatingTo)
        : undefined,
      periodFrom: periodFrom ? Number(periodFrom) : undefined,
      periodTo: periodTo ? Number(periodTo) : undefined,
    };

    const paramsComplaints: PlayersApiGetPlayerComplaintsRequest = {
      pId: String(keycloak?.subject),
      pageSize: pageSize ? Number(pageSize) : 50,
      pageNum: pageNum ? Number(pageNum) : 1,
      locale: i18n.language,
      orderBy: orderBy ? [orderBy] : undefined,
      assTitle: assTitle ? assTitle : undefined,
      toDateOfCreate: toDateOfCreate
        ? getDateForFilter(toDateOfCreate)
        : undefined,
      fromDateOfCreate: fromDateOfCreate
        ? getDateForFilter(fromDateOfCreate)
        : undefined,
    };
    if (selectedTab === HistoryTabs.SESSIONS) {
      requestSessionHistory(paramsSessions);
    }

    if (selectedTab === HistoryTabs.TUTORIAL) {
      requestTutorialHistory(paramsTutorial);
    }

    if (selectedTab === HistoryTabs.FINES) {
      requestFinesHistory(paramsFines);
    }

    if (selectedTab === HistoryTabs.COMPLAINTS) {
      requestComplaintsHistory(paramsComplaints);
    }
    setCount((prev) => prev + 1);
    return () => {
      dispatch(cleanSessionsHistory());
    };
  }, [
    urlState,
    dispatch,
    keycloak?.subject,
    selectedTab,
    assessmentTypes,
    simulationTypes,
  ]);

  useEffect(() => {
    setUrlState((prevState: UrlState) => ({
      ...prevState,
      query: {},
    }));
  }, [selectedTab]);

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setCount(0);
    dispatch(setSessionsHistoryIsReadmore({ value: isReadmoreValue }));

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setCount(0);
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [setUrlState, urlState.query],
  );

  const onChangeTab = (tabName: HistoryTabs) => {
    setSelectedTab(tabName);
    goToPage(1);
    setCount(0);
  };

  return (
    <div className="sessions-history-player">
      <BaseBox noPadding className="sessions-history-player__tabs-wrapper">
        <ul className="sessions-history-player__tabs">
          <li
            className={classNames("sessions-history-player__tabs__item", {
              "sessions-history-player__tabs__item--selected":
                selectedTab === HistoryTabs.SESSIONS,
            })}
            onClick={() => {
              onChangeTab(HistoryTabs.SESSIONS);
            }}
          >
            {t("profile.history.sessions")}
          </li>
          <li
            className={classNames("sessions-history-player__tabs__item", {
              "sessions-history-player__tabs__item--selected":
                selectedTab === HistoryTabs.TUTORIAL,
            })}
            onClick={() => {
              onChangeTab(HistoryTabs.TUTORIAL);
            }}
          >
            {t("profile.history.tutorial")}
          </li>
          <li
            className={classNames("sessions-history-player__tabs__item", {
              "sessions-history-player__tabs__item--selected":
                selectedTab === HistoryTabs.FINES,
            })}
            onClick={() => {
              onChangeTab(HistoryTabs.FINES);
            }}
          >
            {t("profile.history.fines")}
          </li>
          <li
            className={classNames("sessions-history-player__tabs__item", {
              "sessions-history-player__tabs__item--selected":
                selectedTab === HistoryTabs.COMPLAINTS,
            })}
            onClick={() => {
              onChangeTab(HistoryTabs.COMPLAINTS);
            }}
          >
            {t("profile.history.complaints")}
          </li>
        </ul>
      </BaseBox>

      {isLoading && <SessionHistoryPlayerSkeleton />}
      {!isLoading && (
        <div>
          {selectedTab === HistoryTabs.SESSIONS && (
            <SessionsHistory
              historyItems={historyItems}
              autoScrollDisabled={true}
              queryParams={urlState.query}
              onUpdateSortAndFilters={onUpdateQueryParams}
              loading={isLoading}
              roles={historyRoles}
              pagination={sessionsPagination}
              goToPage={(pageNum) => goToPage(pageNum)}
              readmore={(pageNum) => goToPage(pageNum, true)}
            />
          )}
          {selectedTab === HistoryTabs.TUTORIAL && (
            <TutorialHistory
              historyItems={tutorialItems}
              autoScrollDisabled={true}
              queryParams={urlState.query}
              onUpdateSortAndFilters={onUpdateQueryParams}
              loading={isLoading}
              pagination={tutorialPagination}
              goToPage={(pageNum) => goToPage(pageNum)}
              readmore={(pageNum) => goToPage(pageNum, true)}
            />
          )}
          {selectedTab === HistoryTabs.FINES &&
            (showDataGrid(fineItems, urlState) ? (
              <FinesHistory
                historyItems={fineItems}
                autoScrollDisabled={true}
                queryParams={urlState.query}
                onUpdateSortAndFilters={onUpdateQueryParams}
                loading={isLoading}
                pagination={finePagination}
                goToPage={(pageNum) => goToPage(pageNum)}
                readmore={(pageNum) => goToPage(pageNum, true)}
              />
            ) : (
              <span className="sessions-history-player-without-fines">
                {t("fine.youDontHaveFines")}
              </span>
            ))}
          {selectedTab === HistoryTabs.COMPLAINTS &&
            (showDataGrid(complaintsItems, urlState) ? (
              <ComplaintsHistory
                complaintItems={complaintsItems}
                autoScrollDisabled={true}
                queryParams={urlState.query}
                onUpdateSortAndFilters={onUpdateQueryParams}
                loading={isLoading}
                pagination={complaintsPagination}
                goToPage={(pageNum) => goToPage(pageNum)}
                readmore={(pageNum) => goToPage(pageNum, true)}
              />
            ) : (
              <span className="complaints-history-data-grid-wrapper-without-complaints">
                {t("complaints.userDontHaveComplaints")}
              </span>
            ))}
        </div>
      )}
    </div>
  );
};
