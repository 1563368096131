import {
  useNavigate,
  useParams,
  useLocation,
  generatePath,
} from "react-router-dom";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

type DispatchState<TState> = Dispatch<SetStateAction<TState>>;
type RouteObject = Record<string, string>;
type ParamsRouteObject = Record<string, string>;

export type UrlState = {
  params: ParamsRouteObject;
  query: RouteObject;
};

type RouteMatch = {
  params: Record<string, string>;
};

export const encodeValues = <
  T extends Record<string, string | string[] | undefined>,
>(
  obj: T,
) => {
  const data = Object.entries(removeUndefined(obj)).reduce(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.map(encodeURIComponent);
      } else {
        acc[key] = value && encodeURIComponent(value);
      }
      return acc;
    },
    {} as Record<string, string | string[]>,
  );
  return data as T;
};

export const getQueryParamsAsObject = (search: string) => {
  const params: Record<string, string | string[]> = {};

  new URLSearchParams(search).forEach((_, key) => {
    const keyValues = new URLSearchParams(search).getAll(key);

    // default array key
    if (key.endsWith("[]")) {
      const keyName = key.slice(0, -2);
      params[keyName] = keyValues;
      // empty array key
    } else if (key.endsWith("[-]")) {
      const keyName = key.slice(0, -3);
      params[keyName] = [];
      // string key
    } else {
      params[key] = keyValues[0];
    }
  });

  return params;
};

export const removeUndefined = <
  T extends Record<string, string | string[] | undefined>,
>(
  obj: T,
) =>
  Object.keys(obj)
    .filter((key) => obj[key] !== undefined)
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key] as string,
      }),
      {} as Record<string, string | string[]>,
    );

const arrayToQueryParams = (key: string, values: string[]) =>
  values.length === 0
    ? `${key}[-]=`
    : values.map((value) => `${key}[]=${value}`).join("&");

export const objectToQueryParams = (obj: Record<string, string | string[]>) =>
  "?" +
  Object.entries(obj)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) =>
      Array.isArray(value) ? arrayToQueryParams(key, value) : `${key}=${value}`,
    )
    .join("&");

export const useDecodedLocation = () => {
  const { search, ...rest } = useLocation();
  const decodedSearch = useMemo(() => getQueryParamsAsObject(search), [search]);
  return { search: decodedSearch, ...rest };
};

export const decodeValues = (obj: Record<string, string | undefined>) => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      acc[key] = obj[key] && decodeURIComponent(obj[key] as string);
      return acc;
    },
    {} as Record<string, string | undefined>,
  );
};

export const useDecodedRouteMatch = () => {
  const params = useParams();
  const decodedParams = useMemo(
    () => decodeValues(params as Record<string, string>),
    [params],
  );
  return { params: decodedParams } as RouteMatch;
};

export const useUrlState = (
  defaultValues?: UrlState,
): [UrlState, DispatchState<UrlState>] => {
  const navigate = useNavigate();
  const { params } = useDecodedRouteMatch();
  const { search } = useDecodedLocation();
  const { pathname: path } = useLocation();

  const updateUrl = useCallback(
    (dispatch: SetStateAction<UrlState>) => {
      const updatedState =
        typeof dispatch === "function"
          ? dispatch({ params, query: search as RouteObject })
          : dispatch;
      const updatedParams = encodeValues(updatedState.params);
      const updatedQuery = objectToQueryParams(
        encodeValues(updatedState.query),
      );
      navigate(generatePath(path, updatedParams) + updatedQuery);
    },
    [navigate, params, path, search],
  );

  const stateWithDefaults = useMemo(() => {
    return {
      params: Object.assign({}, defaultValues?.params, removeUndefined(params)),
      query: Object.assign({}, defaultValues?.query, removeUndefined(search)),
    };
  }, [defaultValues, params, search]);

  return [stateWithDefaults, updateUrl];
};
