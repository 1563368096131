import { FC, ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUrlState } from "../../../shared/hooks/useUrlState";
import { TDataGridDataParams } from "../../../shared/models";
import { togglePlaceParams } from "../../../shared/helpers";
import isEqual from "lodash/isEqual";
import { useUpdatedQueryParams } from "../../../shared/hooks/useUpdatedQueryParams";
import {
  BaseButton,
  PageWrapper,
  TextWithIcon,
} from "../../../shared/components";
import { useUnit } from "effector-react";
import { RelativeApiGetStudentApplicationRequest } from "../../../generated/social";

import "../moderation-page.scss";
import { StudentsTableDataGrid } from "../../../shared/components/data-grids/moderation/student-table";
import {
  $isStudentRatingsLoading,
  $studentModeration,
  $studentPagination,
  fetchStudentRatings,
} from "../../../entities/hr/moderation/model/student/moderation-student";
import { ERegEx, regExList } from "../../../shared/constants/regExList";

interface Props {}

export const StudentModerationPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const [urlState, setUrlState] = useUrlState();

  const isLoading = useUnit($isStudentRatingsLoading);

  const users = useUnit($studentModeration);

  const pagination = useUnit($studentPagination);

  const { updatedQueryParams } = useUpdatedQueryParams();

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (!isEqual(queryParams, newFilteredParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newFilteredParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstName,
      lastName,
      desiredStudyPlace,
      orderBy,
      pageNum,
      phone,
      pageSize,
    } = urlState.query;

    const params: RelativeApiGetStudentApplicationRequest = {
      firstName: firstName ? [firstName] : undefined,
      lastName: lastName ? [lastName] : undefined,
      phone: phone
        ? [phone.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
        : undefined,
      desiredStudyPlace: desiredStudyPlace
        ? desiredStudyPlace.split(",")
        : undefined,
      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [urlState.query]);

  useEffect(() => {
    if (updatedQueryParams) {
      fetchStudentRatings(pageParams);
    }
  }, [pageParams, updatedQueryParams]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  return (
    <div className="moderation-page">
      <PageWrapper
        isLightBlueBackground
        title={t("title.moderationManagement")}
        isLoadingPanel={isLoading}
        tabsPanelSlot={
          <div className="table__control">
            {filtersLength > 0 && (
              <BaseButton
                className="table__control-btn"
                onClick={clearFilterSettings}
                lightBlue
              >
                <TextWithIcon
                  label={t("table.filters.reset")}
                  iconName={"cross-blue"}
                />
              </BaseButton>
            )}
          </div>
        }
      >
        <div className="moderation-page-data-grid-wrapper">
          <StudentsTableDataGrid
            items={users}
            autoScrollDisabled
            pagination={pagination}
            loading={isLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            queryParams={urlState.query}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum)}
          />
        </div>
      </PageWrapper>
    </div>
  );
};
