import { ChangeEvent, FC, ReactElement } from "react";

import classNames from "classnames";

import { IBaseInputChange } from "src/shared/models/base-input";

import "./base-input-radio.scss";

interface Props {
  value: string;
  activeValue: string;
  name: string;
  label: string;
  onChange: (payload: IBaseInputChange) => void;
  className?: string;
  isDisabled?: boolean;
}

export const BaseInputRadio: FC<Props> = ({
  value,
  activeValue,
  name,
  label,
  onChange,
  className,
  isDisabled = false,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    onChange({ value: target.value, name });
  };

  return (
    <div
      className={classNames("radio-btn", className, {
        "radio-btn--disabled": isDisabled,
      })}
    >
      <input
        className={classNames("radio-btn__input", {
          "radio-btn__input_disabled": isDisabled,
        })}
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={activeValue === value}
        onChange={onChangeHandler}
        disabled={isDisabled}
      />
      <label className="radio-btn__label" htmlFor={value}>
        {label}
      </label>
    </div>
  );
};
