import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "src/shared/components/index";

import { getAvatarUrl, splitStrings } from "src/shared/helpers";

import {
  EDataGridColumnType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { PlayerRelativeInfoRes } from "../../../../generated/social";

import "./profile-relative-table.scss";

interface Props {
  items: PlayerRelativeInfoRes[];
  loading?: boolean;
  unLinkRelative: (id: string) => void;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

type RelativeStatus = "confirmed" | "waiting" | "rejected";

export const ProfileRelativeTable: FC<Props> = ({
  loading,
  items,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  unLinkRelative,
  readmore,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullName",
        showed: true,
        titleUppercase: true,
        title: t("table.header.name"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
      },
      {
        key: "status",
        showed: true,
        titleUppercase: true,
        title: t("table.header.status"),
        type: EDataGridColumnType.STRING,
      },
      {
        key: "unLinkButton",
        showed: true,
        titleUppercase: true,
        title: "",
        type: EDataGridColumnType.BUTTON,
        buttonProps: {
          primary: true,
          children: t("relative.untieRelative"),
          small: true,
          onClick: unLinkRelative,
        },
      },
    ];

    return newColumns;
  }, [t, unLinkRelative]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const fullName = splitStrings([item.firstName, item.lastName]);

      let status: RelativeStatus = t("common.waiting");

      if (item.confirmed === true) {
        status = t("common.confirmed");
      }
      if (item.confirmed === false) {
        status = t("common.rejected");
      }

      return {
        id: item.id,
        selected: false,
        fullName,
        avatarProps: {
          alt: fullName,
          fullName: fullName,
          customPath: getAvatarUrl(item.id),
          userId: item.id,
        },
        status: status,
        unLinkButton: t("relative.untieRelative"),
      };
    });

    setRows(rowsItems);
  }, [items, i18n.language, t]);
  return (
    <div className="profile-relative-table">
      <DataGrid
        rows={rows}
        setRows={setRows}
        columns={columns}
        setColumns={setColumns}
        autoScrollDisabled={autoScrollDisabled}
        onUpdateSortAndFilters={onUpdateSortAndFilters}
        loading={loading}
        titleUppercase
        nowrap
        pagination={pagination}
        goToPage={goToPage}
        readmore={readmore}
      />
    </div>
  );
};
