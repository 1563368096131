export const getSumFractionalNums = (
  nums: number[],
  multiplier: number = 100
): number => {
  const preSum: number = nums.reduce(
    (final, curr) => final + curr * multiplier,
    0
  );

  const sum: number = preSum / multiplier;

  return sum;
};
