import classNames from "classnames";
import { FC, MouseEvent, ReactElement, ReactNode, useState } from "react";

import "./title-with-content.scss";

interface Props {
  title: string;
  type?: "small" | "medium" | "large";
  canToggle?: boolean;
  onQuestion?: () => void;
  itemKey?: string;
  children?: ReactNode;
}

export const TitleWithContent: FC<Props> = ({
  title,
  type = "small",
  canToggle = false,
  children,
  onQuestion,
  itemKey,
}): ReactElement => {
  const [isContentVisible, setIsContentVisible] = useState<boolean>(
    !localStorage.getItem(`title-with-content-${itemKey}`),
  );

  const toggleHandler = () => {
    if (canToggle) {
      setIsContentVisible((prev) => {
        if (!!itemKey) {
          if (!prev) {
            localStorage.removeItem(`title-with-content-${itemKey}`);
          } else {
            localStorage.setItem(`title-with-content-${itemKey}`, "closed");
          }
        }

        return !prev;
      });
    }
  };

  const onQuestionLocal = (e: MouseEvent) => {
    e.stopPropagation();

    if (onQuestion) {
      onQuestion();
    }
  };

  return (
    <div className="title-with-content">
      <h3
        className={classNames("title-with-content__title", {
          "title-with-content__title--small": type === "small",
          "title-with-content__title--medium": type === "medium",
          "title-with-content__title--large": type === "large",
          "title-with-content__title--can-toggle": canToggle,
          "title-with-content__title--can-toggle-visible": isContentVisible,
        })}
        onClick={toggleHandler}
      >
        <div className="title-with-content__title-text">{title}</div>
        {!!onQuestion && (
          <div
            className="title-with-content__title-btn"
            onClick={onQuestionLocal}
          />
        )}
      </h3>
      {isContentVisible && (
        <div className="title-with-content__content">{children}</div>
      )}
    </div>
  );
};
