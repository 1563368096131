import { FC, ReactElement } from "react";

import classNames from "classnames";

import { BannerType } from "src/shared/models/lobby";

interface ISessionBanner {
  type?: BannerType;
  title?: string;
  description?: string;
  className?: string;
}

export const SessionBanner: FC<ISessionBanner> = ({
  type,
  title,
  description,
  className,
}: ISessionBanner): ReactElement => {
  return (
    <div
      className={classNames("session__banner", className, {
        "session__banner--info": type === BannerType.INFO,
      })}
    >
      {!!title && (
        <div
          className={classNames("session__banner-title", {
            "session__banner-title--info": type === BannerType.INFO,
            "session__banner-title--with-desc": description,
          })}
        >
          {title}
        </div>
      )}
      {!!description && (
        <div className="session__banner-description">{description}</div>
      )}
    </div>
  );
};
