import { Action } from "redux";

import { UpdAdmCalcsDto, UpdGameReqDto } from "src/generated/game";

import { SimulationAdminInterface } from "../../types";

export enum SimulationItemAdminActionsType {
  CLEAN_SIMULATION_ITEM_ADMIN = "simulationItemAdmin/CLEAN_SIMULATION_ITEM_ADMIN",
  REQUEST_GET_SIMULATION_ITEM_ADMIN = "simulationItemAdmin/REQUEST_GET_SIMULATION_ITEM_ADMIN",
  REQUEST_PUT_SIMULATION_ITEM_ADMIN = "simulationItemAdmin/REQUEST_PUT_SIMULATION_ITEM_ADMIN",
  REQUEST_PULL_META_ADMIN = "simulationItemAdmin/REQUEST_PULL_META_ADMIN",
  SUCCESS_GET_SIMULATION_ITEM_ADMIN = "simulationItemAdmin/SUCCESS_GET_SIMULATION_ITEM_ADMIN",
  SUCCESS_PUT_SIMULATION_ITEM_ADMIN = "simulationItemAdmin/SUCCESS_PUT_SIMULATION_ITEM_ADMIN",
  SUCCESS_PULL_META_ADMIN = "simulationItemAdmin/SUCCESS_PULL_META_ADMIN",
  FAILURE_GET_SIMULATION_ITEM_ADMIN = "simulationItemAdmin/FAILURE_GET_SIMULATION_ITEM_ADMIN",
  FAILURE_PUT_SIMULATION_ITEM_ADMIN = "simulationItemAdmin/FAILURE_PUT_SIMULATION_ITEM_ADMIN",
  FAILURE_PULL_META_ADMIN = "simulationItemAdmin/FAILURE_PULL_META_ADMIN",
}

export interface CleanSimulationItemAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.CLEAN_SIMULATION_ITEM_ADMIN;
}

export interface RequestGetSimulationItemAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.REQUEST_GET_SIMULATION_ITEM_ADMIN;
  payload: {
    id: string;
    callback?: () => void;
  };
}

export interface RequestPutSimulationItemAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.REQUEST_PUT_SIMULATION_ITEM_ADMIN;
  payload: {
    id: string;
    simulationBody: UpdGameReqDto;
    calcsBody: UpdAdmCalcsDto;
    callback?: () => void;
  };
}

export interface RequestPullMetaAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.REQUEST_PULL_META_ADMIN;
  payload: {
    id: string;
    callback?: () => void;
    errorCallback?: () => void;
  };
}

export interface SuccessGetSimulationItemAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.SUCCESS_GET_SIMULATION_ITEM_ADMIN;
  payload: { game: SimulationAdminInterface };
}

export interface SuccessPutSimulationItemAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.SUCCESS_PUT_SIMULATION_ITEM_ADMIN;
}

export interface SuccessPullMetaAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.SUCCESS_PULL_META_ADMIN;
}

export interface FailureGetSimulationItemAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.FAILURE_GET_SIMULATION_ITEM_ADMIN;
}

export interface FailurePutSimulationItemAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.FAILURE_PUT_SIMULATION_ITEM_ADMIN;
}

export interface FailurePullMetaAdminActionInterface
  extends Action<SimulationItemAdminActionsType> {
  type: SimulationItemAdminActionsType.FAILURE_PULL_META_ADMIN;
}
