import classNames from "classnames";

import { FC, ReactElement, useMemo, useState } from "react";

import {
  AssessmentAvatar,
  BaseBox,
  BaseButton,
  TextWithIcon,
} from "src/shared/components";

import { ITextWithIconProps } from "src/shared/components/text-with-icon/text-with-icon";

import "./base-card.scss";
import { updateAssessment } from "../../../entities/public/assessments/model";

export interface IBaseCardProps {
  title: string;
  subTitle?: string[];
  imgSrc?: string;
  imgBorderColor?: string;
  isAddCard?: boolean;
  isEditable?: boolean;
  membersData?: {
    current: number;
    max: number;
  };
  settingsBtnProps?: ITextWithIconProps;
  to?: string;
  btnLabel: string;
  className?: string;
  isPlanned?: boolean;
  onClickGeneral?: () => void;
  onClickMembers?: () => void;
  onClickSettings?: () => void;
  id?: string;
}

export const BaseCard: FC<IBaseCardProps> = ({
  title,
  subTitle,
  imgSrc,
  id,
  isPlanned,
  imgBorderColor = "#63df9c",
  isAddCard,
  membersData,
  settingsBtnProps,
  to,
  btnLabel,
  className,
  onClickGeneral,
  onClickMembers,
  onClickSettings,
}): ReactElement => {
  const localSubTitle: string | undefined = useMemo(
    () => (subTitle?.length ? subTitle.join(" • ") : undefined),
    [subTitle],
  );
  const [titleValue, setTitleValue] = useState<string>(title);

  const onBlurHandler = (id: string) => {
    if (!title) return;
    if (titleValue === title) return;
    updateAssessment({
      assessmentId: id,
      updateAssessment: { title: titleValue, id },
    });
  };

  return (
    <BaseBox className={classNames("base-card", className)}>
      <div className="base-card__header">
        <h3 className="base-card__title">
          {isPlanned ? (
            <input
              className="base-card__header_input"
              onBlur={() => {
                if (!id) return;
                onBlurHandler(id);
              }}
              value={titleValue}
              onChange={(event) => setTitleValue(event.target.value)}
            />
          ) : (
            title
          )}
        </h3>
        {!!localSubTitle && (
          <div className="base-card__sub-title">{localSubTitle}</div>
        )}
      </div>
      <div className="base-card__img">
        <AssessmentAvatar
          src={imgSrc}
          isAdd={isAddCard}
          color={imgBorderColor}
          activeUsers={membersData?.current}
          allUsers={membersData?.max}
          onClickMembers={onClickMembers}
          onClickCreate={isAddCard ? onClickGeneral : undefined}
        />
      </div>
      <div className="base-card__footer">
        {!!settingsBtnProps && (
          <button
            className="base-card__settings-button"
            onClick={onClickSettings}
          >
            <TextWithIcon {...settingsBtnProps} />
          </button>
        )}

        <BaseButton primary small block onClick={onClickGeneral} to={to}>
          {btnLabel}
        </BaseButton>
      </div>
    </BaseBox>
  );
};
