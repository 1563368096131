import React from "react";
import styles from "./base-switcher.module.scss";
import classNames from "classnames";

export type SwitcherItem = {
  isActive: boolean;
  onClick: () => void;
  title: string;
};

interface IBaseSwitcher {
  items: SwitcherItem[];
  className?: string;
}

export const BaseSwitcher: React.FC<IBaseSwitcher> = ({ items, className }) => {
  return (
    <div className={classNames(styles.switcher, className)}>
      {items.map((item, index) => {
        return (
          <button
            key={index}
            className={classNames(styles.switcher_switch, {
              [styles.switcher_switch__active]: item.isActive,
            })}
            onClick={item.onClick}
          >
            {item.title}
          </button>
        );
      })}
    </div>
  );
};
