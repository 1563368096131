import { AssessmentType } from "src/generated/game";
import { AssessmentApiGetPAssessmentsRequest } from "src/generated/statistic";
import { getDateForFilter } from "src/shared/helpers";
import { UrlState } from "../../../../shared/hooks/useUrlState";
import {
  renderAllCompetencyParams,
  renderNullCompetence,
  updateAdditionalCompetence,
} from "../../../../shared/helpers/renderAdditionalParams";

export const sessionsHistoryQueryHandler = (
  urlState: UrlState,
): AssessmentApiGetPAssessmentsRequest => {
  const {
    pageNum,
    pageSize,
    orderBy,
    title,
    gameTitle,
    curPlayersFrom,
    curPlayersTo,
    createdFrom,
    createdTo,
    startedFrom,
    startedTo,
    finishedFrom,
    finishedTo,
    communicationDiffFrom,
    communicationDiffTo,
    analyticsDiffFrom,
    analyticsDiffTo,
    commandDiffFrom,
    commandDiffTo,
    thinking3dDiffFrom,
    thinking3dDiffTo,
    economicsDiffFrom,
    economicsDiffTo,
    jurisprudenceDiffFrom,
    jurisprudenceDiffTo,
    placeFrom,
    placeTo,
    assessmentType,
    additionalCompetence,
    role,
    tenacityCompetenceNull,
    additionalFrom,
    additionalTo,
  } = urlState.query;

  let assessmentTypeValues: AssessmentType[] = assessmentType
    ? (assessmentType.split(",") as AssessmentType[])
    : [];

  const assessmentTypes = Object.values(AssessmentType);

  assessmentTypeValues = assessmentTypeValues.filter((assessmentTypeValue) =>
    assessmentTypes.includes(assessmentTypeValue),
  );

  const roleTypeValues: string[] = role ? role.split(",") : [];

  const additionalCompetencies = additionalCompetence?.split(",");

  let orderByAdditional: string =
    updateAdditionalCompetence(orderBy, additionalCompetencies) || orderBy;

  return {
    pageSize: pageSize ? Number(pageSize) : 50,
    playerId: urlState.params.id,
    pageNum: pageNum ? Number(pageNum) : 1,
    orderBy: orderBy ? orderByAdditional : undefined,
    aTitle: title ? [title] : undefined,
    gTitle: gameTitle ? [gameTitle] : undefined,
    aType: !!assessmentTypeValues.length ? assessmentTypeValues : undefined,
    curPlayersFrom: curPlayersFrom ? Number(curPlayersFrom) : undefined,
    curPlayersTo: curPlayersTo ? Number(curPlayersTo) : undefined,
    createdFrom: getDateForFilter(createdFrom),
    createdTo: getDateForFilter(createdTo),
    startedFrom: getDateForFilter(startedFrom),
    startedTo: getDateForFilter(startedTo),
    finishedFrom: getDateForFilter(finishedFrom),
    finishedTo: getDateForFilter(finishedTo),
    communicationDiffFrom: communicationDiffFrom
      ? Number(communicationDiffFrom)
      : undefined,
    communicationDiffTo: communicationDiffTo
      ? Number(communicationDiffTo)
      : undefined,
    analyticsDiffFrom: analyticsDiffFrom
      ? Number(analyticsDiffFrom)
      : undefined,
    analyticsDiffTo: analyticsDiffTo ? Number(analyticsDiffTo) : undefined,
    commandDiffFrom: commandDiffFrom ? Number(commandDiffFrom) : undefined,
    commandDiffTo: commandDiffTo ? Number(commandDiffTo) : undefined,
    thinking3dDiffFrom: thinking3dDiffFrom
      ? Number(thinking3dDiffFrom)
      : undefined,
    thinking3dDiffTo: thinking3dDiffTo ? Number(thinking3dDiffTo) : undefined,
    economicsDiffFrom: economicsDiffFrom
      ? Number(economicsDiffFrom)
      : undefined,
    economicsDiffTo: economicsDiffTo ? Number(economicsDiffTo) : undefined,

    jurisprudenceDiffFrom: jurisprudenceDiffFrom
      ? Number(jurisprudenceDiffFrom)
      : undefined,
    jurisprudenceDiffTo: jurisprudenceDiffTo
      ? Number(jurisprudenceDiffTo)
      : undefined,
    ...renderAllCompetencyParams(
      additionalCompetencies,
      additionalFrom,
      additionalTo,
      additionalCompetence,
    ),
    tenacityCompetenceNullQuery: tenacityCompetenceNull
      ? renderNullCompetence(additionalCompetencies)
      : undefined,
    placeFrom: placeFrom ? Number(placeFrom) : undefined,
    placeTo: placeTo ? Number(placeTo) : undefined,
    role: !!roleTypeValues.length ? roleTypeValues : undefined,
  };
};
