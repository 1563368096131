import { FC, memo, ReactElement } from "react";

interface Props {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  toRight?: boolean;
}

const icon: FC<Props> = ({
  className,
  width = 12,
  height = 10,
  color = "currentColor",
  toRight,
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 12 10`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `${toRight ? "scaleX(-1)" : ""}`,
      }}
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M4.71875 2L1.1543 5.57715L4.71875 9.15423"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.1543 5.57715H10.1543"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="11"
            height="10"
            fill="white"
            transform="translate(0.154297)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconArrow = memo<Props>(icon);
