import { Competence } from "../../generated/social";
import i18n from "i18next";
import { ELanguage } from "../store/types";
import { daysForms, selectForm } from "../hooks/useSendingTime";
import { t } from "src/shared/helpers";

export function getFormattedFineText(
  days: Record<string, number> | object,
  rating: Record<string, number>,
  competencies: Competence[],
) {
  let minDay: number = Math.min(...Object.values(days));

  let dateForm: string =
    i18n.language === ELanguage.RU ? selectForm(minDay, daysForms) : "days";

  const fineForm = (
    competencies.length > 1
      ? t("warning.fineWilBeWrittenOff")
      : t("warning.oneFineWilBeWrittenOff")
  ).toLowerCase();

  let finesList: string[] = [];

  const minRating = `-${Math.min(...Object.values(rating))}`;

  const competenceCount = competencies.length;

  competencies.forEach((competence, index) => {
    const translatedCompetence = t(
      `common.competence.declination.${competence}`,
    ).toLowerCase();

    finesList.push(
      `-${rating[competence]} ${t("common.in")} ${translatedCompetence}`,
    );

    if (competenceCount === 2) {
      if (index === competenceCount - 2) {
        finesList.push(t("common.and"));
      }
    }
  });

  const competenceList =
    competenceCount === 3
      ? finesList.slice(0, -1).join(", ") +
        ` ${t("common.and")} ` +
        finesList[finesList.length - 1]
      : finesList.join(" ");

  return {
    minDay,
    competenceList,
    dateForm,
    fineForm,
    minRating,
  };
}
