import { FC, useMemo } from "react";

import { BaseButton } from "src/shared/components";
import { useCheckUserAgent } from "../../../../hooks/useCheckUserAgent";

export const GTDownloadButton: FC = () => {
  const { isIOS, isAndroid, isDesktop } = useCheckUserAgent();

  const url = useMemo(() => {
    if (isAndroid) {
      return "https://play.google.com/store/apps/details?id=com.android.chrome";
    }

    if (isIOS) {
      return "https://apps.apple.com/ru/app/chrome/id535886823";
    }

    return "https://www.google.ru/chrome/";
  }, [isIOS, isAndroid]);

  const title = useMemo(() => {
    if (isAndroid) {
      return "Download fo Android";
    }

    if (isIOS) {
      return "Download fo iOS";
    }

    return "Download";
  }, [isIOS, isAndroid]);

  const target = isDesktop ? "_blank" : undefined;

  return (
    <BaseButton
      large
      primary
      to={url}
      target={target}
      className="gt-popup__button gt-popup__button--download"
    >
      {title}
    </BaseButton>
  );
};
