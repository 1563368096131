import { FC, useCallback, useEffect, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { reflect } from "@effector/reflect";

import { BaseButton, PageWrapper, TextWithIcon } from "src/shared/components";

import { EUserSettingsTablesName } from "src/entities/public/user-settings";

import {
  PlayerRatingsDatagrid,
  playersModel,
} from "src/features/admin/player-ratings";

import { tableSettingsItemsRatings } from "src/features/public/user-settings";

import {
  $copySelectedIds,
  refreshSelectedIds,
  updateSelectedIds,
} from "../../model";

import "./select-players-page.scss";
import TableSettingsDropdown from "../../../../../features/public/user-settings/ui/table-settings-dropdown/table-settings-dropdown";
import { useUrlState } from "src/shared/hooks/useUrlState";
import { useUpdatedQueryParams } from "src/shared/hooks/useUpdatedQueryParams";
import { TDataGridDataParams } from "src/shared/models";
import { togglePlaceParams } from "src/shared/helpers";
import isEqual from "lodash/isEqual";

interface Props {
  isLoading: boolean;
  selectedIds: string[];
}

const View: FC<Props> = ({ isLoading, selectedIds }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [urlState, setUrlState] = useUrlState();

  const { updatedQueryParams } = useUpdatedQueryParams();

  const disabledBtn = selectedIds.length <= 0;

  const onClickBack = () => {
    navigate(`/mailing-lists/newsletter-form?type=${urlState.query.type}`);
    refreshSelectedIds();
  };

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize" && key !== "type",
      ).length,
    [urlState.query],
  );

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1", type: urlState.query.type },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (isEqual(queryParams, newFilteredParams)) {
        return;
      }
      setUrlState((prevState) => ({
        ...prevState,
        query: { ...newFilteredParams },
      }));
    },
    [urlState, setUrlState],
  );

  useEffect(() => {
    if (!updatedQueryParams) {
      return;
    }
    if (updatedQueryParams.mode === "readmore") {
      playersModel.readmorePlayers(updatedQueryParams);
    } else {
      playersModel.fetchPlayers(updatedQueryParams);
    }
  }, [updatedQueryParams]);

  return (
    <PageWrapper
      isLightBlueBackground
      emptyPanel
      controlPanelTitle={t("mailingLists.selectRecipients.selected")}
      controlPanelMaxCount={selectedIds.length}
      title={t("mailingLists.selectRecipients.title")}
      isLoadingPanel={isLoading}
      backButton
      controlPanelSlot={
        <div className="select-players-buttons">
          {filtersLength > 0 && (
            <BaseButton outline small lightBlue onClick={clearFilterSettings}>
              <TextWithIcon
                label={t("mailingLists.selectRecipients.reset")}
                iconName="cross-blue"
              />
            </BaseButton>
          )}
          <BaseButton
            primary
            small
            onClick={onClickBack}
            disabled={disabledBtn}
          >
            {t("mailingLists.selectRecipients.save")}
          </BaseButton>
          <TableSettingsDropdown
            items={tableSettingsItemsRatings}
            tableName={EUserSettingsTablesName.MAILING}
          />
        </div>
      }
    >
      <div className="select-players-page">
        <PlayerRatingsDatagrid
          selectedRowsIds={selectedIds}
          queryParams={urlState.query}
          updateQueryParams={onUpdateQueryParams}
          onUpdateSelectedRows={updateSelectedIds}
        />
      </div>
    </PageWrapper>
  );
};

export default reflect<Props>({
  view: View,
  bind: {
    isLoading: playersModel.$isLoading,
    selectedIds: $copySelectedIds,
  },
});
