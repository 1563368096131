import { sample } from "effector";

import {
  deleteMessages,
  markRead,
  Notification,
} from "src/shared/api/public/sockets/model/notifications";

import { socketsModel } from "src/shared/api/public/sockets";

import {
  $notifications,
  notificationsDomain,
} from "src/entities/public/notifications/model";
import { MsgType } from "src/generated/notification";

// Events

export const markAsRead = notificationsDomain.createEvent();

export const deleteMessage = notificationsDomain.createEvent();

//Effects

export const markAsReadFx = notificationsDomain.createEffect(markRead);

export const delMsgFx = notificationsDomain.createEffect(deleteMessages);

//Stores

export const $isLoadingReadingNotifications =
  notificationsDomain.createStore<boolean>(true);

//Logic

sample({
  clock: markAsRead,
  source: {
    socket: socketsModel.notifications.$socket,
    notifications: $notifications,
  },
  fn: ({ socket, notifications }) => ({
    socket,
    payload: {
      msgIds: notifications
        .filter((mid) => mid.type !== MsgType.AssessmentInvite)
        .map(({ mid }) => mid),
    },
  }),
  target: markAsReadFx,
});

sample({
  clock: deleteMessage,
  source: {
    socket: socketsModel.notifications.$socket,
    notifications: $notifications,
  },
  fn: ({ socket, notifications }) => ({
    socket,
    payload: {
      msgIds: notifications
        .filter((mid) => mid.type === MsgType.AssessmentRevoke)
        .map(({ mid }) => mid),
    },
  }),
  target: delMsgFx,
});

sample({
  clock: markAsReadFx.pending,
  target: $isLoadingReadingNotifications,
});

sample({
  clock: markAsReadFx.done,
  source: $notifications,
  fn: (notifications) =>
    notifications.map((notification): Notification => {
      return notification.isNew
        ? { ...notification, isNew: false }
        : notification;
    }),
  target: $notifications,
});

sample({
  clock: markAsReadFx.done,
  source: $notifications,
  fn: (notifications) => {
    const assessmentRevokeIds = notifications
      .filter(({ type }) => type === MsgType.AssessmentRevoke)
      .map(({ mid }) => mid);

    if (!assessmentRevokeIds.length) return notifications;

    return notifications.filter(
      ({ type, mid }) =>
        type !== MsgType.AssessmentInvite || !assessmentRevokeIds.includes(mid),
    );
  },
  target: $notifications,
});
