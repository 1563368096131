type Payload = Record<string, boolean | null>;

export const getIsAccess = ({
  isFullProfile,
  hasSocial,
  isVerificationSession,
  isSomeCompetenceFirstLvl,
  isPlayerClientId,
}: Payload) => {
  const isSomeNull: boolean =
    isFullProfile === null ||
    (isPlayerClientId
      ? hasSocial === null ||
        isVerificationSession === null ||
        isSomeCompetenceFirstLvl === null
      : false);

  if (isSomeNull) {
    return null;
  }
  /*
          Выдача доступа игроку
        */
  if (isPlayerClientId) {
    return (
      /*
                        Проверка на то, нет ли верификационной сессии
                      */
      !isVerificationSession &&
      /*
                        Проверка на наличие сошила
                      */
      hasSocial &&
      /*
                        Проверка на заполненность профиля, но только
                        с 1го и выше уровня одной из компетенций
                      */
      (isSomeCompetenceFirstLvl ? isFullProfile : true)
    );
  }

  return isFullProfile;
};
