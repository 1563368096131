import { createEvent, sample } from "effector";

import { smsNotificationDomain } from "./sms-notification";

import {
  resetActiveRadioValue,
  resetCopySelectedIds,
  resetSelectedIds,
} from "src/pages/other/select-players-page/model";

// Events

export const resetSmsText = smsNotificationDomain.createEvent();

export const resetNotification = createEvent();

// Logic

sample({
  clock: resetNotification,
  target: [
    resetSmsText,
    resetSelectedIds,
    resetCopySelectedIds,
    resetActiveRadioValue,
  ],
});
