import classNames from "classnames";
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AdmAssessmentDto } from "src/generated/game";
import { BaseTooltip } from "src/shared/components/base-tooltip/base-tooltip";
import "./session-title.scss";

interface Props {
  assessment: AdmAssessmentDto;
}

export const SessionTitle: FC<Props> = ({ assessment }) => {
  const span = useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();

  const isParent = assessment.copy_part_title === "1";

  const isCopy = Number(assessment.copy_part_title) > 1;

  return (
    <>
      <span
        ref={span}
        className={classNames("assessments-table-session-title", {
          "assessments-table-copy-parent": isParent,
        })}
      >
        {assessment.title}
        {isCopy ? `#${Number(assessment.copy_part_title) - 1}` : ""}
      </span>
      {isParent && (
        <BaseTooltip tooltipToggleRef={span} position="bottom-start">
          {t("session.popup.copy.parent")}
        </BaseTooltip>
      )}
    </>
  );
};
