import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUnit, useEvent } from "effector-react";
import classNames from "classnames";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useDelayUnmount } from "src/shared/hooks/useDelayUnmount";
import { useNotifications } from "../../hooks/useNotifications";

import { BaseAvatar, NavList } from "../../components";
import { NotificationButton } from "src/entities/public/notifications/ui/notifications-button";
import { HeaderDropdown } from "../new-header/components/headerDropdown";
import {
  markAsRead,
  NotificationPanel,
} from "../../../features/public/notification";
import { HeaderLinksMenu } from "../new-header/components/headerLinksMenu";
import { EPopupName, popupModel } from "../../../shared/components/base-popup";

import { ELanguage } from "../../store/types";
import { notificationsModel } from "../../../entities/public/notifications";
import { toggleHeader } from "../../../features/public/app-interface";
import { $appInterface } from "../../../features/public/app-interface";

import "./headerMobile.scss";
import { IconButton } from "../icon-button";
import { openPopup } from "../base-popup/model";
import { ReactComponent as MenuIcon } from "../../images/icons/grid-icon.svg";

interface Props {
  storeKeycloak: Keycloak.KeycloakInstance | null;
  name: string;
  avatarUrl: string | undefined;
  logout: () => void;
  switchLanguage: (value: ELanguage) => void;
  links: any[];
  isMenuOpened: boolean;
  isShowedMenu: boolean;
  shouldRender: boolean;
  setIsMenuOpened: Dispatch<SetStateAction<boolean>>;
  isPlayerClientId: boolean;
}

const { $isShowedPanel } = notificationsModel;

const { closePopup } = popupModel;

export const HeaderMobile: FC<Props> = ({
  storeKeycloak,
  name,
  avatarUrl,
  logout,
  switchLanguage,
  links,
  isPlayerClientId,
  isMenuOpened,
  setIsMenuOpened,
  isShowedMenu,
}): ReactElement => {
  const { isLaptop } = useMediaQuery();
  const { t } = useTranslation();
  const headerRef = useRef<HTMLElement | null>(null);
  const { isHeaderShowed } = useUnit($appInterface);
  const setHeaderCoords = useState<number>(0)[1];
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const shouldRender = useDelayUnmount(isDropdownOpened, 200);
  const [isNavigationOpened, setIsNavigationOpened] = useState<boolean>(false);
  const shouldRenderNavigation = useDelayUnmount(isNavigationOpened, 200);
  const isShowedPanel = useUnit($isShowedPanel);
  const { toggleNotificationPanel } = useNotifications();
  const readingNotifications = useEvent(markAsRead);

  const toggleHeaderHandler = useCallback(() => {
    const localCoords: number = window.scrollY;

    setHeaderCoords((prev) => {
      const headerHeight: number = headerRef.current?.clientHeight || 60;
      const isHideCoords: boolean = localCoords - prev > headerHeight;
      const isShowCoords: boolean = prev - localCoords > headerHeight;

      if (isHideCoords) {
        toggleHeader(false);

        return localCoords;
      }

      if (isShowCoords) {
        toggleHeader(true);

        return localCoords;
      }

      return prev;
    });
  }, [setHeaderCoords]);

  const closeOpenMenus = () => {
    setIsDropdownOpened(false);
    setIsNavigationOpened(false);
    toggleNotificationPanel(false);
    closePopup({
      name: EPopupName.LINKS_MENU_POPUP,
    });
  };

  useEffect(() => {
    setHeaderCoords(window.scrollY);
  }, [setHeaderCoords]);

  useEffect(() => {
    if (!isLaptop) {
      document.addEventListener("scroll", toggleHeaderHandler);
    } else {
      toggleHeader(true);
    }

    return () => {
      document.removeEventListener("scroll", toggleHeaderHandler);
    };
  }, [isLaptop, toggleHeaderHandler]);

  useEffect(() => {
    const mainLayout = document.querySelector(".main-layout");

    mainLayout?.addEventListener("mousedown", closeOpenMenus);

    return () => {
      mainLayout?.removeEventListener("mousedown", closeOpenMenus);
    };
  }, []);

  const closeMobileMenuHandler = () => {
    closeOpenMenus();
  };

  const onOpenNotifications = () => {
    readingNotifications();
    setIsNavigationOpened(false);
    setIsDropdownOpened(false);
  };

  const openMobileLinksMenu = () => {
    if (!isLaptop) {
      openPopup({
        name: EPopupName.LINKS_MENU_POPUP,
      });
    }
  };

  return (
    <header
      ref={headerRef}
      className={classNames("header", {
        "header--hide": !isHeaderShowed,
        header__is_dropdown: isDropdownOpened || isNavigationOpened,
      })}
    >
      <div className="header__wrap">
        <div className="header__menu">
          {isShowedMenu && (
            <IconButton
              icon={<MenuIcon />}
              onClick={() => {
                setIsDropdownOpened(false);
                toggleNotificationPanel(false);
                openMobileLinksMenu();

                setIsMenuOpened((prev) => !prev);
              }}
            />
          )}
        </div>
        <Link
          to="/"
          className="header__logo"
          onClick={closeMobileMenuHandler}
          aria-label={t("common.goToHomepage")}
        />
      </div>
      <div className="header__wrap">
        <NotificationButton onOpen={onOpenNotifications} />
        <div
          onClick={() => {
            toggleNotificationPanel(false);
            setIsNavigationOpened(false);
            setIsDropdownOpened((prev) => !prev);
          }}
          className="header__profile"
        >
          {!!storeKeycloak?.subject && (
            <BaseAvatar
              customPath={avatarUrl}
              size={24}
              userId={storeKeycloak.subject}
              alt={t("profile.myAvatar")}
              outlineType
            />
          )}
        </div>
        <button
          className={classNames("header__btn", {
            "header__btn--active-menu": isNavigationOpened,
          })}
          onClick={() => {
            toggleNotificationPanel(false);
            setIsDropdownOpened(false);
            setIsNavigationOpened((prev) => !prev);
          }}
        >
          <div
            className={classNames("header__btn-item", {
              "header__btn-item--active-menu": isNavigationOpened,
            })}
          />
        </button>
      </div>
      <HeaderDropdown
        shouldRender={shouldRender}
        isDropdownOpened={isDropdownOpened}
        setIsDropdownOpened={setIsDropdownOpened}
        storeKeycloak={storeKeycloak}
        name={name}
        avatarUrl={avatarUrl}
        logout={logout}
        switchLanguage={switchLanguage}
        isPlayerClientId={isPlayerClientId}
      />
      {!shouldRenderNavigation ? null : (
        <div
          className={classNames("header__navigation", {
            header__navigation_is_closed: !isNavigationOpened,
          })}
        >
          <NavList
            level={1}
            links={links}
            closeMobileMenuHandler={closeMobileMenuHandler}
          />
        </div>
      )}
      {isShowedPanel && <NotificationPanel />}
      <HeaderLinksMenu
        onClose={closeOpenMenus}
        isLaptop={isLaptop}
        shouldRender={shouldRender}
        isMenuOpened={isMenuOpened}
      />
    </header>
  );
};
