import { FC } from "react";

import { BaseButton, EPopupName, TextWithIcon } from "src/shared/components";

import { useTranslation } from "react-i18next";

import "./fine-for-inaction-popup.scss";
import {
  $activePopups,
  closePopup,
} from "../../../../../shared/components/base-popup/model";
import { useUnit } from "effector-react";
import { FineWarningText } from "../fine-warning-text/fine-warning-text";
import { markAsRead } from "../../../../../features/public/notification";

interface Props {}

export const FineForInactionPopup: FC<Props> = () => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const currentPopup = activePopups.find(
    ({ name }) => name === EPopupName.FINE_FOR_INACTION_POPUP,
  );

  const readingNotifications = useUnit(markAsRead);

  const onClose = () => {
    closePopup({ name: EPopupName.FINE_FOR_INACTION_POPUP });
    readingNotifications();
  };

  return (
    <div className="fine-for-inaction-popup-content">
      {currentPopup?.data && (
        <span className="fine-for-inaction-popup-content__description">
          <FineWarningText
            days={currentPopup.data.periodList}
            rating={currentPopup.data.ratingList}
            competencies={currentPopup.data.competenceList}
            notification={true}
          />
        </span>
      )}
      <TextWithIcon iconSize={124} hideLabel iconName="fine" />
      <BaseButton onClick={onClose} primary>
        {t("popup.welcome.ok")}
      </BaseButton>
    </div>
  );
};
