import {
  CSSProperties,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { getColumnStyles } from "../utils";

import {
  isActiveOrder,
  parseOrderFromQueryString,
  pxToRem,
} from "../../../helpers";

import { useMediaQuery } from "../../../hooks/useMediaQuery";

import {
  Checkbox,
  EDataGridColumnType,
  IDataGridColumn,
  TDataGridDataParams,
} from "../../../models";

import { InputCheckbox } from "../../input-checkbox/input-checkbox";

import { DataGridControlsPanel, DataGridDropdown } from ".";

interface Props {
  column: IDataGridColumn;
  showedDropdown?: string;
  setShowedDropdown: (key?: string) => void;
  changeSelectedAllRows: (toggle: boolean) => void;
  queryParams: TDataGridDataParams;
  nowrap?: boolean;
  uppercase?: boolean;
  allSelectedCheckboxType: Checkbox;
  onUpdateSortAndFilters: (params: TDataGridDataParams) => void;
}

export const DataGridHeadColumn: FC<Props> = ({
  column,
  showedDropdown,
  setShowedDropdown,
  changeSelectedAllRows,
  queryParams,
  onUpdateSortAndFilters,
  nowrap,
  uppercase,
  allSelectedCheckboxType,
}): ReactElement => {
  const { t } = useTranslation();

  const [newQueryParams, setNewQueryParams] = useState<TDataGridDataParams>({});

  const apply = useCallback(() => {
    onUpdateSortAndFilters(newQueryParams);
  }, [newQueryParams, onUpdateSortAndFilters]);

  useEffect(() => {
    setNewQueryParams({ ...queryParams });
  }, [queryParams]);

  const [activeGroupKey, setActiveGroupKey] = useState<string>(
    column?.controlPanel?.activeGroupKey || "",
  );

  const groups = useMemo(
    () => column?.controlPanel?.groups || [],
    [column.controlPanel?.groups],
  );

  const { isMobile, isTablet, isLaptop } = useMediaQuery();

  const activeGroup = useMemo(() => {
    return groups.find((group) => group.key === activeGroupKey);
  }, [groups, activeGroupKey]);

  const hasSort = useMemo(() => !!activeGroup?.sort, [activeGroup]);

  const hasFilters = useMemo(
    () => !!activeGroup?.filters?.length,
    [activeGroup],
  );

  const hasActiveFilter = useMemo(
    () => !!activeGroup?.filters?.some((filter) => !!filter.value),
    [activeGroup],
  );

  const hasActiveSort = useMemo(
    () => !!activeGroup?.sort?.value,
    [activeGroup?.sort?.value],
  );

  const isDescSort = useMemo(
    () => hasActiveSort && !!activeGroup?.sort?.value?.includes("-"),
    [hasActiveSort, activeGroup?.sort?.value],
  );

  const hasActiveSortOrFilter = (): boolean => {
    return hasActiveSort || hasActiveFilter;
  };

  const isAscSort = useMemo(
    () => hasActiveSort && !isDescSort,
    [hasActiveSort, isDescSort],
  );

  const sortPosition = useMemo((): number | undefined => {
    if (
      activeGroup?.sort?.orderKey &&
      queryParams?.[activeGroup.sort.orderKey]
    ) {
      const values = queryParams[activeGroup.sort.orderKey].split(",");

      if (values.length < 2) {
        return;
      }

      return values.findIndex((value) => activeGroup.sort?.value === value) + 1;
    }
  }, [activeGroup, queryParams]);

  const getThClasses = (): string => {
    const classes = ["data-grid__th", "tooltip-wrapper"];

    if (column.textAlign) {
      classes.push(`data-grid__th--text-align_${column.textAlign}`);
    }

    return classNames(classes, {
      "tooltip-wrapper--showed": showedDropdown === column.key,
      "data-grid__th--nowrap": column.nowrap || nowrap,
      "data-grid__th--sticky": !!column.sticky,
      "data-grid__th--uppercase": column.titleUppercase || uppercase,
      "data-grid__th--has-filters": hasFilters,
      "data-grid__th--has-sort": hasSort,
      "data-grid__th--has-active-filter": hasActiveFilter,
      "data-grid__th--has-active-sort": hasActiveSort,
      "data-grid__th--no-padding_right": column.noPaddingRight,
      "data-grid__th--no-padding_left": column.noPaddingLeft,
      "data-grid__th--transparent":
        column.hiddenTitle &&
        column.sticky &&
        column.type === EDataGridColumnType.BUTTON,
    });
  };

  const getThStyles = (): CSSProperties => ({
    minWidth:
      column.minWidth !== undefined ? pxToRem(column.minWidth) : undefined,
    width: column.width !== undefined ? pxToRem(column.width) : undefined,
    ...getColumnStyles(column, isMobile, isTablet, isLaptop),
    zIndex: !!column.sticky ? 4 : 3,
  });

  const toggleSelected = (): void => {
    if (
      allSelectedCheckboxType === Checkbox.EMPTY ||
      allSelectedCheckboxType === Checkbox.RESET
    ) {
      return changeSelectedAllRows(true);
    }

    if (allSelectedCheckboxType === Checkbox.CHOSEN) {
      return changeSelectedAllRows(false);
    }
  };

  const reset = useCallback(() => {
    const params = { ...newQueryParams };

    groups.forEach((group) => {
      group.filters?.forEach((filter) => {
        delete params[filter.key];
      });

      const orderKey = group.sort?.orderKey;

      if (orderKey) {
        const sortValues = group.sort?.items.map(({ value }) => value) || [];

        const newSortValues = parseOrderFromQueryString(
          params[orderKey],
        ).filter((value) => !sortValues.includes(value));

        params[orderKey] = newSortValues.join(",");
      }
    });

    setNewQueryParams(params);

    onUpdateSortAndFilters(params);
  }, [groups, newQueryParams, onUpdateSortAndFilters]);

  useEffect(() => {
    groups.forEach((group) => {
      const activeFilter = group.filters?.find((filter) => {
        if (queryParams?.[filter.key]) {
          setActiveGroupKey(group.key);

          return true;
        }

        return false;
      });

      if (!activeFilter) {
        group.sort?.items.forEach(({ value }) => {
          const orderKey = group.sort?.orderKey || "";

          if (isActiveOrder(value, queryParams?.[orderKey])) {
            setActiveGroupKey(group.key);
          }
        });
      }
    });
  }, [queryParams, groups]);

  return (
    <th
      className={getThClasses()}
      key={column.key}
      data-key={column.key}
      style={getThStyles()}
      title={column.description}
    >
      {column.type === EDataGridColumnType.SELECT_ROW && (
        <InputCheckbox
          value={
            allSelectedCheckboxType === Checkbox.CHOSEN ||
            allSelectedCheckboxType === Checkbox.RESET
          }
          id="all-selected"
          type={allSelectedCheckboxType}
          onChange={toggleSelected}
          title={t("inputs.selectAllRows") + allSelectedCheckboxType}
        />
      )}
      {!!column.controlPanel && (
        <button
          className={classNames(
            "data-grid__th-content",
            "data-grid__th-button",
            column.textAlign
              ? `data-grid__th-content--text-align_${column.textAlign}`
              : null,
            {
              "data-grid__th-button--active":
                showedDropdown === column.key || hasActiveSortOrFilter(),
              "data-grid__th-content--no-padding_left": column.noPaddingLeft,
              "data-grid__th-content--no-padding_right": column.noPaddingRight,
              "data-grid__th-content--no-mobile_filters":
                column.noMobileFilters,
            },
          )}
          onClick={() =>
            setShowedDropdown(
              showedDropdown !== column.key ? column.key : undefined,
            )
          }
        >
          <span className="data-grid__th-button-label">{column.title}</span>

          <span
            className={classNames("data-grid__th-button-arrows", {
              "data-grid__th-button-arrows--asc": isAscSort,
              "data-grid__th-button-arrows--desc": isDescSort,
              "data-grid__th-button-arrows--no-mobile_filters":
                column.noMobileFilters,
            })}
            aria-hidden
          />

          {!!sortPosition && (
            <span className="data-grid__th-button-sort-position">
              {sortPosition}
            </span>
          )}
        </button>
      )}
      {!column.controlPanel && !column.hiddenTitle && (
        <span
          className={classNames("data-grid__th-content", {
            "data-grid__th-content--no-padding_left": column.noPaddingLeft,
            "data-grid__th-content--no-padding_right": column.noPaddingRight,
          })}
        >
          {column.title}
        </span>
      )}
      {!!column.controlPanel && (
        <DataGridDropdown
          title={column.controlPanel.title}
          minWidth={260}
          showed={showedDropdown === column.key}
          position={column.filterPosition}
          withScroll={column.controlPanel.withScroll}
          withoutOffsetX={column.noPaddingLeft || column.noPaddingRight}
        >
          <DataGridControlsPanel
            controlPanel={column.controlPanel}
            activeGroupKey={activeGroupKey}
            setActiveGroupKey={setActiveGroupKey}
            onEscapeKeyUp={() => setShowedDropdown(undefined)}
            queryParams={newQueryParams}
            onUpdateSortAndFilters={setNewQueryParams}
            onSave={apply}
            onReset={reset}
          />
        </DataGridDropdown>
      )}
    </th>
  );
};
