import {
  IRequestAssessmentResults,
  IRequestAssessmentsResults,
} from "src/entities/public/assessments-results";
import { statisticService } from "src/shared/api/base/api-instances";

export const getAssessmentsResults = (
  parameters: IRequestAssessmentsResults,
) => {
  const { params } = parameters;

  return statisticService
    .makeAssessmentsRequest()
    .getAssessments(params)
    .then((res) => res.data);
};

export const getAssessmentResults = (parameters: IRequestAssessmentResults) => {
  const { id } = parameters;

  return statisticService
    .makeAssessmentApiFactory()
    .getAssessment(id)
    .then((res) => res.data);
};
