import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { Pagination } from "../../../../../shared/api/base";

import {
  ChangePlayerHistoryApiGetChangePlayerHistoryRequest,
  UserChangeHistoryDto,
} from "src/generated/social";
import { getRatingsChangeHistory } from "../../../../../shared/api/admin/histories/model/change-history";

// Domain

export const changeRatingHistoryDomain = createDomain("changeRatingHistory");

// Events

export const fetchChangeRatingHistory =
  changeRatingHistoryDomain.createEvent<ChangePlayerHistoryApiGetChangePlayerHistoryRequest>();

// Effects

export const fetchChangeRatingHistoryFx =
  changeRatingHistoryDomain.createEffect(getRatingsChangeHistory);

// Stores

export const $changeRatingHistoryPlayers =
  changeRatingHistoryDomain.createStore<UserChangeHistoryDto[]>([]);

export const $pagination =
  changeRatingHistoryDomain.createStore<Pagination>(null);

export const $queryParams =
  changeRatingHistoryDomain.createStore<ChangePlayerHistoryApiGetChangePlayerHistoryRequest>(
    {},
  );

export const $isLoadingPlayers =
  changeRatingHistoryDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchChangeRatingHistory,
  target: fetchChangeRatingHistoryFx,
});

sample({
  clock: fetchChangeRatingHistory,
  target: $queryParams,
});

sample({
  clock: fetchChangeRatingHistoryFx.pending,
  target: $isLoadingPlayers,
});

spread({
  source: fetchChangeRatingHistoryFx.doneData.map(
    ({ user_changes, pagination }) => ({
      user_changes,
      pagination: pagination || null,
    }),
  ),
  targets: {
    user_changes: $changeRatingHistoryPlayers,
    pagination: $pagination,
  },
});
