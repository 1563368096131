import {
  FC,
  FormEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import { IDataGridColumn, TDataGridDataParams } from "../../../models/dataGrid";

import { DataGridMobileSortColumn } from ".";
import { BaseButton } from "../..";
import { useTranslation } from "react-i18next";

interface Props {
  columns: IDataGridColumn[];
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters: (params: TDataGridDataParams) => void;
}

export const DataGridMobileSort: FC<Props> = ({
  columns,
  onUpdateSortAndFilters,
  queryParams = {},
}): ReactElement => {
  const { t } = useTranslation();

  const [newQueryParams, setNewQueryParams] = useState<TDataGridDataParams>({});

  const apply = useCallback(() => {
    onUpdateSortAndFilters && onUpdateSortAndFilters(newQueryParams);
  }, [newQueryParams, onUpdateSortAndFilters]);

  const onSubmitForm: FormEventHandler = (e) => {
    e.preventDefault();

    apply();
  };

  const hasSortItems = (column: IDataGridColumn) => {
    return column.controlPanel?.groups.some((g) => !!g.sort?.items.length);
  };

  useEffect(() => {
    setNewQueryParams({ ...queryParams });
  }, [queryParams]);

  return (
    <form className="data-grid__mobile-sort" onSubmit={onSubmitForm}>
      {columns.map(
        (column) =>
          hasSortItems(column) && (
            <DataGridMobileSortColumn
              column={column}
              key={column.key}
              queryParams={newQueryParams}
              onUpdateSortAndFilters={setNewQueryParams}
            />
          )
      )}

      <BaseButton submit block white className="data-grid__mobile-sort-apply">
        {t("table.filters.btn.apply")}
      </BaseButton>
    </form>
  );
};
