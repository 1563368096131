import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "../../../../../shared/components/base-popup";
import React, { useCallback, useEffect, useMemo } from "react";
import { BaseButton, BaseLoader } from "src/shared/components";
import styles from "./userRightsPopup.module.scss";
import { useUnit } from "effector-react";
import {
  $isLoadingPermission,
  $permissions,
  postAllUserPermissions,
} from "../../../permissions/model";
import { PermissionDto } from "src/generated/social";
import { resetAssessments } from "../../../permissions/model/reset";
import _ from "lodash";
import { recursiveChangeStatus } from "./helpers/recursiveChangeStatus";
import { useTranslation } from "react-i18next";
import RecursiveRights from "./ui/recursive-rights";

const { $activePopups, closePopup } = popupModel;
export const UserRightsPopup: React.FC = () => {
  const { t } = useTranslation();
  const permissions = useUnit($permissions);
  const isLoading = useUnit($isLoadingPermission);
  const [rights, setRights] = React.useState<PermissionDto[] | null>();
  const activePopups = useUnit($activePopups);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const currentPopup = useMemo(
    () =>
      activePopups.find(({ name }) => name === EPopupName.USER_RIGHTS_POPUP),
    [activePopups],
  );

  const itemId = useMemo(
    //ID ПОЛЬЗОВАТЕЛЯ
    () =>
      currentPopup?.dataId?.find(
        ({ name }) => name === EPopupDataIdName.DEFAULT,
      )?.value,
    [],
  );

  const isChangeable = currentPopup?.someBooleanTrigger;
  const handleClose = (): void => {
    closePopup({ name: EPopupName.USER_RIGHTS_POPUP });
  };

  const flattenArrayWithChildrenObjectsHelper = (member: any): any[] => {
    if (!member.children || !member.children.length) {
      return member;
    }
    return [
      member,
      _.flatMapDeep(member.children, flattenArrayWithChildrenObjectsHelper),
    ];
  };

  const recursiveChangeStatusCallback = useCallback(
    (rightsArr: PermissionDto[], right: PermissionDto) =>
      recursiveChangeStatus(rightsArr, right),
    [],
  );

  const onCheckRadioHandler = (
    rightsArr: PermissionDto[],
    right: PermissionDto,
  ) => {
    if (!rights) return;
    if (!isChangeable) return;
    const newRights = recursiveChangeStatusCallback(rightsArr, right);
    setRights(newRights as PermissionDto[]);
    const comparableFlatten = _.flatMapDeep(
      JSON.parse(JSON.stringify(permissions)),
      flattenArrayWithChildrenObjectsHelper,
    );
    const baseFlatten = _.flatMapDeep(
      JSON.parse(JSON.stringify(newRights)),
      flattenArrayWithChildrenObjectsHelper,
    );
    compareArrays(comparableFlatten, baseFlatten);
  };

  const onCheckRadioHandlerCallback = useCallback(
    (rightsArr: PermissionDto[], right: PermissionDto) =>
      onCheckRadioHandler(rightsArr, right),
    [recursiveChangeStatusCallback, permissions],
  );

  const changeHandler = (right: PermissionDto) =>
    onCheckRadioHandlerCallback(rights as PermissionDto[], right);

  const compareArrays = (
    baseArray: PermissionDto[],
    comparableArray: PermissionDto[],
  ) => {
    for (let index = 0; index < baseArray.length; ++index) {
      if (
        baseArray[index].selected !== undefined &&
        baseArray[index].selected !== comparableArray[index].selected
      ) {
        setIsDisabled(false);
        return;
      }
      if (
        baseArray[index].is_enabled !== undefined &&
        baseArray[index].is_enabled !== comparableArray[index].is_enabled
      ) {
        setIsDisabled(false);
        return;
      }
      setIsDisabled(true);
    }
  };

  const defineDisabled = (): boolean => {
    if (!isChangeable) {
      return false;
    }
    return isDisabled;
  };

  const onButtonHandler = (): void => {
    if (isChangeable) {
      postAllUserPermissions({
        pId: itemId as string,
        body: { permissions: rights as PermissionDto[] },
      });
    }
    handleClose();
  };

  useEffect(() => {
    return () => {
      closePopup({ name: EPopupName.USER_RIGHTS_POPUP });
      resetAssessments();
      setRights(null);
    };
  }, []);

  useEffect(() => {
    setRights(JSON.parse(JSON.stringify(permissions)));
  }, [permissions]);

  return isLoading ? (
    <BaseLoader />
  ) : (
    <div className={styles.user_rights__content}>
      <div className={styles.user_rights__wrapper}>
        {!rights ? null : (
          <RecursiveRights
            rights={rights}
            isChangeable={isChangeable ?? false}
            onChange={changeHandler}
          />
        )}
      </div>
      <BaseButton
        disabled={defineDisabled()}
        primary
        large
        block
        onClick={onButtonHandler}
      >
        {isChangeable
          ? t("permission.main.button.confirm")
          : t("permission.main.button.clear")}
      </BaseButton>
    </div>
  );
};
