import {
  CSSProperties,
  FC,
  Fragment,
  LegacyRef,
  MouseEvent,
  ReactNode,
  TouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useUnit } from "effector-react";

import classNames from "classnames";

import { useLocation } from "react-router-dom";

import { BaseButton, TextWithIcon } from "src/shared/components";

import { pxToRem } from "src/shared/helpers";

import {
  $activePopups,
  $showedPopups,
  closePopup as modelClosePopup,
} from "../../model";

import { EPopupName } from "../../model/types";

import "./base-popup.scss";

interface Props {
  name: EPopupName;
  children: ReactNode;
  title?: string;
  isWithoutPadding?: boolean;
  isWithoutOverflow?: boolean;
  isMobileBottom?: boolean;
  onlyMobile?: boolean;
  isOutsideClick?: boolean;
  escapeClose?: boolean;
  onClose?: () => void;
  withoutScroll?: boolean;
  popupRef?: LegacyRef<HTMLDivElement> | null;
  bgType?: TBasePopupBg;
  isHiddenSwipePanel?: boolean;
  isFullHeight?: boolean;
  isTransparentOverflow?: boolean;
  className?: string;
  closeButton?: boolean;
  isTitleWithoutBottomPadding?: boolean;
}

export type TBasePopupBg = "white" | "black" | "transparent";

export const BasePopup: FC<Props> = ({
  name,
  title,
  isWithoutPadding = false,
  isMobileBottom = true,
  escapeClose = true,
  onlyMobile = false,
  isOutsideClick = true,
  children,
  onClose,
  closeButton = true,
  withoutScroll,
  popupRef,
  bgType = "white",
  isHiddenSwipePanel,
  isFullHeight,
  isTransparentOverflow,
  className,
  isTitleWithoutBottomPadding = false,
}) => {
  const location = useLocation();

  const activePopupsState = useUnit($activePopups);

  const showedPopupsState = useUnit($showedPopups);

  const [startSwipeYPosition, setStartSwipeYPosition] = useState<number>(0);

  const [currentSwipeYPosition, setCurrentSwipeYPosition] = useState<number>(0);

  const [topOffset, setTopOffset] = useState<string>();

  const [isCatch, setIsCatch] = useState<boolean>(false);

  const [currentRoute, setCurrentRoute] = useState<string>();

  const isBlackBg: boolean = bgType === "black";

  const isTransparentBg: boolean = bgType === "transparent";

  const isCurrentPopupActive: boolean = useMemo(
    () => activePopupsState.some((popup) => popup.name === name),
    [activePopupsState, name],
  );

  const isCurrentPopupShowed: boolean = useMemo(
    () => showedPopupsState.includes(name),
    [showedPopupsState, name],
  );

  const onCloseHandler = useCallback(() => {
    const callback = () => onClose?.();

    setCurrentRoute(undefined);

    modelClosePopup({ callback, name });
  }, [onClose, name]);

  const closePopup = (e: TouchEvent | MouseEvent) => {
    const target = e.target as HTMLElement;
    const currentTarget = e.currentTarget as HTMLElement;

    if (target === currentTarget && isOutsideClick) {
      onCloseHandler();
    }
  };

  const onSwipeStart = (e: TouchEvent) => {
    if (!e.changedTouches) {
      return;
    }

    setTopOffset("0.1rem");
    setIsCatch(true);

    if (e.changedTouches.length > 0 && e.changedTouches[0].clientY) {
      setStartSwipeYPosition(e.changedTouches[0].clientY);
    }
  };

  const onSwipeEnd = (e: TouchEvent) => {
    if (!e.changedTouches || !startSwipeYPosition) {
      return;
    }

    const diff = currentSwipeYPosition - startSwipeYPosition;

    setStartSwipeYPosition(0);
    setIsCatch(false);

    if (diff > 50) {
      setTopOffset(undefined);
      onCloseHandler();
    } else {
      setTopOffset("0rem");
    }
  };

  const onSwipeMove = (e: TouchEvent) => {
    if (!e.changedTouches) {
      return;
    }

    if (e.changedTouches.length > 0) {
      if (e.changedTouches[0].clientY) {
        setCurrentSwipeYPosition(Number(e.changedTouches[0].clientY));

        if (currentSwipeYPosition && startSwipeYPosition) {
          if (currentSwipeYPosition - startSwipeYPosition > 0) {
            setTopOffset(pxToRem(currentSwipeYPosition - startSwipeYPosition));
          }
        }
      }
    }
  };

  const contentStyle: CSSProperties = {
    transform: isCurrentPopupShowed ? `translateY(${topOffset})` : undefined,
    transition: isCurrentPopupShowed ? "0s" : undefined,
  };

  const onKeyPressEscape = useCallback(
    (e: KeyboardEvent): void => {
      const isEscapeKey: boolean = e.key === "Escape";

      if (isEscapeKey && escapeClose) {
        onCloseHandler();
      }
    },
    [onCloseHandler],
  );

  // ОТРАБАТЫВАЕТ КРИВО
  // useEffect(() => {
  //   if (!currentRoute && isCurrentPopupActive) {
  //     setCurrentRoute(location.pathname);
  //
  //     return;
  //   }
  //
  //   const isOtherRoute: boolean = currentRoute !== location.pathname;
  //
  //   console.log(currentRoute);
  //   console.log(location.pathname);
  //
  //   if (isCurrentPopupActive && isOtherRoute) {
  //     console.log("route");
  //     onCloseHandler();
  //   }
  // }, [isCurrentPopupActive, currentRoute, location.pathname, onCloseHandler]);

  useEffect(() => {
    if (isCurrentPopupActive) {
      document.addEventListener("keydown", onKeyPressEscape);
    }

    return () => {
      document.removeEventListener("keydown", onKeyPressEscape);
    };
  }, [isCurrentPopupActive, onKeyPressEscape]);

  useEffect(() => {
    return () => onCloseHandler?.();
  }, []);

  return !isCurrentPopupActive ? null : (
    <>
      <div
        className={classNames("base-popup", className, {
          "base-popup--showed": isCurrentPopupShowed,
          "base-popup--mobile-bottom": isMobileBottom,
          "base-popup--only-mobile": onlyMobile,
          "base-popup--transparent-overflow": isTransparentOverflow,
        })}
        onMouseDown={closePopup}
      >
        <div
          className={classNames(
            "base-popup__content",
            `base-popup__content--${bgType}`,
            {
              "base-popup__content--mobile-bottom": isMobileBottom,
              "base-popup__content--showed": isCurrentPopupShowed,
              "base-popup__content--full-height": isFullHeight,
              "base-popup__content--only-mobile": onlyMobile,
            },
          )}
          ref={popupRef}
          style={contentStyle}
        >
          {isMobileBottom && !isHiddenSwipePanel && (
            <div
              className={classNames("base-popup__swipe", {
                "base-popup__swipe--catch": isCatch,
                "base-popup__swipe--only-mobile": onlyMobile,
              })}
              onTouchStart={onSwipeStart}
              onTouchEnd={onSwipeEnd}
              onTouchMove={onSwipeMove}
              onClick={onCloseHandler}
            />
          )}
          {!!title && !isTransparentBg && (
            <h2
              className={classNames("base-popup__title", {
                "base-popup__title--white": isBlackBg,
                "base-popup__title--no-padding": isTitleWithoutBottomPadding,
                "base-popup__title--only-mobile": onlyMobile,
              })}
              onTouchStart={onSwipeStart}
              onTouchEnd={onSwipeEnd}
              onTouchMove={onSwipeMove}
            >
              {title}
              {closeButton && (
                <BaseButton
                  onClick={onCloseHandler}
                  className={classNames("base-popup__close-btn", {
                    "base-popup__close-btn--only-mobile": onlyMobile,
                  })}
                >
                  <TextWithIcon iconName="cross-blue" hideLabel />
                </BaseButton>
              )}
            </h2>
          )}
          <div
            className={classNames("base-popup__children", {
              "base-popup__children--without-padding": isWithoutPadding,
              "base-popup__children--full-padding": !title,
              "base-popup__children--hide-scroll": isBlackBg,
              "base-popup__children--without-scroll": withoutScroll,
              "base-popup__children--only-mobile": onlyMobile,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
