import { createEvent, createStore, sample } from "effector";
import { InvitedPlayers } from "src/features/hr/assessmnet-invite/model";
import { radioVariables } from "src/shared/components/recipients-form/recipients-form";
import { IDataGridRow } from "src/shared/models/dataGrid";
import { $rows } from "src/entities/hr/assessment-invite/model/data-grid";

// Events

export const updateSelectedIds = createEvent<string[]>();

export const updateSelectedPlayers = createEvent<InvitedPlayers[]>();

export const refreshSelectedIds = createEvent();

export const changeIdRadioValues = createEvent<string>();

export const setIsInvitedTab = createEvent<boolean>();

// Stores

export const $isInvitedTab = createStore<boolean>(false);

export const $selectedIds = createStore<string[]>([]);

export const $selectedPlayers = createStore<InvitedPlayers[]>([]);

export const $copySelectedIds = createStore<string[]>([]);

export const $activeRadioValue = createStore<string>(radioVariables[0]);

export const $selectedIdsLength = $selectedIds.map((ids) => ids.length);

// Logic

sample({
  clock: updateSelectedIds,
  source: [$rows, $selectedPlayers],
  fn: ([rows, selectedPlayers], ids) => {
    const result: InvitedPlayers[] = [
      ...(selectedPlayers as InvitedPlayers[]).filter(({ userId }) =>
        ids.includes(userId),
      ),
      ...(rows as IDataGridRow[]).reduce((res, row) => {
        if (
          ids.includes(row.id) &&
          (selectedPlayers as InvitedPlayers[]).every(
            ({ userId }) => userId !== row.id,
          )
        )
          res.push({
            userId: row.id,
            firstname: row.firstName,
            lastname: row.lastName,
            received: false,
            email: row.email,
            locality: row.locality,
            studyWorkPlace: row.stydyWorkPlace,
            phone: row.phone,
          });

        return res;
      }, [] as InvitedPlayers[]),
    ];

    return result;
  },
  target: updateSelectedPlayers,
});

sample({
  clock: updateSelectedPlayers,
  target: $selectedPlayers,
});

sample({
  clock: updateSelectedIds,
  target: $copySelectedIds,
});

sample({
  clock: refreshSelectedIds,
  source: $copySelectedIds,
  target: $selectedIds,
});

sample({
  clock: changeIdRadioValues,
  target: $activeRadioValue,
});

sample({
  clock: setIsInvitedTab,
  target: $isInvitedTab,
});

sample({
  clock: $isInvitedTab,
  fn: () => [],
  target: $copySelectedIds,
});
