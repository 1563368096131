/* tslint:disable */
/* eslint-disable */
/**
 * Alabuga Social API
 * Alabuga Social Service<br><a href=\"../static/alb_svc_social_v1.json\">alb_svc_social_v1.json</a>
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @enum {string}
 */
export enum ActionUserEnum {
  Blocked = "blocked",
  Unblocked = "unblocked",
  Deleted = "deleted",
  Created = "created",
  EsiaVerified = "esiaVerified",
  EsiaUnverified = "esiaUnverified",
}

/**
 *
 * @export
 * @interface AddChangesByAdminPlayerDto
 */
export interface AddChangesByAdminPlayerDto {
  /**
   *
   * @type {Array<string>}
   * @memberof AddChangesByAdminPlayerDto
   */
  users_id: Array<string>;
  /**
   *
   * @type {ActionUserEnum}
   * @memberof AddChangesByAdminPlayerDto
   */
  action: ActionUserEnum;
}
/**
 *
 * @export
 * @interface AddPlayerReq
 */
export interface AddPlayerReq {
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  pid: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  studyWorkPlace?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  desiredStudyPlace?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  desiredSpecialty?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  country?: string;
  /**
   *
   * @type {number}
   * @memberof AddPlayerReq
   */
  region?: number;
  /**
   *
   * @type {number}
   * @memberof AddPlayerReq
   */
  district?: number;
  /**
   *
   * @type {number}
   * @memberof AddPlayerReq
   */
  locality?: number;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  localityOther?: string;
  /**
   *
   * @type {number}
   * @memberof AddPlayerReq
   */
  school?: number;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof AddPlayerReq
   */
  emailVerified?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  utmSource?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  utmMedium?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  utmCampaign?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  utmContent?: string;
  /**
   *
   * @type {string}
   * @memberof AddPlayerReq
   */
  utmTerm?: string;
  /**
   *
   * @type {object}
   * @memberof AddPlayerReq
   */
  raw?: object;
}
/**
 *
 * @export
 * @interface AdmCountries
 */
export interface AdmCountries {
  /**
   *
   * @type {Array<AdmCountry>}
   * @memberof AdmCountries
   */
  countries: Array<AdmCountry>;
  /**
   *
   * @type {Pagination}
   * @memberof AdmCountries
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface AdmCountry
 */
export interface AdmCountry {
  /**
   *
   * @type {string}
   * @memberof AdmCountry
   */
  code: string;
  /**
   *
   * @type {boolean}
   * @memberof AdmCountry
   */
  regEnabled: boolean;
  /**
   *
   * @type {string}
   * @memberof AdmCountry
   */
  phoneMask: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof AdmCountry
   */
  title: LocStrDto;
}
/**
 *
 * @export
 * @interface ArchiveFineDto
 */
export interface ArchiveFineDto {
  /**
   *
   * @type {Array<FineDto>}
   * @memberof ArchiveFineDto
   */
  fines: Array<FineDto>;
}
/**
 *
 * @export
 * @interface AssessmentDownloadHistoryDto
 */
export interface AssessmentDownloadHistoryDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentDownloadHistoryDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDownloadHistoryDto
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDownloadHistoryDto
   */
  download_date: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDownloadHistoryDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDownloadHistoryDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDownloadHistoryDto
   */
  user_role: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDownloadHistoryDto
   */
  assessment_id: string;
}
/**
 *
 * @export
 * @interface AssessmentHistoryCreateDto
 */
export interface AssessmentHistoryCreateDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentHistoryCreateDto
   */
  assessment_id: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentHistoryCreateDto
   */
  role: string;
}
/**
 *
 * @export
 * @interface BlockPlayerReq
 */
export interface BlockPlayerReq {
  /**
   *
   * @type {Array<string>}
   * @memberof BlockPlayerReq
   */
  pids: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof BlockPlayerReq
   */
  is_blocked?: boolean;
}
/**
 *
 * @export
 * @interface BulkUpdFaqReq
 */
export interface BulkUpdFaqReq {
  /**
   *
   * @type {Array<FaqQuestionDto>}
   * @memberof BulkUpdFaqReq
   */
  questions?: Array<FaqQuestionDto>;
}
/**
 *
 * @export
 * @interface CancelConfirmationRelative
 */
export interface CancelConfirmationRelative {
  /**
   *
   * @type {string}
   * @memberof CancelConfirmationRelative
   */
  student_id: string;
  /**
   *
   * @type {string}
   * @memberof CancelConfirmationRelative
   */
  relative_id: string;
}
/**
 *
 * @export
 * @interface CandidateEmployee
 */
export interface CandidateEmployee {
  /**
   *
   * @type {string}
   * @memberof CandidateEmployee
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CandidateEmployee
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CandidateEmployee
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CandidateEmployee
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof CandidateEmployee
   */
  desiredStudyPlace: string;
}
/**
 *
 * @export
 * @interface CandidateStudents
 */
export interface CandidateStudents {
  /**
   *
   * @type {string}
   * @memberof CandidateStudents
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CandidateStudents
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CandidateStudents
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CandidateStudents
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof CandidateStudents
   */
  desiredStudyPlace: string;
}
/**
 *
 * @export
 * @interface CommentRequestDto
 */
export interface CommentRequestDto {
  /**
   *
   * @type {string}
   * @memberof CommentRequestDto
   */
  message: string;
}
/**
 *
 * @export
 * @interface CommentResponseDto
 */
export interface CommentResponseDto {
  /**
   *
   * @type {number}
   * @memberof CommentResponseDto
   */
  comment_id: number;
  /**
   *
   * @type {string}
   * @memberof CommentResponseDto
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof CommentResponseDto
   */
  author_id: string;
  /**
   *
   * @type {string}
   * @memberof CommentResponseDto
   */
  author_first_name: string;
  /**
   *
   * @type {string}
   * @memberof CommentResponseDto
   */
  author_last_name: string;
  /**
   *
   * @type {string}
   * @memberof CommentResponseDto
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CommentResponseDto
   */
  author_avatar_url: string;
  /**
   *
   * @type {string}
   * @memberof CommentResponseDto
   */
  created_at: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum Competence {
  Analytics = "analytics",
  AnalyticsTenacity = "analyticsTenacity",
  Command = "command",
  CommandTenacity = "commandTenacity",
  Communication = "communication",
  CommunicationTenacity = "communicationTenacity",
  Thinking3d = "thinking3d",
  Thinking3dTenacity = "thinking3dTenacity",
  Economics = "economics",
  EconomicsTenacity = "economicsTenacity",
  Jurisprudence = "jurisprudence",
  JurisprudenceTenacity = "jurisprudenceTenacity",
  Microelectronics = "microelectronics",
  MicroelectronicsTenacity = "microelectronicsTenacity",
}

/**
 *
 * @export
 * @interface CompetenceRate
 */
export interface CompetenceRate {
  /**
   *
   * @type {Competence}
   * @memberof CompetenceRate
   */
  competence: Competence;
  /**
   *
   * @type {number}
   * @memberof CompetenceRate
   */
  rate: number;
}
/**
 *
 * @export
 * @interface Complaint
 */
export interface Complaint {
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  appelant_id: string;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  appelant_firstname: string;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  appelant_lastname: string;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  defendant_id: string;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  defendant_firstname: string;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  defendant_lastname: string;
  /**
   *
   * @type {ComplaintType}
   * @memberof Complaint
   */
  type: ComplaintType;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  game_id: string;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  gTitle: string;
  /**
   *
   * @type {number}
   * @memberof Complaint
   */
  msg_id: number;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  msg_text: string;
  /**
   *
   * @type {ComplaintStatus}
   * @memberof Complaint
   */
  status: ComplaintStatus;
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  date_of_create: string;
}
/**
 *
 * @export
 * @interface ComplaintByIdReqBody
 */
export interface ComplaintByIdReqBody {
  /**
   *
   * @type {ComplaintStatus}
   * @memberof ComplaintByIdReqBody
   */
  status: ComplaintStatus;
}
/**
 *
 * @export
 * @interface ComplaintReqBody
 */
export interface ComplaintReqBody {
  /**
   *
   * @type {number}
   * @memberof ComplaintReqBody
   */
  msg_id: number;
  /**
   *
   * @type {string}
   * @memberof ComplaintReqBody
   */
  type: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ComplaintStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ComplaintType {
  Spam = "spam",
  Cheating = "cheating",
  Insult = "insult",
  Other = "other",
}

/**
 *
 * @export
 * @interface Complaints
 */
export interface Complaints {
  /**
   *
   * @type {Array<Complaint>}
   * @memberof Complaints
   */
  complaints: Array<Complaint>;
  /**
   *
   * @type {Pagination}
   * @memberof Complaints
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface ConfirmationRelative
 */
export interface ConfirmationRelative {
  /**
   *
   * @type {string}
   * @memberof ConfirmationRelative
   */
  student_id: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmationRelative
   */
  relative_id: string;
  /**
   *
   * @type {boolean}
   * @memberof ConfirmationRelative
   */
  confirmation: boolean;
}
/**
 *
 * @export
 * @interface ConfirmationRelativeResponse
 */
export interface ConfirmationRelativeResponse {
  /**
   *
   * @type {string}
   * @memberof ConfirmationRelativeResponse
   */
  student_id: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmationRelativeResponse
   */
  relative_id: string;
  /**
   *
   * @type {StatusCompanyEnum}
   * @memberof ConfirmationRelativeResponse
   */
  status_company_relative?: StatusCompanyEnum;
}
/**
 *
 * @export
 * @interface Countries
 */
export interface Countries {
  /**
   *
   * @type {Array<Country>}
   * @memberof Countries
   */
  countries: Array<Country>;
  /**
   *
   * @type {Pagination}
   * @memberof Countries
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface Country
 */
export interface Country {
  /**
   *
   * @type {string}
   * @memberof Country
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof Country
   */
  title: string;
}
/**
 *
 * @export
 * @interface District
 */
export interface District {
  /**
   *
   * @type {number}
   * @memberof District
   */
  did: number;
  /**
   *
   * @type {string}
   * @memberof District
   */
  title: string;
}
/**
 *
 * @export
 * @interface Districts
 */
export interface Districts {
  /**
   *
   * @type {Array<District>}
   * @memberof Districts
   */
  districts: Array<District>;
}
/**
 *
 * @export
 * @interface ErrDto
 */
export interface ErrDto {
  /**
   *
   * @type {string}
   * @memberof ErrDto
   */
  msg?: string;
  /**
   *
   * @type {number}
   * @memberof ErrDto
   */
  code?: number;
}
/**
 *
 * @export
 * @interface ErrResDto
 */
export interface ErrResDto {
  /**
   *
   * @type {ErrDto}
   * @memberof ErrResDto
   */
  err: ErrDto;
}
/**
 *
 * @export
 * @interface FaqQuestionDto
 */
export interface FaqQuestionDto {
  /**
   *
   * @type {LocStrDto}
   * @memberof FaqQuestionDto
   */
  question: LocStrDto;
  /**
   *
   * @type {LocStrDto}
   * @memberof FaqQuestionDto
   */
  answer: LocStrDto;
  /**
   *
   * @type {number}
   * @memberof FaqQuestionDto
   */
  order: number;
  /**
   *
   * @type {string}
   * @memberof FaqQuestionDto
   */
  id?: string;
}
/**
 *
 * @export
 * @interface FaqRes
 */
export interface FaqRes {
  /**
   *
   * @type {Array<FaqQuestionDto>}
   * @memberof FaqRes
   */
  questions?: Array<FaqQuestionDto>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum FilterByEnum {
  LastMGrowth = "last_m_growth",
  Rate05 = "rate_0_5",
  Rate1 = "rate_1",
}

/**
 *
 * @export
 * @interface FineDto
 */
export interface FineDto {
  /**
   *
   * @type {string}
   * @memberof FineDto
   */
  pid: string;
  /**
   *
   * @type {string}
   * @memberof FineDto
   */
  fine_id: string;
  /**
   *
   * @type {Competence}
   * @memberof FineDto
   */
  competence: Competence;
  /**
   *
   * @type {number}
   * @memberof FineDto
   */
  period: number;
  /**
   *
   * @type {number}
   * @memberof FineDto
   */
  removable_rating: number;
  /**
   *
   * @type {string}
   * @memberof FineDto
   */
  firstname_creator?: string;
  /**
   *
   * @type {string}
   * @memberof FineDto
   */
  lastname_creator?: string;
  /**
   *
   * @type {boolean}
   * @memberof FineDto
   */
  archived?: boolean;
  /**
   *
   * @type {string}
   * @memberof FineDto
   */
  date_of_create: string;
}
/**
 *
 * @export
 * @interface FinesDto
 */
export interface FinesDto {
  /**
   *
   * @type {Array<FineDto>}
   * @memberof FinesDto
   */
  fines: Array<FineDto>;
  /**
   *
   * @type {Pagination}
   * @memberof FinesDto
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface FinesReqDto
 */
export interface FinesReqDto {
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof FinesReqDto
   */
  fines?: { [key: string]: object };
}
/**
 *
 * @export
 * @interface GetCandidateEmployeeApplications
 */
export interface GetCandidateEmployeeApplications {
  /**
   *
   * @type {Pagination}
   * @memberof GetCandidateEmployeeApplications
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<CandidateEmployee>}
   * @memberof GetCandidateEmployeeApplications
   */
  candidate_employee: Array<CandidateEmployee>;
}
/**
 *
 * @export
 * @interface GetCandidateStudentApplications
 */
export interface GetCandidateStudentApplications {
  /**
   *
   * @type {Pagination}
   * @memberof GetCandidateStudentApplications
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<CandidateStudents>}
   * @memberof GetCandidateStudentApplications
   */
  candidate_students: Array<CandidateStudents>;
}
/**
 *
 * @export
 * @interface GetDownloadAssessments
 */
export interface GetDownloadAssessments {
  /**
   *
   * @type {Pagination}
   * @memberof GetDownloadAssessments
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<AssessmentDownloadHistoryDto>}
   * @memberof GetDownloadAssessments
   */
  assessments: Array<AssessmentDownloadHistoryDto>;
}
/**
 *
 * @export
 * @interface GetDownloadRating
 */
export interface GetDownloadRating {
  /**
   *
   * @type {Pagination}
   * @memberof GetDownloadRating
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<RatingDownload>}
   * @memberof GetDownloadRating
   */
  players: Array<RatingDownload>;
}
/**
 *
 * @export
 * @interface GetPlayersRates
 */
export interface GetPlayersRates {
  /**
   *
   * @type {Array<string>}
   * @memberof GetPlayersRates
   */
  pids?: Array<string>;
}
/**
 *
 * @export
 * @interface GetPlayersRes
 */
export interface GetPlayersRes {
  /**
   *
   * @type {Pagination}
   * @memberof GetPlayersRes
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<Player>}
   * @memberof GetPlayersRes
   */
  players?: Array<Player>;
}
/**
 *
 * @export
 * @interface GetRatingRes
 */
export interface GetRatingRes {
  /**
   *
   * @type {Pagination}
   * @memberof GetRatingRes
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<Rating>}
   * @memberof GetRatingRes
   */
  ratings: Array<Rating>;
}
/**
 *
 * @export
 * @interface GetRelatives
 */
export interface GetRelatives {
  /**
   *
   * @type {Array<PlayerRelativeInfoRes>}
   * @memberof GetRelatives
   */
  players: Array<PlayerRelativeInfoRes>;
}
/**
 *
 * @export
 * @interface GetRelativesBindTable
 */
export interface GetRelativesBindTable {
  /**
   *
   * @type {Pagination}
   * @memberof GetRelativesBindTable
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<RelativeTableChangeBindDto>}
   * @memberof GetRelativesBindTable
   */
  changes: Array<RelativeTableChangeBindDto>;
}
/**
 *
 * @export
 * @interface GetRelativesTable
 */
export interface GetRelativesTable {
  /**
   *
   * @type {Pagination}
   * @memberof GetRelativesTable
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<RelativeTable>}
   * @memberof GetRelativesTable
   */
  relative: Array<RelativeTable>;
}
/**
 *
 * @export
 * @interface GetUserChangeHistories
 */
export interface GetUserChangeHistories {
  /**
   *
   * @type {Pagination}
   * @memberof GetUserChangeHistories
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<UserChangeHistoryDto>}
   * @memberof GetUserChangeHistories
   */
  user_changes: Array<UserChangeHistoryDto>;
}
/**
 *
 * @export
 * @interface GetUserChangesByAdminPlayerDto
 */
export interface GetUserChangesByAdminPlayerDto {
  /**
   *
   * @type {Pagination}
   * @memberof GetUserChangesByAdminPlayerDto
   */
  pagination?: Pagination;
  /**
   *
   * @type {Array<UserChangesByAdminPlayerDto>}
   * @memberof GetUserChangesByAdminPlayerDto
   */
  user_changes: Array<UserChangesByAdminPlayerDto>;
}
/**
 *
 * @export
 * @interface IdRelativeLinkDto
 */
export interface IdRelativeLinkDto {
  /**
   *
   * @type {string}
   * @memberof IdRelativeLinkDto
   */
  link_id: string;
}
/**
 * message representation
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  msg?: string;
}
/**
 *
 * @export
 * @interface LocStrDto
 */
export interface LocStrDto {
  /**
   *
   * @type {string}
   * @memberof LocStrDto
   */
  en?: string;
  /**
   *
   * @type {string}
   * @memberof LocStrDto
   */
  ru?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum Locale {
  En = "en",
  Ru = "ru",
}

/**
 *
 * @export
 * @interface Localities
 */
export interface Localities {
  /**
   *
   * @type {Array<Locality>}
   * @memberof Localities
   */
  localities: Array<Locality>;
}
/**
 *
 * @export
 * @interface Locality
 */
export interface Locality {
  /**
   *
   * @type {number}
   * @memberof Locality
   */
  lid: number;
  /**
   *
   * @type {string}
   * @memberof Locality
   */
  title: string;
}
/**
 *
 * @export
 * @interface Manager
 */
export interface Manager {
  /**
   *
   * @type {LocStrDto}
   * @memberof Manager
   */
  position: LocStrDto;
  /**
   *
   * @type {string}
   * @memberof Manager
   */
  photo: string;
  /**
   *
   * @type {string}
   * @memberof Manager
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Manager
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof Manager
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof Manager
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ManagersRes
 */
export interface ManagersRes {
  /**
   *
   * @type {Array<Manager>}
   * @memberof ManagersRes
   */
  managers: Array<Manager>;
}
/**
 *
 * @export
 * @interface MapCountries
 */
export interface MapCountries {
  /**
   *
   * @type {Array<MapCountry>}
   * @memberof MapCountries
   */
  countries: Array<MapCountry>;
}
/**
 *
 * @export
 * @interface MapCountry
 */
export interface MapCountry {
  /**
   *
   * @type {string}
   * @memberof MapCountry
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof MapCountry
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof MapCountry
   */
  p_count: number;
  /**
   *
   * @type {number}
   * @memberof MapCountry
   */
  rate_eq_05: number;
  /**
   *
   * @type {number}
   * @memberof MapCountry
   */
  rate_eq_1: number;
  /**
   *
   * @type {number}
   * @memberof MapCountry
   */
  rate_gt_1: number;
}
/**
 *
 * @export
 * @interface MapRegion
 */
export interface MapRegion {
  /**
   *
   * @type {number}
   * @memberof MapRegion
   */
  rid: number;
  /**
   *
   * @type {string}
   * @memberof MapRegion
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof MapRegion
   */
  p_count: number;
  /**
   *
   * @type {number}
   * @memberof MapRegion
   */
  rate_eq_05: number;
  /**
   *
   * @type {number}
   * @memberof MapRegion
   */
  rate_eq_1: number;
  /**
   *
   * @type {number}
   * @memberof MapRegion
   */
  rate_gt_1: number;
}
/**
 *
 * @export
 * @interface MapRegions
 */
export interface MapRegions {
  /**
   *
   * @type {Array<MapRegion>}
   * @memberof MapRegions
   */
  regions: Array<MapRegion>;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalItems: number;
}
/**
 *
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
  /**
   *
   * @type {string}
   * @memberof PermissionDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PermissionDto
   */
  type: string;
  /**
   *
   * @type {Array<PermissionDto>}
   * @memberof PermissionDto
   */
  children: Array<PermissionDto>;
  /**
   *
   * @type {boolean}
   * @memberof PermissionDto
   */
  is_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PermissionDto
   */
  is_default?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PermissionDto
   */
  selected?: boolean;
  /**
   *
   * @type {string}
   * @memberof PermissionDto
   */
  group?: string;
}
/**
 *
 * @export
 * @interface PermissionsDto
 */
export interface PermissionsDto {
  /**
   *
   * @type {Array<PermissionDto>}
   * @memberof PermissionsDto
   */
  permissions: Array<PermissionDto>;
}
/**
 *
 * @export
 * @interface Player
 */
export interface Player {
  /**
   *
   * @type {string}
   * @memberof Player
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof Player
   */
  gRate: number;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  desiredStudyPlace?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  phone?: string;
  /**
   *
   * @type {number}
   * @memberof Player
   */
  gPlace?: number;
  /**
   *
   * @type {number}
   * @memberof Player
   */
  gPlaceTotal?: number;
  /**
   *
   * @type {Array<PlayerCompetenceRate>}
   * @memberof Player
   */
  competenceRates?: Array<PlayerCompetenceRate>;
  /**
   *
   * @type {Array<CommentResponseDto>}
   * @memberof Player
   */
  comments?: Array<CommentResponseDto>;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  lastActivity?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  lastPlay?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  middleName?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  studyWorkPlace?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Player
   */
  desiredSpecialty?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  country?: string;
  /**
   *
   * @type {number}
   * @memberof Player
   */
  region?: number;
  /**
   *
   * @type {number}
   * @memberof Player
   */
  district?: number;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  localityOther?: string;
  /**
   *
   * @type {number}
   * @memberof Player
   */
  school?: number;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  email?: string;
  /**
   *
   * @type {StatusCompanyEnum}
   * @memberof Player
   */
  statusCompany?: StatusCompanyEnum;
  /**
   *
   * @type {StatusEmploymentEnum}
   * @memberof Player
   */
  statusEmployment?: StatusEmploymentEnum;
  /**
   *
   * @type {RejectionReasonEnum}
   * @memberof Player
   */
  rejectionReason?: RejectionReasonEnum;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  confirmationSingle?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  emailVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  esiaVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  esiaVerifiedByHr?: boolean;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  esiaVerifiedByHrDate?: string;
  /**
   *
   * @type {Array<RoleInRelatedProjectEnum>}
   * @memberof Player
   */
  roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>;
  /**
   *
   * @type {string}
   * @memberof Player
   */
  acquisitionChannel?: string;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  is_blocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  is_student?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  is_employee?: boolean;
  /**
   *
   * @type {number}
   * @memberof Player
   */
  secret_code?: number;
  /**
   *
   * @type {boolean}
   * @memberof Player
   */
  is_agreement_confirmed: boolean;
}
/**
 *
 * @export
 * @interface PlayerCompetenceRate
 */
export interface PlayerCompetenceRate {
  /**
   *
   * @type {Competence}
   * @memberof PlayerCompetenceRate
   */
  competence: Competence;
  /**
   *
   * @type {number}
   * @memberof PlayerCompetenceRate
   */
  rate: number;
  /**
   *
   * @type {number}
   * @memberof PlayerCompetenceRate
   */
  diff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerCompetenceRate
   */
  place: number;
}
/**
 *
 * @export
 * @interface PlayerCompetenceRateDiff
 */
export interface PlayerCompetenceRateDiff {
  /**
   *
   * @type {Competence}
   * @memberof PlayerCompetenceRateDiff
   */
  competence: Competence;
  /**
   *
   * @type {number}
   * @memberof PlayerCompetenceRateDiff
   */
  diff?: number;
}
/**
 *
 * @export
 * @interface PlayerComplaint
 */
export interface PlayerComplaint {
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  game_id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  gTitle: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  ass_id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  ass_title: string;
  /**
   *
   * @type {number}
   * @memberof PlayerComplaint
   */
  msg_id: number;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  msg_text: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  date_of_create: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  admin_id?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  admin_firstname?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerComplaint
   */
  admin_lastname?: string;
}
/**
 *
 * @export
 * @interface PlayerComplaints
 */
export interface PlayerComplaints {
  /**
   *
   * @type {Array<PlayerComplaint>}
   * @memberof PlayerComplaints
   */
  complaints: Array<PlayerComplaint>;
  /**
   *
   * @type {Pagination}
   * @memberof PlayerComplaints
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface PlayerFineHistoryDto
 */
export interface PlayerFineHistoryDto {
  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryDto
   */
  player_id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryDto
   */
  creator_id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryDto
   */
  fine_id: string;
  /**
   *
   * @type {Competence}
   * @memberof PlayerFineHistoryDto
   */
  competence: Competence;
  /**
   *
   * @type {number}
   * @memberof PlayerFineHistoryDto
   */
  period: number;
  /**
   *
   * @type {number}
   * @memberof PlayerFineHistoryDto
   */
  removable_rating: number;
  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryDto
   */
  date_of_issue: string;
  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryDto
   */
  firstname_creator?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryDto
   */
  lastname_creator?: string;
}
/**
 *
 * @export
 * @interface PlayerFineStatusDto
 */
export interface PlayerFineStatusDto {
  /**
   *
   * @type {string}
   * @memberof PlayerFineStatusDto
   */
  pid: string;
  /**
   *
   * @type {Array<Competence>}
   * @memberof PlayerFineStatusDto
   */
  competencies: Array<Competence>;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof PlayerFineStatusDto
   */
  period: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof PlayerFineStatusDto
   */
  removable_rating: { [key: string]: number };
}
/**
 *
 * @export
 * @interface PlayerFinesHistoryDtoResponse
 */
export interface PlayerFinesHistoryDtoResponse {
  /**
   *
   * @type {Array<PlayerFineHistoryDto>}
   * @memberof PlayerFinesHistoryDtoResponse
   */
  player_fines_history: Array<PlayerFineHistoryDto>;
  /**
   *
   * @type {Pagination}
   * @memberof PlayerFinesHistoryDtoResponse
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface PlayerInfoForInformationSystem
 */
export interface PlayerInfoForInformationSystem {
  /**
   *
   * @type {string}
   * @memberof PlayerInfoForInformationSystem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerInfoForInformationSystem
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerInfoForInformationSystem
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerInfoForInformationSystem
   */
  middleName?: string;
  /**
   *
   * @type {boolean}
   * @memberof PlayerInfoForInformationSystem
   */
  confirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof PlayerInfoForInformationSystem
   */
  status?: string;
  /**
   *
   * @type {Array<PlayerRelativeInfoRes>}
   * @memberof PlayerInfoForInformationSystem
   */
  children: Array<PlayerRelativeInfoRes>;
}
/**
 *
 * @export
 * @interface PlayerRates
 */
export interface PlayerRates {
  /**
   *
   * @type {string}
   * @memberof PlayerRates
   */
  pid: string;
  /**
   *
   * @type {number}
   * @memberof PlayerRates
   */
  gRate: number;
  /**
   *
   * @type {Array<PlayerCompetenceRate>}
   * @memberof PlayerRates
   */
  competenceRates: Array<PlayerCompetenceRate>;
}
/**
 *
 * @export
 * @interface PlayerRatingRes
 */
export interface PlayerRatingRes {
  /**
   *
   * @type {string}
   * @memberof PlayerRatingRes
   */
  id: string;
  /**
   *
   * @type {Array<PlayerCompetenceRate>}
   * @memberof PlayerRatingRes
   */
  competenceRates: Array<PlayerCompetenceRate>;
  /**
   *
   * @type {number}
   * @memberof PlayerRatingRes
   */
  gPlace?: number;
  /**
   *
   * @type {number}
   * @memberof PlayerRatingRes
   */
  gPlaceTotal?: number;
}
/**
 *
 * @export
 * @interface PlayerRelativeInfoRes
 */
export interface PlayerRelativeInfoRes {
  /**
   *
   * @type {string}
   * @memberof PlayerRelativeInfoRes
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PlayerRelativeInfoRes
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerRelativeInfoRes
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerRelativeInfoRes
   */
  middleName?: string;
  /**
   *
   * @type {boolean}
   * @memberof PlayerRelativeInfoRes
   */
  confirmed?: boolean;
}
/**
 *
 * @export
 * @interface PlayerRoles
 */
export interface PlayerRoles {
  /**
   *
   * @type {Array<string>}
   * @memberof PlayerRoles
   */
  roles: Array<string>;
}
/**
 *
 * @export
 * @interface PlayersBulkDelReq
 */
export interface PlayersBulkDelReq {
  /**
   *
   * @type {Array<string>}
   * @memberof PlayersBulkDelReq
   */
  pids: Array<string>;
}
/**
 *
 * @export
 * @interface PlayersCount
 */
export interface PlayersCount {
  /**
   *
   * @type {number}
   * @memberof PlayersCount
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof PlayersCount
   */
  russian: number;
  /**
   *
   * @type {number}
   * @memberof PlayersCount
   */
  foreign: number;
}
/**
 *
 * @export
 * @interface PlayersCountByRatingDto
 */
export interface PlayersCountByRatingDto {
  /**
   *
   * @type {number}
   * @memberof PlayersCountByRatingDto
   */
  p_count: number;
  /**
   *
   * @type {number}
   * @memberof PlayersCountByRatingDto
   */
  gRate: number;
}
/**
 *
 * @export
 * @interface PlayersCountDto
 */
export interface PlayersCountDto {
  /**
   *
   * @type {PlayersCount}
   * @memberof PlayersCountDto
   */
  players: PlayersCount;
}
/**
 *
 * @export
 * @interface PlayersEmailsReq
 */
export interface PlayersEmailsReq {
  /**
   *
   * @type {Array<string>}
   * @memberof PlayersEmailsReq
   */
  pids: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PlayersEmailsReq
   */
  allEmails?: boolean;
}
/**
 *
 * @export
 * @interface PlayersEmailsRes
 */
export interface PlayersEmailsRes {
  /**
   *
   * @type {Array<string>}
   * @memberof PlayersEmailsRes
   */
  emails: Array<string>;
}
/**
 *
 * @export
 * @interface PlayersGrowth
 */
export interface PlayersGrowth {
  /**
   *
   * @type {PlayersGrowthData}
   * @memberof PlayersGrowth
   */
  players: PlayersGrowthData;
}
/**
 *
 * @export
 * @interface PlayersGrowthData
 */
export interface PlayersGrowthData {
  /**
   *
   * @type {number}
   * @memberof PlayersGrowthData
   */
  growth: number;
  /**
   *
   * @type {number}
   * @memberof PlayersGrowthData
   */
  loss: number;
}
/**
 *
 * @export
 * @interface PlayersLastPlayBulkUpdReq
 */
export interface PlayersLastPlayBulkUpdReq {
  /**
   *
   * @type {Array<string>}
   * @memberof PlayersLastPlayBulkUpdReq
   */
  pids: Array<string>;
}
/**
 *
 * @export
 * @interface PlayersPhonesReq
 */
export interface PlayersPhonesReq {
  /**
   *
   * @type {Array<string>}
   * @memberof PlayersPhonesReq
   */
  pids: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PlayersPhonesReq
   */
  allPhones?: boolean;
}
/**
 *
 * @export
 * @interface PlayersPhonesRes
 */
export interface PlayersPhonesRes {
  /**
   *
   * @type {Array<string>}
   * @memberof PlayersPhonesRes
   */
  phones: Array<string>;
}
/**
 *
 * @export
 * @interface PlayersRates
 */
export interface PlayersRates {
  /**
   *
   * @type {Array<PlayerRates>}
   * @memberof PlayersRates
   */
  rates: Array<PlayerRates>;
}
/**
 *
 * @export
 * @interface Rating
 */
export interface Rating {
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  playerId: string;
  /**
   *
   * @type {number}
   * @memberof Rating
   */
  gRate: number;
  /**
   *
   * @type {Array<CompetenceRate>}
   * @memberof Rating
   */
  competenceRates: Array<CompetenceRate>;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  studyWorkPlace?: string;
  /**
   *
   * @type {number}
   * @memberof Rating
   */
  gPlace?: number;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  desiredStudyPlace?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  desiredSpecialty?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  district?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  school?: string;
  /**
   *
   * @type {StatusCompanyEnum}
   * @memberof Rating
   */
  statusCompany?: StatusCompanyEnum;
  /**
   *
   * @type {StatusEmploymentEnum}
   * @memberof Rating
   */
  statusEmployment?: StatusEmploymentEnum;
  /**
   *
   * @type {RejectionReasonEnum}
   * @memberof Rating
   */
  rejectionReason?: RejectionReasonEnum;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  lastActivity?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  lastPlay?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  acquisitionChannel?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  date_of_getting_analytics_lvl_0_5?: string;
  /**
   *
   * @type {string}
   * @memberof Rating
   */
  date_of_getting_analytics_lvl_1?: string;
  /**
   *
   * @type {Array<RoleInRelatedProjectEnum>}
   * @memberof Rating
   */
  roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>;
  /**
   *
   * @type {boolean}
   * @memberof Rating
   */
  confirmationSingle?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Rating
   */
  esiaVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Rating
   */
  esiaVerifiedByHr?: boolean;
  /**
   *
   * @type {number}
   * @memberof Rating
   */
  secret_code: number;
}
/**
 *
 * @export
 * @interface RatingChartDto
 */
export interface RatingChartDto {
  /**
   *
   * @type {Array<PlayersCountByRatingDto>}
   * @memberof RatingChartDto
   */
  ratings: Array<PlayersCountByRatingDto>;
}
/**
 *
 * @export
 * @interface RatingDownload
 */
export interface RatingDownload {
  /**
   *
   * @type {string}
   * @memberof RatingDownload
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RatingDownload
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof RatingDownload
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof RatingDownload
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof RatingDownload
   */
  download_date: string;
  /**
   *
   * @type {number}
   * @memberof RatingDownload
   */
  user_count: number;
  /**
   *
   * @type {Array<string>}
   * @memberof RatingDownload
   */
  included_fields: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof RatingDownload
   */
  success: boolean;
}
/**
 *
 * @export
 * @interface Region
 */
export interface Region {
  /**
   *
   * @type {number}
   * @memberof Region
   */
  rid: number;
  /**
   *
   * @type {string}
   * @memberof Region
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Region
   */
  code?: string;
}
/**
 *
 * @export
 * @interface Regions
 */
export interface Regions {
  /**
   *
   * @type {Array<Region>}
   * @memberof Regions
   */
  regions: Array<Region>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum RejectionReasonEnum {
  Grade8 = "grade-8",
  Changed = "changed",
  InterestedVo = "interested-vo",
  Employment = "employment",
  ParentsRefusal = "parents_refusal",
  JustPlay = "just-play",
  Reviews = "reviews",
  TooFar = "too-far",
  Grade7OrLower = "grade-7-or-lower",
  TeacherForced = "teacher-forced",
  Other = "other",
  NoSelected = "no-selected",
}

/**
 *
 * @export
 * @interface RejectionReasonsDto
 */
export interface RejectionReasonsDto {
  /**
   *
   * @type {Array<RejectionReasonEnum>}
   * @memberof RejectionReasonsDto
   */
  rejectionReasons: Array<RejectionReasonEnum>;
}
/**
 *
 * @export
 * @interface RelativeLinkResponse
 */
export interface RelativeLinkResponse {
  /**
   *
   * @type {string}
   * @memberof RelativeLinkResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeLinkResponse
   */
  player_id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeLinkResponse
   */
  date_create: string;
  /**
   *
   * @type {number}
   * @memberof RelativeLinkResponse
   */
  count_create: number;
}
/**
 *
 * @export
 * @interface RelativeTable
 */
export interface RelativeTable {
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  firstNameStudent: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  lastNameStudent: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  phoneStudent: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  firstNameRelative: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  lastNameRelative: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  phoneRelative: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  relative_id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTable
   */
  student_id: string;
  /**
   *
   * @type {boolean}
   * @memberof RelativeTable
   */
  confirmed?: boolean;
}
/**
 *
 * @export
 * @interface RelativeTableChangeBindDto
 */
export interface RelativeTableChangeBindDto {
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  student_id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  author_id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  relative_id: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  date_of_changes: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  change_field: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  old_value: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  new_value: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  firstNameAuthor: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  lastNameAuthor: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  firstNameStudent: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  lastNameStudent: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  firstNameRelative: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  lastNameRelative: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  phoneAuthor: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  phoneStudent: string;
  /**
   *
   * @type {string}
   * @memberof RelativeTableChangeBindDto
   */
  phoneRelative: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum RoleInRelatedProjectEnum {
  PersonnelHr = "personnel-hr",
  PersonnelAdmin = "personnel-admin",
  Teacher = "teacher",
  AdministratorSchedule = "administrator-schedule",
  AdministratorForm = "administrator-form",
  NoSelected = "no-selected",
}

/**
 *
 * @export
 * @interface RolesInRelatedProjectsDto
 */
export interface RolesInRelatedProjectsDto {
  /**
   *
   * @type {Array<RoleInRelatedProjectEnum>}
   * @memberof RolesInRelatedProjectsDto
   */
  rolesInRelatedProject: Array<RoleInRelatedProjectEnum>;
}
/**
 *
 * @export
 * @interface School
 */
export interface School {
  /**
   *
   * @type {number}
   * @memberof School
   */
  sid: number;
  /**
   *
   * @type {string}
   * @memberof School
   */
  title: string;
}
/**
 *
 * @export
 * @interface Schools
 */
export interface Schools {
  /**
   *
   * @type {Array<School>}
   * @memberof Schools
   */
  schools: Array<School>;
}
/**
 *
 * @export
 * @interface SidebarRes
 */
export interface SidebarRes {
  /**
   *
   * @type {LocStrDto}
   * @memberof SidebarRes
   */
  content: LocStrDto;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum StatusCompanyEnum {
  Student = "student",
  Employee = "employee",
  Fired = "fired",
  Enrollee = "enrollee",
  CandidateStudent = "candidate-student",
  CandidateEmployee = "candidate-employee",
  CandidateRelatives = "candidate-relatives",
  Relative = "relative",
  NoSelected = "no-selected",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum StatusEmploymentEnum {
  Call = "call",
  PlanToCome = "plan-to-come",
  CurrentIntramuralStage = "current-intramural-stage",
  PassedTheSelection = "passed-the-selection",
  NoSelectionPass = "no-selection-pass",
  SubmittedDocuments = "submitted-documents",
  InTheProcessOfEmployment = "in-the-process-of-employment",
  Employed = "employed",
  DoesNotAnswer = "does-not-answer",
  Thinks = "thinks",
  OnePointNotScored = "one-point-not-scored",
  OnePointScored = "one-point-scored",
  StageArrived = "stage-arrived",
  Rejection = "rejection",
  Other = "other",
  NoSelected = "no-selected",
}

/**
 *
 * @export
 * @interface StatusesCompanyDto
 */
export interface StatusesCompanyDto {
  /**
   *
   * @type {Array<StatusCompanyEnum>}
   * @memberof StatusesCompanyDto
   */
  statusesCompany: Array<StatusCompanyEnum>;
}
/**
 *
 * @export
 * @interface StatusesEmploymentDto
 */
export interface StatusesEmploymentDto {
  /**
   *
   * @type {Array<StatusEmploymentEnum>}
   * @memberof StatusesEmploymentDto
   */
  statusesEmployment: Array<StatusEmploymentEnum>;
}
/**
 *
 * @export
 * @interface UpdPlayerAdminByidReqDto
 */
export interface UpdPlayerAdminByidReqDto {
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  birthdate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  studyWorkPlace?: string;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  desiredStudyPlace?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdPlayerAdminByidReqDto
   */
  desiredSpecialty?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  country?: string;
  /**
   *
   * @type {number}
   * @memberof UpdPlayerAdminByidReqDto
   */
  region?: number;
  /**
   *
   * @type {number}
   * @memberof UpdPlayerAdminByidReqDto
   */
  district?: number;
  /**
   *
   * @type {number}
   * @memberof UpdPlayerAdminByidReqDto
   */
  locality?: number;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  localityOther?: string;
  /**
   *
   * @type {number}
   * @memberof UpdPlayerAdminByidReqDto
   */
  school?: number;
  /**
   *
   * @type {string}
   * @memberof UpdPlayerAdminByidReqDto
   */
  email?: string;
  /**
   *
   * @type {StatusCompanyEnum}
   * @memberof UpdPlayerAdminByidReqDto
   */
  statusCompany?: StatusCompanyEnum;
  /**
   *
   * @type {StatusEmploymentEnum}
   * @memberof UpdPlayerAdminByidReqDto
   */
  statusEmployment?: StatusEmploymentEnum;
  /**
   *
   * @type {RejectionReasonEnum}
   * @memberof UpdPlayerAdminByidReqDto
   */
  rejectionReason?: RejectionReasonEnum;
  /**
   *
   * @type {Array<RoleInRelatedProjectEnum>}
   * @memberof UpdPlayerAdminByidReqDto
   */
  rolesInRelatedProjects?: Array<RoleInRelatedProjectEnum>;
  /**
   *
   * @type {boolean}
   * @memberof UpdPlayerAdminByidReqDto
   */
  confirmationSingle?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdPlayerAdminByidReqDto
   */
  esiaVerifiedByHr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdPlayerAdminByidReqDto
   */
  is_agreement_confirmed?: boolean;
}
/**
 *
 * @export
 * @interface UpdPlayerRatingReq
 */
export interface UpdPlayerRatingReq {
  /**
   *
   * @type {Array<PlayerCompetenceRateDiff>}
   * @memberof UpdPlayerRatingReq
   */
  diffs?: Array<PlayerCompetenceRateDiff>;
  /**
   *
   * @type {Array<PlayerCompetenceRateDiff>}
   * @memberof UpdPlayerRatingReq
   */
  force?: Array<PlayerCompetenceRateDiff>;
}
/**
 *
 * @export
 * @interface UpdPlayerRatingRes
 */
export interface UpdPlayerRatingRes {
  /**
   *
   * @type {string}
   * @memberof UpdPlayerRatingRes
   */
  id: string;
  /**
   *
   * @type {Array<PlayerCompetenceRate>}
   * @memberof UpdPlayerRatingRes
   */
  competenceRates: Array<PlayerCompetenceRate>;
  /**
   *
   * @type {number}
   * @memberof UpdPlayerRatingRes
   */
  gRate: number;
  /**
   *
   * @type {number}
   * @memberof UpdPlayerRatingRes
   */
  gPlace?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdPlayerRatingRes
   */
  is_blocked?: boolean;
}
/**
 *
 * @export
 * @interface UpdSidebarReq
 */
export interface UpdSidebarReq {
  /**
   *
   * @type {LocStrDto}
   * @memberof UpdSidebarReq
   */
  content: LocStrDto;
}
/**
 *
 * @export
 * @interface UpdStatusPlayerAdminByidReqDto
 */
export interface UpdStatusPlayerAdminByidReqDto {
  /**
   *
   * @type {StatusCompanyEnum}
   * @memberof UpdStatusPlayerAdminByidReqDto
   */
  statusCompany?: StatusCompanyEnum;
  /**
   *
   * @type {StatusEmploymentEnum}
   * @memberof UpdStatusPlayerAdminByidReqDto
   */
  statusEmployment?: StatusEmploymentEnum;
  /**
   *
   * @type {RejectionReasonEnum}
   * @memberof UpdStatusPlayerAdminByidReqDto
   */
  rejectionReason?: RejectionReasonEnum;
  /**
   *
   * @type {Array<RoleInRelatedProjectEnum>}
   * @memberof UpdStatusPlayerAdminByidReqDto
   */
  rolesInRelatedProjects?: Array<RoleInRelatedProjectEnum>;
  /**
   *
   * @type {boolean}
   * @memberof UpdStatusPlayerAdminByidReqDto
   */
  confirmationSingle?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdStatusPlayerAdminByidReqDto
   */
  esiaVerifiedByHr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdStatusPlayerAdminByidReqDto
   */
  is_agreement_confirmed?: boolean;
}
/**
 *
 * @export
 * @interface UpdatePlayerSelfReqDto
 */
export interface UpdatePlayerSelfReqDto {
  /**
   *
   * @type {StatusCompanyEnum}
   * @memberof UpdatePlayerSelfReqDto
   */
  statusCompany?: StatusCompanyEnum;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePlayerSelfReqDto
   */
  is_student?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePlayerSelfReqDto
   */
  is_employee?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePlayerSelfReqDto
   */
  is_agreement_confirmed?: boolean;
}
/**
 *
 * @export
 * @interface UserChangeHistoryDto
 */
export interface UserChangeHistoryDto {
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  author_id: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  date_of_changes: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  change_field: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  old_value: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  new_value: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  firstNameAuthor: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  lastNameAuthor: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  firstNamePlayer: string;
  /**
   *
   * @type {string}
   * @memberof UserChangeHistoryDto
   */
  lastNamePlayer: string;
}
/**
 *
 * @export
 * @interface UserChangesByAdminPlayerDto
 */
export interface UserChangesByAdminPlayerDto {
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  firstNameAuthor: string;
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  lastNameAuthor: string;
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  firstNameUser: string;
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  lastNameUser: string;
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  author_id: string;
  /**
   *
   * @type {ActionUserEnum}
   * @memberof UserChangesByAdminPlayerDto
   */
  action: ActionUserEnum;
  /**
   *
   * @type {string}
   * @memberof UserChangesByAdminPlayerDto
   */
  action_date: string;
}
/**
 *
 * @export
 * @interface UserEmploymentInfo
 */
export interface UserEmploymentInfo {
  /**
   *
   * @type {string}
   * @memberof UserEmploymentInfo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserEmploymentInfo
   */
  date_of_getting_analytics_lvl_0_5?: string;
  /**
   *
   * @type {string}
   * @memberof UserEmploymentInfo
   */
  date_of_getting_analytics_lvl_1?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserEmploymentInfo
   */
  confirmationSingle?: boolean;
}
/**
 *
 * @export
 * @interface UserHasPermissionDto
 */
export interface UserHasPermissionDto {
  /**
   *
   * @type {boolean}
   * @memberof UserHasPermissionDto
   */
  has_permission: boolean;
}
/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {object}
   * @memberof UserProfile
   */
  payload?: object;
}
/**
 *
 * @export
 * @interface UsersChangesByAdminPlayerDto
 */
export interface UsersChangesByAdminPlayerDto {
  /**
   *
   * @type {Array<UserChangesByAdminPlayerDto>}
   * @memberof UsersChangesByAdminPlayerDto
   */
  changes: Array<UserChangesByAdminPlayerDto>;
}

/**
 * AdminDictionariesCountryApi - axios parameter creator
 * @export
 */
export const AdminDictionariesCountryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete a country by code
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delAdmCountry: async (
      countryCode: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists("delAdmCountry", "countryCode", countryCode);
      const localVarPath = `/api/v1/adm/countries/{code}`.replace(
        `{${"country_code"}}`,
        encodeURIComponent(String(countryCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the countries
     * @param {Array<string>} [countryCode]
     * @param {Array<boolean>} [regEnabled] filter countries by registration enabled
     * @param {Array<string>} [phoneMask] filter countries by phone mask
     * @param {Array<string>} [title]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;code&#x60;/&#x60;regEnabled&#x60;/&#x60;phoneMask&#x60;/ &#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmCountries: async (
      countryCode?: Array<string>,
      regEnabled?: Array<boolean>,
      phoneMask?: Array<string>,
      title?: Array<string>,
      locale?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/adm/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (countryCode) {
        localVarQueryParameter["country_code"] = countryCode;
      }

      if (regEnabled) {
        localVarQueryParameter["regEnabled"] = regEnabled;
      }

      if (phoneMask) {
        localVarQueryParameter["phoneMask"] = phoneMask;
      }

      if (title) {
        localVarQueryParameter["title"] = title;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a country by code
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmCountry: async (
      countryCode: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists("getAdmCountry", "countryCode", countryCode);
      const localVarPath = `/api/v1/adm/countries/{country_code}`.replace(
        `{${"country_code"}}`,
        encodeURIComponent(String(countryCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new country
     * @param {AdmCountry} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdmCountries: async (
      country: AdmCountry,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'country' is not null or undefined
      assertParamExists("postAdmCountries", "country", country);
      const localVarPath = `/api/v1/adm/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        country,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update all the Countries items
     * @param {AdmCountries} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdmCountryBulkUpd: async (
      body: AdmCountries,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postAdmCountryBulkUpd", "body", body);
      const localVarPath = `/api/v1/adm/countries/bulkUpdate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a country by country_code
     * @param {string} countryCode
     * @param {AdmCountry} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAdmCountry: async (
      countryCode: string,
      country: AdmCountry,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists("putAdmCountry", "countryCode", countryCode);
      // verify required parameter 'country' is not null or undefined
      assertParamExists("putAdmCountry", "country", country);
      const localVarPath = `/api/v1/adm/countries/{country_code}`.replace(
        `{${"country_code"}}`,
        encodeURIComponent(String(countryCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        country,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminDictionariesCountryApi - functional programming interface
 * @export
 */
export const AdminDictionariesCountryApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AdminDictionariesCountryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete a country by code
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delAdmCountry(
      countryCode: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCountry>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delAdmCountry(
        countryCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all the countries
     * @param {Array<string>} [countryCode]
     * @param {Array<boolean>} [regEnabled] filter countries by registration enabled
     * @param {Array<string>} [phoneMask] filter countries by phone mask
     * @param {Array<string>} [title]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;code&#x60;/&#x60;regEnabled&#x60;/&#x60;phoneMask&#x60;/ &#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdmCountries(
      countryCode?: Array<string>,
      regEnabled?: Array<boolean>,
      phoneMask?: Array<string>,
      title?: Array<string>,
      locale?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCountries>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdmCountries(
        countryCode,
        regEnabled,
        phoneMask,
        title,
        locale,
        orderBy,
        pageNum,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get a country by code
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAdmCountry(
      countryCode: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCountry>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAdmCountry(
        countryCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create new country
     * @param {AdmCountry} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAdmCountries(
      country: AdmCountry,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCountry>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAdmCountries(country, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update all the Countries items
     * @param {AdmCountries} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAdmCountryBulkUpd(
      body: AdmCountries,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCountries>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAdmCountryBulkUpd(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update a country by country_code
     * @param {string} countryCode
     * @param {AdmCountry} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putAdmCountry(
      countryCode: string,
      country: AdmCountry,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmCountry>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putAdmCountry(
        countryCode,
        country,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminDictionariesCountryApi - factory interface
 * @export
 */
export const AdminDictionariesCountryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminDictionariesCountryApiFp(configuration);
  return {
    /**
     *
     * @summary Delete a country by code
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delAdmCountry(
      countryCode: string,
      options?: any,
    ): AxiosPromise<AdmCountry> {
      return localVarFp
        .delAdmCountry(countryCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all the countries
     * @param {Array<string>} [countryCode]
     * @param {Array<boolean>} [regEnabled] filter countries by registration enabled
     * @param {Array<string>} [phoneMask] filter countries by phone mask
     * @param {Array<string>} [title]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;code&#x60;/&#x60;regEnabled&#x60;/&#x60;phoneMask&#x60;/ &#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmCountries(
      countryCode?: Array<string>,
      regEnabled?: Array<boolean>,
      phoneMask?: Array<string>,
      title?: Array<string>,
      locale?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<AdmCountries> {
      return localVarFp
        .getAdmCountries(
          countryCode,
          regEnabled,
          phoneMask,
          title,
          locale,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a country by code
     * @param {string} countryCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdmCountry(
      countryCode: string,
      options?: any,
    ): AxiosPromise<AdmCountry> {
      return localVarFp
        .getAdmCountry(countryCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create new country
     * @param {AdmCountry} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdmCountries(
      country: AdmCountry,
      options?: any,
    ): AxiosPromise<AdmCountry> {
      return localVarFp
        .postAdmCountries(country, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update all the Countries items
     * @param {AdmCountries} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAdmCountryBulkUpd(
      body: AdmCountries,
      options?: any,
    ): AxiosPromise<AdmCountries> {
      return localVarFp
        .postAdmCountryBulkUpd(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a country by country_code
     * @param {string} countryCode
     * @param {AdmCountry} country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAdmCountry(
      countryCode: string,
      country: AdmCountry,
      options?: any,
    ): AxiosPromise<AdmCountry> {
      return localVarFp
        .putAdmCountry(countryCode, country, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delAdmCountry operation in AdminDictionariesCountryApi.
 * @export
 * @interface AdminDictionariesCountryApiDelAdmCountryRequest
 */
export interface AdminDictionariesCountryApiDelAdmCountryRequest {
  /**
   *
   * @type {string}
   * @memberof AdminDictionariesCountryApiDelAdmCountry
   */
  readonly countryCode: string;
}

/**
 * Request parameters for getAdmCountries operation in AdminDictionariesCountryApi.
 * @export
 * @interface AdminDictionariesCountryApiGetAdmCountriesRequest
 */
export interface AdminDictionariesCountryApiGetAdmCountriesRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly countryCode?: Array<string>;

  /**
   * filter countries by registration enabled
   * @type {Array<boolean>}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly regEnabled?: Array<boolean>;

  /**
   * filter countries by phone mask
   * @type {Array<string>}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly phoneMask?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly title?: Array<string>;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly locale?: string;

  /**
   * sort countries by fields &#x60;code&#x60;/&#x60;regEnabled&#x60;/&#x60;phoneMask&#x60;/ &#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof AdminDictionariesCountryApiGetAdmCountries
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getAdmCountry operation in AdminDictionariesCountryApi.
 * @export
 * @interface AdminDictionariesCountryApiGetAdmCountryRequest
 */
export interface AdminDictionariesCountryApiGetAdmCountryRequest {
  /**
   *
   * @type {string}
   * @memberof AdminDictionariesCountryApiGetAdmCountry
   */
  readonly countryCode: string;
}

/**
 * Request parameters for postAdmCountries operation in AdminDictionariesCountryApi.
 * @export
 * @interface AdminDictionariesCountryApiPostAdmCountriesRequest
 */
export interface AdminDictionariesCountryApiPostAdmCountriesRequest {
  /**
   *
   * @type {AdmCountry}
   * @memberof AdminDictionariesCountryApiPostAdmCountries
   */
  readonly country: AdmCountry;
}

/**
 * Request parameters for postAdmCountryBulkUpd operation in AdminDictionariesCountryApi.
 * @export
 * @interface AdminDictionariesCountryApiPostAdmCountryBulkUpdRequest
 */
export interface AdminDictionariesCountryApiPostAdmCountryBulkUpdRequest {
  /**
   *
   * @type {AdmCountries}
   * @memberof AdminDictionariesCountryApiPostAdmCountryBulkUpd
   */
  readonly body: AdmCountries;
}

/**
 * Request parameters for putAdmCountry operation in AdminDictionariesCountryApi.
 * @export
 * @interface AdminDictionariesCountryApiPutAdmCountryRequest
 */
export interface AdminDictionariesCountryApiPutAdmCountryRequest {
  /**
   *
   * @type {string}
   * @memberof AdminDictionariesCountryApiPutAdmCountry
   */
  readonly countryCode: string;

  /**
   *
   * @type {AdmCountry}
   * @memberof AdminDictionariesCountryApiPutAdmCountry
   */
  readonly country: AdmCountry;
}

/**
 * AdminDictionariesCountryApi - object-oriented interface
 * @export
 * @class AdminDictionariesCountryApi
 * @extends {BaseAPI}
 */
export class AdminDictionariesCountryApi extends BaseAPI {
  /**
   *
   * @summary Delete a country by code
   * @param {AdminDictionariesCountryApiDelAdmCountryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDictionariesCountryApi
   */
  public delAdmCountry(
    requestParameters: AdminDictionariesCountryApiDelAdmCountryRequest,
    options?: any,
  ) {
    return AdminDictionariesCountryApiFp(this.configuration)
      .delAdmCountry(requestParameters.countryCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all the countries
   * @param {AdminDictionariesCountryApiGetAdmCountriesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDictionariesCountryApi
   */
  public getAdmCountries(
    requestParameters: AdminDictionariesCountryApiGetAdmCountriesRequest = {},
    options?: any,
  ) {
    return AdminDictionariesCountryApiFp(this.configuration)
      .getAdmCountries(
        requestParameters.countryCode,
        requestParameters.regEnabled,
        requestParameters.phoneMask,
        requestParameters.title,
        requestParameters.locale,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a country by code
   * @param {AdminDictionariesCountryApiGetAdmCountryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDictionariesCountryApi
   */
  public getAdmCountry(
    requestParameters: AdminDictionariesCountryApiGetAdmCountryRequest,
    options?: any,
  ) {
    return AdminDictionariesCountryApiFp(this.configuration)
      .getAdmCountry(requestParameters.countryCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create new country
   * @param {AdminDictionariesCountryApiPostAdmCountriesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDictionariesCountryApi
   */
  public postAdmCountries(
    requestParameters: AdminDictionariesCountryApiPostAdmCountriesRequest,
    options?: any,
  ) {
    return AdminDictionariesCountryApiFp(this.configuration)
      .postAdmCountries(requestParameters.country, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update all the Countries items
   * @param {AdminDictionariesCountryApiPostAdmCountryBulkUpdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDictionariesCountryApi
   */
  public postAdmCountryBulkUpd(
    requestParameters: AdminDictionariesCountryApiPostAdmCountryBulkUpdRequest,
    options?: any,
  ) {
    return AdminDictionariesCountryApiFp(this.configuration)
      .postAdmCountryBulkUpd(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a country by country_code
   * @param {AdminDictionariesCountryApiPutAdmCountryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDictionariesCountryApi
   */
  public putAdmCountry(
    requestParameters: AdminDictionariesCountryApiPutAdmCountryRequest,
    options?: any,
  ) {
    return AdminDictionariesCountryApiFp(this.configuration)
      .putAdmCountry(
        requestParameters.countryCode,
        requestParameters.country,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AssessmentHistoryApi - axios parameter creator
 * @export
 */
export const AssessmentHistoryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get download assessment history
     * @summary Get download assessment history
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateDownloadFrom] filter players by date download rating
     * @param {string} [dateDownloadTo] filter players by date download rating
     * @param {Array<string>} [role] filter download  by role
     * @param {Array<string>} [assessmentFilterId] filter download result assessment by assessment_id
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_role&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadAssessmentHistory: async (
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateDownloadFrom?: string,
      dateDownloadTo?: string,
      role?: Array<string>,
      assessmentFilterId?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/assessments/download-history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (dateDownloadFrom !== undefined) {
        localVarQueryParameter["dateDownloadFrom"] = dateDownloadFrom;
      }

      if (dateDownloadTo !== undefined) {
        localVarQueryParameter["dateDownloadTo"] = dateDownloadTo;
      }

      if (role) {
        localVarQueryParameter["role"] = role;
      }

      if (assessmentFilterId) {
        localVarQueryParameter["assessment_filter_id"] = assessmentFilterId;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssessmentHistoryApi - functional programming interface
 * @export
 */
export const AssessmentHistoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AssessmentHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     * Get download assessment history
     * @summary Get download assessment history
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateDownloadFrom] filter players by date download rating
     * @param {string} [dateDownloadTo] filter players by date download rating
     * @param {Array<string>} [role] filter download  by role
     * @param {Array<string>} [assessmentFilterId] filter download result assessment by assessment_id
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_role&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadAssessmentHistory(
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateDownloadFrom?: string,
      dateDownloadTo?: string,
      role?: Array<string>,
      assessmentFilterId?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDownloadAssessments>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDownloadAssessmentHistory(
          firstName,
          lastName,
          dateDownloadFrom,
          dateDownloadTo,
          role,
          assessmentFilterId,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AssessmentHistoryApi - factory interface
 * @export
 */
export const AssessmentHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssessmentHistoryApiFp(configuration);
  return {
    /**
     * Get download assessment history
     * @summary Get download assessment history
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateDownloadFrom] filter players by date download rating
     * @param {string} [dateDownloadTo] filter players by date download rating
     * @param {Array<string>} [role] filter download  by role
     * @param {Array<string>} [assessmentFilterId] filter download result assessment by assessment_id
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_role&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadAssessmentHistory(
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateDownloadFrom?: string,
      dateDownloadTo?: string,
      role?: Array<string>,
      assessmentFilterId?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetDownloadAssessments> {
      return localVarFp
        .getDownloadAssessmentHistory(
          firstName,
          lastName,
          dateDownloadFrom,
          dateDownloadTo,
          role,
          assessmentFilterId,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getDownloadAssessmentHistory operation in AssessmentHistoryApi.
 * @export
 * @interface AssessmentHistoryApiGetDownloadAssessmentHistoryRequest
 */
export interface AssessmentHistoryApiGetDownloadAssessmentHistoryRequest {
  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly lastName?: Array<string>;

  /**
   * filter players by date download rating
   * @type {string}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly dateDownloadFrom?: string;

  /**
   * filter players by date download rating
   * @type {string}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly dateDownloadTo?: string;

  /**
   * filter download  by role
   * @type {Array<string>}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly role?: Array<string>;

  /**
   * filter download result assessment by assessment_id
   * @type {Array<string>}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly assessmentFilterId?: Array<string>;

  /**
   * sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_role&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof AssessmentHistoryApiGetDownloadAssessmentHistory
   */
  readonly pageSize?: number;
}

/**
 * AssessmentHistoryApi - object-oriented interface
 * @export
 * @class AssessmentHistoryApi
 * @extends {BaseAPI}
 */
export class AssessmentHistoryApi extends BaseAPI {
  /**
   * Get download assessment history
   * @summary Get download assessment history
   * @param {AssessmentHistoryApiGetDownloadAssessmentHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentHistoryApi
   */
  public getDownloadAssessmentHistory(
    requestParameters: AssessmentHistoryApiGetDownloadAssessmentHistoryRequest = {},
    options?: any,
  ) {
    return AssessmentHistoryApiFp(this.configuration)
      .getDownloadAssessmentHistory(
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.dateDownloadFrom,
        requestParameters.dateDownloadTo,
        requestParameters.role,
        requestParameters.assessmentFilterId,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ChangePlayerHistoryApi - axios parameter creator
 * @export
 */
export const ChangePlayerHistoryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get change player history
     * @summary Get change player in table rating history
     * @param {string} [firstNameAuthor] First Name
     * @param {string} [lastNameAuthor] Last Name
     * @param {Array<string>} [firstNamePlayer] filter players by firstNamePlayer
     * @param {Array<string>} [lastNamePlayer] filter players by lastNamePlayer
     * @param {string} [changeField] filter by change history by change field
     * @param {string} [oldValue] filter by change history by old value
     * @param {string} [newValue] filter by change history by new value
     * @param {string} [dateOfChangeFrom] filter players by date change
     * @param {string} [dateOfChangeTo] filter players by date change
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstNameAuthor&#x60;/&#x60;lastNameAuthor&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;date_of_changes&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangePlayerHistory: async (
      firstNameAuthor?: string,
      lastNameAuthor?: string,
      firstNamePlayer?: Array<string>,
      lastNamePlayer?: Array<string>,
      changeField?: string,
      oldValue?: string,
      newValue?: string,
      dateOfChangeFrom?: string,
      dateOfChangeTo?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/history-changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      if (firstNameAuthor !== undefined) {
        localVarQueryParameter["firstNameAuthor"] = firstNameAuthor;
      }

      if (lastNameAuthor !== undefined) {
        localVarQueryParameter["lastNameAuthor"] = lastNameAuthor;
      }

      if (firstNamePlayer) {
        localVarQueryParameter["firstNamePlayer"] = firstNamePlayer;
      }

      if (lastNamePlayer) {
        localVarQueryParameter["lastNamePlayer"] = lastNamePlayer;
      }

      if (changeField !== undefined) {
        localVarQueryParameter["changeField"] = changeField;
      }

      if (oldValue !== undefined) {
        localVarQueryParameter["oldValue"] = oldValue;
      }

      if (newValue !== undefined) {
        localVarQueryParameter["newValue"] = newValue;
      }

      if (dateOfChangeFrom !== undefined) {
        localVarQueryParameter["dateOfChangeFrom"] = dateOfChangeFrom;
      }

      if (dateOfChangeTo !== undefined) {
        localVarQueryParameter["dateOfChangeTo"] = dateOfChangeTo;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChangePlayerHistoryApi - functional programming interface
 * @export
 */
export const ChangePlayerHistoryApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ChangePlayerHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     * Get change player history
     * @summary Get change player in table rating history
     * @param {string} [firstNameAuthor] First Name
     * @param {string} [lastNameAuthor] Last Name
     * @param {Array<string>} [firstNamePlayer] filter players by firstNamePlayer
     * @param {Array<string>} [lastNamePlayer] filter players by lastNamePlayer
     * @param {string} [changeField] filter by change history by change field
     * @param {string} [oldValue] filter by change history by old value
     * @param {string} [newValue] filter by change history by new value
     * @param {string} [dateOfChangeFrom] filter players by date change
     * @param {string} [dateOfChangeTo] filter players by date change
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstNameAuthor&#x60;/&#x60;lastNameAuthor&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;date_of_changes&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChangePlayerHistory(
      firstNameAuthor?: string,
      lastNameAuthor?: string,
      firstNamePlayer?: Array<string>,
      lastNamePlayer?: Array<string>,
      changeField?: string,
      oldValue?: string,
      newValue?: string,
      dateOfChangeFrom?: string,
      dateOfChangeTo?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserChangeHistories>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getChangePlayerHistory(
          firstNameAuthor,
          lastNameAuthor,
          firstNamePlayer,
          lastNamePlayer,
          changeField,
          oldValue,
          newValue,
          dateOfChangeFrom,
          dateOfChangeTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ChangePlayerHistoryApi - factory interface
 * @export
 */
export const ChangePlayerHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ChangePlayerHistoryApiFp(configuration);
  return {
    /**
     * Get change player history
     * @summary Get change player in table rating history
     * @param {string} [firstNameAuthor] First Name
     * @param {string} [lastNameAuthor] Last Name
     * @param {Array<string>} [firstNamePlayer] filter players by firstNamePlayer
     * @param {Array<string>} [lastNamePlayer] filter players by lastNamePlayer
     * @param {string} [changeField] filter by change history by change field
     * @param {string} [oldValue] filter by change history by old value
     * @param {string} [newValue] filter by change history by new value
     * @param {string} [dateOfChangeFrom] filter players by date change
     * @param {string} [dateOfChangeTo] filter players by date change
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstNameAuthor&#x60;/&#x60;lastNameAuthor&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;date_of_changes&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangePlayerHistory(
      firstNameAuthor?: string,
      lastNameAuthor?: string,
      firstNamePlayer?: Array<string>,
      lastNamePlayer?: Array<string>,
      changeField?: string,
      oldValue?: string,
      newValue?: string,
      dateOfChangeFrom?: string,
      dateOfChangeTo?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetUserChangeHistories> {
      return localVarFp
        .getChangePlayerHistory(
          firstNameAuthor,
          lastNameAuthor,
          firstNamePlayer,
          lastNamePlayer,
          changeField,
          oldValue,
          newValue,
          dateOfChangeFrom,
          dateOfChangeTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getChangePlayerHistory operation in ChangePlayerHistoryApi.
 * @export
 * @interface ChangePlayerHistoryApiGetChangePlayerHistoryRequest
 */
export interface ChangePlayerHistoryApiGetChangePlayerHistoryRequest {
  /**
   * First Name
   * @type {string}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly firstNameAuthor?: string;

  /**
   * Last Name
   * @type {string}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly lastNameAuthor?: string;

  /**
   * filter players by firstNamePlayer
   * @type {Array<string>}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly firstNamePlayer?: Array<string>;

  /**
   * filter players by lastNamePlayer
   * @type {Array<string>}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly lastNamePlayer?: Array<string>;

  /**
   * filter by change history by change field
   * @type {string}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly changeField?: string;

  /**
   * filter by change history by old value
   * @type {string}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly oldValue?: string;

  /**
   * filter by change history by new value
   * @type {string}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly newValue?: string;

  /**
   * filter players by date change
   * @type {string}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly dateOfChangeFrom?: string;

  /**
   * filter players by date change
   * @type {string}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly dateOfChangeTo?: string;

  /**
   * sort players by fields &#x60;firstNameAuthor&#x60;/&#x60;lastNameAuthor&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;date_of_changes&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof ChangePlayerHistoryApiGetChangePlayerHistory
   */
  readonly pageSize?: number;
}

/**
 * ChangePlayerHistoryApi - object-oriented interface
 * @export
 * @class ChangePlayerHistoryApi
 * @extends {BaseAPI}
 */
export class ChangePlayerHistoryApi extends BaseAPI {
  /**
   * Get change player history
   * @summary Get change player in table rating history
   * @param {ChangePlayerHistoryApiGetChangePlayerHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChangePlayerHistoryApi
   */
  public getChangePlayerHistory(
    requestParameters: ChangePlayerHistoryApiGetChangePlayerHistoryRequest = {},
    options?: any,
  ) {
    return ChangePlayerHistoryApiFp(this.configuration)
      .getChangePlayerHistory(
        requestParameters.firstNameAuthor,
        requestParameters.lastNameAuthor,
        requestParameters.firstNamePlayer,
        requestParameters.lastNamePlayer,
        requestParameters.changeField,
        requestParameters.oldValue,
        requestParameters.newValue,
        requestParameters.dateOfChangeFrom,
        requestParameters.dateOfChangeTo,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete user comment
     * @param {string} pId Player ID
     * @param {number} commentId Comment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserComment: async (
      pId: string,
      commentId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("deleteUserComment", "pId", pId);
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists("deleteUserComment", "commentId", commentId);
      const localVarPath = `/api/v1/comment/{pId}/{comment_id}`
        .replace(`{${"pId"}}`, encodeURIComponent(String(pId)))
        .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user comment
     * @param {string} pId Player ID
     * @param {number} commentId Comment ID
     * @param {CommentRequestDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserComment: async (
      pId: string,
      commentId: number,
      body: CommentRequestDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("updateUserComment", "pId", pId);
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists("updateUserComment", "commentId", commentId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("updateUserComment", "body", body);
      const localVarPath = `/api/v1/comment/{pId}/{comment_id}`
        .replace(`{${"pId"}}`, encodeURIComponent(String(pId)))
        .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Write user comment
     * @param {string} pId Player ID
     * @param {CommentRequestDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeUserComment: async (
      pId: string,
      body: CommentRequestDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("writeUserComment", "pId", pId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("writeUserComment", "body", body);
      const localVarPath = `/api/v1/comment/{pId}`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete user comment
     * @param {string} pId Player ID
     * @param {number} commentId Comment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserComment(
      pId: string,
      commentId: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteUserComment(
          pId,
          commentId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update user comment
     * @param {string} pId Player ID
     * @param {number} commentId Comment ID
     * @param {CommentRequestDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserComment(
      pId: string,
      commentId: number,
      body: CommentRequestDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserComment(
          pId,
          commentId,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Write user comment
     * @param {string} pId Player ID
     * @param {CommentRequestDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async writeUserComment(
      pId: string,
      body: CommentRequestDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.writeUserComment(pId, body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommentApiFp(configuration);
  return {
    /**
     *
     * @summary Delete user comment
     * @param {string} pId Player ID
     * @param {number} commentId Comment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserComment(
      pId: string,
      commentId: number,
      options?: any,
    ): AxiosPromise<Player> {
      return localVarFp
        .deleteUserComment(pId, commentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user comment
     * @param {string} pId Player ID
     * @param {number} commentId Comment ID
     * @param {CommentRequestDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserComment(
      pId: string,
      commentId: number,
      body: CommentRequestDto,
      options?: any,
    ): AxiosPromise<Player> {
      return localVarFp
        .updateUserComment(pId, commentId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Write user comment
     * @param {string} pId Player ID
     * @param {CommentRequestDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeUserComment(
      pId: string,
      body: CommentRequestDto,
      options?: any,
    ): AxiosPromise<Player> {
      return localVarFp
        .writeUserComment(pId, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteUserComment operation in CommentApi.
 * @export
 * @interface CommentApiDeleteUserCommentRequest
 */
export interface CommentApiDeleteUserCommentRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof CommentApiDeleteUserComment
   */
  readonly pId: string;

  /**
   * Comment ID
   * @type {number}
   * @memberof CommentApiDeleteUserComment
   */
  readonly commentId: number;
}

/**
 * Request parameters for updateUserComment operation in CommentApi.
 * @export
 * @interface CommentApiUpdateUserCommentRequest
 */
export interface CommentApiUpdateUserCommentRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof CommentApiUpdateUserComment
   */
  readonly pId: string;

  /**
   * Comment ID
   * @type {number}
   * @memberof CommentApiUpdateUserComment
   */
  readonly commentId: number;

  /**
   *
   * @type {CommentRequestDto}
   * @memberof CommentApiUpdateUserComment
   */
  readonly body: CommentRequestDto;
}

/**
 * Request parameters for writeUserComment operation in CommentApi.
 * @export
 * @interface CommentApiWriteUserCommentRequest
 */
export interface CommentApiWriteUserCommentRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof CommentApiWriteUserComment
   */
  readonly pId: string;

  /**
   *
   * @type {CommentRequestDto}
   * @memberof CommentApiWriteUserComment
   */
  readonly body: CommentRequestDto;
}

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
  /**
   *
   * @summary Delete user comment
   * @param {CommentApiDeleteUserCommentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public deleteUserComment(
    requestParameters: CommentApiDeleteUserCommentRequest,
    options?: any,
  ) {
    return CommentApiFp(this.configuration)
      .deleteUserComment(
        requestParameters.pId,
        requestParameters.commentId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update user comment
   * @param {CommentApiUpdateUserCommentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public updateUserComment(
    requestParameters: CommentApiUpdateUserCommentRequest,
    options?: any,
  ) {
    return CommentApiFp(this.configuration)
      .updateUserComment(
        requestParameters.pId,
        requestParameters.commentId,
        requestParameters.body,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Write user comment
   * @param {CommentApiWriteUserCommentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public writeUserComment(
    requestParameters: CommentApiWriteUserCommentRequest,
    options?: any,
  ) {
    return CommentApiFp(this.configuration)
      .writeUserComment(requestParameters.pId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ComplaintsApi - axios parameter creator
 * @export
 */
export const ComplaintsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Leave Complaint by player
     * @param {ComplaintReqBody} complaint create complaint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComplaint: async (
      complaint: ComplaintReqBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'complaint' is not null or undefined
      assertParamExists("createComplaint", "complaint", complaint);
      const localVarPath = `/api/v1/complaints`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        complaint,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the complaints
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {string} [appelantFirstname] filter complaints by appelant_firstname
     * @param {string} [appelantLastname] filter complaints by appelant_lastname
     * @param {string} [defendantFirstname] filter complaints by defendant_firstname
     * @param {string} [defendantLastname] filter complaints by defendant_lastname
     * @param {string} [fromDateOfCreate] filter complaints by from_date_of_create from
     * @param {string} [toDateOfCreate] filter complaints by to_date_of_create to
     * @param {Array<string>} [types] filter complaints by type
     * @param {string} [status] filter complaints by status
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;appelant_firstname&#x60;/&#x60;appelant_lastname&#x60;/ &#x60;defendant_firstname&#x60;/&#x60;defendant_lastname&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComplaints: async (
      pageNum?: number,
      pageSize?: number,
      locale?: string,
      appelantFirstname?: string,
      appelantLastname?: string,
      defendantFirstname?: string,
      defendantLastname?: string,
      fromDateOfCreate?: string,
      toDateOfCreate?: string,
      types?: Array<string>,
      status?: string,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/complaints`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      if (appelantFirstname !== undefined) {
        localVarQueryParameter["appelant_firstname"] = appelantFirstname;
      }

      if (appelantLastname !== undefined) {
        localVarQueryParameter["appelant_lastname"] = appelantLastname;
      }

      if (defendantFirstname !== undefined) {
        localVarQueryParameter["defendant_firstname"] = defendantFirstname;
      }

      if (defendantLastname !== undefined) {
        localVarQueryParameter["defendant_lastname"] = defendantLastname;
      }

      if (fromDateOfCreate !== undefined) {
        localVarQueryParameter["from_date_of_create"] =
          (fromDateOfCreate as any) instanceof Date
            ? (fromDateOfCreate as any).toISOString().substr(0, 10)
            : fromDateOfCreate;
      }

      if (toDateOfCreate !== undefined) {
        localVarQueryParameter["to_date_of_create"] =
          (toDateOfCreate as any) instanceof Date
            ? (toDateOfCreate as any).toISOString().substr(0, 10)
            : toDateOfCreate;
      }

      if (types) {
        localVarQueryParameter["types"] = types;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Complaint (accept or reject)
     * @param {string} cId Complaint ID
     * @param {ComplaintByIdReqBody} complaint Accept or reject complaint
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchComplaintById: async (
      cId: string,
      complaint: ComplaintByIdReqBody,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cId' is not null or undefined
      assertParamExists("patchComplaintById", "cId", cId);
      // verify required parameter 'complaint' is not null or undefined
      assertParamExists("patchComplaintById", "complaint", complaint);
      const localVarPath = `/api/v1/complaints/{cId}`.replace(
        `{${"cId"}}`,
        encodeURIComponent(String(cId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        complaint,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ComplaintsApi - functional programming interface
 * @export
 */
export const ComplaintsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ComplaintsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Leave Complaint by player
     * @param {ComplaintReqBody} complaint create complaint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createComplaint(
      complaint: ComplaintReqBody,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse200>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createComplaint(
        complaint,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all the complaints
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {string} [appelantFirstname] filter complaints by appelant_firstname
     * @param {string} [appelantLastname] filter complaints by appelant_lastname
     * @param {string} [defendantFirstname] filter complaints by defendant_firstname
     * @param {string} [defendantLastname] filter complaints by defendant_lastname
     * @param {string} [fromDateOfCreate] filter complaints by from_date_of_create from
     * @param {string} [toDateOfCreate] filter complaints by to_date_of_create to
     * @param {Array<string>} [types] filter complaints by type
     * @param {string} [status] filter complaints by status
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;appelant_firstname&#x60;/&#x60;appelant_lastname&#x60;/ &#x60;defendant_firstname&#x60;/&#x60;defendant_lastname&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getComplaints(
      pageNum?: number,
      pageSize?: number,
      locale?: string,
      appelantFirstname?: string,
      appelantLastname?: string,
      defendantFirstname?: string,
      defendantLastname?: string,
      fromDateOfCreate?: string,
      toDateOfCreate?: string,
      types?: Array<string>,
      status?: string,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complaints>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getComplaints(
        pageNum,
        pageSize,
        locale,
        appelantFirstname,
        appelantLastname,
        defendantFirstname,
        defendantLastname,
        fromDateOfCreate,
        toDateOfCreate,
        types,
        status,
        orderBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Patch Complaint (accept or reject)
     * @param {string} cId Complaint ID
     * @param {ComplaintByIdReqBody} complaint Accept or reject complaint
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchComplaintById(
      cId: string,
      complaint: ComplaintByIdReqBody,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complaint>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchComplaintById(
          cId,
          complaint,
          locale,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ComplaintsApi - factory interface
 * @export
 */
export const ComplaintsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ComplaintsApiFp(configuration);
  return {
    /**
     *
     * @summary Leave Complaint by player
     * @param {ComplaintReqBody} complaint create complaint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComplaint(
      complaint: ComplaintReqBody,
      options?: any,
    ): AxiosPromise<InlineResponse200> {
      return localVarFp
        .createComplaint(complaint, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all the complaints
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {string} [appelantFirstname] filter complaints by appelant_firstname
     * @param {string} [appelantLastname] filter complaints by appelant_lastname
     * @param {string} [defendantFirstname] filter complaints by defendant_firstname
     * @param {string} [defendantLastname] filter complaints by defendant_lastname
     * @param {string} [fromDateOfCreate] filter complaints by from_date_of_create from
     * @param {string} [toDateOfCreate] filter complaints by to_date_of_create to
     * @param {Array<string>} [types] filter complaints by type
     * @param {string} [status] filter complaints by status
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;appelant_firstname&#x60;/&#x60;appelant_lastname&#x60;/ &#x60;defendant_firstname&#x60;/&#x60;defendant_lastname&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComplaints(
      pageNum?: number,
      pageSize?: number,
      locale?: string,
      appelantFirstname?: string,
      appelantLastname?: string,
      defendantFirstname?: string,
      defendantLastname?: string,
      fromDateOfCreate?: string,
      toDateOfCreate?: string,
      types?: Array<string>,
      status?: string,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<Complaints> {
      return localVarFp
        .getComplaints(
          pageNum,
          pageSize,
          locale,
          appelantFirstname,
          appelantLastname,
          defendantFirstname,
          defendantLastname,
          fromDateOfCreate,
          toDateOfCreate,
          types,
          status,
          orderBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Complaint (accept or reject)
     * @param {string} cId Complaint ID
     * @param {ComplaintByIdReqBody} complaint Accept or reject complaint
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchComplaintById(
      cId: string,
      complaint: ComplaintByIdReqBody,
      locale?: string,
      options?: any,
    ): AxiosPromise<Complaint> {
      return localVarFp
        .patchComplaintById(cId, complaint, locale, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createComplaint operation in ComplaintsApi.
 * @export
 * @interface ComplaintsApiCreateComplaintRequest
 */
export interface ComplaintsApiCreateComplaintRequest {
  /**
   * create complaint
   * @type {ComplaintReqBody}
   * @memberof ComplaintsApiCreateComplaint
   */
  readonly complaint: ComplaintReqBody;
}

/**
 * Request parameters for getComplaints operation in ComplaintsApi.
 * @export
 * @interface ComplaintsApiGetComplaintsRequest
 */
export interface ComplaintsApiGetComplaintsRequest {
  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly pageSize?: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly locale?: string;

  /**
   * filter complaints by appelant_firstname
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly appelantFirstname?: string;

  /**
   * filter complaints by appelant_lastname
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly appelantLastname?: string;

  /**
   * filter complaints by defendant_firstname
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly defendantFirstname?: string;

  /**
   * filter complaints by defendant_lastname
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly defendantLastname?: string;

  /**
   * filter complaints by from_date_of_create from
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly fromDateOfCreate?: string;

  /**
   * filter complaints by to_date_of_create to
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly toDateOfCreate?: string;

  /**
   * filter complaints by type
   * @type {Array<string>}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly types?: Array<string>;

  /**
   * filter complaints by status
   * @type {string}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly status?: string;

  /**
   * sort countries by fields &#x60;appelant_firstname&#x60;/&#x60;appelant_lastname&#x60;/ &#x60;defendant_firstname&#x60;/&#x60;defendant_lastname&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof ComplaintsApiGetComplaints
   */
  readonly orderBy?: Array<string>;
}

/**
 * Request parameters for patchComplaintById operation in ComplaintsApi.
 * @export
 * @interface ComplaintsApiPatchComplaintByIdRequest
 */
export interface ComplaintsApiPatchComplaintByIdRequest {
  /**
   * Complaint ID
   * @type {string}
   * @memberof ComplaintsApiPatchComplaintById
   */
  readonly cId: string;

  /**
   * Accept or reject complaint
   * @type {ComplaintByIdReqBody}
   * @memberof ComplaintsApiPatchComplaintById
   */
  readonly complaint: ComplaintByIdReqBody;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof ComplaintsApiPatchComplaintById
   */
  readonly locale?: string;
}

/**
 * ComplaintsApi - object-oriented interface
 * @export
 * @class ComplaintsApi
 * @extends {BaseAPI}
 */
export class ComplaintsApi extends BaseAPI {
  /**
   *
   * @summary Leave Complaint by player
   * @param {ComplaintsApiCreateComplaintRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComplaintsApi
   */
  public createComplaint(
    requestParameters: ComplaintsApiCreateComplaintRequest,
    options?: any,
  ) {
    return ComplaintsApiFp(this.configuration)
      .createComplaint(requestParameters.complaint, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all the complaints
   * @param {ComplaintsApiGetComplaintsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComplaintsApi
   */
  public getComplaints(
    requestParameters: ComplaintsApiGetComplaintsRequest = {},
    options?: any,
  ) {
    return ComplaintsApiFp(this.configuration)
      .getComplaints(
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.locale,
        requestParameters.appelantFirstname,
        requestParameters.appelantLastname,
        requestParameters.defendantFirstname,
        requestParameters.defendantLastname,
        requestParameters.fromDateOfCreate,
        requestParameters.toDateOfCreate,
        requestParameters.types,
        requestParameters.status,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Complaint (accept or reject)
   * @param {ComplaintsApiPatchComplaintByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ComplaintsApi
   */
  public patchComplaintById(
    requestParameters: ComplaintsApiPatchComplaintByIdRequest,
    options?: any,
  ) {
    return ComplaintsApiFp(this.configuration)
      .patchComplaintById(
        requestParameters.cId,
        requestParameters.complaint,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DictionariesApi - axios parameter creator
 * @export
 */
export const DictionariesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all the countries
     * @param {Array<string>} [countryCode]
     * @param {Array<string>} [title]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;code&#x60;/&#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountries: async (
      countryCode?: Array<string>,
      title?: Array<string>,
      locale?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (countryCode) {
        localVarQueryParameter["country_code"] = countryCode;
      }

      if (title) {
        localVarQueryParameter["title"] = title;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a country by code
     * @param {string} countryCode
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountry: async (
      countryCode: string,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists("getCountry", "countryCode", countryCode);
      const localVarPath = `/api/v1/dict/countries/{country_code}`.replace(
        `{${"country_code"}}`,
        encodeURIComponent(String(countryCode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get district by id
     * @param {number} district
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDistrictById: async (
      district: number,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'district' is not null or undefined
      assertParamExists("getDistrictById", "district", district);
      const localVarPath = `/api/v1/dict/districts/{district_id}`.replace(
        `{${"district"}}`,
        encodeURIComponent(String(district)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get districts by region id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDistricts: async (
      countryCode?: string,
      regionId?: number,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/districts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (countryCode !== undefined) {
        localVarQueryParameter["country_code"] = countryCode;
      }

      if (regionId !== undefined) {
        localVarQueryParameter["region_id"] = regionId;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get localities by district id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {number} [districtId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocalities: async (
      countryCode?: string,
      regionId?: number,
      districtId?: number,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/localities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (countryCode !== undefined) {
        localVarQueryParameter["country_code"] = countryCode;
      }

      if (regionId !== undefined) {
        localVarQueryParameter["region_id"] = regionId;
      }

      if (districtId !== undefined) {
        localVarQueryParameter["district_id"] = districtId;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get locality by id
     * @param {number} localityId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocalityById: async (
      localityId: number,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'localityId' is not null or undefined
      assertParamExists("getLocalityById", "localityId", localityId);
      const localVarPath = `/api/v1/dict/localities/{locality_id}`.replace(
        `{${"locality_id"}}`,
        encodeURIComponent(String(localityId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get countries
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMapCountries: async (
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/map-countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get regions
     * @param {string} [countryCode]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMapRegions: async (
      countryCode?: string,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/map-regions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (countryCode !== undefined) {
        localVarQueryParameter["country_code"] = countryCode;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get region by id
     * @param {number} regionId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegionById: async (
      regionId: number,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'regionId' is not null or undefined
      assertParamExists("getRegionById", "regionId", regionId);
      const localVarPath = `/api/v1/dict/regions/{region_id}`.replace(
        `{${"region_id"}}`,
        encodeURIComponent(String(regionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get regions by country
     * @param {string} [countryCode]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions: async (
      countryCode?: string,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/regions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (countryCode !== undefined) {
        localVarQueryParameter["country_code"] = countryCode;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the RejectionReasons
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRejectionReasons: async (
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/rejectionReasons`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the RoleInRelatedProjects
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleInRelatedProjects: async (
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/rolesInRelatedProjects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get schools by id
     * @param {number} schoolId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSchoolById: async (
      schoolId: number,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'schoolId' is not null or undefined
      assertParamExists("getSchoolById", "schoolId", schoolId);
      const localVarPath = `/api/v1/dict/schools/{school_id}`.replace(
        `{${"school_id"}}`,
        encodeURIComponent(String(schoolId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get schools by localities id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {number} [districtId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSchools: async (
      countryCode?: string,
      regionId?: number,
      districtId?: number,
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/schools`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (countryCode !== undefined) {
        localVarQueryParameter["country_code"] = countryCode;
      }

      if (regionId !== undefined) {
        localVarQueryParameter["region_id"] = regionId;
      }

      if (districtId !== undefined) {
        localVarQueryParameter["district_id"] = districtId;
      }

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the StatusesCompany
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusesCompany: async (
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/statusesCompany`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the StatusesEmployment
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusesEmployment: async (
      locale?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dict/statusesEmployment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DictionariesApi - functional programming interface
 * @export
 */
export const DictionariesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DictionariesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all the countries
     * @param {Array<string>} [countryCode]
     * @param {Array<string>} [title]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;code&#x60;/&#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCountries(
      countryCode?: Array<string>,
      title?: Array<string>,
      locale?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Countries>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(
        countryCode,
        title,
        locale,
        orderBy,
        pageNum,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get a country by code
     * @param {string} countryCode
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCountry(
      countryCode: string,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Country>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCountry(
        countryCode,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get district by id
     * @param {number} district
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDistrictById(
      district: number,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<District>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDistrictById(
        district,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get districts by region id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDistricts(
      countryCode?: string,
      regionId?: number,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Districts>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDistricts(
        countryCode,
        regionId,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get localities by district id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {number} [districtId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocalities(
      countryCode?: string,
      regionId?: number,
      districtId?: number,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Localities>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalities(
        countryCode,
        regionId,
        districtId,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get locality by id
     * @param {number} localityId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocalityById(
      localityId: number,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Locality>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalityById(
        localityId,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get countries
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMapCountries(
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapCountries>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMapCountries(
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get regions
     * @param {string} [countryCode]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMapRegions(
      countryCode?: string,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapRegions>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMapRegions(
        countryCode,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get region by id
     * @param {number} regionId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRegionById(
      regionId: number,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionById(
        regionId,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get regions by country
     * @param {string} [countryCode]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRegions(
      countryCode?: string,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Regions>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(
        countryCode,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all the RejectionReasons
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRejectionReasons(
      locale?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RejectionReasonsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRejectionReasons(locale, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all the RoleInRelatedProjects
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoleInRelatedProjects(
      locale?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RolesInRelatedProjectsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRoleInRelatedProjects(
          locale,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get schools by id
     * @param {number} schoolId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSchoolById(
      schoolId: number,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSchoolById(
        schoolId,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get schools by localities id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {number} [districtId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSchools(
      countryCode?: string,
      regionId?: number,
      districtId?: number,
      locale?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schools>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSchools(
        countryCode,
        regionId,
        districtId,
        locale,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all the StatusesCompany
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStatusesCompany(
      locale?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StatusesCompanyDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStatusesCompany(locale, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all the StatusesEmployment
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStatusesEmployment(
      locale?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StatusesEmploymentDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStatusesEmployment(locale, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DictionariesApi - factory interface
 * @export
 */
export const DictionariesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DictionariesApiFp(configuration);
  return {
    /**
     *
     * @summary Get all the countries
     * @param {Array<string>} [countryCode]
     * @param {Array<string>} [title]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;code&#x60;/&#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountries(
      countryCode?: Array<string>,
      title?: Array<string>,
      locale?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<Countries> {
      return localVarFp
        .getCountries(
          countryCode,
          title,
          locale,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a country by code
     * @param {string} countryCode
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountry(
      countryCode: string,
      locale?: string,
      options?: any,
    ): AxiosPromise<Country> {
      return localVarFp
        .getCountry(countryCode, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get district by id
     * @param {number} district
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDistrictById(
      district: number,
      locale?: string,
      options?: any,
    ): AxiosPromise<District> {
      return localVarFp
        .getDistrictById(district, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get districts by region id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDistricts(
      countryCode?: string,
      regionId?: number,
      locale?: string,
      options?: any,
    ): AxiosPromise<Districts> {
      return localVarFp
        .getDistricts(countryCode, regionId, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get localities by district id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {number} [districtId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocalities(
      countryCode?: string,
      regionId?: number,
      districtId?: number,
      locale?: string,
      options?: any,
    ): AxiosPromise<Localities> {
      return localVarFp
        .getLocalities(countryCode, regionId, districtId, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get locality by id
     * @param {number} localityId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocalityById(
      localityId: number,
      locale?: string,
      options?: any,
    ): AxiosPromise<Locality> {
      return localVarFp
        .getLocalityById(localityId, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get countries
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMapCountries(
      locale?: string,
      options?: any,
    ): AxiosPromise<MapCountries> {
      return localVarFp
        .getMapCountries(locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get regions
     * @param {string} [countryCode]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMapRegions(
      countryCode?: string,
      locale?: string,
      options?: any,
    ): AxiosPromise<MapRegions> {
      return localVarFp
        .getMapRegions(countryCode, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get region by id
     * @param {number} regionId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegionById(
      regionId: number,
      locale?: string,
      options?: any,
    ): AxiosPromise<Region> {
      return localVarFp
        .getRegionById(regionId, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get regions by country
     * @param {string} [countryCode]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRegions(
      countryCode?: string,
      locale?: string,
      options?: any,
    ): AxiosPromise<Regions> {
      return localVarFp
        .getRegions(countryCode, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all the RejectionReasons
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRejectionReasons(
      locale?: string,
      options?: any,
    ): AxiosPromise<RejectionReasonsDto> {
      return localVarFp
        .getRejectionReasons(locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all the RoleInRelatedProjects
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoleInRelatedProjects(
      locale?: string,
      options?: any,
    ): AxiosPromise<RolesInRelatedProjectsDto> {
      return localVarFp
        .getRoleInRelatedProjects(locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get schools by id
     * @param {number} schoolId
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSchoolById(
      schoolId: number,
      locale?: string,
      options?: any,
    ): AxiosPromise<School> {
      return localVarFp
        .getSchoolById(schoolId, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get schools by localities id
     * @param {string} [countryCode]
     * @param {number} [regionId]
     * @param {number} [districtId]
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSchools(
      countryCode?: string,
      regionId?: number,
      districtId?: number,
      locale?: string,
      options?: any,
    ): AxiosPromise<Schools> {
      return localVarFp
        .getSchools(countryCode, regionId, districtId, locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all the StatusesCompany
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusesCompany(
      locale?: string,
      options?: any,
    ): AxiosPromise<StatusesCompanyDto> {
      return localVarFp
        .getStatusesCompany(locale, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all the StatusesEmployment
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusesEmployment(
      locale?: string,
      options?: any,
    ): AxiosPromise<StatusesEmploymentDto> {
      return localVarFp
        .getStatusesEmployment(locale, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getCountries operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetCountriesRequest
 */
export interface DictionariesApiGetCountriesRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof DictionariesApiGetCountries
   */
  readonly countryCode?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof DictionariesApiGetCountries
   */
  readonly title?: Array<string>;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetCountries
   */
  readonly locale?: string;

  /**
   * sort countries by fields &#x60;code&#x60;/&#x60;title&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof DictionariesApiGetCountries
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof DictionariesApiGetCountries
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof DictionariesApiGetCountries
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getCountry operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetCountryRequest
 */
export interface DictionariesApiGetCountryRequest {
  /**
   *
   * @type {string}
   * @memberof DictionariesApiGetCountry
   */
  readonly countryCode: string;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetCountry
   */
  readonly locale?: string;
}

/**
 * Request parameters for getDistrictById operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetDistrictByIdRequest
 */
export interface DictionariesApiGetDistrictByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetDistrictById
   */
  readonly district: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetDistrictById
   */
  readonly locale?: string;
}

/**
 * Request parameters for getDistricts operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetDistrictsRequest
 */
export interface DictionariesApiGetDistrictsRequest {
  /**
   *
   * @type {string}
   * @memberof DictionariesApiGetDistricts
   */
  readonly countryCode?: string;

  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetDistricts
   */
  readonly regionId?: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetDistricts
   */
  readonly locale?: string;
}

/**
 * Request parameters for getLocalities operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetLocalitiesRequest
 */
export interface DictionariesApiGetLocalitiesRequest {
  /**
   *
   * @type {string}
   * @memberof DictionariesApiGetLocalities
   */
  readonly countryCode?: string;

  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetLocalities
   */
  readonly regionId?: number;

  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetLocalities
   */
  readonly districtId?: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetLocalities
   */
  readonly locale?: string;
}

/**
 * Request parameters for getLocalityById operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetLocalityByIdRequest
 */
export interface DictionariesApiGetLocalityByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetLocalityById
   */
  readonly localityId: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetLocalityById
   */
  readonly locale?: string;
}

/**
 * Request parameters for getMapCountries operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetMapCountriesRequest
 */
export interface DictionariesApiGetMapCountriesRequest {
  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetMapCountries
   */
  readonly locale?: string;
}

/**
 * Request parameters for getMapRegions operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetMapRegionsRequest
 */
export interface DictionariesApiGetMapRegionsRequest {
  /**
   *
   * @type {string}
   * @memberof DictionariesApiGetMapRegions
   */
  readonly countryCode?: string;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetMapRegions
   */
  readonly locale?: string;
}

/**
 * Request parameters for getRegionById operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetRegionByIdRequest
 */
export interface DictionariesApiGetRegionByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetRegionById
   */
  readonly regionId: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetRegionById
   */
  readonly locale?: string;
}

/**
 * Request parameters for getRegions operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetRegionsRequest
 */
export interface DictionariesApiGetRegionsRequest {
  /**
   *
   * @type {string}
   * @memberof DictionariesApiGetRegions
   */
  readonly countryCode?: string;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetRegions
   */
  readonly locale?: string;
}

/**
 * Request parameters for getRejectionReasons operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetRejectionReasonsRequest
 */
export interface DictionariesApiGetRejectionReasonsRequest {
  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetRejectionReasons
   */
  readonly locale?: string;
}

/**
 * Request parameters for getRoleInRelatedProjects operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetRoleInRelatedProjectsRequest
 */
export interface DictionariesApiGetRoleInRelatedProjectsRequest {
  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetRoleInRelatedProjects
   */
  readonly locale?: string;
}

/**
 * Request parameters for getSchoolById operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetSchoolByIdRequest
 */
export interface DictionariesApiGetSchoolByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetSchoolById
   */
  readonly schoolId: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetSchoolById
   */
  readonly locale?: string;
}

/**
 * Request parameters for getSchools operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetSchoolsRequest
 */
export interface DictionariesApiGetSchoolsRequest {
  /**
   *
   * @type {string}
   * @memberof DictionariesApiGetSchools
   */
  readonly countryCode?: string;

  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetSchools
   */
  readonly regionId?: number;

  /**
   *
   * @type {number}
   * @memberof DictionariesApiGetSchools
   */
  readonly districtId?: number;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetSchools
   */
  readonly locale?: string;
}

/**
 * Request parameters for getStatusesCompany operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetStatusesCompanyRequest
 */
export interface DictionariesApiGetStatusesCompanyRequest {
  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetStatusesCompany
   */
  readonly locale?: string;
}

/**
 * Request parameters for getStatusesEmployment operation in DictionariesApi.
 * @export
 * @interface DictionariesApiGetStatusesEmploymentRequest
 */
export interface DictionariesApiGetStatusesEmploymentRequest {
  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof DictionariesApiGetStatusesEmployment
   */
  readonly locale?: string;
}

/**
 * DictionariesApi - object-oriented interface
 * @export
 * @class DictionariesApi
 * @extends {BaseAPI}
 */
export class DictionariesApi extends BaseAPI {
  /**
   *
   * @summary Get all the countries
   * @param {DictionariesApiGetCountriesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getCountries(
    requestParameters: DictionariesApiGetCountriesRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getCountries(
        requestParameters.countryCode,
        requestParameters.title,
        requestParameters.locale,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a country by code
   * @param {DictionariesApiGetCountryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getCountry(
    requestParameters: DictionariesApiGetCountryRequest,
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getCountry(
        requestParameters.countryCode,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get district by id
   * @param {DictionariesApiGetDistrictByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getDistrictById(
    requestParameters: DictionariesApiGetDistrictByIdRequest,
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getDistrictById(
        requestParameters.district,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get districts by region id
   * @param {DictionariesApiGetDistrictsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getDistricts(
    requestParameters: DictionariesApiGetDistrictsRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getDistricts(
        requestParameters.countryCode,
        requestParameters.regionId,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get localities by district id
   * @param {DictionariesApiGetLocalitiesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getLocalities(
    requestParameters: DictionariesApiGetLocalitiesRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getLocalities(
        requestParameters.countryCode,
        requestParameters.regionId,
        requestParameters.districtId,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get locality by id
   * @param {DictionariesApiGetLocalityByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getLocalityById(
    requestParameters: DictionariesApiGetLocalityByIdRequest,
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getLocalityById(
        requestParameters.localityId,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get countries
   * @param {DictionariesApiGetMapCountriesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getMapCountries(
    requestParameters: DictionariesApiGetMapCountriesRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getMapCountries(requestParameters.locale, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get regions
   * @param {DictionariesApiGetMapRegionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getMapRegions(
    requestParameters: DictionariesApiGetMapRegionsRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getMapRegions(
        requestParameters.countryCode,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get region by id
   * @param {DictionariesApiGetRegionByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getRegionById(
    requestParameters: DictionariesApiGetRegionByIdRequest,
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getRegionById(
        requestParameters.regionId,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get regions by country
   * @param {DictionariesApiGetRegionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getRegions(
    requestParameters: DictionariesApiGetRegionsRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getRegions(
        requestParameters.countryCode,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all the RejectionReasons
   * @param {DictionariesApiGetRejectionReasonsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getRejectionReasons(
    requestParameters: DictionariesApiGetRejectionReasonsRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getRejectionReasons(requestParameters.locale, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all the RoleInRelatedProjects
   * @param {DictionariesApiGetRoleInRelatedProjectsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getRoleInRelatedProjects(
    requestParameters: DictionariesApiGetRoleInRelatedProjectsRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getRoleInRelatedProjects(requestParameters.locale, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get schools by id
   * @param {DictionariesApiGetSchoolByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getSchoolById(
    requestParameters: DictionariesApiGetSchoolByIdRequest,
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getSchoolById(
        requestParameters.schoolId,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get schools by localities id
   * @param {DictionariesApiGetSchoolsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getSchools(
    requestParameters: DictionariesApiGetSchoolsRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getSchools(
        requestParameters.countryCode,
        requestParameters.regionId,
        requestParameters.districtId,
        requestParameters.locale,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all the StatusesCompany
   * @param {DictionariesApiGetStatusesCompanyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getStatusesCompany(
    requestParameters: DictionariesApiGetStatusesCompanyRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getStatusesCompany(requestParameters.locale, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all the StatusesEmployment
   * @param {DictionariesApiGetStatusesEmploymentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DictionariesApi
   */
  public getStatusesEmployment(
    requestParameters: DictionariesApiGetStatusesEmploymentRequest = {},
    options?: any,
  ) {
    return DictionariesApiFp(this.configuration)
      .getStatusesEmployment(requestParameters.locale, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DownloadHistoryApi - axios parameter creator
 * @export
 */
export const DownloadHistoryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get download history rating
     * @summary Get download history rating info
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateDownloadFrom] filter players by date download rating
     * @param {string} [dateDownloadTo] filter players by date download rating
     * @param {number} [userCountTo] filter players by user count download rating
     * @param {number} [userCountFrom] filter players by user count download rating
     * @param {boolean} [statusDownloadRating] filter players by status download rating
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_count&#x60;/&#x60;success&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadHistoryRating: async (
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateDownloadFrom?: string,
      dateDownloadTo?: string,
      userCountTo?: number,
      userCountFrom?: number,
      statusDownloadRating?: boolean,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ratings/download-history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (dateDownloadFrom !== undefined) {
        localVarQueryParameter["dateDownloadFrom"] = dateDownloadFrom;
      }

      if (dateDownloadTo !== undefined) {
        localVarQueryParameter["dateDownloadTo"] = dateDownloadTo;
      }

      if (userCountTo !== undefined) {
        localVarQueryParameter["userCountTo"] = userCountTo;
      }

      if (userCountFrom !== undefined) {
        localVarQueryParameter["userCountFrom"] = userCountFrom;
      }

      if (statusDownloadRating !== undefined) {
        localVarQueryParameter["statusDownloadRating"] = statusDownloadRating;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DownloadHistoryApi - functional programming interface
 * @export
 */
export const DownloadHistoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DownloadHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     * Get download history rating
     * @summary Get download history rating info
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateDownloadFrom] filter players by date download rating
     * @param {string} [dateDownloadTo] filter players by date download rating
     * @param {number} [userCountTo] filter players by user count download rating
     * @param {number} [userCountFrom] filter players by user count download rating
     * @param {boolean} [statusDownloadRating] filter players by status download rating
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_count&#x60;/&#x60;success&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadHistoryRating(
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateDownloadFrom?: string,
      dateDownloadTo?: string,
      userCountTo?: number,
      userCountFrom?: number,
      statusDownloadRating?: boolean,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDownloadRating>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDownloadHistoryRating(
          firstName,
          lastName,
          dateDownloadFrom,
          dateDownloadTo,
          userCountTo,
          userCountFrom,
          statusDownloadRating,
          pageNum,
          pageSize,
          orderBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DownloadHistoryApi - factory interface
 * @export
 */
export const DownloadHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DownloadHistoryApiFp(configuration);
  return {
    /**
     * Get download history rating
     * @summary Get download history rating info
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateDownloadFrom] filter players by date download rating
     * @param {string} [dateDownloadTo] filter players by date download rating
     * @param {number} [userCountTo] filter players by user count download rating
     * @param {number} [userCountFrom] filter players by user count download rating
     * @param {boolean} [statusDownloadRating] filter players by status download rating
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_count&#x60;/&#x60;success&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadHistoryRating(
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateDownloadFrom?: string,
      dateDownloadTo?: string,
      userCountTo?: number,
      userCountFrom?: number,
      statusDownloadRating?: boolean,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<GetDownloadRating> {
      return localVarFp
        .getDownloadHistoryRating(
          firstName,
          lastName,
          dateDownloadFrom,
          dateDownloadTo,
          userCountTo,
          userCountFrom,
          statusDownloadRating,
          pageNum,
          pageSize,
          orderBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getDownloadHistoryRating operation in DownloadHistoryApi.
 * @export
 * @interface DownloadHistoryApiGetDownloadHistoryRatingRequest
 */
export interface DownloadHistoryApiGetDownloadHistoryRatingRequest {
  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly lastName?: Array<string>;

  /**
   * filter players by date download rating
   * @type {string}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly dateDownloadFrom?: string;

  /**
   * filter players by date download rating
   * @type {string}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly dateDownloadTo?: string;

  /**
   * filter players by user count download rating
   * @type {number}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly userCountTo?: number;

  /**
   * filter players by user count download rating
   * @type {number}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly userCountFrom?: number;

  /**
   * filter players by status download rating
   * @type {boolean}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly statusDownloadRating?: boolean;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly pageSize?: number;

  /**
   * sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;download_date&#x60;/&#x60;user_count&#x60;/&#x60;success&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof DownloadHistoryApiGetDownloadHistoryRating
   */
  readonly orderBy?: Array<string>;
}

/**
 * DownloadHistoryApi - object-oriented interface
 * @export
 * @class DownloadHistoryApi
 * @extends {BaseAPI}
 */
export class DownloadHistoryApi extends BaseAPI {
  /**
   * Get download history rating
   * @summary Get download history rating info
   * @param {DownloadHistoryApiGetDownloadHistoryRatingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DownloadHistoryApi
   */
  public getDownloadHistoryRating(
    requestParameters: DownloadHistoryApiGetDownloadHistoryRatingRequest = {},
    options?: any,
  ) {
    return DownloadHistoryApiFp(this.configuration)
      .getDownloadHistoryRating(
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.dateDownloadFrom,
        requestParameters.dateDownloadTo,
        requestParameters.userCountTo,
        requestParameters.userCountFrom,
        requestParameters.statusDownloadRating,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FaqApi - axios parameter creator
 * @export
 */
export const FaqApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all the FAQ items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaq: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/faq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update all the FAQ items
     * @param {BulkUpdFaqReq} body FAQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFaqBulkUpd: async (
      body: BulkUpdFaqReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postFaqBulkUpd", "body", body);
      const localVarPath = `/api/v1/faq/bulkUpdate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FaqApi - functional programming interface
 * @export
 */
export const FaqApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FaqApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all the FAQ items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFaq(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFaq(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update all the FAQ items
     * @param {BulkUpdFaqReq} body FAQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postFaqBulkUpd(
      body: BulkUpdFaqReq,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postFaqBulkUpd(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FaqApi - factory interface
 * @export
 */
export const FaqApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FaqApiFp(configuration);
  return {
    /**
     *
     * @summary Get all the FAQ items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFaq(options?: any): AxiosPromise<FaqRes> {
      return localVarFp
        .getFaq(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update all the FAQ items
     * @param {BulkUpdFaqReq} body FAQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFaqBulkUpd(body: BulkUpdFaqReq, options?: any): AxiosPromise<FaqRes> {
      return localVarFp
        .postFaqBulkUpd(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for postFaqBulkUpd operation in FaqApi.
 * @export
 * @interface FaqApiPostFaqBulkUpdRequest
 */
export interface FaqApiPostFaqBulkUpdRequest {
  /**
   * FAQ
   * @type {BulkUpdFaqReq}
   * @memberof FaqApiPostFaqBulkUpd
   */
  readonly body: BulkUpdFaqReq;
}

/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
export class FaqApi extends BaseAPI {
  /**
   *
   * @summary Get all the FAQ items
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaqApi
   */
  public getFaq(options?: any) {
    return FaqApiFp(this.configuration)
      .getFaq(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update all the FAQ items
   * @param {FaqApiPostFaqBulkUpdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaqApi
   */
  public postFaqBulkUpd(
    requestParameters: FaqApiPostFaqBulkUpdRequest,
    options?: any,
  ) {
    return FaqApiFp(this.configuration)
      .postFaqBulkUpd(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FineArchiveApi - axios parameter creator
 * @export
 */
export const FineArchiveApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Archive Fine
     * @param {ArchiveFineDto} fine Archive fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivePlayerFines: async (
      fine: ArchiveFineDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fine' is not null or undefined
      assertParamExists("archivePlayerFines", "fine", fine);
      const localVarPath = `/api/v1/admin/settings/admFinesArchive`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fine,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FineArchiveApi - functional programming interface
 * @export
 */
export const FineArchiveApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FineArchiveApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Archive Fine
     * @param {ArchiveFineDto} fine Archive fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivePlayerFines(
      fine: ArchiveFineDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FineDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.archivePlayerFines(fine, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FineArchiveApi - factory interface
 * @export
 */
export const FineArchiveApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FineArchiveApiFp(configuration);
  return {
    /**
     *
     * @summary Archive Fine
     * @param {ArchiveFineDto} fine Archive fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivePlayerFines(
      fine: ArchiveFineDto,
      options?: any,
    ): AxiosPromise<FineDto> {
      return localVarFp
        .archivePlayerFines(fine, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for archivePlayerFines operation in FineArchiveApi.
 * @export
 * @interface FineArchiveApiArchivePlayerFinesRequest
 */
export interface FineArchiveApiArchivePlayerFinesRequest {
  /**
   * Archive fines
   * @type {ArchiveFineDto}
   * @memberof FineArchiveApiArchivePlayerFines
   */
  readonly fine: ArchiveFineDto;
}

/**
 * FineArchiveApi - object-oriented interface
 * @export
 * @class FineArchiveApi
 * @extends {BaseAPI}
 */
export class FineArchiveApi extends BaseAPI {
  /**
   *
   * @summary Archive Fine
   * @param {FineArchiveApiArchivePlayerFinesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FineArchiveApi
   */
  public archivePlayerFines(
    requestParameters: FineArchiveApiArchivePlayerFinesRequest,
    options?: any,
  ) {
    return FineArchiveApiFp(this.configuration)
      .archivePlayerFines(requestParameters.fine, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FinesApi - axios parameter creator
 * @export
 */
export const FinesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Player Fine
     * @param {number} [periodFrom] Period of fine from
     * @param {number} [periodTo] Period of fine to
     * @param {string} [firstnameCreator] First name creator
     * @param {string} [lastnameCreator] Last name creator
     * @param {number} [removableRatingFrom] Removable ratings from
     * @param {number} [removableRatingTo] Removable ratings to
     * @param {string} [dateOfCreateFrom]
     * @param {string} [dateOfCreateTo]
     * @param {Array<string>} [competence] Competence name
     * @param {string} [orderBy] sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {boolean} [archived] Archived
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerFines: async (
      periodFrom?: number,
      periodTo?: number,
      firstnameCreator?: string,
      lastnameCreator?: string,
      removableRatingFrom?: number,
      removableRatingTo?: number,
      dateOfCreateFrom?: string,
      dateOfCreateTo?: string,
      competence?: Array<string>,
      orderBy?: string,
      archived?: boolean,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/settings/admFines`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (periodFrom !== undefined) {
        localVarQueryParameter["periodFrom"] = periodFrom;
      }

      if (periodTo !== undefined) {
        localVarQueryParameter["periodTo"] = periodTo;
      }

      if (firstnameCreator !== undefined) {
        localVarQueryParameter["firstnameCreator"] = firstnameCreator;
      }

      if (lastnameCreator !== undefined) {
        localVarQueryParameter["lastnameCreator"] = lastnameCreator;
      }

      if (removableRatingFrom !== undefined) {
        localVarQueryParameter["removableRatingFrom"] = removableRatingFrom;
      }

      if (removableRatingTo !== undefined) {
        localVarQueryParameter["removableRatingTo"] = removableRatingTo;
      }

      if (dateOfCreateFrom !== undefined) {
        localVarQueryParameter["dateOfCreateFrom"] =
          (dateOfCreateFrom as any) instanceof Date
            ? (dateOfCreateFrom as any).toISOString()
            : dateOfCreateFrom;
      }

      if (dateOfCreateTo !== undefined) {
        localVarQueryParameter["dateOfCreateTo"] =
          (dateOfCreateTo as any) instanceof Date
            ? (dateOfCreateTo as any).toISOString()
            : dateOfCreateTo;
      }

      if (competence) {
        localVarQueryParameter["competence"] = competence;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (archived !== undefined) {
        localVarQueryParameter["archived"] = archived;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Fines
     * @param {FinesReqDto} fines Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayerFines: async (
      fines: FinesReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fines' is not null or undefined
      assertParamExists("postPlayerFines", "fines", fines);
      const localVarPath = `/api/v1/admin/settings/admFines`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fines,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FinesApi - functional programming interface
 * @export
 */
export const FinesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FinesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Player Fine
     * @param {number} [periodFrom] Period of fine from
     * @param {number} [periodTo] Period of fine to
     * @param {string} [firstnameCreator] First name creator
     * @param {string} [lastnameCreator] Last name creator
     * @param {number} [removableRatingFrom] Removable ratings from
     * @param {number} [removableRatingTo] Removable ratings to
     * @param {string} [dateOfCreateFrom]
     * @param {string} [dateOfCreateTo]
     * @param {Array<string>} [competence] Competence name
     * @param {string} [orderBy] sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {boolean} [archived] Archived
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerFines(
      periodFrom?: number,
      periodTo?: number,
      firstnameCreator?: string,
      lastnameCreator?: string,
      removableRatingFrom?: number,
      removableRatingTo?: number,
      dateOfCreateFrom?: string,
      dateOfCreateTo?: string,
      competence?: Array<string>,
      orderBy?: string,
      archived?: boolean,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinesDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerFines(
        periodFrom,
        periodTo,
        firstnameCreator,
        lastnameCreator,
        removableRatingFrom,
        removableRatingTo,
        dateOfCreateFrom,
        dateOfCreateTo,
        competence,
        orderBy,
        archived,
        pageNum,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Fines
     * @param {FinesReqDto} fines Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlayerFines(
      fines: FinesReqDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinesReqDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postPlayerFines(
        fines,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FinesApi - factory interface
 * @export
 */
export const FinesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FinesApiFp(configuration);
  return {
    /**
     *
     * @summary Get Player Fine
     * @param {number} [periodFrom] Period of fine from
     * @param {number} [periodTo] Period of fine to
     * @param {string} [firstnameCreator] First name creator
     * @param {string} [lastnameCreator] Last name creator
     * @param {number} [removableRatingFrom] Removable ratings from
     * @param {number} [removableRatingTo] Removable ratings to
     * @param {string} [dateOfCreateFrom]
     * @param {string} [dateOfCreateTo]
     * @param {Array<string>} [competence] Competence name
     * @param {string} [orderBy] sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {boolean} [archived] Archived
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerFines(
      periodFrom?: number,
      periodTo?: number,
      firstnameCreator?: string,
      lastnameCreator?: string,
      removableRatingFrom?: number,
      removableRatingTo?: number,
      dateOfCreateFrom?: string,
      dateOfCreateTo?: string,
      competence?: Array<string>,
      orderBy?: string,
      archived?: boolean,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<FinesDto> {
      return localVarFp
        .getPlayerFines(
          periodFrom,
          periodTo,
          firstnameCreator,
          lastnameCreator,
          removableRatingFrom,
          removableRatingTo,
          dateOfCreateFrom,
          dateOfCreateTo,
          competence,
          orderBy,
          archived,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Fines
     * @param {FinesReqDto} fines Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayerFines(
      fines: FinesReqDto,
      options?: any,
    ): AxiosPromise<FinesReqDto> {
      return localVarFp
        .postPlayerFines(fines, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getPlayerFines operation in FinesApi.
 * @export
 * @interface FinesApiGetPlayerFinesRequest
 */
export interface FinesApiGetPlayerFinesRequest {
  /**
   * Period of fine from
   * @type {number}
   * @memberof FinesApiGetPlayerFines
   */
  readonly periodFrom?: number;

  /**
   * Period of fine to
   * @type {number}
   * @memberof FinesApiGetPlayerFines
   */
  readonly periodTo?: number;

  /**
   * First name creator
   * @type {string}
   * @memberof FinesApiGetPlayerFines
   */
  readonly firstnameCreator?: string;

  /**
   * Last name creator
   * @type {string}
   * @memberof FinesApiGetPlayerFines
   */
  readonly lastnameCreator?: string;

  /**
   * Removable ratings from
   * @type {number}
   * @memberof FinesApiGetPlayerFines
   */
  readonly removableRatingFrom?: number;

  /**
   * Removable ratings to
   * @type {number}
   * @memberof FinesApiGetPlayerFines
   */
  readonly removableRatingTo?: number;

  /**
   *
   * @type {string}
   * @memberof FinesApiGetPlayerFines
   */
  readonly dateOfCreateFrom?: string;

  /**
   *
   * @type {string}
   * @memberof FinesApiGetPlayerFines
   */
  readonly dateOfCreateTo?: string;

  /**
   * Competence name
   * @type {Array<string>}
   * @memberof FinesApiGetPlayerFines
   */
  readonly competence?: Array<string>;

  /**
   * sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof FinesApiGetPlayerFines
   */
  readonly orderBy?: string;

  /**
   * Archived
   * @type {boolean}
   * @memberof FinesApiGetPlayerFines
   */
  readonly archived?: boolean;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof FinesApiGetPlayerFines
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof FinesApiGetPlayerFines
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for postPlayerFines operation in FinesApi.
 * @export
 * @interface FinesApiPostPlayerFinesRequest
 */
export interface FinesApiPostPlayerFinesRequest {
  /**
   * Fines
   * @type {FinesReqDto}
   * @memberof FinesApiPostPlayerFines
   */
  readonly fines: FinesReqDto;
}

/**
 * FinesApi - object-oriented interface
 * @export
 * @class FinesApi
 * @extends {BaseAPI}
 */
export class FinesApi extends BaseAPI {
  /**
   *
   * @summary Get Player Fine
   * @param {FinesApiGetPlayerFinesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinesApi
   */
  public getPlayerFines(
    requestParameters: FinesApiGetPlayerFinesRequest = {},
    options?: any,
  ) {
    return FinesApiFp(this.configuration)
      .getPlayerFines(
        requestParameters.periodFrom,
        requestParameters.periodTo,
        requestParameters.firstnameCreator,
        requestParameters.lastnameCreator,
        requestParameters.removableRatingFrom,
        requestParameters.removableRatingTo,
        requestParameters.dateOfCreateFrom,
        requestParameters.dateOfCreateTo,
        requestParameters.competence,
        requestParameters.orderBy,
        requestParameters.archived,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Fines
   * @param {FinesApiPostPlayerFinesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinesApi
   */
  public postPlayerFines(
    requestParameters: FinesApiPostPlayerFinesRequest,
    options?: any,
  ) {
    return FinesApiFp(this.configuration)
      .postPlayerFines(requestParameters.fines, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * KeycloakApi - axios parameter creator
 * @export
 */
export const KeycloakApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Block/Unblock Player
     * @param {BlockPlayerReq} body Player block or unblock
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockUnblockPlayer: async (
      body: BlockPlayerReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("blockUnblockPlayer", "body", body);
      const localVarPath = `/api/v1/players/block-unblock`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Find players with specified fields by AND clause
     * @summary Get Large Players rating info with Stream
     * @param {Array<string>} [name] filter players by firstName OR lastName
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {number} [gPlaceFrom] filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gPlaceTo] filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gRateFrom] filter players by global rate, from, min 0, max 10
     * @param {number} [gRateTo] filter players by global rate, To, min 0, max 10
     * @param {number} [analyticsFrom] filter players by competence, from, min 0, max 10
     * @param {number} [analyticsTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandFrom] filter players by competence, from, min 0, max 10
     * @param {number} [commandTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationFrom] filter players by competence, from, min 0, max 10
     * @param {number} [communicationTo] filter players by competence, to, min 0, max 10
     * @param {string} [createdFrom] filter players by createdAt, from
     * @param {string} [createdTo] filter players by createdAt, to
     * @param {string} [lastPlayFrom] filter players by lastPlay, from
     * @param {string} [lastPlayTo] filter players by lastPlay, to
     * @param {string} [lastActivityFrom] filter players by lastActivity, from
     * @param {string} [lastActivityTo] filter players by lastActivity, to
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {string} [dateOfGettingAnalyticsLvl05From] filter players by date_of_getting_analytics_lvl_0_5, from
     * @param {string} [dateOfGettingAnalyticsLvl05To] filter players by date_of_getting_analytics_lvl_0_5, to
     * @param {string} [dateOfGettingAnalyticsLvl1From] filter players by date_of_getting_analytics_lvl_1, from
     * @param {string} [dateOfGettingAnalyticsLvl1To] filter players by date_of_getting_analytics_lvl_1, to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRatingStream: async (
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ratings/stream`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (name) {
        localVarQueryParameter["name"] = name;
      }

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (gPlaceFrom !== undefined) {
        localVarQueryParameter["gPlaceFrom"] = gPlaceFrom;
      }

      if (gPlaceTo !== undefined) {
        localVarQueryParameter["gPlaceTo"] = gPlaceTo;
      }

      if (gRateFrom !== undefined) {
        localVarQueryParameter["gRateFrom"] = gRateFrom;
      }

      if (gRateTo !== undefined) {
        localVarQueryParameter["gRateTo"] = gRateTo;
      }

      if (analyticsFrom !== undefined) {
        localVarQueryParameter["analyticsFrom"] = analyticsFrom;
      }

      if (analyticsTo !== undefined) {
        localVarQueryParameter["analyticsTo"] = analyticsTo;
      }

      if (commandFrom !== undefined) {
        localVarQueryParameter["commandFrom"] = commandFrom;
      }

      if (commandTo !== undefined) {
        localVarQueryParameter["commandTo"] = commandTo;
      }

      if (communicationFrom !== undefined) {
        localVarQueryParameter["communicationFrom"] = communicationFrom;
      }

      if (communicationTo !== undefined) {
        localVarQueryParameter["communicationTo"] = communicationTo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] =
          (createdFrom as any) instanceof Date
            ? (createdFrom as any).toISOString()
            : createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] =
          (createdTo as any) instanceof Date
            ? (createdTo as any).toISOString()
            : createdTo;
      }

      if (lastPlayFrom !== undefined) {
        localVarQueryParameter["lastPlayFrom"] =
          (lastPlayFrom as any) instanceof Date
            ? (lastPlayFrom as any).toISOString()
            : lastPlayFrom;
      }

      if (lastPlayTo !== undefined) {
        localVarQueryParameter["lastPlayTo"] =
          (lastPlayTo as any) instanceof Date
            ? (lastPlayTo as any).toISOString()
            : lastPlayTo;
      }

      if (lastActivityFrom !== undefined) {
        localVarQueryParameter["lastActivityFrom"] =
          (lastActivityFrom as any) instanceof Date
            ? (lastActivityFrom as any).toISOString()
            : lastActivityFrom;
      }

      if (lastActivityTo !== undefined) {
        localVarQueryParameter["lastActivityTo"] =
          (lastActivityTo as any) instanceof Date
            ? (lastActivityTo as any).toISOString()
            : lastActivityTo;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      if (dateOfGettingAnalyticsLvl05From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5From"] =
          (dateOfGettingAnalyticsLvl05From as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl05From as any).toISOString()
            : dateOfGettingAnalyticsLvl05From;
      }

      if (dateOfGettingAnalyticsLvl05To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5To"] =
          (dateOfGettingAnalyticsLvl05To as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl05To as any).toISOString()
            : dateOfGettingAnalyticsLvl05To;
      }

      if (dateOfGettingAnalyticsLvl1From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1From"] =
          (dateOfGettingAnalyticsLvl1From as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl1From as any).toISOString()
            : dateOfGettingAnalyticsLvl1From;
      }

      if (dateOfGettingAnalyticsLvl1To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1To"] =
          (dateOfGettingAnalyticsLvl1To as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl1To as any).toISOString()
            : dateOfGettingAnalyticsLvl1To;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create assessment history
     * @param {AssessmentHistoryCreateDto} assessmentHistoryCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessmentHistory: async (
      assessmentHistoryCreate: AssessmentHistoryCreateDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentHistoryCreate' is not null or undefined
      assertParamExists(
        "postAssessmentHistory",
        "assessmentHistoryCreate",
        assessmentHistoryCreate,
      );
      const localVarPath = `/api/v1/assessments/download-history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        assessmentHistoryCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create/Update Player
     * @param {AddPlayerReq} body Player to Create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayer: async (
      body: AddPlayerReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postPlayer", "body", body);
      const localVarPath = `/api/v1/players`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Player Info
     * @param {PlayersBulkDelReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersBulkDel: async (
      body: PlayersBulkDelReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postPlayersBulkDel", "body", body);
      const localVarPath = `/api/v1/players/bulkDel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update role player
     * @param {string} pId Player ID
     * @param {PlayerRoles} body Role for update player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postRole: async (
      pId: string,
      body: PlayerRoles,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("postRole", "pId", pId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postRole", "body", body);
      const localVarPath = `/api/v1/players/{pId}/role`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * KeycloakApi - functional programming interface
 * @export
 */
export const KeycloakApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = KeycloakApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Block/Unblock Player
     * @param {BlockPlayerReq} body Player block or unblock
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockUnblockPlayer(
      body: BlockPlayerReq,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.blockUnblockPlayer(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Find players with specified fields by AND clause
     * @summary Get Large Players rating info with Stream
     * @param {Array<string>} [name] filter players by firstName OR lastName
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {number} [gPlaceFrom] filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gPlaceTo] filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gRateFrom] filter players by global rate, from, min 0, max 10
     * @param {number} [gRateTo] filter players by global rate, To, min 0, max 10
     * @param {number} [analyticsFrom] filter players by competence, from, min 0, max 10
     * @param {number} [analyticsTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandFrom] filter players by competence, from, min 0, max 10
     * @param {number} [commandTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationFrom] filter players by competence, from, min 0, max 10
     * @param {number} [communicationTo] filter players by competence, to, min 0, max 10
     * @param {string} [createdFrom] filter players by createdAt, from
     * @param {string} [createdTo] filter players by createdAt, to
     * @param {string} [lastPlayFrom] filter players by lastPlay, from
     * @param {string} [lastPlayTo] filter players by lastPlay, to
     * @param {string} [lastActivityFrom] filter players by lastActivity, from
     * @param {string} [lastActivityTo] filter players by lastActivity, to
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {string} [dateOfGettingAnalyticsLvl05From] filter players by date_of_getting_analytics_lvl_0_5, from
     * @param {string} [dateOfGettingAnalyticsLvl05To] filter players by date_of_getting_analytics_lvl_0_5, to
     * @param {string} [dateOfGettingAnalyticsLvl1From] filter players by date_of_getting_analytics_lvl_1, from
     * @param {string} [dateOfGettingAnalyticsLvl1To] filter players by date_of_getting_analytics_lvl_1, to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRatingStream(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRatingStream(
        name,
        firstName,
        lastName,
        phone,
        gPlaceFrom,
        gPlaceTo,
        gRateFrom,
        gRateTo,
        analyticsFrom,
        analyticsTo,
        commandFrom,
        commandTo,
        communicationFrom,
        communicationTo,
        createdFrom,
        createdTo,
        lastPlayFrom,
        lastPlayTo,
        lastActivityFrom,
        lastActivityTo,
        desiredStudyPlace,
        dateOfGettingAnalyticsLvl05From,
        dateOfGettingAnalyticsLvl05To,
        dateOfGettingAnalyticsLvl1From,
        dateOfGettingAnalyticsLvl1To,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create assessment history
     * @param {AssessmentHistoryCreateDto} assessmentHistoryCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAssessmentHistory(
      assessmentHistoryCreate: AssessmentHistoryCreateDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentDownloadHistoryDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAssessmentHistory(
          assessmentHistoryCreate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create/Update Player
     * @param {AddPlayerReq} body Player to Create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlayer(
      body: AddPlayerReq,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postPlayer(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete Player Info
     * @param {PlayersBulkDelReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlayersBulkDel(
      body: PlayersBulkDelReq,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPlayersBulkDel(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update role player
     * @param {string} pId Player ID
     * @param {PlayerRoles} body Role for update player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postRole(
      pId: string,
      body: PlayerRoles,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postRole(
        pId,
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * KeycloakApi - factory interface
 * @export
 */
export const KeycloakApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = KeycloakApiFp(configuration);
  return {
    /**
     *
     * @summary Block/Unblock Player
     * @param {BlockPlayerReq} body Player block or unblock
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockUnblockPlayer(
      body: BlockPlayerReq,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .blockUnblockPlayer(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Find players with specified fields by AND clause
     * @summary Get Large Players rating info with Stream
     * @param {Array<string>} [name] filter players by firstName OR lastName
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {number} [gPlaceFrom] filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gPlaceTo] filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gRateFrom] filter players by global rate, from, min 0, max 10
     * @param {number} [gRateTo] filter players by global rate, To, min 0, max 10
     * @param {number} [analyticsFrom] filter players by competence, from, min 0, max 10
     * @param {number} [analyticsTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandFrom] filter players by competence, from, min 0, max 10
     * @param {number} [commandTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationFrom] filter players by competence, from, min 0, max 10
     * @param {number} [communicationTo] filter players by competence, to, min 0, max 10
     * @param {string} [createdFrom] filter players by createdAt, from
     * @param {string} [createdTo] filter players by createdAt, to
     * @param {string} [lastPlayFrom] filter players by lastPlay, from
     * @param {string} [lastPlayTo] filter players by lastPlay, to
     * @param {string} [lastActivityFrom] filter players by lastActivity, from
     * @param {string} [lastActivityTo] filter players by lastActivity, to
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {string} [dateOfGettingAnalyticsLvl05From] filter players by date_of_getting_analytics_lvl_0_5, from
     * @param {string} [dateOfGettingAnalyticsLvl05To] filter players by date_of_getting_analytics_lvl_0_5, to
     * @param {string} [dateOfGettingAnalyticsLvl1From] filter players by date_of_getting_analytics_lvl_1, from
     * @param {string} [dateOfGettingAnalyticsLvl1To] filter players by date_of_getting_analytics_lvl_1, to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRatingStream(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .getRatingStream(
          name,
          firstName,
          lastName,
          phone,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          commandFrom,
          commandTo,
          communicationFrom,
          communicationTo,
          createdFrom,
          createdTo,
          lastPlayFrom,
          lastPlayTo,
          lastActivityFrom,
          lastActivityTo,
          desiredStudyPlace,
          dateOfGettingAnalyticsLvl05From,
          dateOfGettingAnalyticsLvl05To,
          dateOfGettingAnalyticsLvl1From,
          dateOfGettingAnalyticsLvl1To,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create assessment history
     * @param {AssessmentHistoryCreateDto} assessmentHistoryCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessmentHistory(
      assessmentHistoryCreate: AssessmentHistoryCreateDto,
      options?: any,
    ): AxiosPromise<AssessmentDownloadHistoryDto> {
      return localVarFp
        .postAssessmentHistory(assessmentHistoryCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create/Update Player
     * @param {AddPlayerReq} body Player to Create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayer(body: AddPlayerReq, options?: any): AxiosPromise<void> {
      return localVarFp
        .postPlayer(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Player Info
     * @param {PlayersBulkDelReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersBulkDel(
      body: PlayersBulkDelReq,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postPlayersBulkDel(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update role player
     * @param {string} pId Player ID
     * @param {PlayerRoles} body Role for update player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postRole(
      pId: string,
      body: PlayerRoles,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postRole(pId, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for blockUnblockPlayer operation in KeycloakApi.
 * @export
 * @interface KeycloakApiBlockUnblockPlayerRequest
 */
export interface KeycloakApiBlockUnblockPlayerRequest {
  /**
   * Player block or unblock
   * @type {BlockPlayerReq}
   * @memberof KeycloakApiBlockUnblockPlayer
   */
  readonly body: BlockPlayerReq;
}

/**
 * Request parameters for getRatingStream operation in KeycloakApi.
 * @export
 * @interface KeycloakApiGetRatingStreamRequest
 */
export interface KeycloakApiGetRatingStreamRequest {
  /**
   * filter players by firstName OR lastName
   * @type {Array<string>}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly name?: Array<string>;

  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly lastName?: Array<string>;

  /**
   * filter players by phone
   * @type {Array<string>}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly phone?: Array<string>;

  /**
   * filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly gPlaceFrom?: number;

  /**
   * filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly gPlaceTo?: number;

  /**
   * filter players by global rate, from, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly gRateFrom?: number;

  /**
   * filter players by global rate, To, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly gRateTo?: number;

  /**
   * filter players by competence, from, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly analyticsFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly analyticsTo?: number;

  /**
   * filter players by competence, from, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly commandFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly commandTo?: number;

  /**
   * filter players by competence, from, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly communicationFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly communicationTo?: number;

  /**
   * filter players by createdAt, from
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly createdFrom?: string;

  /**
   * filter players by createdAt, to
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly createdTo?: string;

  /**
   * filter players by lastPlay, from
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly lastPlayFrom?: string;

  /**
   * filter players by lastPlay, to
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly lastPlayTo?: string;

  /**
   * filter players by lastActivity, from
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly lastActivityFrom?: string;

  /**
   * filter players by lastActivity, to
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly lastActivityTo?: string;

  /**
   * filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly desiredStudyPlace?: Array<string>;

  /**
   * filter players by date_of_getting_analytics_lvl_0_5, from
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly dateOfGettingAnalyticsLvl05From?: string;

  /**
   * filter players by date_of_getting_analytics_lvl_0_5, to
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly dateOfGettingAnalyticsLvl05To?: string;

  /**
   * filter players by date_of_getting_analytics_lvl_1, from
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly dateOfGettingAnalyticsLvl1From?: string;

  /**
   * filter players by date_of_getting_analytics_lvl_1, to
   * @type {string}
   * @memberof KeycloakApiGetRatingStream
   */
  readonly dateOfGettingAnalyticsLvl1To?: string;
}

/**
 * Request parameters for postAssessmentHistory operation in KeycloakApi.
 * @export
 * @interface KeycloakApiPostAssessmentHistoryRequest
 */
export interface KeycloakApiPostAssessmentHistoryRequest {
  /**
   *
   * @type {AssessmentHistoryCreateDto}
   * @memberof KeycloakApiPostAssessmentHistory
   */
  readonly assessmentHistoryCreate: AssessmentHistoryCreateDto;
}

/**
 * Request parameters for postPlayer operation in KeycloakApi.
 * @export
 * @interface KeycloakApiPostPlayerRequest
 */
export interface KeycloakApiPostPlayerRequest {
  /**
   * Player to Create
   * @type {AddPlayerReq}
   * @memberof KeycloakApiPostPlayer
   */
  readonly body: AddPlayerReq;
}

/**
 * Request parameters for postPlayersBulkDel operation in KeycloakApi.
 * @export
 * @interface KeycloakApiPostPlayersBulkDelRequest
 */
export interface KeycloakApiPostPlayersBulkDelRequest {
  /**
   *
   * @type {PlayersBulkDelReq}
   * @memberof KeycloakApiPostPlayersBulkDel
   */
  readonly body: PlayersBulkDelReq;
}

/**
 * Request parameters for postRole operation in KeycloakApi.
 * @export
 * @interface KeycloakApiPostRoleRequest
 */
export interface KeycloakApiPostRoleRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof KeycloakApiPostRole
   */
  readonly pId: string;

  /**
   * Role for update player
   * @type {PlayerRoles}
   * @memberof KeycloakApiPostRole
   */
  readonly body: PlayerRoles;
}

/**
 * KeycloakApi - object-oriented interface
 * @export
 * @class KeycloakApi
 * @extends {BaseAPI}
 */
export class KeycloakApi extends BaseAPI {
  /**
   *
   * @summary Block/Unblock Player
   * @param {KeycloakApiBlockUnblockPlayerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KeycloakApi
   */
  public blockUnblockPlayer(
    requestParameters: KeycloakApiBlockUnblockPlayerRequest,
    options?: any,
  ) {
    return KeycloakApiFp(this.configuration)
      .blockUnblockPlayer(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Find players with specified fields by AND clause
   * @summary Get Large Players rating info with Stream
   * @param {KeycloakApiGetRatingStreamRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KeycloakApi
   */
  public getRatingStream(
    requestParameters: KeycloakApiGetRatingStreamRequest = {},
    options?: any,
  ) {
    return KeycloakApiFp(this.configuration)
      .getRatingStream(
        requestParameters.name,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phone,
        requestParameters.gPlaceFrom,
        requestParameters.gPlaceTo,
        requestParameters.gRateFrom,
        requestParameters.gRateTo,
        requestParameters.analyticsFrom,
        requestParameters.analyticsTo,
        requestParameters.commandFrom,
        requestParameters.commandTo,
        requestParameters.communicationFrom,
        requestParameters.communicationTo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.lastPlayFrom,
        requestParameters.lastPlayTo,
        requestParameters.lastActivityFrom,
        requestParameters.lastActivityTo,
        requestParameters.desiredStudyPlace,
        requestParameters.dateOfGettingAnalyticsLvl05From,
        requestParameters.dateOfGettingAnalyticsLvl05To,
        requestParameters.dateOfGettingAnalyticsLvl1From,
        requestParameters.dateOfGettingAnalyticsLvl1To,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create assessment history
   * @param {KeycloakApiPostAssessmentHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KeycloakApi
   */
  public postAssessmentHistory(
    requestParameters: KeycloakApiPostAssessmentHistoryRequest,
    options?: any,
  ) {
    return KeycloakApiFp(this.configuration)
      .postAssessmentHistory(requestParameters.assessmentHistoryCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create/Update Player
   * @param {KeycloakApiPostPlayerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KeycloakApi
   */
  public postPlayer(
    requestParameters: KeycloakApiPostPlayerRequest,
    options?: any,
  ) {
    return KeycloakApiFp(this.configuration)
      .postPlayer(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Player Info
   * @param {KeycloakApiPostPlayersBulkDelRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KeycloakApi
   */
  public postPlayersBulkDel(
    requestParameters: KeycloakApiPostPlayersBulkDelRequest,
    options?: any,
  ) {
    return KeycloakApiFp(this.configuration)
      .postPlayersBulkDel(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update role player
   * @param {KeycloakApiPostRoleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KeycloakApi
   */
  public postRole(
    requestParameters: KeycloakApiPostRoleRequest,
    options?: any,
  ) {
    return KeycloakApiFp(this.configuration)
      .postRole(requestParameters.pId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ManagerApi - axios parameter creator
 * @export
 */
export const ManagerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Manager
     * @param {string} mid Manager UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delManager: async (
      mid: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mid' is not null or undefined
      assertParamExists("delManager", "mid", mid);
      const localVarPath = `/api/v1/managers/{mid}`.replace(
        `{${"mid"}}`,
        encodeURIComponent(String(mid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Manager
     * @param {string} mid Manager UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManager: async (
      mid: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mid' is not null or undefined
      assertParamExists("getManager", "mid", mid);
      const localVarPath = `/api/v1/managers/{mid}`.replace(
        `{${"mid"}}`,
        encodeURIComponent(String(mid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all the Manager items
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManagers: async (
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/managers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create manager
     * @param {Manager} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postManager: async (
      body: Manager,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postManager", "body", body);
      const localVarPath = `/api/v1/managers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Manager
     * @param {string} mid Manager UUID
     * @param {Manager} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putManager: async (
      mid: string,
      body: Manager,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mid' is not null or undefined
      assertParamExists("putManager", "mid", mid);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("putManager", "body", body);
      const localVarPath = `/api/v1/managers/{mid}`.replace(
        `{${"mid"}}`,
        encodeURIComponent(String(mid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManagerApi - functional programming interface
 * @export
 */
export const ManagerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ManagerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Manager
     * @param {string} mid Manager UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delManager(
      mid: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Manager>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delManager(
        mid,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Manager
     * @param {string} mid Manager UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManager(
      mid: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Manager>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getManager(
        mid,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all the Manager items
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getManagers(
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagersRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getManagers(
        pageNum,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create manager
     * @param {Manager} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postManager(
      body: Manager,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Manager>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postManager(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Manager
     * @param {string} mid Manager UUID
     * @param {Manager} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putManager(
      mid: string,
      body: Manager,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Manager>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putManager(
        mid,
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ManagerApi - factory interface
 * @export
 */
export const ManagerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ManagerApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Manager
     * @param {string} mid Manager UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delManager(mid: string, options?: any): AxiosPromise<Manager> {
      return localVarFp
        .delManager(mid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Manager
     * @param {string} mid Manager UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManager(mid: string, options?: any): AxiosPromise<Manager> {
      return localVarFp
        .getManager(mid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all the Manager items
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManagers(
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<ManagersRes> {
      return localVarFp
        .getManagers(pageNum, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create manager
     * @param {Manager} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postManager(body: Manager, options?: any): AxiosPromise<Manager> {
      return localVarFp
        .postManager(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Manager
     * @param {string} mid Manager UUID
     * @param {Manager} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putManager(
      mid: string,
      body: Manager,
      options?: any,
    ): AxiosPromise<Manager> {
      return localVarFp
        .putManager(mid, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delManager operation in ManagerApi.
 * @export
 * @interface ManagerApiDelManagerRequest
 */
export interface ManagerApiDelManagerRequest {
  /**
   * Manager UUID
   * @type {string}
   * @memberof ManagerApiDelManager
   */
  readonly mid: string;
}

/**
 * Request parameters for getManager operation in ManagerApi.
 * @export
 * @interface ManagerApiGetManagerRequest
 */
export interface ManagerApiGetManagerRequest {
  /**
   * Manager UUID
   * @type {string}
   * @memberof ManagerApiGetManager
   */
  readonly mid: string;
}

/**
 * Request parameters for getManagers operation in ManagerApi.
 * @export
 * @interface ManagerApiGetManagersRequest
 */
export interface ManagerApiGetManagersRequest {
  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof ManagerApiGetManagers
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof ManagerApiGetManagers
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for postManager operation in ManagerApi.
 * @export
 * @interface ManagerApiPostManagerRequest
 */
export interface ManagerApiPostManagerRequest {
  /**
   *
   * @type {Manager}
   * @memberof ManagerApiPostManager
   */
  readonly body: Manager;
}

/**
 * Request parameters for putManager operation in ManagerApi.
 * @export
 * @interface ManagerApiPutManagerRequest
 */
export interface ManagerApiPutManagerRequest {
  /**
   * Manager UUID
   * @type {string}
   * @memberof ManagerApiPutManager
   */
  readonly mid: string;

  /**
   *
   * @type {Manager}
   * @memberof ManagerApiPutManager
   */
  readonly body: Manager;
}

/**
 * ManagerApi - object-oriented interface
 * @export
 * @class ManagerApi
 * @extends {BaseAPI}
 */
export class ManagerApi extends BaseAPI {
  /**
   *
   * @summary Delete Manager
   * @param {ManagerApiDelManagerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagerApi
   */
  public delManager(
    requestParameters: ManagerApiDelManagerRequest,
    options?: any,
  ) {
    return ManagerApiFp(this.configuration)
      .delManager(requestParameters.mid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Manager
   * @param {ManagerApiGetManagerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagerApi
   */
  public getManager(
    requestParameters: ManagerApiGetManagerRequest,
    options?: any,
  ) {
    return ManagerApiFp(this.configuration)
      .getManager(requestParameters.mid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all the Manager items
   * @param {ManagerApiGetManagersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagerApi
   */
  public getManagers(
    requestParameters: ManagerApiGetManagersRequest = {},
    options?: any,
  ) {
    return ManagerApiFp(this.configuration)
      .getManagers(
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create manager
   * @param {ManagerApiPostManagerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagerApi
   */
  public postManager(
    requestParameters: ManagerApiPostManagerRequest,
    options?: any,
  ) {
    return ManagerApiFp(this.configuration)
      .postManager(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Manager
   * @param {ManagerApiPutManagerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagerApi
   */
  public putManager(
    requestParameters: ManagerApiPutManagerRequest,
    options?: any,
  ) {
    return ManagerApiFp(this.configuration)
      .putManager(requestParameters.mid, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Check user permission
     * @param {string} pId Player ID
     * @param {string} permissionName Permission Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkUserPermission: async (
      pId: string,
      permissionName: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("checkUserPermission", "pId", pId);
      // verify required parameter 'permissionName' is not null or undefined
      assertParamExists(
        "checkUserPermission",
        "permissionName",
        permissionName,
      );
      const localVarPath = `/api/v1/permissions/{pId}/{permission_name}`
        .replace(`{${"pId"}}`, encodeURIComponent(String(pId)))
        .replace(
          `{${"permission_name"}}`,
          encodeURIComponent(String(permissionName)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user permissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPermissions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/permissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user permissions
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermissions: async (
      pId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getUserPermissions", "pId", pId);
      const localVarPath = `/api/v1/permissions/{pId}`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Write user permissions
     * @param {string} pId Player ID
     * @param {PermissionsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeUserPermissions: async (
      pId: string,
      body: PermissionsDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("writeUserPermissions", "pId", pId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("writeUserPermissions", "body", body);
      const localVarPath = `/api/v1/permissions/{pId}`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PermissionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Check user permission
     * @param {string} pId Player ID
     * @param {string} permissionName Permission Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkUserPermission(
      pId: string,
      permissionName: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserHasPermissionDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkUserPermission(
          pId,
          permissionName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user permissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPermissions(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPermissions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user permissions
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserPermissions(
      pId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserPermissions(pId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Write user permissions
     * @param {string} pId Player ID
     * @param {PermissionsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async writeUserPermissions(
      pId: string,
      body: PermissionsDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.writeUserPermissions(
          pId,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PermissionsApiFp(configuration);
  return {
    /**
     *
     * @summary Check user permission
     * @param {string} pId Player ID
     * @param {string} permissionName Permission Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkUserPermission(
      pId: string,
      permissionName: string,
      options?: any,
    ): AxiosPromise<UserHasPermissionDto> {
      return localVarFp
        .checkUserPermission(pId, permissionName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user permissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPermissions(options?: any): AxiosPromise<PermissionsDto> {
      return localVarFp
        .getPermissions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user permissions
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermissions(
      pId: string,
      options?: any,
    ): AxiosPromise<PermissionsDto> {
      return localVarFp
        .getUserPermissions(pId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Write user permissions
     * @param {string} pId Player ID
     * @param {PermissionsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    writeUserPermissions(
      pId: string,
      body: PermissionsDto,
      options?: any,
    ): AxiosPromise<PermissionsDto> {
      return localVarFp
        .writeUserPermissions(pId, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkUserPermission operation in PermissionsApi.
 * @export
 * @interface PermissionsApiCheckUserPermissionRequest
 */
export interface PermissionsApiCheckUserPermissionRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PermissionsApiCheckUserPermission
   */
  readonly pId: string;

  /**
   * Permission Name
   * @type {string}
   * @memberof PermissionsApiCheckUserPermission
   */
  readonly permissionName: string;
}

/**
 * Request parameters for getUserPermissions operation in PermissionsApi.
 * @export
 * @interface PermissionsApiGetUserPermissionsRequest
 */
export interface PermissionsApiGetUserPermissionsRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PermissionsApiGetUserPermissions
   */
  readonly pId: string;
}

/**
 * Request parameters for writeUserPermissions operation in PermissionsApi.
 * @export
 * @interface PermissionsApiWriteUserPermissionsRequest
 */
export interface PermissionsApiWriteUserPermissionsRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PermissionsApiWriteUserPermissions
   */
  readonly pId: string;

  /**
   *
   * @type {PermissionsDto}
   * @memberof PermissionsApiWriteUserPermissions
   */
  readonly body: PermissionsDto;
}

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
  /**
   *
   * @summary Check user permission
   * @param {PermissionsApiCheckUserPermissionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PermissionsApi
   */
  public checkUserPermission(
    requestParameters: PermissionsApiCheckUserPermissionRequest,
    options?: any,
  ) {
    return PermissionsApiFp(this.configuration)
      .checkUserPermission(
        requestParameters.pId,
        requestParameters.permissionName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user permissions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PermissionsApi
   */
  public getPermissions(options?: any) {
    return PermissionsApiFp(this.configuration)
      .getPermissions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user permissions
   * @param {PermissionsApiGetUserPermissionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PermissionsApi
   */
  public getUserPermissions(
    requestParameters: PermissionsApiGetUserPermissionsRequest,
    options?: any,
  ) {
    return PermissionsApiFp(this.configuration)
      .getUserPermissions(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Write user permissions
   * @param {PermissionsApiWriteUserPermissionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PermissionsApi
   */
  public writeUserPermissions(
    requestParameters: PermissionsApiWriteUserPermissionsRequest,
    options?: any,
  ) {
    return PermissionsApiFp(this.configuration)
      .writeUserPermissions(
        requestParameters.pId,
        requestParameters.body,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlayerApi - axios parameter creator
 * @export
 */
export const PlayerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get player information for information system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForInformationSystem: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/player-information-system`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayerApi - functional programming interface
 * @export
 */
export const PlayerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlayerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get player information for information system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInfoForInformationSystem(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerInfoForInformationSystem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInfoForInformationSystem(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PlayerApi - factory interface
 * @export
 */
export const PlayerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlayerApiFp(configuration);
  return {
    /**
     *
     * @summary Get player information for information system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForInformationSystem(
      options?: any,
    ): AxiosPromise<PlayerInfoForInformationSystem> {
      return localVarFp
        .getInfoForInformationSystem(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PlayerApi - object-oriented interface
 * @export
 * @class PlayerApi
 * @extends {BaseAPI}
 */
export class PlayerApi extends BaseAPI {
  /**
   *
   * @summary Get player information for information system
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public getInfoForInformationSystem(options?: any) {
    return PlayerApiFp(this.configuration)
      .getInfoForInformationSystem(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlayerFineHistoryApi - axios parameter creator
 * @export
 */
export const PlayerFineHistoryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Player Fine History
     * @param {string} pId Player ID
     * @param {string} [dateOfIssueFrom]
     * @param {string} [dateOfIssueTo]
     * @param {string} [firstnameCreator] First name creator
     * @param {string} [lastnameCreator] Last name creator
     * @param {Array<string>} [competence] Competence name
     * @param {number} [removableRatingFrom] Removable ratings from
     * @param {number} [removableRatingTo] Removable ratings to
     * @param {number} [periodFrom] Period of fine from
     * @param {number} [periodTo] Period of fine to
     * @param {string} [orderBy] sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerFineHistory: async (
      pId: string,
      dateOfIssueFrom?: string,
      dateOfIssueTo?: string,
      firstnameCreator?: string,
      lastnameCreator?: string,
      competence?: Array<string>,
      removableRatingFrom?: number,
      removableRatingTo?: number,
      periodFrom?: number,
      periodTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getPlayerFineHistory", "pId", pId);
      const localVarPath = `/api/v1/player_fine_history/{pId}`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (dateOfIssueFrom !== undefined) {
        localVarQueryParameter["dateOfIssueFrom"] =
          (dateOfIssueFrom as any) instanceof Date
            ? (dateOfIssueFrom as any).toISOString()
            : dateOfIssueFrom;
      }

      if (dateOfIssueTo !== undefined) {
        localVarQueryParameter["dateOfIssueTo"] =
          (dateOfIssueTo as any) instanceof Date
            ? (dateOfIssueTo as any).toISOString()
            : dateOfIssueTo;
      }

      if (firstnameCreator !== undefined) {
        localVarQueryParameter["firstnameCreator"] = firstnameCreator;
      }

      if (lastnameCreator !== undefined) {
        localVarQueryParameter["lastnameCreator"] = lastnameCreator;
      }

      if (competence) {
        localVarQueryParameter["competence"] = competence;
      }

      if (removableRatingFrom !== undefined) {
        localVarQueryParameter["removableRatingFrom"] = removableRatingFrom;
      }

      if (removableRatingTo !== undefined) {
        localVarQueryParameter["removableRatingTo"] = removableRatingTo;
      }

      if (periodFrom !== undefined) {
        localVarQueryParameter["periodFrom"] = periodFrom;
      }

      if (periodTo !== undefined) {
        localVarQueryParameter["periodTo"] = periodTo;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayerFineHistoryApi - functional programming interface
 * @export
 */
export const PlayerFineHistoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PlayerFineHistoryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Player Fine History
     * @param {string} pId Player ID
     * @param {string} [dateOfIssueFrom]
     * @param {string} [dateOfIssueTo]
     * @param {string} [firstnameCreator] First name creator
     * @param {string} [lastnameCreator] Last name creator
     * @param {Array<string>} [competence] Competence name
     * @param {number} [removableRatingFrom] Removable ratings from
     * @param {number} [removableRatingTo] Removable ratings to
     * @param {number} [periodFrom] Period of fine from
     * @param {number} [periodTo] Period of fine to
     * @param {string} [orderBy] sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerFineHistory(
      pId: string,
      dateOfIssueFrom?: string,
      dateOfIssueTo?: string,
      firstnameCreator?: string,
      lastnameCreator?: string,
      competence?: Array<string>,
      removableRatingFrom?: number,
      removableRatingTo?: number,
      periodFrom?: number,
      periodTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerFinesHistoryDtoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPlayerFineHistory(
          pId,
          dateOfIssueFrom,
          dateOfIssueTo,
          firstnameCreator,
          lastnameCreator,
          competence,
          removableRatingFrom,
          removableRatingTo,
          periodFrom,
          periodTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PlayerFineHistoryApi - factory interface
 * @export
 */
export const PlayerFineHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlayerFineHistoryApiFp(configuration);
  return {
    /**
     *
     * @summary Get Player Fine History
     * @param {string} pId Player ID
     * @param {string} [dateOfIssueFrom]
     * @param {string} [dateOfIssueTo]
     * @param {string} [firstnameCreator] First name creator
     * @param {string} [lastnameCreator] Last name creator
     * @param {Array<string>} [competence] Competence name
     * @param {number} [removableRatingFrom] Removable ratings from
     * @param {number} [removableRatingTo] Removable ratings to
     * @param {number} [periodFrom] Period of fine from
     * @param {number} [periodTo] Period of fine to
     * @param {string} [orderBy] sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerFineHistory(
      pId: string,
      dateOfIssueFrom?: string,
      dateOfIssueTo?: string,
      firstnameCreator?: string,
      lastnameCreator?: string,
      competence?: Array<string>,
      removableRatingFrom?: number,
      removableRatingTo?: number,
      periodFrom?: number,
      periodTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<PlayerFinesHistoryDtoResponse> {
      return localVarFp
        .getPlayerFineHistory(
          pId,
          dateOfIssueFrom,
          dateOfIssueTo,
          firstnameCreator,
          lastnameCreator,
          competence,
          removableRatingFrom,
          removableRatingTo,
          periodFrom,
          periodTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getPlayerFineHistory operation in PlayerFineHistoryApi.
 * @export
 * @interface PlayerFineHistoryApiGetPlayerFineHistoryRequest
 */
export interface PlayerFineHistoryApiGetPlayerFineHistoryRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly pId: string;

  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly dateOfIssueFrom?: string;

  /**
   *
   * @type {string}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly dateOfIssueTo?: string;

  /**
   * First name creator
   * @type {string}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly firstnameCreator?: string;

  /**
   * Last name creator
   * @type {string}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly lastnameCreator?: string;

  /**
   * Competence name
   * @type {Array<string>}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly competence?: Array<string>;

  /**
   * Removable ratings from
   * @type {number}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly removableRatingFrom?: number;

  /**
   * Removable ratings to
   * @type {number}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly removableRatingTo?: number;

  /**
   * Period of fine from
   * @type {number}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly periodFrom?: number;

  /**
   * Period of fine to
   * @type {number}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly periodTo?: number;

  /**
   * sort AdmFines by fields competence/ /period/date_of_create/removable_ratings, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly orderBy?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof PlayerFineHistoryApiGetPlayerFineHistory
   */
  readonly pageSize?: number;
}

/**
 * PlayerFineHistoryApi - object-oriented interface
 * @export
 * @class PlayerFineHistoryApi
 * @extends {BaseAPI}
 */
export class PlayerFineHistoryApi extends BaseAPI {
  /**
   *
   * @summary Get Player Fine History
   * @param {PlayerFineHistoryApiGetPlayerFineHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerFineHistoryApi
   */
  public getPlayerFineHistory(
    requestParameters: PlayerFineHistoryApiGetPlayerFineHistoryRequest,
    options?: any,
  ) {
    return PlayerFineHistoryApiFp(this.configuration)
      .getPlayerFineHistory(
        requestParameters.pId,
        requestParameters.dateOfIssueFrom,
        requestParameters.dateOfIssueTo,
        requestParameters.firstnameCreator,
        requestParameters.lastnameCreator,
        requestParameters.competence,
        requestParameters.removableRatingFrom,
        requestParameters.removableRatingTo,
        requestParameters.periodFrom,
        requestParameters.periodTo,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlayerFineStatusApi - axios parameter creator
 * @export
 */
export const PlayerFineStatusApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Player Fine Status
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerFineStatus: async (
      pId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getPlayerFineStatus", "pId", pId);
      const localVarPath =
        `/api/v1/admin/settings/admFinesCheckStatus/{pId}`.replace(
          `{${"pId"}}`,
          encodeURIComponent(String(pId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayerFineStatusApi - functional programming interface
 * @export
 */
export const PlayerFineStatusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PlayerFineStatusApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Player Fine Status
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerFineStatus(
      pId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerFineStatusDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPlayerFineStatus(pId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PlayerFineStatusApi - factory interface
 * @export
 */
export const PlayerFineStatusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlayerFineStatusApiFp(configuration);
  return {
    /**
     *
     * @summary Get Player Fine Status
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerFineStatus(
      pId: string,
      options?: any,
    ): AxiosPromise<PlayerFineStatusDto> {
      return localVarFp
        .getPlayerFineStatus(pId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getPlayerFineStatus operation in PlayerFineStatusApi.
 * @export
 * @interface PlayerFineStatusApiGetPlayerFineStatusRequest
 */
export interface PlayerFineStatusApiGetPlayerFineStatusRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayerFineStatusApiGetPlayerFineStatus
   */
  readonly pId: string;
}

/**
 * PlayerFineStatusApi - object-oriented interface
 * @export
 * @class PlayerFineStatusApi
 * @extends {BaseAPI}
 */
export class PlayerFineStatusApi extends BaseAPI {
  /**
   *
   * @summary Get Player Fine Status
   * @param {PlayerFineStatusApiGetPlayerFineStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerFineStatusApi
   */
  public getPlayerFineStatus(
    requestParameters: PlayerFineStatusApiGetPlayerFineStatusRequest,
    options?: any,
  ) {
    return PlayerFineStatusApiFp(this.configuration)
      .getPlayerFineStatus(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlayersApi - axios parameter creator
 * @export
 */
export const PlayersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Changes By Admin Player
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateActionFrom] filter players by dateActionFrom
     * @param {string} [dateActionTo] filter players by dateAction
     * @param {Array<string>} [action] filter players by Action
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;action_date&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangesByAdminPlayer: async (
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateActionFrom?: string,
      dateActionTo?: string,
      action?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/player/history-admin-changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (dateActionFrom !== undefined) {
        localVarQueryParameter["dateActionFrom"] = dateActionFrom;
      }

      if (dateActionTo !== undefined) {
        localVarQueryParameter["dateActionTo"] = dateActionTo;
      }

      if (action) {
        localVarQueryParameter["Action"] = action;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Info
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayer: async (pId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getPlayer", "pId", pId);
      const localVarPath = `/api/v1/players/{pId}`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get complaints received against the player
     * @param {string} pId Player ID
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {string} [assTitle] filter complaints by to_date_of_create to
     * @param {string} [fromDateOfCreate] filter complaints by from_date_of_create from
     * @param {string} [toDateOfCreate] filter complaints by to_date_of_create to
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;ass_title&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerComplaints: async (
      pId: string,
      locale?: string,
      assTitle?: string,
      fromDateOfCreate?: string,
      toDateOfCreate?: string,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getPlayerComplaints", "pId", pId);
      const localVarPath = `/api/v1/players/{pId}/complaints`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale;
      }

      if (assTitle !== undefined) {
        localVarQueryParameter["ass_title"] = assTitle;
      }

      if (fromDateOfCreate !== undefined) {
        localVarQueryParameter["from_date_of_create"] =
          (fromDateOfCreate as any) instanceof Date
            ? (fromDateOfCreate as any).toISOString().substr(0, 10)
            : fromDateOfCreate;
      }

      if (toDateOfCreate !== undefined) {
        localVarQueryParameter["to_date_of_create"] =
          (toDateOfCreate as any) instanceof Date
            ? (toDateOfCreate as any).toISOString().substr(0, 10)
            : toDateOfCreate;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Ratings
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerRatings: async (
      pId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getPlayerRatings", "pId", pId);
      const localVarPath = `/api/v1/players/{pId}/rating`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Info For Svc
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerSvc: async (
      pId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getPlayerSvc", "pId", pId);
      const localVarPath = `/api/v1/players/{pId}/svc`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get players count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayersCount: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get players growth statistics
     * @param {string} [fromDate] filter rows by fromDate, from
     * @param {string} [toDate] filter rows by toDate, to
     * @param {string} [filterBy] last_m_growth, rate_0_5, rate_1. Default last_m_growth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayersGrowth: async (
      fromDate?: string,
      toDate?: string,
      filterBy?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/players/growth-stats`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] =
          (fromDate as any) instanceof Date
            ? (fromDate as any).toISOString()
            : fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] =
          (toDate as any) instanceof Date
            ? (toDate as any).toISOString()
            : toDate;
      }

      if (filterBy !== undefined) {
        localVarQueryParameter["filterBy"] = filterBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Players Rates
     * @param {GetPlayersRates} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRates: async (
      body: GetPlayersRates,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("getRates", "body", body);
      const localVarPath = `/api/v1/players/getRates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get User Employment Info
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserEmploymentInfo: async (
      pId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getUserEmploymentInfo", "pId", pId);
      const localVarPath = `/api/v1/players/{pId}/employment_info`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Player
     * @param {string} pId Player ID
     * @param {UpdPlayerAdminByidReqDto} body Player to update for admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchPlayerById: async (
      pId: string,
      body: UpdPlayerAdminByidReqDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("patchPlayerById", "pId", pId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("patchPlayerById", "body", body);
      const localVarPath = `/api/v1/players/{pId}`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Changes By Admin Player
     * @param {AddChangesByAdminPlayerDto} body Create to change by admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postChangesByAdminPlayer: async (
      body: AddChangesByAdminPlayerDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postChangesByAdminPlayer", "body", body);
      const localVarPath = `/api/v1/player/history-admin-changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Player Rating
     * @param {string} pId Player ID
     * @param {UpdPlayerRatingReq} body Player Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayerRating: async (
      pId: string,
      body: UpdPlayerRatingReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("postPlayerRating", "pId", pId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postPlayerRating", "body", body);
      const localVarPath = `/api/v1/players/{pId}/rating`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Emails
     * @param {PlayersEmailsReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersEmails: async (
      body: PlayersEmailsReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postPlayersEmails", "body", body);
      const localVarPath = `/api/v1/players/emails`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upd Player Last Play
     * @param {PlayersLastPlayBulkUpdReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersLastPlayBulkUpd: async (
      body: PlayersLastPlayBulkUpdReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postPlayersLastPlayBulkUpd", "body", body);
      const localVarPath = `/api/v1/players/lastPlayBulkUpd`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Phones
     * @param {PlayersPhonesReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersPhones: async (
      body: PlayersPhonesReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postPlayersPhones", "body", body);
      const localVarPath = `/api/v1/players/phones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayersApi - functional programming interface
 * @export
 */
export const PlayersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlayersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Changes By Admin Player
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateActionFrom] filter players by dateActionFrom
     * @param {string} [dateActionTo] filter players by dateAction
     * @param {Array<string>} [action] filter players by Action
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;action_date&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChangesByAdminPlayer(
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateActionFrom?: string,
      dateActionTo?: string,
      action?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserChangesByAdminPlayerDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getChangesByAdminPlayer(
          firstName,
          lastName,
          dateActionFrom,
          dateActionTo,
          action,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Info
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayer(
      pId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayer(
        pId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get complaints received against the player
     * @param {string} pId Player ID
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {string} [assTitle] filter complaints by to_date_of_create to
     * @param {string} [fromDateOfCreate] filter complaints by from_date_of_create from
     * @param {string} [toDateOfCreate] filter complaints by to_date_of_create to
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;ass_title&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerComplaints(
      pId: string,
      locale?: string,
      assTitle?: string,
      fromDateOfCreate?: string,
      toDateOfCreate?: string,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerComplaints>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPlayerComplaints(
          pId,
          locale,
          assTitle,
          fromDateOfCreate,
          toDateOfCreate,
          pageNum,
          pageSize,
          orderBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Ratings
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerRatings(
      pId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerRatingRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPlayerRatings(pId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Info For Svc
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayerSvc(
      pId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerSvc(
        pId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get players count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayersCount(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayersCountDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPlayersCount(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get players growth statistics
     * @param {string} [fromDate] filter rows by fromDate, from
     * @param {string} [toDate] filter rows by toDate, to
     * @param {string} [filterBy] last_m_growth, rate_0_5, rate_1. Default last_m_growth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPlayersGrowth(
      fromDate?: string,
      toDate?: string,
      filterBy?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayersGrowth>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPlayersGrowth(
          fromDate,
          toDate,
          filterBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Players Rates
     * @param {GetPlayersRates} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRates(
      body: GetPlayersRates,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayersRates>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRates(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get User Employment Info
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserEmploymentInfo(
      pId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserEmploymentInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserEmploymentInfo(pId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Patch Player
     * @param {string} pId Player ID
     * @param {UpdPlayerAdminByidReqDto} body Player to update for admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchPlayerById(
      pId: string,
      body: UpdPlayerAdminByidReqDto,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchPlayerById(
        pId,
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Changes By Admin Player
     * @param {AddChangesByAdminPlayerDto} body Create to change by admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postChangesByAdminPlayer(
      body: AddChangesByAdminPlayerDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UsersChangesByAdminPlayerDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postChangesByAdminPlayer(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Player Rating
     * @param {string} pId Player ID
     * @param {UpdPlayerRatingReq} body Player Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlayerRating(
      pId: string,
      body: UpdPlayerRatingReq,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdPlayerRatingRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPlayerRating(pId, body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Emails
     * @param {PlayersEmailsReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlayersEmails(
      body: PlayersEmailsReq,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayersEmailsRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPlayersEmails(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upd Player Last Play
     * @param {PlayersLastPlayBulkUpdReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlayersLastPlayBulkUpd(
      body: PlayersLastPlayBulkUpdReq,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPlayersLastPlayBulkUpd(
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Phones
     * @param {PlayersPhonesReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postPlayersPhones(
      body: PlayersPhonesReq,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayersPhonesRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postPlayersPhones(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PlayersApi - factory interface
 * @export
 */
export const PlayersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlayersApiFp(configuration);
  return {
    /**
     *
     * @summary Get Changes By Admin Player
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [dateActionFrom] filter players by dateActionFrom
     * @param {string} [dateActionTo] filter players by dateAction
     * @param {Array<string>} [action] filter players by Action
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;action_date&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangesByAdminPlayer(
      firstName?: Array<string>,
      lastName?: Array<string>,
      dateActionFrom?: string,
      dateActionTo?: string,
      action?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetUserChangesByAdminPlayerDto> {
      return localVarFp
        .getChangesByAdminPlayer(
          firstName,
          lastName,
          dateActionFrom,
          dateActionTo,
          action,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Info
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayer(pId: string, options?: any): AxiosPromise<Player> {
      return localVarFp
        .getPlayer(pId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get complaints received against the player
     * @param {string} pId Player ID
     * @param {string} [locale] Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
     * @param {string} [assTitle] filter complaints by to_date_of_create to
     * @param {string} [fromDateOfCreate] filter complaints by from_date_of_create from
     * @param {string} [toDateOfCreate] filter complaints by to_date_of_create to
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<string>} [orderBy] sort countries by fields &#x60;ass_title&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerComplaints(
      pId: string,
      locale?: string,
      assTitle?: string,
      fromDateOfCreate?: string,
      toDateOfCreate?: string,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<PlayerComplaints> {
      return localVarFp
        .getPlayerComplaints(
          pId,
          locale,
          assTitle,
          fromDateOfCreate,
          toDateOfCreate,
          pageNum,
          pageSize,
          orderBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Ratings
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerRatings(
      pId: string,
      options?: any,
    ): AxiosPromise<PlayerRatingRes> {
      return localVarFp
        .getPlayerRatings(pId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Info For Svc
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayerSvc(pId: string, options?: any): AxiosPromise<Player> {
      return localVarFp
        .getPlayerSvc(pId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get players count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayersCount(options?: any): AxiosPromise<PlayersCountDto> {
      return localVarFp
        .getPlayersCount(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get players growth statistics
     * @param {string} [fromDate] filter rows by fromDate, from
     * @param {string} [toDate] filter rows by toDate, to
     * @param {string} [filterBy] last_m_growth, rate_0_5, rate_1. Default last_m_growth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlayersGrowth(
      fromDate?: string,
      toDate?: string,
      filterBy?: string,
      options?: any,
    ): AxiosPromise<PlayersGrowth> {
      return localVarFp
        .getPlayersGrowth(fromDate, toDate, filterBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Players Rates
     * @param {GetPlayersRates} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRates(body: GetPlayersRates, options?: any): AxiosPromise<PlayersRates> {
      return localVarFp
        .getRates(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get User Employment Info
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserEmploymentInfo(
      pId: string,
      options?: any,
    ): AxiosPromise<UserEmploymentInfo> {
      return localVarFp
        .getUserEmploymentInfo(pId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Player
     * @param {string} pId Player ID
     * @param {UpdPlayerAdminByidReqDto} body Player to update for admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchPlayerById(
      pId: string,
      body: UpdPlayerAdminByidReqDto,
      options?: any,
    ): AxiosPromise<Player> {
      return localVarFp
        .patchPlayerById(pId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Changes By Admin Player
     * @param {AddChangesByAdminPlayerDto} body Create to change by admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postChangesByAdminPlayer(
      body: AddChangesByAdminPlayerDto,
      options?: any,
    ): AxiosPromise<UsersChangesByAdminPlayerDto> {
      return localVarFp
        .postChangesByAdminPlayer(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Player Rating
     * @param {string} pId Player ID
     * @param {UpdPlayerRatingReq} body Player Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayerRating(
      pId: string,
      body: UpdPlayerRatingReq,
      options?: any,
    ): AxiosPromise<UpdPlayerRatingRes> {
      return localVarFp
        .postPlayerRating(pId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Emails
     * @param {PlayersEmailsReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersEmails(
      body: PlayersEmailsReq,
      options?: any,
    ): AxiosPromise<PlayersEmailsRes> {
      return localVarFp
        .postPlayersEmails(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upd Player Last Play
     * @param {PlayersLastPlayBulkUpdReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersLastPlayBulkUpd(
      body: PlayersLastPlayBulkUpdReq,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postPlayersLastPlayBulkUpd(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Phones
     * @param {PlayersPhonesReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postPlayersPhones(
      body: PlayersPhonesReq,
      options?: any,
    ): AxiosPromise<PlayersPhonesRes> {
      return localVarFp
        .postPlayersPhones(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getChangesByAdminPlayer operation in PlayersApi.
 * @export
 * @interface PlayersApiGetChangesByAdminPlayerRequest
 */
export interface PlayersApiGetChangesByAdminPlayerRequest {
  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly lastName?: Array<string>;

  /**
   * filter players by dateActionFrom
   * @type {string}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly dateActionFrom?: string;

  /**
   * filter players by dateAction
   * @type {string}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly dateActionTo?: string;

  /**
   * filter players by Action
   * @type {Array<string>}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly action?: Array<string>;

  /**
   * sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNamePlayer&#x60;/&#x60;lastNamePlayer&#x60; /&#x60;action_date&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof PlayersApiGetChangesByAdminPlayer
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getPlayer operation in PlayersApi.
 * @export
 * @interface PlayersApiGetPlayerRequest
 */
export interface PlayersApiGetPlayerRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayersApiGetPlayer
   */
  readonly pId: string;
}

/**
 * Request parameters for getPlayerComplaints operation in PlayersApi.
 * @export
 * @interface PlayersApiGetPlayerComplaintsRequest
 */
export interface PlayersApiGetPlayerComplaintsRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly pId: string;

  /**
   * Title in locale. If not present &#x60;token.locale&#x60; used, or \&#39;ru\&#39; by default.
   * @type {string}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly locale?: string;

  /**
   * filter complaints by to_date_of_create to
   * @type {string}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly assTitle?: string;

  /**
   * filter complaints by from_date_of_create from
   * @type {string}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly fromDateOfCreate?: string;

  /**
   * filter complaints by to_date_of_create to
   * @type {string}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly toDateOfCreate?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly pageSize?: number;

  /**
   * sort countries by fields &#x60;ass_title&#x60;/&#x60;date_of_create&#x60;/ with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof PlayersApiGetPlayerComplaints
   */
  readonly orderBy?: Array<string>;
}

/**
 * Request parameters for getPlayerRatings operation in PlayersApi.
 * @export
 * @interface PlayersApiGetPlayerRatingsRequest
 */
export interface PlayersApiGetPlayerRatingsRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayersApiGetPlayerRatings
   */
  readonly pId: string;
}

/**
 * Request parameters for getPlayerSvc operation in PlayersApi.
 * @export
 * @interface PlayersApiGetPlayerSvcRequest
 */
export interface PlayersApiGetPlayerSvcRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayersApiGetPlayerSvc
   */
  readonly pId: string;
}

/**
 * Request parameters for getPlayersGrowth operation in PlayersApi.
 * @export
 * @interface PlayersApiGetPlayersGrowthRequest
 */
export interface PlayersApiGetPlayersGrowthRequest {
  /**
   * filter rows by fromDate, from
   * @type {string}
   * @memberof PlayersApiGetPlayersGrowth
   */
  readonly fromDate?: string;

  /**
   * filter rows by toDate, to
   * @type {string}
   * @memberof PlayersApiGetPlayersGrowth
   */
  readonly toDate?: string;

  /**
   * last_m_growth, rate_0_5, rate_1. Default last_m_growth
   * @type {string}
   * @memberof PlayersApiGetPlayersGrowth
   */
  readonly filterBy?: string;
}

/**
 * Request parameters for getRates operation in PlayersApi.
 * @export
 * @interface PlayersApiGetRatesRequest
 */
export interface PlayersApiGetRatesRequest {
  /**
   *
   * @type {GetPlayersRates}
   * @memberof PlayersApiGetRates
   */
  readonly body: GetPlayersRates;
}

/**
 * Request parameters for getUserEmploymentInfo operation in PlayersApi.
 * @export
 * @interface PlayersApiGetUserEmploymentInfoRequest
 */
export interface PlayersApiGetUserEmploymentInfoRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayersApiGetUserEmploymentInfo
   */
  readonly pId: string;
}

/**
 * Request parameters for patchPlayerById operation in PlayersApi.
 * @export
 * @interface PlayersApiPatchPlayerByIdRequest
 */
export interface PlayersApiPatchPlayerByIdRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayersApiPatchPlayerById
   */
  readonly pId: string;

  /**
   * Player to update for admin
   * @type {UpdPlayerAdminByidReqDto}
   * @memberof PlayersApiPatchPlayerById
   */
  readonly body: UpdPlayerAdminByidReqDto;
}

/**
 * Request parameters for postChangesByAdminPlayer operation in PlayersApi.
 * @export
 * @interface PlayersApiPostChangesByAdminPlayerRequest
 */
export interface PlayersApiPostChangesByAdminPlayerRequest {
  /**
   * Create to change by admin
   * @type {AddChangesByAdminPlayerDto}
   * @memberof PlayersApiPostChangesByAdminPlayer
   */
  readonly body: AddChangesByAdminPlayerDto;
}

/**
 * Request parameters for postPlayerRating operation in PlayersApi.
 * @export
 * @interface PlayersApiPostPlayerRatingRequest
 */
export interface PlayersApiPostPlayerRatingRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof PlayersApiPostPlayerRating
   */
  readonly pId: string;

  /**
   * Player Info
   * @type {UpdPlayerRatingReq}
   * @memberof PlayersApiPostPlayerRating
   */
  readonly body: UpdPlayerRatingReq;
}

/**
 * Request parameters for postPlayersEmails operation in PlayersApi.
 * @export
 * @interface PlayersApiPostPlayersEmailsRequest
 */
export interface PlayersApiPostPlayersEmailsRequest {
  /**
   *
   * @type {PlayersEmailsReq}
   * @memberof PlayersApiPostPlayersEmails
   */
  readonly body: PlayersEmailsReq;
}

/**
 * Request parameters for postPlayersLastPlayBulkUpd operation in PlayersApi.
 * @export
 * @interface PlayersApiPostPlayersLastPlayBulkUpdRequest
 */
export interface PlayersApiPostPlayersLastPlayBulkUpdRequest {
  /**
   *
   * @type {PlayersLastPlayBulkUpdReq}
   * @memberof PlayersApiPostPlayersLastPlayBulkUpd
   */
  readonly body: PlayersLastPlayBulkUpdReq;
}

/**
 * Request parameters for postPlayersPhones operation in PlayersApi.
 * @export
 * @interface PlayersApiPostPlayersPhonesRequest
 */
export interface PlayersApiPostPlayersPhonesRequest {
  /**
   *
   * @type {PlayersPhonesReq}
   * @memberof PlayersApiPostPlayersPhones
   */
  readonly body: PlayersPhonesReq;
}

/**
 * PlayersApi - object-oriented interface
 * @export
 * @class PlayersApi
 * @extends {BaseAPI}
 */
export class PlayersApi extends BaseAPI {
  /**
   *
   * @summary Get Changes By Admin Player
   * @param {PlayersApiGetChangesByAdminPlayerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getChangesByAdminPlayer(
    requestParameters: PlayersApiGetChangesByAdminPlayerRequest = {},
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .getChangesByAdminPlayer(
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.dateActionFrom,
        requestParameters.dateActionTo,
        requestParameters.action,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Info
   * @param {PlayersApiGetPlayerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayer(
    requestParameters: PlayersApiGetPlayerRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .getPlayer(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get complaints received against the player
   * @param {PlayersApiGetPlayerComplaintsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayerComplaints(
    requestParameters: PlayersApiGetPlayerComplaintsRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .getPlayerComplaints(
        requestParameters.pId,
        requestParameters.locale,
        requestParameters.assTitle,
        requestParameters.fromDateOfCreate,
        requestParameters.toDateOfCreate,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Ratings
   * @param {PlayersApiGetPlayerRatingsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayerRatings(
    requestParameters: PlayersApiGetPlayerRatingsRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .getPlayerRatings(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Info For Svc
   * @param {PlayersApiGetPlayerSvcRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayerSvc(
    requestParameters: PlayersApiGetPlayerSvcRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .getPlayerSvc(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get players count
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayersCount(options?: any) {
    return PlayersApiFp(this.configuration)
      .getPlayersCount(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get players growth statistics
   * @param {PlayersApiGetPlayersGrowthRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getPlayersGrowth(
    requestParameters: PlayersApiGetPlayersGrowthRequest = {},
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .getPlayersGrowth(
        requestParameters.fromDate,
        requestParameters.toDate,
        requestParameters.filterBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Players Rates
   * @param {PlayersApiGetRatesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getRates(requestParameters: PlayersApiGetRatesRequest, options?: any) {
    return PlayersApiFp(this.configuration)
      .getRates(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get User Employment Info
   * @param {PlayersApiGetUserEmploymentInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public getUserEmploymentInfo(
    requestParameters: PlayersApiGetUserEmploymentInfoRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .getUserEmploymentInfo(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Player
   * @param {PlayersApiPatchPlayerByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public patchPlayerById(
    requestParameters: PlayersApiPatchPlayerByIdRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .patchPlayerById(requestParameters.pId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Changes By Admin Player
   * @param {PlayersApiPostChangesByAdminPlayerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public postChangesByAdminPlayer(
    requestParameters: PlayersApiPostChangesByAdminPlayerRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .postChangesByAdminPlayer(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Player Rating
   * @param {PlayersApiPostPlayerRatingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public postPlayerRating(
    requestParameters: PlayersApiPostPlayerRatingRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .postPlayerRating(requestParameters.pId, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Emails
   * @param {PlayersApiPostPlayersEmailsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public postPlayersEmails(
    requestParameters: PlayersApiPostPlayersEmailsRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .postPlayersEmails(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upd Player Last Play
   * @param {PlayersApiPostPlayersLastPlayBulkUpdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public postPlayersLastPlayBulkUpd(
    requestParameters: PlayersApiPostPlayersLastPlayBulkUpdRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .postPlayersLastPlayBulkUpd(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Phones
   * @param {PlayersApiPostPlayersPhonesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayersApi
   */
  public postPlayersPhones(
    requestParameters: PlayersApiPostPlayersPhonesRequest,
    options?: any,
  ) {
    return PlayersApiFp(this.configuration)
      .postPlayersPhones(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RatingsApi - axios parameter creator
 * @export
 */
export const RatingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Find players with specified fields by AND clause
     * @summary Get Players rating info
     * @param {Array<string>} [name] filter players by firstName OR lastName
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [idInvited] UUID of users invited to the session
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [assessmentId]
     * @param {Array<string>} [phone] filter players by phone
     * @param {boolean} [isBlocked] filter players by isBlocked
     * @param {Array<string>} [email] filter players by email
     * @param {Array<boolean>} [emailVerified] filter players by emailVerified
     * @param {boolean} [emailFull] filter players by EmailFull
     * @param {Array<string>} [gender] filter players by gender (&#x60;ILIKE \&#39;x\&#39;&#x60;)
     * @param {Array<string>} [groupCode] filter players by groupCode (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [groupCodeNotNull] get players with not null values
     * @param {Array<string>} [studyWorkPlace] filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [studyWorkPlaceNull] filter players by studyWorkPlaceNull
     * @param {boolean} [confirmationSingle] filter players by confirmationSingle
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [desiredSpecialty] filter players by desiredSpecialty (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [desiredSpecialtyNull] filter players by desiredSpecialtyNull
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {Array<string>} [roleInRelatedProjects] filter players by roleInRelatedProjects
     * @param {Array<number>} [region] filter players by region
     * @param {boolean} [regionNull] filter players by regionNull
     * @param {Array<number>} [district] filter players by district
     * @param {boolean} [districtNull] filter players by districtNull
     * @param {boolean} [schoolNull] filter players by schoolNul
     * @param {Array<number>} [school] filter players by school
     * @param {Array<string>} [locality] filter players by locality
     * @param {boolean} [localityNull] filter players by localityNull
     * @param {boolean} [birthdateNull] filter players by birthdateNull
     * @param {string} [birthdateFrom] filter players by birthdateFrom
     * @param {string} [birthdateTo] filter players by birthdateTo
     * @param {Array<string>} [statusCompany] filter players by statusCompany
     * @param {Array<string>} [statusEmployment] filter players by statusEmployment
     * @param {Array<string>} [rejectionReason] filter players by rejectionReason
     * @param {number} [gPlaceFrom] filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gPlaceTo] filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gRateFrom] filter players by global rate, from, min 0, max 10
     * @param {number} [gRateTo] filter players by global rate, To, min 0, max 10
     * @param {number} [analyticsFrom] filter players by competence, from, min 0, max 10
     * @param {number} [analyticsTo] filter players by competence, to, min 0, max 10
     * @param {number} [analyticsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [analyticsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandFrom] filter players by competence, from, min 0, max 10
     * @param {number} [commandTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [commandTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationFrom] filter players by competence, from, min 0, max 10
     * @param {number} [communicationTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [communicationTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dFrom] filter players by thinking3d, from, min 0, max 10
     * @param {number} [thinking3dTo] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [economicsFrom] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTo] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceFrom] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTo] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsFrom] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {string} [createdFrom] filter players by createdAt, from
     * @param {string} [createdTo] filter players by createdAt, to
     * @param {boolean} [lastPlayNull] filter players by lastPlayNull
     * @param {string} [lastPlayFrom] filter players by lastPlay, from
     * @param {string} [lastPlayTo] filter players by lastPlay, to
     * @param {string} [lastActivityFrom] filter players by lastActivity, from
     * @param {string} [lastActivityTo] filter players by lastActivity, to
     * @param {boolean} [lastActivityNull] filter players by lastActivityNull
     * @param {Array<string>} [acquisitionChannel] filter players by acquisitionChannel
     * @param {boolean} [acquisitionChannelNull] filter players by acquisitionNullChannel
     * @param {string} [dateOfGettingAnalyticsLvl05From] filter players by date_of_getting_analytics_lvl_0_5, from
     * @param {string} [dateOfGettingAnalyticsLvl05To] filter players by date_of_getting_analytics_lvl_0_5, to
     * @param {string} [dateOfGettingAnalyticsLvl1From] filter players by date_of_getting_analytics_lvl_1, from
     * @param {string} [dateOfGettingAnalyticsLvl1To] filter players by date_of_getting_analytics_lvl_1, to
     * @param {boolean} [esiaVerified] filter players by esiaVerified
     * @param {number} [secretCode] filter players by secret_code
     * @param {Array<string>} [orderBy] sort players by fields &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60; /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60; /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60; /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60; /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60; /&#x60;acquisitionChannel&#x60;/&#x60;resume&#x60; /&#x60;date_of_getting_analytics_lvl_0_5&#x60; /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60; /&#x60;rolesInRelatedProjects&#x60;/&#x60;rejectionReason&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRating: async (
      name?: Array<string>,
      firstName?: Array<string>,
      idInvited?: Array<string>,
      lastName?: Array<string>,
      assessmentId?: string,
      phone?: Array<string>,
      isBlocked?: boolean,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      confirmationSingle?: boolean,
      desiredStudyPlace?: Array<string>,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      country?: Array<string>,
      countryNull?: boolean,
      roleInRelatedProjects?: Array<string>,
      region?: Array<number>,
      regionNull?: boolean,
      district?: Array<number>,
      districtNull?: boolean,
      schoolNull?: boolean,
      school?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      statusCompany?: Array<string>,
      statusEmployment?: Array<string>,
      rejectionReason?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityFrom?: number,
      analyticsTenacityTo?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      lastActivityNull?: boolean,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      esiaVerified?: boolean,
      secretCode?: number,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ratings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      if (name) {
        localVarQueryParameter["name"] = name;
      }

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (idInvited) {
        localVarQueryParameter["idInvited"] = idInvited;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (assessmentId !== undefined) {
        localVarQueryParameter["assessmentId"] = assessmentId;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (isBlocked !== undefined) {
        localVarQueryParameter["isBlocked"] = isBlocked;
      }

      if (email) {
        localVarQueryParameter["email"] = email;
      }

      if (emailVerified) {
        localVarQueryParameter["emailVerified"] = emailVerified;
      }

      if (emailFull !== undefined) {
        localVarQueryParameter["emailFull"] = emailFull;
      }

      if (gender) {
        localVarQueryParameter["gender"] = gender;
      }

      if (groupCode) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (groupCodeNotNull !== undefined) {
        localVarQueryParameter["groupCodeNotNull"] = groupCodeNotNull;
      }

      if (studyWorkPlace) {
        localVarQueryParameter["studyWorkPlace"] = studyWorkPlace;
      }

      if (studyWorkPlaceNull !== undefined) {
        localVarQueryParameter["studyWorkPlaceNull"] = studyWorkPlaceNull;
      }

      if (confirmationSingle !== undefined) {
        localVarQueryParameter["confirmationSingle"] = confirmationSingle;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      if (desiredSpecialty) {
        localVarQueryParameter["desiredSpecialty"] = desiredSpecialty;
      }

      if (desiredSpecialtyNull !== undefined) {
        localVarQueryParameter["desiredSpecialtyNull"] = desiredSpecialtyNull;
      }

      if (country) {
        localVarQueryParameter["country"] = country;
      }

      if (countryNull !== undefined) {
        localVarQueryParameter["countryNull"] = countryNull;
      }

      if (roleInRelatedProjects) {
        localVarQueryParameter["roleInRelatedProjects"] = roleInRelatedProjects;
      }

      if (region) {
        localVarQueryParameter["region"] = region;
      }

      if (regionNull !== undefined) {
        localVarQueryParameter["regionNull"] = regionNull;
      }

      if (district) {
        localVarQueryParameter["district"] = district;
      }

      if (districtNull !== undefined) {
        localVarQueryParameter["districtNull"] = districtNull;
      }

      if (schoolNull !== undefined) {
        localVarQueryParameter["schoolNull"] = schoolNull;
      }

      if (school) {
        localVarQueryParameter["school"] = school;
      }

      if (locality) {
        localVarQueryParameter["locality"] = locality;
      }

      if (localityNull !== undefined) {
        localVarQueryParameter["localityNull"] = localityNull;
      }

      if (birthdateNull !== undefined) {
        localVarQueryParameter["birthdateNull"] = birthdateNull;
      }

      if (birthdateFrom !== undefined) {
        localVarQueryParameter["birthdateFrom"] = birthdateFrom;
      }

      if (birthdateTo !== undefined) {
        localVarQueryParameter["birthdateTo"] = birthdateTo;
      }

      if (statusCompany) {
        localVarQueryParameter["statusCompany"] = statusCompany;
      }

      if (statusEmployment) {
        localVarQueryParameter["statusEmployment"] = statusEmployment;
      }

      if (rejectionReason) {
        localVarQueryParameter["rejectionReason"] = rejectionReason;
      }

      if (gPlaceFrom !== undefined) {
        localVarQueryParameter["gPlaceFrom"] = gPlaceFrom;
      }

      if (gPlaceTo !== undefined) {
        localVarQueryParameter["gPlaceTo"] = gPlaceTo;
      }

      if (gRateFrom !== undefined) {
        localVarQueryParameter["gRateFrom"] = gRateFrom;
      }

      if (gRateTo !== undefined) {
        localVarQueryParameter["gRateTo"] = gRateTo;
      }

      if (analyticsFrom !== undefined) {
        localVarQueryParameter["analyticsFrom"] = analyticsFrom;
      }

      if (analyticsTo !== undefined) {
        localVarQueryParameter["analyticsTo"] = analyticsTo;
      }

      if (analyticsTenacityFrom !== undefined) {
        localVarQueryParameter["analyticsTenacityFrom"] = analyticsTenacityFrom;
      }

      if (analyticsTenacityTo !== undefined) {
        localVarQueryParameter["analyticsTenacityTo"] = analyticsTenacityTo;
      }

      if (commandFrom !== undefined) {
        localVarQueryParameter["commandFrom"] = commandFrom;
      }

      if (commandTo !== undefined) {
        localVarQueryParameter["commandTo"] = commandTo;
      }

      if (commandTenacityFrom !== undefined) {
        localVarQueryParameter["commandTenacityFrom"] = commandTenacityFrom;
      }

      if (commandTenacityTo !== undefined) {
        localVarQueryParameter["commandTenacityTo"] = commandTenacityTo;
      }

      if (communicationFrom !== undefined) {
        localVarQueryParameter["communicationFrom"] = communicationFrom;
      }

      if (communicationTo !== undefined) {
        localVarQueryParameter["communicationTo"] = communicationTo;
      }

      if (communicationTenacityFrom !== undefined) {
        localVarQueryParameter["communicationTenacityFrom"] =
          communicationTenacityFrom;
      }

      if (communicationTenacityTo !== undefined) {
        localVarQueryParameter["communicationTenacityTo"] =
          communicationTenacityTo;
      }

      if (thinking3dFrom !== undefined) {
        localVarQueryParameter["thinking3dFrom"] = thinking3dFrom;
      }

      if (thinking3dTo !== undefined) {
        localVarQueryParameter["thinking3dTo"] = thinking3dTo;
      }

      if (thinking3dTenacityFrom !== undefined) {
        localVarQueryParameter["thinking3dTenacityFrom"] =
          thinking3dTenacityFrom;
      }

      if (thinking3dTenacityTo !== undefined) {
        localVarQueryParameter["thinking3dTenacityTo"] = thinking3dTenacityTo;
      }

      if (economicsFrom !== undefined) {
        localVarQueryParameter["economicsFrom"] = economicsFrom;
      }

      if (economicsTo !== undefined) {
        localVarQueryParameter["economicsTo"] = economicsTo;
      }

      if (economicsTenacityFrom !== undefined) {
        localVarQueryParameter["economicsTenacityFrom"] = economicsTenacityFrom;
      }

      if (economicsTenacityTo !== undefined) {
        localVarQueryParameter["economicsTenacityTo"] = economicsTenacityTo;
      }

      if (jurisprudenceFrom !== undefined) {
        localVarQueryParameter["jurisprudenceFrom"] = jurisprudenceFrom;
      }

      if (jurisprudenceTo !== undefined) {
        localVarQueryParameter["jurisprudenceTo"] = jurisprudenceTo;
      }

      if (jurisprudenceTenacityFrom !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityFrom"] =
          jurisprudenceTenacityFrom;
      }

      if (jurisprudenceTenacityTo !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityTo"] =
          jurisprudenceTenacityTo;
      }

      if (microelectronicsTo !== undefined) {
        localVarQueryParameter["microelectronicsTo"] = microelectronicsTo;
      }

      if (microelectronicsFrom !== undefined) {
        localVarQueryParameter["microelectronicsFrom"] = microelectronicsFrom;
      }

      if (microelectronicsTenacityTo !== undefined) {
        localVarQueryParameter["microelectronicsTenacityTo"] =
          microelectronicsTenacityTo;
      }

      if (microelectronicsTenacityFrom !== undefined) {
        localVarQueryParameter["microelectronicsTenacityFrom"] =
          microelectronicsTenacityFrom;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] =
          (createdFrom as any) instanceof Date
            ? (createdFrom as any).toISOString()
            : createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] =
          (createdTo as any) instanceof Date
            ? (createdTo as any).toISOString()
            : createdTo;
      }

      if (lastPlayNull !== undefined) {
        localVarQueryParameter["lastPlayNull"] = lastPlayNull;
      }

      if (lastPlayFrom !== undefined) {
        localVarQueryParameter["lastPlayFrom"] =
          (lastPlayFrom as any) instanceof Date
            ? (lastPlayFrom as any).toISOString()
            : lastPlayFrom;
      }

      if (lastPlayTo !== undefined) {
        localVarQueryParameter["lastPlayTo"] =
          (lastPlayTo as any) instanceof Date
            ? (lastPlayTo as any).toISOString()
            : lastPlayTo;
      }

      if (lastActivityFrom !== undefined) {
        localVarQueryParameter["lastActivityFrom"] =
          (lastActivityFrom as any) instanceof Date
            ? (lastActivityFrom as any).toISOString()
            : lastActivityFrom;
      }

      if (lastActivityTo !== undefined) {
        localVarQueryParameter["lastActivityTo"] =
          (lastActivityTo as any) instanceof Date
            ? (lastActivityTo as any).toISOString()
            : lastActivityTo;
      }

      if (lastActivityNull !== undefined) {
        localVarQueryParameter["lastActivityNull"] = lastActivityNull;
      }

      if (acquisitionChannel) {
        localVarQueryParameter["acquisitionChannel"] = acquisitionChannel;
      }

      if (acquisitionChannelNull !== undefined) {
        localVarQueryParameter["acquisitionChannelNull"] =
          acquisitionChannelNull;
      }

      if (dateOfGettingAnalyticsLvl05From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5From"] =
          (dateOfGettingAnalyticsLvl05From as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl05From as any).toISOString()
            : dateOfGettingAnalyticsLvl05From;
      }

      if (dateOfGettingAnalyticsLvl05To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5To"] =
          (dateOfGettingAnalyticsLvl05To as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl05To as any).toISOString()
            : dateOfGettingAnalyticsLvl05To;
      }

      if (dateOfGettingAnalyticsLvl1From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1From"] =
          (dateOfGettingAnalyticsLvl1From as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl1From as any).toISOString()
            : dateOfGettingAnalyticsLvl1From;
      }

      if (dateOfGettingAnalyticsLvl1To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1To"] =
          (dateOfGettingAnalyticsLvl1To as any) instanceof Date
            ? (dateOfGettingAnalyticsLvl1To as any).toISOString()
            : dateOfGettingAnalyticsLvl1To;
      }

      if (esiaVerified !== undefined) {
        localVarQueryParameter["esiaVerified"] = esiaVerified;
      }

      if (secretCode !== undefined) {
        localVarQueryParameter["secret_code"] = secretCode;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Output players count by their rating
     * @summary Get Players rating chart data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRatingChart: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/ratings/chart`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RatingsApi - functional programming interface
 * @export
 */
export const RatingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RatingsApiAxiosParamCreator(configuration);
  return {
    /**
     * Find players with specified fields by AND clause
     * @summary Get Players rating info
     * @param {Array<string>} [name] filter players by firstName OR lastName
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [idInvited] UUID of users invited to the session
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [assessmentId]
     * @param {Array<string>} [phone] filter players by phone
     * @param {boolean} [isBlocked] filter players by isBlocked
     * @param {Array<string>} [email] filter players by email
     * @param {Array<boolean>} [emailVerified] filter players by emailVerified
     * @param {boolean} [emailFull] filter players by EmailFull
     * @param {Array<string>} [gender] filter players by gender (&#x60;ILIKE \&#39;x\&#39;&#x60;)
     * @param {Array<string>} [groupCode] filter players by groupCode (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [groupCodeNotNull] get players with not null values
     * @param {Array<string>} [studyWorkPlace] filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [studyWorkPlaceNull] filter players by studyWorkPlaceNull
     * @param {boolean} [confirmationSingle] filter players by confirmationSingle
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [desiredSpecialty] filter players by desiredSpecialty (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [desiredSpecialtyNull] filter players by desiredSpecialtyNull
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {Array<string>} [roleInRelatedProjects] filter players by roleInRelatedProjects
     * @param {Array<number>} [region] filter players by region
     * @param {boolean} [regionNull] filter players by regionNull
     * @param {Array<number>} [district] filter players by district
     * @param {boolean} [districtNull] filter players by districtNull
     * @param {boolean} [schoolNull] filter players by schoolNul
     * @param {Array<number>} [school] filter players by school
     * @param {Array<string>} [locality] filter players by locality
     * @param {boolean} [localityNull] filter players by localityNull
     * @param {boolean} [birthdateNull] filter players by birthdateNull
     * @param {string} [birthdateFrom] filter players by birthdateFrom
     * @param {string} [birthdateTo] filter players by birthdateTo
     * @param {Array<string>} [statusCompany] filter players by statusCompany
     * @param {Array<string>} [statusEmployment] filter players by statusEmployment
     * @param {Array<string>} [rejectionReason] filter players by rejectionReason
     * @param {number} [gPlaceFrom] filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gPlaceTo] filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gRateFrom] filter players by global rate, from, min 0, max 10
     * @param {number} [gRateTo] filter players by global rate, To, min 0, max 10
     * @param {number} [analyticsFrom] filter players by competence, from, min 0, max 10
     * @param {number} [analyticsTo] filter players by competence, to, min 0, max 10
     * @param {number} [analyticsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [analyticsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandFrom] filter players by competence, from, min 0, max 10
     * @param {number} [commandTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [commandTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationFrom] filter players by competence, from, min 0, max 10
     * @param {number} [communicationTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [communicationTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dFrom] filter players by thinking3d, from, min 0, max 10
     * @param {number} [thinking3dTo] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [economicsFrom] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTo] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceFrom] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTo] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsFrom] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {string} [createdFrom] filter players by createdAt, from
     * @param {string} [createdTo] filter players by createdAt, to
     * @param {boolean} [lastPlayNull] filter players by lastPlayNull
     * @param {string} [lastPlayFrom] filter players by lastPlay, from
     * @param {string} [lastPlayTo] filter players by lastPlay, to
     * @param {string} [lastActivityFrom] filter players by lastActivity, from
     * @param {string} [lastActivityTo] filter players by lastActivity, to
     * @param {boolean} [lastActivityNull] filter players by lastActivityNull
     * @param {Array<string>} [acquisitionChannel] filter players by acquisitionChannel
     * @param {boolean} [acquisitionChannelNull] filter players by acquisitionNullChannel
     * @param {string} [dateOfGettingAnalyticsLvl05From] filter players by date_of_getting_analytics_lvl_0_5, from
     * @param {string} [dateOfGettingAnalyticsLvl05To] filter players by date_of_getting_analytics_lvl_0_5, to
     * @param {string} [dateOfGettingAnalyticsLvl1From] filter players by date_of_getting_analytics_lvl_1, from
     * @param {string} [dateOfGettingAnalyticsLvl1To] filter players by date_of_getting_analytics_lvl_1, to
     * @param {boolean} [esiaVerified] filter players by esiaVerified
     * @param {number} [secretCode] filter players by secret_code
     * @param {Array<string>} [orderBy] sort players by fields &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60; /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60; /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60; /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60; /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60; /&#x60;acquisitionChannel&#x60;/&#x60;resume&#x60; /&#x60;date_of_getting_analytics_lvl_0_5&#x60; /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60; /&#x60;rolesInRelatedProjects&#x60;/&#x60;rejectionReason&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRating(
      name?: Array<string>,
      firstName?: Array<string>,
      idInvited?: Array<string>,
      lastName?: Array<string>,
      assessmentId?: string,
      phone?: Array<string>,
      isBlocked?: boolean,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      confirmationSingle?: boolean,
      desiredStudyPlace?: Array<string>,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      country?: Array<string>,
      countryNull?: boolean,
      roleInRelatedProjects?: Array<string>,
      region?: Array<number>,
      regionNull?: boolean,
      district?: Array<number>,
      districtNull?: boolean,
      schoolNull?: boolean,
      school?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      statusCompany?: Array<string>,
      statusEmployment?: Array<string>,
      rejectionReason?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityFrom?: number,
      analyticsTenacityTo?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      lastActivityNull?: boolean,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      esiaVerified?: boolean,
      secretCode?: number,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRatingRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRating(
        name,
        firstName,
        idInvited,
        lastName,
        assessmentId,
        phone,
        isBlocked,
        email,
        emailVerified,
        emailFull,
        gender,
        groupCode,
        groupCodeNotNull,
        studyWorkPlace,
        studyWorkPlaceNull,
        confirmationSingle,
        desiredStudyPlace,
        desiredSpecialty,
        desiredSpecialtyNull,
        country,
        countryNull,
        roleInRelatedProjects,
        region,
        regionNull,
        district,
        districtNull,
        schoolNull,
        school,
        locality,
        localityNull,
        birthdateNull,
        birthdateFrom,
        birthdateTo,
        statusCompany,
        statusEmployment,
        rejectionReason,
        gPlaceFrom,
        gPlaceTo,
        gRateFrom,
        gRateTo,
        analyticsFrom,
        analyticsTo,
        analyticsTenacityFrom,
        analyticsTenacityTo,
        commandFrom,
        commandTo,
        commandTenacityFrom,
        commandTenacityTo,
        communicationFrom,
        communicationTo,
        communicationTenacityFrom,
        communicationTenacityTo,
        thinking3dFrom,
        thinking3dTo,
        thinking3dTenacityFrom,
        thinking3dTenacityTo,
        economicsFrom,
        economicsTo,
        economicsTenacityFrom,
        economicsTenacityTo,
        jurisprudenceFrom,
        jurisprudenceTo,
        jurisprudenceTenacityFrom,
        jurisprudenceTenacityTo,
        microelectronicsTo,
        microelectronicsFrom,
        microelectronicsTenacityTo,
        microelectronicsTenacityFrom,
        createdFrom,
        createdTo,
        lastPlayNull,
        lastPlayFrom,
        lastPlayTo,
        lastActivityFrom,
        lastActivityTo,
        lastActivityNull,
        acquisitionChannel,
        acquisitionChannelNull,
        dateOfGettingAnalyticsLvl05From,
        dateOfGettingAnalyticsLvl05To,
        dateOfGettingAnalyticsLvl1From,
        dateOfGettingAnalyticsLvl1To,
        esiaVerified,
        secretCode,
        orderBy,
        pageNum,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Output players count by their rating
     * @summary Get Players rating chart data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRatingChart(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RatingChartDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRatingChart(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * RatingsApi - factory interface
 * @export
 */
export const RatingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RatingsApiFp(configuration);
  return {
    /**
     * Find players with specified fields by AND clause
     * @summary Get Players rating info
     * @param {Array<string>} [name] filter players by firstName OR lastName
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [idInvited] UUID of users invited to the session
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [assessmentId]
     * @param {Array<string>} [phone] filter players by phone
     * @param {boolean} [isBlocked] filter players by isBlocked
     * @param {Array<string>} [email] filter players by email
     * @param {Array<boolean>} [emailVerified] filter players by emailVerified
     * @param {boolean} [emailFull] filter players by EmailFull
     * @param {Array<string>} [gender] filter players by gender (&#x60;ILIKE \&#39;x\&#39;&#x60;)
     * @param {Array<string>} [groupCode] filter players by groupCode (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [groupCodeNotNull] get players with not null values
     * @param {Array<string>} [studyWorkPlace] filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [studyWorkPlaceNull] filter players by studyWorkPlaceNull
     * @param {boolean} [confirmationSingle] filter players by confirmationSingle
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [desiredSpecialty] filter players by desiredSpecialty (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {boolean} [desiredSpecialtyNull] filter players by desiredSpecialtyNull
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {Array<string>} [roleInRelatedProjects] filter players by roleInRelatedProjects
     * @param {Array<number>} [region] filter players by region
     * @param {boolean} [regionNull] filter players by regionNull
     * @param {Array<number>} [district] filter players by district
     * @param {boolean} [districtNull] filter players by districtNull
     * @param {boolean} [schoolNull] filter players by schoolNul
     * @param {Array<number>} [school] filter players by school
     * @param {Array<string>} [locality] filter players by locality
     * @param {boolean} [localityNull] filter players by localityNull
     * @param {boolean} [birthdateNull] filter players by birthdateNull
     * @param {string} [birthdateFrom] filter players by birthdateFrom
     * @param {string} [birthdateTo] filter players by birthdateTo
     * @param {Array<string>} [statusCompany] filter players by statusCompany
     * @param {Array<string>} [statusEmployment] filter players by statusEmployment
     * @param {Array<string>} [rejectionReason] filter players by rejectionReason
     * @param {number} [gPlaceFrom] filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gPlaceTo] filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
     * @param {number} [gRateFrom] filter players by global rate, from, min 0, max 10
     * @param {number} [gRateTo] filter players by global rate, To, min 0, max 10
     * @param {number} [analyticsFrom] filter players by competence, from, min 0, max 10
     * @param {number} [analyticsTo] filter players by competence, to, min 0, max 10
     * @param {number} [analyticsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [analyticsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandFrom] filter players by competence, from, min 0, max 10
     * @param {number} [commandTo] filter players by competence, to, min 0, max 10
     * @param {number} [commandTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [commandTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationFrom] filter players by competence, from, min 0, max 10
     * @param {number} [communicationTo] filter players by competence, to, min 0, max 10
     * @param {number} [communicationTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [communicationTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dFrom] filter players by thinking3d, from, min 0, max 10
     * @param {number} [thinking3dTo] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [thinking3dTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [economicsFrom] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTo] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [economicsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceFrom] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTo] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {number} [jurisprudenceTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsFrom] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTenacityTo] filter players by competence, to, min 0, max 10
     * @param {number} [microelectronicsTenacityFrom] filter players by competence, to, min 0, max 10
     * @param {string} [createdFrom] filter players by createdAt, from
     * @param {string} [createdTo] filter players by createdAt, to
     * @param {boolean} [lastPlayNull] filter players by lastPlayNull
     * @param {string} [lastPlayFrom] filter players by lastPlay, from
     * @param {string} [lastPlayTo] filter players by lastPlay, to
     * @param {string} [lastActivityFrom] filter players by lastActivity, from
     * @param {string} [lastActivityTo] filter players by lastActivity, to
     * @param {boolean} [lastActivityNull] filter players by lastActivityNull
     * @param {Array<string>} [acquisitionChannel] filter players by acquisitionChannel
     * @param {boolean} [acquisitionChannelNull] filter players by acquisitionNullChannel
     * @param {string} [dateOfGettingAnalyticsLvl05From] filter players by date_of_getting_analytics_lvl_0_5, from
     * @param {string} [dateOfGettingAnalyticsLvl05To] filter players by date_of_getting_analytics_lvl_0_5, to
     * @param {string} [dateOfGettingAnalyticsLvl1From] filter players by date_of_getting_analytics_lvl_1, from
     * @param {string} [dateOfGettingAnalyticsLvl1To] filter players by date_of_getting_analytics_lvl_1, to
     * @param {boolean} [esiaVerified] filter players by esiaVerified
     * @param {number} [secretCode] filter players by secret_code
     * @param {Array<string>} [orderBy] sort players by fields &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60; /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60; /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60; /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60; /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60; /&#x60;acquisitionChannel&#x60;/&#x60;resume&#x60; /&#x60;date_of_getting_analytics_lvl_0_5&#x60; /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60; /&#x60;rolesInRelatedProjects&#x60;/&#x60;rejectionReason&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRating(
      name?: Array<string>,
      firstName?: Array<string>,
      idInvited?: Array<string>,
      lastName?: Array<string>,
      assessmentId?: string,
      phone?: Array<string>,
      isBlocked?: boolean,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      confirmationSingle?: boolean,
      desiredStudyPlace?: Array<string>,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      country?: Array<string>,
      countryNull?: boolean,
      roleInRelatedProjects?: Array<string>,
      region?: Array<number>,
      regionNull?: boolean,
      district?: Array<number>,
      districtNull?: boolean,
      schoolNull?: boolean,
      school?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      statusCompany?: Array<string>,
      statusEmployment?: Array<string>,
      rejectionReason?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityFrom?: number,
      analyticsTenacityTo?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      lastActivityNull?: boolean,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      esiaVerified?: boolean,
      secretCode?: number,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetRatingRes> {
      return localVarFp
        .getRating(
          name,
          firstName,
          idInvited,
          lastName,
          assessmentId,
          phone,
          isBlocked,
          email,
          emailVerified,
          emailFull,
          gender,
          groupCode,
          groupCodeNotNull,
          studyWorkPlace,
          studyWorkPlaceNull,
          confirmationSingle,
          desiredStudyPlace,
          desiredSpecialty,
          desiredSpecialtyNull,
          country,
          countryNull,
          roleInRelatedProjects,
          region,
          regionNull,
          district,
          districtNull,
          schoolNull,
          school,
          locality,
          localityNull,
          birthdateNull,
          birthdateFrom,
          birthdateTo,
          statusCompany,
          statusEmployment,
          rejectionReason,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          analyticsTenacityFrom,
          analyticsTenacityTo,
          commandFrom,
          commandTo,
          commandTenacityFrom,
          commandTenacityTo,
          communicationFrom,
          communicationTo,
          communicationTenacityFrom,
          communicationTenacityTo,
          thinking3dFrom,
          thinking3dTo,
          thinking3dTenacityFrom,
          thinking3dTenacityTo,
          economicsFrom,
          economicsTo,
          economicsTenacityFrom,
          economicsTenacityTo,
          jurisprudenceFrom,
          jurisprudenceTo,
          jurisprudenceTenacityFrom,
          jurisprudenceTenacityTo,
          microelectronicsTo,
          microelectronicsFrom,
          microelectronicsTenacityTo,
          microelectronicsTenacityFrom,
          createdFrom,
          createdTo,
          lastPlayNull,
          lastPlayFrom,
          lastPlayTo,
          lastActivityFrom,
          lastActivityTo,
          lastActivityNull,
          acquisitionChannel,
          acquisitionChannelNull,
          dateOfGettingAnalyticsLvl05From,
          dateOfGettingAnalyticsLvl05To,
          dateOfGettingAnalyticsLvl1From,
          dateOfGettingAnalyticsLvl1To,
          esiaVerified,
          secretCode,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Output players count by their rating
     * @summary Get Players rating chart data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRatingChart(options?: any): AxiosPromise<RatingChartDto> {
      return localVarFp
        .getRatingChart(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getRating operation in RatingsApi.
 * @export
 * @interface RatingsApiGetRatingRequest
 */
export interface RatingsApiGetRatingRequest {
  /**
   * filter players by firstName OR lastName
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly name?: Array<string>;

  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly firstName?: Array<string>;

  /**
   * UUID of users invited to the session
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly idInvited?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly lastName?: Array<string>;

  /**
   *
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly assessmentId?: string;

  /**
   * filter players by phone
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly phone?: Array<string>;

  /**
   * filter players by isBlocked
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly isBlocked?: boolean;

  /**
   * filter players by email
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly email?: Array<string>;

  /**
   * filter players by emailVerified
   * @type {Array<boolean>}
   * @memberof RatingsApiGetRating
   */
  readonly emailVerified?: Array<boolean>;

  /**
   * filter players by EmailFull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly emailFull?: boolean;

  /**
   * filter players by gender (&#x60;ILIKE \&#39;x\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly gender?: Array<string>;

  /**
   * filter players by groupCode (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly groupCode?: Array<string>;

  /**
   * get players with not null values
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly groupCodeNotNull?: boolean;

  /**
   * filter players by studyWorkPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly studyWorkPlace?: Array<string>;

  /**
   * filter players by studyWorkPlaceNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly studyWorkPlaceNull?: boolean;

  /**
   * filter players by confirmationSingle
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly confirmationSingle?: boolean;

  /**
   * filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly desiredStudyPlace?: Array<string>;

  /**
   * filter players by desiredSpecialty (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly desiredSpecialty?: Array<string>;

  /**
   * filter players by desiredSpecialtyNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly desiredSpecialtyNull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly country?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly countryNull?: boolean;

  /**
   * filter players by roleInRelatedProjects
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly roleInRelatedProjects?: Array<string>;

  /**
   * filter players by region
   * @type {Array<number>}
   * @memberof RatingsApiGetRating
   */
  readonly region?: Array<number>;

  /**
   * filter players by regionNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly regionNull?: boolean;

  /**
   * filter players by district
   * @type {Array<number>}
   * @memberof RatingsApiGetRating
   */
  readonly district?: Array<number>;

  /**
   * filter players by districtNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly districtNull?: boolean;

  /**
   * filter players by schoolNul
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly schoolNull?: boolean;

  /**
   * filter players by school
   * @type {Array<number>}
   * @memberof RatingsApiGetRating
   */
  readonly school?: Array<number>;

  /**
   * filter players by locality
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly locality?: Array<string>;

  /**
   * filter players by localityNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly localityNull?: boolean;

  /**
   * filter players by birthdateNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly birthdateNull?: boolean;

  /**
   * filter players by birthdateFrom
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly birthdateFrom?: string;

  /**
   * filter players by birthdateTo
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly birthdateTo?: string;

  /**
   * filter players by statusCompany
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly statusCompany?: Array<string>;

  /**
   * filter players by statusEmployment
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly statusEmployment?: Array<string>;

  /**
   * filter players by rejectionReason
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly rejectionReason?: Array<string>;

  /**
   * filter players by global place, from, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly gPlaceFrom?: number;

  /**
   * filter players by global place, to, min 0. If &#x60;gPlaceFrom&#x60; or &#x60;gPlaceTo&#x60; are present, all the other filters are ignored.
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly gPlaceTo?: number;

  /**
   * filter players by global rate, from, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly gRateFrom?: number;

  /**
   * filter players by global rate, To, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly gRateTo?: number;

  /**
   * filter players by competence, from, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly analyticsFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly analyticsTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly analyticsTenacityFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly analyticsTenacityTo?: number;

  /**
   * filter players by competence, from, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly commandFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly commandTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly commandTenacityFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly commandTenacityTo?: number;

  /**
   * filter players by competence, from, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly communicationFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly communicationTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly communicationTenacityFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly communicationTenacityTo?: number;

  /**
   * filter players by thinking3d, from, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly thinking3dFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly thinking3dTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly thinking3dTenacityFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly thinking3dTenacityTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly economicsFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly economicsTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly economicsTenacityFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly economicsTenacityTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly jurisprudenceFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly jurisprudenceTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly jurisprudenceTenacityFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly jurisprudenceTenacityTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly microelectronicsTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly microelectronicsFrom?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly microelectronicsTenacityTo?: number;

  /**
   * filter players by competence, to, min 0, max 10
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly microelectronicsTenacityFrom?: number;

  /**
   * filter players by createdAt, from
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly createdFrom?: string;

  /**
   * filter players by createdAt, to
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly createdTo?: string;

  /**
   * filter players by lastPlayNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly lastPlayNull?: boolean;

  /**
   * filter players by lastPlay, from
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly lastPlayFrom?: string;

  /**
   * filter players by lastPlay, to
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly lastPlayTo?: string;

  /**
   * filter players by lastActivity, from
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly lastActivityFrom?: string;

  /**
   * filter players by lastActivity, to
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly lastActivityTo?: string;

  /**
   * filter players by lastActivityNull
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly lastActivityNull?: boolean;

  /**
   * filter players by acquisitionChannel
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly acquisitionChannel?: Array<string>;

  /**
   * filter players by acquisitionNullChannel
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly acquisitionChannelNull?: boolean;

  /**
   * filter players by date_of_getting_analytics_lvl_0_5, from
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly dateOfGettingAnalyticsLvl05From?: string;

  /**
   * filter players by date_of_getting_analytics_lvl_0_5, to
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly dateOfGettingAnalyticsLvl05To?: string;

  /**
   * filter players by date_of_getting_analytics_lvl_1, from
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly dateOfGettingAnalyticsLvl1From?: string;

  /**
   * filter players by date_of_getting_analytics_lvl_1, to
   * @type {string}
   * @memberof RatingsApiGetRating
   */
  readonly dateOfGettingAnalyticsLvl1To?: string;

  /**
   * filter players by esiaVerified
   * @type {boolean}
   * @memberof RatingsApiGetRating
   */
  readonly esiaVerified?: boolean;

  /**
   * filter players by secret_code
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly secretCode?: number;

  /**
   * sort players by fields &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60; /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60; /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60; /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60; /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60; /&#x60;acquisitionChannel&#x60;/&#x60;resume&#x60; /&#x60;date_of_getting_analytics_lvl_0_5&#x60; /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60; /&#x60;rolesInRelatedProjects&#x60;/&#x60;rejectionReason&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof RatingsApiGetRating
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof RatingsApiGetRating
   */
  readonly pageSize?: number;
}

/**
 * RatingsApi - object-oriented interface
 * @export
 * @class RatingsApi
 * @extends {BaseAPI}
 */
export class RatingsApi extends BaseAPI {
  /**
   * Find players with specified fields by AND clause
   * @summary Get Players rating info
   * @param {RatingsApiGetRatingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatingsApi
   */
  public getRating(
    requestParameters: RatingsApiGetRatingRequest = {},
    options?: any,
  ) {
    return RatingsApiFp(this.configuration)
      .getRating(
        requestParameters.name,
        requestParameters.firstName,
        requestParameters.idInvited,
        requestParameters.lastName,
        requestParameters.assessmentId,
        requestParameters.phone,
        requestParameters.isBlocked,
        requestParameters.email,
        requestParameters.emailVerified,
        requestParameters.emailFull,
        requestParameters.gender,
        requestParameters.groupCode,
        requestParameters.groupCodeNotNull,
        requestParameters.studyWorkPlace,
        requestParameters.studyWorkPlaceNull,
        requestParameters.confirmationSingle,
        requestParameters.desiredStudyPlace,
        requestParameters.desiredSpecialty,
        requestParameters.desiredSpecialtyNull,
        requestParameters.country,
        requestParameters.countryNull,
        requestParameters.roleInRelatedProjects,
        requestParameters.region,
        requestParameters.regionNull,
        requestParameters.district,
        requestParameters.districtNull,
        requestParameters.schoolNull,
        requestParameters.school,
        requestParameters.locality,
        requestParameters.localityNull,
        requestParameters.birthdateNull,
        requestParameters.birthdateFrom,
        requestParameters.birthdateTo,
        requestParameters.statusCompany,
        requestParameters.statusEmployment,
        requestParameters.rejectionReason,
        requestParameters.gPlaceFrom,
        requestParameters.gPlaceTo,
        requestParameters.gRateFrom,
        requestParameters.gRateTo,
        requestParameters.analyticsFrom,
        requestParameters.analyticsTo,
        requestParameters.analyticsTenacityFrom,
        requestParameters.analyticsTenacityTo,
        requestParameters.commandFrom,
        requestParameters.commandTo,
        requestParameters.commandTenacityFrom,
        requestParameters.commandTenacityTo,
        requestParameters.communicationFrom,
        requestParameters.communicationTo,
        requestParameters.communicationTenacityFrom,
        requestParameters.communicationTenacityTo,
        requestParameters.thinking3dFrom,
        requestParameters.thinking3dTo,
        requestParameters.thinking3dTenacityFrom,
        requestParameters.thinking3dTenacityTo,
        requestParameters.economicsFrom,
        requestParameters.economicsTo,
        requestParameters.economicsTenacityFrom,
        requestParameters.economicsTenacityTo,
        requestParameters.jurisprudenceFrom,
        requestParameters.jurisprudenceTo,
        requestParameters.jurisprudenceTenacityFrom,
        requestParameters.jurisprudenceTenacityTo,
        requestParameters.microelectronicsTo,
        requestParameters.microelectronicsFrom,
        requestParameters.microelectronicsTenacityTo,
        requestParameters.microelectronicsTenacityFrom,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.lastPlayNull,
        requestParameters.lastPlayFrom,
        requestParameters.lastPlayTo,
        requestParameters.lastActivityFrom,
        requestParameters.lastActivityTo,
        requestParameters.lastActivityNull,
        requestParameters.acquisitionChannel,
        requestParameters.acquisitionChannelNull,
        requestParameters.dateOfGettingAnalyticsLvl05From,
        requestParameters.dateOfGettingAnalyticsLvl05To,
        requestParameters.dateOfGettingAnalyticsLvl1From,
        requestParameters.dateOfGettingAnalyticsLvl1To,
        requestParameters.esiaVerified,
        requestParameters.secretCode,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Output players count by their rating
   * @summary Get Players rating chart data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatingsApi
   */
  public getRatingChart(options?: any) {
    return RatingsApiFp(this.configuration)
      .getRatingChart(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RelativeApi - axios parameter creator
 * @export
 */
export const RelativeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add to candidate relative for player
     * @summary Add relative
     * @param {IdRelativeLinkDto} uuidRelative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addCandidateRelative: async (
      uuidRelative: IdRelativeLinkDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'uuidRelative' is not null or undefined
      assertParamExists("addCandidateRelative", "uuidRelative", uuidRelative);
      const localVarPath = `/api/v1/add-relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uuidRelative,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cancel confirmation relative for player
     * @summary Cancel Confirmation relative
     * @param {CancelConfirmationRelative} cancelConfirmationRelative Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelConfirmationRelative: async (
      cancelConfirmationRelative: CancelConfirmationRelative,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cancelConfirmationRelative' is not null or undefined
      assertParamExists(
        "cancelConfirmationRelative",
        "cancelConfirmationRelative",
        cancelConfirmationRelative,
      );
      const localVarPath = `/api/v1/cancel-confirmation-relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelConfirmationRelative,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Confirmation relative for player
     * @summary Confirmation relative
     * @param {ConfirmationRelative} confirmationRelatives Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmationRelative: async (
      confirmationRelatives: ConfirmationRelative,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'confirmationRelatives' is not null or undefined
      assertParamExists(
        "confirmationRelative",
        "confirmationRelatives",
        confirmationRelatives,
      );
      const localVarPath = `/api/v1/confirmation-relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        confirmationRelatives,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create link for relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLinkRelative: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/relative/create-link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get candidates relative for admin table
     * @summary Get candidates relative for admin table
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [firstNameStudent] First Name
     * @param {string} [lastNameStudent] Last Name
     * @param {Array<string>} [phoneStudent] filter players by phoneStudent
     * @param {Array<string>} [phone] filter players by phone
     * @param {boolean} [confirmed] filter player by confirmed
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/&#x60;confirmed&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCandidateRelativeTable: async (
      firstName?: Array<string>,
      lastName?: Array<string>,
      firstNameStudent?: string,
      lastNameStudent?: string,
      phoneStudent?: Array<string>,
      phone?: Array<string>,
      confirmed?: boolean,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/relative-table`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (firstNameStudent !== undefined) {
        localVarQueryParameter["firstNameStudent"] = firstNameStudent;
      }

      if (lastNameStudent !== undefined) {
        localVarQueryParameter["lastNameStudent"] = lastNameStudent;
      }

      if (phoneStudent) {
        localVarQueryParameter["phoneStudent"] = phoneStudent;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (confirmed !== undefined) {
        localVarQueryParameter["confirmed"] = confirmed;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get change relative candidate history
     * @summary Get change relative candidate history
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [firstNameRelative] filter relative by firstName
     * @param {Array<string>} [lastNameRelative] filter relative by lastName
     * @param {string} [firstNameStudent] First Name
     * @param {string} [lastNameStudent] Last Name
     * @param {Array<string>} [phoneStudent] filter players by phoneStudent
     * @param {Array<string>} [phoneRelative] filter players by phoneRelative
     * @param {string} [dateOfChangeFrom] filter players by date change
     * @param {string} [dateOfChangeTo] filter players by date change
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/ &#x60;firstNameRelative&#x60;/&#x60;lastNameRelative&#x60;/&#x60;date_of_changes&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRelativeTable: async (
      firstName?: Array<string>,
      lastName?: Array<string>,
      firstNameRelative?: Array<string>,
      lastNameRelative?: Array<string>,
      firstNameStudent?: string,
      lastNameStudent?: string,
      phoneStudent?: Array<string>,
      phoneRelative?: Array<string>,
      dateOfChangeFrom?: string,
      dateOfChangeTo?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/relative-change`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (firstNameRelative) {
        localVarQueryParameter["firstNameRelative"] = firstNameRelative;
      }

      if (lastNameRelative) {
        localVarQueryParameter["lastNameRelative"] = lastNameRelative;
      }

      if (firstNameStudent !== undefined) {
        localVarQueryParameter["firstNameStudent"] = firstNameStudent;
      }

      if (lastNameStudent !== undefined) {
        localVarQueryParameter["lastNameStudent"] = lastNameStudent;
      }

      if (phoneStudent) {
        localVarQueryParameter["phoneStudent"] = phoneStudent;
      }

      if (phoneRelative) {
        localVarQueryParameter["phoneRelative"] = phoneRelative;
      }

      if (dateOfChangeFrom !== undefined) {
        localVarQueryParameter["dateOfChangeFrom"] = dateOfChangeFrom;
      }

      if (dateOfChangeTo !== undefined) {
        localVarQueryParameter["dateOfChangeTo"] = dateOfChangeTo;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get employee to receiving status employee
     * @summary Get application to receiving status employee
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployeeApplication: async (
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/employee-application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get link for relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinkRelative: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/relative/create-link`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all relative for player
     * @summary Get relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatives: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get application to receiving status student
     * @summary Get application to receiving status student
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudentApplication: async (
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/student-application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get student firstName, lastName for link_uuid
     * @param {string} linkId Link id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudentForLink: async (
      linkId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkId' is not null or undefined
      assertParamExists("getStudentForLink", "linkId", linkId);
      const localVarPath = `/api/v1/relative/get-student/{link_id}`.replace(
        `{${"link_id"}}`,
        encodeURIComponent(String(linkId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Untie relative for player
     * @summary Untie relative
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    untieRelative: async (
      pId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("untieRelative", "pId", pId);
      const localVarPath = `/api/v1/untie-relative/{pId}`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RelativeApi - functional programming interface
 * @export
 */
export const RelativeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RelativeApiAxiosParamCreator(configuration);
  return {
    /**
     * Add to candidate relative for player
     * @summary Add relative
     * @param {IdRelativeLinkDto} uuidRelative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addCandidateRelative(
      uuidRelative: IdRelativeLinkDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerRelativeInfoRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addCandidateRelative(
          uuidRelative,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cancel confirmation relative for player
     * @summary Cancel Confirmation relative
     * @param {CancelConfirmationRelative} cancelConfirmationRelative Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelConfirmationRelative(
      cancelConfirmationRelative: CancelConfirmationRelative,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ConfirmationRelativeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelConfirmationRelative(
          cancelConfirmationRelative,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Confirmation relative for player
     * @summary Confirmation relative
     * @param {ConfirmationRelative} confirmationRelatives Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmationRelative(
      confirmationRelatives: ConfirmationRelative,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ConfirmationRelativeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.confirmationRelative(
          confirmationRelatives,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create link for relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLinkRelative(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RelativeLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createLinkRelative(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get candidates relative for admin table
     * @summary Get candidates relative for admin table
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [firstNameStudent] First Name
     * @param {string} [lastNameStudent] Last Name
     * @param {Array<string>} [phoneStudent] filter players by phoneStudent
     * @param {Array<string>} [phone] filter players by phone
     * @param {boolean} [confirmed] filter player by confirmed
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/&#x60;confirmed&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCandidateRelativeTable(
      firstName?: Array<string>,
      lastName?: Array<string>,
      firstNameStudent?: string,
      lastNameStudent?: string,
      phoneStudent?: Array<string>,
      phone?: Array<string>,
      confirmed?: boolean,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetRelativesTable>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCandidateRelativeTable(
          firstName,
          lastName,
          firstNameStudent,
          lastNameStudent,
          phoneStudent,
          phone,
          confirmed,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get change relative candidate history
     * @summary Get change relative candidate history
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [firstNameRelative] filter relative by firstName
     * @param {Array<string>} [lastNameRelative] filter relative by lastName
     * @param {string} [firstNameStudent] First Name
     * @param {string} [lastNameStudent] Last Name
     * @param {Array<string>} [phoneStudent] filter players by phoneStudent
     * @param {Array<string>} [phoneRelative] filter players by phoneRelative
     * @param {string} [dateOfChangeFrom] filter players by date change
     * @param {string} [dateOfChangeTo] filter players by date change
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/ &#x60;firstNameRelative&#x60;/&#x60;lastNameRelative&#x60;/&#x60;date_of_changes&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChangeRelativeTable(
      firstName?: Array<string>,
      lastName?: Array<string>,
      firstNameRelative?: Array<string>,
      lastNameRelative?: Array<string>,
      firstNameStudent?: string,
      lastNameStudent?: string,
      phoneStudent?: Array<string>,
      phoneRelative?: Array<string>,
      dateOfChangeFrom?: string,
      dateOfChangeTo?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetRelativesBindTable>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getChangeRelativeTable(
          firstName,
          lastName,
          firstNameRelative,
          lastNameRelative,
          firstNameStudent,
          lastNameStudent,
          phoneStudent,
          phoneRelative,
          dateOfChangeFrom,
          dateOfChangeTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get employee to receiving status employee
     * @summary Get application to receiving status employee
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmployeeApplication(
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCandidateEmployeeApplications>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEmployeeApplication(
          firstName,
          lastName,
          phone,
          desiredStudyPlace,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get link for relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLinkRelative(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RelativeLinkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLinkRelative(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get all relative for player
     * @summary Get relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelatives(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelatives>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRelatives(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get application to receiving status student
     * @summary Get application to receiving status student
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudentApplication(
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCandidateStudentApplications>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStudentApplication(
          firstName,
          lastName,
          phone,
          desiredStudyPlace,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get student firstName, lastName for link_uuid
     * @param {string} linkId Link id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudentForLink(
      linkId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PlayerRelativeInfoRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStudentForLink(linkId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Untie relative for player
     * @summary Untie relative
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async untieRelative(
      pId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.untieRelative(
        pId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * RelativeApi - factory interface
 * @export
 */
export const RelativeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RelativeApiFp(configuration);
  return {
    /**
     * Add to candidate relative for player
     * @summary Add relative
     * @param {IdRelativeLinkDto} uuidRelative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addCandidateRelative(
      uuidRelative: IdRelativeLinkDto,
      options?: any,
    ): AxiosPromise<PlayerRelativeInfoRes> {
      return localVarFp
        .addCandidateRelative(uuidRelative, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cancel confirmation relative for player
     * @summary Cancel Confirmation relative
     * @param {CancelConfirmationRelative} cancelConfirmationRelative Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelConfirmationRelative(
      cancelConfirmationRelative: CancelConfirmationRelative,
      options?: any,
    ): AxiosPromise<ConfirmationRelativeResponse> {
      return localVarFp
        .cancelConfirmationRelative(cancelConfirmationRelative, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Confirmation relative for player
     * @summary Confirmation relative
     * @param {ConfirmationRelative} confirmationRelatives Fines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmationRelative(
      confirmationRelatives: ConfirmationRelative,
      options?: any,
    ): AxiosPromise<ConfirmationRelativeResponse> {
      return localVarFp
        .confirmationRelative(confirmationRelatives, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create link for relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLinkRelative(options?: any): AxiosPromise<RelativeLinkResponse> {
      return localVarFp
        .createLinkRelative(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get candidates relative for admin table
     * @summary Get candidates relative for admin table
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {string} [firstNameStudent] First Name
     * @param {string} [lastNameStudent] Last Name
     * @param {Array<string>} [phoneStudent] filter players by phoneStudent
     * @param {Array<string>} [phone] filter players by phone
     * @param {boolean} [confirmed] filter player by confirmed
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/&#x60;confirmed&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCandidateRelativeTable(
      firstName?: Array<string>,
      lastName?: Array<string>,
      firstNameStudent?: string,
      lastNameStudent?: string,
      phoneStudent?: Array<string>,
      phone?: Array<string>,
      confirmed?: boolean,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetRelativesTable> {
      return localVarFp
        .getCandidateRelativeTable(
          firstName,
          lastName,
          firstNameStudent,
          lastNameStudent,
          phoneStudent,
          phone,
          confirmed,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get change relative candidate history
     * @summary Get change relative candidate history
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [firstNameRelative] filter relative by firstName
     * @param {Array<string>} [lastNameRelative] filter relative by lastName
     * @param {string} [firstNameStudent] First Name
     * @param {string} [lastNameStudent] Last Name
     * @param {Array<string>} [phoneStudent] filter players by phoneStudent
     * @param {Array<string>} [phoneRelative] filter players by phoneRelative
     * @param {string} [dateOfChangeFrom] filter players by date change
     * @param {string} [dateOfChangeTo] filter players by date change
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/ &#x60;firstNameRelative&#x60;/&#x60;lastNameRelative&#x60;/&#x60;date_of_changes&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChangeRelativeTable(
      firstName?: Array<string>,
      lastName?: Array<string>,
      firstNameRelative?: Array<string>,
      lastNameRelative?: Array<string>,
      firstNameStudent?: string,
      lastNameStudent?: string,
      phoneStudent?: Array<string>,
      phoneRelative?: Array<string>,
      dateOfChangeFrom?: string,
      dateOfChangeTo?: string,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetRelativesBindTable> {
      return localVarFp
        .getChangeRelativeTable(
          firstName,
          lastName,
          firstNameRelative,
          lastNameRelative,
          firstNameStudent,
          lastNameStudent,
          phoneStudent,
          phoneRelative,
          dateOfChangeFrom,
          dateOfChangeTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get employee to receiving status employee
     * @summary Get application to receiving status employee
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployeeApplication(
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetCandidateEmployeeApplications> {
      return localVarFp
        .getEmployeeApplication(
          firstName,
          lastName,
          phone,
          desiredStudyPlace,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get link for relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinkRelative(options?: any): AxiosPromise<RelativeLinkResponse> {
      return localVarFp
        .getLinkRelative(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all relative for player
     * @summary Get relative
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatives(options?: any): AxiosPromise<GetRelatives> {
      return localVarFp
        .getRelatives(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get application to receiving status student
     * @summary Get application to receiving status student
     * @param {Array<string>} [firstName] filter players by firstName
     * @param {Array<string>} [lastName] filter players by lastName
     * @param {Array<string>} [phone] filter players by phone
     * @param {Array<string>} [desiredStudyPlace] filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
     * @param {Array<string>} [orderBy] sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudentApplication(
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetCandidateStudentApplications> {
      return localVarFp
        .getStudentApplication(
          firstName,
          lastName,
          phone,
          desiredStudyPlace,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get student firstName, lastName for link_uuid
     * @param {string} linkId Link id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudentForLink(
      linkId: string,
      options?: any,
    ): AxiosPromise<PlayerRelativeInfoRes> {
      return localVarFp
        .getStudentForLink(linkId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Untie relative for player
     * @summary Untie relative
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    untieRelative(pId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .untieRelative(pId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addCandidateRelative operation in RelativeApi.
 * @export
 * @interface RelativeApiAddCandidateRelativeRequest
 */
export interface RelativeApiAddCandidateRelativeRequest {
  /**
   *
   * @type {IdRelativeLinkDto}
   * @memberof RelativeApiAddCandidateRelative
   */
  readonly uuidRelative: IdRelativeLinkDto;
}

/**
 * Request parameters for cancelConfirmationRelative operation in RelativeApi.
 * @export
 * @interface RelativeApiCancelConfirmationRelativeRequest
 */
export interface RelativeApiCancelConfirmationRelativeRequest {
  /**
   * Fines
   * @type {CancelConfirmationRelative}
   * @memberof RelativeApiCancelConfirmationRelative
   */
  readonly cancelConfirmationRelative: CancelConfirmationRelative;
}

/**
 * Request parameters for confirmationRelative operation in RelativeApi.
 * @export
 * @interface RelativeApiConfirmationRelativeRequest
 */
export interface RelativeApiConfirmationRelativeRequest {
  /**
   * Fines
   * @type {ConfirmationRelative}
   * @memberof RelativeApiConfirmationRelative
   */
  readonly confirmationRelatives: ConfirmationRelative;
}

/**
 * Request parameters for getCandidateRelativeTable operation in RelativeApi.
 * @export
 * @interface RelativeApiGetCandidateRelativeTableRequest
 */
export interface RelativeApiGetCandidateRelativeTableRequest {
  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly lastName?: Array<string>;

  /**
   * First Name
   * @type {string}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly firstNameStudent?: string;

  /**
   * Last Name
   * @type {string}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly lastNameStudent?: string;

  /**
   * filter players by phoneStudent
   * @type {Array<string>}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly phoneStudent?: Array<string>;

  /**
   * filter players by phone
   * @type {Array<string>}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly phone?: Array<string>;

  /**
   * filter player by confirmed
   * @type {boolean}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly confirmed?: boolean;

  /**
   * sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/&#x60;confirmed&#x60;, with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof RelativeApiGetCandidateRelativeTable
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getChangeRelativeTable operation in RelativeApi.
 * @export
 * @interface RelativeApiGetChangeRelativeTableRequest
 */
export interface RelativeApiGetChangeRelativeTableRequest {
  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly lastName?: Array<string>;

  /**
   * filter relative by firstName
   * @type {Array<string>}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly firstNameRelative?: Array<string>;

  /**
   * filter relative by lastName
   * @type {Array<string>}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly lastNameRelative?: Array<string>;

  /**
   * First Name
   * @type {string}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly firstNameStudent?: string;

  /**
   * Last Name
   * @type {string}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly lastNameStudent?: string;

  /**
   * filter players by phoneStudent
   * @type {Array<string>}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly phoneStudent?: Array<string>;

  /**
   * filter players by phoneRelative
   * @type {Array<string>}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly phoneRelative?: Array<string>;

  /**
   * filter players by date change
   * @type {string}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly dateOfChangeFrom?: string;

  /**
   * filter players by date change
   * @type {string}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly dateOfChangeTo?: string;

  /**
   * sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;firstNameStudent&#x60;/&#x60;lastNameStudent&#x60;/ &#x60;firstNameRelative&#x60;/&#x60;lastNameRelative&#x60;/&#x60;date_of_changes&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof RelativeApiGetChangeRelativeTable
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getEmployeeApplication operation in RelativeApi.
 * @export
 * @interface RelativeApiGetEmployeeApplicationRequest
 */
export interface RelativeApiGetEmployeeApplicationRequest {
  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof RelativeApiGetEmployeeApplication
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof RelativeApiGetEmployeeApplication
   */
  readonly lastName?: Array<string>;

  /**
   * filter players by phone
   * @type {Array<string>}
   * @memberof RelativeApiGetEmployeeApplication
   */
  readonly phone?: Array<string>;

  /**
   * filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof RelativeApiGetEmployeeApplication
   */
  readonly desiredStudyPlace?: Array<string>;

  /**
   * sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof RelativeApiGetEmployeeApplication
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof RelativeApiGetEmployeeApplication
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof RelativeApiGetEmployeeApplication
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getStudentApplication operation in RelativeApi.
 * @export
 * @interface RelativeApiGetStudentApplicationRequest
 */
export interface RelativeApiGetStudentApplicationRequest {
  /**
   * filter players by firstName
   * @type {Array<string>}
   * @memberof RelativeApiGetStudentApplication
   */
  readonly firstName?: Array<string>;

  /**
   * filter players by lastName
   * @type {Array<string>}
   * @memberof RelativeApiGetStudentApplication
   */
  readonly lastName?: Array<string>;

  /**
   * filter players by phone
   * @type {Array<string>}
   * @memberof RelativeApiGetStudentApplication
   */
  readonly phone?: Array<string>;

  /**
   * filter players by desiredStudyPlace (&#x60;ILIKE \&#39;%x%\&#39;&#x60;)
   * @type {Array<string>}
   * @memberof RelativeApiGetStudentApplication
   */
  readonly desiredStudyPlace?: Array<string>;

  /**
   * sort players by fields &#x60;firstName&#x60;/&#x60;lastName&#x60; with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof RelativeApiGetStudentApplication
   */
  readonly orderBy?: Array<string>;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof RelativeApiGetStudentApplication
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof RelativeApiGetStudentApplication
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getStudentForLink operation in RelativeApi.
 * @export
 * @interface RelativeApiGetStudentForLinkRequest
 */
export interface RelativeApiGetStudentForLinkRequest {
  /**
   * Link id
   * @type {string}
   * @memberof RelativeApiGetStudentForLink
   */
  readonly linkId: string;
}

/**
 * Request parameters for untieRelative operation in RelativeApi.
 * @export
 * @interface RelativeApiUntieRelativeRequest
 */
export interface RelativeApiUntieRelativeRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof RelativeApiUntieRelative
   */
  readonly pId: string;
}

/**
 * RelativeApi - object-oriented interface
 * @export
 * @class RelativeApi
 * @extends {BaseAPI}
 */
export class RelativeApi extends BaseAPI {
  /**
   * Add to candidate relative for player
   * @summary Add relative
   * @param {RelativeApiAddCandidateRelativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public addCandidateRelative(
    requestParameters: RelativeApiAddCandidateRelativeRequest,
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .addCandidateRelative(requestParameters.uuidRelative, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cancel confirmation relative for player
   * @summary Cancel Confirmation relative
   * @param {RelativeApiCancelConfirmationRelativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public cancelConfirmationRelative(
    requestParameters: RelativeApiCancelConfirmationRelativeRequest,
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .cancelConfirmationRelative(
        requestParameters.cancelConfirmationRelative,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Confirmation relative for player
   * @summary Confirmation relative
   * @param {RelativeApiConfirmationRelativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public confirmationRelative(
    requestParameters: RelativeApiConfirmationRelativeRequest,
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .confirmationRelative(requestParameters.confirmationRelatives, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create link for relative
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public createLinkRelative(options?: any) {
    return RelativeApiFp(this.configuration)
      .createLinkRelative(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get candidates relative for admin table
   * @summary Get candidates relative for admin table
   * @param {RelativeApiGetCandidateRelativeTableRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public getCandidateRelativeTable(
    requestParameters: RelativeApiGetCandidateRelativeTableRequest = {},
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .getCandidateRelativeTable(
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.firstNameStudent,
        requestParameters.lastNameStudent,
        requestParameters.phoneStudent,
        requestParameters.phone,
        requestParameters.confirmed,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get change relative candidate history
   * @summary Get change relative candidate history
   * @param {RelativeApiGetChangeRelativeTableRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public getChangeRelativeTable(
    requestParameters: RelativeApiGetChangeRelativeTableRequest = {},
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .getChangeRelativeTable(
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.firstNameRelative,
        requestParameters.lastNameRelative,
        requestParameters.firstNameStudent,
        requestParameters.lastNameStudent,
        requestParameters.phoneStudent,
        requestParameters.phoneRelative,
        requestParameters.dateOfChangeFrom,
        requestParameters.dateOfChangeTo,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get employee to receiving status employee
   * @summary Get application to receiving status employee
   * @param {RelativeApiGetEmployeeApplicationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public getEmployeeApplication(
    requestParameters: RelativeApiGetEmployeeApplicationRequest = {},
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .getEmployeeApplication(
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phone,
        requestParameters.desiredStudyPlace,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get link for relative
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public getLinkRelative(options?: any) {
    return RelativeApiFp(this.configuration)
      .getLinkRelative(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all relative for player
   * @summary Get relative
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public getRelatives(options?: any) {
    return RelativeApiFp(this.configuration)
      .getRelatives(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get application to receiving status student
   * @summary Get application to receiving status student
   * @param {RelativeApiGetStudentApplicationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public getStudentApplication(
    requestParameters: RelativeApiGetStudentApplicationRequest = {},
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .getStudentApplication(
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phone,
        requestParameters.desiredStudyPlace,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get student firstName, lastName for link_uuid
   * @param {RelativeApiGetStudentForLinkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public getStudentForLink(
    requestParameters: RelativeApiGetStudentForLinkRequest,
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .getStudentForLink(requestParameters.linkId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Untie relative for player
   * @summary Untie relative
   * @param {RelativeApiUntieRelativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeApi
   */
  public untieRelative(
    requestParameters: RelativeApiUntieRelativeRequest,
    options?: any,
  ) {
    return RelativeApiFp(this.configuration)
      .untieRelative(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SidebarApi - axios parameter creator
 * @export
 */
export const SidebarApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get sidebar content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSidebar: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/sidebar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update sidebar content
     * @param {UpdSidebarReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSidebar: async (
      body: UpdSidebarReq,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists("postSidebar", "body", body);
      const localVarPath = `/api/v1/sidebar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SidebarApi - functional programming interface
 * @export
 */
export const SidebarApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SidebarApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get sidebar content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSidebar(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SidebarRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSidebar(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update sidebar content
     * @param {UpdSidebarReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSidebar(
      body: UpdSidebarReq,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SidebarRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postSidebar(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SidebarApi - factory interface
 * @export
 */
export const SidebarApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SidebarApiFp(configuration);
  return {
    /**
     *
     * @summary Get sidebar content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSidebar(options?: any): AxiosPromise<SidebarRes> {
      return localVarFp
        .getSidebar(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update sidebar content
     * @param {UpdSidebarReq} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSidebar(body: UpdSidebarReq, options?: any): AxiosPromise<SidebarRes> {
      return localVarFp
        .postSidebar(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for postSidebar operation in SidebarApi.
 * @export
 * @interface SidebarApiPostSidebarRequest
 */
export interface SidebarApiPostSidebarRequest {
  /**
   *
   * @type {UpdSidebarReq}
   * @memberof SidebarApiPostSidebar
   */
  readonly body: UpdSidebarReq;
}

/**
 * SidebarApi - object-oriented interface
 * @export
 * @class SidebarApi
 * @extends {BaseAPI}
 */
export class SidebarApi extends BaseAPI {
  /**
   *
   * @summary Get sidebar content
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SidebarApi
   */
  public getSidebar(options?: any) {
    return SidebarApiFp(this.configuration)
      .getSidebar(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update sidebar content
   * @param {SidebarApiPostSidebarRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SidebarApi
   */
  public postSidebar(
    requestParameters: SidebarApiPostSidebarRequest,
    options?: any,
  ) {
    return SidebarApiFp(this.configuration)
      .postSidebar(requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get user profile by id
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile: async (
      pId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("getUserProfile", "pId", pId);
      const localVarPath = `/api/v1/users/{pId}/profile`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create or update user profile
     * @param {string} pId Player ID
     * @param {UserProfile} profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUserProfile: async (
      pId: string,
      profile: UserProfile,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pId' is not null or undefined
      assertParamExists("postUserProfile", "pId", pId);
      // verify required parameter 'profile' is not null or undefined
      assertParamExists("postUserProfile", "profile", profile);
      const localVarPath = `/api/v1/users/{pId}/profile`.replace(
        `{${"pId"}}`,
        encodeURIComponent(String(pId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth",
        [],
        configuration,
      );

      // authentication kc_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profile,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get user profile by id
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfile(
      pId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(
        pId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create or update user profile
     * @param {string} pId Player ID
     * @param {UserProfile} profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postUserProfile(
      pId: string,
      profile: UserProfile,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postUserProfile(
        pId,
        profile,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @summary Get user profile by id
     * @param {string} pId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(pId: string, options?: any): AxiosPromise<UserProfile> {
      return localVarFp
        .getUserProfile(pId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create or update user profile
     * @param {string} pId Player ID
     * @param {UserProfile} profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUserProfile(
      pId: string,
      profile: UserProfile,
      options?: any,
    ): AxiosPromise<UserProfile> {
      return localVarFp
        .postUserProfile(pId, profile, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getUserProfile operation in UsersApi.
 * @export
 * @interface UsersApiGetUserProfileRequest
 */
export interface UsersApiGetUserProfileRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof UsersApiGetUserProfile
   */
  readonly pId: string;
}

/**
 * Request parameters for postUserProfile operation in UsersApi.
 * @export
 * @interface UsersApiPostUserProfileRequest
 */
export interface UsersApiPostUserProfileRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof UsersApiPostUserProfile
   */
  readonly pId: string;

  /**
   *
   * @type {UserProfile}
   * @memberof UsersApiPostUserProfile
   */
  readonly profile: UserProfile;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Get user profile by id
   * @param {UsersApiGetUserProfileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserProfile(
    requestParameters: UsersApiGetUserProfileRequest,
    options?: any,
  ) {
    return UsersApiFp(this.configuration)
      .getUserProfile(requestParameters.pId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create or update user profile
   * @param {UsersApiPostUserProfileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public postUserProfile(
    requestParameters: UsersApiPostUserProfileRequest,
    options?: any,
  ) {
    return UsersApiFp(this.configuration)
      .postUserProfile(
        requestParameters.pId,
        requestParameters.profile,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
