import { createEvent, sample } from "effector";
import { ERequestStatus, IAssessmentResults } from "src/shared/store/types";
import {
  AssessmentsResultsReadmoreType,
  IAssessmentResultsState,
  IAssessmentsResultsState,
  IRequestAssessmentResults,
  IRequestAssessmentsResults,
} from "./types";
import {
  AssessmentDetailDto,
  AssessmentsBriefResDto,
} from "src/generated/statistic";
import { assessmentsResultsDomain } from "./domain";
import {
  getAssessmentResults,
  getAssessmentsResults,
} from "src/shared/api/public/assessments-results";

// Stores

export const $assessmentsResults =
  assessmentsResultsDomain.createStore<IAssessmentsResultsState>({
    items: [],
    isReadmore: false,
    itemsPagination: null,
    statusItems: ERequestStatus.NEVER,
  });

export const $assessmentResults =
  assessmentsResultsDomain.createStore<IAssessmentResultsState>({
    item: null,
    statusItem: ERequestStatus.NEVER,
  });

// Events

export const cleanAssessmentsResults = assessmentsResultsDomain.createEvent();
export const requestAssessmentsResults =
  createEvent<IRequestAssessmentsResults>();
export const successAssessmentsResults =
  assessmentsResultsDomain.createEvent<AssessmentsBriefResDto>();
export const failureAssessmentsResults = assessmentsResultsDomain.createEvent();
export const setAssessmentsReadmore =
  assessmentsResultsDomain.createEvent<AssessmentsResultsReadmoreType>();

export const cleanAssessmentResults = assessmentsResultsDomain.createEvent();
export const requestAssessmentResults =
  assessmentsResultsDomain.createEvent<IRequestAssessmentResults>();
export const successAssessmentResults =
  assessmentsResultsDomain.createEvent<AssessmentDetailDto>();
export const failureAssessmentResults = assessmentsResultsDomain.createEvent();

// Effects

export const fetchAssessmentsResultsFx = assessmentsResultsDomain.createEffect(
  getAssessmentsResults,
);
export const fetchAssessmentResultsFx =
  assessmentsResultsDomain.createEffect(getAssessmentResults);

// Logic

$assessmentsResults.on(cleanAssessmentsResults, (state) => ({
  ...state,
  items: [],
  itemsPagination: null,
  statusItems: ERequestStatus.NEVER,
}));

$assessmentsResults.on(requestAssessmentsResults, (state) => ({
  ...state,
  statusItems: ERequestStatus.LOADING,
}));

$assessmentsResults.on(successAssessmentsResults, (state, payload) => ({
  ...state,
  items:
    state.isReadmore && payload.assessments
      ? [...state.items, ...payload.assessments]
      : payload.assessments || [],
  itemsPagination: payload.pagination,
  statusItems: ERequestStatus.LOADED,
  isReadmore: false,
}));

$assessmentsResults.on(failureAssessmentsResults, (state) => ({
  ...state,
  statusItems: ERequestStatus.ERROR,
  isReadmore: false,
}));

$assessmentsResults.on(setAssessmentsReadmore, (state, payload) => ({
  ...state,
  isReadmore: payload.value,
}));

$assessmentResults.on(cleanAssessmentResults, (state) => ({
  ...state,
  item: null,
  statusItem: ERequestStatus.NEVER,
}));

$assessmentResults.on(requestAssessmentResults, (state) => ({
  ...state,
  statusItem: ERequestStatus.LOADING,
}));

$assessmentResults.on(successAssessmentResults, (state, payload) => ({
  ...state,
  item: payload as IAssessmentResults,
  statusItem: ERequestStatus.LOADED,
}));

$assessmentResults.on(failureAssessmentResults, (state) => ({
  ...state,
  statusItem: ERequestStatus.ERROR,
}));

sample({
  source: requestAssessmentsResults,
  target: fetchAssessmentsResultsFx,
});

sample({
  source: fetchAssessmentsResultsFx.doneData,
  target: successAssessmentsResults,
});

sample({
  clock: fetchAssessmentsResultsFx.fail,
  target: failureAssessmentsResults,
});

sample({
  source: requestAssessmentResults,
  target: fetchAssessmentResultsFx,
});

sample({
  source: fetchAssessmentResultsFx.doneData,
  target: successAssessmentResults,
});

sample({
  clock: fetchAssessmentResultsFx.fail,
  target: failureAssessmentResults,
});
