import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useUrlState } from "src/shared/hooks/useUrlState";

import { useUnit } from "effector-react";

import {
  BaseButton,
  EmailMailingListTable,
  PageWrapper,
  TextWithIcon,
} from "src/shared/components";

import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";

import { TDataGridDataParams } from "src/shared/models/dataGrid";

import { readMoreRequest } from "src/shared/helpers/readmoreRequest";

import { useUpdatedQueryParams } from "src/shared/hooks/useUpdatedQueryParams";

import { emailNotificationModel } from "src/entities/admin/notification";

import "./email-mailing-lists-page.scss";

const { $emailsList, $isLoading, resetQueryParams } = emailNotificationModel;

const EmailMailingListsPage = () => {
  const { t } = useTranslation();

  const [urlState, setUrlState] = useUrlState();

  const emailsList = useUnit($emailsList);

  const loading = useUnit($isLoading);

  const { updatedQueryParams } = useUpdatedQueryParams();

  const updateQueryParams = (params: TDataGridDataParams) =>
    setUrlState({
      ...urlState,
      query: params,
    });

  useEffect(() => {
    return () => resetQueryParams();
  }, []);

  useEffect(() => {
    readMoreRequest(
      updatedQueryParams,
      emailNotificationModel.readmoreEmails,
      emailNotificationModel.getEmailsList,
    );
  }, [updatedQueryParams]);

  return (
    <PageWrapper
      title={t("navigation.mailingLists")}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("mailingLists.controlPanel.label.mailings")}
      controlPanelMaxCount={emailsList.length}
      isLoadingPanel={loading}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <BaseButton
          small
          outline
          to={"/mailing-lists/newsletter-form?type=e-mail"}
        >
          <TextWithIcon
            label={t("navigation.mailingLists.btn.newNewsletter")}
            iconName="plus-blue"
          />
        </BaseButton>
      }
    >
      <div className="email-mailing-lists-page">
        <EmailMailingListTable
          queryParams={urlState.query}
          updateQueryParams={updateQueryParams}
        />
      </div>
    </PageWrapper>
  );
};

export default EmailMailingListsPage;
