import { createDomain, sample } from "effector";

import { useNavigate } from "react-router-dom";
import {
  IRequestAssessmentsListAdmin,
  requestAssessmentsListAdmin,
} from "src/entities/public/assessments-list-admin";

import { CreateAssessmentResDto } from "src/generated/game";

import {
  PostCopyAssessment,
  postCopyAssessmentHr,
  PostCreateAssessment,
  postCreateAssessmentHr,
} from "src/shared/api/hr/assessments";

import { EPopupName } from "src/shared/components";

import { closePopup } from "src/shared/components/base-popup/model";

type NavigateReactRouter = ReturnType<typeof useNavigate>;

interface CopyAssessment {
  params: PostCopyAssessment;
  refetchParams: IRequestAssessmentsListAdmin;
}

const createAssessmentDomain = createDomain("createAssessmentDomain");

// Events

export const createAssessment = createAssessmentDomain.createEvent<{
  params: PostCreateAssessment;
  navigate: NavigateReactRouter;
}>();

export const copyAssessment =
  createAssessmentDomain.createEvent<CopyAssessment>();

//Effects

const createAssessmentFx = createAssessmentDomain.createEffect(
  postCreateAssessmentHr,
);

const copyAssessmentFx =
  createAssessmentDomain.createEffect(postCopyAssessmentHr);

const onCreateAssessmentFx = createAssessmentDomain.createEffect(
  ({
    response,
    navigate,
  }: {
    response: CreateAssessmentResDto;
    navigate: NavigateReactRouter;
  }) => {
    closePopup({ name: EPopupName.CREATE_UNRATED_SESSION });

    navigate?.(`/sessions/session/${response.assessmentId}`);
  },
);

// Stores

const $navigate =
  createAssessmentDomain.createStore<NavigateReactRouter | null>(null);

// Logic

sample({
  clock: createAssessment,
  fn: ({ params }) => params,
  target: createAssessmentFx,
});

sample({
  clock: createAssessment,
  fn: ({ navigate }) => navigate,
  target: $navigate,
});

sample({
  clock: createAssessmentFx.doneData,
  source: $navigate,
  fn: (navigate, response) => ({
    navigate: navigate as NavigateReactRouter,
    response,
  }),
  target: onCreateAssessmentFx,
});

sample({
  clock: copyAssessment,
  fn: ({ params }) => params,
  target: copyAssessmentFx,
});

sample({
  clock: copyAssessmentFx.done,
  source: copyAssessment,
  fn: (s) => s.refetchParams,
  target: requestAssessmentsListAdmin,
});
