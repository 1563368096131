import styles from "./recursive-rights.module.scss";

import React, { FC } from "react";
import { PermissionDto } from "src/generated/social";
import { BaseInputRadio, InputCheckbox } from "src/shared/components";
import { Checkbox } from "src/shared/models";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
  rights: PermissionDto[];
  isChangeable: boolean;
  onChange: (right: PermissionDto) => void;
}

const RecursiveRights: FC<Props> = ({ rights, isChangeable, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.rightsWrapper}>
      {rights.map((right, index) => (
        <div className={styles.rights} key={(right.name as string) + index}>
          {right.type === "checkbox" && (
            <div className={styles.right}>
              <InputCheckbox
                onChange={() => onChange(right)}
                className={styles.checkbox}
                value={right.is_enabled}
                type={Checkbox.CHOSEN}
                isDisabled={!isChangeable}
                label=""
              />
              <span
                className={classNames(styles.rightName, {
                  [styles.disabled]: !isChangeable,
                })}
                onClick={() => isChangeable && onChange(right)}
              >
                {t(`permission.main.permission-name.${right.name as string}`)}
              </span>
            </div>
          )}

          {right.type === "group_radio" && (
            <div className={classNames(styles.right, styles.right_radio)}>
              <BaseInputRadio
                className={styles.radio}
                value={right.name as string}
                activeValue={right.selected ? (right.name as string) : ""}
                name={right.name as string}
                label=""
                onChange={() => onChange(right)}
                isDisabled={!isChangeable}
              />
              <span
                className={classNames(styles.rightName, {
                  [styles.disabled]: !isChangeable,
                })}
                onClick={() => isChangeable && onChange(right)}
              >
                {t(`permission.main.permission-name.${right.name as string}`)}
              </span>
            </div>
          )}

          {!!right.children?.length && right.is_enabled && (
            <div className={styles.subrights}>
              <RecursiveRights
                rights={right.children}
                isChangeable={isChangeable}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RecursiveRights;
