import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import { AdmAssessmentDto, AssessmentType } from "src/generated/game";

import {
  DataGrid,
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";

import {
  getActiveGroup,
  getOrderByValue,
  languagePicker,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  IDataGridSelectOption,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models";

interface Props {
  assessmentItems: AdmAssessmentDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  onSelectRows?: (params: string[]) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
  selectedRowsIds: string[];
}

const { openPopup } = popupModel;

export const CreatedAssessmentsTable: FC<Props> = ({
  loading,
  assessmentItems,
  queryParams,
  onUpdateSortAndFilters,
  onSelectRows,
  pagination,
  goToPage,
  readmore,
  selectedRowsIds,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const assessmentTypes = useMemo(() => Object.values(AssessmentType), []);

  const assessmentTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return assessmentTypes.map((type) => ({
      value: type,
      label: t(`common.assessment.${type}`, type),
    }));
  }, [assessmentTypes, t]);

  const showMembers = useCallback((rowId: string) => {
    openPopup({
      name: EPopupName.ASSESSMENT_MEMBERS,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
    });
  }, []);

  const openConfig = useCallback((rowId: string): void => {
    openPopup({
      name: EPopupName.ASSESSMENT_PARAMETERS,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
      someBooleanTrigger: false,
    });
  }, []);

  const generateColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: "SELECT",
        type: EDataGridColumnType.SELECT_ROW,
        key: "selected",
        showed: true,
        hiddenTitle: true,
        sticky: {
          mobile: {
            left: 0,
          },
          tablet: {
            left: 0,
          },
          laptop: {
            left: 0,
          },
        },
      },
      {
        title: t("table.header.session"),
        type: EDataGridColumnType.STRING,
        key: "sessionName",
        showed: true,
        filterTitle: t("table.header.session"),
        controlPanel: {
          title: t("table.header.session"),
          activeGroupKey: getActiveGroup(
            ["title"],
            { all: ["title"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "title",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.title || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "title",
                  "-title",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "title",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-title",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.simulation"),
        type: EDataGridColumnType.STRING,
        key: "simulationName",
        showed: true,
        controlPanel: {
          title: t("table.header.simulation"),
          activeGroupKey: getActiveGroup(
            ["gameTitle"],
            { all: ["gameTitle"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "gameTitle",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.gameTitle || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "gameTitle",
                  "-gameTitle",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "gameTitle",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-gameTitle",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.type"),
        type: EDataGridColumnType.STRING,
        key: "assessmentType",
        showed: true,
        filters: [
          {
            key: "assessmentType",
            type: EDataGridFilterType.CHECKBOX,
            placeholder: "Не выбрано",
            value: queryParams?.assessmentType || "",
            items: assessmentTypeOptions,
          },
        ],
        controlPanel: {
          title: t("table.header.type"),
          activeGroupKey: getActiveGroup(
            ["assessmentType"],
            { all: ["assessmentType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "assessmentType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: "Не выбрано",
                  value: queryParams?.assessmentType || "",
                  items: assessmentTypeOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.members.short"),
        type: EDataGridColumnType.STRING,
        key: "players",
        showed: true,
        controlPanel: {
          title: t("table.header.members"),
          activeGroupKey: getActiveGroup(
            ["curPlayers", "curPlayersFrom", "curPlayersTo"],
            { all: ["curPlayers", "curPlayersFrom", "curPlayersTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "curPlayersFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.curPlayersFrom || "",
                  isHalf: true,
                },
                {
                  key: "curPlayersTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.curPlayersTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "curPlayers",
                  "-curPlayers",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "curPlayers",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-curPlayers",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.game.created"),
        type: EDataGridColumnType.DATETIME,
        key: "created",
        showed: true,
        controlPanel: {
          title: t("table.header.game.created"),
          activeGroupKey: getActiveGroup(
            ["created", "createdFrom", "createdTo"],
            { all: ["created", "createdFrom", "createdTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "createdFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.createdFrom || "",
                  isHalf: true,
                },
                {
                  key: "createdTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.createdTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "created",
                  "-created",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.date.desc"),
                    value: "-created",
                  },
                  {
                    title: t("table.filters.date.asc"),
                    value: "created",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.members"),
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "resultsButtonTitle",
        showed: true,
        noPaddingRight: true,
        buttonProps: {
          primary: true,
          xSmall: true,
          xSmallRounded: true,
          disabled: false,
          onClick(rowId: string) {
            showMembers(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 59,
          },
          laptop: {
            right: 79,
          },
        },
      },
      {
        title: t("table.header.settings"),
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "settingsButtonTitle",
        showed: true,
        buttonProps: {
          small: true,
          hideLabel: true,
          transparent: true,
          icon: "props-gray",
          ariaLabel: t("buttons.sessionSettings"),
          noPaddingX: true,
          onClick(rowId) {
            openConfig(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 0,
            width: 59,
          },
          laptop: {
            right: 0,
          },
        },
      },
    ],
    [showMembers, openConfig, assessmentTypeOptions, queryParams, t],
  );

  useEffect(() => {
    const rowsItems: IDataGridRow[] = assessmentItems.map(
      (item): IDataGridRow => ({
        id: item.id,
        selected: false,
        sessionName: item.title,
        simulationName: languagePicker(item.gameTitle, i18n.language),
        assessmentType: t(
          `common.assessment.${item.assessmentType}`,
          item.assessmentType,
        ),
        players: `${item.curPlayers}/${item.maxPlayers}`,
        created: item.createdAt,
        started: item.startedAt,
        finished: item.finishedAt,
        resultsButtonTitle: t("table.header.members"),
        settingsButtonTitle: t("table.header.settings"),
      }),
    );

    setRows(rowsItems);
  }, [assessmentItems, i18n.language, t]);

  useEffect(() => {
    setColumns(generateColumns);
  }, [generateColumns]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"active-assessment-table"}
      queryParams={queryParams}
      selectedRowsIds={selectedRowsIds}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      onUpdateSelectedRows={onSelectRows}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
