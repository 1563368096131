import { createDomain, createEvent, createStore, sample } from "effector";

import {
  PlayerRatingRes,
  PlayersApiGetPlayerRatingsRequest,
} from "src/generated/social";
import { getUserPlaceRating } from "../../../../shared/api/public/profile-competence/model/user-place";
import { updateProfileByIdFx } from "./patch-profile";
import { $socialPlayerInfo } from "./user-social";

// Domain

export const playerPlacesDomain = createDomain("playerPlaces");

// Events

export const setAgreementConfirmed = createEvent<boolean>();

export const getPlaces =
  playerPlacesDomain.createEvent<PlayersApiGetPlayerRatingsRequest>();

export const $places = playerPlacesDomain.createStore<PlayerRatingRes | null>(
  null,
);

// Effects

export const getPlacesFx = playerPlacesDomain.createEffect(getUserPlaceRating);

// Logic

sample({ clock: getPlaces, target: getPlacesFx });

export const $isLoadingCompetence =
  playerPlacesDomain.createStore<boolean>(true);

export const $isLoadingAgreementConfirmed = createStore(true).on(
  setAgreementConfirmed,
  (_, payload) => payload,
);

sample({
  clock: getPlacesFx.pending,
  target: $isLoadingCompetence,
});

sample({
  clock: getPlacesFx.done,
  fn: () => false,
  target: $isLoadingCompetence,
});

sample({
  clock: getPlacesFx.doneData,
  target: $places,
});

sample({
  clock: updateProfileByIdFx.finally,
  fn: (res) => {
    return !res.params.body.is_agreement_confirmed;
  },
  target: $isLoadingAgreementConfirmed,
});

sample({
  clock: $socialPlayerInfo,
  fn: (res) => {
    return !res?.is_agreement_confirmed;
  },
  target: $isLoadingAgreementConfirmed,
});
