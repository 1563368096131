export enum CompetenceEnum {
  COMMUNICATION = "communication",
  ANALYTICS = "analytics",
  COMMAND = "command",
  THINKING_3D = "thinking3d",
  ECONOMICS = "economics",
  JURISPRUDENCE = "jurisprudence",
  MICROELECTRONICS = "microelectronics",
  ANALYTICS_TENACITY = "analyticsTenacity",
  COMMUNICATION_TENACITY = "communicationTenacity",
  ECONOMICS_TENACITY = "economicsTenacity",
  COMMAND_TENACITY = "commandTenacity",
  JURISPRUDENCE_TENACITY = "jurisprudenceTenacity",
  THINKING3D_TENACITY = "thinking3dTenacity",
  MICROELECTRONICS_TENACITY = "microelectronicsTenacity",
}

export enum AdditionalCompetenceEnum {
  THINKING_3D = CompetenceEnum.THINKING_3D,
  ECONOMICS = CompetenceEnum.ECONOMICS,
  JURISPRUDENCE = CompetenceEnum.JURISPRUDENCE,
  MICROELECTRONICS = CompetenceEnum.MICROELECTRONICS,
  ANALYTICS_TENACITY = "analyticsTenacity",
  COMMUNICATION_TENACITY = "communicationTenacity",
  COMMAND_TENACITY = "commandTenacity",
  // Убрано по просьбе Анастасии
  // ECONOMICS_TENACITY = "economicsTenacity",
  // JURISPRUDENCE_TENACITY = "jurisprudenceTenacity",
  // THINKING3D_TENACITY = "thinking3dTenacity",
  // MICROELECTRONICS_TENACITY = "microelectronicsTenacity",
}
