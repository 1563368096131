import axios from "axios";
import { UserInfoInterface } from "src/shared/store/types";

export const getUserInfoAdmin = async (payload: {
  id: string;
}): Promise<UserInfoInterface> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_KC_URL}/realms/${process.env.REACT_APP_KC_REALM}/albAdmin/users/${payload.id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("alabuga-token"),
      },
    },
  );

  return data;
};
