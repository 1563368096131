import { sample } from "effector";

import { lockPageScroll } from "src/shared/helpers";

import { socketsModel } from "src/shared/api/public/sockets";

import {
  $isConnected,
  GetListParams,
  GetListResponse,
  Notification,
  SocketStore,
} from "src/shared/api/public/sockets/model/notifications";

export const notificationsDomain =
  socketsModel.notifications.socketsNotificationsDomain.createDomain();

// Stores

export const $notifications = notificationsDomain.createStore<Notification[]>(
  [],
);

export const $newNotifications = $notifications.map((items) =>
  items.filter((item) => item.isNew),
);

export const $oldNotifications = $notifications.map((items) =>
  items.filter((item) => !item.isNew),
);

export const $notificationsPopup = notificationsDomain.createStore<
  Notification[]
>([]);

export const $isListLoading = notificationsDomain.createStore<boolean>(true);

export const $isShowedPanel = notificationsDomain.createStore<boolean>(false);

export const $isShowedButtonMore =
  notificationsDomain.createStore<boolean>(false);

export const $pageNum = notificationsDomain.createStore<number>(0);

// Events

export const getList = notificationsDomain.createEvent<GetListParams | void>();

export const addNotificationsToList =
  notificationsDomain.createEvent<Notification[]>();

export const addNotificationToList =
  notificationsDomain.createEvent<Notification | null>();

export const removeNotificationFromList = notificationsDomain.createEvent<{
  listMsgs: string[];
} | null>();

export const updateNotificationInList =
  notificationsDomain.createEvent<Notification>();

export const toggleNotificationPanel = notificationsDomain.createEvent<
  boolean | void
>();

// Effects

const getListFx = notificationsDomain.createEffect(
  socketsModel.notifications.getList,
);

const handleGetListEvent = (res: GetListResponse) => {
  addNotificationsToList(res.msgs);
};

const handleGetItemEvent = (res: Notification) => {
  addNotificationToList(res);
};

const handleRemoveItemEvent = (res: { listMsgs: string[] }) => {
  removeNotificationFromList(res);
};

const attachGetListEventFx = notificationsDomain.createEffect(
  (socket: SocketStore) =>
    socketsModel.notifications.attachGetListEvent(socket, handleGetListEvent),
);

const attachGetItemEventFx = notificationsDomain.createEffect(
  (socket: SocketStore) =>
    socketsModel.notifications.attachGetItemEvent(socket, handleGetItemEvent),
);

const attachRemoveItemEventFx = notificationsDomain.createEffect(
  (socket: SocketStore) =>
    socketsModel.notifications.attachRemoveItemEvent(
      socket,
      handleRemoveItemEvent,
    ),
);

// Logic

sample({
  clock: $isConnected,
  source: {},
  target: getList,
});

sample({
  clock: getList,
  source: socketsModel.notifications.$socket,
  fn: (socket, payload) => {
    return { socket, payload };
  },
  target: getListFx,
});

sample({
  clock: getListFx.pending,
  target: $isListLoading,
});

sample({
  clock: addNotificationsToList,
  source: { pageNum: $pageNum, notifications: $notifications },
  fn: ({ pageNum, notifications }, items) => {
    if (pageNum > 0) {
      return [...notifications, ...items];
    }

    return items;
  },
  target: $notifications,
});

sample({
  clock: addNotificationsToList,
  fn: (notifications) => notifications.length > 9,
  target: $isShowedButtonMore,
});

sample({
  clock: addNotificationToList,
  source: $notifications,
  fn: (notifications, newNotification) => {
    if (newNotification) return [newNotification, ...notifications];

    return notifications;
  },
  target: $notifications,
});

sample({
  clock: addNotificationToList,
  source: $notificationsPopup,
  fn: (notifications, newNotification) => {
    if (newNotification) return [newNotification, ...notifications];

    return notifications;
  },
  target: $notificationsPopup,
});

sample({
  clock: removeNotificationFromList,
  source: $notifications,
  fn: (notifications, notificationIds) => {
    let filteredNotifications = notifications;
    notificationIds?.listMsgs.forEach((id) => {
      filteredNotifications = notifications.filter(
        (notification) => notification.mid !== id,
      );
    });
    return filteredNotifications;
  },
  target: $notifications,
});

sample({
  clock: removeNotificationFromList,
  source: $notifications,
  fn: (notifications, notificationIds) => {
    let filteredNotifications = notifications;
    console.log(notifications);
    notificationIds?.listMsgs.forEach((id) => {
      filteredNotifications = notifications.filter(
        (notification) => notification.mid !== id,
      );
    });
    return filteredNotifications;
  },
  target: $notificationsPopup,
});

sample({
  clock: updateNotificationInList,
  source: $notifications,
  fn: (notifications, newNotification) => {
    const indexIn = notifications.findIndex(
      (item) => item.mid === newNotification.mid,
    );

    if (~indexIn) {
      notifications[indexIn] = newNotification;

      return notifications.slice();
    }

    return notifications;
  },
  target: [$notifications, $notificationsPopup],
});

sample({
  clock: updateNotificationInList,
  source: $notifications,
  fn: (notifications, newNotification) => {
    const indexIn = notifications.findIndex(
      (item) => item.mid === newNotification.mid,
    );

    if (indexIn === -1) {
      return newNotification;
    }

    return null;
  },
  target: addNotificationToList,
});

sample({
  source: socketsModel.notifications.$socket,
  filter: (socket) => !!socket,
  target: attachGetListEventFx,
});

sample({
  source: socketsModel.notifications.$socket,
  filter: (socket) => !!socket,
  target: attachRemoveItemEventFx,
});

sample({
  source: socketsModel.notifications.$socket,
  filter: (socket) => !!socket,
  target: attachGetItemEventFx,
});

sample({
  clock: toggleNotificationPanel,
  source: $isShowedPanel,
  fn: (source, clock) => {
    const isShowed: boolean = clock === undefined ? !source : clock;

    lockPageScroll(isShowed);

    return isShowed;
  },

  target: $isShowedPanel,
});
