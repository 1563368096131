import React, { FC, ReactElement } from "react";
import { CopyButton } from "../copy-button/copy-button";
import classNames from "classnames";

interface Props {
  value?: string;
  placeholder?: string;
  onCopy?: () => void;
}

export const CopyInput: FC<Props> = ({
  value = "",
  placeholder,
  onCopy,
}): ReactElement => {
  return (
    <label className="field">
      <input type="text" readOnly value={value} className="field__input" />
      <span
        className={classNames("field__label", {
          "field__label--collapsed": value,
        })}
      >
        {placeholder}
      </span>
      <CopyButton
        noPadding
        transparent
        className="field__copy-btn"
        copyValue={value}
        onCopy={onCopy}
      />
    </label>
  );
};
