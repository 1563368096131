import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import "./base-dropdown.scss";
import classNames from "classnames";

interface Props {
  view: ReactElement;
  children: ReactNode;
  align?: "right" | "left";
  className?: string;
  isLong?: boolean;
}

const BaseDropdown: FC<Props> = ({
  children,
  view,
  align = "left",
  className,
  isLong = false,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [position, setPosition] = useState<"top" | "bottom">("bottom");
  const onDropDown = (): void => {
    setActive((prev) => !prev);
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const viewRef = useRef<HTMLDivElement | null>(null);

  const calcDropdownPosition = () => {
    if (isLong) {
      setPosition("bottom");
      return;
    }

    const viewRect = viewRef.current?.getBoundingClientRect() as DOMRect;

    const bottomSpace = window.innerHeight - viewRect.bottom;
    const dropdownHeight = dropdownRef.current?.clientHeight as number;

    if (bottomSpace < dropdownHeight) {
      setPosition("top");
    } else {
      setPosition("bottom");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", calcDropdownPosition);

    return () => window.removeEventListener("scroll", calcDropdownPosition);
  }, [dropdownRef, viewRef, position, isLong]);

  useEffect(() => {
    const clickHandler = (event: MouseEvent): void => {
      const isClickOutside = !dropdownRef.current?.contains(
        event.target as HTMLElement,
      );

      if (isClickOutside && active) {
        onDropDown();
      }
    };
    document.addEventListener("click", clickHandler);

    calcDropdownPosition();

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [active]);

  return (
    <div ref={dropdownRef} className={classNames(className, "base-dropdown")}>
      <div className="base-dropdown__view" onClick={onDropDown} ref={viewRef}>
        {view}
      </div>
      <div
        className={classNames(
          "base-dropdown__content",
          `base-dropdown__content--align-${align}`,
          `base-dropdown__content--position-${position}`,
          {
            "base-dropdown__content--active": active,
            "base-dropdown__content--long": isLong,
          },
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default BaseDropdown;
