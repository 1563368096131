export enum ClientIdEnum {
  PLAYER = "alb-player",
  HR = "alb-hr",
  ADMIN = "alb-admin",
}

export enum UserTypeEnum {
  PLAYER = "player",
  HR = "hr",
  ADMIN = "admin",
}

export enum UserRoleEnum {
  PLAYER = "Player",
  HR_STAFF = "HR-Staff",
  HR_ALABUGA = "HR-Alabuga",
  HR_ADMIN = "HR-Admin",
  SUPERADMIN = "Superadmin",
}
