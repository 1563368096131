import React, { FC, FormEvent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { UserRoleEnum, UserTypeEnum } from "../../../shared/models/user";
import {
  BaseBox,
  BaseButton,
  ConfirmPopup,
  EPopupName,
  EmptyBlock,
  InputCheckbox,
  PageWrapper,
  ResumeDownloadBlock,
  StatBlock,
  TextWithIcon,
  UserForm,
  popupModel,
} from "src/shared/components";
import "./user-info-page.scss";
import emptyAvatar from "../../../shared/images/avatar-empty.svg";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getUserFormData } from "src/shared/helpers/getUserFormData";
import { ERequestStatus, UserInfoInterface } from "src/shared/store/types";
import { useTranslation } from "react-i18next";
import { FormDataInterface } from "src/shared/components/user-form/user-form";
import { getRequestKeycloakUserData } from "src/shared/helpers/getRequestKeycloakUserData";
import {
  selectProfileCompetenceItem,
  selectProfileCompetenceStatus,
} from "src/shared/store/ducks/profile-competence/selectors";
import { requestProfileCompetence } from "src/shared/store/ducks/profile-competence/actionCreators";
import { Competence } from "src/generated/social";
import { getCompetence } from "src/shared/helpers/getCompetence";
import useInput, {
  EInputValidateType,
  IValidateValue,
} from "../../../shared/hooks/useInput";
import { Checkbox } from "../../../shared/models";
import _ from "lodash";
import { UserInfoHeaderSlot } from "./components/user-info-header-slot";
import useClient from "src/shared/hooks/useClient";
import { RightsSection } from "./components/rights-section";
import { patchUpdateUserPromise } from "src/shared/api/keycloak/patch-update-user";
import {
  $resetPasswordRequestStatus,
  fetchResetPasswordUser,
} from "../add-user-page/lib/create-user";
import {
  $subjectEditRequestStatus,
  $subjectInfoRequestStatus,
  $subjectUser,
  requestGetUserInfoAdmin,
  requestPutUserInfoAdmin,
} from "./lib/user-info-page";
import { useUnit } from "effector-react";

const { openPopup, closePopup } = popupModel;

export const UserInfoPage: FC = () => {
  const { validateValue } = useInput();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const subjectUser = useUnit($subjectUser);
  const subjectCompetencies = useSelector(selectProfileCompetenceItem);
  const subjectCompetenciesRequestStatus = useSelector(
    selectProfileCompetenceStatus,
  );
  const subjectInfoRequestStatus = useUnit($subjectInfoRequestStatus);
  const subjectEditRequestStatus = useUnit($subjectEditRequestStatus);
  const resetPasswordRequestStatus = useUnit($resetPasswordRequestStatus);

  const [isHrChecked, setIsHrChecked] = useState<boolean>(false);
  const [isLoadingHrCheckbox, setIsLoadingHrCheckbox] =
    useState<boolean>(false);

  const isSubjectAdmin = subjectUser?.realmRoles?.includes(
    UserRoleEnum.HR_ADMIN,
  );

  const isSubjectPlayer = subjectUser?.realmRoles?.includes(
    UserRoleEnum.PLAYER,
  );

  const hrRoles = [UserRoleEnum.HR_STAFF, UserRoleEnum.HR_ALABUGA];

  const isSubjectHr = !!_.intersection(subjectUser?.realmRoles, hrRoles).length;

  const subjectUserId = params.id;

  const userTypeMap = [
    { condition: isSubjectAdmin, userType: UserTypeEnum.ADMIN },
    { condition: isSubjectHr, userType: UserTypeEnum.HR },
    { condition: isSubjectPlayer, userType: UserTypeEnum.PLAYER },
  ];

  const userType: UserTypeEnum = userTypeMap.find((item) => item.condition)
    ?.userType!;

  const [formData, setFormData] = useState<FormDataInterface>(
    getUserFormData(),
  );

  useEffect(() => {
    if (subjectUserId) {
      requestGetUserInfoAdmin({ id: subjectUserId });
    }
  }, [subjectUserId]);

  useEffect(() => {
    if (isSubjectPlayer && subjectUserId) {
      dispatch(requestProfileCompetence({ id: subjectUserId }));
    }

    if (subjectUser) {
      setFormData(getUserFormData(subjectUser));
    }

    setIsHrChecked(isSubjectHr);
  }, [subjectUser, dispatch]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!recaptchaToken) {
      return alert("userForm.errorMessage.captcha");
    }

    const validateFormOptions: Record<string, IValidateValue> = {
      email: {
        value: formData.email,
        type: EInputValidateType.EMAIL,
        required: true,
      },
      phoneNumber: {
        value: formData.phoneNumber,
        type: EInputValidateType.PHONE_NUMBER,
        required: true,
      },
    };

    const newFormErrorsData = validateValue(validateFormOptions);
    const isFormCorrect = !Object.keys(newFormErrorsData).length;

    setFormErrors(newFormErrorsData);

    if (isFormCorrect) {
      const requestData: UserInfoInterface = {
        ...getRequestKeycloakUserData(formData),
        username: formData.phoneNumber,
      };
      if (subjectUserId) {
        requestPutUserInfoAdmin({
          payload: { id: subjectUserId, data: requestData },
        });
      }
    } else {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.baseMessage.message.fillAllFields"),
          isError: true,
        },
      });

      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    }
  };

  const resetPasswordHandler = (): void => {
    const callback = () => {
      closePopup({ name: EPopupName.CONFIRM_RESET_PASSWORD_USER });
    };
    if (subjectUserId) {
      fetchResetPasswordUser({
        userId: subjectUserId,
        clientId: `alb-${userType}`,
        callback,
      });
    }
  };

  const openConfirmResetPassword = (): void => {
    openPopup({
      name: EPopupName.CONFIRM_RESET_PASSWORD_USER,
    });
  };

  const getAvatarUrl = () =>
    subjectUser?.attributes?.avatarUrl?.[0] || emptyAvatar;

  const gRate: string | undefined = subjectCompetencies?.gRate.toFixed(2);

  const communicationRate: string | undefined = getCompetence(
    subjectCompetencies?.competenceRates,
    Competence.Communication,
  )?.rate.toFixed(2);

  const analyticsRate: string | undefined = getCompetence(
    subjectCompetencies?.competenceRates,
    Competence.Analytics,
  )?.rate.toFixed(2);

  const commandRate: string | undefined = getCompetence(
    subjectCompetencies?.competenceRates,
    Competence.Command,
  )?.rate.toFixed(2);

  const isResetPasswordLoading: boolean =
    resetPasswordRequestStatus === ERequestStatus.LOADING;

  const isSubjectInfoLoading: boolean =
    subjectInfoRequestStatus === ERequestStatus.LOADING;

  const isSubjectInfoError: boolean =
    subjectInfoRequestStatus === ERequestStatus.ERROR;

  const isSubjectEditLoading: boolean =
    subjectEditRequestStatus === ERequestStatus.LOADING;

  const isCompetenciesLoading: boolean =
    subjectCompetenciesRequestStatus === ERequestStatus.LOADING;

  const isPlayerCompetence: boolean = isSubjectPlayer
    ? !!subjectCompetencies && !isCompetenciesLoading
    : true;

  const doesPlayerHaveResume: boolean =
    !!isSubjectPlayer && !!subjectUser?.attributes?.resumeFileName?.[0];

  const [recaptchaToken, setRecaptchaToken] = useState<string>();

  const { isAdminClientId } = useClient();

  const isRightsSectionShown: boolean = useMemo(() => {
    if (!isSubjectAdmin && !isHrChecked) {
      return false;
    }
    return isAdminClientId;
  }, [isSubjectAdmin, isHrChecked, isAdminClientId]);

  const onChangeHandler = () => {
    if (isLoadingHrCheckbox) return;

    setIsLoadingHrCheckbox(true);
    patchUpdateUserPromise({
      id: subjectUser?.id as string,
      user: isHrChecked ? "user" : "hr",
    })
      .then(() => {
        setIsHrChecked(!isHrChecked);
        setIsLoadingHrCheckbox(false);
      })
      .catch(() => {
        setIsHrChecked(false);
        setIsLoadingHrCheckbox(false);
      });
  };

  const formRequiredFields: Record<string, boolean> = {
    email: true,
    phoneNumber: true,
    login: true,
  };

  const resumeFileName = subjectUser?.attributes?.resumeFileName?.[0] ?? "";

  return (
    <PageWrapper
      title={t("usersControl.userInfoPage.title")}
      emptyPanel
      isLoadingPanel={isSubjectInfoLoading || isCompetenciesLoading}
      titlePanelSlot={<UserInfoHeaderSlot userId={subjectUserId} />}
      isWhiteBackground
    >
      {subjectUser && !isSubjectInfoLoading && isPlayerCompetence && (
        <div
          className={classNames("user-info-page", {
            "user-info-page--not-is-player": !isSubjectPlayer,
          })}
        >
          <div className="user-info-page__form">
            <UserForm
              formData={formData}
              userType={userType}
              setFormData={setFormData}
              onSubmit={onSubmit}
              emailVerified={!subjectUser.emailVerified}
              errors={formErrors}
              setFormErrors={setFormErrors}
              requiredFields={formRequiredFields}
              isWithoutCheckbox={true}
              setRecaptchaToken={setRecaptchaToken}
            />
            {isSubjectPlayer && (
              <div className="user-info-page__save-btn">
                <BaseButton
                  disabled={isResetPasswordLoading}
                  onClick={openConfirmResetPassword}
                >
                  <TextWithIcon
                    iconName="lock-blue"
                    label={t("usersControl.btn.passwordReset")}
                  />
                </BaseButton>
              </div>
            )}
            <div className="user-info-page__save-btn">
              <BaseButton
                submit
                block
                primary
                large
                onClick={onSubmit}
                disabled={isSubjectEditLoading || !recaptchaToken}
              >
                {t("usersControl.btn.saveChanges")}
              </BaseButton>
            </div>
          </div>
          <div className="state-block">
            <StatBlock
              isPlayer={isSubjectPlayer}
              avatarUrl={getAvatarUrl()}
              gRate={gRate}
              gPlace={subjectCompetencies?.gPlace}
              communication={communicationRate}
              analytics={analyticsRate}
              command={commandRate}
            />
            {!isSubjectAdmin && (
              <BaseBox className="state-block__checkbox">
                <h3 className="state-block__checkbox_heading">
                  {t("permissions.role.checkbox.title")}
                </h3>
                <ul className="state-block__checkbox_list">
                  <li className="state-block__checkbox_list_element">
                    <InputCheckbox
                      value={isHrChecked}
                      onChange={onChangeHandler}
                      type={Checkbox.CHOSEN}
                    />
                    <p className="state-block__checkbox_list_text">HR</p>
                  </li>
                </ul>
              </BaseBox>
            )}
            {isRightsSectionShown && (
              <RightsSection
                isSubjectHr={isSubjectHr}
                subjectUserId={subjectUserId}
              />
            )}
            {doesPlayerHaveResume && (
              <div className="state-block__document">
                <ResumeDownloadBlock
                  className="resume-upload__file"
                  resumeName={resumeFileName}
                  userId={subjectUserId}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {!subjectUser && !isSubjectInfoLoading && (
        <EmptyBlock title={t("userForm.errorMessage.notFound")} />
      )}

      {isSubjectInfoError && <EmptyBlock title={t("alert.requestError")} />}

      <ConfirmPopup
        popupName={EPopupName.CONFIRM_RESET_PASSWORD_USER}
        title={t("popup.confirmResetPassword.user.title")}
        description={t("popup.confirmResetPassword.user.description")}
        confirmBtnLabel={t("common.reset")}
        onConfirm={resetPasswordHandler}
        isConfirmBtnDisabled={isResetPasswordLoading}
        reverse
      />
    </PageWrapper>
  );
};
