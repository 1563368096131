import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { reflect } from "@effector/reflect";

import { useUrlState } from "src/shared/hooks/useUrlState";

import { useUpdatedQueryParams } from "src/shared/hooks/useUpdatedQueryParams";

import { FinesDataGrid, finesModel } from "src/entities/admin/fines";

import "./FinesPage.scss";
import { $isLoading } from "../../../model";
import { useTranslation } from "react-i18next";
import {
  ArchiveLink,
  BaseButton,
  EPopupName,
  PageWrapper,
  TextWithIcon,
} from "src/shared/components";
import { FinesPopover } from "../../fines-popover/ui/fines-popover";
import { $fines, $pagination } from "src/entities/admin/fines/model";
import { openPopup } from "src/shared/components/base-popup/model";
import { DeleteFinesPopup } from "../../delete-fines-popup/delete-fines-popup";
import { AddAnotherFinePopup } from "../../add-another-fine-popup/add-another-fine-popup";
import { useUnit } from "effector-react";
import { showDataGrid } from "src/shared/helpers/showDataGrid";
import { clearState } from "../../../../../../shared/helpers/clearState";

interface Props {
  isLoading: boolean;
}

const View: FC<Props> = ({ isLoading }): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();

  const fines = useUnit($fines);

  const pagination = useUnit($pagination);

  const { updatedQueryParams } = useUpdatedQueryParams();

  const [addFinesPopoverOpened, setAddFinesPopoverOpened] =
    useState<boolean>(false);

  const [editFinesPopoverOpened, setEditFinesPopoverOpened] =
    useState<boolean>(false);

  const [selectedFines, setSelectedFines] = useState<string[]>([]);

  const changingButtonsVisible = selectedFines.length > 0;

  const onAddFines = () => {
    if (editFinesPopoverOpened) {
      setEditFinesPopoverOpened(false);
    }
    openPopup({ name: EPopupName.ADD_FINES_POPUP });
    setAddFinesPopoverOpened((prev) => !prev);
  };

  const closeAllPopovers = () => {
    setAddFinesPopoverOpened(false);
    setEditFinesPopoverOpened(false);
  };

  const onClickDocument = (evt: MouseEvent) => {
    const target = evt.target as HTMLDivElement;

    if (
      !target.closest(".fines-control-panel-buttons") &&
      !target.closest(".base-popover") &&
      !target.closest(".base-popup") &&
      !target.closest(".fines-page-without-fines")
    ) {
      closeAllPopovers();
    }
  };

  useEffect(() => {
    document.addEventListener("click", onClickDocument);

    return () => {
      document.removeEventListener("click", onClickDocument);
    };
  });

  const onEditFines = () => {
    if (addFinesPopoverOpened) {
      setAddFinesPopoverOpened(false);
    }
    openPopup({ name: EPopupName.EDIT_FINES_POPUP });
    setEditFinesPopoverOpened((prev) => !prev);
  };

  const onDeleteFines = () => {
    if (editFinesPopoverOpened || addFinesPopoverOpened) {
      closeAllPopovers();
    }

    openPopup({
      name: EPopupName.DELETE_FINES_POPUP,
    });
  };

  const setSelectedRow = useCallback(
    (idList: string[]) => {
      setSelectedFines(idList);
    },
    [setSelectedFines],
  );

  const clearSelectedFines = () => {
    setSelectedFines((prevState) => {
      prevState.length = 0;
      return [];
    });
  };

  useEffect(() => {
    if (updatedQueryParams) {
      if (updatedQueryParams.mode === "readmore") {
        finesModel.readmoreFines(updatedQueryParams);
      } else {
        finesModel.fetchFines(updatedQueryParams);
      }
    }
  }, [updatedQueryParams]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  return (
    <PageWrapper
      title={t("navigation.other")}
      isLoadingPanel={isLoading}
      isShowContentWhenIsLoading
      tabsPanelSlot={<ArchiveLink to={"/other/fines-archive?archived=true"} />}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={selectedFines.length}
      controlPanelTitle={t("navigation.other.fines")}
      controlPanelSlot={
        <div>
          <div className="fines-control-panel-buttons">
            {filtersLength > 0 && (
              <BaseButton onClick={clearFilterSettings} lightPurple>
                <TextWithIcon
                  label={t("table.filters.reset")}
                  iconName={"cross-blue"}
                />
              </BaseButton>
            )}
            {changingButtonsVisible && (
              <>
                <BaseButton onClick={onDeleteFines} lightPurple>
                  <TextWithIcon label="Удалить" iconName="trash-blue" />
                </BaseButton>
                <DeleteFinesPopup
                  clearSelectedFines={clearSelectedFines}
                  selectedFines={selectedFines}
                />
                <BaseButton onClick={onEditFines} lightPurple>
                  <TextWithIcon label="Редактировать" iconName="pen-blue" />
                </BaseButton>
                <FinesPopover
                  isEdit
                  fineItems={selectedFines}
                  clearSelectedFines={clearSelectedFines}
                  onClose={() => {
                    setEditFinesPopoverOpened(false);
                  }}
                  isOpened={editFinesPopoverOpened}
                />
              </>
            )}
            <BaseButton onClick={onAddFines} lightPurple>
              <TextWithIcon
                isTextNowrap
                label={t("fine.addFine")}
                iconName="plus-blue"
              />
            </BaseButton>
            <AddAnotherFinePopup />
          </div>
          <FinesPopover
            clearSelectedFines={clearSelectedFines}
            onClose={() => {
              setAddFinesPopoverOpened(false);
            }}
            isOpened={addFinesPopoverOpened}
          />
        </div>
      }
    >
      <div className="fines-page">
        {showDataGrid(fines, urlState) ? (
          <FinesDataGrid
            selectedRowsIds={selectedFines}
            onSelectRows={setSelectedRow}
            queryParams={urlState.query}
            goToPage={goToPage}
            updateQueryParams={(params) =>
              setUrlState({
                ...urlState,
                query: params,
              })
            }
          />
        ) : (
          <div className="fines-page-without-fines">
            <span>{t("Вы ещё не добавили ни одного штрафа")}</span>
            <BaseButton primary onClick={onAddFines}>
              {t("fine.addFine")}
            </BaseButton>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export const FinesPage = reflect<Props>({
  view: View,
  bind: {
    isLoading: $isLoading,
  },
  hooks: {
    unmounted: finesModel.resetFines,
  },
});
