import axios from "axios";

import {
  AssessmentApi,
  AssessmentApiFactory,
  Configuration,
  ExportApi,
} from "src/generated/statistic";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_STATISTIC_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_STATISTIC_URL;

    config.headers = {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const makeAssessmentsRequest = () => {
  return new AssessmentApi(new Configuration(), "", apiInstance);
};

const makeExportStatisticsRequest = () => {
  return new ExportApi(new Configuration(), "", apiInstance);
};

const makeExportStatisticBlobRequest = () => {
  return new ExportApi(
    new Configuration({
      baseOptions: {
        responseType: "blob",
        headers: {
          "Content-Type": "application/zip",
        },
      },
    }),
    "",
    apiInstance,
  );
};

const makeAssessmentApiFactory = () => {
  return AssessmentApiFactory(new Configuration(), "", apiInstance);
};

export const statisticService = {
  makeAssessmentsRequest,
  makeExportStatisticsRequest,
  makeExportStatisticBlobRequest,
  makeAssessmentApiFactory,
};
