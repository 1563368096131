import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "../../";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  EDataGridTextColor,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "../../../models/dataGrid";

import { UserInfoInterface } from "../../../store/types";
import { UserRoleEnum } from "../../../models/user";
import { getAvatarUrl } from "../../../helpers/pathToUserFile";
import { getActiveGroup } from "../../../helpers/data-grids/getActiveGroup";
import { getOrderByValue } from "../../../helpers/data-grids/getOrderByValue";
import { splitStrings } from "../../../helpers/splitStrings";
import { User } from "src/generated/keycloak";

interface Props {
  users: UserInfoInterface[] | User[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  onSelectRows?: (params: string[]) => void;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
  selectedRowsIds: string[];
}

export const HrTable: FC<Props> = ({
  loading,
  users,
  queryParams,
  onUpdateSortAndFilters,
  pagination,
  onSelectRows,
  goToPage,
  readmore,
  selectedRowsIds,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  // const roleTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
  //   const roleValues = Object.values(ERoleType);

  //   return roleValues.map((value) => ({
  //     value,
  //     label: t(`common.role.${value}`, value),
  //   }));
  // }, [t]);

  const generatedColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: "SELECT",
        type: EDataGridColumnType.SELECT_ROW,
        key: "selected",
        showed: true,
        hiddenTitle: true,
        sticky: {
          mobile: {
            left: 0,
          },
          tablet: {
            left: 0,
          },
          laptop: {
            left: 0,
          },
        },
      },
      {
        title: t("table.header.name"),
        type: EDataGridColumnType.AVATAR,
        key: "fullName",
        showed: true,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.player"),
          activeGroupKey: getActiveGroup(
            ["firstName", "lastName"],
            { all: ["firstName", "lastName"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstName || "",
                },
                {
                  key: "lastName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastName || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstName",
                  "-firstName",
                  "lastName",
                  "-lastName",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstName",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastName",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.phone"),
        type: EDataGridColumnType.PHONE,
        key: "phoneNumber",
        showed: true,
        controlPanel: {
          title: t("table.header.phone"),
          activeGroupKey: getActiveGroup(
            ["phoneNumber"],
            { all: ["phoneNumber"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "phoneNumber",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.phoneNumber || "",
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.phoneWork"),
        type: EDataGridColumnType.PHONE,
        key: "workPhoneNumber",
        showed: true,
        controlPanel: {
          title: t("table.header.phoneWork"),
          activeGroupKey: getActiveGroup(
            ["workPhoneNumber"],
            { all: ["workPhoneNumber"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "workPhoneNumber",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.workPhoneNumber || "",
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.email"),
        type: EDataGridColumnType.EMAIL,
        key: "email",
        showed: true,
        controlPanel: {
          title: t("table.header.email"),
          activeGroupKey: getActiveGroup(
            ["email"],
            { all: ["email"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "email",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.email || "",
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.city"),
        type: EDataGridColumnType.STRING,
        key: "locality",
        showed: true,
        filterTitle: t("table.header.city"),
        filterPosition: EDataGridFilterPosition.LEFT,
        controlPanel: {
          title: t("table.header.city"),
          activeGroupKey: getActiveGroup(
            ["locality"],
            { all: ["locality"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "locality",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.locality || "",
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.studyWorkPlace"),
        type: EDataGridColumnType.STRING,
        key: "studyWorkPlace",
        showed: true,
        filterPosition: EDataGridFilterPosition.LEFT,
        controlPanel: {
          title: t("table.header.studyWorkPlace"),
          activeGroupKey: getActiveGroup(
            ["studyWorkPlace"],
            { all: ["studyWorkPlace"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "studyWorkPlace",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.studyWorkPlace || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "studyWorkPlace",
                  "-studyWorkPlace",
                ]),
                orderKey: "orderBy",
                key: "studyWorkPlace",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "studyWorkPlace",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-studyWorkPlace",
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    [queryParams, t],
  );

  const getRole = useCallback(
    (user: UserInfoInterface | User) => {
      if (user.realmRoles?.includes(UserRoleEnum.HR_ALABUGA)) {
        return t("common.role.HR-Alabuga", "HR-Alabuga");
      }

      if (user.realmRoles?.includes(UserRoleEnum.HR_STAFF)) {
        return t("common.role.HR", "HR");
      }
    },
    [t],
  );

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = users.map((item): IDataGridRow => {
      const fullName = splitStrings([item.firstName, item.lastName]);
      const role = getRole(item);

      const workPhoneNumber = item.attributes?.workPhoneNumber
        ?.toString()
        ?.trim();

      return {
        id: item.id ? item.id : "",
        firstName: item.firstName ? item.firstName : "—",
        lastName: item.lastName ? item.lastName : "—",
        fullName,
        role,
        phoneNumber: item.attributes?.phoneNumber,
        workPhoneNumber: workPhoneNumber || null,
        email: item.email,
        locality: item.attributes?.locality,
        birthdate: item.attributes?.birthdate,
        studyWorkPlace: item.attributes?.studyWorkPlace?.[0],
        selected: false,
        columnsTextColor: {
          email: !item.emailVerified ? EDataGridTextColor.RED : null,
        },
        avatarProps: {
          alt: fullName,
          fullName,
          customPath: getAvatarUrl(item.id),
          userId: item.id,
          link: `/users-control/user/${item.id}/info`,
        },
      };
    });

    setRows(rowsItems);
  }, [users, i18n.language, t, getRole]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      onUpdateSelectedRows={onSelectRows}
      selectedRowsIds={selectedRowsIds}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
