import { FormDataInterface } from "../components/user-form/user-form";
import { UserInfoInterface } from "../store/types";
import { UserReprAcc } from "../../generated/keycloak";
import { Player } from "../../generated/social";

export const getUserFormData = (
  dataForFormat?: UserInfoInterface | UserReprAcc,
  socialPlayer?: Player | null,
) => {
  const data: FormDataInterface = {
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    login: "",
    country: "",
    region: "",
    district: "",
    locality: "",
    school: "",
    groupCode: "",
    birthdate: "",
    supervisedUnit: "",
    studyWorkPlace: "",
    workPhoneNumber: "",
    avatarUrl: "",
    resumeUrl: "",
    resumeFileName: "",
    desiredStudyPlace: "",
    desiredSpecialty: [],
    isEmployee: false,
    isStudent: false,
  };

  if (dataForFormat) {
    data.firstName = dataForFormat.firstName || "";
    data.lastName = dataForFormat.lastName || "";
    data.middleName = dataForFormat.attributes?.middleName?.[0] || "";
    data.email = dataForFormat.email || "";
    data.phoneNumber = dataForFormat.attributes?.phoneNumber?.[0] || "";
    data.gender = dataForFormat.attributes?.gender?.[0] || "";
    data.login = dataForFormat.attributes?.login?.[0] || "";
    data.country = dataForFormat.attributes?.country?.[0] || "";
    data.region = dataForFormat.attributes?.region?.[0] || "";
    data.district = dataForFormat.attributes?.district?.[0] || "";
    data.locality = dataForFormat.attributes?.locality?.[0] || "";
    data.school = dataForFormat.attributes?.school?.[0] || "";
    data.groupCode = dataForFormat.attributes?.groupCode?.[0] || "";
    data.birthdate = dataForFormat.attributes?.birthdate?.[0] || "";
    data.supervisedUnit = dataForFormat.attributes?.supervisedUnit?.[0] || "";
    data.studyWorkPlace = dataForFormat.attributes?.studyWorkPlace?.[0] || "";
    data.workPhoneNumber = dataForFormat.attributes?.workPhoneNumber?.[0] || "";
    data.avatarUrl = dataForFormat.attributes?.avatarUrl?.[0] || "";
    data.resumeUrl = dataForFormat.attributes?.resumeUrl?.[0] || "";
    data.resumeFileName = dataForFormat.attributes?.resumeFileName?.[0] || "";
    data.desiredStudyPlace =
      dataForFormat.attributes?.desiredStudyPlace?.[0] || "";
    data.desiredSpecialty =
      dataForFormat.attributes?.desiredSpecialty?.filter((item) => !!item) ||
      [];
    data.isEmployee = socialPlayer?.is_employee;
    data.isStudent = socialPlayer?.is_student;
  }

  return data;
};
