import React, { FC, ReactElement } from "react";
import { EPopupName } from "../../base-popup";
import { ConfirmPopup } from "../confirm-popup/confirm-popup";
import { useUnit } from "effector-react";
import { $activePopups, closePopup } from "../../base-popup/model";
import { fetchPostUnlinkRelative } from "../../../../entities/public/relatives/unlink-relative/unlink-relative";
import { useTranslation } from "react-i18next";

interface Props {}

export const UnLinkRelativePopup: FC<Props> = (): ReactElement => {
  const activePopups = useUnit($activePopups);

  const { t } = useTranslation();

  const currentPopup = activePopups.find(
    ({ name }) => name === EPopupName.UNLINK_RELATIVE_POPUP,
  );

  const onConfirm = () => {
    fetchPostUnlinkRelative({ pId: currentPopup?.data });
    closePopup({ name: EPopupName.UNLINK_RELATIVE_POPUP });
  };

  return (
    <ConfirmPopup
      popupName={EPopupName.UNLINK_RELATIVE_POPUP}
      title={t("relative.unLinkRelative")}
      confirmBtnLabel={t("common.confirm")}
      cancelBtnLabel={t("common.cancel")}
      onConfirm={onConfirm}
      isSafe
    />
  );
};
