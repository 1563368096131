import { FC, Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BaseButton, TextWithIcon } from "../../../../shared/components";
import "./users-control-panel-btn.scss";

interface Props {
  userId?: string;
  uploadLink?: string;
  addUserType?: string;
  isPlayerHrAdminPage?: boolean;
  isAdminPage?: boolean;
  isArchivePage?: boolean;
  isLinkedMembersPage?: boolean;
  isEditLinkedMembersPage?: boolean;
  isLockDisabled?: boolean;
  isUnlockDisabled?: boolean;
  isDeleteDisabled?: boolean;
  isUntieDisabled?: boolean;
  onLock?: () => void;
  onUnlock?: () => void;
  onDelete?: () => void;
  onUntie?: () => void;
  onSave?: () => void;
}

export const UsersControlPanelBtn: FC<Props> = ({
  userId,
  uploadLink,
  addUserType = "player",
  isPlayerHrAdminPage = false,
  isAdminPage = false,
  isArchivePage = false,
  isLinkedMembersPage = false,
  isEditLinkedMembersPage = false,
  isLockDisabled = false,
  isUnlockDisabled = false,
  isDeleteDisabled = false,
  isUntieDisabled = false,
  onLock,
  onUnlock,
  onDelete,
  onUntie,
  onSave,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="users-control-panel-btn">
      {isPlayerHrAdminPage && (
        <Fragment>
          {uploadLink && (
            <div className="users-control-panel-btn__container--upload">
              <BaseButton>
                <TextWithIcon
                  isTextNowrap
                  label={t("usersControl.controlPanel.btn.upload")}
                  iconName="upload-blue"
                />
              </BaseButton>
            </div>
          )}
          <Link
            className="users-control-panel-btn__link users-control-panel-btn__container"
            to={`/users-control/add-user?userType=${addUserType}`}
          >
            <BaseButton outline small>
              <TextWithIcon
                label={t("usersControl.controlPanel.btn.add")}
                iconName="plus-blue"
              />
            </BaseButton>
          </Link>
          {!isAdminPage && (
            <div className="users-control-panel-btn__container">
              <BaseButton
                onClick={onLock}
                disabled={isLockDisabled}
                outline
                small
              >
                <TextWithIcon
                  label={t("usersControl.controlPanel.btn.block")}
                  iconName="lock-blue"
                />
              </BaseButton>
            </div>
          )}
        </Fragment>
      )}
      {isArchivePage && (
        <Fragment>
          <div className="users-control-panel-btn__container">
            <BaseButton
              onClick={onUnlock}
              disabled={isUnlockDisabled}
              outline
              small
            >
              <TextWithIcon
                label={t("usersControl.controlPanel.btn.unblock")}
                iconName="unlock-blue"
              />
            </BaseButton>
          </div>
          <div className="users-control-panel-btn__container">
            <BaseButton
              onClick={onDelete}
              disabled={isDeleteDisabled}
              outline
              small
            >
              <TextWithIcon
                label={t("usersControl.controlPanel.btn.delete")}
                iconName="trash-blue"
              />
            </BaseButton>
          </div>
        </Fragment>
      )}
      {isLinkedMembersPage && (
        <Fragment>
          <div className="users-control-panel-btn__container">
            <BaseButton
              onClick={onUntie}
              disabled={isUntieDisabled}
              outline
              small
            >
              <TextWithIcon
                label={t("usersControl.controlPanel.btn.untie")}
                iconName="minus-blue"
              />
            </BaseButton>
          </div>
          <Link
            to={`/users-control/user/${userId}/edit-linked-members`}
            className="users-control-panel-btn__link users-control-panel-btn__container"
          >
            <BaseButton outline small>
              <TextWithIcon
                label={t("usersControl.controlPanel.btn.bind")}
                iconName="plus-blue"
              />
            </BaseButton>
          </Link>
        </Fragment>
      )}
      {isEditLinkedMembersPage && (
        <BaseButton primary small onClick={onSave}>
          {t("usersControl.btn.saveChanges")}
        </BaseButton>
      )}
    </div>
  );
};
