import { FC, useEffect } from "react";

import { reflect } from "@effector/reflect";

import { useUnit } from "effector-react";

import { Pagination, QueryParams } from "src/shared/api/base";

import { IDataGridColumn, IDataGridRow } from "src/shared/models/dataGrid";

import { DataGrid as DataGridOriginal } from "src/shared/components/data-grid/data-grid-lazy";

import { EUserSettingsTablesName } from "src/entities/public/user-settings";

import { userSettingsModel } from "src/features/public/user-settings";

import {
  $columns,
  $isLoading,
  $rows,
  fetchPlaces,
} from "../../model/data-grid";

import { $pagination } from "../../model/players";

const { $tablesSettings } = userSettingsModel;

interface Props {
  rows: IDataGridRow[];
  columns: IDataGridColumn[];
  pagination?: Pagination;
  isLoading?: boolean;
  queryParams?: QueryParams;
  updateQueryParams?: (params: QueryParams) => void;
  onUpdateSelectedRows?: (ids: string[]) => void;
  selectedRowsIds: string[];
}

const View: FC<Props> = ({
  rows,
  columns,
  pagination = null,
  isLoading = false,
  queryParams = {},
  updateQueryParams,
  onUpdateSelectedRows,
  selectedRowsIds,
}) => {
  const goToPage = (pageNum: number, isReadmore?: boolean) => {
    if (updateQueryParams) {
      const params: QueryParams = { ...queryParams, pageNum: String(pageNum) };

      if (isReadmore) {
        params.mode = "readmore";
      } else {
        delete params.mode;
      }

      updateQueryParams(params);
    }
  };

  const tablesSettings = useUnit($tablesSettings);

  useEffect(() => {
    fetchPlaces(queryParams);
  }, [queryParams]);

  return (
    <DataGridOriginal
      selectedRowsIds={selectedRowsIds}
      rows={rows}
      columns={columns}
      loading={isLoading}
      pagination={pagination}
      queryParams={queryParams}
      onUpdateSortAndFilters={updateQueryParams}
      goToPage={goToPage}
      readmore={(pageNum) => goToPage(pageNum, true)}
      nowrap
      onUpdateSelectedRows={onUpdateSelectedRows}
      tableSettings={tablesSettings?.[EUserSettingsTablesName.MAILING]}
    />
  );
};

export const DataGrid = reflect<Props>({
  view: View,
  bind: {
    rows: $rows,
    columns: $columns,
    isLoading: $isLoading,
    pagination: $pagination,
  },
});
