import {
  AssessmentType,
  CalcType,
  GameType,
  UpdAdmCalcDto,
  UpdAdmCalcsDto,
} from "../../../../generated/game";
import {
  IFormulasData,
  TFormulasDataCompetence,
  TFormulasDataSingle,
} from "./types";

export const getCalcs = (formulasData: IFormulasData): UpdAdmCalcsDto => {
  const calcs: UpdAdmCalcDto[] = [];

  const localCompetenceFormulas: TFormulasDataCompetence =
    formulasData.competenceFormulas;

  const localSingleFormulas: TFormulasDataSingle = formulasData.singleFormulas;

  const competenceFormulasKeys = Object.keys(
    localCompetenceFormulas,
  ) as GameType[];

  competenceFormulasKeys.forEach((gameType: GameType) => {
    const gameTypeFormulaItem = localCompetenceFormulas[gameType];

    const calcTypeKeys = Object.keys(gameTypeFormulaItem) as CalcType[];

    calcTypeKeys.forEach((type: CalcType) => {
      const calcTypeFormulaItem = gameTypeFormulaItem[type];

      calcs.push({
        gameType: gameType,
        assessmentType: AssessmentType.Rating,
        calcType: type,
        formula: calcTypeFormulaItem,
      });
    });
  });

  const singleFormulasKeys = Object.keys(localSingleFormulas) as GameType[];

  singleFormulasKeys.forEach((mode: GameType) => {
    const gameTypeFormulaItem = localSingleFormulas[mode];

    const calcTypeKeys = Object.keys(gameTypeFormulaItem) as CalcType[];

    calcTypeKeys.forEach((type: CalcType) => {
      const calcTypeFormulaItem = gameTypeFormulaItem[type];

      if (!!calcTypeFormulaItem.trim()) {
        calcs.push({
          gameType: mode,
          assessmentType: AssessmentType.Rating,
          calcType: type,
          formula: calcTypeFormulaItem.trim(),
        });
      }
    });
  });

  return { calcs };
};
