import { useMemo } from "react";

import { isEqual } from "lodash";
import { useUrlState } from "src/shared/hooks/useUrlState";

import usePrevious from "./usePrevious";

import { QueryParams } from "../../shared/api/base";

export const useUpdatedQueryParams = () => {
  const [urlState] = useUrlState();

  const prevUrlState = usePrevious(urlState);

  const updatedQueryParams = useMemo<QueryParams | null>(() => {
    return !isEqual(urlState.query, prevUrlState?.query)
      ? urlState.query
      : null;
  }, [urlState, prevUrlState]);

  return { updatedQueryParams };
};
