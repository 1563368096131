import { InfoItem } from "../../../../generated/cdn";
import { RootState } from "../../store";
import { ERequestStatus } from "../../types";
import { ICdnState } from "./type";

export const selectCdn = (state: RootState): ICdnState => state.cdn;

export const selectCdnContactAvatarStatus = (
  state: RootState
): ERequestStatus => selectCdn(state).statusContactAvatar;

export const selectCdnOptionsContactAvatarStatus = (
  state: RootState
): ERequestStatus => selectCdn(state).statusOptionsContactAvatar;

export const selectCdnResumeStatus = (state: RootState): ERequestStatus =>
  selectCdn(state).statusResume;

export const selectCdnGetResumeStatus = (state: RootState): ERequestStatus =>
  selectCdn(state).statusGetResume;

export const selectCdnOptionsResumeStatus = (
  state: RootState
): ERequestStatus => selectCdn(state).statusOptionsResume;

export const selectCdnUserAvatarStatus = (state: RootState): ERequestStatus =>
  selectCdn(state).statusUserAvatar;

export const selectCdnOptionsUserAvatarStatus = (
  state: RootState
): ERequestStatus => selectCdn(state).statusOptionsUserAvatar;

export const selectCdnOptionsContactAvatar = (
  state: RootState
): InfoItem | null => selectCdn(state).optionsContactAvatar;

export const selectCdnOptionsUserAvatar = (state: RootState): InfoItem | null =>
  selectCdn(state).optionsUserAvatar;

export const selectCdnOptionsResume = (state: RootState): InfoItem | null =>
  selectCdn(state).optionsResume;
