import { FC, ReactElement } from "react";

import { Checkbox } from "../../../models/checkbox";

import { IDataGridColumnControlPanelGroup } from "../../../models/dataGrid";

import { InputCheckbox } from "../../input-checkbox/input-checkbox";

interface Props {
  group: IDataGridColumnControlPanelGroup;
  values: string[];
  setValue: (value: string) => void;
}

export const DataGridMobileSortGroup: FC<Props> = ({
  group,
  values,
  setValue,
}): ReactElement => {
  return (
    <div>
      {group.sort?.items.map((sortItem) => (
        <InputCheckbox
          forSort
          onChange={() => setValue(sortItem.value)}
          value={values.includes(sortItem.value)}
          type={
            values.includes(sortItem.value) ? Checkbox.CHOSEN : Checkbox.EMPTY
          }
          className="data-grid__sort-item"
          key={sortItem.title}
        >
          {sortItem.title}
        </InputCheckbox>
      ))}
    </div>
  );
};
