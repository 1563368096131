import { Competence } from "../../generated/ws4";
import { getCoefficientText } from "./getCoefficientText";
import { IDislikeTooltipFactoryItem } from "../../features/public/dislike-info/model/types";
import i18n from "i18next";

export const getCompetencyType = (key: Competence): string => {
  //  Получаем название компетенции из его названия в ru.json/en.json
  return i18n.t(`table.header.${key}.short`);
};

const getCompetencyDislikes = (
  data: Record<string, { dislikesSum: number | string }>,
): string[] => {
  //  Получаем все компетенции имеющие dislikeSum и игнорируем компетенеции, которые не должны быть в доп. комптенеции
  //  формируем (название компетенции и кол-во дизлайков)

  const negativeDislikes: string[] = [];

  const ignoreCompetencies = [
    Competence.COMMUNICATION,
    Competence.ANALYTICS,
    Competence.COMMAND,
  ];

  for (const key in data) {
    const dislike = data[key].dislikesSum;
    if (ignoreCompetencies.includes(key as Competence)) {
      continue;
    }
    if (Number(dislike) < 0) {
      negativeDislikes.push(
        `${getCompetencyType(key as Competence)}${dislike}`,
      );
    }
  }

  return negativeDislikes;
};

export const getCompetencies = (
  dislikesTooltips?: Partial<Record<Competence, IDislikeTooltipFactoryItem>>,
  competencies?: Record<string, number>[],
): string => {
  // Формируем все входящие компетенции с бека имеющие не 0 в общую колонку
  let coefficientText = getCoefficientText(0);

  if (dislikesTooltips) {
    coefficientText = getCompetencyDislikes(dislikesTooltips)
      .map((el) => el)
      .join(", ");
  }

  if (competencies) {
    const commonCompetencies: string[] = [];

    competencies.forEach((competency) => {
      const keyCompetency = Object.keys(competency)[0] as Competence;
      const valueCompetency = competency[keyCompetency];
      const currentCompetency = getCompetencyType(keyCompetency);
      const coefficientValueText = getCoefficientText(valueCompetency);

      if (valueCompetency !== 0 && coefficientValueText !== "—") {
        commonCompetencies.push(`${currentCompetency}${coefficientValueText}`);
      }
    });

    coefficientText = commonCompetencies.join(", ");
  }

  return coefficientText;
};
