import { FC, ReactElement } from "react";
import { reflect } from "@effector/reflect";

import { DataGrid as DataGridOriginal } from "src/shared/components/data-grid/data-grid-lazy";

import { Pagination, QueryParams } from "../../../../shared/api/base";

import { IDataGridColumn, IDataGridRow } from "src/shared/models";

import { $columns, $isLoading, $pagination, $rows } from "../model";

interface DataGridFinesProps {
  rows: IDataGridRow[];
  columns: IDataGridColumn[];
  pagination: Pagination;
  isLoading: boolean;
  queryParams: QueryParams;
  isArchive: boolean;
  toggleRegEnabled: (code: string) => void;
  selectedRowsIds: string[];
  onSelectRows?: (params: string[]) => void;
  goToPage: (pageNum: number) => void;
  updateQueryParams?: (params: QueryParams) => void;
}

const View: FC<DataGridFinesProps> = ({
  rows,
  columns,
  pagination,
  isLoading,
  queryParams = {},
  selectedRowsIds,
  goToPage,
  onSelectRows,
  updateQueryParams,
}): ReactElement => {
  return (
    <DataGridOriginal
      rows={rows}
      columns={columns}
      loading={isLoading}
      pagination={pagination}
      queryParams={queryParams}
      onUpdateSortAndFilters={updateQueryParams}
      goToPage={goToPage}
      onUpdateSelectedRows={onSelectRows}
      selectedRowsIds={selectedRowsIds}
      readmore={(pageNum) => goToPage(pageNum)}
      nowrap
    />
  );
};

export const DataGrid = reflect<DataGridFinesProps>({
  view: View,
  bind: {
    rows: $rows,
    isLoading: $isLoading,
    pagination: $pagination,
    columns: $columns,
  },
});
