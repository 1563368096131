import { FC, ReactElement } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { formatDate } from "../../helpers/formatDate";

interface Props {
  verified?: boolean;
  date?: number | string;
}

export const PlayerShortStatStatus: FC<Props> = ({
  verified,
  date,
}): ReactElement => {
  const { t } = useTranslation();

  const formattedDate = formatDate(date, "dd.MM.yyyy");

  return (
    <span
      className={classNames(
        "player-short-stat__status",
        `player-short-stat__status--${verified ? "verified" : "notVerified"}`
      )}
    >
      {t(`profile.info.emailStatus.${verified ? "verified" : "notVerified"}`)}
      {formattedDate && (
        <span className="player-short-stat__status-date">{formattedDate}</span>
      )}
    </span>
  );
};
