import { FC, Fragment, ReactElement } from "react";

import classNames from "classnames";

import { Join as UserInfoDto } from "src/generated/ws4";

import useClient from "src/shared/hooks/useClient";

import { EPopupName, SelectItem } from "src/shared/components";

import { IBaseInputChange } from "src/shared/models/base-input";

import { IPlayer, ITeam } from "../../session-table";
import { selectRoomName } from "../../../../../../../shared/store/ducks/room";
import { useSelector } from "react-redux";
import { Specialties } from "../../../../../../../shared/store/types";
import { CreatedBy } from "../../../../../../../generated/game";

import styles from "./players-list.module.scss";
import { useTranslation } from "react-i18next";
import avatarPlaceholder from "../../../../../../../shared/images/icons/avatar-placeholder.svg";
import { openPopup } from "src/shared/components/base-popup/model";
import { GameType } from "src/generated/statistic";
import { ConnectDropTarget } from "react-dnd";
import { PlayerCard } from "../player-card/player-card";

interface Props {
  group: ITeam;
  isTeam?: boolean;
  isCEO?: boolean;
  isCorp?: boolean;
  areaNum?: number;
  currentPlayer?: string;
  disableSelect?: boolean;
  itemsCount: number;
  deletePlayer?: (player: UserInfoDto) => void;
  unAllocatePlayers?: (
    players: UserInfoDto[],
    popup: "player" | "area" | "team",
  ) => void;
  onChangeRole?: (role: string, currentRole: string, userId: string) => void;
  teams?: ITeam[];
  dropRef?: ConnectDropTarget;
  isOver?: boolean;
}

export const PlayersList: FC<Props> = ({
  group,
  isTeam,
  isCorp,
  isCEO,
  areaNum,
  currentPlayer,
  disableSelect,
  itemsCount,
  deletePlayer,
  unAllocatePlayers,
  onChangeRole,
  teams,
  dropRef,
  isOver,
}): ReactElement => {
  const { isHrClientId } = useClient();

  const room = useSelector(selectRoomName);

  const { t } = useTranslation();

  const isTopDropdownPosition = (index: number): boolean => {
    if (itemsCount < 4) return false;

    return index >= itemsCount - 2;
  };

  const onChangeHandler = (
    e: IBaseInputChange,
    currentRole: string,
    userId: string,
  ): void => {
    if (onChangeRole) {
      onChangeRole(e.value, currentRole, userId);
    }
  };

  const computeLabel = (item: UserInfoDto): string | undefined => {
    const currentRole: SelectItem | undefined = group.roles.find(
      (role: SelectItem) => role.value === item.role,
    );

    return currentRole?.label;
  };

  let groupPlayers = group.players;

  if (isCorp) {
    if (isCEO) {
      groupPlayers = groupPlayers.filter(
        (item: UserInfoDto) => item.role === Specialties.CEO,
      );
    } else {
      groupPlayers = groupPlayers.filter(
        (item: UserInfoDto) =>
          item.areaNum === areaNum && item.role !== Specialties.CEO,
      );
    }
  }

  const handleCrossClick = (event: React.MouseEvent, player: IPlayer) => {
    event.stopPropagation();
    if ((isTeam && room.createdBy === CreatedBy.User) || isCorp) {
      if (unAllocatePlayers) {
        unAllocatePlayers([player], "player");
      }
    } else {
      if (deletePlayer) {
        deletePlayer(player);
      }
    }
  };

  const openAllocatePlayerPopup = (player: UserInfoDto) => {
    if (room.gameType !== GameType.Solo) {
      openPopup({
        name: EPopupName.ALLOCATE_PLAYER_POPUP,
        data: { player, teams },
      });
    }
  };

  return (
    <Fragment>
      {!groupPlayers.length && isCEO && (
        <div
          ref={dropRef}
          className={classNames(styles.cell, styles.ceoPlaceholder, {
            [styles.ceoPlaceholder__isOver]: isOver,
          })}
        >
          <img src={avatarPlaceholder} alt="avatar" />
          <span className={styles.name}>{t("playerRoles.teamCeo")}</span>
        </div>
      )}
      {groupPlayers.map((item) => (
        <PlayerCard
          key={item.pid}
          item={item}
          group={group}
          disableSelect={disableSelect}
          isCEO={isCEO}
          isTopDropdownPosition={isTopDropdownPosition}
          currentPlayerId={currentPlayer}
          onChangeHandler={onChangeHandler}
          computeLabel={computeLabel}
          isHrClientId={isHrClientId}
          handleCrossClick={handleCrossClick}
          isTeam={isTeam}
          openAllocatePlayerPopup={openAllocatePlayerPopup}
        />
      ))}
    </Fragment>
  );
};
