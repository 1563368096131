import React from "react";
import styles from "../footer.module.scss";
import classNames from "classnames";

interface IFooterHeading {
  title: string;
  className?: string | null;
}
export const FooterHeading: React.FC<IFooterHeading> = ({
  title,
  className = null,
}) => {
  return <h2 className={classNames(styles.heading, className)}>{title}</h2>;
};
