import classNames from "classnames";
import { FC, Fragment, ReactElement } from "react";
import { DataGrid } from "../..";
import { IDataGridColumn, IDataGridRow } from "../../../models/dataGrid";
import "./solo-results-grid.scss";

interface Props {
  columns: IDataGridColumn[];
  rows: IDataGridRow[];
  className?: string;
}

export const SoloResultsGrid: FC<Props> = ({
  columns,
  rows,
  className,
}): ReactElement => {
  return (
    <Fragment>
      {!!rows.length && (
        <div className={classNames("solo-results-grid", className)}>
          <DataGrid
            className="solo-results-grid__table"
            columns={columns}
            rows={rows}
            autoScrollDisabled
          />
        </div>
      )}
    </Fragment>
  );
};
