import { sample } from "effector";
import {
  BulkUpdFaqReq,
  FaqApiPostFaqBulkUpdRequest,
  FaqQuestionDto,
} from "../../../../generated/social";
import { ERequestStatus } from "../../../../shared/store/types";
import { faqDomain } from "./domain";
import { fetchFaqGetFx, fetchFaqPostFx } from "./getFaq";

// Events

export const cleanFaq = faqDomain.createEvent();

export const fetchFaqPostFailure = faqDomain.createEvent();

export const fetchFaqGetFailure = faqDomain.createEvent();

const fetchFaqGet = faqDomain.createEvent();

export const fetchFaqPost = faqDomain.createEvent<{
  items: BulkUpdFaqReq;
  callback: () => void;
}>();

const fetchFaqGetSuccess = faqDomain.createEvent<FaqQuestionDto[]>();

export const fetchFaqPostSuccess = faqDomain.createEvent();

export const fetchFaq = faqDomain.createEvent();

// Stores

export const $faqItems = faqDomain.createStore<FaqQuestionDto[]>([]);
export const $faqStatusGet = faqDomain.createStore<ERequestStatus>(
  ERequestStatus.NEVER,
);
export const $faqStatusPost = faqDomain.createStore<ERequestStatus>(
  ERequestStatus.NEVER,
);
export const $isLoadingGet = faqDomain.createStore<boolean>(false);
export const $isLoadingPost = faqDomain.createStore<boolean>(false);

// EFFECTS

// Bindings

sample({
  clock: fetchFaq,
  target: fetchFaqGetFx,
});

sample({
  clock: fetchFaqGetFx.pending,
  target: $isLoadingGet,
});

sample({
  clock: fetchFaqPostFx.pending,
  target: $isLoadingPost,
});

sample({
  clock: fetchFaqGet,
  target: fetchFaqGetFx,
});

$faqItems.on(fetchFaqGetFx.doneData, (_, faqRes) => {
  return faqRes.questions;
});

fetchFaqPost.watch(({ items, callback }) => {
  const requestPayload: FaqApiPostFaqBulkUpdRequest = {
    body: items,
  };

  fetchFaqPostFx(requestPayload)
    .then(callback)
    .catch((err) => {
      console.error(err);
    });
});

$faqItems.on(fetchFaqGetSuccess, (_, payload) => payload);
$faqStatusGet.on(fetchFaqGetFailure, () => ERequestStatus.ERROR);

sample({
  clock: cleanFaq,
  fn: () => [],
  target: $faqItems,
});

$faqStatusGet
  .on(fetchFaqGetFx, () => ERequestStatus.LOADING)
  .on(fetchFaqGetFx.done, () => ERequestStatus.LOADED)
  .on(fetchFaqGetFx.fail, () => ERequestStatus.ERROR);

$faqStatusPost
  .on(fetchFaqPostFx, () => ERequestStatus.LOADING)
  .on(fetchFaqPostFx.done, () => ERequestStatus.LOADED)
  .on(fetchFaqPostFx.fail, () => ERequestStatus.ERROR);
