import { createEffect, createEvent, createStore, sample } from "effector";
import {
  CandidateEmployee,
  Pagination,
  RelativeApiGetStudentApplicationRequest,
} from "../../../../../generated/social";
import { getEmployeeApplicationHistory } from "../../../../../shared/api/admin/histories/model/moderation-history/getRelativeModerationHistory";
import { queryParamsToRatingsRequestAdapter } from "../../../ratings/lib/queryParamsToRatingsRequestAdapter";
import { QueryParams } from "../../../../../shared/api/base";

// stores

export const $employeeModeration = createStore<CandidateEmployee[]>([]);

export const $employeeQueryParams = createStore<QueryParams>({});

export const $employeePagination = createStore<Pagination | null>(null);

export const $isEmployeeReadmore = createStore<boolean>(false);

export const fetchEmployeeRatings =
  createEvent<RelativeApiGetStudentApplicationRequest>();

export const $isEmployeeRatingsRequestError = createStore<boolean>(false);

export const $isEmployeeRatingsLoading = createStore<boolean>(false);

const fetchEmployeeApplicationFx = createEffect(getEmployeeApplicationHistory);

sample({
  clock: $employeeQueryParams,
  fn: queryParamsToRatingsRequestAdapter,
  target: fetchEmployeeApplicationFx,
});

sample({
  clock: fetchEmployeeRatings,
  target: fetchEmployeeApplicationFx,
});

sample({
  clock: fetchEmployeeApplicationFx.pending,
  target: $isEmployeeRatingsLoading,
});

sample({
  clock: fetchEmployeeApplicationFx.fail,
  fn: () => true,
  target: $isEmployeeRatingsRequestError,
});

sample({
  clock: fetchEmployeeApplicationFx.doneData,
  source: { isReadmore: $isEmployeeReadmore, ratings: $employeeModeration },
  fn: ({ isReadmore, ratings }, response) =>
    isReadmore
      ? [...ratings, ...response.candidate_employee]
      : response.candidate_employee,
  target: $employeeModeration,
});

sample({
  clock: fetchEmployeeApplicationFx.doneData,
  fn: ({ pagination }) => pagination || null,
  target: $employeePagination,
});
