import React, { ChangeEvent, FC, useState } from "react";
import { BaseButton } from "../../../base-button/base-button";
import "./comment-item.scss";
import { useSendingTime } from "../../../../hooks/useSendingTime";
import { useTranslation } from "react-i18next";
import { CommentResponseDto } from "../../../../../generated/social";
import { useUnit } from "effector-react";
import { $userId, editCommentFx, removeCommentFx } from "../../lib/comments";
import { Link } from "react-router-dom";
import { ClientIdEnum } from "../../../../models";
import { $keycloak } from "../../../../../entities/public/keycloak/model";

interface Props {
  comment: CommentResponseDto;
  serverTime: Date;
}

const CommentItem: FC<Props> = ({ comment, serverTime }) => {
  const authorName = `${comment.author_first_name} ${comment.author_last_name}`;

  const userId = useUnit($userId);

  const [commentText, setCommentText] = useState<string>(comment.message);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isEditPending, setIsEditPending] = useState<boolean>(false);

  const [isDeletePending, setIsDeletePending] = useState<boolean>(false);

  const keycloak = useUnit($keycloak);

  const getSendingTime = useSendingTime(serverTime);

  const { t } = useTranslation();

  const deleteComment = async () => {
    setIsDeletePending(true);
    removeCommentFx({
      userId,
      commentId: comment.comment_id,
    }).finally(() => setIsDeletePending(false));
  };

  const editComment = () => {
    !isEditing && setIsEditing(true);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value);
  };

  const handleInputSubmit = async () => {
    setIsEditPending(true);
    await editCommentFx({
      userId,
      commentId: comment.comment_id,
      comment: commentText,
    }).finally(() => setIsEditPending(false));
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setCommentText(comment.message);
  };

  return (
    <div className="comment-item">
      <div className="comment-item__avatar">
        <img
          src={`${process.env.REACT_APP_yandexStorageEndpoint}/${process.env.REACT_APP_yandexStorageImagesBucket}/avatars/${comment.author_id}`}
          className="comment-item__avatar-image"
          alt="avatar"
        />
      </div>
      <div className="comment-item__comment">
        <div className="comment-item__author-and-time">
          <Link
            to={`/ratings/rating/${comment.author_id}`}
            className="comment-item__author"
          >
            {authorName}
          </Link>
          <p className="comment-item__time">
            {getSendingTime(comment.created_at)}
          </p>
        </div>
        {isEditing ? (
          <input
            value={commentText}
            onChange={handleInputChange}
            className="comment-item__body comment-item__body--editing"
            autoFocus
          />
        ) : (
          <div className="comment-item__body">{comment.message}</div>
        )}
        {(keycloak?.clientId === ClientIdEnum.ADMIN ||
          comment.author_id === keycloak?.subject) && (
          <div className="comment-item__controls">
            {isEditing ? (
              <>
                <BaseButton
                  transparent
                  className="comment-item__controls-button"
                  onClick={handleInputSubmit}
                  isLoading={isEditPending}
                >
                  {t("common.save")}
                </BaseButton>
                <BaseButton
                  transparent
                  className="comment-item__controls-button"
                  onClick={handleCancelEdit}
                >
                  {t("common.cancel")}
                </BaseButton>
              </>
            ) : (
              <>
                <BaseButton
                  transparent
                  className="comment-item__controls-button"
                  onClick={editComment}
                >
                  {t("common.edit")}
                </BaseButton>
                <BaseButton
                  transparent
                  className="comment-item__controls-button"
                  onClick={deleteComment}
                  isLoading={isDeletePending}
                >
                  {t("common.delete")}
                </BaseButton>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentItem;
