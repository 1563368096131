import { call, put, takeLatest } from "redux-saga/effects";

import {
  failureSessionsHistory,
  failureSessionsHistoryRoles,
  successSessionsHistory,
  successSessionsHistoryRoles,
} from "./actionCreators";

import {
  ESessionsHistoryActions,
  IRequestSessionsHistory,
  IRequestSessionsHistoryRoles,
} from "./actionTypes";

import { $axiosStatistic } from "../../../api/api";

import { AxiosResponse } from "axios";

import { AssessmentApi, Configuration } from "../../../../generated/statistic";

export function* fetchSessionsHistory({ payload }: IRequestSessionsHistory) {
  const request = new AssessmentApi(new Configuration(), "", $axiosStatistic);

  try {
    const response: AxiosResponse = yield call(
      [request, request.getPAssessments],
      payload.params,
    );

    yield put(successSessionsHistory(response.data));

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    yield put(failureSessionsHistory());
  }
}

export function* fetchSessionsHistoryRoles({
  payload,
}: IRequestSessionsHistoryRoles) {
  const request = new AssessmentApi(new Configuration(), "", $axiosStatistic);

  try {
    const response: AxiosResponse = yield call(
      [request, request.getPRoles],
      payload.params,
    );

    yield put(successSessionsHistoryRoles(response.data));

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    yield put(failureSessionsHistoryRoles());
  }
}

export function* sessionsHistorySaga() {
  yield takeLatest(
    ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY,
    fetchSessionsHistory,
  );

  yield takeLatest(
    ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY_ROLES,
    fetchSessionsHistoryRoles,
  );
}
