import { FC, ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useUnit } from "effector-react";
import { ERequestStatus } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import "./finished-assessments-page.scss";
import {
  cleanAssessmentsResults,
  requestAssessmentsResults,
  setAssessmentsReadmore,
  $assessmentsResults,
} from "src/entities/public/assessments-results";
import { AssessmentType } from "../../../generated/statistic";

import { FinishedAssessmentsTable } from "../../../shared/components/data-grids/assessments-control/finished-assessments-table";
import { useUrlState } from "src/shared/hooks/useUrlState";
import { TDataGridDataParams } from "../../../shared/models/dataGrid";
import isEqual from "lodash/isEqual";
import { getDateForFilter } from "../../../shared/helpers/getDateForFilter";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";

interface Props {}

export const FinishedAssessmentsPage: FC<Props> = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    items: results,
    itemsPagination: pagination,
    statusItems: loadingStatus,
  } = useUnit($assessmentsResults);
  const isLoading: boolean = loadingStatus === ERequestStatus.LOADING;

  const assessmentTypes = useMemo<AssessmentType[]>(
    () => Object.values(AssessmentType),
    [],
  );

  useEffect(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      title,
      gameTitle,
      curPlayersFrom,
      curPlayersTo,
      createdFrom,
      createdTo,
      startedFrom,
      startedTo,
      finishedFrom,
      finishedTo,
      firstName,
      lastName,
    } = urlState.query;

    let assessmentTypeValues: AssessmentType[] = urlState.query.assessmentType
      ? (urlState.query.assessmentType.split(",") as AssessmentType[])
      : [];

    assessmentTypeValues = assessmentTypeValues.filter((assessmentTypeValue) =>
      assessmentTypes.includes(assessmentTypeValue),
    );

    requestAssessmentsResults({
      params: {
        pageSize: pageSize ? Number(pageSize) : 50,
        pageNum: pageNum ? Number(pageNum) : 1,
        orderBy,
        aTitle: title ? [title] : undefined,
        gTitle: gameTitle ? [gameTitle] : undefined,
        aType: !!assessmentTypeValues.length ? assessmentTypeValues : undefined,
        curPlayersFrom: curPlayersFrom ? Number(curPlayersFrom) : undefined,
        curPlayersTo: curPlayersTo ? Number(curPlayersTo) : undefined,
        createdFrom: getDateForFilter(createdFrom),
        createdTo: getDateForFilter(createdTo),
        startedFrom: getDateForFilter(startedFrom),
        startedTo: getDateForFilter(startedTo),
        finishedFrom: getDateForFilter(finishedFrom),
        finishedTo: getDateForFilter(finishedTo),
        firstName: firstName,
        lastName: lastName,
      },
    });
  }, [urlState.query, dispatch, assessmentTypes]);

  useEffect(() => {
    return () => {
      cleanAssessmentsResults();
    };
  }, []);

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setAssessmentsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  return (
    <PageWrapper
      title={t("assessmentsControl.title")}
      isLoadingPanel={isLoading}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("assessmentsControl.controlPanel.label.assessments")}
      controlPanelMaxCount={pagination?.totalItems}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
    >
      <div className="finished-assessments-page">
        <div className="active-assessments-data-grid-wrapper">
          <FinishedAssessmentsTable
            assessmentItems={results}
            queryParams={urlState.query}
            onUpdateSortAndFilters={onUpdateQueryParams}
            loading={isLoading}
            pagination={pagination}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum, true)}
          />
        </div>
      </div>
    </PageWrapper>
  );
};
