import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { openResponseErrorPopup } from "src/shared/components";
import {
  Configuration,
  Manager,
  ManagerApi,
  ManagersRes,
} from "../../../../generated/social";
import { $axiosSocial } from "../../../api/api";
import {
  failureContactCreate,
  failureContactDelete,
  failureContactGet,
  failureContactsGet,
  failureContactUpdate,
  successContactCreate,
  successContactDelete,
  successContactGet,
  successContactsGet,
  successContactUpdate,
} from "./actionCreators";
import {
  EContactsActions,
  IRequestContactCreate,
  IRequestContactDelete,
  IRequestContactGet,
  IRequestContactsGet,
  IRequestContactUpdate,
} from "./actionTypes";

export function* fetchContactsGet({ payload }: IRequestContactsGet) {
  const request = new ManagerApi(new Configuration(), "", $axiosSocial);

  try {
    const response: AxiosResponse<ManagersRes> = yield call(
      [request, request.getManagers],
      payload?.options || {},
    );

    yield put(successContactsGet(response.data.managers));
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failureContactsGet());
  }
}

export function* fetchContactGet({ payload }: IRequestContactGet) {
  const request = new ManagerApi(new Configuration(), "", $axiosSocial);

  try {
    const response: AxiosResponse<Manager> = yield call(
      [request, request.getManager],
      payload.options,
    );

    yield put(successContactGet(response.data));
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failureContactGet());
  }
}

export function* fetchContactUpdate({ payload }: IRequestContactUpdate) {
  const request = new ManagerApi(new Configuration(), "", $axiosSocial);

  try {
    yield call([request, request.putManager], payload.options);

    yield put(successContactUpdate());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failureContactUpdate());
  }
}

export function* fetchContactDelete({ payload }: IRequestContactDelete) {
  const request = new ManagerApi(new Configuration(), "", $axiosSocial);

  try {
    yield call([request, request.delManager], payload.options);

    yield put(successContactDelete());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failureContactDelete());
  }
}

export function* fetchContactCreate({ payload }: IRequestContactCreate) {
  const request = new ManagerApi(new Configuration(), "", $axiosSocial);

  try {
    yield call([request, request.postManager], payload.options);

    yield put(successContactCreate());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failureContactCreate());
  }
}

export function* contactsSaga() {
  yield takeLatest(EContactsActions.REQUEST_CONTACTS_GET, fetchContactsGet);
  yield takeLatest(EContactsActions.REQUEST_CONTACT_GET, fetchContactGet);
  yield takeLatest(EContactsActions.REQUEST_CONTACT_UPDATE, fetchContactUpdate);
  yield takeLatest(EContactsActions.REQUEST_CONTACT_DELETE, fetchContactDelete);
  yield takeLatest(EContactsActions.REQUEST_CONTACT_CREATE, fetchContactCreate);
}
