import { Action } from "redux";
import {
  HrRoom,
  Join,
  PRoomChanged,
  PRoomR,
  Quit,
  Role,
  Team,
} from "src/generated/ws4";
import { AssessmentStatus } from "./../../../../generated/game";

export enum RoomActionsType {
  ROOM_SET_STATE = "ROOM_SET_STATE",
  ROOM_CONNECT_PLAYER = "ROOM_CONNECT_PLAYER",
  ROOM_CONNECTED_PLAYERS_COUNT_CHANGE = "ROOM_CONNECTED_PLAYERS_COUNT_CHANGE",
  ROOM_LEAVE_PLAYER = "ROOM_LEAVE_PLAYER",
  ROOM_CHANGE_ROLE = "ROOM_CHANGE_ROLE",
  ROOM_CLEAR = "ROOM_CLEAR",
  ROOM_ERROR = "ROOM_ERROR",
  ASSESSMENT_STATUS = "ASSESSMENT_STATUS",
  ROOM_CHANGE_TEAM_ROLE = "ROOM_CHANGE_TEAM_ROLE",
}

export interface RoomSetStateActionInterface extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_SET_STATE;
  payload: PRoomR | HrRoom;
}

export interface RoomConnectPlayerActionInterface
  extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_CONNECT_PLAYER;
  payload: Join;
}

export interface RoomConnectedPlayersCountChangeActionInterface
  extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_CONNECTED_PLAYERS_COUNT_CHANGE;
  payload: PRoomChanged;
}

export interface RoomLeavePlayerActionInterface
  extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_LEAVE_PLAYER;
  payload: Quit;
}

export interface RoomChangeRoleActionInterface extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_CHANGE_ROLE;
  payload: Role;
}

export interface RoomChangeTeamRoleActionInterface
  extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_CHANGE_TEAM_ROLE;
  payload: Team;
}

export interface RoomClearActionInterface extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_CLEAR;
  payload?: never;
}

export interface RoomAssessmentErrorInterface extends Action<RoomActionsType> {
  type: RoomActionsType.ROOM_ERROR;
  payload: boolean;
}

export interface AssessmentStatusInterface extends Action<RoomActionsType> {
  type: RoomActionsType.ASSESSMENT_STATUS;
  payload: AssessmentStatus | undefined;
}
