import {
  FC,
  FormEvent,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { reflect } from "@effector/reflect";

import {
  AssessmentType,
  CreateAssessmentReqDto,
  GameType,
  MaxTeams,
} from "src/generated/game";

import {
  EMinMaxPlayersByRole,
  getMinMaxPlayersGameTypeByRole,
  getSimulationParamsErrors,
  getSimulationParamsFieldItem,
  getSimulationParamsForRequest,
  languagePicker,
  TMinMaxPlayersGameTypeByRoles,
  toCloneObject,
} from "src/shared/helpers";

import useInput, {
  EInputValidateType,
  IValidateValue,
} from "src/shared/hooks/useInput";

import {
  BaseButton,
  BaseGroup,
  BaseInputRadio,
  BaseInputText,
  BasePopup,
  BaseSelect,
  EPopupName,
  FormControl,
  MinMaxCountField,
  SelectItem,
  SimulationParamsFields,
  TTemplateParams,
} from "src/shared/components";

import {
  AssessmentAccess,
  ERequestStatus,
  IGameType,
  IMetaParams,
  LobbyLaunchConditions,
  LobbyTimerState,
  Participation,
  RatedParamsTemplatesInterface,
  SimulationAdminInterface,
} from "src/shared/store/types";

import { $createAssessment } from "src/entities/public/create-assessment";

import { IBaseInputChange } from "src/shared/models/base-input";

import { BaseInputNumber } from "../../form-fields";

import {
  ISimulationAdmin,
  simulationsModel,
} from "src/entities/admin/simulations";

import { createAssessment } from "src/entities/hr/assessment-create";

import "./create-assessment-popup.scss";
import { convertValuesToStringsDeep } from "../../../helpers/convertValuesToStringDeep";

interface Props {
  simulations: ISimulationAdmin[];
}

export interface FormDataInterface {
  assessmentTitle: string;
  assessmentType: AssessmentType;
  playerValidation: LobbyLaunchConditions;
  assessmentAccess: AssessmentAccess;
  gameType: GameType;
  maxAreas?: string;
  lobbyTimerState: LobbyTimerState;
  presenceTimer: string;
  confirmTimer: string;
  participation: string;
}

type TMinMaxPlayersByRoles = TMinMaxPlayersGameTypeByRoles | undefined;

const { $simulations } = simulationsModel;

const View: FC<Props> = ({ simulations }): ReactElement => {
  const { t, i18n } = useTranslation();

  const { validateValue } = useInput();

  const navigate = useNavigate();

  const popupRef = useRef<HTMLDivElement | null>(null);

  const { status: createAssessmentStatus } = useUnit($createAssessment);

  const [selectedSimulationId, setSelectedSimulationId] = useState<string>();

  const [selectedSimulation, setSelectedSimulation] =
    useState<SimulationAdminInterface>();

  const simulationSelectItems: SelectItem[] = useMemo(
    () =>
      simulations.length
        ? simulations
            .filter((simulation) => simulation.rawMetaJson.gameTypes.solo)
            .map((simulation) => ({
              value: simulation.id,
              label: languagePicker(simulation.title, i18n.language),
              image: languagePicker(
                simulation.rawMetaJson.info.logoUrl,
                i18n.language,
              ),
            }))
        : [],
    [simulations, i18n.language],
  );

  const [gameTypesSelectItems, setGameTypesSelectItems] = useState<
    SelectItem[]
  >([]);

  const [activeTemplate, setActiveTemplate] =
    useState<RatedParamsTemplatesInterface>();

  const initialFormDataState: FormDataInterface = {
    assessmentTitle: "",
    assessmentType: AssessmentType.Rating,
    playerValidation: LobbyLaunchConditions.minTeams,
    assessmentAccess: AssessmentAccess.Public,
    gameType: "" as GameType,
    maxAreas: String(activeTemplate?.maxAreas),
    lobbyTimerState: LobbyTimerState.withTimer,
    presenceTimer: "900",
    confirmTimer: "300",
    participation: Participation.optional,
  };

  const [formData, setFormData] =
    useState<FormDataInterface>(initialFormDataState);

  const [formDataErrors, setFormDataErrors] = useState<Record<string, string>>(
    {},
  );

  const [minMaxCounts, setMinMaxCounts] = useState<Record<string, string>>();

  const [minMaxCountsErrors, setMinMaxCountsErrors] = useState<
    Record<string, string>
  >({});

  const [templateParams, setTemplateParams] = useState<TTemplateParams>();

  const [templateParamsErrors, setTemplateParamsErrors] = useState<
    Record<string, string>
  >({});

  const [isErrorScroll, setIsErrorScroll] = useState<boolean>(false);

  const [metaParams, setMetaParams] = useState<IMetaParams>({});

  const isCreateAssessmentLoading: boolean =
    createAssessmentStatus === ERequestStatus.LOADING;

  const selectedGameType: IGameType | undefined = useMemo(
    () =>
      selectedSimulation && formData.gameType
        ? selectedSimulation.rawMetaJson.gameTypes[formData.gameType]
        : undefined,
    [selectedSimulation, formData.gameType],
  );

  const isNotMembersGame: boolean =
    formData.gameType === GameType.Tutorial ||
    formData.gameType === GameType.Single;

  const isTeam: boolean =
    formData.gameType === GameType.Team || formData.gameType === GameType.Corp;

  const minMaxCountGameType: TMinMaxPlayersByRoles = useMemo(() => {
    if (selectedGameType && !isNotMembersGame) {
      const resultType: EMinMaxPlayersByRole = isTeam
        ? EMinMaxPlayersByRole.TEAM_ALL_TEAMS_BY_ROLE
        : EMinMaxPlayersByRole.SOLO_ALL_PLAYERS_BY_ROLE;

      return getMinMaxPlayersGameTypeByRole(selectedGameType, resultType);
    }
  }, [selectedGameType, isTeam, isNotMembersGame]);

  let templateSelectItems: SelectItem[] = [];

  if (selectedSimulation?.paramsTemplates) {
    selectedSimulation.paramsTemplates
      .filter((template) => template.gameType === formData.gameType)
      .forEach((template) => {
        if (template.id) {
          templateSelectItems.push({
            label:
              languagePicker(template.title, i18n.language) ??
              t("popup.createAssessment.field.template.untitled"),
            value: template.id,
          });
        }
      });
  }

  const defaultGameTypeTemplate = useMemo(
    () =>
      selectedSimulation?.paramsTemplates?.find(
        ({ minRate, gameType }) =>
          minRate === undefined && gameType === formData.gameType,
      ),
    [selectedSimulation, formData.gameType],
  );

  useMemo(() => {
    if (defaultGameTypeTemplate) {
      setActiveTemplate(defaultGameTypeTemplate);

      const defaultTemplateMaxTeamsByName = {
        shop: defaultGameTypeTemplate.maxTeamsByName?.["shop"].maxTeams,
        nursery: defaultGameTypeTemplate.maxTeamsByName?.["nursery"].maxTeams,
      };

      const minMaxCounts = convertValuesToStringsDeep(
        (isTeam
          ? defaultTemplateMaxTeamsByName
          : defaultGameTypeTemplate.maxPlayersByRole) as object,
      );

      setMinMaxCounts(minMaxCounts);
      setMinMaxCountsErrors({});

      formData.maxAreas = String(defaultGameTypeTemplate.maxAreas);
    } else {
      if (minMaxCountGameType) {
        const localMinMaxCounts: Record<string, string> = {};
        Object.keys(minMaxCountGameType).forEach((item) => {
          localMinMaxCounts[item] = "";
        });
        setMinMaxCounts(localMinMaxCounts);
        setMinMaxCountsErrors({});
      }
    }
  }, [formData.gameType]);

  useEffect(() => {
    if (!!simulations.length) {
      const selectedSimulationItem: SimulationAdminInterface | undefined =
        simulations.find(
          (simulation) => simulation.id === selectedSimulationId,
        );

      setSelectedSimulation(selectedSimulationItem);
    }
  }, [selectedSimulationId, simulations]);

  useEffect(() => {
    if (selectedSimulation) {
      const gameTypes = selectedSimulation.rawMetaJson.gameTypes;

      const gameTypesKeys = (Object.keys(gameTypes) as GameType[]).filter(
        (gameType) =>
          gameType !== "tutorial" &&
          gameType !== "single" &&
          gameType !== "tutorial_ltd",
      );

      setGameTypesSelectItems(() => {
        return gameTypesKeys.reduce(
          (preGameTypes: SelectItem[], gameTypeKey: GameType) => {
            const currGameType = gameTypes[gameTypeKey];

            if (currGameType.supported) {
              return [
                ...preGameTypes,
                {
                  label: gameTypeKey,
                  value: gameTypeKey,
                },
              ];
            }

            return [...preGameTypes];
          },
          [],
        );
      });
    }
  }, [selectedSimulation]);

  useEffect(() => {
    if (selectedSimulation) {
      const generalParams = selectedSimulation?.rawMetaJson.params;
      const gameTypeParams =
        selectedSimulation?.rawMetaJson.gameTypes[formData.gameType]?.params;
      const params = { ...generalParams, ...gameTypeParams };
      setMetaParams(params);
      setTemplateParams(
        getSimulationParamsFieldItem(params, true, activeTemplate?.params),
      );
    }
  }, [
    selectedSimulation,
    activeTemplate?.params,
    defaultGameTypeTemplate,
    formData.gameType,
  ]);

  useEffect(() => {
    const hasTemplateParamsErrors: boolean =
      !!Object.keys(templateParamsErrors).length;

    const hasFormDataErrors: boolean = !!Object.keys(formDataErrors).length;

    const hasMinMaxCountsErrors: boolean =
      !!Object.keys(minMaxCountsErrors).length;

    const isErrors: boolean =
      hasTemplateParamsErrors || hasFormDataErrors || hasMinMaxCountsErrors;

    if (isErrors && isErrorScroll) {
      setIsErrorScroll(false);

      const errorField = popupRef.current?.querySelector(".error-field");

      errorField?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [templateParamsErrors, formDataErrors, minMaxCountsErrors, isErrorScroll]);

  const onChangeSimulationIdHandler = (payload: IBaseInputChange): void => {
    setSelectedSimulationId(payload.value);
    onCleanFormData();
  };

  const onCleanFormData = (): void => {
    setFormData(initialFormDataState);
    setFormDataErrors({});
    setTemplateParamsErrors({});
    setMinMaxCounts(undefined);
    setMinMaxCountsErrors({});
  };

  const corpParams = selectedSimulation?.rawMetaJson.gameTypes.corp;

  const onCreateAssessment = (e: FormEvent): void => {
    e.preventDefault();

    const templateErrors =
      templateParams && selectedSimulation?.rawMetaJson
        ? validateValue(
            getSimulationParamsErrors(
              templateParams || {},
              selectedSimulation?.rawMetaJson,
            ),
          )
        : {};

    const formErrorsTemplate: Record<string, IValidateValue> = {
      assessmentTitle: {
        value: formData.assessmentTitle,
        type: EInputValidateType.STRING,
        required: true,
      },
      gameType: {
        value: formData.gameType,
        type: EInputValidateType.STRING,
        required: true,
      },
      presenceTimer: {
        value: formData.presenceTimer,
        type: EInputValidateType.NUMBER,
        required: true,
      },
      confirmTimer: {
        value: formData.confirmTimer,
        type: EInputValidateType.NUMBER,
        required: true,
      },
    };
    if (formData.gameType === GameType.Corp && formData.maxAreas) {
      formErrorsTemplate.maxAreas = {
        value: formData.maxAreas,
        type: EInputValidateType.NUMBER,
        required: true,
        min: corpParams?.minAreas,
        max: corpParams?.maxAreas,
      };
    }

    const minMaxCountsErrorsTemplate: Record<string, IValidateValue> = {};

    if (minMaxCountGameType && minMaxCounts) {
      Object.keys(minMaxCountGameType).forEach((item) => {
        minMaxCountsErrorsTemplate[item] = {
          value: minMaxCounts[item],
          type: EInputValidateType.NUMBER,
          required: true,
          min: minMaxCountGameType[item].min,
          max: minMaxCountGameType[item].max,
        };
      });
    }

    const formErrors = validateValue(formErrorsTemplate);
    const localMinMaxCountsErrors = validateValue(minMaxCountsErrorsTemplate);

    setIsErrorScroll(true);
    setFormDataErrors(formErrors);
    setTemplateParamsErrors(templateErrors);
    setMinMaxCountsErrors(localMinMaxCountsErrors);

    const hasTemplateParamsErrors: boolean =
      !!Object.keys(templateErrors).length;

    const hasFormDataErrors: boolean = !!Object.keys(formErrors).length;

    const hasMinMaxCountsErrors: boolean = !!Object.keys(
      localMinMaxCountsErrors,
    ).length;

    const hasErrors: boolean =
      hasTemplateParamsErrors || hasFormDataErrors || hasMinMaxCountsErrors;

    if (selectedSimulationId) {
      const options: CreateAssessmentReqDto = {
        gameType: formData.gameType as GameType,
        assessmentType: formData.assessmentType as AssessmentType,
        params: getSimulationParamsForRequest(templateParams || {}),
        title: formData.assessmentTitle,
        maxAreas:
          formData.gameType === GameType.Corp
            ? Number(formData.maxAreas)
            : undefined,
        validate_players:
          formData.playerValidation === LobbyLaunchConditions.fullTeams,
        is_timeout: formData.lobbyTimerState === LobbyTimerState.withTimer,
        lobby_presence_timer: Number(formData.presenceTimer),
        lobby_confirm_timer: Number(formData.confirmTimer),
        closed_session: formData.assessmentAccess === AssessmentAccess.Private,
        required_participation:
          formData.participation === Participation.required,
      };

      if (minMaxCounts) {
        if (isTeam) {
          const localMinMaxCounts: Record<string, MaxTeams> = {};

          Object.keys(minMaxCounts).forEach((item) => {
            localMinMaxCounts[item] = { maxTeams: Number(minMaxCounts[item]) };
          });

          options.maxTeamsByName = localMinMaxCounts;
        } else {
          const localMinMaxCounts: Record<string, number> = {};

          Object.keys(minMaxCounts).forEach((item) => {
            localMinMaxCounts[item] = Number(minMaxCounts[item]);
          });

          options.maxPlayersByRole = localMinMaxCounts;
        }
      }

      if (!hasErrors) {
        createAssessment({
          params: {
            gameId: selectedSimulationId,
            createAssessment: options,
          },
          navigate,
        });
      }
    }
  };

  const onChangeFormDataHandler = (payload: IBaseInputChange): void => {
    setFormData((prev) => {
      const localPrev = toCloneObject(prev);

      const fieldName = payload.name as keyof FormDataInterface;

      if (payload.name === "gameType" && payload.value !== "corp") {
        delete localPrev.maxAreas;
      }

      return { ...localPrev, [fieldName]: payload.value };
    });

    setFormDataErrors((prev) => {
      const localPrev = toCloneObject(prev);

      if (payload.name && localPrev[payload.name]) {
        delete localPrev[payload.name];
      }
      if (payload.name === "gameType" && payload.value === "corp") {
        delete localPrev.maxAreas;
      }

      return localPrev;
    });
  };

  const onChangeActiveTemplate = (payload: IBaseInputChange): void => {
    const selectedTemplate = selectedSimulation?.paramsTemplates?.find(
      (template: RatedParamsTemplatesInterface) =>
        template.id === payload.value,
    );
    if (selectedTemplate) {
      setActiveTemplate(selectedTemplate);

      const selectedTemplateMaxTeamsByName = {
        shop: selectedTemplate.maxTeamsByName?.["shop"].maxTeams,
        nursery: selectedTemplate.maxTeamsByName?.["nursery"].maxTeams,
      };

      const minMaxCounts = convertValuesToStringsDeep(
        (isTeam
          ? selectedTemplateMaxTeamsByName
          : selectedTemplate.maxPlayersByRole) as object,
      );

      setMinMaxCounts(minMaxCounts);

      formData.maxAreas = String(selectedTemplate.maxAreas);
    }
  };

  const onChangeMinMaxCountHandler = (payload: IBaseInputChange): void => {
    setMinMaxCounts((prev) => {
      const localPrev = { ...prev };

      if (payload.name && localPrev) {
        localPrev[payload.name] = payload.value;
      }

      return localPrev;
    });

    setMinMaxCountsErrors((prev) => {
      const localPrev = { ...prev };

      if (payload.name && localPrev[payload.name]) {
        delete localPrev[payload.name];
      }

      return localPrev;
    });
  };

  const onChangeTemplateParamsHandler = (
    value: string | boolean | string[],
    itemName: string,
  ): void => {
    setTemplateParams((prev) => {
      const localPrev = toCloneObject(prev);

      if (localPrev) {
        localPrev[itemName].value = value;
      }

      return localPrev;
    });

    setTemplateParamsErrors((prev) => {
      const localPrev = toCloneObject(prev);

      if (localPrev[itemName]) {
        delete localPrev[itemName];
      }

      return localPrev;
    });
  };

  const minMaxCountKeys: string[] | undefined = minMaxCountGameType
    ? Object.keys(minMaxCountGameType)
    : undefined;

  const areaPlaceholderCount: string = `(${corpParams?.minAreas}-${corpParams?.maxAreas})`;

  const areaPlaceholder: string = `${t(
    "popup.createAssessment.field.areasCount",
  )} ${areaPlaceholderCount}`;

  return (
    <BasePopup
      name={EPopupName.CREATE_UNRATED_SESSION}
      title={t("popup.createAssessment.title")}
      popupRef={popupRef}
    >
      <form onSubmit={onCreateAssessment} className="create-assessment-form">
        <FormControl
          title={t("popup.createAssessment.subTitle.selectSimulation")}
        >
          <BaseSelect
            placeholder={t("popup.createAssessment.field.simulation")}
            activeItem={selectedSimulationId}
            onChange={onChangeSimulationIdHandler}
            items={simulationSelectItems}
            hideEmptyItem
          />
        </FormControl>
        <FormControl
          title={t("popup.createAssessment.subTitle.assessmentSettings")}
          error={formDataErrors.assessmentTitle}
        >
          <BaseInputText
            error={!!formDataErrors.assessmentTitle}
            name="assessmentTitle"
            onChange={onChangeFormDataHandler}
            value={formData.assessmentTitle}
            placeholder={t("popup.createAssessment.field.assessmentTitle")}
            maxLength={30}
          />
        </FormControl>
        <FormControl
          title={t("popup.createAssessment.subTitle.type")}
          titleColor="gray"
          titleSize="small"
        >
          <BaseGroup
            left={
              <BaseInputRadio
                value={AssessmentType.Rating}
                name="assessmentType"
                activeValue={formData.assessmentType}
                label={t("common.assessment.rated")}
                onChange={onChangeFormDataHandler}
              />
            }
            right={
              <BaseInputRadio
                value={AssessmentType.NonRating}
                name="assessmentType"
                activeValue={formData.assessmentType}
                label={t("common.assessment.unrated")}
                onChange={onChangeFormDataHandler}
              />
            }
          />
        </FormControl>
        <FormControl
          title={t("popup.createAssessment.subTitle.lobbyLaunchConditions")}
          titleColor="gray"
          titleSize="small"
        >
          <BaseGroup
            left={
              <BaseInputRadio
                value={LobbyLaunchConditions.fullTeams}
                name="playerValidation"
                activeValue={formData.playerValidation}
                label={t("common.assessment.fullTeams")}
                onChange={onChangeFormDataHandler}
              />
            }
            right={
              <BaseInputRadio
                value={LobbyLaunchConditions.minTeams}
                name="playerValidation"
                activeValue={formData.playerValidation}
                label={t("common.assessment.minTeams")}
                onChange={onChangeFormDataHandler}
              />
            }
          />
        </FormControl>
        <FormControl
          title={t("popup.createAssessment.subTitle.lobbyTimerState")}
          titleColor="gray"
          titleSize="small"
        >
          <BaseGroup
            left={
              <BaseInputRadio
                value={LobbyTimerState.withTimer}
                name="lobbyTimerState"
                activeValue={formData.lobbyTimerState}
                label={t("common.assessment.withTimer")}
                onChange={onChangeFormDataHandler}
              />
            }
            right={
              <BaseInputRadio
                value={LobbyTimerState.withoutTimer}
                name="lobbyTimerState"
                activeValue={formData.lobbyTimerState}
                label={t("common.assessment.withoutTimer")}
                onChange={onChangeFormDataHandler}
              />
            }
          />
        </FormControl>
        <FormControl
          title={t("popup.createAssessment.subTitle.access")}
          titleColor="gray"
          titleSize="small"
        >
          <BaseGroup
            left={
              <BaseInputRadio
                value={AssessmentAccess.Public}
                name="assessmentAccess"
                activeValue={formData.assessmentAccess}
                label={t("common.assessment.public")}
                onChange={onChangeFormDataHandler}
              />
            }
            right={
              <BaseInputRadio
                value={AssessmentAccess.Private}
                name="assessmentAccess"
                activeValue={formData.assessmentAccess}
                label={t("common.assessment.private")}
                onChange={onChangeFormDataHandler}
              />
            }
          />
        </FormControl>
        {formData.assessmentAccess === AssessmentAccess.Private &&
          formData.assessmentType === AssessmentType.Rating && (
            <FormControl
              title={t("popup.createAssessment.subTitle.participation")}
              titleColor="gray"
              titleSize="small"
            >
              <BaseGroup
                left={
                  <BaseInputRadio
                    value={Participation.required}
                    name="participation"
                    activeValue={formData.participation}
                    label={t("common.assessment.required")}
                    onChange={onChangeFormDataHandler}
                  />
                }
                right={
                  <BaseInputRadio
                    value={Participation.optional}
                    name="participation"
                    activeValue={formData.participation}
                    label={t("common.assessment.optional")}
                    onChange={onChangeFormDataHandler}
                  />
                }
              />
            </FormControl>
          )}
        {formData.lobbyTimerState === LobbyTimerState.withTimer && (
          <Fragment>
            <FormControl error={formDataErrors.presenceTimer}>
              <BaseInputNumber
                placeholder={t("popup.createAssessment.field.presenceTimer")}
                value={String(formData.presenceTimer)}
                onChange={onChangeFormDataHandler}
                name="presenceTimer"
                error={!!formDataErrors.presenceTimer}
              />
            </FormControl>
            <FormControl error={formDataErrors.confirmTimer}>
              <BaseInputNumber
                placeholder={t("popup.createAssessment.field.confirmTimer")}
                value={String(formData.confirmTimer)}
                onChange={onChangeFormDataHandler}
                name="confirmTimer"
                error={!!formDataErrors.confirmTimer}
              />
            </FormControl>
          </Fragment>
        )}
        {selectedSimulation && (
          <Fragment>
            <FormControl error={formDataErrors.gameType}>
              <BaseSelect
                error={!!formDataErrors.gameType}
                placeholder={t("popup.createAssessment.field.gameType")}
                activeItem={formData.gameType}
                onChange={onChangeFormDataHandler}
                items={gameTypesSelectItems}
                name="gameType"
              />
            </FormControl>
            {activeTemplate &&
              formData.gameType &&
              !!templateSelectItems.length && (
                <FormControl>
                  <BaseSelect
                    placeholder={t("popup.createAssessment.field.template")}
                    onChange={onChangeActiveTemplate}
                    activeItem={activeTemplate.id}
                    items={templateSelectItems}
                    hideEmptyItem
                    name="template"
                  />
                </FormControl>
              )}
            {!!minMaxCountGameType &&
              !!minMaxCounts &&
              !!minMaxCountsErrors &&
              minMaxCountKeys?.map((item) => (
                <MinMaxCountField
                  key={item}
                  role={item}
                  selectedGameType={selectedGameType}
                  minMaxCountGameType={minMaxCountGameType}
                  isTeam={isTeam}
                  minMaxCounts={minMaxCounts}
                  minMaxCountsErrors={minMaxCountsErrors}
                  onChange={onChangeMinMaxCountHandler}
                />
              ))}
            {formData.gameType === GameType.Corp && (
              <FormControl error={formDataErrors.maxAreas}>
                <BaseInputNumber
                  placeholder={areaPlaceholder}
                  value={String(formData.maxAreas)}
                  onChange={onChangeFormDataHandler}
                  name={"maxAreas"}
                  error={!!formDataErrors.maxAreas}
                  min={corpParams?.minAreas}
                  max={corpParams?.maxAreas}
                />
              </FormControl>
            )}
            {templateParams && (
              <SimulationParamsFields
                metaParams={metaParams}
                params={templateParams}
                templateItemKey="create-assessment"
                onChange={onChangeTemplateParamsHandler}
                errors={templateParamsErrors}
              />
            )}
          </Fragment>
        )}
        <BaseButton
          disabled={isCreateAssessmentLoading || !selectedSimulation}
          onClick={onCreateAssessment}
          submit
          large
          primary
          block
        >
          {t("popup.createAssessment.btn.createAssessment")}
        </BaseButton>
      </form>
    </BasePopup>
  );
};

export const CreateAssessmentPopup = reflect<Props>({
  view: View,
  bind: {
    simulations: $simulations,
  },
});
