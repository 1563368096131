import { GetListParams } from "../../../../shared/api/admin/countries";
import { QueryParams } from "../../../../shared/api/base";

export const queryParamsToGetListParamsAdapter = (
  queryParams: QueryParams = {},
): GetListParams => {
  return {
    countryCode: queryParams.code ? [queryParams.code] : undefined,
    title: queryParams.title ? [queryParams.title] : undefined,
    orderBy: queryParams.orderBy ? queryParams.orderBy.split(",") : undefined,
    phoneMask: queryParams.phoneMask ? [queryParams.phoneMask] : undefined,
    regEnabled: queryParams.regEnabled
      ? queryParams.regEnabled.split(",").map((val) => val === "true")
      : undefined,
    pageNum: queryParams.pageNum ? Number(queryParams.pageNum) : 1,
    pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 50,
  };
};
