import { createDomain, sample } from "effector";

import { ResponseEmail } from "src/generated/notification";

import { getEmailsListNotification } from "src/shared/api/admin/notification";

import { Pagination, QueryParams } from "src/shared/api/base";

import { queryParamsToGetEmailListAdapter } from "../lib";

// Domain

export const emailNotificationDomain = createDomain("email-notification");

// Events

export const getEmailsList = emailNotificationDomain.createEvent<QueryParams>();

// Effects

export const getEmailsListFx = emailNotificationDomain.createEffect(
  getEmailsListNotification,
);

// Stores

export const $emailsList = emailNotificationDomain.createStore<ResponseEmail[]>(
  [],
);

export const $pagination =
  emailNotificationDomain.createStore<Pagination | null>(null);

export const $queryParams = emailNotificationDomain.createStore<QueryParams>(
  {},
);

export const $isLoading = emailNotificationDomain.createStore<boolean>(false);

// Logic

sample({
  clock: getEmailsList,
  fn: queryParamsToGetEmailListAdapter,
  target: getEmailsListFx,
});

sample({
  clock: getEmailsList,
  target: $queryParams,
});

sample({
  clock: getEmailsListFx.doneData,
  fn: ({ listEmail }) => listEmail,
  target: $emailsList,
});

sample({
  clock: getEmailsListFx.doneData,
  fn: ({ pagination }) => pagination,
  target: $pagination,
});

sample({ clock: getEmailsListFx.pending, target: $isLoading });
