import { ChangeEvent, useMemo, useState } from "react";

import { useUrlState } from "src/shared/hooks/useUrlState";

import { useTranslation } from "react-i18next";

import { PreviewPopup } from "src/shared/components/popups/preview-popup/preview-popup";

import { PageWrapper } from "src/shared/components";

import { IBaseInputChange, IBaseMultiSelectChange } from "src/shared/models";

import { ERadioType } from "src/shared/components/recipients-form/recipients-form";

import { SmsNewsletterForm } from "./sms-newsletter-form/sms-newsletter-form";

import { NotificationsNewsletterForm } from "./notification-newsletter-form/notification-newsletter-form";

import { EmailNewsletterForm } from "./email-newsletter-form/email-newsletter-form";

import "./newsletter-form.scss";
import { NewsLetterControlPanel } from "./components/newsletter-control-panel";
import { useCreateNotification } from "./lib/useCreateNotification";
import {
  refreshSelectedIds,
  updateSelectedIds,
} from "../../other/select-players-page/model";
import { SendToAllConfirmationPopup } from "../../../shared/components/popups/confirm-send-to-all-popup/confirm-send-to-all-popup";

export interface INewsletterFormData {
  title: string;
  body: string;
  link: string;
  smsText: string;
  radioValue: string;
  emailTheme: string;
  emailContent: string;
}

export enum NotificationType {
  NOTIFICATION = "notification",
  E_MAIL = "e-mail",
  // SMS = "sms",
}

const defaultFormData = {
  title: "",
  body: "",
  link: "",
  smsText: "",
  radioValue: ERadioType.SEND_TO_ALL,
  emailTheme: "",
  emailContent: "",
};

const NewsletterForm = () => {
  const { t } = useTranslation();

  const [urlState, setUrlState] = useUrlState();

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const [formData, setFormData] =
    useState<INewsletterFormData>(defaultFormData);

  const hasFormErrors = useMemo(
    () => !!Object.keys(formErrors).length,
    [formErrors],
  );

  const onChangeTextAreaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler({
      value: e.currentTarget.value,
      name: e.currentTarget.name,
    });
  };

  const onChangeTypeHandler = ({
    name,
    value,
  }: IBaseInputChange | IBaseMultiSelectChange): void => {
    if (typeof value === "string") {
      onChangeHandler({ value, name });
      setUrlState({
        ...urlState,
        query: { ...urlState.query, type: value },
      });
      updateSelectedIds([]);
      refreshSelectedIds();
    }
  };

  const onChangeHandler = (payload: IBaseInputChange) => {
    setFormData({
      ...formData,
      [payload.name!]: payload.value,
    });

    setFormErrors(() => {
      const prevErrorsState = { ...formErrors };

      if (payload.name && payload.name in prevErrorsState) {
        delete prevErrorsState[payload.name];
      }

      return prevErrorsState;
    });
  };

  const onClickCreateNotification = useCreateNotification(setFormErrors);

  return (
    <PageWrapper
      title={t("notifications.newsLetter.form.title")}
      backButton
      emptyPanel
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelSlot={
        <NewsLetterControlPanel
          onChangeTypeHandler={onChangeTypeHandler}
          onClickCreateNotification={onClickCreateNotification}
          hasFormErrors={hasFormErrors}
        />
      }
    >
      {urlState.query.type === NotificationType.NOTIFICATION && (
        <NotificationsNewsletterForm
          formDataErrors={formErrors}
          uploadAvatarBlockDescription={[
            t("notifications.newsLetter.form.uploadImage.description"),
          ]}
          onChangeTextAreaHandler={onChangeTextAreaHandler}
          onChangeHandler={onChangeHandler}
        />
      )}

      {urlState.query.type === NotificationType.E_MAIL && (
        <EmailNewsletterForm />
      )}

      {/*{urlState.query.type === NotificationType.SMS && <SmsNewsletterForm />}*/}
      <PreviewPopup />
      <SendToAllConfirmationPopup />
    </PageWrapper>
  );
};
export default NewsletterForm;
