import { socialService } from "../../../../base/api-instances";
import {
  PlayersApiGetChangesByAdminPlayerRequest,
  PlayersApiPostChangesByAdminPlayerRequest,
} from "src/generated/social";

export const getUsersHistory = (
  params: PlayersApiGetChangesByAdminPlayerRequest,
) => {
  return socialService
    .makePlayerRequest()
    .getChangesByAdminPlayer(params)
    .then((res) => res.data);
};

export const postUsersHistory = (
  params: PlayersApiPostChangesByAdminPlayerRequest,
) => {
  return socialService
    .makePlayerRequest()
    .postChangesByAdminPlayer(params)
    .then((res) => res.data);
};
