import { FC } from "react";

import {
  AssessmentInvite,
  AssessmentRevoke,
  AssessmentStatus,
  AssessmentType,
  ChangeStatusCompany,
  FineNotification,
  MsgType,
  MsgType as TypesMessages,
  StatusCompany,
  Txt,
  WsMsg as NotificationMessage,
} from "src/generated/notification";

import { AssessmentCard } from "./assessment-card";

import { NewsCard } from "./news-card";

import { useRoomConnection } from "src/app/providers/room-context/room-context";

import downRating from "src/shared/images/icons/down-rating.svg";
import collegeLogo from "src/shared/images/icons/college-logo-blue.svg";
import statusAgreed from "src/shared/images/icons/status-agreed.svg";
import statusNotAgreed from "src/shared/images/icons/status-not-agreed.svg";
import complaintLogo from "src/shared/images/icons/complaints.svg";

import { Trans, useTranslation } from "react-i18next";
import { FineWarningText } from "src/pages/profile/my-competence-page/components/fine-warning-text/fine-warning-text";
import { Link } from "react-router-dom";

type Props = {
  message?: NotificationMessage;
  closePanel: () => void;
};

export const NotificationCardOnPanel: FC<Props> = ({ message, closePanel }) => {
  const { inviteLink } = useRoomConnection();

  const { t } = useTranslation();

  if (message?.type === TypesMessages.Txt) {
    const item: Txt = message.msg as Txt;

    return (
      <NewsCard
        logoUrl={item.logoUrl}
        whenDate={message.when}
        title={item.title}
        description={item.body}
        link={item.link}
      />
    );
  }

  if (message?.type === TypesMessages.FineNotification) {
    const item: FineNotification = message.msg as FineNotification;

    return (
      <NewsCard
        logoUrl={downRating}
        whenDate={message.when}
        title={t("fine.fineForInaction")}
        description={
          <FineWarningText
            days={item.period}
            rating={item.removable_rating as Record<string, number>}
            competencies={item.competencies}
            notification={true}
          />
        }
      />
    );
  }

  if (message?.type === TypesMessages.Complaint) {
    return (
      <NewsCard
        logoUrl={complaintLogo}
        whenDate={message?.when || ""}
        title={t("common.complaintTitle")}
        description={t("relative.message.desc.complaintDescription")}
      />
    );
  }

  if (message?.type === TypesMessages.StatusCompany) {
    const item: ChangeStatusCompany = message.msg as ChangeStatusCompany;

    if (item.status === StatusCompany.Student) {
      return (
        <NewsCard
          logoUrl={collegeLogo}
          whenDate={message.when}
          title={t("common.accessToNewFunctionality")}
          description={t("relative.message.desc.assessForStudent")}
        />
      );
    }
    if (item.status === StatusCompany.RelativesForRelative) {
      return (
        <NewsCard
          logoUrl={collegeLogo}
          whenDate={message.when}
          title={t("relative.message.desc.relativeAccess")}
          description={t("relative.message.desc.accessForRelative")}
        />
      );
    }

    if (item.status === StatusCompany.Employee) {
      return (
        <NewsCard
          logoUrl={collegeLogo}
          whenDate={message.when}
          title={t("common.accessToNewFunctionality")}
          description={t("relative.message.desc.assessForEmployee")}
        />
      );
    }
    if (item.status === StatusCompany.NoCandidateEmployee) {
      return (
        <NewsCard
          logoUrl={statusNotAgreed}
          whenDate={message.when}
          title={t("common.sorry")}
          description={
            <span>
              {t("relative.message.desc.employeeReject")}
              <Link
                onClick={closePanel}
                className="news-card__description__link"
                to="faq"
              >
                {t("common.faq.link")}
              </Link>
            </span>
          }
        />
      );
    }
    if (item.status === StatusCompany.NoRelativesForRelative) {
      return (
        <NewsCard
          logoUrl={statusNotAgreed}
          whenDate={message.when}
          title={t("common.sorry")}
          description={
            <span>
              <Trans
                i18nKey="relative.message.desc.noAccessRelative"
                values={{ firstName: item.firstName, lastName: item.lastName }}
              />
              <Link
                onClick={closePanel}
                className="news-card__description__link"
                to="faq"
              >
                {t("common.faq.link")}
              </Link>
            </span>
          }
        />
      );
    }
    if (item.status === StatusCompany.NoCandidateStudent) {
      return (
        <NewsCard
          logoUrl={statusNotAgreed}
          whenDate={message.when}
          title={t("common.sorry")}
          description={
            <span>
              {t("relative.message.noCandidateStudent")}
              <Link
                onClick={closePanel}
                className="news-card__description__link"
                to="faq"
              >
                {t("common.faq.link")}
              </Link>
            </span>
          }
        />
      );
    }
    if (item.status === StatusCompany.NoConfirmationReferenceRelative) {
      return (
        <NewsCard
          logoUrl={statusNotAgreed}
          whenDate={message.when}
          title={t("common.sorry")}
          description={t("relative.message.noConfirmationReferenceRelative")}
        />
      );
    }
    if (item.status === StatusCompany.NoRelativesForStudent) {
      return (
        <NewsCard
          logoUrl={statusNotAgreed}
          whenDate={message.when}
          title={t("relative.application.rejected")}
          description={t("relative.message.desc.relativeRelativeRejected")}
        />
      );
    }
    if (item.status === StatusCompany.RelativesForStudent) {
      return (
        <NewsCard
          logoUrl={statusAgreed}
          whenDate={message.when}
          title={t("relative.application.approved")}
          description={t("relative.message.desc.relativeApproved")}
        />
      );
    }
    if (item.status === StatusCompany.CancellationRelative) {
      return (
        <NewsCard
          logoUrl={statusNotAgreed}
          whenDate={message.when}
          title={t("relative.unbinding")}
          description={
            <Trans
              i18nKey="relative.message.desc.userCanceled"
              values={{ firstName: item.firstName, lastName: item.lastName }}
            />
          }
        />
      );
    }
    if (item.status === StatusCompany.CancellationStudent) {
      return (
        <NewsCard
          logoUrl={statusNotAgreed}
          whenDate={message.when}
          title={t("relative.unbinding")}
          description={
            <Trans
              i18nKey="relative.message.desc.userCanceled"
              values={{ firstName: item.firstName, lastName: item.lastName }}
            />
          }
        />
      );
    }
  }

  if (message?.type === MsgType.AssessmentRevoke) {
    const item: AssessmentRevoke = message.msg as AssessmentRevoke;
    return (
      <AssessmentCard
        cardTitle={t("notification.title.assessment.revoke")}
        logoUrl={item.logoUrl}
        whenDate={message.when}
        simulationName={item.gTitle}
        name={item.aTitle}
        isRated={item.aType === AssessmentType.Rating}
        isOpened={false}
      />
    );
  }

  if (message?.type === TypesMessages.AssessmentInvite) {
    const item: AssessmentInvite = message.msg as AssessmentInvite;

    const isLobbyStatus = item.aSt === AssessmentStatus.Lobby;

    const lobbyUrl: string = `${process.env.REACT_APP_DOMAIN}/sessions/session/${item.aid}`;

    return (
      <AssessmentCard
        cardTitle={t("notification.title.assessment.invite")}
        logoUrl={item.logoUrl}
        whenDate={message.when}
        simulationName={item.gTitle}
        name={item.aTitle}
        isRated={item.aType === AssessmentType.Rating}
        isOpened={!item.isClose}
        isRequired={item.required_participation}
        connectLink={isLobbyStatus ? lobbyUrl : inviteLink}
        badgeType={isLobbyStatus ? "wait" : "launched"}
      />
    );
  }

  return null;
};
