import { TextWithIcon } from "../../text-with-icon/text-with-icon";
import { memo, ReactElement, ReactNode } from "react";

export const DataGridRating = memo(
  ({ children }: { children?: ReactNode }): ReactElement => {
    return (
      <span className="data-grid__rating">
        <TextWithIcon iconName="star-blue" iconSize={16} hideLabel />
        <span className="data-grid__rating-label">{children}</span>
      </span>
    );
  },
);
