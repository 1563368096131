import classNames from "classnames";
import { ChangeEvent, FC, ReactElement, useRef } from "react";
import { IBaseInputChange } from "../../models/base-input";
import { CrossIcon, MagnifierIcon } from "../icons-components";
import "./search-input.scss";

interface Props {
  value: string;
  placeholder?: string;
  onChange: (payload: IBaseInputChange) => void;
  onClickMagnifier?: () => void;
  onClickCross?: () => void;
  className?: string;
}

export const SearchInput: FC<Props> = ({
  value,
  placeholder,
  onChange,
  onClickMagnifier,
  onClickCross,
  className,
}): ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChangeHandler = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    onChange({ value: target.value });
  };

  const onClickMagnifierHandler = () => {
    inputRef?.current?.focus();

    if (onClickMagnifier) {
      onClickMagnifier();
    }
  };

  const onClickCrossHandler = () => {
    onChange({ value: "" });

    if (onClickCross) {
      onClickCross();
    }
  };

  return (
    <div className={`search-input ${className}`}>
      <input
        ref={inputRef}
        className="search-input__field"
        type="text"
        value={value}
        onChange={onChangeHandler}
        placeholder={placeholder}
      />
      <button
        className="search-input__magnifier"
        type="button"
        onClick={onClickMagnifierHandler}
      >
        <MagnifierIcon />
      </button>
      <button
        className={classNames("search-input__cross", {
          "search-input__cross--visible": !!value,
        })}
        onClick={onClickCrossHandler}
      >
        <CrossIcon />
      </button>
    </div>
  );
};
