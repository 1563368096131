import { FC, ReactElement } from "react";

import { Trans, useTranslation } from "react-i18next";

import { CalcType } from "src/generated/game";

import { BaseCodeBlock, BasePopup, EPopupName } from "src/shared/components";

import { getArray } from "src/shared/helpers";

import "./formula-info-popup.scss";

interface IDescriptionItem {
  num: number;
  listCount?: number;
  formula?: string;
}

interface IFormulasInfo {
  descriptions: IDescriptionItem[];
  examples: Array<number>;
}

const descriptionsItems: Record<CalcType, IFormulasInfo> = {
  [CalcType.General]: {
    descriptions: [
      { num: 0 },
      {
        num: 1,
        formula: `@dataclass(frozen=True)
    class ScriptPlayer:
        analytics: Decimal
        command: Decimal
        communication: Decimal
        rating: Decimal`,
      },
      { num: 2, listCount: 4 },
      { num: 3 },
      { num: 4 },
    ],
    examples: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  [CalcType.Dislike]: {
    descriptions: [
      {
        num: 0,
        formula: `@dataclass(frozen=False)
        class ScriptReward:
            analytics: Decimal = 0
            analyticsTenacity: Decimal = 0
            command: Decimal = 0
            commandTenacity: Decimal = 0
            communication: Decimal = 0
            communicationTenacity: Decimal = 0,
            thinking3d: Decimal = 0,
            thinking3dTenacity: Decimal = 0,
            economics: Decimal = 0,
            economicsTenacity: Decimal = 0,
            communicationTenacity: Decimal = 0,
            jurisprudence: Decimal = 0,
            jurisprudenceTenacity: Decimal = 0,`,
      },
      { num: 1 },
    ],
    examples: [0],
  },
};

const formulaExamples: Record<CalcType, Array<string>> = {
  [CalcType.General]: [
    `True`,
    `1.0 <= rate <= 1.5`,
    `if 0.5 <= rate < 0.75:
  result = True
  role = 'nursery'
elif 0.75 <= rate < 1.0:
  result = True
  role = 'shop'
else:
  result = False`,
    `0.5`,
    `0.25 if place in [1, 2, 3] and not bankrupt else 0.0`,
    `if bankrupt or place == 24:
    result = -0.32
elif place == 1:
    result = 0.35
elif place == 2:
    result = 0.2
elif place == 3:
    result = 0.1
elif place == 22:
    result = -0.09
elif place == 23:
    result = -0.18
else:
    result = 0.0`,
    `{
      "nursery": {
          1: 0.25 if not bankrupt else 0.0,
          players_count: -0.32 if not bankrupt else -0.5,
      },
      "shop": {
          1: 0.32 if not bankrupt else -0.5,
          players_count - 1: -0.3 if not bankrupt else -0.5,
          players_count: -0.4 if not bankrupt else -0.5,
      },
  }`,
    `if 1.0 <= p.rating < 2.0:
    teams, roles = 'shop', ['captain', 'doctor']
  
elif 2.0 <= p.rating < 3.0:
    roles = 'supply'
  
elif p.communication == max(p.communication, p.analytics, p.command):
    teams, roles = ['shop', 'nursery'], 'sales'`,
  ],
  [CalcType.Dislike]: [
    `if who == 'captain':
  if whom in ['doctor', 'sales', 'supply', 'marketeer']:
    rwd.analytics = -1.0
    rwd.command = -1.0
    rwd.communication = -1.0
else:
  if whom == 'captain':
    rwd.analytics = -0.1
    rwd.command = -0.1
    rwd.communication = -0.1
  else:
    rwd.analytics = -0.5
    rwd.command = -0.5
    rwd.communication = -0.5`,
  ],
};

interface Props {
  calcType: CalcType;
  onClose: () => void;
}

export const FormulaInfoPopup: FC<Props> = ({
  calcType,
  onClose,
}): ReactElement => {
  const { t } = useTranslation();

  const { examples, descriptions } = descriptionsItems[calcType];

  return (
    <BasePopup
      name={EPopupName.FORMULA_INFO_EXAMPLES}
      title={t(
        `simulationControl.simulationPage.modesBlock.subTitle.${calcType}`,
      )}
      onClose={onClose}
    >
      <div className="formula-info-popup">
        <div className="formula-info-popup-descriptions">
          {descriptions.map((item) => (
            <div
              className="formula-info-popup__description"
              key={calcType + item.num}
            >
              <div className="formula-info-popup__description-item">
                <Trans
                  i18nKey={`simulationControl.simulationPage.modesBlock.formulaInfoPopup.${calcType}.description.${item.num}`}
                  components={{
                    strong: (
                      <strong className="formula-info-popup__description-item-strong" />
                    ),
                  }}
                />
              </div>
              {!!item.listCount && (
                <ul className="formula-info-popup__description-list">
                  {getArray(item.listCount).map((_, index) => (
                    <li
                      className="formula-info-popup__description-list-item"
                      key={calcType + item.num + index}
                    >
                      {t(
                        `simulationControl.simulationPage.modesBlock.formulaInfoPopup.${calcType}.description.${item.num}.list.${index}`,
                      )}
                    </li>
                  ))}
                </ul>
              )}

              {item.formula && (
                <BaseCodeBlock
                  className="formula-info-popup__description-formula"
                  code={item.formula}
                />
              )}
            </div>
          ))}
        </div>
        <div className="formula-info-popup-examples">
          <h3 className="formula-info-popup-examples__title">
            {t(
              "simulationControl.simulationPage.modesBlock.formulaInfoPopup.examples",
            )}
          </h3>
          {examples.map((item) => (
            <div
              className="formula-info-popup-examples__item"
              key={"example" + item + calcType}
            >
              <h4 className="formula-info-popup-examples__item-title">
                <Trans
                  i18nKey={`simulationControl.simulationPage.modesBlock.formulaInfoPopup.${calcType}.examples.title.${item}`}
                />
              </h4>
              <BaseCodeBlock code={formulaExamples[calcType][item]} />
            </div>
          ))}
        </div>
      </div>
    </BasePopup>
  );
};
