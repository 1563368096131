import classNames from "classnames";
import dompurify from "dompurify";
import { FC, ReactElement } from "react";

interface Props {
  className?: string;
  description: string;
}

export const BaseInnerHtml: FC<Props> = ({
  className,
  description,
  ...rest
}): ReactElement => {
  const sanitizer = dompurify.sanitize;

  return (
    <div
      {...rest}
      className={classNames("ql-editor", className)}
      dangerouslySetInnerHTML={{
        __html: sanitizer(description, {
          ADD_TAGS: ["iframe"],
          ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
        }),
      }}
    />
  );
};
