import { createEffect, createEvent, createStore } from "effector";
import {
  CommentApiFactory,
  CommentResponseDto,
  Configuration,
} from "../../../../generated/social";
import { AxiosError, AxiosPromise } from "axios";
import { apiInstance } from "../../../../shared/api/base/api-instances";

interface IAddComment {
  comment: string;
  userId: string;
}

interface IDeleteComment {
  commentId: number;
  userId: string;
}

interface IEditComment {
  comment: string;
  userId: string;
  commentId: number;
}

const commentApi = CommentApiFactory(new Configuration(), "", apiInstance);

// Stores

export const $comments = createStore<CommentResponseDto[]>([]);

export const $commentsServerTime = createStore<Date>(new Date());

export const $userId = createStore<string>("");

export const $error = createStore<string | null>(null);

// Events

export const setComments = createEvent<CommentResponseDto[]>();

export const setServerTime = createEvent<Date>();

export const setUserId = createEvent<string>();

// Effects

export const addCommentFx = createEffect<(params: IAddComment) => AxiosPromise>(
  async ({ comment, userId }) => {
    return commentApi.writeUserComment(userId, { message: comment });
  },
);

export const removeCommentFx = createEffect<
  (params: IDeleteComment) => AxiosPromise
>(async ({ userId, commentId }) => {
  return commentApi.deleteUserComment(userId, commentId);
});

export const editCommentFx = createEffect<
  (params: IEditComment) => AxiosPromise
>(async ({ userId, comment, commentId }) => {
  return commentApi.updateUserComment(userId, commentId, { message: comment });
});

// Logic

$userId.on(setUserId, (state, payload) => {
  return payload;
});

$comments.on(setComments, (state, payload) => {
  return payload;
});

$commentsServerTime.on(setServerTime, (state, payload) => {
  return payload;
});

$commentsServerTime.on(
  [addCommentFx.doneData, editCommentFx.doneData, removeCommentFx.doneData],
  (state, payload) => {
    return new Date(payload.headers.date);
  },
);

$comments.on(
  [addCommentFx.doneData, editCommentFx.doneData, removeCommentFx.doneData],
  (state, payload) => {
    return payload.data.comments;
  },
);

$error.on(
  [addCommentFx.fail, editCommentFx.fail, removeCommentFx.fail],
  (state, payload) => {
    const response = (payload.error as AxiosError).response;
    if (response) {
      switch (response.status) {
        case 403:
          return "noPermission";
        case 404:
          return "notFound";
        case 500:
          return "serverError";
        default:
          return "unknown";
      }
    } else {
      return "networkError";
    }
  },
);

$error.on(
  [addCommentFx.pending, editCommentFx.pending, removeCommentFx.pending],
  (state, isPending) => {
    if (isPending) {
      return null;
    }
  },
);
