import { ChangeEvent, FC, ReactElement, useEffect, useState } from "react";

import { IBaseInput } from "../../../models/base-input";
import { ERegEx, regExList } from "../../../constants/regExList";

import { CountryCode } from "libphonenumber-js/types";

import { formatPhone } from "../../../helpers/phone";
import { InputTemplate } from "../input-template/input-template";

interface Props extends IBaseInput {
  regionCode?: CountryCode;
  customRegEx?: RegExp;
}

export const BaseInputPhone: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  onChange,
  regionCode = "RU",
  customRegEx,
  required,
  isSmall,
  forDarkBg,
  isContextBtnDisabled,
  readOnly,
  onRefresh,
  onDelete,
}): ReactElement => {
  const onChangeHandler = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const target = e.target as HTMLInputElement;

    const regEx = customRegEx || regExList[ERegEx.NOT_PLUS_AND_NUMBERS];

    const formattedPhone = await formatPhone(
      target.value,
      undefined,
      regionCode
    );

    const localValue: string =
      formattedPhone !== undefined ? formattedPhone : target.value;

    const formattedLocalValue = localValue.replace(regEx, "");

    onChange({
      value: formattedLocalValue,
      name: target.name,
    });
  };

  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    formatPhone(value).then((formattedPhone) => {
      setInputValue(formattedPhone || "");
    });
  }, [value]);

  return (
    <InputTemplate
      type="tel"
      value={inputValue}
      onChange={onChangeHandler}
      name={name}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      maxLength={20}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      onRefresh={onRefresh}
      onDelete={onDelete}
    />
  );
};
