import classNames from "classnames";
import {
  forwardRef,
  HtmlHTMLAttributes,
  memo,
  ReactElement,
  ReactNode,
} from "react";

import "./base-box.scss";

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  noPadding?: boolean;
  noShadow?: boolean;
  noRound?: boolean;
  children?: ReactNode;
}

export const BaseBox = memo(
  forwardRef<HTMLDivElement, Props>(function (
    { className, noPadding, noShadow, children, noRound, ...rest },
    ref,
  ): ReactElement {
    return (
      <div
        className={classNames("box", className, {
          "box--no-padding": noPadding,
          "box--no-shadow": noShadow,
          "box--no-round": noRound,
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  }),
);
