import { gameService } from "src/shared/api/base/api-instances";
import {
  DeleteCalculationParams,
  GetCalculationParams,
  GetGameCalculationsParams,
  PostTestFormulasParams,
  UpdateAllCalculationsParams,
} from "./query";

export const getCalculations = (params: GetGameCalculationsParams) => {
  return gameService
    .makeCalculationsApi()
    .getCalcs(params)
    .then((res) => res.data);
};

export const getCalculation = (params: GetCalculationParams) => {
  return gameService
    .makeCalculationsApi()
    .getCalc(params)
    .then((res) => res.data);
};

// export const addNewCalculations = (params: PostCalculationsParams) => {
//   return gameService
//     .makeCalculationsApi()
//     .postCalcs(params)
//     .then((res) => res.data);
// };

export const updateAllCalculations = (params: UpdateAllCalculationsParams) => {
  return gameService
    .makeCalculationsApi()
    .postUpdCalcs(params)
    .then((res) => res.data);
};

export const postTestCalculationFormulas = (params: PostTestFormulasParams) => {
  return gameService
    .makeCalculationsApi()
    .postTestCalc(params)
    .then((res) => res.data);
};
export const deleteCalculation = (params: DeleteCalculationParams) => {
  return gameService
    .makeCalculationsApi()
    .deleteCalc(params)
    .then((res) => res.data);
};

// export const updateCalculation = (params: PutCalculationParams) => {
//   return gameService
//     .makeCalculationsApi()
//     .putCalc(params)
//     .then((res) => res.data);
// };
