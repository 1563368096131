import React, { FC, Fragment } from "react";
import styles from "./session-simulation-title.module.scss";
import { GameType } from "../../../generated/game";
import { useTranslation } from "react-i18next";

interface Props {
  titleType: GameType | "Distribute";
  isConnectionFlag?: boolean;
}

const SessionSimulationTitle: FC<Props> = ({ titleType, isConnectionFlag }) => {
  const { t } = useTranslation();
  const connectionFlag = t(
    "simulationControl.simulationPage.simulationWaiting.title",
  );

  const simulationTitles: Partial<
    Record<GameType | "Distribute", { simulationTitle: string }>
  > = {
    [GameType.Team]: {
      simulationTitle: t(
        "simulationControl.simulationPage.simulationTeam.title",
      ),
    },
    [GameType.Corp]: {
      simulationTitle: t(
        "simulationControl.simulationPage.simulationCorp.title",
      ),
    },
    [GameType.Solo]: {
      simulationTitle: t(
        "simulationControl.simulationPage.simulationSolo.title",
      ),
    },
    Distribute: {
      simulationTitle: t(
        "simulationControl.simulationPage.simulationDistribute.title",
      ),
    },
  };

  const defaultTitle = simulationTitles[titleType]?.simulationTitle || "";

  const displayTitle = isConnectionFlag ? connectionFlag : defaultTitle;

  return (
    <Fragment>
      <div className={styles.container_title}>
        <span className={styles.container_title__text}>{displayTitle}</span>
      </div>
    </Fragment>
  );
};

export default SessionSimulationTitle;
