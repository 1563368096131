import { sample } from "effector";

import {
  resetPassword,
  ResetPasswordParams,
} from "src/shared/api/public/account";

import { accountDomain } from "./domain";

// Stores

export const $isLoading = accountDomain.createStore<boolean>(false);

// Events

export const fetchResetPassword =
  accountDomain.createEvent<ResetPasswordParams>();

// Effects

export const fetchResetPasswordFx = accountDomain.createEffect(resetPassword);

// Logic

sample({ clock: fetchResetPassword, target: fetchResetPasswordFx });

sample({ clock: fetchResetPasswordFx.pending, target: $isLoading });
