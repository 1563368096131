import { FC, ReactElement } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { BaseBox, BaseButton } from "src/shared/components";

import LvlUpCat from "src/shared/images/cat-lvl-up.svg";

import "./lvl-up-block.scss";

interface Props {
  className?: string;
}

export const LvlUpBlock: FC<Props> = ({ className }): ReactElement => {
  const { t } = useTranslation();

  return (
    <BaseBox className={classNames("lvl-up-block", className)}>
      <div className="lvl-up-block__slogan">
        <img className="lvl-up-block__img" src={LvlUpCat} />
        <div className="lvl-up-block__titles">
          <h3 className="lvl-up-block__title">{t("levelUp.slogan")}</h3>
          <span className="lvl-up-block__subtitle">
            {t("levelUp.subtitle.slogan")}
          </span>
        </div>
      </div>
      <BaseButton
        to="/level-up"
        primary
        noPaddingX
        small
        className="lvl-up-block__btn"
        contentClassName="lvl-up-block__btn-link"
      >
        {t("levelUp.link")}
      </BaseButton>
    </BaseBox>
  );
};
