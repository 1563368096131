import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "src/shared/components/index";

import useClient from "src/shared/hooks/useClient";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { UserChangeHistoryDto } from "../../../../generated/social";

interface Props {
  items: UserChangeHistoryDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const StudentEmployeeModerationHistoryTable: FC<Props> = ({
  loading,
  items,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isPlayerClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullNameAuthor",
        showed: !isPlayerClientId,
        titleUppercase: true,
        title: t("table.header.name"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.name"),
          activeGroupKey: getActiveGroup(
            ["firstNameAuthor", "lastNameAuthor"],
            { all: ["firstNameAuthor", "lastNameAuthor"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstNameAuthor",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstNameAuthor || "",
                },
                {
                  key: "lastNameAuthor",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastNameAuthor || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstNameAuthor",
                  "-firstNameAuthor",
                  "lastNameAuthor",
                  "-lastNameAuthor",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstNameAuthor",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstNameAuthor",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastNameAuthor",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastNameAuthor",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "changedField",
        showed: true,
        titleUppercase: true,
        title: t("table.header.action"),
        type: EDataGridColumnType.STRING,
        filterPosition: EDataGridFilterPosition.LEFT,
      },
      {
        key: "fullNamePlayer",
        showed: true,
        titleUppercase: true,
        title: t("table.header.user"),
        type: EDataGridColumnType.STRING,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.user"),
          activeGroupKey: getActiveGroup(
            ["firstNamePlayer", "lastNamePlayer"],
            { all: ["firstNamePlayer", "lastNamePlayer"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstNamePlayer",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstNamePlayer || "",
                },
                {
                  key: "lastNamePlayer",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastNamePlayer || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstNamePlayer",
                  "-firstNamePlayer",
                  "lastNamePlayer",
                  "-lastNamePlayer",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstNamePlayer",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstNamePlayer",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastNamePlayer",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastNamePlayer",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "dateOfChange",
        showed: true,
        titleUppercase: true,
        title: t("common.date"),
        type: EDataGridColumnType.DATETIME,
        controlPanel: {
          title: t("common.date"),
          activeGroupKey: getActiveGroup(
            ["dateOfChange", "dateOfChangeFrom", "dateOfChangeTo"],
            {
              all: ["dateOfChange", "dateOfChangeFrom", "dateOfChangeTo"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "dateOfChangeFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.dateOfChangeFrom || "",
                  isHalf: true,
                },
                {
                  key: "dateOfChangeTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.dateOfChangeTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "date_of_changes",
                  "-date_of_changes",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "date_of_changes",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-date_of_changes",
                  },
                ],
              },
            },
          ],
        },
      },
    ];

    return newColumns;
  }, [isPlayerClientId, queryParams]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const fullNameAuthor = splitStrings([
        item.firstNameAuthor,
        item.lastNameAuthor,
      ]);
      const fullNamePlayer = splitStrings([
        item.firstNamePlayer,
        item.lastNamePlayer,
      ]);
      return {
        id: item.id,
        selected: false,
        fullNameAuthor,
        avatarProps: {
          alt: fullNameAuthor,
          fullName: fullNameAuthor,
          customPath: getAvatarUrl(item.author_id),
          userId: item.author_id,
        },
        fullNamePlayer,
        changedField: `${t(
          `table.ratings.${item.change_field}.${item.old_value}`,
          t("table.filters.notSelected"),
        )} → ${t(
          `table.ratings.${item.change_field}.${item.new_value}`,
          t("table.filters.notSelected"),
        )}`,
        dateOfChange: item.date_of_changes,
      };
    });

    setRows(rowsItems);
  }, [items, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
