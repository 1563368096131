import classNames from "classnames";
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";

export type TBaseTextareaSize = "medium" | "default" | "small";

interface Props {
  required?: boolean;
  value: string;
  onChange: ChangeEventHandler;
  error?: boolean;
  label?: string;
  name?: string;
  canResized?: boolean;
  size?: TBaseTextareaSize;
  isMonospace?: boolean;
  className?: string;
  onKeyDown?: (e: KeyboardEvent) => void;
  maxLength?: number;
}

export const BaseTextarea: FC<Props> = ({
  className,
  required,
  value,
  error,
  name,
  label,
  onChange,
  canResized,
  size = "default",
  isMonospace,
  onKeyDown,
  maxLength,
}): ReactElement => {
  const [text, setText] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    if (value) return;
    setText("");
  }, [value]);

  return (
    <label className="field">
      <textarea
        className={classNames(
          "field__textarea",
          `field__textarea--size_${size}`,
          {
            "field__textarea--error error-field": error,
            "field__textarea--resize": canResized,
            "field__textarea--monospace": isMonospace,
            "field__textarea--collapsed": !!text,
          },
          className,
        )}
        maxLength={maxLength}
        name={name}
        value={text}
        onChange={handleChange}
        required={required}
        onKeyDown={onKeyDown}
      />
      <span
        className={classNames("field__textarea-label", {
          required,
        })}
      >
        {label}
      </span>
    </label>
  );
};
