import { socialService } from "../../../../base/api-instances";
import {
  ComplaintsApiGetComplaintsRequest,
  ComplaintsApiPatchComplaintByIdRequest,
} from "../../../../../../generated/social";

export const getComplaintsApplicationHistory = (
  params: ComplaintsApiGetComplaintsRequest,
) => {
  return socialService
    .makeComplaintsRequest()
    .getComplaints(params)
    .then((res) => res.data);
};

export const patchComplaintPlayerById = (
  params: ComplaintsApiPatchComplaintByIdRequest,
) => {
  return socialService
    .makeComplaintsRequest()
    .patchComplaintById(params)
    .then((res) => res.data);
};
