import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
} from "src/shared/models/dataGrid";

import { getActiveGroup } from "src/shared/helpers/data-grids/getActiveGroup";

import { getOrderByValue } from "src/shared/helpers/data-grids/getOrderByValue";

import { t } from "src/shared/helpers";

import { QueryParams } from "src/shared/api/base";

export const generateInviteColumns = (
  queryParams: QueryParams = {},
): IDataGridColumn[] => {
  return [
    {
      title: "SELECT",
      type: EDataGridColumnType.SELECT_ROW,
      key: "selected",
      showed: true,
      hiddenTitle: true,
      sticky: {
        mobile: {
          left: 0,
        },
        tablet: {
          left: 0,
        },
        laptop: {
          left: 0,
        },
      },
    },
    {
      title: t("table.header.player"),
      type: EDataGridColumnType.AVATAR,
      key: "fullName",
      showed: true,
      controlPanel: {
        title: t("table.header.player"),
        activeGroupKey: getActiveGroup(
          ["firstName", "lastName"],
          { all: ["firstName", "lastName"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "firstName",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.name"),
                value: queryParams?.firstName || "",
              },
              {
                key: "lastName",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("table.header.lastName"),
                value: queryParams?.lastName || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "firstName",
                "-firstName",
                "lastName",
                "-lastName",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc.name"),
                  value: "firstName",
                },
                {
                  title: t("table.filters.alphabet.desc.name"),
                  value: "-firstName",
                },
                {
                  title: t("table.filters.alphabet.asc.surname"),
                  value: "lastName",
                },
                {
                  title: t("table.filters.alphabet.desc.surname"),
                  value: "-lastName",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.header.phone"),
      type: EDataGridColumnType.PHONE,
      key: "phone",
      showed: true,
      controlPanel: {
        title: t("table.header.phone"),
        activeGroupKey: getActiveGroup(
          ["phone"],
          { all: ["phone"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "phone",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.phone || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.phoneWork"),
      type: EDataGridColumnType.PHONE,
      key: "workPhone",
      showed: true,
      controlPanel: {
        title: t("table.header.phoneWork"),
        activeGroupKey: getActiveGroup(
          ["workPhone"],
          { all: ["workPhone"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "workPhone",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.workPhone || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("table.header.email"),
      type: EDataGridColumnType.EMAIL,
      key: "email",
      showed: true,
      controlPanel: {
        title: t("table.header.email"),
        activeGroupKey: getActiveGroup(
          ["email"],
          { all: ["email"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "email",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.email || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: t("userForm.inputLabel.locality"),
      type: EDataGridColumnType.STRING,
      key: "locality",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("userForm.inputLabel.locality"),
        activeGroupKey: getActiveGroup(
          ["locality"],
          { all: ["locality"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "locality",
                "-locality",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "locality",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-locality",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: t("table.settings.checkboxLabel.studyWorkPlace"),
      type: EDataGridColumnType.STRING,
      key: "studyWorkPlace",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: t("table.settings.checkboxLabel.studyWorkPlace"),
        activeGroupKey: getActiveGroup(
          ["studyWorkPlace"],
          { all: ["studyWorkPlace"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "studyWorkPlace",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryParams?.studyWorkPlace || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "studyWorkPlace",
                "-studyWorkPlace",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "studyWorkPlace",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-studyWorkPlace",
                },
              ],
            },
          },
        ],
      },
    },
  ];
};
