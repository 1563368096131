import { socialService } from "../../../../base/api-instances";
import {
  AssessmentHistoryApiGetDownloadAssessmentHistoryRequest,
  DownloadHistoryApiGetDownloadHistoryRatingRequest,
  KeycloakApiPostAssessmentHistoryRequest,
} from "src/generated/social";

export const getExportHistory = (
  params: DownloadHistoryApiGetDownloadHistoryRatingRequest,
) => {
  return socialService
    .makeDownloadHistoryRequest()
    .getDownloadHistoryRating(params)
    .then((res) => res.data);
};

export const getSessionsExportHistory = (
  params: AssessmentHistoryApiGetDownloadAssessmentHistoryRequest,
) => {
  return socialService
    .makeDownloadAssessmentHistory()
    .getDownloadAssessmentHistory(params)
    .then((res) => res.data);
};

export const postSessionsExportHistory = (
  params: KeycloakApiPostAssessmentHistoryRequest,
) => {
  return socialService
    .makeKeycloakRequest()
    .postAssessmentHistory(params)
    .then((res) => res.data);
};
