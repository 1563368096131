import { ERequestStatus } from "../../types";
import { ProfileInfoActions } from "./actionCreators";
import { ProfileInfoActionsType } from "./actionTypes";
import { ProfileInfoStateInterface } from "./type";

const initialState: ProfileInfoStateInterface = {
  profileInfo: null,
  statusGetProfile: ERequestStatus.NEVER,
  statusUpdateProfile: ERequestStatus.NEVER,
  statusRepeatVerifiedEmail: ERequestStatus.NEVER,
};

export const profileInfoReducer = (
  state = initialState,
  action: ProfileInfoActions
) => {
  switch (action.type) {
    case ProfileInfoActionsType.CLEAN_PROFILE_INFO:
      return {
        ...state,
        profileInfo: null,
        statusGetProfile: ERequestStatus.NEVER,
        statusUpdateProfile: ERequestStatus.NEVER,
        statusRepeatVerifiedEmail: ERequestStatus.NEVER,
      };
    case ProfileInfoActionsType.REQUEST_GET_PROFILE_INFO:
      return {
        ...state,
        statusGetProfile: ERequestStatus.LOADING,
      };
    case ProfileInfoActionsType.REQUEST_POST_PROFILE_INFO:
      return {
        ...state,
        statusUpdateProfile: ERequestStatus.LOADING,
      };
    case ProfileInfoActionsType.REQUEST_PUT_REPEAT_VERIFIED_EMAIL:
      return {
        ...state,
        statusRepeatVerifiedEmail: ERequestStatus.LOADING,
      };
    case ProfileInfoActionsType.SUCCESS_GET_PROFILE_INFO:
      return {
        ...state,
        profileInfo: { ...action.payload },
        statusGetProfile: ERequestStatus.LOADED,
      };
    case ProfileInfoActionsType.SUCCESS_POST_PROFILE_INFO:
      return {
        ...state,
        profileInfo: { ...state.profileInfo, ...action.payload },
        statusUpdateProfile: ERequestStatus.LOADED,
      };
    case ProfileInfoActionsType.SUCCESS_PUT_REPEAT_VERIFIED_EMAIL:
      return {
        ...state,
        statusRepeatVerifiedEmail: ERequestStatus.LOADED,
      };
    case ProfileInfoActionsType.FAILURE_GET_PROFILE_INFO:
      return {
        ...state,
        statusGetProfile: ERequestStatus.ERROR,
      };
    case ProfileInfoActionsType.FAILURE_POST_PROFILE_INFO:
      return {
        ...state,
        statusUpdateProfile: ERequestStatus.ERROR,
      };
    case ProfileInfoActionsType.FAILURE_PUT_REPEAT_VERIFIED_EMAIL:
      return {
        ...state,
        statusRepeatVerifiedEmail: ERequestStatus.ERROR,
      };
    default:
      return state;
  }
};
