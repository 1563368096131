import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import { t } from "src/shared/helpers";
import { IBaseInputChange } from "../../../models";
import { useTranslation } from "react-i18next";
import Select, { GroupBase, IndicatorsContainerProps } from "react-select";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { SelectArrow } from "../select-arrow/select-arrow";

import "./base-select.scss";
import { selectStyles } from "./select";

const NoOptionMessage = () => <span>{t("common.nothingFound")}</span>;
export interface SelectItem {
  label: string;
  value: string;
  image?: string;
}

export type TSelectSize = "small" | "medium";

export interface IBaseSelectProps {
  className?: string;
  items: SelectItem[];
  activeItem?: string;
  placeholder?: string;
  required?: boolean;
  name?: string;
  error?: boolean;
  hideEmptyItem?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  hideBorder?: boolean;
  forDarkBg?: boolean;
  isTopPosition?: boolean;
  canSearch?: boolean;
  menuPlacement?: "auto" | "top" | "bottom";
  isSessionTable?: boolean;
  onChange: (payload: IBaseInputChange) => void;
  callback?: () => void;
  onMenuOpen?: () => void;
  forTable?: boolean;
  onMenuClose?: () => void;
  size?: TSelectSize;
  isStatusCompany?: boolean;
  isSearchUnderOption?: boolean;
}

export const BaseSelect: FC<IBaseSelectProps> = ({
  className,
  items,
  menuPlacement = "auto",
  activeItem = "",
  placeholder,
  onChange,
  hideEmptyItem,
  disabled,
  hidden,
  hideBorder,
  name,
  error,
  forDarkBg,
  callback,
  isTopPosition,
  required,
  canSearch = false,
  isSessionTable,
  onMenuOpen,
  onMenuClose,
  size,
  forTable,
  isStatusCompany,
  isSearchUnderOption = false,
}): ReactElement => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const hasImage = items.some((item) => !!item.image);
  const { isLaptop } = useMediaQuery();
  const [searchValue, setSearchValue] = useState<string>("");
  const localItems: SelectItem[] = useMemo(() => {
    if (!hideEmptyItem) {
      return [
        {
          value: "",
          label: t("common.select.notSelected"),
        },
        ...items,
      ];
    }

    return items;
  }, [items, hideEmptyItem, t]);
  const localActiveItem: SelectItem | null = useMemo(() => {
    const tmpActiveItem: SelectItem | undefined = items.find(
      (item) => item.value === activeItem,
    );

    if (isStatusCompany && activeItem) {
      return {
        value: activeItem,
        label: t(`table.ratings.statusCompany.${activeItem}`),
      };
    }

    return tmpActiveItem && !!tmpActiveItem.value ? tmpActiveItem : null;
  }, [items, activeItem]);

  const onChangeHandler = useCallback(
    (value: string): void => {
      onChange({
        value,
        name,
      });

      if (!!callback) {
        callback();
      }
    },
    [onChange, name, callback],
  );

  const indicatorsContainer = useCallback(
    (
      state: PropsWithChildren<
        IndicatorsContainerProps<SelectItem, false, GroupBase<SelectItem>>
      >,
    ) => (
      <SelectArrow
        isDisabled={state.isDisabled}
        size={size}
        isSessionTable={isSessionTable}
        forDarkBg={forDarkBg}
      />
    ),
    [size, isSessionTable, forDarkBg],
  );

  const handleFocus = useCallback(() => setIsFocused(true), []);

  const handleBlur = useCallback(() => setIsFocused(false), []);

  const handleChange = useCallback(
    (event: any) => {
      event && onChangeHandler(event.value);
    },
    [onChangeHandler],
  );

  const components = useMemo(
    () => ({
      IndicatorsContainer: indicatorsContainer,
    }),
    [indicatorsContainer],
  );

  return (
    <Select<SelectItem, false, GroupBase<SelectItem>>
      noOptionsMessage={NoOptionMessage}
      classNamePrefix="base-select"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isDisabled={disabled}
      isSearchable={canSearch}
      className={classNames("base-select", className, {
        "base-select-focused": isFocused && isSearchUnderOption,
        "base-select-hidden": hidden,
      })}
      blurInputOnSelect
      onFocus={handleFocus}
      onBlur={handleBlur}
      options={localItems}
      onChange={handleChange}
      value={localActiveItem}
      inputValue={searchValue}
      onInputChange={setSearchValue}
      menuPlacement={menuPlacement}
      components={
        !forTable
          ? components
          : { DropdownIndicator: () => null, IndicatorSeparator: () => null }
      }
      styles={selectStyles({
        canSearch,
        forDarkBg,
        forTable,
        error,
        hasImage,
        hideBorder,
        placeholder,
        required,
        isSessionTable,
        isLaptop,
        isTopPosition,
        size,
        t,
        activeItemImage: localActiveItem?.image,
      })}
    />
  );
};
