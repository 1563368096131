import {
  FC,
  MutableRefObject,
  ReactElement,
  useCallback,
  useState,
} from "react";
import classNames from "classnames";

import { BaseTooltip } from "../base-tooltip/base-tooltip";

import { IStatItem } from "../../models/stat";

interface Props {
  item: IStatItem;
  useToolTip?: boolean;
  isMainCompetence?: boolean;
  descriptionIsVisible?: boolean;
  customClassName?: string;
}

export const PlayerShortStatItem: FC<Props> = ({
  item,
  isMainCompetence,
  useToolTip = true,
  descriptionIsVisible = true,
  customClassName,
}): ReactElement => {
  const [ref, setRef] = useState<MutableRefObject<HTMLLIElement | null>>({
    current: null,
  });

  const itemRef = useCallback((node: HTMLLIElement) => {
    setRef({ current: node });
  }, []);

  return (
    <li
      className={classNames(
        "player-short-stat__list-item",
        "tooltip-wrapper",
        customClassName
      )}
      ref={itemRef}
    >
      <span
        className={classNames(
          "player-short-stat__list-item-icon",
          `player-short-stat__list-item-icon--${item.key}`,
          {
            "player-short-stat__list-item-icon--main": isMainCompetence,
            "player-short-stat__list-item-icon--additional":
              isMainCompetence === false,
          }
        )}
        aria-hidden
        translate="no"
      >
        {item.shortTitle}
      </span>

      {item.description && descriptionIsVisible && (
        <>
          {item.description}
          {item.additionalDescription && (
            <>
              <span className="player-short-stat__list-item__divider">/</span>
              {item.additionalDescription}
            </>
          )}
        </>
      )}

      {!!ref && useToolTip && (
        <BaseTooltip
          tooltipToggleRef={ref}
          position="bottom-start"
          offsetY={5}
          mainAxis={false}
        >
          {item.title}
        </BaseTooltip>
      )}
    </li>
  );
};
