import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest,
  SimulationType,
} from "../../../generated/game";

import "./tutorial-history-page.scss";

import { TDataGridDataParams } from "../../../shared/models";

import { requestSessionsHistoryRoles } from "../../../shared/store/ducks/sessions-history/actionCreators";

import { PageWrapper } from "src/shared/components";

import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";

import { TutorialHistory } from "../../../shared/components/data-grids/histories/tutorial-history";
import {
  $isLoadingTutorial,
  $tutorialItems,
  $tutorialPagination,
  requestTutorialHistory,
} from "../../../shared/components/session-history-player/lib/player-history";
import { useParams } from "react-router-dom";
import { useUrlState } from "../../../shared/hooks/useUrlState";

export const TutorialHistoryPage: FC = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const tutorialItems = useUnit($tutorialItems);

  const tutorialPagination = useUnit($tutorialPagination);

  const isLoading: boolean = useUnit($isLoadingTutorial);

  const simulationTypes = useMemo<SimulationType[]>(
    () => Object.values(SimulationType),
    [],
  );

  useEffect(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      date_of_complete_from,
      date_of_complete_to,
      attemptTo,
      attemptFrom,
      simulationType,
      chapterNumber,
    } = urlState.query;

    let simulationTypesValues: SimulationType[] = simulationType
      ? (simulationType.split(",") as SimulationType[])
      : [];

    simulationTypesValues = simulationTypesValues.filter(
      (simulationTypeValue) => simulationTypes.includes(simulationTypeValue),
    );

    const paramsTutorial: PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest =
      {
        playerId: urlState.params.id,
        pageSize: pageSize ? Number(pageSize) : 50,
        pageNum: pageNum ? Number(pageNum) : 1,
        orderByQuery: orderBy ? orderBy : undefined,
        attemptTo: attemptTo ? Number(attemptTo) : undefined,
        attemptFrom: attemptFrom ? Number(attemptFrom) : undefined,
        simulationType: !!simulationTypesValues.length
          ? simulationTypesValues
          : undefined,
        chapterNumber: chapterNumber,
        dateOfCompleteFrom: date_of_complete_from,
        dateOfCompleteTo: date_of_complete_to,
      };

    requestTutorialHistory(paramsTutorial);
  }, [simulationTypes, urlState.params.id, urlState.query]);

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  return (
    <PageWrapper
      title={t("tutorialHistory.title")}
      backButton
      isShowContentWhenIsLoading
      emptyPanel
      isLightBlueBackground
      isLoadingPanel={isLoading}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
          {/*// TODO: Выгрузка истории туториала*/}
          {/*<div className="table__control-btn">*/}
          {/*  <BaseButton*/}
          {/*    onClick={() => exportHistoryCsv({ playerId: urlState.params.id })}*/}
          {/*  >*/}
          {/*    <TextWithIcon*/}
          {/*      label={`${t("common.unload")} CSV`}*/}
          {/*      iconName={"upload-blue"}*/}
          {/*      isTextNowrap*/}
          {/*    />*/}
          {/*  </BaseButton>*/}
          {/*</div>*/}
          {/*<div className="table__control-btn">*/}
          {/*  <BaseButton*/}
          {/*    onClick={() =>*/}
          {/*      exportHistoryXlsx({ playerId: urlState.params.id })*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <TextWithIcon*/}
          {/*      label={`${t("common.unload")} XLSX`}*/}
          {/*      iconName={"upload-blue"}*/}
          {/*      isTextNowrap*/}
          {/*    />*/}
          {/*  </BaseButton>*/}
          {/*</div>*/}
        </div>
      }
    >
      <div className="tutorial-history-data-grid-wrapper">
        <TutorialHistory
          historyItems={tutorialItems}
          autoScrollDisabled={true}
          queryParams={urlState.query}
          onUpdateSortAndFilters={onUpdateQueryParams}
          loading={isLoading}
          pagination={tutorialPagination}
          goToPage={(pageNum) => goToPage(pageNum)}
          readmore={(pageNum) => goToPage(pageNum)}
        />
      </div>
    </PageWrapper>
  );
};
