import { sample } from "effector";

import {
  GetEmail,
  getEmailNotification,
} from "src/shared/api/admin/notification";

import { emailNotificationDomain } from "./email-notification";

import { ResponseEmail } from "src/generated/notification";

// Event

export const getEmail = emailNotificationDomain.createEvent<GetEmail>();

// Effect

export const getEmailFx =
  emailNotificationDomain.createEffect(getEmailNotification);

// Store

export const $email = emailNotificationDomain.createStore<ResponseEmail | null>(
  null,
);

// Logic

sample({ clock: getEmail, target: getEmailFx });

sample({
  clock: getEmailFx.doneData,
  target: $email,
});
