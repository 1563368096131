import classNames from "classnames";
import { useUnit } from "effector-react";
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";
import { BaseLoader, EmptyBlock, PopupGridGroup } from "../../";
import { AdmAssessmentPlayerDto, GameType } from "../../../../generated/game";
import { getSimulationRole } from "../../../helpers/getSimulationRoles";
import { splitStrings } from "../../../helpers/splitStrings";
import useClient from "../../../hooks/useClient";
import { IBaseInputChange } from "../../../models/base-input";
import {
  EDataGridColumnType,
  EDataGridTextWeight,
  IDataGridColumn,
  IDataGridRow,
} from "../../../models/dataGrid";
import {
  cleanAssessmentItemAdmin,
  requestAssessmentItemAdmin,
  $assessmentItemAdmin,
} from "src/entities/public/assessment-item-admin";
import { requestGetSimulationItemAdmin } from "../../../store/ducks/simulation-item-admin/actionCreators";
import {
  selectSimulationItemAdminItem,
  selectSimulationItemAdminStatusGet,
} from "../../../store/ducks/simulation-item-admin/selectors";
import { ERequestStatus, Specialties } from "../../../store/types";
import { ITeamGridItem } from "../../popup-grid-group/team-results-grid/team-results-grid";
import "./assessment-members-popup.scss";
import { ICorpGridItem } from "../../popup-grid-group/corp-results-grid/corp-results-grid";

interface ICorpTeamsItem {
  areaNum: number;
  corpNum: number;
}

interface Props {}

const { $activePopups } = popupModel;

export const AssessmentMembersPopup: FC<Props> = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const { isHrClientId } = useClient();

  const dispatch = useDispatch();

  const { item: assessment, status: assessmentLoadingStatus } =
    useUnit($assessmentItemAdmin);

  const simulation = useSelector(selectSimulationItemAdminItem);

  const simulationLoadingStatus = useSelector(
    selectSimulationItemAdminStatusGet,
  );

  const activePopups = useUnit($activePopups);

  const itemId = useMemo(() => {
    const currentPopup = activePopups.find(
      ({ name }) => name === EPopupName.ASSESSMENT_MEMBERS,
    );

    return currentPopup?.dataId?.find(
      ({ name }) => name === EPopupDataIdName.DEFAULT,
    )?.value;
  }, [activePopups]);

  const [searchValue, setSearchValue] = useState<string>("");

  const gameType = useMemo(() => {
    return assessment?.gameType
      ? simulation?.rawMetaJson.gameTypes[assessment?.gameType]
      : undefined;
  }, [simulation, assessment]);

  const isTeamGrid: boolean = assessment?.gameType === GameType.Team;

  const isCorpGrid: boolean = assessment?.gameType === GameType.Corp;

  const isSoloGrid: boolean =
    assessment?.gameType === GameType.Tutorial ||
    assessment?.gameType === GameType.Single ||
    assessment?.gameType === GameType.Solo;

  const isLoading: boolean =
    assessmentLoadingStatus === ERequestStatus.LOADING ||
    simulationLoadingStatus === ERequestStatus.LOADING;

  const isLoaded: boolean =
    assessmentLoadingStatus === ERequestStatus.LOADED &&
    simulationLoadingStatus === ERequestStatus.LOADED;

  const isError: boolean =
    assessmentLoadingStatus === ERequestStatus.ERROR ||
    simulationLoadingStatus === ERequestStatus.ERROR;

  useEffect(() => {
    if (itemId) {
      requestAssessmentItemAdmin({ id: itemId });
    }
  }, [dispatch, itemId]);

  useEffect(() => {
    if (assessment?.gameId) {
      dispatch(requestGetSimulationItemAdmin({ id: assessment?.gameId }));
    }
  }, [dispatch, assessment]);

  const onChangeSearch = ({ value }: IBaseInputChange) => {
    setSearchValue(value);
  };

  const getUserUrl = useCallback(
    (id: string): string =>
      isHrClientId ? `/ratings/rating/${id}` : `/users-control/user/${id}/info`,
    [isHrClientId],
  );

  const soloColumns = useMemo<IDataGridColumn[]>(() => {
    if (isSoloGrid) {
      return [
        {
          title: t("table.header.member.name"),
          type: EDataGridColumnType.AVATAR,
          key: "fullName",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
        },
        {
          title: t("table.header.role"),
          type: EDataGridColumnType.STRING,
          key: "role",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
          textAlign: "right",
        },
      ];
    }

    return [];
  }, [t, isSoloGrid]);

  const soloRows = useMemo<IDataGridRow[]>(() => {
    if (assessment?.players && isSoloGrid) {
      return assessment.players.reduce(
        (final: IDataGridRow[], curr: AdmAssessmentPlayerDto) => {
          const { role, lastname, firstname, pid } = curr;

          const isBot: boolean = firstname === "Bot" && lastname === "Bot";

          const getRole = () => {
            if (role && simulation) {
              const gameTypeKey: GameType = assessment.gameType;

              const gameTypeInfo =
                simulation.rawMetaJson.gameTypes[gameTypeKey];

              return getSimulationRole({
                roleKey: role,
                gameType: gameTypeInfo,
                lang: i18n.language,
              }).role;
            }

            return "";
          };

          const fullName: string = splitStrings([firstname, lastname]);

          const isNotMatch: boolean =
            !!searchValue &&
            !fullName
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase());

          if (isNotMatch) {
            return final;
          }

          final.push({
            id: pid || "",
            selected: false,
            fullName,
            role: getRole(),
            avatarProps: !isBot
              ? {
                  alt: fullName,
                  userId: pid,
                  link: getUserUrl(pid || ""),
                }
              : undefined,
          });

          return final;
        },
        [],
      );
    }

    return [];
  }, [
    assessment,
    i18n.language,
    searchValue,
    isSoloGrid,
    simulation,
    getUserUrl,
  ]);

  const teamColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: t("table.header.member.name"),
        type: EDataGridColumnType.AVATAR,
        key: "fullName",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        minWidth: 390,
        width: 390,
      },
      {
        title: t("table.header.role"),
        type: EDataGridColumnType.STRING,
        key: "role",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 160,
        minWidth: 160,
        noPaddingLeft: true,
      },
      {
        title: t("table.header.teamRole"),
        type: EDataGridColumnType.STRING,
        key: "teamRole",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 170,
        minWidth: 170,
        noPaddingLeft: true,
        textAlign: "right",
      },
    ],
    [t],
  );

  const teamRows: ITeamGridItem[] = useMemo(() => {
    if (assessment?.players && isTeamGrid) {
      const tmpTeamsNums: Set<number> = assessment.players.reduce(
        (final: Set<number>, curr: AdmAssessmentPlayerDto) =>
          curr.teamNum ? final.add(curr.teamNum) : final,
        new Set(),
      );

      const teamsNums = Array.from(tmpTeamsNums).sort((a, b) => a - b);

      return teamsNums.reduce((final: ITeamGridItem[], curr: number) => {
        const players: AdmAssessmentPlayerDto[] | undefined =
          assessment.players?.filter((item) => item.teamNum === curr);

        if (players?.length) {
          const rows = players.reduce(
            (final: IDataGridRow[], curr: AdmAssessmentPlayerDto) => {
              const { role, lastname, firstname, pid, teamName } = curr;

              const isBot: boolean = firstname === "Bot" && lastname === "Bot";

              const fullName: string = splitStrings([firstname, lastname]);

              const notFound: boolean = !fullName.includes(searchValue);

              const getRole = () => {
                if (role && simulation) {
                  const gameTypeKey: GameType = GameType.Team;
                  const gameTypeInfo =
                    simulation.rawMetaJson.gameTypes[gameTypeKey];

                  return getSimulationRole({
                    roleKey: teamName || "",
                    gameType: gameTypeInfo,
                    lang: i18n.language,
                    teamRoleKey: role,
                  });
                }
              };

              final.push({
                id: pid,
                selected: false,
                isTransparent: notFound,
                fullName,
                role: " ",
                teamRole: getRole()?.teamRole,
                avatarProps: !isBot
                  ? {
                      alt: fullName,
                      userId: pid,
                      link: getUserUrl(pid),
                    }
                  : undefined,
              });

              return final;
            },
            [],
          );

          const isNotMatch: boolean = !rows.some((item) =>
            item.fullName
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()),
          );

          if (isNotMatch) {
            return final;
          }

          final.push({
            teamNum: curr,
            role: players[0].teamName || "",
            rows,
          });
        }

        return final;
      }, []);
    }

    return [];
  }, [
    i18n.language,
    assessment,
    searchValue,
    isTeamGrid,
    getUserUrl,
    simulation,
  ]);

  const corpRows: ICorpGridItem[] = useMemo(() => {
    if (assessment?.players && isCorpGrid) {
      const tmpCorpTeamsNums = assessment.players.reduce(
        (
          final: ICorpTeamsItem[],
          { areaNum, corpNum, role }: AdmAssessmentPlayerDto,
        ) => {
          const someEqual: boolean = final.some(
            (item) => item.areaNum === areaNum && item.corpNum === corpNum,
          );

          if (role === Specialties.CEO) {
            areaNum = 0;
          }

          if (!someEqual && areaNum !== undefined && corpNum !== undefined) {
            final.push({
              areaNum,
              corpNum,
            });
          }

          return final;
        },
        [],
      );

      const corpTeamsNums = Array.from(tmpCorpTeamsNums).sort(
        (a, b) => a.areaNum - b.areaNum,
      );

      return corpTeamsNums.reduce((final: ICorpGridItem[], curr) => {
        const players: AdmAssessmentPlayerDto[] | undefined =
          assessment.players?.filter((item) => {
            if (item.role === Specialties.CEO) {
              item.areaNum = 0;
            }

            return (
              item.corpNum === curr.corpNum && item.areaNum === curr.areaNum
            );
          });

        if (players?.length) {
          const rows = players.reduce(
            (final: IDataGridRow[], curr: AdmAssessmentPlayerDto) => {
              const { role, lastname, firstname, pid, teamName } = curr;

              const isBot: boolean = firstname === "Bot" && lastname === "Bot";

              const fullName: string = splitStrings([firstname, lastname]);

              const notFound: boolean = !fullName.includes(searchValue);

              const getRole = () => {
                if (role && simulation) {
                  const gameTypeKey: GameType = GameType.Corp;
                  const gameTypeInfo =
                    simulation.rawMetaJson.gameTypes[gameTypeKey];

                  return getSimulationRole({
                    roleKey: teamName || "",
                    gameType: gameTypeInfo,
                    lang: i18n.language,
                    teamRoleKey: role,
                  });
                }
              };

              final.push({
                id: pid,
                selected: false,
                isTransparent: notFound,
                fullName,
                role: " ",
                teamRole: getRole()?.teamRole,
                avatarProps: !isBot
                  ? {
                      alt: fullName,
                      userId: pid,
                      link: getUserUrl(pid),
                    }
                  : undefined,
              });

              return final;
            },
            [],
          );

          const isNotMatch: boolean = !rows.some((item) =>
            item.fullName
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()),
          );

          if (isNotMatch) {
            return final;
          }

          final.push({
            corpNum: curr.corpNum,
            areaNum: curr.areaNum,
            role: players[0].teamName || "",
            rows,
          });
        }

        return final;
      }, []);
    }

    return [];
  }, [
    i18n.language,
    assessment,
    searchValue,
    isTeamGrid,
    getUserUrl,
    simulation,
  ]);

  const getTeamRoleTitle = (role: string) => {
    if (gameType && isTeamGrid) {
      return getSimulationRole({
        roleKey: role,
        lang: i18n.language,
        gameType,
        teamRoleKey: "empty",
      }).role;
    }

    return "";
  };

  useEffect(() => {
    return () => {
      cleanAssessmentItemAdmin();
    };
  }, []);

  return (
    <div
      className={classNames("assessment-members-popup", {
        "assessment-members-popup--solo": isSoloGrid,
        "assessment-members-popup--team": isTeamGrid,
        "assessment-members-popup--corp": isCorpGrid,
      })}
    >
      {isLoading && <BaseLoader />}
      {isLoaded && (
        <PopupGridGroup
          searchValue={searchValue}
          isTeamGrid={isTeamGrid}
          isCorpGrid={isCorpGrid}
          soloColumns={soloColumns}
          soloRows={soloRows}
          teamColumns={teamColumns}
          teamRows={teamRows}
          corpColumns={teamColumns}
          corpRows={corpRows}
          onChangeSearch={onChangeSearch}
          soloClassName="assessment-members-popup--solo"
          teamClassName="assessment-members-popup--team"
          corpClassName="assessment-members-popup--corp"
          getTeamRoleTitle={getTeamRoleTitle}
        />
      )}
      {!assessment?.players?.length && isLoaded && (
        <EmptyBlock title={t("common.emptyBlock.usersList")} />
      )}
      {isError && <EmptyBlock title={t("alert.requestError")} />}
    </div>
  );
};
