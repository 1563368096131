import { sample } from "effector";

import { getSimulations } from "src/shared/api/admin/simulations";

import { simulationsAdminEntitiesDomain } from "./domain";

import { ISimulationAdmin } from "./types";

// Stores

export const $simulations = simulationsAdminEntitiesDomain.createStore<
  ISimulationAdmin[]
>([]);

export const $isLoading =
  simulationsAdminEntitiesDomain.createStore<boolean>(true);

// Events

export const fetchSimulations = simulationsAdminEntitiesDomain.createEvent();

// Effects

export const fetchSimulationsFx =
  simulationsAdminEntitiesDomain.createEffect(getSimulations);

// Logic

sample({ clock: fetchSimulations, target: fetchSimulationsFx });

sample({
  clock: fetchSimulationsFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchSimulationsFx.doneData,
  fn: ({ games }) => games as ISimulationAdmin[],
  target: $simulations,
});
