import { sample } from "effector";
import { UpdateRoomSocketHandler } from "./events";
import { socketsV4Domain } from "./sockets";
import { connectSocket, SocketParams } from "../../lib/connect";
import { namespaces, paths, urls } from "../config";
import { Socket } from "socket.io-client";
import { UpdateRoomSocketCommander } from "./commands";

export const $socketGameV4UpdateRoom =
  socketsV4Domain.createStore<Socket | null>(null);

export const $socketGameV4UpdateRoomCommander =
  socketsV4Domain.createStore<UpdateRoomSocketCommander | null>(null);
export const $socketGameV4UpdateRoomHandler =
  socketsV4Domain.createStore<UpdateRoomSocketHandler | null>(null);

// Effects

const initGameV4UpdateRoomFx = socketsV4Domain.createEffect(connectSocket);

// Events

export const initGameV4UpdateRoom = socketsV4Domain.createEvent();

sample({
  clock: initGameV4UpdateRoom,
  fn: (): SocketParams => ({
    path: paths.game.v4,
    url: urls.game,
    namespace: namespaces.game.update,
  }),
  target: initGameV4UpdateRoomFx,
});

sample({
  clock: initGameV4UpdateRoomFx.doneData,
  target: $socketGameV4UpdateRoom,
});

sample({
  clock: $socketGameV4UpdateRoom,
  fn: (socket) => socket && UpdateRoomSocketCommander.of(socket),
  target: $socketGameV4UpdateRoomCommander,
});

sample({
  clock: $socketGameV4UpdateRoom,
  fn: (socket) => socket && UpdateRoomSocketHandler.of(socket),
  target: $socketGameV4UpdateRoomHandler,
});
