import { ChangeEvent, FC, ReactElement, ReactNode } from "react";

import classNames from "classnames";

import { Checkbox } from "../../models/checkbox";

import "./input-checkbox.scss";

interface Props {
  id?: string;
  value?: boolean;
  label?: string;
  title?: string;
  type?: Checkbox;
  className?: string;
  forDarkBg?: boolean;
  forSort?: boolean;
  isBoldLabel?: boolean;
  vAlign?: "top" | "middle" | "bottom";
  isDisabled?: boolean;
  onChange: (item: boolean) => void;
  children?: ReactNode;
}

export const InputCheckbox: FC<Props> = ({
  value = false,
  label,
  title,
  id,
  type = Checkbox.EMPTY,
  forDarkBg,
  forSort,
  vAlign = "middle",
  onChange,
  className,
  children,
  isBoldLabel,
  isDisabled,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (isDisabled) return;
    onChange((e.target as HTMLInputElement).checked);
  };

  return (
    <label
      className={classNames("checkbox", className, {
        "checkbox--for-dark-bg": forDarkBg,
        "checkbox--for-sort": forSort,
        "checkbox--checked": type === Checkbox.CHOSEN,
        "checkbox--checked-plus": type === Checkbox.RESET,
      })}
      htmlFor={id}
    >
      <input
        id={id}
        checked={value}
        type="checkbox"
        onChange={onChangeHandler}
        className="checkbox__input"
        title={title}
      />
      <span
        className={classNames(
          "checkbox__label",
          `checkbox__label--valign_${vAlign}`,
          {
            "checkbox__label--for-dark-bg": forDarkBg,
            "checkbox__label--for-sort": forSort,
            "checkbox__label--bold": isBoldLabel,
            "checkbox__label--disabled": isDisabled,
          },
        )}
      >
        {label && <span className="checkbox__label-text">{label}</span>}
        {children && <span className="checkbox__label-text">{children}</span>}
      </span>
    </label>
  );
};
