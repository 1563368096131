import { createEvent, createStore, createEffect, sample } from "effector";
import { ERequestStatus } from "src/shared/store/types";
import {
  CreateAssessmentStateInterface,
  IRequestCreateAssessment,
} from "./types";
import { CreateAssessmentResDto } from "src/generated/game";
import { postCreateAssessmentGame } from "src/shared/api/public/create-assessment";
import { openResponseErrorPopup } from "src/shared/components";

// Stores

export const $createAssessment = createStore<CreateAssessmentStateInterface>({
  createAssessment: null,
  status: ERequestStatus.NEVER,
});

// Events

export const requestCreateAssessment = createEvent<IRequestCreateAssessment>();
export const successCreateAssessment = createEvent<CreateAssessmentResDto>();
export const failureCreateAssessment = createEvent();

// Effects

export const fetchCreateAssessmentFx = createEffect(postCreateAssessmentGame);

// Logic

$createAssessment.on(failureCreateAssessment, (state) => ({
  ...state,
  status: ERequestStatus.ERROR,
}));

$createAssessment.on(requestCreateAssessment, (state) => ({
  ...state,
  status: ERequestStatus.LOADING,
}));

$createAssessment.on(successCreateAssessment, (state, poyload) => ({
  createAssessment: { ...poyload },
  status: ERequestStatus.LOADED,
}));

sample({
  clock: fetchCreateAssessmentFx.fail,
  target: failureCreateAssessment,
});

requestCreateAssessment.watch(({ id, assessment, callback }) => {
  fetchCreateAssessmentFx({ id, assessment })
    .then((res) => {
      successCreateAssessment(res);
      callback?.(res);
    })
    .catch((err) => {
      console.error(err);
      openResponseErrorPopup(err?.response?.data?.err?.msg);
    });
});
