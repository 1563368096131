import { useUnit } from "effector-react";
import { FC, ReactElement } from "react";
import { Navigate, RouteProps, Outlet } from "react-router-dom";
import { $isAccess } from "src/features/public/app-access/model/app-access";

type Props = RouteProps & {
  children: JSX.Element;
  redirectUrl?: string;
  isCustomAccess?: boolean;
};

export const PrivateRoute: FC<Props> = ({
  redirectUrl,
  isCustomAccess,
  children,
}): ReactElement => {
  const isAccess = useUnit($isAccess);

  const isNotAccess: boolean =
    isCustomAccess !== undefined ? !isCustomAccess : !isAccess;

  if (isNotAccess) {
    return <Navigate to={redirectUrl || "/profile/info"} replace />;
  }

  return children ? children : <Outlet />;
};
