import { sample } from "effector";

import {
  deleteSmsMessage,
  DeleteSmsMessage,
} from "src/shared/api/admin/notification/sms-notification";

import { $smsList, smsNotificationDomain } from "./sms-notification";

// Event

export const deleteSms = smsNotificationDomain.createEvent<DeleteSmsMessage>();

// Effect

export const deleteSmsFx = smsNotificationDomain.createEffect(deleteSmsMessage);

// Logic

sample({ clock: deleteSms, target: deleteSmsFx });

sample({
  clock: deleteSmsFx.done,
  source: $smsList,
  fn: (smsList, deletedSms) =>
    smsList.filter((sms) => sms.id !== deletedSms.params.id),
  target: $smsList,
});
