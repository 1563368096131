import { createEffect, createEvent, createStore, sample } from "effector";
import { ERequestStatus } from "../../../../store/types";
import {
  Configuration,
  Player,
  PlayersApiFactory,
  RejectionReasonEnum,
  RoleInRelatedProjectEnum,
  StatusCompanyEnum,
  StatusEmploymentEnum,
} from "../../../../../generated/social";
import { $axiosSocial } from "../../../../api/api";

interface IProfileCompetence {
  player: Player | null;
  serverTime: Date | null;
  status: ERequestStatus;
  error?: Error;
}

interface IStatus {
  status: string;
  isLoading: boolean;
}

interface IStatusMulti {
  status: string[];
  isLoading: boolean;
}

interface IRequestPostStatusCompany {
  userId: string;
  status?: StatusCompanyEnum;
}

interface IRequestPostStatusEmployment {
  userId: string;
  status?: StatusEmploymentEnum;
}

interface IRequestPostRoleInRelatedProject {
  userId: string;
  status?: RoleInRelatedProjectEnum[];
}

interface IRequestPostRejectionReason {
  userId: string;
  status?: RejectionReasonEnum;
}

// stores

export const $userId = createStore<string>("");

export const $profileCompetence = createStore<IProfileCompetence>({
  player: null,
  serverTime: new Date(),
  status: ERequestStatus.LOADING,
});

export const $statusCompany = createStore<IStatus>({
  status: "",
  isLoading: false,
});

export const $statusEmployment = createStore<IStatus>({
  status: "",
  isLoading: false,
});

export const $statusRelatedProjects = createStore<IStatusMulti>({
  status: [],
  isLoading: false,
});

export const $rejectionReason = createStore<IStatus>({
  status: "",
  isLoading: false,
});

// events

export const selectUser = createEvent<string>();

// effects

export const requestCompetenceFx = createEffect(async (id: string) => {
  const playersApi = PlayersApiFactory(new Configuration(), "", $axiosSocial);
  const response = await playersApi.getPlayer(id);
  return {
    data: response.data,
    serverTime: new Date(response.headers.date),
  };
});

export const requestPostStatusCompanyFx = createEffect(
  async (params: IRequestPostStatusCompany) => {
    const playersApi = PlayersApiFactory(new Configuration(), "", $axiosSocial);
    const response = await playersApi.patchPlayerById(params.userId, {
      statusCompany: params.status
        ? params.status
        : StatusCompanyEnum.NoSelected,
      statusEmployment: StatusEmploymentEnum.NoSelected,
      rejectionReason: RejectionReasonEnum.NoSelected,
    });

    return {
      data: response.data,
      serverTime: new Date(response.headers.date),
    };
  },
);

export const requestPostStatusEmploymentFx = createEffect(
  async (params: IRequestPostStatusEmployment) => {
    const playersApi = PlayersApiFactory(new Configuration(), "", $axiosSocial);
    const response = await playersApi.patchPlayerById(params.userId, {
      statusEmployment: params.status
        ? params.status
        : StatusEmploymentEnum.NoSelected,
    });

    return {
      data: response.data,
      serverTime: new Date(response.headers.date),
    };
  },
);

export const requestPostRoleInRelatedProjectFx = createEffect(
  async (params: IRequestPostRoleInRelatedProject) => {
    const playersApi = PlayersApiFactory(new Configuration(), "", $axiosSocial);
    const resetParams = params?.status?.includes(
      RoleInRelatedProjectEnum.NoSelected,
    );
    const response = await playersApi.patchPlayerById(params.userId, {
      rolesInRelatedProjects:
        params?.status && !resetParams
          ? params.status
          : [RoleInRelatedProjectEnum.NoSelected],
    });

    return {
      data: response.data,
      serverTime: new Date(response.headers.date),
    };
  },
);

export const requestPostRejectionStatusFx = createEffect(
  async (params: IRequestPostRejectionReason) => {
    const playersApi = PlayersApiFactory(new Configuration(), "", $axiosSocial);
    const response = await playersApi.patchPlayerById(params.userId, {
      rejectionReason: params.status
        ? params.status
        : RejectionReasonEnum.NoSelected,
    });

    return {
      data: response.data,
      serverTime: new Date(response.headers.date),
    };
  },
);

// logic

sample({
  clock: selectUser,
  target: $userId,
});

$profileCompetence.on(requestCompetenceFx.pending, (state, isPending) => {
  if (isPending) {
    return {
      player: null,
      serverTime: null,
      status: ERequestStatus.LOADING,
    };
  }
});

sample({
  clock: requestCompetenceFx.doneData,
  target: $profileCompetence,
  fn: (payload) => ({
    player: payload.data,
    serverTime: payload.serverTime,
    status: ERequestStatus.LOADED,
  }),
});

sample({
  clock: requestCompetenceFx.failData,
  target: $profileCompetence,
  fn: (payload) => ({
    player: null,
    serverTime: null,
    status: ERequestStatus.ERROR,
    error: payload,
  }),
});

sample({
  clock: [
    requestCompetenceFx.doneData,
    requestPostStatusEmploymentFx.doneData,
    requestPostStatusCompanyFx.doneData,
  ],
  fn: (payload): IStatus => ({
    status: String(payload.data.statusEmployment),
    isLoading: false,
  }),
  target: $statusEmployment,
});

sample({
  clock: [requestCompetenceFx.doneData, requestPostStatusCompanyFx.doneData],
  fn: (payload): IStatus => ({
    status: payload.data.statusCompany ? payload.data.statusCompany : "",
    isLoading: false,
  }),
  target: $statusCompany,
});

sample({
  clock: [
    requestCompetenceFx.doneData,
    requestPostRoleInRelatedProjectFx.doneData,
  ],
  fn: (payload): IStatusMulti => ({
    status: payload.data.roleInRelatedProjects as string[],
    isLoading: false,
  }),
  target: $statusRelatedProjects,
});

sample({
  clock: [
    requestCompetenceFx.doneData,
    requestPostRejectionStatusFx.doneData,
    requestPostStatusCompanyFx.doneData,
  ],
  fn: (payload): IStatus => ({
    status: String(payload.data.rejectionReason),
    isLoading: false,
  }),
  target: $rejectionReason,
});

sample({
  clock: requestPostStatusEmploymentFx.pending,
  source: $statusEmployment,
  fn: (state, isPending) => ({
    status: state.status,
    isLoading: isPending,
  }),
  target: $statusEmployment,
});

sample({
  clock: requestPostStatusCompanyFx.pending,
  source: $statusCompany,
  fn: (state, isPending) => ({
    status: state.status,
    isLoading: isPending,
  }),
  target: $statusCompany,
});

sample({
  clock: requestPostRejectionStatusFx.pending,
  source: $rejectionReason,
  fn: (state, isPending) => ({
    status: state.status,
    isLoading: isPending,
  }),
  target: $rejectionReason,
});

sample({
  clock: requestPostRoleInRelatedProjectFx.pending,
  source: $statusRelatedProjects,
  fn: (state, isPending) => ({
    status: state.status,
    isLoading: isPending,
  }),
  target: $statusRelatedProjects,
});
