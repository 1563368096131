import { FC, Fragment, ReactElement, ReactNode, useEffect } from "react";
import { useUnit } from "effector-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import classNames from "classnames";
import {
  BaseAlert,
  BaseButton,
  BasePanel,
  ControlPanel,
  NavList,
  NavListItem,
} from "src/shared/components/index";
import useClient from "src/shared/hooks/useClient";
import { selectProfileCompetenceItem } from "src/shared/store/ducks/profile-competence";
import { appAccessModel } from "src/features/public/app-access";
import { $specificSessionPermission } from "../../../entities/public/permissions/model";
import { $appInterface } from "../../../features/public/app-interface";
import "./page-wrapper.scss";

interface Props {
  title?: string;
  emptyPanel?: boolean;
  isLoadingPanel?: boolean;
  isShowContentWhenIsLoading?: boolean;
  isWithoutPadding?: boolean;
  isLightBlueBackground?: boolean;
  customTabsItems?: NavListItem[];
  tabsPanelSlot?: ReactElement;
  titlePanelSlot?: ReactElement;
  controlPanelTitle?: string;
  controlPanelMaxCount?: number;
  controlPanelSelectedCount?: number;
  controlPanelSlot?: ReactElement;
  controlPanelStyleProps?: { noPadding?: boolean };
  backButton?: boolean;
  isExactWithQueryParams?: boolean;
  customContentClassName?: string;
  additionalPanelSlot?: ReactElement | null;
  children: ReactNode;
  isWhiteBackground?: boolean;
}

export enum Location {
  SESSIONS = "sessions",
  USERS_CONTROL = "users-control",
  SIMULATION_CONTROL = "simulation-control",
  ASSESSMENTS_CONTROL = "assessments-control",
  MAILING_LISTS = "mailing-lists",
  OTHER = "other",
  HISTORY = "history",
  MODERATION = "moderation",
  COMPLAINTS = "complaints",
}

export const PageWrapper: FC<Props> = ({
  title,
  tabsPanelSlot,
  titlePanelSlot,
  emptyPanel = false,
  isLoadingPanel = false,
  isShowContentWhenIsLoading = false,
  isWithoutPadding = false,
  isLightBlueBackground = false,
  children,
  controlPanelTitle,
  controlPanelMaxCount,
  controlPanelSelectedCount,
  controlPanelSlot,
  controlPanelStyleProps,
  backButton,
  customTabsItems,
  isExactWithQueryParams,
  additionalPanelSlot,
  customContentClassName = "",
  isWhiteBackground = false,
}): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const currentLocation: string = location.pathname.split("/")[1];

  const { isPlayerClientId, isHrClientId, isAdminClientId } = useClient();

  useSelector(selectProfileCompetenceItem);

  const { isHeaderShowed } = useUnit($appInterface);

  const isAccess = useUnit(appAccessModel.$isAccess);

  const isSessionAccess = useUnit($specificSessionPermission);

  const isVerificationSession = useUnit(appAccessModel.$isVerificationSession);

  const isFullProfile = useUnit(appAccessModel.$isFullProfile);

  const isSomeCompetenceFirstLvl = useUnit(
    appAccessModel.$isSomeCompetenceFirstLvl,
  );

  const profilePageTabLinksHrSessions: NavListItem[] = [
    {
      title: t("navigation.myProfile.mySessions.waitingStart"),
      to: "/sessions/planned",
      access: !!isAccess && isSessionAccess.has_permission,
    },
    {
      title: t("navigation.myProfile.mySessions.active"),
      to: "/sessions/active",
      access: !!isAccess && isSessionAccess.has_permission,
    },
    {
      title: t("navigation.myProfile.mySessions.finished"),
      to: `/sessions/archive`,
      access: !!isAccess,
    },
  ];

  const usersComplaintsPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.complaints"),
      to: "/complaints",
      access: true,
    },
  ];

  const usersControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.usersControl.members"),
      to: "/users-control/players",
      access: true,
    },
    {
      title: t("navigation.usersControl.hrs"),
      to: "/users-control/hrs",
      access: true,
    },
    {
      title: t("navigation.usersControl.admins"),
      to: "/users-control/admins",
      access: true,
    },
  ];

  const historyPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.moderation"),
      to: "/history/moderation",
      access: true,
    },
    {
      title: t("navigation.ratings"),
      to: "/history/ratings",
      access: true,
    },
    {
      title: t("navigation.users"),
      to: "/history/users",
      access: true,
    },
    {
      title: t("navigation.sessions"),
      to: "/history/sessions",
      access: true,
    },
  ];

  const moderationPageTabLinks: NavListItem[] = [
    {
      title: t("common.students"),
      to: "/moderation/students",
      access: true,
    },
    {
      title: t("common.relatives"),
      to: "/moderation/relatives",
      access: true,
    },
    {
      title: t("common.employees"),
      to: "/moderation/employees",
      access: true,
    },
  ];

  const simulationControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.simulationsControl.active"),
      to: "/simulation-control/active",
      access: true,
    },
    {
      title: t("navigation.simulationsControl.archive"),
      to: "/simulation-control/archive",
      access: false,
    },
  ];

  const assessmentsControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.assessmentsControl.created"),
      to: "/assessments-control/created",
      access: isSessionAccess.has_permission,
    },
    {
      title: t("navigation.assessmentsControl.active"),
      to: "/assessments-control/active",
      access: isSessionAccess.has_permission,
    },
    {
      title: t("navigation.assessmentsControl.finished"),
      to: "/assessments-control/finished",
      access: true,
    },
  ];

  const mailingListsControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.mailingLists.notifications"),
      to: "/mailing-lists/notifications",
      access: true,
    },
    {
      title: t("navigation.mailingLists.e-mail"),
      to: "/mailing-lists/e-mail",
      access: true,
    },
    {
      title: t("navigation.mailingLists.sms"),
      to: "/mailing-lists/sms",
      access: true,
    },
  ];

  const otherPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.other.positions"),
      to: "/other/edit-positions",
      access: false,
    },
    {
      title: t("navigation.other.faq"),
      to: "/other/edit-faq",
      access: true,
    },
    {
      title: t("navigation.other.contacts"),
      to: "/other/edit-contacts",
      access: true,
    },
    {
      title: t("navigation.other.information"),
      to: "/other/edit-information",
      access: true,
    },
    {
      title: t("navigation.other.countries"),
      to: "/other/countries",
      access: true,
    },
    {
      title: t("navigation.other.fines"),
      to: "/other/fines",
      access: true,
    },
  ];

  const secondLvlNavLinks = (): NavListItem[] => {
    const isSessionsCatalog: boolean = currentLocation === Location.SESSIONS;

    const isOtherCatalog: boolean = currentLocation === Location.OTHER;

    const isComplaintsCatalog: boolean =
      currentLocation === Location.COMPLAINTS;

    const isUsersControlCatalog: boolean =
      currentLocation === Location.USERS_CONTROL;

    const isSimulationControlCatalog: boolean =
      currentLocation === Location.SIMULATION_CONTROL;

    const isAssessmentsControlCatalog: boolean =
      currentLocation === Location.ASSESSMENTS_CONTROL;

    const isMailingListsControlCatalog: boolean =
      currentLocation === Location.MAILING_LISTS;

    const isHistoryCatalog: boolean = currentLocation === Location.HISTORY;

    const isModerationCatalog: boolean =
      currentLocation === Location.MODERATION;

    if (!!customTabsItems?.length) {
      return customTabsItems;
    }

    if (isSessionsCatalog && isHrClientId) {
      return profilePageTabLinksHrSessions;
    }

    if (isUsersControlCatalog && isAdminClientId) {
      return usersControlPageTabLinksAdmin;
    }

    if (isHistoryCatalog && isAdminClientId) {
      return historyPageTabLinksAdmin;
    }
    if (isModerationCatalog && isHrClientId) {
      return moderationPageTabLinks;
    }

    if (isSimulationControlCatalog && isAdminClientId) {
      return simulationControlPageTabLinksAdmin;
    }

    if (isAssessmentsControlCatalog && isAdminClientId) {
      return assessmentsControlPageTabLinksAdmin;
    }

    if (isMailingListsControlCatalog && isAdminClientId) {
      return mailingListsControlPageTabLinksAdmin;
    }

    if (isOtherCatalog && isAdminClientId) {
      return otherPageTabLinksAdmin;
    }

    if (isComplaintsCatalog && isAdminClientId) {
      return usersComplaintsPageTabLinksAdmin;
    }

    return [];
  };

  const baseAlertContent = (): string | undefined => {
    const localIsFullProfile: boolean = isPlayerClientId
      ? !!isSomeCompetenceFirstLvl && !isFullProfile
      : !isFullProfile;

    if (localIsFullProfile) {
      return t("profile.info.baseAlert.fillOutProfile");
    }

    if (isVerificationSession) {
      return t("profile.info.baseAlert.verificationSession");
    }
  };

  const isNavList: boolean = !!secondLvlNavLinks().length;

  useEffect(() => {
    if (isNavList) {
      const activeNavItem = document.querySelector(
        ".nav-list--in-header .nav-list__item--active",
      );

      activeNavItem?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "end",
      });
    }
  }, [isNavList]);

  const alertSlot = isFullProfile ? undefined : (
    <BaseButton to="/profile/info" noWrapContent small orange block>
      {t("profile.info.fillTheProfile")}
    </BaseButton>
  );

  return (
    <div
      className={classNames("page-wrapper", {
        "page-wrapper--white-bg": isWhiteBackground,
      })}
    >
      <div
        className={classNames("page-wrapper__container", {
          "page-wrapper__container--header-hidden": !isHeaderShowed,
        })}
      >
        {title && (
          <div
            className={classNames("page-wrapper__title-panel", {
              "page-wrapper__title-panel--empty-base-panel":
                emptyPanel || !isHeaderShowed,
            })}
          >
            <h2 className="page-wrapper__title">
              {backButton && (
                <button
                  className="page-wrapper__back"
                  aria-label={t("common.goToPrevPage")}
                  onClick={() => navigate(-1)}
                />
              )}
              {title}
            </h2>
            {!!titlePanelSlot && titlePanelSlot}
          </div>
        )}

        <BasePanel
          className={classNames("page-wrapper__base-panel", {
            "page-wrapper__base-panel--header-hidden": !isHeaderShowed,
          })}
          isEmpty={emptyPanel}
        >
          <NavList
            level={2}
            location={location}
            links={secondLvlNavLinks()}
            isExactWithQueryParams={isExactWithQueryParams}
            isReplace={backButton}
          />
          {tabsPanelSlot && <Fragment>{tabsPanelSlot}</Fragment>}
        </BasePanel>

        {(controlPanelTitle || controlPanelSlot) && (
          <ControlPanel
            title={controlPanelTitle}
            maxCount={controlPanelMaxCount || 0}
            selectedCount={controlPanelSelectedCount || 0}
            isLoading={isLoadingPanel}
            {...controlPanelStyleProps}
          >
            {controlPanelSlot}
          </ControlPanel>
        )}
        {additionalPanelSlot && (
          <div className="page-wrapper__additional-panel">
            {additionalPanelSlot}
          </div>
        )}
        {isLoadingPanel && <div className="page-wrapper__container-loader" />}
      </div>
      {baseAlertContent() && (
        <BaseAlert slot={alertSlot}>{baseAlertContent()}</BaseAlert>
      )}
      {(isShowContentWhenIsLoading || !isLoadingPanel) && (
        <div
          className={classNames("page-wrapper__content", {
            "page-wrapper__content--without-padding": isWithoutPadding,
            "page-wrapper__content--lightblue-background":
              isLightBlueBackground,
            [customContentClassName]: !!customContentClassName,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
