import classNames from "classnames";
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import { AdmAssessmentDto, AssessmentType, GameType } from "src/generated/game";

import {
  DataGrid,
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";

import {
  getActiveGroup,
  getOrderByValue,
  languagePicker,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  IDataGridSelectOption,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models";
import { SessionTitle } from "./components/session-title";
import { snackbarOpen } from "src/features/public/snackbar";

interface Props {
  assessmentItems: AdmAssessmentDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
  onSelectRows?: (ids: string[]) => void;
  selectedRowsIds: string[];
}

const { openPopup } = popupModel;

export const PlanedAssessmentsTable: FC<Props> = ({
  loading,
  assessmentItems,
  queryParams,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
  onSelectRows,
  selectedRowsIds,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const assessmentTypes = useMemo(() => Object.values(AssessmentType), []);

  const GameTypes = useMemo(() => Object.values(GameType), []);

  const assessmentTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return assessmentTypes.map((type) => ({
      value: type,
      label: t(`common.assessment.${type}`, type),
    }));
  }, [assessmentTypes, t]);

  const gameTypesOptions = useMemo<IDataGridSelectOption[]>(() => {
    return GameTypes.map((type) => ({
      value: type,
      label: t(`common.gameType.${type}`, type),
    }));
  }, [GameTypes, t]);

  const connecting = useCallback((rowId: string): void => {
    window.location.href = `/sessions/session/${rowId}`;
  }, []);

  const copySession = useCallback(
    (rowId: string): void => {
      const session = assessmentItems.find(({ id }) => id === rowId);

      if (Number(session?.copy_part_title) > 1) {
        snackbarOpen({
          visible: true,
          text: t("session.popup.copy.forbidden"),
          time: 3,
          type: "error",
          verticalCentered: true,
        });

        return;
      }

      openPopup({
        name: EPopupName.COPY_SESSION_POPUP,
        data: { id: rowId },
      });
    },
    [assessmentItems, t],
  );

  const openConfig = useCallback((rowId: string): void => {
    openPopup({
      name: EPopupName.ASSESSMENT_PARAMETERS,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
      someBooleanTrigger: false,
    });
  }, []);

  const generateColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: "SELECT",
        type: EDataGridColumnType.SELECT_ROW,
        key: "selected",
        showed: true,
        hiddenTitle: true,
        sticky: {
          mobile: {
            left: 0,
          },
          tablet: {
            left: 0,
          },
          laptop: {
            left: 0,
          },
        },
      },
      {
        title: t("table.header.session"),
        type: EDataGridColumnType.STRING,
        key: "sessionName",
        showed: true,
        filterTitle: t("table.header.session"),
        controlPanel: {
          title: t("table.header.session"),
          activeGroupKey: getActiveGroup(
            ["title"],
            { all: ["title"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "title",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.title || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "title",
                  "-title",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "title",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-title",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.simulation"),
        type: EDataGridColumnType.STRING,
        key: "simulationName",
        showed: true,
        controlPanel: {
          title: t("table.header.simulation"),
          activeGroupKey: getActiveGroup(
            ["gameTitle"],
            { all: ["gameTitle"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "gameTitle",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.gameTitle || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "gameTitle",
                  "-gameTitle",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "gameTitle",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-gameTitle",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.type"),
        type: EDataGridColumnType.STRING,
        key: "assessmentType",
        showed: true,
        filters: [
          {
            key: "assessmentType",
            type: EDataGridFilterType.CHECKBOX,
            placeholder: "Не выбрано",
            value: queryParams?.assessmentType || "",
            items: assessmentTypeOptions,
          },
        ],
        controlPanel: {
          title: t("table.header.type"),
          activeGroupKey: getActiveGroup(
            ["assessmentType"],
            { all: ["assessmentType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "assessmentType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: "Не выбрано",
                  value: queryParams?.assessmentType || "",
                  items: assessmentTypeOptions,
                },
              ],
            },
          ],
        },
      },
      {
        key: "closedSession",
        showed: true,
        titleUppercase: true,
        title: t("table.header.access"),
        type: EDataGridColumnType.STRING,
        filterPosition: EDataGridFilterPosition.LEFT,
        controlPanel: {
          title: t("table.header.access"),
          activeGroupKey: getActiveGroup(
            ["closedSession"],
            { all: ["closedSession"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "closedSession",
                  type: EDataGridFilterType.CHECKBOX,
                  onlyOneActiveOption: true,
                  value: queryParams?.closedSession || "",
                  items: [
                    {
                      value: "false",
                      label: t("table.assessment.false"),
                    },
                    {
                      value: "true",
                      label: t("table.assessment.true"),
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.mode"),
        type: EDataGridColumnType.STRING,
        key: "gameType",
        showed: true,
        controlPanel: {
          title: t("table.header.mode"),
          activeGroupKey: getActiveGroup(
            ["gameType"],
            { all: ["gameType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "gameType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: "Не выбрано",
                  value: queryParams?.gameType || "",
                  items: gameTypesOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.members.short"),
        type: EDataGridColumnType.STRING,
        key: "players",
        showed: true,
        controlPanel: {
          title: t("table.header.members"),
          activeGroupKey: getActiveGroup(
            ["curPlayers", "curPlayersFrom", "curPlayersTo"],
            { all: ["curPlayers", "curPlayersFrom", "curPlayersTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "curPlayersFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.curPlayersFrom || "",
                  isHalf: true,
                },
                {
                  key: "curPlayersTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.curPlayersTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "curPlayers",
                  "-curPlayers",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "curPlayers",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-curPlayers",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("common.members"),
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "connectButtonTitle",
        showed: true,
        noPaddingRight: true,
        buttonProps: {
          primary: true,
          xSmall: true,
          xSmallRounded: true,
          disabled: false,
          onClick(rowId: string) {
            connecting(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 59,
          },
          laptop: {
            right: 79,
          },
        },
      },
      {
        title: t("popup.session.copy.button"),
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "assessmentCopyButtonTitle",
        showed: true,
        noPaddingRight: true,
        buttonProps: {
          primary: false,
          xSmall: true,
          xSmallRounded: true,
          disabled: false,
          borderBlue: true,
          onClick(rowId: string) {
            copySession(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 39,
          },
          laptop: {
            right: 59,
          },
        },
      },
      {
        title: t("table.header.settings"),
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "settingsButtonTitle",
        showed: true,
        buttonProps: {
          small: true,
          hideLabel: true,
          transparent: true,
          icon: "props-gray",
          ariaLabel: t("buttons.sessionSettings"),
          noPaddingX: true,
          onClick(rowId) {
            openConfig(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 0,
            width: 59,
          },
          laptop: {
            right: 0,
          },
        },
      },
    ],
    [
      t,
      queryParams,
      assessmentTypeOptions,
      gameTypesOptions,
      connecting,
      openConfig,
      copySession,
    ],
  );

  useEffect(() => {
    const rowsItems: IDataGridRow[] = assessmentItems.map(
      (item): IDataGridRow => ({
        id: item.id,
        selected: false,
        sessionName: <SessionTitle assessment={item} />,
        simulationName: languagePicker(item.gameTitle, i18n.language),
        assessmentType: t(
          `common.assessment.${item.assessmentType}`,
          item.assessmentType,
        ),
        assessmentStatus: item.assessmentStatus,
        gameType: t(`common.gameType.${item.gameType}`, item.gameType),
        players: `${item.curPlayers}/${item.maxPlayers}`,
        closedSession: t(`table.assessment.${item.closedSession}`),
        connectButtonTitle: t("common.members"),
        settingsButtonTitle: t("table.header.settings"),
        assessmentCopyButtonTitle: t("popup.session.copy.button"),
      }),
    );

    setRows(rowsItems);
  }, [assessmentItems, i18n.language, t]);

  useEffect(() => {
    setColumns(generateColumns);
  }, [generateColumns]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"active-assessment-table"}
      queryParams={queryParams}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
      selectedRowsIds={selectedRowsIds}
      onUpdateSelectedRows={onSelectRows}
    />
  );
};
