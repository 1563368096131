import { FC, ReactNode, useEffect } from "react";
import { ConnectDragSource, DragSourceMonitor, useDrag } from "react-dnd";
import { DragItem } from "../../constants/dragEnums";
import { getEmptyImage } from "react-dnd-html5-backend";

interface DraggableItemProps {
  type: DragItem;
  data: any;
  canDrag?: boolean;
  element: (isDragging: boolean, dragRef: ConnectDragSource) => ReactNode;
}

export const DraggableItem: FC<DraggableItemProps> = ({
  type = DragItem.PLAYER,
  data,
  canDrag = true,
  element,
}) => {
  const [{ isDragging }, dragRef, dragPreview] = useDrag({
    type: type,
    item: data,
    canDrag: canDrag,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (isDragging) {
      document.body.classList.add("dragging");
    } else {
      document.body.classList.remove("dragging");
    }
  }, [isDragging]);

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, []);

  return element(isDragging, dragRef);
};
