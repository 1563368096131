import { ELanguage, languagePicker, t } from "src/shared/helpers";

import { IDataGridImage } from "src/shared/models/dataGrid";

import { Country } from "../../../../shared/api/admin/countries";

export const countriesToRowsAdapter = (
  countries: Country[],
  language: ELanguage,
) => {
  return countries.map((country) => {
    const flag: IDataGridImage = {
      url: `https://storage.yandexcloud.net/flag.cdn.hr.alabuga.ru/${country.code.toLocaleLowerCase()}.svg`,
      width: 30,
      height: 20,
    };

    return {
      id: country.code,
      selected: false,
      code: country.code,
      title: languagePicker(country.title, language),
      regEnabled: {
        checked: country.regEnabled,
        label: t(`countriesPage.regEnable`),
      },
      flag,
    };
  });
};
