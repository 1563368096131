import { EDataGridTextColor } from "../../models";

export const getGridCommonColor = (
  value: Record<string, number>[],
): EDataGridTextColor => {
  const currentCompetency: number[] = [];
  value?.forEach((competency) => {
    for (const key in competency) {
      currentCompetency.push(competency[key]);
    }
  });

  const hasNegative = currentCompetency.some(
    (currentElement) => currentElement < 0,
  );

  return hasNegative ? EDataGridTextColor.RED : EDataGridTextColor.GREEN;
};
