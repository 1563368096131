import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "src/shared/components/index";

import useClient from "src/shared/hooks/useClient";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { daysForms, selectForm } from "../../../hooks/useSendingTime";
import { PlayerFineHistoryDto } from "../../../../generated/social";
import { MainCompetence } from "../../../../pages/other/fines-page/ui/fines-popover/types/types";
import { ELanguage } from "../../../store/types";

interface Props {
  historyItems: PlayerFineHistoryDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const FinesHistory: FC<Props> = ({
  loading,
  historyItems,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isPlayerClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const competenceTypeItems = () => {
    return Object.values(MainCompetence).map((type) => ({
      value: type,
      label: t(`common.competence.${type}`, type),
    }));
  };

  const currentFinesLanguage = (item: PlayerFineHistoryDto) => {
    return i18n.language === ELanguage.RU
      ? selectForm(Number(item.period), daysForms)
      : "days";
  };

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullName",
        showed: !isPlayerClientId,
        titleUppercase: true,
        title: t("table.header.fineCreator"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.fineCreator"),
          activeGroupKey: getActiveGroup(
            ["firstnameCreator", "lastnameCreator"],
            { all: ["firstnameCreator", "lastnameCreator"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstnameCreator",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstnameCreator || "",
                },
                {
                  key: "lastnameCreator",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastnameCreator || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstname_creator",
                  "-firstname_creator",
                  "lastname_creator",
                  "-lastname_creator",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstname_creator",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstname_creator",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastname_creator",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastname_creator",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "date",
        showed: true,
        titleUppercase: true,
        title: t("table.header.fineDate"),
        type: EDataGridColumnType.DATETIME,
        controlPanel: {
          title: t("table.header.fineDate"),
          activeGroupKey: getActiveGroup(
            ["date_of_issue", "date_of_issue_from", "date_of_issue_to"],
            {
              all: ["date_of_issue", "date_of_issue_from", "date_of_issue_to"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "date_of_issue_from",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.date_of_issue_from || "",
                  isHalf: true,
                },
                {
                  key: "date_of_issue_to",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.date_of_issue_to || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "dateOfCreate",
                  "-dateOfCreate",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "date_of_issue",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-date_of_issue",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "competence",
        showed: true,
        titleUppercase: true,
        title: t("table.header.competenceName"),
        type: EDataGridColumnType.STRING,
        controlPanel: {
          title: t("table.header.competenceName"),
          activeGroupKey: getActiveGroup(
            ["competenceName"],
            { all: ["competenceName"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "competence",
                  type: EDataGridFilterType.CHECKBOX,
                  items: competenceTypeItems(),
                  value: queryParams?.competence || "",
                },
              ],
            },
          ],
        },
      },
      {
        key: "removable_rating",
        showed: true,
        titleUppercase: true,
        title: t("table.header.ratingDowngrade"),
        type: EDataGridColumnType.STRING,
        controlPanel: {
          title: t("table.header.ratingDowngrade"),
          activeGroupKey: getActiveGroup(
            ["removableRating", "removableRatingFrom", "removableRatingTo"],
            {
              all: [
                "removableRating",
                "removableRatingFrom",
                "removableRatingTo",
              ],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "removableRatingFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.removableRatingFrom || "",
                  isHalf: true,
                },
                {
                  key: "removableRatingTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.removableRatingTo || "",
                  isHalf: true,
                },
              ],
            },
          ],
        },
      },
      {
        key: "period",
        showed: true,
        titleUppercase: true,
        title: t("table.header.finePeriod"),
        type: EDataGridColumnType.STRING,
        filterPosition: EDataGridFilterPosition.LEFT,
        controlPanel: {
          title: t("table.header.finePeriod"),
          activeGroupKey: getActiveGroup(
            ["period", "periodFrom", "periodTo"],
            { all: ["period", "periodFrom", "periodTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "periodFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.periodFrom || "",
                  isHalf: true,
                },
                {
                  key: "periodTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.periodTo || "",
                  isHalf: true,
                },
              ],
            },
          ],
        },
      },
    ];

    return newColumns;
  }, [isPlayerClientId, queryParams]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = historyItems.map((item): IDataGridRow => {
      const fullName = splitStrings([
        item.firstname_creator,
        item.lastname_creator,
      ]);
      return {
        id: item.fine_id,
        date: item.date_of_issue,
        selected: false,
        fullName,
        avatarProps: {
          alt: fullName,
          fullName,
          customPath: getAvatarUrl(item.creator_id),
          userId: item.creator_id,
        },
        competence: t(`common.competence.${item.competence}`),
        period: `${item.period} ${currentFinesLanguage(item)}`,
        removable_rating: item.removable_rating,
      };
    });

    setRows(rowsItems);
  }, [historyItems, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"session-history-table"}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
