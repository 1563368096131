import { FC, ReactElement, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Competence } from "src/generated/statistic";

import { getSimulationRole } from "src/shared/helpers/getSimulationRoles";

import { IGameType } from "src/shared/store/types";

import { IDislikeItem } from "../../model/types";

import { DislikeItem } from "../dislike-item/dislike-item";

import "./dislike-list.scss";

interface Props {
  dislikesSum?: string;
  dislikes: IDislikeItem[];
  competence: Competence;
  gameType: IGameType;
  isWithNames: boolean;
}

export const DislikeList: FC<Props> = ({
  dislikesSum,
  dislikes,
  competence,
  gameType,
  isWithNames,
}): ReactElement => {
  const { t, i18n } = useTranslation();

  const getRole = (dislike: IDislikeItem) =>
    getSimulationRole({
      teamRoleKey: dislike.role,
      roleKey: dislike.teamName || "",
      lang: i18n.language,
      gameType: gameType,
    }).teamRole;

  const getDislikeLabel = (dislike: IDislikeItem): string => {
    const playerName: string = isWithNames
      ? ` (${dislike.firstname} ${dislike.lastname})`
      : "";

    return `${getRole(dislike)}${playerName}`;
  };

  const filteredDislikes = useMemo(
    () =>
      dislikes.filter((dislike) =>
        dislike.diffs.find(
          ({ competence: diffCompetence }) => diffCompetence === competence,
        ),
      ),
    [dislikes, competence],
  );

  return (
    <div className="dislike-list">
      {dislikesSum && (
        <DislikeItem
          className="dislike-list__header"
          title={t(
            "assessmentsControl.popup.assessmentResults.tooltip.dislikes",
          )}
          description={dislikesSum}
          isBoldTitle
        />
      )}
      <div className="dislike-list__body">
        {filteredDislikes.map((dislike, index) => {
          const dislikeValue: number | undefined = dislike.diffs.find(
            ({ competence: diffsCompetence }) => diffsCompetence === competence,
          )?.diff;

          return (
            <DislikeItem
              key={index}
              className="dislike-list__item"
              title={getDislikeLabel(dislike)}
              description={dislikeValue || 0}
            />
          );
        })}
      </div>
    </div>
  );
};
