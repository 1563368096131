import {
  ISimulationTemplate,
  SimulationAdminInterface,
} from "../../../../shared/store/types";
import { GameType } from "../../../../generated/game";
import { TMaxCount } from "./types";
import { v4 as generateUniqId } from "uuid";
import { getSimulationParamsFieldItem } from "../../../../shared/helpers";

export const composeTemplateData = (
  simulation: SimulationAdminInterface,
  setCloneTemplateData: (payload: ISimulationTemplate[]) => void,
) => {
  let localTemplates: ISimulationTemplate[] = [];

  simulation.paramsTemplates?.forEach((template) => {
    if (template.gameType) {
      const isTeamGameType: boolean =
        template.gameType === GameType.Team ||
        template.gameType === GameType.Corp;

      const maxPlayersByRole: TMaxCount = {};

      if (template.maxPlayersByRole && !isTeamGameType) {
        Object.keys(template.maxPlayersByRole).forEach((key) => {
          maxPlayersByRole[key] = String(template.maxPlayersByRole?.[key]);
        });
      }

      const maxTeamsByName: TMaxCount = {};

      if (template.maxTeamsByName && isTeamGameType) {
        Object.keys(template.maxTeamsByName).forEach((key) => {
          maxTeamsByName[key] = String(template.maxTeamsByName?.[key].maxTeams);
        });
      }
      const paramsTemplate = {
        ...simulation.rawMetaJson.gameTypes[template.gameType].params,
        ...simulation.rawMetaJson.params,
      };

      localTemplates.push({
        id: template.id || generateUniqId(),
        title: template.title,
        gameType: template.gameType,
        minRate: template.minRate ? String(template.minRate) : undefined,
        maxRate: template.maxRate ? String(template.maxRate) : undefined,
        maxPlayersByRole,
        maxTeamsByName,
        params: getSimulationParamsFieldItem(
          paramsTemplate,
          false,
          template.params,
        ),
        maxAreas: template.maxAreas,
        isDefault: template.is_default,
      });
    }
  });

  setCloneTemplateData(localTemplates);

  return localTemplates;
};
