import { FC } from "react";

import { BasePopover, BasePopup, EPopupName } from "src/shared/components";

import { useTranslation } from "react-i18next";

import "./fines-popover.scss";
import { useMediaQuery } from "src/shared/hooks/useMediaQuery";
import { FinesPopoverContent } from "../fines-popover-content";

interface BasePopoverProps {
  isOpened: boolean;
  fineItems?: string[];
  clearSelectedFines: () => void;
  isEdit?: boolean;
  onClose: () => void;
}

export const FinesPopover: FC<BasePopoverProps> = (props: BasePopoverProps) => {
  const { isOpened, isEdit, clearSelectedFines, fineItems, onClose } = props;

  const { t } = useTranslation();

  const { isMobile } = useMediaQuery();

  const title = isEdit ? t("fine.editFine") : t("fine.addFine");

  return !isMobile ? (
    <BasePopover
      title={title}
      className="fines-popover"
      isOpened={isOpened}
      onClose={onClose}
    >
      <FinesPopoverContent
        fineItems={fineItems}
        clearSelectedFines={clearSelectedFines}
        isEdit={isEdit}
        onClose={onClose}
      />
    </BasePopover>
  ) : isEdit ? (
    <BasePopup
      onClose={onClose}
      withoutScroll
      title={title}
      name={EPopupName.EDIT_FINES_POPUP}
      bgType="black"
    >
      <FinesPopoverContent
        clearSelectedFines={clearSelectedFines}
        fineItems={fineItems}
        isEdit={isEdit}
        onClose={onClose}
      />
    </BasePopup>
  ) : (
    <BasePopup
      onClose={onClose}
      withoutScroll
      title={title}
      name={EPopupName.ADD_FINES_POPUP}
      bgType="black"
    >
      <FinesPopoverContent
        clearSelectedFines={clearSelectedFines}
        onClose={onClose}
      />
    </BasePopup>
  );
};
