import { sample } from "effector";

import { $simulations } from "src/entities/admin/simulations/model";

import {
  deleteSimulation,
  DeleteSimulationParams,
} from "src/shared/api/admin/simulations";

import { simulationsAdminFeaturesDomain } from "./domain";

// Stores

export const $isLoadingDelete =
  simulationsAdminFeaturesDomain.createStore<boolean>(false);

// Events

export const fetchDeleteSimulation =
  simulationsAdminFeaturesDomain.createEvent<DeleteSimulationParams>();

// Effects

export const fetchDeleteSimulationFx =
  simulationsAdminFeaturesDomain.createEffect(deleteSimulation);

// Logic

sample({ clock: fetchDeleteSimulation, target: fetchDeleteSimulationFx });

sample({
  clock: fetchDeleteSimulationFx.pending,
  target: $isLoadingDelete,
});

sample({
  clock: fetchDeleteSimulationFx.doneData,
  source: $simulations,
  fn: (prevSimulations, { game }) =>
    prevSimulations?.filter(({ id }) => id !== game.id) || [],
  target: $simulations,
});
