import { combine, sample } from "effector";

import { keycloakModel } from "src/entities/public/keycloak";

import { getIsFullProfileWithoutEmailVerified } from "../lib/getIsFullProfileWithoutEmailVerified";

import { $districts, $regions, $schools } from "./dictionaries";

import { appAccessDomain } from "./domain";

import { $emailVerified, $profile } from "./profile";

import { $dictionariesReady } from "./dictionaries-ready";

const { $isPlayerClientId, $isHrClientId } = keycloakModel;

// Stores

export const $isFullProfileWithoutEmailVerified = appAccessDomain.createStore<
  boolean | null
>(null, { name: "isFullProfileWithoutEmailVerified" });

export const $isFullProfile = appAccessDomain.createStore<boolean | null>(
  null,
  {
    name: "isFullProfile",
  },
);

// Logic

sample({
  clock: combine(
    $profile,
    $isHrClientId,
    $isPlayerClientId,
    $dictionariesReady,
    $regions,
    $districts,
    $schools,
  ),
  fn: (params) => getIsFullProfileWithoutEmailVerified(...params),
  target: $isFullProfileWithoutEmailVerified,
});

sample({
  clock: combine($isFullProfileWithoutEmailVerified, $emailVerified),
  fn: ([isFullProfileWithoutEmailVerified, emailVerified]) => {
    return isFullProfileWithoutEmailVerified !== null && emailVerified !== null
      ? isFullProfileWithoutEmailVerified && emailVerified
      : null;
  },
  target: $isFullProfile,
});
