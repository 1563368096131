import React, { FC, FocusEvent, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { finesModel } from "src/entities/admin/fines";

import "./ui/fines-popover.scss";
import {
  FINE_VALIDATION,
  FinesFormData,
  FinesPopoverContentProps,
  initialData,
  MainCompetence,
} from "./types/types";
import useInput, { EInputValidateType } from "src/shared/hooks/useInput";
import { useUnit } from "effector-react";
import { $fines } from "src/entities/admin/fines/model";
import { daysForms, selectForm } from "src/shared/hooks/useSendingTime";
import { ERegEx, regExList } from "src/shared/constants/regExList";
import {
  closeAllPopup,
  closePopup,
  openPopup,
} from "src/shared/components/base-popup/model";
import {
  BaseButton,
  BaseInputNumber,
  BaseInputText,
  EPopupName,
  FormControl,
} from "src/shared/components";
import { snackbarOpen } from "src/features/public/snackbar";
import { IBaseInputChange } from "src/shared/models";
import { toCloneObject } from "src/shared/helpers";
import { $keycloak } from "../../../../../entities/public/keycloak/model";

export const FinesPopoverContent: FC<FinesPopoverContentProps> = (
  props: FinesPopoverContentProps,
) => {
  const { isEdit, fineItems, clearSelectedFines, onClose } = props;

  const { t } = useTranslation();

  const { validateValue } = useInput();

  const keycloak = useUnit($keycloak);

  const fines = useUnit($fines);

  const [finesFormData, setFinesFormData] = useState<FinesFormData>(
    isEdit ? {} : initialData,
  );

  const [initialFinesFormData, setInitialFinesFormData] =
    useState<FinesFormData>({});

  const [formDataErrors, setFormDataErrors] = useState<Record<string, any>>({});

  useEffect(() => {
    const setupInitialData = () => {
      fineItems?.forEach((fine) => {
        const fineItem = fines.find((prev) => prev.fine_id === fine);

        if (fineItem) {
          const finesData = {
            pid: String(keycloak?.subject),
            period: `${fineItem.period} ${selectForm(
              Number(fineItem.period),
              daysForms,
            )}`,
            removable_rating: String(fineItem.removable_rating),
          };

          setFinesFormData((prevState) => ({
            ...prevState,
            [fineItem.competence]: finesData,
          }));

          setInitialFinesFormData((prevState) => ({
            ...prevState,
            [fineItem.competence]: finesData,
          }));
        }
      });
    };
    setupInitialData();
  }, [fineItems, fines, keycloak?.subject]);

  const validFines = Object.fromEntries(
    Object.entries(finesFormData)
      .filter(([key]) => {
        return (
          Boolean(
            Number(
              finesFormData[key].period.replace(
                regExList[ERegEx.ONLY_NUMBERS],
                "",
              ),
            ),
          ) && Boolean(Number(finesFormData[key].removable_rating))
        );
      })
      .map(([key, data]) => {
        return [
          key,
          {
            pid: String(keycloak?.subject),
            removable_rating: data.removable_rating,
            period: data.period.replace(regExList[ERegEx.ONLY_NUMBERS], ""),
          },
        ];
      }),
  );
  let finesToEdit: string[] = [];

  fineItems?.forEach((selectedId) => {
    const selectedFines = fines.filter((fine) => fine.fine_id === selectedId);
    selectedFines.map((selectedFine) =>
      finesToEdit.push(selectedFine.competence),
    );
  });

  const items = isEdit ? finesToEdit : Object.values(MainCompetence);

  const getModifiedFines = (
    currentFines: FinesFormData,
    initialFines: FinesFormData,
  ): FinesFormData => {
    return Object.entries(currentFines).reduce<FinesFormData>(
      (modified, [key, { pid, period, removable_rating }]) => {
        const initial = initialFines[key];
        const periodCleaned = period.replace(
          regExList[ERegEx.ONLY_NUMBERS],
          "",
        );
        const initialPeriodCleaned = initial.period.replace(
          regExList[ERegEx.ONLY_NUMBERS],
          "",
        );

        if (
          !initial ||
          periodCleaned !== initialPeriodCleaned ||
          removable_rating !== initial.removable_rating
        ) {
          modified[key] = { pid, period: periodCleaned, removable_rating };
        }

        return modified;
      },
      {},
    );
  };

  const onConfirm = () => {
    const formErrors = items.map((competence) => {
      return {
        [competence]: validateValue({
          removable_rating: {
            value: finesFormData[competence].removable_rating,
            type: EInputValidateType.NUMBER,
            required: !!finesFormData[competence].period.length,
            min: FINE_VALIDATION.removable_rating.min,
            max: FINE_VALIDATION.removable_rating.max,
          },
          period: {
            value: finesFormData[competence].period.replace(
              regExList[ERegEx.ONLY_NUMBERS],
              "",
            ),
            type: EInputValidateType.NUMBER,
            required: !!finesFormData[competence].removable_rating.length,
            min: FINE_VALIDATION.period.min,
            max: FINE_VALIDATION.period.max,
          },
        }),
      };
    });

    setFormDataErrors(formErrors);

    const errorsWithoutEmpty = formErrors.filter(
      (value) => JSON.stringify(Object.values(value)) !== "[{}]",
    );

    if (!Object.keys(errorsWithoutEmpty).length) {
      onClose();
      if (!isEdit) {
        closePopup({ name: EPopupName.ADD_FINES_POPUP });
        openPopup({
          name: EPopupName.ADD_ANOTHER_FINES_POPUP,
          data: { validFines: validFines },
        });
        clearSelectedFines();
      } else {
        const modifiedFines = getModifiedFines(
          finesFormData,
          initialFinesFormData,
        );
        finesModel.postFines({
          fines: { fines: modifiedFines },
        });
        closePopup({ name: EPopupName.EDIT_FINES_POPUP });

        snackbarOpen({
          text: t("common.changesSaved"),
          type: "success",
          visible: true,
        });
        clearSelectedFines();
      }
    }
  };

  const onChangeFormData = (value: IBaseInputChange, competence: string) => {
    setFinesFormData((prevState) => {
      const localPrev = toCloneObject(prevState);
      const fieldName = value.name as keyof FinesFormData;

      return {
        ...localPrev,
        [competence]: {
          ...localPrev[competence],
          [fieldName]: value.value,
        },
      };
    });
  };

  const finesBeenEdited =
    JSON.stringify(finesFormData) === JSON.stringify(initialFinesFormData);

  const disabledConfirmButton = isEdit
    ? finesBeenEdited
    : Object.keys(validFines).length === 0;

  const applyButtonTitle = isEdit
    ? t("table.filters.btn.apply")
    : t("common.add");

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    // Убираем слово "дней" при фокусе, чтобы можно было редактировать число
    event.target.value = event.target.value.replace(
      regExList[ERegEx.ONLY_NUMBERS],
      "",
    );
  };
  const handleBlur = (
    event: FocusEvent<HTMLInputElement>,
    competence: string,
  ) => {
    setFinesFormData((prevState) => {
      const localPrev = toCloneObject(prevState);
      const fieldName = event.target.name as keyof FinesFormData;

      return {
        ...localPrev,
        [competence]: {
          ...localPrev[competence],
          [fieldName]:
            event.target.value &&
            `${event.target.value} ${selectForm(
              Number(event.target.value),
              daysForms,
            )}`,
        },
      };
    });
  };

  return (
    <>
      <div className="fines-popover-content">
        {items?.map((competence, index) => {
          return (
            <div className={`fines-popover-input--${competence}`}>
              <div className="fines-popover-input-title">
                {t(`profile.competence.title.${competence}`)}
              </div>
              <FormControl
                marginBottom="smallest"
                error={formDataErrors[index]?.[competence].removable_rating}
              >
                <BaseInputNumber
                  isSmall
                  forDarkBg
                  error={!!formDataErrors[index]?.[competence].removable_rating}
                  value={finesFormData[competence]?.removable_rating || ""}
                  placeholder={t("fine.removableRating")}
                  name="removable_rating"
                  onChange={(value) => onChangeFormData(value, competence)}
                />
              </FormControl>
              <FormControl
                marginBottom="smallest"
                error={formDataErrors[index]?.[competence].period}
              >
                <BaseInputText
                  isSmall
                  forDarkBg
                  onBlur={(event) => handleBlur(event, competence)}
                  onFocus={handleFocus}
                  error={!!formDataErrors[index]?.[competence].period}
                  value={finesFormData[competence]?.period}
                  placeholder={t("fine.periodicity")}
                  name="period"
                  onChange={(value) => onChangeFormData(value, competence)}
                />
              </FormControl>
            </div>
          );
        })}
      </div>
      <div className="fines-popover-buttons">
        <BaseButton
          onClick={onConfirm}
          white
          disabled={disabledConfirmButton}
          bold
          className="fines-popover-button"
        >
          {applyButtonTitle}
        </BaseButton>
        <BaseButton
          onClick={() => {
            onClose();
            closeAllPopup();
          }}
          transparentWhite
          bold
          className="data-grid__filters-button"
        >
          {t("common.cancel")}
        </BaseButton>
      </div>
    </>
  );
};
