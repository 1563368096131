import { Action } from "redux";
import {
  AvatarApiDeleteAvatarAvatarsIdDeleteRequest,
  AvatarApiUploadAvatarAvatarsIdPostRequest,
  ContactApiDeleteContactContactsIdDeleteRequest,
  ContactApiUploadContactContactsPostRequest,
  InfoItem,
  ResumeApiDeleteResumeResumesIdDeleteRequest,
  ResumeApiGetResumeResumesIdGetRequest,
  ResumeApiUploadResumeResumesIdPostRequest,
} from "../../../../generated/cdn";

export enum ECdnActions {
  /*
   * ECdnActions CONTACT AVATAR
   */
  CLEAN_ADD_CONTACT_AVATAR = "cdn/CLEAN_ADD_CONTACT_AVATAR",
  REQUEST_ADD_CONTACT_AVATAR = "cdn/REQUEST_ADD_CONTACT_AVATAR",
  SUCCESS_ADD_CONTACT_AVATAR = "cdn/SUCCESS_ADD_CONTACT_AVATAR",
  FAILURE_ADD_CONTACT_AVATAR = "cdn/FAILURE_ADD_CONTACT_AVATAR",
  CLEAN_DELETE_CONTACT_AVATAR = "cdn/CLEAN_DELETE_CONTACT_AVATAR",
  REQUEST_DELETE_CONTACT_AVATAR = "cdn/REQUEST_DELETE_CONTACT_AVATAR",
  SUCCESS_DELETE_CONTACT_AVATAR = "cdn/SUCCESS_DELETE_CONTACT_AVATAR",
  FAILURE_DELETE_CONTACT_AVATAR = "cdn/FAILURE_DELETE_CONTACT_AVATAR",
  CLEAN_OPTIONS_CONTACT_AVATAR = "cdn/CLEAN_OPTIONS_CONTACT_AVATAR",
  REQUEST_OPTIONS_CONTACT_AVATAR = "cdn/REQUEST_OPTIONS_CONTACT_AVATAR",
  SUCCESS_OPTIONS_CONTACT_AVATAR = "cdn/SUCCESS_OPTIONS_CONTACT_AVATAR",
  FAILURE_OPTIONS_CONTACT_AVATAR = "cdn/FAILURE_OPTIONS_CONTACT_AVATAR",

  /*
   * ECdnActions RESUME
   */
  CLEAN_ADD_RESUME = "cdn/CLEAN_ADD_RESUME",
  REQUEST_ADD_RESUME = "cdn/REQUEST_ADD_RESUME",
  SUCCESS_ADD_RESUME = "cdn/SUCCESS_ADD_RESUME",
  FAILURE_ADD_RESUME = "cdn/FAILURE_ADD_RESUME",
  CLEAN_GET_RESUME = "cdn/CLEAN_GET_RESUME",
  REQUEST_GET_RESUME = "cdn/REQUEST_GET_RESUME",
  SUCCESS_GET_RESUME = "cdn/SUCCESS_GET_RESUME",
  FAILURE_GET_RESUME = "cdn/FAILURE_GET_RESUME",
  CLEAN_DELETE_RESUME = "cdn/CLEAN_DELETE_RESUME",
  REQUEST_DELETE_RESUME = "cdn/REQUEST_DELETE_RESUME",
  SUCCESS_DELETE_RESUME = "cdn/SUCCESS_DELETE_RESUME",
  FAILURE_DELETE_RESUME = "cdn/FAILURE_DELETE_RESUME",
  CLEAN_OPTIONS_RESUME = "cdn/CLEAN_OPTIONS_RESUME",
  REQUEST_OPTIONS_RESUME = "cdn/REQUEST_OPTIONS_RESUME",
  SUCCESS_OPTIONS_RESUME = "cdn/SUCCESS_OPTIONS_RESUME",
  FAILURE_OPTIONS_RESUME = "cdn/FAILURE_OPTIONS_RESUME",

  /*
   * ECdnActions USER AVATAR
   */
  CLEAN_ADD_USER_AVATAR = "cdn/CLEAN_ADD_USER_AVATAR",
  REQUEST_ADD_USER_AVATAR = "cdn/REQUEST_ADD_USER_AVATAR",
  SUCCESS_ADD_USER_AVATAR = "cdn/SUCCESS_ADD_USER_AVATAR",
  FAILURE_ADD_USER_AVATAR = "cdn/FAILURE_ADD_USER_AVATAR",
  CLEAN_DELETE_USER_AVATAR = "cdn/CLEAN_DELETE_USER_AVATAR",
  REQUEST_DELETE_USER_AVATAR = "cdn/REQUEST_DELETE_USER_AVATAR",
  SUCCESS_DELETE_USER_AVATAR = "cdn/SUCCESS_DELETE_USER_AVATAR",
  FAILURE_DELETE_USER_AVATAR = "cdn/FAILURE_DELETE_USER_AVATAR",
  CLEAN_OPTIONS_USER_AVATAR = "cdn/CLEAN_OPTIONS_USER_AVATAR",
  REQUEST_OPTIONS_USER_AVATAR = "cdn/REQUEST_OPTIONS_USER_AVATAR",
  SUCCESS_OPTIONS_USER_AVATAR = "cdn/SUCCESS_OPTIONS_USER_AVATAR",
  FAILURE_OPTIONS_USER_AVATAR = "cdn/FAILURE_OPTIONS_USER_AVATAR",
}

/*
 * Actions CONTACT AVATAR
 */
export interface ICleanAddContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_ADD_CONTACT_AVATAR;
}

export interface IRequestAddContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_ADD_CONTACT_AVATAR;
  payload: {
    callback?: (fileName: string) => void;
    options: ContactApiUploadContactContactsPostRequest;
  };
}

export interface ISuccessAddContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_ADD_CONTACT_AVATAR;
}

export interface IFailureAddContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_ADD_CONTACT_AVATAR;
}

export interface ICleanDeleteContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_DELETE_CONTACT_AVATAR;
}

export interface IRequestDeleteContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_DELETE_CONTACT_AVATAR;
  payload: {
    callback?: () => void;
    options: ContactApiDeleteContactContactsIdDeleteRequest;
  };
}

export interface ISuccessDeleteContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_DELETE_CONTACT_AVATAR;
}

export interface IFailureDeleteContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_DELETE_CONTACT_AVATAR;
}

export interface ICleanOptionsContactAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_OPTIONS_CONTACT_AVATAR;
}

export interface IRequestOptionsContactAvatarAction
  extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_OPTIONS_CONTACT_AVATAR;
  payload?: {
    callback?: () => void;
  };
}

export interface ISuccessOptionsContactAvatarAction
  extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_OPTIONS_CONTACT_AVATAR;
  payload: {
    options: InfoItem;
  };
}

export interface IFailureOptionsContactAvatarAction
  extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_OPTIONS_CONTACT_AVATAR;
}

/*
 * Actions RESUME
 */
export interface ICleanAddResumeAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_ADD_RESUME;
}

export interface IRequestAddResumeAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_ADD_RESUME;
  payload: {
    callback?: () => void;
    options: ResumeApiUploadResumeResumesIdPostRequest;
  };
}

export interface ISuccessAddResumeAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_ADD_RESUME;
}

export interface IFailureAddResumeAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_ADD_RESUME;
}

export interface ICleanGetResumeAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_GET_RESUME;
}

export interface IRequestGetResumeAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_GET_RESUME;
  payload: {
    callback?: () => void;
    options: ResumeApiGetResumeResumesIdGetRequest;
    fileName: string;
  };
}

export interface ISuccessGetResumeAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_GET_RESUME;
}

export interface IFailureGetResumeAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_GET_RESUME;
}

export interface ICleanDeleteResumeAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_DELETE_RESUME;
}

export interface IRequestDeleteResumeAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_DELETE_RESUME;
  payload: {
    callback?: () => void;
    options: ResumeApiDeleteResumeResumesIdDeleteRequest;
  };
}

export interface ISuccessDeleteResumeAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_DELETE_RESUME;
}

export interface IFailureDeleteResumeAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_DELETE_RESUME;
}

export interface ICleanOptionsResumeAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_OPTIONS_RESUME;
}

export interface IRequestOptionsResumeAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_OPTIONS_RESUME;
  payload?: {
    callback?: () => void;
  };
}

export interface ISuccessOptionsResumeAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_OPTIONS_RESUME;
  payload: {
    options: InfoItem;
  };
}

export interface IFailureOptionsResumeAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_OPTIONS_RESUME;
}

/*
 * Actions USER AVATAR
 */
export interface ICleanAddUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_ADD_USER_AVATAR;
}

export interface IRequestAddUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_ADD_USER_AVATAR;
  payload: {
    callback?: () => void;
    options: AvatarApiUploadAvatarAvatarsIdPostRequest;
  };
}

export interface ISuccessAddUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_ADD_USER_AVATAR;
}

export interface IFailureAddUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_ADD_USER_AVATAR;
}

export interface ICleanDeleteUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_DELETE_USER_AVATAR;
}

export interface IRequestDeleteUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_DELETE_USER_AVATAR;
  payload: {
    callback?: () => void;
    options: AvatarApiDeleteAvatarAvatarsIdDeleteRequest;
  };
}

export interface ISuccessDeleteUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_DELETE_USER_AVATAR;
}

export interface IFailureDeleteUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_DELETE_USER_AVATAR;
}

export interface ICleanOptionsUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.CLEAN_OPTIONS_USER_AVATAR;
}

export interface IRequestOptionsUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.REQUEST_OPTIONS_USER_AVATAR;
  payload?: {
    callback?: () => void;
  };
}

export interface ISuccessOptionsUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.SUCCESS_OPTIONS_USER_AVATAR;
  payload: {
    options: InfoItem;
  };
}
export interface IFailureOptionsUserAvatarAction extends Action<ECdnActions> {
  type: ECdnActions.FAILURE_OPTIONS_USER_AVATAR;
}
