import { UrlState } from "../hooks/useUrlState";

export const showDataGrid = (
  items: Array<any>,
  urlState: UrlState,
): boolean => {
  const haveItems = !!items.length;
  const haveFilters = !!Object.keys(urlState.query).filter(
    (param) => param !== "pageNum" && param !== "archived",
  ).length;

  return haveFilters || haveItems;
};
