import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "src/shared/components/index";

import useClient from "src/shared/hooks/useClient";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { RelativeTableChangeBindDto } from "../../../../generated/social";

interface Props {
  items: RelativeTableChangeBindDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const RelativeModerationHistoryTable: FC<Props> = ({
  loading,
  items,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isPlayerClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullNameAuthor",
        showed: !isPlayerClientId,
        titleUppercase: true,
        title: t("table.header.author"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.author"),
          activeGroupKey: getActiveGroup(
            ["firstNameAuthor", "lastNameAuthor"],
            { all: ["firstNameAuthor", "lastNameAuthor"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstNameAuthor",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstNameAuthor || "",
                },
                {
                  key: "lastNameAuthor",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastNameAuthor || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstNameAuthor",
                  "-firstNameAuthor",
                  "lastNameAuthor",
                  "-lastNameAuthor",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstNameAuthor",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstNameAuthor",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastNameAuthor",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastNameAuthor",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "fullNameRelative",
        showed: true,
        titleUppercase: true,
        title: t("table.header.user"),
        type: EDataGridColumnType.STRING,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.user"),
          activeGroupKey: getActiveGroup(
            ["firstNameRelative", "lastNameRelative"],
            { all: ["firstNameRelative", "lastNameRelative"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstNameRelative",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstNameRelative || "",
                },
                {
                  key: "lastNameRelative",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastNameRelative || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstNameRelative",
                  "-firstNameRelative",
                  "lastNameRelative",
                  "-lastNameRelative",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstNameRelative",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstNameRelative",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastNameRelative",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastNameRelative",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.phoneRelative"),
        type: EDataGridColumnType.PHONE,
        key: "phoneRelative",
        showed: true,
        controlPanel: {
          title: t("table.header.phoneRelative"),
          activeGroupKey: getActiveGroup(
            ["phoneRelative"],
            { all: ["phoneRelative"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "phoneRelative",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.phoneRelative || "",
                },
              ],
            },
          ],
        },
      },
      {
        key: "changedField",
        showed: true,
        titleUppercase: true,
        title: t("table.header.action"),
        type: EDataGridColumnType.STRING,
        filterPosition: EDataGridFilterPosition.LEFT,
      },
      {
        key: "fullNameStudent",
        showed: true,
        titleUppercase: true,
        title: t("table.ratings.statusCompany.student"),
        type: EDataGridColumnType.STRING,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.ratings.statusCompany.student"),
          activeGroupKey: getActiveGroup(
            ["firstNameStudent", "lastNameStudent"],
            { all: ["firstNameStudent", "lastNameStudent"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstNameStudent",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstNameStudent || "",
                },
                {
                  key: "lastNameStudent",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastNameStudent || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstNameStudent",
                  "-firstNameStudent",
                  "lastNameStudent",
                  "-lastNameStudent",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstNameStudent",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstNameStudent",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastNameStudent",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastNameStudent",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.phoneStudent"),
        type: EDataGridColumnType.PHONE,
        key: "phoneStudent",
        showed: true,
        controlPanel: {
          title: t("table.header.phoneStudent"),
          activeGroupKey: getActiveGroup(
            ["phoneStudent"],
            { all: ["phoneStudent"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "phoneStudent",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.phoneStudent || "",
                },
              ],
            },
          ],
        },
      },
      {
        key: "dateOfChange",
        showed: true,
        titleUppercase: true,
        title: t("common.date"),
        type: EDataGridColumnType.DATETIME,
        controlPanel: {
          title: t("common.date"),
          activeGroupKey: getActiveGroup(
            ["dateOfChange", "dateOfChangeFrom", "dateOfChangeTo"],
            {
              all: ["dateOfChange", "dateOfChangeFrom", "dateOfChangeTo"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "dateOfChangeFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.dateOfChangeFrom || "",
                  isHalf: true,
                },
                {
                  key: "dateOfChangeTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.dateOfChangeTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "date_of_changes",
                  "-date_of_changes",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "date_of_changes",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-date_of_changes",
                  },
                ],
              },
            },
          ],
        },
      },
    ];

    return newColumns;
  }, [isPlayerClientId, queryParams]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const fullNameAuthor = splitStrings([
        item.firstNameAuthor,
        item.lastNameAuthor,
      ]);
      const fullNameStudent = splitStrings([
        item.firstNameStudent,
        item.lastNameStudent,
      ]);

      const fullNameRelative = splitStrings([
        item.firstNameRelative,
        item.lastNameRelative,
      ]);
      return {
        id: item.id,
        selected: false,
        fullNameAuthor,
        avatarProps: {
          alt: fullNameAuthor,
          fullName: fullNameAuthor,
          customPath: getAvatarUrl(item.author_id),
          userId: item.author_id,
        },
        fullNameStudent,
        fullNameRelative,
        phoneRelative: item.phoneRelative,
        phoneStudent: item.phoneStudent,
        changedField: `${t(
          `table.ratings.${item.change_field}.${item.old_value}`,
          t("table.filters.notSelected"),
        )} → ${t(
          `table.ratings.${item.change_field}.${item.new_value}`,
          t("table.filters.notSelected"),
        )}`,
        dateOfChange: item.date_of_changes,
      };
    });

    setRows(rowsItems);
  }, [items, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
