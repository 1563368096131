import { FC, Fragment, ReactElement, useEffect, useMemo } from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import {
  RateDiff,
  TestResult,
  TestResultAvail,
  TestResultDislike,
  TestResultReward,
  TestResultRole,
} from "src/generated/game";

import { BaseCodeBlock, BasePopup, EPopupName } from "src/shared/components";

import { formulasModel } from "src/features/admin/formulas";

import { IFormulasData } from "../../lib/types";

import "./test-formulas-popup.scss";

type TResultsItem = Partial<
  TestResultAvail & TestResultDislike & TestResultReward & TestResultRole
>;

interface Props {
  formulas: IFormulasData;
}

const { $testResults, resetTestResults } = formulasModel;

const serializeDiffs = (diffs: RateDiff[]) =>
  diffs.map((diff) => `${diff.competence}: ${diff.diff}`).join(", ");

export const TestFormulasPopup: FC<Props> = ({ formulas }): ReactElement => {
  const { t } = useTranslation();

  const testFormula = useUnit($testResults);

  const testResults: TestResult | undefined = testFormula[0];

  const resultsInfo: TResultsItem[] | undefined =
    testResults?.rAvails ||
    testResults?.rDislikes ||
    testResults?.rRewards ||
    testResults?.rRoles;

  const formula: string | undefined = useMemo(() => {
    if (testResults) {
      const { gType, calcType, competence } = testResults;

      if (competence) {
        return formulas.competenceFormulas[gType][calcType];
      }

      return formulas.singleFormulas[gType][calcType];
    }
  }, [testResults, formulas]);

  useEffect(() => {
    return () => {
      resetTestResults();
    };
  }, []);

  return (
    <BasePopup
      name={EPopupName.TEST_FORMULAS_POPUP}
      title={t("popup.testFormula.title")}
    >
      {testResults && (
        <div className="test-formula-popup">
          <div className="test-formula-popup__item">
            Calculation type:
            <span className="test-formula-popup__item-description">
              {testResults.calcType}
            </span>
          </div>
          {formula && (
            <div className="test-formula-popup__item">
              Formula:
              <div className="test-formula-popup__item-description">
                <BaseCodeBlock code={formula} />
              </div>
            </div>
          )}
          <div className="test-formula-popup__list-title">Results:</div>
          <ul className="test-formula-popup__list">
            {resultsInfo?.map((item: TResultsItem, index) => (
              <li key={index} className="test-formula-popup__list-item">
                {Object.keys(item).map((key) => {
                  const description =
                    key === "diffs"
                      ? serializeDiffs(
                          item[key as keyof TResultsItem] as RateDiff[],
                        )
                      : item[key as keyof TResultsItem];

                  return (
                    <Fragment key={key}>
                      {description && (
                        <div className="test-formula-popup__item">
                          {key}:
                          <span className="test-formula-popup__item-description">
                            {String(description)}
                          </span>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </li>
            ))}
          </ul>
        </div>
      )}
    </BasePopup>
  );
};
