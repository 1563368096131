import classNames from "classnames";
import { FC, ReactElement, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUnit } from "effector-react";
import { $appInterface } from "src/features/public/app-interface/model";
import { numberSeparator } from "src/shared/helpers/numberSeparator";
import "./control-panel.scss";

interface Props {
  title?: string;
  maxCount: number;
  selectedCount: number;
  isLoading?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
  children?: ReactNode;
}

export const ControlPanel: FC<Props> = ({
  title,
  maxCount,
  selectedCount,
  isLoading,
  noPadding,
  noBorder,
  children,
}): ReactElement => {
  const { t } = useTranslation();
  const { isHeaderShowed } = useUnit($appInterface);

  const value = useMemo<string | number>(() => {
    if (isLoading) {
      return "...";
    }

    const usersValue = selectedCount > 0 ? selectedCount : maxCount;

    return numberSeparator(Number(usersValue));
  }, [selectedCount, maxCount, isLoading]);

  return (
    <div
      className={classNames("control-panel", {
        "control-panel--header-hidden": !isHeaderShowed,
        "control-panel--no-padding": noPadding,
        "control-panel--no-border": noBorder,
      })}
    >
      {title && (
        <div className="control-panel__title">
          {selectedCount > 0 ? t("common.selected") : title}:&nbsp;
          {value}
        </div>
      )}
      {children && <div className="control-panel__children">{children}</div>}
    </div>
  );
};
