import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { reflect } from "@effector/reflect";

import { useUrlState } from "src/shared/hooks/useUrlState";

import { useUpdatedQueryParams } from "src/shared/hooks/useUpdatedQueryParams";

import { ArchiveDataGrid, finesModel } from "src/entities/admin/fines";

import "../fines-page/FinesPage.scss";
import { $isLoading } from "../../../model";
import { useTranslation } from "react-i18next";
import {
  ArchiveLink,
  BaseButton,
  PageWrapper,
  TextWithIcon,
} from "src/shared/components";
import { $fines, $pagination } from "src/entities/admin/fines/model";
import { useUnit } from "effector-react";
import { TDataGridDataParams } from "src/shared/models";
import isEqual from "lodash/isEqual";
import { showDataGrid } from "src/shared/helpers/showDataGrid";
import { clearState } from "../../../../../../shared/helpers/clearState";

interface Props {
  isLoading: boolean;
  selectedIds: string[];
}

const View: FC<Props> = ({ isLoading, selectedIds }): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();

  const fines = useUnit($fines);
  const pagination = useUnit($pagination);

  const { updatedQueryParams } = useUpdatedQueryParams();

  useEffect(() => {
    if (updatedQueryParams) {
      if (updatedQueryParams.mode === "readmore") {
        finesModel.readmoreFines(updatedQueryParams);
      } else {
        finesModel.fetchFines(updatedQueryParams);
      }
    }
  }, [updatedQueryParams]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize" && key !== "archived",
      ).length,
    [urlState.query],
  );

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1", archived: "true" },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  return (
    <PageWrapper
      title={t("navigation.other.fines")}
      isLoadingPanel={isLoading}
      isShowContentWhenIsLoading
      tabsPanelSlot={<ArchiveLink to={"/other/fines-archive?archived=true"} />}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSlot={
        <div>
          {filtersLength > 0 && (
            <BaseButton onClick={clearFilterSettings} lightPurple>
              <TextWithIcon
                label={t("table.filters.reset")}
                iconName={"cross-blue"}
              />
            </BaseButton>
          )}
        </div>
      }
      controlPanelTitle={t("navigation.other.fines")}
    >
      <div className="fines-page">
        {showDataGrid(fines, urlState) ? (
          <ArchiveDataGrid
            selectedRowsIds={selectedIds}
            goToPage={goToPage}
            queryParams={urlState.query}
            updateQueryParams={onUpdateQueryParams}
          />
        ) : (
          <div className="fines-page-without-fines">
            <span>{t("fine.archive.notFine")}</span>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export const FinesArchivePage = reflect<Props>({
  view: View,
  bind: {
    isLoading: $isLoading,
  },
  hooks: {
    unmounted: finesModel.resetFines,
  },
});
