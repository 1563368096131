import { sample } from "effector";

import { updateUserSettings } from "src/shared/api/public/user-settings";

import { keycloakModel } from "src/entities/public/keycloak";

import {
  IUserSettings,
  userSettingsModel,
} from "src/entities/public/user-settings";

import { saveTableSettingsHandler } from "../lib";

import { userSettingsDomain } from "./domain";

import { IUpdateUserSettingsPayload } from "./types";

import { $preTablesSettings } from "./tables";

const { $userSettings } = userSettingsModel;

const { $keycloak } = keycloakModel;

// Stores

export const $isLoading = userSettingsDomain.createStore<boolean>(false);

// Events

export const fetchUpdateUserSettings =
  userSettingsDomain.createEvent<IUpdateUserSettingsPayload>();

export const saveTableSettings = userSettingsDomain.createEvent();

// Effects

export const fetchUpdateUserSettingsFx =
  userSettingsDomain.createEffect(updateUserSettings);

// Stores

sample({
  clock: saveTableSettings,
  source: {
    userSettings: $userSettings,
    preTablesSettings: $preTablesSettings,
    keycloak: $keycloak,
  },
  fn: saveTableSettingsHandler,
  target: fetchUpdateUserSettingsFx,
});

sample({ clock: fetchUpdateUserSettings, target: fetchUpdateUserSettingsFx });

sample({ clock: fetchUpdateUserSettingsFx.pending, target: $isLoading });

sample({
  clock: fetchUpdateUserSettingsFx.doneData,
  fn: ({ payload }) => payload as IUserSettings,
  target: $userSettings,
});
