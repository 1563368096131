import { FC, ReactElement } from "react";

import classNames from "classnames";

import { Competence, Player } from "src/generated/social";

import { TextWithIcon } from "src/shared/components";

import { CustomScale } from "./ui";

import "./lvl-rating-item.scss";
import { PlayerShortStatItem } from "../user-short-stat/player-short-stat-item";
import { getCompetenceStatItem } from "../../models";
import { useSelector } from "react-redux";
import { selectProfileCompetenceItem } from "../../store/ducks/profile-competence";
import { numberSeparator } from "src/shared/helpers/numberSeparator";

interface Props {
  isWithStar?: boolean;
  isWithScale?: boolean;
  title: string;
  subTitle: string;
  rate: number;
  diff: number;
  competence: Competence;
  place?: number;
  placeTotal?: string;
  isMainCompetence?: boolean;
}

export const LvlRatingItem: FC<Props> = ({
  isWithStar = false,
  isWithScale = true,
  title,
  subTitle,
  rate,
  diff,
  competence,
  place,
  placeTotal,
  isMainCompetence,
}): ReactElement => {
  const integerRate = Math.floor(rate);
  const fractionalRate = (rate - integerRate).toPrecision(12).slice(2, 4);
  let changingType: "increase" | "decrease" | null;
  const diffClass: string = diff > 0 ? "--increase" : "--decrease";
  const profileCompetence = useSelector(selectProfileCompetenceItem) as Player;

  const subTitleElement = (
    <div className="lvl-rating__sub-title">{subTitle}</div>
  );

  const RatingLevel = () => {
    return (
      <>
        <div
          className={classNames(
            "lvl-rating__rate-large",
            `lvl-rating__rate-large--${competence}`,
          )}
        >
          {integerRate}
        </div>
        <div className="lvl-rating__rate-small">.{fractionalRate}</div>
      </>
    );
  };

  const Rating = () => {
    return (
      <div className="lvl-rating__rate--level-wrapper">
        <RatingLevel />
        {subTitleElement}
      </div>
    );
  };

  const Title = () => {
    return (
      <span
        className={classNames("lvl-rating__title-text-wrapper", {
          "lvl-rating__title-text-wrapper--main": isMainCompetence,
        })}
      >
        <PlayerShortStatItem
          isMainCompetence={isMainCompetence}
          item={getCompetenceStatItem(competence, profileCompetence)}
          useToolTip={false}
          descriptionIsVisible={false}
          customClassName={classNames(
            "player-short-stat__list-item--lvl-rating",
            {
              "player-short-stat__list-item--lvl-rating__main":
                isMainCompetence,
              "player-short-stat__list-item--lvl-rating__additional":
                !isMainCompetence,
            },
          )}
        />
        {title}
      </span>
    );
  };

  if (diff > 0) {
    changingType = "increase";
  } else if (diff < 0) {
    changingType = "decrease";
  } else {
    changingType = null;
  }

  return (
    <div
      className={classNames("lvl-rating", {
        "lvl-rating--additional-competence": !isMainCompetence,
      })}
    >
      <h3
        className={classNames("lvl-rating__title", {
          "lvl-rating__title--with-star": isWithStar,
        })}
      >
        <Title />
        {!!(place && placeTotal) && (
          <div
            className={classNames("lvl-rating__places", {
              "lvl-rating__places--additional": !isMainCompetence,
            })}
          >
            <TextWithIcon
              className="lvl-rating__places-icon"
              iconName="state-gray"
              iconSize={16}
              hideLabel
            />
            {numberSeparator(Number(place))}
            <span className="lvl-rating__places-divider">/</span>
            {numberSeparator(Number(placeTotal))}
          </div>
        )}
      </h3>
      <div className="lvl-rating__rate" translate="no">
        <Rating />
        {diff !== 0 && (
          <div
            className={classNames(
              "lvl-rating__rate-diff",
              "lvl-rating__rate-diff" + diffClass,
            )}
          >
            {changingType === "increase" && "+"}
            {diff.toString().slice(0, 5)}
          </div>
        )}
      </div>
      {isWithScale && (
        <CustomScale
          rate={rate}
          competence={competence as Competence}
          isAdditional={!isMainCompetence}
        />
      )}
    </div>
  );
};
