import { LocStrDto } from "../../../../generated/social";
import { RootState } from "../../store";
import { ERequestStatus } from "../../types";
import { ISidebarInformationState } from "./type";

export const selectSidebarInformation = (
  state: RootState,
): ISidebarInformationState => state.sidebarInformation;

export const selectSidebarInformationItem = (
  state: RootState,
): LocStrDto | null => selectSidebarInformation(state).item;

export const selectSidebarInformationStatusGet = (
  state: RootState,
): ERequestStatus => selectSidebarInformation(state).statusGet;

export const selectSidebarInformationStatusPost = (
  state: RootState,
): ERequestStatus => selectSidebarInformation(state).statusPost;
