import { socialService } from "../../../base/api-instances";
import { PlayerFineStatusApiGetPlayerFineStatusRequest } from "src/generated/social";

export const getFineStatus = (
  payload: PlayerFineStatusApiGetPlayerFineStatusRequest,
) => {
  return socialService
    .makePlayerFineStatusRequest()
    .getPlayerFineStatus(payload)
    .then((response) => response.data);
};
