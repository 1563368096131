import { socialService } from "../../../../shared/api/base/api-instances";
import {
  BulkUpdFaqReq,
  FaqApiPostFaqBulkUpdRequest,
} from "../../../../generated/social";
import { faqDomain } from "./domain";

export const getFaqRequest = () => {
  return socialService
    .makeFaqRequest()
    .getFaq()
    .then((res) => res.data);
};

export const postFaqRequest = (params: FaqApiPostFaqBulkUpdRequest) => {
  const requestBody: BulkUpdFaqReq = {
    questions: params.body.questions,
  };

  return socialService
    .makeFaqRequest()
    .postFaqBulkUpd({ body: requestBody })
    .then((res) => res.data);
};

export const fetchFaqGetFx = faqDomain.createEffect(getFaqRequest);

export const fetchFaqPostFx = faqDomain.createEffect(postFaqRequest);
