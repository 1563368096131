import { permissionsDomain } from "./domain";
import { $permissions } from "./permissions";

// Events

export const resetAssessments = permissionsDomain.createEvent();

// Logic

$permissions.reset(resetAssessments);
