import { createDomain, sample } from "effector";

import {
  PlayerFineStatusApiGetPlayerFineStatusRequest,
  PlayerFineStatusDto,
} from "src/generated/social";
import { getFineStatus } from "../../../../shared/api/public/fine-status/model/fine-status";

// Domain

export const playerFineStatusDomain = createDomain("playerFineStatus");

// Events

export const getFines =
  playerFineStatusDomain.createEvent<PlayerFineStatusApiGetPlayerFineStatusRequest>();

export const $fineStatus =
  playerFineStatusDomain.createStore<PlayerFineStatusDto | null>(null);

// Effects

export const getFinesFx = playerFineStatusDomain.createEffect(getFineStatus);

// Logic

sample({ clock: getFines, target: getFinesFx });

sample({
  clock: getFinesFx.doneData,
  target: $fineStatus,
});
