import { createDomain, sample } from "effector";

import { spread } from "patronum";

import { Pagination, QueryParams } from "src/shared/api/base";

import { queryParamsToGetPlayersRatingAdapter } from "../lib/queryParamsToGetPlayersRatingAdapter";

import { getPlayers, PlayerRating } from "src/shared/api/admin/players";

import { RatingsApiGetRatingRequest } from "src/generated/social";

interface PlayersRequestParams {
  assessmentId: string;
  queryParams: QueryParams;
}

// Domain

export const playersDomain = createDomain("players");

// Events

export const fetchPlayers = playersDomain.createEvent<PlayersRequestParams>();

export const setQueryParams = playersDomain.createEvent<QueryParams>();

// Effects

export const fetchPlayersFx = playersDomain.createEffect(getPlayers);

// Stores

export const $players = playersDomain.createStore<PlayerRating[]>([]);

export const $isLoading = playersDomain.createStore<boolean>(false);

export const $pagination = playersDomain.createStore<Pagination>(null);

export const $queryParams = playersDomain.createStore<QueryParams>({});

sample({
  clock: fetchPlayers,
  fn: (payload): RatingsApiGetRatingRequest => ({
    ...queryParamsToGetPlayersRatingAdapter(payload.queryParams),
    assessmentId: payload.assessmentId,
  }),
  target: fetchPlayersFx,
});

sample({
  clock: [fetchPlayers.map(({ queryParams }) => queryParams), setQueryParams],
  target: $queryParams,
});

sample({
  clock: [fetchPlayersFx.pending],
  target: $isLoading,
});

spread({
  source: fetchPlayersFx.doneData.map(({ ratings, pagination }) => ({
    players: ratings || [],
    pagination: pagination || null,
  })),
  targets: {
    players: $players,
    pagination: $pagination,
  },
});
