import { ChartItem } from "../tutorial-chart/chart/chart";

const round = (num: number) => {
  if (num === 0) {
    return 0;
  }

  const factor = Math.pow(10, Math.floor(Math.log10(num)) - 1);
  return Math.round(num / factor) * factor;
};

const getKeyPoints = (data: ChartItem[], numPoints: number) => {
  const maxValue = data.reduce(
    (prev, current) => (current.value > prev ? current.value : prev),
    0,
  );

  const keyPoints = [];
  const step = maxValue / (numPoints - 1);

  for (let i = 0; i < numPoints; i++) {
    const rawValue = maxValue - i * step;
    keyPoints.push(round(rawValue));
  }
  return keyPoints;
};

export default getKeyPoints;
