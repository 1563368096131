import { Country } from "./country";

import { socialService } from "../../../base/api-instances";

import { GetListParams } from "./query";

export const getList = (params: GetListParams) => {
  return socialService
    .makeAdminRequest()
    .getAdmCountries(params)
    .then((res) => res.data);
};

export const update = (country: Country) => {
  return socialService
    .makeAdminRequest()
    .putAdmCountry({
      countryCode: country.code,
      country,
    })
    .then((res) => res.data);
};
