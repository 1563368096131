import { ISessionInfoTooltip } from "../../../../../../shared/models";
import { getDateForFilter } from "../../../../../../shared/helpers";
import classNames from "classnames";
import { FC } from "react";
import { RoomStateInterface } from "../../../../../../shared/store/ducks/room";
import { useTranslation } from "react-i18next";

interface SessionInfoTooltipProps {
  room: RoomStateInterface;
}

export const SessionInfoTooltip: FC<SessionInfoTooltipProps> = ({ room }) => {
  const { t } = useTranslation();

  const sessionInfoParams: ISessionInfoTooltip[] = [
    {
      visible: !!room.createdAt,
      title: t("table.header.game.created"),
      label: getDateForFilter(room.createdAt),
    },
    {
      visible: !!room.createdByUser,
      title: t("common.creator"),
      label: `${room.createdByUser?.firstname} ${room.createdByUser?.lastname}`,
    },
  ];

  return (
    <div className="session-tooltip session-tooltip__list session-tooltip--wide">
      {sessionInfoParams.map((param: ISessionInfoTooltip, index: number) => (
        <div
          key={index}
          className={classNames("session-tooltip__list-item", {
            "session-tooltip__list-item--show": param.visible,
          })}
        >
          <div className="session-tooltip__list-text session-tooltip__list-text--light-gray">
            {param.title}
          </div>
          <div className="session-tooltip__list-text">{param.label}</div>
        </div>
      ))}
    </div>
  );
};
