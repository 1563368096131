import { notificationService } from "src/shared/api/base/api-instances";

import {
  GetNotificationList,
  PostNotification,
  PostPersonalNotification,
  ResendNotification,
} from "./query";
import { NotifyApiPostNotifyNoConfirmationRelativeRequest } from "../../../../../../generated/notification";

export const getNotificationList = (params: GetNotificationList) => {
  return notificationService
    .makeNotificationRequest()
    .getNotifyList(params)
    .then((res) => res.data);
};

export const postNotification = (params: PostNotification) => {
  return notificationService
    .makeNotificationRequest()
    .postNotifyAll(params)
    .then((res) => res.data);
};

export const postPersonalNotification = (params: PostPersonalNotification) => {
  return notificationService
    .makeNotificationRequest()
    .postNotifyPersonal(params)
    .then((res) => res.data);
};

export const postNoConfirmationRelativeNotification = (
  params: NotifyApiPostNotifyNoConfirmationRelativeRequest,
) => {
  return notificationService
    .makeNotificationRequest()
    .postNotifyNoConfirmationRelative(params)
    .then((res) => res.data);
};

export const resendNotification = (params: ResendNotification) => {
  return notificationService
    .makeNotificationRequest()
    .postNotifyResend(params)
    .then((res) => res.data);
};
