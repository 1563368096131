import React from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "../header.module.scss";
import classNames from "classnames";
import { ReactComponent as CollegeIcon } from "../../../images/icons/college-logo.svg";
import { useTranslation } from "react-i18next";
import { BasePopup, EPopupName } from "../../../../shared/components";

interface IProps {
  shouldRender: boolean;
  isMenuOpened: boolean;
  isLaptop: boolean;
  onClose: () => void;
}

interface IMenuList {
  link: string;
  label: string;
  icon: JSX.Element;
}

const menuList: IMenuList[] = [
  // {
  //   link: "https://doc.hr.alabuga.ru",
  //   label: "header.menu.link.personnel",
  //   icon: <PersonnelIcon />,
  // },
  {
    link: `${process.env.REACT_APP_SCHEDULE_URL}`,
    label: "header.menu.link.schedule",
    icon: <CollegeIcon />,
  },
];

export const HeaderLinksMenu: React.FC<IProps> = ({
  shouldRender,
  isMenuOpened,
  isLaptop,
  onClose,
}) => {
  const { t } = useTranslation();

  return isLaptop ? (
    !shouldRender ? null : (
      <div
        className={classNames(styles.links_menu, {
          [styles.links_menu__is_closed]: !isMenuOpened,
        })}
      >
        <ul className={styles.links_menu__list}>
          {menuList.map((el) => (
            <li className={styles.links_menu__element} key={uuidv4()}>
              <a
                onClick={onClose}
                className={styles.links_menu__link}
                href={el.link}
              >
                <div>{el.icon}</div>
                <span>{t(el.label)}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  ) : (
    <BasePopup
      isTitleWithoutBottomPadding
      isWithoutPadding
      withoutScroll
      onlyMobile
      name={EPopupName.LINKS_MENU_POPUP}
      title={t("header.menu.title")}
    >
      <ul className={styles.links_menu__list}>
        {menuList.map((el) => (
          <li className={styles.links_menu__element} key={uuidv4()}>
            <a
              onClick={onClose}
              className={styles.links_menu__link}
              href={el.link}
            >
              <div>{el.icon}</div>
              <span>{t(el.label)}</span>
            </a>
          </li>
        ))}
      </ul>
    </BasePopup>
  );
};
