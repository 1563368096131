export interface FinesPopoverContentProps {
  isEdit?: boolean;
  fineItems?: string[];
  clearSelectedFines: () => void;
  onClose: () => void;
}

export interface FinesData {
  pid?: string;
  period: string;
  removable_rating: string;
}
export enum MainCompetence {
  Analytics = "analytics",
  Command = "command",
  Communication = "communication",
}

export type FinesFormData = {
  [key in string]: FinesData;
};

export const FINE_VALIDATION = {
  removable_rating: {
    min: 0.01,
    max: 0.5,
  },
  period: {
    min: 1,
    max: 180,
  },
};

export const initialData: FinesFormData = {
  communication: {
    period: "",
    removable_rating: "",
  },
  analytics: {
    period: "",
    removable_rating: "",
  },
  command: {
    period: "",
    removable_rating: "",
  },
};
