import classNames from "classnames";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BaseBox, TextWithIcon } from "src/shared/components";

import { IContactLink } from "../../contacts-page";

import "./telegram-block.scss";

interface Props {
  className?: string;
}

const chatsLinks: IContactLink[] = [
  {
    key: "telegram-white",
    link: "https://t.me/hr_alabuga_bot",
    label: "Telegram",
  },
  {
    key: "whats-app-white",
    link: "https://chat.whatsapp.com/CvSJ2lh0M0z0L9ApX1J8QI",
  },
];

export const TelegramBlock: FC<Props> = ({ className }): ReactElement => {
  const { t } = useTranslation();

  return (
    <BaseBox className={classNames("telegram-block", className)} noPadding>
      <h3 className="telegram-block__title">
        {t("contacts.telegramBlock.title")}
      </h3>
      <p className="telegram-block__description">
        {t("contacts.telegramBlock.description")}
      </p>
      <div className="telegram-block-controls">
        {chatsLinks.map((item) => (
          <a
            key={item.key}
            className={classNames(
              "telegram-block-controls__item",
              `telegram-block-controls__item--${item.key}`
            )}
            href={item.link}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <TextWithIcon
              iconName={item.key}
              label={`${t("contacts.telegramBlock.btn.chatIn")} ${item.label}`}
              iconSize={22}
              iconSizeMobile={15}
              labelSize={16}
              labelSizeMobile={12}
              hideLabel={!item.label}
              color="white"
              isBlock
            />
          </a>
        ))}
      </div>
    </BaseBox>
  );
};
