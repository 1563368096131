import { FC, ReactElement } from "react";

import { useTranslation } from "react-i18next";

import ReactCrop, { Crop } from "react-image-crop";

import { BaseButton, BasePopup, EPopupName } from "src/shared/components";

import "./avatar-crop-popup.scss";

interface Props {
  imgSrc: string;
  crop: Partial<Crop>;
  onImageLoaded: (image: HTMLImageElement) => boolean | void;
  onChangeCrop: (crop: Crop) => void;
  onCompleteCrop: (crop: Crop) => void;
  onSaveCrop: () => void;
  onClosePopup: () => void;
  isCircleCrop?: boolean;
  title: string;
  descriptions?: string[];
}

export const AvatarCropPopup: FC<Props> = ({
  imgSrc,
  crop,
  isCircleCrop,
  onImageLoaded,
  onChangeCrop,
  onCompleteCrop,
  onSaveCrop,
  onClosePopup,
  descriptions = [],
  title,
}): ReactElement => {
  const { t } = useTranslation();

  const localDescription: string[] = [
    t("popup.editContacts.crop.description.chooseArea"),
    ...descriptions,
  ];

  return (
    <BasePopup
      title={title}
      name={EPopupName.AVATAR_CROP}
      onClose={onClosePopup}
    >
      <div className="avatar-crop-popup">
        <ul className="avatar-crop-popup__descriptions">
          {localDescription.map((item) => (
            <li key={item} className="avatar-crop-popup__descriptions-item">
              {item}
            </li>
          ))}
        </ul>
        <ReactCrop
          src={imgSrc}
          onImageLoaded={onImageLoaded}
          crop={crop}
          circularCrop={isCircleCrop}
          onChange={onChangeCrop}
          onComplete={onCompleteCrop}
          className="avatar-crop-popup__crop"
        />
        <BaseButton onClick={onSaveCrop} block primary large>
          {t("common.saveChanges")}
        </BaseButton>
      </div>
    </BasePopup>
  );
};
