/* tslint:disable */
/* eslint-disable */
/**
 * Alabuga Keycloak API
 * Alabuga Keycloak Extension.  This extension adds Alabuga Theme, and some nonstandard features.  See also:   [Keycloak Admin REST API](https://www.keycloak.org/docs-api/12.0/rest-api/)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: vadim.nalimov@mediapark.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosInstance, AxiosPromise } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface CredentialRepr
 */
export interface CredentialRepr {
  /**
   *
   * @type {string}
   * @memberof CredentialRepr
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CredentialRepr
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof CredentialRepr
   */
  userLabel?: string;
  /**
   *
   * @type {number}
   * @memberof CredentialRepr
   */
  createdDate?: number;
  /**
   *
   * @type {string}
   * @memberof CredentialRepr
   */
  secretData?: string;
  /**
   *
   * @type {string}
   * @memberof CredentialRepr
   */
  credentialData?: string;
  /**
   *
   * @type {number}
   * @memberof CredentialRepr
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof CredentialRepr
   */
  value?: string;
  /**
   *
   * @type {boolean}
   * @memberof CredentialRepr
   */
  temporary?: boolean;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof CredentialRepr
   */
  config?: { [key: string]: Array<string> };
}
/**
 *
 * @export
 * @interface Err
 */
export interface Err {
  /**
   *
   * @type {string}
   * @memberof Err
   */
  errorMessage?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof Err
   */
  params?: Array<object>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ExecActs {
  VerifyEmail = "VERIFY_EMAIL",
  UpdateProfile = "UPDATE_PROFILE",
  ConfigureTotp = "CONFIGURE_TOTP",
  UpdatePassword = "UPDATE_PASSWORD",
  TermsAndConditions = "TERMS_AND_CONDITIONS",
}

/**
 *
 * @export
 * @interface FederatedIdentityRepr
 */
export interface FederatedIdentityRepr {
  /**
   *
   * @type {string}
   * @memberof FederatedIdentityRepr
   */
  identityProvider?: string;
  /**
   *
   * @type {string}
   * @memberof FederatedIdentityRepr
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof FederatedIdentityRepr
   */
  userName?: string;
}
/**
 *
 * @export
 * @interface IdList
 */
export interface IdList {
  /**
   *
   * @type {Array<string>}
   * @memberof IdList
   */
  ids: Array<string>;
}
/**
 *
 * @export
 * @interface Page
 */
export interface Page {
  /**
   * Page number, one-based.
   * @type {number}
   * @memberof Page
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Page
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Page
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof Page
   */
  totalItems: number;
}
/**
 *
 * @export
 * @interface SocialLinkRepr
 */
export interface SocialLinkRepr {
  /**
   *
   * @type {string}
   * @memberof SocialLinkRepr
   */
  socialProvider?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinkRepr
   */
  socialUserId?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinkRepr
   */
  socialUsername?: string;
}
/**
 *
 * @export
 * @interface UpdLastActivity
 */
export interface UpdLastActivity {
  /**
   *
   * @type {string}
   * @memberof UpdLastActivity
   */
  pid: string;
  /**
   *
   * @type {number}
   * @memberof UpdLastActivity
   */
  when: number;
}
/**
 *
 * @export
 * @interface UpdLastPlayBulk
 */
export interface UpdLastPlayBulk {
  /**
   *
   * @type {Array<string>}
   * @memberof UpdLastPlayBulk
   */
  pids: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpdLastPlayBulk
   */
  when: number;
}
/**
 * User model representation for tables with `realmRoles`.
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  emailVerified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  realmRoles?: Array<string>;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof User
   */
  attributes?: { [key: string]: Array<string> };
}
/**
 *
 * @export
 * @interface UserConsentRepr
 */
export interface UserConsentRepr {
  /**
   *
   * @type {string}
   * @memberof UserConsentRepr
   */
  clientId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserConsentRepr
   */
  grantedClientScopes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UserConsentRepr
   */
  createdDate?: number;
  /**
   *
   * @type {number}
   * @memberof UserConsentRepr
   */
  lastUpdatedDate?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UserConsentRepr
   */
  grantedRealmRoles?: Array<string>;
}
/**
 * User model represenation for profile page.
 * @export
 * @interface UserReprAcc
 */
export interface UserReprAcc {
  /**
   *
   * @type {string}
   * @memberof UserReprAcc
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprAcc
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof UserReprAcc
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprAcc
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprAcc
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserReprAcc
   */
  emailVerified?: boolean;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof UserReprAcc
   */
  attributes?: { [key: string]: Array<string> };
}
/**
 * User model represenation for full admin user manage.
 * @export
 * @interface UserReprIdm
 */
export interface UserReprIdm {
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  self?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserReprIdm
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserReprIdm
   */
  emailVerified?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof UserReprIdm
   */
  realmRoles?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  origin?: string;
  /**
   *
   * @type {number}
   * @memberof UserReprIdm
   */
  createdTimestamp?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserReprIdm
   */
  totp?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  federationLink?: string;
  /**
   *
   * @type {string}
   * @memberof UserReprIdm
   */
  serviceAccountClientId?: string;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof UserReprIdm
   */
  attributes?: { [key: string]: Array<string> };
  /**
   *
   * @type {Array<CredentialRepr>}
   * @memberof UserReprIdm
   */
  credentials?: Array<CredentialRepr>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserReprIdm
   */
  disableableCredentialTypes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserReprIdm
   */
  requiredActions?: Array<string>;
  /**
   *
   * @type {Array<FederatedIdentityRepr>}
   * @memberof UserReprIdm
   */
  federatedIdentities?: Array<FederatedIdentityRepr>;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof UserReprIdm
   */
  clientRoles?: { [key: string]: Array<string> };
  /**
   *
   * @type {Array<UserConsentRepr>}
   * @memberof UserReprIdm
   */
  clientConsents?: Array<UserConsentRepr>;
  /**
   *
   * @type {number}
   * @memberof UserReprIdm
   */
  notBefore?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UserReprIdm
   */
  groups?: Array<string>;
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof UserReprIdm
   */
  access?: { [key: string]: boolean };
  /**
   *
   * @type {Array<SocialLinkRepr>}
   * @memberof UserReprIdm
   */
  socialLinks?: Array<SocialLinkRepr>;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof UserReprIdm
   */
  applicationRoles?: { [key: string]: Array<string> };
}
/**
 *
 * @export
 * @interface UsersRes
 */
export interface UsersRes {
  /**
   *
   * @type {Page}
   * @memberof UsersRes
   */
  pagination: Page;
  /**
   *
   * @type {Array<User>}
   * @memberof UsersRes
   */
  users: Array<User>;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get current user profile info.
     * @summary Get User Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/accountEx`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send reset password email to current user with resend timeouts.
     * @summary Send reset password email
     * @param {string} redirectUri
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordEmail: async (
      redirectUri: string,
      clientId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'redirectUri' is not null or undefined
      assertParamExists("resetPasswordEmail", "redirectUri", redirectUri);
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("resetPasswordEmail", "clientId", clientId);
      const localVarPath = `/realms/Alabuga/accountEx/reset-password-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      if (redirectUri !== undefined) {
        localVarQueryParameter["redirect_uri"] = redirectUri;
      }

      if (clientId !== undefined) {
        localVarQueryParameter["client_id"] = clientId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send verification email to current user with resend timeouts.
     * @summary Send verify email
     * @param {string} redirectUri
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerifyEmail: async (
      redirectUri: string,
      clientId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'redirectUri' is not null or undefined
      assertParamExists("sendVerifyEmail", "redirectUri", redirectUri);
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("sendVerifyEmail", "clientId", clientId);
      const localVarPath = `/realms/Alabuga/accountEx/send-verify-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      if (redirectUri !== undefined) {
        localVarQueryParameter["redirect_uri"] = redirectUri;
      }

      if (clientId !== undefined) {
        localVarQueryParameter["client_id"] = clientId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update current user profile info. Changing username is disabled explicitly. Validates birthdate attribute with `YYYY-MM-DD` format. Sends update info to social service for users with Player roles.
     * @summary Update User Profile
     * @param {UserReprAcc} userReprAcc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount: async (
      userReprAcc: UserReprAcc,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userReprAcc' is not null or undefined
      assertParamExists("updateAccount", "userReprAcc", userReprAcc);
      const localVarPath = `/realms/Alabuga/accountEx`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userReprAcc,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update user to HR.
     * @summary Update user
     * @param {string} userId
     * @param {string} role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      userId: string,
      role: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("updateUser", "userId", userId);
      // verify required parameter 'role' is not null or undefined
      assertParamExists("updateUser", "role", role);
      const localVarPath = `/realms/Alabuga/accountEx/user-role/{userId}/{role}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"role"}}`, encodeURIComponent(String(role)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     * Get current user profile info.
     * @summary Get User Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccount(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReprAcc>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAccount(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Send reset password email to current user with resend timeouts.
     * @summary Send reset password email
     * @param {string} redirectUri
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPasswordEmail(
      redirectUri: string,
      clientId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetPasswordEmail(
          redirectUri,
          clientId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Send verification email to current user with resend timeouts.
     * @summary Send verify email
     * @param {string} redirectUri
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendVerifyEmail(
      redirectUri: string,
      clientId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendVerifyEmail(
        redirectUri,
        clientId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update current user profile info. Changing username is disabled explicitly. Validates birthdate attribute with `YYYY-MM-DD` format. Sends update info to social service for users with Player roles.
     * @summary Update User Profile
     * @param {UserReprAcc} userReprAcc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccount(
      userReprAcc: UserReprAcc,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReprAcc>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(
        userReprAcc,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update user to HR.
     * @summary Update user
     * @param {string} userId
     * @param {string} role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      userId: string,
      role: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(
        userId,
        role,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     * Get current user profile info.
     * @summary Get User Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(options?: any): AxiosPromise<UserReprAcc> {
      return localVarFp
        .getAccount(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send reset password email to current user with resend timeouts.
     * @summary Send reset password email
     * @param {string} redirectUri
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordEmail(
      redirectUri: string,
      clientId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .resetPasswordEmail(redirectUri, clientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send verification email to current user with resend timeouts.
     * @summary Send verify email
     * @param {string} redirectUri
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerifyEmail(
      redirectUri: string,
      clientId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sendVerifyEmail(redirectUri, clientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update current user profile info. Changing username is disabled explicitly. Validates birthdate attribute with `YYYY-MM-DD` format. Sends update info to social service for users with Player roles.
     * @summary Update User Profile
     * @param {UserReprAcc} userReprAcc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(
      userReprAcc: UserReprAcc,
      options?: any,
    ): AxiosPromise<UserReprAcc> {
      return localVarFp
        .updateAccount(userReprAcc, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update user to HR.
     * @summary Update user
     * @param {string} userId
     * @param {string} role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      userId: string,
      role: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUser(userId, role, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for resetPasswordEmail operation in AccountApi.
 * @export
 * @interface AccountApiResetPasswordEmailRequest
 */
export interface AccountApiResetPasswordEmailRequest {
  /**
   *
   * @type {string}
   * @memberof AccountApiResetPasswordEmail
   */
  readonly redirectUri: string;

  /**
   *
   * @type {string}
   * @memberof AccountApiResetPasswordEmail
   */
  readonly clientId: string;
}

/**
 * Request parameters for sendVerifyEmail operation in AccountApi.
 * @export
 * @interface AccountApiSendVerifyEmailRequest
 */
export interface AccountApiSendVerifyEmailRequest {
  /**
   *
   * @type {string}
   * @memberof AccountApiSendVerifyEmail
   */
  readonly redirectUri: string;

  /**
   *
   * @type {string}
   * @memberof AccountApiSendVerifyEmail
   */
  readonly clientId: string;
}

/**
 * Request parameters for updateAccount operation in AccountApi.
 * @export
 * @interface AccountApiUpdateAccountRequest
 */
export interface AccountApiUpdateAccountRequest {
  /**
   *
   * @type {UserReprAcc}
   * @memberof AccountApiUpdateAccount
   */
  readonly userReprAcc: UserReprAcc;
}

/**
 * Request parameters for updateUser operation in AccountApi.
 * @export
 * @interface AccountApiUpdateUserRequest
 */
export interface AccountApiUpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof AccountApiUpdateUser
   */
  readonly userId: string;

  /**
   *
   * @type {string}
   * @memberof AccountApiUpdateUser
   */
  readonly role: string;
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   * Get current user profile info.
   * @summary Get User Profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getAccount(options?: any) {
    return AccountApiFp(this.configuration)
      .getAccount(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send reset password email to current user with resend timeouts.
   * @summary Send reset password email
   * @param {AccountApiResetPasswordEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public resetPasswordEmail(
    requestParameters: AccountApiResetPasswordEmailRequest,
    options?: any,
  ) {
    return AccountApiFp(this.configuration)
      .resetPasswordEmail(
        requestParameters.redirectUri,
        requestParameters.clientId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send verification email to current user with resend timeouts.
   * @summary Send verify email
   * @param {AccountApiSendVerifyEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public sendVerifyEmail(
    requestParameters: AccountApiSendVerifyEmailRequest,
    options?: any,
  ) {
    return AccountApiFp(this.configuration)
      .sendVerifyEmail(
        requestParameters.redirectUri,
        requestParameters.clientId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update current user profile info. Changing username is disabled explicitly. Validates birthdate attribute with `YYYY-MM-DD` format. Sends update info to social service for users with Player roles.
   * @summary Update User Profile
   * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public updateAccount(
    requestParameters: AccountApiUpdateAccountRequest,
    options?: any,
  ) {
    return AccountApiFp(this.configuration)
      .updateAccount(requestParameters.userReprAcc, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update user to HR.
   * @summary Update user
   * @param {AccountApiUpdateUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public updateUser(
    requestParameters: AccountApiUpdateUserRequest,
    options?: any,
  ) {
    return AccountApiFp(this.configuration)
      .updateUser(requestParameters.userId, requestParameters.role, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
     * @summary Export Users with Ratings CSV
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ratingsCsv: async (
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albAdmin/export/ratings/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      if (name) {
        localVarQueryParameter["name"] = name;
      }

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (gPlaceFrom !== undefined) {
        localVarQueryParameter["gPlaceFrom"] = gPlaceFrom;
      }

      if (gPlaceTo !== undefined) {
        localVarQueryParameter["gPlaceTo"] = gPlaceTo;
      }

      if (gRateFrom !== undefined) {
        localVarQueryParameter["gRateFrom"] = gRateFrom;
      }

      if (gRateTo !== undefined) {
        localVarQueryParameter["gRateTo"] = gRateTo;
      }

      if (analyticsFrom !== undefined) {
        localVarQueryParameter["analyticsFrom"] = analyticsFrom;
      }

      if (analyticsTo !== undefined) {
        localVarQueryParameter["analyticsTo"] = analyticsTo;
      }

      if (commandFrom !== undefined) {
        localVarQueryParameter["commandFrom"] = commandFrom;
      }

      if (commandTo !== undefined) {
        localVarQueryParameter["commandTo"] = commandTo;
      }

      if (communicationFrom !== undefined) {
        localVarQueryParameter["communicationFrom"] = communicationFrom;
      }

      if (communicationTo !== undefined) {
        localVarQueryParameter["communicationTo"] = communicationTo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (lastPlayFrom !== undefined) {
        localVarQueryParameter["lastPlayFrom"] = lastPlayFrom;
      }

      if (lastPlayTo !== undefined) {
        localVarQueryParameter["lastPlayTo"] = lastPlayTo;
      }

      if (lastActivityFrom !== undefined) {
        localVarQueryParameter["lastActivityFrom"] = lastActivityFrom;
      }

      if (lastActivityTo !== undefined) {
        localVarQueryParameter["lastActivityTo"] = lastActivityTo;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
     * @summary Export Users with Ratings XLS
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ratingsXls: async (
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albAdmin/export/ratings/xls`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      if (name) {
        localVarQueryParameter["name"] = name;
      }

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (gPlaceFrom !== undefined) {
        localVarQueryParameter["gPlaceFrom"] = gPlaceFrom;
      }

      if (gPlaceTo !== undefined) {
        localVarQueryParameter["gPlaceTo"] = gPlaceTo;
      }

      if (gRateFrom !== undefined) {
        localVarQueryParameter["gRateFrom"] = gRateFrom;
      }

      if (gRateTo !== undefined) {
        localVarQueryParameter["gRateTo"] = gRateTo;
      }

      if (analyticsFrom !== undefined) {
        localVarQueryParameter["analyticsFrom"] = analyticsFrom;
      }

      if (analyticsTo !== undefined) {
        localVarQueryParameter["analyticsTo"] = analyticsTo;
      }

      if (commandFrom !== undefined) {
        localVarQueryParameter["commandFrom"] = commandFrom;
      }

      if (commandTo !== undefined) {
        localVarQueryParameter["commandTo"] = commandTo;
      }

      if (communicationFrom !== undefined) {
        localVarQueryParameter["communicationFrom"] = communicationFrom;
      }

      if (communicationTo !== undefined) {
        localVarQueryParameter["communicationTo"] = communicationTo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (lastPlayFrom !== undefined) {
        localVarQueryParameter["lastPlayFrom"] = lastPlayFrom;
      }

      if (lastPlayTo !== undefined) {
        localVarQueryParameter["lastPlayTo"] = lastPlayTo;
      }

      if (lastActivityFrom !== undefined) {
        localVarQueryParameter["lastActivityFrom"] = lastActivityFrom;
      }

      if (lastActivityTo !== undefined) {
        localVarQueryParameter["lastActivityTo"] = lastActivityTo;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration);
  return {
    /**
     * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
     * @summary Export Users with Ratings CSV
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ratingsCsv(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ratingsCsv(
        name,
        firstName,
        lastName,
        phone,
        gPlaceFrom,
        gPlaceTo,
        gRateFrom,
        gRateTo,
        analyticsFrom,
        analyticsTo,
        commandFrom,
        commandTo,
        communicationFrom,
        communicationTo,
        createdFrom,
        createdTo,
        lastPlayFrom,
        lastPlayTo,
        lastActivityFrom,
        lastActivityTo,
        desiredStudyPlace,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
     * @summary Export Users with Ratings XLS
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ratingsXls(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ratingsXls(
        name,
        firstName,
        lastName,
        phone,
        gPlaceFrom,
        gPlaceTo,
        gRateFrom,
        gRateTo,
        analyticsFrom,
        analyticsTo,
        commandFrom,
        commandTo,
        communicationFrom,
        communicationTo,
        createdFrom,
        createdTo,
        lastPlayFrom,
        lastPlayTo,
        lastActivityFrom,
        lastActivityTo,
        desiredStudyPlace,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExportApiFp(configuration);
  return {
    /**
     * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
     * @summary Export Users with Ratings CSV
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ratingsCsv(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .ratingsCsv(
          name,
          firstName,
          lastName,
          phone,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          commandFrom,
          commandTo,
          communicationFrom,
          communicationTo,
          createdFrom,
          createdTo,
          lastPlayFrom,
          lastPlayTo,
          lastActivityFrom,
          lastActivityTo,
          desiredStudyPlace,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
     * @summary Export Users with Ratings XLS
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ratingsXls(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      commandFrom?: number,
      commandTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      desiredStudyPlace?: Array<string>,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .ratingsXls(
          name,
          firstName,
          lastName,
          phone,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          commandFrom,
          commandTo,
          communicationFrom,
          communicationTo,
          createdFrom,
          createdTo,
          lastPlayFrom,
          lastPlayTo,
          lastActivityFrom,
          lastActivityTo,
          desiredStudyPlace,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for ratingsCsv operation in ExportApi.
 * @export
 * @interface ExportApiRatingsCsvRequest
 */
export interface ExportApiRatingsCsvRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsCsv
   */
  readonly name?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsCsv
   */
  readonly firstName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsCsv
   */
  readonly lastName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsCsv
   */
  readonly phone?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly gPlaceFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly gPlaceTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly gRateFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly gRateTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly analyticsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly analyticsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly commandFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly commandTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly communicationFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsCsv
   */
  readonly communicationTo?: number;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsCsv
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsCsv
   */
  readonly createdTo?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsCsv
   */
  readonly lastPlayFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsCsv
   */
  readonly lastPlayTo?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsCsv
   */
  readonly lastActivityFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsCsv
   */
  readonly lastActivityTo?: string;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsCsv
   */
  readonly desiredStudyPlace?: Array<string>;
}

/**
 * Request parameters for ratingsXls operation in ExportApi.
 * @export
 * @interface ExportApiRatingsXlsRequest
 */
export interface ExportApiRatingsXlsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsXls
   */
  readonly name?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsXls
   */
  readonly firstName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsXls
   */
  readonly lastName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsXls
   */
  readonly phone?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly gPlaceFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly gPlaceTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly gRateFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly gRateTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly analyticsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly analyticsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly commandFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly commandTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly communicationFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiRatingsXls
   */
  readonly communicationTo?: number;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsXls
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsXls
   */
  readonly createdTo?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsXls
   */
  readonly lastPlayFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsXls
   */
  readonly lastPlayTo?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsXls
   */
  readonly lastActivityFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportApiRatingsXls
   */
  readonly lastActivityTo?: string;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportApiRatingsXls
   */
  readonly desiredStudyPlace?: Array<string>;
}

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
  /**
   * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
   * @summary Export Users with Ratings CSV
   * @param {ExportApiRatingsCsvRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public ratingsCsv(
    requestParameters: ExportApiRatingsCsvRequest = {},
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .ratingsCsv(
        requestParameters.name,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phone,
        requestParameters.gPlaceFrom,
        requestParameters.gPlaceTo,
        requestParameters.gRateFrom,
        requestParameters.gRateTo,
        requestParameters.analyticsFrom,
        requestParameters.analyticsTo,
        requestParameters.commandFrom,
        requestParameters.commandTo,
        requestParameters.communicationFrom,
        requestParameters.communicationTo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.lastPlayFrom,
        requestParameters.lastPlayTo,
        requestParameters.lastActivityFrom,
        requestParameters.lastActivityTo,
        requestParameters.desiredStudyPlace,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Filters are compatible with <a href=\"https://social.alabuga.mediapark.com.ru/docs#/keycloak/getRatingStream\">   alb_sv_social/ratings/stream </a>
   * @summary Export Users with Ratings XLS
   * @param {ExportApiRatingsXlsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public ratingsXls(
    requestParameters: ExportApiRatingsXlsRequest = {},
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .ratingsXls(
        requestParameters.name,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phone,
        requestParameters.gPlaceFrom,
        requestParameters.gPlaceTo,
        requestParameters.gRateFrom,
        requestParameters.gRateTo,
        requestParameters.analyticsFrom,
        requestParameters.analyticsTo,
        requestParameters.commandFrom,
        requestParameters.commandTo,
        requestParameters.communicationFrom,
        requestParameters.communicationTo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.lastPlayFrom,
        requestParameters.lastPlayTo,
        requestParameters.lastActivityFrom,
        requestParameters.lastActivityTo,
        requestParameters.desiredStudyPlace,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SocialApi - axios parameter creator
 * @export
 */
export const SocialApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Update Players lastActivity attribute
     * @param {UpdLastActivity} [req]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updLastActivity: async (
      req?: UpdLastActivity,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albSvc/updLastActivity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        req,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Players lastPlay attribute
     * @param {UpdLastPlayBulk} [req]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updLastPlayBulk: async (
      req?: UpdLastPlayBulk,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albSvc/updLastPlayBulk`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        req,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SocialApi - functional programming interface
 * @export
 */
export const SocialApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SocialApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Update Players lastActivity attribute
     * @param {UpdLastActivity} [req]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updLastActivity(
      req?: UpdLastActivity,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updLastActivity(
        req,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Players lastPlay attribute
     * @param {UpdLastPlayBulk} [req]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updLastPlayBulk(
      req?: UpdLastPlayBulk,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updLastPlayBulk(
        req,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SocialApi - factory interface
 * @export
 */
export const SocialApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SocialApiFp(configuration);
  return {
    /**
     *
     * @summary Update Players lastActivity attribute
     * @param {UpdLastActivity} [req]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updLastActivity(req?: UpdLastActivity, options?: any): AxiosPromise<void> {
      return localVarFp
        .updLastActivity(req, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Players lastPlay attribute
     * @param {UpdLastPlayBulk} [req]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updLastPlayBulk(req?: UpdLastPlayBulk, options?: any): AxiosPromise<void> {
      return localVarFp
        .updLastPlayBulk(req, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for updLastActivity operation in SocialApi.
 * @export
 * @interface SocialApiUpdLastActivityRequest
 */
export interface SocialApiUpdLastActivityRequest {
  /**
   *
   * @type {UpdLastActivity}
   * @memberof SocialApiUpdLastActivity
   */
  readonly req?: UpdLastActivity;
}

/**
 * Request parameters for updLastPlayBulk operation in SocialApi.
 * @export
 * @interface SocialApiUpdLastPlayBulkRequest
 */
export interface SocialApiUpdLastPlayBulkRequest {
  /**
   *
   * @type {UpdLastPlayBulk}
   * @memberof SocialApiUpdLastPlayBulk
   */
  readonly req?: UpdLastPlayBulk;
}

/**
 * SocialApi - object-oriented interface
 * @export
 * @class SocialApi
 * @extends {BaseAPI}
 */
export class SocialApi extends BaseAPI {
  /**
   *
   * @summary Update Players lastActivity attribute
   * @param {SocialApiUpdLastActivityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SocialApi
   */
  public updLastActivity(
    requestParameters: SocialApiUpdLastActivityRequest = {},
    options?: any,
  ) {
    return SocialApiFp(this.configuration)
      .updLastActivity(requestParameters.req, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Players lastPlay attribute
   * @param {SocialApiUpdLastPlayBulkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SocialApi
   */
  public updLastPlayBulk(
    requestParameters: SocialApiUpdLastPlayBulkRequest = {},
    options?: any,
  ) {
    return SocialApiFp(this.configuration)
      .updLastPlayBulk(requestParameters.req, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Execute actions email
     * @param {string} id
     * @param {string} clientId
     * @param {string} [redirectUri]
     * @param {number} [lifespan]
     * @param {Array<ExecActs>} [actions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeActionsEmail: async (
      id: string,
      clientId: string,
      redirectUri?: string,
      lifespan?: number,
      actions?: Array<ExecActs>,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("executeActionsEmail", "id", id);
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("executeActionsEmail", "clientId", clientId);
      const localVarPath =
        `/realms/Alabuga/albAdmin/users/{id}/execute-actions-email`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      if (redirectUri !== undefined) {
        localVarQueryParameter["redirect_uri"] = redirectUri;
      }

      if (clientId !== undefined) {
        localVarQueryParameter["client_id"] = clientId;
      }

      if (lifespan !== undefined) {
        localVarQueryParameter["lifespan"] = lifespan;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        actions,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get User Info
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getUser", "id", id);
      const localVarPath = `/realms/Alabuga/albAdmin/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update User Info
     * @param {string} id
     * @param {UserReprIdm} [userReprIdm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putUser: async (
      id: string,
      userReprIdm?: UserReprIdm,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putUser", "id", id);
      const localVarPath = `/realms/Alabuga/albAdmin/users/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userReprIdm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Execute actions email
     * @param {string} id
     * @param {string} clientId
     * @param {string} [redirectUri]
     * @param {number} [lifespan]
     * @param {Array<ExecActs>} [actions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async executeActionsEmail(
      id: string,
      clientId: string,
      redirectUri?: string,
      lifespan?: number,
      actions?: Array<ExecActs>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.executeActionsEmail(
          id,
          clientId,
          redirectUri,
          lifespan,
          actions,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get User Info
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReprIdm>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update User Info
     * @param {string} id
     * @param {UserReprIdm} [userReprIdm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putUser(
      id: string,
      userReprIdm?: UserReprIdm,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putUser(
        id,
        userReprIdm,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @summary Execute actions email
     * @param {string} id
     * @param {string} clientId
     * @param {string} [redirectUri]
     * @param {number} [lifespan]
     * @param {Array<ExecActs>} [actions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeActionsEmail(
      id: string,
      clientId: string,
      redirectUri?: string,
      lifespan?: number,
      actions?: Array<ExecActs>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .executeActionsEmail(
          id,
          clientId,
          redirectUri,
          lifespan,
          actions,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get User Info
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options?: any): AxiosPromise<UserReprIdm> {
      return localVarFp
        .getUser(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update User Info
     * @param {string} id
     * @param {UserReprIdm} [userReprIdm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putUser(
      id: string,
      userReprIdm?: UserReprIdm,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .putUser(id, userReprIdm, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for executeActionsEmail operation in UserApi.
 * @export
 * @interface UserApiExecuteActionsEmailRequest
 */
export interface UserApiExecuteActionsEmailRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiExecuteActionsEmail
   */
  readonly id: string;

  /**
   *
   * @type {string}
   * @memberof UserApiExecuteActionsEmail
   */
  readonly clientId: string;

  /**
   *
   * @type {string}
   * @memberof UserApiExecuteActionsEmail
   */
  readonly redirectUri?: string;

  /**
   *
   * @type {number}
   * @memberof UserApiExecuteActionsEmail
   */
  readonly lifespan?: number;

  /**
   *
   * @type {Array<ExecActs>}
   * @memberof UserApiExecuteActionsEmail
   */
  readonly actions?: Array<ExecActs>;
}

/**
 * Request parameters for getUser operation in UserApi.
 * @export
 * @interface UserApiGetUserRequest
 */
export interface UserApiGetUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiGetUser
   */
  readonly id: string;
}

/**
 * Request parameters for putUser operation in UserApi.
 * @export
 * @interface UserApiPutUserRequest
 */
export interface UserApiPutUserRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiPutUser
   */
  readonly id: string;

  /**
   *
   * @type {UserReprIdm}
   * @memberof UserApiPutUser
   */
  readonly userReprIdm?: UserReprIdm;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Execute actions email
   * @param {UserApiExecuteActionsEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public executeActionsEmail(
    requestParameters: UserApiExecuteActionsEmailRequest,
    options?: any,
  ) {
    return UserApiFp(this.configuration)
      .executeActionsEmail(
        requestParameters.id,
        requestParameters.clientId,
        requestParameters.redirectUri,
        requestParameters.lifespan,
        requestParameters.actions,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get User Info
   * @param {UserApiGetUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(requestParameters: UserApiGetUserRequest, options?: any) {
    return UserApiFp(this.configuration)
      .getUser(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update User Info
   * @param {UserApiPutUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public putUser(requestParameters: UserApiPutUserRequest, options?: any) {
    return UserApiFp(this.configuration)
      .putUser(requestParameters.id, requestParameters.userReprIdm, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Create user and grant roles. Create/Update profile in `alb_svc_social` service for `Player`.
     * @summary Create User
     * @param {UserReprIdm} [userReprIdm] User Create Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUsers: async (
      userReprIdm?: UserReprIdm,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albAdmin/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userReprIdm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get users list with filtering and sorting. All the filters combined with `AND` clause. Multiple values in each filter use `OR` clause.
     * @summary Get Users
     * @param {boolean} [enabled]
     * @param {Array<string>} [id] List of required users with specific IDs.
     * @param {Array<string>} [name] Filter users by &#x60;firstName&#x60; OR &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [firstName] Filter users by &#x60;firstName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [lastName] Filter users by &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [phoneNumber] Filter users by &#x60;phoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [region] Filter users by &#x60;region&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [district] Filter users by &#x60;district&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [locality] Filter users by &#x60;locality&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [school] Filter users by &#x60;school&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [groupCode] Filter users by &#x60;groupCode&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [email] Filter users by &#x60;email&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [role] Filter users with roles &#x60;HR-Admin&#x60;, &#x60;HR-Alabuga&#x60;, &#x60;HR-Staff&#x60;, &#x60;Player&#x60;.
     * @param {Array<string>} [workPhoneNumber] Filter users by &#x60;workPhoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [studyWorkPlace] Filter users by &#x60;studyWorkPlace&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {string} [birthDateFrom] Filter users by &#x60;bithDate&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
     * @param {string} [birthDateTo] Filter users by &#x60;bithDate&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
     * @param {string} [createdFrom] Filter users by &#x60;created&#x60; date time, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [createdTo] Filter users by &#x60;created&#x60; date time, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastPlayFrom] Filter users by &#x60;lastPlay&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastPlayTo] Filter users by &#x60;lastPlay&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastActivityFrom] Filter users by &#x60;lastActivity&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastActivityTo] Filter users by &#x60;lastActivity&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {boolean} [resume] Filter users by present filled &#x60;resume&#x60; attribute.
     * @param {Array<string>} [desiredStudyPlace] Filter users with &#x60;desiredStudyPlace&#x60; attribute, case sensitive &#x60;&#x3D;&#x60;. &#x60;notAnEntrant&#x60;, &#x60;alabugaPolytech&#x60;, &#x60;KFU&#x60;.
     * @param {Array<string>} [orderBy] Order users by fields &#x60;ENABLED&#x60;, &#x60;NAME&#x60;, &#x60;FIRSTNAME&#x60;, &#x60;LASTNAME&#x60;, &#x60;ROLE&#x60;, &#x60;PHONENUMBER&#x60;, &#x60;EMAIL&#x60;, &#x60;REGION&#x60;, &#x60;DISTRICT&#x60;, &#x60;LOCALITY&#x60;, &#x60;SCHOOL&#x60;, &#x60;GROUPCODE&#x60;, &#x60;WORKPHONENUMBER&#x60;, &#x60;STUDYWORKPLACE&#x60;, &#x60;BIRTHDATE&#x60;, &#x60;CREATED&#x60;, &#x60;LASTPLAY&#x60;, &#x60;LASTACTIVITY&#x60;, &#x60;RESUME&#x60;, &#x60;DESIREDSTUDYPLACE&#x60;. Prefix &#x60;-&#x60; for descending, prefix &#x60;+&#x60; or empty for ascending.
     * @param {number} [pageNum] Page number, one-based.
     * @param {number} [pageSize] Page size, [1..1000], default &#x60;10&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    users: async (
      enabled?: boolean,
      id?: Array<string>,
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phoneNumber?: Array<string>,
      region?: Array<string>,
      district?: Array<string>,
      locality?: Array<string>,
      school?: Array<string>,
      groupCode?: Array<string>,
      email?: Array<string>,
      role?: Array<string>,
      workPhoneNumber?: Array<string>,
      studyWorkPlace?: Array<string>,
      birthDateFrom?: string,
      birthDateTo?: string,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      resume?: boolean,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albAdmin/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      if (enabled !== undefined) {
        localVarQueryParameter["enabled"] = enabled;
      }

      if (id) {
        localVarQueryParameter["id"] = id;
      }

      if (name) {
        localVarQueryParameter["name"] = name;
      }

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phoneNumber) {
        localVarQueryParameter["phoneNumber"] = phoneNumber;
      }

      if (region) {
        localVarQueryParameter["region"] = region;
      }

      if (district) {
        localVarQueryParameter["district"] = district;
      }

      if (locality) {
        localVarQueryParameter["locality"] = locality;
      }

      if (school) {
        localVarQueryParameter["school"] = school;
      }

      if (groupCode) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (email) {
        localVarQueryParameter["email"] = email;
      }

      if (role) {
        localVarQueryParameter["role"] = role;
      }

      if (workPhoneNumber) {
        localVarQueryParameter["workPhoneNumber"] = workPhoneNumber;
      }

      if (studyWorkPlace) {
        localVarQueryParameter["studyWorkPlace"] = studyWorkPlace;
      }

      if (birthDateFrom !== undefined) {
        localVarQueryParameter["birthDateFrom"] = birthDateFrom;
      }

      if (birthDateTo !== undefined) {
        localVarQueryParameter["birthDateTo"] = birthDateTo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (lastPlayFrom !== undefined) {
        localVarQueryParameter["lastPlayFrom"] = lastPlayFrom;
      }

      if (lastPlayTo !== undefined) {
        localVarQueryParameter["lastPlayTo"] = lastPlayTo;
      }

      if (lastActivityFrom !== undefined) {
        localVarQueryParameter["lastActivityFrom"] = lastActivityFrom;
      }

      if (lastActivityTo !== undefined) {
        localVarQueryParameter["lastActivityTo"] = lastActivityTo;
      }

      if (resume !== undefined) {
        localVarQueryParameter["resume"] = resume;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete list of users by IDs, with delete `Player` users in `alb_svc_social` service.
     * @summary Delete Users
     * @param {IdList} [ids] User\&#39;s IDs to Delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDelete: async (
      ids?: IdList,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albAdmin/users/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        ids,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Disable Users
     * @param {IdList} [ids] User\&#39;s IDs to Disable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDisable: async (
      ids?: IdList,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albAdmin/users/disable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        ids,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Enable Users
     * @param {IdList} [ids] User\&#39;s IDs to Enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersEnable: async (
      ids?: IdList,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/realms/Alabuga/albAdmin/users/enable`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      // authentication kc_stage_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_stage_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        ids,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * Create user and grant roles. Create/Update profile in `alb_svc_social` service for `Player`.
     * @summary Create User
     * @param {UserReprIdm} [userReprIdm] User Create Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postUsers(
      userReprIdm?: UserReprIdm,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postUsers(
        userReprIdm,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get users list with filtering and sorting. All the filters combined with `AND` clause. Multiple values in each filter use `OR` clause.
     * @summary Get Users
     * @param {boolean} [enabled]
     * @param {Array<string>} [id] List of required users with specific IDs.
     * @param {Array<string>} [name] Filter users by &#x60;firstName&#x60; OR &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [firstName] Filter users by &#x60;firstName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [lastName] Filter users by &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [phoneNumber] Filter users by &#x60;phoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [region] Filter users by &#x60;region&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [district] Filter users by &#x60;district&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [locality] Filter users by &#x60;locality&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [school] Filter users by &#x60;school&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [groupCode] Filter users by &#x60;groupCode&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [email] Filter users by &#x60;email&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [role] Filter users with roles &#x60;HR-Admin&#x60;, &#x60;HR-Alabuga&#x60;, &#x60;HR-Staff&#x60;, &#x60;Player&#x60;.
     * @param {Array<string>} [workPhoneNumber] Filter users by &#x60;workPhoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [studyWorkPlace] Filter users by &#x60;studyWorkPlace&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {string} [birthDateFrom] Filter users by &#x60;bithDate&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
     * @param {string} [birthDateTo] Filter users by &#x60;bithDate&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
     * @param {string} [createdFrom] Filter users by &#x60;created&#x60; date time, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [createdTo] Filter users by &#x60;created&#x60; date time, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastPlayFrom] Filter users by &#x60;lastPlay&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastPlayTo] Filter users by &#x60;lastPlay&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastActivityFrom] Filter users by &#x60;lastActivity&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastActivityTo] Filter users by &#x60;lastActivity&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {boolean} [resume] Filter users by present filled &#x60;resume&#x60; attribute.
     * @param {Array<string>} [desiredStudyPlace] Filter users with &#x60;desiredStudyPlace&#x60; attribute, case sensitive &#x60;&#x3D;&#x60;. &#x60;notAnEntrant&#x60;, &#x60;alabugaPolytech&#x60;, &#x60;KFU&#x60;.
     * @param {Array<string>} [orderBy] Order users by fields &#x60;ENABLED&#x60;, &#x60;NAME&#x60;, &#x60;FIRSTNAME&#x60;, &#x60;LASTNAME&#x60;, &#x60;ROLE&#x60;, &#x60;PHONENUMBER&#x60;, &#x60;EMAIL&#x60;, &#x60;REGION&#x60;, &#x60;DISTRICT&#x60;, &#x60;LOCALITY&#x60;, &#x60;SCHOOL&#x60;, &#x60;GROUPCODE&#x60;, &#x60;WORKPHONENUMBER&#x60;, &#x60;STUDYWORKPLACE&#x60;, &#x60;BIRTHDATE&#x60;, &#x60;CREATED&#x60;, &#x60;LASTPLAY&#x60;, &#x60;LASTACTIVITY&#x60;, &#x60;RESUME&#x60;, &#x60;DESIREDSTUDYPLACE&#x60;. Prefix &#x60;-&#x60; for descending, prefix &#x60;+&#x60; or empty for ascending.
     * @param {number} [pageNum] Page number, one-based.
     * @param {number} [pageSize] Page size, [1..1000], default &#x60;10&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async users(
      enabled?: boolean,
      id?: Array<string>,
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phoneNumber?: Array<string>,
      region?: Array<string>,
      district?: Array<string>,
      locality?: Array<string>,
      school?: Array<string>,
      groupCode?: Array<string>,
      email?: Array<string>,
      role?: Array<string>,
      workPhoneNumber?: Array<string>,
      studyWorkPlace?: Array<string>,
      birthDateFrom?: string,
      birthDateTo?: string,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      resume?: boolean,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.users(
        enabled,
        id,
        name,
        firstName,
        lastName,
        phoneNumber,
        region,
        district,
        locality,
        school,
        groupCode,
        email,
        role,
        workPhoneNumber,
        studyWorkPlace,
        birthDateFrom,
        birthDateTo,
        createdFrom,
        createdTo,
        lastPlayFrom,
        lastPlayTo,
        lastActivityFrom,
        lastActivityTo,
        resume,
        desiredStudyPlace,
        orderBy,
        pageNum,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete list of users by IDs, with delete `Player` users in `alb_svc_social` service.
     * @summary Delete Users
     * @param {IdList} [ids] User\&#39;s IDs to Delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDelete(
      ids?: IdList,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersDelete(
        ids,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Disable Users
     * @param {IdList} [ids] User\&#39;s IDs to Disable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDisable(
      ids?: IdList,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersDisable(
        ids,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Enable Users
     * @param {IdList} [ids] User\&#39;s IDs to Enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersEnable(
      ids?: IdList,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersEnable(
        ids,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * Create user and grant roles. Create/Update profile in `alb_svc_social` service for `Player`.
     * @summary Create User
     * @param {UserReprIdm} [userReprIdm] User Create Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postUsers(userReprIdm?: UserReprIdm, options?: any): AxiosPromise<string> {
      return localVarFp
        .postUsers(userReprIdm, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get users list with filtering and sorting. All the filters combined with `AND` clause. Multiple values in each filter use `OR` clause.
     * @summary Get Users
     * @param {boolean} [enabled]
     * @param {Array<string>} [id] List of required users with specific IDs.
     * @param {Array<string>} [name] Filter users by &#x60;firstName&#x60; OR &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [firstName] Filter users by &#x60;firstName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [lastName] Filter users by &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [phoneNumber] Filter users by &#x60;phoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [region] Filter users by &#x60;region&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [district] Filter users by &#x60;district&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [locality] Filter users by &#x60;locality&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [school] Filter users by &#x60;school&#x60; attribute, &#x60;&#x3D;&#x60;.
     * @param {Array<string>} [groupCode] Filter users by &#x60;groupCode&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [email] Filter users by &#x60;email&#x60;, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [role] Filter users with roles &#x60;HR-Admin&#x60;, &#x60;HR-Alabuga&#x60;, &#x60;HR-Staff&#x60;, &#x60;Player&#x60;.
     * @param {Array<string>} [workPhoneNumber] Filter users by &#x60;workPhoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {Array<string>} [studyWorkPlace] Filter users by &#x60;studyWorkPlace&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
     * @param {string} [birthDateFrom] Filter users by &#x60;bithDate&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
     * @param {string} [birthDateTo] Filter users by &#x60;bithDate&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
     * @param {string} [createdFrom] Filter users by &#x60;created&#x60; date time, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [createdTo] Filter users by &#x60;created&#x60; date time, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastPlayFrom] Filter users by &#x60;lastPlay&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastPlayTo] Filter users by &#x60;lastPlay&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastActivityFrom] Filter users by &#x60;lastActivity&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {string} [lastActivityTo] Filter users by &#x60;lastActivity&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
     * @param {boolean} [resume] Filter users by present filled &#x60;resume&#x60; attribute.
     * @param {Array<string>} [desiredStudyPlace] Filter users with &#x60;desiredStudyPlace&#x60; attribute, case sensitive &#x60;&#x3D;&#x60;. &#x60;notAnEntrant&#x60;, &#x60;alabugaPolytech&#x60;, &#x60;KFU&#x60;.
     * @param {Array<string>} [orderBy] Order users by fields &#x60;ENABLED&#x60;, &#x60;NAME&#x60;, &#x60;FIRSTNAME&#x60;, &#x60;LASTNAME&#x60;, &#x60;ROLE&#x60;, &#x60;PHONENUMBER&#x60;, &#x60;EMAIL&#x60;, &#x60;REGION&#x60;, &#x60;DISTRICT&#x60;, &#x60;LOCALITY&#x60;, &#x60;SCHOOL&#x60;, &#x60;GROUPCODE&#x60;, &#x60;WORKPHONENUMBER&#x60;, &#x60;STUDYWORKPLACE&#x60;, &#x60;BIRTHDATE&#x60;, &#x60;CREATED&#x60;, &#x60;LASTPLAY&#x60;, &#x60;LASTACTIVITY&#x60;, &#x60;RESUME&#x60;, &#x60;DESIREDSTUDYPLACE&#x60;. Prefix &#x60;-&#x60; for descending, prefix &#x60;+&#x60; or empty for ascending.
     * @param {number} [pageNum] Page number, one-based.
     * @param {number} [pageSize] Page size, [1..1000], default &#x60;10&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    users(
      enabled?: boolean,
      id?: Array<string>,
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phoneNumber?: Array<string>,
      region?: Array<string>,
      district?: Array<string>,
      locality?: Array<string>,
      school?: Array<string>,
      groupCode?: Array<string>,
      email?: Array<string>,
      role?: Array<string>,
      workPhoneNumber?: Array<string>,
      studyWorkPlace?: Array<string>,
      birthDateFrom?: string,
      birthDateTo?: string,
      createdFrom?: string,
      createdTo?: string,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      resume?: boolean,
      desiredStudyPlace?: Array<string>,
      orderBy?: Array<string>,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<UsersRes> {
      return localVarFp
        .users(
          enabled,
          id,
          name,
          firstName,
          lastName,
          phoneNumber,
          region,
          district,
          locality,
          school,
          groupCode,
          email,
          role,
          workPhoneNumber,
          studyWorkPlace,
          birthDateFrom,
          birthDateTo,
          createdFrom,
          createdTo,
          lastPlayFrom,
          lastPlayTo,
          lastActivityFrom,
          lastActivityTo,
          resume,
          desiredStudyPlace,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete list of users by IDs, with delete `Player` users in `alb_svc_social` service.
     * @summary Delete Users
     * @param {IdList} [ids] User\&#39;s IDs to Delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDelete(ids?: IdList, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersDelete(ids, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Disable Users
     * @param {IdList} [ids] User\&#39;s IDs to Disable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDisable(ids?: IdList, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersDisable(ids, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Enable Users
     * @param {IdList} [ids] User\&#39;s IDs to Enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersEnable(ids?: IdList, options?: any): AxiosPromise<void> {
      return localVarFp
        .usersEnable(ids, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for postUsers operation in UsersApi.
 * @export
 * @interface UsersApiPostUsersRequest
 */
export interface UsersApiPostUsersRequest {
  /**
   * User Create Info
   * @type {UserReprIdm}
   * @memberof UsersApiPostUsers
   */
  readonly userReprIdm?: UserReprIdm;
}

/**
 * Request parameters for users operation in UsersApi.
 * @export
 * @interface UsersApiUsersRequest
 */
export interface UsersApiUsersRequest {
  /**
   *
   * @type {boolean}
   * @memberof UsersApiUsers
   */
  readonly enabled?: boolean;

  /**
   * List of required users with specific IDs.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly id?: Array<string>;

  /**
   * Filter users by &#x60;firstName&#x60; OR &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly name?: Array<string>;

  /**
   * Filter users by &#x60;firstName&#x60;, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly firstName?: Array<string>;

  /**
   * Filter users by &#x60;lastName&#x60;, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly lastName?: Array<string>;

  /**
   * Filter users by &#x60;phoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly phoneNumber?: Array<string>;

  /**
   * Filter users by &#x60;region&#x60; attribute, &#x60;&#x3D;&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly region?: Array<string>;

  /**
   * Filter users by &#x60;district&#x60; attribute, &#x60;&#x3D;&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly district?: Array<string>;

  /**
   * Filter users by &#x60;locality&#x60; attribute, &#x60;&#x3D;&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly locality?: Array<string>;

  /**
   * Filter users by &#x60;school&#x60; attribute, &#x60;&#x3D;&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly school?: Array<string>;

  /**
   * Filter users by &#x60;groupCode&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly groupCode?: Array<string>;

  /**
   * Filter users by &#x60;email&#x60;, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly email?: Array<string>;

  /**
   * Filter users with roles &#x60;HR-Admin&#x60;, &#x60;HR-Alabuga&#x60;, &#x60;HR-Staff&#x60;, &#x60;Player&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly role?: Array<string>;

  /**
   * Filter users by &#x60;workPhoneNumber&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly workPhoneNumber?: Array<string>;

  /**
   * Filter users by &#x60;studyWorkPlace&#x60; attribute, case-insensitive &#x60;LIKE&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly studyWorkPlace?: Array<string>;

  /**
   * Filter users by &#x60;bithDate&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly birthDateFrom?: string;

  /**
   * Filter users by &#x60;bithDate&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DD&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly birthDateTo?: string;

  /**
   * Filter users by &#x60;created&#x60; date time, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly createdFrom?: string;

  /**
   * Filter users by &#x60;created&#x60; date time, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly createdTo?: string;

  /**
   * Filter users by &#x60;lastPlay&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly lastPlayFrom?: string;

  /**
   * Filter users by &#x60;lastPlay&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly lastPlayTo?: string;

  /**
   * Filter users by &#x60;lastActivity&#x60; attribute, with greater or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly lastActivityFrom?: string;

  /**
   * Filter users by &#x60;lastActivity&#x60; attribute, with lower or equal this value. Format &#x60;YYYY-MM-DDTHH:mm:ss.SSSZ&#x60;.
   * @type {string}
   * @memberof UsersApiUsers
   */
  readonly lastActivityTo?: string;

  /**
   * Filter users by present filled &#x60;resume&#x60; attribute.
   * @type {boolean}
   * @memberof UsersApiUsers
   */
  readonly resume?: boolean;

  /**
   * Filter users with &#x60;desiredStudyPlace&#x60; attribute, case sensitive &#x60;&#x3D;&#x60;. &#x60;notAnEntrant&#x60;, &#x60;alabugaPolytech&#x60;, &#x60;KFU&#x60;.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly desiredStudyPlace?: Array<string>;

  /**
   * Order users by fields &#x60;ENABLED&#x60;, &#x60;NAME&#x60;, &#x60;FIRSTNAME&#x60;, &#x60;LASTNAME&#x60;, &#x60;ROLE&#x60;, &#x60;PHONENUMBER&#x60;, &#x60;EMAIL&#x60;, &#x60;REGION&#x60;, &#x60;DISTRICT&#x60;, &#x60;LOCALITY&#x60;, &#x60;SCHOOL&#x60;, &#x60;GROUPCODE&#x60;, &#x60;WORKPHONENUMBER&#x60;, &#x60;STUDYWORKPLACE&#x60;, &#x60;BIRTHDATE&#x60;, &#x60;CREATED&#x60;, &#x60;LASTPLAY&#x60;, &#x60;LASTACTIVITY&#x60;, &#x60;RESUME&#x60;, &#x60;DESIREDSTUDYPLACE&#x60;. Prefix &#x60;-&#x60; for descending, prefix &#x60;+&#x60; or empty for ascending.
   * @type {Array<string>}
   * @memberof UsersApiUsers
   */
  readonly orderBy?: Array<string>;

  /**
   * Page number, one-based.
   * @type {number}
   * @memberof UsersApiUsers
   */
  readonly pageNum?: number;

  /**
   * Page size, [1..1000], default &#x60;10&#x60;.
   * @type {number}
   * @memberof UsersApiUsers
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for usersDelete operation in UsersApi.
 * @export
 * @interface UsersApiUsersDeleteRequest
 */
export interface UsersApiUsersDeleteRequest {
  /**
   * User\&#39;s IDs to Delete
   * @type {IdList}
   * @memberof UsersApiUsersDelete
   */
  readonly ids?: IdList;
}

/**
 * Request parameters for usersDisable operation in UsersApi.
 * @export
 * @interface UsersApiUsersDisableRequest
 */
export interface UsersApiUsersDisableRequest {
  /**
   * User\&#39;s IDs to Disable
   * @type {IdList}
   * @memberof UsersApiUsersDisable
   */
  readonly ids?: IdList;
}

/**
 * Request parameters for usersEnable operation in UsersApi.
 * @export
 * @interface UsersApiUsersEnableRequest
 */
export interface UsersApiUsersEnableRequest {
  /**
   * User\&#39;s IDs to Enable
   * @type {IdList}
   * @memberof UsersApiUsersEnable
   */
  readonly ids?: IdList;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Create user and grant roles. Create/Update profile in `alb_svc_social` service for `Player`.
   * @summary Create User
   * @param {UsersApiPostUsersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public postUsers(
    requestParameters: UsersApiPostUsersRequest = {},
    options?: any,
  ) {
    return UsersApiFp(this.configuration)
      .postUsers(requestParameters.userReprIdm, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get users list with filtering and sorting. All the filters combined with `AND` clause. Multiple values in each filter use `OR` clause.
   * @summary Get Users
   * @param {UsersApiUsersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public users(requestParameters: UsersApiUsersRequest = {}, options?: any) {
    return UsersApiFp(this.configuration)
      .users(
        requestParameters.enabled,
        requestParameters.id,
        requestParameters.name,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phoneNumber,
        requestParameters.region,
        requestParameters.district,
        requestParameters.locality,
        requestParameters.school,
        requestParameters.groupCode,
        requestParameters.email,
        requestParameters.role,
        requestParameters.workPhoneNumber,
        requestParameters.studyWorkPlace,
        requestParameters.birthDateFrom,
        requestParameters.birthDateTo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.lastPlayFrom,
        requestParameters.lastPlayTo,
        requestParameters.lastActivityFrom,
        requestParameters.lastActivityTo,
        requestParameters.resume,
        requestParameters.desiredStudyPlace,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete list of users by IDs, with delete `Player` users in `alb_svc_social` service.
   * @summary Delete Users
   * @param {UsersApiUsersDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDelete(
    requestParameters: UsersApiUsersDeleteRequest = {},
    options?: any,
  ) {
    return UsersApiFp(this.configuration)
      .usersDelete(requestParameters.ids, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Disable Users
   * @param {UsersApiUsersDisableRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDisable(
    requestParameters: UsersApiUsersDisableRequest = {},
    options?: any,
  ) {
    return UsersApiFp(this.configuration)
      .usersDisable(requestParameters.ids, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Enable Users
   * @param {UsersApiUsersEnableRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersEnable(
    requestParameters: UsersApiUsersEnableRequest = {},
    options?: any,
  ) {
    return UsersApiFp(this.configuration)
      .usersEnable(requestParameters.ids, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
