import { all } from "redux-saga/effects";
import { profileCompetenceSaga } from "./ducks/profile-competence/sagas";
import { profileInfoSaga } from "./ducks/profile-info/sagas";
import { sidebarInformationSaga } from "./ducks/sidebar-information/sagas";
import { simulationItemAdminSaga } from "./ducks/simulation-item-admin/sagas";
import { sessionsHistorySaga } from "./ducks/sessions-history/sagas";
import { usersControlSaga } from "./ducks/users-control/sagas";
import { contactsSaga } from "./ducks/contacts/sagas";
import { cdnSaga } from "./ducks/cdn/sagas";

export default function* rootSaga() {
  yield all([
    profileInfoSaga(),
    profileCompetenceSaga(),
    usersControlSaga(),
    simulationItemAdminSaga(),
    sidebarInformationSaga(),
    sessionsHistorySaga(),
    contactsSaga(),
    cdnSaga(),
  ]);
}
