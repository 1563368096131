export const getCoefficientText = (
  value?: number,
  emptyValue?: string
): string => {
  if (value === undefined || value === 0) {
    return emptyValue || "—";
  }

  if (value > 0) {
    return `+${value}`;
  }

  return String(value);
};
