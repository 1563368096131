import { Action } from "redux";
import { UserInfoInterface } from "../../types";

export enum ProfileInfoActionsType {
  CLEAN_PROFILE_INFO = "profileInfo/CLEAN_PROFILE_INFO",
  REQUEST_GET_PROFILE_INFO = "profileInfo/REQUEST_GET_PROFILE_INFO",
  REQUEST_POST_PROFILE_INFO = "profileInfo/REQUEST_POST_PROFILE_INFO",
  REQUEST_PUT_REPEAT_VERIFIED_EMAIL = "profileInfo/REQUEST_PUT_REPEAT_VERIFIED_EMAIL",
  SUCCESS_GET_PROFILE_INFO = "profileInfo/SUCCESS_GET_PROFILE_INFO",
  SUCCESS_POST_PROFILE_INFO = "profileInfo/SUCCESS_POST_PROFILE_INFO",
  SUCCESS_PUT_REPEAT_VERIFIED_EMAIL = "profileInfo/SUCCESS_PUT_REPEAT_VERIFIED_EMAIL",
  FAILURE_GET_PROFILE_INFO = "profileInfo/FAILURE_GET_PROFILE_INFO",
  FAILURE_POST_PROFILE_INFO = "profileInfo/FAILURE_POST_PROFILE_INFO",
  FAILURE_PUT_REPEAT_VERIFIED_EMAIL = "profileInfo/FAILURE_PUT_REPEAT_VERIFIED_EMAIL",
}

export interface CleanProfileInfoActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.CLEAN_PROFILE_INFO;
}

export interface RequestGetProfileInfoActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.REQUEST_GET_PROFILE_INFO;
  payload: {
    url: string;
    token: string | null;
    callback?: () => void;
  };
}

export interface RequestPostProfileInfoActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.REQUEST_POST_PROFILE_INFO;
  payload: {
    url: string;
    token: string | null;
    user: UserInfoInterface;
    clientId: string;
    redirectUri: string;
    callback?: () => void;
  };
}

export interface RequestPutRepeatVerifiedEmailActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.REQUEST_PUT_REPEAT_VERIFIED_EMAIL;
  payload: {
    redirectUri: string;
    clientId: string;
    callback?: () => void;
  };
}

export interface SuccessGetProfileInfoActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.SUCCESS_GET_PROFILE_INFO;
  payload: UserInfoInterface;
}

export interface SuccessPostProfileInfoActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.SUCCESS_POST_PROFILE_INFO;
  payload: UserInfoInterface;
}

export interface SuccessPutRepeatVerifiedEmailActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.SUCCESS_PUT_REPEAT_VERIFIED_EMAIL;
}

export interface FailureGetProfileInfoActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.FAILURE_GET_PROFILE_INFO;
}

export interface FailurePostProfileInfoActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.FAILURE_POST_PROFILE_INFO;
}

export interface FailurePutRepeatVerifiedEmailActionInterface
  extends Action<ProfileInfoActionsType> {
  type: ProfileInfoActionsType.FAILURE_PUT_REPEAT_VERIFIED_EMAIL;
}
