import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CommentInput from "./ui/comment-input/comment-input";
import CommentsList from "./ui/comments-list/comments-list";
import { Player } from "../../../generated/social";
import { $error, setComments, setServerTime, setUserId } from "./lib/comments";
import { useUnit } from "effector-react";
import { openPopup } from "../../../shared/components/base-popup/model";
import { EPopupName } from "../../../shared/components";
import { $profileCompetence } from "../popups/user-profile-popup/lib/profile";

interface Props {
  user: Player;
}

const UserComments: FC<Props> = ({ user }) => {
  const { t } = useTranslation();

  const profileCompetence = useUnit($profileCompetence);

  const error = useUnit($error);

  useEffect(() => {
    if (error) {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t(`errors.${error}`),
          isError: true,
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }

    if (user.comments) {
      setComments(user.comments);
    }

    if (profileCompetence.serverTime) {
      setServerTime(profileCompetence.serverTime);
    }
  }, [user]);

  return (
    <div className="user-comments-wrapper">
      <h2 className="user-comments-title">{t("profile.comments.title")}</h2>
      <CommentInput />
      <CommentsList />
    </div>
  );
};

export default UserComments;
