import { createEffect, createEvent, createStore, sample } from "effector";
import { cdnService } from "../../../../shared/api/base/api-instances";

// stores

export const $resumePreview = createStore<string | null>(null);

// events

export const getResumePreview = createEvent<string>();

export const removeResumePreview = createEvent();

// effects

export const requestResumePreviewFx = createEffect(async (id: string) => {
  const response = await cdnService
    .makeResumeRequest()
    .getPreviewResumeResumesIdPreviewGet({ id });
  return response.data;
});

// logic

sample({
  clock: getResumePreview,
  target: requestResumePreviewFx,
});

sample({
  clock: requestResumePreviewFx.doneData,
  fn: (data) => {
    const path = data.filename;
    if (path) {
      return `${process.env.REACT_APP_yandexStorageEndpointWebsite}/${process.env.REACT_APP_yandexStorageImagesBucket}/${path}`;
    }
    return null;
  },
  target: $resumePreview,
});

sample({
  clock: removeResumePreview,
  fn: () => null,
  target: $resumePreview,
});
