import { FC, ReactElement, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "../../";

import {
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "../../../models/dataGrid";

import { UserInfoInterface } from "../../../store/types";
import { useGenerateParticipantsColumns } from "src/entities/admin/participants/lib/useGenerateParticipantsColumns";
import { useGenerateParticipantsRows } from "src/entities/admin/participants/lib/useGenerateParticipantsRows";
import { User } from "src/generated/keycloak";

interface Props {
  users: UserInfoInterface[] | User[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  onSelectRows?: (params: string[]) => void;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
  changeCheckbox?: (id: string, key: string, event: boolean) => void;
  selectedRowsIds: string[];
}

export const ParticipantsTable: FC<Props> = ({
  loading,
  users,
  queryParams,
  onUpdateSortAndFilters,
  pagination,
  onSelectRows,
  goToPage,
  readmore,
  changeCheckbox,
  selectedRowsIds,
}): ReactElement => {
  const { i18n } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const generateColumns = useGenerateParticipantsColumns();
  const generateRows = useGenerateParticipantsRows();

  useEffect(() => {
    setColumns(generateColumns(queryParams));
  }, [queryParams, i18n.language]);

  useEffect(() => {
    setRows(generateRows(users));
  }, [users, i18n.language]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      onUpdateSelectedRows={onSelectRows}
      selectedRowsIds={selectedRowsIds}
      loading={loading}
      titleUppercase
      autoScrollDisabled
      nowrap
      changeCheckbox={changeCheckbox}
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
