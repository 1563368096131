import { sample } from "effector";

import { AdmCalcDto } from "src/generated/game";

import {
  getCalculations,
  GetGameCalculationsParams,
} from "src/shared/api/admin/calculations";

import { formulasDomain } from "./domain";

// Stores

export const $formulas = formulasDomain.createStore<AdmCalcDto[]>([]);

export const $isLoading = formulasDomain.createStore<boolean>(false);

// Events

export const fetchFormulas =
  formulasDomain.createEvent<GetGameCalculationsParams>();

// Effects

export const fetchFormulasFx = formulasDomain.createEffect(getCalculations);

// Logic

sample({ clock: fetchFormulas, target: fetchFormulasFx });

sample({
  clock: fetchFormulasFx.doneData,
  fn: ({ calcs }) => calcs || [],
  target: $formulas,
});

sample({
  clock: fetchFormulasFx.pending,
  target: $isLoading,
});
