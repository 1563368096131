import { FC, ReactElement, useMemo } from "react";

import { useUnit } from "effector-react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import {
  EPopupDataIdName,
  EPopupName,
  ParamsInfoItem,
  popupModel,
} from "src/shared/components";

import { GameType } from "src/generated/game";

import { LocalizedStr255, Result } from "src/generated/game/meta";

import { PlayerDto } from "src/generated/statistic";

import { getSimulationParamType, languagePicker } from "src/shared/helpers";

import { $assessmentResults } from "src/entities/public/assessments-results";

import {
  ESimulationParamsType,
  TParams,
  TUserAssessmentResultParams,
} from "src/shared/store/types";

import "./assessment-additional-parameters-popup.scss";

type TPlayerResult = TUserAssessmentResultParams | undefined;

const { $activePopups } = popupModel;

export const AssessmentAdditionalParametersPopup: FC = (): ReactElement => {
  const { i18n } = useTranslation();

  const { item: assessmentResults } = useUnit($assessmentResults);

  const activePopupsState = useUnit($activePopups);

  const playerId = useMemo(() => {
    const currentPopup = activePopupsState.find(
      ({ name }) => name === EPopupName.ASSESSMENT_ADDITIONAL_PARAMETERS,
    );

    const currentPopupDataId = currentPopup?.dataId?.find(
      ({ name }) => name === EPopupDataIdName.DEFAULT,
    )?.value;

    return currentPopupDataId || "";
  }, [activePopupsState]);

  const player: PlayerDto | undefined = assessmentResults?.players?.find(
    (item) => item.pId === playerId,
  );

  const playerResults: TPlayerResult =
    player?.results as TUserAssessmentResultParams;

  const resultsItems = () => {
    if (playerResults && assessmentResults) {
      return Object.keys(playerResults).map((item) => {
        const gameType: GameType = assessmentResults.gType;
        const resultItemMeta: Result | undefined =
          assessmentResults.rawMetaJson.gameTypes[gameType].results?.[item];
        const title: LocalizedStr255 | undefined = resultItemMeta?.title;
        const label: string = languagePicker(title, i18n.language);
        const type = resultItemMeta?.type as ESimulationParamsType;
        const param: TParams = playerResults[item];
        const className: string = `assessment-additional-parameters-popup__item--${type}`;
        const { isBoolean } = getSimulationParamType({
          templateParam: {
            type,
          },
        });

        return (
          <ParamsInfoItem
            key={item}
            className={classNames(
              "assessment-additional-parameters-popup__item",
              className,
            )}
            title={label}
            type={type}
            param={param}
            isBoldTitle={!isBoolean}
          />
        );
      });
    }
  };

  return (
    <div className="assessment-additional-parameters-popup">
      {resultsItems()}
    </div>
  );
};
