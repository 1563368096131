import { combine, sample } from "effector";

import { IDataGridColumn, IDataGridRow } from "src/shared/models";

import { $fines, $queryParams, finesDomain } from "./fines";

import { finesToRowsAdapter, generateColumns } from "../lib";

import { $currentLanguage } from "src/shared/helpers";

// Domains

const dataGrid = finesDomain.createDomain();

// Stores

export const $rows = dataGrid.createStore<IDataGridRow[]>([]);
export const $columns = dataGrid.createStore<IDataGridColumn[]>([]);
export const $archiveColumns = dataGrid.createStore<IDataGridColumn[]>([]);

sample({
  clock: combine($fines, $currentLanguage, finesToRowsAdapter),
  fn: (rows) => rows ?? [],
  target: $rows,
});

sample({
  clock: combine($queryParams, $currentLanguage, (queryParams) =>
    generateColumns(queryParams, false),
  ),
  target: $columns,
});

sample({
  clock: combine($queryParams, $currentLanguage, (queryParams) =>
    generateColumns(queryParams, true),
  ),
  target: $archiveColumns,
});
