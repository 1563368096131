import { socialService } from "../../../base/api-instances";

import {
  FineArchiveApiArchivePlayerFinesRequest,
  FinesApiGetPlayerFinesRequest,
  FinesApiPostPlayerFinesRequest,
} from "src/generated/social";

export const getList = (params: FinesApiGetPlayerFinesRequest) => {
  return socialService
    .makeFinesRequest()
    .getPlayerFines(params)
    .then((res) => res.data);
};

export const addFine = (fines: FinesApiPostPlayerFinesRequest) => {
  return socialService
    .makeFinesRequest()
    .postPlayerFines(fines)
    .then((res) => res.data);
};

export const archiveFine = (fines: FineArchiveApiArchivePlayerFinesRequest) => {
  return socialService
    .makeArchiveFinesRequest()
    .archivePlayerFines(fines)
    .then((res) => res.data);
};
