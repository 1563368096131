import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  BaseButton,
  BaseSelect,
  SelectItem,
  TextWithIcon,
} from "src/shared/components";

import "./selecting-desired-role.scss";
import { useTranslation } from "react-i18next";
import {
  AssessmentDto,
  AssessmentMemberResDto,
  GameType,
} from "../../../../../generated/game";
import { IBaseInputChange } from "../../../../../shared/models";
import { useUnit } from "effector-react";
import _ from "lodash";
import waitingStatusImg from "../../../../../shared/images/desired-role-popup-loader.svg";
import sessionSimulationImg from "../../../../../shared/images/session-simulation.png";
import { useSelector } from "react-redux";
import { selectRoomName } from "../../../../../shared/store/ducks/room";
import { Specialties } from "../../../../../shared/store/types";
import SessionSimulationTitle from "../../../../../shared/components/session-simulation-title/session-simulation-title";
import { useParams } from "react-router-dom";
import {
  $socketConnectionStatus,
  $socketGameV4RoomPlayerCommander,
  $socketGameV4RoomPlayerHandler,
  $socketGameV4UpdateRoomCommander,
  $socketGameV4UpdateRoomHandler,
  SocketConnectionStatus,
} from "src/shared/api/public/sockets/model/v4";
import { $keycloak } from "../../../../../entities/public/keycloak/model";
import { Skeleton } from "../../../../../shared/components/skeleton/Skeleton";

type TeamRole = "nursery" | "shop";

interface Props {
  assessment?: AssessmentDto;
  leaveRoom: () => void;
}

export const SelectingDesiredRolePage: FC<Props> = ({
  assessment,
  leaveRoom,
}): ReactElement | null => {
  const storeKeycloak = useUnit($keycloak);
  const room = useSelector(selectRoomName);

  const socketNonLobbyPlayerCommander = useUnit(
    $socketGameV4UpdateRoomCommander,
  );

  const socketNonLobbyPlayerHandler = useUnit($socketGameV4UpdateRoomHandler);

  const currentPlayerId: string | undefined = storeKeycloak?.subject;

  const [role, setRole] = useState<IBaseInputChange>();

  const [gameNum, setGameNum] = useState<IBaseInputChange>();

  const [areaNum, setAreaNum] = useState<IBaseInputChange>();

  const [connectionFlag, setConnectionFlag] = useState(false);

  const [isDistributedByHr, setIsDistributedByHr] = useState<boolean>(false);

  const [playerIsDistributedFlag, setPlayerIsDistributedFlag] = useState<
    boolean | undefined
  >(false);

  const socketConnectionStatus = useUnit($socketConnectionStatus);

  const { id: assessmentId } = useParams<{ id: string }>();

  const playerId = storeKeycloak?.subject;

  const socketPlayerRoom = useUnit($socketGameV4RoomPlayerHandler);

  useEffect(() => {
    socketPlayerRoom?.onQuit((data) => {
      if (data.pid === playerId) {
        setConnectionFlag(false);
      }
    });
  }, [playerId, socketPlayerRoom]);

  useEffect(() => {
    if (assessmentId && playerId)
      socketNonLobbyPlayerCommander?.getPlayerInfo({
        player_id: playerId ?? "",
        assessment_id: assessmentId,
      });
  }, [assessmentId, playerId, socketNonLobbyPlayerCommander]);

  const preConnectGetPlayerRole = useCallback(
    (playerInfo: AssessmentMemberResDto) => {
      if (!!playerInfo.role) {
        setIsDistributedByHr(true);
        setPlayerIsDistributedFlag(true);

        setGameNum({
          value: String(playerInfo.corpNum ?? playerInfo.teamNum),
        });

        setRole({
          value: String(playerInfo.role),
        });

        setAreaNum({
          value: String(playerInfo.areaNum),
        });
      }
    },
    [],
  );

  useEffect(() => {
    socketNonLobbyPlayerHandler?.playerRole(preConnectGetPlayerRole);
  }, [socketNonLobbyPlayerHandler, preConnectGetPlayerRole]);

  const socketPlayerRoomCommander = useUnit($socketGameV4RoomPlayerCommander);

  const { t } = useTranslation();
  useEffect(() => {
    if (room) {
      const currentPlayerRole = room.players.find(
        (player) => player.pid === currentPlayerId,
      );

      const gameNum = room.gameType === GameType.Team ? "teamNum" : "corpNum";

      if (currentPlayerRole) {
        const playerRole = currentPlayerRole.role;

        const playerNum = currentPlayerRole[gameNum];

        const playerAreaNum = currentPlayerRole.areaNum;

        if (playerRole) {
          setRole({ value: playerRole });
        }

        if (playerNum) {
          setGameNum({ value: String(playerNum) });
        }

        if (playerAreaNum) {
          setAreaNum({ value: String(playerAreaNum) });
        }

        setPlayerIsDistributedFlag(currentPlayerRole.isDistributed);
      }
    }
  }, [room.players, currentPlayerId, room]);

  const teamsRoles: TeamRole[] = useMemo(() => [], []);

  const selectItemNum: SelectItem[] = useMemo(() => {
    const selectItems: SelectItem[] = [];

    const possibleRoles: TeamRole[] = ["nursery", "shop"];

    possibleRoles.forEach((teamName) => {
      // @ts-ignore
      const maxTeams = assessment?.maxTeamsByName?.[teamName]?.maxTeams ?? 0;

      for (let i = 0; i < maxTeams; i++) {
        selectItems.push({
          value: String(selectItems.length + 1),
          label: `${t(`playerRoles.${teamName}`)} №${selectItems.length + 1}`,
        });

        teamsRoles.push(teamName);
      }
    });

    return selectItems;
  }, [assessment?.maxTeamsByName]);

  const connectPlayerToSession = useCallback(
    (role?: string, gameNum?: string, areaNum?: string | null): void => {
      if (role === Specialties.CEO) {
        areaNum = null;
      }

      const teamName = teamsRoles[Number(gameNum) - 1];

      const gameNumName = TeamGameType ? "teamNum" : "corpNum";

      socketPlayerRoomCommander?.join({
        aid: assessment?.id,
        role: role ? role : undefined,
        [gameNumName]: gameNum ? gameNum : undefined,
        areaNum: areaNum ? areaNum : undefined,
        teamName: teamName ? teamName : undefined,
      });

      setConnectionFlag(true);
    },
    [assessment?.id, socketPlayerRoomCommander, teamsRoles],
  );

  useEffect(() => {
    if (
      socketConnectionStatus === SocketConnectionStatus.RECONNECTING &&
      connectionFlag
    ) {
      connectPlayerToSession(role?.value, gameNum?.value, areaNum?.value);
    }
  }, [
    areaNum?.value,
    connectPlayerToSession,
    connectionFlag,
    gameNum?.value,
    role?.value,
    socketConnectionStatus,
  ]);

  if (!assessment) {
    return null;
  }

  const TeamGameType = assessment.gameType === GameType.Team;

  const selectItemRoles: SelectItem[] = Object.values(Specialties).map(
    (speciality) => {
      return {
        label: t(`playerRoles.${speciality}`),
        value: speciality,
      };
    },
  );

  const selectItemAreaNum: SelectItem[] = _.range(
    1,
    (assessment.maxAreas ?? 1) + 1,
  ).map((corpNum) => {
    return {
      label: `${t("common.area")} №${corpNum}`,
      value: String(corpNum),
    };
  });

  const unConnectPlayerToSession = (): void => {
    socketPlayerRoomCommander?.quit({
      aid: assessment.id,
    });

    setConnectionFlag(false);
  };

  const disableSelect = connectionFlag || isDistributedByHr;

  if (socketConnectionStatus === SocketConnectionStatus.DISCONNECTED) {
    return <Skeleton width={580} height={549} border={"40px"} />;
  }

  return (
    <div className="desired-role-container_wrapper">
      <div className="desired-role-container">
        <div className="desired-role-container__title">
          <img alt={"logo"} src={sessionSimulationImg}></img>
          <span>{assessment.title}</span>
        </div>
        <SessionSimulationTitle
          titleType={assessment.gameType}
          isConnectionFlag={connectionFlag}
        />
        <div className="desired-role-container__content">
          <BaseSelect
            menuPlacement="auto"
            placeholder={t("other.roleForm.chooseRole")}
            activeItem={role?.value}
            items={
              TeamGameType
                ? selectItemRoles.filter(
                    (role) => role.value !== Specialties.CEO,
                  )
                : selectItemRoles
            }
            disabled={disableSelect}
            onChange={(e) => {
              setRole(e);
            }}
          />
          <BaseSelect
            menuPlacement="auto"
            placeholder={t("other.roleForm.chooseTeam")}
            activeItem={gameNum?.value}
            items={selectItemNum}
            disabled={disableSelect}
            onChange={(e) => {
              setGameNum(e);
            }}
          />
          <BaseSelect
            menuPlacement="auto"
            placeholder={t("other.roleForm.chooseCity")}
            activeItem={areaNum?.value}
            items={selectItemAreaNum}
            hidden={role?.value === Specialties.CEO || TeamGameType}
            disabled={disableSelect}
            onChange={(e) => {
              setAreaNum(e);
            }}
          />
        </div>
        {connectionFlag && (
          <div className="desired-role-container-waiting_status">
            {playerIsDistributedFlag
              ? t("desired-role.popup.status.waitingStart")
              : t("desired-role.popup.status.waitingDistribution")}
            <div className="desired-role-container-waiting_status__image">
              <img src={waitingStatusImg} alt={"Waiting"}></img>
            </div>
          </div>
        )}
        <div className="desired-role-container__buttons">
          <BaseButton
            block
            secondary
            small
            outline
            onClick={() => {
              leaveRoom();
            }}
          >
            <TextWithIcon
              label={t("menu.btn.logout")}
              iconName="logout-red"
              isTextNowrap
              color="red"
              iconSize={22}
            />
          </BaseButton>
          {!connectionFlag && (
            <BaseButton
              block
              primary
              small
              onClick={() => {
                connectPlayerToSession(
                  role?.value,
                  gameNum?.value,
                  areaNum?.value,
                );
              }}
            >
              {t("sessions.session.join")}
            </BaseButton>
          )}
          {connectionFlag && !playerIsDistributedFlag && (
            <BaseButton primary block small onClick={unConnectPlayerToSession}>
              {t("common.cancel")}
            </BaseButton>
          )}
        </div>
      </div>
    </div>
  );
};
