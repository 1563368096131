import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { Pagination } from "../../../../../shared/api/base";

import {
  DownloadHistoryApiGetDownloadHistoryRatingRequest,
  RatingDownload,
} from "src/generated/social";
import { getExportHistory } from "../../../../../shared/api/admin/histories/model/export-history";

// Domain

export const exportRatingHistoryDomain = createDomain(
  "exportRatingHistoryAdmin",
);

// Events

export const fetchExportRatingHistory =
  exportRatingHistoryDomain.createEvent<DownloadHistoryApiGetDownloadHistoryRatingRequest>();

// Effects

export const fetchExportRatingHistoryFx =
  exportRatingHistoryDomain.createEffect(getExportHistory);

// Stores

export const $exportRatingHistoryPlayers =
  exportRatingHistoryDomain.createStore<RatingDownload[]>([]);

export const $pagination =
  exportRatingHistoryDomain.createStore<Pagination>(null);

export const $queryParams =
  exportRatingHistoryDomain.createStore<DownloadHistoryApiGetDownloadHistoryRatingRequest>(
    {},
  );

export const $isLoadingPlayers =
  exportRatingHistoryDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchExportRatingHistory,
  target: fetchExportRatingHistoryFx,
});

sample({
  clock: fetchExportRatingHistory,
  target: $queryParams,
});

sample({
  clock: fetchExportRatingHistoryFx.pending,
  target: $isLoadingPlayers,
});

spread({
  source: fetchExportRatingHistoryFx.doneData.map(
    ({ players, pagination }) => ({
      players,
      pagination: pagination || null,
    }),
  ),
  targets: {
    players: $exportRatingHistoryPlayers,
    pagination: $pagination,
  },
});
