import classNames from "classnames";

import { FC, ReactElement } from "react";

import { Bell } from "src/shared/components/icons-components";

import { useNotifications } from "src/shared/hooks/useNotifications";

import "./notifications-button.scss";

interface Props {
  className?: string;
  hideMobile?: boolean;
  onOpen?: () => void;
}

export const NotificationButton: FC<Props> = ({
  className,
  hideMobile,
  onOpen,
}): ReactElement => {
  const {
    newItems: newNotifications,
    isShowedPanel,
    toggleNotificationPanel,
  } = useNotifications({
    autoInit: true,
  });

  return (
    <button
      className={classNames("notifications-button", className, {
        "notifications-button--active": isShowedPanel,
        "notifications-button--hideMobile": hideMobile,
        "notifications-button--disabled": !isShowedPanel,
      })}
      onClick={() => {
        onOpen && onOpen();
        toggleNotificationPanel();
      }}
    >
      <Bell className="notifications-button__icon" />

      {newNotifications.length > 0 && (
        <div className="notifications-button__counter">
          {newNotifications.length > 9 ? "9+" : newNotifications.length}
        </div>
      )}
    </button>
  );
};
