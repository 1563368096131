import { createEffect, createEvent, createStore, sample } from "effector";
import { snackbarOpen } from "../../../../../../features/public/snackbar";
import { t } from "i18next";

export enum SocketConnectionStatus {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  RECONNECTING = "reconnecting",
}

export const $socketConnectionStatus = createStore<SocketConnectionStatus>(
  SocketConnectionStatus.DISCONNECTED,
);
export const setSocketConnectionStatus = createEvent<SocketConnectionStatus>();

export const showStatusFx = createEffect((status: SocketConnectionStatus) => {
  if (status === SocketConnectionStatus.DISCONNECTED) {
    snackbarOpen({
      visible: true,
      text: t("socket.disconnected"),
      type: "loading",
      time: 9999,
    });
  }

  if (status === SocketConnectionStatus.RECONNECTING) {
    snackbarOpen({
      text: t("socket.reconnecting"),
      type: "success",
      time: 5,
      visible: true,
    });
  }
});

sample({
  clock: setSocketConnectionStatus,
  fn: (socketConnectionStatus) => socketConnectionStatus,
  target: $socketConnectionStatus,
});

sample({ clock: $socketConnectionStatus, target: showStatusFx });
