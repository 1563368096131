import { simulationsAdminEntitiesDomain } from "./domain";

import { $simulations } from "./simulations";

// Events

export const resetSimulations = simulationsAdminEntitiesDomain.createEvent();

// Logic

$simulations.reset(resetSimulations);
