import { QueryParams } from "../../../../shared/api/base";
import { getDateForFilter, stringToArrayStrings } from "src/shared/helpers";
import { stringToArrayNumbers } from "src/shared/helpers/stringToArrayNumbers";
import { Params } from "./export-rates";
import {
  RejectionReasonEnum,
  RoleInRelatedProjectEnum,
  StatusCompanyEnum,
  StatusEmploymentEnum,
} from "src/generated/export";
import { renderAllCompetencyParams } from "../../../../shared/helpers/renderAdditionalParams";
import { ERegEx, regExList } from "../../../../shared/constants/regExList";

export const queryParamsToGetListParamsAdapter = (
  queryParams: QueryParams = {},
): Params => {
  const additionalCompetencies = queryParams.additionalCompetence?.split(",");

  return {
    firstName: stringToArrayStrings(queryParams.firstName),
    lastName: stringToArrayStrings(queryParams.lastName),
    phone: stringToArrayStrings(
      queryParams.phone?.replace(regExList[ERegEx.ONLY_NUMBERS], ""),
    ),
    email: stringToArrayStrings(queryParams.email),
    emailFull: queryParams.emailFull
      ? queryParams.emailFull === "true"
      : undefined,
    acquisitionChannel: stringToArrayStrings(queryParams.acquisitionChannel),
    acquisitionChannelNull: queryParams.acquisitionChannelNull
      ? queryParams.acquisitionChannelNull === "true"
      : undefined,
    groupCode: stringToArrayStrings(queryParams.groupCode),
    groupCodeNotNull: queryParams.groupCodeNotNull
      ? queryParams.groupCodeNotNull === "true"
      : undefined,
    roleInRelatedProjects: stringToArrayStrings(
      queryParams.roleInRelatedProjects,
    ) as RoleInRelatedProjectEnum[],
    country: stringToArrayStrings(queryParams.country),
    countryNull: queryParams.countryNull
      ? queryParams.countryNull === "true"
      : undefined,
    statusEmployment: stringToArrayStrings(
      queryParams.statusEmployment,
    ) as StatusEmploymentEnum[],
    statusCompany: stringToArrayStrings(
      queryParams.statusCompany,
    ) as StatusCompanyEnum[],
    rejectionReason: stringToArrayStrings(
      queryParams.rejectionReason,
    ) as RejectionReasonEnum[],
    birthdateFrom: getDateForFilter(queryParams.birthdateFrom),
    birthdateTo: getDateForFilter(queryParams.birthdateTo),
    birthdateNull: queryParams.birthdateNull
      ? queryParams.birthdateNull === "true"
      : undefined,
    dateOfGettingAnalyticsLvl05From: getDateForFilter(
      queryParams.dateOfGettingAnalyticsLvl05From,
    ),
    dateOfGettingAnalyticsLvl05To: getDateForFilter(
      queryParams.dateOfGettingAnalyticsLvl05To,
    ),
    dateOfGettingAnalyticsLvl1From: getDateForFilter(
      queryParams.dateOfGettingAnalyticsLvl1From,
    ),
    dateOfGettingAnalyticsLvl1To: getDateForFilter(
      queryParams.dateOfGettingAnalyticsLvl1To,
    ),
    region: stringToArrayNumbers(queryParams.region),
    regionNull: queryParams.regionNull
      ? queryParams.regionNull === "true"
      : undefined,
    district: stringToArrayNumbers(queryParams.district),
    districtNull: queryParams.districtNull
      ? queryParams.districtNull === "true"
      : undefined,
    school: stringToArrayNumbers(queryParams.school),
    schoolNull: queryParams.schoolNull
      ? queryParams.schoolNull === "true"
      : undefined,
    locality: stringToArrayStrings(queryParams.locality),
    localityNull: queryParams.localityNull
      ? queryParams.localityNull === "true"
      : undefined,
    studyWorkPlace: stringToArrayStrings(queryParams.studyWorkPlace),
    studyWorkPlaceNull: queryParams.studyWorkPlaceNull
      ? queryParams.studyWorkPlaceNull === "true"
      : undefined,
    confirmationSingle: queryParams.confirmationSingle
      ? queryParams.confirmationSingle === "true"
      : undefined,
    desiredSpecialty: stringToArrayStrings(queryParams.desiredSpecialty),
    desiredSpecialtyNull: queryParams.desiredSpecialtyNull
      ? queryParams.desiredSpecialtyNull === "true"
      : undefined,
    gPlaceFrom: queryParams.gPlaceFrom
      ? Number(queryParams.gPlaceFrom)
      : undefined,
    gPlaceTo: queryParams.gPlaceTo ? Number(queryParams.gPlaceTo) : undefined,
    gRateFrom: queryParams.gRateFrom
      ? Number(queryParams.gRateFrom)
      : undefined,
    gRateTo: queryParams.gRateTo ? Number(queryParams.gRateTo) : undefined,
    analyticsFrom: queryParams.analyticsFrom
      ? Number(queryParams.analyticsFrom)
      : undefined,
    analyticsTo: queryParams.analyticsTo
      ? Number(queryParams.analyticsTo)
      : undefined,
    analyticsTenacityFrom: queryParams.analyticsTenacityFrom
      ? Number(queryParams.analyticsTenacityFrom)
      : undefined,
    analyticsTenacityTo: queryParams.analyticsTenacityTo
      ? Number(queryParams.analyticsTenacityTo)
      : undefined,
    commandFrom: queryParams.commandFrom
      ? Number(queryParams.commandFrom)
      : undefined,
    commandTo: queryParams.commandTo
      ? Number(queryParams.commandTo)
      : undefined,
    commandTenacityFrom: queryParams.commandTenacityFrom
      ? Number(queryParams.commandTenacityFrom)
      : undefined,
    commandTenacityTo: queryParams.commandTenacityTo
      ? Number(queryParams.commandTenacityTo)
      : undefined,
    thinking3dFrom: queryParams.thinking3dFrom
      ? Number(queryParams.thinking3dFrom)
      : undefined,
    thinking3dTo: queryParams.thinking3dTo
      ? Number(queryParams.thinking3dTo)
      : undefined,
    economicsFrom: queryParams.economicsFrom
      ? Number(queryParams.economicsFrom)
      : undefined,
    economicsTo: queryParams.economicsTo
      ? Number(queryParams.economicsTo)
      : undefined,
    jurisprudenceFrom: queryParams.jurisprudenceFrom
      ? Number(queryParams.jurisprudenceFrom)
      : undefined,
    jurisprudenceTo: queryParams.jurisprudenceTo
      ? Number(queryParams.jurisprudenceTo)
      : undefined,
    communicationFrom: queryParams.communicationFrom
      ? Number(queryParams.communicationFrom)
      : undefined,
    communicationTo: queryParams.communicationTo
      ? Number(queryParams.communicationTo)
      : undefined,
    communicationTenacityFrom: queryParams.communicationTenacityFrom
      ? Number(queryParams.communicationTenacityFrom)
      : undefined,
    communicationTenacityTo: queryParams.communicationTenacityTo
      ? Number(queryParams.communicationTenacityTo)
      : undefined,
    lastActivityFrom: getDateForFilter(queryParams.lastActivityFrom),
    lastActivityTo: getDateForFilter(queryParams.lastActivityTo),
    lastActivityNull: queryParams.lastActivityNull
      ? queryParams.lastActivityNull === "true"
      : undefined,
    lastPlayFrom: getDateForFilter(queryParams.lastPlayFrom),
    lastPlayTo: getDateForFilter(queryParams.lastPlayTo),
    lastPlayNull: queryParams.lastPlayNull
      ? queryParams.lastPlayNull === "true"
      : undefined,
    createdFrom: getDateForFilter(queryParams.createdFrom),
    createdTo: getDateForFilter(queryParams.createdTo),
    orderBy: stringToArrayStrings(queryParams.orderBy),
    desiredStudyPlace: queryParams.desiredStudyPlace
      ? queryParams.desiredStudyPlace.split(",")
      : undefined,
    gender: stringToArrayStrings(queryParams.gender),
    ...renderAllCompetencyParams(
      additionalCompetencies,
      queryParams.additionalFrom,
      queryParams.additionalTo,
      queryParams.additionalCompetence,
    ),
    secretCode: queryParams.secretCode
      ? stringToArrayNumbers(queryParams.secretCode)
      : undefined,
  };
};
