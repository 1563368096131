import { sample } from "effector";

import { KeycloakInstance } from "keycloak-js";

import { keycloakDomain } from "./domain";

// Store

export const $keycloak = keycloakDomain.createStore<KeycloakInstance | null>(
  null,
);

// Event

export const setKeycloak = keycloakDomain.createEvent<KeycloakInstance>();

// Logic

sample({
  clock: setKeycloak,
  target: $keycloak,
});
