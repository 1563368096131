import { createDomain, sample } from "effector";

import { RelativeApiUntieRelativeRequest } from "../../../../generated/social";
import { unLinkRelative } from "../../../../shared/api/public/relatives/relatives";
import { fetchGetProfileRelativeUsers } from "../get-profile-relative-users/get-profile-relative-users";
import { snackbarOpen } from "../../../../features/public/snackbar";
import { t } from "i18next";

export const postUnlinkRelativeDomain = createDomain(
  "postUnlinkRelativePublic",
);

// Effects

export const fetchPostUnlinkRelativeFx =
  postUnlinkRelativeDomain.createEffect(unLinkRelative);

// Stores

export const $isLoading = postUnlinkRelativeDomain.createStore<boolean>(false);

// Events

export const fetchPostUnlinkRelative =
  postUnlinkRelativeDomain.createEvent<RelativeApiUntieRelativeRequest>();

sample({
  clock: fetchPostUnlinkRelative,
  target: fetchPostUnlinkRelativeFx,
});

sample({
  clock: fetchPostUnlinkRelativeFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchPostUnlinkRelativeFx.done,
  source: {},
  fn: () => {
    snackbarOpen({
      visible: true,
      text: t("relative.communicationCanceled"),
      type: "success",
      verticalCentered: true,
    });
  },
  target: fetchGetProfileRelativeUsers,
});
