import {
  AssessmentType,
  GameParamsTemplateDto,
  GameType,
  MaxTeams,
} from "../../../../generated/game";
import { getSimulationParamsForRequest } from "../../../../shared/helpers";
import { TMaxCount } from "./types";
import { ISimulationTemplate } from "../../../../shared/store/types";

export const getTemplates = (templateData: ISimulationTemplate[]) => {
  if (!!templateData.length) {
    return templateData.map((item) => {
      let localItem: GameParamsTemplateDto = {
        assessmentType: AssessmentType.Rating,
        params: getSimulationParamsForRequest(item.params),
      };

      const isTeamGameType: boolean =
        item.gameType === GameType.Team || item.gameType === GameType.Corp;

      const maxPlayersByRole: TMaxCount<number> = !isTeamGameType
        ? {}
        : undefined;

      const maxTeamsByName: TMaxCount<MaxTeams> = isTeamGameType
        ? {}
        : undefined;

      if (item.maxPlayersByRole && maxPlayersByRole) {
        const maxPlayersByRoleKeys: string[] = Object.keys(
          item.maxPlayersByRole,
        );

        maxPlayersByRoleKeys.forEach((key) => {
          maxPlayersByRole[key] = Number(item.maxPlayersByRole?.[key]);
        });
      }

      if (item.maxTeamsByName && maxTeamsByName) {
        const maxTeamsByNameKeys: string[] = Object.keys(item.maxTeamsByName);

        maxTeamsByNameKeys.forEach((key) => {
          maxTeamsByName[key] = {
            maxTeams: Number(item.maxTeamsByName?.[key]),
          };
        });
      }

      if (item.minRate !== undefined && item.maxRate !== undefined) {
        localItem.minRate = Number(item.minRate);
        localItem.maxRate = Number(item.maxRate);
      }

      localItem.gameType = item.gameType;
      localItem.maxPlayersByRole = maxPlayersByRole;
      localItem.maxTeamsByName = maxTeamsByName;
      localItem.title = item.title;
      localItem.id = item.id;
      localItem.maxAreas = item.maxAreas;
      return localItem;
    });
  }

  return [];
};
