import { sample } from "effector";

import { ResponseSms } from "src/generated/notification";

import {
  getSmsMessage,
  GetSmsMessage,
} from "src/shared/api/admin/notification/sms-notification";

import { smsNotificationDomain } from "./sms-notification";

// Event

export const getSms = smsNotificationDomain.createEvent<GetSmsMessage>();

// Effect

export const getSmsFx = smsNotificationDomain.createEffect(getSmsMessage);

// Store

export const $sms = smsNotificationDomain.createStore<ResponseSms | null>(null);

// Logic

sample({
  clock: getSms,
  target: getSmsFx,
});

sample({
  clock: getSmsFx.doneData,
  target: $sms,
});
