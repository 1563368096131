import { FC, useCallback, useMemo } from "react";

import {
  IDataGridColumnControlPanel,
  TDataGridDataParams,
} from "../../../models/dataGrid";

import { BaseSelect } from "../../form-fields";
import { SelectItem } from "../../form-fields/base-select/base-select";
import { DataGridControlsPanelGroup } from "./data-grid-controls-panel-group";
import { useTranslation } from "react-i18next";

interface Props {
  controlPanel: IDataGridColumnControlPanel;
  onEscapeKeyUp?: () => void;
  activeGroupKey: string;
  setActiveGroupKey: (val: string) => void;
  queryParams: TDataGridDataParams;
  onUpdateSortAndFilters: (params: TDataGridDataParams) => void;
  onSave: () => void;
  onReset: () => void;
}

export const DataGridControlsPanel: FC<Props> = ({
  controlPanel,
  onEscapeKeyUp,
  activeGroupKey,
  setActiveGroupKey,
  queryParams,
  onUpdateSortAndFilters,
  onSave,
  onReset,
}) => {
  const { t } = useTranslation();

  const groups = useMemo(
    () => controlPanel.groups || [],
    [controlPanel.groups]
  );

  const activeGroup = useMemo(
    () => groups.find((g) => g.key === activeGroupKey),
    [groups, activeGroupKey]
  );

  const groupListForSelect: SelectItem[] = groups.map(
    ({ title, key }): SelectItem => ({ label: title || key, value: key })
  );

  const onChangeFilter = useCallback(
    (key: string, val: string) => {
      const params = { ...queryParams };

      groups.forEach((g) => {
        if (g.key !== activeGroupKey) {
          g.filters?.forEach((filter) => {
            delete params[filter.key];
          });
        }
      });

      if (val) {
        params[key] = val;
      } else {
        delete params[key];
      }

      onUpdateSortAndFilters(params);
    },
    [groups, activeGroupKey, queryParams, onUpdateSortAndFilters]
  );

  const onChangeSort = useCallback(
    (value: string) => {
      const params = { ...queryParams };

      groups.forEach((g) => {
        // Если есть поле сортировки
        if (g?.sort?.orderKey) {
          // Все значения сортировки в текущей группе
          const groupSortValues = g.sort.items.map((item) => item.value);

          // Ищем значения текущей сортировки
          const sortField = params[g.sort?.orderKey];

          // Разбиваем текущее значение сортировки на массив
          let sortFieldValues = sortField ? sortField.split(",") : [];

          // Совпадает ли группа с выбранной
          if (g.key === activeGroupKey) {
            const isExistSort = sortFieldValues.includes(value);

            // Убираем лишнее значение сортировки для последующей замены
            sortFieldValues = sortFieldValues.filter(
              (val) => !groupSortValues.includes(val)
            );

            // Если обновленное значение указано
            if (value && !isExistSort) {
              sortFieldValues.push(value);
            }
          }
          // TODO: удалить в последствии, если не возникнет багов с сортировкой
          // else {
          //   // Убираем значения неактивной группы из сортировки
          //   sortFieldValues = sortFieldValues.filter((val) => val !== value);
          // }

          if (sortFieldValues.length > 0) {
            params[g.sort.orderKey] = sortFieldValues.join(",");
          } else {
            delete params[g.sort.orderKey];
          }
        }
      });

      onUpdateSortAndFilters(params);
    },
    [groups, queryParams, activeGroupKey, onUpdateSortAndFilters]
  );

  return (
    <div className="data-grid__control-panel">
      {groups.length > 1 && (
        <div className="data-grid__control-panel-group-list">
          <BaseSelect
            size="medium"
            forDarkBg
            placeholder={t("table.filters.notSelected")}
            activeItem={activeGroup?.key}
            name={activeGroup?.key}
            items={groupListForSelect}
            onChange={({ value }) => setActiveGroupKey(value)}
          />
        </div>
      )}
      {activeGroup && (
        <DataGridControlsPanelGroup
          key={activeGroupKey}
          group={activeGroup}
          onEscapeKeyUp={onEscapeKeyUp}
          onChangeFilter={onChangeFilter}
          onChangeSort={onChangeSort}
          queryParams={queryParams}
          onSave={onSave}
          onReset={onReset}
        />
      )}
    </div>
  );
};
