import {
  LocStrDto,
  TenacityEnabled,
  UpdGameReqDto,
} from "../../../../generated/game";
import { getTemplates } from "../lib/getTemplates";
import useInput, {
  EInputValidateType,
} from "../../../../shared/hooks/useInput";
import { requestPutSimulationItemAdmin } from "../../../../shared/store/ducks/simulation-item-admin";
import { getCalcs } from "../lib/getCalcs";
import { useNavigate } from "react-router-dom";
import {
  IFormulasData,
  IGameRules,
  ISimulationFormData,
  ISimulationTitleData,
} from "../lib/types";
import { ISimulationTemplate } from "../../../../shared/store/types";
import { store } from "../../../../shared/store/store";
import { useParams } from "react-router";
import { useErrorScroll } from "../../../../shared/hooks/useErrorScroll";
import { useQuery } from "../../../../shared/hooks/useQuery";

export const useOnSaveSimulation = () => {
  const { validateValue } = useInput();

  const navigate = useNavigate();

  const params = useParams<{ id: string }>();

  const { setIsErrorScroll } = useErrorScroll();

  const { getQuery } = useQuery();

  const selectedGameTypeQuery: string | null = getQuery("gameType");

  return (
    formTitleData: ISimulationTitleData,
    formData: ISimulationFormData,
    templateData: ISimulationTemplate[],
    simulationDescription: LocStrDto,
    gameRules: IGameRules,
    setFormDataErrors: (payload: Record<string, string>) => void,
    formulasData: IFormulasData,
    tenacityData?: TenacityEnabled,
  ) => {
    const options: UpdGameReqDto = {
      title: { ru: formTitleData.title_ru, en: formTitleData.title_en },
      apiKey: formData.apiKey,
      metaUrl: formData.metaUrl,
      createUrl: formData.createUrl,
      playUrl: formData.playUrl,
      tutorialPlayUrl: formData.tutorialPlayUrl,
      ltdTutorialPlayUrl: formData.ltdTutorialPlayUrl,
      finishUrl: formData.finishUrl,
      paramsTemplates: getTemplates(templateData),
      tenacityEnabled: tenacityData,
      desc: simulationDescription || undefined,
      gameRules: gameRules || undefined,
    };

    const localFormDataErrors = validateValue({
      title_ru: {
        value: formTitleData.title_ru,
        type: EInputValidateType.STRING,
        required: true,
      },
      title_en: {
        value: formTitleData.title_en,
        type: EInputValidateType.STRING,
        required: true,
      },
      metaUrl: {
        value: formData.metaUrl,
        type: EInputValidateType.STRING,
        required: true,
      },
      createUrl: {
        value: formData.createUrl,
        type: EInputValidateType.STRING,
        required: true,
      },
      playUrl: {
        value: formData.playUrl,
        type: EInputValidateType.STRING,
        required: true,
      },
      tutorialPlayUrl: {
        value: formData.tutorialPlayUrl,
        type: EInputValidateType.STRING,
        required: true,
      },
      ltdTutorialPlayUrl: {
        value: formData.ltdTutorialPlayUrl,
        type: EInputValidateType.STRING,
        required: true,
      },
      finishUrl: {
        value: formData.finishUrl,
        type: EInputValidateType.STRING,
        required: true,
      },
      apiKey: {
        value: formData.apiKey,
        type: EInputValidateType.STRING,
        required: true,
      },
    });

    const isFormDataErrors: boolean = !!Object.keys(localFormDataErrors).length;

    setIsErrorScroll(true);
    setFormDataErrors(localFormDataErrors);

    if (!isFormDataErrors && params.id) {
      store.dispatch(
        requestPutSimulationItemAdmin({
          id: params.id,
          simulationBody: options,
          calcsBody: getCalcs(formulasData),
          callback: () => navigate("/simulation-control"),
        }),
      );
    } else if (selectedGameTypeQuery) {
      navigate(`/simulation-control/simulation/${params.id}`);
    }
  };
};
