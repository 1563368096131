import { AdditionalCompetenceEnum } from "../models";
import { allAdditionalCompetences } from "./getAdditionalCompetences";

const splitAndFilterOrderBy = (orderBy: string, filterTerm: string) =>
  orderBy.split(",").filter((order) => order !== filterTerm);

export const updateAdditionalCompetence = (
  orderBy: string,
  additionalCompetencies: string[],
) => {
  if (
    orderBy?.includes("additionalCompetence") ||
    orderBy?.includes("-additionalCompetence")
  ) {
    const isNegative = orderBy.includes("-");
    const baseOrderBy = splitAndFilterOrderBy(
      orderBy,
      `${isNegative ? "-" : ""}additionalCompetence`,
    );
    return baseOrderBy
      .concat(
        additionalCompetencies?.map(
          (competence) => `${isNegative ? "-" : ""}${competence}`,
        ),
      )
      .join(",");
  }
};

export const renderAllCompetencyParams = (
  competencies: string[],
  additionalFrom: string,
  additionalTo: string,
  additionalCompetencies: string,
) => {
  const competence = "";

  return competencies?.reduce(
    (acc, key) => {
      if (allAdditionalCompetences.includes(key as AdditionalCompetenceEnum)) {
        acc[`${key}${competence}From`] = additionalCompetencies?.includes(key)
          ? additionalFrom
            ? Number(additionalFrom)
            : undefined
          : undefined;
        acc[`${key}${competence}To`] = additionalCompetencies?.includes(key)
          ? additionalTo
            ? Number(additionalTo)
            : undefined
          : undefined;
      }
      return acc;
    },
    {} as Record<string, number | undefined>,
  );
};

export const renderNullCompetence = (competencies: string[]) => {
  return competencies?.map((element) => element?.concat(""));
};
