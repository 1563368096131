import classNames from "classnames";
import { FC, ReactElement, ReactNode, useState } from "react";
import { BaseBox } from "../..";
import "./grid-accordion.scss";

interface Props {
  className?: string;
  headerSlot?: ReactElement;
  isShowed?: boolean;
  children?: ReactNode;
}

export const GridAccordion: FC<Props> = ({
  headerSlot,
  className,
  isShowed = false,
  children,
}): ReactElement => {
  const [isActive, setIsActive] = useState<boolean>(isShowed);

  const toggleAccordion = (): void => {
    setIsActive((prev) => !prev);
  };

  return (
    <BaseBox noPadding className={classNames("grid-accordion", className)}>
      <div className="grid-accordion">
        <div
          className={classNames("grid-accordion__header", {
            "grid-accordion__header--active": isActive,
          })}
          onClick={toggleAccordion}
        >
          <div className="grid-accordion__header-slot">{headerSlot}</div>
        </div>
        {isActive && <div className="grid-accordion__body">{children}</div>}
      </div>
    </BaseBox>
  );
};
