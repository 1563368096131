import { EPopupName, IPopupOpenPayload } from "../model/types";

export const openPopupHandler = (
  activePopup: IPopupOpenPayload[],
  payload: IPopupOpenPayload,
) => {
  const hasThisPopup: boolean = !!activePopup.find(
    ({ name }) => payload.name === name,
  );

  if (hasThisPopup) {
    return [
      ...activePopup.filter(({ name }) => payload.name !== name),
      payload,
    ];
  }

  return [...activePopup, payload];
};

export const showPopupHandler = (
  showedPopup: EPopupName[],
  { name }: IPopupOpenPayload,
) => {
  !showedPopup.length && document.body.classList.add("overflow-hidden");

  const hasThisPopup: boolean = showedPopup.includes(name);

  if (hasThisPopup) {
    return [...showedPopup.filter((popup) => popup !== name), name];
  }

  return [...showedPopup, name];
};
