import { FC, Fragment, MouseEventHandler, ReactElement } from "react";

import { useUnit } from "effector-react";

import { reflect } from "@effector/reflect";

import { Notification } from "src/shared/api/public/sockets/model/notifications";

import { t } from "src/shared/helpers";

import { IconArrow } from "src/shared/components/icons-components";

import { NotificationCardOnPanel } from "src/entities/public/notifications";

import { useNotifications } from "src/shared/hooks/useNotifications";

import {
  $newNotifications,
  $oldNotifications,
} from "src/entities/public/notifications/model";

import { markAsRead } from "src/features/public/notification";

import "./notifications-panel.scss";

interface Props {
  newNotificationsList?: Notification[];
  oldNotificationsList?: Notification[];
}

const View: FC<Props> = ({
  newNotificationsList,
  oldNotificationsList,
}): ReactElement => {
  const {
    toggleNotificationPanel,
    readmore: readMore,
    isShowedButtonMore,
  } = useNotifications();
  const readingNotifications = useUnit(markAsRead);

  const closePanel = () => {
    readingNotifications();
    toggleNotificationPanel(false);
  };

  const handleOverlayPanel: MouseEventHandler = (e) => {
    const isClickOnOverlay = e.target === e.currentTarget;

    if (isClickOnOverlay) closePanel();
  };

  const isOldEmpty = oldNotificationsList?.length === 0;

  const isNewEmpty = newNotificationsList?.length === 0;

  return (
    <div className="notifications-panel" onClick={handleOverlayPanel}>
      <div className="notifications-panel__wrapper">
        {/*<div className="notifications-panel__header">*/}
        {/*  <div className="notifications-panel__container">*/}
        {/*    <button*/}
        {/*      className="notifications-panel__button-close"*/}
        {/*      type="button"*/}
        {/*      onClick={closePanel}*/}
        {/*    >*/}
        {/*      <IconArrow*/}
        {/*        width={16}*/}
        {/*        height={16}*/}
        {/*        className="notifications-panel__icon notifications-panel__icon--arrow"*/}
        {/*      />*/}
        {/*    </button>*/}
        {/*    <h2 className="notifications-panel__title">*/}
        {/*      {t("notification.panel.title")}*/}
        {/*    </h2>*/}
        {/*  </div>*/}
        {/*  <button*/}
        {/*    className="notifications-panel__viewed"*/}
        {/*    type="button"*/}
        {/*    onClick={readingNotifications}*/}
        {/*  >*/}
        {/*    <Eye className="notifications-panel__icon" />*/}
        {/*    {t("notification.panel.viewed")}*/}
        {/*  </button>*/}
        {/*</div>*/}

        {!isNewEmpty && (
          <Fragment>
            <div className="notifications-panel__divide notifications-panel__divide--new">
              {t("notification.panel.group.new")}
            </div>
            {newNotificationsList?.map((notification) => (
              <Fragment key={notification.mid}>
                <NotificationCardOnPanel
                  closePanel={closePanel}
                  message={notification}
                />
              </Fragment>
            ))}
          </Fragment>
        )}
        {!isOldEmpty && (
          <Fragment>
            <div className="notifications-panel__divide">
              {t("notification.panel.group.old")}
            </div>
            {oldNotificationsList?.map((notification) => (
              <Fragment key={notification.mid}>
                <NotificationCardOnPanel
                  closePanel={closePanel}
                  message={notification}
                />
              </Fragment>
            ))}
          </Fragment>
        )}
        {isNewEmpty && isOldEmpty && (
          <Fragment>
            <div className="notifications-panel__placeholder">
              {t("notification.panel.noNotifications")}
            </div>
          </Fragment>
        )}

        {isShowedButtonMore && (
          <button
            className="notifications-panel__show-more"
            type="button"
            onClick={readMore}
          >
            <IconArrow className="notifications-panel__icon notifications-panel__icon--arrow-down" />
            {t("notification.panel.more.btn")}
          </button>
        )}
      </div>
    </div>
  );
};

export const NotificationPanel = reflect<Props>({
  view: View,
  bind: {
    newNotificationsList: $newNotifications,
    oldNotificationsList: $oldNotifications,
  },
});
