import { AdditionalCompetenceEnum } from "../models";

export const allAdditionalCompetences = Object.values(
  AdditionalCompetenceEnum,
) as AdditionalCompetenceEnum[];

export const getAdditionalCompetences = (dumpedFields: string[]) => {
  if (dumpedFields.includes("additionalCompetencies")) {
    allAdditionalCompetences.forEach((competence) => {
      dumpedFields.push(competence);
    });
  }

  return dumpedFields;
};
