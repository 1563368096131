import { PlayerRating } from "src/shared/api/admin/players";

import { IDataGridRow } from "src/shared/models/dataGrid";

import { getAvatarUrl } from "src/shared/helpers/pathToUserFile";

import { splitStrings } from "src/shared/helpers/splitStrings";
import i18n from "src/shared/i18n/i18n";

export const playersToRowsAdapter = (players: PlayerRating[]) => {
  return players.map<IDataGridRow>((player) => {
    const fullName = splitStrings([player.firstName, player.lastName]);

    return {
      id: player.playerId,
      firstName: player.firstName,
      lastName: player.lastName,
      fullName,
      phone: player.phone,
      avatarProps: {
        alt: fullName,
        fullName,
        customPath: getAvatarUrl(player.playerId),
        userId: player.playerId,
        link: `/ratings/rating/${player.playerId}`,
      },
      selected: false,
      desiredStudyPlace: player.desiredStudyPlace
        ? i18n.t(
            `userForm.select.desiredStudyPlace.${player.desiredStudyPlace}`,
            player.desiredStudyPlace,
          )
        : null,
      email: player.email,
      studyWorkPlace: player.studyWorkPlace,
      locality: player.locality,
      distributeButton: i18n.t("allocate-player.popup.button.distribute"),
    };
  });
};
