import { useEvent, useUnit } from "effector-react";

import { socketsModel } from "src/shared/api/public/sockets";

import { notificationsModel } from "src/entities/public/notifications";

import { useEffect } from "react";

interface UseNotificationsParams {
  autoInit?: boolean;
  autoLoadList?: boolean;
}

export function useNotifications({
  autoInit = false,
  autoLoadList = false,
}: UseNotificationsParams = {}) {
  const init = useEvent(socketsModel.notifications.init);
  const destroy = useEvent(socketsModel.notifications.destroy);

  const socket = useUnit(socketsModel.notifications.$socket);

  const getList = useEvent(notificationsModel.getList);

  const items = useUnit(notificationsModel.$notifications);
  const newItems = useUnit(notificationsModel.$newNotifications);
  const oldItems = useUnit(notificationsModel.$oldNotifications);
  const isListLoading = useUnit(notificationsModel.$isListLoading);

  const isConnectLoading = useUnit(
    socketsModel.notifications.$isConnectLoading,
  );

  const isConnected = useUnit(socketsModel.notifications.$isConnected);

  const isShowedPanel = useUnit(notificationsModel.$isShowedPanel);

  const isShowedButtonMore = useUnit(notificationsModel.$isShowedButtonMore);

  const toggleNotificationPanel = useEvent(
    notificationsModel.toggleNotificationPanel,
  );

  const readmore = useEvent(notificationsModel.readmore);

  useEffect(() => {
    if (autoInit) {
      init();
    }
  }, [autoInit, init, destroy]);

  useEffect(() => {
    if (autoLoadList && isConnected) {
      getList();
    }
  }, [autoLoadList, isConnected, getList, readmore]);

  return {
    init,
    destroy,

    getList,

    toggleNotificationPanel,
    readmore,

    socket,

    items,
    newItems,
    oldItems,

    isConnectLoading,
    isListLoading,
    isConnected,
    isShowedPanel,
    isShowedButtonMore,
  };
}
