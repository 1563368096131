import {
  AssessmentApiGetPAssessmentsRequest,
  AssessmentApiGetPRolesRequest,
  AssessmentsBriefResDto,
  PRolesResDto,
} from "../../../../generated/statistic";

import {
  ESessionsHistoryActions,
  ICleanSessionsHistory,
  IFailureSessionsHistory,
  IFailureSessionsHistoryRoles,
  IRequestSessionsHistory,
  IRequestSessionsHistoryRoles,
  ISetSessionsHistoryIsReadmore,
  ISuccessSessionsHistory,
  ISuccessSessionsHistoryRoles,
} from "./actionTypes";

export const cleanSessionsHistory = (): ICleanSessionsHistory => ({
  type: ESessionsHistoryActions.CLEAN_SESSIONS_HISTORY,
});

export const requestSessionsHistory = (payload: {
  params: AssessmentApiGetPAssessmentsRequest;
  callback?: () => void;
}): IRequestSessionsHistory => ({
  type: ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY,
  payload,
});

export const successSessionsHistory = (
  payload: AssessmentsBriefResDto,
): ISuccessSessionsHistory => ({
  type: ESessionsHistoryActions.SUCCESS_SESSIONS_HISTORY,
  payload,
});

export const failureSessionsHistory = (): IFailureSessionsHistory => ({
  type: ESessionsHistoryActions.FAILURE_SESSIONS_HISTORY,
});

export const requestSessionsHistoryRoles = (payload: {
  params: AssessmentApiGetPRolesRequest;
  callback?: () => void;
}): IRequestSessionsHistoryRoles => ({
  type: ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY_ROLES,
  payload,
});

export const setSessionsHistoryIsReadmore = (payload: {
  value: boolean;
}): ISetSessionsHistoryIsReadmore => ({
  type: ESessionsHistoryActions.SET_SESSIONS_HISTORY_IS_READMORE,
  payload,
});

export const successSessionsHistoryRoles = (
  payload: PRolesResDto,
): ISuccessSessionsHistoryRoles => ({
  type: ESessionsHistoryActions.SUCCESS_SESSIONS_HISTORY_ROLES,
  payload,
});

export const failureSessionsHistoryRoles =
  (): IFailureSessionsHistoryRoles => ({
    type: ESessionsHistoryActions.FAILURE_SESSIONS_HISTORY_ROLES,
  });

export type TSessionsHistoryActions =
  | ICleanSessionsHistory
  | IFailureSessionsHistory
  | IRequestSessionsHistory
  | ISuccessSessionsHistory
  | IFailureSessionsHistoryRoles
  | IRequestSessionsHistoryRoles
  | ISuccessSessionsHistoryRoles
  | ISetSessionsHistoryIsReadmore;
