import { FC, ReactElement } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import "./game-type-mode.scss";
import { BaseInfo } from "src/shared/components";
import openGameRulesPopup from "src/shared/helpers/openGameRulesPopup";

interface Props {
  isCompleted?: boolean;
  gameTitle: string;
  gameRule?: string;
}

export const GameTypeMode: FC<Props> = ({
  isCompleted,
  gameTitle,
  gameRule,
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="game-type-mode">
      <h5 className="game-type-mode__title">{t("common.mode")}</h5>
      <div
        className={classNames("game-type-mode__description", {
          "game-type-mode__description--completed": isCompleted,
        })}
      >
        {gameTitle}
        {!gameRule || gameRule === "" ? null : (
          <BaseInfo
            size={24}
            onClick={() => {
              openGameRulesPopup(gameRule);
            }}
          />
        )}
      </div>
    </div>
  );
};
