import { FC, memo, ReactElement } from "react";
import { pxToRem } from "../../helpers/pxToRem";
import "./base-info.scss";

interface Props {
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

const icon: FC<Props> = ({ size = 25, className, onClick }): ReactElement => {
  const iconSize = size ? pxToRem(size) : undefined;

  return (
    <div className="base-info" onClick={onClick}>
      <button type="button" className="base-info__button">
        <svg
          width={iconSize}
          height={iconSize}
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 23C10.6211 23 9.25574 22.7284 7.98182 22.2007C6.7079 21.6731 5.55039 20.8996 4.57538 19.9246C3.60036 18.9496 2.82694 17.7921 2.29926 16.5182C1.77159 15.2443 1.5 13.8789 1.5 12.5C1.5 11.1211 1.77159 9.75574 2.29926 8.48182C2.82694 7.2079 3.60036 6.05039 4.57538 5.07538C5.55039 4.10036 6.7079 3.32694 7.98182 2.79926C9.25574 2.27159 10.6211 2 12 2C14.7848 2 17.4555 3.10625 19.4246 5.07538C21.3938 7.04451 22.5 9.71523 22.5 12.5C22.5 15.2848 21.3938 17.9555 19.4246 19.9246C17.4555 21.8938 14.7848 23 12 23ZM12 20.75C14.188 20.75 16.2865 19.8808 17.8336 18.3336C19.3808 16.7865 20.25 14.688 20.25 12.5C20.25 10.312 19.3808 8.21354 17.8336 6.66637C16.2865 5.11919 14.188 4.25 12 4.25C9.81196 4.25 7.71354 5.11919 6.16637 6.66637C4.61919 8.21354 3.75 10.312 3.75 12.5C3.75 14.688 4.61919 16.7865 6.16637 18.3336C7.71354 19.8808 9.81196 20.75 12 20.75ZM12 11.75C12.3978 11.75 12.7794 11.908 13.0607 12.1893C13.342 12.4706 13.5 12.8522 13.5 13.25V16.25C13.5 16.6478 13.342 17.0294 13.0607 17.3107C12.7794 17.592 12.3978 17.75 12 17.75C11.6022 17.75 11.2206 17.592 10.9393 17.3107C10.658 17.0294 10.5 16.6478 10.5 16.25V13.25C10.5 12.8522 10.658 12.4706 10.9393 12.1893C11.2206 11.908 11.6022 11.75 12 11.75ZM12 7.25C12.3978 7.25 12.7794 7.40804 13.0607 7.68934C13.342 7.97064 13.5 8.35218 13.5 8.75C13.5 9.14782 13.342 9.52936 13.0607 9.81066C12.7794 10.092 12.3978 10.25 12 10.25C11.6022 10.25 11.2206 10.092 10.9393 9.81066C10.658 9.52936 10.5 9.14782 10.5 8.75C10.5 8.35218 10.658 7.97064 10.9393 7.68934C11.2206 7.40804 11.6022 7.25 12 7.25Z"
            fill="#8B8FA4"
          />
        </svg>
      </button>
    </div>
  );
};

export const BaseInfo = memo<Props>(icon);
