import { FC, ReactElement, useEffect } from "react";

import { AssessmentStatus } from "src/generated/ws4";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import { RoomStateInterface } from "src/shared/store/ducks/room";

const { closePopup } = popupModel;

type Props = {
  render: (...args: any) => ReactElement;
  room: RoomStateInterface;
};

export const ConfirmPopupLoadingHoc: FC<Props> = ({ render, room }) => {
  useEffect(() => {
    if (room.assessmentStatus === AssessmentStatus.IN_PROGRESS) {
      closePopup({ name: EPopupName.CONFIRM_ASSESSMENT_START });
    }
  }, [room.assessmentStatus]);

  return render(false);
};
