import { FC, useMemo, useRef } from "react";
import {
  ITeamTooltipItem,
  ITeamTooltipSection,
} from "../../../../../../shared/models";
import { useTranslation } from "react-i18next";
import { BaseTooltip } from "../../../../../../shared/components";
import styles from "../../session-table/session-table.module.scss";
import { IPlayer, ITeam } from "../../session-table/session-table";

interface Props {
  group: ITeam;
  corpNum?: number;
  areaNum?: number;
  teamNum?: number;
}

export const TeamReadinessTooltip: FC<Props> = ({
  group,
  corpNum = null,
  areaNum = null,
  teamNum = null,
}) => {
  const { t } = useTranslation();

  const toggleRef = useRef<HTMLSpanElement | null>(null);

  let players: IPlayer[] = group.players;

  if (areaNum) {
    players = group.players.filter((player) => player.areaNum === areaNum);
  }

  if (teamNum) {
    players = group.players.filter((player) => player.teamNum === teamNum);
  }

  const teamTooltipData: ITeamTooltipSection[] = useMemo(() => {
    let count = 0;

    let roles = group.roles;

    if (areaNum || teamNum) {
      roles = roles.filter((role) => role.value !== "ceo");
    }

    const playerRoles = roles.map((role) => {
      count = players.filter((player) => player.role === role.value).length;

      const maxRoleMembers = corpNum ? group.areaNums?.length : 1;

      return {
        count,
        role: role.value,
        total: role.value === "ceo" ? 1 : maxRoleMembers,
      };
    });

    return [
      {
        title: areaNum
          ? t("sessions.session.area.readiness")
          : t("sessions.session.team.readiness"),
        list: playerRoles,
      },
    ];
  }, [players]);

  const TEAM_MEMBERS = 5;

  const CEO = 1;

  const areasCount = group.areaNums?.length ?? 1;

  const corpMembers = areasCount * TEAM_MEMBERS + CEO;

  const maxTeamMembers = corpNum ? corpMembers : TEAM_MEMBERS;

  return (
    <span className={`tooltip-wrapper ${styles.listLength}`} ref={toggleRef}>
      {players.length}/{maxTeamMembers}
      <BaseTooltip tooltipToggleRef={toggleRef}>
        <div className="session__board">
          {teamTooltipData.map((team: ITeamTooltipSection, index: number) => (
            <div className="session__board-section" key={index}>
              {team.title && (
                <div className="session__board-title">{team.title}</div>
              )}
              <div className="session__board-grid">
                {team.list.map((item: ITeamTooltipItem) => (
                  <div className="session__board-block" key={item.role}>
                    <div className="session__board-label">
                      {t(`playerRoles.${item.role}`)}
                    </div>
                    <div className="session__board-count">
                      {item.count}/{item.total}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </BaseTooltip>
    </span>
  );
};
