import { sample } from "effector";

import { Socket } from "socket.io-client";

import { namespaces, paths, urls } from "../config";

import { connectSocket, SocketParams } from "../../lib/connect";

import { socketsV4Domain } from "./sockets";

import { LobbyHrSocketCommander } from "./commands";

import { RoomHrSocketHandler } from "./events";

// Store

export const $socketGameV4RoomHr = socketsV4Domain.createStore<Socket | null>(
  null,
);

export const $socketGameV4RoomHrCommander =
  socketsV4Domain.createStore<LobbyHrSocketCommander | null>(null);

export const $socketGameV4RoomHrHandler =
  socketsV4Domain.createStore<RoomHrSocketHandler | null>(null);

// Effects

const initGameV4RoomHrFx = socketsV4Domain.createEffect(connectSocket);

// Events

export const initGameV4RoomHr = socketsV4Domain.createEvent();

// Logic

sample({
  clock: initGameV4RoomHr,
  fn: (): SocketParams => ({
    path: paths.game.v4,
    url: urls.game,
    namespace: namespaces.game.room.hr,
  }),
  target: initGameV4RoomHrFx,
});

sample({
  clock: initGameV4RoomHrFx.doneData,
  target: $socketGameV4RoomHr,
});

sample({
  clock: $socketGameV4RoomHr,
  fn: (socket) => socket && LobbyHrSocketCommander.of(socket),
  target: $socketGameV4RoomHrCommander,
});

sample({
  clock: $socketGameV4RoomHr,
  fn: (socket) => socket && RoomHrSocketHandler.of(socket),
  target: $socketGameV4RoomHrHandler,
});
