import classNames from "classnames";
import { FC, ReactElement } from "react";

import "./base-group.scss";

interface Props {
  reverse?: boolean;
  left: ReactElement;
  leftWide?: boolean;
  right: ReactElement | null;
  isCityDateGroup?: boolean;
  isMobileColumn?: boolean;
  verticalAlignCenter?: boolean;
  className?: string;
}

export const BaseGroup: FC<Props> = ({
  left,
  leftWide,
  right,
  isCityDateGroup,
  reverse = false,
  isMobileColumn = false,
  verticalAlignCenter,
  className,
}): ReactElement => {
  return (
    <div
      className={classNames("base-group", className, {
        "base-group--reverse": reverse,
        "base-group--is-mobile-column": isMobileColumn,
        "base-group--vertical-align-center": verticalAlignCenter,
      })}
    >
      <div
        className={classNames("base-group__item", {
          "base-group__item--city-date": isCityDateGroup,
          "base-group__item--is-mobile-column": isMobileColumn,
          "base-group__item--full-width": leftWide,
        })}
      >
        {left}
      </div>
      {right && (
        <div
          className={classNames("base-group__item", {
            "base-group__item--city-date": isCityDateGroup,
            "base-group__item--is-mobile-column": isMobileColumn,
            "base-group__item--short-width": leftWide,
          })}
        >
          {right}
        </div>
      )}
    </div>
  );
};
