import { BaseButton, PageWrapper, TextWithIcon } from "../../shared/components";
import { useTranslation } from "react-i18next";
import { useUnit } from "effector-react";
import { useCallback, useEffect, useMemo } from "react";
import { TDataGridDataParams } from "../../shared/models";
import { getDateForFilter, togglePlaceParams } from "../../shared/helpers";
import isEqual from "lodash/isEqual";
import { useUrlState } from "../../shared/hooks/useUrlState";
import { ComplaintsDataGrid } from "../../shared/components/data-grids/complaints/complaints";
import { useUpdatedQueryParams } from "../../shared/hooks/useUpdatedQueryParams";
import {
  $complaints,
  $complaintsPagination,
  $isComplaintsLoading,
  fetchComplaints,
} from "../../entities/admin/complaints/model/complaints";
import { ComplaintsApiGetComplaintsRequest } from "../../generated/social";

export const ComplaintsPage = () => {
  const { t } = useTranslation();

  const isLoading = useUnit($isComplaintsLoading);

  const [urlState, setUrlState] = useUrlState();

  const users = useUnit($complaints);

  const { updatedQueryParams } = useUpdatedQueryParams();

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (!isEqual(queryParams, newFilteredParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newFilteredParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const pagination = useUnit($complaintsPagination);

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams: ComplaintsApiGetComplaintsRequest = useMemo(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      appelantFirstname,
      appelantLastname,
      defendantFirstname,
      defendantLastname,
      fromDateOfCreate,
      fromDateToCreate,
      complaintsType,
    } = urlState.query;

    const params: ComplaintsApiGetComplaintsRequest = {
      appelantFirstname: appelantFirstname ? appelantFirstname : undefined,
      appelantLastname: appelantLastname ? appelantLastname : undefined,
      defendantFirstname: defendantFirstname ? defendantFirstname : undefined,
      defendantLastname: defendantLastname ? defendantLastname : undefined,
      fromDateOfCreate: getDateForFilter(fromDateOfCreate),
      toDateOfCreate: getDateForFilter(fromDateToCreate),
      types: complaintsType ? complaintsType.split(",") : undefined,
      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
      status: "pending",
    };

    return params;
  }, [urlState.query]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  useEffect(() => {
    if (updatedQueryParams) {
      fetchComplaints(pageParams);
    }
  }, [pageParams, updatedQueryParams]);

  return (
    <>
      <div className="moderation-page">
        <PageWrapper
          isLightBlueBackground
          title={t("title.complaints")}
          isLoadingPanel={isLoading}
          tabsPanelSlot={
            <div className="table__control">
              {filtersLength > 0 && (
                <BaseButton
                  className="table__control-btn"
                  onClick={clearFilterSettings}
                  lightBlue
                >
                  <TextWithIcon
                    label={t("table.filters.reset")}
                    iconName={"cross-blue"}
                  />
                </BaseButton>
              )}
            </div>
          }
        >
          <div className="moderation-page-data-grid-wrapper">
            <ComplaintsDataGrid
              items={users}
              autoScrollDisabled
              pagination={pagination}
              loading={isLoading}
              onUpdateSortAndFilters={onUpdateQueryParams}
              queryParams={urlState.query}
              goToPage={(pageNum) => goToPage(pageNum)}
              readmore={(pageNum) => goToPage(pageNum)}
            />
          </div>
        </PageWrapper>
      </div>
    </>
  );
};
