import axios from "axios";
import { Configuration, ExportFromSocialApi } from "src/generated/export";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_EXPORT_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_EXPORT_URL;

    config.headers = {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const makeExportBlobRequest = () => {
  return new ExportFromSocialApi(
    new Configuration({
      baseOptions: {
        responseType: "blob",
        headers: {
          "Content-Type": "application/zip",
        },
      },
    }),
    "",
    apiInstance,
  );
};

export const exportService = {
  apiInstance,
  makeExportBlobRequest,
};
