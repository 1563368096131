import { FC, ReactElement, useEffect } from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import {
  BaseButton,
  BasePopup,
  EPopupName,
  popupModel,
} from "src/shared/components";

import { EUserSettingsTablesName } from "src/entities/public/user-settings";

import {
  $isLoading,
  $preTablesSettings,
  fetchUpdateUserSettingsFx,
  saveTableSettings,
  setColumnsVisibility,
} from "../../model";

import { ListCheckbox } from "../list-checkbox";

import "./table-settings-popup.scss";

const { closePopup } = popupModel;

interface Props {
  items: string[];
  tableName: EUserSettingsTablesName;
}

export const TableSettingsPopup: FC<Props> = ({
  items,
  tableName,
}): ReactElement => {
  const { t } = useTranslation();

  const preTableSettings = useUnit($preTablesSettings);

  const isLoading = useUnit($isLoading);

  const onChange = (columnName: string, value: boolean) => {
    setColumnsVisibility({
      tableName,
      columnName,
      value,
    });
  };

  useEffect(() => {
    const subscription = fetchUpdateUserSettingsFx.doneData.watch(() =>
      closePopup({ name: EPopupName.TABLE_SETTINGS }),
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <BasePopup
      name={EPopupName.TABLE_SETTINGS}
      title={t("table.settings.popup.title")}
    >
      <div className="table-settings-popup">
        <div className="table-settings-popup__description">
          {t("table.settings.popup.description")}
        </div>
        <div className="table-settings-popup__items">
          {items.map((item) => {
            const checked: boolean | undefined =
              preTableSettings?.[tableName]?.columnsVisibility?.[item];

            return (
              <ListCheckbox
                key={item}
                item={item}
                checked={checked !== undefined ? checked : true}
                onChange={onChange}
              />
            );
          })}
        </div>

        <BaseButton
          className="table-settings-popup__btn"
          onClick={saveTableSettings}
          disabled={isLoading}
          isLoading={isLoading}
          primary
          large
          block
        >
          {t("common.saveChanges")}
        </BaseButton>
      </div>
    </BasePopup>
  );
};
