import { createDomain, sample } from "effector";

import { countriesModel } from "src/entities/admin/countries";

import { $currentLanguage, t } from "src/shared/helpers";

// Domains

const editCountriesPage = createDomain();

// Stores

export const $pageTitle = editCountriesPage.createStore<string>(
  t("navigation.other.countries", "Countries"),
);

export const $isLoading = editCountriesPage.createStore<boolean>(false);

// Logic

sample({
  clock: $currentLanguage,
  fn: () => t("navigation.other.countries", "Countries"),
  target: $pageTitle,
});

sample({
  clock: countriesModel.$isSomeLoading,
  target: $isLoading,
});
