import classNames from "classnames";
import { FC, ReactElement } from "react";

import { useTranslation } from "react-i18next";
import { CompetenceEnum } from "../../models/competence";
import "./lvl-state.scss";

interface Props {
  competence: string;
  rate: number;
  diff: number;
  isDropdown?: boolean;
}

export const LvlState: FC<Props> = ({
  competence,
  rate,
  diff,
  isDropdown,
}): ReactElement => {
  const { t } = useTranslation();
  const width: string = `calc(100% - ${(10 - rate) * 10}%)`;
  const diffClass: string = diff > 0 ? "--increase" : "--decrease";
  const finalRate: string = rate.toFixed(2).toString().replace(".", ",");
  let competenceClass: string;
  let title: string;
  let fullTitle: string;

  switch (competence) {
    case CompetenceEnum.COMMUNICATION:
      competenceClass = "--communication";
      title = t("lvlState.title.communication");
      fullTitle = t("lvlState.title.communicationFull");
      break;
    case CompetenceEnum.ANALYTICS:
      competenceClass = "--analytics";
      title = t("lvlState.title.analytics");
      fullTitle = t("lvlState.title.analyticsFull");
      break;
    case CompetenceEnum.COMMAND:
      competenceClass = "--command";
      title = t("lvlState.title.command");
      fullTitle = t("lvlState.title.commandFull");
      break;
    case CompetenceEnum.THINKING_3D:
      competenceClass = "--thinking3d";
      title = t("lvlState.title.thinking3d");
      fullTitle = t("lvlState.title.thinking3dFull");
      break;
    case CompetenceEnum.ECONOMICS:
      competenceClass = "--economics";
      title = t("lvlState.title.economics");
      fullTitle = t("lvlState.title.economicsFull");
      break;
    case CompetenceEnum.JURISPRUDENCE:
      competenceClass = "--jurisprudence";
      title = t("lvlState.title.jurisprudence");
      fullTitle = t("lvlState.title.jurisprudenceFull");
      break;
    case CompetenceEnum.MICROELECTRONICS:
      competenceClass = "--microelectronics";
      title = t("lvlState.title.microelectronics");
      fullTitle = t("lvlState.title.microelectronicsFull");
      break;

    default:
      title = "";
      fullTitle = "";
      competenceClass = "";
      break;
  }

  return (
    <div
      className={classNames("lvl-state", {
        "lvl-state--is-dropdown": isDropdown,
      })}
      translate="no"
    >
      <div
        aria-label={`${fullTitle}: ${rate}`}
        className={classNames(
          "lvl-state__title",
          "lvl-state__title" + competenceClass,
          {
            "lvl-state__title_is_dropdown": isDropdown,
          },
        )}
      >
        {title}
      </div>
      {isDropdown ? null : (
        <div className="lvl-state__line" aria-hidden>
          <div
            style={{ width: width }}
            className={classNames(
              "lvl-state__line-inner",
              "lvl-state__line-inner" + competenceClass,
            )}
          />
        </div>
      )}
      <div
        className={classNames("lvl-state__rate", {
          "lvl-state__rate_is_dropdown": isDropdown,
        })}
        aria-hidden
      >
        {finalRate}
      </div>
      {diff !== 0 && !isDropdown && (
        <div
          aria-hidden
          className={classNames(
            "lvl-state__diff",
            "lvl-state__diff" + diffClass,
          )}
        />
      )}
    </div>
  );
};
