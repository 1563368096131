import { simulationsPublicEntitiesDomain } from "./domain";

import { $simulations } from "./simulations";

// Events

export const resetSimulation = simulationsPublicEntitiesDomain.createEvent();

// Logic

$simulations.reset(resetSimulation);
