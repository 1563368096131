import { FC, ReactElement, useCallback, useEffect, useRef } from "react";

import classNames from "classnames";

import { Competence } from "src/generated/social";

import { pxToRem } from "src/shared/helpers";

import { NumberItem } from "../number-item";

import "./custom-scale.scss";
import { isMainCompetence } from "../../../../helpers/isMainCompetence";

interface Props {
  rate: number;
  competence: Competence;
  isAdditional?: boolean;
}

export const CustomScale: FC<Props> = ({
  rate,
  competence,
  isAdditional,
}): ReactElement => {
  const isAdditionalCompetence = !isMainCompetence(competence);

  const integerRate = Math.floor(rate);

  const componentRef = useRef<HTMLDivElement | null>(null);

  let progressWrapWidth: string = `${rate * 10}%`;
  if (isAdditional && competence !== Competence.Jurisprudence) {
    progressWrapWidth = `${rate * 100}%`;
  }

  if (competence === Competence.Jurisprudence) {
    progressWrapWidth = `${(rate / 4) * 100}%`;
  }
  if (competence === Competence.Microelectronics) {
    progressWrapWidth = `${(rate / 10) * 100}%`;
  }

  const setComponentWidthVariable = useCallback(() => {
    if (componentRef.current) {
      const componentWidth: string = pxToRem(componentRef.current?.offsetWidth);

      const propertyNamePrefix = "--custom-scale-width--";
      const propertyNamePostfix = isAdditionalCompetence
        ? "additional-competence"
        : "main-competence";
      const fullPropertyName = propertyNamePrefix + propertyNamePostfix;

      document.body.style.setProperty(fullPropertyName, componentWidth);
    }
  }, []);

  useEffect(() => {
    setComponentWidthVariable();

    window.addEventListener("resize", setComponentWidthVariable);

    return () => {
      window.removeEventListener("resize", setComponentWidthVariable);
    };
  }, [setComponentWidthVariable]);

  let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  if (isAdditional && competence !== Competence.Jurisprudence) {
    numbers = [0, 1];
  }
  if (competence === Competence.Jurisprudence) {
    numbers = [0, 1, 2, 3, 4];
  }
  if (competence === Competence.Microelectronics) {
    numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }

  const numberItem = (number: number) => {
    return (
      <div
        className={classNames("custom-scale__number", {
          "custom-scale__number--current-lvl": number === integerRate,
        })}
      >
        <NumberItem
          className={classNames("custom-scale__number-item", {
            "custom-scale__number-item--current-lvl": number === integerRate,
          })}
          number={number}
          integerRate={integerRate}
          rate={rate}
          competence={competence}
        />
      </div>
    );
  };
  return (
    <div className="custom-scale-wrapper custom-scale-wrapper--mini-view">
      <div
        className={classNames("custom-scale", `custom-scale--${competence}`)}
        ref={componentRef}
      >
        <div
          className={classNames(
            "custom-scale__progress-bar",
            `custom-scale__progress-bar--${competence}`,
          )}
          style={{
            width: progressWrapWidth,
          }}
        />
        <div className="custom-scale__numbers">
          {Array(numbers.length)
            .fill(0)
            .map((_, index: number) => {
              const isNumberItem = numbers.indexOf(index) >= 0;

              return (
                <div key={index + "main"} className="custom-scale__number">
                  {isNumberItem && numberItem(index)}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
