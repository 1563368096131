import { useEffect, useMemo, useState } from "react";

export const useCheckUserAgent = () => {
  const [isChrome, setIsChrome] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isMacOS, setIsMacOS] = useState(false);

  const checkIsChrome = () => {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  };

  const checkIsIOS = () => {
    const platform = navigator.platform || navigator.userAgent;

    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const checkIsAndroid = () => {
    return navigator.userAgent.toLowerCase().indexOf("android") > -1; //&& ua.indexOf("mobile");
  };

  const checkIsMacOS = () => {
    const platform = navigator.platform || navigator.userAgent;

    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];

    return macosPlatforms.indexOf(platform) !== -1;
  };

  const isMobile = useMemo(() => {
    return isIOS || isAndroid;
  }, [isIOS, isAndroid]);

  const isDesktop = useMemo(() => {
    return !isMobile;
  }, [isMobile]);

  useEffect(() => {
    setIsChrome(checkIsChrome());
    setIsIOS(checkIsIOS());
    setIsAndroid(checkIsAndroid());
    setIsMacOS(checkIsMacOS());
  }, []);

  return {
    isChrome,
    isIOS,
    isAndroid,
    isMacOS,
    isMobile,
    isDesktop,
  };
};
