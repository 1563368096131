import { FC } from "react";

import {
  AssessmentInvite,
  AssessmentRevoke,
  AssessmentStatus,
  MsgType,
  Txt,
  WsMsg,
} from "src/generated/notification";

import { t } from "src/shared/helpers";

import { BaseCard } from "./base-card";

import { NewsCard } from "./news-card";

import { AssessmentCard, AssessmentCardProps } from "./assessment-card";

import { useRoomConnection } from "src/app/providers/room-context/room-context";

interface Props {
  message?: WsMsg;
  onClose?: (item?: WsMsg) => void;
}

export const NotificationCard: FC<Props> = ({ message, onClose }) => {
  const { inviteLink, room } = useRoomConnection();

  const playUrl = inviteLink || room.playUrl || "";

  if (message?.type === MsgType.Txt) {
    const item: Txt = message.msg as Txt;

    return (
      <BaseCard iconUrl={item.logoUrl} onClose={onClose} message={message}>
        <NewsCard title={item.title} description={item.body} link={item.link} />
      </BaseCard>
    );
  }

  if (message?.type === MsgType.AssessmentRevoke) {
    const item: AssessmentRevoke = message.msg as AssessmentRevoke;
    return (
      <BaseCard iconUrl={item.logoUrl} onClose={onClose} message={message}>
        <AssessmentCard
          title={item.aTitle}
          simulationTitle={item.gTitle}
          assessmentType={item.aType}
        />
      </BaseCard>
    );
  }

  if (message?.type === MsgType.AssessmentInvite) {
    const item: AssessmentInvite = message.msg as AssessmentInvite;

    const isLobbyStatus = item.aSt === AssessmentStatus.Lobby;

    const isClose =
      item.aSt === AssessmentStatus.Lobby ||
      item.aSt === AssessmentStatus.Finished;

    const lobbyUrl: string = `${process.env.REACT_APP_DOMAIN}/sessions/session/${item.aid}`;

    const badge: AssessmentCardProps["badgeProps"] = isLobbyStatus
      ? { text: t("popup.session.join.badge.wait"), theme: "wait" }
      : null;

    const cardTitle = isLobbyStatus
      ? t("popup.session.join")
      : t("popup.session.active");
    return (
      <BaseCard
        iconUrl={item.logoUrl}
        onClose={onClose}
        message={message}
        isClosable={isClose}
        title={cardTitle}
      >
        <AssessmentCard
          title={item.aTitle}
          simulationTitle={item.gTitle}
          assessmentType={item.aType}
          isRequired={item.required_participation}
          url={isLobbyStatus ? lobbyUrl : playUrl}
          type={isLobbyStatus ? "invite" : "connect"}
          badgeProps={badge}
        />
      </BaseCard>
    );
  }

  return null;
};
