import { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { call, put, takeLatest } from "redux-saga/effects";
import { openResponseErrorPopup } from "src/shared/components";
import {
  AvatarApi,
  ContactApi,
  InfoItem,
  ResumeApi,
} from "../../../../generated/cdn";
import { Configuration } from "../../../../generated/social";
import { $axiosCdn, $axiosCdnDownload } from "../../../api/api";
import {
  failureAddContactAvatar,
  failureAddResume,
  failureAddUserAvatar,
  failureDeleteContactAvatar,
  failureDeleteResume,
  failureDeleteUserAvatar,
  failureGetResume,
  failureOptionsContactAvatar,
  failureOptionsResume,
  failureOptionsUserAvatar,
  successAddContactAvatar,
  successAddResume,
  successAddUserAvatar,
  successDeleteContactAvatar,
  successDeleteResume,
  successDeleteUserAvatar,
  successGetResume,
  successOptionsContactAvatar,
  successOptionsResume,
  successOptionsUserAvatar,
} from "./actionCreators";
import {
  ECdnActions,
  IRequestAddContactAvatarAction,
  IRequestAddResumeAction,
  IRequestAddUserAvatarAction,
  IRequestDeleteContactAvatarAction,
  IRequestDeleteResumeAction,
  IRequestDeleteUserAvatarAction,
  IRequestGetResumeAction,
  IRequestOptionsContactAvatarAction,
  IRequestOptionsResumeAction,
  IRequestOptionsUserAvatarAction,
} from "./actionTypes";

export function* fetchAddContactAvatar({
  payload,
}: IRequestAddContactAvatarAction) {
  const request = new ContactApi(new Configuration(), "", $axiosCdn);

  try {
    const response: AxiosResponse = yield call(
      [request, request.uploadContactContactsPost],
      payload.options,
    );

    yield put(successAddContactAvatar());

    if (payload.callback) {
      payload.callback(response.data.filename);
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureAddContactAvatar());
  }
}

export function* fetchDeleteContactAvatar({
  payload,
}: IRequestDeleteContactAvatarAction) {
  const request = new ContactApi(new Configuration(), "", $axiosCdn);

  try {
    yield call(
      [request, request.deleteContactContactsIdDelete],
      payload.options,
    );

    yield put(successDeleteContactAvatar());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureDeleteContactAvatar());
  }
}

export function* fetchOptionsContactAvatar({
  payload,
}: IRequestOptionsContactAvatarAction) {
  const request = new ContactApi(new Configuration(), "", $axiosCdn);

  try {
    const response: AxiosResponse<InfoItem> = yield call([
      request,
      request.infoContactsInfoGet,
    ]);

    yield put(successOptionsContactAvatar({ options: response.data }));

    if (payload?.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureOptionsContactAvatar());
  }
}

export function* fetchAddResume({ payload }: IRequestAddResumeAction) {
  const request = new ResumeApi(new Configuration(), "", $axiosCdn);

  try {
    yield call([request, request.uploadResumeResumesIdPost], payload.options);

    yield put(successAddResume());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureAddResume());
  }
}

export function* fetchGetResume({ payload }: IRequestGetResumeAction) {
  const request = new ResumeApi(new Configuration(), "", $axiosCdnDownload);

  try {
    const response: AxiosResponse = yield call(
      [request, request.getResumeResumesIdGet],
      payload.options,
    );

    fileDownload(response.data, payload.fileName);

    yield put(successGetResume());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureGetResume());
  }
}

export function* fetchDeleteResume({ payload }: IRequestDeleteResumeAction) {
  const request = new ResumeApi(new Configuration(), "", $axiosCdn);

  try {
    yield call([request, request.deleteResumeResumesIdDelete], payload.options);

    yield put(successDeleteResume());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureDeleteResume());
  }
}

export function* fetchOptionsResume({ payload }: IRequestOptionsResumeAction) {
  const request = new ResumeApi(new Configuration(), "", $axiosCdn);

  try {
    const response: AxiosResponse<InfoItem> = yield call([
      request,
      request.infoResumesInfoGet,
    ]);

    yield put(successOptionsResume({ options: response.data }));

    if (payload?.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureOptionsResume());
  }
}

export function* fetchAddUserAvatar({ payload }: IRequestAddUserAvatarAction) {
  const request = new AvatarApi(new Configuration(), "", $axiosCdn);

  try {
    yield call([request, request.uploadAvatarAvatarsIdPost], payload.options);

    yield put(successAddUserAvatar());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureAddUserAvatar());
  }
}

export function* fetchDeleteUserAvatar({
  payload,
}: IRequestDeleteUserAvatarAction) {
  const request = new AvatarApi(new Configuration(), "", $axiosCdn);

  try {
    yield call([request, request.deleteAvatarAvatarsIdDelete], payload.options);

    yield put(successDeleteUserAvatar());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureDeleteUserAvatar());
  }
}

export function* fetchOptionsUserAvatar({
  payload,
}: IRequestOptionsUserAvatarAction) {
  const request = new AvatarApi(new Configuration(), "", $axiosCdn);

  try {
    const response: AxiosResponse<InfoItem> = yield call([
      request,
      request.infoAvatarsInfoGet,
    ]);

    yield put(successOptionsUserAvatar({ options: response.data }));

    if (payload?.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.detail?.[0]?.msg);

    yield put(failureOptionsUserAvatar());
  }
}

export function* cdnSaga() {
  yield takeLatest(
    ECdnActions.REQUEST_ADD_CONTACT_AVATAR,
    fetchAddContactAvatar,
  );
  yield takeLatest(
    ECdnActions.REQUEST_DELETE_CONTACT_AVATAR,
    fetchDeleteContactAvatar,
  );
  yield takeLatest(
    ECdnActions.REQUEST_OPTIONS_CONTACT_AVATAR,
    fetchOptionsContactAvatar,
  );
  yield takeLatest(ECdnActions.REQUEST_ADD_RESUME, fetchAddResume);
  yield takeLatest(ECdnActions.REQUEST_GET_RESUME, fetchGetResume);
  yield takeLatest(ECdnActions.REQUEST_DELETE_RESUME, fetchDeleteResume);
  yield takeLatest(ECdnActions.REQUEST_OPTIONS_RESUME, fetchOptionsResume);
  yield takeLatest(ECdnActions.REQUEST_ADD_USER_AVATAR, fetchAddUserAvatar);
  yield takeLatest(
    ECdnActions.REQUEST_DELETE_USER_AVATAR,
    fetchDeleteUserAvatar,
  );
  yield takeLatest(
    ECdnActions.REQUEST_OPTIONS_USER_AVATAR,
    fetchOptionsUserAvatar,
  );
}
