import classNames from "classnames";

import { useUnit } from "effector-react";

import { FC, ReactElement, useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
  BaseButton,
  ConfirmPopup,
  EPopupName,
  TextWithIcon,
} from "src/shared/components";

import { $isLoading, fetchResetPassword } from "../../model";

import { closePopup, openPopup } from "src/shared/components/base-popup/model";

import "./reset-password.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  clientId: string;
}

export const ResetPassword: FC<Props> = ({
  className,
  disabled,
  clientId,
}): ReactElement => {
  const { t } = useTranslation();

  const isLoading: boolean = useUnit($isLoading);

  const resetPasswordHandler = () => {
    fetchResetPassword({
      clientId,
      redirectUri: window.location.href,
    });

    closePopup({ name: EPopupName.RESET_PASSWORD });
  };

  const handleClick = () => openPopup({ name: EPopupName.RESET_PASSWORD });

  useEffect(() => {
    return () => {
      closePopup({ name: EPopupName.RESET_PASSWORD });
    };
  }, []);

  return (
    <div className={classNames("reset-password", className)}>
      <BaseButton
        disabled={disabled || isLoading}
        lightBlue
        onClick={handleClick}
        className="reset-password__button"
      >
        <TextWithIcon
          iconName="lock-filled"
          iconSize={24}
          label={t("usersControl.btn.passwordReset")}
        />
      </BaseButton>

      <ConfirmPopup
        popupName={EPopupName.RESET_PASSWORD}
        title={t("profile.popup.deletePassword.title")}
        description={t("profile.popup.deletePassword.description")}
        confirmBtnLabel={t("profile.popup.deletePassword.btn.confirm")}
        isConfirmBtnDisabled={isLoading}
        onConfirm={resetPasswordHandler}
      />
    </div>
  );
};
