import { gameService } from "src/shared/api/base/api-instances";
import {
  AssessmentMembersApiGetGameAssessmentAddMembersRequest,
  AssessmentMembersApiPatchGameAssessmentAddMembersRequest,
} from "src/generated/game";

export const getInvitedPlayers = (
  params: AssessmentMembersApiGetGameAssessmentAddMembersRequest,
) => {
  return gameService
    .makeAssessmentMembersRequest()
    .getGameAssessmentAddMembers(params)
    .then((response) => response.data);
};

export const patchAssessmentMembers = (
  params: AssessmentMembersApiPatchGameAssessmentAddMembersRequest,
) => {
  return gameService
    .makeAssessmentMembersRequest()
    .patchGameAssessmentAddMembers(params)
    .then((response) => response.data);
};
