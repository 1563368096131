import {
  FC,
  FormEvent,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocStrDto, Manager } from "../../../../generated/social";
import {
  AvatarUpload,
  BaseBox,
  BaseButton,
  FormControl,
} from "../../../../shared/components";
import {
  BaseInputEmail,
  BaseInputPhone,
  BaseInputText,
} from "../../../../shared/components/form-fields";
import { getContactAvatarUrl } from "../../../../shared/helpers/pathToUserFile";
import { toCloneObject } from "../../../../shared/helpers/toCloneObject";
import useInput, {
  EInputValidateType,
  IValidateValue,
} from "../../../../shared/hooks/useInput";
import { IBaseInputChange } from "../../../../shared/models/base-input";
import {
  requestAddContactAvatar,
  requestDeleteContactAvatar,
} from "../../../../shared/store/ducks/cdn/actionCreators";
import {
  selectCdnContactAvatarStatus,
  selectCdnOptionsContactAvatar,
} from "../../../../shared/store/ducks/cdn/selectors";
import { ERequestStatus } from "../../../../shared/store/types";
import { ContactPositions } from "../contact-positions/contact-positions";
import "./contacts-form.scss";

interface Props {
  btnLabel: string;
  isDisabledBtn: boolean;
  contactItem?: Manager;
  onSave: (contact: Manager) => void;
}

const defaultEmptyPositionItem: LocStrDto = { ru: "" };

export interface IContactFormData {
  fullName: string;
  phone: string;
  email: string;
  photo: string;
  position: LocStrDto;
}

export const ContactsForm: FC<Props> = ({
  btnLabel,
  contactItem,
  onSave,
  isDisabledBtn,
}): ReactElement => {
  const { t } = useTranslation();
  const { validateValue } = useInput();
  const dispatch = useDispatch();
  const optionsContactAvatar = useSelector(selectCdnOptionsContactAvatar);
  const statusContactAvatar = useSelector(selectCdnContactAvatarStatus);
  const isLoadingContactAvatar = statusContactAvatar === ERequestStatus.LOADING;
  const [formData, setFormData] = useState<IContactFormData>();
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [positionsErrors, setPositionsErrors] = useState<
    Record<string, string>
  >({});
  const [isErrorScroll, setIsErrorScroll] = useState<boolean>(false);
  const [avatarFile, setAvatarFile] = useState<File>();

  useEffect(() => {
    setFormData(() => {
      return {
        fullName: contactItem?.fullName || "",
        phone: contactItem?.phone || "",
        email: contactItem?.email || "",
        photo: contactItem?.photo ? getContactAvatarUrl(contactItem.photo) : "",
        position: contactItem?.position || defaultEmptyPositionItem,
      };
    });
  }, [contactItem]);

  useEffect(() => {
    const isEmptyLocalFormErrors = !Object.keys(formErrors).length;
    const isEmptyLocalPositionsErrors = !Object.keys(positionsErrors).length;
    const isError: boolean =
      (!isEmptyLocalPositionsErrors || !isEmptyLocalFormErrors) &&
      isErrorScroll;

    if (isError) {
      const errorField: Element | null = document.querySelector(".error-field");

      setIsErrorScroll(false);

      errorField?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [formErrors, positionsErrors, isErrorScroll]);

  const onChangeHandler = (payload: IBaseInputChange) => {
    setFormData((prev) => {
      const localPrev = toCloneObject(prev);

      if (payload.name && localPrev) {
        localPrev[payload.name as keyof IContactFormData] = payload.value;
      }

      return localPrev;
    });

    setFormErrors((prev) => {
      const localPrev = { ...prev };

      if (payload.name) {
        delete localPrev[payload.name as keyof IContactFormData];
      }

      return localPrev;
    });
  };

  const onChangeAvatarHandler = useCallback((file?: File, avatar?: string) => {
    if (avatar !== undefined) {
      setFormData((prev) => {
        const localPrev = toCloneObject(prev);

        if (localPrev) {
          localPrev.photo = avatar;
        }

        return localPrev;
      });
    }

    setFormErrors((prev) => {
      const localPrev = { ...prev };

      delete localPrev.photo;

      return localPrev;
    });

    setAvatarFile(file);
  }, []);

  const onSaveHandler = (e: FormEvent) => {
    e.preventDefault();

    if (formData) {
      const validatePositionsOptions = (): Record<string, string> => {
        let localErrors: {
          [key: string]: IValidateValue;
        } = {};

        Object.keys(formData.position).forEach((item) => {
          const localValue: string = formData.position[
            item as keyof LocStrDto
          ] as string;

          localErrors[item] = {
            value: localValue,
            type: EInputValidateType.STRING,
            required: true,
          };
        });

        return validateValue(localErrors);
      };

      const validateFormDataOptions: { [key: string]: IValidateValue } = {
        email: {
          value: formData.email,
          type: EInputValidateType.EMAIL,
          required: true,
        },
        fullName: {
          value: formData.fullName,
          type: EInputValidateType.STRING,
          required: true,
        },
        phone: {
          value: formData.phone,
          type: EInputValidateType.PHONE_NUMBER,
          required: true,
        },
        photo: {
          value: formData.photo,
          type: EInputValidateType.STRING,
          required: true,
        },
      };

      const localFormErrors = validateValue(validateFormDataOptions);
      const isEmptyLocalFormErrors = !Object.keys(localFormErrors).length;
      const isEmptyPositionsErrors = !Object.keys(validatePositionsOptions())
        .length;

      setIsErrorScroll(true);
      setFormErrors(localFormErrors);
      setPositionsErrors(validatePositionsOptions());

      if (isEmptyLocalFormErrors && isEmptyPositionsErrors) {
        const saveCallback = (fileName?: string) => {
          const id: string = fileName?.replace("contacts/", "") || "";
          const photo: string =
            contactItem?.photo && !fileName ? contactItem.photo : id;

          const contact: Manager = {
            fullName: formData.fullName,
            phone: formData.phone,
            email: formData.email,
            position: formData.position,
            photo,
          };

          onSave(contact);
        };

        const deleteCallback = () => {
          if (avatarFile) {
            dispatch(
              requestAddContactAvatar({
                options: {
                  file: avatarFile,
                },
                callback: saveCallback,
              }),
            );
          } else {
            saveCallback();
          }
        };

        if (avatarFile && contactItem?.photo) {
          dispatch(
            requestDeleteContactAvatar({
              options: {
                id: contactItem.photo,
              },
              callback: deleteCallback,
            }),
          );
        } else {
          deleteCallback();
        }
      }
    }
  };

  const onChangePositionsHandler = useCallback((items: LocStrDto): void => {
    setFormData((prev) => {
      const localPrev = toCloneObject(prev);

      if (localPrev) {
        localPrev.position = items;
      }

      return localPrev;
    });
  }, []);

  const isDisabled: boolean = isDisabledBtn || isLoadingContactAvatar;

  const uploadAvatarBlockDescription: string[] = [
    t("other.editContacts.uploadAvatarDescription.uploadPhoto"),
  ];

  const cropPopupDescription: string[] = [
    t("popup.editContacts.crop.contacts.description.willAsContactPhoto"),
  ];

  return (
    <div className="contacts-form">
      {!!formData && (
        <Fragment>
          <form className="contacts-form__form" onSubmit={onSaveHandler}>
            <BaseBox>
              <FormControl>
                <ContactPositions
                  onChange={onChangePositionsHandler}
                  positions={contactItem?.position || defaultEmptyPositionItem}
                  errors={positionsErrors}
                />
              </FormControl>
              <FormControl error={formErrors.fullName}>
                <BaseInputText
                  value={formData.fullName}
                  onChange={onChangeHandler}
                  placeholder={t(
                    "other.editContacts.field.placeholder.fullName",
                  )}
                  name="fullName"
                  error={!!formErrors.fullName}
                  required
                />
              </FormControl>
              <FormControl error={formErrors.email}>
                <BaseInputEmail
                  value={formData.email}
                  onChange={onChangeHandler}
                  placeholder={t("profile.info.txt.email")}
                  name="email"
                  required
                  error={!!formErrors.email}
                />
              </FormControl>
              <FormControl error={formErrors.phone}>
                <BaseInputPhone
                  value={formData.phone}
                  onChange={onChangeHandler}
                  placeholder={t("profile.info.txt.phone")}
                  name="phone"
                  required
                  error={!!formErrors.phone}
                />
              </FormControl>
              <BaseButton
                className="contacts-form__btn"
                submit
                primary
                block
                large
                onClick={onSaveHandler}
                disabled={isDisabled}
              >
                {btnLabel}
              </BaseButton>
            </BaseBox>
          </form>
          {optionsContactAvatar && (
            <div className="contacts-form__avatar">
              <AvatarUpload
                avatarUrl={formData.photo}
                onChange={onChangeAvatarHandler}
                error={formErrors.photo}
                isCircleCrop={false}
                fileSize={optionsContactAvatar.maxSize}
                cropRatio={0.86}
                formats={optionsContactAvatar.contentType}
                descriptions={uploadAvatarBlockDescription}
                cropDescriptions={cropPopupDescription}
                cropTitle={t("popup.editContacts.crop.title.contactPhoto")}
              />
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
