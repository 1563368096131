import { getCalcs } from "./getCalcs";
import { fetchTestFormulas } from "../../../../features/admin/formulas/model";
import { IFormulasData } from "./types";
import { SimulationAdminInterface } from "../../../../shared/store/types";

export const testFormulas = (
  simulation: SimulationAdminInterface,
  formulasData: IFormulasData,
) => {
  const formulasArr = getCalcs(formulasData).calcs.map(
    ({ formula, calcType, gameType, competence }) => {
      return {
        gType: gameType,
        formula,
        calcType,
        competence,
      };
    },
  );

  fetchTestFormulas({
    gameId: simulation.id,
    testCalcBody: {
      formulas: formulasArr,
    },
  });
};
