import { Action } from "redux";
import { Player } from "../../../../generated/social";

export enum ProfileCompetenceActionsType {
  CLEAN_PROFILE_COMPETENCE = "ProfileCompetence/CLEAN_PROFILE_COMPETENCE",
  REQUEST_PROFILE_COMPETENCE = "ProfileCompetence/REQUEST_PROFILE_COMPETENCE",
  SUCCESS_PROFILE_COMPETENCE = "ProfileCompetence/SUCCESS_PROFILE_COMPETENCE",
  FAILURE_PROFILE_COMPETENCE = "ProfileCompetence/FAILURE_PROFILE_COMPETENCE",
  SET_IS_SOCIAL = "ProfileCompetence/SET_IS_SOCIAL",
}

export interface CleanProfileCompetenceActionInterface
  extends Action<ProfileCompetenceActionsType> {
  type: ProfileCompetenceActionsType.CLEAN_PROFILE_COMPETENCE;
}

export interface RequestProfileCompetenceActionInterface
  extends Action<ProfileCompetenceActionsType> {
  type: ProfileCompetenceActionsType.REQUEST_PROFILE_COMPETENCE;
  payload: {
    id: string;
    callback?: () => void;
  };
}

export interface SuccessProfileCompetenceActionInterface
  extends Action<ProfileCompetenceActionsType> {
  type: ProfileCompetenceActionsType.SUCCESS_PROFILE_COMPETENCE;
  payload: Player;
}

export interface FailureProfileCompetenceActionInterface
  extends Action<ProfileCompetenceActionsType> {
  type: ProfileCompetenceActionsType.FAILURE_PROFILE_COMPETENCE;
}

export interface SetIsSocialInterface
  extends Action<ProfileCompetenceActionsType> {
  type: ProfileCompetenceActionsType.SET_IS_SOCIAL;
  payload: boolean;
}
