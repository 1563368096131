import { FC, memo, ReactElement } from "react";

interface Props {
  className?: string;
}

export const CrossIcon: FC<Props> = memo(
  ({ className }): ReactElement => (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6486 3.04658C13.1173 2.57795 13.1173 1.81815 12.6486 1.34952C12.18 0.88089 11.4202 0.88089 10.9516 1.34952L6.99786 5.30325L3.04413 1.34952C2.5755 0.88089 1.81571 0.88089 1.34708 1.34952C0.878448 1.81815 0.878448 2.57795 1.34708 3.04658L5.30081 7.0003L1.34708 10.954C0.878448 11.4227 0.878448 12.1825 1.34708 12.6511C1.81571 13.1197 2.5755 13.1197 3.04413 12.6511L6.99786 8.69736L10.9516 12.6511C11.4202 13.1197 12.18 13.1197 12.6486 12.6511C13.1173 12.1825 13.1173 11.4227 12.6486 10.954L8.69492 7.0003L12.6486 3.04658Z"
        fill="currentColor"
      />
    </svg>
  )
);
