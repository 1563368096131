import { ERequestStatus } from "../../types";
import { TCdnActions } from "./actionCreators";
import { ECdnActions } from "./actionTypes";
import { ICdnState } from "./type";

const initialState: ICdnState = {
  statusContactAvatar: ERequestStatus.NEVER,
  statusOptionsContactAvatar: ERequestStatus.NEVER,
  statusResume: ERequestStatus.NEVER,
  statusGetResume: ERequestStatus.NEVER,
  statusOptionsResume: ERequestStatus.NEVER,
  statusUserAvatar: ERequestStatus.NEVER,
  statusOptionsUserAvatar: ERequestStatus.NEVER,
  optionsContactAvatar: null,
  optionsResume: null,
  optionsUserAvatar: null,
};

export const cdnReducer = (state = initialState, action: TCdnActions) => {
  switch (action.type) {
    /*
     * AddContactAvatar
     */
    case ECdnActions.CLEAN_ADD_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_ADD_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_ADD_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_ADD_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.ERROR,
      };

    /*
     * DeleteContactAvatar
     */
    case ECdnActions.CLEAN_DELETE_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_DELETE_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_DELETE_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_DELETE_CONTACT_AVATAR:
      return {
        ...state,
        statusContactAvatar: ERequestStatus.ERROR,
      };

    /*
     * OptionsContactAvatar
     */
    case ECdnActions.CLEAN_OPTIONS_CONTACT_AVATAR:
      return {
        ...state,
        optionsContactAvatar: null,
        statusOptionsContactAvatar: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_OPTIONS_CONTACT_AVATAR:
      return {
        ...state,
        statusOptionsContactAvatar: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_OPTIONS_CONTACT_AVATAR:
      return {
        ...state,
        optionsContactAvatar: action.payload.options,
        statusOptionsContactAvatar: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_OPTIONS_CONTACT_AVATAR:
      return {
        ...state,
        statusOptionsContactAvatar: ERequestStatus.ERROR,
      };

    /*
     * AddResume
     */
    case ECdnActions.CLEAN_ADD_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_ADD_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_ADD_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_ADD_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.ERROR,
      };

    /*
     * GetResume
     */
    case ECdnActions.CLEAN_GET_RESUME:
      return {
        ...state,
        statusGetResume: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_GET_RESUME:
      return {
        ...state,
        statusGetResume: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_GET_RESUME:
      return {
        ...state,
        statusGetResume: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_GET_RESUME:
      return {
        ...state,
        statusGetResume: ERequestStatus.ERROR,
      };

    /*
     * DeleteResume
     */
    case ECdnActions.CLEAN_DELETE_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_DELETE_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_DELETE_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_DELETE_RESUME:
      return {
        ...state,
        statusResume: ERequestStatus.ERROR,
      };

    /*
     * OptionsResume
     */
    case ECdnActions.CLEAN_OPTIONS_RESUME:
      return {
        ...state,
        statusOptionsResume: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_OPTIONS_RESUME:
      return {
        ...state,
        statusOptionsResume: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_OPTIONS_RESUME:
      return {
        ...state,
        optionsResume: action.payload.options,
        statusOptionsResume: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_OPTIONS_RESUME:
      return {
        ...state,
        statusOptionsResume: ERequestStatus.ERROR,
      };

    /*
     * AddUserAvatar
     */
    case ECdnActions.CLEAN_ADD_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_ADD_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_ADD_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_ADD_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.ERROR,
      };

    /*
     * DeleteUserAvatar
     */
    case ECdnActions.CLEAN_DELETE_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_DELETE_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_DELETE_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_DELETE_USER_AVATAR:
      return {
        ...state,
        statusUserAvatar: ERequestStatus.ERROR,
      };

    /*
     * OptionsUserAvatar
     */
    case ECdnActions.CLEAN_OPTIONS_USER_AVATAR:
      return {
        ...state,
        optionsUserAvatar: null,
        statusOptionsUserAvatar: ERequestStatus.NEVER,
      };
    case ECdnActions.REQUEST_OPTIONS_USER_AVATAR:
      return {
        ...state,
        statusOptionsUserAvatar: ERequestStatus.LOADING,
      };
    case ECdnActions.SUCCESS_OPTIONS_USER_AVATAR:
      return {
        ...state,
        optionsUserAvatar: action.payload.options,
        statusOptionsUserAvatar: ERequestStatus.LOADED,
      };
    case ECdnActions.FAILURE_OPTIONS_USER_AVATAR:
      return {
        ...state,
        statusOptionsUserAvatar: ERequestStatus.ERROR,
      };
    default:
      return state;
  }
};
