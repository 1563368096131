export enum EPopupName {
  GAME_RULES = "game-rules",
  CREATE_UNRATED_SESSION = "create-unrated-session",
  RESET_PASSWORD = "reset-password",
  CONFIRM_RESET_PASSWORD_MYSELF = "confirm-reset-password-myself",
  CONFIRM_RESET_PASSWORD_USER = "confirm-reset-password-user",
  SETTING_RIGHTS = "setting-rights",
  LOCK_USERS = "lock-users",
  UNLOCK_USERS = "unlock-users",
  DELETE_USERS = "delete-users",
  UNTIE_USERS = "untie-users",
  SUCCESSFUL_PRESERVATION = "successful-preservation",
  FORCE_TERMINATE = "force-terminate",
  ASSESSMENT_MEMBERS = "assessment-members",
  ASSESSMENT_RESULT = "assessment-result",
  ASSESSMENT_PARAMETERS = "assessment-parameters",
  EMAIL_VERIFY = "email-verify",
  EMAIL_PREVIEW = "preview",
  DELETE_AVATAR = "delete-avatar",
  DELETE_RESUME = "delete-resume",
  TEST_FORMULAS_POPUP = "test-formulas-popup",
  BASE_MESSAGE_POPUP = "base-message-popup",
  FORMULA_INFO_EXAMPLES = "formulas-info-examples",
  CONFIRM_PRESENCE = "confirm-presence",
  CONFIRM_ASSESSMENT_DELETE = "confirm-assessment-delete",
  CONFIRM_ASSESSMENT_START = "confirm-assessment-start",
  CONFIRM_DELETE_PLAYER = "confirm-delete-player",
  CONFIRM_UNALLOCATE_PLAYER = "confirm-unallocate-player",
  CONFIRM_DELETE_CONTACT_ITEM = "confirm-delete-contact-item",
  AVATAR_CROP = "avatar-crop-popup",
  ADD_SIMULATION = "add-simulation",
  CONFIRM_PULL_META = "confirm-pull-meta",
  CONFIRM_DELETE_SIMULATION_TEMPLATE = "confirm-delete-simulation-template",
  SIMULATION_TEMPLATE = "simulation-template",
  ASSESSMENT_ADDITIONAL_PARAMETERS = "assessment-additional-parameters",
  DOWNLOAD_FILE = "download-file",
  DATAGRID_SORT = "datagrid-sort",
  DATAGRID_FILTERS = "datagrid-filters",
  SEND_NOTIFICATION = "send-notification",
  TERMINATE_TOURNAMENTS = "terminate-tournaments",
  GOOGLE_TRANSLATE = "google-translate",
  WELCOME = "welcome",
  INFORMATION_POPUP = "information-popup",
  REVOKE_INVITE = "revoke-invite",
  CREATE_TOURNAMENT = "create-tournament",
  TABLE_SETTINGS = "table-settings",
  TOURNAMENT_INVITE_RESULT = "tournament-invite-result",
  CAPTCHA = "captcha",
  RIGHTS_POPUP = "rights-popup",
  CONGRATULATION_POPUP = "congratulation-popup",
  FINISHED_SESSION_POPUP = "finished-session-popup",
  UNLOCK_POPUP = "unlock-popup",
  ROLE_POPUP = "role-selection-popup",
  DESIRED_ROLE_POPUP = "selected-desired-role-popup",
  ALLOCATE_PLAYER_POPUP = "allocate-player-popup",
  USER_RIGHTS_POPUP = "user-rights-popup",
  USER_PROFILE_POPUP = "user-profile-popup",
  LINKS_MENU_POPUP = "links-menu-popup",
  USER_DRAFT_POPUP = "user-draft-popup",
  ADD_FINES_POPUP = "add-fines-popup",
  COPY_SESSION_POPUP = "copy-session-popup",
  EDIT_FINES_POPUP = "fines-popup",
  DELETE_FINES_POPUP = "delete-fines-popup",
  ADD_ANOTHER_FINES_POPUP = "add-another-fines-popup",
  FINE_FOR_INACTION_POPUP = "fine-for-inaction-popup",
  KICK_UNDISTRIBUTED_PLAYERS = "kick-undistributed-players",
  INVITE_AGAIN = "invite-again",
  INVITE_RELATIVE = "invite-relative",
  INVITATION_POPUP = "invitation-popup",
  WAITING_RELATIVE_STATUS_POPUP = "waiting-relative-status-popup",
  UNLINK_RELATIVE_POPUP = "unlink-relative-popup",
  CONFIRM_SENDTOALL_POPUP = "confirm-sendtoall-popup",
  AGREEMENT_CONFIRMED = "agreement-confirmed-popup",
}

export interface IPopupMessage {
  text: string;
  type?: "default" | "response";
  isError?: boolean;
}

export enum EPopupDataIdName {
  DEFAULT = "default",
  VIEWED_USER = "viewed-user",
}

export interface IPopupDataId {
  value: string;
  name: EPopupDataIdName;
}

export interface IPopupOpenPayload {
  name: EPopupName;
  message?: IPopupMessage;
  dataId?: IPopupDataId[];
  data?: any;
  someBooleanTrigger?: boolean;
}

export interface IPopupClosePayload {
  name: EPopupName;
  callback?: () => void;
}

export interface IPopupCloseAllPayload {
  callback?: () => void;
}
