import { keycloakService } from "../../../base/api-instances/keycloak-service";

import { ResetPasswordParams } from "./query";
import {
  failureResetPassword,
  successResetPassword,
} from "../../../../../features/public/account/lib";

export const resetPassword = (params: ResetPasswordParams) => {
  keycloakService
    .makeAccountRequest()
    .resetPasswordEmail(params)
    .then(successResetPassword)
    .catch((error) => {
      failureResetPassword(error?.response?.data?.errorMessage);
    });
};
