import { sample } from "effector";

import {
  resendNotification,
  ResendNotification,
} from "src/shared/api/admin/notification/notifications";

import { $isLoading, notificationDomain } from "./notifications";

import {
  errorFx,
  successFx,
} from "src/entities/admin/notification/common-notification";

// Event

export const resendNotify =
  notificationDomain.createEvent<ResendNotification>();

// Effect

export const resendNotifyFx =
  notificationDomain.createEffect(resendNotification);

// Logic

sample({ clock: resendNotify, target: resendNotifyFx });

sample({ clock: resendNotifyFx.pending, target: $isLoading });

sample({ clock: resendNotifyFx.doneData, target: successFx });

sample({ clock: resendNotifyFx.fail, target: errorFx });
