import { Tooltip } from "react-tooltip";
import getKeyPoints from "../../helpers/getKeyPoints";
import styles from "./chart.module.scss";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ChartItem {
  label: string;
  value: number;
}

interface Props {
  data: ChartItem[];
}

export const Chart: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const [tooltip, setTooltip] = useState<ChartItem | null>(null);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState<boolean>(false);

  const highestValue = data.reduce(
    (highest, current) => (current.value > highest ? current.value : highest),
    0,
  );

  const keyPoints = getKeyPoints(data, 7);

  return (
    <div className={styles.chart_wrapper}>
      <div className={styles.y_labels}>
        {keyPoints.map((value, index) => (
          <p key={value + index}>{value}</p>
        ))}
      </div>

      <div className={styles.chart}>
        <div></div>
        {data.map((entry) => (
          <div
            className={styles.chart_bar}
            style={{ height: `${(entry.value / highestValue) * 100}%` }}
            onMouseEnter={() => {
              setTooltip(entry);
              setIsVisibleTooltip(true);
            }}
            onMouseLeave={() => setIsVisibleTooltip(false)}
          />
        ))}
        <div></div>

        <Tooltip
          anchorSelect={`.${styles.chart}`}
          float
          noArrow
          place="right"
          className={styles.tooltip}
          isOpen={isVisibleTooltip}
        >
          {t("navigation.users")}
          <span>{tooltip?.value}</span>
        </Tooltip>
      </div>

      <div></div>

      <div className={styles.x_labels}>
        <div></div>
        {data.map((entry) => (
          <div>{entry.label}</div>
        ))}
        <div></div>
      </div>
    </div>
  );
};
