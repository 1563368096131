import { createDomain, sample } from "effector";

import { QueryParams } from "../../../../shared/api/base";

import { getRegions, Region } from "../../../../shared/api/public/regions";

import { queryParamsToGetListParamsAdapter } from "../lib/queryParamsToGetParamsAdapter";

export const regionsDomain = createDomain("regionsPublic");

// Effects

export const fetchRegionsFx = regionsDomain.createEffect(getRegions);

// Stores

export const $regions = regionsDomain.createStore<Region[]>([]);

export const $isLoading = regionsDomain.createStore<boolean>(false);

// Events

export const fetchRegions = regionsDomain.createEvent<QueryParams>();

sample({
  clock: fetchRegions,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchRegionsFx,
});

sample({
  clock: fetchRegionsFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchRegionsFx.doneData,
  fn: ({ regions }) => regions,
  target: $regions,
});
