import { useDragLayer } from "react-dnd";
import styles from "./player-drag-layer.module.scss";
import {
  BaseAvatar,
  IconCross,
  TextWithIcon,
} from "../../../../../../../shared/components";
import React from "react";
import { IconCrossColor } from "../../../../../../../shared/components/icons-components/icon-cross/icon-cross";
import { DragItem } from "../../../../../../../shared/constants/dragEnums";

export const PlayerDragLayer = () => {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
      currentOffset: monitor.getSourceClientOffset(),
    }),
  );

  const getDraggedElementSize = () => {
    if (!isDragging || !item) {
      return { width: 0, height: 0 };
    }

    if (itemType === DragItem.PLAYER) {
      const draggedElement = document.getElementById("dragged-player");
      if (draggedElement) {
        const { width, height } = draggedElement.getBoundingClientRect();
        return { width, height };
      }
    }

    return { width: 0, height: 0 };
  };

  const { width, height } = getDraggedElementSize();

  const renderItem = () => {
    if (!isDragging || !currentOffset || !item) {
      return null;
    }

    return (
      <div
        className={styles.wrapper}
        style={{
          transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <TextWithIcon iconSize={20} iconName="drag-blue" hideLabel />
        <BaseAvatar isDrag={true} size={40} userId={item.pid} />
        <span className={styles.name}>
          {item.firstname} {item.lastname}
        </span>
        <span className={styles.buttonCross}>
          <IconCross size={20} color={IconCrossColor.RED} />
        </span>
      </div>
    );
  };

  return <div>{renderItem()}</div>;
};
