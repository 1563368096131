import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { useUnit } from "effector-react";

import {
  CalcType,
  Competence,
  GameType,
  TestFormula,
} from "src/generated/game";

import { getErrorFormula, toCloneObject } from "src/shared/helpers";

import { BaseBox } from "src/shared/components";

import { formulasModel } from "src/features/admin/formulas";

import { IFormulasData } from "../../lib/types";

import { FormulaInfoPopup, TestFormulasItem, TestFormulasPopup } from "../";

import "./formulas-block.scss";
import { EPopupName, popupModel } from "src/shared/components/base-popup";

interface Props {
  gameId: string;
  formulasData: IFormulasData;
  setFormulasData: Dispatch<SetStateAction<IFormulasData | null>>;
  gameType: GameType;
  className?: string;
  tenacity?: boolean;
}

const {
  resetTestResults,
  fetchTestSingleFormula,
  fetchTestSingleFormulaFx,
  $testResults,
  $isLoading,
} = formulasModel;

const { $activePopups, openPopup } = popupModel;

export const FormulasBlock: FC<Props> = ({
  gameId,
  formulasData,
  setFormulasData,
  gameType,
  className,
  tenacity,
}): ReactElement => {
  const { t } = useTranslation();

  const isLoadingTestFormula: boolean = useUnit($isLoading);

  const testFormulaItems = useUnit($testResults);

  const activePopups = useUnit($activePopups);

  const [selectedCalcType, setSelectedCalcType] = useState<CalcType>();

  const isTutorialGameType: boolean = gameType === GameType.Tutorial;

  const isTestFormulasPopup = useMemo(
    () =>
      activePopups.some(({ name }) => name === EPopupName.TEST_FORMULAS_POPUP),
    [activePopups],
  );

  const isFormulaInfoPopup = useMemo(
    () =>
      activePopups.some(
        ({ name }) => name === EPopupName.FORMULA_INFO_EXAMPLES,
      ),
    [activePopups],
  );

  useEffect(() => {
    const subscription = fetchTestSingleFormulaFx.doneData.watch(() => {
      openPopup({ name: EPopupName.TEST_FORMULAS_POPUP });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onTestFormula = ({
    formula,
    calcType,
    gType,
    competence,
  }: TestFormula) => {
    resetTestResults();

    fetchTestSingleFormula({
      gameId: gameId,
      testCalcBody: {
        formulas: [
          {
            formula,
            calcType,
            gType,
            competence,
          },
        ],
      },
    });
  };

  const onOpenFormulasInfoExamplesPopup = (calcType: CalcType): void => {
    setSelectedCalcType(calcType);

    openPopup({ name: EPopupName.FORMULA_INFO_EXAMPLES });
  };

  const onCloseFormulasInfoExamplesPopup = (): void => {
    setSelectedCalcType(undefined);
  };

  const onChangeCompetenceFormula = (
    formula: string,
    gameType: GameType,
    type: CalcType,
  ) => {
    setFormulasData((prev) => {
      const localPrev = toCloneObject(prev);

      if (localPrev) {
        localPrev.competenceFormulas[gameType][type] = formula;
      }

      return localPrev;
    });
  };

  const onChangeSingleFormula = (
    formula: string,
    gameType: GameType,
    type: CalcType,
  ) => {
    setFormulasData((prev) => {
      const localPrev = toCloneObject(prev);

      if (localPrev) {
        localPrev.singleFormulas[gameType][type] = formula;
      }

      return localPrev;
    });
  };

  const competenceFormulasKeys = Object.keys(
    formulasData.competenceFormulas[gameType],
  ) as CalcType[];

  const singleFormulasGameTypeKeys = Object.keys(
    formulasData.singleFormulas[gameType],
  ) as CalcType[];

  return (
    <div className={classNames("formulas-block", className)}>
      {competenceFormulasKeys.map((calcType: CalcType) => {
        Object.keys(
          formulasData.competenceFormulas[gameType][calcType],
        ) as Competence[];

        return (
          <BaseBox
            key={gameType + calcType}
            className={classNames(
              "formulas-block-type",
              `formulas-block-type--calc-type_${calcType}`,
            )}
          >
            <h3 className="formulas-block-type__title">
              {t(
                `simulationControl.simulationPage.modesBlock.subTitle.${calcType}`,
              )}
              <button
                type="button"
                className="formulas-block-type__question-btn"
                onClick={() => onOpenFormulasInfoExamplesPopup(calcType)}
              />
            </h3>
            {(() => {
              const formula =
                formulasData.competenceFormulas[gameType][calcType];
              return (
                <TestFormulasItem
                  className={classNames("formulas-block-type__test-field", {
                    "formulas-block-type__test-field--tenacity": tenacity,
                  })}
                  key={gameType + calcType}
                  gameType={gameType}
                  type={calcType}
                  value={formula}
                  error={
                    !!getErrorFormula(testFormulaItems, {
                      calcType,
                      gameType,
                    })
                  }
                  testBtnDisabled={isLoadingTestFormula || !formula.trim()}
                  onClickTest={() =>
                    onTestFormula({
                      formula,
                      calcType,
                      gType: gameType,
                    })
                  }
                  textareaSize="medium"
                  onChange={(e) =>
                    onChangeCompetenceFormula(e, gameType, calcType)
                  }
                  label={t(
                    "simulationControl.simulationPage.modesBlock.testFormulasItem.label.general",
                  )}
                />
              );
            })()}
          </BaseBox>
        );
      })}
      {!isTutorialGameType &&
        singleFormulasGameTypeKeys.map((calcType) => {
          const formula: string =
            formulasData.singleFormulas[gameType][calcType];
          return (
            <BaseBox
              key={gameType + calcType}
              className={classNames(
                "formulas-block-type",
                `formulas-block-type--calc-type_${calcType}`,
              )}
            >
              <h3 className="formulas-block-type__title">
                {t(
                  `simulationControl.simulationPage.modesBlock.subTitle.${calcType}`,
                )}
                <button
                  type="button"
                  className="formulas-block-type__question-btn"
                  onClick={() => onOpenFormulasInfoExamplesPopup(calcType)}
                />
              </h3>
              <TestFormulasItem
                className="formulas-block-type__test-field"
                key={gameType + calcType}
                gameType={gameType}
                type={calcType}
                value={formula}
                error={
                  !!getErrorFormula(testFormulaItems, { calcType, gameType })
                }
                testBtnDisabled={isLoadingTestFormula || !formula.trim()}
                onClickTest={() =>
                  onTestFormula({
                    formula,
                    calcType,
                    gType: gameType,
                  })
                }
                onChange={(e) => onChangeSingleFormula(e, gameType, calcType)}
                textareaSize="medium"
                label={t(
                  `simulationControl.simulationPage.modesBlock.testFormulasItem.label.${calcType}`,
                )}
              />
            </BaseBox>
          );
        })}
      {isTestFormulasPopup && <TestFormulasPopup formulas={formulasData} />}
      {isFormulaInfoPopup && selectedCalcType && (
        <FormulaInfoPopup
          calcType={selectedCalcType}
          onClose={onCloseFormulasInfoExamplesPopup}
        />
      )}
    </div>
  );
};
