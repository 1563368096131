import { createDomain, sample } from "effector";

import { QueryParams } from "../../../../shared/api/base";

import {
  District,
  getDistricts,
} from "../../../../shared/api/public/districts";

import { queryParamsToGetListParamsAdapter } from "../lib/queryParamsToGetParamsAdapter";

export const districtsDomain = createDomain("districtsPublic");

// Effects

export const fetchDistrictsFx = districtsDomain.createEffect(getDistricts);

// Stores

export const $districts = districtsDomain.createStore<District[]>([]);

export const $isLoading = districtsDomain.createStore<boolean>(false);

// Events

export const fetchDistricts = districtsDomain.createEvent<QueryParams>();

sample({
  clock: fetchDistricts,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchDistrictsFx,
});

sample({
  clock: fetchDistrictsFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchDistrictsFx.doneData,
  fn: ({ districts }) => districts,
  target: $districts,
});
