import { FC, Fragment, ReactElement, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { getFormattedDatetime } from "src/shared/helpers/datetime";
import {
  BaseButton,
  BaseMultiSelect,
  BaseSelect,
  TextWithIcon,
} from "src/shared/components";
import { InputCheckbox } from "src/shared/components/input-checkbox/input-checkbox";
import { getAvatarUrl } from "src/shared/helpers/pathToUserFile";
import { Checkbox } from "src/shared/models/checkbox";

import {
  EDataGridColumnType,
  IDataGridColumn,
  IDataGridRow,
} from "src/shared/models/dataGrid";

import { DataGridImage } from "../data-grid-image";
import { DataGridPhone } from "../data-grid-phone";
import { DataGridRating } from "../data-grid-rating";
import { DataGridUserAvatar } from "../data-grid-user-avatar";
import { DataGridSessionStatus } from "../data-grid-session-status";
import { DataGridDraggable } from "../data-grid-draggable";
import { DataGridRemove } from "../data-grid-remove";
import { numberSeparator } from "src/shared/helpers/numberSeparator";
import { ResumeIcon } from "src/shared/components/icons-components/icon-resume";

interface Props {
  row: IDataGridRow;
  column: IDataGridColumn;
  selectedRows: string[];
  selectRow: (row: IDataGridRow) => void;
  changeCheckbox?: (id: string, key: string, value: boolean) => void;
}

export const DataGridCol: FC<Props> = ({
  row,
  column,
  selectedRows,
  selectRow,
  changeCheckbox,
}): ReactElement => {
  const { t } = useTranslation();

  const renderColumn = useCallback(() => {
    const currentColumn = row[column.key];
    const splitCurrentColumn = Array.isArray(currentColumn)
      ? currentColumn.join(", ")?.split("")
      : currentColumn;

    switch (true) {
      case typeof currentColumn === "number" &&
        column.key === "gPlace" &&
        currentColumn > 0: {
        return numberSeparator(currentColumn);
      }
      case typeof currentColumn === "number" && currentColumn === 0: {
        return "—";
      }
      case Array.isArray(currentColumn): {
        return splitCurrentColumn;
      }
      case !Array.isArray(currentColumn): {
        return currentColumn;
      }
      default: {
        return "—";
      }
    }
  }, [column.key, row]);

  return (
    <Fragment>
      {column.type === EDataGridColumnType.SELECT_ROW && (
        <InputCheckbox
          id={`row-selected-${row.id}`}
          value={selectedRows.includes(row.id)}
          type={
            selectedRows.includes(row.id) ? Checkbox.CHOSEN : Checkbox.EMPTY
          }
          onChange={() => selectRow(row)}
          title={t("inputs.selectRow")}
        />
      )}

      {column.type === EDataGridColumnType.CHECKBOX &&
        !row[column.key].hideCheckbox && (
          <InputCheckbox
            id={`row-checkbox-${row.id}-${column.key}`}
            value={row[column.key].checked}
            type={row[column.key].checked ? Checkbox.CHOSEN : Checkbox.EMPTY}
            onChange={(event) =>
              changeCheckbox && changeCheckbox(row.id, column.key, event)
            }
            title={row[column.key].label}
            label={row[column.key].label}
          />
        )}
      {column.type === EDataGridColumnType.SELECT &&
        !row[column.key].hideSelect && (
          <BaseSelect
            className={column.selectProps?.className}
            {...row[column.key]}
          />
        )}
      {column.type === EDataGridColumnType.MULTI_SELECT &&
        !row[column.key].hideSelect && (
          <BaseMultiSelect
            className={column.selectProps?.className}
            activeItems={row[column.key].activeItem}
            name={row?.key}
            items={row[column.key]?.items || []}
            hideBorder
            forTable
            {...row[column.key]}
          />
        )}
      {column.type === EDataGridColumnType.BUTTON &&
        !row?.buttonProps?.[column.key]?.hideBtn && (
          <BaseButton
            {...column.buttonProps}
            {...row?.buttonProps?.[column.key]}
            onClick={(event) => {
              event.stopPropagation();

              if (column?.buttonProps?.onClick) {
                column.buttonProps.onClick(row.id);
              }
            }}
          >
            {column.buttonProps?.icon && (
              <TextWithIcon
                iconName={column.buttonProps.icon}
                iconSize={column.buttonProps.iconSize || 22}
                hideLabel={column.buttonProps?.hideLabel}
                label={row[column.key]}
              />
            )}
            {!column.buttonProps?.icon &&
              !column.buttonProps?.hideLabel &&
              row[column.key]}
          </BaseButton>
        )}

      {column.type === EDataGridColumnType.STRING && (
        <span className="data-grid__place-for-content data-grid__place-for-content">
          {renderColumn()}
        </span>
      )}

      {column.type === EDataGridColumnType.PHONE && (
        <Fragment>
          {!!row[column.key] && (
            <a href={`tel:${row[column.key]}`} className="data-grid__phone">
              <DataGridPhone phone={row[column.key]} />
            </a>
          )}
          {!row[column.key] && "—"}
        </Fragment>
      )}

      {column.type === EDataGridColumnType.EMAIL && (
        <Fragment>
          {!!row[column.key] && (
            <a href={`mailto:${row[column.key]}`} className="data-grid__email">
              {row[column.key]}
            </a>
          )}
          {!row[column.key] && "—"}
        </Fragment>
      )}

      {column.type === EDataGridColumnType.DATETIME && (
        <Fragment>
          {row[column.key]
            ? getFormattedDatetime(row[column.key], column.dateFormat)
            : "—"}
        </Fragment>
      )}

      {column.type === EDataGridColumnType.AVATAR && (
        <DataGridUserAvatar
          {...row.avatarProps}
          customPath={
            row.avatarPath ||
            row.avatarProps?.customPath ||
            getAvatarUrl(row.id)
          }
        >
          {row[column.key]}
        </DataGridUserAvatar>
      )}

      {column.type === EDataGridColumnType.MULTI_AVATAR && (
        <>
          {column.key === "appelantName" && (
            <DataGridUserAvatar
              {...row.avatarProps}
              customPath={
                row.avatarPath ||
                row.avatarProps?.customPath ||
                getAvatarUrl(row.id)
              }
            >
              {row[column.key]}
            </DataGridUserAvatar>
          )}
          {column.key === "defendantName" && (
            <DataGridUserAvatar
              {...row.avatarPropsExtra}
              customPath={
                row.avatarPropsExtra?.customPath || getAvatarUrl(row.id)
              }
            >
              {row[column.key]}
            </DataGridUserAvatar>
          )}
        </>
      )}

      {column.type === EDataGridColumnType.RATING && (
        <DataGridRating>{row[column.key]}</DataGridRating>
      )}

      {column.type === EDataGridColumnType.DRAGGABLE && <DataGridDraggable />}

      {column.type === EDataGridColumnType.SESSION_STATUS && (
        <DataGridSessionStatus />
      )}

      {column.type === EDataGridColumnType.REMOVE && <DataGridRemove />}

      {column.type === EDataGridColumnType.IMAGE && (
        <Fragment>
          {row[column.key]?.url && <DataGridImage {...row[column.key]} />}
        </Fragment>
      )}

      {column.type === EDataGridColumnType.RESUME && (
        <span className="data-grid__place-for-content data-grid__place-for-content data-grid__td--color_gray">
          {row[column.key] ? <ResumeIcon /> : "—"}
        </span>
      )}
    </Fragment>
  );
};
