import { FC, Fragment, ReactElement } from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { useUnit } from "effector-react";

import { Competence, LevelUpResDto } from "src/generated/game";

import { AssessmentStatus } from "src/generated/ws4";

import { requestLvlUp, $lvlUp } from "src/entities/public/lvl-up";

import { selectRoom } from "src/shared/store/ducks/room";

import { ERequestStatus } from "src/shared/store/types";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import { appAccessModel } from "src/features/public/app-access";

import "./base-banner.scss";
import { BaseButton } from "../base-button/base-button";
import { $socialPlayerInfo } from "../../../features/public/app-access/model";

interface Props {}

const { openPopup } = popupModel;

export const BaseBanner: FC<Props> = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const socialPlayerInfo = useUnit($socialPlayerInfo);

  const { status: lvlUpLoadingStatus } = useUnit($lvlUp);

  const isLvlUpLoading: boolean = lvlUpLoadingStatus === ERequestStatus.LOADING;

  const room = useSelector(selectRoom);

  const isAssessmentInProgress =
    room.assessmentStatus === AssessmentStatus.IN_PROGRESS;

  const isVerificationSession = useUnit(appAccessModel.$isVerificationSession);

  const isFullProfile = useUnit(appAccessModel.$isFullProfile);

  const isSomeCompetenceFirstLvl = useUnit(
    appAccessModel.$isSomeCompetenceFirstLvl,
  );

  const onClick = () => {
    const _callback = (response: LevelUpResDto) => {
      if (!!response.playUrl) {
        window.location.assign(response.playUrl);
      } else if (!!response.assessmentId) {
        navigate(`/sessions/session/${response.assessmentId}`);
      }
    };

    const localIsFullProfile: boolean = isSomeCompetenceFirstLvl
      ? !!isFullProfile
      : true;

    if (localIsFullProfile) {
      requestLvlUp({
        locale: i18n.language === "ru" ? "ru" : "en",
        competence: {
          combined: [Competence.Communication, Competence.Analytics],
        },
        callback: _callback,
      });
    } else {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.baseMessage.message.fillProfile"),
        },
      });
    }
  };

  const type: () => string = () => {
    const communication = socialPlayerInfo?.competenceRates?.find(
      (item) => item.competence === Competence.Communication,
    )?.rate;

    if (communication !== undefined) {
      if (communication <= 0) {
        return "first";
      } else if (communication > 0 && communication < 1) {
        return "second";
      } else if (communication >= 1) {
        return "third";
      }
    }

    return "";
  };

  const title = () => {
    if (type() === "first") {
      return i18n.language === "ru"
        ? "Не упусти шанс стать технологическим предпринимателем!"
        : "Don't miss your chance to become a tech entrepreneur!";
    } else if (type() === "second") {
      return i18n.language === "ru"
        ? "Осталось 2 шага до поступления!"
        : "2 steps left before admission!";
    } else if (type() === "third") {
      return i18n.language === "ru"
        ? "Хочешь зарплату до 70 тысяч рублей уже на 1 курсе?"
        : "Do you want a salary of up to 70 thousand rubles already in the 1st year?";
    }
  };

  const description = () => {
    if (type() === "first") {
      return "";
    } else if (type() === "second") {
      return i18n.language === "ru"
        ? "Пройди обучение, достигни третьего уровня"
        : "Complete the training, reach the third level";
    } else if (type() === "third") {
      return i18n.language === "ru"
        ? "Достигни третьего уровня в общении и аналитике"
        : "Reach Level Three in Communication and Analytics";
    }
  };

  const isStartButtonDisabled: boolean =
    isLvlUpLoading || isAssessmentInProgress || !!isVerificationSession;

  return (
    <Fragment>
      {socialPlayerInfo && (
        <div className={`base-banner base-banner--${type()}`}>
          <div className={`base-banner__img base-banner__img--${type()}`} />
          <div className={`base-banner__info base-banner__info--${type()}`}>
            <div
              className={`base-banner__info-title base-banner__info-title--${type()}`}
            >
              {title()}
            </div>
            {!!description() && (
              <div
                className={`base-banner__info-description base-banner__info-description--${type()}`}
              >
                {description()}
              </div>
            )}
          </div>
          <div className="base-banner__left_wrapper">
            <div className="base-banner__controls">
              <BaseButton
                large
                className={`base-banner__btn base-banner__btn--${type()}`}
                onClick={onClick}
                disabled={isStartButtonDisabled}
              >
                {i18n.language === "ru" ? "К цели!" : "To the goal!"}
              </BaseButton>
            </div>
            <a
              className={`base-banner__question-mark base-banner__question-mark__${type()}`}
              rel="noreferrer"
              target="_blank"
              href="https://polytech.alabuga.ru/"
            >
              ?
            </a>
          </div>
        </div>
      )}
    </Fragment>
  );
};
