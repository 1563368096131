import { UserRoleEnum } from "../shared/models";

export interface LobbyList {
  assessments: Array<LobbyAssessment>;
}

export interface LobbyAssessment {
  aid: string;
  gid: string;
  aType: AssessmentType;
  title: string;
  createdAt: string;
  maxPlayers: number;
  curPlayers: number;
  logoUrl?: string;
}

export enum AssessmentType {
  RATING = "rating",
  NON_RATING = "nonRating",
  INDIVIDUAL = "individual",
}

export interface LobbyCreated {
  assessment: LobbyAssessment;
}

export interface LobbyChanged {
  aid: string;
  curPlayers: number;
}

export interface LobbyStarted {
  aid: string;
}

export interface LobbyDeleted {
  aid: string;
}

export interface Err {
  type: string;
  err?: string;
}

export interface Update {
  aid: string;
  pid: string;
  reason: QuitReason;
}

export interface ActList {
  assessments: Array<ActAssessment>;
}

export interface ActAssessment {
  aid: string;
  gid: string;
  aType: AssessmentType;
  title: string;
  createdAt: string;
  maxPlayers: number;
  curPlayers: number;
  logoUrl?: string;
}

export interface ActStarted {
  assessment: ActAssessment;
}

export interface ActFinished {
  aid: string;
}

export interface JoinPlayerCmd {
  aid?: string;
  role?: string;
  teamNum?: string | null;
  corpNum?: string;
  areaNum?: string | null;
  teamName?: string;
}

export interface QuitPlayerCmd {
  aid: string;
}

export interface GetMemberInfoCmd {
  assessment_id: string;
  player_id: string;
}

export interface ChangePlayerDesiredRoleCmd {
  tid: string;
  desiredRole: string;
}

export type CompetenceRating = Record<string, number>;

export type CompetenceRatingBankrupt = Record<string, CompetenceRating>;

export interface PRoomR {
  aid: string;
  gid: string;
  maxPlayers?: number;
  maxAreas: number;
  curPlayers: number;
  aType: AssessmentType;
  aStatus?: AssessmentStatus;
  gType: GameType;
  hr: boolean;
  createdBy?: CreatedBy;
  createdAt: string;
  createdByUser?: UserInfo;
  maxPlayersByRole?: MaxPlayersByRole;
  maxTeamsByName?: MaxTeamsByName;
  playUrl?: string;
  title?: string;
  minRate?: number;
  maxRate?: number;
  competence?: Competence;
  validate_players?: boolean;
  is_timeout?: boolean;
  lobby_presence_timer?: number;
  lobby_confirm_timer?: number;
  closed_session?: boolean;
  required_participation?: boolean;
  competence_rating_bankrupt?: CompetenceRatingBankrupt;
}

export enum AssessmentStatus {
  LOBBY = "lobby",
  IN_PROGRESS = "inProgress",
  FINISHED = "finished",
}

export enum GameType {
  TUTORIAL = "tutorial",
  SINGLE = "single",
  SOLO = "solo",
  TEAM = "team",
  CORP = "corp",
}

export enum CreatedBy {
  AUTO = "auto",
  USER = "user",
}

export interface UserInfo {
  uid: string;
  firstname: string;
  lastname: string;
}

export enum Competence {
  ANALYTICS = "analytics",
  ANALYTICS_TENACITY = "analyticsTenacity",
  COMMAND = "command",
  COMMAND_TENACITY = "commandTenacity",
  COMMUNICATION = "communication",
  COMMUNICATION_TENACITY = "communicationTenacity",
  THINKING_3D = "thinking3d",
  THINKING_3D_TENACITY = "thinking3dTenacity",
  ECONOMICS = "economics",
  ECONOMICS_TENACITY = "economicsTenacity",
  JURISPRUDENCE = "jurisprudence",
  JURISPRUDENCE_TENACITY = "jurisprudenceTenacity",
}

export interface PRoomChanged {
  curPlayers: number;
  hr: boolean;
}

export interface Role {
  aid: string;
  pid: string;
  role: string;
  teamName?: string;
  teamNum?: number;
}

export interface Team {
  aid: string;
  teamNum: number;
  toTeamName: string;
}

export interface Update {
  pid: string;
}

export interface Quit {
  aid: string;
  pid: string;
  reason: QuitReason;
}

export enum QuitReason {
  KICK = "kick",
  QUIT = "quit",
  DISCONNECT = "disconnect",
  TIMEOUT = "timeout",
}

export interface Join {
  pid: string;
  firstname?: string;
  lastname?: string;
  role: string;
  teamName?: string;
  isDistributed?: boolean;
  teamNum?: number;
  corpNum?: number;
  areaNum?: number;
  user_role: UserRoleEnum;
}

export interface Play {
  aid: string;
  gid: string;
  url: string;
}

export interface Finished {
  aid: string;
  reason: FinReason;
}

export enum FinReason {
  DEL_EMPTY = "delEmpty",
  DEL_ADM = "delAdm",
  DEL_HR = "delHr",
  FINISHED = "finished",
  FORCE_FINISH_ADM = "forceFinishAdm",
  FORCE_FINISH_HR = "forceFinishHr",
}

export interface Results {
  aid: string;
  gid: string;
  players: Array<ResultsPlayer>;
}

export interface ResultsPlayer {
  pid: string;
  place: number;
  results?: MetaPlayerResults;
}

export interface MetaPlayerResults {
  additionalProperties?: Map<
    String,
    object | string | number | Array<unknown> | boolean | null
  >;
}

export interface Active {
  aid: string;
  gid: string;
  aType: AssessmentType;
  gType: GameType;
  playUrl?: string;
  title?: string;
  closed_session: boolean;
  required_participation?: boolean;
  competence_rating_bankrupt?: CompetenceRatingBankrupt;
  createdAt: string;
}

export interface ChangeDesiredRole {
  tid: string;
  desiredRole: string;
}

export interface JoinHrCmd {
  aid: string;
}

export interface QuitHrCmd {
  aid: string;
}

export interface RoleCmd {
  aid: string;
  pid: string;
  role: string;
  teamName?: string | null;
  teamNum?: number;
  corpNum?: number;
  areaNum?: number | null;
}

export interface TeamCmd {
  aid: string;
  teamNum: number;
  toTeamName: string;
}

export interface KickCmd {
  aid: string;
  pid: string;
}

export interface StartCmd {
  aid: string;
}

export interface DistributeCmd {
  aid: string;
  pid: string;
}

export interface HrRoom {
  aid: string;
  gid?: string;
  maxPlayers?: number;
  maxAreas?: number;
  maxPlayersByRole?: MaxPlayersByRole;
  maxTeamsByName?: MaxTeamsByName;
  aType: AssessmentType;
  aStatus?: AssessmentStatus;
  gType: GameType;
  createdBy?: CreatedBy;
  createdAt: string;
  createdByUser?: UserInfo;
  playUrl?: string;
  title?: string;
  players?: Array<PInfo>;
  minRate?: number;
  maxRate?: number;
  competence?: Competence;
  validate_players?: boolean;
  is_timeout?: boolean;
  lobby_presence_timer?: number;
  lobby_confirm_timer?: number;
  closed_session?: boolean;
  required_participation?: boolean;
  competence_rating_bankrupt?: CompetenceRatingBankrupt;
}

export interface MaxPlayersByRole {
  additionalProperties?: Map<String, number>;
}

export interface MaxTeamsByName {
  additionalProperties?: Map<String, MaxTeams>;
}

export interface MaxTeams {
  maxTeams?: number;
  maxPlayersByRole?: MaxPlayersByRole;
}

export interface PInfo {
  pid: string;
  firstname?: string;
  lastname?: string;
  role?: string;
  teamName?: string;
  teamNum?: number;
}

export interface TourJoinHrCmd {
  tid: string;
}

export interface TourQuitHrCmd {
  tid: string;
}

export interface PlayersTourECmd {
  tId?: string;
  teId: string;
  pIds?: Array<string>;
}

export interface PlayersTourACmd {
  tId?: string;
  teId: string;
  trIds: Array<string>;
}

export interface TourStateHr {
  tId: string;
  rooms: Array<TourStateRoom>;
  players: Array<TourStateHrPlayer>;
}

export interface TourStateRoom {
  trId: string;
  maxPlayers: number;
  players: Array<TourStateHrPlayer>;
  teId?: string;
  tpId?: string;
}

export interface TourStateHrPlayer {
  pId: string;
  isOnline: boolean;
  firstname?: string;
  lastname?: string;
  inRoom?: string;
  role?: string;
  teamName?: string;
  teamNum?: string;
}

export interface TourStateHrChanged {
  players: Array<TourStateHrPlayer>;
}

export interface TourStateHrAssessments {
  assessments: Array<LobbyAssessment>;
}

export interface TourJoinPCmd {
  tid: string;
}

export interface TourQuitPCmd {
  tid: string;
}

export interface TourStateP {
  tId: string;
  aType: AssessmentType;
  gId: string;
  gLogo: LocStr;
  gTitle: LocStr;
  playersOnline: number;
  playersTotal: number;
  tTitle?: LocStr;
}

export interface LocStr {
  en?: string;
  ru: string;
}

export interface TourStatePChanged {
  tId: string;
  playersOnline: number;
  playersTotal: number;
}
