import {
  FC,
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  BaseButton,
  ConfirmPopup,
  NavListItem,
  PageWrapper,
} from "src/shared/components";

import isEqual from "lodash/isEqual";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router";

import {
  GameType,
  LocStrDto,
  TenacityEnabled,
  TestResult,
} from "src/generated/game";

import {
  getErrorFormula,
  languagePicker,
  toCloneObject,
} from "src/shared/helpers";

import { useQuery } from "src/shared/hooks/useQuery";

import { useErrorScroll } from "src/shared/hooks/useErrorScroll";

import {
  ERequestStatus,
  ISimulationListPlayerMetaGameTypes,
  ISimulationTemplate,
  SimulationListPlayerMetaInterface,
} from "src/shared/store/types";

import {
  cleanSimulationItemAdmin,
  requestGetSimulationItemAdmin,
  requestPullMetaAdmin,
  selectSimulationItemAdminItem,
  selectSimulationItemAdminStatusGet,
  selectSimulationItemAdminStatusPullMeta,
  selectSimulationItemAdminStatusUpdateSimulation,
} from "src/shared/store/ducks/simulation-item-admin";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import { formulasModel as formulasEntitiesModel } from "src/entities/admin/formulas";

import { formulasModel as formulasFeaturesModel } from "src/features/admin/formulas";

import {
  FormulasBlock,
  InfoBlock,
  LinksBlock,
  RolesBlock,
  RulesBlock,
  TemplatesBlock,
} from "./components";

import "./simulation-page.scss";
import Quill from "quill";
import {
  IFormulasData,
  IGameRules,
  ISimulationFormData,
  ISimulationRulesTextData,
  ISimulationTitleData,
} from "./lib/types";
import { useOnSaveSimulation } from "./hooks/useOnSaveSimulation";
import { testFormulas } from "./lib/testFormulas";
import { getCustomTabsItems } from "./lib/getCustomTabsItems";
import { composeTemplateData } from "./lib/composeTemplateData";
import { composeFormulasData } from "./lib/composeFormulasData";
import { getFormattedUrl } from "../../../shared/helpers/getFormattedUrl";

const {
  $formulas,
  fetchFormulas,
  $isLoading: $isLoadingFormulasGet,
  resetFormulas,
} = formulasEntitiesModel;

const {
  fetchTestFormulasFx,
  resetTestResults,
  $testResults,
  $isLoading: $isLoadingFormulasUpdate,
} = formulasFeaturesModel;

const { openPopup, closePopup } = popupModel;

export const SimulationPage: FC = (): ReactElement => {
  const ruleTextEditorRef = useRef<HTMLElement | null>(null);
  const { isErrorScroll, setIsErrorScroll, scrollToErrorField } =
    useErrorScroll();
  const { t, i18n } = useTranslation();
  const params = useParams<{
    id: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const simulation = useSelector(selectSimulationItemAdminItem);
  const calcs = useUnit($formulas);
  const loadingStatusGetSimulation = useSelector(
    selectSimulationItemAdminStatusGet,
  );
  const loadingStatusUpdate = useSelector(
    selectSimulationItemAdminStatusUpdateSimulation,
  );
  const loadingStatusPullMeta = useSelector(
    selectSimulationItemAdminStatusPullMeta,
  );
  const [formData, setFormData] = useState<ISimulationFormData>({
    metaUrl: "",
    createUrl: "",
    playUrl: "",
    tutorialPlayUrl: "",
    ltdTutorialPlayUrl: "",
    finishUrl: "",
    apiKey: "",
  });

  const [formTitleData, setFormTitleData] = useState<ISimulationTitleData>({
    title_ru: "",
    title_en: "",
  });

  const [metaData, setMetaData] =
    useState<SimulationListPlayerMetaInterface | null>(null);
  const [formulasData, setFormulasData] = useState<IFormulasData | null>(null);
  const [templateData, setTemplateData] = useState<ISimulationTemplate[]>([]);
  const [formDataErrors, setFormDataErrors] = useState<Record<string, string>>(
    {},
  );

  const onSaveSimulation = useOnSaveSimulation();

  const [gameRules, setGameRules] = useState<IGameRules>({} as IGameRules);

  const [simulationDescription, setSimulationDescription] = useState<LocStrDto>(
    {} as LocStrDto,
  );

  const gameTypes: ISimulationListPlayerMetaGameTypes | undefined =
    simulation?.rawMetaJson?.gameTypes;

  const [tenacityData, setTenacityData] = useState<TenacityEnabled>();

  const [cloneFormulasData, setCloneFormulasData] = useState<IFormulasData>();

  const [cloneTemplateData, setCloneTemplateData] = useState<
    ISimulationTemplate[]
  >([]);

  const [cloneTenacityData, setCloneTenacityData] = useState<TenacityEnabled>(
    {} as TenacityEnabled,
  );

  const [cloneFromTitleData, setCloneFormTitleData] =
    useState<ISimulationTitleData>({} as ISimulationTitleData);

  const [cloneFormData, setCloneFormData] = useState<ISimulationFormData>(
    {} as ISimulationFormData,
  );

  // TODO: HRP-228 исправить any
  const [cloneGameRulesText, setCloneGameRules] = useState<any>(
    {} as ISimulationRulesTextData,
  );

  const [cloneSimulationDescription, setCloneSimulationDescription] =
    useState<LocStrDto>({} as LocStrDto);

  const isEqualFormulas: boolean = useMemo(() => {
    return isEqual(formulasData, cloneFormulasData);
  }, [formulasData, cloneFormulasData]);

  const isEqualTenacityData: boolean = useMemo(
    () => isEqual(tenacityData, cloneTenacityData),
    [tenacityData, cloneTenacityData],
  );

  const isEqualTemplates: boolean = useMemo(
    () => isEqual(templateData, cloneTemplateData),
    [templateData, cloneTemplateData],
  );

  const isEqualFormDatas: boolean = useMemo(
    () => isEqual(formData, cloneFormData),
    [formData, cloneFormData],
  );

  const isEqualNameDatas: boolean = useMemo(
    () => isEqual(formTitleData, cloneFromTitleData),
    [formTitleData, cloneFromTitleData],
  );

  const isEqualGameRules: boolean = useMemo(
    () => isEqual(gameRules, cloneGameRulesText),
    [gameRules, cloneGameRulesText],
  );

  const isEqualSimulationDescription: boolean = useMemo(
    () => isEqual(simulationDescription, cloneSimulationDescription),
    [simulationDescription, cloneSimulationDescription],
  );

  const isEqualSimulation: boolean =
    isEqualFormulas &&
    isEqualTemplates &&
    isEqualNameDatas &&
    isEqualFormDatas &&
    isEqualTenacityData &&
    isEqualSimulationDescription &&
    isEqualGameRules;

  const testFormulasResults = useUnit($testResults);

  const errorFormula: TestResult | undefined = useMemo(
    () => getErrorFormula(testFormulasResults),
    [testFormulasResults],
  );

  const { getQuery } = useQuery();

  const selectedGameTypeQuery: string | null = getQuery("gameType");

  const selectedTenacityGameTypeQuery =
    selectedGameTypeQuery as keyof TenacityEnabled;

  const selectedTenacityGameType =
    tenacityData?.[selectedTenacityGameTypeQuery];

  const isCorrectSelectedGameType: boolean = useMemo(
    () =>
      !!gameTypes &&
      !!selectedGameTypeQuery &&
      !!gameTypes[selectedGameTypeQuery],
    [gameTypes, selectedGameTypeQuery],
  );

  const defaultUrl: string = useMemo(
    () => `/simulation-control/simulation/${params.id}`,
    [params.id],
  );

  const supportedGameTypes: GameType[] | undefined = useMemo(() => {
    if (metaData) {
      const gameTypesKeys = Object.keys(metaData.gameTypes) as GameType[];

      return gameTypesKeys.filter(
        (item: GameType) => metaData.gameTypes[item].supported,
      );
    }
  }, [metaData]);

  const customTabsItems: NavListItem[] = useMemo(
    () => getCustomTabsItems(defaultUrl, gameTypes),
    [gameTypes],
  );

  /*
    Пушит на дефолтный урл, когда selectedGameTypeQuery
    не имеется в GameType
  */
  useEffect(() => {
    if (!isCorrectSelectedGameType && !!selectedGameTypeQuery && !!gameTypes) {
      navigate(defaultUrl);
    }
  }, [isCorrectSelectedGameType, selectedGameTypeQuery, gameTypes]);

  useEffect(() => {
    const current: any = ruleTextEditorRef?.current;
    if (current) {
      const quill: Quill = current.editor;
      if (quill) {
        setTimeout(() => quill.root.blur());
      }
    }
  }, [selectedGameTypeQuery]);

  useEffect(() => {
    if (supportedGameTypes) {
      const localTenacityData: Record<string, boolean> = {};

      supportedGameTypes.forEach((item) => {
        if (item !== GameType.Tutorial && item !== GameType.TutorialLtd) {
          localTenacityData[item] = !!simulation?.tenacityEnabled?.[item];
        }
      });

      setTenacityData(localTenacityData);

      setCloneTenacityData(localTenacityData);
    }
  }, [simulation?.tenacityEnabled, supportedGameTypes]);

  useEffect(() => {
    if (!!supportedGameTypes?.length) {
      const { localCompetenceFormulas, localSingleFormulas } =
        composeFormulasData(formulasData, supportedGameTypes, calcs);

      setFormulasData({
        competenceFormulas: localCompetenceFormulas,
        singleFormulas: localSingleFormulas,
      });

      setCloneFormulasData((prev) => {
        if (!prev) {
          return {
            competenceFormulas: localCompetenceFormulas,
            singleFormulas: localSingleFormulas,
          };
        }

        return prev;
      });
    }
  }, [supportedGameTypes, calcs]);

  useEffect(() => {
    if (params.id) {
      dispatch(requestGetSimulationItemAdmin({ id: params.id }));

      fetchFormulas({ gameId: params.id });
    }
    return () => {
      dispatch(cleanSimulationItemAdmin());

      resetFormulas();
    };
  }, [params.id, dispatch]);

  useEffect(() => {
    if (simulation) {
      setMetaData(simulation.rawMetaJson);

      setFormData((prev) => {
        const updatedFormData: typeof prev = {
          ...prev,
          metaUrl: simulation?.metaUrl ?? "",
          createUrl: getFormattedUrl("createUrl", simulation),
          playUrl: getFormattedUrl("playUrl", simulation),
          tutorialPlayUrl: getFormattedUrl("tutorialPlayUrl", simulation),
          ltdTutorialPlayUrl: getFormattedUrl("ltdTutorialPlayUrl", simulation),
          finishUrl: getFormattedUrl("finishUrl", simulation),
        };

        const localPrev = toCloneObject(updatedFormData);

        Object.keys(localPrev).forEach((item) => {
          localPrev[item as keyof ISimulationFormData] =
            simulation[item as keyof ISimulationFormData] ?? "";
        });

        setCloneFormData(localPrev);

        return localPrev;
      });
      setFormTitleData((prev) => {
        const localPrev = toCloneObject(prev);

        localPrev.title_ru = simulation.title?.ru ?? "";
        localPrev.title_en = simulation.title?.en ?? "";

        setCloneFormTitleData(localPrev);

        return localPrev;
      });
    }
  }, [simulation]);

  useEffect(() => {
    if (simulation) {
      setTemplateData(composeTemplateData(simulation, setCloneTemplateData));

      const gameRules =
        simulation?.gameRules || ({} as { [key: string]: LocStrDto });
      if (gameRules) {
        setGameRules(gameRules);
        setCloneGameRules(gameRules);
      }

      const desc = simulation?.desc || ({} as LocStrDto);
      if (desc) {
        setSimulationDescription(desc);
        setCloneSimulationDescription(desc);
      }
    }
  }, [simulation]);

  useEffect(() => {
    if (isErrorScroll && !selectedGameTypeQuery) {
      scrollToErrorField();
    }
  }, [formDataErrors, isErrorScroll, selectedGameTypeQuery]);

  useEffect(() => {
    if (errorFormula?.gType) {
      setIsErrorScroll(true);

      navigate(
        `/simulation-control/simulation/${params.id}?gameType=${errorFormula?.gType}`,
      );
    }
  }, [errorFormula, params.id]);

  useEffect(() => {
    if (errorFormula?.gType && selectedGameTypeQuery && isErrorScroll) {
      scrollToErrorField();
    }
  }, [errorFormula, selectedGameTypeQuery, isErrorScroll]);

  const onTestFormules = (e: FormEvent) => {
    e.preventDefault();

    resetTestResults();

    if (simulation && formulasData) {
      testFormulas(simulation, formulasData);
    }
  };

  const onPullMeta = () => {
    const callback: () => void = () => {
      closePopup({
        name: EPopupName.CONFIRM_PULL_META,
        callback: () =>
          params.id &&
          dispatch(requestGetSimulationItemAdmin({ id: params.id })),
      });
    };
    if (params.id) {
      dispatch(requestPullMetaAdmin({ id: params.id, callback }));
    }
  };

  const onChangeTenacityEnabledHandler = (value: boolean) => {
    setTenacityData((prev) => {
      const localPrev = { ...prev };

      if (selectedGameTypeQuery) {
        localPrev[selectedTenacityGameTypeQuery] = value;
      }

      return localPrev;
    });
  };

  useEffect(() => {
    const subscription = fetchTestFormulasFx.doneData.watch(({ results }) => {
      const someErrorFormula: boolean = !!getErrorFormula(results);

      if (!someErrorFormula && formulasData) {
        onSaveSimulation(
          formTitleData,
          formData,
          templateData,
          simulationDescription,
          gameRules,
          setFormDataErrors,
          formulasData,
          tenacityData,
        );
      } else {
        openPopup({
          name: EPopupName.BASE_MESSAGE_POPUP,
          message: {
            text: "invalidFormulas",
            type: "response",
            isError: true,
          },
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [onSaveSimulation, dispatch]);

  const isLoadingFormulasGet = useUnit($isLoadingFormulasGet);

  const isLoadingFormulasUpdate = useUnit($isLoadingFormulasUpdate);

  const isLoadingGetSimulation: boolean =
    loadingStatusGetSimulation === ERequestStatus.LOADING;

  const isLoadingPullMeta: boolean =
    loadingStatusPullMeta === ERequestStatus.LOADING;

  const isLoadingSimulationUpdate: boolean =
    loadingStatusUpdate === ERequestStatus.LOADING;

  const isLoadedGetSimulation: boolean =
    loadingStatusGetSimulation === ERequestStatus.LOADED;

  const isTutorialSelected: boolean =
    selectedGameTypeQuery === GameType.Tutorial;

  const isDisabledSaveSimulationBtn: boolean =
    isLoadingPullMeta ||
    isLoadingSimulationUpdate ||
    isLoadingFormulasUpdate ||
    isEqualSimulation;

  return (
    <PageWrapper
      title={t("simulationControl.simulationPage.title")}
      backButton
      isExactWithQueryParams
      isLightBlueBackground
      isLoadingPanel={isLoadingGetSimulation || isLoadingFormulasGet}
      emptyPanel={isLoadingGetSimulation || isLoadingFormulasGet}
      customTabsItems={customTabsItems}
      tabsPanelSlot={
        <>
          {isLoadedGetSimulation && (
            <div className="simulation-page__save-btn simulation-page__save-btn--desktop">
              <BaseButton
                small
                primary
                submit={false}
                onClick={onTestFormules}
                disabled={isDisabledSaveSimulationBtn}
              >
                {t("common.saveChanges")}
              </BaseButton>
            </div>
          )}
        </>
      }
    >
      {isLoadedGetSimulation &&
        !isLoadingFormulasGet &&
        metaData?.gameTypes && (
          <div className="simulation-page">
            {selectedGameTypeQuery && (
              <>
                {gameTypes && (
                  <RolesBlock
                    className="simulation-page-game-type__roles"
                    selectedGameType={gameTypes[selectedGameTypeQuery]}
                    tenacity={selectedTenacityGameType}
                    onChangeTenacity={onChangeTenacityEnabledHandler}
                  />
                )}
                {formulasData && simulation && (
                  <FormulasBlock
                    gameId={simulation.id}
                    setFormulasData={setFormulasData}
                    formulasData={formulasData}
                    gameType={selectedGameTypeQuery as GameType}
                  />
                )}
              </>
            )}
            {selectedGameTypeQuery && !isTutorialSelected && (
              <TemplatesBlock
                className="simulation-page-game-type__templates"
                templates={templateData}
                selectedGameType={selectedGameTypeQuery as GameType}
                metaData={metaData}
                setTemplates={setTemplateData}
              />
            )}
            <form
              className="simulation-page-general-wrapper"
              onSubmit={onTestFormules}
            >
              {!selectedGameTypeQuery && (
                <div className="simulation-page-general">
                  <LinksBlock
                    className="simulation-page-general__links"
                    formData={formData}
                    setFormData={setFormData}
                    errors={formDataErrors}
                    setFormDataErrors={setFormDataErrors}
                  />
                  <InfoBlock
                    className="simulation-page-general__info"
                    imgUrl={metaData.info.logoUrl.ru}
                    formTitleData={formTitleData}
                    setFormTitleData={setFormTitleData}
                    errors={formDataErrors}
                    setFormDataErrors={setFormDataErrors}
                    title={languagePicker(metaData.info.title, i18n.language)}
                    versionInfo={metaData.info.version}
                    description={languagePicker(
                      metaData.info.desc,
                      i18n.language,
                    )}
                  />
                </div>
              )}
              <div className="simulation-page__save-btn simulation-page__save-btn--mobile">
                <BaseButton
                  block
                  large
                  primary
                  submit
                  onClick={onTestFormules}
                  disabled={isDisabledSaveSimulationBtn}
                >
                  {t("common.saveChanges")}
                </BaseButton>
              </div>
            </form>
            <RulesBlock
              gameRules={gameRules}
              setGameRules={setGameRules}
              simulationDescription={simulationDescription}
              setSimulationDescription={setSimulationDescription}
              setRef={(value) => {
                ruleTextEditorRef.current = value;
              }}
            />
          </div>
        )}
      <ConfirmPopup
        popupName={EPopupName.CONFIRM_PULL_META}
        title={t("simulationControl.simulationPage.popup.pullMeta.title")}
        description={t(
          "simulationControl.simulationPage.popup.pullMeta.description",
        )}
        cancelBtnLabel={t("common.cancel")}
        confirmBtnLabel={t("common.update")}
        onConfirm={onPullMeta}
        isConfirmBtnDisabled={isLoadingPullMeta}
      />
    </PageWrapper>
  );
};
