import { FC, ReactElement } from "react";

import { useTranslation } from "react-i18next";

import { TTextWithIconName } from "../../shared/components/text-with-icon/text-with-icon";

import { PageWrapper } from "src/shared/components";

import { InfoBlock, SocialNetworksBlock, TelegramBlock } from "./components";

import "./contacts-page.scss";

interface Props {}

const hrSupportValues = {
  phone: "+7 (85557) 5 90 24",
  mail: "hr@alabuga.ru",
};

const technicalSupportValues = {
  phone: "+7 (927) 243 23 48",
  mail: "hr.support@alabuga.ru",
};

export interface IContactLink {
  key: TTextWithIconName;
  link: string;
  label?: string;
}

export const ContactsPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t("contacts.title")} emptyPanel>
      <div className="contacts-page">
        <TelegramBlock className="contacts-page__item contacts-page__item--large" />
        <InfoBlock
          className="contacts-page__item"
          valueItems={hrSupportValues}
          title={t("contacts.infoBlock.hr.title")}
          description="contacts.infoBlock.hr.description"
        />
        <InfoBlock
          className="contacts-page__item"
          valueItems={technicalSupportValues}
          title={t("contacts.infoBlock.technical.title")}
          description="contacts.infoBlock.technical.description"
        />
        <SocialNetworksBlock className="contacts-page__item contacts-page__item--large" />
      </div>
    </PageWrapper>
  );
};
