import { sample } from "effector";

import {
  SendEmail,
  sendEmailNotification,
} from "src/shared/api/admin/notification";

import { $isLoading, emailNotificationDomain } from "./email-notification";

import { errorFx, successFx } from "../../common-notification";

// Event

export const sendEmail = emailNotificationDomain.createEvent<SendEmail>();

// Effect

export const sendEmailFx = emailNotificationDomain.createEffect(
  sendEmailNotification,
);

// Logic

sample({ clock: sendEmail, target: sendEmailFx });

sample({ clock: sendEmailFx.pending, target: $isLoading });

sample({ clock: sendEmailFx.doneData, target: successFx });

sample({ clock: sendEmailFx.fail, target: errorFx });
