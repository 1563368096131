import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { AssessmentType } from "src/generated/game";

import {
  Competence,
  PAssessmentBriefDto,
  PRoleDto,
} from "src/generated/statistic";

import { DataGrid } from "src/shared/components/index";

import {
  IGameType,
  SimulationListPlayerMetaInterface,
} from "src/shared/store/types";

import useClient from "src/shared/hooks/useClient";

import {
  getActiveGroup,
  getCoefficientText,
  getCoefficientTextColor,
  getCompetencies,
  getDateControlPanelGroup,
  getOrderByValue,
  languagePicker,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  EDataGridTextColor,
  IDataGridColumn,
  IDataGridRow,
  IDataGridSelectOption,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";

import { dislikeTooltipFactory } from "src/features/public/dislike-info";
import { isMainCompetence } from "../../../helpers/isMainCompetence";
import { getGridCommonColor } from "../../../helpers/data-grids/getGridCommonColor";

interface Props {
  historyItems: PAssessmentBriefDto[];
  loading?: boolean;
  roles: PRoleDto[];
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

const { openPopup } = popupModel;

export const SessionsHistory: FC<Props> = ({
  loading,
  historyItems,
  roles,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isHrClientId, isAdminClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const assessmentTypes = useMemo(() => Object.values(AssessmentType), []);

  const { id } = useParams<{ id: string }>();

  const assessmentTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return assessmentTypes.map((type) => ({
      value: type,
      label: t(`common.assessment.${type}`, type),
    }));
  }, [assessmentTypes, t]);

  const roleOptions = useMemo<IDataGridSelectOption[]>(() => {
    return roles.map(
      (roleItem): IDataGridSelectOption => ({
        value: roleItem.role,
        label: t(`playerRoles.${roleItem.role}`, roleItem.role),
      }),
    );
  }, [roles, t]);

  const showAssessmentResults = useCallback(
    (rowId: string): void => {
      openPopup({
        name: EPopupName.ASSESSMENT_RESULT,
        dataId: [
          { name: EPopupDataIdName.DEFAULT, value: rowId },
          { name: EPopupDataIdName.VIEWED_USER, value: id as string },
        ],
      });
    },
    [id],
  );

  const showAssessmentParameters = useCallback((rowId: string): void => {
    openPopup({
      name: EPopupName.ASSESSMENT_PARAMETERS,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
      someBooleanTrigger: true,
    });
  }, []);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        title: t("table.header.session"),
        type: EDataGridColumnType.STRING,
        key: "sessionName",
        showed: true,
        noPaddingLeft: true,
        filterTitle: t("table.header.session"),
        controlPanel: {
          title: t("table.header.session"),
          activeGroupKey: getActiveGroup(
            ["aTitle"],
            { all: ["aTitle"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "aTitle",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.aTitle || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "aTitle",
                  "-aTitle",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "aTitle",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-aTitle",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.simulation"),
        type: EDataGridColumnType.STRING,
        key: "simulationName",
        showed: true,
        filterTitle: t("table.header.simulation"),
        controlPanel: {
          title: t("table.header.simulation"),
          activeGroupKey: getActiveGroup(
            ["gTitle"],
            { all: ["gTitle"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "gTitle",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.gTitle || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "gTitle",
                  "-gTitle",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "gTitle",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-gTitle",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.type"),
        filterTitle: t("table.header.type"),
        type: EDataGridColumnType.STRING,
        key: "assessmentType",
        showed: true,
        controlPanel: {
          title: t("table.header.type"),
          activeGroupKey: getActiveGroup(
            ["assessmentType"],
            { all: ["assessmentType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "assessmentType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: t("table.filters.notSelected"),
                  value: queryParams?.assessmentType || "",
                  items: assessmentTypeOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.members.short"),
        filterTitle: t("table.header.members"),
        type: EDataGridColumnType.STRING,
        key: "players",
        showed: true,
        controlPanel: {
          title: t("table.header.members"),
          activeGroupKey: getActiveGroup(
            ["curPlayers", "curPlayersFrom", "curPlayersTo"],
            { all: ["curPlayers", "curPlayersFrom", "curPlayersTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "curPlayersFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.curPlayersFrom || "",
                  isHalf: true,
                },
                {
                  key: "curPlayersTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.curPlayersTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "curPlayers",
                  "-curPlayers",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "curPlayers",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-curPlayers",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.game.end"),
        filterTitle: t("table.header.game.end"),
        type: EDataGridColumnType.DATETIME,
        key: "finished",
        showed: true,
        controlPanel: {
          title: t("table.header.activity"),
          activeGroupKey: getActiveGroup(
            [
              "created",
              "createdFrom",
              "createdTo",
              "started",
              "startedFrom",
              "startedTo",
              "finished",
              "finishedFrom",
              "finishedTo",
            ],
            {
              created: ["created", "createdFrom", "createdTo"],
              started: ["started", "startedFrom", "startedTo"],
              finished: ["finished", "finishedFrom", "finishedTo"],
            },
            queryParams,
            "orderBy",
            true,
          ),
          groups: [
            getDateControlPanelGroup(
              "created",
              t("table.filters.game.createdAt"),
              queryParams,
            ),
            getDateControlPanelGroup(
              "started",
              t("table.filters.game.startedAt"),
              queryParams,
            ),
            getDateControlPanelGroup(
              "finished",
              t("table.filters.game.finishedAt"),
              queryParams,
            ),
          ],
        },
      },
      {
        title: t("table.header.role"),
        type: EDataGridColumnType.STRING,
        key: "role",
        showed: true,
        controlPanel: {
          title: t("table.header.role"),
          activeGroupKey: getActiveGroup(
            ["role"],
            { all: ["role"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "role",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: t("table.filters.notSelected"),
                  value: queryParams?.role || "",
                  items: roleOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.communication.short"),
        filterTitle: t("table.header.communication"),
        description: t("table.header.communication"),
        type: EDataGridColumnType.STRING,
        key: "communicationDiff",
        showed: true,
        controlPanel: {
          title: t("table.header.communication"),
          activeGroupKey: getActiveGroup(
            [
              "communicationDiff",
              "communicationDiffFrom",
              "communicationDiffTo",
            ],
            {
              all: [
                "communicationDiff",
                "communicationDiffFrom",
                "communicationDiffTo",
              ],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "communicationDiffFrom",
                  type: EDataGridFilterType.NUMBER,
                  isHalf: true,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.communicationDiffFrom || "",
                },
                {
                  key: "communicationDiffTo",
                  type: EDataGridFilterType.NUMBER,
                  isHalf: true,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.communicationDiffTo || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "communicationDiff",
                  "-communicationDiff",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "communicationDiff",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-communicationDiff",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.analytics.short"),
        filterTitle: t("table.header.analytics"),
        filterPosition: EDataGridFilterPosition.LEFT,
        description: t("table.header.analytics"),
        type: EDataGridColumnType.STRING,
        key: "analyticsDiff",
        showed: true,
        controlPanel: {
          title: t("table.header.analytics"),
          activeGroupKey: getActiveGroup(
            ["analyticsDiff", "analyticsDiffFrom", "analyticsDiffTo"],
            { all: ["analyticsDiff", "analyticsDiffFrom", "analyticsDiffTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "analyticsDiffFrom",
                  type: EDataGridFilterType.NUMBER,
                  isHalf: true,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.analyticsDiffFrom || "",
                },
                {
                  key: "analyticsDiffTo",
                  type: EDataGridFilterType.NUMBER,
                  isHalf: true,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.analyticsDiffTo || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "analyticsDiff",
                  "-analyticsDiff",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "analyticsDiff",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-analyticsDiff",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.command.short"),
        filterTitle: t("table.header.command"),
        filterPosition: EDataGridFilterPosition.LEFT,
        description: t("table.header.command"),
        type: EDataGridColumnType.STRING,
        key: "commandDiff",
        showed: true,
        controlPanel: {
          title: t("table.header.command"),
          activeGroupKey: getActiveGroup(
            ["commandDiff", "commandDiffFrom", "commandDiffTo"],
            { all: ["commandDiff", "commandDiffFrom", "commandDiffTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "commandDiffFrom",
                  type: EDataGridFilterType.NUMBER,
                  isHalf: true,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.commandDiffFrom || "",
                },
                {
                  key: "commandDiffTo",
                  type: EDataGridFilterType.NUMBER,
                  isHalf: true,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.commandDiffTo || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "commandDiff",
                  "-commandDiff",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "commandDiff",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-commandDiff",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.competencies.name"),
        filterTitle: t("table.header.thinking3d"),
        description: t("table.header.thinking3d"),
        type: EDataGridColumnType.STRING,
        key: "additionalCompetencies",
        showed: true,
        controlPanel: {
          title: t("table.header.competencies.name"),
          activeGroupKey: getActiveGroup(
            ["additionalCompetence"],
            {
              all: ["additionalCompetence"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "additionalCompetence",
                  type: EDataGridFilterType.MULTI_SELECT,
                  items: Object.values(Competence)
                    .filter((competence) => !isMainCompetence(competence))
                    .map((competence) => {
                      return {
                        label: t(`common.competence.${competence}`),
                        value: competence,
                      };
                    }),
                  placeholder: i18n.t("common.enterValue"),
                  value: queryParams?.additionalCompetence || "",
                  canSearch: false,
                },
                {
                  key: "additionalFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  isHalf: true,
                  value: queryParams?.additionalCompetenceFrom || "",
                },
                {
                  key: "additionalTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  isHalf: true,
                  value: queryParams?.additionalCompetenceTo || "",
                },
                {
                  key: "tenacityCompetenceNull",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: i18n.t("common.empty"),
                  items: [
                    {
                      label: t("common.empty"),
                      value: "true",
                    },
                  ],
                  value: queryParams?.additionalNull || "",
                  disableFilters: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "additionalCompetence",
                  "-additionalCompetence",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "additionalCompetence",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-additionalCompetence",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.position"),
        filterTitle: t("table.header.position"),
        filterPosition: EDataGridFilterPosition.LEFT,
        type: EDataGridColumnType.STRING,
        key: "place",
        showed: true,
        controlPanel: {
          title: t("table.header.position"),
          activeGroupKey: getActiveGroup(
            ["place", "placeFrom", "placeTo"],
            { all: ["place", "placeFrom", "placeTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "placeFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  isHalf: true,
                  value: queryParams?.placeFrom || "",
                },
                {
                  key: "placeTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  isHalf: true,
                  value: queryParams?.placeTo || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "place",
                  "-place",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "place",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-place",
                  },
                ],
              },
            },
          ],
        },
      },
    ];

    if (isHrClientId || isAdminClientId) {
      const additionalColumns: IDataGridColumn[] = [
        {
          title: t("table.header.results"),
          hiddenTitle: true,
          type: EDataGridColumnType.BUTTON,
          key: "resultsButton",
          showed: isHrClientId || isAdminClientId,
          noPaddingRight: true,
          buttonProps: {
            primary: true,
            xSmall: true,
            xSmallRounded: true,
            onClick: showAssessmentResults,
          },
          sticky: {
            tablet: {
              right: 59,
            },
            laptop: {
              right: 79,
            },
          },
        },
        {
          title: t("table.header.settings"),
          hiddenTitle: true,
          type: EDataGridColumnType.BUTTON,
          key: "settingsButton",
          showed: isHrClientId || isAdminClientId,
          buttonProps: {
            small: true,
            hideLabel: true,
            transparent: true,
            icon: "props-gray",
            noPaddingX: true,
            onClick: showAssessmentParameters,
          },
          sticky: {
            tablet: {
              right: 0,
              width: 59,
            },
            laptop: {
              right: 0,
            },
          },
        },
      ];

      newColumns.push(...additionalColumns);
    }

    return newColumns;
  }, [
    queryParams,
    showAssessmentParameters,
    showAssessmentResults,
    isHrClientId,
    isAdminClientId,
    assessmentTypeOptions,
    roleOptions,
    t,
  ]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = historyItems.map((item): IDataGridRow => {
      const meta = item.rawMetaJson as SimulationListPlayerMetaInterface;

      const gameType: IGameType = meta.gameTypes[item.gType];

      const dislikesTooltips = dislikeTooltipFactory(
        item.dislikes,
        gameType,
        [
          Competence.Communication,
          Competence.Analytics,
          Competence.Command,
          Competence.AnalyticsTenacity,
          Competence.CommunicationTenacity,
          Competence.CommandTenacity,
          Competence.EconomicsTenacity,
          Competence.JurisprudenceTenacity,
          Competence.Thinking3dTenacity,
        ],
        {
          communication: item.communicationDiff,
          analytics: item.analyticsDiff,
          command: item.commandDiff,
          analyticsTenacity: item.analyticsTenacityDiff,
          communicationTenacity: item.communicationTenacityDiff,
          commandTenacity: item.commandTenacityDiff,
          economicsTenacity: item.economicsTenacityDiff,
          jurisprudenceTenacity: item.jurisprudenceTenacityDiff,
          thinking3dTenacity: item.thinking3dTenacityDiff,
        },
      );

      const commonCompetencies: Record<string, number>[] = [
        {
          [Competence.Thinking3d]: item.thinking3dDiff ?? 0,
        },
        {
          [Competence.Jurisprudence]: item.jurisprudenceDiff ?? 0,
        },
        {
          [Competence.AnalyticsTenacity]: item.analyticsTenacityDiff ?? 0,
        },
        {
          [Competence.CommunicationTenacity]:
            item.communicationTenacityDiff ?? 0,
        },
        {
          [Competence.CommandTenacity]: item.commandTenacityDiff ?? 0,
        },
        {
          [Competence.EconomicsTenacity]: item.economicsTenacityDiff ?? 0,
        },
        {
          [Competence.JurisprudenceTenacity]:
            item.jurisprudenceTenacityDiff ?? 0,
        },
        {
          [Competence.Thinking3dTenacity]: item.thinking3dTenacityDiff ?? 0,
        },
        {
          [Competence.Microelectronics]: item.microelectronicsDiff ?? 0,
        },
        {
          [Competence.MicroelectronicsTenacity]:
            item.microelectronicsTenacityDiff ?? 0,
        },
      ];

      return {
        id: item.aId || "",
        selected: false,
        sessionName: item.aTitle || "—",
        simulationName: languagePicker(item.gTitle, i18n.language),
        assessmentType: t(`common.assessment.${item.aType}`, item.aType),
        players: `${item.curPlayers}/${item.maxPlayers}`,
        created: item.createdAt,
        started: item.startedAt,
        finished: item.finishedAt,
        role: item.role ? t(`playerRoles.${item.role}`, item.role) : "",
        communicationDiff: getCoefficientText(item.communicationDiff),
        analyticsDiff: getCoefficientText(item.analyticsDiff),
        commandDiff: getCoefficientText(item.commandDiff),
        additionalCompetencies: getCompetencies({}, commonCompetencies),
        place: item.place,
        resultsButton: t("table.header.results"),
        settingsButton: t("table.header.settings"),
        columnsTextColor: {
          sessionName: !item.aTitle ? EDataGridTextColor.GRAY : null,
          communicationDiff: getCoefficientTextColor(item.communicationDiff),
          analyticsDiff: getCoefficientTextColor(item.analyticsDiff),
          commandDiff: getCoefficientTextColor(item.commandDiff),
          additionalCompetencies: getGridCommonColor(commonCompetencies),
          place: !item.place ? EDataGridTextColor.GRAY : null,
          rating: EDataGridTextColor.BLUE,
        },
        tooltips: {
          finished: {
            items: [
              {
                key: t("table.header.game.created"),
                value: item.createdAt,
                type: "datetime",
              },
              {
                key: t("table.header.game.run"),
                value: item.startedAt,
                type: "datetime",
              },
              {
                key: t("table.header.game.end"),
                value: item.finishedAt,
                type: "datetime",
              },
            ],
          },
          communicationDiff: dislikesTooltips.communication?.content,
          analyticsDiff: dislikesTooltips.analytics?.content,
          commandDiff: dislikesTooltips.command?.content,
        },
      };
    });

    setRows(rowsItems);
  }, [historyItems, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"session-history-table"}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
