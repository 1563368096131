import { createEvent, sample } from "effector";

import { $notificationTxt } from "./update-notification";

import {
  resetActiveRadioValue,
  resetCopySelectedIds,
  resetSelectedIds,
} from "src/pages/other/select-players-page/model";

// Events

export const resetNotificationTxt = createEvent();

export const resetNotification = createEvent();

// Logic

sample({
  clock: resetNotification,
  target: [
    resetNotificationTxt,
    resetSelectedIds,
    resetCopySelectedIds,
    resetActiveRadioValue,
  ],
});

$notificationTxt.reset(resetNotification);
