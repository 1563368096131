import { sample } from "effector";

import { SelectItem } from "src/shared/components";

import { userFormDomain } from "./domain";

import {
  countriesToSelectItemsAdapter,
  districtsToSelectItemsAdapter,
  regionsToSelectItemsAdapter,
  schoolsToSelectItemsAdapter,
} from "../lib/toSelectItemsAdapter";

import { $countries, $districts, $regions, $schools } from "./dictionaries";

// Stores

export const $countriesSelectItems = userFormDomain.createStore<SelectItem[]>(
  [],
);

export const $regionsSelectItems = userFormDomain.createStore<SelectItem[]>([]);

export const $districtsSelectItems = userFormDomain.createStore<SelectItem[]>(
  [],
);

export const $schoolsSelectItems = userFormDomain.createStore<SelectItem[]>([]);

// Logic

sample({
  clock: $countries,
  fn: countriesToSelectItemsAdapter,
  target: $countriesSelectItems,
});

sample({
  clock: $regions,
  fn: regionsToSelectItemsAdapter,
  target: $regionsSelectItems,
});

sample({
  clock: $districts,
  fn: districtsToSelectItemsAdapter,
  target: $districtsSelectItems,
});

sample({
  clock: $schools,
  fn: schoolsToSelectItemsAdapter,
  target: $schoolsSelectItems,
});
