import { sample } from "effector";

import { getList } from "../../../../shared/api/admin/countries";

import { QueryParams } from "../../../../shared/api/base";

import { queryParamsToGetListParamsAdapter } from "../lib";

import {
  $countries,
  $pagination,
  $queryParams,
  countriesDomain,
} from "./countries";

// Events

export const readmoreCountries = countriesDomain.createEvent<QueryParams>();

// Effects

export const readmoreCountriesFx = countriesDomain.createEffect(getList);

// Logic

sample({
  clock: [readmoreCountries],
  fn: queryParamsToGetListParamsAdapter,
  target: readmoreCountriesFx,
});

sample({
  clock: readmoreCountries,
  target: $queryParams,
});

sample({
  clock: readmoreCountriesFx.doneData,
  source: $countries,
  fn: (countries, data) => [...countries, ...data.countries],
  target: $countries,
});

sample({
  clock: readmoreCountriesFx.doneData,
  fn: (data) => data.pagination || null,
  target: $pagination,
});
