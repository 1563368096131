import { FC, ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUrlState } from "src/shared/hooks/useUrlState";
import isEqual from "lodash/isEqual";
import { PageWrapper } from "src/shared/components";
import {
  BaseButton,
  BasePanel,
  NavList,
  NavListItem,
  TextWithIcon,
} from "../../../shared/components";

import "./users-change-history-page.scss";
import { TDataGridDataParams } from "../../../shared/models";
import {
  getDateForFilter,
  stringToArrayStrings,
  togglePlaceParams,
} from "../../../shared/helpers";
import { useUnit } from "effector-react";
import { useUpdatedQueryParams } from "../../../shared/hooks/useUpdatedQueryParams";
import { PlayersApiGetChangesByAdminPlayerRequest } from "../../../generated/social";
import {
  $changeUsersHistory,
  $isLoadingUsers,
  $pagination,
  fetchChangeUsersHistory,
} from "../../../entities/admin/histories/model/users/users-change-history";
import { ChangeUsersHistory } from "../../../shared/components/data-grids/histories/users-change-history";

interface Props {}

export const UsersChangeHistoryPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const [urlState, setUrlState] = useUrlState();

  const isLoading = useUnit($isLoadingUsers);

  const changeUsersHistory = useUnit($changeUsersHistory);

  const pagination = useUnit($pagination);

  const { updatedQueryParams } = useUpdatedQueryParams();

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (!isEqual(queryParams, newFilteredParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newFilteredParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstName,
      lastName,
      dateActionFrom,
      dateActionTo,
      action,
      orderBy,
      pageNum,
      pageSize,
    } = urlState.query;

    const params: PlayersApiGetChangesByAdminPlayerRequest = {
      firstName: firstName ? [firstName] : undefined,
      lastName: lastName ? [lastName] : undefined,
      dateActionFrom: getDateForFilter(dateActionFrom),
      dateActionTo: getDateForFilter(dateActionTo),
      action: action ? stringToArrayStrings(action) : undefined,
      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [urlState.query]);

  useEffect(() => {
    if (updatedQueryParams) {
      fetchChangeUsersHistory(pageParams);
    }
  }, [pageParams]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  const historyPageTabLinks: NavListItem[] = [
    {
      title: t("navigation.history.changes"),
      to: "/history/users/change",
      access: true,
    },
  ];

  return (
    <div className="sessions-export-history-page">
      <PageWrapper
        isLightBlueBackground
        isShowContentWhenIsLoading
        isLoadingPanel={isLoading}
        controlPanelSlot={
          <>
            <BasePanel className="sessions-export-history-page-tabs">
              <NavList level={2} links={historyPageTabLinks} />
            </BasePanel>
            <div className="table__control">
              {filtersLength > 0 && (
                <BaseButton
                  className="table__control-btn"
                  onClick={clearFilterSettings}
                  lightBlue
                >
                  <TextWithIcon
                    label={t("table.filters.reset")}
                    iconName={"cross-blue"}
                  />
                </BaseButton>
              )}
            </div>
          </>
        }
      >
        <div className="users-change-history-page-data-grid-wrapper">
          <ChangeUsersHistory
            items={changeUsersHistory}
            pagination={pagination}
            loading={isLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            queryParams={urlState.query}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum)}
          />
        </div>
      </PageWrapper>
    </div>
  );
};
