import classNames from "classnames";
import { FC, ReactElement, useMemo } from "react";
import { Trans } from "react-i18next";
import { BaseBox } from "src/shared/components";
import "./info-block.scss";

interface Props {
  className?: string;
  valueItems: Record<string, string>;
  title: string;
  description: string;
}

export const InfoBlock: FC<Props> = ({
  className,
  valueItems,
  title,
  description,
}): ReactElement => {
  const componentsItems = useMemo(
    () => ({
      phone: (
        <a
          href={`tel:${valueItems.phone}`}
          className="info-block__description-link"
        >
          ...
        </a>
      ),
      mail: (
        <a
          href={`mailto:${valueItems.mail}`}
          className="info-block__description-link"
        >
          ...
        </a>
      ),
    }),
    [valueItems]
  );

  return (
    <BaseBox className={classNames("info-block", className)} noPadding>
      <h3 className="info-block__title">{title}</h3>
      <p className="info-block__description">
        <Trans
          i18nKey={description}
          components={componentsItems}
          values={valueItems}
        />
      </p>
    </BaseBox>
  );
};
