import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext as useReactContext,
  useEffect,
  useState,
} from "react";

import { useUnit } from "effector-react";

import { useSelector } from "react-redux";

import { languagePicker } from "src/shared/helpers";

import i18n from "src/shared/i18n/i18n";

import useClient from "src/shared/hooks/useClient";

import {
  Active,
  AssessmentStatus,
  HrRoom,
  Play,
  PRoomR,
} from "src/generated/ws4";

import { selectRoom } from "src/shared/store/ducks/room";

import { $simulationListPlayer } from "src/entities/public/simulation-list-player";

import {
  $socketGameV4RoomHrHandler,
  $socketGameV4RoomPlayerHandler,
  $socketGameV4UpdateRoomHandler,
} from "src/shared/api/public/sockets/model/v4";

import { ISimulationPlayer } from "src/shared/store/types";
import { $keycloak } from "../../../entities/public/keycloak/model";

export const useRoomConnect = () => {
  const [simulation, setSimulation] = useState<ISimulationPlayer | null>(null);

  const [simulationTitle, setSimulationTitle] = useState<string>("");

  const [roomLogo, setRoomLogo] = useState<string>("");

  const room = useSelector(selectRoom);

  const [inviteLink, setInviteLink] = useState(room.playUrl || "");

  const storeKeycloak = useUnit($keycloak);

  const socketPlayerRoom = useUnit($socketGameV4RoomPlayerHandler);

  const socketHrRoom = useUnit($socketGameV4RoomHrHandler);

  const socketUpdateRoom = useUnit($socketGameV4UpdateRoomHandler);

  const { items: typesOfSimulationsList } = useUnit($simulationListPlayer);

  const { isPlayerClientId, isHrClientId } = useClient();

  const userId: string | undefined = storeKeycloak?.subject;

  const isAssessmentInProgress =
    room.assessmentStatus === AssessmentStatus.IN_PROGRESS;

  const isAssessmentInLobby = room.assessmentStatus === AssessmentStatus.LOBBY;

  const isAssessmentFin = room.assessmentStatus === AssessmentStatus.FINISHED;

  const onActive = useCallback((data: Active) => {
    if (!!data.playUrl) {
      const resultUrl = data.playUrl.replace("{locale}", i18n.language);
      setInviteLink(resultUrl);
    }
  }, []);

  const onRoomSetState = useCallback((data: PRoomR | HrRoom) => {
    if (data.aStatus === AssessmentStatus.IN_PROGRESS && !!data.playUrl) {
      const resultUrl = data.playUrl.replace("{locale}", i18n.language);
      setInviteLink(resultUrl);
    }
  }, []);

  const onRoomStartGame = useCallback((data: Play) => {
    const resultUrl: string = !!data.url
      ? data.url.replace("{locale}", i18n.language)
      : "";

    setInviteLink(resultUrl);
  }, []);

  const onRoomResultGame = useCallback(() => {
    setInviteLink("");
  }, []);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.pRoomR(onRoomSetState);
      socketPlayerRoom?.pRoomNR(onRoomSetState);
    }

    if (isHrClientId) {
      socketHrRoom?.onStateChange(onRoomSetState);
    }

    return () => {
      socketPlayerRoom?.pRoomR(onRoomSetState)();
      socketPlayerRoom?.pRoomNR(onRoomSetState)();
      socketHrRoom?.onStateChange(onRoomSetState)();
    };
  }, [
    onRoomSetState,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.onPlay(onRoomStartGame);
    }

    if (isHrClientId) {
      socketHrRoom?.onPlay(onRoomStartGame);
    }

    return () => {
      socketPlayerRoom?.onPlay(onRoomStartGame)();
      socketHrRoom?.onPlay(onRoomStartGame)();
    };
  }, [
    onRoomStartGame,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
    onActive,
  ]);

  useEffect(() => {
    socketPlayerRoom?.onActive(onActive);

    return () => {
      socketPlayerRoom?.onActive(onActive)();
    };
  }, [socketPlayerRoom, onActive]);

  useEffect(() => {
    socketPlayerRoom?.onResults(onRoomResultGame);

    return () => {
      socketPlayerRoom?.onResults(onRoomResultGame)();
    };
  }, [onRoomResultGame, isPlayerClientId, socketPlayerRoom]);

  useEffect(() => {
    const simulationMeta: ISimulationPlayer | undefined =
      typesOfSimulationsList?.find(
        (el: ISimulationPlayer) => el.id === room.gameId,
      );

    if (!!simulationMeta) {
      setSimulation(simulationMeta);
    }
  }, [typesOfSimulationsList, room]);

  useEffect(() => {
    if (!!simulation?.title) {
      setSimulationTitle(languagePicker(simulation?.title, i18n.language));
    }
    if (!!simulation?.rawMetaJson?.info.logoUrl) {
      setRoomLogo(
        languagePicker(simulation?.rawMetaJson?.info.logoUrl, i18n.language),
      );
    }
  }, [simulation]);

  return {
    simulation,
    simulationTitle,
    roomLogo,
    room,
    isPlayerClientId,
    userId,
    isAssessmentInProgress,
    isAssessmentInLobby,
    isAssessmentFin,
    inviteLink,
  };
};

type ContextOptions = {
  name?: string;
};

interface IPropsProvider {
  children: ReactNode;
}

export function createCallableCtx<R>(
  value: () => R,
  { name }: ContextOptions,
): readonly [() => R, any] {
  //TODO: Починить типизацию
  const Ctx = createContext<R>({} as R);

  const useContext = () => {
    const context = useReactContext(Ctx);

    if (context === undefined)
      throw new Error("useContext must be inside a Provider with a value");

    return context;
  };

  const Provider: FC<IPropsProvider> = (props) => {
    return <Ctx.Provider value={value()}>{props.children}</Ctx.Provider>;
  };

  if (name) {
    Provider.displayName = name;
  }

  return [useContext, Provider] as const;
}

export const [useRoomConnection, RoomConnectionProvider] = createCallableCtx(
  useRoomConnect,
  { name: "RoomConnectionProvider" },
);
