import { sample } from "effector";
import { Socket } from "socket.io-client";

import { ActAssessment } from "src/generated/ws4";

import { namespaces, paths, urls } from "../config";

import { connectSocket, SocketParams } from "../../lib/connect";

import { socketsV4Domain } from "./sockets";

import { ActSocketCommander } from "./commands";

import { ActSocketHandler } from "./events";

// Events

export const initGameV4Act = socketsV4Domain.createEvent<void>();

export const subGameV4Act = socketsV4Domain.createEvent<void>();

export const unSubGameV4Act = socketsV4Domain.createEvent<void>();

export const setGameActV4Assessments =
  socketsV4Domain.createEvent<ActAssessment[]>();

// Effects

const initGameV4ActFx = socketsV4Domain.createEffect(connectSocket);

const addHandlersFx = socketsV4Domain.createEffect((socket: Socket | null) => {
  if (!socket) return;

  ActSocketHandler.of(socket).onList((res) =>
    setGameActV4Assessments(res.assessments),
  );
});

const subGameV4ActFx = socketsV4Domain.createEffect((socket: Socket | null) => {
  if (!socket) return;

  ActSocketCommander.of(socket).sub();
});

const unSubGameV4ActFx = socketsV4Domain.createEffect(
  (socket: Socket | null) => {
    if (!socket) return;

    ActSocketCommander.of(socket).unSub();
  },
);

// Store

export const $socketGameV4Act = socketsV4Domain.createStore<Socket | null>(
  null,
);

export const $assessmentsAct = socketsV4Domain.createStore<ActAssessment[]>([]);

export const $socketGameV4ActCommander =
  socketsV4Domain.createStore<ActSocketCommander | null>(null);

export const $socketGameV4ActHandler =
  socketsV4Domain.createStore<ActSocketHandler | null>(null);

// Logic

sample({
  clock: initGameV4Act,
  fn: (): SocketParams => ({
    path: paths.game.v4,
    url: urls.game,
    namespace: namespaces.game.act,
  }),
  target: initGameV4ActFx,
});

sample({
  clock: initGameV4ActFx.doneData,
  target: $socketGameV4Act,
});

sample({
  clock: $socketGameV4Act,
  target: addHandlersFx,
});

sample({
  clock: $socketGameV4Act,
  target: subGameV4ActFx,
});

sample({
  clock: setGameActV4Assessments,
  target: $assessmentsAct,
});

sample({
  clock: subGameV4Act,
  source: $socketGameV4Act,
  target: subGameV4ActFx,
});

sample({
  clock: unSubGameV4Act,
  source: $socketGameV4Act,
  target: unSubGameV4ActFx,
});

sample({
  clock: $socketGameV4Act,
  fn: (socket) => socket && ActSocketCommander.of(socket),
  target: $socketGameV4ActCommander,
});

sample({
  clock: $socketGameV4Act,
  fn: (socket) => socket && ActSocketHandler.of(socket),
  target: $socketGameV4ActHandler,
});
