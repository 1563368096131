import { sample } from "effector";

import { errorFx, successFx } from "../../common-notification";

import { notificationDomain, resetNotification } from "../model";

import {
  postNoConfirmationRelativeNotification,
  PostNotification,
  postNotification,
  PostPersonalNotification,
  postPersonalNotification,
} from "src/shared/api/admin/notification/notifications";
import { NotifyApiPostNotifyNoConfirmationRelativeRequest } from "../../../../../generated/notification";

// Events

export const sendNotification =
  notificationDomain.createEvent<PostNotification>();

export const sendPersonalNotification =
  notificationDomain.createEvent<PostPersonalNotification>();

export const sendNoConfirmationRelativeNotification =
  notificationDomain.createEvent<NotifyApiPostNotifyNoConfirmationRelativeRequest>();

// Effects

export const sendNotificationFx =
  notificationDomain.createEffect(postNotification);

export const sendPersonalNotificationFx = notificationDomain.createEffect(
  postPersonalNotification,
);

export const sendNoConfirmationRelativeNotificationFx =
  notificationDomain.createEffect(postNoConfirmationRelativeNotification);

// Logic

sample({
  clock: sendNotification,
  target: sendNotificationFx,
});

sample({
  clock: sendPersonalNotification,
  target: sendPersonalNotificationFx,
});

sample({
  clock: sendNoConfirmationRelativeNotification,
  target: sendNoConfirmationRelativeNotificationFx,
});

sample({
  clock: [sendNotificationFx.doneData, sendPersonalNotificationFx.doneData],
  target: [successFx, resetNotification],
});

sample({
  clock: [sendNotificationFx.fail, sendPersonalNotificationFx.fail],
  target: errorFx,
});
