import { memo, useCallback } from "react";

import { BaseButton, TextWithIcon } from "src/shared/components";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

const { openPopup } = popupModel;

export const DataGridMobileSortButton = memo(() => {
  const showSortPopup = useCallback(() => {
    openPopup({ name: EPopupName.DATAGRID_SORT });
  }, []);

  return (
    <BaseButton onClick={showSortPopup} ariaLabel="Показать сортировку">
      <TextWithIcon
        iconName={"sort-blue"}
        iconSize={[20, 16]}
        isTextNowrap
        hideLabelOnMobile
      />
    </BaseButton>
  );
});
