import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  BaseButton,
  DataGrid,
  EDesiredStudyPlace,
} from "src/shared/components/index";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import {
  CandidateEmployee,
  Player,
  Rating,
  StatusCompanyEnum,
} from "../../../../generated/social";
import { useUnit } from "effector-react";
import {
  $isUpdateLoading,
  $updatedPlayer,
  updatePlayerById,
} from "../../../../features/admin/player-ratings/model";

interface Props {
  items: CandidateEmployee[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const EmployeeTableDataGrid: FC<Props> = ({
  loading,
  items,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const employeeStatus = useUnit($updatedPlayer);

  const desiredStudyPlaceList = Object.values(EDesiredStudyPlace);

  const isLoading = useUnit($isUpdateLoading);

  const filterIsNotEmployee = [
    EDesiredStudyPlace.ALABUGA_POLYTECH,
    EDesiredStudyPlace.ALABUGA_START,
    EDesiredStudyPlace.KFU,
  ];

  const desiredStudyPlaceOptions = desiredStudyPlaceList
    ?.filter((item) => !filterIsNotEmployee.includes(item))
    .map((item) => ({
      label: i18n.t(`userForm.select.desiredStudyPlace.${item}`),
      value: item,
    }));

  let resolvedUsers: Player[] = useMemo(() => [], []);

  useEffect(() => {
    if (employeeStatus) {
      const index = resolvedUsers.findIndex(
        (user) => user.id === employeeStatus.id,
      );

      if (index !== -1) {
        resolvedUsers[index] = employeeStatus;
      } else {
        resolvedUsers.push(employeeStatus);
      }
    }
  }, [employeeStatus, resolvedUsers]);

  const onConfirm = (id: string) => {
    updatePlayerById({
      pId: id,
      body: { statusCompany: StatusCompanyEnum.Employee },
    });
  };

  const onReject = (id: string) => {
    updatePlayerById({
      pId: id,
      body: { statusCompany: StatusCompanyEnum.NoSelected },
    });
  };

  const cancelAction = (id: string) => {
    updatePlayerById({
      pId: id,
      body: { statusCompany: StatusCompanyEnum.CandidateEmployee },
    });
  };

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullName",
        showed: true,
        titleUppercase: true,
        title: t("table.header.name"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.name"),
          activeGroupKey: getActiveGroup(
            ["firstName", "lastName"],
            { all: ["firstName", "lastName"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstName || "",
                },
                {
                  key: "lastName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastName || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstName",
                  "-firstName",
                  "lastName",
                  "-lastName",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstName",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastName",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.phone"),
        type: EDataGridColumnType.PHONE,
        key: "phone",
        showed: true,
        controlPanel: {
          title: t("table.header.phone"),
          activeGroupKey: getActiveGroup(
            ["phone"],
            { all: ["phone"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "phone",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.phone || "",
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.desiredStudyPlace"),
        type: EDataGridColumnType.STRING,
        key: "desiredStudyPlace",
        showed: true,
        filterPosition: EDataGridFilterPosition.RIGHT,
        controlPanel: {
          title: t("table.header.desiredStudyPlace"),
          activeGroupKey: getActiveGroup(
            ["desiredStudyPlace"],
            { all: ["desiredStudyPlace"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "desiredStudyPlace",
                  type: EDataGridFilterType.CHECKBOX,
                  items: desiredStudyPlaceOptions,
                  placeholder: t("table.filters.notSelected"),
                  value: queryParams?.desiredStudyPlace || "",
                },
              ],
            },
          ],
        },
      },
      {
        key: "coordination",
        showed: true,
        titleUppercase: true,
        title: t("common.coordination"),
        type: EDataGridColumnType.STRING,
      },
    ];

    return newColumns;
  }, [queryParams, t]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const fullName = splitStrings([item.firstName, item.lastName]);

      let confirmationStatus: ReactElement | null = null;

      let currentUserStatus = resolvedUsers.find((user) => user.id === item.id);

      if (currentUserStatus) {
        confirmationStatus =
          currentUserStatus.statusCompany === StatusCompanyEnum.Employee ? (
            <span
              onClick={() => {
                cancelAction(item.id);
              }}
              className="moderation-page__coordination__confirmed"
            >
              {t("common.confirmed")}
            </span>
          ) : (
            <span
              onClick={() => cancelAction(item.id)}
              className="moderation-page__coordination__rejected"
            >
              {t("common.rejected")}
            </span>
          );
      }

      const coordination = (
        <div className="moderation-page__coordination-buttons">
          <BaseButton
            onClick={() => onConfirm(item.id)}
            disabled={isLoading}
            isLoading={isLoading}
            xSmall
            xSmallRounded
            primary
          >
            {t("common.confirm")}
          </BaseButton>
          <BaseButton
            onClick={() => onReject(item.id)}
            disabled={isLoading}
            isLoading={isLoading}
            xSmall
            xSmallRounded
            secondary
          >
            {t("common.reject")}
          </BaseButton>
        </div>
      );

      if (
        currentUserStatus?.statusCompany === StatusCompanyEnum.CandidateEmployee
      ) {
        confirmationStatus = coordination;
      }

      return {
        id: item.id,
        selected: false,
        fullName,
        avatarProps: {
          alt: fullName,
          fullName: fullName,
          customPath: getAvatarUrl(item.id),
          userId: item.id,
        },
        phone: item.phone,
        desiredStudyPlace: item.desiredStudyPlace
          ? t(
              `userForm.select.desiredStudyPlace.${item.desiredStudyPlace}`,
              item.desiredStudyPlace,
            )
          : null,
        coordination: confirmationStatus ? confirmationStatus : coordination,
      };
    });

    setRows(rowsItems);
  }, [items, resolvedUsers, t, employeeStatus, isLoading]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
