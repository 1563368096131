import { exportService } from "../../../base/api-instances/export-service";
import {
  ExportFromSocialApiExportRatingToCSVRequest,
  ExportFromSocialApiExportRatingToXLSXRequest,
} from "src/generated/export";

export const exportRatingsCSV = (
  params: ExportFromSocialApiExportRatingToCSVRequest,
) => {
  return exportService.makeExportBlobRequest().exportRatingToCSV(params);
};

export const exportRatingsExcel = (
  params: ExportFromSocialApiExportRatingToXLSXRequest,
) => {
  return exportService.makeExportBlobRequest().exportRatingToXLSX(params);
};
