import classNames from "classnames";
import { FC, MutableRefObject, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseInfo,
  BaseTooltip,
  EPopupDataIdName,
  EPopupName,
} from "../../../../../shared/components";
import { SessionInfoTooltip } from "../session-tooltips/session-info-tooltip/session-info-tooltip";
import { SessionShared } from "../session-shared/session-shared";
import { BannerType } from "../../../../../shared/models";
import { SessionBanner } from "../session-banner/session-banner";
import { SessionControl } from "../session-control/session-control";
import openGameRulesPopup from "../../../../../shared/helpers/openGameRulesPopup";
import useClient from "../../../../../shared/hooks/useClient";
import { RoomStateInterface } from "../../../../../shared/store/ducks/room";
import { AssessmentStatus } from "../../../../../generated/ws4";
import { openPopup } from "../../../../../shared/components/base-popup/model";
import { LocStrDto } from "../../../../../generated/game";
import { snackbarOpen } from "src/features/public/snackbar";

interface SessionHeaderProps {
  room: RoomStateInterface;
  assessmentId?: string;
  isRatingPlayer: boolean;
  roomLogo: string;
  simulationTitle: string;
  rules: LocStrDto;
  online: boolean;
  leaveRoom: () => void;
  startSession: () => void;
}

export const SessionHeader: FC<SessionHeaderProps> = (
  props: SessionHeaderProps,
) => {
  const {
    room,
    online,
    assessmentId,
    simulationTitle,
    isRatingPlayer,
    roomLogo,
    rules,
    leaveRoom,
    startSession,
  } = props;
  const { t, i18n } = useTranslation();

  const lobbyParamsVisible = !!room.createdAt || !!room.createdByUser;

  const isDisableStartBtn = room.assessmentStatus !== AssessmentStatus.LOBBY;

  const lobbyUrl: string = `${process.env.REACT_APP_DOMAIN}/sessions/session/${assessmentId}`;

  const tooltipToggleRef: MutableRefObject<null> = useRef(null);

  const tooltipPlayersConnectRef: MutableRefObject<null> = useRef(null);

  const { isHrClientId } = useClient();

  const onCopyLink = (): void => {
    snackbarOpen({
      text: t("popup.baseMessage.copied"),
      visible: true,
      type: "success",
      verticalCentered: true,
    });
  };

  const openSessionSettings = useCallback((): void => {
    if (assessmentId) {
      openPopup({
        name: EPopupName.ASSESSMENT_PARAMETERS,
        dataId: [{ name: EPopupDataIdName.DEFAULT, value: assessmentId }],
      });
    }
  }, [assessmentId]);

  const openLobbyPopup = (name: EPopupName): void => {
    openPopup({ name });
  };

  return (
    <div className="session__container">
      <div className="session__wrap">
        <div className="session__wrap-box">
          <div
            className={classNames("session__img-wrap", {
              "session__img-wrap__offline": !online,
            })}
          >
            <img alt="session__img" className="session__img" src={roomLogo} />
          </div>
          <div className="session__info">
            <div className="session__info-wrap">
              <div className="session__title">{simulationTitle}</div>
              {lobbyParamsVisible && (
                <div className="tooltip-wrapper" ref={tooltipToggleRef}>
                  <BaseInfo size={24} />
                  <BaseTooltip
                    tooltipToggleRef={tooltipToggleRef}
                    offsetY={-30}
                  >
                    <SessionInfoTooltip room={room} />
                  </BaseTooltip>
                </div>
              )}
            </div>
            <div className="session__sub-title">{room.title}</div>

            <div className="session__params">
              <div className="session__params-item">
                {t(`sessions.session.${room.assessmentType}`)}
              </div>
              <div className="session__params-item">
                {t(
                  `sessions.session.${room.closed_session ? "closed" : "open"}`,
                )}
              </div>
            </div>
          </div>
        </div>

        {isHrClientId && (
          <SessionShared
            assessmentId={assessmentId}
            lobbyUrl={lobbyUrl}
            onCopyLink={onCopyLink}
          />
        )}

        <SessionBanner
          type={BannerType.INFO}
          title={t(`sessions.session.status.game.${room.assessmentStatus}`)}
          description={!isHrClientId ? t("sessions.session.start.default") : ""}
        />
        <SessionControl
          isDisableStartBtn={isDisableStartBtn}
          leaveRoom={leaveRoom}
          rules={rules}
          openRulesPopup={() => {
            openGameRulesPopup(rules[i18n.language as "ru" | "en"]);
          }}
          openDeletePopup={() =>
            openLobbyPopup(EPopupName.CONFIRM_ASSESSMENT_DELETE)
          }
          openStartPopup={() =>
            openLobbyPopup(EPopupName.CONFIRM_ASSESSMENT_START)
          }
          startSession={startSession}
          openSessionSettings={openSessionSettings}
        />
      </div>
      {room.players && (
        <div
          className="session__players-connected tooltip-wrapper"
          ref={tooltipPlayersConnectRef}
        >
          <div className="session__players-connected-text">
            {`${room.players.length || room.connectedPlayers}/${
              room.maxPlayers
            } ${t("sessions.session.playersConnected")}`}
          </div>
          {/*<BaseTooltip*/}
          {/*  mobileWide*/}
          {/*  tooltipToggleRef={tooltipPlayersConnectRef}*/}
          {/*  position="bottom"*/}
          {/*  offsetY={20}*/}
          {/*  className="session-tooltip"*/}
          {/*>*/}
          {/*  <TeamTooltip room={room} isSoloGameType={SoloGameType} />*/}
          {/*</BaseTooltip>*/}
        </div>
      )}

      {isRatingPlayer && (
        <div className="session__wrap">
          <SessionBanner
            description={t("sessions.session.list.players.not.available")}
            className="session__banner--empty"
          />
        </div>
      )}
    </div>
  );
};
