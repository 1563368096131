import {
  Dispatch,
  FC,
  Fragment,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import { FaqQuestionDto, LocStrDto } from "../../../generated/social";
import {
  BaseButton,
  FormControl,
  TextWithIcon,
} from "../../../shared/components";
import { BaseSelect } from "../../../shared/components/form-fields";
import { SelectItem } from "../../../shared/components/form-fields/base-select/base-select";
import {
  isStringEmpty,
  isStringTextEditorEmpty,
} from "../../../shared/helpers/isStringEmpty";
import { toCloneObject } from "../../../shared/helpers/toCloneObject";
import { defaultLanguage, languageList } from "../../../shared/i18n/i18n";
import { IBaseInputChange } from "../../../shared/models/base-input";
import { ERequestStatus, IBooleanLang } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import { EditFaqItem } from "../components";
import "./edit-faq-page.scss";
import { useStore, useUnit } from "effector-react";
import {
  $faqItems,
  $faqStatusGet,
  $isLoadingGet,
  $isLoadingPost,
  cleanFaq,
  fetchFaq,
  fetchFaqPost,
} from "../../../entities/public/faq/model/faq";

export interface IFaqItem {
  question: LocStrDto;
  answer: LocStrDto;
  emptyQuestion: IBooleanLang;
  emptyAnswer: IBooleanLang;
}

const booleanLang: IBooleanLang = {
  ru: false,
  en: false,
};

interface Props {}

const { openPopup } = popupModel;

const QuestionCreators: FC<{
  activeLanguage: keyof LocStrDto;
  setHasFaqItemsErrors: Dispatch<SetStateAction<Record<string, boolean>>>;
  faqItems: IFaqItem[];
  setFaqItems: Dispatch<SetStateAction<IFaqItem[]>>;
}> = ({ activeLanguage, setHasFaqItemsErrors, faqItems, setFaqItems }) => {
  const onDeleteItem = (index: number) => {
    setFaqItems((prev) => {
      const localPrev = [...prev];

      if (localPrev) {
        localPrev.splice(index, 1);
      }

      return localPrev;
    });
  };

  const onChangeAnswer = (payload: IBaseInputChange, index: number) => {
    setFaqItems((prev) => {
      const localPrev = [...prev];
      const isFieldEmpty: boolean = isStringTextEditorEmpty(payload.value);

      if (localPrev) {
        localPrev[index].answer[activeLanguage] = isFieldEmpty
          ? ""
          : payload.value;
        localPrev[index].emptyAnswer[activeLanguage] = isFieldEmpty;
      }

      return localPrev;
    });
  };

  const onChangeQuestion = (payload: IBaseInputChange, index: number) => {
    setFaqItems((prev) => {
      const localPrev = [...prev];
      const isFieldEmpty: boolean = isStringEmpty(payload.value);

      if (localPrev) {
        localPrev[index].question[activeLanguage] = payload.value;
        localPrev[index].emptyQuestion[activeLanguage] = isFieldEmpty;
      }

      return localPrev;
    });
  };

  return (
    <>
      {languageList.map((langItem) => (
        <Fragment key={langItem}>
          {langItem === activeLanguage &&
            faqItems.reduce((newItems, item, index) => {
              const otherLanguage: keyof LocStrDto = languageList.find(
                (lang) => lang !== activeLanguage,
              ) as keyof LocStrDto;

              const isOtherFilled =
                item.question[otherLanguage] && item.question[otherLanguage];

              const isCurrentFilled =
                item.question[activeLanguage] && item.question[activeLanguage];

              if (isOtherFilled && !isCurrentFilled) return newItems;

              const element = (
                <EditFaqItem
                  key={index}
                  indexInList={newItems.length}
                  index={index}
                  item={item}
                  onChangeQuestion={onChangeQuestion}
                  onChangeAnswer={onChangeAnswer}
                  onDelete={onDeleteItem}
                  activeLanguage={activeLanguage}
                  setHasErrors={setHasFaqItemsErrors}
                />
              );

              newItems.push(element);

              return newItems;
            }, [] as JSX.Element[])}
        </Fragment>
      ))}
    </>
  );
};

export const EditFaqPage: FC<Props> = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const faq = useUnit($faqItems);
  const isLoadingGet = useUnit($isLoadingGet);
  const isLoadingPost = useUnit($isLoadingPost);
  const isLoadedGet = useUnit($faqStatusGet) === ERequestStatus.LOADED;

  const [faqItems, setFaqItems] = useState<IFaqItem[]>([]);

  const [activeLanguage, setActiveLanguage] = useState<keyof LocStrDto>(
    defaultLanguage as keyof LocStrDto,
  );
  const [hasFaqItemsErrors, setHasFaqItemsErrors] = useState<
    Record<string, boolean>
  >({});

  const langSelectItems: SelectItem[] = useMemo(
    () =>
      languageList.map((value) => ({
        label: value,
        value,
      })),
    [],
  );

  useEffect(() => {
    fetchFaq();

    return () => {
      cleanFaq();
    };
  }, []);

  useEffect(() => {
    if (!!faq.length) {
      setFaqItems(() => {
        return faq.map((item) => {
          return {
            question: item.question,
            answer: item.answer,
            emptyQuestion: toCloneObject(booleanLang),
            emptyAnswer: toCloneObject(booleanLang),
          };
        });
      });
    }
  }, [faq, i18n.language]);

  const onAddFaqItem = () => {
    setFaqItems((prev) => {
      const localPrev = [...prev];
      const stringLang: LocStrDto = {
        ru: "",
        en: "",
      };

      if (localPrev) {
        localPrev.push({
          question: toCloneObject(stringLang),
          answer: toCloneObject(stringLang),
          emptyQuestion: toCloneObject(booleanLang),
          emptyAnswer: toCloneObject(booleanLang),
        });
      }

      return localPrev;
    });
  };

  const onSave = () => {
    const questions: FaqQuestionDto[] =
      faqItems.reduce((result, { question, answer }) => {
        const isAllLanguagesQuestionsEmpty =
          Object.values(question).filter(Boolean).length > 0;

        const isAllLanguagesAnswersEmpty =
          Object.values(answer).filter(Boolean).length > 0;

        const isNotEmptyQuestion =
          isAllLanguagesQuestionsEmpty && isAllLanguagesAnswersEmpty;

        if (isNotEmptyQuestion) {
          const faqItem = {
            question,
            answer,
            order: result.length + 1,
          };

          result.push(faqItem);
        }

        return result;
      }, [] as FaqQuestionDto[]) || [];

    const callback = () => {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.baseMessage.dataSaved"),
        },
      });
    };

    fetchFaqPost({ items: { questions }, callback });
  };

  const onLangChange = (payload: IBaseInputChange) => {
    setActiveLanguage(payload.value as keyof LocStrDto);
  };

  const hasErrors = Object.values(hasFaqItemsErrors).filter(Boolean).length > 0;

  return (
    <PageWrapper
      title={t("editFaq.title")}
      titlePanelSlot={
        <div className="edit-faq-page__desktop-save-btn">
          <BaseButton
            primary
            small
            disabled={hasErrors || isLoadingPost}
            onClick={onSave}
          >
            {t("editFaq.btn.save")}
          </BaseButton>
        </div>
      }
      isLoadingPanel={isLoadingGet}
    >
      {isLoadedGet && (
        <div className="edit-faq-page">
          {!!langSelectItems.length && (
            <FormControl>
              <BaseSelect
                placeholder={t("common.languageSelect.placeholder")}
                items={langSelectItems}
                onChange={onLangChange}
                activeItem={activeLanguage}
              />
            </FormControl>
          )}
          <QuestionCreators
            activeLanguage={activeLanguage}
            setHasFaqItemsErrors={setHasFaqItemsErrors}
            faqItems={faqItems}
            setFaqItems={setFaqItems}
          />
          <BaseButton onClick={onAddFaqItem}>
            <TextWithIcon
              label={t("editFaq.btn.addQuestion")}
              iconName="plus-blue"
            />
          </BaseButton>
          <div className="edit-faq-page__mobile-save-btn">
            <BaseButton
              primary
              large
              block
              disabled={hasErrors || isLoadingPost}
              onClick={onSave}
            >
              {t("editFaq.btn.save")}
            </BaseButton>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};
