import { createDomain, sample } from "effector";

import { finesModel } from "src/entities/admin/fines";

// Domains

const finesPage = createDomain();

// Stores

export const $isLoading = finesPage.createStore<boolean>(false);

// Logic

sample({
  clock: finesModel.$isLoading,
  target: $isLoading,
});
