import React, { FC, ReactElement, useEffect, useState } from "react";

import styles from "./new-base-accoridon.module.scss";

import crossIcon from "../../images/icons/cross-blue.svg";
import classNames from "classnames";

interface Props {
  title: string;
  children: ReactElement;
  onClick?: () => void;
  open?: boolean;
  className?: string;
}

export const NewBaseAccordion: FC<Props> = ({
  title,
  children,
  onClick,
  open,
  className,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    setIsActive(open ?? isActive);
  }, [open]);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      className={classNames(styles.accordion, className, {
        [styles.active]: isActive,
      })}
    >
      <div className={styles.title} onClick={onClick || toggleActive}>
        <h3>{title}</h3>
        <img
          src={crossIcon}
          alt="cross"
          className={classNames({
            [styles.active]: isActive,
          })}
        />
      </div>

      <div
        className={classNames(styles.body, {
          [styles.active]: isActive,
        })}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};
