export const pathToUserFile = `${process.env.REACT_APP_yandexStorageEndpointWebsite}/${process.env.REACT_APP_yandexStorageImagesBucket}`;

export const getAvatarUrl = (
  userId: string | undefined,
  isDrag?: boolean,
): string => {
  if (isDrag) {
    return userId ? `${pathToUserFile}/avatars/${userId}` : "none";
  } else {
    return userId
      ? `${pathToUserFile}/avatars/${userId}?random=${performance.now()}`
      : "none";
  }
};

export const getResumeUrl = (userId: string): string => {
  return `${pathToUserFile}/resumes/${userId}`;
};

export const getContactAvatarUrl = (contactAvatarId: string): string => {
  return `${pathToUserFile}/contacts/${contactAvatarId}`;
};
