import axios from "axios";

interface IPayload {
  clientId: string;
  redirectUri: string;
}

export const putRepeatVerifiedEmail = async (payload: IPayload) => {
  await axios.put(
    `${process.env.REACT_APP_KC_URL}/realms/${process.env.REACT_APP_KC_REALM}/accountEx/send-verify-email?client_id=${payload.clientId}&redirect_uri=${payload.redirectUri}`,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("alabuga-token"),
      },
    },
  );
};
