import { FC, MouseEvent, ReactNode, TouchEvent } from "react";

import { WsMsg } from "src/generated/notification";

import { useMediaQuery } from "src/shared/hooks/useMediaQuery";

import { BaseButton, IconCross } from "src/shared/components";

import { IconCrossColor } from "src/shared/components/icons-components/icon-cross/icon-cross";

import "./base-card.scss";

type BaseCardProps = {
  title?: string;
  altText?: string;
  iconUrl?: string;
  onClose?: (messages: WsMsg) => void;
  isClosable?: boolean;
  message: WsMsg;
  children?: ReactNode;
  buttonProps?: {
    url: string;
    title: string;
  };
};

export const BaseCard: FC<BaseCardProps> = ({
  title,
  iconUrl,
  altText,
  onClose,
  children,
  buttonProps,
  message,
  isClosable = true,
}) => {
  const { isMobile } = useMediaQuery();

  const handleClose = () => onClose?.(message);

  const isClosableMobile = (!isClosable && isMobile) || isClosable;

  const handleTouch = (() => {
    let clientY: number;

    return (e: TouchEvent<HTMLDivElement>) => {
      const isTouchEnd = e.touches.length === 0;

      const touchCoordY = e.changedTouches[0].clientY;

      if (isTouchEnd && clientY > touchCoordY) {
        handleClose();
      }

      clientY = touchCoordY;
    };
  })();

  const handleMouse = (() => {
    let clientY: number;

    return (e: MouseEvent<HTMLDivElement>) => {
      const isMouseEnd = e.type === "mouseleave" || e.type === "mouseup";

      const touchCoordY = e.clientY;

      if (isMouseEnd && clientY > touchCoordY && isClosableMobile) {
        handleClose();
      }

      clientY = touchCoordY;
    };
  })();

  return (
    <div
      className="base-tile"
      onTouchStart={handleTouch}
      onTouchEnd={handleTouch}
      onMouseDown={handleMouse}
      onMouseLeave={handleMouse}
      onMouseUp={handleMouse}
    >
      {title && <h5 className="base-tile__title">{title}</h5>}

      <div className="base-tile__card">
        {iconUrl && (
          <img
            className="base-tile__image"
            src={iconUrl}
            alt={altText ?? title}
          />
        )}

        <div className="base-tile__content">{children}</div>
      </div>

      {onClose && isClosable && (
        <div className="base-tile__icon-cross">
          <IconCross
            onClick={handleClose}
            size={10}
            color={IconCrossColor["DARK-GREY"]}
          />
        </div>
      )}

      {buttonProps && (
        <BaseButton className="base-tile__button" to={buttonProps.url} light>
          {buttonProps.title}
        </BaseButton>
      )}
    </div>
  );
};
