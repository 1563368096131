import classNames from "classnames";
import React, {
  CSSProperties,
  FC,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import { pxToRem } from "../../helpers/pxToRem";
import { useMediaQuery } from "../../hooks/useMediaQuery";

import { CirclesSpinner } from "../icons-components";
import "./text-with-icon.scss";

export type TTextWithIconName =
  | "upload-blue"
  | "plus-blue"
  | "lock-blue"
  | "lock-blue-large"
  | "lock-gray"
  | "unlock-blue"
  | "trash-blue"
  | "minus-blue"
  | "file-blue"
  | "cross-blue"
  | "cross-white"
  | "cross-red"
  | "cross-red-bold"
  | "trash-red"
  | "history-blue"
  | "settings-blue"
  | "settings-gray"
  | "star-blue"
  | "pen-blue"
  | "play-white"
  | "logout-blue"
  | "logout-white"
  | "logout-red"
  | "book-white"
  | "human-gray"
  | "download-blue"
  | "mail-gray"
  | "phone-gray"
  | "globe-gray"
  | "vk-color"
  | "youtube-color"
  | "whats-app-white"
  | "telegram-white"
  | "copy-blue"
  | "circle-arrow-blue"
  | "props-gray"
  | "sort-blue"
  | "filters-blue"
  | "quit-icon"
  | "state-gray"
  | "check-square"
  | "burger-gray"
  | "close-gray"
  | "ban"
  | "notification-bell"
  | "check-success-tick-green"
  | "vk-blue"
  | "facebook-blue"
  | "dice-white"
  | "icon-check-blue"
  | "icon-check-green"
  | "translate-icon"
  | "docs-icon"
  | "college-logo"
  | "personnel-logo"
  | "trash-red-filled"
  | "camera-blue-filled"
  | "lock-filled"
  | "fullscreen-gray"
  | "arrow-down"
  | "g-translate"
  | "warning-red"
  | "fine"
  | "drag"
  | "drag-blue"
  | "lens"
  | "cross-grey"
  | "copy-icon";

export type TTextWithIconColor = "red" | "black" | "blue" | "gray" | "white";

export type TTextWithIconLabelWeight =
  | "initial"
  | "inherit"
  | "unset"
  | "normal"
  | "bold"
  | 400
  | 600
  | 700
  | 800;

export interface ITextWithIconProps {
  className?: string;
  classNameIcon?: string;
  children?: ReactNode;
  label?: string;
  iconName: TTextWithIconName;
  color?: TTextWithIconColor;
  disabled?: boolean;
  isTextNowrap?: boolean;
  isLoader?: boolean;
  iconSize?: number | [number, number];
  iconSizeMobile?: number | [number, number];
  labelSize?: number;
  labelSizeMobile?: number;
  labelWeight?: TTextWithIconLabelWeight;
  labelWeightMobile?: TTextWithIconLabelWeight;
  hideLabel?: boolean;
  hideLabelOnMobile?: boolean;
  isBreakWord?: boolean;
  isBlock?: boolean;
  customWidth?: string;
}

export const TextWithIcon: FC<ITextWithIconProps> = ({
  className,
  classNameIcon,
  label,
  iconName,
  color = "blue",
  disabled = false,
  isTextNowrap = false,
  isLoader = false,
  iconSize = 16,
  iconSizeMobile,
  labelSize = 14,
  labelSizeMobile,
  hideLabel = false,
  children,
  hideLabelOnMobile,
  labelWeight = "bold",
  labelWeightMobile,
  isBreakWord,
  isBlock,
  customWidth,
}): ReactElement => {
  const { isLaptop } = useMediaQuery();

  const [iconPath, setIconPath] = useState<string>();

  const localIconSize: number | [number, number] = isLaptop
    ? iconSize
    : iconSizeMobile || iconSize;

  const localLabelSize: number = isLaptop
    ? labelSize
    : labelSizeMobile || labelSize;

  const localLabelWeight: TTextWithIconLabelWeight = isLaptop
    ? labelWeight
    : labelWeightMobile || labelWeight;

  const iconStyle: CSSProperties = {
    backgroundImage: !isLoader && !!iconPath ? `url(${iconPath})` : undefined,
    minWidth:
      typeof localIconSize === "number"
        ? pxToRem(localIconSize)
        : pxToRem(localIconSize[0]),
    width:
      typeof localIconSize === "number"
        ? pxToRem(localIconSize)
        : pxToRem(localIconSize[0]),
    height:
      typeof localIconSize === "number"
        ? pxToRem(localIconSize)
        : pxToRem(localIconSize[1]),
  };

  const labelStyle: CSSProperties = {
    fontSize: pxToRem(localLabelSize),
    fontWeight: localLabelWeight,
    width: customWidth,
  };

  const getIcon = async (name: string) => {
    try {
      const icon = await import(`../../images/icons/${name}.svg`);

      setIconPath(icon.default);
    } catch (error: any) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getIcon(iconName);
  }, []);

  return (
    <span
      className={classNames(
        "text-with-icon",
        `text-with-icon--${color}`,
        className,
        {
          "text-with-icon--disabled": disabled,
          "text-with-icon--text-nowrap": isTextNowrap,
          "text-with-icon--is-loader": isLoader,
          "text-with-icon--hide-label": hideLabel,
          "text-with-icon--hide-label-on-mobile": hideLabelOnMobile,
          "text-with-icon--break-word": isBreakWord,
          "text-with-icon--block": isBlock,
        },
      )}
      style={labelStyle}
    >
      <span
        className={classNames("text-with-icon__icon", classNameIcon, {
          "text-with-icon__icon--hide-label": hideLabel,
          "text-with-icon__icon--hide-label-on-mobile": hideLabelOnMobile,
        })}
        style={iconStyle}
      >
        {isLoader && <CirclesSpinner color={color} />}
      </span>
      {!hideLabel &&
        ((
          <span
            className={classNames({
              "label-text-rule": !!customWidth,
            })}
          >
            {label}
          </span>
        ) ||
          children)}
    </span>
  );
};
