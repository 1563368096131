import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { Pagination } from "../../../../../shared/api/base";
import {
  AssessmentHistoryApiGetDownloadAssessmentHistoryRequest,
  PlayersApiGetChangesByAdminPlayerRequest,
  PlayersApiPostChangesByAdminPlayerRequest,
  UserChangesByAdminPlayerDto,
} from "src/generated/social";
import {
  getUsersHistory,
  postUsersHistory,
} from "../../../../../shared/api/admin/histories/model/users-change-history";
// Domain

export const changeUsersHistoryDomain = createDomain("changeUsersHistoryAdmin");

// Events

export const fetchChangeUsersHistory =
  changeUsersHistoryDomain.createEvent<PlayersApiGetChangesByAdminPlayerRequest>();

export const postChangeUsersHistory =
  changeUsersHistoryDomain.createEvent<PlayersApiPostChangesByAdminPlayerRequest>();

// Effects

export const fetchChangeUsersHistoryFx =
  changeUsersHistoryDomain.createEffect(getUsersHistory);

export const postChangeUsersHistoryFx =
  changeUsersHistoryDomain.createEffect(postUsersHistory);

// Stores

export const $changeUsersHistory = changeUsersHistoryDomain.createStore<
  UserChangesByAdminPlayerDto[]
>([]);

export const $pagination =
  changeUsersHistoryDomain.createStore<Pagination>(null);

export const $queryParams =
  changeUsersHistoryDomain.createStore<AssessmentHistoryApiGetDownloadAssessmentHistoryRequest>(
    {},
  );

export const $isLoadingUsers =
  changeUsersHistoryDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchChangeUsersHistory,
  target: fetchChangeUsersHistoryFx,
});

sample({
  clock: postChangeUsersHistory,
  target: postChangeUsersHistoryFx,
});

sample({
  clock: fetchChangeUsersHistory,
  target: $queryParams,
});

sample({
  clock: fetchChangeUsersHistoryFx.pending,
  target: $isLoadingUsers,
});

spread({
  source: fetchChangeUsersHistoryFx.doneData.map(
    ({ user_changes, pagination }) => ({
      user_changes,
      pagination: pagination || null,
    }),
  ),
  targets: {
    user_changes: $changeUsersHistory,
    pagination: $pagination,
  },
});
