import { createEffect, createEvent, createStore, sample } from "effector";
import { ERequestStatus } from "src/shared/store/types";
import { IUsersListAdminState, ReadMoreType } from "./types";
import { User, UsersApiUsersRequest, UsersRes } from "src/generated/keycloak";
import { getUsers } from "src/shared/api/admin/users";

// Stores

export const $usersListAdmin = createStore<IUsersListAdminState>({
  items: [],
  isReadmore: false,
  pagination: null,
  status: ERequestStatus.NEVER,
});

// Events

export const cleanUsersListAdmin = createEvent();
export const requestUsersListAdmin = createEvent<UsersApiUsersRequest>();
export const setUsersListAdminIsReadmore = createEvent<ReadMoreType>();
export const successUsersListAdmin = createEvent<UsersRes>();
export const failureUsersListAdmin = createEvent();

// Effects

export const fetchUsersListAdminFx = createEffect(getUsers);

// Logic

$usersListAdmin.on(cleanUsersListAdmin, (state) => ({
  ...state,
  items: [],
  pagination: null,
  status: ERequestStatus.NEVER,
}));

$usersListAdmin.on(setUsersListAdminIsReadmore, (state, payload) => ({
  ...state,
  isReadmore: payload.value,
}));

$usersListAdmin.on(requestUsersListAdmin, (state) => ({
  ...state,
  status: ERequestStatus.LOADING,
}));

$usersListAdmin.on(failureUsersListAdmin, (state) => ({
  ...state,
  status: ERequestStatus.ERROR,
  isReadmore: false,
  items: [],
  pagination: null,
}));

$usersListAdmin.on(successUsersListAdmin, (state, payload) => ({
  ...state,
  status: ERequestStatus.LOADED,
  pagination: payload.pagination,
  isReadmore: false,
  items: state.isReadmore
    ? [...(state.items as User[]), ...payload.users]
    : payload.users,
}));

sample({
  clock: fetchUsersListAdminFx.fail,
  target: failureUsersListAdmin,
});

sample({
  source: requestUsersListAdmin,
  target: fetchUsersListAdminFx,
});

sample({
  source: fetchUsersListAdminFx.doneData,
  target: successUsersListAdmin,
});
