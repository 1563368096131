import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { QueryParams } from "../../../../shared/api/base";

import { queryParamsToGetListParamsAdapter } from "../lib";
import {
  addFine,
  archiveFine,
  getList,
} from "../../../../shared/api/admin/fines/model";
import {
  FineArchiveApiArchivePlayerFinesRequest,
  FineDto,
  FinesApiPostPlayerFinesRequest,
  Pagination,
} from "src/generated/social";

// Domain

export const finesDomain = createDomain("finesAdmin");

// Events

export const fetchFines = finesDomain.createEvent<QueryParams>();
export const postFines =
  finesDomain.createEvent<FinesApiPostPlayerFinesRequest>();

export const archiveFines =
  finesDomain.createEvent<FineArchiveApiArchivePlayerFinesRequest>();

// Effects

export const fetchFinesFx = finesDomain.createEffect(getList);
export const postFinesFx = finesDomain.createEffect(addFine);
export const archiveFinesFx = finesDomain.createEffect(archiveFine);

// Stores

export const $fines = finesDomain.createStore<FineDto[]>([]);

export const $pagination = finesDomain.createStore<Pagination | null>(null);

export const $queryParams = finesDomain.createStore<QueryParams>({});

export const $isLoading = finesDomain.createStore<boolean>(false);

// Logic

sample({
  clock: postFines,
  target: postFinesFx,
});

sample({
  clock: archiveFines,
  target: archiveFinesFx,
});

sample({ clock: archiveFinesFx.done, source: {}, target: fetchFines });

sample({ clock: postFinesFx.done, source: {}, target: fetchFines });

sample({
  clock: fetchFines,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchFinesFx,
});

sample({
  clock: fetchFines,
  target: $queryParams,
});

sample({
  clock: fetchFinesFx.pending,
  target: $isLoading,
});

spread({
  source: fetchFinesFx.doneData.map(({ fines, pagination }) => ({
    fines: fines,
    pagination: pagination || null,
  })),
  targets: {
    fines: $fines,
    pagination: $pagination,
  },
});
