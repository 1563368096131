import { createEffect, createEvent, createStore, sample } from "effector";
import { ERequestStatus, ISimulationPlayer } from "src/shared/store/types";
import { ISimulationListPlayerState } from "./types";
import { getPGames } from "src/shared/api/public/simulation-list-player";

// Stores

export const $simulationListPlayer = createStore<ISimulationListPlayerState>({
  items: [],
  status: ERequestStatus.NEVER,
});

// Events

export const cleanSimulationListPlayer = createEvent();
export const requestSimulationListPlayer = createEvent();
export const successSimulationListPlayer = createEvent<ISimulationPlayer[]>();
export const failureSimulationListPlayer = createEvent();

// Effects

export const fetchSimulationListPlayerFx = createEffect(getPGames);

// Logic

$simulationListPlayer.reset(cleanSimulationListPlayer);

$simulationListPlayer.on(requestSimulationListPlayer, (state) => ({
  ...state,
  status: ERequestStatus.LOADING,
}));

$simulationListPlayer.on(successSimulationListPlayer, (state, payload) => {
  return {
    ...state,
    items: payload,
    status: ERequestStatus.LOADED,
  };
});

$simulationListPlayer.on(failureSimulationListPlayer, (state) => ({
  ...state,
  status: ERequestStatus.ERROR,
}));

sample({
  clock: fetchSimulationListPlayerFx.fail,
  target: failureSimulationListPlayer,
});

sample({
  source: requestSimulationListPlayer,
  target: fetchSimulationListPlayerFx,
});

sample({
  source: fetchSimulationListPlayerFx.doneData,
  fn: (sourceData) => sourceData as ISimulationPlayer[],
  target: successSimulationListPlayer,
});
