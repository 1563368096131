export const getGrowthRatio = (growth: number, loss: number): number => {
  const totalDiff = growth + loss;

  const ratio = (growth / totalDiff) * 100;

  if (ratio === 0) {
    return 100;
  }

  if (ratio >= 97 && ratio < 100) {
    return 97;
  }

  return ratio;
};
