import { ReactElement } from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { BaseBox, BaseInputText, FormControl } from "src/shared/components";

import BaseTextEditor from "src/shared/components/form-fields/base-text-editor/base-text-editor";

import { RecipientsForm } from "src/shared/components/recipients-form/recipients-form";

import { IBaseInputChange } from "src/shared/models/base-input";

import { emailNotificationModel } from "src/entities/admin/notification";

import "./email-newsletter-form.scss";

export const EmailNewsletterForm = (): ReactElement => {
  const { t } = useTranslation();

  const { $emailTheme, $emailText, updateEmailText, updateEmailTheme } =
    emailNotificationModel;

  const emailTheme = useUnit($emailTheme);

  const emailText = useUnit($emailText);

  const onChangeEmailText = (e: IBaseInputChange) => {
    updateEmailText(e.value);
  };

  const onChangeEmailTheme = (e: IBaseInputChange) => {
    updateEmailTheme(e.value);
  };

  return (
    <div className="email-newsletter-form__info-page">
      <div className="email-newsletter-form__info-page-form">
        <BaseBox className="email-newsletter-form__info-page-data">
          <FormControl>
            <BaseInputText
              name="emailTheme"
              placeholder={t("email.newsLetter.form.letterSubject")}
              value={emailTheme}
              onChange={onChangeEmailTheme}
            />
          </FormControl>
          <FormControl>
            <BaseTextEditor
              name="emailContent"
              value={emailText}
              onChange={onChangeEmailText}
              className="email-newsletter-form__text-input"
            />
          </FormControl>
        </BaseBox>
        <RecipientsForm name="radioValue" />
      </div>
    </div>
  );
};
