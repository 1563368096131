import {
  FC,
  ReactElement,
  ReactEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import classNames from "classnames";

import { IDataGridColumn, TDataGridDataParams } from "../../../models/dataGrid";

import { BaseSelect } from "../../form-fields";
import { SelectItem } from "../../form-fields/base-select/base-select";

import { DataGridMobileSortGroup } from ".";

import { isActiveOrder } from "../../../helpers/data-grids/getOrderByValue";

interface Props {
  column: IDataGridColumn;
  queryParams: TDataGridDataParams;
  onUpdateSortAndFilters: (params: TDataGridDataParams) => void;
}

export const DataGridMobileSortColumn: FC<Props> = ({
  column,
  queryParams,
  onUpdateSortAndFilters,
}): ReactElement => {
  const [activeGroupKey, setActiveGroupKey] = useState<string>(
    column?.controlPanel?.activeGroupKey || ""
  );

  const [opened, setOpened] = useState<boolean>(false);

  const onToggle: ReactEventHandler = (e): void => {
    const target = e.target as HTMLDetailsElement;

    setOpened(target.open);
  };

  const groups = useMemo(() => column?.controlPanel?.groups || [], [
    column.controlPanel?.groups,
  ]);

  const activeGroup = useMemo(() => {
    return groups.find((g) => g.key === activeGroupKey);
  }, [groups, activeGroupKey]);

  const hasValue = useMemo(
    () =>
      groups.some((g) => {
        const orderKey = g.sort?.orderKey || "";

        return g.sort?.items.some((item) =>
          isActiveOrder(item.value, queryParams[orderKey])
        );
      }),
    [groups, queryParams]
  );

  const values = useMemo<string[]>(() => {
    if (activeGroup?.sort?.orderKey) {
      const valuesStr = queryParams[activeGroup?.sort?.orderKey];

      return valuesStr ? valuesStr.split(",") : [];
    }

    return [];
  }, [queryParams, activeGroup]);

  const sortIndex = useMemo(() => {
    const columnSortValues =
      activeGroup?.sort?.items.map((item) => item.value) || [];

    return values.findIndex((val) => columnSortValues.includes(val)) + 1;
  }, [values, activeGroup]);

  const groupListForSelect: SelectItem[] = useMemo(() => {
    return groups.map(
      ({ title, key }): SelectItem => ({ label: title || key, value: key })
    );
  }, [groups]);

  const apply = useCallback(
    (value: string) => {
      const params = { ...queryParams };

      groups.forEach((g) => {
        // Если есть поле сортировки
        if (g?.sort?.orderKey) {
          // Все значения сортировки в текущей группе
          const groupSortValues = g.sort.items.map((item) => item.value);

          // Ищем значения текущей сортировки
          const sortField = params[g.sort?.orderKey];

          // Разбиваем текущее значение сортировки на массив
          let sortFieldValues = sortField ? sortField.split(",") : [];

          // Совпадает ли группа с выбранной
          if (g.key === activeGroupKey) {
            const isExistSort = sortFieldValues.includes(value);

            // Убираем лишнее значение сортировки для последующей замены
            sortFieldValues = sortFieldValues.filter(
              (val) => !groupSortValues.includes(val)
            );

            // Если обновленное значение указано
            if (value && !isExistSort) {
              sortFieldValues.push(value);
            }
          } else {
            // Убираем значения неактивной группы из сортировки
            sortFieldValues = sortFieldValues.filter((val) => val !== value);
          }

          if (sortFieldValues.length > 0) {
            params[g.sort.orderKey] = sortFieldValues.join(",");
          } else {
            delete params[g.sort.orderKey];
          }
        }
      });

      onUpdateSortAndFilters(params);
    },
    [groups, queryParams, activeGroupKey, onUpdateSortAndFilters]
  );

  useEffect(() => {
    groups.forEach((g) => {
      g.sort?.items.forEach(({ value }) => {
        const orderKey = g.sort?.orderKey || "";

        if (isActiveOrder(value, queryParams[orderKey])) {
          setActiveGroupKey(g.key);
        }
      });
    });
  }, [queryParams, groups]);

  return (
    <details
      className="data-grid__mobile-sort-item"
      onToggle={onToggle}
      open={opened}
      key={column.key}
    >
      <summary
        className={classNames("data-grid__mobile-sort-item-title", {
          "data-grid__mobile-sort-item-title--opened": opened,
          "data-grid__mobile-sort-item-title--has-value": hasValue,
        })}
      >
        {column.filterTitle || column.title}
        {!!sortIndex && (
          <div className="data-grid__mobile-sort-item-index">{sortIndex}</div>
        )}
      </summary>
      {groups.length > 1 && (
        <div className="data-grid__mobile-filters-item-form-control">
          <BaseSelect
            size="medium"
            className="data-grid__mobile-filters-select-group"
            forDarkBg
            placeholder="Не выбрана"
            activeItem={activeGroupKey}
            name={activeGroupKey}
            items={groupListForSelect}
            onChange={({ value }) => {
              setActiveGroupKey(value);
            }}
          />
        </div>
      )}
      {activeGroup && (
        <DataGridMobileSortGroup
          group={activeGroup}
          values={values}
          setValue={apply}
        />
      )}
    </details>
  );
};
