import { PlayersApiGetPlayerRatingsRequest } from "src/generated/social";
import { socialService } from "../../../base/api-instances";

export const getUserPlaceRating = (
  payload: PlayersApiGetPlayerRatingsRequest,
) => {
  return socialService
    .makePlayerRequest()
    .getPlayerRatings(payload)
    .then((response) => response.data);
};
