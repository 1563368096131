import { sample } from "effector";

import { TestResult } from "src/generated/game";

import {
  postTestCalculationFormulas,
  PostTestFormulasParams,
  updateAllCalculations,
  UpdateAllCalculationsParams,
} from "src/shared/api/admin/calculations";

import { formulasDomain } from "./domain";

// Stores

export const $testResults = formulasDomain.createStore<TestResult[]>([]);

export const $isLoading = formulasDomain.createStore<boolean>(false);

// Events

export const fetchTestFormulas =
  formulasDomain.createEvent<PostTestFormulasParams>();

export const fetchTestSingleFormula =
  formulasDomain.createEvent<PostTestFormulasParams>();

export const fetchUpdateFormulas =
  formulasDomain.createEvent<UpdateAllCalculationsParams>();

// Effect

export const fetchTestFormulasFx = formulasDomain.createEffect(
  postTestCalculationFormulas,
);

export const fetchTestSingleFormulaFx = formulasDomain.createEffect(
  postTestCalculationFormulas,
);

export const fetchUpdateFormulasFx = formulasDomain.createEffect(
  updateAllCalculations,
);

// Logic

sample({
  clock: fetchTestFormulas,
  target: fetchTestFormulasFx,
});

sample({
  clock: fetchTestSingleFormula,
  target: fetchTestSingleFormulaFx,
});

sample({
  clock: fetchUpdateFormulas,
  target: fetchUpdateFormulasFx,
});

sample({
  clock: [
    fetchTestFormulasFx.pending,
    fetchTestSingleFormulaFx.pending,
    fetchUpdateFormulasFx.pending,
  ],
  target: $isLoading,
});

sample({
  clock: [fetchTestFormulasFx.doneData, fetchTestSingleFormulaFx.doneData],
  fn: ({ results }) => results,
  target: $testResults,
});
