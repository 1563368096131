import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import { IBaseMultiSelectChange } from "../../../models";
import { SelectItem, TSelectSize } from "../base-select/base-select";
import Select, {
  GroupBase,
  IndicatorsContainerProps,
  MultiValue,
} from "react-select";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { SelectArrow } from "../select-arrow/select-arrow";
import { useTranslation } from "react-i18next";
import { selectStyles } from "../base-select/select";

interface Props {
  className?: string;
  items: SelectItem[];
  activeItems?: string[];
  placeholder?: string;
  name?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  isTopPosition?: boolean;
  onChange: (payload: IBaseMultiSelectChange) => void;
  canSearch?: boolean;
  size?: TSelectSize;
  forDarkBg?: boolean;
  hideBorder?: boolean;
  forTable?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

export const BaseMultiSelect: FC<Props> = ({
  className,
  items,
  activeItems = [],
  placeholder,
  onChange,
  name,
  error,
  required,
  disabled,
  canSearch,
  isTopPosition,
  onMenuOpen,
  size,
  forDarkBg,
  onMenuClose,
  hideBorder,
  forTable,
}): ReactElement => {
  const { t } = useTranslation();
  const { isLaptop } = useMediaQuery();
  const [searchValue, setSearchValue] = useState<string>("");
  const localActiveItems: SelectItem[] = useMemo(() => {
    return items.filter((item) => activeItems.includes(item.value));
  }, [items, activeItems]);
  const label: string = useMemo(() => {
    const localItems = items.filter((item) => localActiveItems.includes(item));

    return localItems.map((item) => item.label).join(", ");
  }, [items, localActiveItems]);

  const onChangeHandler = (value: MultiValue<SelectItem>): void => {
    const localValue: string[] = value.map((item) => item.value);

    onChange({ value: localValue, name });
  };

  const indicatorsContainer = useCallback(
    (
      state: PropsWithChildren<
        IndicatorsContainerProps<SelectItem, true, GroupBase<SelectItem>>
      >,
    ) => <SelectArrow size={size} forDarkBg isDisabled={state.isDisabled} />,
    [size],
  );
  return (
    <Select<SelectItem, true, GroupBase<SelectItem>>
      noOptionsMessage={() => <span>{t("common.nothingFound")}</span>}
      isMulti
      classNamePrefix="base-multi-select"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      isDisabled={disabled}
      isSearchable={canSearch}
      className={classNames("base-select", className)}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      openMenuOnFocus={false}
      options={items}
      onChange={(event) => event && onChangeHandler(event)}
      value={localActiveItems}
      inputValue={searchValue}
      onInputChange={(value) => {
        setSearchValue(value);
      }}
      components={{
        IndicatorsContainer: (state) =>
          !forTable ? indicatorsContainer(state) : null,
      }}
      styles={selectStyles({
        isSearchValue: !!searchValue,
        isTopPosition,
        canSearch,
        error,
        placeholder,
        required,
        isLaptop,
        label,
        hideBorder,
        forTable,
        t,
        size,
        forDarkBg,
      })}
    />
  );
};
