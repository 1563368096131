import { Fragment, memo } from "react";

import { BaseButton, EPopupName, TextWithIcon } from "src/shared/components";

import "./google-translate-popup.scss";
import { useCheckUserAgent } from "../../../hooks/useCheckUserAgent";

import { popupModel } from "../../base-popup";
import { GTDownloadButton } from "./download-button/download-button";

interface Props {}

const { closePopup } = popupModel;

export const GTPopup = memo<Props>(() => {
  const { isChrome, isMobile, isDesktop, isIOS } = useCheckUserAgent();

  const onCloseHandler = () => {
    closePopup({ name: EPopupName.GOOGLE_TRANSLATE });
  };

  return (
    <div className="gt-popup">
      <div className="gt-popup__header">
        <BaseButton onClick={onCloseHandler} className="base-popup__close-btn">
          <TextWithIcon iconName="cross-white" hideLabel />
        </BaseButton>
      </div>
      <div className="gt-popup__content">
        {!isChrome && !isIOS && (
          <Fragment>
            <h3 className="gt-popup__title">
              Use Google Chrome to translate the page
            </h3>
            <GTDownloadButton />
            <p className="gt-popup__text">
              After installing Google Chrome, follow the{" "}
              <a
                className="gt-popup__link gt-popup__link--bold"
                href="https://hr.alabuga.ru"
              >
                hr.alabuga.ru
              </a>{" "}
              and click on “Translate”
            </p>
          </Fragment>
        )}

        {isIOS && (
          <Fragment>
            <p className="gt-popup__text gt-popup__text--left">
              To use the translation function, you must open the page in Google
              Chrome.
            </p>
            <p className="gt-popup__text">
              <GTDownloadButton />
            </p>
            <p className="gt-popup__text gt-popup__text--left">
              If you opened a page in Google Chrome, follow the instructions.
            </p>
          </Fragment>
        )}

        {(isChrome || isIOS) && (
          <Fragment>
            {/* TODO Какой-то текст надо поставить */}
            <ol className="gt-popup__text gt-popup__text--left">
              <li>Right click anywhere on the screen</li>
              <li>Click "Translate"</li>
              <li>
                Click on <span className="gt-popup__menu-icon" />
              </li>
              <li>Choose "Another language"</li>
              <li>Choose your language</li>
              <li>Click translate</li>
            </ol>
            {isMobile && (
              <div className="gt-popup__video-wrapper">
                <video
                  controls
                  preload="metadata"
                  className="gt-popup__video"
                  src="/video/video-translate-mobile.mp4"
                />
              </div>
            )}
            {isDesktop && (
              <div className="gt-popup__video-wrapper">
                <video
                  controls
                  preload="metadata"
                  className="gt-popup__video"
                  src="/video/video-translate-desktop.mp4"
                />
              </div>
            )}
            <BaseButton
              large
              block
              primary
              onClick={onCloseHandler}
              className="gt-popup__button gt-popup__button--understand"
            >
              I understand
            </BaseButton>
          </Fragment>
        )}
      </div>
    </div>
  );
});
