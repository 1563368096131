import { RootState } from "../../store";
import { ERequestStatus, UserInfoInterface } from "../../types";
import { ProfileInfoStateInterface } from "./type";

export const selectProfileInfo = (
  state: RootState,
): ProfileInfoStateInterface => state.profileInfo;

export const selectProfileInfoItem = (
  state: RootState,
): UserInfoInterface | null => selectProfileInfo(state).profileInfo;

export const selectProfileInfoStatusGetProfile = (
  state: RootState,
): ERequestStatus => selectProfileInfo(state).statusGetProfile;

export const selectProfileInfoStatusUpdateProfile = (
  state: RootState,
): ERequestStatus => selectProfileInfo(state).statusUpdateProfile;

export const selectProfileInfoStatusRepeatVerifiedEmail = (
  state: RootState,
): ERequestStatus => selectProfileInfo(state).statusRepeatVerifiedEmail;
