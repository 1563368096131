import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PageWrapper } from "src/shared/components";

import "./policy-page.scss";

import { IPolicyMockItem } from "../../shared/models/mocks";

interface Props {}

export const PolicyPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const [policyMock, setPolicyMock] = useState<IPolicyMockItem[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    import("../../shared/mocks/policyMock").then((mock) => {
      setPolicyMock(mock.policyMock);
    });
  }, []);

  const renderItems = () => {
    return policyMock.map((item, index) => {
      return (
        <div key={item.title + index} className="policy">
          <h2 className="policy__title">{item.title}</h2>
          <ul className="policy__list">
            {item.items.map((it, idx) => {
              return (
                <li key={it + idx} className="policy__list-item">
                  {it}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  };

  return (
    <PageWrapper title={t("policy.title")} emptyPanel>
      {renderItems()}
    </PageWrapper>
  );
};
