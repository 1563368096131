import React, { FC, ReactElement, ReactNode, useEffect } from "react";

import dompurify from "dompurify";

import { useUnit } from "effector-react";

import { useDispatch, useSelector } from "react-redux";

import { KeycloakInstance } from "keycloak-js";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AssessmentStatus } from "src/generated/ws4";

import useClient from "src/shared/hooks/useClient";

import { BaseLoader, MainLayout } from "src/shared/components";

import { selectRoomName } from "src/shared/store/ducks/room";

import {
  requestSimulationListPlayer,
  $simulationListPlayer,
} from "src/entities/public/simulation-list-player";

import { keycloakModel } from "src/entities/public/keycloak";

import { useConnectNotificationToRoom } from "../entities/public/notifications";

import { appAccessModel } from "src/features/public/app-access";

import { useRoomSocket } from "../shared/api/public/sockets";

import "src/app/styles/index.scss";
import { Header } from "src/shared/components/new-header/header";
import { useMediaQuery } from "../shared/hooks/useMediaQuery";

import TagManager from "react-gtm-module";
import { Footer } from "../shared/components/footer/footer";
import { useUrlState } from "../shared/hooks/useUrlState";
import { setRelativeId } from "../entities/public/relatives/set-relative-id/set-relative-id";
import { fetchGetStudentForLink } from "../entities/public/relatives/get-student-for-link/get-student-for-link";

interface Props {
  keycloak: KeycloakInstance;
  children: ReactNode;
}

const App: FC<Props> = ({ keycloak, children }): ReactElement => {
  const dispatch = useDispatch();

  const storeKeycloak = useUnit(keycloakModel.$keycloak);

  const room = useSelector(selectRoomName);

  const profileInfo = useUnit(appAccessModel.$profile);

  const { items: simulationListPlayer } = useUnit($simulationListPlayer);

  const profileCompetence = useUnit(appAccessModel.$socialPlayerInfo);

  const [urlState] = useUrlState();

  const isAssessmentInProgress =
    room.assessmentStatus === AssessmentStatus.IN_PROGRESS;

  const isSocial = useUnit(appAccessModel.$hasSocial);

  const { isPlayerClientId } = useClient();

  const isAccess = useUnit(appAccessModel.$isAccess);

  const { isLandscape } = useMediaQuery();

  useEffect(() => {
    appAccessModel.getProfile();

    dompurify.addHook("uponSanitizeElement", (node, data) => {
      if (data.tagName === "iframe") {
        const src = node.getAttribute("src") || "";

        if (!src.includes("https://www.youtube.com/embed/")) {
          return node.parentNode?.removeChild(node);
        }
      }
    });
  }, []);

  useEffect(() => {
    keycloakModel.setKeycloak(keycloak);
  }, [dispatch, keycloak]);

  useEffect(() => {
    const isEmptyList: boolean = !simulationListPlayer.length;

    if (isAssessmentInProgress && isEmptyList) {
      requestSimulationListPlayer();
    }
  }, [isAssessmentInProgress, simulationListPlayer]);

  useRoomSocket(keycloak);
  useConnectNotificationToRoom();

  const isPlayerAccess: boolean = isPlayerClientId
    ? !!profileCompetence || !isSocial
    : true;

  const tagManagerArgs = {
    gtmId: "GTM-KXQWHB5",
  };

  if (process.env.REACT_APP_DOMAIN === "https://hr.alabuga.ru") {
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
    if (urlState.query.invite_link) {
      setRelativeId(urlState.query.invite_link);
      fetchGetStudentForLink({ linkId: urlState.query.invite_link });
    }
  }, [urlState.query.invite_link]);

  const render = () => {
    const hasAccessToApp: boolean =
      !!storeKeycloak?.authenticated &&
      isAccess !== null &&
      !!profileInfo &&
      isPlayerAccess;

    if (hasAccessToApp) {
      return (
        <DndProvider backend={HTML5Backend}>
          <Header />
          <MainLayout>{children}</MainLayout>
          <Footer isScrollable={isLandscape} />
        </DndProvider>
      );
    } else {
      return (
        <div className="app-preloader">
          <BaseLoader />
        </div>
      );
    }
  };

  return <div className="App">{render()}</div>;
};

export default App;
