import { ERequestStatus } from "../../types";
import { TSidebarInformationActions } from "./actionCreators";
import { ESidebarInformationActions } from "./actionTypes";
import { ISidebarInformationState } from "./type";

const initialState: ISidebarInformationState = {
  item: null,
  statusGet: ERequestStatus.NEVER,
  statusPost: ERequestStatus.NEVER,
};

export const sidebarInformationReducer = (
  state = initialState,
  action: TSidebarInformationActions
) => {
  switch (action.type) {
    case ESidebarInformationActions.CLEAN_SIDEBAR_INFORMATION:
      return {
        ...state,
        item: null,
        statusGet: ERequestStatus.NEVER,
        statusPost: ERequestStatus.NEVER,
      };
    case ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_GET:
      return {
        ...state,
        statusGet: ERequestStatus.LOADING,
      };
    case ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_POST:
      return {
        ...state,
        statusPost: ERequestStatus.LOADING,
      };
    case ESidebarInformationActions.SUCCESS_SIDEBAR_INFORMATION_GET:
      return {
        ...state,
        item: action.payload,
        statusGet: ERequestStatus.LOADED,
      };
    case ESidebarInformationActions.SUCCESS_SIDEBAR_INFORMATION_POST:
      return {
        ...state,
        statusPost: ERequestStatus.LOADED,
      };
    case ESidebarInformationActions.FAILURE_SIDEBAR_INFORMATION_GET:
      return {
        ...state,
        statusGet: ERequestStatus.ERROR,
      };
    case ESidebarInformationActions.FAILURE_SIDEBAR_INFORMATION_POST:
      return {
        ...state,
        statusPost: ERequestStatus.ERROR,
      };
    default:
      return state;
  }
};
