import { Fragment } from "react";
import { reflect } from "@effector/reflect";
import { useMediaQuery } from "src/shared/hooks/useMediaQuery";
import { t } from "src/shared/helpers";
import { Notification } from "src/shared/api/public/sockets/model/notifications";
import {
  NotificationCard,
  notificationsModel,
  SessionEventButton,
} from "src/entities/public/notifications";
import {
  $hiddenProgressAssessment,
  ProgressAssessment,
  removeAllNotifications,
  removeNotification,
  showProgressAssessment,
} from "src/features/player/hide-notifications/model";
import "./notification-cards-on-page.scss";
import { markAsRead } from "../../../../public/notification";
import { MsgType } from "../../../../../generated/notification";

const { $notificationsPopup } = notificationsModel;

type Props = {
  messages: Notification[];
  hiddenProgressAssessment: ProgressAssessment | null;
};

const View = ({ messages, hiddenProgressAssessment }: Props) => {
  const { isMobile } = useMediaQuery();

  const hasAssessmentRevoke = messages.some(
    (message) => message.type === MsgType.AssessmentRevoke,
  );

  const filteredMessages = messages.filter(
    (message) =>
      message.isNew &&
      message.type !== MsgType.FineNotification &&
      message.type !== MsgType.StatusCompany &&
      (!hasAssessmentRevoke || message.type !== MsgType.AssessmentInvite),
  );

  const items = isMobile
    ? filteredMessages.slice(-1)
    : filteredMessages.slice(-4);

  const isHideAllButtonVisible = isMobile ? false : items.length > 1;

  const handleClose = (message?: Notification) =>
    message && removeNotification(message?.mid);

  const handleCloseAll = () => {
    markAsRead();
    removeAllNotifications();
  };

  return (
    <div className="tile-on-page">
      {isHideAllButtonVisible && (
        <button className="tile-on-page__hide-button" onClick={handleCloseAll}>
          {t("notification.hide.button")}
        </button>
      )}

      {hiddenProgressAssessment && (
        <SessionEventButton onClick={() => showProgressAssessment()} />
      )}

      {items?.map((message) => (
        <Fragment key={message.mid}>
          <NotificationCard message={message} onClose={handleClose} />
        </Fragment>
      ))}
    </div>
  );
};

export const NotificationCardsOnPage = reflect<Props>({
  view: View,
  bind: {
    messages: $notificationsPopup,
    hiddenProgressAssessment: $hiddenProgressAssessment,
  },
});
