import { FC, ReactElement } from "react";

import { useTranslation } from "react-i18next";

import styles from "./session-table-head.module.scss";

interface Props {
  isSolo?: boolean;
}

export const SessionTableHead: FC<Props> = ({ isSolo }): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={styles.head}>
      <div className={styles.item}>{t("table.header.member.name")}</div>
      {isSolo && <div className={styles.item}>{t("table.header.role")}</div>}
    </div>
  );
};
