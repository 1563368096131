import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useUnit } from "effector-react";
import { useUrlState } from "src/shared/hooks/useUrlState";
import { BaseButton, ConfirmPopup } from "../../../shared/components";
import {
  $assessmentsListAdmin,
  setAssessmentsListAdminIsReadmore,
  cleanAssessmentsListAdmin,
  requestAssessmentsListAdmin,
} from "src/entities/public/assessments-list-admin";
import { ERequestStatus } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import { AssessmentStatus, AssessmentType } from "../../../generated/game";
import {
  requestForceTerminateAssessments,
  $forceTerminateAssessments,
} from "src/entities/public/force-terminate-assessments";
import { TDataGridDataParams } from "../../../shared/models/dataGrid";
import { CreatedAssessmentsTable } from "../../../shared/components/data-grids/assessments-control/created-assessments-table";
import { getDateForFilter } from "../../../shared/helpers/getDateForFilter";
import isEqual from "lodash/isEqual";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import "./created-assessments-page.scss";

interface Props {}

const { openPopup } = popupModel;

export const CreatedAssessmentsPage: FC<Props> = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    items: assessments,
    pagination,
    status: assessmentsStatus,
  } = useUnit($assessmentsListAdmin);

  const { status: forceTerminateAssessmentsStatus } = useUnit(
    $forceTerminateAssessments,
  );

  const [urlState, setUrlState] = useUrlState();
  const [checkList, setCheckList] = useState<string[]>([]);

  const isAssessmentsLoading: boolean =
    assessmentsStatus === ERequestStatus.LOADING;

  const isForceTerminateAssessmentsLoading: boolean =
    forceTerminateAssessmentsStatus === ERequestStatus.LOADING;

  const assessmentTypes = useMemo<AssessmentType[]>(
    () => Object.values(AssessmentType),
    [],
  );

  useEffect(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      title,
      gameTitle,
      curPlayersFrom,
      curPlayersTo,
      createdFrom,
      createdTo,
      startedFrom,
      startedTo,
    } = urlState.query;

    let assessmentTypeValues: AssessmentType[] = urlState.query.assessmentType
      ? (urlState.query.assessmentType.split(",") as AssessmentType[])
      : [];

    assessmentTypeValues = assessmentTypeValues.filter((assessmentTypeValue) =>
      assessmentTypes.includes(assessmentTypeValue),
    );

    requestAssessmentsListAdmin({
      params: {
        localeQuery: "ru",
        assessmentStatusQuery: [AssessmentStatus.Lobby],
        pageSize: pageSize ? Number(pageSize) : 50,
        pageNum: pageNum ? Number(pageNum) : 1,
        orderByQuery: orderBy,
        titleQuery: title ? [title] : undefined,
        gameTitleQuery: gameTitle ? [gameTitle] : undefined,
        assessmentTypeQuery: !!assessmentTypeValues.length
          ? assessmentTypeValues
          : undefined,
        curPlayersFromQuery: curPlayersFrom
          ? Number(curPlayersFrom)
          : undefined,
        curPlayersToQuery: curPlayersTo ? Number(curPlayersTo) : undefined,
        createdFromQuery: getDateForFilter(createdFrom),
        createdToQuery: getDateForFilter(createdTo),
        startedFromQuery: getDateForFilter(startedFrom),
        startedToQuery: getDateForFilter(startedTo),
      },
    });
  }, [urlState.query, dispatch, assessmentTypes]);

  useEffect(() => {
    return () => {
      cleanAssessmentsListAdmin();
    };
  }, [dispatch]);

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setAssessmentsListAdminIsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };
  const clearSelectedAssessments = () => {
    setCheckList((prevState) => {
      prevState.length = 0;
      return [];
    });
  };

  const onConfirmHandler = () => {
    requestForceTerminateAssessments({
      body: { ids: checkList },
      callback: () => {
        requestAssessmentsListAdmin({
          params: {
            localeQuery: "ru",
            assessmentStatusQuery: [AssessmentStatus.Lobby],
            pageSize: 50,
          },
        });
        clearSelectedAssessments();
      },
    });
  };

  const openConfirmPopup = () => {
    openPopup({ name: EPopupName.FORCE_TERMINATE });
  };

  const getSelectedRow = useCallback(
    (idList: string[]) => {
      setCheckList(idList);
    },
    [setCheckList],
  );

  return (
    <PageWrapper
      title={t("assessmentsControl.title")}
      isLoadingPanel={isAssessmentsLoading}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("assessmentsControl.controlPanel.label.assessments")}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={checkList.length}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <BaseButton
          small
          primary
          disabled={!checkList.length}
          onClick={openConfirmPopup}
        >
          {t("usersControl.controlPanel.btn.forceTerminate")}
        </BaseButton>
      }
    >
      <div className="created-assessments-page">
        <div className="created-assessments-data-grid-wrapper">
          <CreatedAssessmentsTable
            assessmentItems={assessments}
            queryParams={urlState.query}
            onSelectRows={getSelectedRow}
            selectedRowsIds={checkList}
            loading={isAssessmentsLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            pagination={pagination}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum, true)}
          />
        </div>
      </div>

      <ConfirmPopup
        popupName={EPopupName.FORCE_TERMINATE}
        title={t("usersControl.popup.forceTerminateAssessments.title")}
        description={t(
          "usersControl.popup.forceTerminateAssessments.description",
        )}
        confirmBtnLabel={t(
          "usersControl.popup.forceTerminateAssessments.confirmBtn",
        )}
        onConfirm={onConfirmHandler}
        isConfirmBtnDisabled={isForceTerminateAssessmentsLoading}
      />
    </PageWrapper>
  );
};
