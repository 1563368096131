import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { BaseButton, DataGrid } from "src/shared/components/index";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import {
  ConfirmationRelativeResponse,
  RelativeTable,
  StatusCompanyEnum,
} from "../../../../generated/social";
import {
  $relativeStatus,
  fetchPostConfirmationRelative,
} from "../../../../entities/hr/moderation/model/relatives/relatives-confirmation";
import { useUnit } from "effector-react";
import { fetchPostCancelConfirmationRelative } from "../../../../entities/hr/moderation/model/relatives/relative-cancel-confirmation";

interface Props {
  items: RelativeTable[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const RelativeTableDataGrid: FC<Props> = ({
  loading,
  items,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const relativeStatus = useUnit($relativeStatus);

  const resolvedUsers: ConfirmationRelativeResponse[] = useMemo(() => [], []);

  useEffect(() => {
    if (relativeStatus) {
      const index = resolvedUsers.findIndex(
        (user) =>
          user.relative_id === relativeStatus.relative_id &&
          user.student_id === relativeStatus.student_id,
      );

      if (index !== -1) {
        resolvedUsers[index] = relativeStatus;
      } else {
        resolvedUsers.push(relativeStatus);
      }
    }
  }, [relativeStatus, resolvedUsers]);

  const onConfirm = (relativeId: string, studentId: string) => {
    fetchPostConfirmationRelative({
      confirmationRelatives: {
        relative_id: relativeId,
        student_id: studentId,
        confirmation: true,
      },
    });
  };

  const onReject = (relativeId: string, studentId: string) => {
    fetchPostConfirmationRelative({
      confirmationRelatives: {
        relative_id: relativeId,
        student_id: studentId,
        confirmation: false,
      },
    });
  };

  const cancelAction = (relativeId: string, studentId: string) => {
    fetchPostCancelConfirmationRelative({
      cancelConfirmationRelative: {
        relative_id: relativeId,
        student_id: studentId,
      },
    });
  };

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullNameRelative",
        showed: true,
        titleUppercase: true,
        title: t("table.header.name"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.name"),
          activeGroupKey: getActiveGroup(
            ["firstNameRelative", "lastNameRelative"],
            { all: ["firstNameRelative", "lastNameRelative"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstNameRelative",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstNameRelative || "",
                },
                {
                  key: "lastNameRelative",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastNameRelative || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstName",
                  "-firstName",
                  "lastName",
                  "-lastName",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstName",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastName",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.phoneRelative"),
        type: EDataGridColumnType.PHONE,
        key: "phoneRelative",
        showed: true,
        controlPanel: {
          title: t("table.header.phoneRelative"),
          activeGroupKey: getActiveGroup(
            ["phoneRelative"],
            { all: ["phoneRelative"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "phoneRelative",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.phoneRelative || "",
                },
              ],
            },
          ],
        },
      },
      {
        key: "fullNameStudent",
        showed: true,
        titleUppercase: true,
        title: t("table.ratings.statusCompany.student"),
        type: EDataGridColumnType.STRING,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.ratings.statusCompany.student"),
          activeGroupKey: getActiveGroup(
            ["firstNameStudent", "lastNameStudent"],
            { all: ["firstNameStudent", "lastNameStudent"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstNameStudent",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstNameStudent || "",
                },
                {
                  key: "lastNameStudent",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastNameStudent || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstNameStudent",
                  "-firstNameStudent",
                  "lastNameStudent",
                  "-lastNameStudent",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstNameStudent",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstNameStudent",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastNameStudent",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastNameStudent",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.phoneStudent"),
        type: EDataGridColumnType.PHONE,
        key: "phoneStudent",
        showed: true,
        controlPanel: {
          title: t("table.header.phoneStudent"),
          activeGroupKey: getActiveGroup(
            ["phoneStudent"],
            { all: ["phoneStudent"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "phoneStudent",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.phoneStudent || "",
                },
              ],
            },
          ],
        },
      },
      {
        key: "coordination",
        showed: true,
        titleUppercase: true,
        title: t("common.coordination"),
        type: EDataGridColumnType.STRING,
      },
    ];

    return newColumns;
  }, [queryParams, t]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const fullNameStudent = splitStrings([
        item.firstNameStudent,
        item.lastNameStudent,
      ]);
      const fullNameRelative = splitStrings([
        item.firstNameRelative,
        item.lastNameRelative,
      ]);

      let confirmationStatus: ReactElement | null = null;

      const currentUserStatus = resolvedUsers.find(
        (user) =>
          user.relative_id === item.relative_id &&
          user.student_id === item.student_id,
      );

      if (currentUserStatus) {
        confirmationStatus =
          currentUserStatus.status_company_relative ===
            StatusCompanyEnum.Relative ||
          currentUserStatus.status_company_relative ===
            StatusCompanyEnum.CandidateEmployee ? (
            <span
              onClick={() => {
                cancelAction(item.relative_id, item.student_id);
              }}
              className="moderation-page__coordination__confirmed"
            >
              {t("common.confirmed")}
            </span>
          ) : (
            <span
              onClick={() => {
                cancelAction(item.relative_id, item.student_id);
              }}
              className="moderation-page__coordination__rejected"
            >
              {t("common.rejected")}
            </span>
          );
      }

      const coordination = (
        <div className="moderation-page__coordination-buttons">
          <BaseButton
            onClick={() => onConfirm(item.relative_id, item.student_id)}
            xSmall
            xSmallRounded
            primary
          >
            {t("common.confirm")}
          </BaseButton>
          <BaseButton
            onClick={() => onReject(item.relative_id, item.student_id)}
            xSmall
            xSmallRounded
            secondary
          >
            {t("common.reject")}
          </BaseButton>
        </div>
      );

      if (
        currentUserStatus?.status_company_relative ===
        StatusCompanyEnum.CandidateRelatives
      ) {
        confirmationStatus = coordination;
      }

      return {
        id: item.student_id + item.relative_id,
        selected: false,
        fullNameStudent,
        avatarProps: {
          alt: fullNameRelative,
          fullName: fullNameRelative,
          customPath: getAvatarUrl(item.id),
          userId: item.id,
        },
        fullNameRelative,
        phoneStudent: item.phoneStudent,
        phoneRelative: item.phoneRelative,
        coordination: confirmationStatus ? confirmationStatus : coordination,
      };
    });

    setRows(rowsItems);
  }, [items, i18n.language, t, relativeStatus, resolvedUsers]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
