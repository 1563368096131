import {
  GetCsvAssessmentHistoryParams,
  GetCsvHistoryParams,
  GetXlsxAssessmentHistoryParams,
  GetXlsxHistoryParams,
} from "./query";
import { statisticService } from "../../../base/api-instances";
import {
  ExportApiExportAssessmentsStoryToCsvRequest,
  ExportApiExportAssessmentsStoryToXlsxRequest,
} from "../../../../../generated/statistic";

export const exportHistoryCSV = (params: GetCsvHistoryParams) => {
  return statisticService
    .makeExportStatisticBlobRequest()
    .exportUserStoryToCsv(params);
};

export const exportHistoryXLSX = (params: GetXlsxHistoryParams) => {
  return statisticService
    .makeExportStatisticBlobRequest()
    .exportUserStoryToXlsx(params);
};

export const exportAssessmentHistoryXLSX = (
  params: GetXlsxAssessmentHistoryParams,
) => {
  return statisticService
    .makeExportStatisticBlobRequest()
    .exportAssessmentStoryToXlsx(params);
};

export const exportAssessmentHistoryCSV = (
  params: GetCsvAssessmentHistoryParams,
) => {
  return statisticService
    .makeExportStatisticBlobRequest()
    .exportAssessmentStoryToCsv(params);
};

export const exportAssessmentsStoryXLSX = (
  params: ExportApiExportAssessmentsStoryToXlsxRequest,
) => {
  return statisticService
    .makeExportStatisticBlobRequest()
    .exportAssessmentsStoryToXlsx(params);
};

export const exportAssessmentsStoryCSV = (
  params: ExportApiExportAssessmentsStoryToCsvRequest,
) => {
  return statisticService
    .makeExportStatisticBlobRequest()
    .exportAssessmentsStoryToCsv(params);
};
