import { FC, FormEvent, memo } from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { useUrlState } from "src/shared/hooks/useUrlState";

import { BaseBox, BaseButton, BaseInputRadio } from "src/shared/components";

import { IBaseInputChange } from "src/shared/models/base-input";

import { SelectedPlayersModel } from "src/pages/other/select-players-page";

import "./recipients-form.scss";

export enum ERadioType {
  SEND_TO_ALL = "send-to-all",
  SELECTIVELY = "selectively",
}

interface Props {
  name: string;
}

export const radioVariables = Object.values(ERadioType);

export const RecipientsForm: FC<Props> = memo(({ name }) => {
  const { t } = useTranslation();

  const { $selectedIdsLength, $activeRadioValue, changeIdRadioValues } =
    SelectedPlayersModel;

  const selectedIdLength = useUnit($selectedIdsLength);

  const activeValue = useUnit($activeRadioValue);

  const disabled = activeValue === ERadioType.SEND_TO_ALL;

  const [urlState] = useUrlState();

  const onClickHandler = (e: FormEvent) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  const onChangeRadio = (e: IBaseInputChange) => {
    changeIdRadioValues(e.value);
  };

  const isEmailFullParam =
    urlState.query.type === "e-mail" ? "&emailFull=true" : "";

  const filteredRadioVariables =
    urlState.query.type === "sms"
      ? radioVariables.filter((type) => type !== ERadioType.SEND_TO_ALL)
      : radioVariables;

  return (
    <BaseBox>
      <h2>{t("recipients.form.recipients")}</h2>
      <div className="recipients-form__radio">
        {filteredRadioVariables.map((type) => (
          <BaseInputRadio
            key={type}
            value={type}
            activeValue={activeValue}
            name={name}
            label={t(`recipients.form.type.${type}`)}
            onChange={onChangeRadio}
          />
        ))}
      </div>
      <div className="recipients-form__base-box-pick-item">
        <span className="recipients-form__counter-selected-users">
          {activeValue === ERadioType.SELECTIVELY
            ? `${t("recipients.form.recipientsList")} : ${selectedIdLength}`
            : ""}
        </span>
        <BaseButton
          to={`/mailing-lists/select-recipients?type=${urlState.query.type}${isEmailFullParam}`}
          onClick={onClickHandler}
          small
          disabled={disabled}
        >
          {t("recipients.form.select")}
        </BaseButton>
      </div>
    </BaseBox>
  );
});
