import classNames from "classnames";
import { ReactElement, useEffect, useState } from "react";
import { useUnit } from "effector-react";
import { $snackbar } from "src/features/public/snackbar";
import { snackbarClose } from "src/features/public/snackbar";
import "./base-snackbar.scss";

export const BaseSnackbar = (): ReactElement => {
  const snackbar = useUnit($snackbar);

  const defaultTime = 3;
  const time: number = snackbar.time || defaultTime;

  const [counter, setCounter] = useState<number>(time);

  useEffect(() => {
    if (snackbar.visible) {
      setCounter(time);
    }
  }, [snackbar.visible, time]);

  useEffect(() => {
    if (snackbar.visible && counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      snackbarClose();
      setCounter(time);
    }
  }, [counter, snackbar.time, snackbar.visible]);

  return (
    <div
      className={classNames("base-snackbar", {
        "base-snackbar--visible": snackbar.visible,
        "base-snackbar--vertical-centered": snackbar.verticalCentered,
      })}
      onClick={() => snackbarClose()}
    >
      <div
        className={classNames(
          "base-snackbar__text",
          `base-snackbar__text--with-icon_${snackbar.type}`,
          {
            "base-snackbar__text--with-icon": snackbar.type,
          },
        )}
      >
        {snackbar.text}
      </div>
    </div>
  );
};
