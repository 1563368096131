import { createEvent, createStore, sample } from "effector";
import { radioVariables } from "src/shared/components/recipients-form/recipients-form";

// Events

export const updateSelectedIds = createEvent<string[]>();

export const refreshSelectedIds = createEvent();

export const changeIdRadioValues = createEvent<string>();

// Stores

export const $selectedIds = createStore<string[]>([]);

export const $copySelectedIds = createStore<string[]>([]);

export const $activeRadioValue = createStore<string>(radioVariables[0]);

export const $selectedIdsLength = $selectedIds.map((ids) => ids.length);

// Logic

sample({
  clock: updateSelectedIds,
  target: $copySelectedIds,
});

sample({
  clock: refreshSelectedIds,
  source: $copySelectedIds,
  target: $selectedIds,
});

sample({
  clock: changeIdRadioValues,
  target: $activeRadioValue,
});
