import axios from "axios";

import {
  AdminDictionariesCountryApi,
  AssessmentHistoryApi,
  ChangePlayerHistoryApi,
  ComplaintsApi,
  Configuration,
  DictionariesApi,
  DownloadHistoryApi,
  FaqApi,
  FineArchiveApi,
  FinesApi,
  KeycloakApi,
  PermissionsApi,
  PlayerFineHistoryApi,
  PlayerFineStatusApi,
  PlayersApi,
  RatingsApi,
  RelativeApi,
  UsersApi,
} from "src/generated/social";

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_SOCIAL_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_SOCIAL_URL;

    config.headers = {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const makeAdminRequest = () => {
  return new AdminDictionariesCountryApi(new Configuration(), "", apiInstance);
};

const makeFinesRequest = () => {
  return new FinesApi(new Configuration(), "", apiInstance);
};

const makeArchiveFinesRequest = () => {
  return new FineArchiveApi(new Configuration(), "", apiInstance);
};

const makePlayerFineStatusRequest = () => {
  return new PlayerFineStatusApi(new Configuration(), "", apiInstance);
};

const makePublicRequest = () => {
  return new DictionariesApi(new Configuration(), "", apiInstance);
};

const makeRatingsRequest = () => {
  return new RatingsApi(new Configuration(), "", apiInstance);
};

const makeUserSettingsRequest = () => {
  return new UsersApi(new Configuration(), "", apiInstance);
};

const makePermissionRequest = () => {
  return new PermissionsApi(new Configuration(), "", apiInstance);
};

const makePlayerRequest = () => {
  return new PlayersApi(new Configuration(), "", apiInstance);
};

const makeFineHistoryRequest = () => {
  return new PlayerFineHistoryApi(new Configuration(), "", apiInstance);
};

const makeDownloadHistoryRequest = () => {
  return new DownloadHistoryApi(new Configuration(), "", apiInstance);
};

const makeDownloadAssessmentHistory = () => {
  return new AssessmentHistoryApi(new Configuration(), "", apiInstance);
};

const makeKeycloakRequest = () => {
  return new KeycloakApi(new Configuration(), "", apiInstance);
};

const makeChangePlayerRatingsRequest = () => {
  return new ChangePlayerHistoryApi(new Configuration(), "", apiInstance);
};

const makeFaqRequest = () => {
  return new FaqApi(new Configuration(), "", apiInstance);
};

const makeRelativeRequest = () => {
  return new RelativeApi(new Configuration(), "", apiInstance);
};

const makeComplaintsRequest = () => {
  return new ComplaintsApi(new Configuration(), "", apiInstance);
};

export const socialService = {
  apiInstance,
  makeAdminRequest,
  makePublicRequest,
  makeRatingsRequest,
  makePlayerRequest,
  makeUserSettingsRequest,
  makePermissionRequest,
  makeFinesRequest,
  makeArchiveFinesRequest,
  makePlayerFineStatusRequest,
  makeFineHistoryRequest,
  makeDownloadHistoryRequest,
  makeDownloadAssessmentHistory,
  makeKeycloakRequest,
  makeChangePlayerRatingsRequest,
  makeFaqRequest,
  makeRelativeRequest,
  makeComplaintsRequest,
};
