import { gameService } from "src/shared/api/base/api-instances";
import { AssessmentValidateApiGetGameAssessmentValidateRequest } from "src/generated/game";

export const getValidateAssessmentHr = (
  params: AssessmentValidateApiGetGameAssessmentValidateRequest,
) => {
  return gameService
    .makeValidateAssessmentRequest()
    .getGameAssessmentValidate(params)
    .then((data) => data)
    .catch((error) => error);
};
