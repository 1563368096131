import { some } from "patronum";

import { $isLoading } from "./countries";
import { $isLoadingUpdateCountry } from "./update-country";

// Stores

export const $isSomeLoading = some({
  predicate: (loading) => loading,
  stores: [$isLoading, $isLoadingUpdateCountry],
});
