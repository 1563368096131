export const urls = {
  game: process.env.REACT_APP_GAME_SOCKET_URL!,
  notifications: process.env.REACT_APP_NOTIFICATION_SOCKET_URL!,
};

export const paths = {
  game: {
    v3: "/ws/v3",
    v4: "/ws/v4",
  },
  notifications: {
    v1: "/ws/v1",
  },
};

export const namespaces = {
  game: {
    lobby: "lobby",
    act: "act",
    room: {
      player: "roomPlayer",
      hr: "roomHr",
    },
    update: "nonLobbyPlayer",
  },
};
