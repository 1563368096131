/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosInstance, AxiosPromise } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface FileDeleteItem
 */
export interface FileDeleteItem {
  /**
   *
   * @type {string}
   * @memberof FileDeleteItem
   */
  status: string;
}
/**
 *
 * @export
 * @interface FileUploadItem
 */
export interface FileUploadItem {
  /**
   *
   * @type {string}
   * @memberof FileUploadItem
   */
  filename: string;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface InfoItem
 */
export interface InfoItem {
  /**
   *
   * @type {Array<string>}
   * @memberof InfoItem
   */
  accessRole: Array<string>;
  /**
   *
   * @type {number}
   * @memberof InfoItem
   */
  maxSize: number;
  /**
   *
   * @type {Array<string>}
   * @memberof InfoItem
   */
  contentType: Array<string>;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationError
   */
  loc: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}

/**
 * AvatarApi - axios parameter creator
 * @export
 */
export const AvatarApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete avatar
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAvatarAvatarsIdDelete: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteAvatarAvatarsIdDelete", "id", id);
      const localVarPath = `/avatars/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Info about loading avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoAvatarsInfoGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/avatars/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload avatar
     * @param {string} id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAvatarAvatarsIdPost: async (
      id: string,
      file: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("uploadAvatarAvatarsIdPost", "id", id);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadAvatarAvatarsIdPost", "file", file);
      const localVarPath = `/avatars/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AvatarApi - functional programming interface
 * @export
 */
export const AvatarApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AvatarApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete avatar
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAvatarAvatarsIdDelete(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDeleteItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAvatarAvatarsIdDelete(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Info about loading avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async infoAvatarsInfoGet(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.infoAvatarsInfoGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload avatar
     * @param {string} id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadAvatarAvatarsIdPost(
      id: string,
      file: any,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadAvatarAvatarsIdPost(
          id,
          file,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AvatarApi - factory interface
 * @export
 */
export const AvatarApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AvatarApiFp(configuration);
  return {
    /**
     *
     * @summary Delete avatar
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAvatarAvatarsIdDelete(
      id: string,
      options?: any,
    ): AxiosPromise<FileDeleteItem> {
      return localVarFp
        .deleteAvatarAvatarsIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Info about loading avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoAvatarsInfoGet(options?: any): AxiosPromise<InfoItem> {
      return localVarFp
        .infoAvatarsInfoGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload avatar
     * @param {string} id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAvatarAvatarsIdPost(
      id: string,
      file: any,
      options?: any,
    ): AxiosPromise<FileUploadItem> {
      return localVarFp
        .uploadAvatarAvatarsIdPost(id, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteAvatarAvatarsIdDelete operation in AvatarApi.
 * @export
 * @interface AvatarApiDeleteAvatarAvatarsIdDeleteRequest
 */
export interface AvatarApiDeleteAvatarAvatarsIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof AvatarApiDeleteAvatarAvatarsIdDelete
   */
  readonly id: string;
}

/**
 * Request parameters for uploadAvatarAvatarsIdPost operation in AvatarApi.
 * @export
 * @interface AvatarApiUploadAvatarAvatarsIdPostRequest
 */
export interface AvatarApiUploadAvatarAvatarsIdPostRequest {
  /**
   *
   * @type {string}
   * @memberof AvatarApiUploadAvatarAvatarsIdPost
   */
  readonly id: string;

  /**
   *
   * @type {any}
   * @memberof AvatarApiUploadAvatarAvatarsIdPost
   */
  readonly file: any;
}

/**
 * AvatarApi - object-oriented interface
 * @export
 * @class AvatarApi
 * @extends {BaseAPI}
 */
export class AvatarApi extends BaseAPI {
  /**
   *
   * @summary Delete avatar
   * @param {AvatarApiDeleteAvatarAvatarsIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarApi
   */
  public deleteAvatarAvatarsIdDelete(
    requestParameters: AvatarApiDeleteAvatarAvatarsIdDeleteRequest,
    options?: any,
  ) {
    return AvatarApiFp(this.configuration)
      .deleteAvatarAvatarsIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Info about loading avatar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarApi
   */
  public infoAvatarsInfoGet(options?: any) {
    return AvatarApiFp(this.configuration)
      .infoAvatarsInfoGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload avatar
   * @param {AvatarApiUploadAvatarAvatarsIdPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarApi
   */
  public uploadAvatarAvatarsIdPost(
    requestParameters: AvatarApiUploadAvatarAvatarsIdPostRequest,
    options?: any,
  ) {
    return AvatarApiFp(this.configuration)
      .uploadAvatarAvatarsIdPost(
        requestParameters.id,
        requestParameters.file,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContactContactsIdDelete: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteContactContactsIdDelete", "id", id);
      const localVarPath = `/contacts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Info about loading contact
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoContactsInfoGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/contacts/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload contact
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadContactContactsPost: async (
      file: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadContactContactsPost", "file", file);
      const localVarPath = `/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteContactContactsIdDelete(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDeleteItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteContactContactsIdDelete(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Info about loading contact
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async infoContactsInfoGet(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.infoContactsInfoGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload contact
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadContactContactsPost(
      file: any,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadContactContactsPost(
          file,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContactApiFp(configuration);
  return {
    /**
     *
     * @summary Delete contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContactContactsIdDelete(
      id: string,
      options?: any,
    ): AxiosPromise<FileDeleteItem> {
      return localVarFp
        .deleteContactContactsIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Info about loading contact
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoContactsInfoGet(options?: any): AxiosPromise<InfoItem> {
      return localVarFp
        .infoContactsInfoGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload contact
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadContactContactsPost(
      file: any,
      options?: any,
    ): AxiosPromise<FileUploadItem> {
      return localVarFp
        .uploadContactContactsPost(file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteContactContactsIdDelete operation in ContactApi.
 * @export
 * @interface ContactApiDeleteContactContactsIdDeleteRequest
 */
export interface ContactApiDeleteContactContactsIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ContactApiDeleteContactContactsIdDelete
   */
  readonly id: string;
}

/**
 * Request parameters for uploadContactContactsPost operation in ContactApi.
 * @export
 * @interface ContactApiUploadContactContactsPostRequest
 */
export interface ContactApiUploadContactContactsPostRequest {
  /**
   *
   * @type {any}
   * @memberof ContactApiUploadContactContactsPost
   */
  readonly file: any;
}

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
  /**
   *
   * @summary Delete contact
   * @param {ContactApiDeleteContactContactsIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactApi
   */
  public deleteContactContactsIdDelete(
    requestParameters: ContactApiDeleteContactContactsIdDeleteRequest,
    options?: any,
  ) {
    return ContactApiFp(this.configuration)
      .deleteContactContactsIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Info about loading contact
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactApi
   */
  public infoContactsInfoGet(options?: any) {
    return ContactApiFp(this.configuration)
      .infoContactsInfoGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload contact
   * @param {ContactApiUploadContactContactsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactApi
   */
  public uploadContactContactsPost(
    requestParameters: ContactApiUploadContactContactsPostRequest,
    options?: any,
  ) {
    return ContactApiFp(this.configuration)
      .uploadContactContactsPost(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete image
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImgImagesIdDelete: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteImgImagesIdDelete", "id", id);
      const localVarPath = `/images/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Info about loading images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoImagesInfoGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/images/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload image
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImgImagesPost: async (
      file: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadImgImagesPost", "file", file);
      const localVarPath = `/images`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete image
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteImgImagesIdDelete(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDeleteItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteImgImagesIdDelete(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Info about loading images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async infoImagesInfoGet(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.infoImagesInfoGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload image
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadImgImagesPost(
      file: any,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadImgImagesPost(file, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ImagesApiFp(configuration);
  return {
    /**
     *
     * @summary Delete image
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImgImagesIdDelete(
      id: string,
      options?: any,
    ): AxiosPromise<FileDeleteItem> {
      return localVarFp
        .deleteImgImagesIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Info about loading images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoImagesInfoGet(options?: any): AxiosPromise<InfoItem> {
      return localVarFp
        .infoImagesInfoGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload image
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImgImagesPost(
      file: any,
      options?: any,
    ): AxiosPromise<FileUploadItem> {
      return localVarFp
        .uploadImgImagesPost(file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteImgImagesIdDelete operation in ImagesApi.
 * @export
 * @interface ImagesApiDeleteImgImagesIdDeleteRequest
 */
export interface ImagesApiDeleteImgImagesIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ImagesApiDeleteImgImagesIdDelete
   */
  readonly id: string;
}

/**
 * Request parameters for uploadImgImagesPost operation in ImagesApi.
 * @export
 * @interface ImagesApiUploadImgImagesPostRequest
 */
export interface ImagesApiUploadImgImagesPostRequest {
  /**
   *
   * @type {any}
   * @memberof ImagesApiUploadImgImagesPost
   */
  readonly file: any;
}

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
  /**
   *
   * @summary Delete image
   * @param {ImagesApiDeleteImgImagesIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApi
   */
  public deleteImgImagesIdDelete(
    requestParameters: ImagesApiDeleteImgImagesIdDeleteRequest,
    options?: any,
  ) {
    return ImagesApiFp(this.configuration)
      .deleteImgImagesIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Info about loading images
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApi
   */
  public infoImagesInfoGet(options?: any) {
    return ImagesApiFp(this.configuration)
      .infoImagesInfoGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload image
   * @param {ImagesApiUploadImgImagesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesApi
   */
  public uploadImgImagesPost(
    requestParameters: ImagesApiUploadImgImagesPostRequest,
    options?: any,
  ) {
    return ImagesApiFp(this.configuration)
      .uploadImgImagesPost(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ResumeApi - axios parameter creator
 * @export
 */
export const ResumeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteResumeResumesIdDelete: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteResumeResumesIdDelete", "id", id);
      const localVarPath = `/resumes/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get preview resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreviewResumeResumesIdPreviewGet: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getPreviewResumeResumesIdPreviewGet", "id", id);
      const localVarPath = `/resumes/{id}/preview`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResumeResumesIdGet: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getResumeResumesIdGet", "id", id);
      const localVarPath = `/resumes/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Info about loading resume
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoResumesInfoGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/resumes/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload resume
     * @param {string} id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadResumeResumesIdPost: async (
      id: string,
      file: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("uploadResumeResumesIdPost", "id", id);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadResumeResumesIdPost", "file", file);
      const localVarPath = `/resumes/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResumeApi - functional programming interface
 * @export
 */
export const ResumeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ResumeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteResumeResumesIdDelete(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDeleteItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteResumeResumesIdDelete(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get preview resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPreviewResumeResumesIdPreviewGet(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPreviewResumeResumesIdPreviewGet(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResumeResumesIdGet(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getResumeResumesIdGet(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Info about loading resume
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async infoResumesInfoGet(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.infoResumesInfoGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload resume
     * @param {string} id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadResumeResumesIdPost(
      id: string,
      file: any,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadResumeResumesIdPost(
          id,
          file,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ResumeApi - factory interface
 * @export
 */
export const ResumeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ResumeApiFp(configuration);
  return {
    /**
     *
     * @summary Delete resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteResumeResumesIdDelete(
      id: string,
      options?: any,
    ): AxiosPromise<FileDeleteItem> {
      return localVarFp
        .deleteResumeResumesIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get preview resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreviewResumeResumesIdPreviewGet(
      id: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .getPreviewResumeResumesIdPreviewGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get resume
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResumeResumesIdGet(id: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .getResumeResumesIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Info about loading resume
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    infoResumesInfoGet(options?: any): AxiosPromise<InfoItem> {
      return localVarFp
        .infoResumesInfoGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload resume
     * @param {string} id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadResumeResumesIdPost(
      id: string,
      file: any,
      options?: any,
    ): AxiosPromise<FileUploadItem> {
      return localVarFp
        .uploadResumeResumesIdPost(id, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteResumeResumesIdDelete operation in ResumeApi.
 * @export
 * @interface ResumeApiDeleteResumeResumesIdDeleteRequest
 */
export interface ResumeApiDeleteResumeResumesIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ResumeApiDeleteResumeResumesIdDelete
   */
  readonly id: string;
}

/**
 * Request parameters for getPreviewResumeResumesIdPreviewGet operation in ResumeApi.
 * @export
 * @interface ResumeApiGetPreviewResumeResumesIdPreviewGetRequest
 */
export interface ResumeApiGetPreviewResumeResumesIdPreviewGetRequest {
  /**
   *
   * @type {string}
   * @memberof ResumeApiGetPreviewResumeResumesIdPreviewGet
   */
  readonly id: string;
}

/**
 * Request parameters for getResumeResumesIdGet operation in ResumeApi.
 * @export
 * @interface ResumeApiGetResumeResumesIdGetRequest
 */
export interface ResumeApiGetResumeResumesIdGetRequest {
  /**
   *
   * @type {string}
   * @memberof ResumeApiGetResumeResumesIdGet
   */
  readonly id: string;
}

/**
 * Request parameters for uploadResumeResumesIdPost operation in ResumeApi.
 * @export
 * @interface ResumeApiUploadResumeResumesIdPostRequest
 */
export interface ResumeApiUploadResumeResumesIdPostRequest {
  /**
   *
   * @type {string}
   * @memberof ResumeApiUploadResumeResumesIdPost
   */
  readonly id: string;

  /**
   *
   * @type {any}
   * @memberof ResumeApiUploadResumeResumesIdPost
   */
  readonly file: any;
}

/**
 * ResumeApi - object-oriented interface
 * @export
 * @class ResumeApi
 * @extends {BaseAPI}
 */
export class ResumeApi extends BaseAPI {
  /**
   *
   * @summary Delete resume
   * @param {ResumeApiDeleteResumeResumesIdDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResumeApi
   */
  public deleteResumeResumesIdDelete(
    requestParameters: ResumeApiDeleteResumeResumesIdDeleteRequest,
    options?: any,
  ) {
    return ResumeApiFp(this.configuration)
      .deleteResumeResumesIdDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get preview resume
   * @param {ResumeApiGetPreviewResumeResumesIdPreviewGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResumeApi
   */
  public getPreviewResumeResumesIdPreviewGet(
    requestParameters: ResumeApiGetPreviewResumeResumesIdPreviewGetRequest,
    options?: any,
  ) {
    return ResumeApiFp(this.configuration)
      .getPreviewResumeResumesIdPreviewGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get resume
   * @param {ResumeApiGetResumeResumesIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResumeApi
   */
  public getResumeResumesIdGet(
    requestParameters: ResumeApiGetResumeResumesIdGetRequest,
    options?: any,
  ) {
    return ResumeApiFp(this.configuration)
      .getResumeResumesIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Info about loading resume
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResumeApi
   */
  public infoResumesInfoGet(options?: any) {
    return ResumeApiFp(this.configuration)
      .infoResumesInfoGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload resume
   * @param {ResumeApiUploadResumeResumesIdPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResumeApi
   */
  public uploadResumeResumesIdPost(
    requestParameters: ResumeApiUploadResumeResumesIdPostRequest,
    options?: any,
  ) {
    return ResumeApiFp(this.configuration)
      .uploadResumeResumesIdPost(
        requestParameters.id,
        requestParameters.file,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
