import { sample } from "effector";

import { $pageNum, getList, notificationsDomain } from "./notifications";

// Event

export const readmore = notificationsDomain.createEvent();

// Logic

sample({
  clock: readmore,
  source: $pageNum,
  fn: (pageNum) => pageNum + 1,
  target: $pageNum,
});

sample({
  clock: $pageNum,
  fn: (pageNum) => ({ pageNum }),
  target: getList,
});
