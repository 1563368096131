import { createEffect, createEvent, createStore, sample } from "effector";
import {
  CandidateStudents,
  Pagination,
  RelativeApiGetStudentApplicationRequest,
} from "../../../../../generated/social";
import { getStudentApplicationHistory } from "../../../../../shared/api/admin/histories/model/moderation-history/getRelativeModerationHistory";
import { queryParamsToRatingsRequestAdapter } from "../../../ratings/lib/queryParamsToRatingsRequestAdapter";
import { QueryParams } from "../../../../../shared/api/base";

// stores

export const $studentModeration = createStore<CandidateStudents[]>([]);

export const $isStudentRatingsRequestError = createStore<boolean>(false);

export const $studentQueryParams = createStore<QueryParams>({});

export const $studentPagination = createStore<Pagination | null>(null);

export const $isStudentReadmore = createStore<boolean>(false);

export const $isStudentRatingsLoading = createStore<boolean>(false);

// events
export const fetchStudentRatings =
  createEvent<RelativeApiGetStudentApplicationRequest>();

// Fx

const fetchStudentApplicationFx = createEffect(getStudentApplicationHistory);

sample({
  clock: $studentQueryParams,
  fn: queryParamsToRatingsRequestAdapter,
  target: fetchStudentApplicationFx,
});

sample({
  clock: fetchStudentRatings,
  target: fetchStudentApplicationFx,
});

sample({
  clock: fetchStudentApplicationFx.pending,
  target: $isStudentRatingsLoading,
});

sample({
  clock: fetchStudentApplicationFx.fail,
  fn: () => true,
  target: $isStudentRatingsRequestError,
});

sample({
  clock: fetchStudentApplicationFx.doneData,
  source: { isReadmore: $isStudentReadmore, ratings: $studentModeration },
  fn: ({ isReadmore, ratings }, response) =>
    isReadmore
      ? [...ratings, ...response.candidate_students]
      : response.candidate_students,
  target: $studentModeration,
});

sample({
  clock: [fetchStudentApplicationFx.doneData],
  fn: ({ pagination }) => pagination || null,
  target: $studentPagination,
});
