import { call, put, takeLatest } from "redux-saga/effects";
import {
  EPopupName,
  openResponseErrorPopup,
  popupModel,
} from "src/shared/components/base-popup";
import { deleteUsers } from "src/shared/api/keycloak/delete-users";
import { disableUsers } from "src/shared/api/keycloak/disable-users";
import { enableUsers } from "src/shared/api/keycloak/enable-users";
import {
  failureUsersControlDelete,
  failureUsersControlDisable,
  failureUsersControlEnable,
  successUsersControlDelete,
  successUsersControlDisable,
  successUsersControlEnable,
} from "./actionCreators";
import {
  EUsersControlActions,
  IRequestUsersControlDelete,
  IRequestUsersControlDisable,
  IRequestUsersControlEnable,
} from "./actionTypes";
import { requestUsersListAdmin } from "src/entities/public/users-list-admin";

const { closePopup } = popupModel;

export function* fetchUsersControlDelete({
  payload,
}: IRequestUsersControlDelete) {
  try {
    yield call(deleteUsers, payload);

    yield put(successUsersControlDelete());

    closePopup({ name: EPopupName.DELETE_USERS });

    yield requestUsersListAdmin(payload.queryForUpdateList);
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.errorMessage);

    yield put(failureUsersControlDelete());
  }
}

export function* fetchUsersControlDisable({
  payload,
}: IRequestUsersControlDisable) {
  try {
    yield call(disableUsers, payload);

    yield put(successUsersControlDisable());

    closePopup({ name: EPopupName.LOCK_USERS });

    yield requestUsersListAdmin(payload.queryForUpdateList);
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.errorMessage);

    yield put(failureUsersControlDisable());
  }
}

export function* fetchUsersControlEnable({
  payload,
}: IRequestUsersControlEnable) {
  try {
    yield call(enableUsers, payload);

    yield put(successUsersControlEnable());

    closePopup({ name: EPopupName.UNLOCK_USERS });

    yield requestUsersListAdmin(payload.queryForUpdateList);
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.errorMessage);

    yield put(failureUsersControlEnable());
  }
}

export function* usersControlSaga() {
  yield takeLatest(
    EUsersControlActions.REQUEST_DELETE_USERS,
    fetchUsersControlDelete,
  );
  yield takeLatest(
    EUsersControlActions.REQUEST_DISABLE_USERS,
    fetchUsersControlDisable,
  );
  yield takeLatest(
    EUsersControlActions.REQUEST_ENABLE_USERS,
    fetchUsersControlEnable,
  );
}
