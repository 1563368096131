import { FC, Fragment, ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocStrDto } from "../../../generated/social";
import {
  BaseButton,
  BaseError,
  FormControl,
  TitleWithContent,
} from "../../../shared/components";
import { isStringTextEditorEmpty } from "../../../shared/helpers/isStringEmpty";
import { toCloneObject } from "../../../shared/helpers/toCloneObject";
import { defaultLanguage, languageList } from "../../../shared/i18n/i18n";
import {
  cleanSidebarInformation,
  requestSidebarInformationGet,
  requestSidebarInformationPost,
} from "../../../shared/store/ducks/sidebar-information/actionCreators";
import {
  selectSidebarInformationItem,
  selectSidebarInformationStatusGet,
  selectSidebarInformationStatusPost,
} from "../../../shared/store/ducks/sidebar-information/selectors";
import { ERequestStatus, IBooleanLang } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import "./edit-information-page.scss";
import {
  BaseSelect,
  BaseTextEditor,
} from "../../../shared/components/form-fields";
import { IBaseInputChange } from "../../../shared/models/base-input";
import { SelectItem } from "../../../shared/components/form-fields/base-select/base-select";
import { EPopupName, popupModel } from "src/shared/components/base-popup";

interface ISidebarInformation {
  value: LocStrDto;
  emptyValue: IBooleanLang;
}

const booleanLang: IBooleanLang = {
  ru: false,
  en: false,
};

interface Props {}

const { openPopup } = popupModel;

export const EditInformationPage: FC<Props> = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const sidebarInformation = useSelector(selectSidebarInformationItem);
  const sidebarInformationStatusGet = useSelector(
    selectSidebarInformationStatusGet,
  );
  const sidebarInformationStatusPost = useSelector(
    selectSidebarInformationStatusPost,
  );
  const [sidebar, setSidebar] = useState<ISidebarInformation | null>(null);
  const [activeLanguage, setActiveLanguage] = useState<keyof LocStrDto>(
    defaultLanguage as keyof LocStrDto,
  );
  const [langSelectItems, setLangSelectItems] = useState<SelectItem[]>([]);
  const isLoadingSidebarGet: boolean =
    sidebarInformationStatusGet === ERequestStatus.LOADING;
  const isLoadingSidebarPost: boolean =
    sidebarInformationStatusPost === ERequestStatus.LOADING;

  useEffect(() => {
    setLangSelectItems(() => {
      return languageList.map((item) => ({
        value: item,
        label: item,
      }));
    });
    dispatch(requestSidebarInformationGet({}));

    return () => {
      dispatch(cleanSidebarInformation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (sidebarInformation) {
      setSidebar(() => {
        return {
          value: sidebarInformation,
          emptyValue: toCloneObject(booleanLang),
        };
      });
    }
  }, [sidebarInformation, i18n.language]);

  const onChangeSidebar = (payload: IBaseInputChange) => {
    setSidebar((prev) => {
      const localPrev = toCloneObject(prev as ISidebarInformation);
      const isValueEmpty: boolean = isStringTextEditorEmpty(payload.value);

      if (localPrev) {
        localPrev.value[activeLanguage] = payload.value;
        localPrev.emptyValue[activeLanguage] = isValueEmpty;
      }

      return localPrev;
    });
  };

  const onSave = () => {
    if (sidebar) {
      const callback = () => {
        openPopup({
          name: EPopupName.BASE_MESSAGE_POPUP,
          message: { text: t("popup.baseMessage.dataSaved") },
        });
      };

      dispatch(
        requestSidebarInformationPost({
          item: {
            content: sidebar.value,
          },
          callback,
        }),
      );
    }
  };

  const onLangChange = (payload: IBaseInputChange) => {
    setActiveLanguage(payload.value as keyof LocStrDto);
  };

  const isSidebarInformationEmpty: boolean = languageList.some((langItem) => {
    const _langItem = langItem as keyof LocStrDto;

    return isStringTextEditorEmpty(sidebar?.value[_langItem] || "");
  });

  const renderTextEditor = languageList.map(
    (langItem) =>
      langItem === activeLanguage && (
        <Fragment key={langItem}>
          <BaseTextEditor
            value={sidebar?.value[activeLanguage] || ""}
            onChange={onChangeSidebar}
            className={classNames({
              "quill-error": sidebar?.emptyValue[activeLanguage],
            })}
          />
          {sidebar?.emptyValue[activeLanguage] && (
            <BaseError
              text={t("editFaq.errorMessage.notEmpty")}
              type="danger"
            />
          )}
        </Fragment>
      ),
  );

  return (
    <PageWrapper
      title={t("editInformation.title")}
      titlePanelSlot={
        <div className="edit-faq-page__desktop-save-btn">
          <BaseButton
            primary
            small
            disabled={isLoadingSidebarPost || isSidebarInformationEmpty}
            onClick={onSave}
          >
            {t("editFaq.btn.save")}
          </BaseButton>
        </div>
      }
      isLoadingPanel={isLoadingSidebarGet}
    >
      <div className="edit-information-page">
        {sidebar && (
          <div className="edit-information-page__sidebar-information">
            <FormControl>
              <BaseSelect
                placeholder={t("common.languageSelect.placeholder")}
                items={langSelectItems}
                onChange={onLangChange}
                activeItem={activeLanguage}
              />
            </FormControl>
            <TitleWithContent
              title={t("editInformation.titleWithContent.sidebarInformation")}
              canToggle
              type="large"
            >
              {renderTextEditor}
            </TitleWithContent>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
