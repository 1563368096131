import { FC, useEffect } from "react";
import {
  BaseButton,
  BaseCoutdown,
  BasePopup,
  EPopupName,
} from "../../../../../shared/components";
import { TFunction } from "i18next";
import { useTimer } from "../../../../../shared/hooks/useTimer";
import { RoomStateInterface } from "../../../../../shared/store/ducks/room";
import { closePopup } from "../../../../../shared/components/base-popup/model";

interface ConfirmPresencePopupProps {
  t: TFunction;
  isPlayer: boolean;
  room: RoomStateInterface;
  onFinishedTimer: () => void;
  onFinishTimerDelay: () => void;
}

export const ConfirmPresencePopup: FC<ConfirmPresencePopupProps> = (
  props: ConfirmPresencePopupProps,
) => {
  const { t, room, isPlayer, onFinishedTimer, onFinishTimerDelay } = props;

  const { startTimer, seconds } = useTimer(
    room.lobby_presence_timer,
    room.lobby_confirm_timer,
    onFinishTimerDelay,
    onFinishedTimer,
  );

  useEffect(() => {
    isPlayer && room.is_timeout && startTimer();
  }, [isPlayer, room.is_timeout, startTimer]);

  const confirmPresence = (): void => {
    closePopup({ name: EPopupName.CONFIRM_PRESENCE });

    startTimer();
  };

  return (
    <BasePopup name={EPopupName.CONFIRM_PRESENCE} isOutsideClick={false}>
      <div className="session-page__timer-title">
        {t("room.presence.confirm")}
      </div>
      <div className="session-page__timer-clock">
        <BaseCoutdown time={seconds} />
      </div>
      <BaseButton block large primary onClick={confirmPresence}>
        {t("room.i.am.here")}!
      </BaseButton>
    </BasePopup>
  );
};
