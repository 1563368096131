import { sample } from "effector";

import {
  addSmsMessage,
  CreateSmsMessage,
} from "src/shared/api/admin/notification/sms-notification";

import { $sms } from "./get-sms";

import { resetNotification } from "./reset-sms";

import { smsNotificationDomain } from "./sms-notification";

import { errorFx, successFx } from "../../common-notification";

// Event

export const createSms = smsNotificationDomain.createEvent<CreateSmsMessage>();

// Effect

export const createSmsFx = smsNotificationDomain.createEffect(addSmsMessage);

// Logic

sample({ clock: createSms, target: createSmsFx });

sample({
  clock: createSmsFx.doneData,
  target: [$sms, successFx, resetNotification],
});

sample({
  clock: createSmsFx.fail,
  target: errorFx,
});
