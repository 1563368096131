import { FC, memo, ReactElement } from "react";

import classNames from "classnames";

export type TBasePopupButtonGroupItem = {
  label: string;
  onClick: () => void;
  isBold?: boolean;
  isDisabled?: boolean;
};

export type TBasePopupButtonGroup = TBasePopupButtonGroupItem[];

export interface IBasePopupButtonGroupsProps {
  groups: TBasePopupButtonGroup[];
}

const BasePopupButtonsListComponent: FC<IBasePopupButtonGroupsProps> = ({
  groups,
}): ReactElement => {
  return (
    <div className="base-popup__buttons-list">
      {groups.map((buttons, i) => (
        <div className="base-popup__buttons-list-item" key={i}>
          {buttons.map((button) => (
            <button
              key={button.label}
              type="button"
              disabled={button.isDisabled}
              className={classNames("base-popup__buttons-list-button", {
                "base-popup__buttons-list-button--bold": button.isBold,
              })}
              onClick={(e) => {
                e.preventDefault();

                button.onClick();
              }}
            >
              {button.label}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export const BasePopupButtonsList = memo(BasePopupButtonsListComponent);
