import classNames from "classnames";
import { FC, ReactElement } from "react";

import { Checkbox } from "../../../models/checkbox";

import {
  EDataGridFilterType,
  IDataGridColumnControlPanelGroup,
  IDataGridFilterItem,
  IDataGridSelectOption,
  TDataGridDataParams,
} from "../../../models/dataGrid";
import {
  BaseInputDate,
  BaseInputNumber,
  BaseInputText,
  BaseSelect,
} from "../../form-fields";
import { InputCheckbox } from "../../input-checkbox/input-checkbox";

interface Props {
  group: IDataGridColumnControlPanelGroup;
  queryParams: TDataGridDataParams;
  onUpdateSortAndFilters: (params: TDataGridDataParams) => void;
}

export const DataGridMobileFiltersGroup: FC<Props> = ({
  group,
  queryParams,
  onUpdateSortAndFilters,
}): ReactElement => {
  const setFilterValue = (filter: IDataGridFilterItem, value: string): void => {
    onUpdateSortAndFilters({
      ...queryParams,
      [filter.key]: value,
    });
  };

  const isChecked = (
    filterItem: IDataGridSelectOption,
    value?: string
  ): boolean => {
    if (!value) {
      return false;
    }

    let filterValues = value.split(",");

    return filterValues.includes(filterItem.value);
  };

  const onChangeCheckbox = (
    filter: IDataGridFilterItem,
    filterItem: IDataGridSelectOption,
    toggle: boolean
  ) => {
    let filterValues = !!filter.value ? filter.value.split(",") : [];

    if (toggle) {
      if (filter.onlyOneActiveOption) {
        filterValues = [filterItem.value];
      } else {
        filterValues.push(filterItem.value);
      }
    } else {
      filterValues = filterValues.filter(
        (filterValue) => filterValue !== filterItem.value
      );
    }

    setFilterValue(filter, filterValues.join(","));
  };

  return (
    <div className="data-grid__mobile-filters-item-group">
      {group.filters?.map((filter) => (
        <div
          key={filter.key}
          className={classNames("data-grid__mobile-filters-item-form-control", {
            "data-grid__mobile-filters-item-form-control--half": filter.isHalf,
          })}
        >
          {filter.type === EDataGridFilterType.SEARCH && (
            <BaseInputText
              isSmall
              forDarkBg
              value={queryParams?.[filter.key] || ""}
              placeholder={filter.placeholder}
              name={filter.key}
              onChange={({ value }) => {
                setFilterValue(filter, value);
              }}
            />
          )}
          {filter.type === EDataGridFilterType.SELECT && (
            <BaseSelect
              size="medium"
              forDarkBg
              activeItem={queryParams?.[filter.key]}
              placeholder={filter.placeholder}
              name={filter.key}
              items={filter.items || []}
              onChange={({ value }) => {
                setFilterValue(filter, value);
              }}
            />
          )}
          {filter.type === EDataGridFilterType.NUMBER && (
            <BaseInputNumber
              isSmall
              forDarkBg
              placeholder={filter.placeholder}
              name={filter.key}
              value={queryParams?.[filter.key] || ""}
              onChange={({ value }) => {
                setFilterValue(filter, value);
              }}
              {...filter.numberProps}
            />
          )}
          {filter.type === EDataGridFilterType.DATE && (
            <BaseInputDate
              isSmall
              forDarkBg
              placeholder={filter.placeholder}
              name={filter.key}
              value={queryParams?.[filter.key] || ""}
              onChange={({ value }) => {
                setFilterValue(filter, value);
              }}
            />
          )}
          {filter.type === EDataGridFilterType.CHECKBOX && (
            <div className="data-grid__filters-checkbox-list">
              {filter?.items?.map((filterItem) => (
                <InputCheckbox
                  forDarkBg
                  key={`${filter.key}-${filterItem.label}`}
                  className="data-grid__filters-checkbox-list-item"
                  value={isChecked(filterItem, queryParams[filter.key])}
                  type={
                    isChecked(filterItem, queryParams?.[filter.key])
                      ? Checkbox.CHOSEN
                      : Checkbox.EMPTY
                  }
                  onChange={(toggle) =>
                    onChangeCheckbox(filter, filterItem, toggle)
                  }
                >
                  {filterItem.label}
                </InputCheckbox>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
