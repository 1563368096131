/* tslint:disable */
/* eslint-disable */
/**
 * Alabuga Notification API
 * Alabuga Notification Service
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 * Модель инвайта в ассессмент
 * @export
 * @interface AssessmentInvite
 */
export interface AssessmentInvite {
  /**
   *
   * @type {string}
   * @memberof AssessmentInvite
   */
  aid: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentInvite
   */
  aTitle: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentInvite
   */
  gTitle: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentInvite
   */
  logoUrl: string;
  /**
   *
   * @type {AssessmentStatus}
   * @memberof AssessmentInvite
   */
  aSt: AssessmentStatus;
  /**
   *
   * @type {AssessmentType}
   * @memberof AssessmentInvite
   */
  aType: AssessmentType;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentInvite
   */
  isClose: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentInvite
   */
  required_participation?: boolean;
}
/**
 * Модель исключения из ассессмента.
 * @export
 * @interface AssessmentRevoke
 */
export interface AssessmentRevoke {
  /**
   *
   * @type {string}
   * @memberof AssessmentRevoke
   */
  aid: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRevoke
   */
  aTitle: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRevoke
   */
  gTitle: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentRevoke
   */
  logoUrl: string;
  /**
   *
   * @type {AssessmentType}
   * @memberof AssessmentRevoke
   */
  aType: AssessmentType;
}
/**
 *      Enum-перечисление статусов ассессментов.
 * @export
 * @enum {string}
 */
export enum AssessmentStatus {
  Lobby = "lobby",
  InProgress = "inProgress",
  Finished = "finished",
}

/**
 *      Enum-перечисление типов ассессментов.
 * @export
 * @enum {string}
 */
export enum AssessmentType {
  Rating = "rating",
  NonRating = "nonRating",
}

/**
 *
 * @export
 * @interface BodyTempWsV1Post
 */
export interface BodyTempWsV1Post {
  /**
   *
   * @type {WsMsg}
   * @memberof BodyTempWsV1Post
   */
  msg: WsMsg;
  /**
   *
   * @type {WsEvtMyMsgs}
   * @memberof BodyTempWsV1Post
   */
  evt_my_msgs: WsEvtMyMsgs;
  /**
   *
   * @type {WsCmdMyMsgs}
   * @memberof BodyTempWsV1Post
   */
  cmd_my_msgs: WsCmdMyMsgs;
  /**
   *
   * @type {WsCmdDelMsgs}
   * @memberof BodyTempWsV1Post
   */
  cmd_del_msgs: WsCmdDelMsgs;
  /**
   *
   * @type {WsCmdMarkRead}
   * @memberof BodyTempWsV1Post
   */
  cmd_mark_read: WsCmdMarkRead;
}
/**
 *      Enum-перечисление типов каналов.
 * @export
 * @enum {string}
 */
export enum ChanType {
  Platform = "platform",
  Hrs = "hrs",
  Players = "players",
}

/**
 * Модель для уведомления при смене статуса компании
 * @export
 * @interface ChangeStatusCompany
 */
export interface ChangeStatusCompany {
  /**
   *
   * @type {StatusCompany}
   * @memberof ChangeStatusCompany
   */
  status: StatusCompany;
  /**
   *
   * @type {string}
   * @memberof ChangeStatusCompany
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeStatusCompany
   */
  lastName?: string;
}
/**
 * Модель для уведомления пользователя о жалобе в случае одобрения админом.
 * @export
 * @interface Complaint
 */
export interface Complaint {
  /**
   *
   * @type {string}
   * @memberof Complaint
   */
  txt: string;
}
/**
 * Модель уведомления о штрафе.
 * @export
 * @interface FineNotification
 */
export interface FineNotification {
  /**
   *
   * @type {Array<any>}
   * @memberof FineNotification
   */
  competencies: Array<any>;
  /**
   *
   * @type {object}
   * @memberof FineNotification
   */
  period: object;
  /**
   *
   * @type {object}
   * @memberof FineNotification
   */
  removable_rating: object;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 * Модель для списка истории массовых сообщений.
 * @export
 * @interface ListMassMsgHistoryRes
 */
export interface ListMassMsgHistoryRes {
  /**
   *
   * @type {Array<MassMsgHistoryRes>}
   * @memberof ListMassMsgHistoryRes
   */
  listHistory: Array<MassMsgHistoryRes>;
}
/**
 * Модель для истории массовых сообщений.
 * @export
 * @interface MassMsgHistoryRes
 */
export interface MassMsgHistoryRes {
  /**
   *
   * @type {string}
   * @memberof MassMsgHistoryRes
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MassMsgHistoryRes
   */
  sid: string;
  /**
   *
   * @type {MassMsgStatusType}
   * @memberof MassMsgHistoryRes
   */
  status: MassMsgStatusType;
  /**
   *
   * @type {string}
   * @memberof MassMsgHistoryRes
   */
  sent: string;
  /**
   *
   * @type {number}
   * @memberof MassMsgHistoryRes
   */
  countUsers: number;
}
/**
 *      Enum-перечисление статусов массовых сообщений.
 * @export
 * @enum {string}
 */
export enum MassMsgStatusType {
  InQueue = "inQueue",
  Delivered = "delivered",
}

/**
 * Модель сообщения.
 * @export
 * @interface Msg
 */
export interface Msg {
  /**
   *
   * @type {MsgType}
   * @memberof Msg
   */
  type: MsgType;
  /**
   *
   * @type {AssessmentInvite | AssessmentRevoke | ChangeStatusCompany | Txt | FineNotification | Complaint}
   * @memberof Msg
   */
  body:
    | AssessmentInvite
    | AssessmentRevoke
    | ChangeStatusCompany
    | Txt
    | FineNotification
    | Complaint;
}
/**
 *      Enum-перечисление типов сообщений
 * @export
 * @enum {string}
 */
export enum MsgType {
  AssessmentInvite = "assessmentInvite",
  AssessmentRevoke = "assessmentRevoke",
  FineNotification = "fineNotification",
  Txt = "txt",
  StatusCompany = "statusCompany",
  Complaint = "complaint",
}

/**
 * Модель для пагинации.
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalItems: number;
}
/**
 * Модель для запроса отправки электронной почты.
 * @export
 * @interface ReqEmail
 */
export interface ReqEmail {
  /**
   *
   * @type {string}
   * @memberof ReqEmail
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof ReqEmail
   */
  text: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ReqEmail
   */
  uids: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ReqEmail
   */
  shouldSend?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ReqEmail
   */
  allEmails?: boolean;
}
/**
 * Модель для запроса прямого отправителя электронной почты.
 * @export
 * @interface ReqEmailDirectlySender
 */
export interface ReqEmailDirectlySender {
  /**
   *
   * @type {string}
   * @memberof ReqEmailDirectlySender
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof ReqEmailDirectlySender
   */
  message: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ReqEmailDirectlySender
   */
  recipients: Array<string>;
}
/**
 * Модель для запроса отправителя электронной почты.
 * @export
 * @interface ReqEmailSender
 */
export interface ReqEmailSender {
  /**
   *
   * @type {boolean}
   * @memberof ReqEmailSender
   */
  allEmails: boolean;
}
/**
 * Модель для запроса отправки уведомления всем пользователям.
 * @export
 * @interface ReqNotifyAll
 */
export interface ReqNotifyAll {
  /**
   *
   * @type {ChanType}
   * @memberof ReqNotifyAll
   */
  chan: ChanType;
  /**
   *
   * @type {Msg}
   * @memberof ReqNotifyAll
   */
  msg: Msg;
}
/**
 * Модель для запроса отправки уведомления если пользователь отклонил заявку в родственники
 * @export
 * @interface ReqNotifyNoConfirmationReference
 */
export interface ReqNotifyNoConfirmationReference {
  /**
   *
   * @type {string}
   * @memberof ReqNotifyNoConfirmationReference
   */
  uid: string;
  /**
   *
   * @type {ChangeStatusCompany}
   * @memberof ReqNotifyNoConfirmationReference
   */
  msg: ChangeStatusCompany;
}
/**
 * Модель для запроса отправки персонального уведомления.
 * @export
 * @interface ReqNotifyPersonal
 */
export interface ReqNotifyPersonal {
  /**
   *
   * @type {Array<string>}
   * @memberof ReqNotifyPersonal
   */
  uids: Array<string>;
  /**
   *
   * @type {Msg}
   * @memberof ReqNotifyPersonal
   */
  msg: Msg;
}
/**
 * Модель для запроса отправки SMS.
 * @export
 * @interface ReqSms
 */
export interface ReqSms {
  /**
   *
   * @type {string}
   * @memberof ReqSms
   */
  text: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ReqSms
   */
  uids: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ReqSms
   */
  shouldSend?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ReqSms
   */
  allPhones?: boolean;
}
/**
 * Модель для запроса отправителя SMS.
 * @export
 * @interface ReqSmsSender
 */
export interface ReqSmsSender {
  /**
   *
   * @type {boolean}
   * @memberof ReqSmsSender
   */
  allPhones: boolean;
}
/**
 * Модель ответа для электронной почты.
 * @export
 * @interface ResponseEmail
 */
export interface ResponseEmail {
  /**
   *
   * @type {string}
   * @memberof ResponseEmail
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResponseEmail
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof ResponseEmail
   */
  text: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ResponseEmail
   */
  uids: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ResponseEmail
   */
  sent?: string;
}
/**
 * Модель для ответа со списком электронной почты.
 * @export
 * @interface ResponseListEmail
 */
export interface ResponseListEmail {
  /**
   *
   * @type {Array<ResponseEmail>}
   * @memberof ResponseListEmail
   */
  listEmail: Array<ResponseEmail>;
  /**
   *
   * @type {Pagination}
   * @memberof ResponseListEmail
   */
  pagination: Pagination;
}
/**
 * Модель для ответа со списком уведомлений.
 * @export
 * @interface ResponseListNotifies
 */
export interface ResponseListNotifies {
  /**
   *
   * @type {Array<ResponseNotify>}
   * @memberof ResponseListNotifies
   */
  listNotifies: Array<ResponseNotify>;
  /**
   *
   * @type {Pagination}
   * @memberof ResponseListNotifies
   */
  pagination: Pagination;
}
/**
 * Модель для ответа со списком SMS.
 * @export
 * @interface ResponseListSms
 */
export interface ResponseListSms {
  /**
   *
   * @type {Array<ResponseSms>}
   * @memberof ResponseListSms
   */
  listSms: Array<ResponseSms>;
  /**
   *
   * @type {Pagination}
   * @memberof ResponseListSms
   */
  pagination: Pagination;
}
/**
 * Модель для ответа уведомления.
 * @export
 * @interface ResponseNotify
 */
export interface ResponseNotify {
  /**
   *
   * @type {string}
   * @memberof ResponseNotify
   */
  id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ResponseNotify
   */
  uids: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ResponseNotify
   */
  when?: string;
  /**
   *
   * @type {Msg}
   * @memberof ResponseNotify
   */
  msg: Msg;
}
/**
 * Модель ответа для SMS.
 * @export
 * @interface ResponseSms
 */
export interface ResponseSms {
  /**
   *
   * @type {string}
   * @memberof ResponseSms
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResponseSms
   */
  text: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ResponseSms
   */
  uids: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ResponseSms
   */
  sent?: string;
}
/**
 *      Enum-перечисление статусов компании при смене которых прилетает уведомление
 * @export
 * @enum {string}
 */
export enum StatusCompany {
  Student = "student",
  Employee = "employee",
  NoCandidateStudent = "no-candidate-student",
  NoCandidateEmployee = "no-candidate-employee",
  RelativesForRelative = "relatives-for-relative",
  NoRelativesForRelative = "no-relatives-for-relative",
  NoRelativesForStudent = "no-relatives-for-student",
  RelativesForStudent = "relatives-for-student",
  NoConfirmationReferenceRelative = "no-confirmation-reference-relative",
  CancellationRelative = "cancellation-relative",
  CancellationStudent = "cancellation-student",
}

/**
 * Модель текста в сообщении.
 * @export
 * @interface Txt
 */
export interface Txt {
  /**
   *
   * @type {string}
   * @memberof Txt
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof Txt
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Txt
   */
  logoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Txt
   */
  link?: string;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string | number>}
   * @memberof ValidationError
   */
  loc: Array<string | number>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *      Enum-перечисление команд WebSocket.
 * @export
 * @enum {string}
 */
export enum WsCmd {
  MyMsgsCmd = "myMsgsCmd",
  MarkReadCmd = "markReadCmd",
  DelMsgsCmd = "delMsgsCmd",
}

/**
 * Модель команды \"delMsgs\" WebSocket.
 * @export
 * @interface WsCmdDelMsgs
 */
export interface WsCmdDelMsgs {
  /**
   *
   * @type {Array<string>}
   * @memberof WsCmdDelMsgs
   */
  msgIds: Array<string>;
}
/**
 * Модель команды \"markRead\" WebSocket.
 * @export
 * @interface WsCmdMarkRead
 */
export interface WsCmdMarkRead {
  /**
   *
   * @type {Array<string>}
   * @memberof WsCmdMarkRead
   */
  msgIds: Array<string>;
}
/**
 * Модель команды \"myMsgs\" WebSocket.
 * @export
 * @interface WsCmdMyMsgs
 */
export interface WsCmdMyMsgs {
  /**
   *
   * @type {number}
   * @memberof WsCmdMyMsgs
   */
  pageNum?: number;
  /**
   *
   * @type {number}
   * @memberof WsCmdMyMsgs
   */
  pageSize?: number;
}
/**
 *      Enum-перечисление событий WebSocket.      msg - событие \"msg\" WebSocket для отправки клиенту нового сообщения.     removeMsgs - событие \"removeMsgs\" WebSocket для удаления сообщений.     myMsgs - событие \"myMsgs\" WebSocket для отправки списка текущих сообщений.
 * @export
 * @enum {string}
 */
export enum WsEvt {
  MsgEvt = "msgEvt",
  RemoveMsgsEvt = "removeMsgsEvt",
  MyMsgsEvt = "myMsgsEvt",
}

/**
 * Модель для события \"MyMsgs\" WebSocket.
 * @export
 * @interface WsEvtMyMsgs
 */
export interface WsEvtMyMsgs {
  /**
   *
   * @type {Array<WsMsg>}
   * @memberof WsEvtMyMsgs
   */
  msgs: Array<WsMsg>;
}
/**
 * Модель события \"msg\" WebSocket.
 * @export
 * @interface WsMsg
 */
export interface WsMsg {
  /**
   *
   * @type {string}
   * @memberof WsMsg
   */
  mid: string;
  /**
   *
   * @type {string}
   * @memberof WsMsg
   */
  when: string;
  /**
   *
   * @type {MsgType}
   * @memberof WsMsg
   */
  type: MsgType;
  /**
   *
   * @type {Txt | AssessmentInvite | AssessmentRevoke | ChangeStatusCompany | FineNotification | Complaint}
   * @memberof WsMsg
   */
  msg:
    | Txt
    | AssessmentInvite
    | AssessmentRevoke
    | ChangeStatusCompany
    | FineNotification
    | Complaint;
  /**
   *
   * @type {boolean}
   * @memberof WsMsg
   */
  isNew: boolean;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Do not call. Dummy method to include websocket models to schema
     * @param {WsEvt} evt
     * @param {WsCmd} cmd
     * @param {BodyTempWsV1Post} bodyTempWsV1Post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tempWsV1Post: async (
      evt: WsEvt,
      cmd: WsCmd,
      bodyTempWsV1Post: BodyTempWsV1Post,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'evt' is not null or undefined
      assertParamExists("tempWsV1Post", "evt", evt);
      // verify required parameter 'cmd' is not null or undefined
      assertParamExists("tempWsV1Post", "cmd", cmd);
      // verify required parameter 'bodyTempWsV1Post' is not null or undefined
      assertParamExists("tempWsV1Post", "bodyTempWsV1Post", bodyTempWsV1Post);
      const localVarPath = `/ws/v1/_`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (evt !== undefined) {
        localVarQueryParameter["evt"] = evt;
      }

      if (cmd !== undefined) {
        localVarQueryParameter["cmd"] = cmd;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyTempWsV1Post,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Do not call. Dummy method to include websocket models to schema
     * @param {WsEvt} evt
     * @param {WsCmd} cmd
     * @param {BodyTempWsV1Post} bodyTempWsV1Post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tempWsV1Post(
      evt: WsEvt,
      cmd: WsCmd,
      bodyTempWsV1Post: BodyTempWsV1Post,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tempWsV1Post(
        evt,
        cmd,
        bodyTempWsV1Post,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Do not call. Dummy method to include websocket models to schema
     * @param {WsEvt} evt
     * @param {WsCmd} cmd
     * @param {BodyTempWsV1Post} bodyTempWsV1Post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tempWsV1Post(
      evt: WsEvt,
      cmd: WsCmd,
      bodyTempWsV1Post: BodyTempWsV1Post,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .tempWsV1Post(evt, cmd, bodyTempWsV1Post, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for tempWsV1Post operation in DefaultApi.
 * @export
 * @interface DefaultApiTempWsV1PostRequest
 */
export interface DefaultApiTempWsV1PostRequest {
  /**
   *
   * @type {WsEvt}
   * @memberof DefaultApiTempWsV1Post
   */
  readonly evt: WsEvt;

  /**
   *
   * @type {WsCmd}
   * @memberof DefaultApiTempWsV1Post
   */
  readonly cmd: WsCmd;

  /**
   *
   * @type {BodyTempWsV1Post}
   * @memberof DefaultApiTempWsV1Post
   */
  readonly bodyTempWsV1Post: BodyTempWsV1Post;
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Do not call. Dummy method to include websocket models to schema
   * @param {DefaultApiTempWsV1PostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tempWsV1Post(
    requestParameters: DefaultApiTempWsV1PostRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .tempWsV1Post(
        requestParameters.evt,
        requestParameters.cmd,
        requestParameters.bodyTempWsV1Post,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete email messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delEmail: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("delEmail", "id", id);
      const localVarPath = `/api/v1/email/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get email messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmail: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getEmail", "id", id);
      const localVarPath = `/api/v1/email/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list email history
     * @param {string} id
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailsHistory: async (
      id: string,
      offset?: number,
      limit?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getEmailsHistory", "id", id);
      const localVarPath = `/api/v1/email/{id}/history`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list email messages
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort messages by fields &#x60;subject&#x60;/&#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailsList: async (
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/email/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add email message
     * @param {ReqEmail} reqEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postEmail: async (
      reqEmail: ReqEmail,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reqEmail' is not null or undefined
      assertParamExists("postEmail", "reqEmail", reqEmail);
      const localVarPath = `/api/v1/email/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqEmail,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update email message
     * @param {string} id
     * @param {ReqEmail} reqEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putEmail: async (
      id: string,
      reqEmail: ReqEmail,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putEmail", "id", id);
      // verify required parameter 'reqEmail' is not null or undefined
      assertParamExists("putEmail", "reqEmail", reqEmail);
      const localVarPath = `/api/v1/email/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqEmail,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send email messages
     * @param {string} id
     * @param {ReqEmailSender} reqEmailSender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmail: async (
      id: string,
      reqEmailSender: ReqEmailSender,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("sendEmail", "id", id);
      // verify required parameter 'reqEmailSender' is not null or undefined
      assertParamExists("sendEmail", "reqEmailSender", reqEmailSender);
      const localVarPath = `/api/v1/email/{id}/send`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqEmailSender,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send email message directly without uuid
     * @param {ReqEmailDirectlySender} reqEmailDirectlySender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailDirectly: async (
      reqEmailDirectlySender: ReqEmailDirectlySender,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reqEmailDirectlySender' is not null or undefined
      assertParamExists(
        "sendEmailDirectly",
        "reqEmailDirectlySender",
        reqEmailDirectlySender,
      );
      const localVarPath = `/api/v1/email/send_directly`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqEmailDirectlySender,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete email messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delEmail(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delEmail(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get email messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmail(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEmail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmail(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get list email history
     * @param {string} id
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmailsHistory(
      id: string,
      offset?: number,
      limit?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListMassMsgHistoryRes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEmailsHistory(
          id,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get list email messages
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort messages by fields &#x60;subject&#x60;/&#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmailsList(
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseListEmail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailsList(
        pageNum,
        pageSize,
        orderBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add email message
     * @param {ReqEmail} reqEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postEmail(
      reqEmail: ReqEmail,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEmail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postEmail(
        reqEmail,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update email message
     * @param {string} id
     * @param {ReqEmail} reqEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putEmail(
      id: string,
      reqEmail: ReqEmail,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putEmail(
        id,
        reqEmail,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send email messages
     * @param {string} id
     * @param {ReqEmailSender} reqEmailSender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEmail(
      id: string,
      reqEmailSender: ReqEmailSender,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEmail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmail(
        id,
        reqEmailSender,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send email message directly without uuid
     * @param {ReqEmailDirectlySender} reqEmailDirectlySender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEmailDirectly(
      reqEmailDirectlySender: ReqEmailDirectlySender,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sendEmailDirectly(
          reqEmailDirectlySender,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmailApiFp(configuration);
  return {
    /**
     *
     * @summary Delete email messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delEmail(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .delEmail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get email messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmail(id: string, options?: any): AxiosPromise<ResponseEmail> {
      return localVarFp
        .getEmail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list email history
     * @param {string} id
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailsHistory(
      id: string,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<ListMassMsgHistoryRes> {
      return localVarFp
        .getEmailsHistory(id, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list email messages
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort messages by fields &#x60;subject&#x60;/&#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailsList(
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseListEmail> {
      return localVarFp
        .getEmailsList(pageNum, pageSize, orderBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add email message
     * @param {ReqEmail} reqEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postEmail(reqEmail: ReqEmail, options?: any): AxiosPromise<ResponseEmail> {
      return localVarFp
        .postEmail(reqEmail, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update email message
     * @param {string} id
     * @param {ReqEmail} reqEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putEmail(
      id: string,
      reqEmail: ReqEmail,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .putEmail(id, reqEmail, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send email messages
     * @param {string} id
     * @param {ReqEmailSender} reqEmailSender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmail(
      id: string,
      reqEmailSender: ReqEmailSender,
      options?: any,
    ): AxiosPromise<ResponseEmail> {
      return localVarFp
        .sendEmail(id, reqEmailSender, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send email message directly without uuid
     * @param {ReqEmailDirectlySender} reqEmailDirectlySender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmailDirectly(
      reqEmailDirectlySender: ReqEmailDirectlySender,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sendEmailDirectly(reqEmailDirectlySender, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delEmail operation in EmailApi.
 * @export
 * @interface EmailApiDelEmailRequest
 */
export interface EmailApiDelEmailRequest {
  /**
   *
   * @type {string}
   * @memberof EmailApiDelEmail
   */
  readonly id: string;
}

/**
 * Request parameters for getEmail operation in EmailApi.
 * @export
 * @interface EmailApiGetEmailRequest
 */
export interface EmailApiGetEmailRequest {
  /**
   *
   * @type {string}
   * @memberof EmailApiGetEmail
   */
  readonly id: string;
}

/**
 * Request parameters for getEmailsHistory operation in EmailApi.
 * @export
 * @interface EmailApiGetEmailsHistoryRequest
 */
export interface EmailApiGetEmailsHistoryRequest {
  /**
   *
   * @type {string}
   * @memberof EmailApiGetEmailsHistory
   */
  readonly id: string;

  /**
   *
   * @type {number}
   * @memberof EmailApiGetEmailsHistory
   */
  readonly offset?: number;

  /**
   *
   * @type {number}
   * @memberof EmailApiGetEmailsHistory
   */
  readonly limit?: number;
}

/**
 * Request parameters for getEmailsList operation in EmailApi.
 * @export
 * @interface EmailApiGetEmailsListRequest
 */
export interface EmailApiGetEmailsListRequest {
  /**
   * Page Number, one-based
   * @type {number}
   * @memberof EmailApiGetEmailsList
   */
  readonly pageNum?: number;

  /**
   * Page Size [1..1000], default 10
   * @type {number}
   * @memberof EmailApiGetEmailsList
   */
  readonly pageSize?: number;

  /**
   * Sort messages by fields &#x60;subject&#x60;/&#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
   * @type {Array<string>}
   * @memberof EmailApiGetEmailsList
   */
  readonly orderBy?: Array<string>;
}

/**
 * Request parameters for postEmail operation in EmailApi.
 * @export
 * @interface EmailApiPostEmailRequest
 */
export interface EmailApiPostEmailRequest {
  /**
   *
   * @type {ReqEmail}
   * @memberof EmailApiPostEmail
   */
  readonly reqEmail: ReqEmail;
}

/**
 * Request parameters for putEmail operation in EmailApi.
 * @export
 * @interface EmailApiPutEmailRequest
 */
export interface EmailApiPutEmailRequest {
  /**
   *
   * @type {string}
   * @memberof EmailApiPutEmail
   */
  readonly id: string;

  /**
   *
   * @type {ReqEmail}
   * @memberof EmailApiPutEmail
   */
  readonly reqEmail: ReqEmail;
}

/**
 * Request parameters for sendEmail operation in EmailApi.
 * @export
 * @interface EmailApiSendEmailRequest
 */
export interface EmailApiSendEmailRequest {
  /**
   *
   * @type {string}
   * @memberof EmailApiSendEmail
   */
  readonly id: string;

  /**
   *
   * @type {ReqEmailSender}
   * @memberof EmailApiSendEmail
   */
  readonly reqEmailSender: ReqEmailSender;
}

/**
 * Request parameters for sendEmailDirectly operation in EmailApi.
 * @export
 * @interface EmailApiSendEmailDirectlyRequest
 */
export interface EmailApiSendEmailDirectlyRequest {
  /**
   *
   * @type {ReqEmailDirectlySender}
   * @memberof EmailApiSendEmailDirectly
   */
  readonly reqEmailDirectlySender: ReqEmailDirectlySender;
}

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
  /**
   *
   * @summary Delete email messages by id
   * @param {EmailApiDelEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public delEmail(requestParameters: EmailApiDelEmailRequest, options?: any) {
    return EmailApiFp(this.configuration)
      .delEmail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get email messages by id
   * @param {EmailApiGetEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public getEmail(requestParameters: EmailApiGetEmailRequest, options?: any) {
    return EmailApiFp(this.configuration)
      .getEmail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list email history
   * @param {EmailApiGetEmailsHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public getEmailsHistory(
    requestParameters: EmailApiGetEmailsHistoryRequest,
    options?: any,
  ) {
    return EmailApiFp(this.configuration)
      .getEmailsHistory(
        requestParameters.id,
        requestParameters.offset,
        requestParameters.limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list email messages
   * @param {EmailApiGetEmailsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public getEmailsList(
    requestParameters: EmailApiGetEmailsListRequest = {},
    options?: any,
  ) {
    return EmailApiFp(this.configuration)
      .getEmailsList(
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add email message
   * @param {EmailApiPostEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public postEmail(requestParameters: EmailApiPostEmailRequest, options?: any) {
    return EmailApiFp(this.configuration)
      .postEmail(requestParameters.reqEmail, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update email message
   * @param {EmailApiPutEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public putEmail(requestParameters: EmailApiPutEmailRequest, options?: any) {
    return EmailApiFp(this.configuration)
      .putEmail(requestParameters.id, requestParameters.reqEmail, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send email messages
   * @param {EmailApiSendEmailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public sendEmail(requestParameters: EmailApiSendEmailRequest, options?: any) {
    return EmailApiFp(this.configuration)
      .sendEmail(
        requestParameters.id,
        requestParameters.reqEmailSender,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send email message directly without uuid
   * @param {EmailApiSendEmailDirectlyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public sendEmailDirectly(
    requestParameters: EmailApiSendEmailDirectlyRequest,
    options?: any,
  ) {
    return EmailApiFp(this.configuration)
      .sendEmailDirectly(requestParameters.reqEmailDirectlySender, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Обработка события завершения ассессмента.
     * @summary Finish assessment event
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assessmentFinish: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("assessmentFinish", "assessmentId", assessmentId);
      const localVarPath =
        `/api/v1/event/assessmentFinish/{assessment_id}`.replace(
          `{${"assessment_id"}}`,
          encodeURIComponent(String(assessmentId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration);
  return {
    /**
     * Обработка события завершения ассессмента.
     * @summary Finish assessment event
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assessmentFinish(
      assessmentId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.assessmentFinish(assessmentId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EventApiFp(configuration);
  return {
    /**
     * Обработка события завершения ассессмента.
     * @summary Finish assessment event
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assessmentFinish(assessmentId: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .assessmentFinish(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for assessmentFinish operation in EventApi.
 * @export
 * @interface EventApiAssessmentFinishRequest
 */
export interface EventApiAssessmentFinishRequest {
  /**
   *
   * @type {string}
   * @memberof EventApiAssessmentFinish
   */
  readonly assessmentId: string;
}

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
  /**
   * Обработка события завершения ассессмента.
   * @summary Finish assessment event
   * @param {EventApiAssessmentFinishRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public assessmentFinish(
    requestParameters: EventApiAssessmentFinishRequest,
    options?: any,
  ) {
    return EventApiFp(this.configuration)
      .assessmentFinish(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotifyApi - axios parameter creator
 * @export
 */
export const NotifyApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get list of notifications
     * @param {string} [whenFrom] Filter messages by &#x60;when&#x60; field, from.
     * @param {string} [whenTo] Filter messages by &#x60;when&#x60; field, to.
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort countries by field &#x60;when&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifyList: async (
      whenFrom?: string,
      whenTo?: string,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/notify/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (whenFrom !== undefined) {
        localVarQueryParameter["whenFrom"] =
          (whenFrom as any) instanceof Date
            ? (whenFrom as any).toISOString()
            : whenFrom;
      }

      if (whenTo !== undefined) {
        localVarQueryParameter["whenTo"] =
          (whenTo as any) instanceof Date
            ? (whenTo as any).toISOString()
            : whenTo;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Broadcast message
     * @param {ReqNotifyAll} reqNotifyAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyAll: async (
      reqNotifyAll: ReqNotifyAll,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reqNotifyAll' is not null or undefined
      assertParamExists("postNotifyAll", "reqNotifyAll", reqNotifyAll);
      const localVarPath = `/api/v1/notify/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqNotifyAll,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Personal messages
     * @param {ReqNotifyNoConfirmationReference} reqNotifyNoConfirmationReference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyNoConfirmationRelative: async (
      reqNotifyNoConfirmationReference: ReqNotifyNoConfirmationReference,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reqNotifyNoConfirmationReference' is not null or undefined
      assertParamExists(
        "postNotifyNoConfirmationRelative",
        "reqNotifyNoConfirmationReference",
        reqNotifyNoConfirmationReference,
      );
      const localVarPath = `/api/v1/notify/no-confirmation-reference-relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqNotifyNoConfirmationReference,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Personal messages
     * @param {ReqNotifyPersonal} reqNotifyPersonal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyPersonal: async (
      reqNotifyPersonal: ReqNotifyPersonal,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reqNotifyPersonal' is not null or undefined
      assertParamExists(
        "postNotifyPersonal",
        "reqNotifyPersonal",
        reqNotifyPersonal,
      );
      const localVarPath = `/api/v1/notify/personal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqNotifyPersonal,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resend personal messages
     * @param {string} mid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyResend: async (
      mid: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mid' is not null or undefined
      assertParamExists("postNotifyResend", "mid", mid);
      const localVarPath = `/api/v1/notify/{mid}/resend`.replace(
        `{${"mid"}}`,
        encodeURIComponent(String(mid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotifyApi - functional programming interface
 * @export
 */
export const NotifyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotifyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get list of notifications
     * @param {string} [whenFrom] Filter messages by &#x60;when&#x60; field, from.
     * @param {string} [whenTo] Filter messages by &#x60;when&#x60; field, to.
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort countries by field &#x60;when&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotifyList(
      whenFrom?: string,
      whenTo?: string,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseListNotifies>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifyList(
        whenFrom,
        whenTo,
        pageNum,
        pageSize,
        orderBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Broadcast message
     * @param {ReqNotifyAll} reqNotifyAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNotifyAll(
      reqNotifyAll: ReqNotifyAll,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postNotifyAll(
        reqNotifyAll,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Personal messages
     * @param {ReqNotifyNoConfirmationReference} reqNotifyNoConfirmationReference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNotifyNoConfirmationRelative(
      reqNotifyNoConfirmationReference: ReqNotifyNoConfirmationReference,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseNotify>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postNotifyNoConfirmationRelative(
          reqNotifyNoConfirmationReference,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Personal messages
     * @param {ReqNotifyPersonal} reqNotifyPersonal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNotifyPersonal(
      reqNotifyPersonal: ReqNotifyPersonal,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseNotify>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postNotifyPersonal(
          reqNotifyPersonal,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Resend personal messages
     * @param {string} mid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNotifyResend(
      mid: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseNotify>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postNotifyResend(mid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NotifyApi - factory interface
 * @export
 */
export const NotifyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotifyApiFp(configuration);
  return {
    /**
     *
     * @summary Get list of notifications
     * @param {string} [whenFrom] Filter messages by &#x60;when&#x60; field, from.
     * @param {string} [whenTo] Filter messages by &#x60;when&#x60; field, to.
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort countries by field &#x60;when&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifyList(
      whenFrom?: string,
      whenTo?: string,
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseListNotifies> {
      return localVarFp
        .getNotifyList(whenFrom, whenTo, pageNum, pageSize, orderBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Broadcast message
     * @param {ReqNotifyAll} reqNotifyAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyAll(
      reqNotifyAll: ReqNotifyAll,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .postNotifyAll(reqNotifyAll, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Personal messages
     * @param {ReqNotifyNoConfirmationReference} reqNotifyNoConfirmationReference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyNoConfirmationRelative(
      reqNotifyNoConfirmationReference: ReqNotifyNoConfirmationReference,
      options?: any,
    ): AxiosPromise<ResponseNotify> {
      return localVarFp
        .postNotifyNoConfirmationRelative(
          reqNotifyNoConfirmationReference,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Personal messages
     * @param {ReqNotifyPersonal} reqNotifyPersonal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyPersonal(
      reqNotifyPersonal: ReqNotifyPersonal,
      options?: any,
    ): AxiosPromise<ResponseNotify> {
      return localVarFp
        .postNotifyPersonal(reqNotifyPersonal, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resend personal messages
     * @param {string} mid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotifyResend(mid: string, options?: any): AxiosPromise<ResponseNotify> {
      return localVarFp
        .postNotifyResend(mid, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getNotifyList operation in NotifyApi.
 * @export
 * @interface NotifyApiGetNotifyListRequest
 */
export interface NotifyApiGetNotifyListRequest {
  /**
   * Filter messages by &#x60;when&#x60; field, from.
   * @type {string}
   * @memberof NotifyApiGetNotifyList
   */
  readonly whenFrom?: string;

  /**
   * Filter messages by &#x60;when&#x60; field, to.
   * @type {string}
   * @memberof NotifyApiGetNotifyList
   */
  readonly whenTo?: string;

  /**
   * Page Number, one-based
   * @type {number}
   * @memberof NotifyApiGetNotifyList
   */
  readonly pageNum?: number;

  /**
   * Page Size [1..1000], default 10
   * @type {number}
   * @memberof NotifyApiGetNotifyList
   */
  readonly pageSize?: number;

  /**
   * Sort countries by field &#x60;when&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
   * @type {Array<string>}
   * @memberof NotifyApiGetNotifyList
   */
  readonly orderBy?: Array<string>;
}

/**
 * Request parameters for postNotifyAll operation in NotifyApi.
 * @export
 * @interface NotifyApiPostNotifyAllRequest
 */
export interface NotifyApiPostNotifyAllRequest {
  /**
   *
   * @type {ReqNotifyAll}
   * @memberof NotifyApiPostNotifyAll
   */
  readonly reqNotifyAll: ReqNotifyAll;
}

/**
 * Request parameters for postNotifyNoConfirmationRelative operation in NotifyApi.
 * @export
 * @interface NotifyApiPostNotifyNoConfirmationRelativeRequest
 */
export interface NotifyApiPostNotifyNoConfirmationRelativeRequest {
  /**
   *
   * @type {ReqNotifyNoConfirmationReference}
   * @memberof NotifyApiPostNotifyNoConfirmationRelative
   */
  readonly reqNotifyNoConfirmationReference: ReqNotifyNoConfirmationReference;
}

/**
 * Request parameters for postNotifyPersonal operation in NotifyApi.
 * @export
 * @interface NotifyApiPostNotifyPersonalRequest
 */
export interface NotifyApiPostNotifyPersonalRequest {
  /**
   *
   * @type {ReqNotifyPersonal}
   * @memberof NotifyApiPostNotifyPersonal
   */
  readonly reqNotifyPersonal: ReqNotifyPersonal;
}

/**
 * Request parameters for postNotifyResend operation in NotifyApi.
 * @export
 * @interface NotifyApiPostNotifyResendRequest
 */
export interface NotifyApiPostNotifyResendRequest {
  /**
   *
   * @type {string}
   * @memberof NotifyApiPostNotifyResend
   */
  readonly mid: string;
}

/**
 * NotifyApi - object-oriented interface
 * @export
 * @class NotifyApi
 * @extends {BaseAPI}
 */
export class NotifyApi extends BaseAPI {
  /**
   *
   * @summary Get list of notifications
   * @param {NotifyApiGetNotifyListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotifyApi
   */
  public getNotifyList(
    requestParameters: NotifyApiGetNotifyListRequest = {},
    options?: any,
  ) {
    return NotifyApiFp(this.configuration)
      .getNotifyList(
        requestParameters.whenFrom,
        requestParameters.whenTo,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Broadcast message
   * @param {NotifyApiPostNotifyAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotifyApi
   */
  public postNotifyAll(
    requestParameters: NotifyApiPostNotifyAllRequest,
    options?: any,
  ) {
    return NotifyApiFp(this.configuration)
      .postNotifyAll(requestParameters.reqNotifyAll, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Personal messages
   * @param {NotifyApiPostNotifyNoConfirmationRelativeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotifyApi
   */
  public postNotifyNoConfirmationRelative(
    requestParameters: NotifyApiPostNotifyNoConfirmationRelativeRequest,
    options?: any,
  ) {
    return NotifyApiFp(this.configuration)
      .postNotifyNoConfirmationRelative(
        requestParameters.reqNotifyNoConfirmationReference,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Personal messages
   * @param {NotifyApiPostNotifyPersonalRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotifyApi
   */
  public postNotifyPersonal(
    requestParameters: NotifyApiPostNotifyPersonalRequest,
    options?: any,
  ) {
    return NotifyApiFp(this.configuration)
      .postNotifyPersonal(requestParameters.reqNotifyPersonal, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resend personal messages
   * @param {NotifyApiPostNotifyResendRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotifyApi
   */
  public postNotifyResend(
    requestParameters: NotifyApiPostNotifyResendRequest,
    options?: any,
  ) {
    return NotifyApiFp(this.configuration)
      .postNotifyResend(requestParameters.mid, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SmsApi - axios parameter creator
 * @export
 */
export const SmsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete sms messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delSms: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("delSms", "id", id);
      const localVarPath = `/api/v1/sms/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get sms messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSms: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getSms", "id", id);
      const localVarPath = `/api/v1/sms/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list sms history
     * @param {string} id
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSmsHistory: async (
      id: string,
      offset?: number,
      limit?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getSmsHistory", "id", id);
      const localVarPath = `/api/v1/sms/{id}/history`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list sms messages
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort countries by fields &#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSmsList: async (
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/sms/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add sms message
     * @param {ReqSms} reqSms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSms: async (
      reqSms: ReqSms,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reqSms' is not null or undefined
      assertParamExists("postSms", "reqSms", reqSms);
      const localVarPath = `/api/v1/sms/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqSms,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update sms message
     * @param {string} id
     * @param {ReqSms} reqSms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSms: async (
      id: string,
      reqSms: ReqSms,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putSms", "id", id);
      // verify required parameter 'reqSms' is not null or undefined
      assertParamExists("putSms", "reqSms", reqSms);
      const localVarPath = `/api/v1/sms/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqSms,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send sms messages
     * @param {string} id
     * @param {ReqSmsSender} reqSmsSender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms: async (
      id: string,
      reqSmsSender: ReqSmsSender,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("sendSms", "id", id);
      // verify required parameter 'reqSmsSender' is not null or undefined
      assertParamExists("sendSms", "reqSmsSender", reqSmsSender);
      const localVarPath = `/api/v1/sms/{id}/send`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reqSmsSender,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SmsApi - functional programming interface
 * @export
 */
export const SmsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SmsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete sms messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delSms(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delSms(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get sms messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSms(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSms>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSms(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get list sms history
     * @param {string} id
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSmsHistory(
      id: string,
      offset?: number,
      limit?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListMassMsgHistoryRes>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSmsHistory(
        id,
        offset,
        limit,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get list sms messages
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort countries by fields &#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSmsList(
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseListSms>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSmsList(
        pageNum,
        pageSize,
        orderBy,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add sms message
     * @param {ReqSms} reqSms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSms(
      reqSms: ReqSms,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSms>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postSms(
        reqSms,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update sms message
     * @param {string} id
     * @param {ReqSms} reqSms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putSms(
      id: string,
      reqSms: ReqSms,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putSms(
        id,
        reqSms,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send sms messages
     * @param {string} id
     * @param {ReqSmsSender} reqSmsSender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendSms(
      id: string,
      reqSmsSender: ReqSmsSender,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSms>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendSms(
        id,
        reqSmsSender,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SmsApi - factory interface
 * @export
 */
export const SmsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SmsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete sms messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delSms(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .delSms(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get sms messages by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSms(id: string, options?: any): AxiosPromise<ResponseSms> {
      return localVarFp
        .getSms(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list sms history
     * @param {string} id
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSmsHistory(
      id: string,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<ListMassMsgHistoryRes> {
      return localVarFp
        .getSmsHistory(id, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list sms messages
     * @param {number} [pageNum] Page Number, one-based
     * @param {number} [pageSize] Page Size [1..1000], default 10
     * @param {Array<string>} [orderBy] Sort countries by fields &#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSmsList(
      pageNum?: number,
      pageSize?: number,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<ResponseListSms> {
      return localVarFp
        .getSmsList(pageNum, pageSize, orderBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add sms message
     * @param {ReqSms} reqSms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSms(reqSms: ReqSms, options?: any): AxiosPromise<ResponseSms> {
      return localVarFp
        .postSms(reqSms, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update sms message
     * @param {string} id
     * @param {ReqSms} reqSms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSms(id: string, reqSms: ReqSms, options?: any): AxiosPromise<void> {
      return localVarFp
        .putSms(id, reqSms, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send sms messages
     * @param {string} id
     * @param {ReqSmsSender} reqSmsSender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms(
      id: string,
      reqSmsSender: ReqSmsSender,
      options?: any,
    ): AxiosPromise<ResponseSms> {
      return localVarFp
        .sendSms(id, reqSmsSender, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for delSms operation in SmsApi.
 * @export
 * @interface SmsApiDelSmsRequest
 */
export interface SmsApiDelSmsRequest {
  /**
   *
   * @type {string}
   * @memberof SmsApiDelSms
   */
  readonly id: string;
}

/**
 * Request parameters for getSms operation in SmsApi.
 * @export
 * @interface SmsApiGetSmsRequest
 */
export interface SmsApiGetSmsRequest {
  /**
   *
   * @type {string}
   * @memberof SmsApiGetSms
   */
  readonly id: string;
}

/**
 * Request parameters for getSmsHistory operation in SmsApi.
 * @export
 * @interface SmsApiGetSmsHistoryRequest
 */
export interface SmsApiGetSmsHistoryRequest {
  /**
   *
   * @type {string}
   * @memberof SmsApiGetSmsHistory
   */
  readonly id: string;

  /**
   *
   * @type {number}
   * @memberof SmsApiGetSmsHistory
   */
  readonly offset?: number;

  /**
   *
   * @type {number}
   * @memberof SmsApiGetSmsHistory
   */
  readonly limit?: number;
}

/**
 * Request parameters for getSmsList operation in SmsApi.
 * @export
 * @interface SmsApiGetSmsListRequest
 */
export interface SmsApiGetSmsListRequest {
  /**
   * Page Number, one-based
   * @type {number}
   * @memberof SmsApiGetSmsList
   */
  readonly pageNum?: number;

  /**
   * Page Size [1..1000], default 10
   * @type {number}
   * @memberof SmsApiGetSmsList
   */
  readonly pageSize?: number;

  /**
   * Sort countries by fields &#x60;text&#x60;/&#x60;sent&#x60; with &#x60;+&#x60; or none prefix to ASC, with &#x60;-&#x60; prefix to DESC.
   * @type {Array<string>}
   * @memberof SmsApiGetSmsList
   */
  readonly orderBy?: Array<string>;
}

/**
 * Request parameters for postSms operation in SmsApi.
 * @export
 * @interface SmsApiPostSmsRequest
 */
export interface SmsApiPostSmsRequest {
  /**
   *
   * @type {ReqSms}
   * @memberof SmsApiPostSms
   */
  readonly reqSms: ReqSms;
}

/**
 * Request parameters for putSms operation in SmsApi.
 * @export
 * @interface SmsApiPutSmsRequest
 */
export interface SmsApiPutSmsRequest {
  /**
   *
   * @type {string}
   * @memberof SmsApiPutSms
   */
  readonly id: string;

  /**
   *
   * @type {ReqSms}
   * @memberof SmsApiPutSms
   */
  readonly reqSms: ReqSms;
}

/**
 * Request parameters for sendSms operation in SmsApi.
 * @export
 * @interface SmsApiSendSmsRequest
 */
export interface SmsApiSendSmsRequest {
  /**
   *
   * @type {string}
   * @memberof SmsApiSendSms
   */
  readonly id: string;

  /**
   *
   * @type {ReqSmsSender}
   * @memberof SmsApiSendSms
   */
  readonly reqSmsSender: ReqSmsSender;
}

/**
 * SmsApi - object-oriented interface
 * @export
 * @class SmsApi
 * @extends {BaseAPI}
 */
export class SmsApi extends BaseAPI {
  /**
   *
   * @summary Delete sms messages by id
   * @param {SmsApiDelSmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public delSms(requestParameters: SmsApiDelSmsRequest, options?: any) {
    return SmsApiFp(this.configuration)
      .delSms(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get sms messages by id
   * @param {SmsApiGetSmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public getSms(requestParameters: SmsApiGetSmsRequest, options?: any) {
    return SmsApiFp(this.configuration)
      .getSms(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list sms history
   * @param {SmsApiGetSmsHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public getSmsHistory(
    requestParameters: SmsApiGetSmsHistoryRequest,
    options?: any,
  ) {
    return SmsApiFp(this.configuration)
      .getSmsHistory(
        requestParameters.id,
        requestParameters.offset,
        requestParameters.limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list sms messages
   * @param {SmsApiGetSmsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public getSmsList(
    requestParameters: SmsApiGetSmsListRequest = {},
    options?: any,
  ) {
    return SmsApiFp(this.configuration)
      .getSmsList(
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add sms message
   * @param {SmsApiPostSmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public postSms(requestParameters: SmsApiPostSmsRequest, options?: any) {
    return SmsApiFp(this.configuration)
      .postSms(requestParameters.reqSms, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update sms message
   * @param {SmsApiPutSmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public putSms(requestParameters: SmsApiPutSmsRequest, options?: any) {
    return SmsApiFp(this.configuration)
      .putSms(requestParameters.id, requestParameters.reqSms, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send sms messages
   * @param {SmsApiSendSmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public sendSms(requestParameters: SmsApiSendSmsRequest, options?: any) {
    return SmsApiFp(this.configuration)
      .sendSms(requestParameters.id, requestParameters.reqSmsSender, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
