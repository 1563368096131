import { PlayerRating } from "src/shared/api/admin/players";

import { getFormattedDatetime as format, t } from "src/shared/helpers";

import { IDataGridRow } from "src/shared/models/dataGrid";

import { getAvatarUrl } from "src/shared/helpers/pathToUserFile";

import { splitStrings } from "src/shared/helpers/splitStrings";

export const playersToRowsAdapter = (players: PlayerRating[]) => {
  return players.map<IDataGridRow>((player) => {
    let analytics = player.competenceRates.find(
      (i) => i.competence === "analytics",
    );

    let communication = player.competenceRates.find(
      (i) => i.competence === "communication",
    );

    let command = player.competenceRates.find(
      (i) => i.competence === "command",
    );

    let thinking3d = player.competenceRates.find(
      (i) => i.competence === "thinking3d",
    );

    let economics = player.competenceRates.find(
      (i) => i.competence === "economics",
    );

    let jurisprudence = player.competenceRates.find(
      (i) => i.competence === "jurisprudence",
    );

    let analyticsTenacity = player.competenceRates.find(
      (i) => i.competence === "analyticsTenacity",
    );

    let communicationTenacity = player.competenceRates.find(
      (i) => i.competence === "communicationTenacity",
    );

    let commandTenacity = player.competenceRates.find(
      (i) => i.competence === "commandTenacity",
    );

    let thinking3dTenacity = player.competenceRates.find(
      (i) => i.competence === "thinking3dTenacity",
    );

    let economicsTenacity = player.competenceRates.find(
      (i) => i.competence === "economicsTenacity",
    );

    let jurisprudenceTenacity = player.competenceRates.find(
      (i) => i.competence === "jurisprudenceTenacity",
    );

    const fullName = splitStrings([player.firstName, player.lastName]);

    return {
      id: player.playerId,
      gPlace: player.gPlace,
      firstName: player.firstName,
      lastName: player.lastName,
      fullName,
      phone: player.phone,
      lastPlay: format(player.lastPlay),
      lastActivity: format(player.lastActivity),
      created: format(player.createdAt),
      avatarProps: {
        alt: fullName,
        fullName,
        customPath: getAvatarUrl(player.playerId),
        userId: player.playerId,
        link: `/ratings/rating/${player.playerId}`,
      },
      gRate: player.gRate,
      selected: false,
      analytics: analytics?.rate,
      communication: communication?.rate,
      command: command?.rate,
      thinking3d: thinking3d?.rate,
      economics: economics?.rate,
      jurisprudence: jurisprudence?.rate,
      desiredStudyPlace: player.desiredStudyPlace
        ? t(
            `userForm.select.desiredStudyPlace.${player.desiredStudyPlace}`,
            player.desiredStudyPlace,
          )
        : null,
      gender: player.gender,
      email: player.email,
      birthdate: player.birthdate,
      country: player.country,
      region: player.region,
      district: player.district,
      school: player.school,
      locality: player.locality,
      studyWorkPlace: player.studyWorkPlace,
      desiredSpecialty: player.desiredSpecialty,
      analyticsTenacity: analyticsTenacity?.rate,
      communicationTenacity: communicationTenacity?.rate,
      commandTenacity: commandTenacity?.rate,
      thinking3dTenacity: thinking3dTenacity?.rate,
      economicsTenacity: economicsTenacity?.rate,
      jurisprudenceTenacity: jurisprudenceTenacity?.rate,
    };
  });
};
