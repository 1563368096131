import { combine, sample } from "effector";

import { IDataGridColumn, IDataGridRow } from "src/shared/models/dataGrid";

import { $currentLanguage } from "src/shared/helpers";

import { playersToRowsAdapter } from "../lib/playersToRowsAdapter";

import {
  $isLoading as $isLoadingPlayers,
  $pagination,
  $players,
  $queryParams,
  playersDomain,
} from "./players";
import { $invitedPlayers } from "./invited-players";
import { invitedPlayersToRowsAdapter } from "../lib/invitedPlayersToRowsAdapter";
import {
  $sessionPlayerChanged,
  $sessionTeams,
} from "../../assessment-players/model/assessment-players";
import { generateInviteColumns } from "../lib/generateInviteColumns";

// Domains

const dataGrid = playersDomain.createDomain();

// Stores

export const $rows = dataGrid.createStore<IDataGridRow[]>([]);
export const $columns = dataGrid.createStore<IDataGridColumn[]>([]);

export const $isLoading = $isLoadingPlayers.map(Boolean);

export const $invitedRows = dataGrid.createStore<IDataGridRow[]>([]);

// Logic

sample({
  clock: combine($players, $pagination, $currentLanguage, playersToRowsAdapter),
  fn: (rows) => rows ?? [],
  target: $rows,
});

sample({
  clock: combine(
    $invitedPlayers,
    $pagination,
    $currentLanguage,
    $sessionPlayerChanged,
  ),
  source: $sessionTeams,
  fn: (teams, [players]) => {
    const updatedPlayers = teams
      .flatMap((value) => value.players)
      .filter(Boolean);

    return invitedPlayersToRowsAdapter(players, teams, updatedPlayers);
  },
  target: $invitedRows,
});

sample({
  clock: combine($queryParams, $currentLanguage, (queryParams, _) =>
    generateInviteColumns(queryParams),
  ),
  target: $columns,
});
