import { createEffect, createEvent, createStore, sample } from "effector";
import { ILvlUpState, IRequestLvlUp } from "./types";
import { ERequestStatus } from "src/shared/store/types";
import { LevelUpResDto } from "src/generated/game";
import { postLevelUp } from "src/shared/api/public/lvl-up";
import { openResponseErrorPopup } from "src/shared/components";

// Stores

export const $lvlUp = createStore<ILvlUpState>({
  info: null,
  status: ERequestStatus.NEVER,
});

// Events

export const requestLvlUp = createEvent<IRequestLvlUp>();
export const successLvlUp = createEvent<LevelUpResDto>();
export const failureLvlUp = createEvent();

// Effects

export const fetchLvlUpFx = createEffect(postLevelUp);

// Logic

$lvlUp.on(failureLvlUp, (state) => ({
  ...state,
  status: ERequestStatus.ERROR,
}));

$lvlUp.on(requestLvlUp, (state) => ({
  ...state,
  status: ERequestStatus.LOADING,
}));

$lvlUp.on(successLvlUp, (state, payload) => ({
  info: { ...payload },
  status: ERequestStatus.LOADED,
}));

sample({
  clock: fetchLvlUpFx.fail,
  target: failureLvlUp,
});

requestLvlUp.watch(({ locale, competence, callback }) => {
  fetchLvlUpFx({ locale, competence })
    .then((res) => {
      successLvlUp(res);
      callback?.(res);
    })
    .catch((err) => {
      console.error(err);
      openResponseErrorPopup(err?.response?.data?.err?.msg);
    });
});
