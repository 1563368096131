import {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";

import classNames from "classnames";
import { format as formatDate } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  BaseBox,
  BaseButton,
  EmptyBlock,
  PlayerShortStat,
  TextWithIcon,
} from "../../../shared/components";

import { requestProfileCompetence } from "../../../shared/store/ducks/profile-competence/actionCreators";
import {
  selectProfileCompetenceItem,
  selectProfileCompetenceStatus,
} from "../../../shared/store/ducks/profile-competence/selectors";
import { ERequestStatus } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import "./rating-page.scss";
import {
  cleanSidebarInformation,
  requestSidebarInformationGet,
} from "../../../shared/store/ducks/sidebar-information/actionCreators";
import { selectSidebarInformationStatusGet } from "../../../shared/store/ducks/sidebar-information/selectors";
import useClient from "../../../shared/hooks/useClient";

import { formatPhone } from "../../../shared/helpers/phone";
import { useMediaQuery } from "../../../shared/hooks/useMediaQuery";
import UserComments from "../../../shared/components/user-comments/user-comments";
import { $keycloak } from "../../../entities/public/keycloak/model";
import {
  $subjectInfoRequestStatus,
  $subjectUser,
  cleanUserInfoAdmin,
  requestGetUserInfoAdmin,
} from "../../users-control/user-info-page/lib/user-info-page";
import { useUnit } from "effector-react";

interface IInfoList {
  title: string;
  description?: string | ReactElement;
}

interface Props {}

export const RatingPage: FC<Props> = (): ReactElement => {
  const [firstTry, setFirstTry] = useState<boolean>(true);

  const keycloak = useUnit($keycloak);

  const { t } = useTranslation();
  const { isMobile, isTablet, isLaptop } = useMediaQuery();

  const dispatch = useDispatch();
  const profileInfo = useUnit($subjectUser);
  const profileCompetence = useSelector(selectProfileCompetenceItem);
  const profileInfoStatus = useUnit($subjectInfoRequestStatus);
  const profileCompetenceStatus = useSelector(selectProfileCompetenceStatus);
  const sidebarInformationStatus = useSelector(
    selectSidebarInformationStatusGet,
  );
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { isPlayerClientId, isHrClientId, isAdminClientId } = useClient();

  const isHrOrAdminClinet = isHrClientId || isAdminClientId;

  const [formattedPhone, setFormattedPhone] = useState<string>();

  useEffect(() => {
    const clientId: string | undefined = params.id;
    const isPersonalId: boolean = clientId === keycloak?.subject;

    const competenceRequest = (userId: string): void => {
      dispatch(
        requestProfileCompetence({
          id: userId,
          callback: () => setFirstTry(false),
        }),
      );
    };

    if (isPersonalId) {
      navigate(`/profile/info`);
    } else if (clientId) {
      competenceRequest(clientId);
      requestGetUserInfoAdmin({ id: clientId });
    } else if (!clientId && keycloak?.subject) {
      competenceRequest(keycloak.subject);
      dispatch(requestSidebarInformationGet({}));
    }
  }, [params.id, dispatch, navigate, keycloak?.subject]);

  useEffect(() => {
    return () => {
      if (isHrOrAdminClinet) {
        cleanUserInfoAdmin();
        // dispatch(cleanProfileCompetence());
      }

      if (isPlayerClientId) {
        dispatch(cleanSidebarInformation());
      }
    };
  }, [dispatch, isHrOrAdminClinet, isPlayerClientId]);

  const isProfileCompetenceLoading: boolean =
    profileCompetenceStatus === ERequestStatus.LOADING;

  const isSidebarInformationLoading: boolean =
    sidebarInformationStatus === ERequestStatus.LOADING;

  const isProfileInfoLoading: boolean = isHrOrAdminClinet
    ? profileInfoStatus === ERequestStatus.LOADING
    : false;

  const isPageLoading: boolean =
    firstTry &&
    (isProfileCompetenceLoading ||
      isSidebarInformationLoading ||
      isProfileInfoLoading);

  const isProfileCompetenceError: boolean =
    profileCompetenceStatus === ERequestStatus.ERROR;
  const isProfileInfoError: boolean =
    profileInfoStatus === ERequestStatus.ERROR;

  const isUserNotFound: boolean =
    isProfileCompetenceError &&
    !profileCompetence &&
    (isHrOrAdminClinet ? isProfileInfoError && !profileInfo : true);

  useEffect(() => {
    const phone: string | undefined = profileInfo?.attributes?.phoneNumber?.[0];

    formatPhone(phone).then((newPhone) => {
      setFormattedPhone(newPhone);
    });
  }, [profileInfo?.attributes?.phoneNumber]);

  const userPhoneNumber = useMemo<ReactElement | undefined>(() => {
    if (formattedPhone) {
      const phone = profileInfo?.attributes?.phoneNumber?.[0];

      return (
        <a href={`tel:${phone}`} className="rating-page__list-item-link">
          {formattedPhone}
        </a>
      );
    }
  }, [formattedPhone, profileInfo?.attributes?.phoneNumber]);

  const userEmail = useMemo<ReactElement | undefined>(() => {
    if (profileInfo?.email) {
      return (
        <a
          href={`mailto:${profileInfo.email}`}
          className="rating-page__list-item-link"
        >
          {profileInfo.email}
        </a>
      );
    }
  }, [profileInfo?.email]);

  const userBirthdate = useMemo<string | undefined>(() => {
    if (profileInfo?.attributes?.birthdate?.[0]) {
      return formatDate(
        new Date(profileInfo.attributes.birthdate[0]),
        "dd.MM.yyyy",
      );
    }
  }, [profileInfo?.attributes?.birthdate]);

  const desiredStudyPlace = useMemo<string | undefined>(() => {
    if (profileInfo?.attributes?.desiredStudyPlace?.[0]) {
      return t(
        `userForm.select.desiredStudyPlace.${profileInfo.attributes?.desiredStudyPlace[0]}`,
      );
    }
  }, [profileInfo?.attributes?.desiredStudyPlace, t]);

  const desiredSpecialty =
    profileInfo?.attributes?.desiredStudyPlace?.[0] &&
    !!profileInfo?.attributes?.desiredSpecialty?.length
      ? profileInfo?.attributes?.desiredSpecialty
          .map((item) =>
            t(
              `userForm.multiSelect.${profileInfo?.attributes?.desiredStudyPlace?.[0]}.${item}`,
            ),
          )
          .join(", ")
      : undefined;

  const infoList: IInfoList[] = [];

  if (isHrOrAdminClinet && profileInfo) {
    if (userPhoneNumber) {
      infoList.push({
        title: t("userForm.inputLabel.phoneNumber"),
        description: userPhoneNumber,
      });
    }

    if (userEmail) {
      infoList.push({
        title: t("userForm.inputLabel.email"),
        description: userEmail,
      });
    }

    if (userBirthdate) {
      infoList.push({
        title: t("userForm.inputLabel.birthdate"),
        description: userBirthdate,
      });
    }

    if (profileInfo?.attributes?.locality?.[0]) {
      infoList.push({
        title: t("userForm.inputLabel.locality"),
        description: profileInfo?.attributes?.locality?.[0],
      });
    }

    if (profileInfo?.attributes?.studyWorkPlace?.[0]) {
      infoList.push({
        title: t("userForm.inputLabel.studyWorkPlace"),
        description: profileInfo?.attributes?.studyWorkPlace?.[0],
      });
    }

    if (desiredStudyPlace) {
      infoList.push({
        title: t("userForm.inputLabel.desiredStudyPlace"),
        description: desiredStudyPlace,
      });
    }

    if (desiredSpecialty) {
      infoList.push({
        title: t("userForm.inputLabel.desiredSpecialty"),
        description: desiredSpecialty,
      });
    }
  }

  const isFullWidth = (item: IInfoList): boolean => {
    const isString = typeof item.description === "string";
    if (isString) {
      const description = item.description as string;

      if (isMobile && description.length >= 12) {
        return true;
      }

      if (isTablet && description.length >= 66) {
        return true;
      }

      if (isLaptop && description.length >= 54) {
        return true;
      }
    }

    return false;
  };

  return (
    <PageWrapper
      title={t("profile.title.hr.ratingPage")}
      isLoadingPanel={isPageLoading}
      emptyPanel
      backButton={isHrOrAdminClinet}
      titlePanelSlot={
        <BaseButton
          className="page-wrapper__title-sub-item"
          small
          transparent
          submit={false}
          noPaddingX
          to={`/ratings/rating/${params.id}/sessions-history`}
        >
          <TextWithIcon
            iconName="history-blue"
            iconSize={22}
            hideLabelOnMobile
            label={t("sessionsHistory.title")}
          />
        </BaseButton>
      }
    >
      {!firstTry && (
        <BaseBox className="rating-page">
          {isHrOrAdminClinet && (
            <Fragment>
              {profileCompetence && profileInfo && (
                <PlayerShortStat
                  profileCompetence={profileCompetence}
                  profileInfo={profileInfo}
                />
              )}
              {!!infoList.length && (
                <ul className="rating-page__list">
                  {infoList.map((item) => (
                    <li key={item.title} className="rating-page__list-item">
                      <div className="rating-page__list-item-title">
                        {item.title}
                      </div>
                      <div
                        className={classNames(
                          "rating-page__list-item-description",
                          {
                            "rating-page__list-item-description--full-width":
                              isFullWidth(item),
                          },
                        )}
                      >
                        {item.description}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {profileCompetence && <UserComments user={profileCompetence} />}
            </Fragment>
          )}
        </BaseBox>
      )}
      {isUserNotFound && <EmptyBlock title="Пользователь не найден..." />}
    </PageWrapper>
  );
};
