import { createEffect, createEvent, createStore, sample } from "effector";
import { putUserInfoAdmin } from "../../../../shared/api/keycloak/put-user-info-admin";
import {
  EPopupName,
  openResponseErrorPopup,
} from "../../../../shared/components";
import { openPopupFx } from "../../../../shared/components/base-popup/model";
import {
  ERequestStatus,
  UserInfoInterface,
} from "../../../../shared/store/types";
import { getUserInfoAdmin } from "../../../../shared/api/keycloak/get-user-info-admin";
import { t } from "i18next";

type RequestPutUserInfoAdminActionInterface = {
  payload: {
    id: string;
    data: UserInfoInterface;
  };
};
type RequestGetUserInfoAdminActionInterface = {
  id: string;
};

// Effects
const fetchPutUserInfoAdminFx = createEffect(
  async (params: RequestPutUserInfoAdminActionInterface) => {
    const response = await putUserInfoAdmin(params.payload);
    return response;
  },
);

const fetchGetUserInfoAdminFx = createEffect(
  async (params: RequestGetUserInfoAdminActionInterface) => {
    const response = await getUserInfoAdmin(params);
    return response;
  },
);

// Stores
export const $subjectInfoRequestStatus = createStore<ERequestStatus>(
  ERequestStatus.IDLE,
);

export const $subjectEditRequestStatus = createStore<ERequestStatus>(
  ERequestStatus.IDLE,
);

export const $subjectUser = createStore<UserInfoInterface | null>({
  attributes: {},
});

// Events
export const requestPutUserInfoAdmin =
  createEvent<RequestPutUserInfoAdminActionInterface>();

export const cleanUserInfoAdmin = createEvent();

export const requestGetUserInfoAdmin =
  createEvent<RequestGetUserInfoAdminActionInterface>();

// Samples
sample({
  clock: requestPutUserInfoAdmin,
  target: fetchPutUserInfoAdminFx,
});

sample({
  clock: requestGetUserInfoAdmin,
  target: fetchGetUserInfoAdminFx,
});

sample({
  clock: fetchGetUserInfoAdminFx.done,
  fn: (response) => response.result,
  target: $subjectUser,
});

sample({
  clock: fetchPutUserInfoAdminFx.done,
  fn: () => ({
    name: EPopupName.BASE_MESSAGE_POPUP,
    message: { text: t("popup.baseMessage.dataSaved") },
  }),
  target: openPopupFx,
});

fetchPutUserInfoAdminFx.failData.watch((error: any) => {
  openResponseErrorPopup(error?.response?.data?.errorMessage);
});

$subjectInfoRequestStatus
  .on(fetchGetUserInfoAdminFx, () => ERequestStatus.LOADING)
  .on(fetchGetUserInfoAdminFx.done, () => ERequestStatus.LOADED)
  .on(fetchGetUserInfoAdminFx.failData, () => ERequestStatus.ERROR);

$subjectEditRequestStatus
  .on(fetchPutUserInfoAdminFx, () => ERequestStatus.LOADING)
  .on(fetchPutUserInfoAdminFx.done, () => ERequestStatus.LOADED)
  .on(fetchPutUserInfoAdminFx.failData, () => ERequestStatus.ERROR);

$subjectUser.on(cleanUserInfoAdmin, () => null);

$subjectInfoRequestStatus.on(cleanUserInfoAdmin, () => ERequestStatus.IDLE);
$subjectEditRequestStatus.on(cleanUserInfoAdmin, () => ERequestStatus.IDLE);
