import { useCallback } from "react";
import { FormDataInterface } from "../components";

export const useDeepEqual = () => {
  type NestedObjectValue = Array<[]>;

  type NestedObject = {
    [key: string]: NestedObjectValue;
  };

  const sliceNestedObject = (
    obj: NestedObject,
  ): Record<string, NestedObjectValue> => {
    let result: Record<string, NestedObjectValue> = {};

    for (const key in obj) {
      const objectValue = obj[key];
      switch (!!obj) {
        case typeof objectValue === "object" && !Array.isArray(objectValue):
          const slice = sliceNestedObject(objectValue as any);
          result = { ...result, ...slice };
          break;
        case Array.isArray(objectValue):
          result[key] = objectValue[objectValue.length - 1];
          break;
        default:
          result[key] = objectValue;
      }
    }

    return result;
  };

  const deepEqualLogic = (
    serverValue: any | null,
    draftValue: any | null,
  ): boolean => {
    serverValue = sliceNestedObject(serverValue);
    draftValue = sliceNestedObject(draftValue);

    if (!serverValue || !draftValue) {
      return false;
    }

    for (let key in draftValue) {
      if (serverValue.hasOwnProperty(key) && draftValue.hasOwnProperty(key)) {
        if (
          typeof serverValue[key] === "object" &&
          serverValue[key] !== null &&
          !Array.isArray(serverValue[key])
        ) {
          if (!deepEqualLogic(serverValue[key], draftValue[key])) {
            return true;
          }
        } else if (
          JSON.stringify(serverValue[key]) !== JSON.stringify(draftValue[key])
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const deepEqual = useCallback(
    (
      serverValue: FormDataInterface | null,
      draftValue: FormDataInterface | null,
    ): boolean => {
      return deepEqualLogic(serverValue, draftValue);
    },
    [],
  );

  return deepEqual;
};
