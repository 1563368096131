import { FC, ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUrlState } from "src/shared/hooks/useUrlState";
import isEqual from "lodash/isEqual";

import "../moderation-history.scss";
import { useUnit } from "effector-react";
import { PageWrapper } from "src/shared/components";
import {
  BaseButton,
  BasePanel,
  NavList,
  NavListItem,
  TextWithIcon,
} from "../../../../shared/components";
import { TDataGridDataParams } from "../../../../shared/models";
import {
  getDateForFilter,
  togglePlaceParams,
} from "../../../../shared/helpers";
import {
  ChangePlayerHistoryApiGetChangePlayerHistoryRequest,
  StatusCompanyEnum,
} from "../../../../generated/social";
import {
  $changeRatingHistoryPlayers,
  $isLoadingPlayers,
  fetchChangeRatingHistory,
} from "../../../../entities/admin/histories/model/ratings/ratings-change-history";
import { $pagination } from "../../../../entities/public/countries";
import { StudentEmployeeModerationHistoryTable } from "../../../../shared/components/data-grids/histories/student-employee-moderation-history";
import { useLocation } from "react-router-dom";

interface Props {}

export const StudentEmployeeModerationHistory: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const [urlState, setUrlState] = useUrlState();

  const isLoading = useUnit($isLoadingPlayers);

  const location = useLocation();

  const studentLocation = "/history/moderation/students";

  const moderationHistoryPlayers = useUnit($changeRatingHistoryPlayers);

  const pagination = useUnit($pagination);

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (!isEqual(queryParams, newFilteredParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newFilteredParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstNameAuthor,
      lastNameAuthor,
      firstNamePlayer,
      lastNamePlayer,
      dateOfChangeFrom,
      dateOfChangeTo,
      orderBy,
      pageNum,
      pageSize,
    } = urlState.query;

    const params: ChangePlayerHistoryApiGetChangePlayerHistoryRequest = {
      firstNameAuthor: firstNameAuthor ? firstNameAuthor : undefined,
      lastNameAuthor: lastNameAuthor ? lastNameAuthor : undefined,
      firstNamePlayer: firstNamePlayer ? [firstNamePlayer] : undefined,
      lastNamePlayer: lastNamePlayer ? [lastNamePlayer] : undefined,
      dateOfChangeFrom: getDateForFilter(dateOfChangeFrom),
      dateOfChangeTo: getDateForFilter(dateOfChangeTo),
      oldValue:
        location.pathname === studentLocation
          ? StatusCompanyEnum.CandidateStudent
          : StatusCompanyEnum.CandidateEmployee,
      newValue:
        location.pathname === studentLocation
          ? StatusCompanyEnum.Student
          : StatusCompanyEnum.Employee,
      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [location.pathname, urlState.query]);

  useEffect(() => {
    fetchChangeRatingHistory(pageParams);
  }, [pageParams, location.pathname]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  const historyPageTabLinks: NavListItem[] = [
    {
      title: t("common.students"),
      to: "/history/moderation/students",
      access: true,
    },
    {
      title: t("common.relatives"),
      to: "/history/moderation/relatives",
      access: true,
    },
    {
      title: t("common.employees"),
      to: "/history/moderation/employees",
      access: true,
    },
  ];

  return (
    <div className="moderation-history-page">
      <PageWrapper
        isLightBlueBackground
        isShowContentWhenIsLoading
        isLoadingPanel={isLoading}
        controlPanelSlot={
          <>
            <BasePanel className="moderation-history-page-tabs">
              <NavList level={2} links={historyPageTabLinks} />
            </BasePanel>
            <div className="table__control">
              {filtersLength > 0 && (
                <BaseButton
                  className="table__control-btn"
                  onClick={clearFilterSettings}
                  lightBlue
                >
                  <TextWithIcon
                    label={t("table.filters.reset")}
                    iconName={"cross-blue"}
                  />
                </BaseButton>
              )}
            </div>
          </>
        }
      >
        <div className="moderation-history-page-data-grid-wrapper">
          <StudentEmployeeModerationHistoryTable
            items={moderationHistoryPlayers}
            pagination={pagination}
            loading={isLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            queryParams={urlState.query}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum)}
          />
        </div>
      </PageWrapper>
    </div>
  );
};
