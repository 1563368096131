import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { BaseInnerHtml, DataGrid, EPopupName } from "src/shared/components";

import { getActiveGroup, getOrderByValue } from "src/shared/helpers/data-grids";

import { ITable } from "src/shared/components/data-grids/mailing-lists/types";

import { QueryParams } from "src/shared/api/base";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
} from "../../../models/dataGrid";

import { smsNotificationModel } from "src/entities/admin/notification";
import { openPopup } from "../../base-popup/model";

const { $isLoading, sendSms, $pagination, $smsList } = smsNotificationModel;

export const SmsMailingListsTable: FC<ITable> = ({
  queryParams,
  updateQueryParams,
}): ReactElement => {
  const { t } = useTranslation();

  const isLoading = useUnit($isLoading);

  const pagination = useUnit($pagination);

  const smsList = useUnit($smsList);

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);

  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const onClickResend = useCallback(
    (id: string) =>
      // sendSms({
      //   id,
      //   reqSmsSender: { allPhones: false },
      // }),
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.baseMessage.smsNotAvailable"),
          isError: true,
        },
      }),
    [t],
  );

  const goToPage = (pageNum: number, isReadmore?: boolean) => {
    if (updateQueryParams) {
      const params: QueryParams = { ...queryParams, pageNum: String(pageNum) };

      if (isReadmore) {
        params.mode = "readmore";
      } else {
        delete params.mode;
      }

      updateQueryParams(params);
    }
  };

  const generateColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: t("table.header.content"),
        type: EDataGridColumnType.STRING,
        key: "content",
        showed: true,
        width: 500,
        minWidth: 500,
      },
      {
        title: t("table.header.sent"),
        type: EDataGridColumnType.DATETIME,
        key: "sent",
        showed: true,
        width: 200,
        minWidth: 200,
        controlPanel: {
          title: t("table.header.sent"),
          activeGroupKey: getActiveGroup(
            ["sent", "sentFrom", "sentTo"],
            { all: ["sent", "sentFrom", "sentTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "sentFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.createdFrom || "",
                  isHalf: true,
                },
                {
                  key: "sentTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.createdTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, ["sent", "-sent"]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.date.desc"),
                    value: "-sent",
                  },
                  {
                    title: t("table.filters.date.asc"),
                    value: "sent",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.recipients"),
        type: EDataGridColumnType.STRING,
        key: "recipients",
        showed: true,
        width: 200,
        minWidth: 200,
      },
      {
        title: t("table.header.resend"),
        width: 1,
        minWidth: 1,
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "resultsButtonTitle",
        showed: true,
        noPaddingRight: true,
        buttonProps: {
          primary: true,
          xSmall: true,
          xSmallRounded: true,
          disabled: isLoading,
          onClick(rowId: string) {
            onClickResend(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 0,
          },
          laptop: {
            right: 0,
          },
        },
      },
    ],
    [queryParams, t, onClickResend, isLoading],
  );

  useEffect(() => {
    const rowsItems: IDataGridRow[] = smsList.map((item): IDataGridRow => {
      const clearedText = <BaseInnerHtml description={item.text} />;
      return {
        key: item.id,
        tooltips: { content: { content: clearedText } },
        id: String(item.id),
        content: clearedText,
        sent: item.sent,
        recipients: item.uids.length,
        selected: false,
        resultsButtonTitle: t("table.header.resend"),
      };
    });

    setRows(rowsItems);
  }, [smsList, t]);

  useEffect(() => {
    setColumns(generateColumns);
  }, [generateColumns]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"active-assessment-table"}
      queryParams={queryParams}
      onUpdateSortAndFilters={updateQueryParams}
      loading={isLoading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={(pageNum) => goToPage(pageNum, true)}
    />
  );
};
