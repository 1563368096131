import React, { ChangeEvent, FC, KeyboardEvent, ReactElement } from "react";

import classNames from "classnames";

import { TextWithIcon } from "../..";

import "./input.scss";

import {
  InputHTMLProps,
  TInputTypeAttribute,
} from "../../../models/base-input";

interface Props extends InputHTMLProps {
  type?: TInputTypeAttribute;
  value: string;
  name?: string;
  maxLength?: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  isSmall?: boolean;
  forDarkBg?: boolean;
  required?: boolean;
  placeholder?: string;
  step?: number;
  min?: number | string;
  max?: number | string;
  isContextBtnDisabled?: boolean;
  readOnly?: boolean;
  onRefresh?: () => void;
  onDelete?: () => void;
}

export const InputTemplate: FC<Props> = ({
  type = "text",
  value,
  name,
  maxLength,
  onChange,
  onKeyPress,
  disabled,
  error,
  isSmall,
  forDarkBg,
  required,
  placeholder,
  step,
  min,
  max,
  isContextBtnDisabled,
  readOnly,
  onRefresh,
  onDelete,
  ...otherProps
}): ReactElement => (
  <label className={classNames("field", `field--type_${type}`)}>
    <input
      type={type}
      value={value}
      name={name}
      maxLength={maxLength}
      onChange={onChange}
      onKeyPress={onKeyPress}
      disabled={disabled}
      step={step}
      min={min}
      max={max}
      required={required}
      readOnly={readOnly}
      onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
        e.currentTarget.blur()
      }
      {...otherProps}
      className={classNames("field__input", `field__input--type_${type}`, {
        "field__input--error error-field": error,
        "field__input--size_small": isSmall,
        "field__input--with-btn": onDelete || onRefresh,
        "field__input--for-dark-bg": forDarkBg,
        [`field__input--type_${type}-has-value`]: !!value,
      })}
    />
    <span
      className={classNames("field__label", {
        "field__label--collapsed": value,
        "field__label--for-size_small": isSmall,
        "field__label--with-btn": onDelete || onRefresh,
        "field__label--for-dark-bg": forDarkBg,
        required: required,
      })}
    >
      {placeholder}
    </span>
    {onRefresh && !onDelete && (
      <button
        type="button"
        className="field__refresh-btn"
        onClick={onRefresh}
        disabled={isContextBtnDisabled}
      >
        <TextWithIcon iconName="circle-arrow-blue" iconSize={18} hideLabel />
      </button>
    )}
    {onDelete && !onRefresh && (
      <button
        type="button"
        className="field__delete-btn"
        onClick={onDelete}
        disabled={isContextBtnDisabled}
      >
        <TextWithIcon iconName="cross-red-bold" iconSize={10} hideLabel />
      </button>
    )}
  </label>
);
