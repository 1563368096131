import { $districts, $regions, $schools } from "./dictionaries";

import { $formData } from "./form-data";

import { userFormDomain } from "./domain";

import { $userType } from "./user-type";

// Events

export const resetFormData = userFormDomain.createEvent();

export const resetUserType = userFormDomain.createEvent();

export const resetRegions = userFormDomain.createEvent<undefined | null>();

export const resetDistricts = userFormDomain.createEvent<undefined | null>();

export const resetSchools = userFormDomain.createEvent<undefined | null>();

// Logic

$formData.reset(resetFormData);

$userType.reset(resetUserType);

$regions.reset(resetRegions);

$districts.reset(resetDistricts);

$schools.reset(resetSchools);
