import { stringToArrayStrings } from "src/shared/helpers";

import {
  GetRatingParams,
  QueryParams,
} from "src/shared/api/admin/players/model";
import { ERegEx, regExList } from "../../../../shared/constants/regExList";

export const queryParamsToGetPlayersRatingAdapter = (
  queryParams: QueryParams = {},
): GetRatingParams => {
  return {
    name: queryParams.name ? [queryParams.name] : undefined,
    firstName: queryParams.firstName ? [queryParams.firstName] : undefined,
    lastName: queryParams.lastName ? [queryParams.lastName] : undefined,
    phone: queryParams.phone
      ? [queryParams.phone?.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
      : undefined,
    gPlaceFrom: queryParams.gPlaceFrom
      ? Number(queryParams.gPlaceFrom)
      : undefined,
    gPlaceTo: queryParams.gPlaceTo ? Number(queryParams.gPlaceTo) : undefined,
    gRateFrom: queryParams.gRateFrom
      ? Number(queryParams.gRateFrom)
      : undefined,
    gRateTo: queryParams.gRateTo ? Number(queryParams.gRateTo) : undefined,
    analyticsFrom: queryParams.analyticsFrom
      ? Number(queryParams.analyticsFrom)
      : undefined,
    analyticsTo: queryParams.analyticsTo
      ? Number(queryParams.analyticsTo)
      : undefined,
    commandFrom: queryParams.commandFrom
      ? Number(queryParams.commandFrom)
      : undefined,
    commandTo: queryParams.commandTo
      ? Number(queryParams.commandTo)
      : undefined,
    communicationFrom: queryParams.communicationFrom
      ? Number(queryParams.communicationFrom)
      : undefined,
    communicationTo: queryParams.communicationTo
      ? Number(queryParams.communicationTo)
      : undefined,
    thinking3dFrom: queryParams.thinking3dFrom
      ? Number(queryParams.thinking3dFrom)
      : undefined,
    thinking3dTo: queryParams.thinking3dTo
      ? Number(queryParams.thinking3dTo)
      : undefined,
    economicsFrom: queryParams.economicsFrom
      ? Number(queryParams.economicsFrom)
      : undefined,
    economicsTo: queryParams.economicsTo
      ? Number(queryParams.economicsTo)
      : undefined,
    jurisprudenceFrom: queryParams.jurisprudenceFrom
      ? Number(queryParams.jurisprudenceFrom)
      : undefined,
    jurisprudenceTo: queryParams.jurisprudenceTo
      ? Number(queryParams.jurisprudenceTo)
      : undefined,
    createdFrom: queryParams.createdFrom ? queryParams.createdFrom : undefined,
    createdTo: queryParams.createdTo ? queryParams.createdTo : undefined,
    lastPlayFrom: queryParams.lastPlayFrom
      ? queryParams.lastPlayFrom
      : undefined,
    lastPlayTo: queryParams.lastPlayTo ? queryParams.lastPlayTo : undefined,
    lastActivityFrom: queryParams.lastActivityFrom
      ? queryParams.lastActivityFrom
      : undefined,
    lastActivityTo: queryParams.lastActivityTo
      ? queryParams.lastActivityTo
      : undefined,
    desiredStudyPlace: queryParams.desiredStudyPlace
      ? [queryParams.desiredStudyPlace]
      : undefined,
    orderBy: queryParams.orderBy ? [queryParams.orderBy] : undefined,
    pageNum: queryParams.pageNum ? Number(queryParams.pageNum) : 1,
    pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 50,
    email: stringToArrayStrings(queryParams.email),
    emailFull: queryParams.emailFull
      ? queryParams.emailFull === "true"
      : undefined,
    country: stringToArrayStrings(queryParams.country),
    region: queryParams.region ? [Number(queryParams.region)] : undefined,
    district: queryParams.district ? [Number(queryParams.district)] : undefined,
    school: queryParams.school ? [Number(queryParams.school)] : undefined,
    locality: stringToArrayStrings(queryParams.locality),
    studyWorkPlace: stringToArrayStrings(queryParams.studyWorkPlace),
    desiredSpecialty: stringToArrayStrings(queryParams.desiredSpecialty),
    gender: stringToArrayStrings(queryParams.gender),
    communicationTenacityFrom: queryParams.communicationTenacityFrom
      ? Number(queryParams.communicationTenacityFrom)
      : undefined,
    communicationTenacityTo: queryParams.communicationTenacityTo
      ? Number(queryParams.communicationTenacityTo)
      : undefined,

    analyticsTenacityFrom: queryParams.analyticsTenacityFrom
      ? Number(queryParams.analyticsTenacityFrom)
      : undefined,
    analyticsTenacityTo: queryParams.analyticsTenacityTo
      ? Number(queryParams.analyticsTenacityTo)
      : undefined,

    commandTenacityFrom: queryParams.commandTenacityFrom
      ? Number(queryParams.commandTenacityFrom)
      : undefined,
    commandTenacityTo: queryParams.commandTenacityTo
      ? Number(queryParams.commandTenacityTo)
      : undefined,

    thinking3dTenacityFrom: queryParams.thinking3dTenacityFrom
      ? Number(queryParams.thinking3dTenacityFrom)
      : undefined,
    thinking3dTenacityTo: queryParams.thinking3dTenacityTo
      ? Number(queryParams.thinking3dTenacityTo)
      : undefined,

    economicsTenacityFrom: queryParams.economicsTenacityFrom
      ? Number(queryParams.economicsTenacityFrom)
      : undefined,
    economicsTenacityTo: queryParams.economicsTenacityTo
      ? Number(queryParams.economicsTenacityTow)
      : undefined,

    jurisprudenceTenacityFrom: queryParams.jurisprudenceTenacityFrom
      ? Number(queryParams.jurisprudenceTenacityFrom)
      : undefined,
    jurisprudenceTenacityTo: queryParams.jurisprudenceTenacityTo
      ? Number(queryParams.jurisprudenceTenacityTo)
      : undefined,
    secretCode: queryParams.secretCode
      ? Number(queryParams.secretCode)
      : undefined,
  };
};
