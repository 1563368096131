import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { Join as UserInfoDto } from "src/generated/ws4";

import useClient from "src/shared/hooks/useClient";

import {
  BaseAccordion,
  BaseButton,
  BaseTooltip,
  SelectItem,
  TextWithIcon,
} from "src/shared/components";

import { selectRoomName } from "src/shared/store/ducks/room";

import { PlayersList, SessionTableHead } from "./components";

import { RowDragTypes } from "src/shared/components/data-grid/components/data-grid-body-row";

import styles from "./session-table.module.scss";
import _ from "lodash";
import { UnassignedPlayersList } from "./components/unassigned-players-list/unassigned-players-list";
import { Specialties } from "../../../../../shared/store/types";
import { EPopupName } from "../../../../../shared/components";
import { openPopup } from "../../../../../shared/components/base-popup/model";
import { useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { CreatedBy } from "../../../../../generated/game";
import { ValidateLobbyHelper } from "./components/validate-lobby-helper/validateLobbyHelper";
import { getValidateAssessmentHr } from "../../../../../shared/api/hr/assessments/model/validate-assessment";
import {
  capitalizeFirstLetter,
  splitStrings,
} from "../../../../../shared/helpers";
import { PlayersSearch } from "./components/players-search/players-search";
import { TeamReadinessTooltip } from "../session-tooltips/team-readiness-tooltip/team-readiness-tooltip";
import { TeamTooltip } from "../session-tooltips/team-tooltip/team-tooltip";
import { MembersTooltip } from "../session-tooltips/members-tooltip/members-tooltip";
import { snackbarOpen } from "src/features/public/snackbar";
import { useUnit } from "effector-react";
import { $socketGameV4RoomHrCommander } from "../../../../../shared/api/public/sockets/model/v4";
import { $keycloak } from "../../../../../entities/public/keycloak/model";
import { DropContainer } from "../../../../../shared/components/base-drop-container/base-drop-container";
import { DragItem, DropArea } from "../../../../../shared/constants/dragEnums";
import { useDndScrolling } from "react-dnd-scrolling";

interface DefaultProps {
  isTeam?: boolean;
  isCorp?: boolean;
  isSolo?: boolean;
  items: ITeam[];
  currentPlayer?: string;
  disableSelect?: boolean;
  deletePlayer: (player: UserInfoDto) => void;
  unAllocatePlayers: (
    players: UserInfoDto[],
    popup: "player" | "area" | "team",
  ) => void;
}

interface BaseProps extends DefaultProps {
  onDrop?: never;
  dropValue?: never;
  dropAccept?: never;
}

interface DropProps extends DefaultProps {
  onDrop: () => void;
  dropValue: string[];
  dropAccept?: string;
}

export interface IPlayer extends UserInfoDto {
  isSearchTarget?: boolean;
}

type Props = BaseProps | DropProps;

export interface ITeam {
  teamsRoles?: SelectItem[];
  areaNums?: SelectItem[];
  corpNums?: SelectItem[];
  teamNums?: SelectItem[];
  teamName?: SelectItem;
  teamNum?: number | null;
  corpNum?: number | null;
  roles: SelectItem[];
  players: IPlayer[];
  maxPlayers?: number;
}

export const SessionTable: FC<Props> = ({
  isTeam,
  isCorp,
  items,
  currentPlayer,
  deletePlayer,
  unAllocatePlayers,
  disableSelect,
  isSolo,
}): ReactElement => {
  const storeKeycloak = useUnit($keycloak);

  const { t } = useTranslation();

  const room = useSelector(selectRoomName);

  const userId: string | undefined = storeKeycloak?.subject;

  const { isHrClientId, isPlayerClientId } = useClient();

  const { id: assessmentId } = useParams<{ id: string }>();

  const socketHrRoomCommander = useUnit($socketGameV4RoomHrCommander);

  const isAutoLobbyOrSolo = room.createdBy === CreatedBy.Auto || isSolo;

  const lobbyUrl: string = `${process.env.REACT_APP_DOMAIN}/sessions/session/${assessmentId}`;

  const [isValidateHelpOpened, setIsValidateHelpOpened] =
    useState<boolean>(false);
  const [validateMessage, setValidateMessage] = useState<string>("");

  const [searchQuery, setSearchQuery] = useState("");

  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  const [unassignedSearchedCount, setUnassignedSearchedCount] =
    useState<number>(0);

  const onCopyLink = (): void => {
    snackbarOpen({
      text: t("popup.baseMessage.copied"),
      visible: true,
      type: "success",
      verticalCentered: true,
    });
  };

  const { id: sessionId } = useParams<{ id: string }>();

  const onChangeHandlerPlayer = (
    role: string,
    currentRole: string,
    userId: string,
  ): void => {
    if (!isHrClientId) {
      return;
    }

    let areaNum;
    // TODO: Временное решение для CEO
    if (currentRole === Specialties.CEO) {
      areaNum = 1;
    }
    if (role === Specialties.CEO) {
      areaNum = null;
    }
    if (room.assessmentId) {
      socketHrRoomCommander?.role({
        aid: room.assessmentId,
        pid: userId,
        role,
        areaNum: areaNum,
      });
    }
  };

  const isPlayerTeam = useCallback(
    (group: ITeam): string | undefined => {
      if (isPlayerClientId) {
        return (
          group?.players.find((item: UserInfoDto) => item.pid === userId) &&
          ` (${t("common.my")})`
        );
      }
    },
    [isPlayerClientId, userId, t],
  );

  let AREA_COUNT = room.maxAreas;

  let TEAM_PLAYERS_COUNT = 5;

  let isValidAreaOrTeam = useCallback(
    (players: UserInfoDto[], areaNum?: number): boolean => {
      let playersList = players;
      if (isCorp && areaNum) {
        const groupedPlayersByAreaNum = _.groupBy(
          players.filter((item: UserInfoDto) => item.role !== Specialties.CEO),
          "areaNum",
        );
        playersList = groupedPlayersByAreaNum[areaNum];
      }
      if (playersList) {
        const rolesOfPlayersInTeam = playersList.map(
          (player: UserInfoDto) => player.role,
        );

        const isGroupHasDuplicateRole =
          _.uniq(rolesOfPlayersInTeam).length !== rolesOfPlayersInTeam.length;

        return (
          !isGroupHasDuplicateRole && playersList.length === TEAM_PLAYERS_COUNT
        );
      } else {
        return false;
      }
    },
    [TEAM_PLAYERS_COUNT, isCorp],
  );

  const isValidCorpTeam = useCallback(
    (players: UserInfoDto[], areaNumList: number[]): boolean => {
      const areaNumState = _.uniq(areaNumList)
        .sort()
        .map((areaNum) => isValidAreaOrTeam(players, areaNum));

      const isCeoInTeam =
        players.filter((player: UserInfoDto) => player.role === Specialties.CEO)
          .length === 1;

      return (
        areaNumState.filter((state: boolean) => state).length === AREA_COUNT &&
        isCeoInTeam
      );
    },
    [AREA_COUNT, isValidAreaOrTeam],
  );

  const onValidate = async () => {
    if (!isValidateHelpOpened && assessmentId) {
      const message = await getValidateAssessmentHr({
        assessmentId: assessmentId,
      });
      if (message.response?.status === 500) {
        setValidateMessage(
          capitalizeFirstLetter(message.response.data.err.msg).replaceAll(
            " ",
            "",
          ),
        );
      } else {
        setValidateMessage("");
      }
    }
    setIsValidateHelpOpened((prev) => !prev);
  };

  const unassignedPlayersList: IPlayer[] = useMemo(() => {
    const unassignedPlayers = room.players.filter((player) => {
      return !player.isDistributed;
    });

    return unassignedPlayers.map((player) => {
      const fullName = splitStrings([player.firstname, player.lastname]);

      return {
        ...player,
        isSearchTarget: searchQuery
          ? fullName.toLowerCase().includes(searchQuery.toLowerCase())
          : undefined,
      };
    });
  }, [room.players, searchQuery]);

  useEffect(() => {
    setUnassignedSearchedCount(
      unassignedPlayersList.filter((player) => player.isSearchTarget).length,
    );
  }, [searchQuery]);

  const isSessionWithUnassignedPlayers =
    room.createdBy === CreatedBy.User && !isSolo;

  const teamsCount =
    Number(room.maxTeamsByName?.nursery.maxTeams) +
    Number(room.maxTeamsByName?.shop.maxTeams);

  const sortedTeams =
    items &&
    _.sortBy(items, isTeam ? "teamNum" : "corpNum", (a, b) => {
      if (isTeam && a.teamNum) {
        return a.teamNum - b;
      }
      if (isCorp && a.corpNum) {
        return a.corpNum - b;
      }
    });

  const displayTeams = sortedTeams.filter(
    (team) =>
      team.players.filter((player) => {
        const isSearchTarget = splitStrings([
          player.firstname,
          player.lastname,
        ]).includes(searchQuery);

        player.isSearchTarget = isSearchTarget;
        return isSearchTarget;
      }).length || !searchQuery,
  );

  const areaNumList: number[] = [];

  const teamReadinessStatus: boolean[] = [];

  let lobbyIsReadyToStart = false;

  sortedTeams.forEach((group: ITeam) => {
    group.players.forEach((player: UserInfoDto) => {
      if (player.areaNum) {
        areaNumList.push(player.areaNum);
      }
    });

    if (room.validate_players) {
      if (isCorp) {
        teamReadinessStatus.push(isValidCorpTeam(group.players, areaNumList));
      } else {
        teamReadinessStatus.push(isValidAreaOrTeam(group.players));
      }

      lobbyIsReadyToStart = !teamReadinessStatus.includes(false);
    } else {
      lobbyIsReadyToStart = true;
    }
  });

  const openCloseSessionPopup = () => {
    openPopup({ name: EPopupName.FORCE_TERMINATE });
  };

  const readyTeams = items.reduce((total, current): number => {
    if (isCorp) {
      return total + (isValidCorpTeam(current.players, areaNumList) ? 1 : 0);
    }

    return total + (isValidAreaOrTeam(current.players) ? 1 : 0);
  }, 0);

  const teamsTooltip = useRef<HTMLSpanElement | null>(null);

  useRef<HTMLSpanElement | null>(null);

  const kickUndistributedPlayers = (event: React.FormEvent) => {
    event.preventDefault();

    openPopup({
      name: EPopupName.KICK_UNDISTRIBUTED_PLAYERS,
    });
  };

  return (
    <div
      className={classNames(styles.table, {
        [styles.unassigned]: isSessionWithUnassignedPlayers,
      })}
    >
      {!isSessionWithUnassignedPlayers && <SessionTableHead isSolo={isSolo} />}
      <div className={styles.section}>
        {isSessionWithUnassignedPlayers && (
          <header className={styles.sectionHeader}>
            <div>
              <span>{t("sessions.session.teams")}</span>
              <span
                className={`tooltip-wrapper ${styles.listLength}`}
                ref={teamsTooltip}
              >
                {`(${readyTeams}/${teamsCount})`}
                <BaseTooltip tooltipToggleRef={teamsTooltip}>
                  <TeamTooltip
                    room={room}
                    isSoloGameType={!isTeam && !isCorp}
                  />
                </BaseTooltip>
              </span>
            </div>

            <div>
              <BaseButton
                outline
                className={styles.headerButton}
                disabled
                onClick={() => {
                  return null;
                }}
              >
                {t("assessment.rooms.buttonFillAutomate")}
              </BaseButton>
            </div>

            {room.validate_players && (
              <div>
                <BaseButton lightPurple xSmallRounded onClick={onValidate}>
                  ?
                </BaseButton>

                <ValidateLobbyHelper
                  onClose={() => {
                    setIsValidateHelpOpened(false);
                  }}
                  message={validateMessage}
                  isMenuOpened={isValidateHelpOpened}
                />
              </div>
            )}
          </header>
        )}

        <div
          className={classNames(styles.teamsList, {
            [styles.empty]: !displayTeams.length && searchQuery,
          })}
        >
          {!displayTeams.length && searchQuery && (
            <p>
              {t("assessmentsControl.popup.assessmentResults.empty.notFound")}
            </p>
          )}

          {displayTeams.map((group: ITeam, groupIndex: number) => {
            const areaNumList: number[] = [];
            group.areaNums?.forEach((areaNum) => {
              areaNumList.push(Number(areaNum.value));
            });

            return (
              <div key={groupIndex}>
                {isTeam && !isAutoLobbyOrSolo && (
                  <DropContainer
                    dropArea={DropArea.TEAM}
                    dropData={{
                      teamNum: group.teamNum,
                      teamName: group.teamName?.value,
                      isTeam: true,
                    }}
                    element={({ isOver, dropRef }) => (
                      <BaseAccordion
                        fullWidth
                        arrowOnLeft
                        roundTitle
                        isOver={isOver}
                        containerRef={dropRef}
                        overrideActiveState={
                          group.players.find(
                            (player) => player.isSearchTarget,
                          ) && !!searchQuery
                        }
                        className={classNames(styles.teamAccordion, {
                          [styles.empty]: !group.players.length,
                        })}
                        headerTheme={
                          isHrClientId && group.players.length
                            ? isValidAreaOrTeam(group.players)
                              ? "light-green"
                              : "light-red"
                            : "dark"
                        }
                        headerSlot={
                          <div className={styles.accordionHeaderContent}>
                            {group.teamName?.label} №{group.teamNum}
                            <span className={styles.listLength}>
                              {`(${group.players.length}/${TEAM_PLAYERS_COUNT})`}
                            </span>
                            {isPlayerTeam(group)}
                            <BaseButton
                              className={styles.accordionHeaderCross}
                              disabled={!group.players.length}
                              onClick={(e) => {
                                e.stopPropagation();
                                unAllocatePlayers(group.players, "team");
                              }}
                            >
                              <TextWithIcon iconName="cross-red" hideLabel />
                            </BaseButton>
                          </div>
                        }
                      >
                        <PlayersList
                          group={group}
                          isTeam={isTeam}
                          currentPlayer={currentPlayer}
                          disableSelect={disableSelect}
                          itemsCount={items.length}
                          onChangeRole={onChangeHandlerPlayer}
                          unAllocatePlayers={unAllocatePlayers}
                          deletePlayer={deletePlayer}
                          teams={items}
                        />
                      </BaseAccordion>
                    )}
                  />
                )}

                {isCorp && !isAutoLobbyOrSolo && (
                  <DropContainer
                    dropArea={DropArea.TEAM}
                    dropData={{
                      corpNum: group.corpNum,
                      teamName: group.teamName?.value,
                    }}
                    element={({ isOver, dropRef, didDrop }) => (
                      <BaseAccordion
                        fullWidth
                        arrowOnLeft
                        roundTitle
                        className={styles.teamAccordion}
                        isOver={isOver}
                        overrideActiveState={
                          (group.players.find(
                            (player) => player.isSearchTarget,
                          ) &&
                            !!searchQuery) ||
                          didDrop
                        }
                        headerTheme={
                          isHrClientId && group.players.length
                            ? isValidCorpTeam(group.players, areaNumList)
                              ? "light-green"
                              : "light-red"
                            : "dark"
                        }
                        headerSlot={
                          <div
                            ref={dropRef}
                            className={styles.accordionHeaderContent}
                          >
                            {group.teamName?.label} №{group.corpNum}
                            <TeamReadinessTooltip
                              group={group}
                              corpNum={group.corpNum ?? undefined}
                            />
                            <BaseButton
                              className={styles.accordionHeaderCross}
                              disabled={!group.players.length}
                              onClick={(e) => {
                                e.stopPropagation();
                                unAllocatePlayers(group.players, "team");
                              }}
                            >
                              <TextWithIcon iconName="cross-red" hideLabel />
                            </BaseButton>
                          </div>
                        }
                      >
                        <div className={styles.corpAccordionContent}>
                          <DropContainer
                            dropArea={DropArea.CEO}
                            dropData={{
                              corpNum: group.corpNum,
                              teamName: group.teamName?.value,
                            }}
                            element={({ isOver, dropRef }) => (
                              <PlayersList
                                group={group}
                                isTeam={isTeam}
                                isCEO={true}
                                isOver={isOver}
                                dropRef={dropRef}
                                isCorp={isCorp}
                                onChangeRole={onChangeHandlerPlayer}
                                currentPlayer={currentPlayer}
                                disableSelect={disableSelect}
                                itemsCount={items.length}
                                deletePlayer={deletePlayer}
                                unAllocatePlayers={unAllocatePlayers}
                                teams={items}
                              />
                            )}
                          />

                          {_.uniq(areaNumList)
                            .sort()
                            .map((areaNum) => {
                              const areaPlayers = group.players.filter(
                                (player) => player.areaNum === areaNum,
                              );

                              return (
                                <DropContainer
                                  dropArea={DropArea.CITY}
                                  dropData={{
                                    areaNum: areaNum,
                                    corpNum: group.corpNum,
                                    teamName: group.teamName?.value,
                                  }}
                                  element={({ isOver, dropRef, didDrop }) => (
                                    <BaseAccordion
                                      fullWidth
                                      arrowOnLeft
                                      roundTitle
                                      isOver={isOver}
                                      containerRef={dropRef}
                                      className={styles.teamAccordion}
                                      overrideActiveState={
                                        (areaPlayers.find(
                                          (player) => player.isSearchTarget,
                                        ) &&
                                          !!searchQuery) ||
                                        didDrop
                                      }
                                      headerTheme={
                                        isHrClientId && areaPlayers.length
                                          ? isValidAreaOrTeam(
                                              group.players,
                                              Number(areaNum),
                                            )
                                            ? "light-green"
                                            : "light-red"
                                          : "dark"
                                      }
                                      key={String(areaNum)}
                                      headerSlot={
                                        <div
                                          className={
                                            styles.accordionHeaderContent
                                          }
                                        >
                                          {t("common.area")} {areaNum}
                                          <TeamReadinessTooltip
                                            group={group}
                                            areaNum={areaNum}
                                          />
                                          <BaseButton
                                            className={
                                              styles.accordionHeaderCross
                                            }
                                            disabled={!areaPlayers.length}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              unAllocatePlayers(
                                                areaPlayers,
                                                "area",
                                              );
                                            }}
                                          >
                                            <TextWithIcon
                                              iconName="cross-red"
                                              hideLabel
                                            />
                                          </BaseButton>
                                        </div>
                                      }
                                    >
                                      <PlayersList
                                        group={group}
                                        isTeam={isTeam}
                                        isCorp={isCorp}
                                        areaNum={Number(areaNum)}
                                        currentPlayer={currentPlayer}
                                        disableSelect={disableSelect}
                                        itemsCount={items.length}
                                        deletePlayer={deletePlayer}
                                        unAllocatePlayers={unAllocatePlayers}
                                        onChangeRole={onChangeHandlerPlayer}
                                        teams={items}
                                      />
                                    </BaseAccordion>
                                  )}
                                />
                              );
                            })}
                        </div>
                      </BaseAccordion>
                    )}
                  />
                )}

                {isAutoLobbyOrSolo && (
                  <PlayersList
                    group={group}
                    currentPlayer={currentPlayer}
                    disableSelect={!isSolo || disableSelect}
                    itemsCount={items.length}
                    deletePlayer={deletePlayer}
                    onChangeRole={onChangeHandlerPlayer}
                    teams={items}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>

      {isSessionWithUnassignedPlayers && (
        <div className={styles.section}>
          <header className={styles.sectionHeader}>
            <MembersTooltip
              connected={room.players.length}
              maximum={Number(room.maxPlayers) ?? 0}
            />

            <div className={styles.buttons}>
              <PlayersSearch
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                active={isSearchActive}
                setActive={setIsSearchActive}
                clearValue={() => {
                  setSearchQuery("");
                  setIsSearchActive(false);
                }}
              />

              <CopyToClipboard text={lobbyUrl} onCopy={onCopyLink}>
                <BaseButton
                  outline
                  className={classNames(styles.headerButton, {
                    [styles.collapsed]: isSearchActive || searchQuery,
                  })}
                >
                  <TextWithIcon
                    iconName={"copy-blue"}
                    iconSize={18}
                    label={t("sessions.session.link")}
                    isBlock
                    hideLabel={isSearchActive || !!searchQuery}
                  />
                </BaseButton>
              </CopyToClipboard>

              <BaseButton
                to={`/sessions/session/${sessionId}/invite`}
                className={classNames(styles.headerButton, {
                  [styles.collapsed]: isSearchActive || searchQuery,
                })}
                outline
              >
                <TextWithIcon
                  iconName={"plus-blue"}
                  iconSize={18}
                  label={t("sessions.session.invite")}
                  isBlock
                  hideLabel={isSearchActive || !!searchQuery}
                />
              </BaseButton>
            </div>
          </header>
          <DropContainer
            acceptType={DragItem.PLAYER}
            dropArea={DropArea.UNASSIGNED_PLAYERS}
            element={({ isOver, dropRef }) => (
              <div
                ref={dropRef}
                className={classNames(styles.table, styles.playersListTable)}
              >
                <header
                  className={classNames(styles.tableHeader, {
                    [styles.isOver]: isOver,
                  })}
                >
                  <div>
                    {t("common.unassignedMembers")}

                    <span className={styles.listLength}>
                      ({unassignedPlayersList.length})
                    </span>
                  </div>

                  <BaseButton
                    className={styles.accordionHeaderCross}
                    xSmall
                    disabled={!unassignedPlayersList.length}
                    onClick={kickUndistributedPlayers}
                  >
                    <TextWithIcon iconName="cross-red" hideLabel />
                  </BaseButton>
                </header>

                <div
                  className={classNames(styles.tableContent, {
                    [styles.empty]: !unassignedSearchedCount && searchQuery,
                  })}
                >
                  {!unassignedSearchedCount && searchQuery ? (
                    <span>
                      {t(
                        "assessmentsControl.popup.assessmentResults.empty.notFound",
                      )}
                    </span>
                  ) : (
                    <UnassignedPlayersList
                      players={unassignedPlayersList}
                      deletePlayer={deletePlayer}
                      isTeam={isTeam}
                      teams={items}
                    />
                  )}
                </div>
              </div>
            )}
          />

          <div className={styles.footerButtons}>
            <BaseButton
              primary
              disabled={!lobbyIsReadyToStart}
              onClick={() =>
                openPopup({ name: EPopupName.CONFIRM_ASSESSMENT_START })
              }
            >
              {t("sessions.session.start")}
            </BaseButton>
            <BaseButton secondary onClick={openCloseSessionPopup}>
              {t("sessions.session.close")}
            </BaseButton>
          </div>
        </div>
      )}
    </div>
  );
};
