import { PlayerRelativeInfoRes } from "../../../../generated/social";
import { ReactElement } from "react";
import cls from "./relatives-tooltip.module.scss";
import { useTranslation } from "react-i18next";

interface RelativesTooltipProps {
  relatives?: PlayerRelativeInfoRes[];
}

export const RelativesTooltip = ({
  relatives,
}: RelativesTooltipProps): ReactElement => {
  const { t } = useTranslation();
  const waitingStatusCount = relatives?.filter(
    (relative) => !relative.confirmed,
  ).length;

  const confirmedStatusCount = relatives?.filter(
    (relative) => relative.confirmed,
  ).length;

  return (
    <div className={cls.wrapper}>
      <div className={cls.title}>{t("common.relatives")}</div>
      <div className={cls.description}>{t("relative.tooltipDesc")}</div>
      <div className={cls.status}>
        <span className={cls.status__name}>{t("common.waiting")}</span>
        <span>{waitingStatusCount}</span>
      </div>
      <div className={cls.status}>
        <span className={cls.status__name}>{t("common.confirmed")}</span>
        <span>{confirmedStatusCount}</span>
      </div>
    </div>
  );
};
