import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { ProfileCompetenceStateInterface } from "./ducks/profile-competence/type";
import { ProfileInfoStateInterface } from "./ducks/profile-info/type";
import { RoomStateInterface } from "./ducks/room/type";
import { rootReducer } from "./rootReducer";
import rootSaga from "./saga";
import { IUsersControlState } from "./ducks/users-control/type";
import { SimulationItemAdminStateInterface } from "./ducks/simulation-item-admin/type";
import { ISidebarInformationState } from "./ducks/sidebar-information/type";
import { ISessionsHistoryState } from "./ducks/sessions-history/type";
import { IContactsState } from "./ducks/contacts/type";
import { ICdnState } from "./ducks/cdn/type";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export interface RootState {
  profileInfo: ProfileInfoStateInterface;
  profileCompetence: ProfileCompetenceStateInterface;
  room: RoomStateInterface;
  usersControl: IUsersControlState;
  simulationItemAdmin: SimulationItemAdminStateInterface;
  sidebarInformation: ISidebarInformationState;
  sessionsHistory: ISessionsHistoryState;
  contacts: IContactsState;
  cdn: ICdnState;
}

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
