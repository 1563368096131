import { createEffect } from "effector";
import { snackbarOpen } from "src/features/public/snackbar";
import { t } from "src/shared/helpers";

// Effects

export const successFx = createEffect(() =>
  snackbarOpen({
    text: t("snackbar.newsletter.successfulSending"),
    type: "success",
    visible: true,
  }),
);

export const errorFx = createEffect(() =>
  snackbarOpen({
    text: t("snackbar.newsletter.sendError"),
    type: "error",
    visible: true,
  }),
);
