import { permissionsDomain } from "./domain";
import { PermissionDto } from "src/generated/social";
import {
  CheckUserPermissions,
  GetUserPermissions,
  WriteUserPermissions,
} from "../../../../shared/api/public/permissions/model/query";
import {
  CheckUserPermission,
  getAllUserPermissions,
  writeAllUserPermissions,
} from "../../../../shared/api/public/permissions";
import { sample } from "effector";

export const $permissions = permissionsDomain.createStore<PermissionDto[]>([]);

//todo: зарефакторить и перенести все в один стор
export const $isLoadingPermission =
  permissionsDomain.createStore<boolean>(true);

export const $specificPermission = permissionsDomain.createStore<any>({
  has_permission: false,
});

export const $specificRatingPermission = permissionsDomain.createStore<any>({
  has_permission: false,
});

export const $specificSessionPermission = permissionsDomain.createStore<any>({
  has_permission: false,
});

export const $specificChartsPermission = permissionsDomain.createStore<any>({
  has_permission: false,
});

export const $specificDownloadPermission =
  permissionsDomain.createStore<boolean>(false);

export const fetchUserPermissions =
  permissionsDomain.createEvent<GetUserPermissions>();

export const postAllUserPermissions =
  permissionsDomain.createEvent<WriteUserPermissions>();

export const fetchSpecificPermission =
  permissionsDomain.createEvent<CheckUserPermissions>();

export const fetchSpecificRatingPermission =
  permissionsDomain.createEvent<CheckUserPermissions>();

export const fetchSpecificSessionPermission =
  permissionsDomain.createEvent<CheckUserPermissions>();

export const fetchSpecificDownloadPermission =
  permissionsDomain.createEvent<CheckUserPermissions>();

export const fetchSpecificChartsPermission =
  permissionsDomain.createEvent<CheckUserPermissions>();

export const fetchUserPermissionsFx = permissionsDomain.createEffect(
  getAllUserPermissions,
);

export const writeAllUserPermissionsFx = permissionsDomain.createEffect(
  writeAllUserPermissions,
);

export const fetchSpecificPermissionFx =
  permissionsDomain.createEffect(CheckUserPermission);

export const fetchSpecificRatingPermissionFx =
  permissionsDomain.createEffect(CheckUserPermission);

export const fetchSpecificSessionPermissionFx =
  permissionsDomain.createEffect(CheckUserPermission);

export const fetchSpecificDownloadPermissionFx =
  permissionsDomain.createEffect(CheckUserPermission);

const fetchSpecificChartsPermissionsFx =
  permissionsDomain.createEffect(CheckUserPermission);

sample({
  clock: fetchUserPermissions,
  target: fetchUserPermissionsFx,
});

sample({
  clock: postAllUserPermissions,
  target: writeAllUserPermissionsFx,
});

sample({
  clock: fetchSpecificPermission,
  target: fetchSpecificPermissionFx,
});

sample({
  clock: fetchSpecificRatingPermission,
  target: fetchSpecificRatingPermissionFx,
});

sample({
  clock: fetchSpecificSessionPermission,
  target: fetchSpecificSessionPermissionFx,
});

sample({
  clock: fetchSpecificDownloadPermission,
  target: fetchSpecificDownloadPermissionFx,
});

sample({
  clock: fetchSpecificPermissionFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $specificPermission,
});

sample({
  clock: fetchUserPermissionsFx.doneData,
  fn: ({ permissions }) => {
    return permissions || [];
  },
  target: $permissions,
});

sample({
  clock: fetchSpecificRatingPermissionFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $specificRatingPermission,
});

sample({
  clock: fetchSpecificSessionPermissionFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $specificSessionPermission,
});

sample({
  clock: fetchSpecificDownloadPermissionFx.doneData,
  fn: (res: any) => {
    return res.has_permission;
  },
  target: $specificDownloadPermission,
});

sample({
  clock: fetchSpecificChartsPermission,
  target: fetchSpecificChartsPermissionsFx,
});

sample({
  clock: fetchSpecificChartsPermissionsFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $specificChartsPermission,
});

sample({
  clock: fetchUserPermissionsFx.pending,
  target: $isLoadingPermission,
});
