import { useUnit } from "effector-react";
import { useTranslation } from "react-i18next";
import { exportModel } from "src/entities/hr/export-rates";
import { $specificDownloadPermission } from "src/entities/public/permissions/model";
import { EUserSettingsTablesName } from "src/entities/public/user-settings";
import { tableSettingsItemsRatings } from "src/features/public/user-settings";
import TableSettingsDropdown from "src/features/public/user-settings/ui/table-settings-dropdown/table-settings-dropdown";
import { BaseButton, EPopupName, TextWithIcon } from "src/shared/components";
import { openPopup } from "src/shared/components/base-popup/model";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import { useMediaQuery } from "src/shared/hooks/useMediaQuery";
import { useUrlState } from "src/shared/hooks/useUrlState";
import { clearState } from "../../../../shared/helpers/clearState";

export const RatingsTableControls = () => {
  const { t } = useTranslation();

  const { isMobile } = useMediaQuery();

  const [urlState, setUrlState] = useUrlState();

  const isDisabledExportButtons = useUnit(exportModel.$isLoading);

  const isExportAllowed = useUnit($specificDownloadPermission);

  const filters = Object.keys(urlState.query).filter(
    (key) => key !== "pageNum",
  ).length;

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const showDownloadPopup = () => {
    openPopup({ name: EPopupName.DOWNLOAD_FILE });
  };

  return (
    <div className="table__control">
      {filters > 0 && (
        <div
          className="table__control-btn table__control-btn--hide-on-mobile"
          onClick={clearFilterSettings}
        >
          <TextWithIcon
            label={t("table.filters.reset")}
            hideLabel={isMobile}
            iconName={"cross-blue"}
            isTextNowrap
          />
        </div>
      )}
      <div className="table__control-btn table__control-btn--hide-on-mobile">
        <BaseButton
          disabled={isDisabledExportButtons || !isExportAllowed}
          onClick={exportModel.exportXlsx}
        >
          <TextWithIcon
            hideLabel={isMobile}
            label={`${t("common.unload")} XLSX`}
            iconName={"upload-blue"}
            isTextNowrap
            isLoader={isDisabledExportButtons}
          />
        </BaseButton>
      </div>
      <div className="table__control-btn table__control-btn--hide-on-mobile">
        <BaseButton
          disabled={isDisabledExportButtons || !isExportAllowed}
          onClick={exportModel.exportCsv}
        >
          <TextWithIcon
            hideLabel={isMobile}
            label={`${t("common.unload")} CSV`}
            iconName={"upload-blue"}
            isTextNowrap
            isLoader={isDisabledExportButtons}
          />
        </BaseButton>
      </div>

      <div className="table__control-btn table__control-btn--hide-on-not-mobile">
        <BaseButton
          disabled={isDisabledExportButtons}
          onClick={showDownloadPopup}
        >
          <TextWithIcon
            hideLabel={isMobile}
            label={`${t("common.unload")} CSV (new)`}
            iconName={"upload-blue"}
            isTextNowrap
            isLoader={isDisabledExportButtons}
          />
        </BaseButton>
      </div>
      <div className="table__control-btn table__control-btn--hide-on-not-mobile">
        <DataGridMobileSortButton />
      </div>
      <div className="table__control-btn table__control-btn--hide-on-not-mobile">
        <DataGridMobileFiltersButton />
      </div>
      <div className="table__control-btn">
        <TableSettingsDropdown
          items={tableSettingsItemsRatings}
          tableName={EUserSettingsTablesName.RATINGS}
        />
      </div>
    </div>
  );
};
