import classNames from "classnames";
import { useUnit } from "effector-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  $ratingChart,
  $tutorialChart,
  getRatingChart,
  getTutorialChart,
} from "src/entities/hr/charts/model/charts";
import {
  BaseSwitcher,
  SwitcherItem,
} from "src/shared/components/base-switcher/base-switcher";
import { Chart } from "./chart/chart";
import styles from "./tutorial-chart.module.scss";

export const TutorialChart = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<"passed" | "in_progress">("passed");

  const tutorial = useUnit($tutorialChart);
  const rating = useUnit($ratingChart);

  const data = useMemo(
    () => [
      ...(tutorial.data?.chapters ?? []).map((chapter) => ({
        label: t("charts.tutorial.chapter", { chapter: chapter.chapter }),
        value: chapter.p_count,
      })),
      ...(rating.data?.ratings.filter(({ gRate }) => gRate !== 0) ?? []).map(
        (rating) => ({
          label: t("charts.tutorial.rating", { rating: `${rating.gRate}.0` }),
          value: rating.p_count,
        }),
      ),
    ],
    [tutorial, rating, t],
  );

  const switcherValues: SwitcherItem[] = [
    {
      title: t("charts.tutorial.completed"),
      onClick: () => setTab("passed"),
      isActive: tab === "passed",
    },
    {
      title: t("charts.tutorial.progress"),
      onClick: () => setTab("in_progress"),
      isActive: tab === "in_progress",
    },
  ];

  useEffect(() => {
    getRatingChart();
    getTutorialChart({ chapterType: tab });
  }, [tab]);

  return (
    <div className={styles.block}>
      <div className={styles.switcher_wrapper}>
        <BaseSwitcher
          items={switcherValues}
          className={classNames(styles.switcher, {
            [styles.disabled]: tutorial.isLoading || rating.isLoading,
          })}
        />
      </div>
      <Chart data={data} />
    </div>
  );
};
