import { FC, ReactElement, useEffect } from "react";
import { PageWrapper } from "src/shared/components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectContactsItem,
  selectContactsItemStatusGet,
  selectContactsItemStatusUpdate,
} from "../../../shared/store/ducks/contacts/selectors";
import {
  cleanContact,
  requestContactGet,
  requestContactUpdate,
} from "../../../shared/store/ducks/contacts/actionCreators";
import { useNavigate, useParams } from "react-router-dom";
import { ContactsForm } from "../components";
import { ERequestStatus } from "../../../shared/store/types";
import { Manager } from "../../../generated/social";
import { useTranslation } from "react-i18next";
import {
  cleanOptionsContactAvatar,
  requestOptionsContactAvatar,
} from "../../../shared/store/ducks/cdn/actionCreators";
import { selectCdnOptionsContactAvatarStatus } from "../../../shared/store/ducks/cdn/selectors";

interface Props {}

export const EditContactPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const item = useSelector(selectContactsItem);
  const loadingStatusGet = useSelector(selectContactsItemStatusGet);
  const loadingStatusUpdate = useSelector(selectContactsItemStatusUpdate);
  const loadingStatusOptions = useSelector(selectCdnOptionsContactAvatarStatus);
  const isLoadingGet: boolean = loadingStatusGet === ERequestStatus.LOADING;
  const isLoadingUpdate: boolean =
    loadingStatusUpdate === ERequestStatus.LOADING;
  const isLoadingOptions: boolean =
    loadingStatusOptions === ERequestStatus.LOADING;

  useEffect(() => {
    if (id) {
      dispatch(
        requestContactGet({
          options: {
            mid: id,
          },
        }),
      );

      dispatch(requestOptionsContactAvatar());
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(cleanContact());
      dispatch(cleanOptionsContactAvatar());
    };
  }, [dispatch]);

  const onSaveHandler = (contactItem: Manager) => {
    const callback = () => {
      navigate("/other/edit-contacts");
    };
    if (id) {
      dispatch(
        requestContactUpdate({
          options: {
            mid: id,
            body: contactItem,
          },
          callback,
        }),
      );
    }
  };

  return (
    <PageWrapper
      title={t("other.editContacts.pageTitle.contact")}
      isLoadingPanel={isLoadingGet || isLoadingOptions}
      backButton
      emptyPanel
    >
      {!!item && (
        <ContactsForm
          isDisabledBtn={isLoadingUpdate}
          contactItem={item}
          btnLabel={t("common.saveChanges")}
          onSave={onSaveHandler}
        />
      )}
    </PageWrapper>
  );
};
