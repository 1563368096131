import { createStore, createEvent } from "effector";
import { ISnackbarState } from "./types";

//store

export const $snackbar = createStore<ISnackbarState>({
  visible: false,
  text: "",
  time: 3,
  type: undefined,
});

//events

export const snackbarOpen = createEvent<ISnackbarState>();

export const snackbarClose = createEvent();

//Logic

$snackbar.on(snackbarOpen, (state, payload) => ({
  ...state,
  ...payload,
}));

$snackbar.reset(snackbarClose);
