import { useUnit } from "effector-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  $playersGrowth,
  getPlayersGrowth,
} from "src/entities/hr/charts/model/charts";
import { BaseSelect, SelectItem } from "src/shared/components";
import styles from "./statistics-chart.module.scss";
import { getGrowthRatio } from "../helpers/getGrowthRatio";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import { ru } from "date-fns/locale";

type GrowthType = "last_m_growth" | "rate_0_5" | "rate_1";

const monthAgo = new Date();
monthAgo.setMonth(monthAgo.getMonth() - 1);

export const StatisticsChart = () => {
  const { t } = useTranslation();

  const [filterBy, setFilterBy] = useState<GrowthType>("last_m_growth");
  const [fromDate, setFromDate] = useState<Date | null>(new Date(monthAgo));
  const [toDate, setToDate] = useState<Date | null>(new Date());

  const growth = useUnit($playersGrowth);

  useEffect(() => {
    if (fromDate && toDate) {
      getPlayersGrowth({
        fromDate: format(fromDate, "yyyy-MM-dd"),
        toDate: format(toDate, "yyyy-MM-dd"),
        filterBy,
      });
    }
  }, [fromDate, toDate, filterBy]);

  const fieldSelectValues: SelectItem[] = [
    {
      value: "rate_0_5",
      label: t("charts.statistics.received_rating", { rating: 0.5 }),
    },
    {
      value: "rate_1",
      label: t("charts.statistics.received_rating", { rating: 1 }),
    },
    {
      value: "last_m_growth",
      label: t("charts.statistics.last_m_growth"),
    },
  ];

  const growthRatio = getGrowthRatio(
    growth.data?.players.growth ?? 0,
    growth.data?.players.loss ?? 0,
  );

  return (
    <div className={styles.statistics}>
      <h3>{t("charts.statistics.title")}</h3>
      <BaseSelect
        hideEmptyItem
        items={fieldSelectValues}
        onChange={(payload) => setFilterBy(payload.value as GrowthType)}
        activeItem={filterBy}
        placeholder={t("charts.statistics.field")}
        disabled={growth.isLoading}
      />
      <h4>{t("charts.statistics.period")}</h4>
      <div className={styles.time_selectors}>
        <label className={styles.datepicker}>
          <span className={styles.datepicker_placeholder}>
            {t("charts.statistics.period_from")}
          </span>
          <DatePicker
            className={styles.datepicker_input}
            onChange={setFromDate}
            selected={fromDate}
            dateFormat="dd.MM.yyyy"
            maxDate={toDate ?? new Date()}
            locale={ru}
            required
          />
        </label>
        <label className={styles.datepicker}>
          <span className={styles.datepicker_placeholder}>
            {t("charts.statistics.period_to")}
          </span>
          <DatePicker
            className={styles.datepicker_input}
            onChange={setToDate}
            dateFormat="dd.MM.yyyy"
            selected={toDate}
            minDate={fromDate ?? undefined}
            maxDate={new Date()}
            locale={ru}
            required
          />
        </label>
      </div>

      {filterBy === "last_m_growth" && (
        <div
          className={styles.chart}
          style={{
            background: `linear-gradient(to right, #00D085 ${growthRatio}%, #FF3D3D ${growthRatio}%)`,
          }}
        />
      )}

      <ul className={styles.tags}>
        <li className={styles.tag}>
          <span className={styles.tag_mark_green}></span>
          <span className={styles.tag_name}>
            {t(
              filterBy === "last_m_growth"
                ? "charts.statistics.growth"
                : "charts.statistics.received_rating",
              { rating: null },
            )}
          </span>
          <span className={styles.tag_count}>
            {growth.data?.players.growth}
          </span>
        </li>
        {filterBy === "last_m_growth" && (
          <li className={styles.tag}>
            <span className={styles.tag_mark_red}></span>
            <span className={styles.tag_name}>
              {t("charts.statistics.loss")}
            </span>
            <span className={styles.tag_count}>
              {growth.data?.players.loss}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};
