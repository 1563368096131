import { createDomain, sample } from "effector";

import {
  getLink,
  postCreateLink,
} from "../../../../shared/api/public/relatives/relatives";
import { RelativeLinkResponse } from "../../../../generated/social";

export const createLinkDomain = createDomain("createLinkPublic");

// Effects

export const fetchCreateLinkFx = createLinkDomain.createEffect(postCreateLink);
export const fetchGetLinkFx = createLinkDomain.createEffect(getLink);

// Stores

export const $relativeLink =
  createLinkDomain.createStore<RelativeLinkResponse | null>(null);

export const $isLoading = createLinkDomain.createStore<boolean>(false);

// Events

export const fetchCreateLink = createLinkDomain.createEvent();
export const fetchGetLink = createLinkDomain.createEvent();

sample({
  clock: fetchCreateLink,
  target: fetchCreateLinkFx,
});

sample({
  clock: fetchCreateLinkFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchCreateLinkFx.doneData,
  target: $relativeLink,
});

sample({
  clock: fetchGetLink,
  target: fetchGetLinkFx,
});

sample({
  clock: fetchGetLinkFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchGetLinkFx.doneData,
  target: $relativeLink,
});

sample({
  clock: fetchGetLinkFx.fail,
  target: fetchCreateLinkFx,
});
