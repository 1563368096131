import { QueryParams } from "../../../../shared/api/base";

export const queryParamsToGetListParamsAdapter = (
  queryParams: QueryParams = {},
): any => {
  return {
    competence: queryParams.competenceName
      ? queryParams.competenceName.split(",")
      : undefined,
    archived: queryParams.archived ? queryParams.archived : false,
    periodFrom: queryParams.periodFrom,
    periodTo: queryParams.periodTo,
    firstnameCreator: queryParams.firstnameCreator,
    lastnameCreator: queryParams.lastnameCreator,
    removableRatingFrom: queryParams.removableRatingFrom,
    removableRatingTo: queryParams.removableRatingTo,
    dateOfCreateFrom: queryParams.dateOfCreateFrom,
    dateOfCreateTo: queryParams.dateOfCreateTo,
    orderBy: queryParams.orderBy ? [queryParams.orderBy] : undefined,
    pageNum: queryParams.pageNum ? Number(queryParams.pageNum) : 1,
    pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 50,
  };
};
