import { QueryParams } from "src/shared/api/base";
import { GetNotificationList } from "src/shared/api/admin/notification/notifications";

export const queryParamsToGetNotificationListAdapter = (
  queryParams: QueryParams = {},
): GetNotificationList => ({
  orderBy: queryParams.orderBy ? [queryParams.orderBy] : undefined,
  pageNum: queryParams.pageNum ? Number(queryParams.pageNum) : 1,
  pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 50,
  whenFrom: queryParams.whenFrom
    ? new Date(queryParams.whenFrom).toISOString()
    : undefined,
  whenTo: queryParams.whenTo
    ? new Date(queryParams.whenTo).toISOString()
    : undefined,
});
