import { RoomActionsType } from "./actionTypes";
import { PopupsActions } from "./actionCreators";
import { RoomStateInterface } from "./type";
import { Join as UserInfoDto } from "src/generated/ws4";

const initialState: RoomStateInterface = {
  assessmentId: undefined,
  assessmentType: undefined,
  assessmentStatus: undefined,
  title: "",
  gameId: "",
  gameType: undefined,
  maxPlayers: "",
  players: [],
  roles: [],
  error: false,
  createdAt: "",
  createdBy: "",
  maxTeamsByName: undefined,
  maxPlayersByRole: undefined,
  connectedPlayers: 0,
  validate_players: false,
  is_timeout: false,
  lobby_presence_timer: 0,
  lobby_confirm_timer: 0,
  closed_session: false,
  required_participation: false,
  competence_rating_bankrupt: {},
};

export const roomReducer = (state = initialState, action: PopupsActions) => {
  switch (action.type) {
    case RoomActionsType.ROOM_SET_STATE:
      const { payload } = action;
      const players = "players" in payload ? payload.players : [];

      return {
        ...state,
        maxPlayers: payload.maxPlayers,
        assessmentId: payload.aid,
        assessmentType: payload.aType,
        assessmentStatus: payload.aStatus,
        gameType: payload.gType,
        createdAt: payload.createdAt,
        createdBy: payload.createdBy,
        createdByUser: payload.createdByUser,
        playUrl: payload.playUrl,
        title: payload.title,
        minRate: payload.minRate,
        maxRate: payload.maxRate,
        competencies: payload.competence,
        maxTeamsByName: payload.maxTeamsByName,
        maxPlayersByRole: payload.maxPlayersByRole,
        maxAreas: payload.maxAreas,
        gameId: payload.gid,
        players,
        connectedPlayers: players?.length || 0,
        validate_players: payload.validate_players,
        is_timeout: payload.is_timeout,
        lobby_presence_timer: payload.lobby_presence_timer,
        lobby_confirm_timer: payload.lobby_confirm_timer,
        closed_session: payload.closed_session,
        required_participation: payload.required_participation,
        competence_rating_bankrupt: payload.competence_rating_bankrupt,
      };
    case RoomActionsType.ROOM_CONNECT_PLAYER:
      const newPlayers = state.players.some(
        (player: UserInfoDto) => player.pid === action.payload.pid,
      )
        ? state.players
        : [...state.players, action.payload];

      return {
        ...state,
        players: newPlayers,
        connectedPlayers: newPlayers?.length || 0,
      };
    case RoomActionsType.ROOM_CONNECTED_PLAYERS_COUNT_CHANGE:
      return {
        ...state,
        connectedPlayers: action.payload.curPlayers,
      };
    case RoomActionsType.ROOM_LEAVE_PLAYER:
      const playersWithoutLeave = state.players.filter(
        (player: UserInfoDto) => player.pid !== action.payload.pid,
      );

      return {
        ...state,
        players: playersWithoutLeave,
        connectedPlayers: playersWithoutLeave.length,
      };
    case RoomActionsType.ROOM_CHANGE_ROLE:
      return {
        ...state,
        players: state.players.map((player: UserInfoDto) => {
          if (player.pid === action.payload.pid) {
            return { ...player, role: action.payload.role };
          } else {
            return player;
          }
        }),
      };
    case RoomActionsType.ROOM_CHANGE_TEAM_ROLE:
      return {
        ...state,
        players: state.players.map((player: UserInfoDto) => ({
          ...player,
          teamName:
            player.teamNum === action.payload.teamNum
              ? action.payload.toTeamName
              : player.teamName,
        })),
      };

    case RoomActionsType.ASSESSMENT_STATUS:
      return {
        ...state,
        assessmentStatus: action.payload,
      };
    case RoomActionsType.ROOM_CLEAR:
      return { ...initialState, assessmentStatus: state.assessmentStatus };
    case RoomActionsType.ROOM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
