import { Dispatch, FC, ReactElement, SetStateAction } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { BaseBox, BaseInputText, FormControl } from "src/shared/components";

import { toCloneObject } from "src/shared/helpers";

import { IBaseInputChange } from "src/shared/models";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import { ISimulationFormData } from "../../lib/types";

import "./links-block.scss";

interface Props {
  formData: ISimulationFormData;
  setFormData: Dispatch<SetStateAction<ISimulationFormData>>;
  errors?: Record<string, string>;
  setFormDataErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  className?: string;
}

const { openPopup } = popupModel;

export const LinksBlock: FC<Props> = ({
  formData,
  setFormData,
  errors,
  setFormDataErrors,
  className,
}): ReactElement => {
  const { t } = useTranslation();

  const onChangeHandler = (payload: IBaseInputChange): void => {
    const inputName = payload.name as keyof ISimulationFormData;

    setFormData((prev) => {
      const localPrev: ISimulationFormData = toCloneObject(prev);

      if (inputName) {
        localPrev[inputName] = payload.value;
      }

      return localPrev;
    });

    setFormDataErrors((prev) => {
      const localPrev = toCloneObject(prev);

      if (inputName) {
        delete localPrev[inputName];
      }

      return localPrev;
    });
  };

  const openPullMetaPopup = (): void => {
    openPopup({ name: EPopupName.CONFIRM_PULL_META });
  };

  const renderFields = Object.keys(formData).map((item: string) => {
    const typedItem = item as keyof ISimulationFormData;
    const isMetaField: boolean = item === "metaUrl";
    const isApiKeyField: boolean = item === "apiKey";
    const onRefresh = isMetaField ? openPullMetaPopup : undefined;
    const placeholder = isApiKeyField
      ? item
      : `${t(
          "simulationControl.simulationPage.linksBlock.fieldPlaceholder.link",
        )} ${item}`;

    return (
      <FormControl key={item} error={errors?.[item]}>
        <BaseInputText
          value={formData[typedItem]}
          placeholder={placeholder}
          onChange={onChangeHandler}
          name={item}
          error={!!errors?.[item]}
          onRefresh={onRefresh}
          readOnly={isMetaField}
        />
      </FormControl>
    );
  });

  return (
    <BaseBox className={classNames("simulation-links-block", className)}>
      <h3 className="simulation-links-block__title">
        {t("simulationControl.simulationPage.linksBlock.title")}
      </h3>
      {renderFields}
    </BaseBox>
  );
};
