import { FC, FormEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseButton, InputPassword } from "../../../shared/components";
import { PageWrapper } from "src/shared/components";
import "./change-password-page.scss";
import { Navigate } from "react-router-dom";

interface Props {}

export const ChangePasswordPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

  const onChangeCurrentPassword = (text: string) => {
    setCurrentPassword(text);
  };

  const onChangeNewPassword = (text: string) => {
    setNewPassword(text);
  };

  const onChangeConfirmNewPassword = (text: string) => {
    setConfirmNewPassword(text);
  };

  const changePassword = (e: FormEvent) => {
    e.preventDefault();
  };

  if (true) {
    return <Navigate to="/profile/info" />;
  }

  return (
    <PageWrapper
      title={t("profile.changePassword.title")}
      backButton
      emptyPanel
    >
      <form onSubmit={changePassword} className="change-password">
        <p className="change-password__discription">
          {t("profile.changePassword.text")}
        </p>
        <div className="change-password__control">
          <InputPassword
            placeholder={t("profile.changePassword.inputLabel.currentPassword")}
            value={currentPassword}
            onChange={onChangeCurrentPassword}
          />
        </div>
        <div className="change-password__control">
          <InputPassword
            placeholder={t("profile.changePassword.inputLabel.newPassword")}
            value={newPassword}
            onChange={onChangeNewPassword}
          />
        </div>
        <div className="change-password__control">
          <InputPassword
            placeholder={t(
              "profile.changePassword.inputLabel.confirmNewPassword",
            )}
            value={confirmNewPassword}
            onChange={onChangeConfirmNewPassword}
          />
        </div>
        <div className="change-password__submit-btn">
          <BaseButton submit block primary large>
            {t("profile.changePassword.submitBtn")}
          </BaseButton>
        </div>
      </form>
    </PageWrapper>
  );
};
