import formatDateFns from "date-fns/format";

export const formatDate = (
  date?: string | number | null,
  format: string = "yyyy-MM-dd",
): undefined | string => {
  if (!date) {
    return;
  }

  try {
    let jsDate = new Date(date);

    return formatDateFns(jsDate, format);
  } catch (e) {
    return undefined;
  }
};
