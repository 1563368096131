import { combine, sample } from "effector";

import { assessmentInviteDomain } from "./domain";

import {
  AssessmentInvite,
  AssessmentRevoke,
  MsgType,
} from "src/generated/notification";

import { PostPersonalNotification } from "src/shared/api/admin/notification";

import {
  sendPersonalNotification,
  sendPersonalNotificationFx,
} from "src/entities/admin/notification/notification";
import {
  AssessmentMembersApiPostGameAssessmentAddMembersRequest,
  PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembersRequest,
} from "src/generated/game";
import { gameService } from "../../../../shared/api/base/api-instances";
import { $queryParams } from "../../../../entities/hr/assessment-invite/model";
import { fetchInvitedPlayersFx } from "src/entities/hr/assessment-invite/model/invited-players";
import i18n from "src/shared/i18n/i18n";

export type InvitedPlayers = {
  userId: string;
  firstname: string;
  lastname: string;
  received: boolean;
  email: string;
  phone: string;
  locality: string;
  studyWorkPlace: string;
  role?: string;
  teamName?: string;
  teamNum?: number;
  areaNum?: number;
  corpNum?: number;
  isDistributed?: boolean;
};

// Events

export const invitePlayers =
  assessmentInviteDomain.createEvent<InvitedPlayers[]>();

export const revokePlayers = assessmentInviteDomain.createEvent<string[]>();

export const sendInvitePlayers =
  assessmentInviteDomain.createEvent<PostPersonalNotification>();

export const setSelectedAssessment =
  assessmentInviteDomain.createEvent<AssessmentInvite | null>();

export const receiveSelectedPlayer =
  assessmentInviteDomain.createEvent<InvitedPlayers["userId"]>();

export const leaveSelectedPlayer =
  assessmentInviteDomain.createEvent<InvitedPlayers["userId"]>();

export const removeAssessmentMembers =
  assessmentInviteDomain.createEvent<PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembersRequest>();

// Effects

export const addAssessmentMembersFx = assessmentInviteDomain.createEffect(
  (params: AssessmentMembersApiPostGameAssessmentAddMembersRequest) => {
    gameService
      .makeAssessmentMembersRequest()
      .postGameAssessmentAddMembers(params);
  },
);

const removeAssessmentMembersFx = assessmentInviteDomain.createEffect(
  async (
    params: PostDeletedAssessmentMembersApiPostDeletedGameAssessmentMembersRequest,
  ) => {
    return await gameService
      .makeDeleteAssessmentMembersRequest()
      .postDeletedGameAssessmentMembers(params);
  },
);

// Stores

export const $selectedAssessment =
  assessmentInviteDomain.createStore<AssessmentInvite | null>(null);

export const $invitedPlayers = assessmentInviteDomain.createStore<
  InvitedPlayers[]
>([]);

// Logic

sample({
  clock: invitePlayers,
  target: $invitedPlayers,
});

sample({
  clock: setSelectedAssessment,
  source: $selectedAssessment,
  filter: (state, payload) => state?.aid !== payload?.aid,
  fn: () => [],
  target: $invitedPlayers,
});

sample({
  clock: setSelectedAssessment,
  target: $selectedAssessment,
});

sample({
  clock: invitePlayers,
  source: $selectedAssessment,
  fn: (selectedAssessment, players) => ({
    reqNotifyPersonal: {
      uids: players.map(({ userId }) => userId),
      msg: {
        type: MsgType.AssessmentInvite,
        body: (selectedAssessment || {}) as AssessmentInvite,
      },
    },
  }),
  target: sendPersonalNotification,
});

sample({
  clock: invitePlayers,
  source: $selectedAssessment,
  fn: (
    selectedAssessment,
    players,
  ): AssessmentMembersApiPostGameAssessmentAddMembersRequest => ({
    assessmentId: selectedAssessment?.aid ?? "",
    members: {
      members: players.map((player) => ({
        playerId: player.userId,
        studyWorkPlace: player.studyWorkPlace,
        email: player.email,
        locality: player.locality,
        phone: player.phone,
        firstName: player.firstname,
        lastName: player.lastname,
        teamName: player.teamName,
        teamNum: player.teamNum,
        corpNum: player.corpNum,
        areaNum: player.areaNum,
        role: player.role,
        isDistributed: !!player.role,
      })),
    },
  }),
  target: addAssessmentMembersFx,
});

sample({
  clock: sendPersonalNotificationFx.failData,
  fn: () => [],
  target: $invitedPlayers,
});

sample({
  clock: receiveSelectedPlayer,
  source: $invitedPlayers,
  fn: (selected, receiveId) =>
    selected.map((player) => {
      if (player.userId === receiveId) {
        return {
          ...player,
          received: true,
        };
      }

      return player;
    }),
  target: $invitedPlayers,
});

sample({
  clock: leaveSelectedPlayer,
  source: $invitedPlayers,
  fn: (selected, receiveId) =>
    selected.map((player) => {
      if (player.userId === receiveId) {
        return {
          ...player,
          received: false,
        };
      }

      return player;
    }),
  target: $invitedPlayers,
});

sample({
  clock: removeAssessmentMembers,
  target: removeAssessmentMembersFx,
});

sample({
  clock: removeAssessmentMembers,
  source: $selectedAssessment,
  fn: (assessment, payload) => ({
    reqNotifyPersonal: {
      uids: payload.members.members.map((player) => player.playerId),
      msg: {
        type: MsgType.AssessmentRevoke,
        body: {
          aid: assessment?.aid,
          aTitle: i18n.t("notification.title.assessment.revoke"),
          gTitle: assessment?.gTitle,
          logoUrl: assessment?.logoUrl,
          aType: assessment?.aType,
        } as AssessmentRevoke,
      },
    },
  }),
  target: sendPersonalNotification,
});

sample({
  clock: removeAssessmentMembersFx.done,
  source: combine(
    $queryParams,
    $selectedAssessment,
    (queryParams, selectedAsseeemnt) => ({
      assessmentId: selectedAsseeemnt?.aid ?? "",
      queryParams,
    }),
  ),
  target: fetchInvitedPlayersFx,
});
