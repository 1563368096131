import { FC, useCallback, useEffect } from "react";

import { useUnit } from "effector-react";

import { appAccessModel } from "src/features/public/app-access";

import classNames from "classnames";

import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import { useUrlState } from "src/shared/hooks/useUrlState";

import useClient from "../../../shared/hooks/useClient";

import "./sessions-history-page.scss";

import { TDataGridDataParams } from "../../../shared/models/dataGrid";
import { PageWrapper } from "src/shared/components";

import { SessionsHistory } from "../../../shared/components/data-grids/histories/sessions-history";
import { sessionsHistoryQueryHandler } from "./lib/sessionsHistoryQueryHandler";
import { SessionsHistoryTableControl } from "./components/sessions-history-table-control";
import { $profileCompetence } from "src/shared/components/popups/user-profile-popup/lib/profile";
import {
  $isLoading,
  $playerRoles,
  $sessionItems,
  $sessionsPagination,
  requestPlayerRoles,
  requestSessionHistory,
  setIsSessionsHistoryReadmore,
} from "src/shared/components/session-history-player/lib/player-history";

export const SessionsHistoryPage: FC = () => {
  const [urlState, setUrlState] = useUrlState();
  const { t } = useTranslation();

  const { isPlayerClientId } = useClient();

  const isVerificationSession = useUnit(appAccessModel.$isVerificationSession);
  const historyItems = useUnit($sessionItems);
  const isLoading = useUnit($isLoading);
  const historyPagination = useUnit($sessionsPagination);
  const historyRoles = useUnit($playerRoles);

  const competence = useUnit($profileCompetence);

  useEffect(() => {
    requestPlayerRoles({
      playerId: urlState.params.id,
    });
  }, [urlState.params.id]);

  useEffect(() => {
    const requestParams = sessionsHistoryQueryHandler(urlState);
    requestSessionHistory(requestParams);
  }, [urlState]);

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setIsSessionsHistoryReadmore(isReadmoreValue);

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  return (
    <PageWrapper
      title={t("sessionsHistory.title")}
      backButton
      isShowContentWhenIsLoading
      emptyPanel
      isLightBlueBackground
      isLoadingPanel={isLoading}
      titlePanelSlot={<SessionsHistoryTableControl />}
    >
      <div
        className={classNames("sessions-history-data-grid-wrapper", {
          "sessions-history-data-grid-wrapper--for_player":
            isPlayerClientId && competence && !isVerificationSession,
        })}
      >
        <SessionsHistory
          historyItems={historyItems}
          queryParams={urlState.query}
          onUpdateSortAndFilters={onUpdateQueryParams}
          loading={isLoading}
          roles={historyRoles}
          pagination={historyPagination}
          goToPage={(pageNum) => goToPage(pageNum)}
          readmore={(pageNum) => goToPage(pageNum, true)}
        />
      </div>
    </PageWrapper>
  );
};
