import classNames from "classnames";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BaseBox, TextWithIcon } from "../../../../shared/components";
import { IContactLink } from "../../contacts-page";
import "./social-networks-block.scss";

interface Props {
  className?: string;
}

const socialsLinks: IContactLink[] = [
  {
    key: "vk-color",
    link: "https://vk.com/alabugapolytech",
  },
  {
    key: "youtube-color",
    link: "https://www.youtube.com/@alabugapolytech",
  },
  {
    key: "globe-gray",
    link: "https://alabuga.ru",
    label: "alabuga.ru",
  },
];

export const SocialNetworksBlock: FC<Props> = ({ className }): ReactElement => {
  const { t } = useTranslation();

  return (
    <BaseBox className={classNames("social-networks-block", className)}>
      <h3 className="social-networks-block__title">
        {t("contacts.socialNetworksBlock.title")}
      </h3>
      <div className="social-networks-block__controls">
        {socialsLinks.map((item) => (
          <a
            key={item.key}
            className={classNames(
              "social-networks-block__controls-item",
              "social-networks-block__controls-item--social",
              `social-networks-block__controls-item--${item.key}`
            )}
            href={item.link}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <TextWithIcon
              iconName={item.key}
              label={item.label}
              iconSize={22}
              labelSize={18}
              labelSizeMobile={16}
              labelWeight={600}
              hideLabel={!item.label}
              isBlock
            />
          </a>
        ))}
      </div>
    </BaseBox>
  );
};
