import { sample } from "effector";

import { getNotificationList } from "src/shared/api/admin/notification/notifications";

import { QueryParams } from "src/shared/api/base";

import {
  $notificationList,
  $pagination,
  $queryParams,
  notificationDomain,
} from "./notifications";

import { queryParamsToGetNotificationListAdapter } from "../lib";

// Event

export const readmoreNotifications =
  notificationDomain.createEvent<QueryParams>();

// Effect

export const readmoreNotificationsFx =
  notificationDomain.createEffect(getNotificationList);

// Logic

sample({
  clock: readmoreNotifications,
  fn: queryParamsToGetNotificationListAdapter,
  target: readmoreNotificationsFx,
});

sample({
  clock: readmoreNotifications,
  target: $queryParams,
});

sample({
  clock: readmoreNotificationsFx.doneData,
  source: $notificationList,
  fn: (notify, data) => [...notify, ...data.listNotifies],
  target: $notificationList,
});

sample({
  clock: readmoreNotificationsFx.doneData,
  fn: ({ pagination }) => pagination,
  target: $pagination,
});
