import { ChangeEvent, FC, ReactElement } from "react";
import { getTodayDate } from "../../../helpers/getTodayDate";
import { IBaseInput } from "../../../models/base-input";
import { InputTemplate } from "../input-template/input-template";

interface Props extends IBaseInput {
  min?: string | number;
  max?: string | number;
}

export const BaseInputDate: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  min,
  max,
  isSmall,
  forDarkBg,
  onChange,
  required,
  isContextBtnDisabled,
  readOnly,
  onRefresh,
  onDelete,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const val = target.value;

    onChange({
      value: val,
      name: target.name,
    });
  };

  return (
    <InputTemplate
      type="date"
      value={value}
      min={min || "1900-01-01"}
      max={max || getTodayDate()}
      onChange={onChangeHandler}
      name={name}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      onRefresh={onRefresh}
      onDelete={onDelete}
    />
  );
};
