import { socialService } from "../../../../base/api-instances";
import {
  RelativeApiGetChangeRelativeTableRequest,
  RelativeApiGetStudentApplicationRequest,
} from "src/generated/social";

export const getRelativeModerationHistory = (
  params: RelativeApiGetChangeRelativeTableRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .getChangeRelativeTable(params)
    .then((res) => res.data);
};

export const getStudentApplicationHistory = (
  params: RelativeApiGetStudentApplicationRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .getStudentApplication(params)
    .then((res) => res.data);
};

export const getEmployeeApplicationHistory = (
  params: RelativeApiGetStudentApplicationRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .getEmployeeApplication(params)
    .then((res) => res.data);
};
