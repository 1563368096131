import { sample } from "effector";

import { paths, urls } from "../config";

import {
  connectSocket,
  disconnectSocket,
  SocketParams,
} from "../../lib/connect";

import { socketsNotificationsDomain } from "./domain";

import { SocketStore } from "./types";

// Store

export const $socket =
  socketsNotificationsDomain.createStore<SocketStore>(null);

export const $isConnected =
  socketsNotificationsDomain.createStore<boolean>(false);

export const $isConnectLoading =
  socketsNotificationsDomain.createStore<boolean>(false);

// Effects

const initFx = socketsNotificationsDomain.createEffect(connectSocket);

const destroyFx = socketsNotificationsDomain.createEffect(disconnectSocket);

const attachHasConnectHandlerFx = socketsNotificationsDomain.createEffect(
  (socket: SocketStore) => {
    socket?.on("disconnect", () => {
      changeIsConnected(false);
    });

    socket?.on("connect", () => {
      changeIsConnected(true);
    });
  },
);

// Events

export const init = socketsNotificationsDomain.createEvent();
export const destroy = socketsNotificationsDomain.createEvent();
export const changeIsConnected =
  socketsNotificationsDomain.createEvent<boolean>();

// Logic

sample({
  clock: initFx.pending,
  target: $isConnectLoading,
});

sample({
  source: [$socket, $isConnectLoading],
  clock: init,
  filter: ([socket, isLoading]) => !socket && !isLoading,
  fn: (): SocketParams => ({
    path: paths.notifications.v1,
    url: urls.notifications,
  }),
  target: initFx,
});

sample({
  clock: initFx.doneData,
  target: $socket,
});

sample({
  clock: destroy,
  source: $socket,
  target: destroyFx,
});

sample({
  source: $socket,
  filter: (socket) => !!socket,
  target: attachHasConnectHandlerFx,
});

sample({
  source: changeIsConnected,
  target: $isConnected,
});
