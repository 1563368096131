import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "src/shared/components/index";

import useClient from "src/shared/hooks/useClient";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { UserChangesByAdminPlayerDto } from "../../../../generated/social";

interface Props {
  items: UserChangesByAdminPlayerDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const ChangeUsersHistory: FC<Props> = ({
  loading,
  items,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isPlayerClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullName",
        showed: true,
        titleUppercase: true,
        title: t("table.header.name"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.fineCreator"),
          activeGroupKey: getActiveGroup(
            ["firstName", "lastName"],
            { all: ["firstName", "lastNameAuthor"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstName || "",
                },
                {
                  key: "lastName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastName || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstName",
                  "-firstName",
                  "lastName",
                  "-lastName",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstName",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastName",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "action",
        showed: true,
        titleUppercase: true,
        title: t("table.header.action"),
        type: EDataGridColumnType.STRING,
      },
      {
        key: "dateAction",
        showed: true,
        titleUppercase: true,
        title: t("common.date"),
        type: EDataGridColumnType.DATETIME,
        controlPanel: {
          title: t("common.date"),
          activeGroupKey: getActiveGroup(
            ["dateAction", "dateActionFrom", "dateActionTo"],
            {
              all: ["dateAction", "dateActionFrom", "dateActionTo"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "dateActionFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.dateActionFrom || "",
                  isHalf: true,
                },
                {
                  key: "dateActionTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.dateActionTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "action_date",
                  "-action_date",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "action_date",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-action_date",
                  },
                ],
              },
            },
          ],
        },
      },
    ];

    return newColumns;
  }, [isPlayerClientId, queryParams]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const fullName = splitStrings([
        item.firstNameAuthor,
        item.lastNameAuthor,
      ]);
      const fullNameUser = splitStrings([
        item.firstNameUser,
        item.lastNameUser,
      ]);
      return {
        id: item.id,
        selected: false,
        fullName,
        avatarProps: {
          alt: fullName,
          fullName: fullName,
          customPath: getAvatarUrl(item.author_id),
          userId: item.author_id,
        },
        dateAction: item.action_date,
        action: `${t(`users.history.${item.action}`)} ${fullNameUser}`,
      };
    });

    setRows(rowsItems);
  }, [items, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
