// TODO - исправить посде исправления api
export const queryParamsInRaw = (queryParams: { [key: string]: any }) => {
  let rawQueryParams: string = "?";

  Object.keys(queryParams).forEach((key, index) => {
    if (index > 0) {
      rawQueryParams += "&" + key + "=" + queryParams[key];

      return;
    }

    rawQueryParams += key + "=" + queryParams[key];
  });

  return rawQueryParams;
};
