import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { useUnit } from "effector-react";

import { KeycloakInstance } from "keycloak-js";

import useClient from "src/shared/hooks/useClient";
import { AssessmentStatus } from "src/generated/game";

import {
  Active,
  AssessmentStatus as AssessmentStatusWS4,
  Err,
  HrRoom,
  Join,
  Play,
  PRoomChanged,
  PRoomR,
  Quit,
  QuitReason,
  Results,
  ResultsPlayer,
  Role,
  Team,
} from "src/generated/ws4";

import { socketsModel } from "src/shared/api/public/sockets";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import {
  roomChangeRole,
  roomChangeTeamRole,
  roomClear,
  roomConnectedPlayersCountChange,
  roomConnectPlayer,
  roomError,
  roomLeavePlayer,
  roomSetState,
  setAssessmentStatus,
} from "src/shared/store/ducks/room";

import { EInputError, Error } from "src/shared/models/error";

import {
  $socketGameV4RoomHrHandler,
  $socketGameV4RoomPlayerHandler,
} from "../model/v4";
import { $notifications } from "../../../../../entities/public/notifications/model";
import { markAsRead } from "../../../../../features/public/notification";
import { $socketGameV4UpdateRoomHandler } from "../model/v4/gameUpdate";
import { getSocialProfileInfo } from "../../../../../features/public/app-access/model";
import { $keycloak } from "../../../../../entities/public/keycloak/model";

const { openPopup } = popupModel;

export const useRoomSocket = (keycloak: KeycloakInstance) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const storeKeycloak = useUnit($keycloak);

  const socketPlayerRoom = useUnit($socketGameV4RoomPlayerHandler);

  const socketHrRoom = useUnit($socketGameV4RoomHrHandler);

  const socketUpdateRoom = useUnit($socketGameV4UpdateRoomHandler);

  const userId: string | undefined = storeKeycloak?.subject;

  const pathToCloud: string = `${process.env.REACT_APP_yandexStorageEndpoint}/${process.env.REACT_APP_yandexStorageImagesBucket}`;

  const { isPlayerClientId, isHrClientId } = useClient();

  const message = useUnit($notifications);

  const readingNotifications = useUnit(markAsRead);

  const lobbyMessageHandler = useCallback(
    (message: string, isNotError?: boolean, url?: string) => {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: message,
          isError: !isNotError,
        },
        data: {
          callback: () => navigate(url || "/"),
        },
      });
    },
    [navigate],
  );

  const onRoomSetState = useCallback(
    (data: PRoomR | HrRoom) => {
      dispatch(roomSetState(data));
    },
    [dispatch],
  );

  const onRoomConnectPlayer = useCallback(
    (data: Join) => {
      dispatch(roomConnectPlayer(data));
    },
    [dispatch],
  );

  const onRoomConnectedPlayersCountChange = useCallback(
    (data: PRoomChanged) => {
      dispatch(roomConnectedPlayersCountChange(data));
    },
    [dispatch],
  );

  const onRoomLeavePlayer = useCallback(
    (data: Quit) => {
      dispatch(roomLeavePlayer(data));

      if (keycloak?.subject === data.pid && data.reason === QuitReason.KICK) {
        navigate(`/my-competence`);

        openPopup({
          name: EPopupName.BASE_MESSAGE_POPUP,
          message: {
            text: t("popup.excluded.byHr"),
            isError: true,
          },
        });
      }
    },
    [dispatch, navigate, keycloak?.subject, t],
  );

  const onRoomChangeRole = useCallback(
    (data: Role) => {
      dispatch(roomChangeRole(data));
    },
    [dispatch],
  );

  const onRoomChangeTeamRole = useCallback(
    (data: Team) => {
      dispatch(roomChangeTeamRole(data));
    },
    [dispatch],
  );

  const onRoomStartGame = useCallback(
    (data: Play) => {
      const resultUrl: Play["url"] = !!data.url
        ? data.url.replace("{locale}", i18n.language)
        : "";

      dispatch(setAssessmentStatus(AssessmentStatus.InProgress));

      readingNotifications();
      try {
        const audio: HTMLAudioElement = new Audio(
          `${pathToCloud}/audio/game-start.mp3`,
        );

        audio.play();
      } catch (err) {
        console.error(err);
      }

      window.open(resultUrl, "_blank");
    },
    [dispatch, i18n.language, pathToCloud],
  );

  const onRoomResultGame = useCallback(
    (data: Results) => {
      dispatch(setAssessmentStatus(AssessmentStatus.Finished));

      const player: ResultsPlayer | undefined = data.players.find(
        (item: ResultsPlayer) => item.pid === userId,
      );

      if (!!player?.place) {
        dispatch(roomClear());
      }

      if (isPlayerClientId) {
        lobbyMessageHandler(t("popup.session.finished"), true);
      }
    },
    [dispatch, isPlayerClientId, lobbyMessageHandler, t, userId],
  );

  const onRoomUpdateRates = useCallback(
    (data: Quit) => {
      if (
        keycloak?.subject === data.pid &&
        data.reason === QuitReason.TIMEOUT
      ) {
        dispatch(setAssessmentStatus(AssessmentStatus.Finished));
        dispatch(roomClear());
        readingNotifications();
        getSocialProfileInfo({ pId: data.pid });
        lobbyMessageHandler(t("notification.timeout"), true);
      }
    },
    [keycloak?.subject, dispatch, lobbyMessageHandler, t],
  );

  const onRoomError = useCallback(
    (data: Err) => {
      const isFullLobby: boolean = data.type === Error.FULL_LOBBY;

      const isForbidden: boolean = data.type === Error.FORBIDDEN;

      const isNotFound: boolean = data.type === Error.NOT_FOUND;

      const isInternalServerError: boolean =
        data.type === Error.INTERNAL_SERVER_ERROR;

      const isSessionInProgress: boolean =
        data.type === Error.ASSESSMENT_IN_PROGRESS;

      const isSessionFinished: boolean =
        data.type === Error.ASSESSMENT_FINISHED;

      const isRoleOccupied: boolean =
        data.type === Error.BAD_REQUEST &&
        data.err === EInputError.ROLE_IS_OCCUPIED;

      if (isFullLobby) {
        lobbyMessageHandler(t("popup.session.fullLobby"));
      }

      if (isSessionInProgress) {
        lobbyMessageHandler(t("popup.session.assessmentInProgress"));
      }

      if (isSessionFinished) {
        lobbyMessageHandler(t("popup.session.assessmentFinished"));
      }

      if (isRoleOccupied) {
        dispatch(roomError(true));

        navigate(`/my-competence`);
      }

      if (isForbidden) {
        lobbyMessageHandler(t("popup.session.forbidden"));
      }

      if (isNotFound || isInternalServerError) {
        dispatch(roomError(true));

        if (data.err) {
          lobbyMessageHandler(data.err);
        }
      }
    },
    [dispatch, lobbyMessageHandler, t, navigate],
  );

  const onRoomState = useCallback(
    (data: Active) => {
      dispatch(
        roomSetState({
          aid: data.aid,
          gid: data.gid,
          aType: data.aType,
          gType: data.gType,
          playUrl: data.playUrl,
          aStatus: AssessmentStatusWS4.IN_PROGRESS,
          title: data.title,
          closed_session: data.closed_session,
          createdAt: data.createdAt,
          required_participation: data.required_participation,
          competence_rating_bankrupt: data.competence_rating_bankrupt,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.pRoomR(onRoomSetState);
      socketPlayerRoom?.pRoomNR(onRoomSetState);
    }

    if (isHrClientId) {
      socketHrRoom?.onStateChange(onRoomSetState);
    }

    return () => {
      socketPlayerRoom?.pRoomR(onRoomSetState)();
      socketPlayerRoom?.pRoomNR(onRoomSetState)();
      socketHrRoom?.onStateChange(onRoomSetState)();
    };
  }, [
    onRoomSetState,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.pRoomChanged(onRoomConnectedPlayersCountChange);
    }

    return () => {
      socketPlayerRoom?.pRoomChanged(onRoomConnectedPlayersCountChange)();
    };
  }, [
    onRoomConnectedPlayersCountChange,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketsModel.v4.initGameV4RoomPlayer();
      socketsModel.v4.initGameV4UpdateRoom();
    }

    if (isHrClientId) {
      socketsModel.v4.initGameV4RoomHr();
      socketsModel.v4.initGameV4UpdateRoom();
    }
  }, [isPlayerClientId, isHrClientId]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.onJoin(onRoomConnectPlayer);
    }

    if (isHrClientId) {
      socketHrRoom?.onJoin(onRoomConnectPlayer);
    }

    return () => {
      socketPlayerRoom?.onJoin(onRoomConnectPlayer)();
      socketHrRoom?.onJoin(onRoomConnectPlayer)();
    };
  }, [
    onRoomConnectPlayer,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.onQuit(onRoomLeavePlayer);
    }

    if (isHrClientId) {
      socketHrRoom?.onQuit(onRoomLeavePlayer);
    }

    return () => {
      socketPlayerRoom?.onQuit(onRoomLeavePlayer)();
      socketHrRoom?.onQuit(onRoomLeavePlayer)();
    };
  }, [
    onRoomLeavePlayer,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.onPlay(onRoomStartGame);
    }

    if (isHrClientId) {
      socketHrRoom?.onPlay(onRoomStartGame);
    }

    return () => {
      socketPlayerRoom?.onPlay(onRoomStartGame)();
      socketHrRoom?.onPlay(onRoomStartGame)();
    };
  }, [
    onRoomStartGame,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.onRoleChange(onRoomChangeRole);
    }

    if (isHrClientId) {
      socketHrRoom?.onRoleChange(onRoomChangeRole);
    }

    return () => {
      socketPlayerRoom?.onRoleChange(onRoomChangeRole)();
      socketHrRoom?.onRoleChange(onRoomChangeRole)();
    };
  }, [
    onRoomChangeRole,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.onTeamChange(onRoomChangeTeamRole);
    }

    if (isHrClientId) {
      socketHrRoom?.onTeamChange(onRoomChangeTeamRole);
    }

    return () => {
      socketPlayerRoom?.onTeamChange(onRoomChangeTeamRole)();
      socketHrRoom?.onTeamChange(onRoomChangeTeamRole)();
    };
  }, [
    onRoomChangeTeamRole,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    socketPlayerRoom?.onResults(onRoomResultGame);

    return () => {
      socketPlayerRoom?.onResults(onRoomResultGame)();
    };
  }, [onRoomResultGame, isPlayerClientId, isHrClientId, socketPlayerRoom]);

  useEffect(() => {
    socketPlayerRoom?.onActive(onRoomState);

    return () => {
      socketPlayerRoom?.onActive(onRoomState)();
    };
  }, [onRoomState, socketPlayerRoom]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketPlayerRoom?.onError(onRoomError);
    }

    if (isHrClientId) {
      socketHrRoom?.onError(onRoomError);
    }

    return () => {
      socketPlayerRoom?.onError(onRoomError)();
      socketHrRoom?.onError(onRoomError)();
    };
  }, [
    onRoomError,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoom,
    socketHrRoom,
  ]);

  useEffect(() => {
    if (isPlayerClientId) {
      socketUpdateRoom?.onQuit(onRoomUpdateRates);
    }

    if (isHrClientId) {
      socketUpdateRoom?.onQuit(onRoomUpdateRates);
    }

    return () => {
      socketUpdateRoom?.onQuit(onRoomUpdateRates)();
      socketUpdateRoom?.onQuit(onRoomUpdateRates)();
    };
  }, [
    onRoomUpdateRates,
    isPlayerClientId,
    isHrClientId,
    socketHrRoom,
    socketUpdateRoom,
  ]);
};
