import { createDomain, sample } from "effector";
import { confirmationRelativePost } from "../../../../../shared/api/hr/moderation/model/relatives";
import {
  ConfirmationRelativeResponse,
  RelativeApiConfirmationRelativeRequest,
} from "../../../../../generated/social";

export const postConfirmationRelativeDomain = createDomain(
  "postConfirmationRelativePublic",
);

// Effects

export const fetchPostConfirmationRelativeFx =
  postConfirmationRelativeDomain.createEffect(confirmationRelativePost);

// Stores

export const $relativeStatus =
  postConfirmationRelativeDomain.createStore<ConfirmationRelativeResponse | null>(
    null,
  );

export const $isLoading =
  postConfirmationRelativeDomain.createStore<boolean>(false);

// Events

export const fetchPostConfirmationRelative =
  postConfirmationRelativeDomain.createEvent<RelativeApiConfirmationRelativeRequest>();

sample({
  clock: fetchPostConfirmationRelative,
  target: fetchPostConfirmationRelativeFx,
});

sample({
  clock: fetchPostConfirmationRelativeFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchPostConfirmationRelativeFx.doneData,
  target: $relativeStatus,
});
