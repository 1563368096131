import { createDomain, sample } from "effector";

import { ResponseSms } from "src/generated/notification";

import { getSmsListMessages } from "src/shared/api/admin/notification/sms-notification";

import { Pagination, QueryParams } from "src/shared/api/base";

import { queryParamsToGetSmsListAdapter } from "src/entities/admin/notification/sms-notification/lib";

// Domain

export const smsNotificationDomain = createDomain("sms-notification");

// Events

export const getSmsList = smsNotificationDomain.createEvent<QueryParams>();

// Effects

export const getSmsListFx =
  smsNotificationDomain.createEffect(getSmsListMessages);

// Stores

export const $smsList = smsNotificationDomain.createStore<ResponseSms[]>([]);

export const $pagination = smsNotificationDomain.createStore<Pagination | null>(
  null,
);

export const $queryParams = smsNotificationDomain.createStore<QueryParams>({});

export const $isLoading = smsNotificationDomain.createStore<boolean>(false);

// Logic

sample({
  clock: getSmsList,
  fn: queryParamsToGetSmsListAdapter,
  target: getSmsListFx,
});

sample({
  clock: getSmsList,
  target: $queryParams,
});

sample({
  clock: getSmsListFx.doneData,
  fn: ({ listSms }) => listSms,
  target: $smsList,
});

sample({
  clock: getSmsListFx.doneData,
  fn: ({ pagination }) => pagination,
  target: $pagination,
});

sample({ clock: getSmsListFx.pending, target: $isLoading });
