import { createDomain, sample } from "effector";

import {
  PlayerRelativeInfoRes,
  RelativeApiAddCandidateRelativeRequest,
} from "../../../../generated/social";
import { postAddCandidateRelative } from "../../../../shared/api/public/relatives/relatives";
import {
  EPopupName,
  openResponseErrorPopup,
} from "../../../../shared/components";
import { openPopup } from "../../../../shared/components/base-popup/model";

export const postAddCandidateRelativeDomain = createDomain(
  "postAddCandidateRelativePublic",
);

// Effects

export const fetchPostAddCandidateRelativeFx =
  postAddCandidateRelativeDomain.createEffect(postAddCandidateRelative);

// Stores

export const $candidateStatus =
  postAddCandidateRelativeDomain.createStore<PlayerRelativeInfoRes | null>(
    null,
  );

export const $isLoading =
  postAddCandidateRelativeDomain.createStore<boolean>(false);

// Events

export const fetchPostAddCandidateRelative =
  postAddCandidateRelativeDomain.createEvent<RelativeApiAddCandidateRelativeRequest>();

sample({
  clock: fetchPostAddCandidateRelative,
  target: fetchPostAddCandidateRelativeFx,
});

sample({
  clock: fetchPostAddCandidateRelativeFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchPostAddCandidateRelativeFx.doneData,
  target: $candidateStatus,
});

fetchPostAddCandidateRelativeFx.failData.watch((error: any) => {
  openResponseErrorPopup(error.response?.data?.err?.msg);
});

fetchPostAddCandidateRelativeFx.doneData.watch(() => {
  openPopup({ name: EPopupName.WAITING_RELATIVE_STATUS_POPUP });
});
