export enum Error {
  BAD_REQUEST = "400",
  FORBIDDEN = "403",
  NOT_FOUND = "404",
  INTERNAL_SERVER_ERROR = "500",
  ASSESSMENT_FINISHED = "997",
  ASSESSMENT_IN_PROGRESS = "998",
  FULL_LOBBY = "999",
}

export enum EInputError {
  EMPTY = "empty",
  MORE_THAN_MAX = "more-than-max",
  LESS_THAN_MIN = "less-than-min",
  INVALID_EMAIL = "invalid-email",
  NOT_FULL_MOBILE = "not-full-mobile",
  ROLE_IS_OCCUPIED = "role is occupied",
}
