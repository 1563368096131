import { FC } from "react";

import { t } from "src/shared/helpers";

import "./news-card.scss";

type NewsCardProps = {
  title: string;
  description: string;
  link?: string;
};

export const NewsCard: FC<NewsCardProps> = ({ title, description, link }) => (
  <div className="news-tile">
    <h6 className="news-tile__title">{title}</h6>
    <p className="news-tile__description">{description}</p>
    {link && (
      <a className="news-tile__link" href={link}>
        {t("notification.go.button")}
      </a>
    )}
  </div>
);
