import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { Pagination } from "../../../../../shared/api/base";
import {
  AssessmentDownloadHistoryDto,
  AssessmentHistoryApiGetDownloadAssessmentHistoryRequest,
  KeycloakApiPostAssessmentHistoryRequest,
} from "src/generated/social";
import {
  getSessionsExportHistory,
  postSessionsExportHistory,
} from "../../../../../shared/api/admin/histories/model/export-history";
// Domain

export const exportSessionsHistoryDomain = createDomain(
  "exportSessionsHistoryAdmin",
);

// Events

export const fetchExportSessionsHistory =
  exportSessionsHistoryDomain.createEvent<AssessmentHistoryApiGetDownloadAssessmentHistoryRequest>();

export const postExportSessionsHistory =
  exportSessionsHistoryDomain.createEvent<KeycloakApiPostAssessmentHistoryRequest>();

// Effects

export const fetchExportSessionsHistoryFx =
  exportSessionsHistoryDomain.createEffect(getSessionsExportHistory);

export const postExportSessionsHistoryFx =
  exportSessionsHistoryDomain.createEffect(postSessionsExportHistory);

// Stores

export const $exportSessionsHistoryPlayers =
  exportSessionsHistoryDomain.createStore<AssessmentDownloadHistoryDto[]>([]);

export const $pagination =
  exportSessionsHistoryDomain.createStore<Pagination>(null);

export const $queryParams =
  exportSessionsHistoryDomain.createStore<AssessmentHistoryApiGetDownloadAssessmentHistoryRequest>(
    {},
  );

export const $isLoadingAssessments =
  exportSessionsHistoryDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchExportSessionsHistory,
  target: fetchExportSessionsHistoryFx,
});

sample({
  clock: postExportSessionsHistory,
  target: postExportSessionsHistoryFx,
});

sample({
  clock: fetchExportSessionsHistory,
  target: $queryParams,
});

sample({
  clock: fetchExportSessionsHistoryFx.pending,
  target: $isLoadingAssessments,
});

spread({
  source: fetchExportSessionsHistoryFx.doneData.map(
    ({ assessments, pagination }) => ({
      assessments,
      pagination: pagination || null,
    }),
  ),
  targets: {
    assessments: $exportSessionsHistoryPlayers,
    pagination: $pagination,
  },
});
