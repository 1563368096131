import { LocStrDto, UpdSidebarReq } from "../../../../generated/social";
import {
  ESidebarInformationActions,
  ICleanSidebarInformation,
  IFailureSidebarInformationGet,
  IFailureSidebarInformationPost,
  IRequestSidebarInformationGet,
  IRequestSidebarInformationPost,
  ISuccessSidebarInformationGet,
  ISuccessSidebarInformationPost,
} from "./actionTypes";

export const cleanSidebarInformation = (): ICleanSidebarInformation => ({
  type: ESidebarInformationActions.CLEAN_SIDEBAR_INFORMATION,
});

export const requestSidebarInformationGet = (payload: {
  callback?: () => void;
}): IRequestSidebarInformationGet => ({
  type: ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_GET,
  payload,
});

export const requestSidebarInformationPost = (payload: {
  item: UpdSidebarReq;
  callback?: () => void;
}): IRequestSidebarInformationPost => ({
  type: ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_POST,
  payload,
});

export const successSidebarInformationGet = (
  payload: LocStrDto,
): ISuccessSidebarInformationGet => ({
  type: ESidebarInformationActions.SUCCESS_SIDEBAR_INFORMATION_GET,
  payload,
});

export const successSidebarInformationPost =
  (): ISuccessSidebarInformationPost => ({
    type: ESidebarInformationActions.SUCCESS_SIDEBAR_INFORMATION_POST,
  });

export const failureSidebarInformationGet =
  (): IFailureSidebarInformationGet => ({
    type: ESidebarInformationActions.FAILURE_SIDEBAR_INFORMATION_GET,
  });

export const failureSidebarInformationPost =
  (): IFailureSidebarInformationPost => ({
    type: ESidebarInformationActions.FAILURE_SIDEBAR_INFORMATION_POST,
  });

export type TSidebarInformationActions =
  | ICleanSidebarInformation
  | IFailureSidebarInformationGet
  | IFailureSidebarInformationPost
  | IRequestSidebarInformationGet
  | IRequestSidebarInformationPost
  | ISuccessSidebarInformationGet
  | ISuccessSidebarInformationPost;
