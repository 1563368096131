import { FC, Fragment, lazy, ReactElement, Suspense } from "react";
import { IBaseTextEditorProps } from "../../../models/formFields";

const BaseTextEditorLazy = lazy(() => import("./base-text-editor"));

export const BaseTextEditor: FC<IBaseTextEditorProps> = ({
  ...props
}): ReactElement => {
  return (
    <Suspense fallback={<Fragment />}>
      <BaseTextEditorLazy {...props} />
    </Suspense>
  );
};
