import { IDataGridRow } from "src/shared/models/dataGrid";

import { getAvatarUrl } from "src/shared/helpers/pathToUserFile";

import { splitStrings } from "src/shared/helpers/splitStrings";
import i18n from "src/shared/i18n/i18n";
import { AssessmentMemberResDto } from "src/generated/game";
import {
  IPlayer,
  ITeam,
} from "src/pages/sessions/session-page/components/session-table/session-table";

export const invitedPlayersToRowsAdapter = (
  players: AssessmentMemberResDto[],
  teams: ITeam[],
  updatedTeams?: IPlayer[],
) => {
  const updateTeams = updatedTeams?.map<IPlayer>((player) => {
    return {
      pid: player?.pid,
      role: player?.role,
      teamNum: player?.teamNum,
      corpNum: player?.corpNum,
      areaNum: player?.areaNum,
      teamName: player?.teamName,
      user_role: player?.user_role,
    };
  });

  return players.map<IDataGridRow>((player) => {
    const fullName = splitStrings([player.firstName, player.lastName]);

    const isDistributed = player.isDistributed;

    const teamName = teams.find(
      (team) =>
        team.teamNum === player.teamNum || team.corpNum === player.corpNum,
    )?.teamName?.value;

    const team =
      player.teamNum || player.corpNum
        ? `${i18n.t(`playerRoles.${teamName}`)} №${
            player.corpNum ?? player.teamNum
          }`
        : null;

    const areaNum =
      player.areaNum && player.role !== "ceo"
        ? `${i18n.t("common.area")} ${player.areaNum}`
        : null;

    const role = player.role ? i18n.t(`playerRoles.${player.role}`) : null;

    const currentRole = () => {
      const updateRole = updateTeams?.find(
        (team) => team.pid === player.playerId,
      )?.role;

      if (isDistributed) {
        player["isDistributed"] = false;
        return role;
      }

      return updateRole ? i18n.t(`playerRoles.${updateRole}`) : role;
    };

    const currentTeam = () => {
      const updateTeam = updateTeams?.find(
        (team) => team.pid === player.playerId,
      );

      if (isDistributed) {
        player["isDistributed"] = false;
        return team;
      }

      if (updateTeam && (updateTeam.teamName || updateTeam.corpNum)) {
        return `${i18n.t(`playerRoles.${updateTeam.teamName}`)} №${
          updateTeam.teamNum || updateTeam.corpNum
        }`;
      }
      return team;
    };

    const currentArea = () => {
      const updateArea = updateTeams?.find(
        (team) => team.pid === player.playerId && team.role !== "ceo",
      )?.areaNum;

      if (isDistributed) {
        player["isDistributed"] = false;
        return areaNum;
      }

      return updateArea ? `${i18n.t("common.area")} ${updateArea}` : areaNum;
    };

    return {
      id: player.playerId,
      firstName: player.firstName,
      lastName: player.lastName,
      fullName,
      phone: player.phone,
      avatarProps: {
        alt: fullName,
        fullName,
        customPath: getAvatarUrl(player.playerId),
        userId: player.playerId,
        link: `/ratings/rating/${player.playerId}`,
      },
      selected: false,
      email: player.email,
      studyWorkPlace: player.studyWorkPlace,
      locality: player.locality,
      distributeButton: i18n.t("allocate-player.popup.button.distribute"),
      team: currentTeam(),
      areaNum: currentArea(),
      role: currentRole(),
      isDistributed: player.isDistributed,
    };
  });
};
