/* tslint:disable */
/* eslint-disable */
/**
 * Alabuga Export Service
 * Alabuga Export Service
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 * Тип Enum для валидации RejectionReason
 * @export
 * @enum {string}
 */
export enum RejectionReasonEnum {
  Grade8 = "grade-8",
  Changed = "changed",
  InterestedVo = "interested-vo",
  Employment = "employment",
  ParentsRefusal = "parents_refusal",
  JustPlay = "just-play",
  Reviews = "reviews",
  TooFar = "too-far",
  Grade7OrLower = "grade-7-or-lower",
  TeacherForced = "teacher-forced",
  Other = "other",
  NoSelected = "no-selected",
}

/**
 * Тип Enum для валидации RoleInRelatedProject
 * @export
 * @enum {string}
 */
export enum RoleInRelatedProjectEnum {
  PersonnelHr = "personnel-hr",
  PersonnelAdmin = "personnel-admin",
  Teacher = "teacher",
  AdministratorSchedule = "administrator-schedule",
  NoSelected = "no-selected",
}

/**
 * Тип Enum для валидации StatusCompany
 * @export
 * @enum {string}
 */
export enum StatusCompanyEnum {
  Student = "student",
  Employee = "employee",
  Fired = "fired",
  Enrollee = "enrollee",
  Other = "other",
  CandidateStudent = "candidate-student",
  CandidateEmployee = "candidate-employee",
  CandidateRelatives = "candidate-relatives",
  Relative = "relative",
  NoSelected = "no-selected",
}

/**
 * Тип Enum для валидации StatusEmployment
 * @export
 * @enum {string}
 */
export enum StatusEmploymentEnum {
  Call = "call",
  PlanToCome = "plan-to-come",
  CurrentIntramuralStage = "current-intramural-stage",
  PassedTheSelection = "passed-the-selection",
  NoSelectionPass = "no-selection-pass",
  SubmittedDocuments = "submitted-documents",
  InTheProcessOfEmployment = "in-the-process-of-employment",
  Employed = "employed",
  DoesNotAnswer = "does-not-answer",
  Thinks = "thinks",
  OnePointNotScored = "one-point-not-scored",
  OnePointScored = "one-point-scored",
  StageRegister = "stage-register",
  StageArrived = "stage-arrived",
  Rejection = "rejection",
  Other = "other",
  NoSelected = "no-selected",
}

/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string | number>}
   * @memberof ValidationError
   */
  loc: Array<string | number>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Health check for kubernetes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endpointHealthGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Health check for kubernetes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async endpointHealthGet(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.endpointHealthGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Health check for kubernetes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endpointHealthGet(options?: any): AxiosPromise<any> {
      return localVarFp
        .endpointHealthGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Health check for kubernetes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public endpointHealthGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .endpointHealthGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExportFromSocialApi - axios parameter creator
 * @export
 */
export const ExportFromSocialApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary GET Ratings table in csv
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {Array<string>} [email]
     * @param {Array<boolean>} [emailVerified]
     * @param {boolean} [emailFull]
     * @param {Array<string>} [gender]
     * @param {Array<string>} [groupCode]
     * @param {boolean} [groupCodeNotNull]
     * @param {Array<string>} [studyWorkPlace]
     * @param {boolean} [studyWorkPlaceNull]
     * @param {Array<string>} [desiredSpecialty]
     * @param {boolean} [desiredSpecialtyNull]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {boolean} [districtNull]
     * @param {Array<number>} [district]
     * @param {Array<number>} [school]
     * @param {boolean} [schoolNull]
     * @param {boolean} [regionNull]
     * @param {Array<number>} [region]
     * @param {Array<string>} [locality]
     * @param {boolean} [localityNull]
     * @param {boolean} [birthdateNull]
     * @param {string} [birthdateFrom]
     * @param {string} [birthdateTo]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [analyticsTenacityTo]
     * @param {number} [analyticsTenacityFrom]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [commandTenacityFrom]
     * @param {number} [commandTenacityTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {number} [communicationTenacityFrom]
     * @param {number} [communicationTenacityTo]
     * @param {number} [thinking3dFrom]
     * @param {number} [thinking3dTo]
     * @param {number} [thinking3dTenacityFrom]
     * @param {number} [thinking3dTenacityTo]
     * @param {number} [economicsFrom]
     * @param {number} [economicsTo]
     * @param {number} [economicsTenacityFrom]
     * @param {number} [economicsTenacityTo]
     * @param {number} [jurisprudenceFrom]
     * @param {number} [jurisprudenceTo]
     * @param {number} [jurisprudenceTenacityFrom]
     * @param {number} [jurisprudenceTenacityTo]
     * @param {number} [microelectronicsTo]
     * @param {number} [microelectronicsFrom]
     * @param {number} [microelectronicsTenacityTo]
     * @param {number} [microelectronicsTenacityFrom]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {boolean} [lastPlayNull]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {boolean} [lastActivityNull]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<RoleInRelatedProjectEnum>} [roleInRelatedProjects]
     * @param {Array<StatusCompanyEnum>} [statusCompany]
     * @param {Array<RejectionReasonEnum>} [rejectionReason]
     * @param {Array<string>} [acquisitionChannel]
     * @param {boolean} [acquisitionChannelNull]
     * @param {Array<StatusEmploymentEnum>} [statusEmployment]
     * @param {boolean} [confirmationSingle]
     * @param {string} [dateOfGettingAnalyticsLvl05From]
     * @param {string} [dateOfGettingAnalyticsLvl05To]
     * @param {string} [dateOfGettingAnalyticsLvl1From]
     * @param {string} [dateOfGettingAnalyticsLvl1To]
     * @param {Array<string>} [dumpedFields]
     * @param {Array<number>} [secretCode]
     * @param {Array<string>} [orderBy]          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportRatingToCSV: async (
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      desiredStudyPlace?: Array<string>,
      country?: Array<string>,
      countryNull?: boolean,
      districtNull?: boolean,
      district?: Array<number>,
      school?: Array<number>,
      schoolNull?: boolean,
      regionNull?: boolean,
      region?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityTo?: number,
      analyticsTenacityFrom?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityNull?: boolean,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>,
      statusCompany?: Array<StatusCompanyEnum>,
      rejectionReason?: Array<RejectionReasonEnum>,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      statusEmployment?: Array<StatusEmploymentEnum>,
      confirmationSingle?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      dumpedFields?: Array<string>,
      secretCode?: Array<number>,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/export_from_social/ratings_to_csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (name) {
        localVarQueryParameter["name"] = name;
      }

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (email) {
        localVarQueryParameter["email"] = email;
      }

      if (emailVerified) {
        localVarQueryParameter["emailVerified"] = emailVerified;
      }

      if (emailFull !== undefined) {
        localVarQueryParameter["emailFull"] = emailFull;
      }

      if (gender) {
        localVarQueryParameter["gender"] = gender;
      }

      if (groupCode) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (groupCodeNotNull !== undefined) {
        localVarQueryParameter["groupCodeNotNull"] = groupCodeNotNull;
      }

      if (studyWorkPlace) {
        localVarQueryParameter["studyWorkPlace"] = studyWorkPlace;
      }

      if (studyWorkPlaceNull !== undefined) {
        localVarQueryParameter["studyWorkPlaceNull"] = studyWorkPlaceNull;
      }

      if (desiredSpecialty) {
        localVarQueryParameter["desiredSpecialty"] = desiredSpecialty;
      }

      if (desiredSpecialtyNull !== undefined) {
        localVarQueryParameter["desiredSpecialtyNull"] = desiredSpecialtyNull;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      if (country) {
        localVarQueryParameter["country"] = country;
      }

      if (countryNull !== undefined) {
        localVarQueryParameter["countryNull"] = countryNull;
      }

      if (districtNull !== undefined) {
        localVarQueryParameter["districtNull"] = districtNull;
      }

      if (district) {
        localVarQueryParameter["district"] = district;
      }

      if (school) {
        localVarQueryParameter["school"] = school;
      }

      if (schoolNull !== undefined) {
        localVarQueryParameter["schoolNull"] = schoolNull;
      }

      if (regionNull !== undefined) {
        localVarQueryParameter["regionNull"] = regionNull;
      }

      if (region) {
        localVarQueryParameter["region"] = region;
      }

      if (locality) {
        localVarQueryParameter["locality"] = locality;
      }

      if (localityNull !== undefined) {
        localVarQueryParameter["localityNull"] = localityNull;
      }

      if (birthdateNull !== undefined) {
        localVarQueryParameter["birthdateNull"] = birthdateNull;
      }

      if (birthdateFrom !== undefined) {
        localVarQueryParameter["birthdateFrom"] = birthdateFrom;
      }

      if (birthdateTo !== undefined) {
        localVarQueryParameter["birthdateTo"] = birthdateTo;
      }

      if (gPlaceFrom !== undefined) {
        localVarQueryParameter["gPlaceFrom"] = gPlaceFrom;
      }

      if (gPlaceTo !== undefined) {
        localVarQueryParameter["gPlaceTo"] = gPlaceTo;
      }

      if (gRateFrom !== undefined) {
        localVarQueryParameter["gRateFrom"] = gRateFrom;
      }

      if (gRateTo !== undefined) {
        localVarQueryParameter["gRateTo"] = gRateTo;
      }

      if (analyticsFrom !== undefined) {
        localVarQueryParameter["analyticsFrom"] = analyticsFrom;
      }

      if (analyticsTo !== undefined) {
        localVarQueryParameter["analyticsTo"] = analyticsTo;
      }

      if (analyticsTenacityTo !== undefined) {
        localVarQueryParameter["analyticsTenacityTo"] = analyticsTenacityTo;
      }

      if (analyticsTenacityFrom !== undefined) {
        localVarQueryParameter["analyticsTenacityFrom"] = analyticsTenacityFrom;
      }

      if (commandFrom !== undefined) {
        localVarQueryParameter["commandFrom"] = commandFrom;
      }

      if (commandTo !== undefined) {
        localVarQueryParameter["commandTo"] = commandTo;
      }

      if (commandTenacityFrom !== undefined) {
        localVarQueryParameter["commandTenacityFrom"] = commandTenacityFrom;
      }

      if (commandTenacityTo !== undefined) {
        localVarQueryParameter["commandTenacityTo"] = commandTenacityTo;
      }

      if (communicationFrom !== undefined) {
        localVarQueryParameter["communicationFrom"] = communicationFrom;
      }

      if (communicationTo !== undefined) {
        localVarQueryParameter["communicationTo"] = communicationTo;
      }

      if (communicationTenacityFrom !== undefined) {
        localVarQueryParameter["communicationTenacityFrom"] =
          communicationTenacityFrom;
      }

      if (communicationTenacityTo !== undefined) {
        localVarQueryParameter["communicationTenacityTo"] =
          communicationTenacityTo;
      }

      if (thinking3dFrom !== undefined) {
        localVarQueryParameter["thinking3dFrom"] = thinking3dFrom;
      }

      if (thinking3dTo !== undefined) {
        localVarQueryParameter["thinking3dTo"] = thinking3dTo;
      }

      if (thinking3dTenacityFrom !== undefined) {
        localVarQueryParameter["thinking3dTenacityFrom"] =
          thinking3dTenacityFrom;
      }

      if (thinking3dTenacityTo !== undefined) {
        localVarQueryParameter["thinking3dTenacityTo"] = thinking3dTenacityTo;
      }

      if (economicsFrom !== undefined) {
        localVarQueryParameter["economicsFrom"] = economicsFrom;
      }

      if (economicsTo !== undefined) {
        localVarQueryParameter["economicsTo"] = economicsTo;
      }

      if (economicsTenacityFrom !== undefined) {
        localVarQueryParameter["economicsTenacityFrom"] = economicsTenacityFrom;
      }

      if (economicsTenacityTo !== undefined) {
        localVarQueryParameter["economicsTenacityTo"] = economicsTenacityTo;
      }

      if (jurisprudenceFrom !== undefined) {
        localVarQueryParameter["jurisprudenceFrom"] = jurisprudenceFrom;
      }

      if (jurisprudenceTo !== undefined) {
        localVarQueryParameter["jurisprudenceTo"] = jurisprudenceTo;
      }

      if (jurisprudenceTenacityFrom !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityFrom"] =
          jurisprudenceTenacityFrom;
      }

      if (jurisprudenceTenacityTo !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityTo"] =
          jurisprudenceTenacityTo;
      }

      if (microelectronicsTo !== undefined) {
        localVarQueryParameter["microelectronicsTo"] = microelectronicsTo;
      }

      if (microelectronicsFrom !== undefined) {
        localVarQueryParameter["microelectronicsFrom"] = microelectronicsFrom;
      }

      if (microelectronicsTenacityTo !== undefined) {
        localVarQueryParameter["microelectronicsTenacityTo"] =
          microelectronicsTenacityTo;
      }

      if (microelectronicsTenacityFrom !== undefined) {
        localVarQueryParameter["microelectronicsTenacityFrom"] =
          microelectronicsTenacityFrom;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (lastPlayNull !== undefined) {
        localVarQueryParameter["lastPlayNull"] = lastPlayNull;
      }

      if (lastPlayFrom !== undefined) {
        localVarQueryParameter["lastPlayFrom"] = lastPlayFrom;
      }

      if (lastPlayTo !== undefined) {
        localVarQueryParameter["lastPlayTo"] = lastPlayTo;
      }

      if (lastActivityNull !== undefined) {
        localVarQueryParameter["lastActivityNull"] = lastActivityNull;
      }

      if (lastActivityFrom !== undefined) {
        localVarQueryParameter["lastActivityFrom"] = lastActivityFrom;
      }

      if (lastActivityTo !== undefined) {
        localVarQueryParameter["lastActivityTo"] = lastActivityTo;
      }

      if (roleInRelatedProjects) {
        localVarQueryParameter["roleInRelatedProjects"] = roleInRelatedProjects;
      }

      if (statusCompany) {
        localVarQueryParameter["statusCompany"] = statusCompany;
      }

      if (rejectionReason) {
        localVarQueryParameter["rejectionReason"] = rejectionReason;
      }

      if (acquisitionChannel) {
        localVarQueryParameter["acquisitionChannel"] = acquisitionChannel;
      }

      if (acquisitionChannelNull !== undefined) {
        localVarQueryParameter["acquisitionChannelNull"] =
          acquisitionChannelNull;
      }

      if (statusEmployment) {
        localVarQueryParameter["statusEmployment"] = statusEmployment;
      }

      if (confirmationSingle !== undefined) {
        localVarQueryParameter["confirmationSingle"] = confirmationSingle;
      }

      if (dateOfGettingAnalyticsLvl05From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5From"] =
          dateOfGettingAnalyticsLvl05From;
      }

      if (dateOfGettingAnalyticsLvl05To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5To"] =
          dateOfGettingAnalyticsLvl05To;
      }

      if (dateOfGettingAnalyticsLvl1From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1From"] =
          dateOfGettingAnalyticsLvl1From;
      }

      if (dateOfGettingAnalyticsLvl1To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1To"] =
          dateOfGettingAnalyticsLvl1To;
      }

      if (dumpedFields) {
        localVarQueryParameter["dumped_fields"] = dumpedFields;
      }

      if (secretCode) {
        localVarQueryParameter["secretCode"] = secretCode;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary GET Ratings table in xlsx
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {Array<string>} [email]
     * @param {Array<boolean>} [emailVerified]
     * @param {boolean} [emailFull]
     * @param {Array<string>} [gender]
     * @param {Array<string>} [groupCode]
     * @param {boolean} [groupCodeNotNull]
     * @param {Array<string>} [studyWorkPlace]
     * @param {boolean} [studyWorkPlaceNull]
     * @param {Array<string>} [desiredSpecialty]
     * @param {boolean} [desiredSpecialtyNull]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {boolean} [districtNull]
     * @param {Array<number>} [district]
     * @param {Array<number>} [school]
     * @param {boolean} [schoolNull]
     * @param {boolean} [regionNull]
     * @param {Array<number>} [region]
     * @param {Array<string>} [locality]
     * @param {boolean} [localityNull]
     * @param {boolean} [birthdateNull]
     * @param {string} [birthdateFrom]
     * @param {string} [birthdateTo]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [analyticsTenacityTo]
     * @param {number} [analyticsTenacityFrom]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [commandTenacityFrom]
     * @param {number} [commandTenacityTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {number} [communicationTenacityFrom]
     * @param {number} [communicationTenacityTo]
     * @param {number} [thinking3dFrom]
     * @param {number} [thinking3dTo]
     * @param {number} [thinking3dTenacityFrom]
     * @param {number} [thinking3dTenacityTo]
     * @param {number} [economicsFrom]
     * @param {number} [economicsTo]
     * @param {number} [economicsTenacityFrom]
     * @param {number} [economicsTenacityTo]
     * @param {number} [jurisprudenceFrom]
     * @param {number} [jurisprudenceTo]
     * @param {number} [jurisprudenceTenacityFrom]
     * @param {number} [jurisprudenceTenacityTo]
     * @param {number} [microelectronicsTo]
     * @param {number} [microelectronicsFrom]
     * @param {number} [microelectronicsTenacityTo]
     * @param {number} [microelectronicsTenacityFrom]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {boolean} [lastPlayNull]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {boolean} [lastActivityNull]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<RoleInRelatedProjectEnum>} [roleInRelatedProjects]
     * @param {Array<StatusCompanyEnum>} [statusCompany]
     * @param {Array<RejectionReasonEnum>} [rejectionReason]
     * @param {Array<string>} [acquisitionChannel]
     * @param {boolean} [acquisitionChannelNull]
     * @param {Array<StatusEmploymentEnum>} [statusEmployment]
     * @param {boolean} [confirmationSingle]
     * @param {string} [dateOfGettingAnalyticsLvl05From]
     * @param {string} [dateOfGettingAnalyticsLvl05To]
     * @param {string} [dateOfGettingAnalyticsLvl1From]
     * @param {string} [dateOfGettingAnalyticsLvl1To]
     * @param {Array<string>} [dumpedFields]
     * @param {Array<number>} [secretCode]
     * @param {Array<string>} [orderBy]          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportRatingToXLSX: async (
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      desiredStudyPlace?: Array<string>,
      country?: Array<string>,
      countryNull?: boolean,
      districtNull?: boolean,
      district?: Array<number>,
      school?: Array<number>,
      schoolNull?: boolean,
      regionNull?: boolean,
      region?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityTo?: number,
      analyticsTenacityFrom?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityNull?: boolean,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>,
      statusCompany?: Array<StatusCompanyEnum>,
      rejectionReason?: Array<RejectionReasonEnum>,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      statusEmployment?: Array<StatusEmploymentEnum>,
      confirmationSingle?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      dumpedFields?: Array<string>,
      secretCode?: Array<number>,
      orderBy?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/export_from_social/ratings_to_xlsx`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2PasswordBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "OAuth2PasswordBearer",
        [],
        configuration,
      );

      if (name) {
        localVarQueryParameter["name"] = name;
      }

      if (firstName) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (phone) {
        localVarQueryParameter["phone"] = phone;
      }

      if (email) {
        localVarQueryParameter["email"] = email;
      }

      if (emailVerified) {
        localVarQueryParameter["emailVerified"] = emailVerified;
      }

      if (emailFull !== undefined) {
        localVarQueryParameter["emailFull"] = emailFull;
      }

      if (gender) {
        localVarQueryParameter["gender"] = gender;
      }

      if (groupCode) {
        localVarQueryParameter["groupCode"] = groupCode;
      }

      if (groupCodeNotNull !== undefined) {
        localVarQueryParameter["groupCodeNotNull"] = groupCodeNotNull;
      }

      if (studyWorkPlace) {
        localVarQueryParameter["studyWorkPlace"] = studyWorkPlace;
      }

      if (studyWorkPlaceNull !== undefined) {
        localVarQueryParameter["studyWorkPlaceNull"] = studyWorkPlaceNull;
      }

      if (desiredSpecialty) {
        localVarQueryParameter["desiredSpecialty"] = desiredSpecialty;
      }

      if (desiredSpecialtyNull !== undefined) {
        localVarQueryParameter["desiredSpecialtyNull"] = desiredSpecialtyNull;
      }

      if (desiredStudyPlace) {
        localVarQueryParameter["desiredStudyPlace"] = desiredStudyPlace;
      }

      if (country) {
        localVarQueryParameter["country"] = country;
      }

      if (countryNull !== undefined) {
        localVarQueryParameter["countryNull"] = countryNull;
      }

      if (districtNull !== undefined) {
        localVarQueryParameter["districtNull"] = districtNull;
      }

      if (district) {
        localVarQueryParameter["district"] = district;
      }

      if (school) {
        localVarQueryParameter["school"] = school;
      }

      if (schoolNull !== undefined) {
        localVarQueryParameter["schoolNull"] = schoolNull;
      }

      if (regionNull !== undefined) {
        localVarQueryParameter["regionNull"] = regionNull;
      }

      if (region) {
        localVarQueryParameter["region"] = region;
      }

      if (locality) {
        localVarQueryParameter["locality"] = locality;
      }

      if (localityNull !== undefined) {
        localVarQueryParameter["localityNull"] = localityNull;
      }

      if (birthdateNull !== undefined) {
        localVarQueryParameter["birthdateNull"] = birthdateNull;
      }

      if (birthdateFrom !== undefined) {
        localVarQueryParameter["birthdateFrom"] = birthdateFrom;
      }

      if (birthdateTo !== undefined) {
        localVarQueryParameter["birthdateTo"] = birthdateTo;
      }

      if (gPlaceFrom !== undefined) {
        localVarQueryParameter["gPlaceFrom"] = gPlaceFrom;
      }

      if (gPlaceTo !== undefined) {
        localVarQueryParameter["gPlaceTo"] = gPlaceTo;
      }

      if (gRateFrom !== undefined) {
        localVarQueryParameter["gRateFrom"] = gRateFrom;
      }

      if (gRateTo !== undefined) {
        localVarQueryParameter["gRateTo"] = gRateTo;
      }

      if (analyticsFrom !== undefined) {
        localVarQueryParameter["analyticsFrom"] = analyticsFrom;
      }

      if (analyticsTo !== undefined) {
        localVarQueryParameter["analyticsTo"] = analyticsTo;
      }

      if (analyticsTenacityTo !== undefined) {
        localVarQueryParameter["analyticsTenacityTo"] = analyticsTenacityTo;
      }

      if (analyticsTenacityFrom !== undefined) {
        localVarQueryParameter["analyticsTenacityFrom"] = analyticsTenacityFrom;
      }

      if (commandFrom !== undefined) {
        localVarQueryParameter["commandFrom"] = commandFrom;
      }

      if (commandTo !== undefined) {
        localVarQueryParameter["commandTo"] = commandTo;
      }

      if (commandTenacityFrom !== undefined) {
        localVarQueryParameter["commandTenacityFrom"] = commandTenacityFrom;
      }

      if (commandTenacityTo !== undefined) {
        localVarQueryParameter["commandTenacityTo"] = commandTenacityTo;
      }

      if (communicationFrom !== undefined) {
        localVarQueryParameter["communicationFrom"] = communicationFrom;
      }

      if (communicationTo !== undefined) {
        localVarQueryParameter["communicationTo"] = communicationTo;
      }

      if (communicationTenacityFrom !== undefined) {
        localVarQueryParameter["communicationTenacityFrom"] =
          communicationTenacityFrom;
      }

      if (communicationTenacityTo !== undefined) {
        localVarQueryParameter["communicationTenacityTo"] =
          communicationTenacityTo;
      }

      if (thinking3dFrom !== undefined) {
        localVarQueryParameter["thinking3dFrom"] = thinking3dFrom;
      }

      if (thinking3dTo !== undefined) {
        localVarQueryParameter["thinking3dTo"] = thinking3dTo;
      }

      if (thinking3dTenacityFrom !== undefined) {
        localVarQueryParameter["thinking3dTenacityFrom"] =
          thinking3dTenacityFrom;
      }

      if (thinking3dTenacityTo !== undefined) {
        localVarQueryParameter["thinking3dTenacityTo"] = thinking3dTenacityTo;
      }

      if (economicsFrom !== undefined) {
        localVarQueryParameter["economicsFrom"] = economicsFrom;
      }

      if (economicsTo !== undefined) {
        localVarQueryParameter["economicsTo"] = economicsTo;
      }

      if (economicsTenacityFrom !== undefined) {
        localVarQueryParameter["economicsTenacityFrom"] = economicsTenacityFrom;
      }

      if (economicsTenacityTo !== undefined) {
        localVarQueryParameter["economicsTenacityTo"] = economicsTenacityTo;
      }

      if (jurisprudenceFrom !== undefined) {
        localVarQueryParameter["jurisprudenceFrom"] = jurisprudenceFrom;
      }

      if (jurisprudenceTo !== undefined) {
        localVarQueryParameter["jurisprudenceTo"] = jurisprudenceTo;
      }

      if (jurisprudenceTenacityFrom !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityFrom"] =
          jurisprudenceTenacityFrom;
      }

      if (jurisprudenceTenacityTo !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityTo"] =
          jurisprudenceTenacityTo;
      }

      if (microelectronicsTo !== undefined) {
        localVarQueryParameter["microelectronicsTo"] = microelectronicsTo;
      }

      if (microelectronicsFrom !== undefined) {
        localVarQueryParameter["microelectronicsFrom"] = microelectronicsFrom;
      }

      if (microelectronicsTenacityTo !== undefined) {
        localVarQueryParameter["microelectronicsTenacityTo"] =
          microelectronicsTenacityTo;
      }

      if (microelectronicsTenacityFrom !== undefined) {
        localVarQueryParameter["microelectronicsTenacityFrom"] =
          microelectronicsTenacityFrom;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] = createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] = createdTo;
      }

      if (lastPlayNull !== undefined) {
        localVarQueryParameter["lastPlayNull"] = lastPlayNull;
      }

      if (lastPlayFrom !== undefined) {
        localVarQueryParameter["lastPlayFrom"] = lastPlayFrom;
      }

      if (lastPlayTo !== undefined) {
        localVarQueryParameter["lastPlayTo"] = lastPlayTo;
      }

      if (lastActivityNull !== undefined) {
        localVarQueryParameter["lastActivityNull"] = lastActivityNull;
      }

      if (lastActivityFrom !== undefined) {
        localVarQueryParameter["lastActivityFrom"] = lastActivityFrom;
      }

      if (lastActivityTo !== undefined) {
        localVarQueryParameter["lastActivityTo"] = lastActivityTo;
      }

      if (roleInRelatedProjects) {
        localVarQueryParameter["roleInRelatedProjects"] = roleInRelatedProjects;
      }

      if (statusCompany) {
        localVarQueryParameter["statusCompany"] = statusCompany;
      }

      if (rejectionReason) {
        localVarQueryParameter["rejectionReason"] = rejectionReason;
      }

      if (acquisitionChannel) {
        localVarQueryParameter["acquisitionChannel"] = acquisitionChannel;
      }

      if (acquisitionChannelNull !== undefined) {
        localVarQueryParameter["acquisitionChannelNull"] =
          acquisitionChannelNull;
      }

      if (statusEmployment) {
        localVarQueryParameter["statusEmployment"] = statusEmployment;
      }

      if (confirmationSingle !== undefined) {
        localVarQueryParameter["confirmationSingle"] = confirmationSingle;
      }

      if (dateOfGettingAnalyticsLvl05From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5From"] =
          dateOfGettingAnalyticsLvl05From;
      }

      if (dateOfGettingAnalyticsLvl05To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_0_5To"] =
          dateOfGettingAnalyticsLvl05To;
      }

      if (dateOfGettingAnalyticsLvl1From !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1From"] =
          dateOfGettingAnalyticsLvl1From;
      }

      if (dateOfGettingAnalyticsLvl1To !== undefined) {
        localVarQueryParameter["date_of_getting_analytics_lvl_1To"] =
          dateOfGettingAnalyticsLvl1To;
      }

      if (dumpedFields) {
        localVarQueryParameter["dumped_fields"] = dumpedFields;
      }

      if (secretCode) {
        localVarQueryParameter["secretCode"] = secretCode;
      }

      if (orderBy) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExportFromSocialApi - functional programming interface
 * @export
 */
export const ExportFromSocialApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ExportFromSocialApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary GET Ratings table in csv
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {Array<string>} [email]
     * @param {Array<boolean>} [emailVerified]
     * @param {boolean} [emailFull]
     * @param {Array<string>} [gender]
     * @param {Array<string>} [groupCode]
     * @param {boolean} [groupCodeNotNull]
     * @param {Array<string>} [studyWorkPlace]
     * @param {boolean} [studyWorkPlaceNull]
     * @param {Array<string>} [desiredSpecialty]
     * @param {boolean} [desiredSpecialtyNull]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {boolean} [districtNull]
     * @param {Array<number>} [district]
     * @param {Array<number>} [school]
     * @param {boolean} [schoolNull]
     * @param {boolean} [regionNull]
     * @param {Array<number>} [region]
     * @param {Array<string>} [locality]
     * @param {boolean} [localityNull]
     * @param {boolean} [birthdateNull]
     * @param {string} [birthdateFrom]
     * @param {string} [birthdateTo]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [analyticsTenacityTo]
     * @param {number} [analyticsTenacityFrom]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [commandTenacityFrom]
     * @param {number} [commandTenacityTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {number} [communicationTenacityFrom]
     * @param {number} [communicationTenacityTo]
     * @param {number} [thinking3dFrom]
     * @param {number} [thinking3dTo]
     * @param {number} [thinking3dTenacityFrom]
     * @param {number} [thinking3dTenacityTo]
     * @param {number} [economicsFrom]
     * @param {number} [economicsTo]
     * @param {number} [economicsTenacityFrom]
     * @param {number} [economicsTenacityTo]
     * @param {number} [jurisprudenceFrom]
     * @param {number} [jurisprudenceTo]
     * @param {number} [jurisprudenceTenacityFrom]
     * @param {number} [jurisprudenceTenacityTo]
     * @param {number} [microelectronicsTo]
     * @param {number} [microelectronicsFrom]
     * @param {number} [microelectronicsTenacityTo]
     * @param {number} [microelectronicsTenacityFrom]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {boolean} [lastPlayNull]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {boolean} [lastActivityNull]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<RoleInRelatedProjectEnum>} [roleInRelatedProjects]
     * @param {Array<StatusCompanyEnum>} [statusCompany]
     * @param {Array<RejectionReasonEnum>} [rejectionReason]
     * @param {Array<string>} [acquisitionChannel]
     * @param {boolean} [acquisitionChannelNull]
     * @param {Array<StatusEmploymentEnum>} [statusEmployment]
     * @param {boolean} [confirmationSingle]
     * @param {string} [dateOfGettingAnalyticsLvl05From]
     * @param {string} [dateOfGettingAnalyticsLvl05To]
     * @param {string} [dateOfGettingAnalyticsLvl1From]
     * @param {string} [dateOfGettingAnalyticsLvl1To]
     * @param {Array<string>} [dumpedFields]
     * @param {Array<number>} [secretCode]
     * @param {Array<string>} [orderBy]          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportRatingToCSV(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      desiredStudyPlace?: Array<string>,
      country?: Array<string>,
      countryNull?: boolean,
      districtNull?: boolean,
      district?: Array<number>,
      school?: Array<number>,
      schoolNull?: boolean,
      regionNull?: boolean,
      region?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityTo?: number,
      analyticsTenacityFrom?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityNull?: boolean,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>,
      statusCompany?: Array<StatusCompanyEnum>,
      rejectionReason?: Array<RejectionReasonEnum>,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      statusEmployment?: Array<StatusEmploymentEnum>,
      confirmationSingle?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      dumpedFields?: Array<string>,
      secretCode?: Array<number>,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportRatingToCSV(
          name,
          firstName,
          lastName,
          phone,
          email,
          emailVerified,
          emailFull,
          gender,
          groupCode,
          groupCodeNotNull,
          studyWorkPlace,
          studyWorkPlaceNull,
          desiredSpecialty,
          desiredSpecialtyNull,
          desiredStudyPlace,
          country,
          countryNull,
          districtNull,
          district,
          school,
          schoolNull,
          regionNull,
          region,
          locality,
          localityNull,
          birthdateNull,
          birthdateFrom,
          birthdateTo,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          analyticsTenacityTo,
          analyticsTenacityFrom,
          commandFrom,
          commandTo,
          commandTenacityFrom,
          commandTenacityTo,
          communicationFrom,
          communicationTo,
          communicationTenacityFrom,
          communicationTenacityTo,
          thinking3dFrom,
          thinking3dTo,
          thinking3dTenacityFrom,
          thinking3dTenacityTo,
          economicsFrom,
          economicsTo,
          economicsTenacityFrom,
          economicsTenacityTo,
          jurisprudenceFrom,
          jurisprudenceTo,
          jurisprudenceTenacityFrom,
          jurisprudenceTenacityTo,
          microelectronicsTo,
          microelectronicsFrom,
          microelectronicsTenacityTo,
          microelectronicsTenacityFrom,
          createdFrom,
          createdTo,
          lastPlayNull,
          lastPlayFrom,
          lastPlayTo,
          lastActivityNull,
          lastActivityFrom,
          lastActivityTo,
          roleInRelatedProjects,
          statusCompany,
          rejectionReason,
          acquisitionChannel,
          acquisitionChannelNull,
          statusEmployment,
          confirmationSingle,
          dateOfGettingAnalyticsLvl05From,
          dateOfGettingAnalyticsLvl05To,
          dateOfGettingAnalyticsLvl1From,
          dateOfGettingAnalyticsLvl1To,
          dumpedFields,
          secretCode,
          orderBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary GET Ratings table in xlsx
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {Array<string>} [email]
     * @param {Array<boolean>} [emailVerified]
     * @param {boolean} [emailFull]
     * @param {Array<string>} [gender]
     * @param {Array<string>} [groupCode]
     * @param {boolean} [groupCodeNotNull]
     * @param {Array<string>} [studyWorkPlace]
     * @param {boolean} [studyWorkPlaceNull]
     * @param {Array<string>} [desiredSpecialty]
     * @param {boolean} [desiredSpecialtyNull]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {boolean} [districtNull]
     * @param {Array<number>} [district]
     * @param {Array<number>} [school]
     * @param {boolean} [schoolNull]
     * @param {boolean} [regionNull]
     * @param {Array<number>} [region]
     * @param {Array<string>} [locality]
     * @param {boolean} [localityNull]
     * @param {boolean} [birthdateNull]
     * @param {string} [birthdateFrom]
     * @param {string} [birthdateTo]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [analyticsTenacityTo]
     * @param {number} [analyticsTenacityFrom]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [commandTenacityFrom]
     * @param {number} [commandTenacityTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {number} [communicationTenacityFrom]
     * @param {number} [communicationTenacityTo]
     * @param {number} [thinking3dFrom]
     * @param {number} [thinking3dTo]
     * @param {number} [thinking3dTenacityFrom]
     * @param {number} [thinking3dTenacityTo]
     * @param {number} [economicsFrom]
     * @param {number} [economicsTo]
     * @param {number} [economicsTenacityFrom]
     * @param {number} [economicsTenacityTo]
     * @param {number} [jurisprudenceFrom]
     * @param {number} [jurisprudenceTo]
     * @param {number} [jurisprudenceTenacityFrom]
     * @param {number} [jurisprudenceTenacityTo]
     * @param {number} [microelectronicsTo]
     * @param {number} [microelectronicsFrom]
     * @param {number} [microelectronicsTenacityTo]
     * @param {number} [microelectronicsTenacityFrom]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {boolean} [lastPlayNull]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {boolean} [lastActivityNull]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<RoleInRelatedProjectEnum>} [roleInRelatedProjects]
     * @param {Array<StatusCompanyEnum>} [statusCompany]
     * @param {Array<RejectionReasonEnum>} [rejectionReason]
     * @param {Array<string>} [acquisitionChannel]
     * @param {boolean} [acquisitionChannelNull]
     * @param {Array<StatusEmploymentEnum>} [statusEmployment]
     * @param {boolean} [confirmationSingle]
     * @param {string} [dateOfGettingAnalyticsLvl05From]
     * @param {string} [dateOfGettingAnalyticsLvl05To]
     * @param {string} [dateOfGettingAnalyticsLvl1From]
     * @param {string} [dateOfGettingAnalyticsLvl1To]
     * @param {Array<string>} [dumpedFields]
     * @param {Array<number>} [secretCode]
     * @param {Array<string>} [orderBy]          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportRatingToXLSX(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      desiredStudyPlace?: Array<string>,
      country?: Array<string>,
      countryNull?: boolean,
      districtNull?: boolean,
      district?: Array<number>,
      school?: Array<number>,
      schoolNull?: boolean,
      regionNull?: boolean,
      region?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityTo?: number,
      analyticsTenacityFrom?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityNull?: boolean,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>,
      statusCompany?: Array<StatusCompanyEnum>,
      rejectionReason?: Array<RejectionReasonEnum>,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      statusEmployment?: Array<StatusEmploymentEnum>,
      confirmationSingle?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      dumpedFields?: Array<string>,
      secretCode?: Array<number>,
      orderBy?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportRatingToXLSX(
          name,
          firstName,
          lastName,
          phone,
          email,
          emailVerified,
          emailFull,
          gender,
          groupCode,
          groupCodeNotNull,
          studyWorkPlace,
          studyWorkPlaceNull,
          desiredSpecialty,
          desiredSpecialtyNull,
          desiredStudyPlace,
          country,
          countryNull,
          districtNull,
          district,
          school,
          schoolNull,
          regionNull,
          region,
          locality,
          localityNull,
          birthdateNull,
          birthdateFrom,
          birthdateTo,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          analyticsTenacityTo,
          analyticsTenacityFrom,
          commandFrom,
          commandTo,
          commandTenacityFrom,
          commandTenacityTo,
          communicationFrom,
          communicationTo,
          communicationTenacityFrom,
          communicationTenacityTo,
          thinking3dFrom,
          thinking3dTo,
          thinking3dTenacityFrom,
          thinking3dTenacityTo,
          economicsFrom,
          economicsTo,
          economicsTenacityFrom,
          economicsTenacityTo,
          jurisprudenceFrom,
          jurisprudenceTo,
          jurisprudenceTenacityFrom,
          jurisprudenceTenacityTo,
          microelectronicsTo,
          microelectronicsFrom,
          microelectronicsTenacityTo,
          microelectronicsTenacityFrom,
          createdFrom,
          createdTo,
          lastPlayNull,
          lastPlayFrom,
          lastPlayTo,
          lastActivityNull,
          lastActivityFrom,
          lastActivityTo,
          roleInRelatedProjects,
          statusCompany,
          rejectionReason,
          acquisitionChannel,
          acquisitionChannelNull,
          statusEmployment,
          confirmationSingle,
          dateOfGettingAnalyticsLvl05From,
          dateOfGettingAnalyticsLvl05To,
          dateOfGettingAnalyticsLvl1From,
          dateOfGettingAnalyticsLvl1To,
          dumpedFields,
          secretCode,
          orderBy,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExportFromSocialApi - factory interface
 * @export
 */
export const ExportFromSocialApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExportFromSocialApiFp(configuration);
  return {
    /**
     *
     * @summary GET Ratings table in csv
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {Array<string>} [email]
     * @param {Array<boolean>} [emailVerified]
     * @param {boolean} [emailFull]
     * @param {Array<string>} [gender]
     * @param {Array<string>} [groupCode]
     * @param {boolean} [groupCodeNotNull]
     * @param {Array<string>} [studyWorkPlace]
     * @param {boolean} [studyWorkPlaceNull]
     * @param {Array<string>} [desiredSpecialty]
     * @param {boolean} [desiredSpecialtyNull]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {boolean} [districtNull]
     * @param {Array<number>} [district]
     * @param {Array<number>} [school]
     * @param {boolean} [schoolNull]
     * @param {boolean} [regionNull]
     * @param {Array<number>} [region]
     * @param {Array<string>} [locality]
     * @param {boolean} [localityNull]
     * @param {boolean} [birthdateNull]
     * @param {string} [birthdateFrom]
     * @param {string} [birthdateTo]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [analyticsTenacityTo]
     * @param {number} [analyticsTenacityFrom]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [commandTenacityFrom]
     * @param {number} [commandTenacityTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {number} [communicationTenacityFrom]
     * @param {number} [communicationTenacityTo]
     * @param {number} [thinking3dFrom]
     * @param {number} [thinking3dTo]
     * @param {number} [thinking3dTenacityFrom]
     * @param {number} [thinking3dTenacityTo]
     * @param {number} [economicsFrom]
     * @param {number} [economicsTo]
     * @param {number} [economicsTenacityFrom]
     * @param {number} [economicsTenacityTo]
     * @param {number} [jurisprudenceFrom]
     * @param {number} [jurisprudenceTo]
     * @param {number} [jurisprudenceTenacityFrom]
     * @param {number} [jurisprudenceTenacityTo]
     * @param {number} [microelectronicsTo]
     * @param {number} [microelectronicsFrom]
     * @param {number} [microelectronicsTenacityTo]
     * @param {number} [microelectronicsTenacityFrom]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {boolean} [lastPlayNull]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {boolean} [lastActivityNull]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<RoleInRelatedProjectEnum>} [roleInRelatedProjects]
     * @param {Array<StatusCompanyEnum>} [statusCompany]
     * @param {Array<RejectionReasonEnum>} [rejectionReason]
     * @param {Array<string>} [acquisitionChannel]
     * @param {boolean} [acquisitionChannelNull]
     * @param {Array<StatusEmploymentEnum>} [statusEmployment]
     * @param {boolean} [confirmationSingle]
     * @param {string} [dateOfGettingAnalyticsLvl05From]
     * @param {string} [dateOfGettingAnalyticsLvl05To]
     * @param {string} [dateOfGettingAnalyticsLvl1From]
     * @param {string} [dateOfGettingAnalyticsLvl1To]
     * @param {Array<string>} [dumpedFields]
     * @param {Array<number>} [secretCode]
     * @param {Array<string>} [orderBy]          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportRatingToCSV(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      desiredStudyPlace?: Array<string>,
      country?: Array<string>,
      countryNull?: boolean,
      districtNull?: boolean,
      district?: Array<number>,
      school?: Array<number>,
      schoolNull?: boolean,
      regionNull?: boolean,
      region?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityTo?: number,
      analyticsTenacityFrom?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityNull?: boolean,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>,
      statusCompany?: Array<StatusCompanyEnum>,
      rejectionReason?: Array<RejectionReasonEnum>,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      statusEmployment?: Array<StatusEmploymentEnum>,
      confirmationSingle?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      dumpedFields?: Array<string>,
      secretCode?: Array<number>,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportRatingToCSV(
          name,
          firstName,
          lastName,
          phone,
          email,
          emailVerified,
          emailFull,
          gender,
          groupCode,
          groupCodeNotNull,
          studyWorkPlace,
          studyWorkPlaceNull,
          desiredSpecialty,
          desiredSpecialtyNull,
          desiredStudyPlace,
          country,
          countryNull,
          districtNull,
          district,
          school,
          schoolNull,
          regionNull,
          region,
          locality,
          localityNull,
          birthdateNull,
          birthdateFrom,
          birthdateTo,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          analyticsTenacityTo,
          analyticsTenacityFrom,
          commandFrom,
          commandTo,
          commandTenacityFrom,
          commandTenacityTo,
          communicationFrom,
          communicationTo,
          communicationTenacityFrom,
          communicationTenacityTo,
          thinking3dFrom,
          thinking3dTo,
          thinking3dTenacityFrom,
          thinking3dTenacityTo,
          economicsFrom,
          economicsTo,
          economicsTenacityFrom,
          economicsTenacityTo,
          jurisprudenceFrom,
          jurisprudenceTo,
          jurisprudenceTenacityFrom,
          jurisprudenceTenacityTo,
          microelectronicsTo,
          microelectronicsFrom,
          microelectronicsTenacityTo,
          microelectronicsTenacityFrom,
          createdFrom,
          createdTo,
          lastPlayNull,
          lastPlayFrom,
          lastPlayTo,
          lastActivityNull,
          lastActivityFrom,
          lastActivityTo,
          roleInRelatedProjects,
          statusCompany,
          rejectionReason,
          acquisitionChannel,
          acquisitionChannelNull,
          statusEmployment,
          confirmationSingle,
          dateOfGettingAnalyticsLvl05From,
          dateOfGettingAnalyticsLvl05To,
          dateOfGettingAnalyticsLvl1From,
          dateOfGettingAnalyticsLvl1To,
          dumpedFields,
          secretCode,
          orderBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary GET Ratings table in xlsx
     * @param {Array<string>} [name]
     * @param {Array<string>} [firstName]
     * @param {Array<string>} [lastName]
     * @param {Array<string>} [phone]
     * @param {Array<string>} [email]
     * @param {Array<boolean>} [emailVerified]
     * @param {boolean} [emailFull]
     * @param {Array<string>} [gender]
     * @param {Array<string>} [groupCode]
     * @param {boolean} [groupCodeNotNull]
     * @param {Array<string>} [studyWorkPlace]
     * @param {boolean} [studyWorkPlaceNull]
     * @param {Array<string>} [desiredSpecialty]
     * @param {boolean} [desiredSpecialtyNull]
     * @param {Array<string>} [desiredStudyPlace]
     * @param {Array<string>} [country]
     * @param {boolean} [countryNull]
     * @param {boolean} [districtNull]
     * @param {Array<number>} [district]
     * @param {Array<number>} [school]
     * @param {boolean} [schoolNull]
     * @param {boolean} [regionNull]
     * @param {Array<number>} [region]
     * @param {Array<string>} [locality]
     * @param {boolean} [localityNull]
     * @param {boolean} [birthdateNull]
     * @param {string} [birthdateFrom]
     * @param {string} [birthdateTo]
     * @param {number} [gPlaceFrom]
     * @param {number} [gPlaceTo]
     * @param {number} [gRateFrom]
     * @param {number} [gRateTo]
     * @param {number} [analyticsFrom]
     * @param {number} [analyticsTo]
     * @param {number} [analyticsTenacityTo]
     * @param {number} [analyticsTenacityFrom]
     * @param {number} [commandFrom]
     * @param {number} [commandTo]
     * @param {number} [commandTenacityFrom]
     * @param {number} [commandTenacityTo]
     * @param {number} [communicationFrom]
     * @param {number} [communicationTo]
     * @param {number} [communicationTenacityFrom]
     * @param {number} [communicationTenacityTo]
     * @param {number} [thinking3dFrom]
     * @param {number} [thinking3dTo]
     * @param {number} [thinking3dTenacityFrom]
     * @param {number} [thinking3dTenacityTo]
     * @param {number} [economicsFrom]
     * @param {number} [economicsTo]
     * @param {number} [economicsTenacityFrom]
     * @param {number} [economicsTenacityTo]
     * @param {number} [jurisprudenceFrom]
     * @param {number} [jurisprudenceTo]
     * @param {number} [jurisprudenceTenacityFrom]
     * @param {number} [jurisprudenceTenacityTo]
     * @param {number} [microelectronicsTo]
     * @param {number} [microelectronicsFrom]
     * @param {number} [microelectronicsTenacityTo]
     * @param {number} [microelectronicsTenacityFrom]
     * @param {string} [createdFrom]
     * @param {string} [createdTo]
     * @param {boolean} [lastPlayNull]
     * @param {string} [lastPlayFrom]
     * @param {string} [lastPlayTo]
     * @param {boolean} [lastActivityNull]
     * @param {string} [lastActivityFrom]
     * @param {string} [lastActivityTo]
     * @param {Array<RoleInRelatedProjectEnum>} [roleInRelatedProjects]
     * @param {Array<StatusCompanyEnum>} [statusCompany]
     * @param {Array<RejectionReasonEnum>} [rejectionReason]
     * @param {Array<string>} [acquisitionChannel]
     * @param {boolean} [acquisitionChannelNull]
     * @param {Array<StatusEmploymentEnum>} [statusEmployment]
     * @param {boolean} [confirmationSingle]
     * @param {string} [dateOfGettingAnalyticsLvl05From]
     * @param {string} [dateOfGettingAnalyticsLvl05To]
     * @param {string} [dateOfGettingAnalyticsLvl1From]
     * @param {string} [dateOfGettingAnalyticsLvl1To]
     * @param {Array<string>} [dumpedFields]
     * @param {Array<number>} [secretCode]
     * @param {Array<string>} [orderBy]          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportRatingToXLSX(
      name?: Array<string>,
      firstName?: Array<string>,
      lastName?: Array<string>,
      phone?: Array<string>,
      email?: Array<string>,
      emailVerified?: Array<boolean>,
      emailFull?: boolean,
      gender?: Array<string>,
      groupCode?: Array<string>,
      groupCodeNotNull?: boolean,
      studyWorkPlace?: Array<string>,
      studyWorkPlaceNull?: boolean,
      desiredSpecialty?: Array<string>,
      desiredSpecialtyNull?: boolean,
      desiredStudyPlace?: Array<string>,
      country?: Array<string>,
      countryNull?: boolean,
      districtNull?: boolean,
      district?: Array<number>,
      school?: Array<number>,
      schoolNull?: boolean,
      regionNull?: boolean,
      region?: Array<number>,
      locality?: Array<string>,
      localityNull?: boolean,
      birthdateNull?: boolean,
      birthdateFrom?: string,
      birthdateTo?: string,
      gPlaceFrom?: number,
      gPlaceTo?: number,
      gRateFrom?: number,
      gRateTo?: number,
      analyticsFrom?: number,
      analyticsTo?: number,
      analyticsTenacityTo?: number,
      analyticsTenacityFrom?: number,
      commandFrom?: number,
      commandTo?: number,
      commandTenacityFrom?: number,
      commandTenacityTo?: number,
      communicationFrom?: number,
      communicationTo?: number,
      communicationTenacityFrom?: number,
      communicationTenacityTo?: number,
      thinking3dFrom?: number,
      thinking3dTo?: number,
      thinking3dTenacityFrom?: number,
      thinking3dTenacityTo?: number,
      economicsFrom?: number,
      economicsTo?: number,
      economicsTenacityFrom?: number,
      economicsTenacityTo?: number,
      jurisprudenceFrom?: number,
      jurisprudenceTo?: number,
      jurisprudenceTenacityFrom?: number,
      jurisprudenceTenacityTo?: number,
      microelectronicsTo?: number,
      microelectronicsFrom?: number,
      microelectronicsTenacityTo?: number,
      microelectronicsTenacityFrom?: number,
      createdFrom?: string,
      createdTo?: string,
      lastPlayNull?: boolean,
      lastPlayFrom?: string,
      lastPlayTo?: string,
      lastActivityNull?: boolean,
      lastActivityFrom?: string,
      lastActivityTo?: string,
      roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>,
      statusCompany?: Array<StatusCompanyEnum>,
      rejectionReason?: Array<RejectionReasonEnum>,
      acquisitionChannel?: Array<string>,
      acquisitionChannelNull?: boolean,
      statusEmployment?: Array<StatusEmploymentEnum>,
      confirmationSingle?: boolean,
      dateOfGettingAnalyticsLvl05From?: string,
      dateOfGettingAnalyticsLvl05To?: string,
      dateOfGettingAnalyticsLvl1From?: string,
      dateOfGettingAnalyticsLvl1To?: string,
      dumpedFields?: Array<string>,
      secretCode?: Array<number>,
      orderBy?: Array<string>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportRatingToXLSX(
          name,
          firstName,
          lastName,
          phone,
          email,
          emailVerified,
          emailFull,
          gender,
          groupCode,
          groupCodeNotNull,
          studyWorkPlace,
          studyWorkPlaceNull,
          desiredSpecialty,
          desiredSpecialtyNull,
          desiredStudyPlace,
          country,
          countryNull,
          districtNull,
          district,
          school,
          schoolNull,
          regionNull,
          region,
          locality,
          localityNull,
          birthdateNull,
          birthdateFrom,
          birthdateTo,
          gPlaceFrom,
          gPlaceTo,
          gRateFrom,
          gRateTo,
          analyticsFrom,
          analyticsTo,
          analyticsTenacityTo,
          analyticsTenacityFrom,
          commandFrom,
          commandTo,
          commandTenacityFrom,
          commandTenacityTo,
          communicationFrom,
          communicationTo,
          communicationTenacityFrom,
          communicationTenacityTo,
          thinking3dFrom,
          thinking3dTo,
          thinking3dTenacityFrom,
          thinking3dTenacityTo,
          economicsFrom,
          economicsTo,
          economicsTenacityFrom,
          economicsTenacityTo,
          jurisprudenceFrom,
          jurisprudenceTo,
          jurisprudenceTenacityFrom,
          jurisprudenceTenacityTo,
          microelectronicsTo,
          microelectronicsFrom,
          microelectronicsTenacityTo,
          microelectronicsTenacityFrom,
          createdFrom,
          createdTo,
          lastPlayNull,
          lastPlayFrom,
          lastPlayTo,
          lastActivityNull,
          lastActivityFrom,
          lastActivityTo,
          roleInRelatedProjects,
          statusCompany,
          rejectionReason,
          acquisitionChannel,
          acquisitionChannelNull,
          statusEmployment,
          confirmationSingle,
          dateOfGettingAnalyticsLvl05From,
          dateOfGettingAnalyticsLvl05To,
          dateOfGettingAnalyticsLvl1From,
          dateOfGettingAnalyticsLvl1To,
          dumpedFields,
          secretCode,
          orderBy,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for exportRatingToCSV operation in ExportFromSocialApi.
 * @export
 * @interface ExportFromSocialApiExportRatingToCSVRequest
 */
export interface ExportFromSocialApiExportRatingToCSVRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly name?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly firstName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly lastName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly phone?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly email?: Array<string>;

  /**
   *
   * @type {Array<boolean>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly emailVerified?: Array<boolean>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly emailFull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly gender?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly groupCode?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly groupCodeNotNull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly studyWorkPlace?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly studyWorkPlaceNull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly desiredSpecialty?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly desiredSpecialtyNull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly desiredStudyPlace?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly country?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly countryNull?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly districtNull?: boolean;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly district?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly school?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly schoolNull?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly regionNull?: boolean;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly region?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly locality?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly localityNull?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly birthdateNull?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly birthdateFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly birthdateTo?: string;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly gPlaceFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly gPlaceTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly gRateFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly gRateTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly analyticsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly analyticsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly analyticsTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly analyticsTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly commandFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly commandTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly commandTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly commandTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly communicationFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly communicationTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly communicationTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly communicationTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly thinking3dFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly thinking3dTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly thinking3dTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly thinking3dTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly economicsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly economicsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly economicsTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly economicsTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly jurisprudenceFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly jurisprudenceTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly jurisprudenceTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly jurisprudenceTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly microelectronicsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly microelectronicsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly microelectronicsTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly microelectronicsTenacityFrom?: number;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly createdTo?: string;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly lastPlayNull?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly lastPlayFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly lastPlayTo?: string;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly lastActivityNull?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly lastActivityFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly lastActivityTo?: string;

  /**
   *
   * @type {Array<RoleInRelatedProjectEnum>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>;

  /**
   *
   * @type {Array<StatusCompanyEnum>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly statusCompany?: Array<StatusCompanyEnum>;

  /**
   *
   * @type {Array<RejectionReasonEnum>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly rejectionReason?: Array<RejectionReasonEnum>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly acquisitionChannel?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly acquisitionChannelNull?: boolean;

  /**
   *
   * @type {Array<StatusEmploymentEnum>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly statusEmployment?: Array<StatusEmploymentEnum>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly confirmationSingle?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly dateOfGettingAnalyticsLvl05From?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly dateOfGettingAnalyticsLvl05To?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly dateOfGettingAnalyticsLvl1From?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly dateOfGettingAnalyticsLvl1To?: string;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly dumpedFields?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly secretCode?: Array<number>;

  /**
   *          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToCSV
   */
  readonly orderBy?: Array<string>;
}

/**
 * Request parameters for exportRatingToXLSX operation in ExportFromSocialApi.
 * @export
 * @interface ExportFromSocialApiExportRatingToXLSXRequest
 */
export interface ExportFromSocialApiExportRatingToXLSXRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly name?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly firstName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly lastName?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly phone?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly email?: Array<string>;

  /**
   *
   * @type {Array<boolean>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly emailVerified?: Array<boolean>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly emailFull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly gender?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly groupCode?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly groupCodeNotNull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly studyWorkPlace?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly studyWorkPlaceNull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly desiredSpecialty?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly desiredSpecialtyNull?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly desiredStudyPlace?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly country?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly countryNull?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly districtNull?: boolean;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly district?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly school?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly schoolNull?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly regionNull?: boolean;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly region?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly locality?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly localityNull?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly birthdateNull?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly birthdateFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly birthdateTo?: string;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly gPlaceFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly gPlaceTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly gRateFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly gRateTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly analyticsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly analyticsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly analyticsTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly analyticsTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly commandFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly commandTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly commandTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly commandTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly communicationFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly communicationTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly communicationTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly communicationTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly thinking3dFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly thinking3dTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly thinking3dTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly thinking3dTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly economicsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly economicsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly economicsTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly economicsTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly jurisprudenceFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly jurisprudenceTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly jurisprudenceTenacityFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly jurisprudenceTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly microelectronicsTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly microelectronicsFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly microelectronicsTenacityTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly microelectronicsTenacityFrom?: number;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly createdFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly createdTo?: string;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly lastPlayNull?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly lastPlayFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly lastPlayTo?: string;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly lastActivityNull?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly lastActivityFrom?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly lastActivityTo?: string;

  /**
   *
   * @type {Array<RoleInRelatedProjectEnum>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly roleInRelatedProjects?: Array<RoleInRelatedProjectEnum>;

  /**
   *
   * @type {Array<StatusCompanyEnum>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly statusCompany?: Array<StatusCompanyEnum>;

  /**
   *
   * @type {Array<RejectionReasonEnum>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly rejectionReason?: Array<RejectionReasonEnum>;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly acquisitionChannel?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly acquisitionChannelNull?: boolean;

  /**
   *
   * @type {Array<StatusEmploymentEnum>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly statusEmployment?: Array<StatusEmploymentEnum>;

  /**
   *
   * @type {boolean}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly confirmationSingle?: boolean;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly dateOfGettingAnalyticsLvl05From?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly dateOfGettingAnalyticsLvl05To?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly dateOfGettingAnalyticsLvl1From?: string;

  /**
   *
   * @type {string}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly dateOfGettingAnalyticsLvl1To?: string;

  /**
   *
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly dumpedFields?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly secretCode?: Array<number>;

  /**
   *          sort players by fields         &#x60;id&#x60;/&#x60;firstName&#x60;/&#x60;lastName&#x60;/&#x60;name&#x60;/&#x60;gPlace&#x60;/&#x60;gRate&#x60;/&#x60;gender&#x60;         /&#x60;analytics&#x60;/&#x60;command&#x60;/&#x60;communication&#x60;         /&#x60;created&#x60;/&#x60;lastPlay&#x60;/&#x60;lastActivity&#x60;/&#x60;phone&#x60;/&#x60;email&#x60;/&#x60;birthdate&#x60;         /&#x60;studyWorkPlace&#x60;/&#x60;desiredStudyPlace&#x60;/&#x60;desiredSpecialty&#x60;         /&#x60;country&#x60;/&#x60;region&#x60;/&#x60;district&#x60;/&#x60;school&#x60;/&#x60;locality&#x60;/         &#x60;acquisitionChannel&#x60;/&#x60;resume&#x60;/&#x60;statusEmployment&#x60;         /&#x60;statusCompany&#x60;/&#x60;date_of_getting_analytics_lvl_0_5&#x60;         /&#x60;date_of_getting_analytics_lvl_1&#x60;/&#x60;confirmationSingle&#x60;         /&#x60;rolesInRelatedProjects/&#x60;rejectionReason&#x60;,         with \&#39;&#x60;+&#x60;\&#39; or none prefix to ASC, with \&#39;&#x60;-&#x60;\&#39; prefix to DESC.
   * @type {Array<string>}
   * @memberof ExportFromSocialApiExportRatingToXLSX
   */
  readonly orderBy?: Array<string>;
}

/**
 * ExportFromSocialApi - object-oriented interface
 * @export
 * @class ExportFromSocialApi
 * @extends {BaseAPI}
 */
export class ExportFromSocialApi extends BaseAPI {
  /**
   *
   * @summary GET Ratings table in csv
   * @param {ExportFromSocialApiExportRatingToCSVRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportFromSocialApi
   */
  public exportRatingToCSV(
    requestParameters: ExportFromSocialApiExportRatingToCSVRequest = {},
    options?: any,
  ) {
    return ExportFromSocialApiFp(this.configuration)
      .exportRatingToCSV(
        requestParameters.name,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phone,
        requestParameters.email,
        requestParameters.emailVerified,
        requestParameters.emailFull,
        requestParameters.gender,
        requestParameters.groupCode,
        requestParameters.groupCodeNotNull,
        requestParameters.studyWorkPlace,
        requestParameters.studyWorkPlaceNull,
        requestParameters.desiredSpecialty,
        requestParameters.desiredSpecialtyNull,
        requestParameters.desiredStudyPlace,
        requestParameters.country,
        requestParameters.countryNull,
        requestParameters.districtNull,
        requestParameters.district,
        requestParameters.school,
        requestParameters.schoolNull,
        requestParameters.regionNull,
        requestParameters.region,
        requestParameters.locality,
        requestParameters.localityNull,
        requestParameters.birthdateNull,
        requestParameters.birthdateFrom,
        requestParameters.birthdateTo,
        requestParameters.gPlaceFrom,
        requestParameters.gPlaceTo,
        requestParameters.gRateFrom,
        requestParameters.gRateTo,
        requestParameters.analyticsFrom,
        requestParameters.analyticsTo,
        requestParameters.analyticsTenacityTo,
        requestParameters.analyticsTenacityFrom,
        requestParameters.commandFrom,
        requestParameters.commandTo,
        requestParameters.commandTenacityFrom,
        requestParameters.commandTenacityTo,
        requestParameters.communicationFrom,
        requestParameters.communicationTo,
        requestParameters.communicationTenacityFrom,
        requestParameters.communicationTenacityTo,
        requestParameters.thinking3dFrom,
        requestParameters.thinking3dTo,
        requestParameters.thinking3dTenacityFrom,
        requestParameters.thinking3dTenacityTo,
        requestParameters.economicsFrom,
        requestParameters.economicsTo,
        requestParameters.economicsTenacityFrom,
        requestParameters.economicsTenacityTo,
        requestParameters.jurisprudenceFrom,
        requestParameters.jurisprudenceTo,
        requestParameters.jurisprudenceTenacityFrom,
        requestParameters.jurisprudenceTenacityTo,
        requestParameters.microelectronicsTo,
        requestParameters.microelectronicsFrom,
        requestParameters.microelectronicsTenacityTo,
        requestParameters.microelectronicsTenacityFrom,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.lastPlayNull,
        requestParameters.lastPlayFrom,
        requestParameters.lastPlayTo,
        requestParameters.lastActivityNull,
        requestParameters.lastActivityFrom,
        requestParameters.lastActivityTo,
        requestParameters.roleInRelatedProjects,
        requestParameters.statusCompany,
        requestParameters.rejectionReason,
        requestParameters.acquisitionChannel,
        requestParameters.acquisitionChannelNull,
        requestParameters.statusEmployment,
        requestParameters.confirmationSingle,
        requestParameters.dateOfGettingAnalyticsLvl05From,
        requestParameters.dateOfGettingAnalyticsLvl05To,
        requestParameters.dateOfGettingAnalyticsLvl1From,
        requestParameters.dateOfGettingAnalyticsLvl1To,
        requestParameters.dumpedFields,
        requestParameters.secretCode,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary GET Ratings table in xlsx
   * @param {ExportFromSocialApiExportRatingToXLSXRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportFromSocialApi
   */
  public exportRatingToXLSX(
    requestParameters: ExportFromSocialApiExportRatingToXLSXRequest = {},
    options?: any,
  ) {
    return ExportFromSocialApiFp(this.configuration)
      .exportRatingToXLSX(
        requestParameters.name,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.phone,
        requestParameters.email,
        requestParameters.emailVerified,
        requestParameters.emailFull,
        requestParameters.gender,
        requestParameters.groupCode,
        requestParameters.groupCodeNotNull,
        requestParameters.studyWorkPlace,
        requestParameters.studyWorkPlaceNull,
        requestParameters.desiredSpecialty,
        requestParameters.desiredSpecialtyNull,
        requestParameters.desiredStudyPlace,
        requestParameters.country,
        requestParameters.countryNull,
        requestParameters.districtNull,
        requestParameters.district,
        requestParameters.school,
        requestParameters.schoolNull,
        requestParameters.regionNull,
        requestParameters.region,
        requestParameters.locality,
        requestParameters.localityNull,
        requestParameters.birthdateNull,
        requestParameters.birthdateFrom,
        requestParameters.birthdateTo,
        requestParameters.gPlaceFrom,
        requestParameters.gPlaceTo,
        requestParameters.gRateFrom,
        requestParameters.gRateTo,
        requestParameters.analyticsFrom,
        requestParameters.analyticsTo,
        requestParameters.analyticsTenacityTo,
        requestParameters.analyticsTenacityFrom,
        requestParameters.commandFrom,
        requestParameters.commandTo,
        requestParameters.commandTenacityFrom,
        requestParameters.commandTenacityTo,
        requestParameters.communicationFrom,
        requestParameters.communicationTo,
        requestParameters.communicationTenacityFrom,
        requestParameters.communicationTenacityTo,
        requestParameters.thinking3dFrom,
        requestParameters.thinking3dTo,
        requestParameters.thinking3dTenacityFrom,
        requestParameters.thinking3dTenacityTo,
        requestParameters.economicsFrom,
        requestParameters.economicsTo,
        requestParameters.economicsTenacityFrom,
        requestParameters.economicsTenacityTo,
        requestParameters.jurisprudenceFrom,
        requestParameters.jurisprudenceTo,
        requestParameters.jurisprudenceTenacityFrom,
        requestParameters.jurisprudenceTenacityTo,
        requestParameters.microelectronicsTo,
        requestParameters.microelectronicsFrom,
        requestParameters.microelectronicsTenacityTo,
        requestParameters.microelectronicsTenacityFrom,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.lastPlayNull,
        requestParameters.lastPlayFrom,
        requestParameters.lastPlayTo,
        requestParameters.lastActivityNull,
        requestParameters.lastActivityFrom,
        requestParameters.lastActivityTo,
        requestParameters.roleInRelatedProjects,
        requestParameters.statusCompany,
        requestParameters.rejectionReason,
        requestParameters.acquisitionChannel,
        requestParameters.acquisitionChannelNull,
        requestParameters.statusEmployment,
        requestParameters.confirmationSingle,
        requestParameters.dateOfGettingAnalyticsLvl05From,
        requestParameters.dateOfGettingAnalyticsLvl05To,
        requestParameters.dateOfGettingAnalyticsLvl1From,
        requestParameters.dateOfGettingAnalyticsLvl1To,
        requestParameters.dumpedFields,
        requestParameters.secretCode,
        requestParameters.orderBy,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
