import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { DataGrid } from "../..";

import { getActiveGroup, getOrderByValue } from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
} from "../../../models/dataGrid";

import { ITable } from "src/shared/components/data-grids/mailing-lists/types";

import { QueryParams } from "src/shared/api/base";

import { notificationModel } from "src/entities/admin/notification";

import { Txt } from "src/generated/notification";

const { $notificationList, $isLoading, $pagination, resendNotify } =
  notificationModel;

export const NotificationsMailingListTable: FC<ITable> = ({
  queryParams,
  updateQueryParams,
}): ReactElement => {
  const { t } = useTranslation();

  const notificationList = useUnit($notificationList);

  const isLoading = useUnit($isLoading);

  const pagination = useUnit($pagination);

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);

  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const onClickResend = useCallback((id: string) => {
    resendNotify({ mid: id });
  }, []);

  const goToPage = (pageNum: number, isReadmore?: boolean) => {
    if (updateQueryParams) {
      const params: QueryParams = { ...queryParams, pageNum: String(pageNum) };

      if (isReadmore) {
        params.mode = "readmore";
      } else {
        delete params.mode;
      }

      updateQueryParams(params);
    }
  };

  const generateColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: t("table.header.title"),
        type: EDataGridColumnType.STRING,
        key: "title",
        showed: true,
        width: 315,
        minWidth: 315,
        nowrap: false,
      },
      {
        title: t("table.header.content"),
        type: EDataGridColumnType.STRING,
        key: "content",
        showed: true,
        width: 315,
        minWidth: 315,
      },
      {
        title: t("table.header.sent"),
        type: EDataGridColumnType.DATETIME,
        key: "when",
        showed: true,
        width: 200,
        minWidth: 200,
        controlPanel: {
          title: t("table.header.sent"),
          activeGroupKey: getActiveGroup(
            ["when", "whenFrom", "whenTo"],
            { all: ["when", "whenFrom", "whenTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "whenFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.createdFrom || "",
                  isHalf: true,
                },
                {
                  key: "whenTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.createdTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, ["when", "-when"]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.date.desc"),
                    value: "-when",
                  },
                  {
                    title: t("table.filters.date.asc"),
                    value: "when",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.recipients"),
        type: EDataGridColumnType.STRING,
        key: "recipients",
        showed: true,
      },
      {
        title: t("table.header.resend"),
        width: 1,
        minWidth: 1,
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "resendButtonTitle",
        showed: true,
        noPaddingRight: true,
        buttonProps: {
          primary: true,
          xSmall: true,
          xSmallRounded: true,
          disabled: false,
          onClick(rowId: string) {
            onClickResend(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 0,
          },
          laptop: {
            right: 0,
          },
        },
      },
    ],
    [onClickResend, queryParams, t],
  );

  useEffect(() => {
    const rowsItems: IDataGridRow[] = notificationList.map(
      (item): IDataGridRow => {
        const { title, body: content } = item.msg.body as Txt;

        return {
          key: item.id,
          tooltips: { content: { content } },
          id: item.id,
          title,
          content: <div className="notification-text-ellipsis">{content}</div>,
          when: item.when,
          recipients: item.uids.length,
          selected: false,
          resendButtonTitle: t("table.header.resend"),
        };
      },
    );

    setRows(rowsItems);
  }, [notificationList, t]);

  useEffect(() => {
    setColumns(generateColumns);
  }, [generateColumns]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"active-assessment-table"}
      queryParams={queryParams}
      onUpdateSortAndFilters={updateQueryParams}
      loading={isLoading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={(pageNum) => goToPage(pageNum, true)}
    />
  );
};
