import { FC, Fragment, lazy, ReactElement, Suspense } from "react";

import { DataGridProps } from "../../models/dataGrid";

const DataGridLazy = lazy(() => import("./data-grid"));

export const DataGrid: FC<DataGridProps> = ({ ...props }): ReactElement => {
  return (
    <Suspense fallback={<Fragment />}>
      <DataGridLazy {...props} />
    </Suspense>
  );
};
