import { IGameType, IRole } from "../store/types";
import { languagePicker } from "./languagePicker";

export const getSimulationRoles = (gameType: IGameType, lang: string) => {
  const rolesKeys: string[] | undefined = gameType.roles
    ? Object.keys(gameType.roles)
    : undefined;
  const teamsKeys: string[] | undefined = gameType.teams
    ? Object.keys(gameType.teams)
    : undefined;

  const roles = (): string => {
    const getLabels = (
      keys: string[],
      fieldType: "roles" | "teams",
    ): string => {
      const labelsArr: string[] = keys.map((item) =>
        languagePicker(gameType[fieldType]?.[item].title, lang),
      );

      return labelsArr.join(", ");
    };

    if (rolesKeys) {
      return getLabels(rolesKeys, "roles");
    }

    if (teamsKeys) {
      return getLabels(teamsKeys, "teams");
    }

    return "";
  };

  const teamRoles = (): string => {
    if (teamsKeys) {
      const localTeamsRoles: string[] = teamsKeys.reduce(
        (finalArr: string[], currItem: string) => {
          const localTeamRoles: Record<string, IRole> | undefined =
            gameType.teams?.[currItem].roles;

          if (localTeamRoles) {
            const teamRolesKey: string[] = Object.keys(localTeamRoles);

            teamRolesKey.forEach((item) => {
              const title: string = languagePicker(
                localTeamRoles?.[item].title,
                lang,
              );

              if (!finalArr.includes(title)) {
                finalArr.push(title);
              }
            });
          }

          return finalArr;
        },
        [],
      );

      return localTeamsRoles.join(", ");
    }

    return "";
  };

  return { roles: roles(), teamRoles: teamRoles() };
};

export const getSimulationRole = (payload: {
  roleKey: string;
  gameType: IGameType;
  lang?: string;
  teamRoleKey?: string;
}): { role: string; teamRole?: string } => {
  const { gameType, roleKey, lang, teamRoleKey } = payload;

  if (teamRoleKey) {
    const role = gameType.teams?.[roleKey]?.title;
    const teamRole = gameType.teams?.[roleKey]?.roles[teamRoleKey]?.title;

    return {
      role: languagePicker(role, lang),
      teamRole: languagePicker(teamRole, lang),
    };
  }

  const role = gameType.roles?.[roleKey]?.title;

  return { role: languagePicker(role, lang) };
};
