import {
  CountryCode,
  NumberFormat,
  PhoneNumber,
} from "libphonenumber-js/types";

export const parsePhone = async (
  text: string,
  regionCode?: CountryCode,
): Promise<PhoneNumber> => {
  const module = await import("libphonenumber-js");

  return module.parsePhoneNumber(text, regionCode);
};

export const formatPhone = async (
  phoneNumber?: string,
  format: NumberFormat = "INTERNATIONAL",
  regionCode?: CountryCode,
): Promise<string | undefined> => {
  if (phoneNumber === undefined) {
    return;
  }

  try {
    const phone = await parsePhone(phoneNumber, regionCode);

    return phone.format(format);
  } catch (e) {
    return phoneNumber;
  }
};
