import { createEffect, createEvent, createStore, sample } from "effector";
import {
  getComplaintsApplicationHistory,
  patchComplaintPlayerById,
} from "../../../../shared/api/hr/complaints/model/complaints-history/getComplaintsApplicationHistory";
import {
  Complaint,
  ComplaintsApiGetComplaintsRequest,
  ComplaintsApiPatchComplaintByIdRequest,
  ComplaintStatus,
  Pagination,
} from "../../../../generated/social";
import { QueryParams } from "../../../../shared/api/base";
import { EPopupName } from "../../../../shared/components";
import { openPopup } from "../../../../shared/components/base-popup/model";
import { t } from "i18next";

// stores
export const $isComplaintsLoading = createStore<boolean>(false);

export const $complaints = createStore<Complaint[]>([]);

export const $complaintsQueryParams = createStore<QueryParams>({});

export const $isComplaintsRatingsRequestError = createStore<boolean>(false);

export const $isComplaintsReadmore = createStore<boolean>(false);

export const $complaintsPagination = createStore<Pagination | null>(null);

export const $updatedPlayer = createStore<Complaint | null>(null);

// events
export const fetchComplaints = createEvent<ComplaintsApiGetComplaintsRequest>();

export const patchPlayerComplaintById =
  createEvent<ComplaintsApiPatchComplaintByIdRequest>();

// fx
const fetchComplaintsApplicationFx = createEffect(
  getComplaintsApplicationHistory,
);

const fetchPatchComplaintByIdApplicationFx = createEffect(
  patchComplaintPlayerById,
);

// samples

// sample({
//   clock: $complaintsQueryParams,
//   fn: queryParamsToRatingsRequestAdapter,
//   target: fetchComplaintsApplicationFx,
// });

sample({
  clock: fetchComplaints,
  target: fetchComplaintsApplicationFx,
});

sample({
  clock: fetchComplaintsApplicationFx.pending,
  target: $isComplaintsLoading,
});

sample({
  clock: fetchComplaintsApplicationFx.fail,
  fn: () => true,
  target: $isComplaintsRatingsRequestError,
});

sample({
  clock: fetchComplaintsApplicationFx.doneData,
  fn: (response) =>
    response.complaints.filter(
      (complaint) => complaint.status === ComplaintStatus.Pending,
    ),
  target: $complaints,
});

sample({
  clock: [fetchComplaintsApplicationFx.doneData],
  fn: ({ pagination }) => pagination || null,
  target: $complaintsPagination,
});

sample({
  clock: patchPlayerComplaintById,
  target: fetchPatchComplaintByIdApplicationFx,
});

fetchPatchComplaintByIdApplicationFx.fail.watch(() => {
  openPopup({
    message: { text: t("alert.requestError"), isError: true },
    name: EPopupName.BASE_MESSAGE_POPUP,
  });
});

sample({
  clock: fetchPatchComplaintByIdApplicationFx.doneData,
  target: $updatedPlayer,
});
