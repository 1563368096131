import { IRequestAssessmentsListAdmin } from "src/entities/public/assessments-list-admin";
import { gameService } from "src/shared/api/base/api-instances";

export const getAdmListAssessments = (
  parameters: IRequestAssessmentsListAdmin,
) => {
  const { params } = parameters;

  return gameService
    .makeAdminAssessmentApiRequest()
    .getAdmAssessments(params)
    .then((res) => res.data);
};
