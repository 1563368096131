import { PrivateRoute } from "../components";

interface Props {
  isSessionAccess: boolean;
  path: string;
  children: JSX.Element;
}

export const getSessionRoutes = ({
  path,
  children,
  isSessionAccess,
}: Props) => {
  const routes = [];

  if (isSessionAccess) {
    routes.push({
      path: path,
      element: <PrivateRoute>{children}</PrivateRoute>,
    });
  }

  return routes;
};
