import { FC, memo, ReactElement } from "react";

import ReCAPTCHA from "react-google-recaptcha";

import { BasePopup, EPopupName } from "src/shared/components";

interface Props {
  setCaptchaToken: (token?: string) => void;
}

const sitekey = process.env.REACT_APP_CAPTCHA_SITE_KEY || "";

export const CaptchaPopup: FC<Props> = memo(
  ({ setCaptchaToken }): ReactElement => {
    const handleChange = (params: string | null) =>
      setCaptchaToken(params as string);

    return (
      <BasePopup name={EPopupName.CAPTCHA}>
        <ReCAPTCHA sitekey={sitekey} onChange={handleChange} />
      </BasePopup>
    );
  },
);
