import { gameService } from "../../../base/api-instances";
import { DeleteSimulationParams } from "./query";

export const getSimulations = () => {
  return gameService
    .makeSimulationsRequest()
    .getGames()
    .then((res) => res.data);
};

export const deleteSimulation = (params: DeleteSimulationParams) => {
  return gameService
    .makeSimulationsRequest()
    .delGame(params)
    .then((res) => res.data);
};
