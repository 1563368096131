import { FC, FormEvent, ReactElement, useState } from "react";

import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";

import { useNavigate } from "react-router";

import { BaseButton, BaseInputText, FormControl } from "src/shared/components";

import { IBaseInputChange } from "src/shared/models";

import { requestPullMetaAdmin } from "src/shared/store/ducks/simulation-item-admin";

import { deleteSimulationById } from "src/shared/api/simulations/admin/delete-simulation-by-id";

import { postSimulation } from "src/shared/api/simulations/admin/post-simulation";

import {
  BasePopup,
  EPopupName,
  popupModel,
} from "src/shared/components/base-popup";

import "./add-simulation-popup.scss";

interface Props {}

const { openPopup, closePopup } = popupModel;

export const AddSimulationPopup: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [metaUrl, setMetaUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeHandler = (payload: IBaseInputChange) => {
    setMetaUrl(payload.value);
  };

  const onCreateNewAssessment = async (e: FormEvent) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);

      try {
        await postSimulation({
          options: {
            metaUrl,
          },
        }).then(async (data) => {
          const callback = () => {
            setLoading(false);
            closePopup({ name: EPopupName.ADD_SIMULATION });
            navigate(`/simulation-control/simulation/${data.game.id}`);
          };

          const errorCallback = async () => {
            setLoading(false);

            await deleteSimulationById({ id: data.game.id });
          };

          dispatch(
            requestPullMetaAdmin({
              id: data.game.id,
              callback,
              errorCallback,
            }),
          );
        });
      } catch (error: any) {
        setLoading(false);

        console.error(error);

        openPopup({
          name: EPopupName.BASE_MESSAGE_POPUP,
          message: {
            text: error?.response?.data?.err?.msg || "somethingWentWrong",
            type: "response",
            isError: true,
          },
        });
      }
    }
  };

  return (
    <BasePopup
      name={EPopupName.ADD_SIMULATION}
      title={t("simulationControl.activePage.card.btn.connect")}
    >
      <form className="add-simulation-popup" onSubmit={onCreateNewAssessment}>
        <FormControl>
          <BaseInputText
            name="metaUrl"
            placeholder={`${t(
              "simulationControl.simulationPage.linksBlock.fieldPlaceholder.link",
            )} metaUrl`}
            value={metaUrl}
            onChange={onChangeHandler}
          />
        </FormControl>
        <BaseButton
          block
          large
          primary
          submit
          disabled={!metaUrl}
          onClick={onCreateNewAssessment}
          isLoading={loading}
        >
          {t("simulationControl.activePage.card.btn.connect")}
        </BaseButton>
      </form>
    </BasePopup>
  );
};
