import { FC, ReactElement } from "react";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import { IBaseTextEditorProps } from "../../../models";

const BaseTextEditor: FC<IBaseTextEditorProps> = ({
  setRef,
  value,
  className,
  onChange,
  name,
}): ReactElement => {
  const quillToolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["link", "video"],

    ["clean"],
  ];

  const onChangeHandler = ({
    newValue,
    source,
  }: {
    newValue: string;
    source: string;
  }) => {
    onChange({ value: newValue, source, name });
  };

  return (
    <ReactQuill
      ref={(quill) => setRef?.(quill)}
      modules={{
        toolbar: quillToolbarOptions,
      }}
      theme="snow"
      value={value}
      onChange={(value, delta, source) => {
        onChangeHandler({
          newValue: value,
          source,
        });
      }}
      className={className}
    />
  );
};

export default BaseTextEditor;
