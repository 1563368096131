import { createEvent, createStore, createEffect, sample } from "effector";
import { ERequestStatus } from "src/shared/store/types";
import {
  IForceTerminateAssessmentsState,
  IRequestForceTerminateAssessments,
} from "./types";
import { postAdmAssessmentFinish } from "src/shared/api/public/force-terminate-assessments";
import {
  EPopupName,
  openResponseErrorPopup,
  popupModel,
} from "src/shared/components/base-popup";

const { closePopup } = popupModel;

// Stores

export const $forceTerminateAssessments =
  createStore<IForceTerminateAssessmentsState>({
    status: ERequestStatus.NEVER,
  });

// Events

export const requestForceTerminateAssessments =
  createEvent<IRequestForceTerminateAssessments>();
export const successForceTerminateAssessments = createEvent();
export const failureForceTerminateAssessments = createEvent();

// Effects

export const fetchForceTerminateAssessmentsFx = createEffect(
  postAdmAssessmentFinish,
);

// Logic

$forceTerminateAssessments.on(requestForceTerminateAssessments, (state) => ({
  ...state,
  status: ERequestStatus.LOADING,
}));

$forceTerminateAssessments.on(successForceTerminateAssessments, (state) => ({
  ...state,
  status: ERequestStatus.LOADED,
}));

$forceTerminateAssessments.on(failureForceTerminateAssessments, (state) => ({
  ...state,
  status: ERequestStatus.ERROR,
}));

sample({
  clock: fetchForceTerminateAssessmentsFx.fail,
  target: failureForceTerminateAssessments,
});

requestForceTerminateAssessments.watch(({ body, callback }) => {
  fetchForceTerminateAssessmentsFx({ body })
    .then(() => {
      successForceTerminateAssessments();
    })
    .then(() => {
      closePopup({ name: EPopupName.FORCE_TERMINATE });
      callback?.();
    })
    .catch((err) => {
      openResponseErrorPopup(err?.response?.data?.err?.msg);
    });
});
