import { UserInfoInterface } from "../../types";
import {
  CleanProfileInfoActionInterface,
  FailureGetProfileInfoActionInterface,
  FailurePostProfileInfoActionInterface,
  FailurePutRepeatVerifiedEmailActionInterface,
  ProfileInfoActionsType,
  RequestGetProfileInfoActionInterface,
  RequestPostProfileInfoActionInterface,
  RequestPutRepeatVerifiedEmailActionInterface,
  SuccessGetProfileInfoActionInterface,
  SuccessPostProfileInfoActionInterface,
  SuccessPutRepeatVerifiedEmailActionInterface,
} from "./actionTypes";

export const cleanProfileInfo = (): CleanProfileInfoActionInterface => ({
  type: ProfileInfoActionsType.CLEAN_PROFILE_INFO,
});

export const requestGetProfileInfo = (payload: {
  url: string;
  token: string | null;
  callback?: () => void;
}): RequestGetProfileInfoActionInterface => ({
  type: ProfileInfoActionsType.REQUEST_GET_PROFILE_INFO,
  payload,
});

export const requestPostProfileInfo = (payload: {
  url: string;
  token: string | null;
  user: UserInfoInterface;
  clientId: string;
  redirectUri: string;
  callback?: () => void;
}): RequestPostProfileInfoActionInterface => ({
  type: ProfileInfoActionsType.REQUEST_POST_PROFILE_INFO,
  payload,
});

export const requestPutRepeatVerifiedEmail = (payload: {
  redirectUri: string;
  clientId: string;
  callback?: () => void;
}): RequestPutRepeatVerifiedEmailActionInterface => ({
  type: ProfileInfoActionsType.REQUEST_PUT_REPEAT_VERIFIED_EMAIL,
  payload,
});

export const successGetProfileInfo = (
  payload: UserInfoInterface,
): SuccessGetProfileInfoActionInterface => ({
  type: ProfileInfoActionsType.SUCCESS_GET_PROFILE_INFO,
  payload,
});

export const successPostProfileInfo = (
  payload: UserInfoInterface,
): SuccessPostProfileInfoActionInterface => ({
  type: ProfileInfoActionsType.SUCCESS_POST_PROFILE_INFO,
  payload,
});

export const successPutRepeatVerifiedEmail =
  (): SuccessPutRepeatVerifiedEmailActionInterface => ({
    type: ProfileInfoActionsType.SUCCESS_PUT_REPEAT_VERIFIED_EMAIL,
  });

export const failureGetProfileInfo =
  (): FailureGetProfileInfoActionInterface => ({
    type: ProfileInfoActionsType.FAILURE_GET_PROFILE_INFO,
  });

export const failurePostProfileInfo =
  (): FailurePostProfileInfoActionInterface => ({
    type: ProfileInfoActionsType.FAILURE_POST_PROFILE_INFO,
  });

export const failurePutRepeatVerifiedEmail =
  (): FailurePutRepeatVerifiedEmailActionInterface => ({
    type: ProfileInfoActionsType.FAILURE_PUT_REPEAT_VERIFIED_EMAIL,
  });

export type ProfileInfoActions =
  | CleanProfileInfoActionInterface
  | RequestGetProfileInfoActionInterface
  | RequestPostProfileInfoActionInterface
  | RequestPutRepeatVerifiedEmailActionInterface
  | SuccessGetProfileInfoActionInterface
  | SuccessPostProfileInfoActionInterface
  | SuccessPutRepeatVerifiedEmailActionInterface
  | FailureGetProfileInfoActionInterface
  | FailurePostProfileInfoActionInterface
  | FailurePutRepeatVerifiedEmailActionInterface;
