import { createEffect, createEvent, createStore, sample } from "effector";
import {
  AssessmentApiGetPAssessmentsRequest,
  AssessmentApiGetPRolesRequest,
  PAssessmentBriefDto,
  PRoleDto,
} from "../../../../generated/statistic";
import {
  Pagination,
  PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest,
  PlayerTutorialHistoryDto,
} from "../../../../generated/game";
import {
  getComplaintsHistory,
  getFinesHistory,
  getPlayerRoles,
  getSessionsHistory,
  getTutorialHistory,
} from "../../../../shared/api/public/player-sessions-history/model/player-sessions-history";
import {
  PlayerComplaint,
  PlayerFineHistoryApiGetPlayerFineHistoryRequest,
  PlayerFineHistoryDto,
  PlayersApiGetPlayerComplaintsRequest,
} from "../../../../generated/social";

// stores

export const $sessionItems = createStore<PAssessmentBriefDto[]>([]);

export const $isSessionsHistoryReadmore = createStore<boolean>(false);

export const $playerRoles = createStore<PRoleDto[]>([]);

export const $tutorialItems = createStore<PlayerTutorialHistoryDto[]>([]);

export const $fineItems = createStore<PlayerFineHistoryDto[]>([]);

export const $complaintsItems = createStore<PlayerComplaint[]>([]);

export const $isLoading = createStore<boolean>(false);
export const $isLoadingTutorial = createStore<boolean>(false);
export const $isLoadingFines = createStore<boolean>(false);

export const $sessionsPagination = createStore<Pagination | null>(null);
export const $tutorialPagination = createStore<Pagination | null>(null);
export const $finePagination = createStore<Pagination | null>(null);

export const $complaintsPagination = createStore<Pagination | null>(null);

// events

export const requestSessionHistory =
  createEvent<AssessmentApiGetPAssessmentsRequest>();

export const requestTutorialHistory =
  createEvent<PlayerTutorialHistoryApiGetPlayerTutorialHistoryRequest>();

export const requestFinesHistory =
  createEvent<PlayerFineHistoryApiGetPlayerFineHistoryRequest>();

export const requestComplaintsHistory =
  createEvent<PlayersApiGetPlayerComplaintsRequest>();

export const requestPlayerRoles = createEvent<AssessmentApiGetPRolesRequest>();

export const setIsSessionsHistoryReadmore = createEvent<boolean>();

// effects

const requestSessionHistoryFx = createEffect(getSessionsHistory);

const requestTutorialHistoryFx = createEffect(getTutorialHistory);

const requestFinesHistoryFx = createEffect(getFinesHistory);

const requestComplaintsHistoryFx = createEffect(getComplaintsHistory);

export const getPlayerRolesFx = createEffect(getPlayerRoles);

// logic

sample({
  clock: requestSessionHistory,
  target: requestSessionHistoryFx,
});

sample({
  clock: requestTutorialHistory,
  target: requestTutorialHistoryFx,
});

sample({
  clock: requestFinesHistory,
  target: requestFinesHistoryFx,
});

sample({
  clock: requestComplaintsHistory,
  target: requestComplaintsHistoryFx,
});

sample({
  clock: setIsSessionsHistoryReadmore,
  target: $isSessionsHistoryReadmore,
});

sample({
  clock: requestPlayerRoles,
  target: getPlayerRolesFx,
});

sample({
  clock: getPlayerRolesFx.doneData,
  fn: (roles) => roles.roles,
  target: $playerRoles,
});

sample({
  clock: [
    requestTutorialHistoryFx.pending,
    requestSessionHistoryFx.pending,
    requestFinesHistoryFx.pending,
  ],
  fn: (isPending) => isPending || false,
  target: $isLoading,
});

sample({
  clock: [requestFinesHistoryFx.pending],
  fn: (isPending) => isPending || false,
  target: $isLoadingFines,
});

sample({
  clock: [requestFinesHistoryFx.done],
  fn: () => false,
  target: $isLoadingFines,
});

sample({
  clock: [requestTutorialHistoryFx.pending],
  fn: (isPending) => isPending || false,
  target: $isLoadingTutorial,
});

sample({
  clock: [requestTutorialHistoryFx.done],
  fn: () => false,
  target: $isLoadingTutorial,
});

sample({
  clock: [
    requestTutorialHistoryFx.done,
    requestSessionHistoryFx.done,
    requestFinesHistoryFx.done,
  ],
  fn: () => false,
  target: $isLoading,
});

sample({
  clock: requestSessionHistoryFx.doneData,
  source: {
    displayedSessions: $sessionItems,
    isReadmore: $isSessionsHistoryReadmore,
  },
  fn: ({ displayedSessions, isReadmore }, sessions) => {
    const requestedSessions = sessions.assessments ?? [];

    return isReadmore
      ? [...displayedSessions, ...requestedSessions]
      : requestedSessions;
  },
  target: $sessionItems,
});

sample({
  clock: requestSessionHistoryFx.doneData,
  fn: (data) => data.pagination || null,
  target: $sessionsPagination,
});

sample({
  clock: requestTutorialHistoryFx.doneData,
  source: $tutorialItems,
  fn: (sessions, data) => data.tutorials ?? sessions,
  target: $tutorialItems,
});

sample({
  clock: requestTutorialHistoryFx.doneData,
  fn: (data) => data.pagination || null,
  target: $tutorialPagination,
});

sample({
  clock: requestFinesHistoryFx.doneData,
  source: $fineItems,
  fn: (fines, data) => data.player_fines_history ?? fines,
  target: $fineItems,
});

sample({
  clock: requestComplaintsHistoryFx.doneData,
  source: $complaintsItems,
  fn: (complaints, data) => {
    return data.complaints ?? complaints;
  },
  target: $complaintsItems,
});

sample({
  clock: requestFinesHistoryFx.doneData,
  fn: (data) => data.pagination || null,
  target: $finePagination,
});

sample({
  clock: requestComplaintsHistoryFx.doneData,
  fn: (data) => data.pagination || null,
  target: $complaintsPagination,
});
