import { GetRatingParams, QueryParams } from "src/shared/api/admin/players";
import { getDateForFilter, stringToArrayStrings } from "src/shared/helpers";
import { stringToArrayNumbers } from "src/shared/helpers/stringToArrayNumbers";
import {
  renderAllCompetencyParams,
  updateAdditionalCompetence,
} from "../../../../shared/helpers/renderAdditionalParams";
import { ERegEx, regExList } from "../../../../shared/constants/regExList";

export const queryParamsToRatingsRequestAdapter = (
  queryParams: QueryParams,
): GetRatingParams => {
  const {
    firstName,
    lastName,
    gender,
    phone,
    email,
    emailFull,
    groupCode,
    groupCodeNotNull,
    birthdateFrom,
    birthdateTo,
    birthdateNull,
    country,
    countryNull,
    region,
    regionNull,
    district,
    districtNull,
    school,
    schoolNull,
    locality,
    localityNull,
    gPlaceTo,
    gPlaceFrom,
    gRateTo,
    gRateFrom,
    pageNum,
    pageSize,
    orderBy,
    communicationTo,
    communicationFrom,
    commandTo,
    commandFrom,
    analyticsTo,
    analyticsFrom,
    thinking3dFrom,
    thinking3dTo,
    economicsFrom,
    economicsTo,
    jurisprudenceFrom,
    jurisprudenceTo,
    lastActivityFrom,
    lastActivityTo,
    lastActivityNull,
    lastPlayFrom,
    lastPlayTo,
    lastPlayNull,
    createdFrom,
    createdTo,
    desiredStudyPlace,
    studyWorkPlace,
    studyWorkPlaceNull,
    desiredSpecialty,
    desiredSpecialtyNull,
    communicationTenacityFrom,
    communicationTenacityTo,
    confirmationSingle,
    dateOfGettingAnalyticsLvl05From,
    dateOfGettingAnalyticsLvl05To,
    dateOfGettingAnalyticsLvl1From,
    dateOfGettingAnalyticsLvl1To,
    analyticsTenacityFrom,
    analyticsTenacityTo,
    commandTenacityFrom,
    commandTenacityTo,
    statusEmployment,
    statusCompany,
    acquisitionChannel,
    acquisitionChannelNull,
    rejectionReason,
    roleInRelatedProjects,
    additionalCompetence,
    additionalFrom,
    additionalTo,
    secretCode,
  } = queryParams;

  const additionalCompetencies = additionalCompetence?.split(",");

  let orderByAdditional: string =
    updateAdditionalCompetence(orderBy, additionalCompetencies) || orderBy;

  return {
    firstName: stringToArrayStrings(firstName),
    lastName: stringToArrayStrings(lastName),
    phone: phone
      ? stringToArrayStrings(phone?.replace(regExList[ERegEx.ONLY_NUMBERS], ""))
      : undefined,
    email: stringToArrayStrings(email),
    emailFull: emailFull ? emailFull === "true" : undefined,
    acquisitionChannel: stringToArrayStrings(acquisitionChannel),
    acquisitionChannelNull: acquisitionChannelNull
      ? acquisitionChannelNull === "true"
      : undefined,
    groupCode: stringToArrayStrings(groupCode),
    groupCodeNotNull: groupCodeNotNull
      ? groupCodeNotNull === "true"
      : undefined,
    roleInRelatedProjects: stringToArrayStrings(roleInRelatedProjects),
    country: stringToArrayStrings(country),
    countryNull: countryNull ? countryNull === "true" : undefined,
    statusEmployment: stringToArrayStrings(statusEmployment),
    statusCompany: stringToArrayStrings(statusCompany),
    rejectionReason: stringToArrayStrings(rejectionReason),
    birthdateFrom: getDateForFilter(birthdateFrom),
    birthdateTo: getDateForFilter(birthdateTo, true),
    birthdateNull: birthdateNull ? birthdateNull === "true" : undefined,
    dateOfGettingAnalyticsLvl05From: getDateForFilter(
      dateOfGettingAnalyticsLvl05From,
    ),
    dateOfGettingAnalyticsLvl05To: getDateForFilter(
      dateOfGettingAnalyticsLvl05To,
      true,
    ),
    dateOfGettingAnalyticsLvl1From: getDateForFilter(
      dateOfGettingAnalyticsLvl1From,
    ),
    dateOfGettingAnalyticsLvl1To: getDateForFilter(
      dateOfGettingAnalyticsLvl1To,
      true,
    ),
    region: stringToArrayNumbers(region),
    regionNull: regionNull ? regionNull === "true" : undefined,
    district: stringToArrayNumbers(district),
    districtNull: districtNull ? districtNull === "true" : undefined,
    school: stringToArrayNumbers(school),
    schoolNull: schoolNull ? schoolNull === "true" : undefined,
    locality: stringToArrayStrings(locality),
    localityNull: localityNull ? localityNull === "true" : undefined,
    studyWorkPlace: stringToArrayStrings(studyWorkPlace),
    studyWorkPlaceNull: studyWorkPlaceNull
      ? studyWorkPlaceNull === "true"
      : undefined,
    confirmationSingle: confirmationSingle
      ? confirmationSingle === "true"
      : undefined,
    desiredSpecialty: stringToArrayStrings(desiredSpecialty),
    desiredSpecialtyNull: desiredSpecialtyNull
      ? desiredSpecialtyNull === "true"
      : undefined,
    gPlaceFrom: gPlaceFrom ? Number(gPlaceFrom) : undefined,
    gPlaceTo: gPlaceTo ? Number(gPlaceTo) : undefined,
    gRateFrom: gRateFrom ? Number(gRateFrom) : undefined,
    gRateTo: gRateTo ? Number(gRateTo) : undefined,
    analyticsFrom: analyticsFrom ? Number(analyticsFrom) : undefined,
    analyticsTo: analyticsTo ? Number(analyticsTo) : undefined,
    commandFrom: commandFrom ? Number(commandFrom) : undefined,
    commandTo: commandTo ? Number(commandTo) : undefined,
    thinking3dFrom: thinking3dFrom ? Number(thinking3dFrom) : undefined,
    thinking3dTo: thinking3dTo ? Number(thinking3dTo) : undefined,
    economicsFrom: economicsFrom ? Number(economicsFrom) : undefined,
    economicsTo: economicsTo ? Number(economicsTo) : undefined,
    jurisprudenceFrom: jurisprudenceFrom
      ? Number(jurisprudenceFrom)
      : undefined,
    jurisprudenceTo: jurisprudenceTo ? Number(jurisprudenceTo) : undefined,
    communicationFrom: communicationFrom
      ? Number(communicationFrom)
      : undefined,
    communicationTo: communicationTo ? Number(communicationTo) : undefined,
    lastActivityFrom: getDateForFilter(lastActivityFrom),
    lastActivityTo: getDateForFilter(lastActivityTo, true),
    lastActivityNull: lastActivityNull
      ? lastActivityNull === "true"
      : undefined,
    lastPlayFrom: getDateForFilter(lastPlayFrom),
    lastPlayTo: getDateForFilter(lastPlayTo, true),
    lastPlayNull: lastPlayNull ? lastPlayNull === "true" : undefined,
    createdFrom: getDateForFilter(createdFrom),
    createdTo: getDateForFilter(createdTo, true),
    orderBy: stringToArrayStrings(orderBy)
      ? stringToArrayStrings(orderByAdditional)
      : undefined,
    pageNum: pageNum ? Number(pageNum) : undefined,
    pageSize: pageSize ? Number(pageSize) : 50,
    desiredStudyPlace: desiredStudyPlace
      ? desiredStudyPlace.split(",")
      : undefined,
    gender: stringToArrayStrings(gender),
    communicationTenacityFrom: communicationTenacityFrom
      ? Number(communicationTenacityFrom)
      : undefined,
    communicationTenacityTo: communicationTenacityTo
      ? Number(communicationTenacityTo)
      : undefined,

    analyticsTenacityFrom: analyticsTenacityFrom
      ? Number(analyticsTenacityFrom)
      : undefined,
    analyticsTenacityTo: analyticsTenacityTo
      ? Number(analyticsTenacityTo)
      : undefined,

    commandTenacityFrom: commandTenacityFrom
      ? Number(commandTenacityFrom)
      : undefined,
    commandTenacityTo: commandTenacityTo
      ? Number(commandTenacityTo)
      : undefined,
    secretCode: secretCode ? Number(secretCode) : undefined,
    ...renderAllCompetencyParams(
      additionalCompetencies,
      additionalFrom,
      additionalTo,
      additionalCompetence,
    ),
  };
};
