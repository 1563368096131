import { IBaseCardListItem } from "src/shared/components";

import { ELanguage } from "src/shared/store/types";

import { languagePicker, t } from "src/shared/helpers";

import { ISimulationAdmin } from "src/entities/admin/simulations";

import { deleteSimulation } from "./deleteSimulation";

export const getBaseCardListItemsAdapter = (
  simulations: ISimulationAdmin[],
  language: ELanguage,
): IBaseCardListItem[] =>
  simulations.map(({ id, title, rawMetaJson }) => ({
    id,
    title: languagePicker(title, language),
    btnLabel: t("simulationControl.activePage.card.btn.tune"),
    imgSrc: languagePicker(rawMetaJson?.info?.logoUrl, language),
    settingsBtnProps: {
      iconSize: 18,
      labelWeight: "bold",
      iconName: "trash-red",
      color: "red",
      label: t("simulationControl.activePage.card.btn.delete"),
    },
    to: `/simulation-control/simulation/${id}`,
    onClickSettings: () => deleteSimulation(id),
  }));
