import classNames from "classnames";
import styles from "../players-list/players-list.module.scss";
import {
  BaseAvatar,
  BaseSelect,
  IconCross,
} from "../../../../../../../shared/components";
import { IconCrossColor } from "../../../../../../../shared/components/icons-components/icon-cross/icon-cross";
import { FC, MouseEvent, ReactElement } from "react";
import { IPlayer, ITeam } from "../../session-table";
import {
  IBaseInputChange,
  UserRoleEnum,
} from "../../../../../../../shared/models";
import { Join as UserInfoDto } from "../../../../../../../generated/ws4";
import { DraggableItem } from "../../../../../../../shared/components/base-draggable-item/base-draggable-item";
import { DragItem } from "../../../../../../../shared/constants/dragEnums";
import { PlayerDragLayer } from "../player-drag-layer/player-drag-layer";

interface PlayerCardProps {
  item: IPlayer;
  group: ITeam;
  disableSelect?: boolean;
  isCEO?: boolean;
  isTopDropdownPosition: (index: number) => void;
  currentPlayerId?: string;
  onChangeHandler: (
    e: IBaseInputChange,
    currentRole: string,
    userId: string,
  ) => void;
  computeLabel: (item: UserInfoDto) => string | undefined;
  isHrClientId: boolean;
  handleCrossClick: (event: MouseEvent, player: IPlayer) => void;
  isTeam?: boolean;
  openAllocatePlayerPopup: (player: UserInfoDto) => void;
}

export const PlayerCard: FC<PlayerCardProps> = ({
  item,
  disableSelect,
  isCEO,
  currentPlayerId,
  group,
  onChangeHandler,
  computeLabel,
  isHrClientId,
  handleCrossClick,
  isTeam,
  openAllocatePlayerPopup,
}): ReactElement => {
  return (
    <>
      <DraggableItem
        type={DragItem.PLAYER}
        data={item}
        canDrag={isHrClientId}
        element={(isDragging, dragRef) => {
          return (
            <div
              key={item.pid}
              ref={dragRef}
              id="dragged-player"
              className={classNames(styles.cell, {
                [styles.ceo]: isCEO,
                [styles["cell--blue"]]: item.pid === currentPlayerId,
                [styles.transparent]: item.isSearchTarget === false,
                [styles.dragging]: isDragging,
              })}
              onClick={() => openAllocatePlayerPopup(item)}
            >
              <BaseAvatar
                size={40}
                userId={item.pid}
                className={classNames({
                  [styles["base-avatar__admin"]]:
                    item.user_role === UserRoleEnum.HR_ADMIN && isHrClientId,
                  [styles["base-avatar__hr"]]:
                    item.user_role === UserRoleEnum.HR_ALABUGA && isHrClientId,
                })}
              />
              <span
                className={classNames(styles.name, {
                  [styles.nameTeam]: isTeam,
                })}
              >
                {item.firstname} {item.lastname}
              </span>
              {!disableSelect && (
                <span onClick={(e) => e.stopPropagation()}>
                  <BaseSelect
                    isSessionTable
                    items={group.roles}
                    activeItem={item.role}
                    hideEmptyItem
                    hideBorder
                    size="small"
                    className={styles.select}
                    // isTopPosition={() => isTopDropdownPosition(index)}
                    onChange={(e) => onChangeHandler(e, item.role, item.pid)}
                  />
                </span>
              )}
              {disableSelect && computeLabel(item)}
              {isHrClientId && (
                <span className={styles.buttonCross}>
                  <IconCross
                    size={20}
                    color={IconCrossColor.RED}
                    onClick={(event) => handleCrossClick(event, item)}
                  />
                </span>
              )}
            </div>
          );
        }}
      />
      <PlayerDragLayer />
    </>
  );
};
