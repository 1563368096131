import { PermissionDto } from "src/generated/social";

export const recursiveChangeStatus = (
  data: PermissionDto[],
  changed: PermissionDto,
): PermissionDto[] => {
  // рекурсивный проход по данным с бэка и проставление галок по полю enabled
  return data.map((current) => {
    if (changed.type === "group_radio" && changed.group === current.group) {
      return {
        ...current,
        selected: current.name === changed.name,
      };
    }
    if (changed.type === "checkbox" && changed.name === current.name) {
      return {
        ...current,
        is_enabled: !current.is_enabled,
      };
    }
    if (current.children?.length) {
      current.children = recursiveChangeStatus(
        current.children as PermissionDto[],
        changed,
      );
    }
    return current;
  });
};
