import { socialService } from "../../../base/api-instances";
import { PlayersApiGetPlayerRequest } from "src/generated/social";

export const getUserProfileCompetence = (
  payload: PlayersApiGetPlayerRequest,
) => {
  return socialService
    .makePlayerRequest()
    .getPlayer(payload)
    .then((response) => response.data);
};
