import { FC } from "react";

import { EPopupName, popupModel } from "src/shared/components/base-popup";
import { ConfirmPopup } from "src/shared/components";
import { useTranslation } from "react-i18next";

import { finesModel } from "src/entities/admin/fines";
import { useUnit } from "effector-react/effector-react.umd";
import { $fines } from "src/entities/admin/fines/model";
import { FineDto } from "src/generated/social";

const { closePopup } = popupModel;

interface DeleteFinesPopupProps {
  selectedFines: string[];
  clearSelectedFines: () => void;
}

export const DeleteFinesPopup: FC<DeleteFinesPopupProps> = ({
  selectedFines,
  clearSelectedFines,
}) => {
  const { t } = useTranslation();

  const fines = useUnit($fines);

  let finesToDelete: FineDto[] = [];

  selectedFines.forEach((selectedFine) => {
    let fine = fines.filter((fine) => fine.fine_id === selectedFine);
    finesToDelete.push(fine[0]);
  });

  const onConfirm = () => {
    if (finesToDelete) {
      finesModel.archiveFines({ fine: { fines: finesToDelete } });
    }
    clearSelectedFines();
    closePopup({ name: EPopupName.DELETE_FINES_POPUP });
  };

  return (
    <ConfirmPopup
      popupName={EPopupName.DELETE_FINES_POPUP}
      title={t("fine.deleteFine")}
      cancelBtnLabel={t("common.canceled")}
      description={t("fine.deleteFine.desc")}
      confirmBtnLabel={t("common.delete")}
      onConfirm={onConfirm}
    />
  );
};
