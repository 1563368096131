import { FC, ReactElement } from "react";

import { useTranslation } from "react-i18next";

import useClient from "src/shared/hooks/useClient";

import { BaseButton, BaseGroup, TextWithIcon } from "src/shared/components";
import { LocStrDto } from "../../../../../generated/game";

interface ISessionControl {
  isDisableStartBtn?: boolean;
  leaveRoom: () => void;
  openRulesPopup: () => void;
  openDeletePopup: () => void;
  openStartPopup: () => void;
  startSession: () => void;
  openSessionSettings: () => void;
  rules: LocStrDto | string;
}

export const SessionControl: FC<ISessionControl> = ({
  isDisableStartBtn,
  leaveRoom,
  openRulesPopup,
  openDeletePopup,
  openStartPopup,
  rules,
}: ISessionControl): ReactElement => {
  const { t } = useTranslation();
  const { isPlayerClientId, isHrClientId } = useClient();

  return (
    <div className="session__controls">
      {isPlayerClientId && (
        <div className="session__controls-panel">
          <BaseGroup
            left={
              <BaseButton
                block
                small
                outline
                onClick={leaveRoom}
                className="session__controls-btn"
              >
                <TextWithIcon
                  label={t("menu.btn.logout")}
                  iconName="logout-blue"
                  isTextNowrap
                  iconSize={24}
                />
              </BaseButton>
            }
            right={
              rules === "" ? null : (
                <BaseButton
                  block
                  small
                  primary
                  onClick={openRulesPopup}
                  className="session__controls-btn"
                >
                  <TextWithIcon
                    label={t("sessions.session.btn.rules")}
                    color="white"
                    iconName="book-white"
                    isTextNowrap
                    iconSize={24}
                  />
                </BaseButton>
              )
            }
          />
        </div>
      )}
      {isHrClientId && (
        <div className="session__controls-panel session__controls-panel--mobile-wrap">
          <BaseButton
            block
            small
            secondary
            onClick={openDeletePopup}
            className="session__controls-btn session__controls-btn--mobile-short"
          >
            <TextWithIcon
              label={t("sessions.session.end")}
              color="red"
              isTextNowrap
              iconName="cross-red"
              iconSize={22}
            />
          </BaseButton>
          <BaseButton
            block
            small
            outline
            onClick={leaveRoom}
            className="session__controls-btn session__controls-btn--mobile-short"
          >
            <TextWithIcon
              label={t("menu.btn.logout")}
              iconName="logout-blue"
              isTextNowrap
              iconSize={22}
            />
          </BaseButton>
          <BaseButton
            block
            small
            primary
            disabled={isDisableStartBtn}
            onClick={openStartPopup}
            className="session__controls-btn"
          >
            <TextWithIcon
              label={t("sessions.session.start")}
              color="white"
              iconName="play-white"
              isTextNowrap
              iconSize={22}
            />
          </BaseButton>
        </div>
      )}
    </div>
  );
};
