import { socialService } from "../../../../base/api-instances";
import { ChangePlayerHistoryApiGetChangePlayerHistoryRequest } from "src/generated/social";

export const getRatingsChangeHistory = (
  params: ChangePlayerHistoryApiGetChangePlayerHistoryRequest,
) => {
  return socialService
    .makeChangePlayerRatingsRequest()
    .getChangePlayerHistory(params)
    .then((res) => res.data);
};
