import React from "react";
import { useTranslation } from "react-i18next";
import {
  exportHistoryCsv,
  exportHistoryXlsx,
} from "src/entities/hr/export-rates/model";
import { BaseButton, TextWithIcon } from "src/shared/components";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import { useUrlState } from "../../../../shared/hooks/useUrlState";

export const SessionsHistoryTableControl = () => {
  const { t } = useTranslation();

  const [urlState] = useUrlState();

  return (
    <div className="table__control">
      <div className="table__control-btn table__control-btn--hide-on-not-mobile">
        <DataGridMobileSortButton />
      </div>
      <div className="table__control-btn table__control-btn--hide-on-not-mobile">
        <DataGridMobileFiltersButton />
      </div>
      <div className="table__control-btn">
        <BaseButton
          onClick={() => exportHistoryCsv({ playerId: urlState.params.id })}
        >
          <TextWithIcon
            label={`${t("common.unload")} CSV`}
            iconName={"upload-blue"}
            isTextNowrap
          />
        </BaseButton>
      </div>
      <div className="table__control-btn">
        <BaseButton
          onClick={() => exportHistoryXlsx({ playerId: urlState.params.id })}
        >
          <TextWithIcon
            label={`${t("common.unload")} XLSX`}
            iconName={"upload-blue"}
            isTextNowrap
          />
        </BaseButton>
      </div>
    </div>
  );
};
