import { TTemplateParams } from "../components/simulation-params-fields/simulation-params-fields";
import {
  ESimulationParamsType,
  IMetaParams,
  IParams,
  SimulationListPlayerMetaParamsItemInterface,
} from "../store/types";

export const getSimulationParamsFieldItem = (
  simulationParams: IMetaParams,
  isDefaultParams: boolean,
  templateParams?: IParams
): TTemplateParams => {
  const localParams: TTemplateParams = {};
  const simulationParamsKeys: string[] = Object.keys(simulationParams);

  simulationParamsKeys.forEach((simulationParamKey) => {
    const {
      default: defaultParamValue,
      type,
      items,
      required,
    }: SimulationListPlayerMetaParamsItemInterface = simulationParams[
      simulationParamKey
    ];

    const templateParamItem = templateParams?.[simulationParamKey];

    const isTemplateParamItem: boolean = templateParamItem !== undefined;

    const isArrayType: boolean = type === ESimulationParamsType.ARRAY;

    const isBooleanType: boolean = type === ESimulationParamsType.BOOLEAN;

    const getValue = () => {
      if (isArrayType) {
        const defaultValue: string[] = isDefaultParams
          ? [String(items?.default)]
          : [];

        return isTemplateParamItem
          ? (templateParamItem as Array<string | number>).map((item) =>
              String(item)
            )
          : defaultValue;
      }

      if (isBooleanType) {
        return isTemplateParamItem ? !!templateParamItem : !!defaultParamValue;
      }

      const defaultValue: string = isDefaultParams
        ? String(defaultParamValue || "")
        : "";

      return isTemplateParamItem ? String(templateParamItem) : defaultValue;
    };

    localParams[simulationParamKey] = {
      subType: isArrayType ? items?.type : undefined,
      value: getValue(),
      type,
      required,
    };
  });

  return localParams;
};
