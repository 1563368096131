import { sample } from "effector";

import { getSimulations } from "src/shared/api/public/simulations";

import { simulationsPublicEntitiesDomain } from "./domain";

import { ISimulationPlayer } from "./types";

// Stores

export const $simulations = simulationsPublicEntitiesDomain.createStore<
  ISimulationPlayer[]
>([]);

export const $isLoading =
  simulationsPublicEntitiesDomain.createStore<boolean>(false);

// Events

export const fetchSimulations = simulationsPublicEntitiesDomain.createEvent();

// Effects

export const fetchSimulationsFx =
  simulationsPublicEntitiesDomain.createEffect(getSimulations);

// Logic

sample({
  clock: fetchSimulations,
  target: fetchSimulationsFx,
});

sample({
  clock: fetchSimulationsFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchSimulationsFx.doneData,
  fn: ({ games }) => (games as ISimulationPlayer[]) || [],
  target: $simulations,
});
