import { sample } from "effector";

import { Txt } from "src/generated/notification";

import { ImagesApiUploadImgImagesPostRequest } from "src/generated/cdn";

import { uploadImage } from "src/shared/api/admin/cdn";

import { notificationDomain } from "../model";

const cdnUrl = `${process.env.REACT_APP_yandexStorageEndpointWebsite}/${process.env.REACT_APP_yandexStorageImagesBucket}`;

// Events

export const updateTxtNotification = notificationDomain.createEvent<Txt>();

export const uploadFile =
  notificationDomain.createEvent<ImagesApiUploadImgImagesPostRequest>();

// Effects

export const uploadFileFx = notificationDomain.createEffect(uploadImage);

// Stores

export const $notificationTxt = notificationDomain.createStore<Txt>({} as Txt);

// Logic

sample({
  clock: updateTxtNotification,
  target: $notificationTxt,
});

sample({
  clock: uploadFile,
  target: uploadFileFx,
});

sample({
  clock: uploadFileFx.doneData,
  source: $notificationTxt,
  fn: (notificationTxt, data) => {
    const logoUrl = cdnUrl + "/" + data.filename;

    return { ...notificationTxt, logoUrl };
  },
  target: $notificationTxt,
});
