// Effects

import { sample } from "effector";

import { some } from "patronum";

import { QueryParams } from "src/shared/api/base";
import { getCountries } from "src/shared/api/public/countries";

import { getDistricts } from "src/shared/api/public/districts";

import { getRegions } from "src/shared/api/public/regions";

import { getSchools } from "src/shared/api/public/schools";

import { Country, District, Region, School } from "src/generated/social";
import {
  queryParamsToGetDistrictsListParamsAdapter,
  queryParamsToGetRegionsListParamsAdapter,
  queryParamsToGetSchoolsListParamsAdapter,
} from "../lib/queryParamsToGetParamsAdapter";

import { userFormDomain } from "./domain";

// Effects

export const fetchCountriesFx = userFormDomain.createEffect(getCountries);

export const fetchRegionsFx = userFormDomain.createEffect(getRegions);

export const fetchDistrictsFx = userFormDomain.createEffect(getDistricts);

export const fetchSchoolsFx = userFormDomain.createEffect(getSchools);

// Stores

export const $countries = userFormDomain.createStore<Country[]>([]);

export const $regions = userFormDomain.createStore<Region[]>([]);

export const $districts = userFormDomain.createStore<District[]>([]);

export const $schools = userFormDomain.createStore<School[]>([]);

export const $isCountriesLoading = userFormDomain.createStore<boolean>(false);

export const $isRegionsLoading = userFormDomain.createStore<boolean>(false);

export const $isDistrictsLoading = userFormDomain.createStore<boolean>(false);

export const $isSchoolsLoading = userFormDomain.createStore<boolean>(false);

export const $isSomeDictionaryLoading = some({
  predicate: (loading) => loading,
  stores: [
    $isCountriesLoading,
    $isRegionsLoading,
    $isDistrictsLoading,
    $isSchoolsLoading,
  ],
});

// Events

export const fetchCountries = userFormDomain.createEvent<QueryParams>();

export const fetchRegions = userFormDomain.createEvent<QueryParams>();

export const fetchDistricts = userFormDomain.createEvent<QueryParams>();

export const fetchSchools = userFormDomain.createEvent<QueryParams>();

// Logic

/* fetch */
sample({
  clock: fetchCountries,
  target: fetchCountriesFx,
});

sample({
  clock: fetchRegions,
  fn: queryParamsToGetRegionsListParamsAdapter,
  target: fetchRegionsFx,
});

sample({
  clock: fetchDistricts,
  fn: queryParamsToGetDistrictsListParamsAdapter,
  target: fetchDistrictsFx,
});

sample({
  clock: fetchSchools,
  fn: queryParamsToGetSchoolsListParamsAdapter,
  target: fetchSchoolsFx,
});

/* loading detect */
sample({
  clock: fetchCountriesFx.pending,
  target: $isCountriesLoading,
});

sample({
  clock: fetchRegionsFx.pending,
  target: $isRegionsLoading,
});

sample({
  clock: fetchDistrictsFx.pending,
  target: $isDistrictsLoading,
});

sample({
  clock: fetchSchoolsFx.pending,
  target: $isSchoolsLoading,
});

/* data setting */
sample({
  clock: fetchCountriesFx.doneData,
  fn: ({ countries }) => countries,
  target: $countries,
});

sample({
  clock: fetchRegionsFx.doneData,
  fn: ({ regions }) => regions,
  target: $regions,
});

sample({
  clock: fetchDistrictsFx.doneData,
  fn: ({ districts }) => districts,
  target: $districts,
});

sample({
  clock: fetchSchoolsFx.doneData,
  fn: ({ schools }) => schools,
  target: $schools,
});
