import { Player } from "../../../../generated/social";
import {
  CleanProfileCompetenceActionInterface,
  FailureProfileCompetenceActionInterface,
  ProfileCompetenceActionsType,
  RequestProfileCompetenceActionInterface,
  SetIsSocialInterface,
  SuccessProfileCompetenceActionInterface,
} from "./actionTypes";

export const cleanProfileCompetence =
  (): CleanProfileCompetenceActionInterface => ({
    type: ProfileCompetenceActionsType.CLEAN_PROFILE_COMPETENCE,
  });

export const requestProfileCompetence = (payload: {
  id: string;
  callback?: () => void;
}): RequestProfileCompetenceActionInterface => ({
  type: ProfileCompetenceActionsType.REQUEST_PROFILE_COMPETENCE,
  payload,
});

export const successProfileCompetence = (
  payload: Player,
): SuccessProfileCompetenceActionInterface => ({
  type: ProfileCompetenceActionsType.SUCCESS_PROFILE_COMPETENCE,
  payload,
});

export const failureProfileCompetence =
  (): FailureProfileCompetenceActionInterface => ({
    type: ProfileCompetenceActionsType.FAILURE_PROFILE_COMPETENCE,
  });

export const setIsSocial = (payload: boolean): SetIsSocialInterface => ({
  type: ProfileCompetenceActionsType.SET_IS_SOCIAL,
  payload,
});

export type ProfileCompetenceActions =
  | CleanProfileCompetenceActionInterface
  | RequestProfileCompetenceActionInterface
  | SuccessProfileCompetenceActionInterface
  | FailureProfileCompetenceActionInterface
  | SetIsSocialInterface;
