export interface MapMarker {
  name: string;
  size: number;
  coordinates: [number, number];
}

export const russiaCityMarkers: MapMarker[] = [
  { name: "Москва", size: 4, coordinates: [37.6173, 55.7558] },
  {
    name: "Санкт-Петербург",
    size: 4,
    coordinates: [30.3158, 59.9343],
  },
  { name: "Абакан", size: 4, coordinates: [91.4294, 53.7209] },
  { name: "Анадырь", size: 4, coordinates: [177.5035, 64.7314] },
  { name: "Архангельск", size: 4, coordinates: [40.5168, 64.5399] },
  { name: "Астрахань", size: 2, coordinates: [48.0336, 46.3497] },
  { name: "Барнаул", size: 4, coordinates: [83.7636, 53.348] },
  { name: "Белгород", size: 2, coordinates: [36.5983, 50.5954] },
  { name: "Биробиджан", size: 2, coordinates: [132.9334, 48.7946] },
  { name: "Благовещенск", size: 4, coordinates: [127.5348, 50.2906] },
  { name: "Брянск", size: 2, coordinates: [34.3633, 53.2521] },
  {
    name: "Великий Новгород",
    size: 2,
    coordinates: [31.2729, 58.5213],
  },
  { name: "Владивосток", size: 4, coordinates: [131.8855, 43.1155] },
  { name: "Владикавказ", size: 1, coordinates: [44.672, 43.035] },
  { name: "Владимир", size: 2, coordinates: [40.4083, 56.1291] },
  { name: "Волгоград", size: 4, coordinates: [44.5168, 48.7072] },
  { name: "Вологда", size: 4, coordinates: [39.8916, 59.2205] },
  { name: "Воронеж", size: 4, coordinates: [39.1843, 51.6755] },
  { name: "Горно-Алтайск", size: 4, coordinates: [85.9585, 51.9584] },
  { name: "Грозный", size: 2, coordinates: [45.6949, 43.3178] },
  { name: "Екатеринбург", size: 4, coordinates: [60.6122, 56.8519] },
  { name: "Иваново", size: 4, coordinates: [40.9817, 57.0004] },
  { name: "Ижевск", size: 4, coordinates: [53.2077, 56.8528] },
  { name: "Иркутск", size: 4, coordinates: [104.296, 52.2978] },
  { name: "Йошкар-Ола", size: 2, coordinates: [47.8864, 56.6344] },
  { name: "Казань", size: 3, coordinates: [49.1221, 55.8304] },
  { name: "Калининград", size: 4, coordinates: [20.4522, 54.7104] },
  { name: "Калуга", size: 4, coordinates: [36.2754, 54.507] },
  { name: "Кемерово", size: 4, coordinates: [86.0879, 55.3903] },
  { name: "Киров", size: 4, coordinates: [49.6683, 58.6036] },
  { name: "Кострома", size: 2, coordinates: [40.9345, 57.7677] },
  { name: "Краснодар", size: 2, coordinates: [38.976, 45.0355] },
  { name: "Красноярск", size: 4, coordinates: [92.8705, 56.0106] },
  { name: "Курган", size: 4, coordinates: [65.3458, 55.4413] },
  { name: "Курск", size: 2, coordinates: [36.193, 51.7304] },
  { name: "Кызыл", size: 4, coordinates: [95.7197, 51.7063] },
  { name: "Липецк", size: 2, coordinates: [39.5708, 52.6103] },
  { name: "Магадан", size: 4, coordinates: [150.8035, 59.5682] },
  { name: "Махачкала", size: 2, coordinates: [47.5046, 42.9831] },
  { name: "Майкоп", size: 2, coordinates: [40.1058, 44.609] },
  { name: "Мурманск", size: 4, coordinates: [33.0846, 68.9707] },
  { name: "Назрань", size: 1, coordinates: [44.7744, 43.2274] },
  { name: "Нальчик", size: 2, coordinates: [43.6188, 43.498] },
  { name: "Нарьян-Мар", size: 4, coordinates: [53.0877, 67.638] },
  { name: "Нижний Новгород", size: 2, coordinates: [44.002, 56.3269] },
  { name: "Новосибирск", size: 4, coordinates: [82.9346, 55.0084] },
  { name: "Омск", size: 4, coordinates: [73.3682, 54.9885] },
  { name: "Орёл", size: 2, coordinates: [36.079, 52.9685] },
  { name: "Оренбург", size: 4, coordinates: [55.101, 51.7682] },
  { name: "Пенза", size: 2, coordinates: [45.0192, 53.1945] },
  { name: "Пермь", size: 4, coordinates: [56.2516, 58.0105] },
  { name: "Петрозаводск", size: 4, coordinates: [34.3605, 61.785] },
  {
    name: "Петропавловск-Камчатский",
    size: 4,
    coordinates: [158.6483, 53.0341],
  },
  { name: "Псков", size: 4, coordinates: [28.334, 57.8194] },
  { name: "Ростов-на-Дону", size: 4, coordinates: [39.7015, 47.2357] },
  { name: "Рязань", size: 4, coordinates: [39.7203, 54.6197] },
  { name: "Салехард", size: 4, coordinates: [66.6139, 66.5333] },
  { name: "Самара", size: 4, coordinates: [50.1004, 53.1959] },
  { name: "Саратов", size: 4, coordinates: [46.0343, 51.5336] },
  { name: "Севастополь", size: 2, coordinates: [33.5254, 44.6167] },
  { name: "Симферополь", size: 2, coordinates: [34.1053, 44.9521] },
  { name: "Смоленск", size: 4, coordinates: [32.0544, 54.7826] },
  { name: "Сочи", size: 4, coordinates: [39.7231, 43.6028] },
  { name: "Ставрополь", size: 2, coordinates: [41.9734, 45.0448] },
  { name: "Сыктывкар", size: 4, coordinates: [50.8132, 61.6687] },
  { name: "Тамбов", size: 4, coordinates: [41.4344, 52.7213] },
  { name: "Тверь", size: 4, coordinates: [35.8843, 56.8587] },
  { name: "Томск", size: 4, coordinates: [84.9477, 56.4846] },
  { name: "Тула", size: 2, coordinates: [37.6174, 54.1921] },
  { name: "Тюмень", size: 4, coordinates: [65.5165, 57.1522] },
  { name: "Улан-Удэ", size: 4, coordinates: [107.606, 51.8335] },
  { name: "Ульяновск", size: 4, coordinates: [48.397, 54.3174] },
  { name: "Уфа", size: 4, coordinates: [56.0375, 54.7352] },
  { name: "Хабаровск", size: 2, coordinates: [135.072, 48.4827] },
  { name: "Ханты-Мансийск", size: 4, coordinates: [69.0189, 61.0042] },
  { name: "Чебоксары", size: 2, coordinates: [47.2422, 56.1322] },
  { name: "Челябинск", size: 4, coordinates: [61.4291, 55.1599] },
  { name: "Черкесск", size: 2, coordinates: [42.0469, 44.2269] },
  { name: "Чита", size: 4, coordinates: [113.4994, 52.0317] },
  { name: "Элиста", size: 2, coordinates: [44.2698, 46.3083] },
  {
    name: "Южно-Сахалинск",
    size: 4,
    coordinates: [142.7356, 46.9594],
  },
  { name: "Якутск", size: 4, coordinates: [129.7326, 62.0281] },
  { name: "Ярославль", size: 2, coordinates: [39.8737, 57.6261] },
  { name: "Саранск", size: 2, coordinates: [45.1749, 54.1838] },
];
