import { combineReducers } from "redux";
import { profileCompetenceReducer } from "./ducks/profile-competence/reducer";
import { profileInfoReducer } from "./ducks/profile-info/reducer";
import { roomReducer } from "./ducks/room/reducer";
import { simulationItemAdminReducer } from "./ducks/simulation-item-admin/reducer";
import { sidebarInformationReducer } from "./ducks/sidebar-information/reducer";
import { sessionsHistoryReducer } from "./ducks/sessions-history/reducer";
import { usersControlReducer } from "./ducks/users-control/reducer";
import { contactsReducer } from "./ducks/contacts/reducer";
import { cdnReducer } from "./ducks/cdn/reducer";

export const rootReducer = combineReducers({
  profileInfo: profileInfoReducer,
  profileCompetence: profileCompetenceReducer,
  room: roomReducer,
  usersControl: usersControlReducer,
  simulationItemAdmin: simulationItemAdminReducer,
  sidebarInformation: sidebarInformationReducer,
  sessionsHistory: sessionsHistoryReducer,
  contacts: contactsReducer,
  cdn: cdnReducer,
});
