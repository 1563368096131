import { NavLink, Location } from "react-router-dom";
import classNames from "classnames";
import "./nav-list.scss";
import { FC, ReactElement } from "react";

export interface NavListItem {
  title: string;
  to: string;
  access: boolean;
}

interface Props {
  level: 1 | 2 | 3 | 4;
  links: NavListItem[];
  location?: Location<any>;
  closeMobileMenuHandler?: () => void;
  isExactWithQueryParams?: boolean;
  isReplace?: boolean;
  isThirdLevelSeparated?: boolean;
  exact?: boolean;
}

export const NavList: FC<Props> = ({
  level,
  links,
  location,
  closeMobileMenuHandler,
  isExactWithQueryParams = false,
  isReplace,
  isThirdLevelSeparated,
  exact = false,
}): ReactElement => (
  <div
    className={classNames({
      "nav-list": true,
      "nav-list--in-mobile-dropdown": level === 1,
      "nav-list--in-header": level === 2,
      "nav-list--in-page": level === 3,
      "nav-list--in-header-new": level === 4,
    })}
  >
    {links.map((item: NavListItem, index: number) => (
      <NavLink
        replace={isReplace}
        key={index}
        className={({ isActive }) =>
          classNames("nav-list__item", {
            "nav-list__item--no-access": !item.access,
            "nav-list__item--separated": isThirdLevelSeparated,
            "nav-list__item--active": isExactWithQueryParams
              ? isActive &&
                location &&
                location?.pathname + location.search === item.to
              : isActive,
          })
        }
        to={item.to}
        onClick={closeMobileMenuHandler}
      >
        {item.title}
      </NavLink>
    ))}
  </div>
);
