import { ChangeEvent, FC, ReactElement, useEffect, useState } from "react";

interface Props {
  value?: string;
  placeholder?: string;
  error?: boolean;
  onChange: (password: string) => void;
}

export const InputPassword: FC<Props> = ({
  value = "",
  placeholder,
  error = false,
  onChange,
}): ReactElement => {
  const [localValue, setLocalValue] = useState(value);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (value !== "") {
      setLocalValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (localValue !== undefined) {
      onChange(localValue);
    }
  }, [localValue, onChange]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setLocalValue((e.target as HTMLInputElement).value);
  };

  const toggleVisible = (): void => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <label className="field">
      <input
        type={passwordVisible ? "text" : "password"}
        value={localValue}
        onChange={onChangeHandler}
        className={`field__input ${error ? "field__input--error" : ""}`}
      />
      <span
        className={`field__label ${
          localValue ? "field__label--collapsed" : ""
        }`}
      >
        {placeholder}
      </span>
      <span
        className={`field__eye field__eye--${
          passwordVisible ? "close" : "open"
        }`}
        onClick={toggleVisible}
      ></span>
    </label>
  );
};
