import { FC, ReactElement, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { BaseTooltip, DataGrid } from "src/shared/components/index";

import useClient from "src/shared/hooks/useClient";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { RatingDownload } from "../../../../generated/social";

interface Props {
  items: RatingDownload[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const RatingsExportHistory: FC<Props> = ({
  loading,
  items,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isPlayerClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const includedFieldsTooltip = useRef<HTMLSpanElement | null>(null);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "fullName",
        showed: !isPlayerClientId,
        titleUppercase: true,
        title: t("table.header.name"),
        type: EDataGridColumnType.AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.fineCreator"),
          activeGroupKey: getActiveGroup(
            ["firstName", "lastName"],
            { all: ["firstName", "lastName"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstName || "",
                },
                {
                  key: "lastName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastName || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "firstName",
                  "-firstName",
                  "lastName",
                  "-lastName",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "firstName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-firstName",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "lastName",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-lastName",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "dateDownload",
        showed: true,
        titleUppercase: true,
        title: t("common.date"),
        type: EDataGridColumnType.DATETIME,
        controlPanel: {
          title: t("common.date"),
          activeGroupKey: getActiveGroup(
            ["dateDownload", "dateDownloadFrom", "dateDownloadTo"],
            {
              all: ["dateDownload", "dateDownloadFrom", "dateDownloadTo"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "dateDownloadFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.dateDownloadFrom || "",
                  isHalf: true,
                },
                {
                  key: "dateDownloadTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.dateDownloadTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "download_date",
                  "-download_date",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "download_date",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-download_date",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "userCount",
        showed: true,
        titleUppercase: true,
        title: t("table.header.userCount"),
        type: EDataGridColumnType.STRING,
        controlPanel: {
          title: t("table.header.userCount"),
          activeGroupKey: getActiveGroup(
            ["userCount, useCountFrom, userCountTo"],
            { all: ["userCount, useCountFrom, userCountTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "userCountFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.userCountTo || "",
                  isHalf: true,
                },
                {
                  key: "userCountTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.userCountFrom || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "user_count",
                  "-user_count",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "user_count",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-user_count",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "includedFields",
        showed: true,
        titleUppercase: true,
        title: t("table.header.includedFields"),
        type: EDataGridColumnType.STRING,
      },
      {
        key: "statusDownloadRating",
        showed: true,
        titleUppercase: true,
        title: t("table.header.uploadStatus"),
        type: EDataGridColumnType.STRING,
        filterPosition: EDataGridFilterPosition.LEFT,
        controlPanel: {
          title: t("table.header.uploadStatus"),
          activeGroupKey: getActiveGroup(
            ["statusDownloadRating"],
            { all: ["statusDownloadRating"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "statusDownloadRating",
                  type: EDataGridFilterType.CHECKBOX,
                  onlyOneActiveOption: true,
                  value: queryParams?.statusDownloadRating || "",
                  items: [
                    {
                      value: "true",
                      label: t("table.status.true"),
                    },
                    {
                      value: "false",
                      label: t("table.status.false"),
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ];

    return newColumns;
  }, [isPlayerClientId, queryParams]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const fullName = splitStrings([item.firstname, item.lastname]);

      const includedFieldsFormatted = item.included_fields
        .map((field) => t(`table.header.${field}`))
        .join(", ");

      const includedFieldsElement = (
        <div className="tooltip-wrapper export-history-page--included-fields">
          <span>{includedFieldsFormatted}</span>
          <BaseTooltip tooltipToggleRef={includedFieldsTooltip}>
            {includedFieldsFormatted}
          </BaseTooltip>
        </div>
      );
      return {
        id: item.id,
        selected: false,
        fullName,
        avatarProps: {
          alt: fullName,
          fullName,
          customPath: getAvatarUrl(item.user_id),
          userId: item.user_id,
        },
        dateDownload: item.download_date,
        userCount: item.user_count,
        includedFields: includedFieldsElement,
        statusDownloadRating: t(`table.status.${item.success}`),
      };
    });

    setRows(rowsItems);
  }, [items, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
