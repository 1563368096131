import { useCallback, useEffect, useState } from "react";

export const useTimer = (
  delay: number = 0,
  time: number,
  finishedDelay: () => void,
  finished?: () => void,
) => {
  const [seconds, setSeconds] = useState<number>(time);
  const [isActive, setIsActive] = useState<boolean>(false);

  const startTimer = useCallback(() => {
    setSeconds(time);
    setIsActive(false);
    setTimeout(() => {
      setIsActive(true);
    }, delay * 1000);
  }, [delay, finishedDelay, time]);

  useEffect(() => {
    if (!isActive) return;

    if (seconds > 0) {
      finishedDelay();
      const timer = setTimeout(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      finished && finished();
    }
  }, [isActive, seconds, finished]);

  return {
    seconds,
    startTimer,
  };
};
