import { ChangeEvent, FC, ReactElement } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import "./list-checkbox.scss";

interface Props {
  item: string;
  checked: boolean;
  onChange: (name: string, checked: boolean) => void;
}

export const ListCheckbox: FC<Props> = ({
  item,
  checked,
  onChange,
}): ReactElement => {
  const { t } = useTranslation();

  const localId: string = `table-${item}`;

  const onChangeHandler = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    onChange(item, target.checked);
  };

  return (
    <label
      className={classNames("list-checkbox", {
        "list-checkbox--active": checked,
      })}
      htmlFor={localId}
    >
      <input
        id={localId}
        className="list-checkbox__input"
        type="checkbox"
        checked={checked}
        onChange={onChangeHandler}
      />
      <span className="list-checkbox__label">
        {t(`table.settings.checkboxLabel.${item}`)}
      </span>
    </label>
  );
};
