import React, { FC } from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { BaseInnerHtml, BasePopup, EPopupName } from "src/shared/components";

import { ResponseEmail } from "src/generated/notification";

import { emailNotificationModel } from "src/entities/admin/notification";

import { ContactsInfoBlock } from "src/pages/contacts-page/components";

import "./preview-popup.scss";

interface Props {
  newsletterFormData?: ResponseEmail;
}

export const PreviewPopup: FC<Props> = ({ newsletterFormData }) => {
  const { t } = useTranslation();

  const { $emailTheme, $emailText } = emailNotificationModel;

  const emailTheme = useUnit($emailTheme);

  const emailText = useUnit($emailText);

  const url = `${process.env.REACT_APP_KC_URL}/resources/3hudy/login/alabuga-web/img/banner-main`;

  return (
    <BasePopup
      name={EPopupName.EMAIL_PREVIEW}
      title={t("popup.email.preview.title")}
      isWithoutPadding
    >
      <div className="email-newsletter-preview">
        <div className="email-newsletter-preview__corner-gradient">
          <div className="email-newsletter-preview__container">
            <div className="email-newsletter-preview__logo"></div>
            <div className="email-newsletter-preview__header">
              {t("popup.email.preview.header")}
            </div>
            <p className="email-newsletter-preview__description">
              {t("popup.email.preview.description")}
            </p>
          </div>
          <div className="email-newsletter-preview__background">
            <picture>
              <source type="image/avif" src={`${url}.avif`} />
              <source type="image/webp" src={`${url}.webp`} />
              <img
                src={`${url}.jpg`}
                className="email-newsletter-preview__background-image"
                alt="Banner"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
        <div className="email-newsletter-preview__theme">
          {newsletterFormData && newsletterFormData.subject}
          {emailTheme}
        </div>
        {emailText && (
          <BaseInnerHtml
            className="email-newsletter-preview__email-content"
            description={emailText}
          />
        )}
        {newsletterFormData && (
          <BaseInnerHtml
            className="email-newsletter-preview__email-content"
            description={newsletterFormData.text}
          />
        )}
        <ContactsInfoBlock className="email-newsletter-preview__contacts" />
      </div>
    </BasePopup>
  );
};
