import { FC, ReactElement, ReactNode } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import "./tabs-panel-link.scss";
import classNames from "classnames";
import {
  TextWithIcon,
  TTextWithIconName,
} from "../text-with-icon/text-with-icon";

interface Props extends NavLinkProps {
  isUppercase?: boolean;
  icon?: TTextWithIconName;
  children: ReactNode;
}

export const TabsPanelLink: FC<Props> = ({
  isUppercase,
  children,
  icon,
  ...props
}): ReactElement => {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames("tabs-panel-link", {
          "tabs-panel-link--uppercase": isUppercase,
          "tabs-panel-link--active": isActive,
        })
      }
      {...props}
    >
      {icon && (
        <TextWithIcon iconName={icon} iconSize={22}>
          {children}
        </TextWithIcon>
      )}
      {!icon && children}
    </NavLink>
  );
};
