import { combine, split } from "effector";

import { $currentLanguage } from "src/shared/helpers";

import { isQueryParams } from "../lib/isQueryParams";

import {
  districtsRequestCheck,
  regionsRequestCheck,
  schoolsRequestCheck,
} from "../lib/requestCheck";

import { $country, $district, $region } from "./form-data";

import { resetDistricts, resetRegions, resetSchools } from "./reset";

import { fetchDistricts, fetchRegions, fetchSchools } from "./dictionaries";

import { debounce } from "patronum";

// Logic

split({
  source: debounce({
    source: combine($country, $currentLanguage, regionsRequestCheck),
    timeout: 200,
  }),
  match: isQueryParams,
  cases: {
    request: [resetRegions, fetchRegions],
    reset: resetRegions,
  },
});

split({
  source: debounce({
    source: combine($country, $region, $currentLanguage, districtsRequestCheck),
    timeout: 200,
  }),
  match: isQueryParams,
  cases: {
    request: [resetDistricts, fetchDistricts],
    reset: resetDistricts,
  },
});

split({
  source: debounce({
    source: combine(
      $country,
      $region,
      $district,
      $currentLanguage,
      schoolsRequestCheck,
    ),
    timeout: 200,
  }),
  match: isQueryParams,
  cases: {
    request: [resetSchools, fetchSchools],
    reset: resetSchools,
  },
});
