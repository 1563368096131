import classNames from "classnames";
import { FC, ReactElement, ReactNode } from "react";

import { BaseError } from "..";
import "./form-control.scss";

export type TFormControlMarginBottom =
  | "smallest"
  | "small"
  | "medium"
  | "large"
  | "default";

interface Props {
  title?: string;
  titleSize?: "medium" | "small";
  titleColor?: "black" | "gray" | "white";
  marginBottom?: TFormControlMarginBottom | null;
  description?: string;
  error?: string;
  info?: string;
  className?: string;
  messageSlot?: ReactElement;
  children?: ReactNode;
}

export const FormControl: FC<Props> = ({
  title,
  titleSize = "medium",
  titleColor = "black",
  description,
  info,
  error,
  children,
  className,
  messageSlot,
  marginBottom = "default",
}): ReactElement => {
  return (
    <div
      className={classNames(
        "form-control",
        className,
        marginBottom
          ? `form-control--margin-bottom_${marginBottom}`
          : undefined,
      )}
    >
      {title && (
        <h4
          className={`form-control__title form-control__title-size--${titleSize} form-control__title-color--${titleColor}`}
        >
          {title}
        </h4>
      )}
      <div className="form-control__field">
        <div className="form-control__field-input">{children}</div>
      </div>
      {description && <BaseError type="description" text={description} />}
      {info && <BaseError type="info" text={info} />}
      {error && <BaseError type="danger" text={error} />}
      {messageSlot}
    </div>
  );
};
