import { FC, ReactElement, ReactNode } from "react";
import "./empty-block.scss";

interface Props {
  title?: string;
  description?: string;
  children?: ReactNode;
}

export const EmptyBlock: FC<Props> = ({
  title,
  description,
  children,
}): ReactElement => {
  return (
    <div className="empty-block-wrap">
      <div className="empty-block">
        {title && <h2 className="empty-block__title">{title}</h2>}
        {description && (
          <div className="empty-block__description">{description}</div>
        )}
        {children}
      </div>
    </div>
  );
};
