import { format as formatDate } from "date-fns";

export const getDateForFilter = (
  dateStr?: string | null,
  isTo: boolean = false,
): undefined | string => {
  if (!dateStr) {
    return;
  }

  try {
    let date = new Date(dateStr);

    // if (isTo) {
    //   date = addDays(date, 1);
    // }

    return formatDate(date, "yyyy-MM-dd");
  } catch (e) {
    return undefined;
  }
};
