import {
  AvatarApiDeleteAvatarAvatarsIdDeleteRequest,
  AvatarApiUploadAvatarAvatarsIdPostRequest,
  ContactApiDeleteContactContactsIdDeleteRequest,
  ContactApiUploadContactContactsPostRequest,
  InfoItem,
  ResumeApiDeleteResumeResumesIdDeleteRequest,
  ResumeApiGetResumeResumesIdGetRequest,
  ResumeApiUploadResumeResumesIdPostRequest,
} from "../../../../generated/cdn";
import {
  ECdnActions,
  ICleanAddContactAvatarAction,
  ICleanAddResumeAction,
  ICleanAddUserAvatarAction,
  ICleanDeleteContactAvatarAction,
  ICleanDeleteResumeAction,
  ICleanDeleteUserAvatarAction,
  ICleanGetResumeAction,
  ICleanOptionsContactAvatarAction,
  ICleanOptionsResumeAction,
  ICleanOptionsUserAvatarAction,
  IFailureAddContactAvatarAction,
  IFailureAddResumeAction,
  IFailureAddUserAvatarAction,
  IFailureDeleteContactAvatarAction,
  IFailureDeleteResumeAction,
  IFailureDeleteUserAvatarAction,
  IFailureGetResumeAction,
  IFailureOptionsContactAvatarAction,
  IFailureOptionsResumeAction,
  IFailureOptionsUserAvatarAction,
  IRequestAddContactAvatarAction,
  IRequestAddResumeAction,
  IRequestAddUserAvatarAction,
  IRequestDeleteContactAvatarAction,
  IRequestDeleteResumeAction,
  IRequestDeleteUserAvatarAction,
  IRequestGetResumeAction,
  IRequestOptionsContactAvatarAction,
  IRequestOptionsResumeAction,
  IRequestOptionsUserAvatarAction,
  ISuccessAddContactAvatarAction,
  ISuccessAddResumeAction,
  ISuccessAddUserAvatarAction,
  ISuccessDeleteContactAvatarAction,
  ISuccessDeleteResumeAction,
  ISuccessDeleteUserAvatarAction,
  ISuccessGetResumeAction,
  ISuccessOptionsContactAvatarAction,
  ISuccessOptionsResumeAction,
  ISuccessOptionsUserAvatarAction,
} from "./actionTypes";

/*
 * Actions CONTACT AVATAR
 */
export const cleanAddContactAvatar = (): ICleanAddContactAvatarAction => ({
  type: ECdnActions.CLEAN_ADD_CONTACT_AVATAR,
});

export const requestAddContactAvatar = (payload: {
  callback?: (fileName: string) => void;
  options: ContactApiUploadContactContactsPostRequest;
}): IRequestAddContactAvatarAction => ({
  type: ECdnActions.REQUEST_ADD_CONTACT_AVATAR,
  payload,
});

export const successAddContactAvatar = (): ISuccessAddContactAvatarAction => ({
  type: ECdnActions.SUCCESS_ADD_CONTACT_AVATAR,
});

export const failureAddContactAvatar = (): IFailureAddContactAvatarAction => ({
  type: ECdnActions.FAILURE_ADD_CONTACT_AVATAR,
});

export const cleanDeleteContactAvatar =
  (): ICleanDeleteContactAvatarAction => ({
    type: ECdnActions.CLEAN_DELETE_CONTACT_AVATAR,
  });

export const requestDeleteContactAvatar = (payload: {
  callback?: () => void;
  options: ContactApiDeleteContactContactsIdDeleteRequest;
}): IRequestDeleteContactAvatarAction => ({
  type: ECdnActions.REQUEST_DELETE_CONTACT_AVATAR,
  payload,
});

export const successDeleteContactAvatar =
  (): ISuccessDeleteContactAvatarAction => ({
    type: ECdnActions.SUCCESS_DELETE_CONTACT_AVATAR,
  });

export const failureDeleteContactAvatar =
  (): IFailureDeleteContactAvatarAction => ({
    type: ECdnActions.FAILURE_DELETE_CONTACT_AVATAR,
  });

export const cleanOptionsContactAvatar =
  (): ICleanOptionsContactAvatarAction => ({
    type: ECdnActions.CLEAN_OPTIONS_CONTACT_AVATAR,
  });

export const requestOptionsContactAvatar = (payload?: {
  callback?: () => void;
}): IRequestOptionsContactAvatarAction => ({
  type: ECdnActions.REQUEST_OPTIONS_CONTACT_AVATAR,
  payload,
});

export const successOptionsContactAvatar = (payload: {
  options: InfoItem;
}): ISuccessOptionsContactAvatarAction => ({
  type: ECdnActions.SUCCESS_OPTIONS_CONTACT_AVATAR,
  payload,
});

export const failureOptionsContactAvatar =
  (): IFailureOptionsContactAvatarAction => ({
    type: ECdnActions.FAILURE_OPTIONS_CONTACT_AVATAR,
  });

/*
 * Actions RESUME
 */
export const cleanAddResume = (): ICleanAddResumeAction => ({
  type: ECdnActions.CLEAN_ADD_RESUME,
});

export const requestAddResume = (payload: {
  callback?: () => void;
  options: ResumeApiUploadResumeResumesIdPostRequest;
}): IRequestAddResumeAction => ({
  type: ECdnActions.REQUEST_ADD_RESUME,
  payload,
});

export const successAddResume = (): ISuccessAddResumeAction => ({
  type: ECdnActions.SUCCESS_ADD_RESUME,
});

export const failureAddResume = (): IFailureAddResumeAction => ({
  type: ECdnActions.FAILURE_ADD_RESUME,
});

export const cleanGetResume = (): ICleanGetResumeAction => ({
  type: ECdnActions.CLEAN_GET_RESUME,
});

export const requestGetResume = (payload: {
  callback?: () => void;
  options: ResumeApiGetResumeResumesIdGetRequest;
  fileName: string;
}): IRequestGetResumeAction => ({
  type: ECdnActions.REQUEST_GET_RESUME,
  payload,
});

export const successGetResume = (): ISuccessGetResumeAction => ({
  type: ECdnActions.SUCCESS_GET_RESUME,
});

export const failureGetResume = (): IFailureGetResumeAction => ({
  type: ECdnActions.FAILURE_GET_RESUME,
});

export const cleanDeleteResume = (): ICleanDeleteResumeAction => ({
  type: ECdnActions.CLEAN_DELETE_RESUME,
});

export const requestDeleteResume = (payload: {
  callback?: () => void;
  options: ResumeApiDeleteResumeResumesIdDeleteRequest;
}): IRequestDeleteResumeAction => ({
  type: ECdnActions.REQUEST_DELETE_RESUME,
  payload,
});

export const successDeleteResume = (): ISuccessDeleteResumeAction => ({
  type: ECdnActions.SUCCESS_DELETE_RESUME,
});

export const failureDeleteResume = (): IFailureDeleteResumeAction => ({
  type: ECdnActions.FAILURE_DELETE_RESUME,
});

export const cleanOptionsResume = (): ICleanOptionsResumeAction => ({
  type: ECdnActions.CLEAN_OPTIONS_RESUME,
});

export const requestOptionsResume = (payload?: {
  callback?: () => void;
}): IRequestOptionsResumeAction => ({
  type: ECdnActions.REQUEST_OPTIONS_RESUME,
  payload,
});

export const successOptionsResume = (payload: {
  options: InfoItem;
}): ISuccessOptionsResumeAction => ({
  type: ECdnActions.SUCCESS_OPTIONS_RESUME,
  payload,
});

export const failureOptionsResume = (): IFailureOptionsResumeAction => ({
  type: ECdnActions.FAILURE_OPTIONS_RESUME,
});

/*
 * Actions USER AVATAR
 */
export const cleanAddUserAvatar = (): ICleanAddUserAvatarAction => ({
  type: ECdnActions.CLEAN_ADD_USER_AVATAR,
});

export const requestAddUserAvatar = (payload: {
  callback?: () => void;
  options: AvatarApiUploadAvatarAvatarsIdPostRequest;
}): IRequestAddUserAvatarAction => ({
  type: ECdnActions.REQUEST_ADD_USER_AVATAR,
  payload,
});

export const successAddUserAvatar = (): ISuccessAddUserAvatarAction => ({
  type: ECdnActions.SUCCESS_ADD_USER_AVATAR,
});

export const failureAddUserAvatar = (): IFailureAddUserAvatarAction => ({
  type: ECdnActions.FAILURE_ADD_USER_AVATAR,
});

export const cleanDeleteUserAvatar = (): ICleanDeleteUserAvatarAction => ({
  type: ECdnActions.CLEAN_DELETE_USER_AVATAR,
});

export const requestDeleteUserAvatar = (payload: {
  callback?: () => void;
  options: AvatarApiDeleteAvatarAvatarsIdDeleteRequest;
}): IRequestDeleteUserAvatarAction => ({
  type: ECdnActions.REQUEST_DELETE_USER_AVATAR,
  payload,
});

export const successDeleteUserAvatar = (): ISuccessDeleteUserAvatarAction => ({
  type: ECdnActions.SUCCESS_DELETE_USER_AVATAR,
});

export const failureDeleteUserAvatar = (): IFailureDeleteUserAvatarAction => ({
  type: ECdnActions.FAILURE_DELETE_USER_AVATAR,
});

export const cleanOptionsUserAvatar = (): ICleanOptionsUserAvatarAction => ({
  type: ECdnActions.CLEAN_OPTIONS_USER_AVATAR,
});

export const requestOptionsUserAvatar = (payload?: {
  callback?: () => void;
}): IRequestOptionsUserAvatarAction => ({
  type: ECdnActions.REQUEST_OPTIONS_USER_AVATAR,
  payload,
});

export const successOptionsUserAvatar = (payload: {
  options: InfoItem;
}): ISuccessOptionsUserAvatarAction => ({
  type: ECdnActions.SUCCESS_OPTIONS_USER_AVATAR,
  payload,
});

export const failureOptionsUserAvatar =
  (): IFailureOptionsUserAvatarAction => ({
    type: ECdnActions.FAILURE_OPTIONS_USER_AVATAR,
  });

export type TCdnActions =
  | ICleanAddContactAvatarAction
  | IRequestAddContactAvatarAction
  | ISuccessAddContactAvatarAction
  | IFailureAddContactAvatarAction
  | ICleanDeleteContactAvatarAction
  | IRequestDeleteContactAvatarAction
  | ISuccessDeleteContactAvatarAction
  | IFailureDeleteContactAvatarAction
  | ICleanOptionsContactAvatarAction
  | IRequestOptionsContactAvatarAction
  | ISuccessOptionsContactAvatarAction
  | IFailureOptionsContactAvatarAction
  | ICleanAddResumeAction
  | IRequestAddResumeAction
  | ISuccessAddResumeAction
  | IFailureAddResumeAction
  | ICleanGetResumeAction
  | IRequestGetResumeAction
  | ISuccessGetResumeAction
  | IFailureGetResumeAction
  | ICleanDeleteResumeAction
  | IRequestDeleteResumeAction
  | ISuccessDeleteResumeAction
  | IFailureDeleteResumeAction
  | ICleanOptionsResumeAction
  | IRequestOptionsResumeAction
  | ISuccessOptionsResumeAction
  | IFailureOptionsResumeAction
  | ICleanAddUserAvatarAction
  | IRequestAddUserAvatarAction
  | ISuccessAddUserAvatarAction
  | IFailureAddUserAvatarAction
  | ICleanDeleteUserAvatarAction
  | IRequestDeleteUserAvatarAction
  | ISuccessDeleteUserAvatarAction
  | IFailureDeleteUserAvatarAction
  | ICleanOptionsUserAvatarAction
  | IRequestOptionsUserAvatarAction
  | ISuccessOptionsUserAvatarAction
  | IFailureOptionsUserAvatarAction;
