import { sample } from "effector";

import { queryParamsToGetListParamsAdapter } from "../lib";

import { $queryParams, countriesDomain, fetchCountriesFx } from "./countries";

// Events

export const refreshCountries = countriesDomain.createEvent();

// Logic

sample({
  clock: refreshCountries,
  source: $queryParams,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchCountriesFx,
});
