import { combine, sample } from "effector";

import { IBaseCardListItem } from "src/shared/components";

import { $currentLanguage } from "src/shared/helpers";

import { $simulations } from "src/entities/admin/simulations/model";

import { getBaseCardListItemsAdapter } from "../lib/getBaseCardListItemsAdapter";

import { simulationsAdminFeaturesDomain } from "./domain";

// Stores

export const $cardListItems = simulationsAdminFeaturesDomain.createStore<
  IBaseCardListItem[]
>([]);

// Logic

sample({
  clock: combine($simulations, $currentLanguage, getBaseCardListItemsAdapter),
  target: $cardListItems,
});
