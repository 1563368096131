import { call, put, takeLatest } from "redux-saga/effects";
import {
  failureSidebarInformationGet,
  failureSidebarInformationPost,
  successSidebarInformationGet,
  successSidebarInformationPost,
} from "./actionCreators";
import {
  ESidebarInformationActions,
  IRequestSidebarInformationGet,
  IRequestSidebarInformationPost,
} from "./actionTypes";
import { $axiosSocial } from "../../../api/api";
import { AxiosResponse } from "axios";
import { Configuration } from "../../../../generated/statistic";
import { SidebarApiFactory } from "../../../../generated/social";

export function* fetchSidebarInformationGet({
  payload,
}: IRequestSidebarInformationGet) {
  const request = SidebarApiFactory(new Configuration(), "", $axiosSocial);

  try {
    const response: AxiosResponse = yield call(request.getSidebar);

    yield put(successSidebarInformationGet(response.data.content));

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    yield put(failureSidebarInformationGet());
  }
}

export function* fetchSidebarInformationPost({
  payload,
}: IRequestSidebarInformationPost) {
  const request = SidebarApiFactory(new Configuration(), "", $axiosSocial);

  try {
    yield call(request.postSidebar, payload.item);

    yield put(successSidebarInformationPost());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    yield put(failureSidebarInformationPost());
  }
}

export function* sidebarInformationSaga() {
  yield takeLatest(
    ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_GET,
    fetchSidebarInformationGet,
  );
  yield takeLatest(
    ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_POST,
    fetchSidebarInformationPost,
  );
}
