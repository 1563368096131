import classNames from "classnames";
import { FC, ReactElement } from "react";
import { TSelectSize } from "../base-select/base-select";

interface Props {
  isDisabled?: boolean;
  size?: TSelectSize;
  isSessionTable?: boolean;
  forDarkBg?: boolean;
}

export const SelectArrow: FC<Props> = ({
  isDisabled,
  size,
  isSessionTable,
  forDarkBg,
}): ReactElement => (
  <div
    className={classNames(
      "base-select__triangle",
      `base-select__triangle--${size}`,
      {
        "base-select__triangle--disabled": isDisabled,
        "base-select__triangle--session-table": isSessionTable,
        "base-select__triangle--dark-bg": forDarkBg,
      },
    )}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82804 9.3516C7.61787 9.57832 7.5 9.88432 7.5 10.2032C7.5 10.522 7.61787 10.828 7.82804 11.0548L11.1345 14.6135C11.3798 14.8716 11.697 15 12.0109 15C12.3248 15 12.6364 14.8716 12.8761 14.6135L16.1724 11.0668C16.3823 10.8399 16.5 10.534 16.5 10.2152C16.5 9.89642 16.3823 9.59048 16.1724 9.36361C16.0691 9.25176 15.946 9.16295 15.8102 9.10232C15.6744 9.0417 15.5287 9.01048 15.3815 9.01048C15.2343 9.01048 15.0886 9.0417 14.9528 9.10232C14.8171 9.16295 14.6939 9.25176 14.5906 9.36361L12.0053 12.1446L9.40984 9.3516C9.30629 9.24019 9.18309 9.15177 9.04735 9.09142C8.9116 9.03107 8.766 9 8.61894 9C8.47188 9 8.32627 9.03107 8.19053 9.09142C8.05478 9.15177 7.93158 9.24019 7.82804 9.3516Z"
        fill="currentcolor"
      />
    </svg>
  </div>
);
