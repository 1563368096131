import { FC, memo, ReactElement } from "react";

import { Property } from "csstype";

interface Props {
  width?: number;
  height?: number;
  color?: Property.Color;
  className?: string;
}

const icon: FC<Props> = ({
  width = 10,
  height = 11,
  color = "currentColor",
  className,
}): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 11"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M7.15741 6.76923H2.84259C1.27518 6.76923 0 7.75014 0 8.95584V10.5627C0 10.8042 0.254529 11 0.568518 11H9.43148C9.74547 11 10 10.8042 10 10.5627V8.95584C10 7.75014 8.72482 6.76923 7.15741 6.76923Z" />
    <path d="M5 0C3.39174 0 2.08333 1.32853 2.08333 2.96155C2.08333 4.06922 2.68543 5.03661 3.57461 5.54452C3.99636 5.78541 4.4826 5.92308 5 5.92308C5.5174 5.92308 6.00364 5.78541 6.42539 5.54452C7.3146 5.03661 7.91667 4.06918 7.91667 2.96155C7.91667 1.32856 6.60826 0 5 0Z" />
  </svg>
);

export const IconUser = memo<Props>(icon);
