import { Skeleton } from "../../../../../shared/components/skeleton/Skeleton";
import cls from "./my-competence-page-skeleton.module.scss";

export const MyCompetencePageSkeleton = () => {
  return (
    <div className={cls.wrapper}>
      <Skeleton width={1190} height={80} border="20px" />
      <Skeleton width={360} height={38} border="20px" />
      <Skeleton width={1190} height={243} border="20px" />
      <Skeleton width={468} height={36} border="20px" />
      <Skeleton width={172} height={21} border="20px" />
      <div className={cls.row}>
        <Skeleton width={380} height={215} border="20px" />
        <Skeleton width={380} height={215} border="20px" />
        <Skeleton width={380} height={215} border="20px" />
      </div>
      <Skeleton width={1190} height={121} border="20px" />
      <Skeleton width={1190} height={61} border="20px" />
    </div>
  );
};
