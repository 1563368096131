import { FC, ReactElement, useMemo } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { Competence } from "src/generated/statistic";

import { IGameType } from "src/shared/store/types";

import { IDislikeItem } from "../../model/types";

import { DislikeItem } from "../dislike-item/dislike-item";

import { DislikeList } from "../dislike-list/dislike-list";

import "./dislike-info.scss";

interface Props {
  dislikes: IDislikeItem[];
  assessmentResult?: string | number;
  gameType: IGameType;
  competence: Competence;
  dislikesSum?: string;
}

export const DislikeInfo: FC<Props> = ({
  assessmentResult,
  dislikes,
  gameType,
  dislikesSum,
  competence,
}): ReactElement => {
  const { t } = useTranslation();

  const isWithNames: boolean = useMemo(
    () => dislikes.some((dislike) => dislike.firstname || dislike.lastname),
    [dislikes],
  );

  return (
    <div
      className={classNames("dislike-info", {
        "dislike-info--large": isWithNames,
      })}
    >
      {!!assessmentResult && (
        <DislikeItem
          className="dislike-info__result"
          title={t(
            "assessmentsControl.popup.assessmentResults.tooltip.results",
          )}
          description={assessmentResult}
        />
      )}
      <DislikeList
        dislikes={dislikes}
        competence={competence}
        gameType={gameType}
        dislikesSum={dislikesSum}
        isWithNames={isWithNames}
      />
    </div>
  );
};
