import classNames from "classnames";
import formatDate from "date-fns/format";
import getDaysInMonth from "date-fns/getDaysInMonth";
import upperFirst from "lodash/upperFirst";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateLocale } from "../../../helpers/dates";
import { getArray } from "../../../helpers/getArray";
import { IBaseInput, IBaseInputChange } from "../../../models/base-input";
import { ELanguage } from "../../../store/types";
import { BaseSelect, SelectItem } from "../base-select/base-select";
import "./base-date-picker.scss";

interface Props extends IBaseInput {}

interface IDateData {
  day?: number;
  month?: number;
  year?: number;
}

const dateNow = new Date();
const yearNow: number = dateNow.getFullYear();

const getDaysSelectItems = (days: number): SelectItem[] =>
  getArray(days).map(
    (_, index): SelectItem => ({
      value: String(index + 1),
      label: String(index + 1),
    }),
  );

const years: SelectItem[] = getArray(120).map(
  (_, index): SelectItem => ({
    value: String(yearNow - index),
    label: String(yearNow - index),
  }),
);

export const BaseDatePicker: FC<Props> = ({
  value,
  onChange,
  name,
  error,
  placeholder,
  required,
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const [days, setDays] = useState<SelectItem[]>(getDaysSelectItems(31));
  const [monthes, setMonthes] = useState<SelectItem[]>([]);
  const [daysInMonth, setDaysInMonth] = useState<number>(31);
  const [date, setDate] = useState<IDateData>({
    day: !!value ? new Date(value).getDate() : undefined,
    month: !!value ? new Date(value).getMonth() : undefined,
    year: !!value ? new Date(value).getFullYear() : undefined,
  });

  const setMonthesHandler = useCallback(async (): Promise<void> => {
    const currentLocale = (): string => {
      const currentLang: string = i18n.language;
      if (currentLang === ELanguage.EN) {
        return "en-US";
      }

      return ELanguage.RU;
    };
    const locale = await getDateLocale(currentLocale());
    const localMonthes: SelectItem[] = getArray(12).map(
      (_, index): SelectItem => {
        const localMonth: string = formatDate(
          new Date(yearNow, index),
          "MMMM",
          {
            locale: locale.default,
          },
        );

        const label: string = upperFirst(localMonth);

        return {
          value: String(index),
          label: label,
        };
      },
    );

    setMonthes(localMonthes);
  }, [i18n.language]);

  useEffect(() => {
    setMonthesHandler();
  }, [i18n.language, setMonthesHandler]);

  useEffect(() => {
    const localDaysInMonth: number = getDaysInMonth(
      new Date(
        date.year !== undefined ? date.year : dateNow.getFullYear(),
        date.month !== undefined ? date.month : 0,
      ),
    );

    setDaysInMonth(localDaysInMonth);
  }, [date.month, date.year]);

  useEffect(() => {
    setDays(getDaysSelectItems(daysInMonth));

    if (Number(date.day) > daysInMonth) {
      setDate((prev) => ({ ...prev, day: undefined }));
    }
  }, [daysInMonth, date.day]);

  const onSaveHandler = (localDate: IDateData) => {
    let formattedDate: string = "";

    if (
      localDate.day !== undefined &&
      localDate.month !== undefined &&
      localDate.year !== undefined
    ) {
      formattedDate = formatDate(
        new Date(localDate.year, localDate.month, localDate.day),
        "yyyy-MM-dd",
      );
    }

    onChange({ name, value: formattedDate });
  };

  const onChangeHandler = (payload: IBaseInputChange): void => {
    setDate((prev) => {
      const localPrev = {
        ...prev,
        [payload.name as keyof IDateData]: Number(payload.value),
      };

      onSaveHandler(localPrev);

      return localPrev;
    });
  };

  return (
    <div className="base-date-picker">
      {!!placeholder && (
        <h3
          className={classNames("base-date-picker__title", {
            required: required,
          })}
        >
          {placeholder}
        </h3>
      )}
      <div className="base-date-picker__items">
        <div className="base-date-picker__item">
          <BaseSelect
            hideEmptyItem
            placeholder={t("common.day")}
            items={days}
            activeItem={date.day !== undefined ? String(date.day) : ""}
            onChange={onChangeHandler}
            name="day"
            error={error && date.day === undefined}
            required={required}
          />
        </div>
        <div className="base-date-picker__item">
          <BaseSelect
            hideEmptyItem
            placeholder={t("common.month")}
            items={monthes}
            activeItem={date.month !== undefined ? String(date.month) : ""}
            onChange={onChangeHandler}
            name="month"
            error={error && date.month === undefined}
            required={required}
          />
        </div>
        <div className="base-date-picker__item">
          <BaseSelect
            hideEmptyItem
            placeholder={t("common.year")}
            items={years}
            activeItem={date.year !== undefined ? String(date.year) : ""}
            onChange={onChangeHandler}
            name="year"
            error={error && date.year === undefined}
            required={required}
          />
        </div>
      </div>
    </div>
  );
};
