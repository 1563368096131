import { FC, ReactElement, useEffect } from "react";

import { reflect } from "@effector/reflect";

import { useUrlState } from "src/shared/hooks/useUrlState";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";

import { PageWrapper } from "src/shared/components";

import { useUpdatedQueryParams } from "src/shared/hooks/useUpdatedQueryParams";

import {
  CountriesDataGrid,
  countriesModel,
} from "src/entities/admin/countries";

import { $isLoading, $pageTitle } from "../../model";

import "./EditCountriesPage.scss";

interface Props {
  isLoading: boolean;
  pageTitle: string;
}

const View: FC<Props> = ({ isLoading, pageTitle }): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { updatedQueryParams } = useUpdatedQueryParams();

  useEffect(() => {
    if (updatedQueryParams) {
      if (updatedQueryParams.mode === "readmore") {
        countriesModel.readmoreCountries(updatedQueryParams);
      } else {
        countriesModel.fetchCountries(updatedQueryParams);
      }
    }
  }, [updatedQueryParams]);

  return (
    <PageWrapper
      title={pageTitle}
      isLoadingPanel={isLoading}
      isShowContentWhenIsLoading
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
    >
      <div className="edit-countries-page">
        <CountriesDataGrid
          queryParams={urlState.query}
          updateQueryParams={(params) =>
            setUrlState({
              ...urlState,
              query: params,
            })
          }
        />
      </div>
    </PageWrapper>
  );
};

export const EditCountriesPage = reflect<Props>({
  view: View,
  bind: {
    isLoading: $isLoading,
    pageTitle: $pageTitle,
  },
  hooks: {
    unmounted: countriesModel.resetCountries,
  },
});
