import { FC, memo, ReactElement } from "react";

interface Props {
  className?: string;
}

export const Bell: FC<Props> = memo(({ className }): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6225 3.50713C12.4312 3.50713 13.2077 3.65797 13.9283 3.93412V3.84029C13.9283 2.54985 12.9352 1.5 11.7144 1.5H11.531C10.3102 1.5 9.31706 2.54985 9.31706 3.84029V3.93293C10.0373 3.6576 10.8136 3.50713 11.6225 3.50713Z M8.88217 20.6048C9.34869 21.7197 10.4045 22.5 11.63 22.5C12.8555 22.5 13.9112 21.7197 14.3778 20.6048H8.88217Z M4.34858 19.3856H18.9029C19.1783 19.3856 19.4304 19.1893 19.4877 18.9046C19.5444 18.6227 19.4229 18.3445 19.1763 18.224C19.1177 18.184 18.6773 17.8584 18.2364 16.8734C17.4266 15.0646 17.2566 12.5167 17.2566 10.6977C17.2566 10.6898 17.2564 10.6819 17.2562 10.6741C17.2442 7.40283 14.7229 4.74567 11.6255 4.74567C8.5208 4.74567 5.99488 7.4157 5.99488 10.6977C5.99488 12.5167 5.8249 15.0646 5.01506 16.8734C4.57412 17.8584 4.13369 18.1839 4.07517 18.224C3.8286 18.3445 3.70706 18.6227 3.76374 18.9046C3.82101 19.1893 4.07323 19.3856 4.34858 19.3856Z"
        fill="currentColor"
      />
    </svg>
  );
});
