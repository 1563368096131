import { Competence, Player } from "src/generated/social";

interface Payload {
  socialPlayerInfo: Player | null;
  isPlayerClientId: boolean;
}

export const getIsVerifiedSession = ({ socialPlayerInfo }: Payload) => {
  if (socialPlayerInfo) {
    socialPlayerInfo.competenceRates?.find(
      (competence) => competence.competence === Competence.Communication,
    );

    // TODO: HRP-228
    // Временно убрали верификационную сессию
    // return !!isVerificationLvl;
    return false;
  }

  return false;
};
