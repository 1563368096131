import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { BaseButton, DataGrid } from "src/shared/components/index";

import {
  getActiveGroup,
  getAvatarUrl,
  getOrderByValue,
  splitStrings,
} from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  IDataGridSelectOption,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import {
  Complaint,
  ComplaintStatus,
  ComplaintType,
} from "../../../../generated/social";
import { useUnit } from "effector-react";
import {
  $isComplaintsLoading,
  $updatedPlayer,
  patchPlayerComplaintById,
} from "../../../../entities/admin/complaints/model/complaints";
import { snackbarOpen } from "../../../../features/public/snackbar";

interface Props {
  items: Complaint[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

export const ComplaintsDataGrid: FC<Props> = ({
  loading,
  items,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const [complaintIsClicked, setComplaintIsClicked] = useState({
    action: ComplaintStatus.Pending,
    clicked: false,
    id: "",
  });

  const isLoading = useUnit($isComplaintsLoading);

  const updatedPlayer = useUnit($updatedPlayer);

  let resolvedUsers: string[] = useMemo(() => [], []);

  const onConfirm = (id: string) => {
    patchPlayerComplaintById({
      cId: id,
      complaint: { status: ComplaintStatus.Accepted },
    });
    setComplaintIsClicked({
      action: ComplaintStatus.Accepted,
      clicked: true,
      id,
    });
    snackbarOpen({
      text: t("snackbar.complaints.success"),
      visible: true,
      type: "success",
      verticalCentered: true,
    });
  };

  const onReject = (id: string) => {
    patchPlayerComplaintById({
      cId: id,
      complaint: { status: ComplaintStatus.Rejected },
    });
    setComplaintIsClicked({
      action: ComplaintStatus.Rejected,
      clicked: true,
      id,
    });
    snackbarOpen({
      text: t("snackbar.complaints.fail"),
      visible: true,
      type: "error",
      verticalCentered: true,
    });
  };

  const complaintsTypes = useMemo<ComplaintType[]>(
    () => Object.values(ComplaintType),
    [],
  );

  const complainTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return complaintsTypes.map((type) => {
      return {
        value: type,
        label: i18n.t(`common.complaints.${type}`, type),
      };
    });
  }, [complaintsTypes, i18n]);

  const cancelAction = (id: string) => {
    patchPlayerComplaintById({
      cId: id,
      complaint: { status: ComplaintStatus.Pending },
    });
    setComplaintIsClicked({
      action: ComplaintStatus.Pending,
      clicked: false,
      id,
    });
  };

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        key: "appelantName",
        showed: true,
        titleUppercase: true,
        title: t("table.header.sender"),
        type: EDataGridColumnType.MULTI_AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.sender"),
          activeGroupKey: getActiveGroup(
            ["appelantFirstname", "appelantLastname"],
            { all: ["appelantFirstname", "appelantLastname"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "appelantFirstname",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.appelantFirstname || "",
                },
                {
                  key: "appelantLastname",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.appelantLastname || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "appelant_firstname",
                  "-appelant_firstname",
                  "appelant_lastname",
                  "-appelant_lastname",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "appelant_firstname",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-appelant_firstname",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "appelant_lastname",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-appelant_lastname",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "dateComplaint",
        showed: true,
        titleUppercase: true,
        title: t("common.date"),
        type: EDataGridColumnType.DATETIME,
        controlPanel: {
          title: t("common.date"),
          activeGroupKey: getActiveGroup(
            ["dateComplaint", "fromDateOfCreate", "fromDateToCreate"],
            {
              all: ["dateComplaint", "fromDateOfCreate", "fromDateToCreate"],
            },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "fromDateOfCreate",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.fromDateOfCreate || "",
                  isHalf: true,
                },
                {
                  key: "fromDateToCreate",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.toDateOfCreate || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "date_of_create",
                  "-date_of_create",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "date_of_create",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-date_of_create",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        key: "defendantName",
        showed: true,
        titleUppercase: true,
        title: t("table.header.recipient"),
        type: EDataGridColumnType.MULTI_AVATAR,
        nowrap: true,
        noPaddingLeft: true,
        controlPanel: {
          title: t("table.header.recipient"),
          activeGroupKey: getActiveGroup(
            ["defendantFirstname", "defendantLastname"],
            { all: ["defendantFirstname", "defendantLastname"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "defendantFirstname",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.defendantFirstname || "",
                },
                {
                  key: "defendantLastname",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.defendantLastname || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "defendant_firstname",
                  "-defendant_firstname",
                  "defendant_lastname",
                  "-defendant_lastname",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc.name"),
                    value: "defendant_firstname",
                  },
                  {
                    title: t("table.filters.alphabet.desc.name"),
                    value: "-defendant_firstname",
                  },
                  {
                    title: t("table.filters.alphabet.asc.surname"),
                    value: "defendant_lastname",
                  },
                  {
                    title: t("table.filters.alphabet.desc.surname"),
                    value: "-defendant_lastname",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.type"),
        filterTitle: t("table.header.type"),
        type: EDataGridColumnType.STRING,
        key: "complaintsType",
        showed: true,
        controlPanel: {
          title: t("table.header.type"),
          activeGroupKey: getActiveGroup(
            ["complaintsType"],
            { all: ["complaintsType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "complaintsType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: t("table.filters.notSelected"),
                  value: queryParams?.types || "",
                  items: complainTypeOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.complaint.message"),
        type: EDataGridColumnType.STRING,
        key: "msgText",
        showed: true,
      },
      {
        key: "coordination",
        showed: true,
        titleUppercase: true,
        title: "",
        type: EDataGridColumnType.STRING,
      },
    ];

    return newColumns;
  }, [complainTypeOptions, queryParams, t]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = items.map((item): IDataGridRow => {
      const appelantName = splitStrings([
        item.appelant_firstname,
        item.appelant_lastname,
      ]);
      const defendantName = splitStrings([
        item.defendant_firstname,
        item.defendant_lastname,
      ]);

      let confirmationStatus: ReactElement | null = null;

      if (
        (complaintIsClicked.clicked &&
          complaintIsClicked.id.includes(item.id)) ||
        resolvedUsers.includes(item.id)
      ) {
        confirmationStatus =
          complaintIsClicked.action === ComplaintStatus.Accepted ? (
            <BaseButton
              transparent
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => {
                cancelAction(item.id);
              }}
              className="moderation-page__coordination__confirmed"
            >
              {t("common.confirmed")}
            </BaseButton>
          ) : (
            <BaseButton
              transparent
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => cancelAction(item.id)}
              className="moderation-page__coordination__rejected"
            >
              {t("common.rejected")}
            </BaseButton>
          );

        resolvedUsers.push(item.id);
      }

      const coordination = (
        <div className="moderation-page__coordination-buttons">
          <BaseButton
            onClick={() => onConfirm(item.id)}
            disabled={isLoading}
            isLoading={isLoading}
            xSmall
            xSmallRounded
            primary
          >
            {t("common.confirm.complaint")}
          </BaseButton>
          <BaseButton
            onClick={() => onReject(item.id)}
            disabled={isLoading}
            isLoading={isLoading}
            xSmall
            xSmallRounded
            secondary
          >
            {t("common.reject")}
          </BaseButton>
        </div>
      );

      if (!complaintIsClicked.clicked) {
        confirmationStatus = coordination;
      }

      return {
        id: item.id,
        selected: false,
        appelantName,
        avatarProps: {
          alt: appelantName,
          fullName: appelantName,
          customPath: getAvatarUrl(item.id),
          userId: item.id,
        },
        avatarPropsExtra: {
          alt: defendantName,
          fullName: defendantName,
          customPath: getAvatarUrl(item.defendant_id),
          userId: item.defendant_id,
        },
        defendantName,
        dateComplaint: item.date_of_create,
        msgText: item.msg_text,
        complaintsType: i18n.t(`common.complaints.${item.type}`),
        coordination: confirmationStatus ? confirmationStatus : coordination,
      };
    });

    setRows(rowsItems);
  }, [items, t, isLoading, complaintIsClicked]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
