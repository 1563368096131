import {
  HrRoom,
  Join,
  PRoomChanged,
  PRoomR,
  Quit,
  Role,
  Team,
} from "src/generated/ws4";
import { AssessmentStatus } from "./../../../../generated/game";
import {
  AssessmentStatusInterface,
  RoomActionsType,
  RoomAssessmentErrorInterface,
  RoomChangeRoleActionInterface,
  RoomChangeTeamRoleActionInterface,
  RoomClearActionInterface,
  RoomConnectedPlayersCountChangeActionInterface,
  RoomConnectPlayerActionInterface,
  RoomLeavePlayerActionInterface,
  RoomSetStateActionInterface,
} from "./actionTypes";

export const roomSetState = (
  payload: PRoomR | HrRoom,
): RoomSetStateActionInterface => {
  return {
    type: RoomActionsType.ROOM_SET_STATE,
    payload: payload,
  };
};

export const roomConnectPlayer = (
  payload: Join,
): RoomConnectPlayerActionInterface => {
  return {
    type: RoomActionsType.ROOM_CONNECT_PLAYER,
    payload: payload,
  };
};

export const roomConnectedPlayersCountChange = (
  payload: PRoomChanged,
): RoomConnectedPlayersCountChangeActionInterface => {
  return {
    type: RoomActionsType.ROOM_CONNECTED_PLAYERS_COUNT_CHANGE,
    payload: payload,
  };
};

export const roomLeavePlayer = (
  payload: Quit,
): RoomLeavePlayerActionInterface => {
  return {
    type: RoomActionsType.ROOM_LEAVE_PLAYER,
    payload: payload,
  };
};

export const roomChangeRole = (
  payload: Role,
): RoomChangeRoleActionInterface => {
  return {
    type: RoomActionsType.ROOM_CHANGE_ROLE,
    payload: payload,
  };
};

export const roomChangeTeamRole = (
  payload: Team,
): RoomChangeTeamRoleActionInterface => {
  return {
    type: RoomActionsType.ROOM_CHANGE_TEAM_ROLE,
    payload: payload,
  };
};

export const roomClear = (): RoomClearActionInterface => {
  return {
    type: RoomActionsType.ROOM_CLEAR,
  };
};

export const roomError = (payload: boolean): RoomAssessmentErrorInterface => {
  return {
    type: RoomActionsType.ROOM_ERROR,
    payload: payload,
  };
};

export const setAssessmentStatus = (
  payload: AssessmentStatus | undefined,
): AssessmentStatusInterface => {
  return {
    type: RoomActionsType.ASSESSMENT_STATUS,
    payload,
  };
};

export type PopupsActions =
  | RoomSetStateActionInterface
  | RoomConnectPlayerActionInterface
  | RoomLeavePlayerActionInterface
  | RoomChangeRoleActionInterface
  | RoomChangeTeamRoleActionInterface
  | RoomClearActionInterface
  | RoomAssessmentErrorInterface
  | RoomConnectedPlayersCountChangeActionInterface
  | AssessmentStatusInterface;
