import { FC, Fragment, ReactElement, ReactNode, useEffect } from "react";
import { useUnit } from "effector-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import classNames from "classnames";
import {
  BaseAlert,
  BaseButton,
  BaseLoader,
  ControlPanel,
  NavList,
  NavListItem,
} from "src/shared/components/index";
import { NewBasePanel } from "../new-base-panel/new-base-panel";
import useClient from "src/shared/hooks/useClient";
import { selectProfileCompetenceItem } from "src/shared/store/ducks/profile-competence";
import { appAccessModel } from "src/features/public/app-access";
import { $specificSessionPermission } from "../../../entities/public/permissions/model";
import { $appInterface } from "../../../features/public/app-interface";
import styles from "./new-page-wrapper.module.scss";

interface Props {
  title?: string;
  emptyPanel?: boolean;
  isLoadingPanel?: boolean;
  isLoadingPage?: boolean;
  isShowContentWhenIsLoading?: boolean;
  isWithoutPadding?: boolean;
  customTabsItems?: NavListItem[];
  tabsPanelSlot?: ReactElement;
  titlePanelSlot?: ReactElement;
  controlPanelTitle?: string;
  controlPanelMaxCount?: number;
  controlPanelSelectedCount?: number;
  controlPanelSlot?: ReactElement;
  controlPanelStyleProps?: { noPadding?: boolean };
  backButton?: boolean;
  isExactWithQueryParams?: boolean;
  customContentClassName?: string;
  additionalPanelSlot?: ReactElement | null;
  noContainer?: boolean;
  children?: ReactNode;
}

export enum Location {
  SESSIONS = "sessions",
  USERS_CONTROL = "users-control",
  SIMULATION_CONTROL = "simulation-control",
  ASSESSMENTS_CONTROL = "assessments-control",
  MAILING_LISTS = "mailing-lists",
  OTHER = "other",
  TOURNAMENTS = "tournaments",
  TOURNAMENT = "tournament",
}

export const NewPageWrapper: FC<Props> = ({
  title,
  tabsPanelSlot,
  titlePanelSlot,
  emptyPanel = false,
  isLoadingPanel = false,
  isLoadingPage = false,
  isShowContentWhenIsLoading = false,
  isWithoutPadding = false,
  children,
  controlPanelTitle,
  controlPanelMaxCount,
  controlPanelSelectedCount,
  controlPanelSlot,
  controlPanelStyleProps,
  backButton,
  customTabsItems,
  isExactWithQueryParams,
  additionalPanelSlot,
  customContentClassName = "",
  noContainer,
}): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const currentLocation: string = location.pathname.split("/")[1];

  const { isPlayerClientId, isHrClientId, isAdminClientId } = useClient();

  useSelector(selectProfileCompetenceItem);

  const { isHeaderShowed } = useUnit($appInterface);

  const isAccess = useUnit(appAccessModel.$isAccess);

  const isSessionAccess = useUnit($specificSessionPermission);

  const isVerificationSession = useUnit(appAccessModel.$isVerificationSession);

  const isFullProfile = useUnit(appAccessModel.$isFullProfile);

  const isSomeCompetenceFirstLvl = useUnit(
    appAccessModel.$isSomeCompetenceFirstLvl,
  );

  const profilePageTabLinksHrSessions: NavListItem[] = [
    {
      title: t("navigation.myProfile.mySessions.waitingStart"),
      to: "/sessions/planned",
      access: !!isAccess && isSessionAccess.has_permission,
    },
    {
      title: t("navigation.myProfile.mySessions.active"),
      to: "/sessions/active",
      access: !!isAccess && isSessionAccess.has_permission,
    },
    {
      title: t("navigation.myProfile.mySessions.finished"),
      to: `/sessions/archive`,
      access: !!isAccess,
    },
  ];

  const tournamentsPageTabLinksHr: NavListItem[] = [
    {
      title: t("navigation.tournaments.current"),
      to: "/tournaments/active",
      access: !!isAccess,
    },
    {
      title: t("navigation.tournaments.completed"),
      to: "/tournaments/completed",
      access: !!isAccess,
    },
  ];

  const tournamentSettingsPageTabLinksHr: NavListItem[] = [
    {
      title: t("navigation.tournamentSettings.settings"),
      to: `/tournament/${id}/settings`,
      access: !!isAccess,
    },
    {
      title: t("navigation.tournamentSettings.participants"),
      to: `/tournament/${id}/participants`,
      access: !!isAccess,
    },
    {
      title: t("navigation.tournamentSettings.sessions"),
      to: `/tournament/${id}/sessions`,
      access: !!isAccess,
    },
    {
      title: t("navigation.tournamentSettings.results"),
      to: `/tournament/${id}/results`,
      access: !!isAccess,
    },
  ];

  const usersControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.usersControl.members"),
      to: "/users-control/players",
      access: true,
    },
    {
      title: t("navigation.usersControl.hrs"),
      to: "/users-control/hrs",
      access: true,
    },
    {
      title: t("navigation.usersControl.admins"),
      to: "/users-control/admins",
      access: true,
    },
  ];

  const simulationControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.simulationsControl.active"),
      to: "/simulation-control/active",
      access: true,
    },
    {
      title: t("navigation.simulationsControl.archive"),
      to: "/simulation-control/archive",
      access: false,
    },
  ];

  const assessmentsControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.assessmentsControl.created"),
      to: "/assessments-control/created",
      access: isSessionAccess.has_permission,
    },
    {
      title: t("navigation.assessmentsControl.active"),
      to: "/assessments-control/active",
      access: isSessionAccess.has_permission,
    },
    {
      title: t("navigation.assessmentsControl.finished"),
      to: "/assessments-control/finished",
      access: true,
    },
  ];

  const mailingListsControlPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.mailingLists.notifications"),
      to: "/mailing-lists/notifications",
      access: true,
    },
    {
      title: t("navigation.mailingLists.e-mail"),
      to: "/mailing-lists/e-mail",
      access: true,
    },
    {
      title: t("navigation.mailingLists.sms"),
      to: "/mailing-lists/sms",
      access: true,
    },
  ];

  const otherPageTabLinksAdmin: NavListItem[] = [
    {
      title: t("navigation.other.positions"),
      to: "/other/edit-positions",
      access: false,
    },
    {
      title: t("navigation.other.faq"),
      to: "/other/edit-faq",
      access: true,
    },
    {
      title: t("navigation.other.contacts"),
      to: "/other/edit-contacts",
      access: true,
    },
    {
      title: t("navigation.other.information"),
      to: "/other/edit-information",
      access: true,
    },
    {
      title: t("navigation.other.countries"),
      to: "/other/countries",
      access: true,
    },
  ];

  const secondLvlNavLinks = (): NavListItem[] => {
    const isSessionsCatalog: boolean = currentLocation === Location.SESSIONS;

    const isOtherCatalog: boolean = currentLocation === Location.OTHER;

    const isUsersControlCatalog: boolean =
      currentLocation === Location.USERS_CONTROL;

    const isSimulationControlCatalog: boolean =
      currentLocation === Location.SIMULATION_CONTROL;

    const isAssessmentsControlCatalog: boolean =
      currentLocation === Location.ASSESSMENTS_CONTROL;

    const isMailingListsControlCatalog: boolean =
      currentLocation === Location.MAILING_LISTS;

    const isTournamentsControlCatalog: boolean =
      currentLocation === Location.TOURNAMENTS;

    const isTournamentSettingsCatalog: boolean =
      currentLocation === Location.TOURNAMENT;

    if (!!customTabsItems?.length) {
      return customTabsItems;
    }

    if (isSessionsCatalog && isHrClientId) {
      return profilePageTabLinksHrSessions;
    }

    if (isTournamentsControlCatalog && isHrClientId) {
      return tournamentsPageTabLinksHr;
    }

    if (isTournamentSettingsCatalog && isHrClientId) {
      return tournamentSettingsPageTabLinksHr;
    }

    if (isUsersControlCatalog && isAdminClientId) {
      return usersControlPageTabLinksAdmin;
    }

    if (isSimulationControlCatalog && isAdminClientId) {
      return simulationControlPageTabLinksAdmin;
    }

    if (isAssessmentsControlCatalog && isAdminClientId) {
      return assessmentsControlPageTabLinksAdmin;
    }

    if (isMailingListsControlCatalog && isAdminClientId) {
      return mailingListsControlPageTabLinksAdmin;
    }

    if (isOtherCatalog && isAdminClientId) {
      return otherPageTabLinksAdmin;
    }

    return [];
  };

  const baseAlertContent = (): string | undefined => {
    const localIsFullProfile: boolean = isPlayerClientId
      ? !!isSomeCompetenceFirstLvl && !isFullProfile
      : !isFullProfile;

    if (localIsFullProfile) {
      return t("profile.info.baseAlert.fillOutProfile");
    }

    if (isVerificationSession) {
      return t("profile.info.baseAlert.verificationSession");
    }
  };

  const isNavList: boolean = !!secondLvlNavLinks().length;

  useEffect(() => {
    if (isNavList) {
      const activeNavItem = document.querySelector(
        ".nav-list--in-header .nav-list__item--active",
      );

      activeNavItem?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "end",
      });
    }
  }, [isNavList]);

  const alertSlot = isFullProfile ? undefined : (
    <BaseButton to="/profile/info" noWrapContent small orange block>
      {t("profile.info.fillTheProfile")}
    </BaseButton>
  );

  return (
    <div
      className={classNames(styles.pageWrapper, {
        [styles.blank]: noContainer,
      })}
    >
      {!emptyPanel && (
        <NewBasePanel className={classNames(styles.basePanel)}>
          <NavList
            level={2}
            links={secondLvlNavLinks()}
            isExactWithQueryParams={isExactWithQueryParams}
            isReplace={backButton}
          />
          {tabsPanelSlot && <Fragment>{tabsPanelSlot}</Fragment>}
        </NewBasePanel>
      )}

      {isLoadingPage ? (
        <BaseLoader></BaseLoader>
      ) : (
        <div
          className={classNames(styles.container, {
            [styles.headerHidden]: !isHeaderShowed,
            [styles.blank]: noContainer,
          })}
        >
          {title && (
            <div
              className={classNames(styles.titlePanel, {
                [styles.emptyBasePanel]: emptyPanel || !isHeaderShowed,
              })}
            >
              <h2 className={styles.title}>
                {backButton && (
                  <button
                    className={styles.backButton}
                    aria-label={t("common.goToPrevPage")}
                    onClick={() => navigate(-1)}
                  />
                )}
                {title}
              </h2>
              {!!titlePanelSlot && titlePanelSlot}
            </div>
          )}

          {(controlPanelTitle || controlPanelSlot) && (
            <ControlPanel
              title={controlPanelTitle}
              maxCount={controlPanelMaxCount || 0}
              selectedCount={controlPanelSelectedCount || 0}
              isLoading={isLoadingPanel}
              {...controlPanelStyleProps}
            >
              {controlPanelSlot}
            </ControlPanel>
          )}
          {additionalPanelSlot && (
            <div className={styles.additionalPanel}>{additionalPanelSlot}</div>
          )}
          {isLoadingPanel && <div className={styles.containerLoader} />}
          {baseAlertContent() && (
            <BaseAlert slot={alertSlot}>{baseAlertContent()}</BaseAlert>
          )}
          {(isShowContentWhenIsLoading || !isLoadingPanel) && (
            <div
              className={classNames(styles.content, {
                [styles.withoutPadding]: isWithoutPadding,
                [customContentClassName]: !!customContentClassName,
                [styles.blank]: noContainer,
              })}
            >
              {children}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
