import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useUrlState } from "src/shared/hooks/useUrlState";
import isEqual from "lodash/isEqual";
import { useUnit } from "effector-react";

import {
  ArchiveLink,
  ConfirmPopup,
  TextWithIcon,
} from "../../../shared/components";

import { UsersControlPanelBtn } from "../components";

import { ERequestStatus } from "../../../shared/store/types";

import { PageWrapper } from "src/shared/components";

import {
  $usersListAdmin,
  cleanUsersListAdmin,
  requestUsersListAdmin,
  setUsersListAdminIsReadmore,
} from "src/entities/public/users-list-admin";

import {
  requestUsersControlDelete,
  requestUsersControlEnable,
} from "../../../shared/store/ducks/users-control/actionCreators";

import {
  selectUsersControlDeleteStatus,
  selectUsersControlEnableStatus,
} from "../../../shared/store/ducks/users-control/selectors";

import { ArchivedUsersTable } from "../../../shared/components/data-grids/user-managments/archive";
import { TDataGridDataParams, UserRoleEnum } from "../../../shared/models";
import { UsersApiUsersRequest } from "../../../generated/keycloak";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import { togglePlaceParams } from "../../../shared/helpers";
import { postChangeUsersHistory } from "../../../entities/admin/histories/model/users/users-change-history";
import { ActionUserEnum } from "../../../generated/social";
import "./archive-page.scss";
import { ERegEx, regExList } from "../../../shared/constants/regExList";

interface Props {}

const { openPopup } = popupModel;

export const ArchivePage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [urlState, setUrlState] = useUrlState();

  const [checkList, setCheckList] = useState<string[]>([]);

  const {
    items: usersListAdminItems,
    pagination,
    status: usersListAdminStatus,
  } = useUnit($usersListAdmin);

  const usersControlEnableStatus = useSelector(selectUsersControlEnableStatus);
  const usersControlDeleteStatus = useSelector(selectUsersControlDeleteStatus);

  const isLoadingUsers: boolean =
    usersListAdminStatus === ERequestStatus.LOADING;

  const isLoadingDelete: boolean =
    usersControlDeleteStatus === ERequestStatus.LOADING;

  const isLoadingEnable: boolean =
    usersControlEnableStatus === ERequestStatus.LOADING;

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (!isEqual(queryParams, newFilteredParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newFilteredParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setUsersListAdminIsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstName,
      lastName,
      role,
      phoneNumber,
      email,

      orderBy,
      pageNum,
      pageSize,
    } = urlState.query;

    const defaultRoles = [
      UserRoleEnum.PLAYER,
      UserRoleEnum.HR_STAFF,
      UserRoleEnum.HR_ALABUGA,
      UserRoleEnum.HR_ADMIN,
      UserRoleEnum.SUPERADMIN,
    ];

    const params: UsersApiUsersRequest = {
      enabled: false,
      role: role ? role.split(",") : defaultRoles,

      firstName: firstName ? [firstName] : undefined,
      lastName: lastName ? [lastName] : undefined,
      phoneNumber: phoneNumber
        ? [phoneNumber.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
        : undefined,
      email: email ? [email] : undefined,

      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [urlState.query]);

  useEffect(() => {
    requestUsersListAdmin(pageParams);
  }, [pageParams]);

  useEffect(() => {
    return () => {
      cleanUsersListAdmin();
    };
  }, []);

  const unlockUser = useCallback(() => {
    postChangeUsersHistory({
      body: { users_id: checkList, action: ActionUserEnum.Unblocked },
    });
    dispatch(
      requestUsersControlEnable({
        usersId: checkList,
        queryForUpdateList: pageParams,
      }),
    );
    setCheckList([]);
  }, [pageParams, checkList, dispatch, setCheckList]);

  const deleteUser = useCallback(() => {
    postChangeUsersHistory({
      body: { users_id: checkList, action: ActionUserEnum.Deleted },
    });
    dispatch(
      requestUsersControlDelete({
        usersId: checkList,
        queryForUpdateList: pageParams,
      }),
    );
    setCheckList([]);
  }, [pageParams, checkList, dispatch, setCheckList]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  return (
    <PageWrapper
      title={t("usersControl.title")}
      tabsPanelSlot={<ArchiveLink to={"/users-control/archive"} />}
      isLightBlueBackground
      isShowContentWhenIsLoading
      isLoadingPanel={isLoadingUsers}
      controlPanelTitle={t("usersControl.controlPanel.label.users")}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={checkList.length}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <div className="table__control">
          {filtersLength > 0 && (
            <div className="table__control-btn" onClick={clearFilterSettings}>
              <TextWithIcon
                label={t("table.filters.reset")}
                iconName={"cross-blue"}
              />
            </div>
          )}
          <UsersControlPanelBtn
            isArchivePage
            isUnlockDisabled={!checkList.length}
            isDeleteDisabled={!checkList.length}
            onUnlock={() => openPopup({ name: EPopupName.UNLOCK_USERS })}
            onDelete={() => openPopup({ name: EPopupName.DELETE_USERS })}
          />
        </div>
      }
    >
      <div className="users-archive-datagrid-wrapper">
        <ArchivedUsersTable
          users={usersListAdminItems}
          pagination={pagination}
          loading={isLoadingUsers}
          onUpdateSortAndFilters={onUpdateQueryParams}
          onSelectRows={setCheckList}
          selectedRowsIds={checkList}
          queryParams={urlState.query}
          goToPage={(pageNum) => goToPage(pageNum)}
          readmore={(pageNum) => goToPage(pageNum, true)}
        />
      </div>

      <ConfirmPopup
        popupName={EPopupName.UNLOCK_USERS}
        title={t("usersControl.popup.unblockUsers.title")}
        description={t("usersControl.popup.unblockUsers.description")}
        confirmBtnLabel={t("usersControl.controlPanel.btn.unblock")}
        onConfirm={unlockUser}
        isConfirmBtnDisabled={isLoadingEnable}
      />

      <ConfirmPopup
        popupName={EPopupName.DELETE_USERS}
        reverse
        title={t("usersControl.popup.deleteUsers.title")}
        description={t("usersControl.popup.deleteUsers.description")}
        confirmBtnLabel={t("usersControl.controlPanel.btn.delete")}
        onConfirm={deleteUser}
        isConfirmBtnDisabled={isLoadingDelete}
      />
    </PageWrapper>
  );
};
