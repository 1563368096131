import { QueryParams } from "src/shared/api/base";

export const readMoreRequest = (
  updatedQueryParams: QueryParams | null,
  readMoreData: (params: QueryParams) => void,
  fetchData: (params: QueryParams) => void,
) => {
  if (!updatedQueryParams) return;
  if (updatedQueryParams.mode === "readmore") {
    readMoreData(updatedQueryParams);
    return;
  }
  fetchData(updatedQueryParams);
};
