import { sample } from "effector";

import { getEmailsListNotification } from "src/shared/api/admin/notification";

import { QueryParams } from "src/shared/api/base";

import {
  $emailsList,
  $pagination,
  $queryParams,
  emailNotificationDomain,
} from "./email-notification";

import { queryParamsToGetEmailListAdapter } from "../lib";

// Event

export const readmoreEmails =
  emailNotificationDomain.createEvent<QueryParams>();

// Effect

export const readmoreEmailsFx = emailNotificationDomain.createEffect(
  getEmailsListNotification,
);

// Logic

sample({
  clock: readmoreEmails,
  fn: queryParamsToGetEmailListAdapter,
  target: readmoreEmailsFx,
});

sample({
  clock: readmoreEmails,
  target: $queryParams,
});

sample({
  clock: readmoreEmailsFx.doneData,
  source: $emailsList,
  fn: (emails, data) => [...emails, ...data.listEmail],
  target: $emailsList,
});

sample({
  clock: readmoreEmailsFx.doneData,
  fn: ({ pagination }) => pagination,
  target: $pagination,
});
