import { FC, ReactElement, useMemo } from "react";

import classNames from "classnames";

import { ILvlUpGameTypeItem, ILvlUpSimulationItem } from "../../model/types";

import { GameTypeCard } from "../";

import { BaseInfo } from "src/shared/components";

import "./level-up-card.scss";
import openGameRulesPopup from "src/shared/helpers/openGameRulesPopup";
import _ from "lodash";
import i18n from "i18next";

export const LevelUpCard: FC<ILvlUpSimulationItem> = ({
  id,
  imgUrl,
  simulationTitle,
  simulationRules,
  availability,
  gameTypes,
}): ReactElement => {
  const groupedGameTypes = useMemo(() => {
    return Object.values(_.groupBy(gameTypes, "gameType"));
  }, [gameTypes]);
  const text =
    (simulationRules && simulationRules[i18n.language as "en" | "ru"]) || "";

  return (
    <div
      className={classNames("level-up-card", {
        "level-up-card--disabled": !availability,
      })}
    >
      <div className="level-up-card__info">
        <div className="level-up-card__info-heading">
          <div
            className="level-up-card__info-image"
            style={{
              backgroundImage: `url(${imgUrl})`,
            }}
          />

          <div className="level-up-card__info-title">{simulationTitle}</div>
        </div>
        {text === "" ? null : (
          <div
            className="level-up-card__info-rules"
            onClick={() => {
              openGameRulesPopup(text);
            }}
          >
            <BaseInfo size={24} />
          </div>
        )}
      </div>
      <div className="level-up-card__list">
        {groupedGameTypes.map((items: ILvlUpGameTypeItem[]) => (
          <GameTypeCard
            key={id + items[0].gameType}
            gameTypes={items}
            simulationId={id}
            className="level-up-card__list-item"
          />
        ))}
      </div>
    </div>
  );
};
