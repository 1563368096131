import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseSwitcher,
  SwitcherItem,
} from "src/shared/components/base-switcher/base-switcher";
import styles from "./users-chart.module.scss";
import { ReactComponent as InfoIcon } from "../../../shared/images/icons/info-icon.svg";
import { BaseTooltip } from "src/shared/components";
import classNames from "classnames";
import { MapChart } from "./map-chart/map-chart";
import {
  $playersCount,
  getMapChartRussia,
  getMapChartWorld,
  getPlayersCount,
} from "src/entities/hr/charts/model/charts";
import { useUnit } from "effector-react";

export const UsersChart = () => {
  const { t } = useTranslation();

  const tooltipRef = useRef<HTMLSpanElement>(null);

  const [view, setView] = useState<"world" | "russia">("russia");

  const players = useUnit($playersCount);

  const switcherValues: SwitcherItem[] = [
    {
      title: t("charts.map.russia"),
      onClick: () => setView("russia"),
      isActive: view === "russia",
    },
    {
      title: t("charts.map.world"),
      onClick: () => setView("world"),
      isActive: view === "world",
    },
  ];

  useEffect(() => {
    if (view === "russia") {
      getMapChartRussia({});
    }

    if (view === "world") {
      getMapChartWorld({});
    }
  }, [view]);

  useEffect(() => {
    getPlayersCount();
  }, []);

  return (
    <div className={styles.block}>
      <header>
        <span
          ref={tooltipRef}
          className={classNames(styles.tooltip_wrapper, "tooltip-wrapper")}
        >
          <InfoIcon />
          <BaseTooltip
            position="bottom-start"
            className={styles.tooltip}
            tooltipToggleRef={tooltipRef}
          >
            <h5>
              {t("charts.map.total_users")}: {players?.players.total}
            </h5>
            <p>
              <span>{t("charts.map.foreigners")}</span>
              <span>{players?.players.foreign}</span>
            </p>
            <p>
              <span>{t("charts.map.region_not_selected")}</span>
              <span>{players?.players.russian}</span>
            </p>
          </BaseTooltip>
        </span>
        <BaseSwitcher items={switcherValues} className={styles.switcher} />
      </header>

      <MapChart view={view} />
    </div>
  );
};
