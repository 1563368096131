import React, { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { languagePicker } from "../../helpers/languagePicker";
import { Checkbox } from "../../models/checkbox";
import {
  ESimulationArrayParamType,
  ESimulationParamsType,
  IMetaParams,
} from "../../store/types";
import {
  FormControl,
  TFormControlMarginBottom,
} from "../form-control/form-control";
import { BaseArrayField, BaseInputNumber, BaseInputText } from "../form-fields";
import { InputCheckbox } from "../input-checkbox/input-checkbox";

export interface ITemplateParamsItem {
  value: string | boolean | string[];
  type: ESimulationParamsType;
  subType?: ESimulationArrayParamType;
  required: boolean;
}

export type TTemplateParams = Record<string, ITemplateParamsItem>;

interface Props {
  templateItemKey: string;
  params: TTemplateParams;
  metaParams: IMetaParams;
  onChange: (
    value: string | boolean | string[],
    itemName: string,
    templateName?: string,
  ) => void;
  errors?: Record<string, string>;
}

export const SimulationParamsFields: React.FC<Props> = ({
  templateItemKey,
  params,
  metaParams,
  onChange,
  errors,
}): React.ReactElement => {
  const { i18n } = useTranslation();

  return (
    <Fragment>
      {Object.keys(params).map((paramName, index) => {
        const { type, subType, value, required } = params[paramName];

        const isArrayType: boolean = ESimulationParamsType.ARRAY === type;

        const isBooleanType: boolean = ESimulationParamsType.BOOLEAN === type;

        const isArrayString: boolean =
          isArrayType && subType === ESimulationArrayParamType.STRING;

        const isArrayNumber: boolean =
          isArrayType &&
          (subType === ESimulationArrayParamType.INTEGER ||
            subType === ESimulationArrayParamType.NUMBER);

        const formControlMargin = (): TFormControlMarginBottom => {
          if (isArrayString) {
            return "medium";
          }

          if (isArrayNumber) {
            return "large";
          }

          return "small";
        };

        const title = languagePicker(
          metaParams[paramName]?.title,
          i18n.language,
        );

        const description = languagePicker(
          metaParams[paramName]?.desc,
          i18n.language,
        );

        const onChangeStringHandler = (payload: {
          value: string;
          name?: string;
        }) => {
          onChange(payload.value, paramName, templateItemKey);
        };

        const onChangeBooleanHandler = (value: boolean) => {
          onChange(value, paramName, templateItemKey);
        };

        const onChangeArrayHandler = (payload: {
          value: string[];
          name: string;
        }) => {
          onChange(payload.value, paramName, templateItemKey);
        };

        const getMinValue = (): number | undefined => {
          if (isArrayType) {
            return metaParams[paramName]?.items?.min;
          }
        };

        const getMaxValue = (): number | undefined => {
          if (isArrayType) {
            return metaParams[paramName]?.items?.max;
          }
        };

        const booleanValue = value as boolean;

        const stringValue = value as string;

        const stringArrayValue = value as string[];

        const Field = (): ReactElement => {
          if (isBooleanType) {
            return (
              <InputCheckbox
                label={title}
                value={booleanValue}
                onChange={onChangeBooleanHandler}
                type={value ? Checkbox.CHOSEN : Checkbox.EMPTY}
              />
            );
          }

          if (isArrayType) {
            return (
              <BaseArrayField
                paramKey={templateItemKey + paramName}
                placeholder={title}
                value={stringArrayValue}
                name={paramName}
                onChange={onChangeArrayHandler}
                error={!!errors?.[paramName]}
                min={getMinValue()}
                max={getMaxValue()}
                type={subType}
                required={required}
              />
            );
          }

          return (
            <BaseInputText
              placeholder={title}
              value={stringValue}
              onChange={onChangeStringHandler}
              name={paramName}
              error={!!errors?.[paramName]}
              required={required}
            />
          );
        };

        return (
          <FormControl
            error={errors?.[paramName]}
            description={description}
            key={templateItemKey + index}
            marginBottom={formControlMargin()}
          >
            {Field()}
          </FormControl>
        );
      })}
    </Fragment>
  );
};
