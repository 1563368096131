import { FC, ReactElement, useEffect } from "react";

import { reflect } from "@effector/reflect";

import { useTranslation } from "react-i18next";

import {
  AddSimulationPopup,
  BaseButton,
  PageWrapper,
  TextWithIcon,
} from "src/shared/components";

import { simulationsModel } from "src/entities/admin/simulations";

import {
  openAddSimulationPopup,
  SimulationList,
} from "src/features/admin/simulations";

import "./active-page.scss";

interface Props {
  isLoading: boolean;
}

const { $isLoading, fetchSimulations, resetSimulations } = simulationsModel;

const View: FC<Props> = ({ isLoading }): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchSimulations();

    return () => {
      resetSimulations();
    };
  }, []);

  return (
    <PageWrapper
      isLightBlueBackground
      isLoadingPanel={isLoading}
      title={t("simulationControl.activePage.title")}
      titlePanelSlot={
        <BaseButton
          className="active-page__add-btn"
          onClick={openAddSimulationPopup}
        >
          <TextWithIcon iconName="plus-blue" iconSize={18} hideLabel />
        </BaseButton>
      }
    >
      <div className="active-page">
        {!isLoading && <SimulationList />}
        <AddSimulationPopup />
      </div>
    </PageWrapper>
  );
};

export const ActivePage = reflect<Props>({
  view: View,
  bind: {
    isLoading: $isLoading,
  },
});
