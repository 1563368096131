import {
  Commands,
  Events,
  Notification,
  PayloadForDeleteMsgs,
  PayloadForMarkRead,
  SocketStore,
} from "./types";

export function markRead({
  socket,
  payload,
}: {
  socket: SocketStore;
  payload: PayloadForMarkRead;
}) {
  socket?.emit(Commands.MarkReadCmd, payload);
}

export async function attachGetItemEvent(
  socket: SocketStore,
  callback: (res: Notification) => void,
) {
  socket?.on(Events.MsgEvt, callback);
}

export async function attachRemoveItemEvent(
  socket: SocketStore,
  callback: (res: { listMsgs: string[] }) => void,
) {
  socket?.on(Events.RemoveMsgsEvt, callback);
}

export function deleteMessages({
  socket,
  payload,
}: {
  socket: SocketStore;
  payload: PayloadForDeleteMsgs;
}) {
  socket?.emit(Commands.DelMsgsCmd, payload);
}
