import { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBlocker } from "react-router-dom";
import {
  EPopupName,
  popupModel,
} from "../../../../shared/components/base-popup";
import { t } from "src/shared/helpers";

import { ConfirmPopup } from "src/shared/components/index";
import { useUnit } from "effector-react"; //TODO: REACT18
import { $isLoading } from "../../../../features/public/account/model";
import {
  $isDraft,
  setIsDraft,
} from "../../../../pages/profile/info-page/lib/draft";
import { $activePopups } from "../../base-popup/model";

const { openPopup, closePopup } = popupModel;

export const DraftPopup: FC = (): ReactElement => {
  const navigate = useNavigate();
  const isLoading: boolean = useUnit($isLoading);
  const isDraft = useUnit($isDraft);
  const [currentPath, setCurrentPath] = useState<string>("");
  const activePopups = useUnit($activePopups);
  const currentPopup = activePopups.find(
    ({ name }) => name === EPopupName.USER_DRAFT_POPUP,
  );
  const [unlock, setUnlock] = useState(true);

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (isDraft && currentLocation.pathname !== nextLocation.pathname) {
      setCurrentPath(nextLocation.pathname);
      openPopup({
        name: EPopupName.USER_DRAFT_POPUP,
      });
      setUnlock(true);
      return true;
    }
    return false;
  });

  const redirectUserHandler = () => {
    setUnlock(false);

    if (!!currentPopup?.data?.keycloak) {
      currentPopup.data.keycloak();
    }

    closePopup({
      name: EPopupName.USER_DRAFT_POPUP,
    });

    setIsDraft(false);
  };

  useEffect(() => {
    if (blocker && blocker.reset && !unlock) {
      blocker.reset();
      navigate(currentPath);
    }

    return () => {
      return blocker && blocker.reset && blocker.reset();
    };
  }, [currentPath, isDraft, navigate, unlock]);

  return (
    <ConfirmPopup
      popupName={EPopupName.USER_DRAFT_POPUP}
      title={t("popup.baseMessage.informationIsNotSave")}
      description={""}
      confirmBtnLabel={t("common.yes")}
      isConfirmBtnDisabled={isLoading}
      onConfirm={redirectUserHandler}
    />
  );
};
