import { FC, ReactElement, useMemo } from "react";

import { useUnit } from "effector-react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import {
  AssessmentType,
  CreateAssessmentResDto,
  GameType,
  PLvlUpCompetence,
} from "src/generated/game";

import { AssessmentStatus } from "src/generated/ws4";

import { constructPlayUrl } from "src/shared/helpers";

import { selectRoom } from "src/shared/store/ducks/room";

import {
  requestCreateAssessment,
  $createAssessment,
} from "src/entities/public/create-assessment";

import { ERequestStatus } from "src/shared/store/types";

import { IGameTypeControlItem } from "../../model/types";

import { CompetenceControl } from "../";

import "./competence-control-list.scss";

interface Props {
  items: IGameTypeControlItem[];
  simulationId: string;
  gameType: GameType;
  gameSubType: string;
  availability: boolean;
}

export const CompetenceControlList: FC<Props> = ({
  items,
  simulationId: id,
  gameType,
  gameSubType,
  availability,
}): ReactElement => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const roomState = useSelector(selectRoom);

  const { status: loadingStatus } = useUnit($createAssessment);

  const isAssessmentInProgress =
    roomState.assessmentStatus === AssessmentStatus.IN_PROGRESS;

  const isLoading: boolean = loadingStatus === ERequestStatus.LOADING;

  const someCompetenceNotAvail: boolean = useMemo(
    () => items.some((item) => !item.availability),
    [items],
  );

  const disabledButtons: boolean =
    isAssessmentInProgress || isLoading || someCompetenceNotAvail;

  const onCreateHandler = (competencies: PLvlUpCompetence) => {
    const callback = ({ playUrl, assessmentId }: CreateAssessmentResDto) => {
      if (playUrl) {
        const constructedPlayUrl = constructPlayUrl({
          playUrl,
          assessmentId: assessmentId || "",
          language: i18n.language,
          queryParams: "",
        });

        window.location.assign(constructedPlayUrl);
      } else if (assessmentId) {
        navigate(`/sessions/session/${assessmentId}`);
      }
    };

    const assessment = {
      assessmentType: AssessmentType.Rating,
      gameType,
      competencies,
      calc_name: gameSubType,
    };

    requestCreateAssessment({ id, assessment, callback });
  };

  return (
    <div className="competence-control-list">
      {items.map(({ combined, tenacity }) => (
        <div
          key={combined[0]}
          className={classNames("competence-control-list__item", {
            "competence-control-list__item--with-tenacity": tenacity,
          })}
        >
          <CompetenceControl
            className="competence-control-list__item-competence"
            competenceItems={combined}
            onClick={() => onCreateHandler({ combined })}
            btnLabel={t("levelUp")}
            btnDisable={!availability || disabledButtons}
          />
          {tenacity && (
            <CompetenceControl
              className={classNames(
                "competence-control-list__item-competence",
                "competence-control-list__item-competence--tenacity",
              )}
              competenceItems={[tenacity]}
              onClick={() => onCreateHandler({ combined: [tenacity] })}
              btnLabel={t("levelUp.internship")}
              btnDisable={!availability || disabledButtons}
            />
          )}
        </div>
      ))}
    </div>
  );
};
