import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { EPopupName } from "../../base-popup";
import { ConfirmPopup } from "../confirm-popup/confirm-popup";
import { useUnit } from "effector-react";
import { closePopup } from "../../base-popup/model";
import { $relativeId } from "../../../../entities/public/relatives/set-relative-id/set-relative-id";
import { $studentFullName } from "../../../../entities/public/relatives/get-student-for-link/get-student-for-link";
import { fetchPostAddCandidateRelative } from "../../../../entities/public/relatives/add-candidate-relative/add-candidate-relative";
import { sendNoConfirmationRelativeNotification } from "../../../../entities/admin/notification/notification";
import { StatusCompany } from "../../../../generated/notification";

interface Props {}

export const InvitationPopup: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const studentInfo = useUnit($studentFullName);

  const relativeId = useUnit($relativeId);

  const onConfirm = () => {
    if (relativeId) {
      fetchPostAddCandidateRelative({
        uuidRelative: { link_id: relativeId },
      });
      closePopup({ name: EPopupName.INVITATION_POPUP });
    }
  };

  const onCancel = () => {
    if (studentInfo) {
      sendNoConfirmationRelativeNotification({
        reqNotifyNoConfirmationReference: {
          uid: studentInfo?.id,
          msg: { status: StatusCompany.NoConfirmationReferenceRelative },
        },
      });
    }
  };

  return (
    <ConfirmPopup
      popupName={EPopupName.INVITATION_POPUP}
      title={`${studentInfo?.firstName} ${studentInfo?.lastName} ${t(
        "relative.wantToAdd",
      )}`}
      confirmBtnLabel={t("common.confirm")}
      cancelBtnLabel={t("common.reject")}
      description={t("relative.confirmOffer")}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isSafe
    />
  );
};
