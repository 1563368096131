import {
  Commands,
  Events,
  GetListParams,
  GetListResponse,
  SocketStore,
} from "./types";

interface GetParams {
  socket: SocketStore;
  payload: GetListParams | void;
}

export function getList({ socket, payload }: GetParams) {
  const pageNum = payload?.pageNum || 0;
  const pageSize = payload?.pageSize || 10;

  socket?.emit(Commands.MyMsgsCmd, { pageNum, pageSize });
}

export async function attachGetListEvent(
  socket: SocketStore,
  callback: (res: GetListResponse) => void,
) {
  socket?.on(Events.MyMsgsEvt, callback);
}
