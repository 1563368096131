import { FC, ReactElement } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { Competence } from "src/generated/game";

import { BaseButton } from "src/shared/components";

import { ControlsConnector } from "../";

import "./competence-control.scss";

interface Props {
  className?: string;
  competenceItems: Competence[];
  btnLabel: string;
  btnDisable: boolean;
  onClick: () => void;
}

export const CompetenceControl: FC<Props> = ({
  className,
  competenceItems,
  btnLabel,
  btnDisable,
  onClick,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={classNames("competence-control", className)}>
      <div className="competence-control__container">
        {competenceItems.length > 1 && (
          <ControlsConnector length={competenceItems.length} />
        )}
        <ul className="competence-control__list">
          {competenceItems.map((competence) => {
            return (
              <li key={competence} className="competence-control__list-item">
                {t(`common.competence.${competence}`, competence)}
              </li>
            );
          })}
        </ul>
      </div>
      <BaseButton xSmall primary onClick={onClick} disabled={btnDisable}>
        {btnLabel}
      </BaseButton>
    </div>
  );
};
