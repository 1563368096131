import { combine, sample } from "effector";

import { $currentLanguage } from "src/shared/helpers";

import { simulationsModel } from "src/entities/public/simulations";

import { appAccessModel } from "src/features/public/app-access";

import { getLvlUpItemsAdapter } from "../lib/getLvlUpItemsAdapter";

import { ILvlUpSimulationItem } from "./types";

import { lvlUpDomain } from "./domain";

const { $simulations } = simulationsModel;

const { $socialPlayerInfo } = appAccessModel;

// Stores

export const $lvlUpItems = lvlUpDomain.createStore<ILvlUpSimulationItem[]>([]);

// Logic

sample({
  clock: combine(
    $simulations,
    $socialPlayerInfo,
    $currentLanguage,
    getLvlUpItemsAdapter,
  ),
  target: $lvlUpItems,
});
