import { useEffect, useMemo } from "react";
import { useRoomConnection } from "src/app/providers/room-context/room-context";

import {
  AssessmentInvite,
  AssessmentStatus,
  AssessmentType,
  MsgType,
  WsMsg,
} from "src/generated/notification";

import { updateNotificationInList } from "../../model";

const useAddMessage = (
  condition: boolean,
  addFunc: (notification: WsMsg) => void,
  message: WsMsg,
) => {
  useEffect(() => {
    if (!condition) return;

    if ((message.msg as AssessmentInvite).aid) {
      addFunc(message);
    }
  }, [condition, addFunc, message]);
};

export const useConnectNotificationToRoom = () => {
  const { isAssessmentInProgress, room, roomLogo, simulationTitle } =
    useRoomConnection();

  const msg = useMemo(
    () => ({
      aid: room?.assessmentId as string,
      aTitle: room?.title as string,
      gTitle: simulationTitle,
      logoUrl: roomLogo,
      aSt: room?.assessmentStatus as string as AssessmentStatus,
      aType: room?.assessmentType as AssessmentType,
    }),
    [room, simulationTitle, roomLogo],
  );

  const messageInvite: WsMsg = useMemo(
    () => ({
      type: MsgType.AssessmentInvite,
      mid: "MsgType.AssessmentInvite" + msg.aid + msg.aSt,
      when: new Date().toUTCString(),
      isNew: true,
      msg,
    }),
    [msg],
  );

  useAddMessage(
    isAssessmentInProgress,
    updateNotificationInList,
    messageInvite,
  );
};
