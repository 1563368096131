import { FC, Fragment, ReactElement } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { BaseCard, EmptyBlock, IBaseCardProps } from "src/shared/components";

import { useMediaQuery } from "src/shared/hooks/useMediaQuery";

import { t } from "src/shared/helpers";

// import "swiper/modules/effect-coverflow/effect-coverflow.min.css"; TODO: REACT18
import "swiper/swiper.min.css";

import "./base-card-list.scss";

export interface IBaseCardListItem extends IBaseCardProps {
  id: string;
}

interface Props {
  cardList: IBaseCardListItem[];
  isPlanned?: boolean;
  onClickCreate?: () => void;
  toCreate?: string;
  addCardProps?: {
    onClickGeneral?: () => void;
    to?: string;
    title: string;
    btnLabel: string;
  };
}

export const BaseCardList: FC<Props> = ({
  cardList,
  addCardProps,
  isPlanned,
}): ReactElement => {
  const { isMobile } = useMediaQuery();

  return (
    <Fragment>
      {isMobile && (
        <Swiper
          spaceBetween={0}
          slidesPerView="auto"
          centeredSlides
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          className="base-card-list-slider"
        >
          {cardList.map((cardItem) => (
            <SwiperSlide
              className="base-card-list-slider__item"
              key={cardItem.id}
            >
              <BaseCard {...cardItem} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {!isMobile && (
        <div className="base-card-list">
          {!!addCardProps && (
            <BaseCard
              {...addCardProps}
              isAddCard={!!addCardProps}
              className="base-card-list__item"
            />
          )}

          {cardList.map((cardItem) => (
            <BaseCard
              {...cardItem}
              isPlanned={isPlanned}
              key={cardItem.id}
              className="base-card-list__item"
            />
          ))}
        </div>
      )}
      {!cardList.length && !addCardProps && (
        <EmptyBlock
          title={t("emptyBlock.notFoundAssessments.title")}
          description={t("emptyBlock.notFoundAssessments.description")}
        />
      )}
      {/*<TournamentRoomTabPage*/}
      {/*  tId={"2"}*/}
      {/*  teId={"3"}*/}
      {/*  route={"active"}*/}
      {/*  assessmentStatus={AssessmentStatus.InProgress}*/}
      {/*/>*/}
    </Fragment>
  );
};
