import {
  Configuration,
  ExecActs,
  UserApi,
  UsersApiPostUsersRequest,
  UsersApiUsersRequest,
} from "src/generated/keycloak";
import { keycloakService } from "../../../base/api-instances";
import { $axiosKeycloak } from "../../../api";

export type FetchResetPasswordParams = {
  userId: string;
  clientId: string;
  callback?: () => void;
};

export const fetchCreateUser = (params: UsersApiPostUsersRequest) => {
  return keycloakService
    .makeUsersRequest()
    .postUsers(params)
    .then((response) => response.data);
};

export const getUsers = (payload: UsersApiUsersRequest) => {
  return keycloakService
    .makeUsersRequest()
    .users(payload)
    .then((response) => response.data);
};

export const fetchResetUserPassword = async ({
  userId,
  clientId,
  callback,
}: FetchResetPasswordParams) => {
  const request = new UserApi(new Configuration(), "", $axiosKeycloak);
  const params = {
    id: userId,
    clientId,
    actions: [ExecActs.UpdatePassword],
  };

  await request.executeActionsEmail(params);
  callback && callback();
};
