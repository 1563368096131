import { FC, ReactElement, useEffect, useState } from "react";

import classNames from "classnames";

import { useDrag } from "react-dnd";

import {
  EDataGridTextWeight,
  IDataGridColumn,
  IDataGridRow,
} from "../../../models";

import { DataGridColWrapper } from "./cols/data-grid-col-wrapper";

interface DataGridBodyRowProps {
  row: IDataGridRow;
  rowIndex: number;
  columns: IDataGridColumn[];
  selectedRows: string[];
  setRows?: (rows: IDataGridRow[]) => void;
  nowrap?: boolean;
  selectRow: (row: IDataGridRow) => void;
  contentFontWeight?: EDataGridTextWeight;
  hiddenHeader?: boolean;
  changeCheckbox?: (id: string, key: string, value: boolean) => void;
  canDrag?: boolean;
}

export const RowDragTypes = {
  GRID_ROW: "GRID_ROW",
};

export const DataGridBodyRow: FC<DataGridBodyRowProps> = ({
  row,
  rowIndex,
  columns,
  selectedRows,
  selectRow,
  nowrap,
  contentFontWeight,
  hiddenHeader,
  changeCheckbox,
  canDrag,
}): ReactElement => {
  const [isMultiSelectPreview, setIsMultiSelectPreview] = useState(false);

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: RowDragTypes.GRID_ROW,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const handleRowClick = () => row.onClick?.(row.id);

  const isInSelectedRows = selectedRows.includes(row.id);

  const isMultiSelectedDrag =
    selectedRows.length > 1 || (selectedRows.length === 1 && !isInSelectedRows);

  const handleDragStart = () => {
    if (!isMultiSelectedDrag) return;

    setIsMultiSelectPreview(true);
  };

  const handleDragEnd = () => {
    if (!isMultiSelectedDrag) return;

    setIsMultiSelectPreview(false);
  };

  useEffect(() => {
    if (!isDragging || isInSelectedRows) return;

    selectRow(row);
  }, [isDragging, isInSelectedRows, selectRow, row]);

  return (
    <tr
      className={classNames(
        "data-grid__tr",
        ["data-grid__tr--odd", "data-grid__tr--even"][rowIndex % 2],
        {
          "data-grid__tr--selected": row.selected,
          "data-grid__tr--has-hover-state": !!row.onClick,
          "data-grid__tr--transparent": row.isTransparent,
          "data-grid__tr--dragging": isDragging,
          "data-grid__tr--in-selected-rows": isInSelectedRows,
          "data-grid__tr--multi-selected": isMultiSelectPreview,
        }
      )}
      onClick={handleRowClick}
      data-row-id={row.id}
      ref={canDrag ? dragRef : null}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {columns.map((column) =>
        column.showed ? (
          <DataGridColWrapper
            column={column}
            row={row}
            selectRow={selectRow}
            selectedRows={selectedRows}
            changeCheckbox={changeCheckbox}
            contentFontWeight={contentFontWeight}
            hiddenHeader={hiddenHeader}
            key={column.key}
            nowrap={nowrap}
          />
        ) : null
      )}
    </tr>
  );
};
