import { FormDataInterface } from "src/shared/components/user-form/user-form";

export type TFormDataStringKeys = Array<
  keyof Omit<FormDataInterface, "desiredSpecialty"> | undefined
>;

export const getFieldsNamesForClean = (
  changedField: keyof FormDataInterface,
  isPlayer: boolean,
): TFormDataStringKeys => {
  const schoolField = isPlayer ? "school" : undefined;

  const isCountry: boolean = changedField === "country";

  const allAddressFields = ["region", "district", "locality"].concat(
    schoolField ?? [],
  ) as TFormDataStringKeys;

  if (isCountry) {
    return allAddressFields;
  }

  const isRegion: boolean = changedField === "region";

  if (isRegion) {
    return allAddressFields.slice(1);
  }

  const isDistrict: boolean = changedField === "district";

  if (isDistrict) {
    return allAddressFields.slice(2);
  }

  return [];
};
