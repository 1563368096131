import { useTranslation } from "react-i18next";

export const secondsForms = ["секунда", "секунды", "секунд"];
export const minutesForms = ["минута", "минуты", "минут"];
export const hoursForms = ["час", "часа", "часов"];
export const daysForms = ["день", "дня", "дней"];
export const monthsForms = ["месяц", "месяца", "месяцев"];

export const useSendingTime = (serverTime: Date) => {
  const { t, i18n } = useTranslation();

  return (timestamp: string): string => {
    const messageDate = new Date(timestamp);
    const timeDifference = serverTime.getTime() - messageDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);

    if (secondsDifference === 0) {
      return t("common.time.justNow");
    } else if (secondsDifference < 60) {
      const timeAgo =
        i18n.language === "ru"
          ? selectForm(secondsDifference, secondsForms)
          : t("common.time.minutes");
      return `${secondsDifference} ${timeAgo} ${t("common.time.ago")}`;
    } else if (secondsDifference < 3600) {
      const minutes = Math.floor(secondsDifference / 60);
      const timeAgo =
        i18n.language === "ru"
          ? selectForm(minutes, minutesForms)
          : t("common.time.minutes");
      return `${minutes} ${timeAgo} ${t("common.time.ago")}`;
    } else if (secondsDifference < 86400) {
      const hours = Math.floor(secondsDifference / 3600);
      const timeAgo =
        i18n.language === "ru"
          ? selectForm(hours, hoursForms)
          : t("common.time.minutes");
      return `${hours} ${timeAgo} ${t("common.time.ago")}`;
    } else if (secondsDifference < 604800) {
      const days = Math.floor(secondsDifference / 86400);
      const timeAgo =
        i18n.language === "ru"
          ? selectForm(days, daysForms)
          : t("common.time.minutes");
      return `${days} ${timeAgo} ${t("common.time.ago")}`;
    } else {
      const formattedDate = messageDate.toLocaleDateString(i18n.language, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return formattedDate;
    }
  };
};

export const selectForm = (number: number, forms: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  let index: number;
  if (number % 100 > 4 && number % 100 < 20) {
    index = 2;
  } else {
    index = cases[Math.min(number % 10, 5)];
  }
  return forms[index] ? forms[index] : forms[1];
};
