import { TTemplateParams } from "../components/simulation-params-fields/simulation-params-fields";
import {
  ESimulationArrayParamType,
  ESimulationParamsType,
  IParams,
} from "../store/types";

export const getSimulationParamsForRequest = (
  params: TTemplateParams,
): IParams => {
  let localParams: IParams = {};
  const paramsKeys: string[] = Object.keys(params);

  paramsKeys.forEach((param) => {
    const { type, subType, value, required } = params[param];

    const isNumberType: boolean =
      type === ESimulationParamsType.NUMBER ||
      type === ESimulationParamsType.INTEGER;

    const isNumberSubtype: boolean =
      subType === ESimulationArrayParamType.INTEGER ||
      subType === ESimulationArrayParamType.NUMBER;

    const isArrayType: boolean = type === ESimulationParamsType.ARRAY;

    const isArrayStringType: boolean =
      isArrayType && subType === ESimulationArrayParamType.STRING;

    const isArrayNumberType: boolean = isArrayType && isNumberSubtype;

    const stringArrayValue = value as string[];

    const boolStringValue = value as boolean | string;

    const hasValue: boolean = isArrayType
      ? !!stringArrayValue.length
      : value !== "";

    if (!hasValue && required) {
      return;
    }

    if (isNumberType) {
      localParams[param] = Number(value);
    } else if (isArrayNumberType) {
      localParams[param] = stringArrayValue.map((item) => Number(item));
    } else if (isArrayStringType) {
      localParams[param] = stringArrayValue;
    } else {
      localParams[param] = boolStringValue;
    }
  });

  return localParams;
};
