import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { Pagination } from "../../../../../shared/api/base";

import {
  RelativeApiGetChangeRelativeTableRequest,
  RelativeTableChangeBindDto,
} from "src/generated/social";
import { getRelativeModerationHistory } from "../../../../../shared/api/admin/histories/model/moderation-history/getRelativeModerationHistory";

// Domain

export const moderationRelativeHistoryDomain = createDomain(
  "moderationRelativeHistory",
);

// Events

export const fetchModerationRelativeHistory =
  moderationRelativeHistoryDomain.createEvent<RelativeApiGetChangeRelativeTableRequest>();

// Effects

export const fetchModerationRelativeHistoryFx =
  moderationRelativeHistoryDomain.createEffect(getRelativeModerationHistory);

// Stores

export const $moderationRelativeHistoryPlayers =
  moderationRelativeHistoryDomain.createStore<RelativeTableChangeBindDto[]>([]);

export const $pagination =
  moderationRelativeHistoryDomain.createStore<Pagination>(null);

export const $queryParams =
  moderationRelativeHistoryDomain.createStore<RelativeApiGetChangeRelativeTableRequest>(
    {},
  );

export const $isLoadingPlayers =
  moderationRelativeHistoryDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchModerationRelativeHistory,
  target: fetchModerationRelativeHistoryFx,
});

sample({
  clock: fetchModerationRelativeHistory,
  target: $queryParams,
});

sample({
  clock: fetchModerationRelativeHistoryFx.pending,
  target: $isLoadingPlayers,
});

spread({
  source: fetchModerationRelativeHistoryFx.doneData.map(
    ({ changes, pagination }) => ({
      changes,
      pagination: pagination || null,
    }),
  ),
  targets: {
    changes: $moderationRelativeHistoryPlayers,
    pagination: $pagination,
  },
});
