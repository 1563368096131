import { createDomain, sample } from "effector";

import { QueryParams } from "../../../../shared/api/base";

import { getSchools, School } from "../../../../shared/api/public/schools";

import { queryParamsToGetListParamsAdapter } from "../lib/queryParamsToGetParamsAdapter";

export const schoolsDomain = createDomain("schoolsPublic");

// Effects

export const fetchSchoolsFx = schoolsDomain.createEffect(getSchools);

// Stores

export const $schools = schoolsDomain.createStore<School[]>([]);

export const $isLoading = schoolsDomain.createStore<boolean>(false);

// Events

export const fetchSchools = schoolsDomain.createEvent<QueryParams>();

sample({
  clock: fetchSchools,
  fn: queryParamsToGetListParamsAdapter,
  target: fetchSchoolsFx,
});

sample({
  clock: fetchSchoolsFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchSchoolsFx.doneData,
  fn: ({ schools }) => schools,
  target: $schools,
});
