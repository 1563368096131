import { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import {
  AvatarUpload,
  BaseAlert,
  BaseBox,
  BaseButton,
  TextWithIcon,
  UserForm,
} from "../../../shared/components";
import { FormDataInterface } from "../../../shared/components/user-form/user-form";
import { getRequestKeycloakUserData } from "../../../shared/helpers/getRequestKeycloakUserData";
import { getUserFormData } from "../../../shared/helpers/getUserFormData";
import useInput, {
  EInputValidateType,
  IValidateValue,
} from "../../../shared/hooks/useInput";
import { useQuery } from "../../../shared/hooks/useQuery";
import { UserRoleEnum, UserTypeEnum } from "../../../shared/models/user";
import { ERequestStatus } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import {
  requestOptionsUserAvatar,
  selectCdnOptionsUserAvatar,
} from "src/shared/store/ducks/cdn";
import "./add-user-page.scss";
import { useNavigate } from "react-router-dom";
import { UserReprIdm } from "src/generated/keycloak";
import i18n from "src/shared/i18n/i18n";
import { useUnit } from "effector-react";
import {
  $isLoading,
  $resetPasswordRequestStatus,
  fetchPostCreateUser,
  setUploadFilesEvent,
} from "./lib/create-user";
import {
  $userType,
  setUserType,
} from "../../../features/public/user-form/model";

interface Props {}

const requiredFields: Record<string, boolean> = {
  email: true,
  phoneNumber: true,
  login: true,
  country: true,
};

const { openPopup } = popupModel;

const userTypeToUserRole = (userType: UserTypeEnum): string => {
  if (userType === UserTypeEnum.ADMIN) {
    return UserRoleEnum.HR_ADMIN;
  }

  if (userType === UserTypeEnum.HR) {
    return UserRoleEnum.HR_STAFF;
  }

  return UserRoleEnum.PLAYER;
};

const getUserRoleLabel = (userType: UserTypeEnum): string => {
  if (userType === UserTypeEnum.ADMIN) {
    return i18n.t("common.administrator");
  } else if (userType === UserTypeEnum.HR) {
    return "HR";
  }

  return i18n.t("common.member");
};

export const AddUserPage: FC<Props> = (): ReactElement => {
  const { validateValue } = useInput();

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { getQuery } = useQuery();

  const possibleUserTypes = [
    UserTypeEnum.PLAYER,
    UserTypeEnum.HR,
    UserTypeEnum.ADMIN,
  ];

  const userType = useUnit($userType);

  const isUserTypeCorrect: boolean = possibleUserTypes.includes(userType!);

  const isAdmin: boolean = userType === UserTypeEnum.ADMIN;

  const [formData, setFormData] = useState<FormDataInterface>(
    getUserFormData(),
  );

  const navigate = useNavigate();

  const resetPasswordRequestStatus = useUnit($resetPasswordRequestStatus);

  const isResetPasswordLoading: boolean =
    resetPasswordRequestStatus === ERequestStatus.LOADING;

  const avatarOptions = useSelector(selectCdnOptionsUserAvatar);

  const someString = `${"ecaps.agubala.rh.ved//:spt"}${"th"}`;

  const isShowCaptcha =
    process.env.REACT_APP_DOMAIN !== someString.split("").reverse().join("");

  const isLoading = useUnit($isLoading);

  useEffect(() => {
    dispatch(requestOptionsUserAvatar());
  }, [dispatch]);

  useEffect(() => {
    const userType = getQuery("userType") as UserTypeEnum;
    if (userType) {
      setUserType(userType);
    }
  }, []);

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    const validateValueOptions: { [key: string]: IValidateValue } = {
      email: {
        value: formData.email,
        type: EInputValidateType.EMAIL,
        required: true,
      },
      phoneNumber: {
        value: formData.phoneNumber,
        type: EInputValidateType.PHONE_NUMBER,
        required: !isAdmin,
      },
      login: {
        value: formData.login,
        type: EInputValidateType.STRING,
        required: isAdmin,
      },
      country: {
        value: formData.country,
        type: EInputValidateType.STRING,
        required: true,
      },
    };

    const newFormErrorsState = validateValue(validateValueOptions);

    const isAllFieldsCorrect = !Object.keys(newFormErrorsState).length;

    setFormErrors(newFormErrorsState);

    if (userType && isUserTypeCorrect && isAllFieldsCorrect) {
      const user: UserReprIdm = {
        ...getRequestKeycloakUserData(formData, true),
        realmRoles: [userTypeToUserRole(userType)],
        enabled: true,
        username:
          userType === UserTypeEnum.ADMIN
            ? formData.login
            : formData.phoneNumber,
      };

      fetchPostCreateUser({ userReprIdm: user });
    } else {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.baseMessage.message.fillAllFields"),
          isError: true,
        },
      });

      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    }
  };

  // редиректит, если не корректрый userType
  if (!userType || !isUserTypeCorrect) {
    navigate(`/users-control/add-user?userType=${UserTypeEnum.PLAYER}`);
  }
  const onChangeAvatarHandler = (file?: File, avatar?: string) => {
    setFormData((formData) => ({
      ...formData,
      avatarUrl: avatar || "",
    }));

    setUploadFilesEvent(file);
  };

  const getUserType = () => {
    return userType !== null ? userType : UserTypeEnum.PLAYER;
  };

  return (
    <PageWrapper
      title={`${t("common.new")} ${getUserRoleLabel(userType!)}`}
      backButton
      emptyPanel
      isLightBlueBackground
    >
      <div className="add-user-page">
        <div className="add-user-page__form">
          <BaseBox>
            <UserForm
              isReCaptchaShow={isShowCaptcha}
              formData={formData}
              userType={getUserType()}
              onSubmit={onSubmitForm}
              setFormData={setFormData}
              emailVerified={false}
              errors={formErrors}
              setFormErrors={setFormErrors}
              isWithoutCheckbox={true}
              requiredFields={requiredFields}
            />

            <BaseButton
              submit
              block
              primary
              large
              disabled={isLoading || isResetPasswordLoading}
              onClick={onSubmitForm}
              className="add-user-page__form-btn"
            >
              {t("usersControl.btn.saveChanges")}
            </BaseButton>
          </BaseBox>
        </div>
        <div className="add-user-page__photo">
          {avatarOptions && (
            <AvatarUpload
              avatarUrl={formData.avatarUrl}
              fileSize={avatarOptions.maxSize}
              formats={avatarOptions.contentType}
              className="info-page__avatar"
              onChange={onChangeAvatarHandler}
              cropTitle={t("profile.info.title.photo")}
              cropDescriptions={[]}
            />
          )}
        </div>
        <div className="add-user-page__rights">
          <BaseBox className="add-user-page__set-rights">
            <BaseButton disabled>
              <TextWithIcon
                iconName="check-square"
                label={t("usersControl.btn.changeRights")}
              />
            </BaseButton>
          </BaseBox>
          <div className="add-user-page__alert">
            <BaseAlert isSmall children={t("usersControl.alert.userRights")} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
