import { FC, ReactElement, useMemo } from "react";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";

import { useUnit } from "effector-react";

import {
  BasePopup,
  EPopupName,
  popupModel,
} from "src/shared/components/base-popup";
import { useTranslation } from "react-i18next";

const { $activePopups } = popupModel;

// ToDo: {HRP-122} - удалить
// const simulations: Record<string, JSX.Element> = {
//   "Бизнес-Коты": <SessionRulesCats />,
//   Грузовики: <SessionRulesTrucks />,
// };

export const SessionRulesPopup: FC = (): ReactElement | null => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const text = useMemo(() => {
    const currentPopup = activePopups.find(
      ({ name }) => name === EPopupName.GAME_RULES,
    );
    return currentPopup?.message?.text;
  }, [activePopups]);

  return text ? (
    <BasePopup name={EPopupName.GAME_RULES} title={t("popup.rules.title")}>
      <div className="ql-editor" style={{ padding: 0 }}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </BasePopup>
  ) : null;
};
