import { notificationService } from "src/shared/api/base/api-instances";
import {
  DeleteEmail,
  GetEmail,
  GetEmailHistory,
  GetEmailsList,
  PostEmail,
  PutEmail,
  SendEmail,
  SendEmailDirectly,
} from "./query";

export const getEmailsListNotification = (params: GetEmailsList) => {
  return notificationService
    .makeEmailNotificationRequest()
    .getEmailsList(params)
    .then((res) => res.data);
};

export const getEmailNotification = (params: GetEmail) => {
  return notificationService
    .makeEmailNotificationRequest()
    .getEmail(params)
    .then((res) => res.data);
};

export const putEmailNotification = (params: PutEmail) => {
  return notificationService
    .makeEmailNotificationRequest()
    .putEmail(params)
    .then((res) => res.data);
};

export const sendEmailNotification = (params: SendEmail) => {
  return notificationService
    .makeEmailNotificationRequest()
    .sendEmail(params)
    .then((res) => res.data);
};

export const sendDirectlyEmailNotification = (params: SendEmailDirectly) => {
  return notificationService
    .makeEmailNotificationRequest()
    .sendEmailDirectly(params)
    .then((res) => res.data);
};

export const addEmailNotification = (params: PostEmail) => {
  return notificationService
    .makeEmailNotificationRequest()
    .postEmail(params)
    .then((res) => res.data);
};

export const deleteEmailNotification = (params: DeleteEmail) => {
  return notificationService
    .makeEmailNotificationRequest()
    .delEmail(params)
    .then((res) => res.data);
};

export const getEmailNotificationHistory = (params: GetEmailHistory) => {
  return notificationService
    .makeEmailNotificationRequest()
    .getEmailsHistory(params)
    .then((res) => res.data);
};
