import { EDataGridTextColor } from "../models/dataGrid";

export const getCoefficientTextColor = (
  value?: number,
): EDataGridTextColor | null => {
  if (value === undefined || value === 0) {
    return EDataGridTextColor.GRAY;
  }

  if (value > 0) {
    return EDataGridTextColor.GREEN;
  }

  if (value < 0) {
    return EDataGridTextColor.RED;
  }

  return null;
};
