import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import "./404-page.scss";

import imgAvif from "../../shared/images/404-page.avif";
import imgWebp from "../../shared/images/404-page.webp";
import imgJpg from "../../shared/images/404-page.jpg";

import imgMobileAvif from "../../shared/images/404-page-mobile.avif";
import imgMobileWebp from "../../shared/images/404-page-mobile.webp";
import imgMobileJpg from "../../shared/images/404-page-mobile.jpg";

interface Props {}

const tabletMaxMedia: string = "(max-width: 1023px)";

export const Page404: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="page-404">
      <picture className="page-404__bg">
        <source
          srcSet={imgMobileAvif}
          type="image/avif"
          media={tabletMaxMedia}
        />
        <source
          srcSet={imgMobileWebp}
          type="image/webp"
          media={tabletMaxMedia}
        />
        <source
          srcSet={imgMobileJpg}
          type="image/jpeg"
          media={tabletMaxMedia}
        />
        <source srcSet={imgAvif} type="image/avif" />
        <source srcSet={imgWebp} type="image/webp" />
        <img
          className="page-404__bg-img"
          alt="404"
          src={imgJpg}
          loading="lazy"
        />
      </picture>
      <h2 className="page-404__title">404</h2>
      <h3 className="page-404__subtitle">{t("page404.subtitle")}</h3>
      <p className="page-404__description">{t("page404.description")}</p>
    </div>
  );
};
