import { Action } from "redux";

import {
  AssessmentApiGetPAssessmentsRequest,
  AssessmentApiGetPRolesRequest,
  PAssessmentsBriefResDto,
  PRolesResDto,
} from "../../../../generated/statistic";

export enum ESessionsHistoryActions {
  CLEAN_SESSIONS_HISTORY = "sessionsHistory/CLEAN_SESSIONS_HISTORY",
  SET_SESSIONS_HISTORY_IS_READMORE = "sessionsHistory/SET_SESSIONS_HISTORY_IS_READMORE",
  REQUEST_SESSIONS_HISTORY = "sessionsHistory/REQUEST_SESSIONS_HISTORY",
  SUCCESS_SESSIONS_HISTORY = "sessionsHistory/SUCCESS_SESSIONS_HISTORY",
  FAILURE_SESSIONS_HISTORY = "sessionsHistory/FAILURE_SESSIONS_HISTORY",
  REQUEST_SESSIONS_HISTORY_ROLES = "sessionsHistory/REQUEST_SESSIONS_HISTORY_ROLES",
  SUCCESS_SESSIONS_HISTORY_ROLES = "sessionsHistory/SUCCESS_SESSIONS_HISTORY_ROLES",
  FAILURE_SESSIONS_HISTORY_ROLES = "sessionsHistory/FAILURE_SESSIONS_HISTORY_ROLES",
}

export interface ICleanSessionsHistory extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.CLEAN_SESSIONS_HISTORY;
}

export interface IRequestSessionsHistory
  extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY;
  payload: {
    params: AssessmentApiGetPAssessmentsRequest;
    callback?: () => void;
  };
}

export interface ISetSessionsHistoryIsReadmore
  extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.SET_SESSIONS_HISTORY_IS_READMORE;
  payload: {
    value: boolean;
  };
}

export interface ISuccessSessionsHistory
  extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.SUCCESS_SESSIONS_HISTORY;
  payload: PAssessmentsBriefResDto;
}

export interface IFailureSessionsHistory
  extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.FAILURE_SESSIONS_HISTORY;
}

export interface IRequestSessionsHistoryRoles
  extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY_ROLES;
  payload: {
    params: AssessmentApiGetPRolesRequest;
    callback?: () => void;
  };
}

export interface ISuccessSessionsHistoryRoles
  extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.SUCCESS_SESSIONS_HISTORY_ROLES;
  payload: PRolesResDto;
}

export interface IFailureSessionsHistoryRoles
  extends Action<ESessionsHistoryActions> {
  type: ESessionsHistoryActions.FAILURE_SESSIONS_HISTORY_ROLES;
}
