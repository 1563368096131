import { Socket } from "socket.io-client";
import { AssessmentMemberResDto } from "src/generated/game";

import {
  ActFinished,
  Active,
  ActList,
  ActStarted,
  Err,
  Finished,
  HrRoom,
  Join,
  LobbyChanged,
  LobbyCreated,
  LobbyDeleted,
  LobbyList,
  LobbyStarted,
  Play,
  PRoomChanged,
  PRoomR,
  Quit,
  Results,
  Role,
  Team,
  Update,
} from "src/generated/ws4";

export const events = {
  lobby: {
    list: "lobbyList",
    created: "lobbyCreated",
    changed: "lobbyChanged",
    started: "lobbyStarted",
    deleted: "lobbyDeleted",
    err: "err",
  },

  act: {
    list: "actList",
    started: "actStarted",
    finished: "actFinished",
    err: "err",
  },

  roomPlayer: {
    pRoomR: "pRoomR",
    pRoomNR: "pRoomNR",
    pRoomChanged: "pRoomChanged",
    role: "role",
    team: "team",
    quit: "quit",
    join: "join",
    play: "play",
    fin: "fin",
    results: "results",
    active: "active",
    err: "err",
  },

  roomHr: {
    hrRoomState: "hrRoom",
    role: "role",
    team: "team",
    quit: "quit",
    join: "join",
    play: "play",
    fin: "fin",
    err: "err",
  },

  nonLobbyPlayer: {
    role: "pInfo",
  },
};

export const updates = {
  updateRoom: {
    quit: "quit",
  },
};

abstract class SocketHandler {
  protected socket: Socket;

  constructor(socket: Socket) {
    this.socket = socket;
  }
}

export class LobbySocketHandler extends SocketHandler {
  static of(socket: SocketHandler["socket"]): LobbySocketHandler {
    return new LobbySocketHandler(socket);
  }

  onList(handler: (result: LobbyList) => void) {
    this.socket.on(events.lobby.list, handler);
    return () => this.socket.off(events.lobby.list, handler);
  }

  onCreate(handler: (result: LobbyCreated) => void) {
    this.socket.on(events.lobby.created, handler);
    return () => this.socket.off(events.lobby.created, handler);
  }

  onChange(handler: (result: LobbyChanged) => void) {
    this.socket.on(events.lobby.changed, handler);
    return () => this.socket.off(events.lobby.changed, handler);
  }

  onStart(handler: (result: LobbyStarted) => void) {
    this.socket.on(events.lobby.started, handler);
    return () => this.socket.off(events.lobby.started, handler);
  }

  onDelete(handler: (result: LobbyDeleted) => void) {
    this.socket.on(events.lobby.deleted, handler);
    return () => this.socket.off(events.lobby.deleted, handler);
  }

  onError(handler: (result: Err) => void) {
    this.socket.on(events.lobby.err, handler);
    return () => this.socket.off(events.lobby.err, handler);
  }
}

export class ActSocketHandler extends SocketHandler {
  static of(socket: SocketHandler["socket"]): ActSocketHandler {
    return new ActSocketHandler(socket);
  }

  onList(handler: (result: ActList) => void) {
    this.socket.on(events.act.list, handler);
    return () => this.socket.off(events.act.list, handler);
  }

  onStart(handler: (result: ActStarted) => void) {
    this.socket.on(events.act.started, handler);
    return () => this.socket.off(events.act.started, handler);
  }

  onFinished(handler: (result: ActFinished) => void) {
    this.socket.on(events.act.finished, handler);
    return () => this.socket.off(events.act.finished, handler);
  }

  onError(handler: (result: Err) => void) {
    this.socket.on(events.lobby.err, handler);
    return () => this.socket.off(events.lobby.err, handler);
  }
}

export class RoomPlayerSocketHandler extends SocketHandler {
  static of(
    socket: RoomPlayerSocketHandler["socket"],
  ): RoomPlayerSocketHandler {
    return new RoomPlayerSocketHandler(socket);
  }

  pRoomR(handler: (result: PRoomR) => void) {
    this.socket.on(events.roomPlayer.pRoomR, handler);
    return () => this.socket.off(events.roomPlayer.pRoomR, handler);
  }

  pRoomNR(handler: (result: PRoomR) => void) {
    this.socket.on(events.roomPlayer.pRoomNR, handler);
    return () => this.socket.off(events.roomPlayer.pRoomNR, handler);
  }

  pRoomChanged(handler: (result: PRoomChanged) => void) {
    this.socket.on(events.roomPlayer.pRoomChanged, handler);
    return () => this.socket.off(events.roomPlayer.pRoomChanged, handler);
  }

  onRoleChange(handler: (result: Role) => void) {
    this.socket.on(events.roomPlayer.role, handler);
    return () => this.socket.off(events.roomPlayer.role, handler);
  }

  onTeamChange(handler: (result: Team) => void) {
    this.socket.on(events.roomPlayer.team, handler);
    return () => this.socket.off(events.roomPlayer.team, handler);
  }

  onQuit(handler: (result: Quit) => void) {
    this.socket.on(events.roomPlayer.quit, handler);
    return () => this.socket.off(events.roomPlayer.quit, handler);
  }

  onJoin(handler: (result: Join) => void) {
    this.socket.on(events.roomPlayer.join, handler);
    return () => this.socket.off(events.roomPlayer.join, handler);
  }

  onPlay(handler: (result: Play) => void) {
    this.socket.on(events.roomPlayer.play, handler);
    return () => this.socket.off(events.roomPlayer.play, handler);
  }

  onFin(handler: (result: Finished) => void) {
    this.socket.on(events.roomPlayer.fin, handler);
    return () => this.socket.off(events.roomPlayer.fin, handler);
  }

  onResults(handler: (result: Results) => void) {
    this.socket.on(events.roomPlayer.results, handler);
    return () => this.socket.off(events.roomPlayer.results, handler);
  }

  onActive(handler: (result: Active) => void) {
    this.socket.on(events.roomPlayer.active, handler);
    return () => this.socket.off(events.roomPlayer.active, handler);
  }

  onError(handler: (result: Err) => void) {
    this.socket.on(events.roomPlayer.err, handler);
    return () => this.socket.off(events.roomPlayer.err, handler);
  }
}

export class RoomHrSocketHandler extends SocketHandler {
  static of(socket: RoomHrSocketHandler["socket"]): RoomHrSocketHandler {
    return new RoomHrSocketHandler(socket);
  }

  onStateChange(handler: (result: HrRoom) => void) {
    this.socket.on(events.roomHr.hrRoomState, handler);
    return () => this.socket.off(events.roomHr.hrRoomState, handler);
  }

  onRoleChange(handler: (result: Role) => void) {
    this.socket.on(events.roomHr.role, handler);
    return () => this.socket.off(events.roomHr.role, handler);
  }

  onTeamChange(handler: (result: Team) => void) {
    this.socket.on(events.roomHr.team, handler);
    return () => this.socket.off(events.roomHr.team, handler);
  }

  onQuit(handler: (result: Quit) => void) {
    this.socket.on(events.roomHr.quit, handler);
    return () => this.socket.off(events.roomHr.quit, handler);
  }

  onJoin(handler: (result: Join) => void) {
    this.socket.on(events.roomHr.join, handler);
    return () => this.socket.off(events.roomHr.join, handler);
  }

  onPlay(handler: (result: Play) => void) {
    this.socket.on(events.roomHr.play, handler);
    return () => this.socket.off(events.roomHr.play, handler);
  }

  onFin(handler: (result: Finished) => void) {
    this.socket.on(events.roomHr.fin, handler);
    return () => this.socket.off(events.roomHr.fin, handler);
  }

  onError(handler: (result: Err) => void) {
    this.socket.on(events.roomHr.err, handler);
    return () => this.socket.off(events.roomHr.err, handler);
  }
}

export class UpdateRoomSocketHandler extends SocketHandler {
  static of(
    socket: UpdateRoomSocketHandler["socket"],
  ): UpdateRoomSocketHandler {
    return new UpdateRoomSocketHandler(socket);
  }

  onQuit(handler: (result: Update) => void) {
    this.socket.on(updates.updateRoom.quit, handler);
    return () => this.socket.off(updates.updateRoom.quit, handler);
  }

  playerRole(handler: (result: AssessmentMemberResDto) => void) {
    this.socket.on(events.nonLobbyPlayer.role, handler);
    return () => this.socket.off(events.nonLobbyPlayer.role, handler);
  }
}
