import { ChangeEvent, FC, ReactElement, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useUnit } from "effector-react";

import {
  BaseButton,
  BaseTextarea,
  FormControl,
} from "src/shared/components/index";

import {
  BasePopup,
  EPopupName,
  popupModel,
} from "src/shared/components/base-popup";

import "./confirm-popup.scss";

interface Props {
  reverse?: boolean;
  popupName: EPopupName;
  title: string;
  description?: string;
  cancelBtnLabel?: string;
  confirmBtnLabel: string;
  isConfirmBtnDisabled?: boolean;
  onCancel?: () => void;
  onConfirm: (message?: string) => void;
  withComment?: boolean;
  requiredComment?: boolean;
  commentPlaceholder?: string;
  commentDescription?: string;
  isSafe?: boolean;
}

const { closePopup, $activePopups } = popupModel;

export const ConfirmPopup: FC<Props> = ({
  reverse,
  popupName,
  title,
  description,
  cancelBtnLabel,
  confirmBtnLabel,
  isConfirmBtnDisabled,
  onConfirm,
  onCancel,
  withComment,
  requiredComment,
  commentPlaceholder,
  commentDescription,
  isSafe,
}): ReactElement => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");

  const activePopups = useUnit($activePopups);

  const cancelLabel: string = cancelBtnLabel || t("common.cancel");

  const canConfirm: boolean = requiredComment ? !!message.trim() : true;

  useEffect(() => {
    activePopups.length && setMessage("");
  }, [activePopups]);

  const onCancelHandler = () => {
    closePopup({ name: popupName });

    setMessage("");

    onCancel && onCancel();
  };

  const onChangeMessageHandler = (e: ChangeEvent) => {
    const { value } = e.target as HTMLTextAreaElement;

    setMessage(value);
  };

  const onConfirmHandler = () => {
    if (canConfirm && !isConfirmBtnDisabled) {
      onConfirm(message);
    }
  };

  return (
    <BasePopup name={popupName} isWithoutPadding>
      <div className="confirm-popup">
        <h2 className="confirm-popup__title">{title}</h2>
        {description && (
          <p className="confirm-popup__description">{description}</p>
        )}
        {withComment && (
          <FormControl
            className="confirm-popup__message"
            description={commentDescription}
          >
            <BaseTextarea
              size="small"
              value={message}
              onChange={onChangeMessageHandler}
              label={commentPlaceholder}
              required={requiredComment}
            />
          </FormControl>
        )}
        <div className="confirm-popup__controls">
          <BaseButton
            primary={reverse}
            secondary={!reverse && !isSafe}
            outline
            block
            large
            onClick={onCancelHandler}
          >
            {cancelLabel}
          </BaseButton>
          <BaseButton
            primary={!reverse}
            secondary={reverse}
            outline={!isSafe}
            block
            large
            noWrapContent
            onClick={onConfirmHandler}
            disabled={!canConfirm}
            isLoading={isConfirmBtnDisabled}
          >
            {confirmBtnLabel}
          </BaseButton>
        </div>
      </div>
    </BasePopup>
  );
};
