import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "./comment-input.scss";
import { useSelector } from "react-redux";
import { selectProfileInfoItem } from "../../../../store/ducks/profile-info";
import { $userId, addCommentFx } from "../../lib/comments";
import { useUnit } from "effector-react";
import { BaseButton } from "../../../base-button/base-button";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const CommentInput: FC = () => {
  const { t } = useTranslation();

  const [comment, setComment] = useState<string>("");

  const [isPending, setIsPending] = useState<boolean>(false);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const areaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (
        !(event.target as HTMLElement)?.closest(".comment-input__visual-area")
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener("click", clickHandler);

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  const userId = useUnit($userId);

  useSelector(selectProfileInfoItem);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
    setIsPending(true);
    addCommentFx({ comment, userId }).finally(() => setIsPending(false));

    setComment("");
  };

  return (
    <div className="comment-input-wrapper">
      <div className="comment-input__avatar">
        <img
          className="comment-input__avatar-image"
          src={`https://website.yandexcloud.net/${process.env.REACT_APP_CDN_URL?.replace(
            /(^\w+:|^)\/\//,
            "",
          )}/avatars/${userId}`}
          alt="avatar"
        />
      </div>
      <div
        className={classNames("comment-input__visual-area", {
          "comment-input__visual-area--focused": isFocused,
        })}
        ref={areaRef}
      >
        <input
          onFocus={() => setIsFocused(true)}
          ref={inputRef}
          value={comment}
          onChange={handleChange}
          className="comment-input__input-invisible"
          placeholder={t("profile.comments.writeComment")}
        />
        <BaseButton
          primary
          xSmallRounded
          className={classNames("comment-input__button", {
            "comment-input__button--visible": isFocused,
          })}
          disabled={!comment.length}
          onClick={handleSubmit}
          isLoading={isPending}
        >
          {t("common.save")}
        </BaseButton>
      </div>
    </div>
  );
};

export default CommentInput;
