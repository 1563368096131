import { useUnit } from "effector-react";
import { FC, useEffect } from "react";
import {
  $specificPermission,
  fetchSpecificPermission,
} from "src/entities/public/permissions/model";
import { UserRights } from "src/features/hr/account/ui/user-rights/user-rights";
import { $keycloak } from "../../../../entities/public/keycloak/model";

interface Props {
  subjectUserId?: string;
  isSubjectHr: boolean;
}

export const RightsSection: FC<Props> = ({ subjectUserId, isSubjectHr }) => {
  const keycloak = useUnit($keycloak);
  const selfUserId: string | undefined = keycloak?.subject;

  const specificPermission = useUnit($specificPermission);

  const clientIdToModal = isSubjectHr ? "hr" : "admin";

  useEffect(() => {
    fetchSpecificPermission({
      pId: selfUserId as string,
      permissionName: "write-permissions",
    });
  }, [selfUserId]);

  return (
    <UserRights
      hasPermission={specificPermission.has_permission}
      userId={subjectUserId}
      clientIdToModal={clientIdToModal}
    />
  );
};
