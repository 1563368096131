import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import stylesUserRights from "../../../entities/public/popups/ui/user-rights-popup/userRightsPopup.module.scss";

import { useUnit } from "effector-react";

import {
  AssessmentAdditionalParametersPopup,
  AssessmentMembersPopup,
  AssessmentParametersPopup,
  AssessmentResultsPopup,
  BaseMessagePopup,
  BaseSnackbar,
  CreateAssessmentPopup,
  WelcomePopup,
} from "src/shared/components";

import {
  BasePopup,
  EPopupName,
  popupModel,
} from "src/shared/components/base-popup";

import { notificationsModel } from "src/entities/public/notifications";

import { markAsRead } from "src/features/public/notification";

import { NotificationCardsOnPage } from "src/features/player/hide-notifications";

import "./main-layout.scss";
import { UserRightsPopup } from "../../../entities/public/popups/ui/user-rights-popup/user-rights-popup";
import { useTranslation } from "react-i18next";
import { FineForInactionPopup } from "../../../pages/profile/my-competence-page/components/fine-for-inaction-popup/fine-for-inaction-popup";
import { InvitationPopup } from "../popups/ invitation-popup/invitation-popup";
import { WaitingRelativeStatus } from "../popups/waiting-relative-status-popup/waiting-relative-status-popup";
import { AllocatePlayerPopup } from "src/pages/sessions/session-page/components/session-table/components/allocate-player-popup/allocate-player-popup";
import { GTPopup } from "../popups/google-translate-popup/google-translate-popup";
import { AgreementConfirmedPopup } from "../popups/agreement-confirmed-popup/agreement-confirmed-popup";

interface Props {}

const { $activePopups } = popupModel;

const { $isShowedPanel } = notificationsModel;

export const MainLayout: FC<Props> = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement => {
  const activePopups = useUnit($activePopups);

  useUnit($isShowedPanel);
  const { t } = useTranslation();
  useCallback(
    (name: EPopupName) => activePopups.some((popup) => name === popup.name),
    [activePopups],
  );

  const userRights = useMemo(
    () =>
      activePopups.find(({ name }) => name === EPopupName.USER_RIGHTS_POPUP),
    [activePopups],
  );

  const isChangeable = userRights?.someBooleanTrigger;

  const readingNotifications = useUnit(markAsRead);

  const onCloseFinePopup = () => {
    readingNotifications();
  };

  return (
    <div className="main-layout">
      {children}

      <BasePopup name={EPopupName.BASE_MESSAGE_POPUP}>
        <BaseMessagePopup />
      </BasePopup>

      <BasePopup
        name={EPopupName.ASSESSMENT_MEMBERS}
        title={t("assessmentsControl.popup.assessmentMembers.title")}
        isWithoutPadding
        isFullHeight
      >
        <AssessmentMembersPopup />
      </BasePopup>

      <BasePopup
        name={EPopupName.ASSESSMENT_RESULT}
        title={t("assessmentsControl.popup.assessmentResults.title")}
        isWithoutPadding
        isFullHeight
      >
        <AssessmentResultsPopup />
      </BasePopup>

      <BasePopup
        name={EPopupName.ASSESSMENT_PARAMETERS}
        title={t("assessmentsControl.popup.assessmentParameters.title")}
      >
        <AssessmentParametersPopup />
      </BasePopup>

      <BasePopup
        name={EPopupName.ASSESSMENT_ADDITIONAL_PARAMETERS}
        title={t(
          "assessmentsControl.popup.assessmentAdditionalParameters.title",
        )}
      >
        <AssessmentAdditionalParametersPopup />
      </BasePopup>

      <BasePopup name={EPopupName.WELCOME} isWithoutPadding>
        <WelcomePopup />
      </BasePopup>

      <BasePopup
        name={EPopupName.GOOGLE_TRANSLATE}
        isWithoutPadding
        isMobileBottom
      >
        <GTPopup />
      </BasePopup>

      <BasePopup
        className={stylesUserRights.user_rights}
        title={
          isChangeable
            ? t("permissions.main.modal.change.title")
            : t("permissions.main.modal.view.title")
        }
        name={EPopupName.USER_RIGHTS_POPUP}
      >
        <UserRightsPopup />
      </BasePopup>

      <BasePopup
        closeButton={false}
        onClose={onCloseFinePopup}
        isTitleWithoutBottomPadding
        className="fine-for-inaction-popup"
        title={t("fine.fineForInaction")}
        name={EPopupName.FINE_FOR_INACTION_POPUP}
      >
        <FineForInactionPopup />
      </BasePopup>

      <InvitationPopup />
      <AgreementConfirmedPopup />
      <WaitingRelativeStatus />

      <BaseSnackbar />

      <NotificationCardsOnPage />

      <BasePopup name={EPopupName.ALLOCATE_PLAYER_POPUP} withoutScroll={true}>
        <AllocatePlayerPopup />
      </BasePopup>

      <CreateAssessmentPopup />
    </div>
  );
};
