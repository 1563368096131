import { combine, sample } from "effector";

import { every } from "patronum";

import { keycloakModel } from "src/entities/public/keycloak";

import { $districts, $regions, $schools } from "./dictionaries";

import { appAccessDomain } from "./domain";

import { $country, $district, $region } from "./profile";

const { $isPlayerClientId } = keycloakModel;

// Stores

export const $regionsReady = appAccessDomain.createStore<boolean>(false);

export const $districtsReady = appAccessDomain.createStore<boolean>(false);

export const $schoolsReady = appAccessDomain.createStore<boolean>(false);

export const $dictionariesReady = every({
  predicate: (ready) => ready,
  stores: [$regionsReady, $districtsReady, $schoolsReady],
});

// Events

export const setRegionsReady = appAccessDomain.createEvent<boolean>();

export const setDistrictsReady = appAccessDomain.createEvent<boolean>();

export const setSchoolsReady = appAccessDomain.createEvent<boolean>();

// Logic

sample({ clock: setRegionsReady, target: $regionsReady });

sample({ clock: setDistrictsReady, target: $districtsReady });

sample({ clock: setSchoolsReady, target: $schoolsReady });

sample({
  clock: combine($regions, $country, (regions, country) => ({
    regions,
    country,
  })),
  fn: ({ regions, country }) => {
    if (country !== null) {
      return country ? regions !== null : true;
    }

    return false;
  },
  target: setRegionsReady,
});

sample({
  clock: combine(
    $districts,
    $country,
    $region,
    (districts, country, region) => ({
      districts,
      country,
      region,
    }),
  ),
  fn: ({ districts, region, country }) => {
    const isEveryNull: boolean = region !== null && country !== null;

    if (isEveryNull) {
      return region && country ? districts !== null : true;
    }

    return false;
  },
  target: setDistrictsReady,
});

sample({
  clock: combine(
    $schools,
    $country,
    $region,
    $district,
    $isPlayerClientId,
    (schools, region, country, district, isPlayerClientId) => ({
      schools,
      region,
      country,
      district,
      isPlayerClientId,
    }),
  ),
  fn: ({ schools, region, country, district, isPlayerClientId }) => {
    if (!isPlayerClientId) {
      return true;
    }

    const isEveryNull: boolean =
      region !== null && country !== null && district !== null;

    if (isEveryNull) {
      return district && region && country ? schools !== null : true;
    }

    return false;
  },
  target: setSchoolsReady,
});
