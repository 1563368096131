import {
  FC,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { usePopper } from "react-popper";
import {
  AutoPlacement,
  BasePlacement,
  VariationPlacement,
} from "@popperjs/core/lib/enums.d";
import { PositioningStrategy } from "@popperjs/core/lib/types.d";

import "./base-tooltip.scss";
import classNames from "classnames";

interface Props {
  tooltipToggleRef: MutableRefObject<null | Element>;
  strategy?: PositioningStrategy;
  position?: AutoPlacement | BasePlacement | VariationPlacement;
  offsetX?: number;
  offsetY?: number;
  mainAxis?: boolean;
  mobileWide?: boolean;
  className?: string;
  children?: ReactNode;
}
export const BaseTooltip: FC<Props> = ({
  tooltipToggleRef,
  strategy = "fixed",
  position = "left-end",
  offsetX = 0,
  offsetY = 0,
  mainAxis = true,
  children,
  mobileWide,
  className,
}): ReactElement | null => {
  const [tooltipRef, setTooltipRef] =
    useState<MutableRefObject<HTMLDivElement> | null>(null);

  const itemRef = useCallback((node: HTMLDivElement) => {
    setTooltipRef({ current: node });
  }, []);

  const { styles, attributes } = usePopper(
    tooltipToggleRef.current,
    tooltipRef?.current,
    {
      strategy: strategy,
      placement: position,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [offsetX, offsetY],
          },
        },
        {
          name: "preventOverflow",
          options: {
            mainAxis,
          },
        },
      ],
    },
  );

  return (
    <div
      className={classNames("base-tooltip", className, {
        "base-tooltip--mobile-wide": mobileWide,
      })}
      ref={itemRef}
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
    </div>
  );
};
