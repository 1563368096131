import axios from "axios";

import {
  AvatarApi,
  Configuration,
  ContactApi,
  ImagesApi,
  ResumeApi,
} from "src/generated/cdn";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_CDN_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_CDN_URL;

    config.headers = {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const makeAvatarRequest = () => {
  return new AvatarApi(new Configuration(), "", apiInstance);
};

const makeContactRequest = () => {
  return new ContactApi(new Configuration(), "", apiInstance);
};

const makeImagesRequest = () => {
  return new ImagesApi(new Configuration(), "", apiInstance);
};

const makeResumeRequest = () => {
  return new ResumeApi(new Configuration(), "", apiInstance);
};

export const cdnService = {
  makeAvatarRequest,
  makeContactRequest,
  makeImagesRequest,
  makeResumeRequest,
};
