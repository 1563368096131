export enum BannerType {
  DEFAULT = "default",
  INFO = "info",
}

export interface ISessionInfoTooltip {
  visible: boolean;
  title: string;
  label?: string;
}

export interface ITeamTooltipItem {
  role: string;
  additionalRole?: string;
  count?: number;
  total?: number;
}

export interface ITeamTooltipSection {
  title?: string;
  list: ITeamTooltipItem[];
}
export interface IRolesList {
  teamId: string;
  maxCount: number;
}
