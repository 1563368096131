import axios from "axios";
import {
  Configuration,
  EmailApi,
  NotifyApi,
  SmsApi,
} from "src/generated/notification";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_URL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_NOTIFICATION_URL;

    config.headers = {
      Accept: "application/json",
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("alabuga-token")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const makeEmailNotificationRequest = () => {
  return new EmailApi(new Configuration(), "", apiInstance);
};

const makeSmsNotificationRequest = () => {
  return new SmsApi(new Configuration(), "", apiInstance);
};

const makeNotificationRequest = () => {
  return new NotifyApi(new Configuration(), "", apiInstance);
};

export const notificationService = {
  apiInstance,
  makeEmailNotificationRequest,
  makeSmsNotificationRequest,
  makeNotificationRequest,
};
