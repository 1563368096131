import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import {
  AssessmentType,
  PlayerTutorialHistoryDto,
  SimulationType,
} from "src/generated/game";

import { DataGrid } from "src/shared/components/index";

import useClient from "src/shared/hooks/useClient";

import { getActiveGroup, getOrderByValue } from "src/shared/helpers";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  IDataGridSelectOption,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";

interface Props {
  historyItems: PlayerTutorialHistoryDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  autoScrollDisabled?: boolean;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

const { openPopup } = popupModel;

export const TutorialHistory: FC<Props> = ({
  loading,
  historyItems,
  queryParams,
  autoScrollDisabled,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { isHrClientId, isAdminClientId } = useClient();

  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const assessmentTypes = useMemo(() => Object.values(AssessmentType), []);

  const simulationTypes = useMemo(() => Object.values(SimulationType), []);

  const { id } = useParams<{ id: string }>();

  const assessmentTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return assessmentTypes.map((type) => ({
      value: type,
      label: t(`common.assessment.${type}`, type),
    }));
  }, [assessmentTypes, t]);

  const simulationTypeItems = useMemo<IDataGridSelectOption[]>(() => {
    return simulationTypes.map((type) => ({
      value: type,
      label: t(`common.simulation.${type}`, type),
    }));
  }, []);

  const showAssessmentResults = useCallback(
    (rowId: string): void => {
      if (id) {
        openPopup({
          name: EPopupName.ASSESSMENT_RESULT,
          dataId: [
            { name: EPopupDataIdName.DEFAULT, value: rowId },
            { name: EPopupDataIdName.VIEWED_USER, value: id },
          ],
        });
      }
    },
    [id],
  );

  const showAssessmentParameters = useCallback((rowId: string): void => {
    openPopup({
      name: EPopupName.ASSESSMENT_PARAMETERS,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
      someBooleanTrigger: true,
    });
  }, []);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        title: t("table.header.mode"),
        type: EDataGridColumnType.STRING,
        key: "simulationType",
        showed: true,
        filterTitle: t("table.header.mode"),
        controlPanel: {
          title: t("table.header.mode"),
          activeGroupKey: getActiveGroup(
            ["simulation_type"],
            { all: ["simulation_type"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "simulationType",
                  type: EDataGridFilterType.CHECKBOX,
                  items: simulationTypeItems,
                  value: queryParams?.simulationType || "",
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.chapters"),
        type: EDataGridColumnType.STRING,
        key: "simulationName",
        showed: true,
        noPaddingLeft: true,
        filterTitle: t("table.header.chapters"),
        controlPanel: {
          title: t("table.header.chapters"),
          activeGroupKey: getActiveGroup(
            ["chapterNumber"],
            { all: ["chapterNumber"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "chapterNumber",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.chapterNumber || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "chapterNumber",
                  "-chapterNumber",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "chapter_number",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-chapter_number",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.attempts"),
        filterTitle: t("table.header.attempts"),
        filterPosition: EDataGridFilterPosition.LEFT,
        type: EDataGridColumnType.STRING,
        key: "attempts",
        showed: true,
        controlPanel: {
          title: t("table.header.attempts"),
          activeGroupKey: getActiveGroup(
            ["attempts", "attemptsFrom", "attemptsTo"],
            { all: ["attempts", "attemptsFrom", "attemptsTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "attemptFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.attemptsFrom || "",
                  isHalf: true,
                },
                {
                  key: "attemptTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.attemptsTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "attempts",
                  "-attempts",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "attempts",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-attempts",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.completed"),
        filterTitle: t("table.header.completed"),
        filterPosition: EDataGridFilterPosition.LEFT,
        type: EDataGridColumnType.DATETIME,
        key: "completed",
        showed: true,
        controlPanel: {
          title: t("table.header.completed"),
          activeGroupKey: getActiveGroup(
            ["completed"],
            { all: ["completed"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "date_of_complete_from",
                  type: EDataGridFilterType.DATE,
                  isHalf: true,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.date_of_complete_from || "",
                },
                {
                  key: "date_of_complete_to",
                  type: EDataGridFilterType.DATE,
                  isHalf: true,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.date_of_complete_to || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "date_of_complete",
                  "-date_of_complete",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.date.asc"),
                    value: "date_of_complete",
                  },
                  {
                    title: t("table.filters.date.desc"),
                    value: "-date_of_complete",
                  },
                ],
              },
            },
          ],
        },
      },
    ];

    return newColumns;
  }, [
    queryParams,
    showAssessmentParameters,
    showAssessmentResults,
    isHrClientId,
    isAdminClientId,
    assessmentTypeOptions,
    t,
  ]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = historyItems.map((item): IDataGridRow => {
      return {
        id: item.history_id || "",
        selected: false,
        simulationName: t(
          `common.chapter.${item.simulation_type}.${item.chapter_number}`,
        ),
        attempts: item.attempts,
        simulationType: t(`common.simulation.${item.simulation_type}`),
        completed: item.date_of_complete,
      };
    });

    setRows(rowsItems);
  }, [historyItems, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"session-history-table"}
      queryParams={queryParams}
      autoScrollDisabled={autoScrollDisabled}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
