import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BaseInputNumber, FormControl } from "..";
import { TMinMaxPlayersGameTypeByRoles } from "../../helpers/getMinMaxPlayersGameType";
import { languagePicker } from "../../helpers/languagePicker";
import { IBaseInputChange } from "../../models/base-input";
import { IGameType } from "../../store/types";

interface Props {
  role: string;
  minMaxCountGameType: TMinMaxPlayersGameTypeByRoles;
  selectedGameType?: IGameType;
  isTeam: boolean;
  minMaxCounts: Record<string, string>;
  minMaxCountsErrors: Record<string, string>;
  onChange: (payload: IBaseInputChange) => void;
}

export const MinMaxCountField: FC<Props> = ({
  role,
  minMaxCountGameType,
  selectedGameType,
  isTeam,
  minMaxCounts,
  minMaxCountsErrors,
  onChange,
}): ReactElement => {
  const { t, i18n } = useTranslation();

  const countGameType = minMaxCountGameType[role];

  const roleTitle = selectedGameType?.teams || selectedGameType?.roles;

  const roleLabel: string = languagePicker(
    roleTitle?.[role].title,
    i18n.language,
  );

  const placeholderLabel: string = t(
    `popup.createAssessment.field.membersCount.${isTeam ? "team" : "solo"}`,
  );

  const placeholderCount: string = `(${countGameType?.min}-${countGameType?.max})`;

  const placeholder: string = `${placeholderLabel} "${roleLabel}" ${placeholderCount}`;

  return (
    <FormControl key={role} error={minMaxCountsErrors[role]}>
      <BaseInputNumber
        error={!!minMaxCountsErrors[role]}
        placeholder={placeholder}
        value={minMaxCounts[role]}
        onChange={onChange}
        name={role}
        min={countGameType?.min}
        max={countGameType?.max}
      />
    </FormControl>
  );
};
