import { FC } from "react";

import { EPopupName, popupModel } from "src/shared/components/base-popup";
import { ConfirmPopup } from "src/shared/components";
import { useTranslation } from "react-i18next";
import { finesModel } from "src/entities/admin/fines";
import { useUnit } from "effector-react";
import { $activePopups } from "src/shared/components/base-popup/model";
import { snackbarOpen } from "src/features/public/snackbar";

const { closePopup } = popupModel;
export const AddAnotherFinePopup: FC = () => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const currentPopup = activePopups.find(
    ({ name }) => name === EPopupName.ADD_ANOTHER_FINES_POPUP,
  );

  const onConfirm = () => {
    closePopup({ name: EPopupName.ADD_ANOTHER_FINES_POPUP });
    if (currentPopup) {
      finesModel.postFines({
        fines: { fines: currentPopup.data.validFines },
      });
      snackbarOpen({
        text: t("fine.newFineAdded"),
        type: "success",
        visible: true,
      });
    }
  };

  return (
    <ConfirmPopup
      popupName={EPopupName.ADD_ANOTHER_FINES_POPUP}
      title={t("fine.addNewFine")}
      cancelBtnLabel={t("common.cancel")}
      description={t("fine.addFine.desc")}
      confirmBtnLabel={t("permission.main.button.confirm")}
      onConfirm={onConfirm}
    />
  );
};
