import React, { FC, ReactElement, useCallback, useEffect } from "react";

import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useUrlState } from "src/shared/hooks/useUrlState";

import { PlayerFineHistoryApiGetPlayerFineHistoryRequest } from "../../../generated/social";

import "./fines-history-page.scss";

import { TDataGridDataParams } from "../../../shared/models";

import { requestSessionsHistoryRoles } from "../../../shared/store/ducks/sessions-history/actionCreators";

import { PageWrapper } from "src/shared/components";

import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";

import { FinesHistory } from "../../../shared/components/data-grids/histories/fines-history";
import {
  $fineItems,
  $finePagination,
  $isLoadingFines,
  requestFinesHistory,
} from "../../../shared/components/session-history-player/lib/player-history";
import { showDataGrid } from "../../../shared/helpers/showDataGrid";
import { useParams } from "react-router-dom";

export const FinesHistoryPage: FC = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();

  const finesItems = useUnit($fineItems);

  const finesPagination = useUnit($finePagination);

  const isLoading: boolean = useUnit($isLoadingFines);

  useEffect(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      date_of_issue_to,
      date_of_issue_from,
      competence,
      removableRatingFrom,
      removableRatingTo,
      periodFrom,
      periodTo,
      firstnameCreator,
      lastnameCreator,
    } = urlState.query;

    const finesParams: PlayerFineHistoryApiGetPlayerFineHistoryRequest = {
      pId: urlState.params.id,
      firstnameCreator: firstnameCreator,
      lastnameCreator: lastnameCreator,
      pageSize: pageSize ? Number(pageSize) : 50,
      pageNum: pageNum ? Number(pageNum) : 1,
      orderBy: orderBy ? orderBy : undefined,
      dateOfIssueFrom: date_of_issue_from,
      dateOfIssueTo: date_of_issue_to,
      competence: competence ? competence.split(",") : undefined,
      removableRatingFrom: removableRatingFrom
        ? Number(removableRatingFrom)
        : undefined,
      removableRatingTo: removableRatingTo
        ? Number(removableRatingTo)
        : undefined,
      periodFrom: periodFrom ? Number(periodFrom) : undefined,
      periodTo: periodTo ? Number(periodTo) : undefined,
    };

    requestFinesHistory(finesParams);
  }, [urlState.params.id, urlState.query]);

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  return (
    <PageWrapper
      title={t("fineHistory.title")}
      backButton
      isShowContentWhenIsLoading
      emptyPanel
      isLightBlueBackground
      isLoadingPanel={isLoading}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
    >
      <div className="fines-history-data-grid-wrapper">
        {showDataGrid(finesItems, urlState) ? (
          <FinesHistory
            historyItems={finesItems}
            autoScrollDisabled={true}
            queryParams={urlState.query}
            onUpdateSortAndFilters={onUpdateQueryParams}
            loading={isLoading}
            pagination={finesPagination}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum)}
          />
        ) : (
          <span className="fines-history-data-grid-wrapper-without-fines">
            {t("fine.userDontHaveFines")}
          </span>
        )}
      </div>
    </PageWrapper>
  );
};
