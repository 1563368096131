import React, { FC, ReactElement, useCallback, useEffect } from "react";

import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import { useUrlState } from "src/shared/hooks/useUrlState";

import "./complaints-history-page.scss";

import { TDataGridDataParams } from "../../../shared/models";

import { PageWrapper } from "src/shared/components";

import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";

import {
  $complaintsItems,
  $complaintsPagination,
  $isLoadingFines,
  requestComplaintsHistory,
} from "../../../shared/components/session-history-player/lib/player-history";
import { showDataGrid } from "../../../shared/helpers/showDataGrid";
import { ComplaintsHistory } from "../../../shared/components/data-grids/complaints/complaints-history";
import { PlayersApiGetPlayerComplaintsRequest } from "../../../generated/social";
import { getDateForFilter } from "../../../shared/helpers";

export const ComplaintsHistoryPage: FC = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();

  const complaintsItems = useUnit($complaintsItems);

  const complaintsPagination = useUnit($complaintsPagination);

  const isLoading: boolean = useUnit($isLoadingFines);

  useEffect(() => {
    const {
      pageNum,
      pageSize,
      assTitle,
      orderBy,
      fromDateOfCreate,
      toDateOfCreate,
    } = urlState.query;

    const complaintsParams: PlayersApiGetPlayerComplaintsRequest = {
      pId: urlState.params.id,
      pageSize: pageSize ? Number(pageSize) : 50,
      pageNum: pageNum ? Number(pageNum) : 1,
      orderBy: orderBy ? orderBy.split(",") : undefined,
      fromDateOfCreate: fromDateOfCreate
        ? getDateForFilter(fromDateOfCreate)
        : undefined,
      toDateOfCreate: toDateOfCreate
        ? getDateForFilter(toDateOfCreate)
        : undefined,
      assTitle: assTitle,
    };

    requestComplaintsHistory(complaintsParams);
  }, [urlState.params.id, urlState.query]);

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  return (
    <PageWrapper
      title={t("complaintsHistory.title")}
      backButton
      isShowContentWhenIsLoading
      emptyPanel
      isLightBlueBackground
      isLoadingPanel={isLoading}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
    >
      <div className="complaints-history-data-grid-wrapper">
        {showDataGrid(complaintsItems, urlState) ? (
          <ComplaintsHistory
            complaintItems={complaintsItems}
            autoScrollDisabled={true}
            queryParams={urlState.query}
            onUpdateSortAndFilters={onUpdateQueryParams}
            loading={isLoading}
            pagination={complaintsPagination}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum)}
          />
        ) : (
          <span className="complaints-history-data-grid-wrapper-without-complaints">
            {t("complaints.userDontHaveComplaints")}
          </span>
        )}
      </div>
    </PageWrapper>
  );
};
