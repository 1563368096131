import { createEvent, createStore, sample } from "effector";

export const $relativeId = createStore<string>("");

export const setRelativeId = createEvent<string>();

sample({
  clock: setRelativeId,
  target: $relativeId,
});
