import { FC, memo, ReactElement, ReactNode } from "react";
import classNames from "classnames";

import { pxToRem } from "../../../helpers";
import { EDataGridFilterPosition } from "../../../models";

interface Props {
  title?: string;
  minWidth?: number;
  showed?: boolean;
  position?: EDataGridFilterPosition;
  withoutOffsetX?: boolean;
  smallPadding?: boolean;
  withScroll?: boolean;
  children?: ReactNode;
}

const DataGridDropdownComponent: FC<Props> = ({
  title,
  minWidth,
  showed,
  position = EDataGridFilterPosition.RIGHT,
  withoutOffsetX,
  withScroll,
  children,
  smallPadding,
}): ReactElement => {
  return (
    <div
      className={classNames("data-grid__dropdown", {
        "data-grid__dropdown--showed": showed,
        "data-grid__dropdown--left": position === EDataGridFilterPosition.LEFT,
        "data-grid__dropdown--without-offset_right":
          withoutOffsetX && position === EDataGridFilterPosition.LEFT,
        "data-grid__dropdown--without-offset_left":
          withoutOffsetX && position === EDataGridFilterPosition.RIGHT,
        "data-grid__dropdown--small-padding": smallPadding,
        "data-grid__dropdown--with-scroll": withScroll,
      })}
      style={{ minWidth: minWidth ? pxToRem(minWidth) : undefined }}
    >
      {title && <div className="data-grid__dropdown-title">{title}</div>}
      {children && (
        <div className="data-grid__dropdown-content">{children}</div>
      )}
    </div>
  );
};

export const DataGridDropdown = memo<FC<Props>>(DataGridDropdownComponent);
