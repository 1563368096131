import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useUrlState } from "src/shared/hooks/useUrlState";

import { useUnit } from "effector-react";

import {
  BaseButton,
  NotificationsMailingListTable,
  PageWrapper,
  TextWithIcon,
} from "src/shared/components";

import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";

import { TDataGridDataParams } from "src/shared/models/dataGrid";

import { readMoreRequest } from "src/shared/helpers/readmoreRequest";

import { useUpdatedQueryParams } from "src/shared/hooks/useUpdatedQueryParams";

import { notificationModel } from "src/entities/admin/notification";

import "./notifications-mailing-lists-page.scss";

const { $notificationList, $isLoading, resetQueryParams } = notificationModel;

export const NotificationsMailingListsPage = () => {
  const { t } = useTranslation();

  const [urlState, setUrlState] = useUrlState();

  const notificationList = useUnit($notificationList);

  const loading = useUnit($isLoading);

  const { updatedQueryParams } = useUpdatedQueryParams();

  const updateQueryParams = (params: TDataGridDataParams) =>
    setUrlState({
      ...urlState,
      query: params,
    });

  useEffect(() => {
    return () => resetQueryParams();
  }, []);

  useEffect(() => {
    readMoreRequest(
      updatedQueryParams,
      notificationModel.readmoreNotifications,
      notificationModel.fetchNotificationList,
    );
  }, [updatedQueryParams]);

  return (
    <div>
      <PageWrapper
        title={t("navigation.mailingLists")}
        isLightBlueBackground
        isShowContentWhenIsLoading
        controlPanelTitle={t("mailingLists.controlPanel.label.mailings")}
        controlPanelMaxCount={notificationList.length}
        isLoadingPanel={loading}
        titlePanelSlot={
          <div className="table__control">
            <div className="table__control-btn table__control-btn--hide-on-not-mobile">
              <DataGridMobileSortButton />
            </div>
            <div className="table__control-btn table__control-btn--hide-on-not-mobile">
              <DataGridMobileFiltersButton />
            </div>
          </div>
        }
        controlPanelSlot={
          <BaseButton
            small
            outline
            to={"/mailing-lists/newsletter-form?type=notification"}
          >
            <TextWithIcon
              label={t("navigation.mailingLists.btn.newNewsletter")}
              iconName="plus-blue"
            />
          </BaseButton>
        }
      >
        <div className="notifications-mailing-lists-page">
          <NotificationsMailingListTable
            queryParams={urlState.query}
            updateQueryParams={updateQueryParams}
          />
        </div>
      </PageWrapper>
    </div>
  );
};
