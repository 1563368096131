import classNames from "classnames";
import { FC, Fragment, ReactElement } from "react";
import { DataGrid } from "../..";
import { IDataGridColumn, IDataGridRow } from "../../../models/dataGrid";
import { GridAccordion } from "../grid-accordion/grid-accordion";
import { GridColumnItem } from "../grid-column-item/grid-column-item";
import "./team-results-grid.scss";
import { useTranslation } from "react-i18next";

interface Props {
  columns: IDataGridColumn[];
  teams: ITeamGridItem[];
  className?: string;
  getTeamRoleTitle: (role: string) => string;
}

export interface ITeamGridItem {
  place?: number;
  teamNum: number;
  role: string;
  rows: IDataGridRow[];
}

export const TeamResultsGrid: FC<Props> = ({
  columns,
  teams,
  className,
  getTeamRoleTitle,
}): ReactElement => {
  const { t } = useTranslation();

  const headerItems = columns.map((column, index) => (
    <GridColumnItem
      key={index + column.title}
      column={column}
      showed={column.showed}
      className={classNames(
        "team-results-grid-header__item",
        column.textAlign
          ? `team-results-grid-header__item--text-align_${column.textAlign}`
          : undefined,
      )}
    >
      {column.title}
    </GridColumnItem>
  ));

  const accordionHeader = (team: ITeamGridItem) => {
    const className: string = "team-results-grid__accordion-header-item";

    const nameTitle: string = `${t("common.team")} ${team.teamNum}`;

    return (
      <div className="team-results-grid__accordion-header">
        <GridColumnItem column={columns[0]} className={className}>
          {nameTitle}
        </GridColumnItem>
        <GridColumnItem column={columns[1]} className={className}>
          {team.place || ""}
        </GridColumnItem>
        <GridColumnItem column={columns[2]} className={className}>
          {getTeamRoleTitle(team.role)}
        </GridColumnItem>
      </div>
    );
  };

  const accordionsItems = teams.map((team, index) => (
    <GridAccordion
      isShowed
      key={team.role + index}
      className="team-results-grid__accordion"
      headerSlot={accordionHeader(team)}
    >
      <DataGrid
        className="team-results-grid__table"
        columns={columns}
        rows={team.rows}
        autoScrollDisabled
        hiddenHeader
      />
    </GridAccordion>
  ));

  return (
    <Fragment>
      {!!teams.length && (
        <div className={classNames("team-results-grid", className)}>
          <div className="team-results-grid-header">{headerItems}</div>
          {accordionsItems}
        </div>
      )}
    </Fragment>
  );
};
