import axios from "axios";
import { UserInfoInterface } from "src/shared/store/types";

export const putUserInfoAdmin = async (payload: {
  id: string;
  data: UserInfoInterface;
}): Promise<void> => {
  await axios.put(
    `${process.env.REACT_APP_KC_URL}/realms/${process.env.REACT_APP_KC_REALM}/albAdmin/users/${payload.id}`,
    payload.data,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("alabuga-token"),
      },
    },
  );
};
