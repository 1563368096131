import { FC, ReactElement, useMemo } from "react";
import classNames from "classnames";

import { getAvatarUrl } from "../../helpers/pathToUserFile";
import { pxToRem } from "../../helpers/pxToRem";

import "./base-avatar.scss";
import useClient from "../../hooks/useClient";

export interface IBaseAvatarProps {
  className?: string;
  size?: number;
  userId?: string;
  alt?: string;
  customPath?: string;
  borderRadius?: string | number;
  outlineType?: boolean;
  isDrag?: boolean;
}

export const BaseAvatar: FC<IBaseAvatarProps> = ({
  className,
  size,
  userId,
  alt,
  customPath,
  borderRadius,
  outlineType,
  isDrag = false,
}): ReactElement => {
  const { isAdminClientId, isHrClientId } = useClient();

  const cssBorderRadius = useMemo<string | undefined>(() => {
    if (typeof borderRadius === "string" && borderRadius.includes("%")) {
      return borderRadius;
    }

    if (typeof borderRadius === "string" && borderRadius.includes("px")) {
      return pxToRem(parseFloat(borderRadius));
    }

    if (typeof borderRadius === "number") {
      return pxToRem(borderRadius);
    }
  }, [borderRadius]);

  return (
    <img
      className={classNames(
        "base-avatar",
        className,
        outlineType
          ? {
              "base-avatar__admin": isAdminClientId,
              "base-avatar__hr": isHrClientId,
            }
          : null,
      )}
      src={customPath || getAvatarUrl(userId, isDrag)}
      style={{
        minWidth: size ? pxToRem(size) : undefined,
        width: size ? pxToRem(size) : undefined,
        height: size ? pxToRem(size) : undefined,
        borderRadius: cssBorderRadius,
      }}
      alt={alt}
    />
  );
};
