import classNames from "classnames";
import { CSSProperties, FC, ReactElement, ReactNode } from "react";
import { pxToRem } from "../../../helpers/pxToRem";
import { IDataGridColumn } from "../../../models/dataGrid";
import "./grid-column-item.scss";

interface Props {
  column: IDataGridColumn;
  className?: string;
  showed?: boolean;
  children?: ReactNode;
}

export const GridColumnItem: FC<Props> = ({
  column,
  className,
  showed = true,
  children,
}): ReactElement | null => {
  const { key, minWidth, width, noPaddingLeft, noPaddingRight } = column;

  const styles: CSSProperties = {
    minWidth: minWidth ? pxToRem(minWidth) : undefined,
    width: width ? pxToRem(width) : undefined,
  };

  return showed ? (
    <div
      key={key}
      className={classNames("grid-column-item", className, {
        "grid-column-item--no-padding-left": noPaddingLeft,
        "grid-column-item--no-padding-right": noPaddingRight,
      })}
      style={styles}
    >
      {children}
    </div>
  ) : null;
};
