import { useLocation } from "react-router-dom";

export function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  const getQuery = (queryParam: string): string | null => {
    return query.get(queryParam);
  };

  const getAllQuery = (): { [key: string]: string } => {
    const queryObject: { [key: string]: string } = {};

    query.forEach((value, key) => {
      queryObject[key] = value;
    });

    return queryObject;
  };

  const getAllQueryAsString = (): string => {
    return query.toString();
  };

  const setQuery = (queryParam: string, value: string) => {
    return query.set(queryParam, value);
  };

  const deleteQuery = (queryParam: string) => {
    return query.delete(queryParam);
  };

  return {
    query,
    getQuery,
    setQuery,
    deleteQuery,
    getAllQuery,
    getAllQueryAsString,
  };
}
