import { FC, ReactElement } from "react";
import { format } from "date-fns";

interface Props {
  time: number;
}

export const BaseCoutdown: FC<Props> = ({ time }): ReactElement => {
  return <div className="base-timer">{format(time * 1000, "mm:ss")}</div>;
};
