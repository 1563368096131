import { CSSProperties } from "react";

import { pxToRem } from "../../helpers/pxToRem";

import {
  IDataGridColumn,
  IDataGridColumnSticky,
  IDataGridPositionByDeviceType,
} from "../../models/dataGrid";

export const getColumnStyles = (
  column: IDataGridColumn,
  isMobile = false,
  isTablet = false,
  isLaptop = false,
  options: { hasWidthStyles?: boolean } = {}
): CSSProperties => {
  const { sticky } = column;

  const styles: CSSProperties = {};

  if (sticky) {
    let deviceType: keyof IDataGridPositionByDeviceType = "mobile";

    if (isMobile) {
      deviceType = "mobile";
    }

    if (isTablet) {
      deviceType = "tablet";
    }

    if (isLaptop) {
      deviceType = "laptop";
    }

    const stickyParams: Array<keyof IDataGridColumnSticky> = [
      "left",
      "right",
      "width",
    ];

    styles.zIndex = 2;

    stickyParams.forEach((param) => {
      if (sticky?.[deviceType]?.[param] !== undefined) {
        styles[param] = pxToRem(sticky?.[deviceType]?.[param] || 0);
      }
    });
  }

  if (options.hasWidthStyles) {
    styles.width = column.width;
    styles.minWidth = column.minWidth;
  }

  return styles;
};
