import { ERegEx, regExList } from "../constants/regExList";

export const isStringEmpty = (string: string): boolean => {
  return !string.trim();
};

/*
 * isStringTextEditorEmpty является проверной на пустую строку
 * у компонента TextEditor, т.к. при визуельно пустом поле, там
 * всё же остаются теги, которые не являются пустой строкой
 */
export const isStringTextEditorEmpty = (string: string): boolean => {
  return !string.replace(regExList[ERegEx.HTML_TAGS], "").trim();
};
