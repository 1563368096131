import {
  CalcType,
  Competence,
  GameType,
  TestResult,
} from "../../generated/game";

export const getErrorFormula = (
  formulaItems: TestResult[],
  options?: {
    calcType?: CalcType;
    gameType?: GameType;
    competence?: Competence;
  },
): TestResult | undefined =>
  formulaItems.find((result) => {
    const isGameType: boolean = options?.gameType
      ? result.gType === options?.gameType
      : true;

    const isCalcType: boolean = options?.calcType
      ? result.calcType === options?.calcType
      : true;

    const isCompetence: boolean = options?.competence
      ? result.competence === options?.competence
      : true;

    const resultsItem =
      result.rAvails || result.rDislikes || result.rRewards || result.rRoles;

    const hasError: boolean = !!resultsItem?.some(
      ({ err }) => err !== undefined,
    );

    return isCalcType && isGameType && isCompetence && hasError;
  });
