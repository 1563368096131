import { Competence } from "src/generated/statistic";

import { getCoefficientText } from "src/shared/helpers/getCoefficientText";

import { IGameType } from "src/shared/store/types";

import {
  IDislikeItem,
  TDislikeTooltipAssessmentResults,
  TDislikeTooltipFactory,
} from "../model/types";

import { DislikeInfo } from "../ui";

import { getDislikesSumByCompetence } from "./getDislikesSumByCompetence";

export const dislikeTooltipFactory = (
  dislikes: IDislikeItem[] = [],
  gameType: IGameType,
  competenceArr: Competence[],
  results?: TDislikeTooltipAssessmentResults,
): TDislikeTooltipFactory => {
  return competenceArr.reduce(
    (final: TDislikeTooltipFactory, currCompetence) => {
      const competenceDislikeSum: string = getCoefficientText(
        getDislikesSumByCompetence(dislikes, currCompetence),
      );

      const hasCompetenceDislikes: boolean = !!dislikes?.some(({ diffs }) =>
        diffs.some(({ competence }) => competence === currCompetence),
      );

      return {
        ...final,
        [currCompetence]: {
          content: hasCompetenceDislikes
            ? {
                content: (
                  <DislikeInfo
                    assessmentResult={results?.[currCompetence]}
                    competence={currCompetence}
                    dislikes={dislikes}
                    gameType={gameType}
                    dislikesSum={competenceDislikeSum}
                  />
                ),
              }
            : undefined /* TODO: Do solution without undefined */,
          dislikesSum: competenceDislikeSum,
        },
      };
    },
    {},
  );
};
