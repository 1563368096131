import { createEvent, createStore, sample } from "effector";

export const $isDraft = createStore<boolean>(false);

export const setIsDraft = createEvent<boolean>();

sample({
  clock: setIsDraft,
  fn: (isDraft) => isDraft,
  target: $isDraft,
});
