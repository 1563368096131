import { createEvent, createStore, createEffect, sample } from "effector";
import { ERequestStatus, IAssessmentItemAdmin } from "src/shared/store/types";
import {
  AssessmentItemAdminStateInterface,
  IRequestAssessmentItemAdmin,
} from "./types";
import { AdmAssessmentResDto } from "src/generated/game";
import { getAdmAssessment } from "src/shared/api/public/adm-assessment";

// Stores

export const $assessmentItemAdmin =
  createStore<AssessmentItemAdminStateInterface>({
    item: null,
    status: ERequestStatus.NEVER,
  });

// Events

export const requestAssessmentItemAdmin =
  createEvent<IRequestAssessmentItemAdmin>();
export const failureAssessmentItemAdmin = createEvent();
export const successAssessmentItemAdmin = createEvent<AdmAssessmentResDto>();
export const changeAssessmentItemAdmin = createEvent<IAssessmentItemAdmin>();

// Effects

export const fetchAssessmentItemAdminFx = createEffect(getAdmAssessment);

// Logic

$assessmentItemAdmin.on(requestAssessmentItemAdmin, (state) => ({
  ...state,
  status: ERequestStatus.LOADING,
}));

$assessmentItemAdmin.on(successAssessmentItemAdmin, (state, payload) => ({
  ...state,
  item: payload.assessment as IAssessmentItemAdmin,
  status: ERequestStatus.LOADED,
}));

$assessmentItemAdmin.on(failureAssessmentItemAdmin, (state) => ({
  ...state,
  status: ERequestStatus.ERROR,
}));

$assessmentItemAdmin.on(changeAssessmentItemAdmin, (state, payload) => ({
  ...state,
  item: payload,
  status: ERequestStatus.LOADED,
}));

sample({
  source: requestAssessmentItemAdmin,
  target: fetchAssessmentItemAdminFx,
});

sample({
  source: fetchAssessmentItemAdminFx.doneData,
  target: successAssessmentItemAdmin,
});

sample({
  clock: fetchAssessmentItemAdminFx.fail,
  target: failureAssessmentItemAdmin,
});
