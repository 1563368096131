import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

import { ELanguage } from "../store/types";

export const defaultLanguage: string =
  process.env.REACT_APP_DEFAULT_LANGUAGE || ELanguage.RU;

export const languageList: string[] = ["ru", "en"];

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}.json", // react-app/public/locales
    },
    lng: localStorage.getItem("language") || defaultLanguage,
    fallbackLng: [defaultLanguage],

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
