import { useUnit } from "effector-react";
import {
  $companyStatuses,
  $employmentStatuses,
  $rejectionReasons,
} from "src/entities/public/rating-statuses/model";
import {
  countriesToSelectItemsAdapter,
  desiredSpecialityItemsAdapter,
  districtsToSelectItemsAdapter,
  regionsToSelectItemsAdapter,
  schoolsToSelectItemsAdapter,
} from "src/features/public/user-form/lib";
import { QueryParams } from "src/shared/api/base";
import { EDesiredStudyPlace, SelectItem } from "src/shared/components";
import { getActiveGroup, getOrderByValue } from "src/shared/helpers";
import i18n from "src/shared/i18n/i18n";
import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  IDataGridColumn,
} from "src/shared/models";
import { Statuses } from "src/shared/store/types";
import { $places } from "../../../../features/admin/player-ratings/model/data-grid";
import { RoleInRelatedProjectEnum } from "src/generated/social";
import { Competence } from "../../../../generated/statistic";
import { isMainCompetence } from "../../../../shared/helpers/isMainCompetence";

export const useGenerateRatingsColumns = () => {
  const companyStatuses = useUnit($companyStatuses);

  const rejectionReasons = useUnit($rejectionReasons);

  const employmentStatusesListForHeader = useUnit($employmentStatuses);

  const places = useUnit($places);

  const groupCodeOptions = [
    {
      label: i18n.t("common.selectAll"),
      value: "true",
    },
    {
      label: i18n.t("common.empty"),
      value: "false",
    },
  ];

  const desiredStudyPlaceList = Object.values(EDesiredStudyPlace);

  const desiredStudyPlaceOptions = desiredStudyPlaceList.map((item) => ({
    label: i18n.t(`userForm.select.desiredStudyPlace.${item}`),
    value: item,
  }));

  const confirmationSingleOptions = [
    {
      label: i18n.t("common.have"),
      value: "true",
    },
    {
      label: i18n.t("common.dontHave"),
      value: "false",
    },
  ];

  const roleInRelatedProjectsItems = Object.values(
    RoleInRelatedProjectEnum,
  ).map((role) => ({
    label: i18n.t(`table.users.rolesInRelatedProjects.${role}`),
    value: role,
  }));

  const emptyItems: SelectItem[] = [
    {
      label: i18n.t("common.empty"),
      value: "true",
    },
  ];

  const serializedCompanyEmploymentStatusesHeader = (status: Statuses) => {
    switch (status) {
      case Statuses.COMPANY:
        return companyStatuses.statusesCompany.map((status) => ({
          label: i18n.t(`table.ratings.statusCompany.${status}`),
          value: `${status}`,
        }));

      case Statuses.REJECTION:
        return rejectionReasons.rejectionReasons.map((reason) => ({
          label: i18n.t(`table.ratings.rejectionReason.${reason}`),
          value: `${reason}`,
        }));

      case Statuses.EMPLOYMENT:
        return employmentStatusesListForHeader.statusesEmployment.map(
          (status) => ({
            label: i18n.t(`table.ratings.statusEmployment.${status}`),
            value: `${status}`,
          }),
        );
    }
  };

  return (queryParams: QueryParams): IDataGridColumn[] => [
    {
      title: "#",
      type: EDataGridColumnType.STRING,
      key: "indexNumber",
      showed: true,
      noPaddingLeft: true,
    },
    {
      title: i18n.t("table.header.position"),
      type: EDataGridColumnType.STRING,
      key: "gPlace",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.position"),
        activeGroupKey: getActiveGroup(
          ["gPlace", "gPlaceFrom", "gPlaceTo"],
          { all: ["gPlace", "gPlaceFrom", "gPlaceTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "gPlaceFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.gPlaceFrom || "",
              },
              {
                key: "gPlaceTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.gPlaceTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "gPlace",
                "-gPlace",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "gPlace",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-gPlace",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.player"),
      type: EDataGridColumnType.AVATAR,
      key: "fullName",
      showed: true,
      controlPanel: {
        title: i18n.t("table.header.player"),
        activeGroupKey: getActiveGroup(
          ["firstName", "lastName"],
          { all: ["firstName", "lastName"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "firstName",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("table.header.name"),
                value: queryParams?.firstName || "",
              },
              {
                key: "lastName",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("table.header.lastName"),
                value: queryParams?.lastName || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "firstName",
                "-firstName",
                "lastName",
                "-lastName",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc.name"),
                  value: "firstName",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc.name"),
                  value: "-firstName",
                },
                {
                  title: i18n.t("table.filters.alphabet.asc.surname"),
                  value: "lastName",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc.surname"),
                  value: "-lastName",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("userForm.inputLabel.gender"),
      type: EDataGridColumnType.STRING,
      key: "gender",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("userForm.inputLabel.gender"),
        activeGroupKey: getActiveGroup(
          ["gender"],
          { all: ["gender"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "gender",
                "-gender",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.asc.gender"),
                  value: "gender",
                },
                {
                  title: i18n.t("table.filters.desc.gender"),
                  value: "-gender",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.phone", "Phone"),
      type: EDataGridColumnType.PHONE,
      key: "phone",
      showed: true,
      controlPanel: {
        title: i18n.t("table.header.phone"),
        activeGroupKey: getActiveGroup(
          ["phone"],
          { all: ["phone"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "phone",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.phone || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.email"),
      type: EDataGridColumnType.EMAIL,
      key: "email",
      showed: true,
      controlPanel: {
        title: i18n.t("table.header.email"),
        activeGroupKey: getActiveGroup(
          ["email"],
          { all: ["email"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "email",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.email || "",
              },
              {
                key: "emailFull",
                type: EDataGridFilterType.CHECKBOX,
                value: queryParams?.emailFull || "",
                items: [
                  {
                    label: i18n.t("common.selectAll"),
                    value: "true",
                  },
                  {
                    label: i18n.t("common.empty"),
                    value: "false",
                  },
                ],
                onlyOneActiveOption: true,
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.groupCode"),
      type: EDataGridColumnType.STRING,
      key: "groupCode",
      showed: true,
      controlPanel: {
        title: i18n.t("table.header.groupCode"),
        activeGroupKey: getActiveGroup(
          ["groupCode"],
          { all: ["groupCode"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "groupCode",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.groupCode || "",
              },
              {
                key: "groupCodeNotNull",
                type: EDataGridFilterType.CHECKBOX,
                items: groupCodeOptions,
                value: queryParams?.groupCodeNotNull || "",
                onlyOneActiveOption: true,
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "groupCode",
                "-groupCode",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc.groupCode"),
                  value: "groupCode",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc.groupCode"),
                  value: "-groupCode",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.settings.checkboxLabel.birthdate"),
      type: EDataGridColumnType.STRING,
      key: "birthdate",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.settings.checkboxLabel.birthdate"),
        activeGroupKey: getActiveGroup(
          ["birthdate", "birthdateFrom", "birthdateTo"],
          {
            all: ["birthdate", "birthdateFrom", "birthdateTo"],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "birthdateFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.birthdateFrom || "",
              },
              {
                key: "birthdateTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.birthdateTo || "",
              },
              {
                key: "birthdateNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.birthdateNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "birthdate",
                "-birthdate",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "birthdate",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-birthdate",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.rating"),
      type: EDataGridColumnType.RATING,
      key: "gRate",
      showed: true,
      filterTitle: i18n.t("table.header.rating"),
      controlPanel: {
        title: i18n.t("table.header.rating"),
        activeGroupKey: getActiveGroup(
          ["gRate", "gRateFrom", "gRateTo"],
          {
            all: ["gRate", "gRateFrom", "gRateTo"],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "gRateFrom",
                type: EDataGridFilterType.NUMBER,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.gRateFrom || "",
                isHalf: true,
                numberProps: {
                  step: 0.01,
                },
              },
              {
                key: "gRateTo",
                type: EDataGridFilterType.NUMBER,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.gRateTo || "",
                isHalf: true,
                numberProps: {
                  step: 0.01,
                },
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, ["gRate", "-gRate"]),
              orderKey: "orderBy",
              key: "gRate",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "gRate",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-gRate",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.communication.short"),
      description: i18n.t("table.header.communication"),
      filterTitle: i18n.t("table.header.communication"),
      type: EDataGridColumnType.STRING,
      key: "communication",
      showed: true,
      controlPanel: {
        title: i18n.t("table.header.communication"),
        activeGroupKey: getActiveGroup(
          ["communication", "communicationFrom", "communicationTo"],
          { all: ["communication", "communicationFrom", "communicationTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "communicationFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.communicationFrom || "",
              },
              {
                key: "communicationTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.communicationTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "communication",
                "-communication",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "communication",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-communication",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.communicationTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "communicationTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.settings.checkboxLabel.communicationTenacity"),
        activeGroupKey: getActiveGroup(
          [
            "communicationTenacity",
            "communicationTenacityFrom",
            "communicationTenacityTo",
          ],
          {
            all: [
              "communicationTenacity",
              "communicationTenacityFrom",
              "communicationTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "communicationTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.communicationTenacityFrom || "",
              },
              {
                key: "communicationTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.communicationTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "communicationTenacity",
                "-communicationTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "communicationTenacity",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-communicationTenacity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.analytics.short"),
      description: i18n.t("table.header.analytics"),
      filterTitle: i18n.t("table.header.analytics"),
      type: EDataGridColumnType.STRING,
      key: "analytics",
      showed: true,
      controlPanel: {
        title: i18n.t("table.header.analytics"),
        activeGroupKey: getActiveGroup(
          ["analytics", "analyticsFrom", "analyticsTo"],
          { all: ["analytics", "analyticsFrom", "analyticsTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "analyticsFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.analyticsFrom || "",
              },
              {
                key: "analyticsTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.analyticsTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "analytics",
                "-analytics",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "analytics",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-analytics",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.analyticsTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "analyticsTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.settings.checkboxLabel.analyticsTenacity"),
        activeGroupKey: getActiveGroup(
          ["analyticsTenacity", "analyticsTenacityFrom", "analyticsTenacityTo"],
          {
            all: [
              "analyticsTenacity",
              "analyticsTenacityFrom",
              "analyticsTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "analyticsTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.analyticsFrom || "",
              },
              {
                key: "analyticsTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.analyticsTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "analyticsTenacity",
                "-analyticsTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "analyticsTenacity",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-analyticsTenacity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.command.short"),
      description: i18n.t("table.header.command"),
      filterTitle: i18n.t("table.header.command"),
      type: EDataGridColumnType.STRING,
      key: "command",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: i18n.t("table.header.command"),
        activeGroupKey: getActiveGroup(
          ["command", "commandFrom", "commandTo"],
          { all: ["command", "commandFrom", "commandTo"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "commandFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.commandFrom || "",
              },
              {
                key: "commandTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.commandTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "command",
                "-command",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "command",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-command",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.commandTenacity.short"),
      type: EDataGridColumnType.STRING,
      key: "commandTenacity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.settings.checkboxLabel.commandTenacity"),
        activeGroupKey: getActiveGroup(
          ["commandTenacity", "commandTenacityFrom", "commandTenacityTo"],
          {
            all: [
              "commandTenacity",
              "commandTenacityFrom",
              "commandTenacityTo",
            ],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "commandTenacityFrom",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.commandTenacityFrom || "",
              },
              {
                key: "commandTenacityTo",
                type: EDataGridFilterType.NUMBER,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.commandTenacityTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "commandTenacity",
                "-commandTenacity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "commandTenacity",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-commandTenacity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.registration"),
      type: EDataGridColumnType.STRING,
      key: "created",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.registration"),
        activeGroupKey: getActiveGroup(
          ["created"],
          { all: ["created"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "createdFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.createdFrom || "",
              },
              {
                key: "createdTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.createdTo || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "created",
                "-created",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "created",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-created",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.lastActivity"),
      type: EDataGridColumnType.STRING,
      key: "lastActivity",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.lastActivity"),
        activeGroupKey: getActiveGroup(
          ["lastActivity"],
          { all: ["lastActivity"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "lastActivityFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.lastActivityFrom || "",
              },
              {
                key: "lastActivityTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.lastActivityTo || "",
              },
              {
                key: "lastActivityNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.lastActivityNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "lastActivity",
                "-lastActivity",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "lastActivity",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-lastActivity",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.lastPlay"),
      type: EDataGridColumnType.STRING,
      key: "lastPlay",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.lastPlay"),
        activeGroupKey: getActiveGroup(
          ["lastPlay"],
          { all: ["lastPlay"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "lastPlayFrom",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.lastPlayFrom || "",
              },
              {
                key: "lastPlayTo",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.lastPlayTo || "",
              },
              {
                key: "lastPlayNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.lastPlayNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "lastPlay",
                "-lastPlay",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "lastPlay",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-lastPlay",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("userForm.inputLabel.country"),
      type: EDataGridColumnType.STRING,
      key: "country",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("userForm.inputLabel.country"),
        activeGroupKey: getActiveGroup(
          ["country"],
          { all: ["country"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "country",
                type: EDataGridFilterType.MULTI_SELECT,
                items: countriesToSelectItemsAdapter(places.countries),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.country || "",
              },
              {
                key: "countryNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.countryNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "country",
                "-country",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc"),
                  value: "country",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc"),
                  value: "-country",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("userForm.inputLabel.region"),
      type: EDataGridColumnType.STRING,
      key: "region",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("userForm.inputLabel.region"),
        activeGroupKey: getActiveGroup(
          ["region"],
          { all: ["region"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "region",
                type: EDataGridFilterType.MULTI_SELECT,
                items: regionsToSelectItemsAdapter(places.regions),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.region || "",
              },
              {
                key: "regionNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.regionNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "region",
                "-region",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc"),
                  value: "region",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc"),
                  value: "-region",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("userForm.inputLabel.district"),
      type: EDataGridColumnType.STRING,
      key: "district",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("userForm.inputLabel.district"),
        activeGroupKey: getActiveGroup(
          ["district"],
          { all: ["district"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "district",
                type: EDataGridFilterType.MULTI_SELECT,
                items: districtsToSelectItemsAdapter(places.districts),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.district || "",
              },
              {
                key: "districtNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.districtNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "district",
                "-district",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc"),
                  value: "district",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc"),
                  value: "-district",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("userForm.inputLabel.school"),
      type: EDataGridColumnType.STRING,
      key: "school",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("userForm.inputLabel.school"),
        activeGroupKey: getActiveGroup(
          ["school"],
          { all: ["school"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "school",
                type: EDataGridFilterType.MULTI_SELECT,
                items: schoolsToSelectItemsAdapter(places.schools),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.school || "",
              },
              {
                key: "schoolNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.schoolNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "school",
                "-school",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc"),
                  value: "school",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc"),
                  value: "-school",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("userForm.inputLabel.locality"),
      type: EDataGridColumnType.STRING,
      key: "locality",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("userForm.inputLabel.locality"),
        activeGroupKey: getActiveGroup(
          ["locality"],
          { all: ["locality"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "locality",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.locality || "",
              },
              {
                key: "localityNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.localityNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "locality",
                "-locality",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc"),
                  value: "locality",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc"),
                  value: "-locality",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.settings.checkboxLabel.studyWorkPlace"),
      type: EDataGridColumnType.STRING,
      key: "studyWorkPlace",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.settings.checkboxLabel.studyWorkPlace"),
        activeGroupKey: getActiveGroup(
          ["studyWorkPlace"],
          { all: ["studyWorkPlace"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "studyWorkPlace",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.studyWorkPlace || "",
              },
              {
                key: "studyWorkPlaceNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.studyWorkPlaceNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "studyWorkPlace",
                "-studyWorkPlace",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc"),
                  value: "studyWorkPlace",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc"),
                  value: "-studyWorkPlace",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.desiredStudyPlace"),
      type: EDataGridColumnType.STRING,
      key: "desiredStudyPlace",
      showed: true,
      filterPosition: EDataGridFilterPosition.LEFT,
      controlPanel: {
        title: i18n.t("table.header.desiredStudyPlace"),
        activeGroupKey: getActiveGroup(
          ["desiredStudyPlace"],
          { all: ["desiredStudyPlace"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "desiredStudyPlace",
                type: EDataGridFilterType.CHECKBOX,
                items: desiredStudyPlaceOptions,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.desiredStudyPlace || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("userForm.inputLabel.desiredSpecialty"),
      type: EDataGridColumnType.STRING,
      key: "desiredSpecialty",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("userForm.inputLabel.desiredSpecialty"),
        activeGroupKey: getActiveGroup(
          ["desiredSpecialty"],
          { all: ["desiredSpecialty"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "desiredSpecialty",
                type: EDataGridFilterType.MULTI_SELECT,
                placeholder: i18n.t("common.enterValue"),
                items: desiredSpecialityItemsAdapter(),
                value: queryParams?.desiredSpecialty || "",
              },
              {
                key: "desiredSpecialtyNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.desiredSpecialtyNull || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.acquisitionChannel"),
      type: EDataGridColumnType.STRING,
      key: "acquisitionChannel",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.acquisitionChannel"),
        activeGroupKey: getActiveGroup(
          ["acquisitionChannel"],
          { all: ["acquisitionChannel"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "acquisitionChannel",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.acquisitionChannel || "",
              },
              {
                key: "acquisitionChannelNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: emptyItems,
                value: queryParams?.acquisitionChannelNull || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "acquisitionChannel",
                "-acquisitionChannel",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc"),
                  value: "acquisitionChannel",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc"),
                  value: "-acquisitionChannel",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.employmentStatus"),
      type: EDataGridColumnType.SELECT,
      key: "statusEmployment",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.statusEmployment"),
        activeGroupKey: getActiveGroup(
          ["statusEmployment"],
          { all: ["statusEmployment"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "statusEmployment",
                type: EDataGridFilterType.MULTI_SELECT,
                items: serializedCompanyEmploymentStatusesHeader(
                  Statuses.EMPLOYMENT,
                ),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.statusEmployment || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.companyStatus"),
      type: EDataGridColumnType.SELECT,
      key: "statusCompany",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.statusCompany"),
        activeGroupKey: getActiveGroup(
          ["statusCompany"],
          { all: ["statusCompany"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "statusCompany",
                type: EDataGridFilterType.CHECKBOX,
                items: serializedCompanyEmploymentStatusesHeader(
                  Statuses.COMPANY,
                ),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.statusCompany || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.rejectionReason"),
      type: EDataGridColumnType.SELECT,
      key: "rejectionReason",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.rejectionReason"),
        activeGroupKey: getActiveGroup(
          ["rejectionReason"],
          { all: ["rejectionReason"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "rejectionReason",
                type: EDataGridFilterType.MULTI_SELECT,
                items: serializedCompanyEmploymentStatusesHeader(
                  Statuses.REJECTION,
                ),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.rejectionReason || "",
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.date_of_getting_analytics_lvl_1"),
      type: EDataGridColumnType.STRING,
      key: "date_of_getting_analytics_lvl_1",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.date_of_getting_analytics_lvl_1"),
        activeGroupKey: getActiveGroup(
          ["date_of_getting_analytics_lvl_1"],
          { all: ["date_of_getting_analytics_lvl_1"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "dateOfGettingAnalyticsLvl1From",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.dateOfGettingAnalyticsLvl1From || "",
              },
              {
                key: "dateOfGettingAnalyticsLvl1To",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.dateOfGettingAnalyticsLvl1To || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "date_of_getting_analytics_lvl_1",
                "-date_of_getting_analytics_lvl_1",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "date_of_getting_analytics_lvl_1",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-date_of_getting_analytics_lvl_1",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.date_of_getting_analytics_lvl_0_5"),
      type: EDataGridColumnType.STRING,
      key: "date_of_getting_analytics_lvl_0_5",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.date_of_getting_analytics_lvl_0_5"),
        activeGroupKey: getActiveGroup(
          ["date_of_getting_analytics_lvl_0_5"],
          { all: ["date_of_getting_analytics_lvl_0_5"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "dateOfGettingAnalyticsLvl05From",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.from"),
                value: queryParams?.dateOfGettingAnalyticsLvl05From || "",
              },
              {
                key: "dateOfGettingAnalyticsLvl05To",
                type: EDataGridFilterType.DATE,
                isHalf: true,
                placeholder: i18n.t("table.filters.to"),
                value: queryParams?.dateOfGettingAnalyticsLvl05To || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "date_of_getting_analytics_lvl_0_5",
                "-date_of_getting_analytics_lvl_0_5",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "date_of_getting_analytics_lvl_0_5",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-date_of_getting_analytics_lvl_0_5",
                },
              ],
            },
          },
        ],
      },
    },
    {
      key: "confirmationSingle",
      showed: true,
      titleUppercase: true,
      title: i18n.t("table.header.confirmationSingle"),
      type: EDataGridColumnType.CHECKBOX,
      controlPanel: {
        title: i18n.t("table.header.confirmationSingle"),
        activeGroupKey: getActiveGroup(
          ["confirmationSingle"],
          { all: ["confirmationSingle"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "confirmationSingle",
                type: EDataGridFilterType.CHECKBOX,
                items: confirmationSingleOptions,
                placeholder: "Не выбрано",
                value: queryParams?.confirmationSingle || "",
                onlyOneActiveOption: true,
              },
            ],
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.competencies.name"),
      filterTitle: i18n.t("table.header.competencies.name"),
      description: i18n.t("table.header.competencies.name"),
      type: EDataGridColumnType.STRING,
      key: "additionalCompetencies",
      showed: true,
      controlPanel: {
        title: i18n.t("table.header.competencies.name"),
        activeGroupKey: getActiveGroup(
          ["additionalCompetence"],
          {
            all: ["additionalCompetence"],
          },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "additionalCompetence",
                type: EDataGridFilterType.MULTI_SELECT,
                items: Object.values(Competence)
                  .filter((competence) => !isMainCompetence(competence))
                  .map((competence) => {
                    return {
                      label: i18n.t(`common.competence.${competence}`),
                      value: competence,
                    };
                  }),
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.additionalCompetence || "",
                canSearch: false,
              },
              {
                key: "additionalFrom",
                type: EDataGridFilterType.NUMBER,
                placeholder: i18n.t("table.filters.from"),
                isHalf: true,
                value: queryParams?.additionalCompetenceFrom || "",
              },
              {
                key: "additionalTo",
                type: EDataGridFilterType.NUMBER,
                placeholder: i18n.t("table.filters.to"),
                isHalf: true,
                value: queryParams?.additionalCompetenceTo || "",
              },
              {
                key: "tenacityCompetenceNull",
                type: EDataGridFilterType.CHECKBOX,
                placeholder: i18n.t("common.empty"),
                items: [
                  {
                    label: i18n.t("common.empty"),
                    value: "true",
                  },
                ],
                value: queryParams?.additionalNull || "",
                disableFilters: true,
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "additionalCompetence",
                "-additionalCompetence",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.ascending"),
                  value: "additionalCompetence",
                },
                {
                  title: i18n.t("table.filters.descending"),
                  value: "-additionalCompetence",
                },
              ],
            },
          },
        ],
      },
    },
    {
      key: "rolesInRelatedProjects",
      showed: true,
      titleUppercase: true,
      title: i18n.t("table.header.rolesInRelatedProjects"),
      type: EDataGridColumnType.MULTI_SELECT,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.rolesInRelatedProjects"),
        activeGroupKey: getActiveGroup(
          ["rolesInRelatedProjects"],
          { all: ["rolesInRelatedProjects"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "roleInRelatedProjects",
                type: EDataGridFilterType.CHECKBOX,
                items: roleInRelatedProjectsItems,
                placeholder: "Не выбрано",
                value: queryParams?.roleInRelatedProjects || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryParams?.orderBy, [
                "roleInRelatedProjects",
                "-roleInRelatedProjects",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: i18n.t("table.filters.alphabet.asc.rolesInProjects"),
                  value: "rolesInRelatedProjects",
                },
                {
                  title: i18n.t("table.filters.alphabet.desc.rolesInProjects"),
                  value: "-rolesInRelatedProjects",
                },
              ],
            },
          },
        ],
      },
    },
    {
      title: i18n.t("table.header.secretCode"),
      type: EDataGridColumnType.STRING,
      key: "secretCode",
      showed: true,
      noPaddingLeft: true,
      controlPanel: {
        title: i18n.t("table.header.secretCode"),
        activeGroupKey: getActiveGroup(
          ["secretCode"],
          { all: ["secretCode"] },
          queryParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "secretCode",
                type: EDataGridFilterType.SEARCH,
                placeholder: i18n.t("common.enterValue"),
                value: queryParams?.secretCode || "",
              },
            ],
          },
        ],
      },
    },
  ];
};
