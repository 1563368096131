import { createDomain, sample } from "effector";
import { spread } from "patronum";

import { Pagination } from "../../../../../shared/api/base";

import {
  RelativeApiGetCandidateRelativeTableRequest,
  RelativeTable,
} from "src/generated/social";
import { getRelativeTable } from "../../../../../shared/api/hr/moderation/model/relatives";

// Domain

export const moderationUsersDomain = createDomain("moderationUsers");

// Events

export const fetchModerationUsers =
  moderationUsersDomain.createEvent<RelativeApiGetCandidateRelativeTableRequest>();

// Effects

export const fetchModerationUsersFx =
  moderationUsersDomain.createEffect(getRelativeTable);

// Stores

export const $moderationUsersPlayers = moderationUsersDomain.createStore<
  RelativeTable[]
>([]);

export const $pagination = moderationUsersDomain.createStore<Pagination>(null);

export const $queryParams =
  moderationUsersDomain.createStore<RelativeApiGetCandidateRelativeTableRequest>(
    {},
  );

export const $isLoadingModeration =
  moderationUsersDomain.createStore<boolean>(false);

// Logic

sample({
  clock: fetchModerationUsers,
  target: fetchModerationUsersFx,
});

sample({
  clock: fetchModerationUsers,
  target: $queryParams,
});

sample({
  clock: fetchModerationUsersFx.pending,
  target: $isLoadingModeration,
});

spread({
  source: fetchModerationUsersFx.doneData.map(({ relative, pagination }) => ({
    relative,
    pagination: pagination || null,
  })),
  targets: {
    relative: $moderationUsersPlayers,
    pagination: $pagination,
  },
});
