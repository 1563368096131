import { FC, ReactElement, useEffect, useState } from "react";

import { useUnit } from "effector-react";

import { Rating } from "src/generated/social";
import { keycloakModel } from "src/entities/public/keycloak";
import {
  IDataGridColumn,
  IDataGridRow,
  TDataGridDataParams,
  TDataGridPagination,
} from "src/shared/models/dataGrid";

import { DataGrid } from "src/shared/components";

import { EUserSettingsTablesName } from "src/entities/public/user-settings";

import { userSettingsModel } from "src/features/public/user-settings";
import {
  $companyStatuses,
  $employmentStatuses,
  $rejectionReasons,
  fetchAllCompanyStatuses,
  fetchAllEmploymentStatuses,
  fetchAllRejectionReasons,
  fetchAllRelatedProjects,
} from "../../../entities/public/rating-statuses/model";
import _ from "lodash";
import { patchPlayerById } from "../../../shared/api/hr/rating-statuses";
import { useGenerateRatingsColumns } from "../../../entities/hr/ratings/lib/useGenerateRatingsColumns";
import { useGetRatingsRowsItems } from "../../../entities/hr/ratings/lib/useGetRatingsRowsItems";
import {
  $places,
  fetchPlaces,
} from "../../../features/admin/player-ratings/model/data-grid";

interface Props {
  pagination?: TDataGridPagination | null;
  ratings: Rating[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

const { $tablesSettings } = userSettingsModel;

export const RatingsTable: FC<Props> = ({
  loading,
  ratings,
  queryParams,
  pagination,
  onUpdateSortAndFilters,
  goToPage,
  readmore,
}): ReactElement => {
  const [columns, setColumns] = useState<IDataGridColumn[]>([]);

  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const { $isAdminClientId, $isHrClientId } = keycloakModel;

  const tablesSettings = useUnit($tablesSettings);

  const isAdminClientId = useUnit($isAdminClientId);

  const isHrClientId = useUnit($isHrClientId);

  const [ratingsToState, setRatingsToState] = useState<Rating[]>([]);

  useEffect(() => {
    fetchAllEmploymentStatuses();
    fetchAllCompanyStatuses();
    fetchAllRejectionReasons();
    fetchAllRelatedProjects();
    fetchPlaces({});
  }, []);

  const companyStatuses = useUnit($companyStatuses);

  const rejectionReasons = useUnit($rejectionReasons);

  const employmentStatusesListForHeader = useUnit($employmentStatuses);

  const places = useUnit($places);

  const generateRatingsColumns = useGenerateRatingsColumns();

  const getRatingsRowsItems = useGetRatingsRowsItems();

  useEffect(() => {
    setColumns(generateRatingsColumns(queryParams ?? {}));
  }, [
    queryParams,
    companyStatuses,
    rejectionReasons,
    employmentStatusesListForHeader,
    places,
  ]);

  useEffect(() => {
    setRatingsToState(_.cloneDeep(ratings));
  }, [ratings]);

  const onCheckHandler = (id: string, key: string, event: boolean) => {
    if (!isHrClientId && !isAdminClientId) return;
    if (!ratingsToState) return;

    const newRatingsList = ratingsToState.map((player) =>
      player.playerId === id
        ? { ...player, confirmationSingle: event }
        : player,
    );

    patchPlayerById({ pId: id, body: { confirmationSingle: event } });

    setRatingsToState(newRatingsList);
  };

  useEffect(() => {
    let indexOffset = 0;

    if (pagination) {
      indexOffset += pagination.pageSize * (pagination.pageNum - 1);

      if (ratings.length > pagination.pageSize) {
        const showedPages = Math.ceil(ratings.length / pagination.pageSize);

        indexOffset -= (showedPages - 1) * pagination.pageSize;
      }
    }

    setRows(getRatingsRowsItems(ratingsToState, indexOffset));
  }, [ratingsToState, pagination]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      autoScrollDisabled
      setColumns={setColumns}
      queryParams={queryParams}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      changeCheckbox={onCheckHandler}
      goToPage={goToPage}
      readmore={readmore}
      tableSettings={tablesSettings?.[EUserSettingsTablesName.RATINGS]}
    />
  );
};
