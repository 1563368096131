import {
  FC,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useUnit } from "effector-react";
import isEqual from "lodash/isEqual";

import { useUrlState } from "src/shared/hooks/useUrlState";
import { useTranslation } from "react-i18next";

import { ArchiveLink, TextWithIcon } from "../../../shared/components";

import { PageWrapper } from "src/shared/components";
import { UsersControlPanelBtn } from "../components";

import {
  $usersListAdmin,
  cleanUsersListAdmin,
  requestUsersListAdmin,
  setUsersListAdminIsReadmore,
} from "src/entities/public/users-list-admin";

import { ERequestStatus } from "../../../shared/store/types";

import { TDataGridDataParams, UserRoleEnum } from "../../../shared/models";
import { AdminTable } from "../../../shared/components/data-grids/user-managments/admin";
import { UsersApiUsersRequest } from "../../../generated/keycloak";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import "./admins-page.scss";
import { ERegEx, regExList } from "../../../shared/constants/regExList";

interface Props {}

export const AdminsPage: FC<Props> = (): ReactElement => {
  const [urlState, setUrlState] = useUrlState();

  const { t } = useTranslation();

  const {
    items: usersListAdminItems,
    pagination,
    status: usersListAdminStatus,
  } = useUnit($usersListAdmin);

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setUsersListAdminIsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,

      orderBy,
      pageNum,
      pageSize,
    } = urlState.query;

    const params: UsersApiUsersRequest = {
      role: [UserRoleEnum.HR_ADMIN],
      enabled: true,

      firstName: firstName ? [firstName] : undefined,
      lastName: lastName ? [lastName] : undefined,
      phoneNumber: phoneNumber
        ? [phoneNumber.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
        : undefined,
      email: email ? [email] : undefined,

      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [urlState.query]);

  useEffect(() => {
    requestUsersListAdmin(pageParams);
  }, [pageParams]);

  useEffect(() => {
    return () => {
      cleanUsersListAdmin();
    };
  }, []);

  const isLoadingUsers: boolean =
    usersListAdminStatus === ERequestStatus.LOADING;

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  return (
    <PageWrapper
      title={t("usersControl.title")}
      tabsPanelSlot={<ArchiveLink to={"/users-control/archive"} />}
      isLoadingPanel={isLoadingUsers}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("usersControl.controlPanel.label.users")}
      controlPanelMaxCount={pagination?.totalItems}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <Fragment>
          <div className="table__control">
            {filtersLength > 0 && (
              <div className="table__control-btn" onClick={clearFilterSettings}>
                <TextWithIcon
                  label={t("table.filters.reset")}
                  iconName={"cross-blue"}
                />
              </div>
            )}
            <UsersControlPanelBtn
              addUserType="admin"
              isPlayerHrAdminPage
              isAdminPage
            />
          </div>
        </Fragment>
      }
    >
      <div className="admins-page-data-grid-wrapper">
        <AdminTable
          users={usersListAdminItems}
          queryParams={urlState.query}
          onUpdateSortAndFilters={onUpdateQueryParams}
          loading={isLoadingUsers}
          pagination={pagination}
          goToPage={(pageNum) => goToPage(pageNum)}
          readmore={(pageNum) => goToPage(pageNum, true)}
        />
      </div>
    </PageWrapper>
  );
};
