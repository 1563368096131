export const getTodayDate = () => {
  let today: Date = new Date();
  let dd: string | number = today.getDate();
  let mm: string | number = today.getMonth() + 1; //January is 0!
  let yyyy: number = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "-" + mm + "-" + dd;
};
