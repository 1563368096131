import { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useUnit } from "effector-react";

import { getArray } from "src/shared/helpers";

import { BaseButton } from "src/shared/components";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import { keycloakModel } from "src/entities/public/keycloak";

import { appAccessModel } from "src/features/public/app-access";

import "./welcome-popup.scss";

const { $socialPlayerInfo } = appAccessModel;

const { $isPlayerClientId } = keycloakModel;

const { openPopup, closePopup } = popupModel;

export const WelcomePopup: FC = () => {
  const { t } = useTranslation();

  const social = useUnit($socialPlayerInfo);

  const isPlayerClientId = useUnit($isPlayerClientId);

  const onClosePopup = () => {
    closePopup({ name: EPopupName.WELCOME });
  };

  useEffect(() => {
    if (!social) return;

    const userWithHighRate: boolean = !!social.competenceRates?.some(
      ({ rate }) => rate,
    );

    const firstVisitorsIds: string[] =
      localStorage.getItem("isFirstVisit")?.split(",") || [];

    const isSecondVisit: boolean = firstVisitorsIds.includes(social.id);

    if (!isPlayerClientId || isSecondVisit || userWithHighRate) return;

    openPopup({
      name: EPopupName.WELCOME,
    });

    const newVisitorsIds: string = [...firstVisitorsIds, social.id].join(",");

    localStorage.setItem("isFirstVisit", newVisitorsIds);
  }, [isPlayerClientId, social]);

  return (
    <div className="welcome-popup">
      <div className="welcome-popup__banner" />
      <div className="welcome-popup__wrapper">
        <div className="welcome-popup__title">{t("popup.welcome.title")}</div>
        <div className="welcome-popup__content">
          <div className="welcome-popup__subtitle">
            {t("popup.welcome.subtitle")}
          </div>
          {getArray(4).map((_: number, index: number) => {
            return (
              <div className="welcome-popup__item" key={index}>
                <span className="welcome-popup__item-label">
                  {`${t("popup.welcome.mode")}${index + 1} - `}
                </span>
                {t(`popup.welcome.description${index + 1}`)}.
              </div>
            );
          })}
        </div>
        <BaseButton
          primary
          block
          className="welcome-popup__btn"
          onClick={onClosePopup}
        >
          {t("popup.welcome.ok")}
        </BaseButton>
      </div>
    </div>
  );
};
