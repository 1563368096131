import {
  FC,
  MutableRefObject,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { BaseCodeBlock, BaseTooltip } from "../../..";
import "./code-block.scss";

interface Props {
  competence: string;
  formula: string;
}

export const CodeBlock: FC<Props> = ({ competence, formula }): ReactElement => {
  const { t } = useTranslation();

  const [ref, setRef] = useState<MutableRefObject<HTMLDivElement> | null>(null);

  const itemRef = useCallback((node: HTMLDivElement) => {
    setRef({ current: node });
  }, []);

  const fakeScroll = () => {
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  };

  return (
    <div className="code-block">
      <div className="code-block__title">
        {t(`common.competence.${competence}`)}
      </div>
      <div
        className="code-block__description tooltip-wrapper"
        ref={itemRef}
        onClick={fakeScroll}
      >
        <BaseCodeBlock code={formula} forTooltip />
        {!!ref && (
          <div className="code-block__description-tooltip">
            <BaseTooltip
              className="code-block__description-tooltip-item"
              tooltipToggleRef={ref}
              position="bottom-end"
              offsetY={10}
            >
              <BaseCodeBlock isTransparentBg code={formula} />
            </BaseTooltip>
          </div>
        )}
      </div>
    </div>
  );
};
