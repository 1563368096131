import { gameService } from "src/shared/api/base/api-instances";

import { PostCopyAssessment, PostCreateAssessment } from "./query";

export const postCreateAssessmentHr = (params: PostCreateAssessment) => {
  return gameService
    .makeHrGamesRequest()
    .postGameHrCreateAssessment(params)
    .then((res) => res.data);
};

export const postCopyAssessmentHr = (params: PostCopyAssessment) => {
  return gameService
    .makeHrGamesRequest()
    .postGameHrCopyAssessment(params)
    .then((res) => res.data);
};
