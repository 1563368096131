import { IRequestForceTerminateAssessments } from "src/entities/public/force-terminate-assessments";
import { gameService } from "src/shared/api/base/api-instances";

export const postAdmAssessmentFinish = (
  params: IRequestForceTerminateAssessments,
) => {
  const { body } = params;

  return gameService
    .makeAdminAssessmentApi()
    .postAdmAssessmentFinish(body)
    .then((res) => res.data);
};
