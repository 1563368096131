import { FC, ReactElement } from "react";

import classNames from "classnames";

import { ILvlUpGameTypeItem } from "../../model/types";

import { CompetenceControlList, GameTypeAvailability, GameTypeMode } from "../";

import "./game-type-card.scss";
import i18n from "i18next";

interface Props {
  gameTypes: ILvlUpGameTypeItem[];
  className?: string;
  simulationId: string;
}

export const GameTypeCard: FC<Props> = ({
  gameTypes,
  className,
  simulationId,
}): ReactElement => {
  return (
    <div className={classNames("game-type-card", className)}>
      {gameTypes.map((game, index, gameTypes) => {
        return (
          <div
            key={game.gameSubType + game.gameType}
            className={classNames("game-type-card__container-wrapper", {
              "game-type-card__container-wrapper--divided": Boolean(
                gameTypes[index + 1],
              ),
            })}
          >
            <div
              className={classNames("game-type-card__container", {
                //   ToDo поменять класс
                "game-type-card--disabled": !game.availability,
              })}
            >
              <GameTypeMode
                gameTitle={game.gameSubType}
                gameRule={
                  game.gameRule && game.gameRule[i18n.language as "ru" | "en"]
                }
              />
              <GameTypeAvailability
                availabilityList={game.competenceAvailability}
              />
            </div>
            {!!game.competenceControls.length && (
              <CompetenceControlList
                simulationId={simulationId}
                items={game.competenceControls}
                gameType={game.gameType}
                availability={game.availability}
                gameSubType={game.gameSubType}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
