import { FC, ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUrlState } from "../../../shared/hooks/useUrlState";
import { TDataGridDataParams } from "../../../shared/models";
import { togglePlaceParams } from "../../../shared/helpers";
import isEqual from "lodash/isEqual";
import { useUpdatedQueryParams } from "../../../shared/hooks/useUpdatedQueryParams";
import {
  BaseButton,
  PageWrapper,
  TextWithIcon,
} from "../../../shared/components";
import { useUnit } from "effector-react";
import {
  $isLoadingModeration,
  $moderationUsersPlayers,
  $pagination,
  fetchModerationUsers,
} from "../../../entities/hr/moderation/model/relatives/moderation-relatives";
import { RelativeApiGetCandidateRelativeTableRequest } from "../../../generated/social";
import { RelativeTableDataGrid } from "../../../shared/components/data-grids/moderation/relative-table";

import "../moderation-page.scss";
import { ERegEx, regExList } from "../../../shared/constants/regExList";

interface Props {}

export const RelativeModerationPage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const [urlState, setUrlState] = useUrlState();

  const isLoading = useUnit($isLoadingModeration);

  const moderationUsers = useUnit($moderationUsersPlayers);

  const pagination = useUnit($pagination);

  const { updatedQueryParams } = useUpdatedQueryParams();

  const clearFilterSettings = () => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { pageNum: "1" },
    }));
  };

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;
      const newFilteredParams = togglePlaceParams(queryParams, newQueryParams);

      if (!isEqual(queryParams, newFilteredParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newFilteredParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number) => {
    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const pageParams = useMemo(() => {
    const {
      firstNameRelative,
      lastNameRelative,
      firstNameStudent,
      lastNameStudent,
      orderBy,
      pageNum,
      phoneRelative,
      phoneStudent,
      pageSize,
    } = urlState.query;

    const params: RelativeApiGetCandidateRelativeTableRequest = {
      firstName: firstNameRelative ? [firstNameRelative] : undefined,
      lastName: lastNameRelative ? [lastNameRelative] : undefined,
      firstNameStudent: firstNameStudent ? firstNameStudent : undefined,
      lastNameStudent: lastNameStudent ? lastNameStudent : undefined,
      phone: phoneRelative
        ? [phoneRelative.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
        : undefined,
      phoneStudent: phoneStudent
        ? [phoneStudent.replace(regExList[ERegEx.ONLY_NUMBERS], "")]
        : undefined,
      orderBy: orderBy ? orderBy.split(",") : undefined,
      pageNum: pageNum ? Number(pageNum) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
    };

    return params;
  }, [urlState.query]);

  useEffect(() => {
    if (updatedQueryParams) {
      fetchModerationUsers(pageParams);
    }
  }, [pageParams, updatedQueryParams]);

  const filtersLength = useMemo(
    () =>
      Object.keys(urlState.query).filter(
        (key) => key !== "pageNum" && key !== "pageSize",
      ).length,
    [urlState.query],
  );

  return (
    <div className="moderation-page">
      <PageWrapper
        isLightBlueBackground
        title={t("title.moderationManagement")}
        isLoadingPanel={isLoading}
        tabsPanelSlot={
          <div className="table__control">
            {filtersLength > 0 && (
              <BaseButton
                className="table__control-btn"
                onClick={clearFilterSettings}
                lightBlue
              >
                <TextWithIcon
                  label={t("table.filters.reset")}
                  iconName={"cross-blue"}
                />
              </BaseButton>
            )}
          </div>
        }
      >
        <div className="moderation-page-data-grid-wrapper">
          <RelativeTableDataGrid
            items={moderationUsers}
            pagination={pagination}
            loading={isLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            queryParams={urlState.query}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum)}
          />
        </div>
      </PageWrapper>
    </div>
  );
};
