import { sample } from "effector";

import {
  sendSmsMessage,
  SendSmsMessage,
} from "src/shared/api/admin/notification/sms-notification";

import { $isLoading, smsNotificationDomain } from "./sms-notification";

import { errorFx, successFx } from "../../common-notification";

// Event

export const sendSms = smsNotificationDomain.createEvent<SendSmsMessage>();

// Effect

export const sendSmsFx = smsNotificationDomain.createEffect(sendSmsMessage);

// Logic

sample({ clock: sendSms, target: sendSmsFx });

sample({ clock: sendSmsFx.pending, target: $isLoading });

sample({ clock: sendSmsFx.doneData, target: successFx });

sample({ clock: sendSmsFx.fail, target: errorFx });
