import { createDomain, createEvent, createStore, sample } from "effector";

import { getProfileRelativeTable } from "../../../../shared/api/public/relatives/relatives";
import { GetRelatives } from "../../../../generated/social";

export const getProfileRelativeUsersDomain = createDomain(
  "getProfileRelativeUsersPublic",
);

// Effects

export const fetchGetProfileRelativeUsersFx =
  getProfileRelativeUsersDomain.createEffect(getProfileRelativeTable);

// Stores

export const $relatives =
  getProfileRelativeUsersDomain.createStore<GetRelatives | null>(null);
export const $isNoAccess = createStore<boolean>(false);

export const $isLoading =
  getProfileRelativeUsersDomain.createStore<boolean>(false);

// Events

export const fetchGetProfileRelativeUsers =
  getProfileRelativeUsersDomain.createEvent();

export const setIsNoAccess = createEvent<boolean>();

sample({
  clock: fetchGetProfileRelativeUsers,
  target: fetchGetProfileRelativeUsersFx,
});

sample({
  clock: fetchGetProfileRelativeUsersFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchGetProfileRelativeUsersFx.doneData,
  target: $relatives,
});

sample({
  clock: setIsNoAccess,
  fn: (isNoAccess) => isNoAccess,
  target: $isNoAccess,
});

fetchGetProfileRelativeUsersFx.fail.watch(() => {
  setIsNoAccess(true);
});
