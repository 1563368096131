import {
  FC,
  FormEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import { IDataGridColumn, TDataGridDataParams } from "../../../models/dataGrid";

import { BaseButton } from "../..";
import { useTranslation } from "react-i18next";
import { DataGridMobileFiltersColumn } from "./data-grid-mobile-filters-column";

interface Props {
  columns: IDataGridColumn[];
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
}

export const DataGridMobileFilters: FC<Props> = ({
  columns,
  onUpdateSortAndFilters,
  queryParams = {},
}): ReactElement => {
  const { t } = useTranslation();

  const [newQueryParams, setNewQueryParams] = useState<TDataGridDataParams>({});

  const apply = useCallback(() => {
    onUpdateSortAndFilters && onUpdateSortAndFilters(newQueryParams);
  }, [newQueryParams, onUpdateSortAndFilters]);

  const onSubmitForm: FormEventHandler = (e) => {
    e.preventDefault();

    apply();
  };

  useEffect(() => {
    setNewQueryParams({ ...queryParams });
  }, [queryParams]);

  return (
    <form className="data-grid__mobile-filters" onSubmit={onSubmitForm}>
      <div className="data-grid__mobile-filters-columns">
        {columns.map((column) => (
          <DataGridMobileFiltersColumn
            key={`column-${column.key}`}
            column={column}
            queryParams={newQueryParams}
            onUpdateSortAndFilters={setNewQueryParams}
          />
        ))}
      </div>

      <BaseButton
        submit
        block
        white
        className="data-grid__mobile-filters-apply"
      >
        {t("table.filters.btn.apply")}
      </BaseButton>
    </form>
  );
};
