import { createEvent } from "effector";
import {
  $activeRadioValue,
  $copySelectedIds,
  $selectedIds,
} from "src/pages/other/select-players-page/model/selected-players";

// Events

export const resetSelectedIds = createEvent();

export const resetCopySelectedIds = createEvent();

export const resetActiveRadioValue = createEvent();

// Logic

$selectedIds.reset(resetSelectedIds);

$copySelectedIds.reset(resetCopySelectedIds);

$activeRadioValue.reset(resetActiveRadioValue);
