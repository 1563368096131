import { createEvent, sample } from "effector";

import {
  addEmailNotification,
  PostEmail,
} from "src/shared/api/admin/notification";

import { $isLoading, emailNotificationDomain } from "./email-notification";

import { $email } from "./get-email";

import { errorFx, successFx } from "../../common-notification";

import {
  resetEmailText,
  resetEmailTheme,
  resetNotification,
} from "./reset-email";

// Event

export const createEmail = emailNotificationDomain.createEvent<PostEmail>();

export const updateEmailTheme = emailNotificationDomain.createEvent<string>();

export const updateEmailText = emailNotificationDomain.createEvent<string>();

export const showSnackBar = createEvent<PostEmail>();

// Effect

export const createEmailFx =
  emailNotificationDomain.createEffect(addEmailNotification);

// Stores

export const $emailTheme = emailNotificationDomain.createStore<string>("");

export const $emailText = emailNotificationDomain.createStore<string>("");

// Logic

sample({ clock: createEmail, target: createEmailFx });

sample({
  clock: createEmailFx.doneData,
  target: [$email, successFx, resetNotification],
});

sample({
  clock: createEmailFx.fail,
  target: errorFx,
});

sample({ clock: createEmailFx.pending, target: $isLoading });

sample({
  clock: updateEmailTheme,
  target: $emailTheme,
});

sample({
  clock: updateEmailText,
  target: $emailText,
});

$emailTheme.reset(resetEmailTheme);

$emailText.reset(resetEmailText);
