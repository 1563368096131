import { Action } from "redux";
import { LocStrDto, UpdSidebarReq } from "../../../../generated/social";

export enum ESidebarInformationActions {
  CLEAN_SIDEBAR_INFORMATION = "faq/CLEAN_SIDEBAR_INFORMATION",
  REQUEST_SIDEBAR_INFORMATION_GET = "faq/REQUEST_SIDEBAR_INFORMATION_GET",
  REQUEST_SIDEBAR_INFORMATION_POST = "faq/REQUEST_SIDEBAR_INFORMATION_POST",
  SUCCESS_SIDEBAR_INFORMATION_GET = "faq/SUCCESS_SIDEBAR_INFORMATION_GET",
  SUCCESS_SIDEBAR_INFORMATION_POST = "faq/SUCCESS_SIDEBAR_INFORMATION_POST",
  FAILURE_SIDEBAR_INFORMATION_GET = "faq/FAILURE_SIDEBAR_INFORMATION_GET",
  FAILURE_SIDEBAR_INFORMATION_POST = "faq/FAILURE_SIDEBAR_INFORMATION_POST",
}

export interface ICleanSidebarInformation
  extends Action<ESidebarInformationActions> {
  type: ESidebarInformationActions.CLEAN_SIDEBAR_INFORMATION;
}

export interface IRequestSidebarInformationGet
  extends Action<ESidebarInformationActions> {
  type: ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_GET;
  payload: {
    callback?: () => void;
  };
}

export interface IRequestSidebarInformationPost
  extends Action<ESidebarInformationActions> {
  type: ESidebarInformationActions.REQUEST_SIDEBAR_INFORMATION_POST;
  payload: {
    item: UpdSidebarReq;
    callback?: () => void;
  };
}

export interface ISuccessSidebarInformationGet
  extends Action<ESidebarInformationActions> {
  type: ESidebarInformationActions.SUCCESS_SIDEBAR_INFORMATION_GET;
  payload: LocStrDto;
}

export interface ISuccessSidebarInformationPost
  extends Action<ESidebarInformationActions> {
  type: ESidebarInformationActions.SUCCESS_SIDEBAR_INFORMATION_POST;
}

export interface IFailureSidebarInformationGet
  extends Action<ESidebarInformationActions> {
  type: ESidebarInformationActions.FAILURE_SIDEBAR_INFORMATION_GET;
}

export interface IFailureSidebarInformationPost
  extends Action<ESidebarInformationActions> {
  type: ESidebarInformationActions.FAILURE_SIDEBAR_INFORMATION_POST;
}
