import React from "react";
import { BaseButton } from "../base-button/base-button";
import styles from "./iconButton.module.scss";
import classNames from "classnames";

interface IProps {
  icon: JSX.Element;
  onClick?: (arg?: any) => void;
  className?: string | null;
  link?: string;
}

export const IconButton: React.FC<IProps> = ({
  icon,
  onClick,
  className = null,
  link,
}) => {
  return (
    <BaseButton
      className={classNames(styles.button, className)}
      onClick={onClick}
      to={link}
    >
      {icon}
    </BaseButton>
  );
};
