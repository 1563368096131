import useInput, {
  EInputValidateType,
  IValidateValue,
} from "src/shared/hooks/useInput";
import { NotificationType } from "../newsletter-form";
import { ChanType, Msg, MsgType, ReqEmail } from "src/generated/notification";
import { emailNotificationModel } from "src/entities/admin/notification";
import {
  $notificationTxt,
  sendNotification,
  sendPersonalNotification,
} from "src/entities/admin/notification/notification";
import { useStore, useUnit } from "effector-react";
import {
  $emailText,
  $emailTheme,
} from "src/entities/admin/notification/email-notification";
import { $smsText } from "src/entities/admin/notification/sms-notification";
import { ERadioType } from "src/shared/components/recipients-form/recipients-form";
import { useUrlState } from "src/shared/hooks/useUrlState";
import {
  $activeRadioValue,
  $selectedIds,
} from "../../../other/select-players-page/model";
import { openPopup } from "../../../../shared/components/base-popup/model";
import { EPopupName } from "../../../../shared/components";
import { t } from "i18next";

export const useCreateNotification = (
  setFormErrors: (payload: Record<string, string>) => void,
) => {
  const { validateValue } = useInput();

  const [urlState] = useUrlState();

  const selectedIds = useUnit($selectedIds);

  const emailTheme = useUnit($emailTheme);

  const emailText = useUnit($emailText);

  const smsText = useUnit($smsText);

  const values = useStore($notificationTxt);

  const activeRadioValue = useUnit($activeRadioValue);

  const shouldSendToAll = activeRadioValue === ERadioType.SEND_TO_ALL;

  const isPersonal =
    activeRadioValue === ERadioType.SELECTIVELY && selectedIds.length !== 0;

  const formErrorsTemplate: Record<string, IValidateValue> = {
    title: {
      value: values.title,
      type: EInputValidateType.STRING,
      required: true,
    },
    body: {
      value: values.body,
      type: EInputValidateType.STRING,
      required: true,
    },
    link: {
      value: values.link!,
      type: EInputValidateType.LINK,
      required: true,
    },
  };

  return () => {
    if (selectedIds.length === 0 && !shouldSendToAll) {
      openPopup({
        name: EPopupName.BASE_MESSAGE_POPUP,
        message: {
          text: t("popup.baseMessage.message.notSelectedIds"),
          isError: true,
        },
      });
      return;
    }
    if (urlState.query.type === NotificationType.NOTIFICATION) {
      const newFormDataErrorsState = validateValue(formErrorsTemplate);
      setFormErrors(newFormDataErrorsState);
      const isAllFieldsCorrect = !Object.keys(newFormDataErrorsState).length;
      if (isAllFieldsCorrect) {
        const payloadForNotification: Msg = {
          type: MsgType.Txt,
          body: {
            ...values,
            title: values.title,
            body: values.body,
            link: values.link,
          },
        };

        if (isPersonal) {
          sendPersonalNotification({
            reqNotifyPersonal: {
              uids: selectedIds,
              msg: payloadForNotification,
            },
          });
        } else {
          openPopup({
            name: EPopupName.CONFIRM_SENDTOALL_POPUP,
            message: {
              text: t("popup.confirmSendToAll.title"),
            },
            data: {
              callback: () =>
                sendNotification({
                  reqNotifyAll: {
                    chan: ChanType.Players,
                    msg: payloadForNotification,
                  },
                }),
            },
          });
        }
      }
    }

    // if (urlState.query.type === NotificationType.SMS) {
    //   const payloadForSms: ReqSms = {
    //     text: smsText,
    //     uids: selectedIds,
    //     shouldSend: true,
    //     allPhones: shouldSendToAll,
    //   };
    //   smsNotificationModel.createSms({ reqSms: payloadForSms });
    // }

    if (urlState.query.type === NotificationType.E_MAIL) {
      const payloadForEmail: ReqEmail = {
        subject: emailTheme,
        text: emailText,
        uids: selectedIds,
        shouldSend: true,
        allEmails: shouldSendToAll,
      };

      if (isPersonal) {
        emailNotificationModel.createEmail({
          reqEmail: payloadForEmail,
        });
      } else {
        openPopup({
          name: EPopupName.CONFIRM_SENDTOALL_POPUP,
          message: {
            text: t("popup.confirmSendToAll.title"),
          },
          data: {
            callback: () => {
              emailNotificationModel.createEmail({
                reqEmail: payloadForEmail,
              });
            },
          },
        });
      }
    }
  };
};
