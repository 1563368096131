import React, { FC, ReactElement, useCallback, useEffect } from "react";

import { useUnit } from "effector-react";

import isEqual from "lodash/isEqual";

import { useTranslation } from "react-i18next";

import { EmptyBlock, PageWrapper } from "src/shared/components";

import { RatingsTable } from "src/shared/components/data-grids/ratings";

import { TDataGridDataParams } from "src/shared/models";

import "./ratings-page.scss";
import UserProfilePopup from "../../../shared/components/popups/user-profile-popup/user-profile-popup";
import { useUrlState } from "src/shared/hooks/useUrlState";
import { RatingsTableControls } from "./components/ratings-table-controls";
import { DownloadRatingsPopup } from "./components/download-ratings-popup";
import {
  $isRatingsLoading,
  $isRatingsRequestError,
  $ratings,
  $ratingsPagination,
  setIsRatingsReadmore,
  setRatingsQueryParams,
} from "src/entities/hr/ratings/model/ratings";
import { exportModel } from "src/entities/hr/export-rates";

export const RatingsPage: FC = React.memo((): ReactElement => {
  const { t } = useTranslation();

  const users = useUnit($ratings);

  const pagination = useUnit($ratingsPagination);

  const [urlState, setUrlState] = useUrlState();

  const isLoading = useUnit($isRatingsLoading);

  const isRequestError = useUnit($isRatingsRequestError);

  useEffect(() => {
    exportModel.setParams(urlState.query);
    setRatingsQueryParams(urlState.query);
  }, [urlState.query]);

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      const queryParams = urlState.query;

      if (!isEqual(queryParams, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number, isReadmore: boolean = false) => {
    setIsRatingsReadmore(isReadmore);

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  return (
    <PageWrapper
      title={t("ratings.title")}
      isLoadingPanel={isLoading}
      isShowContentWhenIsLoading
      emptyPanel
      isLightBlueBackground
      titlePanelSlot={<RatingsTableControls />}
    >
      <div className="ratings-page-data-grid-wrapper">
        <RatingsTable
          ratings={users}
          loading={isLoading}
          queryParams={urlState.query}
          onUpdateSortAndFilters={onUpdateQueryParams}
          pagination={pagination}
          goToPage={(pageNum) => goToPage(pageNum)}
          readmore={(pageNum) => goToPage(pageNum, true)}
        />
        {isRequestError && !isLoading && (
          <EmptyBlock title={t("alert.requestError")} />
        )}
      </div>

      <DownloadRatingsPopup />

      <UserProfilePopup />
    </PageWrapper>
  );
});
