import { FC, ReactNode } from "react";

import classNames from "classnames";

import "./base-popover.scss";
import { useDelayUnmount } from "src/shared/hooks/useDelayUnmount";

interface BasePopoverProps {
  className?: string;
  title?: string;
  isOpened: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const BasePopover: FC<BasePopoverProps> = (props: BasePopoverProps) => {
  const { className, title, isOpened, children } = props;

  const shouldRenderMenu = useDelayUnmount(isOpened, 200);

  return !shouldRenderMenu ? null : (
    <div className="base-popover-wrapper">
      <div
        className={classNames("base-popover", className, {
          "base-popover__is_closed": !isOpened,
        })}
      >
        {title && <div className="base-popover__title">{title}</div>}
        <div className="base-popover-content">{children}</div>
      </div>
    </div>
  );
};
