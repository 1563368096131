import axios from "axios";

export const postSimulation = async (payload: {
  options: any;
}): Promise<any> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_GAME_URL}/api/v1/admin/games`,
    payload.options,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("alabuga-token"),
      },
    },
  );

  return data;
};
