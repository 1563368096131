import { createDomain, sample } from "effector";
import { confirmationCancel } from "../../../../../shared/api/hr/moderation/model/relatives";
import { RelativeApiCancelConfirmationRelativeRequest } from "../../../../../generated/social";
import { $relativeStatus } from "./relatives-confirmation";

export const postCancelConfirmationRelativeDomain = createDomain(
  "postCancelConfirmationRelativePublic",
);

// Effects

export const fetchPostCancelConfirmationRelativeFx =
  postCancelConfirmationRelativeDomain.createEffect(confirmationCancel);

// Stores

export const $isLoading =
  postCancelConfirmationRelativeDomain.createStore<boolean>(false);

// Events

export const fetchPostCancelConfirmationRelative =
  postCancelConfirmationRelativeDomain.createEvent<RelativeApiCancelConfirmationRelativeRequest>();

sample({
  clock: fetchPostCancelConfirmationRelative,
  target: fetchPostCancelConfirmationRelativeFx,
});

sample({
  clock: fetchPostCancelConfirmationRelativeFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchPostCancelConfirmationRelativeFx.doneData,
  target: $relativeStatus,
});
