import {
  AdmAssessmentDto,
  AssessmentType,
  GameDto,
  GameParamsTemplateDto,
  GameType,
  PGameDto,
} from "../../generated/game";
import {
  LocalizedStr1024,
  LocalizedStr255,
  Result,
} from "../../generated/game/meta";
import { LocStrDto } from "../../generated/social";
import { AssessmentDetailDto } from "../../generated/statistic";
import { TTemplateParams } from "../components";

export enum ERequestStatus {
  NEVER = "never",
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
  IDLE = "idle",
}

export enum LobbyLaunchConditions {
  fullTeams = "fullTeams",
  minTeams = "minTeams",
}

export enum Statuses {
  EMPLOYMENT = "EMPLOYMENT",
  COMPANY = "COMPANY",
  REJECTION = "REJECTION",
}

export enum LobbyTimerState {
  withTimer = "withTimer",
  withoutTimer = "withoutTimer",
}

export enum Participation {
  required = "required",
  optional = "optional",
}

export enum AssessmentAccess {
  Public = "public",
  Private = "private",
}

export enum ESimulationParamsType {
  ARRAY = "array",
  INTEGER = "integer",
  NUMBER = "number",
  BOOLEAN = "boolean",
  STRING = "string",
}

export enum ESimulationArrayParamType {
  INTEGER = "integer",
  NUMBER = "number",
  STRING = "string",
}

export enum ELanguage {
  RU = "ru",
  EN = "en",
}

export interface IBooleanLang {
  ru: boolean;
  en: boolean;
}

export enum Specialties {
  CEO = "ceo",
  CAPTAIN = "captain",
  MARKETEER = "marketeer",
  SALES = "sales",
  SUPPLY = "supply",
  DOCTOR = "doctor",
}

export enum Teamnames {
  NURSERY = "nursery",
  SHOP = "shop",
}

export interface IAssessmentResults extends AssessmentDetailDto {
  rawMetaJson: SimulationListPlayerMetaInterface;
  params: IParams;
}

export interface ISimulationPlayer extends PGameDto {
  rawMetaJson: SimulationListPlayerMetaInterface;
}

export interface SimulationAdminInterface extends GameDto {
  rawMetaJson: SimulationListPlayerMetaInterface;
  paramsTemplates?: RatedParamsTemplatesInterface[];
}

export interface RatedParamsTemplatesInterface extends GameParamsTemplateDto {
  params: IParams;
}

export interface IAssessmentItemAdmin extends AdmAssessmentDto {
  params: IParams;
}

export type TParams = string | boolean | number | number[] | string[];

export interface IParams {
  [key: string]: TParams;
}

export interface ISimulationTemplate {
  id: string;
  title?: LocStrDto;
  minRate?: string;
  maxRate?: string;
  gameType: GameType;
  maxAreas?: number;
  minAreas?: number;
  maxPlayersByRole?: Record<string, string>;
  maxTeamsByName?: Record<string, string>;
  assessmentType?: AssessmentType;
  params: TTemplateParams;
  isDefault?: boolean;
}

export interface SimulationListPlayerMetaInterface {
  gameTypes: ISimulationListPlayerMetaGameTypes;
  info: SimulationListPlayerMetaInfoInterface;
  params?: IMetaParams;
  game_urls?: ISimulationUrls;
}

export interface ISimulationListPlayerMetaGameTypes {
  [key: string]: IGameType;
}

export type TUserAssessmentResult = Record<string, Result>;

export type TUserAssessmentResultParams = Record<string, TParams>;

export interface IRole {
  title: LocalizedStr255;
  desc?: LocalizedStr1024;
  minPlayers?: number;
  maxPlayers?: number;
}

export interface ITeam {
  roles: Record<string, IRole>;
  title: LocalizedStr255;
  desc?: LocalizedStr1024;
  minTeams: number;
  maxTeams: number;
}

export interface IGameType {
  supported: boolean;
  roles?: Record<string, IRole>;
  teams?: Record<string, ITeam>;
  results?: TUserAssessmentResult;
  chapters?: boolean;
  minPlayers?: number;
  maxPlayers?: number;
  minTeams?: number;
  maxTeams?: number;
  maxAreas?: number;
  minAreas?: number;
  maxCorps?: number;
  params?: IMetaParams;
}

export interface SimulationListPlayerMetaInfoInterface {
  title: LocStrDto;
  desc: LocStrDto;
  logoUrl: {
    ru: string;
    en?: string;
  };
  version: string;
}

export interface IMetaParams {
  [key: string]: SimulationListPlayerMetaParamsItemInterface;
}

export interface SimulationListPlayerMetaParamsItemInterface {
  required: boolean;
  title: LocStrDto;
  desc?: LocStrDto;
  type: ESimulationParamsType;
  default?: number | boolean | string;
  min?: number;
  max?: number;
  items?: {
    type: ESimulationArrayParamType;
    default: number;
    min: number;
    max: number;
    minItems?: number;
    maxItems?: number;
  };
}

export interface UserInfoInterface {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  emailVerified?: boolean;
  email?: string;
  enabled?: boolean;
  realmRoles?: string[];
  attributes: {
    login?: string[];
    birthdate?: string[];
    gender?: string[];
    region?: string[];
    district?: string[];
    locality?: string[];
    school?: string[];
    groupCode?: string[];
    phoneNumber?: string[];
    phoneNumberVerified?: string[];
    supervisedUnit?: string[];
    studyWorkPlace?: string[];
    workPhoneNumber?: string[];
    avatarUrl?: string[];
    resumeUrl?: string[];
    resumeFileName?: string[];
    locale?: string[];
    desiredStudyPlace?: string[];
    desiredSpecialty?: string[];
    middleName?: string[];
    country?: string[];
  };
  isStudent?: boolean;
  isEmployee?: boolean;
}

export interface IPagination {
  pageNum: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface ISimulationUrls {
  metaUrl: {
    ru: string;
    en: string;
  };
  createUrl: {
    ru: string;
    en: string;
  };
  playUrl: {
    ru: string;
    en: string;
  };
  tutorialPlayUrl: {
    ru: string;
    en: string;
  };
  ltdTutorialPlayUrl: {
    ru: string;
    en: string;
  };
  finishUrl: {
    ru: string;
    en: string;
  };
}

export enum EKfu {
  TEACHER_OF_MATHEMATICS_AND_PHYSICS = "teacherOfMathematicsAndPhysics",
  PRE_SCHOOL_TEACHER = "preschoolTeacher",
  JURISPRUDENCE = "jurisprudence",
  ECONOMY = "economy",
  APPLIED_INFORMATICS = "appliedInformatics",
  FUNDAMENTAL_AND_APPLIED_CHEMISTRY = "fundamentalAndAppliedChemistry",
  MECHATRONICS = "mechatronics",
}

// noinspection JSUnusedGlobalSymbols
export enum EAlabugaPolytech {
  INDUSTRIAL_AUTOMATION = "industrialAutomation",
  INDUSTRIAL_ROBOTICS = "industrialRobotics",
  ELECTRICAL_INSTALLATION = "electricalInstallation",
  LABORATORY_CHEMICAL_ANALYSIS = "laboratoryChemicalAnalysis",
  PYTHON_PROGRAMMING = "pythonProgramming",
  BIM_DESIGN_OF_BUILDINGS_AND_STRUCTURES = "bimDesignOfBuildingsAndStructures",
  MICROELECTRONICS = "microelectronics",
  JURISPRUDENCE = "jurisprudence",
  ECONOMY = "economy",
  BUSINESS_INFORMATICS_1C = "businessInformatics1c",
  MEDICAL_BUSINESS = "Medicine",
  INTERNATIONAL_PEDAGOGY = "InternationalPedagogy",
  INDUSTRIAL_MACHINERY = "IndustrialMachinery",
  CNC = "CNC",
  PROGRAMMING_MICROCONTROLLERS_IN_C_LANGUAGE = "ProgrammingMicrocontrollersInCLanguage",
  INDUSTRIAL_SAFETY = "IndustrialSafety",
}

// noinspection JSUnusedGlobalSymbols
export enum EAlabugaStart {
  LOGISTICS = "logistics",
  TOWER_CRANE_OPERATOR = "towerCraneOperator",
  WELDING_ENGINEERING = "weldingEngineering",
  HEAVY_EQUIPMENT_OPERATOR = "heavyEquipmentOperator",
  SERVICE_AND_HOSPITALITY = "serviceAndHospitality",
  CATERING_INDUSTRY = "cateringIndustry",
  FRONT_DESK_SERVICES = "frontDeskServices",
  TILING_WORK = "tilingWork",
  INSTALLATION_WORK = "installationWork",
  PRODUCTION_OPERATOR = "productionOperator",
}
