import { sample } from "effector";

import {
  $queryParams,
  fetchNotificationListFx,
  notificationDomain,
} from "./notifications";

import { queryParamsToGetNotificationListAdapter } from "../lib";

// Event

export const refreshNotificationList = notificationDomain.createEvent();

// Logic

sample({
  clock: refreshNotificationList,
  source: $queryParams,
  fn: queryParamsToGetNotificationListAdapter,
  target: fetchNotificationListFx,
});
