import classNames from "classnames";
import { FC, ReactElement, ReactNode } from "react";
import { useUnit } from "effector-react";
import { $appInterface } from "src/features/public/app-interface/model";
import "./base-panel.scss";

interface Props {
  isEmpty?: boolean;
  className?: string;
  children?: ReactNode;
}

export const BasePanel: FC<Props> = ({
  isEmpty,
  children,
  className,
}): ReactElement => {
  const { isHeaderShowed } = useUnit($appInterface);

  return (
    <div className={classNames("base-panel", className)}>
      {!isEmpty && (
        <div
          className={classNames("base-panel__children", className, {
            "base-panel__children--empty": isEmpty,
            "base-panel__children--header-hidden": !isHeaderShowed,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
