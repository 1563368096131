import React, { FC } from "react";
import CommentItem from "../comment-item/comment-item";
import { useUnit } from "effector-react";
import { $comments, $commentsServerTime } from "../../lib/comments";

const CommentsList: FC = () => {
  const comments = useUnit($comments);
  const serverTime = useUnit($commentsServerTime);

  const sortedComments = comments.sort((first, second) => {
    const firstDate = new Date(first.created_at);
    const secondDate = new Date(second.created_at);

    return firstDate.getTime() - secondDate.getTime();
  });

  return (
    <div className="comments-list">
      {sortedComments.map((comment) => (
        <CommentItem
          comment={comment}
          key={comment.comment_id}
          serverTime={serverTime}
        />
      ))}
    </div>
  );
};

export default CommentsList;
