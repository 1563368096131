import { FC, ReactElement } from "react";

import { useTranslation } from "react-i18next";

import { BasePopup, EPopupName } from "src/shared/components";

import "./email-verify-popup.scss";

interface Props {
  email: string;
}

export const EmailVerifyPopup: FC<Props> = ({ email }): ReactElement => {
  const { t } = useTranslation();

  return (
    <BasePopup name={EPopupName.EMAIL_VERIFY}>
      <div className="popup__email-verify">
        <div className="popup__email-verify__circle">
          <div className="popup__email-verify__icon" />
        </div>
        <div className="popup__email-verify__title">
          {t("popup.email.verify.title")}
        </div>
        <div className="popup__email-verify__text">
          {t("popup.email.verify.text")}
        </div>
        <div className="popup__email-verify__email">{email}</div>
        <div className="popup__email-verify__text">
          {t("popup.email.verify.checkSpam")}
        </div>
      </div>
    </BasePopup>
  );
};
