import { Socket } from "socket.io-client";

import {
  DistributeCmd,
  JoinHrCmd,
  JoinPlayerCmd,
  KickCmd,
  QuitHrCmd,
  QuitPlayerCmd,
  RoleCmd,
  StartCmd,
  TeamCmd,
  GetMemberInfoCmd,
} from "src/generated/ws4";

export const commands = {
  lobby: {
    sub: "lobbySub",
    unSub: "lobbyUnSub",
  },

  act: {
    sub: "actSub",
    unSub: "actUnSub",
  },

  roomPlayer: {
    join: "joinPlayerCmd",
    quit: "quitPlayerCmd",
  },

  roomHr: {
    join: "joinHrCmd",
    quit: "quitHrCmd",
    role: "roleCmd",
    team: "teamCmd",
    kick: "kickCmd",
    start: "startCmd",
    distribute: "distributeCmd",
  },

  nonRoomPlayer: {
    getMemberInfo: "getMemberInfoCmd",
  },
};

abstract class SocketCommander {
  protected socket: Socket;

  constructor(socket: Socket) {
    this.socket = socket;
  }
}

export class LobbySocketCommander extends SocketCommander {
  static of(socket: SocketCommander["socket"]): LobbySocketCommander {
    return new LobbySocketCommander(socket);
  }

  sub() {
    this.socket.emit(commands.lobby.sub);
    return this;
  }

  unSub() {
    this.socket.emit(commands.lobby.unSub);
    return this;
  }
}

export class ActSocketCommander extends SocketCommander {
  static of(socket: SocketCommander["socket"]): ActSocketCommander {
    return new ActSocketCommander(socket);
  }

  sub() {
    this.socket.emit(commands.act.sub);
    return this;
  }

  unSub() {
    this.socket.emit(commands.act.unSub);
    return this;
  }
}
export class LobbyPlayerSocketCommander extends SocketCommander {
  static of(socket: SocketCommander["socket"]): LobbyPlayerSocketCommander {
    return new LobbyPlayerSocketCommander(socket);
  }

  join(payload: JoinPlayerCmd) {
    this.socket.emit(commands.roomPlayer.join, payload);
    return this;
  }

  quit(payload: QuitPlayerCmd) {
    this.socket.emit(commands.roomPlayer.quit, payload);
    return this;
  }
}

export class LobbyHrSocketCommander extends SocketCommander {
  static of(socket: SocketCommander["socket"]): LobbyHrSocketCommander {
    return new LobbyHrSocketCommander(socket);
  }

  join(payload: JoinHrCmd) {
    this.socket.emit(commands.roomHr.join, payload);
    return this;
  }

  quit(payload: QuitHrCmd) {
    this.socket.emit(commands.roomHr.quit, payload);
    return this;
  }

  role(payload: RoleCmd) {
    this.socket.emit(commands.roomHr.role, payload);
    return this;
  }

  team(payload: TeamCmd) {
    this.socket.emit(commands.roomHr.team, payload);
    return this;
  }

  kick(payload: KickCmd) {
    this.socket.emit(commands.roomHr.kick, payload);
    return this;
  }

  start(payload: StartCmd) {
    this.socket.emit(commands.roomHr.start, payload);
    return this;
  }

  distribute(payload: DistributeCmd) {
    this.socket.emit(commands.roomHr.distribute, payload);
    return this;
  }
}

export class UpdateRoomSocketCommander extends SocketCommander {
  static of(socket: SocketCommander["socket"]): UpdateRoomSocketCommander {
    return new UpdateRoomSocketCommander(socket);
  }

  getPlayerInfo(payload: GetMemberInfoCmd) {
    this.socket.emit(commands.nonRoomPlayer.getMemberInfo, payload);
    return this;
  }
}
