import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
} from "src/shared/models/dataGrid";

import { getActiveGroup } from "src/shared/helpers/data-grids/getActiveGroup";
import { getOrderByValue } from "src/shared/helpers/data-grids/getOrderByValue";

import { QueryParams } from "../../../../shared/api/base";

import { t } from "../../../../shared/helpers/locale";

export const generateColumns = (
  queryGetParams: QueryParams = {},
): IDataGridColumn[] => {
  return [
    {
      key: "regEnabled",
      showed: true,
      titleUppercase: true,
      title: t("table.header.registration"),
      type: EDataGridColumnType.CHECKBOX,
      width: 0,
      minWidth: 0,
      controlPanel: {
        activeGroupKey: "all",
        title: t("table.header.registration"),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "regEnabled",
                type: EDataGridFilterType.CHECKBOX,
                value: queryGetParams.regEnabled || "",
                items: [
                  {
                    value: "true",
                    label: t("table.filters.permitted"),
                  },
                  {
                    value: "false",
                    label: t("table.filters.forbidden"),
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      key: "flag",
      showed: true,
      titleUppercase: true,
      title: t("table.header.flag"),
      type: EDataGridColumnType.IMAGE,
      width: 0,
      minWidth: 0,
    },
    {
      key: "code",
      showed: true,
      titleUppercase: true,
      title: t("table.header.countryCode"),
      type: EDataGridColumnType.STRING,
      width: 0,
      minWidth: 0,
      controlPanel: {
        title: t("table.header.countryCode"),
        activeGroupKey: getActiveGroup(
          ["code"],
          { all: ["code"] },
          queryGetParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "code",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryGetParams?.code || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryGetParams?.orderBy, [
                "code",
                "-code",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "code",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-code",
                },
              ],
            },
          },
        ],
      },
    },
    {
      key: "title",
      showed: true,
      titleUppercase: true,
      title: t("table.header.label"),
      type: EDataGridColumnType.STRING,
      controlPanel: {
        title: t("table.header.label"),
        activeGroupKey: getActiveGroup(
          ["title"],
          { all: ["title"] },
          queryGetParams,
        ),
        groups: [
          {
            key: "all",
            filters: [
              {
                key: "title",
                type: EDataGridFilterType.SEARCH,
                placeholder: t("common.enterValue"),
                value: queryGetParams?.title || "",
              },
            ],
            sort: {
              value: getOrderByValue(queryGetParams?.orderBy, [
                "title",
                "-title",
              ]),
              orderKey: "orderBy",
              items: [
                {
                  title: t("table.filters.alphabet.asc"),
                  value: "title",
                },
                {
                  title: t("table.filters.alphabet.desc"),
                  value: "-title",
                },
              ],
            },
          },
        ],
      },
    },
  ];
};
