import { TextWithIcon } from "src/shared/components";

import "./session-event-button.scss";

type Props = {
  onClick: () => void;
};

export const SessionEventButton = ({ onClick }: Props) => {
  return (
    <button className="session-event-button" onClick={onClick}>
      <TextWithIcon iconName="dice-white" iconSize={30} />
    </button>
  );
};
