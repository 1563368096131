import { TutorialApiGetTutorialChartRequest } from "src/generated/game";
import {
  DictionariesApiGetMapCountriesRequest,
  DictionariesApiGetMapRegionsRequest,
  PlayersApiGetPlayersGrowthRequest,
} from "src/generated/social";
import { gameService, socialService } from "src/shared/api/base/api-instances";

export const fetchMapChartRussia = (
  params?: DictionariesApiGetMapRegionsRequest,
) =>
  socialService
    .makePublicRequest()
    .getMapRegions(params)
    .then((response) => response.data);

export const fetchMapChartWorld = (
  params?: DictionariesApiGetMapCountriesRequest,
) =>
  socialService
    .makePublicRequest()
    .getMapCountries(params)
    .then((response) => response.data);

export const fetchPlayersGrowth = (params: PlayersApiGetPlayersGrowthRequest) =>
  socialService
    .makePlayerRequest()
    .getPlayersGrowth(params)
    .then((response) => response.data);

export const fetchPlayersCount = () =>
  socialService
    .makePlayerRequest()
    .getPlayersCount()
    .then((response) => response.data);

export const fetchRatingChart = () =>
  socialService
    .makeRatingsRequest()
    .getRatingChart()
    .then((response) => response.data);

export const fetchTutorialChart = (
  params: TutorialApiGetTutorialChartRequest,
) =>
  gameService
    .makeTutorialRequest()
    .getTutorialChart(params)
    .then((response) => response.data);
