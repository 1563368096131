import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { EPopupName } from "../../base-popup";
import { ConfirmPopup } from "../confirm-popup/confirm-popup";
import { useNavigate } from "react-router-dom";
import { closePopup } from "../../base-popup/model";

interface Props {}

export const WaitingRelativeStatus: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onConfirm = () => {
    closePopup({ name: EPopupName.WAITING_RELATIVE_STATUS_POPUP });
    localStorage.setItem("scrollToRelative", "true");
    navigate("/profile/info");
  };

  return (
    <ConfirmPopup
      popupName={EPopupName.WAITING_RELATIVE_STATUS_POPUP}
      title={t("relative.waitingStatus")}
      confirmBtnLabel={t("common.goToTable")}
      cancelBtnLabel={t("common.cancel")}
      description={t("relative.checkCurrentStatus")}
      onConfirm={onConfirm}
      isSafe
    />
  );
};
