import { socialService } from "../../../base/api-instances";
import {
  RelativeApiCancelConfirmationRelativeRequest,
  RelativeApiConfirmationRelativeRequest,
  RelativeApiGetCandidateRelativeTableRequest,
} from "src/generated/social";

export const getRelativeTable = (
  params: RelativeApiGetCandidateRelativeTableRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .getCandidateRelativeTable(params)
    .then((res) => res.data);
};

export const confirmationRelativePost = (
  params: RelativeApiConfirmationRelativeRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .confirmationRelative(params)
    .then((res) => res.data);
};

export const confirmationCancel = (
  params: RelativeApiCancelConfirmationRelativeRequest,
) => {
  return socialService
    .makeRelativeRequest()
    .cancelConfirmationRelative(params)
    .then((res) => res.data);
};
