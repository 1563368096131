import { AxiosResponse } from "axios";

import { call, put, takeLatest } from "redux-saga/effects";
import { openResponseErrorPopup } from "src/shared/components";

import {
  AdminGamesApiFactory,
  AdminGamesCalculationsApiFactory,
  Configuration,
} from "src/generated/game";

import { $axiosGame } from "src/shared/api/api";

import {
  failureGetSimulationItemAdmin,
  failurePullMetaAdmin,
  failurePutSimulationItemAdmin,
  successGetSimulationItemAdmin,
  successPullMetaAdmin,
  successPutSimulationItemAdmin,
} from "./actionCreators";

import {
  RequestGetSimulationItemAdminActionInterface,
  RequestPullMetaAdminActionInterface,
  RequestPutSimulationItemAdminActionInterface,
  SimulationItemAdminActionsType,
} from "./actionTypes";

export function* fetchGetSimulationItemAdmin({
  payload,
}: RequestGetSimulationItemAdminActionInterface) {
  const request = AdminGamesApiFactory(new Configuration(), "", $axiosGame);

  try {
    const response: AxiosResponse = yield call(request.getGame, payload.id);

    yield put(successGetSimulationItemAdmin(response.data));

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failureGetSimulationItemAdmin());
  }
}

export function* fetchPutSimulationItemAdmin({
  payload,
}: RequestPutSimulationItemAdminActionInterface) {
  const requestSimulation = AdminGamesApiFactory(
    new Configuration(),
    "",
    $axiosGame,
  );

  const requestCalcs = AdminGamesCalculationsApiFactory(
    new Configuration(),
    "",
    $axiosGame,
  );

  try {
    yield call(requestSimulation.putGame, payload.id, payload.simulationBody);
    yield call(requestCalcs.postUpdCalcs, payload.id, payload.calcsBody);

    yield put(successPutSimulationItemAdmin());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failurePutSimulationItemAdmin());
  }
}

export function* fetchPullMetaAdmin({
  payload,
}: RequestPullMetaAdminActionInterface) {
  const request = AdminGamesApiFactory(new Configuration(), "", $axiosGame);

  try {
    yield call(request.postGameMeta, payload.id);

    yield put(successPullMetaAdmin());

    if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    if (payload.errorCallback) {
      payload.errorCallback();
    }

    console.error(error);

    openResponseErrorPopup(error?.response?.data?.err?.msg);

    yield put(failurePullMetaAdmin());
  }
}

export function* simulationItemAdminSaga() {
  yield takeLatest(
    SimulationItemAdminActionsType.REQUEST_GET_SIMULATION_ITEM_ADMIN,
    fetchGetSimulationItemAdmin,
  );

  yield takeLatest(
    SimulationItemAdminActionsType.REQUEST_PUT_SIMULATION_ITEM_ADMIN,
    fetchPutSimulationItemAdmin,
  );

  yield takeLatest(
    SimulationItemAdminActionsType.REQUEST_PULL_META_ADMIN,
    fetchPullMetaAdmin,
  );
}
