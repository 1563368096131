import { TTemplateParams } from "../components/simulation-params-fields/simulation-params-fields";
import { EInputValidateType, IValidateValue } from "../hooks/useInput";
import {
  ESimulationArrayParamType,
  ESimulationParamsType,
  SimulationListPlayerMetaInterface,
  SimulationListPlayerMetaParamsItemInterface,
} from "../store/types";

export const getSimulationParamsErrors = (
  params: TTemplateParams,
  meta: SimulationListPlayerMetaInterface | null
) => {
  let errors: { [key: string]: IValidateValue } = {};
  const paramsKey: string[] = Object.keys(params);

  paramsKey.forEach((paramKey) => {
    const paramType: ESimulationParamsType = params[paramKey].type;
    const isBooleanType: boolean = paramType === ESimulationParamsType.BOOLEAN;

    if (isBooleanType) {
      return;
    }

    const metaParams = meta?.params;
    if (metaParams === undefined) {
      return
    }

    const metaArrayType: ESimulationArrayParamType | undefined =
      metaParams[paramKey].items?.type;

    const metaParamsItem:
      | SimulationListPlayerMetaParamsItemInterface
      | undefined = metaParams[paramKey];

    const isNumberType: boolean =
      paramType === ESimulationParamsType.INTEGER ||
      paramType === ESimulationParamsType.NUMBER;

    const isNumberArrayType: boolean =
      paramType === ESimulationParamsType.ARRAY &&
      (metaArrayType === ESimulationArrayParamType.NUMBER ||
        metaArrayType === ESimulationArrayParamType.INTEGER);

    const isStringArrayType: boolean =
      paramType === ESimulationParamsType.ARRAY &&
      metaArrayType === ESimulationArrayParamType.STRING;

    const minValue = (): number | undefined => {
      if (isNumberType) {
        return metaParamsItem?.min;
      }

      if (isNumberArrayType) {
        return metaParamsItem?.items?.min;
      }
    };

    const maxValue = (): number | undefined => {
      if (isNumberType) {
        return metaParamsItem?.max;
      }

      if (isNumberArrayType) {
        return metaParamsItem?.items?.max;
      }
    };

    const setValidateType = (): EInputValidateType => {
      if (isNumberArrayType) {
        return EInputValidateType.ARRAY_NUMBER;
      }

      if (isStringArrayType) {
        return EInputValidateType.ARRAY_STRING;
      }

      if (isNumberType) {
        return EInputValidateType.NUMBER;
      }

      return EInputValidateType.STRING;
    };

    errors[paramKey] = {
      value: params[paramKey].value as string | string[],
      type: setValidateType(),
      required: !!metaParamsItem?.required,
      min: minValue(),
      max: maxValue(),
    };
  });

  return errors;
};
