import { notificationService } from "src/shared/api/base/api-instances/notification-service";
import {
  ChangeSmsMessage,
  CreateSmsMessage,
  DeleteSmsMessage,
  GetSmsHistory,
  GetSmsList,
  GetSmsMessage,
  SendSmsMessage,
} from "./query";

export const getSmsListMessages = (params: GetSmsList) => {
  return notificationService
    .makeSmsNotificationRequest()
    .getSmsList(params)
    .then((res) => res.data);
};

export const getSmsMessage = (params: GetSmsMessage) => {
  return notificationService
    .makeSmsNotificationRequest()
    .getSms(params)
    .then((res) => res.data);
};

export const addSmsMessage = (params: CreateSmsMessage) => {
  return notificationService
    .makeSmsNotificationRequest()
    .postSms(params)
    .then((res) => res.data);
};

export const changeSmsMessage = (params: ChangeSmsMessage) => {
  return notificationService
    .makeSmsNotificationRequest()
    .putSms(params)
    .then((res) => res.data);
};

export const sendSmsMessage = (params: SendSmsMessage) => {
  return notificationService
    .makeSmsNotificationRequest()
    .sendSms(params)
    .then((res) => res.data);
};

export const deleteSmsMessage = (params: DeleteSmsMessage) => {
  return notificationService
    .makeSmsNotificationRequest()
    .delSms(params)
    .then((res) => res.data);
};

export const getSmsHistoryList = (params: GetSmsHistory) => {
  return notificationService
    .makeSmsNotificationRequest()
    .getSmsHistory(params)
    .then((res) => res.data);
};
