import { sample } from "effector";

import {
  changeSmsMessage,
  ChangeSmsMessage,
} from "src/shared/api/admin/notification/sms-notification";

import { $smsList, smsNotificationDomain } from "./sms-notification";

import { resetSmsText } from "./reset-sms";

// Events

export const changeSms = smsNotificationDomain.createEvent<ChangeSmsMessage>();

export const updateSmsText = smsNotificationDomain.createEvent<string>();

// Effect

export const changeSmsFx = smsNotificationDomain.createEffect(changeSmsMessage);

// Stores

export const $smsText = smsNotificationDomain.createStore<string>("");

// Logic

sample({ clock: changeSms, target: changeSmsFx });

sample({
  clock: changeSmsFx.done,
  source: $smsList,
  fn: (smsList, changedSms) => {
    return smsList.map((sms) => {
      if (sms.id !== changedSms.params.id) {
        return sms;
      }

      return {
        ...sms,
        text: changedSms.params.reqSms.text,
        uids: changedSms.params.reqSms.uids,
      };
    });
  },
  target: $smsList,
});

sample({ clock: updateSmsText, target: $smsText });

$smsText.reset(resetSmsText);
