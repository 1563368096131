import { FC, ReactElement, useMemo } from "react";

import classNames from "classnames";

import { getArray } from "src/shared/helpers";

import "./controls-connector.scss";

interface Props {
  length: number;
}

export const ControlsConnector: FC<Props> = ({ length }): ReactElement => {
  const isDuo: boolean = length === 2;

  const isTriplet: boolean = length === 3;

  const isMore: boolean = length > 3;

  const isMulti: boolean = length >= 3;

  const items: number[] = useMemo(() => {
    if (isDuo || isTriplet) {
      return getArray(1);
    }

    if (isMore) {
      return getArray(length - 2);
    }

    return [];
  }, [length, isDuo, isTriplet, isMore]);

  return (
    <div className="controls-connector">
      {items.map((_, index) => {
        const isFirstItem: boolean = index === 0 && isMulti;

        return (
          <div
            key={index}
            className={classNames("controls-connector__item", {
              "controls-connector__item--first": isFirstItem,
              "controls-connector__item--multi": isMulti,
            })}
          >
            {isMulti && <div className="controls-connector__item-minus" />}
          </div>
        );
      })}
    </div>
  );
};
