import { NavListItem } from "../../../../shared/components";
import {
  IGameType,
  ISimulationListPlayerMetaGameTypes,
} from "../../../../shared/store/types";
import { capitalizeFirstLetter } from "../../../../shared/helpers";
import i18n from "i18next";

const gameTypesOrder: Record<string, number> = {
  tutorial: 0,
  tutorial_ltd: 1,
  single: 2,
  solo: 3,
  team: 4,
  corp: 5,
};

export const getCustomTabsItems = (
  defaultUrl: string,
  gameTypes?: ISimulationListPlayerMetaGameTypes
) => {
  const generalTabItem: NavListItem = {
    title: i18n.t("simulationControl.simulationPage.tabLabel.general"),
    to: defaultUrl,
    access: true,
  };

  if (gameTypes) {
    const gameTypeKeys: string[] = Object.keys(gameTypes);
    const gameTypesOrderKeys: string[] = Object.keys(gameTypesOrder);

    const sortedKeys: string[] = gameTypeKeys
      .filter((item) => gameTypesOrderKeys.includes(item))
      .sort((left, right) => {
        return gameTypesOrder[left] - gameTypesOrder[right];
      });

    const noSortedKeys: string[] = gameTypeKeys.filter(
      (item) => !gameTypesOrderKeys.includes(item)
    );

    const combinedKeys: string[] = [...sortedKeys, ...noSortedKeys];

    return combinedKeys.reduce(
      (finalNavList: NavListItem[], current: string) => {
        const localGameType: IGameType | undefined = gameTypes?.[current];
        const isSortedGameType: boolean = gameTypesOrderKeys.includes(current);
        const title: string = isSortedGameType
          ? i18n.t(`simulationControl.simulationPage.tabLabel.${current}`)
          : capitalizeFirstLetter(current);

        if (localGameType) {
          finalNavList.push({
            title,
            to: `${defaultUrl}?gameType=${current}`,
            access: localGameType.supported,
          });
        }

        return finalNavList;
      },
      [generalTabItem]
    );
  }

  return [generalTabItem];
};
