import {
  FC,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { ResponseEmail } from "src/generated/notification";

import { QueryParams } from "src/shared/api/base";

import { getActiveGroup, getOrderByValue } from "src/shared/helpers/data-grids";

import { BaseInnerHtml, DataGrid } from "src/shared/components";

import { ITable } from "src/shared/components/data-grids/mailing-lists/types";

import { PreviewPopup } from "src/shared/components/popups/preview-popup/preview-popup";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
} from "src/shared/models/dataGrid";

import { emailNotificationModel } from "src/entities/admin/notification";
import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components/base-popup";

const { $emailsList, $isLoading, sendEmail, $pagination } =
  emailNotificationModel;

const { $activePopups, openPopup } = popupModel;

export const EmailMailingListTable: FC<ITable> = ({
  queryParams,
  updateQueryParams,
}): ReactElement => {
  const { t } = useTranslation();

  const activePopups = useUnit($activePopups);

  const pagination = useUnit($pagination);

  const isLoading = useUnit($isLoading);

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);

  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const [email, setEmail] = useState<ResponseEmail | undefined>(undefined);

  const emailsList = useUnit($emailsList);

  useMemo(
    () => activePopups.some(({ name }) => name === EPopupName.EMAIL_PREVIEW),
    [activePopups],
  );

  const showPreviewPopup = useCallback(
    (id: string) => {
      openPopup({
        name: EPopupName.EMAIL_PREVIEW,
        dataId: [{ name: EPopupDataIdName.DEFAULT, value: id }],
      });

      const currentEmail = emailsList.find((el) => el.id === id);

      currentEmail && setEmail(currentEmail);
    },
    [emailsList],
  );

  const onClickResend = useCallback(
    (id: string) =>
      sendEmail({
        id,
        reqEmailSender: { allEmails: true },
      }),
    [],
  );

  const goToPage = (pageNum: number, isReadmore?: boolean) => {
    if (updateQueryParams) {
      const params: QueryParams = { ...queryParams, pageNum: String(pageNum) };

      if (isReadmore) {
        params.mode = "readmore";
      } else {
        delete params.mode;
      }

      updateQueryParams(params);
    }
  };

  const generateColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: t("table.header.topic"),
        type: EDataGridColumnType.STRING,
        key: "topic",
        showed: true,
        width: 315,
        minWidth: 315,
        nowrap: false,
      },
      {
        title: t("table.header.content"),
        type: EDataGridColumnType.STRING,
        key: "content",
        showed: true,
        width: 515,
        minWidth: 515,
      },
      {
        title: t("table.header.sent"),
        type: EDataGridColumnType.DATETIME,
        key: "sent",
        showed: true,
        width: 200,
        minWidth: 200,
        controlPanel: {
          title: t("table.header.sent"),
          activeGroupKey: getActiveGroup(
            ["sent", "sentFrom", "sentTo"],
            { all: ["sent", "sentFrom", "sentTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "sentFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.createdFrom || "",
                  isHalf: true,
                },
                {
                  key: "sentTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.createdTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, ["sent", "-sent"]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.date.desc"),
                    value: "-sent",
                  },
                  {
                    title: t("table.filters.date.asc"),
                    value: "sent",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.recipients"),
        type: EDataGridColumnType.STRING,
        key: "recipients",
        showed: true,
        width: 200,
        minWidth: 200,
      },
      {
        title: t("table.header.preview"),
        width: 1,
        minWidth: 1,
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "previewButtonTitle",
        showed: true,
        noPaddingRight: true,
        buttonProps: {
          light: true,
          lightPurple: true,
          xSmall: true,
          xSmallRounded: true,
          disabled: false,
          onClick(rowId: string) {
            showPreviewPopup(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 119,
          },
          laptop: {
            right: 225,
          },
        },
      },
      {
        title: t("table.header.resend"),
        width: 1,
        minWidth: 1,
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "resendButtonTitle",
        showed: true,
        noPaddingRight: true,
        buttonProps: {
          primary: true,
          xSmall: true,
          xSmallRounded: true,
          disabled: isLoading,
          onClick(rowId: string) {
            onClickResend(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 0,
          },
          laptop: {
            right: 0,
          },
        },
      },
    ],
    [isLoading, showPreviewPopup, onClickResend, queryParams, t],
  );

  useEffect(() => {
    const rowsItems: IDataGridRow[] = emailsList.map((item): IDataGridRow => {
      const clearedText = <BaseInnerHtml description={item.text} />;
      return {
        key: item.id,
        tooltips: { content: { content: clearedText } },
        id: String(item.id),
        topic: item.subject,
        content: (
          <div className="notification-text-ellipsis">{clearedText}</div>
        ),
        sent: item.sent,
        recipients: item.uids.length,
        selected: false,
        previewButtonTitle: t("table.header.preview"),
        resendButtonTitle: t("table.header.resend"),
      };
    });

    setRows(rowsItems);
  }, [emailsList, t]);

  useEffect(() => {
    setColumns(generateColumns);
  }, [generateColumns]);

  return (
    <Fragment>
      <DataGrid
        rows={rows}
        columns={columns}
        queryParams={queryParams}
        onUpdateSortAndFilters={updateQueryParams}
        loading={isLoading}
        titleUppercase
        nowrap
        pagination={pagination}
        goToPage={goToPage}
        readmore={(pageNum) => goToPage(pageNum, true)}
      />
      <PreviewPopup newsletterFormData={email} />
    </Fragment>
  );
};
