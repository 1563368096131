import { $assessments } from "./assessments";

import { assessmentsDomain } from "./domain";

// Events

export const resetAssessments = assessmentsDomain.createEvent();

// Logic

$assessments.reset(resetAssessments);
