import styles from "./charts-page.module.scss";
import { TutorialChart } from "./tutorial-chart/tutorial-chart";
import { StatisticsChart } from "./statistics-chart/statistics-chart";
import { UsersChart } from "./users-chart/users-chart";

export const ChartsPage = () => {
  return (
    <div className={styles.wrapper}>
      <TutorialChart />
      <StatisticsChart />
      <UsersChart />
    </div>
  );
};
