import { ImagesApiUploadImgImagesPostRequest } from "src/generated/cdn";

import { cdnService } from "../../../base/api-instances/cdn-service";

export const uploadImage = (params: ImagesApiUploadImgImagesPostRequest) => {
  return cdnService
    .makeImagesRequest()
    .uploadImgImagesPost(params)
    .then((res) => res.data);
};
