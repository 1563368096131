import { memo, ReactElement } from "react";

import classNames from "classnames";

import { t } from "src/shared/helpers";

export const DataGridSessionStatus = memo(
  ({ isOnline }: { isOnline?: boolean }): ReactElement => {
    return (
      <span
        className={classNames("data-grid__session-status", {
          "data-grid__session-status--online": isOnline,
        })}
      >
        {isOnline
          ? t("assessment.player.status.online")
          : t("assessment.player.status.offline")}
      </span>
    );
  },
);
