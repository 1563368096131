import { LocStrDto } from "../../generated/social";
import { ELanguage } from "../store/types";

export const languagePicker = (
  item?: LocStrDto,
  currentLanguage?: string,
): string => {
  const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

  if (!item) {
    return "";
  }

  if (!currentLanguage) {
    currentLanguage = defaultLanguage;
  }

  return (
    item[currentLanguage as ELanguage] ||
    item[defaultLanguage as ELanguage] ||
    ""
  );
};
