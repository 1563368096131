export enum DragItem {
  PLAYER = "player",
}

export enum DropArea {
  UNASSIGNED_PLAYERS = "unassigned_players",
  CITY = "city",
  TEAM = "team",
  CEO = "ceo",
}
