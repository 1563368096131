import { createDomain, sample } from "effector";

import {
  PlayerRelativeInfoRes,
  RelativeApiGetStudentForLinkRequest,
} from "../../../../generated/social";
import { getStudentForLink } from "../../../../shared/api/public/relatives/relatives";
import { openPopup } from "../../../../shared/components/base-popup/model";
import {
  EPopupName,
  openResponseErrorPopup,
} from "../../../../shared/components";

export const getStudentForLinkDomain = createDomain("getStudentForLinkPublic");

// Effects

export const fetchGetStudentForLinkFx =
  getStudentForLinkDomain.createEffect(getStudentForLink);

// Stores

export const $studentFullName =
  getStudentForLinkDomain.createStore<PlayerRelativeInfoRes | null>(null);

export const $isLoading = getStudentForLinkDomain.createStore<boolean>(false);

// Events

export const fetchGetStudentForLink =
  getStudentForLinkDomain.createEvent<RelativeApiGetStudentForLinkRequest>();

sample({
  clock: fetchGetStudentForLink,
  target: fetchGetStudentForLinkFx,
});

sample({
  clock: fetchGetStudentForLinkFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchGetStudentForLinkFx.doneData,
  target: $studentFullName,
});

fetchGetStudentForLinkFx.doneData.watch(() => {
  openPopup({
    name: EPopupName.INVITATION_POPUP,
  });
});
fetchGetStudentForLinkFx.fail.watch(() => {
  openResponseErrorPopup("Incorrect link");
});
