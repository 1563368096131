import { ratingStatusesDomain } from "./domain";
import {
  GetPlayersRes,
  RejectionReasonsDto,
  RolesInRelatedProjectsDto,
  StatusesCompanyDto,
  StatusesEmploymentDto,
} from "src/generated/social";
import {
  FetchAllPlayers,
  getAllCompanyStatuses,
  getAllEmploymentStatuses,
  getAllPlayers,
  getAllRejectionReasons,
  getAllRelatedProject,
} from "../../../../shared/api/hr/rating-statuses";
import { sample } from "effector";

export enum employmentStatusesForEmployeesEnum {
  Call = "call",
  PlanToCome = "plan-to-come",
  CurrentIntramuralStage = "current-intramural-stage",
  PassedTheSelection = "passed-the-selection",
  NoSelectionPass = "no-selection-pass",
  SubmittedDocuments = "submitted-documents",
  InTheProcessOfEmployment = "in-the-process-of-employment",
  Employed = "employed",
  NoSelected = "no-selected",
}

//stores
export const $employmentStatuses =
  ratingStatusesDomain.createStore<StatusesEmploymentDto>({
    statusesEmployment: [],
  });

export const $companyStatuses =
  ratingStatusesDomain.createStore<StatusesCompanyDto>({ statusesCompany: [] });

export const $rejectionReasons =
  ratingStatusesDomain.createStore<RejectionReasonsDto>({
    rejectionReasons: [],
  });

export const $relatedProjects =
  ratingStatusesDomain.createStore<RolesInRelatedProjectsDto>({
    rolesInRelatedProject: [],
  });

export const $allPlayers = ratingStatusesDomain.createStore<GetPlayersRes>({
  players: [],
});

export const $isLoadingPlayers =
  ratingStatusesDomain.createStore<boolean>(true);

//events
export const fetchAllEmploymentStatuses = ratingStatusesDomain.createEvent();

export const fetchAllCompanyStatuses = ratingStatusesDomain.createEvent();
export const fetchAllRejectionReasons = ratingStatusesDomain.createEvent();

export const fetchAllRelatedProjects = ratingStatusesDomain.createEvent();

export const fetchAllPlayers =
  ratingStatusesDomain.createEvent<FetchAllPlayers>();

//effects

export const fetchAllEmploymentStatusesFx = ratingStatusesDomain.createEffect(
  getAllEmploymentStatuses,
);

export const fetchAllCompanyStatusesFx = ratingStatusesDomain.createEffect(
  getAllCompanyStatuses,
);

export const fetchAllRejectionReasonsFx = ratingStatusesDomain.createEffect(
  getAllRejectionReasons,
);

export const fetchAllRelatedProjectFx =
  ratingStatusesDomain.createEffect(getAllRelatedProject);

export const fetchAllPlayersFx =
  ratingStatusesDomain.createEffect(getAllPlayers);

sample({
  clock: fetchAllEmploymentStatuses,
  target: fetchAllEmploymentStatusesFx,
});

sample({
  clock: fetchAllCompanyStatuses,
  target: fetchAllCompanyStatusesFx,
});

sample({
  clock: fetchAllRejectionReasons,
  target: fetchAllRejectionReasonsFx,
});

sample({
  clock: fetchAllRelatedProjects,
  target: fetchAllRelatedProjectFx,
});

sample({
  clock: fetchAllPlayers,
  target: fetchAllPlayersFx,
});

sample({
  clock: fetchAllEmploymentStatusesFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $employmentStatuses,
});

sample({
  clock: fetchAllCompanyStatusesFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $companyStatuses,
});

sample({
  clock: fetchAllRejectionReasonsFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $rejectionReasons,
});

sample({
  clock: fetchAllRelatedProjectFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $relatedProjects,
});

sample({
  clock: fetchAllPlayersFx.pending,
  target: $isLoadingPlayers,
});

sample({
  clock: fetchAllPlayersFx.doneData,
  fn: (res: any) => {
    return res;
  },
  target: $allPlayers,
});
