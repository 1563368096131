import { FC, useRef } from "react";
import styles from "../../session-table/session-table.module.scss";
import { BaseTooltip } from "../../../../../../shared/components";
import { useTranslation } from "react-i18next";

interface Props {
  connected: number;
  maximum: number;
}

export const MembersTooltip: FC<Props> = ({ connected, maximum }) => {
  const { t } = useTranslation();

  const maxPlayersTooltip = useRef<HTMLSpanElement | null>(null);

  return (
    <div>
      <span>{`${t("common.members")}: ${connected}`}</span>
      <span
        className={`tooltip-wrapper ${styles.listLength}`}
        ref={maxPlayersTooltip}
      >
        /{maximum}
        <BaseTooltip tooltipToggleRef={maxPlayersTooltip}>
          <div className="session__board">
            <div className="session__board-section">
              <div className="session__board-title">
                {t("table.header.members")}
              </div>
              <div className="session__board-grid">
                <div className="session__board-block">
                  <div className="session__board-label">
                    {t("sessions.session.members.connected")}
                  </div>
                  <div className="session__board-count">{connected}</div>
                </div>
                <div className="session__board-block">
                  <div className="session__board-label">
                    {t("sessions.session.members.max")}
                  </div>
                  <div className="session__board-count">{maximum}</div>
                </div>
              </div>
            </div>
          </div>
        </BaseTooltip>
      </span>
    </div>
  );
};
