import { Player } from "../../../../generated/social";
import { RootState } from "../../store";
import { ERequestStatus } from "../../types";
import { ProfileCompetenceStateInterface } from "./type";

export const selectProfileCompetence = (
  state: RootState,
): ProfileCompetenceStateInterface => state.profileCompetence;

export const selectProfileCompetenceItem = (state: RootState): Player | null =>
  selectProfileCompetence(state).profileCompetence;

export const selectProfileCompetenceIsSocial = (state: RootState): boolean =>
  selectProfileCompetence(state).isSocial;

export const selectProfileCompetenceStatus = (
  state: RootState,
): ERequestStatus => selectProfileCompetence(state).status;
