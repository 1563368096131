import { FC, ReactElement } from "react";
import { reflect } from "@effector/reflect";

import { DataGrid as DataGridOriginal } from "src/shared/components/data-grid/data-grid-lazy";

import { Pagination, QueryParams } from "../../../../shared/api/base";

import { IDataGridColumn, IDataGridRow } from "src/shared/models/dataGrid";

import {
  $columns,
  $isSomeLoading,
  $pagination,
  $rows,
  toggleCountryRegEnabled,
} from "../model";

interface DataGridCountersProps {
  rows: IDataGridRow[];
  columns: IDataGridColumn[];
  pagination: Pagination;
  isLoading: boolean;
  queryParams: QueryParams;
  toggleRegEnabled: (code: string) => void;
  updateQueryParams?: (params: QueryParams) => void;
}

const View: FC<DataGridCountersProps> = ({
  rows,
  columns,
  pagination,
  isLoading,
  queryParams = {},
  toggleRegEnabled,
  updateQueryParams,
}): ReactElement => {
  const onCheckBoxChange = (code: string, key: string) => {
    if (key === "regEnabled") {
      toggleRegEnabled(code);
    }
  };

  const goToPage = (pageNum: number, isReadmore?: boolean) => {
    if (updateQueryParams) {
      const params: QueryParams = { ...queryParams, pageNum: String(pageNum) };

      if (isReadmore) {
        params.mode = "readmore";
      } else {
        delete params.mode;
      }

      updateQueryParams(params);
    }
  };

  return (
    <DataGridOriginal
      rows={rows}
      columns={columns}
      loading={isLoading}
      pagination={pagination}
      changeCheckbox={onCheckBoxChange}
      queryParams={queryParams}
      onUpdateSortAndFilters={updateQueryParams}
      goToPage={goToPage}
      readmore={(pageNum) => goToPage(pageNum, true)}
      nowrap
    />
  );
};

export const DataGrid = reflect<DataGridCountersProps>({
  view: View,
  bind: {
    rows: $rows,
    isLoading: $isSomeLoading,
    pagination: $pagination,
    columns: $columns,
    toggleRegEnabled: toggleCountryRegEnabled,
  },
});
