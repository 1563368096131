import Keycloak from "keycloak-js";

interface Params {
  $keycloak: Keycloak | null;
  $isPlayerClientId: boolean;
  $isHrClientId: boolean;
  $isAdminClientId: boolean;
  $isPlayerRole: boolean;
  $isHrStaffRole: boolean;
  $isHrAlabugaRole: boolean;
  $isHrAdminRole: boolean;
}

export const verifyClient = ({
  $keycloak,
  $isPlayerClientId,
  $isHrClientId,
  $isAdminClientId,
  $isPlayerRole,
  $isHrStaffRole,
  $isHrAlabugaRole,
  $isHrAdminRole,
}: Params) => {
  if ($keycloak) {
    const isFakePlayer: boolean = $isPlayerClientId && !$isPlayerRole;
    const isFakeHr: boolean =
      $isHrClientId && !$isHrStaffRole && !$isHrAlabugaRole;
    const isFakeAdmin: boolean = $isAdminClientId && !$isHrAdminRole;

    if (isFakePlayer || isFakeHr || isFakeAdmin) {
      $keycloak.logout();
    }
  }
};
