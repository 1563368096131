import { FC, memo } from "react";

import { IDataGridRowTooltipItem } from "src/shared/models/dataGrid";

import { getFormattedDatetime } from "src/shared/helpers/datetime";

interface Props {
  item: IDataGridRowTooltipItem;
  dateFormat?: string;
}

export const DataGridFieldTooltipItem: FC<Props> = memo(
  ({ item, dateFormat }) => {
    let value = item.value;

    const isDateTime = item.type === "datetime";

    if (isDateTime) {
      value = getFormattedDatetime(item.value, dateFormat);
    }

    return (
      <div className="data-grid__dropdown-list-item">
        <dt className="data-grid__dropdown-list-item-label">{item.key}</dt>
        <dd className="data-grid__dropdown-list-item-value">{value}</dd>
      </div>
    );
  },
);
