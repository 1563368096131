import { ChangeEvent, FC, ReactElement } from "react";

import { useTranslation } from "react-i18next";

import { useStore } from "effector-react";

import { IBaseInputChange } from "src/shared/models/base-input";

import { RecipientsForm } from "src/shared/components/recipients-form/recipients-form";

import {
  AvatarUpload as NotificationImageUpload,
  BaseBox,
  BaseInputText,
  BaseTextarea,
  FormControl,
} from "src/shared/components";

import "./notification-newsletter-form.scss";

import { notificationModel } from "src/entities/admin/notification";
import { formDataModel } from "src/entities/admin/notification";

interface NotificationsProps {
  formDataErrors: Record<string, string>;
  uploadAvatarBlockDescription: string[];
  onChangeTextAreaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeHandler: (payload: IBaseInputChange) => void;
}

export const NotificationsNewsletterForm: FC<NotificationsProps> = ({
  uploadAvatarBlockDescription,
  formDataErrors,
  onChangeHandler,
}): ReactElement => {
  const { t } = useTranslation();

  const { $notificationTxt, updateTxtNotification, uploadFile } =
    notificationModel;

  const values = useStore($notificationTxt);

  const onChange = (e: IBaseInputChange) => {
    if (e.name) {
      updateTxtNotification({ ...values, [e.name]: e.value });
    }
    onChangeHandler({ value: e.value, name: e.name });
  };

  const onChangeNotificationImage = (file?: File) => {
    if (file) {
      uploadFile({ file });
      return;
    }

    updateTxtNotification({ ...values, logoUrl: "" });
  };

  const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeHandler({ value: e.target.value, name: "body" });
    updateTxtNotification({ ...values, body: e.target.value });
  };

  return (
    <div className="notifications-newsletter-form__info-page">
      <div className="notifications-newsletter-form__info-page-form">
        <BaseBox className="notifications-newsletter-form__info-page-data">
          <div className="notifications-newsletter-form__base-box-title">
            {t("notifications.newsLetter.form.required")}
          </div>
          <div className="notifications-newsletter-form__base-box-item">
            <FormControl error={formDataErrors.title}>
              <BaseInputText
                name="title"
                value={values.title}
                onChange={onChange}
                placeholder={t("notifications.newsLetter.form.header")}
                maxLength={30}
                error={!!formDataErrors.title}
                required
              />
            </FormControl>
          </div>
          <div className="notifications-newsletter-form__base-box-item">
            <FormControl error={formDataErrors.body}>
              <BaseTextarea
                required
                name="body"
                label={`${t("notifications.newsLetter.form.content")}`}
                value={values.body}
                error={!!formDataErrors.body}
                maxLength={500}
                onChange={onChangeTextArea}
              />
            </FormControl>
          </div>
          <div className="notifications-newsletter-form__base-box-item">
            <FormControl error={formDataErrors.link}>
              <BaseInputText
                name="link"
                value={values.link || ""}
                onChange={onChange}
                placeholder={t("notifications.newsLetter.form.link")}
                maxLength={30}
                error={!!formDataErrors.link}
                required
              />
            </FormControl>
          </div>
        </BaseBox>
        <NotificationImageUpload
          title={t("notifications.newsLetter.form.uploadImage.title")}
          descriptions={uploadAvatarBlockDescription}
          avatarUrl={values.logoUrl || ""}
          onChange={onChangeNotificationImage}
          cropTitle={t("notifications.newsLetter.form.uploadImage.title")}
          formats={["image/jpeg", "image/jpg"]}
          fileSize={2097152}
          isCircleCrop={false}
        />
        <RecipientsForm name="radioValue" />
      </div>
    </div>
  );
};
