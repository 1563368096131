import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnit } from "effector-react";
import { BaseButton, ConfirmPopup } from "../../../shared/components";
import {
  $assessmentsListAdmin,
  requestAssessmentsListAdmin,
  setAssessmentsListAdminIsReadmore,
} from "src/entities/public/assessments-list-admin";
import { ERequestStatus } from "../../../shared/store/types";
import { AssessmentStatus, AssessmentType } from "../../../generated/game";
import {
  requestForceTerminateAssessments,
  $forceTerminateAssessments,
} from "src/entities/public/force-terminate-assessments";
import { TDataGridDataParams } from "../../../shared/models";
import { ActiveAssessmentsTable } from "../../../shared/components/data-grids/assessments-control/active-assessments-table";
import { useUrlState } from "src/shared/hooks/useUrlState";
import { getDateForFilter } from "../../../shared/helpers";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import { PageWrapper } from "../../../shared/components/page-wrapper/page-wrapper";
import "./active-assessments-page.scss";

const { openPopup } = popupModel;

export const ActiveAssessmentsPage = () => {
  const { t } = useTranslation();

  const {
    items: assessments,
    pagination,
    status: assessmentsStatus,
  } = useUnit($assessmentsListAdmin);

  const { status: forceTerminateAssessmentsStatus } = useUnit(
    $forceTerminateAssessments,
  );

  const [urlState, setUrlState] = useUrlState();
  const [checkedList, setCheckedList] = useState<string[]>([]);

  const isAssessmentsLoading: boolean =
    assessmentsStatus === ERequestStatus.LOADING;

  const isTerminateLoading: boolean =
    forceTerminateAssessmentsStatus === ERequestStatus.LOADING;

  const assessmentTypesList = useMemo<AssessmentType[]>(
    () => Object.values(AssessmentType),
    [],
  );

  useEffect(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      title,
      gameTitle,
      curPlayersFrom,
      curPlayersTo,
      createdFrom,
      createdTo,
      startedFrom,
      startedTo,
      firstName,
      lastName,
    } = urlState.query;

    const assessmentTypesQuery = (urlState.query.assessmentType?.split(",") ??
      []) as AssessmentType[];

    requestAssessmentsListAdmin({
      params: {
        localeQuery: "ru",
        assessmentStatusQuery: [AssessmentStatus.InProgress],
        pageSize: pageSize ? Number(pageSize) : 50,
        pageNum: pageNum ? Number(pageNum) : 1,
        orderByQuery: orderBy,
        titleQuery: title ? [title] : undefined,
        gameTitleQuery: gameTitle ? [gameTitle] : undefined,
        assessmentTypeQuery: assessmentTypesQuery.filter((type) =>
          assessmentTypesList.includes(type),
        ),
        curPlayersFromQuery: curPlayersFrom
          ? Number(curPlayersFrom)
          : undefined,
        curPlayersToQuery: curPlayersTo ? Number(curPlayersTo) : undefined,
        createdFromQuery: getDateForFilter(createdFrom),
        createdToQuery: getDateForFilter(createdTo),
        startedFromQuery: getDateForFilter(startedFrom),
        startedToQuery: getDateForFilter(startedTo),
        firstName,
        lastName,
      },
    });
  }, [urlState.query]);

  const onUpdateQueryParams = (newQueryParams: TDataGridDataParams) => {
    setUrlState({
      ...urlState,
      query: { ...newQueryParams, pageNum: "1" },
    });
  };

  const goToPage = (pageNum: number, isReadMore: boolean = false) => {
    setAssessmentsListAdminIsReadmore({ value: isReadMore });

    setUrlState({
      ...urlState,
      query: { ...urlState.query, pageNum: String(pageNum) },
    });
  };

  const clearSelectedAssessments = () => {
    setCheckedList((prevState) => {
      prevState.length = 0;
      return [];
    });
  };

  const terminateAssessment = () => {
    const callback = () => {
      requestAssessmentsListAdmin({
        params: {
          localeQuery: "ru",
          assessmentStatusQuery: [AssessmentStatus.InProgress],
          pageSize: 50,
        },
      });
      clearSelectedAssessments();
    };

    requestForceTerminateAssessments({
      body: { ids: checkedList },
      callback,
    });
  };

  const openTerminatePopup = () => {
    openPopup({ name: EPopupName.FORCE_TERMINATE });
  };

  const getSelectedRow = useCallback(
    (idList: string[]) => {
      setCheckedList(idList);
    },
    [setCheckedList],
  );

  return (
    <PageWrapper
      title={t("assessmentsControl.title")}
      isLoadingPanel={isAssessmentsLoading}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("assessmentsControl.controlPanel.label.assessments")}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={checkedList.length}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <BaseButton
          small
          primary
          disabled={!checkedList.length}
          onClick={openTerminatePopup}
        >
          {t("usersControl.controlPanel.btn.forceTerminate")}
        </BaseButton>
      }
    >
      <div className="active-assessments-page">
        <div className="active-assessments-data-grid-wrapper">
          <ActiveAssessmentsTable
            assessmentItems={assessments}
            queryParams={urlState.query}
            onSelectRows={getSelectedRow}
            selectedRowsIds={checkedList}
            loading={isAssessmentsLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            pagination={pagination}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum, true)}
          />
        </div>
      </div>

      <ConfirmPopup
        popupName={EPopupName.FORCE_TERMINATE}
        title={t("usersControl.popup.forceTerminateAssessments.title")}
        description={t(
          "usersControl.popup.forceTerminateAssessments.description",
        )}
        confirmBtnLabel={t(
          "usersControl.popup.forceTerminateAssessments.confirmBtn",
        )}
        onConfirm={terminateAssessment}
        isConfirmBtnDisabled={isTerminateLoading}
      />
    </PageWrapper>
  );
};
