import { QueryParams } from "src/shared/api/base";
import { GetSmsList } from "src/shared/api/admin/notification/sms-notification";

export const queryParamsToGetSmsListAdapter = (
  queryParams: QueryParams = {},
): GetSmsList => ({
  orderBy: queryParams.orderBy ? [queryParams.orderBy] : undefined,
  pageNum: queryParams.pageNum ? Number(queryParams.pageNum) : 1,
  pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 50,
});
