import { KeycloakInstance } from "keycloak-js";

import { toCloneObject } from "src/shared/helpers";

import {
  IUserSettings,
  TUserSettingsTables,
} from "src/entities/public/user-settings";

import { ISetColumnsVisibilityPayload } from "../model/types";

export const tableSettingsItemsRatings: string[] = [
  "fullName",
  "gender",
  "phone",
  "email",
  "groupCode",
  "birthdate",
  "communication",
  "communicationTenacity",
  "analytics",
  "analyticsTenacity",
  "command",
  "commandTenacity",
  "created",
  "lastActivity",
  "lastPlay",
  "country",
  "region",
  "district",
  "school",
  "locality",
  "studyWorkPlace",
  "desiredStudyPlace",
  "desiredSpecialty",
  "acquisitionChannel",
  "date_of_getting_analytics_lvl_1",
  "date_of_getting_analytics_lvl_0_5",
  "statusEmployment",
  "statusCompany",
  "rejectionReason",
  "rolesInRelatedProjects",
  "confirmationSingle",
  "additionalCompetencies",
  "secretCode",
];

export const setColumnsVisibilityHandler = (
  table: TUserSettingsTables | null,
  { tableName, value, columnName }: ISetColumnsVisibilityPayload,
) => {
  if (table) {
    const localPrev = toCloneObject(table);

    localPrev[tableName].columnsVisibility[columnName] = value;
    const columnsValues: boolean[] = Object.values(
      localPrev[tableName].columnsVisibility,
    );

    columnsValues.some((value) => value);

    // if (!isSomeActiveColumns) {
    //   return table;
    // }
    return localPrev;
  } else {
    return {
      [tableName]: {
        columnsVisibility: {
          [columnName]: value,
        },
      },
    };
  }
};

export const saveTableSettingsHandler = ({
  userSettings,
  preTablesSettings,
  keycloak,
}: {
  userSettings: IUserSettings | null;
  preTablesSettings: TUserSettingsTables | null;
  keycloak: KeycloakInstance | null;
}) => {
  let localPrev = toCloneObject(userSettings);

  if (localPrev && preTablesSettings) {
    localPrev.tables = preTablesSettings;
  } else if (preTablesSettings) {
    localPrev = {
      tables: preTablesSettings,
    };
  }

  return {
    profile: {
      payload: localPrev || {},
    },
    pId: keycloak?.subject || "",
  };
};
