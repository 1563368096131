import { socialService } from "../../../base/api-instances";

import { GetUserSettingsParams, UpdateUserSettingsParams } from "./query";

export const getUserSettings = (params: GetUserSettingsParams) =>
  socialService
    .makeUserSettingsRequest()
    .getUserProfile(params)
    .then(({ data }) => data);

export const updateUserSettings = (params: UpdateUserSettingsParams) =>
  socialService
    .makeUserSettingsRequest()
    .postUserProfile(params)
    .then(({ data }) => data);
