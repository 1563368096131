import { useEffect, useState } from "react";

const defaultConfig = { attributes: true, childList: true, subtree: true };

export const useMutationObservable = (
  target: HTMLElement,
  callback: (e: MutationRecord[]) => void,
  config: MutationObserverInit = defaultConfig,
) => {
  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    const localObserver = new MutationObserver(callback);

    setObserver(localObserver);
  }, [callback]);

  useEffect(() => {
    if (!observer) {
      return;
    }

    observer.observe(target, config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, target, config]);
};
