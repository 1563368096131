import { ChangeEvent, FC, ReactElement } from "react";
import { ERegEx, regExList } from "../../../constants/regExList";
import { IBaseInput } from "../../../models/base-input";
import { InputTemplate } from "../input-template/input-template";

interface Props extends IBaseInput {}

export const BaseInputEmail: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  onChange,
  required,
  isSmall,
  forDarkBg,
  isContextBtnDisabled,
  readOnly,
  onDelete,
  onRefresh,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const localValue = target.value.replace(regExList[ERegEx.ONLY_SPACES], "");

    onChange({
      value: localValue,
      name: target.name,
    });
  };

  return (
    <InputTemplate
      type="email"
      value={value}
      onChange={onChangeHandler}
      name={name}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      onRefresh={onRefresh}
      onDelete={onDelete}
    />
  );
};
