import classNames from "classnames";
import { CSSProperties, memo, MouseEvent, useMemo } from "react";

import { useMediaQuery } from "src/shared/hooks/useMediaQuery";

import {
  EDataGridColumnType,
  EDataGridTextColor,
  EDataGridTextWeight,
  IDataGridColumn,
  IDataGridRow,
} from "src/shared/models/dataGrid";
import { getColumnStyles } from "../../utils";
import { DataGridCol } from "./data-grid-col";

import { DataGridFieldTooltip } from "./data-grid-field-tooltip";

interface Props {
  row: IDataGridRow;
  column: IDataGridColumn;
  contentFontWeight?: EDataGridTextWeight;
  selectedRows: string[];
  nowrap?: boolean;
  hiddenHeader?: boolean;
  selectRow: (row: IDataGridRow) => void;
  changeCheckbox?: (id: string, key: string, value: boolean) => void;
}

export const DataGridColWrapper = memo<Props>(
  ({
    row,
    column,
    contentFontWeight,
    nowrap,
    hiddenHeader,
    selectedRows,
    selectRow,
    changeCheckbox,
  }) => {
    const { isMobile, isTablet, isLaptop } = useMediaQuery();

    const getTdClasses = (
      dataGridColumn: IDataGridColumn,
      dataGridRow?: IDataGridRow,
    ): string => {
      const externalClassesSet = new Set<string>();
      const externalClasses: string[] = [];

      const isEmptyValue =
        dataGridRow?.[dataGridColumn.key] === undefined ||
        dataGridRow?.[dataGridColumn.key] === null ||
        dataGridRow?.[dataGridColumn.key] === "";

      if (
        dataGridRow?.columnsTextColor?.[dataGridColumn.key] &&
        !isEmptyValue
      ) {
        externalClassesSet.add(
          `data-grid__td--color_${dataGridRow?.columnsTextColor?.[
            dataGridColumn.key
          ]}`,
        );
      }

      if (isEmptyValue) {
        externalClassesSet.add(
          `data-grid__td--color_${EDataGridTextColor.GRAY}`,
        );
      }

      if (contentFontWeight) {
        externalClassesSet.add(
          `data-grid__td--font-weight_${contentFontWeight}`,
        );
      }

      if (dataGridColumn.fontWeight) {
        externalClassesSet.add(
          `data-grid__td--font-weight_${dataGridColumn.fontWeight}`,
        );
      }

      if (dataGridColumn.type) {
        externalClassesSet.add(`data-grid__td--type_${dataGridColumn.type}`);
      }

      if (dataGridColumn.textAlign) {
        externalClassesSet.add(
          `data-grid__td--text-align_${dataGridColumn.textAlign}`,
        );
      }

      externalClassesSet.forEach((className) =>
        externalClasses.push(className),
      );

      return classNames(
        "data-grid__td",
        {
          "data-grid__td--nowrap": dataGridColumn.nowrap || nowrap,
          "data-grid__td--no-padding_right": dataGridColumn.noPaddingRight,
          "data-grid__td--no-padding_left": dataGridColumn.noPaddingLeft,
          "data-grid__td--sticky": dataGridColumn.sticky,
        },
        [...externalClasses],
      );
    };

    const getTdStyles = (
      dataGridColumn: IDataGridColumn,
      options: { hasWidthStyles?: boolean } = {},
    ): CSSProperties => ({
      ...getColumnStyles(dataGridColumn, isMobile, isTablet, isLaptop, options),
    });

    const onClickOnColumn = (
      evt: MouseEvent<HTMLTableCellElement>,
      dataGridColumn: IDataGridColumn,
    ) => {
      if (dataGridColumn.type === EDataGridColumnType.SELECT_ROW) {
        evt.stopPropagation();
      }
    };

    const hasTooltip = useMemo<boolean>(() => {
      if (row[column.key] === undefined || row[column.key] === null) {
        return false;
      }

      if (
        column.type === EDataGridColumnType.BUTTON ||
        column.type === EDataGridColumnType.SELECT_ROW
      ) {
        return false;
      }

      return Boolean(row.tooltips?.[column.key]);
    }, [column, row]);

    return (
      <td
        className={getTdClasses(column, row)}
        style={getTdStyles(column, {
          hasWidthStyles: hiddenHeader,
        })}
        key={row.id + "-" + column.key}
        onClick={(evt) => onClickOnColumn(evt, column)}
      >
        {hasTooltip && (
          <DataGridFieldTooltip
            items={row.tooltips?.[column.key]?.items}
            content={row.tooltips?.[column.key]?.content}
          >
            <DataGridCol
              row={row}
              column={column}
              selectedRows={selectedRows}
              selectRow={selectRow}
              changeCheckbox={changeCheckbox}
            />
          </DataGridFieldTooltip>
        )}

        {!hasTooltip && (
          <DataGridCol
            row={row}
            column={column}
            selectedRows={selectedRows}
            selectRow={selectRow}
            changeCheckbox={changeCheckbox}
          />
        )}
      </td>
    );
  },
);
