import { EInputValidateType, IValidateValue } from "../hooks/useInput";
import { District, Region, School } from "../../generated/social";
import { FormDataInterface } from "../components";

export const commonOptions = (
  formData: FormDataInterface,
): Record<string, IValidateValue> => ({
  firstName: {
    type: EInputValidateType.STRING,
    value: formData.firstName,
    required: true,
  },
  lastName: {
    type: EInputValidateType.STRING,
    value: formData.lastName,
    required: true,
  },
  email: {
    type: EInputValidateType.EMAIL,
    value: formData.email,
    required: true,
  },
  birthdate: {
    type: EInputValidateType.STRING,
    value: formData.birthdate,
    required: true,
  },
});

export const additionalOptions = (
  formData: FormDataInterface,
  regions: Region[],
  districts: District[],
): Record<string, IValidateValue> => ({
  country: {
    type: EInputValidateType.STRING,
    value: formData.country,
    required: true,
  },
  region: {
    type: EInputValidateType.STRING,
    value: formData.region,
    required: !!regions.length,
  },
  district: {
    type: EInputValidateType.STRING,
    value: formData.district,
    required: !!districts.length,
  },
  locality: {
    type: EInputValidateType.STRING,
    value: formData.locality,
    required: true,
  },
  studyWorkPlace: {
    type: EInputValidateType.STRING,
    value: formData.studyWorkPlace,
    required: true,
  },
});

export const hrOptions = (
  formData: FormDataInterface,
): Record<string, IValidateValue> => ({
  supervisedUnit: {
    type: EInputValidateType.STRING,
    value: formData.supervisedUnit,
    required: true,
  },
  workPhoneNumber: {
    type: EInputValidateType.STRING,
    value: formData.workPhoneNumber,
    required: true,
  },
});

export const playerOptions = (
  formData: FormDataInterface,
  schools: School[],
): Record<string, IValidateValue> => ({
  desiredStudyPlace: {
    type: EInputValidateType.STRING,
    value: formData.desiredStudyPlace,
    required: true,
  },
  school: {
    type: EInputValidateType.STRING,
    value: formData.desiredStudyPlace,
    required: !!schools.length,
  },
  gender: {
    type: EInputValidateType.STRING,
    value: formData.gender,
    required: true,
  },
});

export const entrantOptions = (
  formData: FormDataInterface,
): Record<string, IValidateValue> => ({
  desiredSpecialty: {
    type: EInputValidateType.ARRAY_STRING,
    value: formData.desiredSpecialty,
    required: true,
  },
});
